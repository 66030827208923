import { useState } from "react";
// mui
import { Box } from "@mui/material";
// component
import CarouselCustom from "../carousel-custom";
import NextSlideButton from "../carousel-custom/NextSlideButton";
import PrevSlideButton from "../carousel-custom/PrevSlideButton";
// theme and type
import { hPink, hPurple } from "@theme/index";
import { ICarouselContentProps } from "./CarouselContant.type";

export function CarouselContent({
  device,
  maxLength = 0,
  slideIconHeight,
  children,
}: ICarouselContentProps) {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const nextSlide = () => {
    if (currentSlide + 1 < maxLength) {
      setCurrentSlide((prevState) => prevState + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevState) => prevState - 1);
    } else {
      setCurrentSlide(maxLength - 1);
    }
  };
  const indicatorChange = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <Box height="100%" display="flex" alignItems="center" px={[8, 8, 8, 8, 12]}>
      <Box display="flex">
        {device === "desktop" && maxLength > 1 && (
          <div>
            <PrevSlideButton
              prevSlide={prevSlide}
              fontSize="80px"
              height={slideIconHeight || "240px"}
            />
          </div>
        )}
        <Box display="flex" width="100%">
          <CarouselCustom
            currentSlide={currentSlide}
            indicatorChange={indicatorChange}
            indicatorColor={`linear-gradient(296.45deg, ${hPink[300]} -3.27%, ${hPurple[200]} 102.18%)`}
          >
            {children}
          </CarouselCustom>
        </Box>
        {device === "desktop" && maxLength > 1 && (
          <div>
            <NextSlideButton
              nextSlide={nextSlide}
              fontSize="80px"
              height={slideIconHeight || "240px"}
            />
          </div>
        )}
      </Box>
    </Box>
  );
}
