import { useEffect, useState } from "react";
// mui
import { Box, Divider, Grid, Typography } from "@mui/material";
// icon and style
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { useViewInfluencerReportStyle as classes } from "./Report.style";
// component
import Engagement from "@components/global/campaign-report/Engagement";
import Conversion from "@components/global/campaign-report/Conversion";
import InfluencerPost from "@components/global/campaign-report/InfluencerPost";
import CampaignPerformance from "@components/global/campaign-report/CampaignPerformance";
import LocationDistribution from "@components/global/campaign-report/LocationDistribution";
import InfluencerProfileCard from "@components/employer-job/view-influencer-report/ProfileCard";
import AgeAndGenderDistribution from "@components/global/campaign-report/AgeAndGenderDistribution";
import InfluencerPostReportFilter from "@components/employer-job/view-influencer-report/PostReportFilter";
// api and type
import { ReportAPI } from "@api/global";
import { IGetInfluencerReportRes } from "@api/report/type";
import { IInitParams } from "@components/global/campaign-report/campaignReport.type";
// react route
import { useHistory, useParams } from "react-router-dom";
import Loading from "@components/global/loading/Loading";

export default function InfluencerPostReport() {
  const history = useHistory();
  const { jobId } = useParams<{ jobId: string }>();

  const [isLoading, setIsLoading] = useState(false)
  const [influencerReport, setInfluencerReport] = useState<IGetInfluencerReportRes | null>(null);
  const [params, setParams] = useState<IInitParams>({
    job_id: jobId,
    chart_type: "weekly",
  });

  useEffect(() => {
    let isMounted = true;
    
    const getInfluencerJobReport = () => {
      setIsLoading(true);

      ReportAPI.getInfluencerJobReport(params).then((response) => {
        if (isMounted) {
          setInfluencerReport(response);
          setIsLoading(false)
        }
      }).finally(() => {
        if (isMounted) {
          setIsLoading(false)
        }
      })
    }

    getInfluencerJobReport()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.job_id]);

  const componentList = [
    {
      component: <InfluencerPost socialPost={influencerReport?.social_post} />,
      xs: 4,
      display: "initial",
    },
    {
      component: <Engagement engagement={influencerReport?.engagement} />,
      xs: 12,
      display: "initial",
    },
    {
      component: <Conversion />,
      xs: 12,
      display: "none",
    },
    {
      component: <AgeAndGenderDistribution />,
      xs: 12,
      display: "none",
    },
    {
      component: <LocationDistribution />,
      xs: 12,
      display: "none",
    },
  ];

  const onFilterDate = (data: IInitParams) => {
    ReportAPI.getInfluencerJobReport(data).then((response) => {
      setInfluencerReport(response);
    });
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Box p={2}>
        <Box sx={classes.backBtn}>
          <span onClick={() => history.goBack()}>
            <ChevronLeftOutlinedIcon />
            <span>Back</span>
          </span>
        </Box>
        <Box sx={classes.postReportCon}>
          <div>
            <Typography fontSize="24px">Influencer Post Report</Typography>
            <Typography color="#5C5C5C">
              รายงานแสดงข้อมูลของแคมแปนของอินฟลูเอนเซอร์
            </Typography>
          </div>
          <Divider sx={{ my: "24px" }} />
          <Grid container spacing={5}>
            <InfluencerPostReportFilter
              params={params}
              setParams={setParams}
              publishLink={influencerReport?.social_post?.post?.published_url}
              filterDate={onFilterDate}
            />
            <Grid item xs={8}>
              <Grid container rowGap={5} sx={classes.postReportCardCon}>
                <Grid item xs={12}>
                  <InfluencerProfileCard
                    socialAccount={influencerReport?.social_account}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ height: "658px" }}>
                    <CampaignPerformance
                      columns={2}
                      performance={influencerReport?.impression}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {componentList.map((item, index) => (
              <Grid item xs={item.xs} key={index} display={item.display}>
                {item.component}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
