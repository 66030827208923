// mui and icons
import {
  Box,
  Grid,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// style and type
import { useTimelineDialogStyle as classes } from "../CampaignDetail.style";
import { ITimelineDialogProps } from "../CampaignDetail.type";
// others
import dayjs from "dayjs";

export default function TimelineSendReturnDialog(props: ITimelineDialogProps) {
  const { open, onClose, timeline } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>Timeline</Typography>
        <Typography>ระยะเวลาของแคมเปญ</Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.dialogContentBox}>
          <Grid container>
            <Grid item xs={12} mb={3}>
              <Box>
                <Typography variant="title1" color="primary">
                  วันจัดส่งสินค้าสำหรับการรีวิว
                </Typography>
                <Box display="flex" mt={5} flexDirection={["column", "row"]}>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center">
                    <Typography variant="title2">
                      จะจัดส่งสินค้าภายในวันที่
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} mt={[2, 0]}>
                    <Box sx={classes.openDate}>
                      <Typography variant="title2">
                        {timeline?.shipping_date &&
                          dayjs(timeline?.shipping_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Box
              width="100%"
              component="img"
              src={require("@assets/images/campaign/sent-product.png")}
            />
            <Box component="ul" ml={-5}>
              {[
                "อินฟลูเอนเซอร์ควรได้รับสินค้าภายใน 5 วันหลังทำการจัดส่ง โดยระยะเวลาในการจัดส่งขึ้นอยู่กับบริษัทและ ประเภทการจัดส่ง",
                "เมื่อได้รับสินค้า อินฟลูเอนเซอร์ต้องทำการตรวจสอบสินค้า พร้อมแนบหลักฐานการรับสินค้าก่อนเริ่มงานทุกครั้ง",
              ].map((li, index) => (
                <Typography key={index} variant="body2" component="li">
                  {li}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
