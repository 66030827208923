import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { JobAPI } from "@api/global";
import { IEmployerJobListState } from "./type";
import { IEmployerJobListParams } from "@api/job/type";

export const initialState: IEmployerJobListState = {
  jobList: null,
  listing: 0,
  offering: 0,
  working: 0,
  waiting_for_approve: 0,
  approved: 0,
  published: 0,
  completed: 0,
  rejected: 0,
  first_draft: 0,
  loading: false,
  error: "",
  total_item: 0,
  item_per_page: 0,
  page: 0,
};

export const getEmployerJobListAsync = createAsyncThunk(
  "employerJobListAsync",
  async (req:{ employerId: string, campaignId:string, params: IEmployerJobListParams }) => {
    try{
      const response = await JobAPI.getEmployerJobList(req.employerId, req.campaignId, req.params)
      return response
    }
    catch(err){
      throw(err)
    }
  }
)

export const EmployerJobSlice = createSlice({
  name:"employerJobSlice",
  initialState,
  reducers:{},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerJobListAsync.pending,(state) => {
        state.loading = true
      })
      .addCase(getEmployerJobListAsync.fulfilled,(state, { payload } ) => {
        state.jobList=payload.item;
        state.working=payload.working;
        state.waiting_for_approve=payload.waiting_for_approve;
        state.approved=payload.approved;
        state.published=payload.published;
        state.rejected=payload.rejected;
        state.first_draft=payload.first_draft;
        state.loading=false;
        state.total_item=payload.total_item;
        state.item_per_page=payload.item_per_page;
        state.page=110;
      })
      .addCase(getEmployerJobListAsync.rejected, (state) => {
        state.jobList = null;
        state.loading = false;
      });
  }
})

export const selectEmployerJobList = (state: RootState) => state.employerJobList

export default EmployerJobSlice.reducer;