// mui
import { Box } from "@mui/material";
// component and context
import SyncFacebook from "./SyncFacebook";
import InfluencerSocialHeader from "../default/Header";
import InfluencerSocialPending from "../default/Pending";
import InfluencerSocialInfo from "../default/Information";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";

export default function InfluencerFacebookTab() {
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const facebookInfo = influencerSocial?.facebook;

  return (
    <div>
      {!facebookInfo || !facebookInfo.length ? (
        <SyncFacebook />
      ) : facebookInfo[0].social_account.length === 0 ? (
        <InfluencerSocialPending platform="facebook" />
      ) : (
        facebookInfo && (
          <Box className="d-flex f-d-column" rowGap="20px">
            <InfluencerSocialHeader platform="facebook" />
            <InfluencerSocialInfo
              platform="facebook"
              email={facebookInfo[0].email}
            />
          </Box>
        )
      )}
    </div>
  );
}
