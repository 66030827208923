import { useContext, useState } from "react";
// mui, icon and style
import { Box, Divider, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { usePaymentSummaryStyle as classes } from "./Offering.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// component and others
import { depositInfo, percentPrice } from "@utils/helpers/payment";
import { CampaignInfoContext } from "@views/campaign/campaign-detail";
import DepositRate from "@components/campaign/campaign-charge/charge-dialog/DepositRate";

export default function PaymentSummary() {
  const [openDepositInfo, setOpenDepositInfo] = useState(false);

  const campaignWallet = useContext(CampaignInfoContext)?.campaignWalletBalance;
  const isWalletBalance = (campaignWallet?.total || 0) >= 0;
  const campPrice = campaignWallet?.campaign_price || 0;

  const employerInfo = useAppSelector(selectEmployerInfo)
  const isTeamPlan = employerInfo?.package_plan === "team";
  const teamBilling = employerInfo?.team?.billing_address;
  const personalBilling = employerInfo?.address.billing_address;
  const billing = isTeamPlan ? teamBilling : personalBilling;

  const numFormater = Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Box sx={classes.container}>
      <Box sx={classes.summaryBox}>
        <Typography variant="h6">สรุปการชำระเงิน</Typography>
        <Box display="flex" justifyContent="space-between" mt="16px">
          <Typography>ราคาแคมเปญ</Typography>
          <Typography variant="title1">
            {numFormater.format(campPrice)} THB
          </Typography>
        </Box>
        <Box sx={classes.summaryDetailBox}>
          <Box component="ul" m={0} width="100%">
            {[
              {
                label: `หักมัดจำ ${depositInfo(campPrice)}%`,
                total: percentPrice("deposit", campPrice),
              },
              {
                label: "ยอดสำรองจ่าย",
                total: percentPrice("amount", campPrice),
              },
            ].map((li, index) => (
              <Box key={index} component="li" ml={-2}>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="#333333" mr="10px">
                      {li.label}
                    </Typography>
                    {index === 0 && (
                      <IconButton
                        onClick={() => setOpenDepositInfo(true)}
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  <Typography variant="body2" textAlign="end" color="#333333">
                    {li.total} THB
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Divider sx={{ my: "16px", borderStyle: "dashed" }} />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" color="primary">
            {isWalletBalance ? "จำนวนเงิน" : "Top Up"}
          </Typography>
          <Typography variant="h5" color="primary">
            {numFormater.format(campaignWallet?.[isWalletBalance ? "campaign_price" : "total"] || 0)} THB
          </Typography>
        </Box>
      </Box>
      <Box sx={classes.summaryBox}>
        <Typography variant="h6">ข้อมูลใบเสร็จ</Typography>
        <Box my="16px">
          <Typography variant="title1">{employerInfo?.organisation?.name}</Typography>
          <Typography>{billing?.tax_id}</Typography>
          <Typography>
            {`${billing?.address} ${billing?.subdistrict?.name_th || ""} ${
              billing?.district?.name_th || ""
            } ${billing?.province?.name_th || ""} ${billing?.postcode}`}
          </Typography>
        </Box>
        <Box sx={{ bgcolor: "#F5EEFF", p: "16px", borderRadius: "8px" }}>
          <Typography>
            คุณจะได้รับใบเสร็จเมื่อแคมเปญดำเนินการเสร็จสิ้น
          </Typography>
        </Box>
      </Box>

      {/* dialog */}
      <DepositRate open={openDepositInfo} onClose={setOpenDepositInfo} />
    </Box>
  );
}
