const tab = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  cursor: "pointer",
  height: "36px",
  border: "1px solid #E0E0E0",
  borderRadius: "8px",
  padding: "10px",
}

export const useSocialTabStyles = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    display: "flex",
    height: "100%",
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
    borderTop: "1px solid #8C499C",
    columnGap: "15px",
  },
  tab: {
    ...tab,
    border: "1px solid #E0E0E0",
  },
  tabSelected: {
    ...tab,
    bgcolor:"#F5EEFF",
    borderWidth: 0,
    "& > p" :{
      fontWeight: 400
    }
  },
  noSocial: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "50px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  textNoSocial: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
  // Overall
  boxSocial: {
    padding: "20px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
};

export const useOtherPlatformStyle = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    py: { sm: 4 },
    px: { sm: 6 },
    columnGap: "30px",
    rowGap: { xs: "16px", sm: "30px" },
  },
}