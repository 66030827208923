import { Box, Divider, Typography } from "@mui/material";

export default function WhoIsTheCompany() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>2. Who is the Company? </Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>
        Masterplan Media Co., Ltd. was registered as a limited company under
        Thai laws on November 13, 2012, to operate marketing services through
        online media. The Company’s head office is located at HashU 152/35,
        Chaloem Phrakiat Ratchakan Thi 9 Rd, Nong Bon, Prawet, Bangkok
        10250.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        You can contact the Company as a data controller to inquire more about
        the Privacy Policy and the Company's practices in relation to the
        protection of personal data or request that the Company take any action
        via email: Info@hashu.co
      </Typography>
    </Box>
  );
}
