import { useEffect, useState } from "react";
// mui and style
import { Box, Fab, Grid, Typography } from "@mui/material";
import "./multiImageInput.css";
// icon
import imageIcon from "@assets/images/other/photo.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// api and alert
import { AppInformationAPI } from "@api/global";
import SwalCustom from "../sweet-alert-custom/SwalCustom";

interface IMultiImageInput {
  register: Function;
  keyName: string;
  setValue: Function;
  error?: any;
  [any: string]: any;
  getValues?: Function;
  icon?: string;
}

interface IFileItems {
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

interface IFileUpload {
  file_id: string;
  file_name: string;
  file_type: string;
  url: string;
  destination: string;
  purpose: string;
}

function MultiImageInput(props: IMultiImageInput) {
  const { register, keyName, setValue, error, icon, imageKeyData } = props;
  const [imageData, setImageData] = useState<IFileUpload[]>([]);

  useEffect(() => {
    if (imageKeyData) {
      setImageData(imageKeyData);
    }
  }, [imageKeyData]);

  const onInputImage = async (ev: {
    target: { files: Iterable<IFileItems> | ArrayLike<IFileItems> };
  }) => {
    const files = Array.from(ev.target.files);

    for (const item of files) {
      const data = {
        file: item,
        destination: "company",
        permission: "public",
      };
      await AppInformationAPI.uploadAttachment(data)
        .then((response) => {
          const result = {
            file_id: "",
            file_name: response.file_name,
            file_type: response.file_type,
            url: response.public_url as string,
            destination: response.destination,
            purpose: "",
          };
          setImageData((imageData: any) => [...imageData, result]);
          setValue(keyName, [...imageData, result]);
        })
        .catch((err) => {
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${err.response.data.error.message}`,
            confirmButtonColor: "#E30D18",
            confirmButtonText: "ตกลง",
          });
        });
    }

    // files.forEach(async (item) => {
    //   const data = {
    //     file: item,
    //     destination: "company",
    //     permission: "public",
    //   };
    //   await uploadImage(data)
    //     .then((response) => {
    //       const result = {
    //         file_id: "",
    //         file_name: response.file_name,
    //         file_type: response.file_type,
    //         url: response.public_url as string,
    //         destination: response.destination,
    //         purpose: "",
    //       };
    //       setImageData((imageData: any) => [...imageData, result]);
    //       setValue(keyName, [...imageData, result]);
    //     })
    //     .catch((err) => {
    //       SwalCustom.fire({
    //         icon: "error",
    //         title: "Oops !",
    //         text: `${err.response.data.error.message}`,
    //         confirmButtonColor: "#E30D18",
    //         confirmButtonText: "ตกลง",
    //       });
    //     });
    // });
  };

  const onHandleRemove = (index: number) => {
    const data = [...imageData];
    data.splice(index, 1);
    setImageData(data);
    setValue(keyName, data);
  };

  const onHandleChangeImage = async (e: any, fileId: string) => {
    const file = await e.target.files[0];
    const data = {
      file: file,
      destination: "company",
      permission: "public",
    };
    const imgData = [...imageData];
    const imgDataIndex = imgData.findIndex((item) => item.file_id === fileId);
    await AppInformationAPI.uploadAttachment(data)
      .then((response) => {
        imgData[imgDataIndex].file_name = response.file_name;
        imgData[imgDataIndex].url = response.public_url
          ? response.public_url
          : "";
        imgData[imgDataIndex].file_type = response.file_type;
        setImageData(imgData);
        setValue(keyName, imgData);
      })
      .catch((err) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${err.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  // const onHandleRemove = (data: any) => {
  //   const selectedItem = imageData.filter((item: any) => item.id !== data.id);
  //   setImageData(selectedItem);
  // };

  return (

    <Grid container spacing={4}>
      {imageData &&
        imageData?.map((item: IFileUpload, index: number) => {
          return (
            <Grid
              key={index}
              item
              position="relative"
              sx={{
                "& > button": {
                  bgcolor: "black",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "black",
                  },
                  zIndex: 0,
                },
              }}
            >
              <Fab
                onClick={() => onHandleRemove(index)}
                sx={{
                  position: "absolute",
                  top: "6px",
                  right: "-7px",
                  maxWidth: "20px",
                  minWidth: "20px",
                  maxHeight: "20px",
                  minHeight: "20px",
                }}
              >
                <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
              </Fab>
              <Box
                sx={{ display: "inline-block", cursor: "pointer" }}
                component="label"
              >
                <img
                  src={item.url}
                  alt="img"
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  id="input-file"
                  onChange={(e: any) => onHandleChangeImage(e, item.file_id)}
                />
              </Box>
            </Grid>
          );
        })}
      <Grid item>
        <div>
          <label
            className="label-upload"
            style={{
              position: "relative",
              borderColor: error ? "red" : "grey",
              // width: "150px",
              // height: "150px",
            }}
          >
            <div
              style={{
                width: "40px",
                marginBottom: "-5px",
              }}
            >
              <img src={imageIcon} alt="img" style={{ color: "white" }} />
            </div>
            <div>+ Image</div>
            <input
              className=""
              type="file"
              multiple
              accept="image/*"
              id="input-file"
              {...register(keyName, {
                required: true,
                onChange: onInputImage,
              })}
            />
            {!icon && (
              <Fab
                color="primary"
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "-10px",
                  maxWidth: "28px",
                  minWidth: "28px",
                  maxHeight: "28px",
                  minHeight: "28px",
                  zIndex: 9,
                }}
              >
                <CameraAltIcon sx={{ fontSize: "16px" }} />
              </Fab>
            )}
          </label>
        </div>
        {error && (
          <Typography color="error" fontSize="12px" m="10px 0 0 11px">
            {error.message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default MultiImageInput;
