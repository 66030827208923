import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { IEmployerReportParams, IInfluencerReportParams } from "./type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class ReportAPIs {
  async getInfluencerJobReport(params: IInfluencerReportParams) {
    const { access_token } = getTokenFromStorage();
    
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const date = JSON.stringify(params.date);
    const { data } = await instance.get("/reports", { headers, params: { ...params, date: date }});
    return data.result.data;
  }

  async getEmployerReport(params: IEmployerReportParams) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const date = JSON.stringify(params.date);
    const { data } = await instance.get("/reports", { headers, params: { ...params, date: date }});
    return data.result.data;
  }

  async getAccountReportList(campaignId: string) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/reports/${campaignId}/account-list`, { headers });
    return data.result.data;
  }

  async campaignPostReportAsCSV(campaignId: string) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/reports/${campaignId}/download/csv`, { headers });
    return data.result.data;
  }
}