import { styled } from "@mui/material";

export const useCampaignBriefTabStyle = {
  tabsCon: {
    width: "920px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "108px",
    borderRadius: "20px",
    backgroundColor: "#fff",
    mb: "24px",
    position: "sticky",
    top: "70px",
    zIndex: 10,
    "& > div": {
      width: "96%",
    },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  organization: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
      borderRadius: "4px",
      mr: "5px",
    },
  },
  tabButton: {
    width: "100%",
    "& .css-15qycc7-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      mt: "10px",
    },
  },
};

export const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: "32px",
  height: "32px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));
