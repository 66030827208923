import React, { useEffect, useState } from "react";
// mui
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
// component
import AwenessCard from "./AwenessCard";
import CardInfluencer from "./CardInfluencer";
import EstimateSummary from "./EstimateSummary";
// api and type
import { JobAPI } from "@api/global";
import { IInfluencerActiveInfoRes } from "@api/job/type";
import { IJobEmployerCampaignParams } from "@api/influencer/type";
// redux
import { useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
// other
import { hPink } from "@theme/index";
import Loading from "@global/loading/Loading";
import { useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

const initialParams: IJobEmployerCampaignParams = {
  page: 1,
  total_page: 1,
  item_per_page: 10,
  sort: "DESC",
  total_item: 0,
};

export default function InfluencerListTab() {
  const { session_token } = getTokenFromStorage();
  const { campaignId } = useParams<{ campaignId: string }>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";

  const campaignInfo = useAppSelector(selectCampaignInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [campaignInfluencerList, setCampaignInfluencerList] = useState<IInfluencerActiveInfoRes>();

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    JobAPI.getCampaignInfluencerList(employerId, campaignId, initialParams)
      .then((response) => {
        if (isMounted) {
          setCampaignInfluencerList(response);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false
    }
  }, [employerId, campaignId]);

  const onExportShippingAddress = () => {
    JobAPI.downloadShippingAddress(campaignId, employerId).then((response) => {
      window.open(response.public_url)
    })
  }

  if (isLoading) return <Loading />

  return (
    <div>
      <EstimateSummary summary={campaignInfluencerList?.summary} />
      <Box display="flex" justifyContent="space-between" mt="28px">
        <Typography variant="h6">
          รายชื่อผู้ผ่านการคัดเลือก {campaignInfluencerList?.total_item} คน
        </Typography>
        <Button
          onClick={onExportShippingAddress}
          sx={{
            bgcolor: hPink[300],
            color: "white",
            px:"15px",
            "&:hover": {
              color: hPink[300],
            },
          }}
        >
          <Icon className="ico-hu-download" sx={{ mr: "4px" }} />
          <Typography variant="buttonM">Export Shipping Address</Typography>
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" mt="25px">
        <Grid container spacing={4}>
          {campaignInfluencerList?.items.map((item, index) => (
            <React.Fragment key={index}>
              {campaignInfo?.objective === "awareness" ? (
                campaignInfo.location !== null ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    xl={2}
                    key={item.job_id}
                  >
                    <AwenessCard item={item} />
                  </Grid>
                ) : campaignInfo.review_type === "product" &&
                  campaignInfo.product_review_type === "no_shipping" ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    xl={2}
                    key={item.job_id}
                  >
                    <AwenessCard item={item} />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} lg={3} key={item.job_id}>
                    <CardInfluencer item={item} />
                  </Grid>
                )
              ) : campaignInfo?.objective === "conversion" ? (
                <Grid item xs={12} sm={6} lg={3} key={item.job_id}>
                  <CardInfluencer item={item} />
                </Grid>
              ) : null}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
