import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { CampaignAPI } from "@api/global";
import { ICampaignInfoState } from "./type";

const initialState: ICampaignInfoState = {
  data: null,
  loading: false,
  error: "",
  snackBar: null,
  snackBarIsActive: false
};

export const getCampaignInfoAsync = createAsyncThunk(
  "getCampaignInfoAsync",
  async (campaignId: string) => {
    try {
      const response = await CampaignAPI.getCampaignInfo(campaignId);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const campaignInfoSlice = createSlice({
  name: "campaignInfo",
  initialState,
  reducers: {
    setCampaignInfoStatus: (state, { payload }) => {
      if (state.data) {
        state.data.status = payload;
      }
    },
    setSnackBar:(state, { payload }) => {
      state.snackBar = payload;
    },
    setSnackBarIsActive:(state, { payload }) => {
      state.snackBarIsActive = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignInfoAsync.pending, (state) => {
        state.data = null;
        state.loading = true;
        state.error = "";
      })
      .addCase(getCampaignInfoAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getCampaignInfoAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const { setCampaignInfoStatus, setSnackBar, setSnackBarIsActive } = campaignInfoSlice.actions;

export const selectCampaignInfo = (state: RootState) => state.campaignInfo.data;
export const selectCampaignInfoOnload = (state: RootState) => state.campaignInfo.loading;
export const campDetailSnackbar = (state: RootState) => state.campaignInfo.snackBar;
export const campDetailSnackbarIsActive = (state: RootState) => state.campaignInfo.snackBarIsActive;

export default campaignInfoSlice.reducer;



