import { useEffect, useState } from "react";
// mui
import {
  Box,
  Card,
  Grid,
  Link,
  Button,
  Divider,
  Typography,
} from "@mui/material";
// style and icon
import { useTrackStatusStyles as classes } from "./TackStatus.style";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// util and component
import { scrollToTop } from "@utils/helpers/scrollToTop";
import CardOrganizing from "@components/campaign/campaign-private/CardOrganizing";
// type
import { IPrivateItemRes } from "@api/job/type";
// redux
import {
  getInfluencerPrivateCampaign,
  selectInfluencerPrivateCampaign,
} from "@slices/CampaignPrivate.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
// other
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function TrackProposalStatus() {
  const { session_token } = getTokenFromStorage()

  const history = useHistory();
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const employerId = params.get("emp_id") || session_token?.employer_id || ""
  const { campaignId } = useParams<{campaignId: string}>();

  const dispatch = useAppDispatch();
  const influencerPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);
  const campaignInfo = useAppSelector(selectCampaignInfo);

  const [offering, setOffering] = useState<IPrivateItemRes[] | undefined>([]);
  const [canceled, setCanceled] = useState<IPrivateItemRes[] | undefined>([]);
  const [reserved, setReserved] = useState<IPrivateItemRes[] | undefined>([]);
  const [working, setWorking] = useState<IPrivateItemRes[] | undefined>([]);

  useEffect(() => {
    const offeringList = influencerPrivateInfo?.item.filter((item) => {
      return item.status === "offering";
    });
    const canceledList = influencerPrivateInfo?.item.filter((item) => {
      return item.status === "canceled";
    });
    const reservedList = influencerPrivateInfo?.item.filter((item) => {
      return item.status === "reserved";
    });
    const workingList = influencerPrivateInfo?.item.filter((item) => {
      return item.status === "working";
    });
    setOffering(offeringList);
    setCanceled(canceledList);
    setReserved(reservedList);
    setWorking(workingList);
  }, [influencerPrivateInfo?.item]);

  useEffect(() => {
    const params = {
      employerId: employerId,
      campaignId: campaignId,
      params: {
        page: 1,
        item_per_page: 20,
      },
    };
    dispatch(getInfluencerPrivateCampaign(params));
    scrollToTop();
  }, [dispatch, employerId, campaignId]);

  const goSelectMoreInfluencer = () => {
    history.push(`/campaigns/${campaignId}/select-more-influencer?emp_id=${employerId}`);
  };

  return (
    <div className="container-main">
      <Link
        onClick={() => history.goBack()}
        underline="none"
        sx={classes.goBack}
      >
        <KeyboardArrowLeftIcon />
        <span>Back</span>
      </Link>

      <div>
        <Typography variant="h5">Track Proposal Status</Typography>
        <Typography variant="subtitle1">ติดตามสถานะการยื่นข้อเสนอ</Typography>
      </div>
      <Box sx={{ marginTop: "24px" }}>
        <Card sx={{ padding: "24px" }}>
          <Box>
            <Typography variant="h6">Estimate Summary</Typography>
            <Typography>
              ค่าประมาณการที่คำนวณจากอินฟลูเอนเซอร์จากการยื่่นข้อเสนอให้อินฟลูเอนเซอร์ตัวจริงในครั้งแรกเท่านั้น
            </Typography>
          </Box>

          <Box sx={classes.boxRemark}>
            <Typography variant="title2">Remark :</Typography>
            <Box
              sx={{
                ...classes.remark,
                backgroundColor: "#EBDFFF",
              }}
            ></Box>
            <Typography variant="body2">ยื่นข้อเสนอ</Typography>
            <Box
              sx={{
                ...classes.remark,
                backgroundColor: "#FFFFFF",
                border: "1px solid #E0E0E0",
              }}
            ></Box>
            <Typography variant="body2">ยังไม่ได้ยื่นข้อเสนอ</Typography>
            <Box
              sx={{
                ...classes.remark,
                backgroundColor: "#E0E0E0",
              }}
            ></Box>
            <Typography variant="body2">ปฏิเสธข้อเสนอ</Typography>
          </Box>

          {offering?.length ? (
            <Box sx={{ marginTop: "24px" }}>
              <Typography variant="h6">
                อินฟลูเอนเซอร์ได้รับข้อเสนอแล้ว
              </Typography>
              <Grid container spacing={4} sx={{ marginTop: "0px" }}>
                {offering?.map((item) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      lg={1.2}
                      xl={1}
                      key={item.influencer_id}
                    >
                      <CardOrganizing item={item} handleClick={() => {}} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ) : null}

          {canceled?.length ? (
            <Box sx={{ marginTop: "24px" }}>
              <Typography variant="title1">
                อินฟลูเอนเซอร์ที่ปฏิเสธข้อเสนอ
              </Typography>
              <Grid container spacing={4} sx={{ marginTop: "0px" }}>
                {canceled?.map((item) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      lg={1.2}
                      xl={1}
                      key={item.influencer_id}
                    >
                      <CardOrganizing item={item} handleClick={() => {}} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ) : null}
        </Card>

        <Card sx={classes.card}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <Typography variant="h6">กลุ่มที่ยังไม่ได้ยื่นข้อเสนอ</Typography>
              <Typography>
                ระบบจะทำการยื่นข้อเสนอให้กับอินฟลูเอนเซอร์ในรอบถัดไปในช่วงเวลาที่กำหนด
                (ทุกๆ 24 ชั่วโมง)
              </Typography>
            </div>
            <div>
              <Button
                onClick={goSelectMoreInfluencer}
                disabled={
                  Number(working?.length) >= Number(campaignInfo?.job_limit)
                    ? true
                    : false
                }
              >
                เพิ่มรายชื่ออินฟลูเอนเซอร์
              </Button>
            </div>
          </Box>

          <Divider sx={classes.divider} />

          {reserved?.length ? (
            <Grid container spacing={4}>
              {reserved?.map((item) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={2}
                    lg={1.2}
                    xl={1}
                    key={item.influencer_id}
                  >
                    <CardOrganizing item={item} handleClick={() => {}} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box sx={classes.boxNoData}>
              <img
                src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
                alt="no campaign"
              />
              <Box sx={classes.boxTextNoData}>
                <Typography variant="h5" my="8px">
                  ไม่พบรายชื่อที่ยังไม่ได้ยื่นข้อเสนอ
                </Typography>
                <Typography>
                  ขณะนี้ระบบได้ยื่นข้อเสนอให้กับอินฟลูเอนเซอร์ที่คุณเลือกทั้งหมดแล้ว
                  กดปุ่ม "เพิ่มรายชื่ออินฟลูเอนเซอร์"
                  หากคุณต้องการยื่นข้อเสนอเพิ่มเติม
                </Typography>
              </Box>
            </Box>
          )}
        </Card>
      </Box>
    </div>
  );
}
