// mui
import { Box, Typography } from "@mui/material";

export default function Personal() {
  return (
    <div>
      <Typography variant="h6">
        ขั้นตอนการเชื่อมต่อบัญชี Personal Account
      </Typography>

      <Box mt="4px">
        {[
          `1. กดเมนู My Profile และมองหาแถบ Instagram จากนั้นคลิกปุ่ม "Personal Account"`,
          `2. เปิดหน้าบัญชี Instagram ที่ต้องการเชื่อมต่อ โดยคัดลอกลิงค์หน้าโปรไฟล์ แล้วนำมาวางช่อง "Instagram URL"`,
          `3. คลิกปุ่ม "กดที่นี่" เพื่อขอรหัสการยืนยันตัวตน"`,
          `4. จากนั้นคัดลอกรหัสที่ได้รับ นำไปวางในหน้า "Instagram Bio" ของคุณ(คุณสามารถลบรหัสออกได้หลังทำการเชื่อมต่อเสร็จสิ้น)`,
          `5. กลับมาหน้า HashU อีกครั้ง คลิกปุ่ม"Verify Instagram Account"`,
          `6. เมื่อทำการเชื่อมต่อสำเร็จ ให้เลือก 3
          คอนเทนท์และกลุ่มเป้าหมายที่เกี่ยวข้องกับบัญชีมากที่สุด แล้วคลิกปุ่ม "Verify Instagram Account"`,
          `7. การเชื่อมต่อ Instagram แบบ Personal Account เสร็จสิ้น`
        ].map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
      </Box>

      <Box
        sx={{
          mt: "24px",
          mb: "24px",
          "& video": {
            width: "100%",
          },
        }}
      >
        <video
          height="auto"
          controls
          src="https://storage.googleapis.com/hashu-bucket-uat/media/videos/personal.mp4"
        />
      </Box>
    </div>
  );
}
