import { useState } from "react";
// mui and icon
import { Box, Button, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Instagram from "@assets/images/social/ig-glyph-gradient.png";
// style and component
import { useInstagramProfessionalStyle as classes } from "../../../InfluencerSocial.style";
import Connected from "./Connected";

export default function NonConnect(props: {
  checkComponents: (value: boolean) => void;
}) {
  const { checkComponents } = props;

  const [continues, setContinues] = useState(false);

  return continues ? (
    <Connected setContinues={setContinues} continues={continues} />
  ) : (
    <Stack sx={classes.container}>
      <Box sx={classes.goBack} onClick={() => checkComponents(false)}>
        <ArrowBackIosIcon />
      </Box>
      <Box sx={classes.syncSocial} py={3}>
        <img
          src={Instagram}
          alt="facebook_sync"
          style={{
            height: "72px",
            width: "72px",
          }}
        />
      </Box>
      <Box textAlign="center" width="40%" pt={4}>
        <Typography variant="h5" color="inherit">
          Connect with Instagram
        </Typography>
      </Box>
      <Box textAlign="center" py={5}>
        <Typography variant="body1" mx="10px">
          ขั้นตอนการเชื่อมต่อ Instagram Account กับ Facebook Page
        </Typography>
      </Box>
      <Box
        sx={{
          mb: "24px",
          "& video": {
            width: "100%",
          },
        }}
      >
        <video
          height="auto"
          controls
          src="https://storage.googleapis.com/hashu-bucket-uat/media/videos/Connect%20Tiktok%20Desktop%20v.2%2Bmusic.mp4"
        />
      </Box>
      <Box>
        <Typography variant="body1">
          ฉันได้ทำการเชื่อมต่อ Facebook Page แล้ว
        </Typography>
      </Box>
      <Box sx={classes.syncSocial} py={3}>
        <Button sx={classes.buttonSync} onClick={() => setContinues(true)}>
          ดำเนินการต่อ
        </Button>
      </Box>
    </Stack>
  );
}
