import { useEffect, useState } from "react";
// mui
import {
  Box,
  Button,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
// style
import {
  GridDropZoneStyle,
  useCampaignPrivateStyles as classes,
} from "../CampaignPrivate.style";
// component
import CardOrganizing from "../CardOrganizing";
import BudgetStatus from "../status/BudgetStatus";
import OrganizingStatus from "../status/OrganizingStatus";
import PrivateStep from "../../campaign-detail/PrivateStep";
import ConfirmPrivateJobDialog from "./ConfirmPrivateJobDialog";
// type
import { IPrivateItemRes } from "@api/job/type";
import { IPrivateSummary } from "../PrivateCampaign.type";
// route
import { useHistory, useLocation, useParams } from "react-router-dom";
// redux
import {
  insertJobPrivateCampaign,
  getInfluencerPrivateCampaign,
  selectInfluencerPrivateCampaign,
} from "@slices/CampaignPrivate.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
// react-grid-dnd
import {
  swap,
  move,
  GridItem,
  GridDropZone,
  GridContextProvider,
} from "react-grid-dnd";
// data
import { initPrivateSummary } from "../PrivateCampaignData";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function IndexOrganizing() {
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.up("xs"));

  const dispatch = useAppDispatch();
  const influencerPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const jobLimit = campaignInfo?.job_limit || 0;

  const [items, setItems] = useState<any>({
    listing: [],
    reserved: [],
  });
  const [privateSummary, setPrivateSummary] = useState<IPrivateSummary>(initPrivateSummary);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const params = {
      employerId: employerId,
      campaignId: campaignId,
      params: {
        page: 1,
        item_per_page: 20,
      },
    };
    dispatch(getInfluencerPrivateCampaign(params));
  }, [dispatch, employerId, campaignId]);

  useEffect(() => {
    const filterListing =
      influencerPrivateInfo &&
      influencerPrivateInfo?.item?.filter((item) => {
        return item.status === "stealth" || item.status === "offering";
      });
    const filterReserved =
      influencerPrivateInfo &&
      influencerPrivateInfo?.item?.filter((item) => {
        return item.status === "reserved";
      });
    setItems({
      listing: filterListing,
      reserved: filterReserved,
    });
  }, [influencerPrivateInfo, influencerPrivateInfo?.item]);

  useEffect(() => {
    const followers = items.listing?.reduce(
      (sum: number, item: IPrivateItemRes) =>
        (sum = sum + Number(item.follower)),
      0
    );
    const likes = items.listing?.reduce(
      (sum: number, item: IPrivateItemRes) =>
        (sum = sum + Number(item.avg_like)),
      0
    );
    const comments = items.listing?.reduce(
      (sum: number, item: IPrivateItemRes) =>
        (sum = sum + Number(item.avg_comment)),
      0
    );
    const shares = items.listing?.reduce(
      (sum: number, item: IPrivateItemRes) =>
        (sum = sum + Number(item.avg_share)),
      0
    );
    const budgets = items.listing?.reduce(
      (sum: number, item: IPrivateItemRes) =>
        (sum = sum + Number(item.rate_card)),
      0
    );
    setPrivateSummary({
      followers: followers,
      likes: likes,
      comments: comments,
      shares: shares,
      budgets: budgets,
      privateSelected: items.listing?.length,
    });
  }, [items.listing]);

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ) => {
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems((prevItems: any) => ({
        ...prevItems,
        [sourceId]: result[0],
        [targetId]: result[1],
      }));
    } else {
      const result = swap(items[sourceId], sourceIndex, targetIndex);
      return setItems((prevItems: any) => ({
        ...prevItems,
        [sourceId]: result,
      }));
    }
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const handleOffering = () => {
    if (role === "employer") {
      history.push(`/campaigns/${campaignId}/info?st=private-offering`);
    } else {
      history.push(`/campaigns/${campaignId}/info?st=private-offering&emp_id=${employerId}`);
    }
  };

  const confirmJobPrivateWithAPI = async (
    data: IPrivateItemRes[],
    reserved: IPrivateItemRes[]
  ) => {
    if (privateSummary.privateSelected < jobLimit) {
      setOpenDialog(true);
    } else {
      const newData = data.map((item) => {
        return {
          social_account_id: item.id,
          status: "stealth",
        };
      });
      const newReserved = reserved.map((item) => {
        return {
          social_account_id: item.id,
          status: "reserved",
        };
      });
      const mergedData = newData.concat(newReserved);
      const req = {
        employerId: employerId,
        campaignId: campaignId,
        body: mergedData,
      };
      try {
        dispatch(insertJobPrivateCampaign(req));
        handleOffering();
      } catch (err: any) {
        return err;
      }
    }
  };

  return (
    <>
      <Box sx={classes.privateWrap}>
        <PrivateStep activeStep={3} />

        <div>
          <Typography variant="h5">Organizing Your Influencer List</Typography>
          <Typography variant="subTitle1">
            จัดการลำดับรายชื่อของอินฟลูเอนเซอร์
          </Typography>
        </div>

        <Box m="24px 0">
          <Typography variant="h5">1. อินฟลูเอนเซอร์ตัวจริง</Typography>
          <Typography variant="subTitle1">
            Estimate Summary
            เป็นค่าประมาณการที่คำนวณจากการเลือกอินฟลูเอนเซอร์ตัวจริงเท่านั้น
          </Typography>
        </Box>

        <Box sx={classes.boxStatus}>
          <OrganizingStatus
            jobLimit={jobLimit}
            privateSummary={privateSummary}
          />
        </Box>

        <BudgetStatus
          use={privateSummary.budgets}
          total={Number(influencerPrivateInfo?.total_budget)}
        />

        <GridContextProvider onChange={onChange}>
          <Box sx={{ touchAction: "none" }}>
            <GridDropZone
              id="listing"
              boxesPerRow={xl ? 8 : lg ? 7 : md ? 4 : sm ? 3 : xs ? 2 : 2}
              rowHeight={200}
              style={{
                ...GridDropZoneStyle,
                height: `${
                  Math.floor(
                    (items.listing?.length - 1) /
                      (xl ? 8 : lg ? 7 : md ? 4 : sm ? 3 : xs ? 2 : 2) +
                      1
                  ) * 2
                }50px`,
              }}
            >
              {items &&
                items.listing?.map((item: IPrivateItemRes) => {
                  return (
                    <GridItem key={item.influencer_id}>
                      <CardOrganizing
                        item={item}
                        influencer={items}
                        setInfluencer={setItems}
                        handleClick={() => {}}
                      />
                    </GridItem>
                  );
                })}
            </GridDropZone>
            <Box sx={{ margin: "24px 0" }}>
              <Typography variant="h6">2. อินฟลูเอนเซอร์ตัวสำรอง</Typography>
              <Typography>
                กรณีที่รายชื่อตัวจริงปฎิเสธการรับงาน
                ระบบจะดำเนินการส่งคำเชิญให้ตัวสำรองตามลำดับ
              </Typography>
            </Box>
            <GridDropZone
              id="reserved"
              boxesPerRow={xl ? 8 : lg ? 7 : md ? 4 : sm ? 3 : xs ? 2 : 2}
              rowHeight={200}
              style={{
                ...GridDropZoneStyle,
                height: `${
                  Math.floor(
                    (items.reserved?.length - 1) /
                      (xl ? 8 : lg ? 7 : md ? 4 : sm ? 3 : xs ? 2 : 2) +
                      1
                  ) * 2
                }50px`,
              }}
            >
              {items &&
                items.reserved?.map((item: IPrivateItemRes) => {
                  return (
                    <GridItem key={item.influencer_id}>
                      <CardOrganizing
                        item={item}
                        influencer={items}
                        setInfluencer={setItems}
                        handleClick={() => {}}
                      />
                    </GridItem>
                  );
                })}
            </GridDropZone>
          </Box>
        </GridContextProvider>

        <Divider sx={{ margin: "24px 0" }} />

        <Box display="flex" justifyContent="space-between">
          <Button
            onClick={() => history.goBack()}
            sx={classes.backButton}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              confirmJobPrivateWithAPI(items.listing, items.reserved);
            }}
            disabled={
              privateSummary.privateSelected === 0
                ? true
                : privateSummary.privateSelected > jobLimit
                ? true
                : false
            }
            sx={classes.nextButton}
          >
            Next
          </Button>
        </Box>
      </Box>

      <ConfirmPrivateJobDialog
        open={openDialog}
        onClose={onClose}
        listing={items.listing}
        reserved={items.reserved}
        jobLimit={jobLimit}
        handleOffering={handleOffering}
      />
    </>
  );
}
