export const useCampaignOverviewStyle = {
  productDetail: {
    "& .MuiAvatar-root": {
      width: "150px",
      height: "150px",
      borderRadius: "8px",
      cursor: "pointer",
    },
    mt: { xs: "-10px", sm: 0 },
  },
  productDetails: {
    display: "flex",
    "& .MuiAvatar-root:not(.imgItem4)": {
      width: {
        xs: "50%",
        sm: "150px",
      },
      height: {
        xs: "50%",
        sm: "150px",
      },
      borderRadius: {
        xs: "8px",
        sm: 0,
      },
      cursor: "pointer",
    },
    "& > .MuiAvatar-root:first-of-type": {
      borderRadius: {
        xs: "8px",
        sm: "8px 0 0 8px",
      },
    },
    "& > .MuiAvatar-root:last-of-type": {
      borderRadius: {
        xs: "8px",
        sm: "0 8px 8px 0",
      },
    },
    flexWrap: "wrap",
    height: {
      xs: "350px",
      sm: "175px",
    },
  },
  ul: {
    width: "93%",
    mt: "12px",
    mb: 0,
  },
  detailItem: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
  },
  txtEl: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
  },
  map: {
    mt: "16px",
    width: "98%",
    maxWidth: "600px",
    height: "80%",
    "& img": {
      width: { xs: "90%", sm: "100%" },
    },
    cursor: "pointer",
    objectFit: "cover",
  },
  mapUrl: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: "blue",
    },
  },
  category: {
    "& span:last-of-type": { display: "none" },
    mt: {
      xs: "-15px",
      sm: 0,
    },
  },
  imgItemMoreThan4: {
    position: "absolute",
    width: {
      xs: "100%",
      sm: "150px",
    },
    height: {
      xs: "100%",
      sm: "150px",
    },
    bgcolor: "#000",
    top: 0,
    left: 0,
    opacity: 0.6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: {
      xs: "8px",
      sm: "0 8px 8px 0",
    },
  },
  prodImg: {
    position: "relative",
    "& .css-1pqm26d-MuiAvatar-img, .css-1dfe1y8": {
      borderRadius: {
        xs: "0 8px 8px 0",
        sm: "8px",
      },
      width: "100%",
      height: "100%",
    },
    width: {
      xs: "50%",
      sm: "150px",
    },
    height: {
      xs: "165px",
      sm: "150px",
    },
    "& > .css-1cfuqz3-MuiAvatar-root": {
      width: {
        xs: "100%",
        sm: "150px",
      },
      height: {
        xs: "100%",
        sm: "150px",
      },
    },
  },
};

export const useCampaignBriefStyle = {
  breifVideo: {
    "& video": {
      width: "100%",
      height: "100%",
      borderRadius: "8px",
      cursor: "pointer",
    },
    position: "relative",
    width: {
      xs: "47%",
      sm: "150px",
    },
    height: {
      sm: "150px",
    },
  },
  iconPlayVdo: {
    position: "absolute",
    width: "100%",
    height: {
      xs: "95%",
      sm: "100%",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    "& > .material-icons": {
      fontSize: "40px",
      color: "#fff",
    },
  },
  hashtagBox: {
    minHeight: "48px",
    bgcolor: "#F4ECFF",
    display: "flex",
    px: "10px",
    justifyContent: "space-between",
  },
  hashtag: {
    cursor: "auto",
    maxHeight: "32px",
    mr: "10px",
    my: "5px",
    fontSize: "15px",
    fontWeight: 300,
    letterSpacing: "0.15px",
    border: "1px solid #8C499C",
    bgcolor: "#fff",
    "&:hover": {
      border: "1px solid #8C499C",
    },
  },
  hashtagCopyBox: {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "32px",
    height: "32px",
    bgcolor: "#fff",
    cursor: "pointer",
  },
};

export const useBudgetAndRewardStyle = {
  compensation: {
    border: "none",
    cursor: "auto",
    "&:hover": { border: "none" },
    lineHeight: "inherit",
  },
  productAndServiceGrid: {
    mt: { xs: "-5px", sm: 0, md: "-15px", lg: 0 },
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    mb: { xs: 0, md: "40px", lg: 0 },
  },
  productAndServiceCon: {
    border: "1px solid #8C499C",
    borderRadius: "8px",
    height: { xs: "72px", xl: "88px" },
    width: {
      xs: "100%",
      sm: "100%",
    },
    display: "flex",
    alignItems: "center",
    mt: "5px",
    mr: "5px",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      borderRadius: "4px",
      width: { xs: "48px", xl: "64px" },
      height: { xs: "48px", xl: "64px" },
      ml: "12px",
    },
  },
  productAndService: {
    ml: "8px",
    height: "76%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& > p": {
      width: "90%",
      wordBreak: "break-all",
      textOverflow: "ellipsis",
      display: "-webkit-box !important",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
    },
    "& p:first-of-type": {
      WebkitLineClamp: "2",
      lineHeight: "16px",
    },
    "& p:last-of-type": {
      fontSize: "12px",
      color: "#5C5C5C",
      whiteSpace: "normal",
      WebkitLineClamp: "1",
    },
  },
};

export const useDoOrDoNotStyle = {
  do: {
    bgcolor: "rgba(119, 221, 119, 0.1)",
    padding: "16px",
    borderRadius: "12px",
    "& > span": {
      fontWeight: "500",
      color: "#4CAF50",
    },
  },
  dont: {
    bgcolor: "rgba(255, 132, 132, 0.1)",
    padding: "16px",
    borderRadius: "12px",
    "& > span": {
      fontWeight: "500",
      color: "#F44336",
    },
  },
};

export const useTargetAudienceStyle = {
  circle: {
    fontSize: "7px",
    mx: "8px",
  },
  lastComma: {
    display: "flex",
    "p:last-of-type + span": { display: "none" },
    flexWrap: "wrap",
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    display: "flex",
    alignItems: "center",
  },
};

export const useSocialMediaAccountStyle = {
  platform: {
    display: "flex",
    alignItems: "center",
  },
  social: {
    display: "flex",
    borderRadius: "10px",
    bgcolor: "#F5F6F8",
    height: "72px",
    width: "100%",
    maxWidth: "400px",
    alignItems: "center",
    "& .css-urvyfo-MuiAvatar-root": {
      width: "48px",
      height: "48px",
      borderRadius: "4px",
      ml: "13px",
    },
    "& div": {
      ml: "13px",
      "& p:first-of-type": {
        color: "#8C499C",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
      },
      "& p:last-of-type": {
        color: "#5C5C5C",
        fontSize: "12px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "capitalize",
      },
    },
  },
};

export const useTermsAndConditionsDetailStyle = {
  checkBox: {
    display: "flex",
    mb: "10px",
    "& > span": { height: "28px", ml: "-12px" },
  },
};

const icon = {
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const useTimelineStyle = {
  con: {
    mt: {
      xs: "15px",
      lg: 0,
    },
  },
  stepLabelIconActive: {
    ...icon,
    bgcolor: "#fff",
    color: "#8C499C",
    border: "2px solid #8C499C",
  },
  stepLabelIconNotActive: {
    ...icon,
    bgcolor: "#fff",
    color: "#EBDFFF",
    border: "2px solid #EBDFFF",
  },
  stepLabelIconSuccess: {
    ...icon,
    bgcolor: "#8C499C",
    color: "#fff",
    border: "2px solid #8C499C",
  },
  lineIsActive: {
    ml: "24px",
    borderLeft: "3px dashed #8C499C",
    pb: 3,
    height: "20px",
  },
  lineNotActive: {
    ml: "24px",
    borderLeft: "3px solid #F5F6F8",
    pb: 3,
    height: "20px",
  },
  lineSuccess: {
    ml: "24px",
    borderLeft: "3px solid #8C499C",
    pb: 3,
    height: "20px",
  },
  leftCon: {
    borderLeft: "4px solid #F5F6F8",
    position: "absolute",
    top: "40px",
    left: "63px",
    zIndex: 1,
  },
  leftConButton: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    bgcolor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 10,
    "& svg": {
      fontSize: "20px",
      color: "#fff",
      zIndex: 9,
    },
    border: "2px dashed #8C499C",
  },
  leftConButton2: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    bgcolor: "#F5F6F8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    "& svg": {
      fontSize: "20px",
      color: "#5C5C5C",
    },
  },
};

export const useTermsAndConditionsDialogStyle = {
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "96px",
    borderBottom: "1px solid #EBDFFF",
    "& p:first-of-type": {
      fontSize: "20px",
      fontWeight: "500",
      color: "#8C499C",
      mb: "4px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
      fontSize: "14px",
    },
  },
  dialogContent: {
    mt: "15px",
    borderRadius: "12px",
    padding: "8px 8px 0 8px",
  },
  table: {
    "& table, td, th": {
      border: "1px solid #ddd",
      textAlign: "left",
    },
    "& table":{
      borderCollapse: "collapse",
      width: "100%",
      mt:"10px"
    },
    "& th, td":{
      padding: "15px",
    },
    "& td":{
      color:"red"
    }
  },
  acceptCondition: {
    display: "flex",
    justifyContent: "center",
    "& button:first-of-type": {
      border: "none",
      "&:hover": { border: "none" },
      width: "277px",
      maxHeight: "40px",
    },
    "& button:last-of-type": {
      width: "277px",
      maxHeight: "40px",
      ml: "15px",
    },
  },
};

export const useCreatecampaignSuccessStyle = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    my: "20px",
    mx: "10px",
    "& > .MuiAvatar-root": {
      width: "240px",
      height: "240px",
      borderRadius: "10px",
    },
  },
  contentTxt: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "& p:first-of-type": {
      fontWeght: "500",
      color: "#893DBD",
      m: "25px 0 16px 0",
      fontSize: "16px",
    },
    "& p:last-of-type": {
      fontSize: "14px",
    },
  },
  applyButton: {
    mt: "16px",
    "& button:first-of-type": {
      width: "180px",
      maxHeight: "32px",
      fontSize: "14px",
    },
    "& button:last-of-type": {
      width: "180px",
      maxHeight: "32px",
      fontSize: "14px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};

const stepLabelIcon = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const useTimelineDialogStyle = {
  dialog: {
    "& .css-1q2uuof-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
      overflowY: "initial",
    },
    "& .css-x29yx8": {
      bgcolor: "inherit",
      boxShadow: "none",
      overflowY: "initial",
    },
  },
  dialogTitle: {
    ...useTermsAndConditionsDialogStyle.dialogTitle,
    position: "relative",
    width: {
      xs: "89%",
      sm: "93%",
    },
    marginTop: "10px",
    backgroundColor: "#fff",
    overflowY: "initial",
  },
  dialogContent: {
    bgcolor: "#fff",
    width: {
      xs: "89%",
      sm: "93%",
    },
    overflowY: "none",
    borderRadius: "0 0 20px 20px",
  },
  dialogContentBox: useTermsAndConditionsDialogStyle.dialogContent,
  timelineBoxActive: {
    border: "2px solid #8C499C",
    p: "20px",
    borderRadius: "8px",
  },
  timelineBoxNotActive: {
    p: "20px",
    borderRadius: "8px",
    border: "2px dashed #EBDFFF",
  },
  draftTimelineDivider: {
    position: "absolute",
    height: "920px",
    top: 0,
    left: 25,
    borderWidth: "1.5px",
  },
  draftTimeline: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "#F4ECFF",
    borderRadius: "50px",
    width: "200px",
    height: "40px",
    color: "#8C499C",
  },
  openDate: {
    "& span": {
      bgcolor: "#F4ECFF",
      width: {
        xs: "100%",
        sm: "200px",
      },
      height: "42px",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      justifyContent: "center",
      color: "#8C499C",
    },
  },
  selectInfluDate: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
      mr: "10px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  annouceDate: {
    bgcolor: "#F4ECFF",
    width: "200px",
    height: "32px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#8C499C",
    fontWeight: 400,
  },
  selectInfluAndAnnounce: {
    fontSize: "14px",
    fontWeight: 300,
    ml: "10px",
    "& ul li:first-of-type": {
      marginLeft: "-22px",
    },
  },
  acceptCondition: useTermsAndConditionsDialogStyle.acceptCondition,
  iconBtn: {
    position: "absolute",
    right: "-18px",
    top: "-18px",
    "& > svg": {
      bgcolor: "#fff",
      borderRadius: "50%",
      fill: "black",
      fontSize: "30px",
    },
  },
  stepper: {
    "& .css-1pe7n21-MuiStepConnector-root": {
      ml: "20px",
      borderLeft: "2px solid #EBDFFF",
      "& span": {
        border: "none",
      },
    },
  },
  stepLabelIconActive: {
    ...stepLabelIcon,
    bgcolor: "#fff",
    color: "#8C499C",
    border: "2px solid #8C499C",
  },
  stepLabelIconNotActive: {
    ...stepLabelIcon,
    bgcolor: "#fff",
    color: "#EBDFFF",
    border: "2px solid #EBDFFF",
  },
  stepLabelIconIsSuccess: {
    ...stepLabelIcon,
    bgcolor: "#8C499C",
    color: "#fff",
    border: "2px solid #8C499C",
  },
  stepConnect: {
    ml: "23px",
    borderLeft: "3px solid #ECECEC",
  },
  dateBox: {
    bgcolor: "#F4ECFF",
    p: "10px 16px 10px 16px",
    width: {
      sm: "180px",
    },
    borderRadius: "50px",
    maxWidth: {
      sm: "200px",
    },
    display: "flex",
    justifyContent: "center",
    mt: {
      xs: 2,
      sm: 0,
    },
  },
  lineNotActive: {
    ml: "22px",
    borderLeft: "2px solid #EBDFFF",
    pb: 3,
  },
  lineIsActive: {
    ml: "22px",
    borderLeft: "2px dashed #8C499C",
    pb: 3,
  },
  lineIsSuccess: {
    ml: "22px",
    borderLeft: "2px solid #8C499C",
    pb: 3,
  },
};

export const useImageListStyle = {
  breifImgMoreThane4: {
    position: "absolute",
    bgcolor: "rgba(0,0,0,0.9)",
    top: 0,
    left: 0,
    opacity: 0.6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0 0 8px 0",
    width: {
      xs: "100%",
      sm: "150px",
    },
    height: {
      xs: "100%",
      sm: "150px",
    },
    "& > p": {
      color: "#fff",
      fontSize: "30px",
    },
    cursor: "pointer",
  },
  imageListBox: {
    alignItems: "center",
    height: {
      xs: "100%",
      sm: "175px",
    },
    flexWrap: {
      xs: "wrap",
      sm: "nowrap",
    },
  },
  imageList: {
    maxHeight: "150px",
    minHeight: "150px",
    objectFit: "cover",
    width: { xs: "50%", sm: "150px" },
    cursor: "pointer",
  },
  imageMoreBox: {
    position: "relative",
    "& .css-1pqm26d-MuiAvatar-img, .css-1dfe1y8": {
      borderRadius: {
        xs: "0 8px 0 0",
        sm: "0 8px 8px 0",
      },
      width: "100%",
      height: {
        xs: "100%",
        sm: "150px",
      },
    },
    width: {
      xs: "50%",
      sm: "150px",
    },
    "& > .css-1cfuqz3-MuiAvatar-root": {
      width: {
        xs: "100%",
        sm: "150px",
      },
      height: {
        xs: "100%",
        sm: "150px",
      },
      borderRadius: {
        xs: "0 0 8px 0",
        sm: "8px",
      },
    },
    overflow: "hidden",
  },
};

export const useVedioListStyle = {
  itemsBox: {
    cursor: "pointer",
    flexWrap: "wrap",
    "& > div:first-of-type": {
      "& video, .iconPlayVdo": {
        borderRadius: {
          xs: "8px 0 0 0",
          sm: "8px 0 0 8px",
        },
      },
    },
    "& > div:nth-of-type(2)": {
      "& video, .iconPlayVdo": {
        borderRadius: {
          xs: "0 8px 0 0",
          sm: "initial",
        },
      },
    },
    "& > div:nth-of-type(3)": {
      "& video, .iconPlayVdo": {
        borderRadius: {
          xs: "0 0 0 8px",
          sm: "initial",
        },
      },
    },
    "& > div:last-of-type ": {
      "& video, .iconPlayVdo": {
        borderRadius: {
          xs: "0 0 8px 0",
          sm: "0 8px 8px 0",
        },
      },
    },
    border: "1px solid red",
  },
  breifImg: {
    position: "relative",
    cursor: "pointer",
    "& video": {
      borderRadius: "0 8px 8px 0",
    },
  },
  breifVdoMoreThane4: {
    position: "absolute",
    bgcolor: "rgba(0,0,0,0.9)",
    top: 0,
    left: 0,
    opacity: 0.6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: {
      xs: "0 0 8px 0",
      sm: "0 8px 8px 0",
    },
    width: {
      xs: "100%",
      sm: "150px",
    },
    height: {
      xs: "100%",
      sm: "150px",
    },
    "& > p": {
      color: "#fff",
      fontSize: "30px",
    },
  },
  item1Box: {
    "& video, .iconPlayVdo": {
      width: {
        xs: "47%",
        sm: "150px",
      },
      height: "150px",
      borderRadius: "8px",
    },
  },
  item2Box: {
    "& video": {
      width: {
        xs: "47%",
        sm: "150px",
      },
      height: "150px",
    },
    "& > div:first-of-type": {
      "& video, .iconPlayVdo": {
        borderRadius: "8px 0 0 8px",
      },
    },
    "& > div:last-of-type ": {
      "& video, .iconPlayVdo": {
        borderRadius: "0 8px 8px 0",
      },
    },
  },
  breifVideo: {
    "& video": {
      width: "100%",
      height: {
        xs: "100%",
        sm: "150px",
      },
      cursor: "pointer",
      objectFit: "cover",
    },
    position: "relative",
    width: {
      xs: "50%",
      sm: "150px",
    },
  },
  iconPlayVdo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    // borderRadius: "8px",
    "& > .material-icons": {
      fontSize: "40px",
      color: "#fff",
    },
  },
};

export const useBudgetAndRewardDialogStyle = {
  dialog: {
    "& .css-fkeb6g-MuiPaper-root-MuiDialog-paper, .css-t255vf": {
      bgcolor: "inherit",
      boxShadow: "none",
      overflowY: "inherit",
      width: "100%",
      ml: 2,
    },
    "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
      bgcolor: "rgba(0,0,0,0.1)",
    },
  },
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "30px",
      },
    },
  },
  cardContent: {
    width: { xs: "87%", sm: "89%" },
    justifyContent: "center",
    "& > img": {
      height: ["200px", "327px"],
      width: "100%",
      objectFit: "cover",
      borderRadius: "8px",
      pt: "20px",
    },
    p: "30px",
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  specifyingProductDetails: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    height: "140px",
    "& p:first-of-type": {
      pt: "8px",
      pl: "12px",
      color: "#8C499C",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  dialogAction: {
    display: "flex",
    justifyContent: "center",
    mt: "10px",
    "& button": {
      width: "100px",
    },
  },
};
