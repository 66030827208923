import React, { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Icon,
  Stack,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
import { useEditShippingStyle as classes } from "../employer-detail/Detail.style";
// api and type
import { EmployerAPI } from "@api/global";
import { IEditEmployerProps } from "../employer-detail/Detail.type";
import { IEditShippingForm, IShippingAddressForm } from "./EditEmployer.type";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ShippingAddressFormSchema } from "./EditEmployer.schema";
// json
import provinces from "@assets/json/province.json";
import districts from "@assets/json/district.json";
import subdistricts from "@assets/json/subDistrict.json";
// other
import Loading from "@global/loading/Loading";
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
import { initShippingAddressForm } from "../create-employer/EmployerData";

export default function EditShipping(props: IEditEmployerProps) {
  const { employerInfo, setEmployerInfo, open, onClose } = props;

  const [isLoading, setIsLoading] = useState(false);

  const {
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<IShippingAddressForm>({
    resolver: yupResolver(ShippingAddressFormSchema),
    defaultValues: initShippingAddressForm,
  });

  const shipping = employerInfo?.address.shipping_address;

  useEffect(() => {
    if (!shipping) return;

    const form = {
      fullname: shipping.fullname,
      phone: shipping.phone,
      address: shipping.address,
      postcode: shipping.postcode,
      province: shipping.province?.id,
      district: shipping.district?.id,
      subdistrict: shipping.subdistrict?.id,
    };
    reset(form);
  }, [reset, shipping]);

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IShippingAddressForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const handleProvinceChange = async (e: { target: { value: number } }) => {
    const provinceId = e.target.value;
    const provinceSelect = provinces.find(
      (province) => province.id === provinceId
    );
    setValue("province", provinceSelect?.id || null);
    setValue("district", null);
    setValue("subdistrict", null);
    setValue("postcode", "");
  };

  const handleDistrictChange = async (e: { target: { value: number } }) => {
    const districtId = e.target.value;
    const districtSelect = districts.find(
      (district) => district.id === districtId
    );
    setValue("district", districtSelect?.id || null);
    setValue("subdistrict", null);
    setValue("postcode", "");
  };

  const handleSubdistrictChange = async (e: { target: { value: number } }) => {
    const subdistrictId = e.target.value;
    const subdistrictSelect = subdistricts.find(
      (subdistrict: { id: number }) => subdistrict.id === subdistrictId
    );

    setValue("subdistrict", subdistrictSelect?.id || null);
    setValue("postcode", subdistrictSelect?.postcode || "");
  };

  const onEditShipping = async (data: IEditShippingForm) => {
    onClose(false);
    setIsLoading(true);

    const body = {
      address: {
        shipping_address: {
          fullname: data.fullname,
          phone: data.phone.replaceAll("-", ""),
          address: data.address,
          province_id: data.province,
          district_id: data.district,
          subdistrict_id: data.subdistrict,
        },
      },
    };

    EmployerAPI.updateEmployerInfo(employerInfo?.employer_full_id || "", body)
      .then((response) => {
        setEmployerInfo(response);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        onClose(true);
      });
  };

  if (isLoading) return <Loading />

  return (
    <DialogScrollingCustom
      title="Address"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onEditShipping)}>
        <DialogContent sx={classes.dialogContent}>
          <Box px={2} py={0}>
            <Box display="flex" alignItems="center" gap={1}>
              <Icon className="ico-hu-shipping" color="primary" />
              <Typography variant="body1" fontWeight="bold">
                Shipping Address
              </Typography>
            </Box>
            <Stack px={5} pt={3}>
              {/* Full Name */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Full Name<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={getValues("fullname")}
                    onChange={(e) => {
                      handleChangeInput(e, "fullname");
                    }}
                    variant="outlined"
                    placeholder="Full Name"
                    fullWidth
                    error={!!errors.fullname}
                    helperText={errors.fullname?.message}
                  />
                </Grid>
              </Grid>
              {/* Phone */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Phone<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={formatPhoneNumber(getValues("phone"))}
                    onChange={(e) => {
                      handleChangeInput(e, "phone");
                    }}
                    variant="outlined"
                    placeholder="Phone"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                </Grid>
              </Grid>
              {/* Province */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Province<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <BasicSelectCustom
                    id="address-province-select-1"
                    value={watch("province")}
                    onChange={handleProvinceChange}
                    data={provinces}
                    valueKey="id"
                    labelKey="name_th"
                    placeholder="Province"
                    fullWidth
                    error={!!errors.province}
                    helperText={errors.province?.message}
                  />
                </Grid>
              </Grid>
              {/* District */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    District<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <BasicSelectCustom
                    id="address-district-select-1"
                    value={watch("district")}
                    onChange={handleDistrictChange}
                    data={districts.filter(
                      (dist) => dist.province_id === watch("province")
                    )}
                    valueKey="id"
                    labelKey="name_th"
                    placeholder="District"
                    fullWidth
                    error={!!errors.district}
                    helperText={errors.district?.message}
                  />
                </Grid>
              </Grid>
              {/* Sub District */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Subdistrict<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <BasicSelectCustom
                    id="address-sub-district-select-1"
                    value={watch("subdistrict")}
                    onChange={handleSubdistrictChange}
                    data={subdistricts.filter(
                      (subDist) => subDist.district_id === watch("district")
                    )}
                    valueKey="id"
                    labelKey="name_th"
                    placeholder="Subdistrict"
                    fullWidth
                    error={!!errors.subdistrict}
                    helperText={errors.subdistrict?.message}
                  />
                </Grid>
              </Grid>
              {/* Zipcode */}
              <Grid container pb={3}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Zipcode<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={getValues("postcode")}
                    onChange={(e) => {
                      handleChangeInput(e, "postcode");
                    }}
                    variant="outlined"
                    placeholder="Zipcode"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              {/* Address */}
              <Grid container pb={0}>
                <Grid item xs={12} md={3} xl={2} mt={[0, 0, 2]}>
                  <Typography variant="body2">
                    Address<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                  <TextFieldCustom
                    value={getValues("address")}
                    onChange={(e) => {
                      handleChangeInput(e, "address");
                    }}
                    variant="outlined"
                    multiline
                    rows={2}
                    placeholder="Address"
                    fullWidth
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            mt: 2,
            pb: 5,
          }}
        >
          <SaveButton
            type="submit"
            variant="contained"
            startIcon={<Icon className="ico-hu-save" />}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
    </DialogScrollingCustom>
  );
}
