export const useFilterInfluencerListStyle = {
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: "20px 0 0 20px",
      width: { xs: "100%", sm: "50%", md: "30%" },
    },
  },

  headers: {
    position: "fixed",
    top: 0,
    width: "inherit",
    background: "#ffffff",
    zIndex: 1,
    borderBottom: "1px solid #e0e0e0",
    minHeight: "64px",
    display: "flex",
    borderRadius: "20px 0 0 0",
    "& .boxHeader": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "0 24px",
      borderRadius: "20px 0 0 0",
      "& > .MuiTypography-root": {
        color: "#893DBD",
      },
      "& > 	.MuiIcon-root": {
        backgroundColor: "#E0E0E0",
        cursor: "pointer",
      },
    },
  },

  content: {
    mt: 10,
    mb: 10,
    height: "100%",
    overflow: "auto",
  },

  sortBy: {
    padding: "24px",
    marginTop: "20px",
  },

  divider: { marginTop: "20px" },

  subHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },

  paperSearch: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    height: "40px",
    mt: "15px",
  },

  boxCategory: { display: "flex", flexWrap: "wrap", marginTop: "20px" },

  chip: {
    minWidth: "60px",
    margin: "0 12px 12px 0",
    border: "1px solid #5C5C5C",
    padding: "8px",
    "&:hover": {
      border: "1px solid #F4ECFF",
      color: "#893DBD",
      bgcolor: "#F4ECFF",
    },
  },

  itemCategory: {
    minWidth: "48px",
    border: "2px solid #E0E0E0",
    marginRight: "8px",
    borderRadius: "20px",
    marginBottom: "8px",
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },

  footer: {
    position: "fixed",
    bottom: 0,
    width: "inherit",
    background: "#ffffff",
    zIndex: 1,
    borderTop: "1px solid #e0e0e0",
    minHeight: "64px",
    display: "flex",
    borderRadius: "0 0 0 20px",
    "& .boxFooter": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "0 24px",
      borderRadius: "0 0 0 20px",
    },
  },
};
