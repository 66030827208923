import { useEffect, useState } from "react";
// mui
import {
  Box,
  Step,
  Avatar,
  Divider,
  Stepper,
  StepLabel,
  IconButton,
  Typography,
  StepContent,
} from "@mui/material";
// api and type
import { JobAPI } from "@api/global";
import { IJobTimeline, IJobTimelineInfo } from "@api/job/type";
// icon and style
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useInfCampaignTimelineTabStyle as classes } from "../Campaign.style";
// component
import TimelineInfo from "./TimelineInfo";
// resdux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// other
import dayjs from "dayjs";
import Loading from "@components/global/loading/Loading";
import { useLocation, useParams } from "react-router-dom";

interface IStepLabelCustom {
  expanded: string[];
  setExpanded: (value: string[]) => void;
  data: IJobTimeline | null;
  index: number;
  employerUrl?: string;
  influencerUrl?: string;
}

interface IStepContentCustom {
  index: number;
  expanded: string[];
  data: IJobTimeline | null;
  contentType?: string;
  platform?: string;
}

function StepLabelCustom(props: IStepLabelCustom) {
  const { expanded, setExpanded, data, index, employerUrl, influencerUrl } =
    props;

  const handleChangeExpend = (panel: string, isExpanded: boolean) => {
    const paneled = panel.replace(/-disabled/gi, "");
    const expendList = [...expanded];
    const expendedIndex = expendList.findIndex(
      (item) => item.replace(/-disabled/gi, "") === paneled
    );
    expendList[expendedIndex] = isExpanded ? paneled : `${panel}-disabled`;
    setExpanded(expendList);
  };

  const converNameTothai = (data: IJobTimeline | null) => {
    if (data?.name === "employer update job info") {
      const jobInfo = data.data?.job_employer_info;
      if (jobInfo?.conversion != null || jobInfo?.product_shipping !== null) {
        data.name = "employer update product shipping and conversion";
      }
      if (jobInfo?.publish_range !== null) {
        data.name = "employer update date post";
      }
    }

    switch (data?.name) {
      case "employer update product shipping and conversion":
        return "จัดส่งสินค้าสำหรับการรีวิว";
      case "influencer confirm reciept product":
        return "ได้รับสินค้า";
      case "influencer submit draft no1":
        return "อินฟลูเอนเซอร์ส่ง Draft ครั้งที่ 1";
      case "employer checked no1":
        return "ผู้จ้างงานตรวจ Draft ครั้งที่ 1";
      case "influencer submit reject draft no1":
        return "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 1";
      case "employer checked no2":
        return "ผู้จ้างงานตรวจ Draft ครั้งที่ 2";
      case "influencer submit reject draft no2":
        return "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 2";
      case "employer final approved":
        return "ผู้จ้างงานตรวจ Draft ครั้งสุดท้าย";
      case "employer update date post":
        return "ผู้จ้างงานอัพเดทวันที่อินฟลูเอนเซอร์โพสต์งาน";
      case "influencer update publish link":
        return "โพสต์และแนบลิงค์ส่งงาน";
      case "influencer regist campaign":
        return "สมัครเข้าร่วมแคมเปญ";
      case "campaign completed":
        return "สิ้นสุดแคมเปญ";
      case "employer approved":
        return "ผู้จ้างงาน Approved Draft";
      default:
        return "-";
    }
  };

  return (
    <StepLabel
      icon={
        <Box display="flex">
          <Box sx={classes.icon}>
            <Typography variant="h6">
              {dayjs(data?.date).format("DD/MM/YYYY")}
            </Typography>
            <Typography>{dayjs(data?.date).format("HH:mm")} น.</Typography>
          </Box>
          <Avatar
            sx={{ width: "60px", height: "60px" }}
            src={data?.name.includes("employer") ? employerUrl : influencerUrl}
          />
        </Box>
      }
    >
      <Box sx={classes.titleBox}>
        <Box>
          <Typography variant="h6">{converNameTothai(data)}</Typography>
        </Box>
        {expanded.includes(`panel-${index}`) ? (
          <IconButton
            onClick={() => handleChangeExpend(`panel-${index}`, false)}
          >
            <KeyboardArrowUpIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => handleChangeExpend(`panel-${index}`, true)}
          >
            <KeyboardArrowDownIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </StepLabel>
  );
}

function StepContentCustom(props: IStepContentCustom) {
  const { data, contentType, platform, index, expanded } = props;
  if (data === null) return null;
  if (data?.name === "employer update job info") {
    const jobInfo = data.data?.job_employer_info;
    if (jobInfo?.conversion !== null || jobInfo?.product_shipping !== null) {
      data.name = "employer update product shipping and conversion";
    }
    if (jobInfo?.publish_range !== null) {
      data.name = "employer update date post";
    }
  }

  return (
    <StepContent sx={{ ml: "200px" }}>
      <Box
        display={expanded.includes(`panel-${index}`) ? "flex" : "none"}
        ml={4}
      >
        <TimelineInfo
          data={data}
          contentType={contentType}
          platform={platform}
        />
      </Box>
    </StepContent>
  );
}

export default function TimelineTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [timelineInfo, setTimelineInfo] = useState<IJobTimelineInfo>();
  const [expanded, setExpanded] = useState<string[]>([]);

  const timelineList = timelineInfo?.item || [];
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const contentType = jobInfo?.content_type.type;

  const { influencerId } = useParams<{ influencerId: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jobId = params.get("id") || "";

  useEffect(() => {
    let isMounted = true;
    const getJobTimeline = () => {
      setIsLoading(true);
      JobAPI.getInfluencerJobTimeline(jobId, influencerId)
        .then((response) => {
          if (isMounted) {
            setTimelineInfo(response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (isMounted) {
            setIsLoading(false);
            console.log("Can't get job timeline error:", err);
          }
        });
    };
    getJobTimeline();
    return () => {
      isMounted = false;
    };
  }, [influencerId, jobId]);

  useEffect(() => {
    const getExpended = () => {
      let expands: string[] = [];
      for (let i = 0; i <= timelineList.length + 1; i++) {
        const expand = `panel-${i}`;
        expands.push(expand);
      }
      setExpanded(expands);
    };
    getExpended();
  }, [timelineList.length]);

  let influencerRegistInfo: IJobTimeline | null = null;
  influencerRegistInfo = {
    name: "influencer regist campaign",
    date: jobInfo?.campaign_info.created_at || "",
    data: { social_account: jobInfo?.social_account },
  };

  if (isLoading) return <Loading />;

  return (
    <Box sx={classes.container}>
      <div>
        <Typography variant="h5">Job Timeline</Typography>
        <Typography variant="subTitle1">
          ความสัมพันธ์ระหว่างผู้จ้างงานและอินฟลูเอนเซอร์
        </Typography>
      </div>
      <Divider sx={classes.divider} />
      <Stepper orientation="vertical" sx={classes.stepper} connector={<></>}>
        {/* influencer regist campaign */}
        <Step expanded>
          <StepLabelCustom
            index={0}
            data={influencerRegistInfo}
            expanded={expanded}
            setExpanded={setExpanded}
            employerUrl={timelineInfo?.employer_profile_url}
            influencerUrl={timelineInfo?.influencer_profile_url}
          />
          <StepContentCustom
            index={0}
            expanded={expanded}
            data={influencerRegistInfo}
            contentType={contentType}
            platform={timelineInfo?.platform}
          />
        </Step>
        {timelineList.map((item, index) => (
          <Step expanded key={index}>
            <StepLabelCustom
              index={index + 1}
              data={item}
              expanded={expanded}
              setExpanded={setExpanded}
              employerUrl={timelineInfo?.employer_profile_url}
              influencerUrl={timelineInfo?.influencer_profile_url}
            />
            <StepContentCustom
              index={index + 1}
              expanded={expanded}
              data={item}
              contentType={contentType}
              platform={timelineInfo?.platform}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
