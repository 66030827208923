export const numberWithCommas = (numInput?: string | number) => {
  const pnum = numInput?.toString().match(/\d/g)?.join("");

  // eslint-disable-next-line no-useless-escape
  const pureNum = pnum?.split(".")[0].replace(/\,/g, "");
  if (numInput) {
    return pureNum?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0"
  }
};
