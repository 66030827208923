import { useEffect, useState } from "react";
// mui and icons
import { Box, Grid, Typography } from "@mui/material";
import { HotIcon } from "@assets/images/referral/referalIcon";
// styel and component
import { useEventLeaderboardtabStyle as classes } from "../../Events.style";
import LeaderboardCard from "./LeaderboardCard";
// mockdata and type
import { leaderboardCardMockData } from "./EventLeaderboardMockData";
import { IEventLeaderboardTabProps } from "../../Events.type";
// component
import LeaderboardDodium from "./LeaderboardDodium";

interface IData {
  profile_url: string;
  name: string;
  rating: number;
  point: number;
}

export default function EventLeaderboardTab(props: IEventLeaderboardTabProps) {
  const { index, TabPanel, tabSelected } = props;
  const [data, setData] = useState<IData[]>(leaderboardCardMockData);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    const newData = [
      {
        profile_url:
          "https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
        name: "Judtem ",
        rating: 5,
        point: 1234,
      },
      {
        profile_url:
          "https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
        name: "Judtem ",
        rating: 6,
        point: 4555,
      },
      {
        profile_url:
          "https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
        name: "Judtem ",
        rating: 4,
        point: 56566,
      },
      {
        profile_url:
          "https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
        name: "Judtem ",
        rating: 5,
        point: 67555,
      },
    ];

    setData((prevData) => [...prevData, ...newData]);
    setIsLoading(false);
  };

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    if (
      scrollHeight - (scrollTop + windowHeight) < windowHeight * 0.1 &&
      // hasMore
      // &&
      !isLoading
    ) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabPanel index={index} value={tabSelected}>
      <Box sx={classes.container}>
        <Box sx={classes.billboardTitleBox}>
          <span>BILLBOARD</span>
          <HotIcon />
          <span>100</span>
        </Box>
        <Typography
          textAlign="center"
          fontSize={["16px ", "20px ", "24px"]}
          fontWeight={400}
          color="#893DBD"
        >
          100 ลำดับแรก ณ วันที่ 23/02/2023
        </Typography>
        <Box mt={["10px", "450px", "500px"]}>
          <LeaderboardDodium />
        </Box>
        <Box display="flex" justifyContent="center">
          <Grid container spacing={3} maxWidth="1010px">
            {data.map((item, index) => (
              <Grid key={index} item xs={6} sm={4} md={3} lg={2.4}>
                <LeaderboardCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </TabPanel>
  );
}
