export const useMyJobsStyle = {
  myjobCard: {
    cursor: "pointer",
  },
  paginationCon: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    "& .MuiGrid-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none", md: "flex" },
    },
    "& .css-1gdk4t4-MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root":
      {
        width: {
          xs: "36px",
          md: "40px",
        },
        height: {
          xs: "36px",
          md: "40px",
        },
      },
    bottom: 30,
    width: "91%",
  },
  headerTabbox: {
    mt: "-30px",
    position: {
      xs: "sticky",
      sm: "inherit",
    },
    top: 0,
    zIndex: 100,
    pb: {
      xs: "20px",
      sm: 0,
    },
  },
  headerTab: {
    background: {
      xs: "linear-gradient(to bottom, #8C499C 77%, #f5f6f8 23%)",
      sm: "linear-gradient(to bottom, #f5f6f8 77%, #f5f6f8 23%)",
    },
    mx: "-10px",
    px: {
      xs: "20px",
      sm: "10px",
    },
    pt: "35px",
    position: "relative",
  },
  myJobTitle: {
    color: ["#fff", "#000"],
    mt: "-10px",
    mb: "25px",
  },
  noCampaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  noCampDiscBox: {
    maxWidth: "400px",
    textAlign: "center",
    mt: "15px",
  },
  goToJobBoardBtn: {
    border: "none",
    mt: "24px",
    width: "240px",
    "&:hover": { border: "none" },
  },
};

export const useMyJobsDetailStyle = {
  myJobsCon: {
    objectFit: "cover",
    m: {
      xs: "-30px -10px -55px -20px",
      sm: "-30px -28px -55px -25px",
      md: "-20px 0 0 -20px",
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: ["240px", "240px", "100%"],
  },
  callback: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: {
      xs: "10%",
      md: "4%",
    },
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
    "& > span": {
      display: {
        xs: "none",
        md: "initial",
      },
    },
    "& > svg": {
      m: {
        xs: "13px 0 0 17px",
        md: "13px 0 13px 13px",
      },
      bgcolor: {
        xs: "#fff",
        md: "initial",
      },
      borderRadius: "50%",
      fontSize: {
        xs: "30px",
        md: "16px",
      },
    },
  },
};
