// mui and style
import { Box, Grid, Icon, IconButton, Typography } from "@mui/material";
import { useProfileTabStyle as classes } from "./ProfileTab.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";

export default function Portfolio() {
  const profileInfo = useAppSelector(selectInfluencerProfile);
  const portfolio = profileInfo?.portfolio;

  return (
    <Grid container spacing={4} p="20px">
      {portfolio?.map((item: any) => (
        <Grid key={item.id} item xs={12} sm={4}>
          <Box sx={classes.boxBorder} p="20px">
            <div>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  color="primary"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {item?.title}
                </Typography>
                <IconButton
                  onClick={() => window.open(item?.url)}
                  sx={{ ml: "5px" }}
                >
                  <Icon
                    fontSize="small"
                    className="ico-hu-open"
                    color="primary"
                  />
                </IconButton>
              </Box>
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {item?.url}
              </Typography>
            </div>
            <Typography mt="16px">{item?.description}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
