import { useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Icon,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import { useConversionStyle as classes } from "./JobDefault.style";
// type and dayJs
import { IConversionProps } from "./JobDefault.type";
import dayjs from "dayjs";

export default function Conversion(props: IConversionProps) {
  const { conversion } = props;
  const [coppy, setCoppy] = useState({ link: "Coppy", coupone: "Coppy" });

  const onCopyTxt = (status: string, txt?: string) => {
    if (status === "link") {
      setCoppy({ ...coppy, link: "Coppied" });
      navigator.clipboard.writeText(txt ? txt : "");
    } else if (status === "coupone") {
      setCoppy({ ...coppy, coupone: "Coppied" });
      navigator.clipboard.writeText(txt ? txt : "");
    } else {
      setCoppy({ link: "Coppy", coupone: "Coppy" });
    }
  };

  return (
    <>
      <Box>
        <Typography color="primary">
          “สิ่งที่ต้องเพิ่มในแคปชั่น” นอกเหนือจาก Key Message และ Hashtag
        </Typography>
        <Typography sx={classes.listingTitle}>
          โปรดนำ URL หรือคูปองด้านล่างไปเป็นส่วนหนึ่งของแคปชั่นคุณ
        </Typography>
      </Box>
      <Grid container my={2} spacing={3} mb={5}>
        <Grid item xs={12}>
          <Typography mt={2} variant="title1">
            Lead Link
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={classes.couponTxtItem}>
          <li>URL Link</li>
        </Grid>
        <Grid item xs={12} md={9} mt={[-1, -1, -2]}>
          <Box sx={classes.txtLink}>
            <Typography>{conversion?.lead_link || "-"}</Typography>
            <Tooltip title={coppy.link} placement="top">
              <IconButton
                onClick={() => onCopyTxt("link", conversion?.lead_link)}
                onMouseOut={() => onCopyTxt("mouseOut")}
              >
                <Icon className="ico-hu-copy-outline" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="title1">Coupon</Typography>
        </Grid>
        <Grid item xs={12} md={3} mt={[-1, -1, 0]} sx={classes.couponTxtItem}>
          <li>Coupon Code</li>
        </Grid>
        <Grid item xs={12} md={9} mt={[-1, -1, -2]}>
          <Box sx={classes.txtLink}>
            <Typography>{conversion?.coupon || "-"}</Typography>
            <Tooltip title={coppy.coupone} placement="top">
              <IconButton
                onClick={() => onCopyTxt("coupone", conversion?.coupon)}
                onMouseOut={() => onCopyTxt("mouseOut")}
              >
                <Icon className="ico-hu-copy-outline" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sx={classes.couponTxtItem}>
          <li>Coupon Name</li>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body2" ml="15px">
            {conversion?.coupon_name || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={classes.couponTxtItem}>
          <li>Validity Period</li>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body2" ml="15px">
            {conversion?.start_validity_period
              ? `${dayjs(conversion.start_validity_period).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(conversion.end_validity_period).format(
                  "DD/MM/YYYY"
                )}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={classes.couponTxtItem}>
          <li>Description</li>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body2" ml="15px">
            {conversion?.note || "-"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
