// mui
import { Box, Stack } from "@mui/material";
// type and others
import { IRangeInputProps } from "../CampaignBrief.type";
import TextFieldCustom from "@global/text-field";
import { numberWithCommas } from "@utils/helpers/numberWithCommas";

export default function RangeInput(props: IRangeInputProps) {
  const {
    name,
    limit,
    startKeyName,
    endKeyName,
    errors,
    firstPlaceholder,
    secondPlaceholder,
    form: { watch, setValue },
  } = props;

  const firstValue = watch(startKeyName as any);
  const secondValue = watch(endKeyName as any);

  const handleInputChange = (
    event: { target: { value: any } },
    keyName: string
  ) => {
    const value = event.target.value.replace(/,/gi, "");
    const isNumeric = typeof value === "number" || /^\d+$/.test(value);

    if (limit) {
      const newValue = Math.floor(Number(value))
      if ((newValue <= 100 && newValue >= 0)) {
        setValue(keyName as any, newValue);
      }
    } else {
      if (value === "" || isNumeric) {
        setValue(keyName as any, Number(value));
      }
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <Box width="100%">
        <TextFieldCustom
          variant="outlined"
          placeholder={firstPlaceholder}
          fullWidth
          error={!!errors?.min}
          helperText={errors?.min?.message}
          value={firstValue ? numberWithCommas(Number(firstValue)) : ""}
          onChange={(e) => handleInputChange(e, startKeyName)}
          inputProps={{
            type: name === "follower" ? "string" : "number",
          }}
          sx={{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderRadius: "50px",
            },
          }}
        />
      </Box>
      <span>-</span>
      <Box width="100%">
        <TextFieldCustom
          variant="outlined"
          placeholder={secondPlaceholder}
          fullWidth
          error={!!errors?.max}
          helperText={errors?.max?.message}
          value={secondValue ? numberWithCommas(Number(secondValue)) : ""}
          onChange={(e) => handleInputChange(e, endKeyName)}
          inputProps={{
            type: name === "follower" ? "string" : "number",
          }}
          sx={{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderRadius: "50px",
            },
          }}
        />
      </Box>
    </Stack>
  );
}
