// mui and icon
import { Box, Grid, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// type and style
import { ICreateCampaignProps } from "../../CampaignBrief.type";
import { useProductDetailsStyle as classes } from "../../CampaignBrief.style";
import { ICampaignBriefFormKey } from "@views/campaign/create-campaign/CreateCampaign.type";
// data and component
import {
  reviewProductList,
  productDescriptionList,
} from "../../campaignBrief.constants";
import ReturnProduct from "./ReturnProduct";
// others
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import MultiImagesInput from "../../brief-default/MultiImagesInput";

export default function ProductDetails(props: ICreateCampaignProps) {
  const { form } = props;

  const {
    watch,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  const handleChangeReviewType = (
    e: { target: { value: string } },
    keyName: ICampaignBriefFormKey
  ) => {
    const { value } = e.target;
    setValue(keyName, value);
    if (value === "no_shipping") {
      setValue("timeline.shipping_date", "");
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">
          Product Details<span className="require">*</span>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={classes.prodImg}>
          <CircleIcon />
          Product Images
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" spacing={6}>
          <MultiImagesInput
            form={form}
            keyName="product.images"
            errors={errors?.product?.images}
            images={getValues("product.images")}
          />
        </Stack>
      </Grid>
      <Grid item xs={3} mt="23px">
        <Typography sx={classes.prodImg}>
          <CircleIcon />
          Product Name
        </Typography>
      </Grid>
      <Grid item xs={9} mt="15px">
        <TextFieldCustom
          variant="outlined"
          placeholder="Product Name"
          fullWidth
          error={!!errors?.product?.name}
          helperText={errors?.product?.name?.message}
          inputProps={{
            ...register("product.name", {
              required: true,
            }),
          }}
        />
      </Grid>
      <Grid item xs={3} mt="23px">
        <Typography sx={classes.prodImg}>
          <CircleIcon />
          Description
        </Typography>
      </Grid>
      <Grid item xs={9} mt="15px">
        <TextFieldCustom
          variant="outlined"
          multiline
          minRows={5}
          placeholder="Please enter text..."
          fullWidth
          error={!!errors?.product?.description}
          helperText={errors?.product?.description?.message}
          inputProps={{
            ...register(`product.description`, { required: true }),
          }}
        />
        <Box sx={classes.exmpDescBox}>
          <Typography variant="title1" color="primary">
            ตัวอย่าง หัวข้อในการระบุรายละเอียดสินค้า
          </Typography>
          <Box sx={classes.exmpDesc}>
            <ul>
              {productDescriptionList.firstDesc.map((desc, index) => (
                <Typography component="li" key={index} variant="body2">
                  {desc}
                </Typography>
              ))}
            </ul>
            <ul style={{ marginLeft: "30px" }}>
              {productDescriptionList.secondDesc.map((desc, index) => (
                <Typography component="li" key={index} variant="body2">
                  {desc}
                </Typography>
              ))}
            </ul>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} mt="23px">
        <Typography variant="body2">
          Review Product<span className="require">*</span>
        </Typography>
      </Grid>
      <Grid item xs={9} mt="15px">
        <BasicSelectCustom
          className="select-review-product"
          id="review-product-select"
          value={watch("product_review_type")}
          onChange={(e) => {
            handleChangeReviewType(e, "product_review_type");
          }}
          data={reviewProductList}
          valueKey="value"
          labelKey="label"
          placeholder="Select"
          fullWidth
          error={!!errors?.product_review_type}
          helperText={errors?.product_review_type?.message}
        />
      </Grid>
      {getValues("product_review_type") === "send_return" && (
        <ReturnProduct form={form} />
      )}
    </>
  );
}
