import { JobAPIs } from "@api/job/api";
import { TeamAPIs } from "@api/team/api";
import { SocialAPIs } from "@api/social/api";
import { ReportAPIs } from "@api/report/api";
import { PaymentAPIs } from "@api/payment/api";
import { PackageAPIs } from "@api/package/api";
import { ExpenseAPIs } from "@api/expense/api";
import { CampaignAPIs } from "@api/campaign/api";
import { EmployerAPIs } from "@api/employer/api";
import { DashboardAPIs } from "@api/dashboard/api";
import { InfluencerAPIs } from "@api/influencer/api";
import { NotificationAPIs } from "@api/notification/api";
import { AuthenticationAPIs } from "@api/authentication/api";
import { AppInformationAPIs } from "@api/app-information/api";

export const TeamAPI = new TeamAPIs();
export const SocialAPI = new SocialAPIs();
export const ReportAPI = new ReportAPIs();
export const PackageAPI = new PackageAPIs();
export const PaymentAPI = new PaymentAPIs();
export const ExpenseAPI = new ExpenseAPIs();
export const CampaignAPI = new CampaignAPIs();
export const DashboardAPI = new DashboardAPIs();
export const NotificationAPI = new NotificationAPIs();
export const AuthenticationAPI = new AuthenticationAPIs();
export const EmployerAPI = new EmployerAPIs();
export const InfluencerAPI = new InfluencerAPIs();
export const AppInformationAPI = new AppInformationAPIs();
export const JobAPI = new JobAPIs();