// mui and style
import { Box, Grid, Typography } from "@mui/material";
import { useDoOrDoNotStyle as classes } from "./CampaignDetail.style";
// type and others
import { ICampaignDetailProps } from "./CampaignDetail.type";
import nl2br from "react-nl2br";

export default function DoOrDoNot(props: ICampaignDetailProps) {
  const { campaignInfo } = props;
  const brief = campaignInfo?.brief;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Box sx={classes.do}>
          <Typography variant="title1">“สิ่งที่ต้องทำ”</Typography>
          <Typography variant="body1" p="4px 8px 8px 8px">
            {nl2br(brief?.emphasis)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={classes.dont}>
          <Typography variant="title1">“สิ่งที่ห้ามทำ”</Typography>
          <Typography variant="body1" p="4px 8px 8px 8px">
            {nl2br(brief?.prohibition)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
