import { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  Paper,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import {
  TableRowCustom,
  TableCellCustom,
} from "@components/employer-expense/Expense.style";
import { adminDashboardStyles as classes } from "@views/admin-dashboard/Dashboard.style";
// type and api
import {
  IDashboardCampaign,
  IOrganizationParams,
  IDashboardCampaignParams,
} from "@api/dashboard/type";
import { DashboardAPI } from "@api/global";
// other
import { useHistory } from "react-router-dom";
import PaginationCustom from "@components/global/pagination-custom";

export default function CampaignList() {
  const [campaignList, setCampaignList] = useState<IDashboardCampaign>();
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [params, setParams] = useState<IDashboardCampaignParams>({
    page: paginate,
    item_per_page: rowsPerPage,
    order_by: "code",
    order: "asc",
  });

  const history = useHistory();
  const numberFormat = Intl.NumberFormat("en");

  useEffect(() => {
    let isMounted = true;
    const getCampaignList = (params: IOrganizationParams) => {
      DashboardAPI.getDashboardCampaign(params)
        .then((response) => {
          if (isMounted) {
            setCampaignList(response);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.log("can not get campaign List error : ", err);
          }
        });
    };
    getCampaignList(params);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const tableCampaign = [
    { id: "id", name: "ID", order_by: "code" },
    { id: "name", name: "Campaign Name", order_by: "name" },
    { id: "organization", name: "Organization", order_by: "organization" },
    { id: "type", name: "Type", order_by: "campaign_type" },
    { id: "platform", name: "Platform", order_by: "platform" },
    { id: "category", name: "Category", order_by: "category" },
    { id: "budget", name: "Budget", order_by: "budget" },
    { id: "duration", name: "Duration", order_by: "duration" },
  ];

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleSortCampaignList = (order_by: string, order: string) => {
    setParams((prev) => {
      return {
        ...prev,
        order_by: order_by,
        order: order,
      };
    });
  };

  return (
    <Box sx={classes.cardWrap}>
      <Typography variant="h6">Lasted Campaigns</Typography>
      <Box mt="20px">
        {campaignList?.item !== null && campaignList?.item.length ? (
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {tableCampaign.map((item, index) => (
                    <TableCellCustom key={index}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {item.name}
                        {params.order_by === `${item.order_by}` &&
                        params.order === "desc" ? (
                          <Icon
                            className="ico-hu-arrow-up"
                            sx={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => {
                              handleSortCampaignList(item.order_by, "asc");
                            }}
                          />
                        ) : (
                          <Icon
                            className="ico-hu-arrow-down"
                            sx={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => {
                              handleSortCampaignList(item.order_by, "desc");
                            }}
                          />
                        )}
                      </Box>
                    </TableCellCustom>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {campaignList?.item.map((item, index) => (
                  <TableRowCustom 
                    key={index}
                    onClick={() => history.push(`campaigns/${item.id}/info?emp_id=${item?.employer_id || ""}`)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCellCustom align="left">
                      <Typography>{item.code}</Typography>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Box display="flex">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Avatar
                            src={item.campaign_image_url}
                            variant="rounded"
                          />
                        </Box>
                        <Box
                          ml="14px"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          width="180px"
                        >
                          <Typography sx={classes.textEllipsis}>
                            {item.name}
                          </Typography>
                          <Typography>{item.organization_email}</Typography>
                        </Box>
                      </Box>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Box width="120px">
                        <Typography sx={classes.textEllipsis}>
                          {item.company_name}
                        </Typography>
                      </Box>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Typography>{item.campaign_type}</Typography>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Typography>{item.platform}</Typography>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Box width="120px">
                        <Typography sx={classes.textEllipsis}>
                          {item.categories}
                        </Typography>
                      </Box>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Typography>{numberFormat.format(item.budget)}</Typography>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Typography>{item.duration}</Typography>
                    </TableCellCustom>
                  </TableRowCustom>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={classes.noCampaign}>
            <img
              src={require("@assets/images/infographic/influencer/search-not-found.png")}
              alt="no campaign"
            />
            <Box sx={classes.textNoCampaign}>
              <Typography variant="h5" my="8px">
                ไม่พบผลการค้นหา
              </Typography>
              <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
            </Box>
          </Box>
        )}

        <div>
          {campaignList?.item !== null && campaignList?.item.length ? (
            <Box sx={classes.paginate}>
              <PaginationCustom
                page={paginate}
                count={paginate > 0 ? Math.ceil((campaignList?.total_item || 0) / rowsPerPage) : 0}
                onPaginationChange={handleChangePage}
                rowsPerPageData={[]}
                rowsPerPage={rowsPerPage}
                onRowPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          ) : null}
        </div>
      </Box>
    </Box>
  );
}
