import React from "react";
// mui
import { Box, Grid, Typography } from "@mui/material";
// style and color
import { useLocationDistributionStyle as classes } from "./CampaignReport.style";
import { hGrey } from "@theme/index";
// chartJs
import { locationDistribution as locatDist } from "./CampaignReport.chart";
import { Bar } from "react-chartjs-2";

export default function LocationDistribution() {
  const label = [
    "Bangkok",
    "Nakon Pathom",
    "Kamphaeng Rhet",
    "Ratchaburi",
    "Chiang Mai",
    "Other",
  ];
  const dataPoints = [2830, 3520, 4403, 5600, 1660, 300];

  let merged = dataPoints.map((_dataPoint, i) => {
    return { datapoint: dataPoints[i], label: label[i] };
  });

  const dataSort = merged.sort((b, a) => {
    return a.datapoint - b.datapoint;
  });

  const dt = [];
  const lab = [];
  for (let i = 0; i < dataSort.length; i++) {
    dt.push(dataSort[i].datapoint);
    lab.push(dataSort[i].label);
  }

  const data = {
    ...locatDist.data,
    labels: lab,
    datasets: [
      {
        ...locatDist.data.datasets[0],
        // data: dt,
        data: [],
      },
    ],
  };

  const zip = data.datasets[0].data.map((e, i) => {
    return [e, data.labels[i]];
  });

  let percentage = (dataPoint: number) => {
    const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
    return (dataPoint / sum) * 100;
  };

  // const totals = data.datasets[0].data.reduce(
  //   (a: number, b: number) => a + b,
  //   0
  // );

  const nf = new Intl.NumberFormat("jp-JP");

  return (
    <Box sx={classes.container}>
      <Box sx={classes.locatTitle}>
        <Typography>Location Distribution</Typography>
      </Box>

      <Box display="flex" flexDirection={["column", "row"]}>
        <Box sx={classes.chart}>
          <Bar
            options={locatDist.options}
            data={data}
            plugins={locatDist.chartAreaBorder}
          />
        </Box>
        <Box sx={classes.locatGridBox}>
          <Grid container sx={classes.locatGrid}>
            {zip.map((data, index) => (
              <React.Fragment key={index}>
                <Grid item xs={6.5}>
                  <Typography>{data[1]}</Typography>
                </Grid>
                <Grid item xs={5.5}>
                  <Typography color={hGrey[900]}>
                    {nf.format(Number(data[0]))}
                    <span>
                      {" (" + percentage(Number(data[0])).toFixed(2) + "%)"}
                    </span>
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={6.5} my={3}>
              {/* <Typography color={hPurple[300]}>Totals</Typography> */}
            </Grid>
            <Grid item xs={5.5} my={3}>
              {/* <Typography color={hPurple[300]}>{nf.format(totals)}</Typography> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
