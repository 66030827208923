// mui
import { Avatar } from "@mui/material";
// style
import { useGetPlatformStyle as classes } from "./GetPlatform.Style";
// asset
import tiktok from "@assets/images/social/tiktok-only.png";
import twitter from "@assets/images/social/twitter_5969020.png";
import youtube from "@assets/images/social/youtube-only.png";
import facebook from "@assets/images/social/facebook-only.png";
import instagram from "@assets/images/social/instagram-only.png";

interface IPlatformProps {
  platform: string;
}

export default function MatchPlatformIcon(props: IPlatformProps) {
  const { platform } = props;

  return (
    <div>
      {platform === "facebook" ? (
        <>
          <Avatar src={facebook} sx={classes.platformIcon} />
        </>
      ) : platform === "twitter" ? (
        <>
          <Avatar src={twitter} sx={classes.platformIcon} />
        </>
      ) : platform === "instagram" ? (
        <>
          <Avatar src={instagram} sx={classes.platformIcon} />
        </>
      ) : platform === "youtube" ? (
        <>
          <Avatar src={youtube} sx={classes.platformIcon} />
        </>
      ) : platform === "tiktok" ? (
        <Avatar src={tiktok} sx={classes.platformIcon} />
      ) : (
        <></>
      )}
    </div>
  );
}
