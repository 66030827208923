import { Card } from "@mui/material";
import React from "react";
import Eight from "../Influencer/Eight";
import Five from "../Influencer/Five";
import Seven from "../Influencer/Seven";
import Six from "../Influencer/Six";
import Two from "../Influencer/Two";
import FourMarketer from "./FourMarketer";
import IntroductionMarketer from "./IntroductionMarketer";
import ThreeMarketer from "./ThreeMarketer";

export default function index() {
  return (
    <Card sx={{ width: "95%", height: "100%" }}>
      <IntroductionMarketer />
      <Two />
      <ThreeMarketer />
      <FourMarketer />
      <Five />
      <Six />
      <Seven />
      <Eight />
    </Card>
  );
}
