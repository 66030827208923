import { useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useTeamWalletStyle as classes } from "./Wallettab.style";
// component
import EditBilling from "@global/edit-billing/EditBilling";
import CampaignDetailDialog from "./wallet-dialog/CampaignDetail";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectWalletBalance } from "@slices/PackageInfo.slice";
import { selectEmployerInfo, setEmployerInfo } from "@slices/EmployerInfo.slice";
// api and type
import { TeamAPI } from "@api/global";
import { ITransactionHistoryList } from "../Member.type";
// other
import { useHistory } from "react-router-dom";
import Loading from "@components/global/loading/Loading";

export default function TeamWallet(props: {
  transaction: ITransactionHistoryList | null;
}) {
  const { transaction } = props;

  const [isLoading, setIsLoading] = useState(false)
  const [openEditBilling, setOpenEditBilling] = useState(false);
  const [openCampaignDetail, setOpenCampaignDetail] = useState(false);

  const history = useHistory();
  const numberFormat = new Intl.NumberFormat('th-TH', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  });

  const dispatch = useAppDispatch()
  const walletBalance = useAppSelector(selectWalletBalance);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const billing = employerInfo?.team?.billing_address;

  const onEditBilling = (body: any) => {
    setOpenEditBilling(false)
    setIsLoading(true)
    TeamAPI.updateTeamBilling(employerInfo?.team?.id || "", body).then((response) => {
      dispatch(setEmployerInfo(response))
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
      setOpenEditBilling(true)
    })
  };

  if (isLoading) return <Loading />

  return (
    <>
      <div>
        <Typography variant="h5">Team Wallet</Typography>
        <Typography variant="subTitle1">กระเป๋าเงินของทีม</Typography>
      </div>
      <Divider sx={{ m: "24px 0" }} />
      <Box sx={classes.walletBox}>
        <Box sx={classes.firstWalletBoxItem}>
          <Typography variant="h6" fontWeight={500}>
            ยอดเงินทั้งหมด
          </Typography>
          <Typography variant="h4" mt={2} fontWeight={500} color="#8C499C">
            {numberFormat.format(walletBalance)} บาท
          </Typography>
          <Button
            variant="contained"
            onClick={() => history.push(`/payment/top-up/team?t_id=${employerInfo?.employer_full_id}`)}
            startIcon={<Icon className="ico-hu-topup" />}
          >
            เติมเงิน Wallet
          </Button>
        </Box>
        <Box sx={classes.secondWalletBoxItem}>
          <Typography variant="h6" fontWeight={500}>
            ยอดเงินสำรองจ่าย
          </Typography>
          <Typography variant="h4" mt={2} fontWeight={500}>
            0.00 บาท
          </Typography>
          <Button
            onClick={() => setOpenCampaignDetail(true)}
            startIcon={<Icon className="ico-hu-campaign" />}
            disabled
          >
            0 แคมเปญ
          </Button>
        </Box>
        <Box sx={classes.thirdWalletBoxItem}>
          <Typography variant="h6" fontWeight={500} mt="24px">
            ยอดเงินที่ใช้ได้
          </Typography>
          <Typography variant="h4" mt={1} fontWeight={500} color="#8C499C">
            {numberFormat.format(walletBalance)} บาท
          </Typography>
          <Typography variant="body2" mb="24px">
            ยอดเงิน {numberFormat.format(transaction?.balance || 0)} บาท
            จะหมดอายุใน 14/07/2024
          </Typography>
        </Box>
      </Box>
      <Box sx={classes.billingBox}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">ข้อมูลใบเสร็จ</Typography>
          <IconButton onClick={() => setOpenEditBilling(true)}>
            <Icon
              className="ico-hu-edit"
              color="primary"
              sx={{ cursor: "pointer" }}
            />
          </IconButton>
        </Box>
        <Box mt="16px">
          <Typography variant="title1">
            {employerInfo?.organisation.name}
          </Typography>
          <Typography my="6px">{billing?.tax_id}</Typography>
          <Typography>
            {`${billing?.address} ${billing?.subdistrict?.name_th || ""} ${
              billing?.district?.name_th || ""
            } ${billing?.province?.name_th || ""} ${billing?.postcode}`}
          </Typography>
        </Box>
      </Box>

      {/* dialog */}
      <EditBilling
        open={openEditBilling}
        onClose={() => setOpenEditBilling(false)}
        onEditBilling={onEditBilling}
        billing={billing}
      />
      <CampaignDetailDialog
        open={openCampaignDetail}
        onClose={setOpenCampaignDetail}
      />
    </>
  );
}
