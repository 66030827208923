// mui and style
import { Box, Stack, Typography, InputAdornment, Icon } from "@mui/material";
import { useDateRangeInputCustomStyle as classes } from "./DateRangeInputCustom.style";
// other
import TextFieldCustom from "@global/text-field";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

interface IDateRangeInputCustomProps {
  setValue: Function;
  watch: Function;
  startKeyName: string;
  endKeyName: string;
  placeholderFirst?: string;
  placeholderSecond?: string;
  [any: string]: any;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
}

export default function DateRangeInputCustom(
  props: IDateRangeInputCustomProps
) {
  const {
    startKeyName,
    endKeyName,
    startError,
    endError,
    minDate,
    maxDate,
    placeholderFirst,
    placeholderSecond,
    setValue,
    watch,
    disabled,
  } = props;

  const startDateVal = new Date(watch(startKeyName));
  const endDateVal = new Date(watch(endKeyName));

  const isDate = (date: Date | string) => {
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return null;
    }
    return dateObj;
  };

  const handleChangeDate = (date: Date | null, keyName: any) => {
    if (date !== null) return setValue(keyName, date);
  };

  return (
    <Stack direction="row" spacing={2} sx={classes.container}>
      <Box width="100%">
        <Box className="datepicker-container">
          <DatePicker
            selected={isDate(startDateVal)}
            startDate={isDate(startDateVal)}
            endDate={isDate(endDateVal)}
            onChange={(date) => handleChangeDate(date, startKeyName)}
            calendarClassName="datepicker-calendar-container"
            dateFormat="dd/MM/yyyy"
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            monthsShown={2}
            disabled={disabled ? true : false}
            customInput={
              <div className="input-container">
                <TextFieldCustom
                  fullWidth
                  placeholder={placeholderFirst}
                  value={
                    isDate(startDateVal)
                      ? dayjs(startDateVal).format("DD/MM/YYYY")
                      : ""
                  }
                  disabled={disabled ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className="ico-hu-calendar-date"
                          sx={{
                            cursor: "pointer",
                            color: disabled
                              ? "#a2a2a2"
                              : startError
                              ? "red"
                              : "#8C499C",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderRadius: "50px",
                        borderColor: startError ? "red" : "#8C499C",
                      },
                  }}
                />
              </div>
            }
          />
        </Box>
        {startError && (
          <Typography
            variant="caption"
            color="error.main"
            fontWeight={400}
            ml="15px"
          >
            {startError}
          </Typography>
        )}
      </Box>
      <span
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        -
      </span>
      <Box width="100%">
        <Box className="datepicker-container">
          <DatePicker
            name={endKeyName}
            dateFormat="dd/MM/yyyy"
            selected={isDate(endDateVal)}
            calendarClassName="datepicker-calendar-container"
            onChange={(date) => handleChangeDate(date, endKeyName)}
            selectsEnd
            startDate={isDate(startDateVal)}
            endDate={isDate(endDateVal)}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            monthsShown={2}
            placeholderText={placeholderSecond}
            disabled={disabled ? true : false}
            customInput={
              <div className="input-container">
                <TextFieldCustom
                  fullWidth
                  value={
                    isDate(endDateVal)
                      ? dayjs(endDateVal).format("DD/MM/YYYY")
                      : ""
                  }
                  disabled={disabled ? true : false}
                  placeholder={placeholderSecond}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className="ico-hu-calendar-date"
                          sx={{
                            cursor: "pointer",
                            color: disabled
                              ? "#a2a2a2"
                              : endError
                              ? "red"
                              : "#8C499C",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderRadius: "50px",
                        borderColor: endError ? "red" : "#8C499C",
                      },
                  }}
                />
              </div>
            }
          />
        </Box>
        {endError && (
          <Typography
            variant="caption"
            color="error.main"
            fontWeight={400}
            ml="15px"
          >
            {endError}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
