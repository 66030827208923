// mui
import { Box, Button, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
// icon and style
import googleIcon from "@assets/images/social/google-icon.png";
import youtubeIcon from "@assets/images/social/youtube-icon.png";
import { useSyncYoutubeStyle as classes } from "../InfluencerSocial.style";
// utils
import { SOCIAL_URL } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function SyncYoutube() {
  const { access_token } = getTokenFromStorage();

  const onLoginYoutube = () => {
    window.location.replace(
      `${SOCIAL_URL}/youtube/login?token=${access_token}`
    );
  };

  return (
    <Stack sx={classes.container}>
      <div>
        <img src={youtubeIcon} alt="tiktok_sync" style={{ height: "80px" }} />
      </div>
      <Box textAlign="center" width="40%" pt={4}>
        <Typography variant="h5" color={grey[700]}>
          Connect with YouTube
        </Typography>
      </Box>
      <Box className="d-flex j-c-center t-a-center" py={5}>
        <Typography variant="body1" mx="10px" width={{ lg: "70%" }}>
          Amplify your chance to reach out to more brands and followers Simply
          add more of your social channels below.
        </Typography>
      </Box>
      <Box py={5}>
        <Button sx={classes.buttonSyncSocial} onClick={onLoginYoutube}>
          <img
            src={googleIcon}
            style={{ width: "20px", height: "20px" }}
            alt="goole icon"
          />
          <Typography fontWeight={500}>Continue with Google</Typography>
        </Button>
      </Box>
    </Stack>
  );
}
