import { hGrey } from "@theme/index";

export const categoryDialogStyle = {
  boxTextTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: "24px",
  },
  itemsCategory: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "162px",
    height: "104px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #c4c4c4",
    borderRadius: "8px",
    color: hGrey[500],
    cursor: "pointer",
  },
  line: {
    marginTop: "24px",
  },
  boxTextTitle2: {
    marginTop: "16px",
    marginBottom: "22px",
  },
  gridAge: {
    marginBottom: "24px",
  },
  lineCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemsGender: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "118px",
    height: "95px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #c4c4c4",
    borderRadius: "8px",
    color: hGrey[500],
    cursor: "pointer",
  },
  genderSelected: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "118px",
    height: "95px",
    backgroundColor: "#F4ECFF",
    border: "1px solid #8C499C",
    borderRadius: "8px",
    color: "#8C499C",
    cursor: "pointer",
  },
  categoryBadge: {
    width: "100%",
    "& .MuiBadge-colorPrimary": {
      color: "red",
    },
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },

  boxCategorySelected: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "162px",
    height: "104px",
    backgroundColor: "#F4ECFF",
    border: "2px solid #8C499C",
    borderRadius: "8px",
    color: "#8C499C",
    cursor: "pointer",
  },

  iconBadge: {
    width: "24px",
    height: "24px",
    backgroundColor: "white",
    borderRadius: "50%",
  },

  boxProfileDialog: {
    margin: "24px 0",
    padding: "12px",
    border: "1px solid #8C499C",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "row",
  },

  imageProfileDialog: {
    width: "48px",
    height: "48px",
    borderRadius: "8px",
    marginRight: "16px",
  },
  submitBtn: {
    minWidth: "300px",
    border: "none",
    "&:hover": { border: "none" },
  },
};
