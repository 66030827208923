import React from "react";
// mui and icons
import { Grid, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and types
import { useTargetAudienceStyle as classes } from "./CampaignDetail.style";
import { ICampaignDetailProps } from "./CampaignDetail.type";

export default function TargetAudience(props: ICampaignDetailProps) {
  const { campaignInfo } = props;

  const numberFormat = Intl.NumberFormat("en");

  const target = campaignInfo?.audience.target;
  const influencer = campaignInfo?.audience.influencer;
  const isCampaignPrivate = campaignInfo?.campaign_type === "private";

  const genderMatch = (gender: string) => {
    if (gender === "man" || gender === "male") {
      return "ชาย";
    } else if (gender === "women" || gender === "female") {
      return "หญิง";
    } else {
      return gender;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography color="primary" variant="title1">
          กลุ่มลูกค้าของแบรนด์
        </Typography>
      </Grid>
      <Grid item xs={4.5} sm={4} xl={3}>
        <Typography sx={classes.label}>
          <CircleIcon sx={classes.circle} />
          Age
        </Typography>
      </Grid>
      <Grid item xs={7.5} sm={8} xl={9}>
        <Typography variant="body1">
          {target?.age.min && target.age.max
            ? `${target?.age.min} - ${target?.age.max} ปี`
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4.5} sm={4} xl={3}>
        <Typography sx={classes.label}>
          <CircleIcon sx={classes.circle} />
          Gender
        </Typography>
      </Grid>
      <Grid item xs={7.5} sm={8} xl={9} sx={classes.lastComma}>
        {target?.gender.length ? (
          target.gender.length < 3 ? (
            target.gender.map((item, index) => (
              <React.Fragment key={index}>
                <Typography variant="body1">{genderMatch(item)}</Typography>
                <span>{","}&nbsp;&nbsp;</span>
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body1">ทุกเพศ</Typography>
          )
        ) : (
          "-"
        )}
      </Grid>
      <Grid item xs={4.5} sm={4} xl={3}>
        <Typography sx={classes.label}>
          <CircleIcon sx={classes.circle} />
          Location
        </Typography>
      </Grid>
      <Grid item xs={7.5} sm={8} xl={9} sx={classes.lastComma}>
        {target?.location.length
          ? target.location.map((item, index) => (
              <React.Fragment key={index}>
                <Typography key={index} variant="body1">
                  {item.name_th}
                </Typography>
                <span>{","}&nbsp;&nbsp;</span>
              </React.Fragment>
            ))
          : "-"}
      </Grid>
      {!isCampaignPrivate && (
        <>
          <Grid item xs={12} sm={12} mt={3}>
            <Typography color="primary" fontWeight="500">
              คุณสมบัติของอินฟลูเอนเซอร์
            </Typography>
          </Grid>
          <Grid item xs={4.5} sm={4} xl={3}>
            <Typography sx={classes.label}>
              <CircleIcon sx={classes.circle} />
              Followers
            </Typography>
          </Grid>
          <Grid item xs={7.5} sm={8} xl={9}>
            {influencer?.follower.min && influencer?.follower.max ? (
              <Typography variant="body1">
                {`${numberFormat.format(
                  influencer.follower.min
                )} - ${numberFormat.format(influencer?.follower.max)}`}
              </Typography>
            ) : (
              "-"
            )}
          </Grid>
          <Grid item xs={4.5} sm={4} xl={3}>
            <Typography sx={classes.label}>
              <CircleIcon sx={classes.circle} />
              Age
            </Typography>
          </Grid>
          <Grid item xs={7.5} sm={8} xl={9}>
            <Typography variant="body1">
              {influencer?.age.min && influencer.age.max
                ? `${influencer.age.min} - ${influencer.age.max} ปี`
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4.5} sm={4} xl={3}>
            <Typography sx={classes.label}>
              <CircleIcon sx={classes.circle} />
              Gender
            </Typography>
          </Grid>
          <Grid item xs={7.5} sm={8} xl={9} sx={classes.lastComma}>
            {influencer?.gender.length ? (
              influencer.gender.length < 3 ? (
                influencer.gender.map((item, index) => (
                  <React.Fragment key={index}>
                    <Typography key={index} variant="body1">
                      {genderMatch(item)}
                    </Typography>
                    <span>{","}&nbsp;&nbsp;</span>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1">ทุกเพศ</Typography>
              )
            ) : (
              "-"
            )}
          </Grid>
          <Grid item xs={4.5} sm={4} xl={3}>
            <Typography sx={classes.label}>
              <CircleIcon sx={classes.circle} />
              Location
            </Typography>
          </Grid>
          <Grid item xs={7.5} sm={8} xl={9} sx={classes.lastComma}>
            {influencer?.location.length
              ? influencer.location.map((item, index) => (
                  <React.Fragment key={index}>
                    <Typography key={index} variant="body1">
                      {item.name_th}
                    </Typography>
                    <span>{","}&nbsp;&nbsp;</span>
                  </React.Fragment>
                ))
              : "-"}
          </Grid>
        </>
      )}
    </Grid>
  );
}
