import { hPink, hPurple } from "@theme/index";

export const useSignInDialogStyle = {
  authenDialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "490px"],
      margin: [1, 2, 3, 3, 0],
    },
  },
  dialogContent: {
    marginTop: 3,
  },
  textFieldIcon: {
    color: "grey.500",
    fontSize: "18px",
  },
  forgetPasswordLink: {
    cursor: "pointer",
    paddingTop: "8px",
  },
  divider: {
    borderBottomWidth: "2px",
    borderBottomColor: "grey.300",
  },
  createAccountLink: {
    cursor: "pointer",
    paddingTop: "6px",
  },
};

export const useSelectAccountDialogStyle = {
  selectAccountDialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "660px"],
      margin: [1, 2, 3, 3, 0],
    },
  },
  selectAccountDialogAction: {
    marginTop: 3,
    marginBottom: [4, 10],
  },
  gridContainer: {
    paddingInline: [2, 10],
  },
  influencerPaper: {
    boxShadow: "unset",
    filter: `drop-shadow(0px 2px 6px ${hPink[100]})`,
    cursor: "pointer",
    transition: "filter 0.1s",
    "&:hover": {
      filter: `drop-shadow(0px 2px 10px ${hPink[100]})`,
    },
  },
  marketerPaper: {
    boxShadow: "unset",
    filter: `drop-shadow(0px 2px 6px ${hPurple[100]})`,
    cursor: "pointer",
    transition: "filter 0.1s",
    "&:hover": {
      filter: `drop-shadow(0px 2px 10px ${hPurple[100]})`,
    },
  },
  imageAvatar: { height: "120px", width: "120px" },
};

export const useCreateAccountDialogStyle = {
  createAccountDialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["95%", "90%", "90%", "840px"],
      margin: [2, 2, 3, 3, 5],
    },
  },
  firstDialogContent: {
    marginTop: 5,
    paddingInline: [1, 6],
  },
  secondDialogContent: {
    paddingInline: [1, 6],
    mt: 8,
  },
  thirdDialogContent: {
    paddingInline: [1, 6],
    mb: 2,
  },
  textFieldGrey: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey.500",
    },
    "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
      color: "grey.500",
    },
  },
  selectGrey: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid`,
      borderColor: "grey.500",
    },
  },
  divider: {
    borderBottomWidth: "2px",
    borderBottomColor: "grey.200",
  },
  iconInput: {
    color: "grey.500",
    fontSize: "16px",
    paddingRight: "2px",
  },
  iconButton: {
    color: "grey.500",
    fontSize: "18px",
  },
  dialogAction: { marginTop: 4, justifyContent: "center" },
  registorButton: { width: ["100%", "50%"] },
  date: {
    width: "100%",
    "& > div": {
      width: "100%",
    },
  },
  dateError: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  initDate: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(133, 133, 133)",
    },
  },
};
