import { useState } from "react";
// mui
import {
  Box,
  Icon,
  Grid,
  Stack,
  Button,
  Avatar,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
// icon and style
import CameraIcon from "@mui/icons-material/PhotoCamera";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
import { useEditInfluencerProfileInfoStyle as classes } from "../InfluencerProfile.style";
// type and api
import {
  IEditInfluencerProfileForm,
  IEditInfluencerProfileProps,
} from "../InfluencerProfile.type";
import { AppInformationAPI } from "@api/global";
// util
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
import { genderList } from "@utils/constants/genderArray.constants";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
// other
import TextFieldCustom from "@components/global/text-field";
import BasicSelectCustom from "@components/global/basic-select";
import uniqid from "uniqid";
import dayjs from "dayjs";

const initImgLoading = {
  loading: false,
  purpose: "",
};

export default function EditInfluencerProfile(
  props: IEditInfluencerProfileProps
) {
  const { form, onSubmit, images, setImages } = props;
  const [imgLoading, setImgLoading] = useState(initImgLoading);

  const influencerInfo = useAppSelector(selectInfluencerProfile);

  const {
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = form;

  const handleImageChange = async (
    e: any,
    purpose: string,
    fileID?: string
  ) => {
    let data = {
      file: e.target.files[0],
      destination: `influencers/${influencerInfo?.influencer_id || ""}`,
      permission: "public",
    };

    setImgLoading({ loading: true, purpose: purpose });
    const result = await AppInformationAPI.uploadAttachment(data).catch(() => {
      setImgLoading({ loading: false, purpose: purpose });
      return;
    });

    let imageData = {
      file_name: result.file_name as string,
      file_type: result.file_type as string,
      url: (result.public_url || result.private_url) as string,
      purpose: purpose,
      description: "",
    };

    let imageList = [...images];
    // is image exist to update image
    if (fileID) {
      const imageIndex = imageList.findIndex((img) => img.file_id === fileID);
      imageList[imageIndex] = {
        ...imageList[imageIndex],
        file_name: imageData.file_name,
        file_type: imageData.file_type,
        url: imageData.url,
        purpose: imageData.purpose,
      };
    } else {
      // is image not exist to upsert image
      imageList = [...imageList, { ...imageData, file_id: `img-${uniqid()}` }];
    }

    setImages(imageList);
    setImgLoading({ loading: false, purpose: purpose });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IEditInfluencerProfileForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const imageSelected = (purpose: string) => {
    return images?.find((img) => img?.purpose === purpose);
  };

  const isLoading = (purpose: string) =>
    imgLoading.loading && imgLoading.purpose === purpose;

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Box display="flex" columnGap={1} mt={{ md: "15px" }}>
        <Icon className="ico-hu-person" color="primary" />
        <Typography variant="title1">Personal Information</Typography>
      </Box>
      <Box mt="15px" maxHeight="63vh" overflow="scroll">
        {/* Profile */}
        <Grid container mb={5}>
          <Grid item xs={12} md={2.5} xl={2.5}>
            <Typography variant="title2">
              รูปโปรไฟล์<span className="require">*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9.5} xl={9.5} mt={[1.5, 1.5, 0]}>
            <Box sx={classes.profileContainerBox} component="label">
              <Box sx={classes.profileBox}>
                <Avatar src={imageSelected("profile_image")?.url} />
                {isLoading("profile_image") && (
                  <Box sx={classes.loading}>
                    <CircularProgress size="100px" thickness={3} />
                  </Box>
                )}
                <Button
                  variant="contained"
                  component="label"
                  disableElevation
                  onChange={(e: any) =>
                    handleImageChange(
                      e,
                      "profile_image",
                      imageSelected("profile_image")?.file_id
                    )
                  }
                  sx={classes.profileButton}
                >
                  <input
                    hidden
                    name="profile"
                    accept="image/*"
                    multiple
                    type="file"
                    disabled={isLoading("profile_image")}
                  />
                  <CameraIcon fontSize="inherit" />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* Image */}
        <Grid container mb={5}>
          <Grid item xs={12} md={2.5} xl={2.5}>
            <Typography variant="title2">
              รูปภาพเพิ่มเติม<span className="require">*</span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            xl={9.5}
            display="flex"
            columnGap="20px"
            flexWrap="wrap"
            mt="5px"
          >
            {["frontal", "portrait", "headshot"].map((value, index) => (
              <Stack key={index} alignItems="center">
                <Box sx={classes.imagesContainerBox} component="label">
                  <Box sx={classes.imagesBox}>
                    <Avatar
                      src={imageSelected(`influencer_${value}_image`)?.url}
                    />
                    {isLoading(`influencer_${value}_image`) && (
                      <Box sx={classes.loading}>
                        <CircularProgress size="90px" thickness={3} />
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      component="label"
                      disableElevation
                      onChange={(e: any) =>
                        handleImageChange(
                          e,
                          `influencer_${value}_image`,
                          imageSelected(`influencer_${value}_image`)?.file_id
                        )
                      }
                      sx={classes.imagesButton}
                    >
                      <input
                        hidden
                        name={value}
                        accept="image/*"
                        multiple
                        type="file"
                        disabled={isLoading(`influencer_${value}_image`)}
                      />
                      <CameraIcon fontSize="inherit" />
                    </Button>
                  </Box>
                </Box>
              </Stack>
            ))}
          </Grid>
        </Grid>
        {[
          {
            label: "ชื่อ นามสกุล",
            isRequired: true,
            component: (
              <TextFieldCustom
                value={getValues("fullname")}
                onChange={(e) => handleInputChange(e, "fullname")}
                type="text"
                placeholder="Full Name"
                fullWidth
                error={!!errors.fullname}
                helperText={errors.fullname?.message}

              />
            ),
          },
          {
            label: "วันเกิด",
            isRequired: false,
            component: (
              <TextFieldCustom
                value={dayjs(getValues("birth_date")).format("DD/MM/YYYY")}
                onChange={(e) => handleInputChange(e, "birth_date")}
                variant="outlined"
                placeholder="Birthdate"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            ),
          },
          {
            label: "เพศ",
            isRequired: true,
            component: (
              <BasicSelectCustom
                id="gender-select-1"
                value={getValues("gender")}
                onChange={(e) => handleInputChange(e, "gender")}
                data={genderList}
                valueKey="value"
                labelKey="label"
                placeholder="Gender"
                fullWidth
                error={!!errors.gender}
                helperText={errors.gender?.message}
              />
            ),
          },
          {
            label: "สัญชาติ",
            isRequired: false,
            component: (
              <TextFieldCustom
                value={getValues("nationality")}
                onChange={(e) => handleInputChange(e, "nationality")}
                variant="outlined"
                placeholder="Nationality"
                fullWidth
                disabled
              />
            ),
          },
          {
            label: "เบอร์โทรศัพท์",
            isRequired: true,
            component: (
              <TextFieldCustom
                value={formatPhoneNumber(getValues("phone"))}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length >= 13) return;
                  handleInputChange(e, "phone");
                }}
                type="text"
                placeholder="Phone"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            ),
          },
          {
            label: "Line ID",
            isRequired: false,
            component: (
              <TextFieldCustom
                value={getValues("line_id")}
                onChange={(e) => handleInputChange(e, "line_id")}
                variant="outlined"
                placeholder="Line ID"
                fullWidth
              />
            ),
          },
          {
            label: "แนะนำตัวเอง",
            isRequired: false,
            component: (
              <TextFieldCustom
                value={getValues("description")}
                onChange={(e) => handleInputChange(e, "description")}
                variant="outlined"
                multiline
                rows={4}
                placeholder="Description"
                fullWidth
              />
            ),
          },
        ].map((item, index) => (
          <Grid key={index} container mb={3}>
            <Grid item xs={12} md={2.5} xl={2.5} mt={1}>
              <Typography variant="title2">
                {item.label}
                {item.isRequired && <span className="require">*</span>} :
              </Typography>
            </Grid>
            <Grid item xs={12} md={9.5} xl={9.5} mt={[1.5, 1.5, 0]}>
              {item.component}
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box display="flex" justifyContent="center" mt="24px">
        <SaveButton
          variant="contained"
          type="submit"
          startIcon={<Icon className="ico-hu-save" />}
          disabled={
            !getValues("fullname") ||
            !getValues("gender") ||
            !getValues("phone")
              ? true
              : false
          }
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
      </Box>
    </Box>
  );
}
