import { useEffect, useState } from "react";
// mui and icon
import { Box, Stack, CardHeader, Typography, CardContent } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// data and style
import { genderList } from "../campaignBrief.constants";
import { useCampaignTargetAudience as classes } from "../CampaignBrief.style";
// type and api
import { ICreateCampaignProps, ILocationList } from "../CampaignBrief.type";
import { IProvince } from "@api/app-information/type";
import { AppInformationAPI } from "@api/global";
// components and others
import RangeInput from "./RangInput";
import SelectGenderLocation from "./SelectGenderLocation";
import WrapInputWithLabel from "@global/wrap-input-label";

export default function TargetAudience(props: ICreateCampaignProps) {
  const { form } = props;

  const [locationList, setLocationList] = useState<ILocationList[]>([]);

  const {
    watch,
    formState: { errors },
  } = form;

  const isCampaignPrivate = watch("campaign_type") === "private";

  useEffect(() => {
    let isMounted = true;
    function getProvincesList() {
      AppInformationAPI.getProvinceList().then((response) => {
        if (isMounted) {
          const provincesLists = response.map((item: IProvince) => {
            return {
              id: item.id,
              label: item.name_th,
              value: item.name_en,
            };
          });
          setLocationList(provincesLists);
        }
      });
    }
    getProvincesList();

    return () => {
      isMounted = false;
    };
  }, []);

  const genderLocation = [
    {
      id: "gender",
      title: "Gender",
      option: genderList,
      defaultValue: watch("audience.influencer.gender"),
    },
    {
      id: "location",
      title: "Location",
      option: locationList,
      defaultValue: watch("audience.influencer.location").map(
        (item) => item.id
      ),
    },
  ];

  const targetInfluencer = [
    {
      id: "gender",
      title: "Gender",
      option: genderList,
      defaultValue: watch("audience.target.gender"),
    },
    {
      id: "location",
      title: "Location",
      option: locationList,
      defaultValue: watch("audience.target.location").map((item) => item.id),
    },
  ];

  return (
    <>
      <CardHeader
        title={
          <Typography variant="body1" color="primary" fontWeight="500">
            กลุ่มลูกค้าของแบรนด์
          </Typography>
        }
      />
      <CardContent sx={{ mt: "-16px" }}>
        <Stack spacing={2.5}>
          <WrapInputWithLabel
            title={
              <Box sx={classes.ageTitle}>
                <CircleIcon />
                <Typography>Age</Typography>
              </Box>
            }
          >
            <RangeInput
              form={form}
              limit={100}
              name="age"
              firstPlaceholder="Min"
              secondPlaceholder="Max"
              startKeyName="audience.target.age.min"
              endKeyName="audience.target.age.max"
              errors={errors.audience?.target?.age}
            />
          </WrapInputWithLabel>

          {targetInfluencer.map((item, index) => (
            <WrapInputWithLabel
              key={index}
              title={
                <Box sx={classes.ageTitle}>
                  <CircleIcon />
                  <Typography>{item.title}</Typography>
                </Box>
              }
            >
              <SelectGenderLocation
                form={form}
                name={item.id}
                options={item.option}
                defaultValue={item.defaultValue}
                keyName={`audience.target.${item.id}`}
              />
            </WrapInputWithLabel>
          ))}
        </Stack>
      </CardContent>
      {!isCampaignPrivate && (
        <>
          <CardHeader
            title={
              <Typography variant="body1" color="primary" fontWeight="500">
                คุณสมบัตอินฟลูเอนเซอร์ที่คุณต้องการ
              </Typography>
            }
          />
          <CardContent sx={{ mt: "-16px" }}>
            <Stack
              spacing={2.5}
              sx={{
                "& > .MuiGrid-root:nth-of-type(3)": {
                  mt: "20px",
                },
              }}
            >
              <WrapInputWithLabel
                title={
                  <Box sx={classes.ageTitle}>
                    <CircleIcon />
                    <Typography>Follower</Typography>
                  </Box>
                }
              >
                <RangeInput
                  form={form}
                  name="follower"
                  firstPlaceholder="Min"
                  secondPlaceholder="Max"
                  startKeyName={`audience.influencer.follower.min`}
                  endKeyName={`audience.influencer.follower.max`}
                  errors={errors.audience?.influencer?.follower}
                />
              </WrapInputWithLabel>
              <WrapInputWithLabel
                title={
                  <Box sx={classes.ageTitle}>
                    <CircleIcon />
                    <Typography>Age</Typography>
                  </Box>
                }
              >
                <RangeInput
                  form={form}
                  name="age"
                  limit={100}
                  firstPlaceholder="Min"
                  secondPlaceholder="Max"
                  startKeyName={`audience.influencer.age.min`}
                  endKeyName={`audience.influencer.age.max`}
                  errors={errors.audience?.influencer?.age}
                />
              </WrapInputWithLabel>

              {genderLocation.map((item, index) => (
                <WrapInputWithLabel
                  key={index}
                  title={
                    <Box sx={classes.ageTitle}>
                      <CircleIcon />
                      <Typography>{item.title}</Typography>
                    </Box>
                  }
                >
                  <SelectGenderLocation
                    form={form}
                    name={item.id}
                    options={item.option}
                    keyName={`audience.influencer.${item.id}`}
                    defaultValue={item.defaultValue}
                  />
                </WrapInputWithLabel>
              ))}
            </Stack>
          </CardContent>
        </>
      )}
    </>
  );
}
