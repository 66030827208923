export const useCampaignChargeStyle = {
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },
  cardCampaignCharge: {
    mt: "24px",
    p: "24px",
    borderRadius: "15px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
  },
  boxCompensation: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    padding: "24px",
  },
  dividerCompensation: { margin: "16px 0", border: "1px dashed #EBDFFF" },
  boxDeposit: {
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#F5F6F8",
    marginTop: "4px",
  },
  dividerExpenditure: { margin: "24px 0" },
  boxButton: { display: "flex", justifyContent: "center", marginTop: "24px" },
  buttonStart: {
    width: ["100%", "20%"],
    backgroundColor: "#893DBD",
    color: "white",
    border: "2px solid #893DBD",
    "&:hover": {
      backgroundColor: "#893DBD",
      border: "2px solid #893DBD",
      color: "white",
      boxShadow: 3,
    },
  },
};
