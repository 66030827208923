import { useEffect, useState } from "react";
// mui, icons and style
import {
  Box,
  Grid,
  Button,
  Divider,
  useTheme,
  Checkbox,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { useCampaignDetailTabStyle as classes } from "./DetailTab.style";
// api and types
import { CampaignAPI } from "@api/global";
import { ICampaignDetailProps, IInitCreateJob } from "../CampaignDetail.type";
// components
import PrivateStep from "../PrivateStep";
import Timeline from "@global/campaign-detail/Timeline";
import DoOrDoNot from "@global/campaign-detail/DoOrDoNot";
import CampaignCard from "@global/campaign-card/CampaignCard";
import CampaignBrief from "@global/campaign-detail/CampaignBrief";
import TargetAudience from "@global/campaign-detail/TargetAudience";
import BudgetAndReward from "@global/campaign-detail/BudgetAndReward";
import CampaignOverview from "@global/campaign-detail/CampaignOverview";
import TermsAndConditions from "@global/campaign-detail/TermsAndConditions";
import TimelineDetailDialog from "@components/global/campaign-detail/detail-dialog/TimelineDetails";
import CreateCampaignSuccessDialog from "@components/global/campaign-detail/detail-dialog/CreateCampaignSuccess";
import TermsAndConditionsDialog from "@components/global/campaign-detail/detail-dialog/TermsAndConditions";
import InfufficientBalanceDialog from "@components/global/campaign-detail/detail-dialog/InfufficientBalance";
// others
import { Link } from "react-scroll";
import { hBlack } from "@theme/index";
import Loading from "@global/loading/Loading";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

const initCreateJob = {
  campaign_id: "",
  social_account_id: 0,
  status: "listing",
  tier: "",
  price: 0,
  price_currency: "baht",
  term_and_condition: [],
};

export default function CampaignDetailTab(props: ICampaignDetailProps) {
  const { campaignInfo } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [createJob, setCreateJob] = useState<IInitCreateJob>(initCreateJob);
  const [isAcceptService, setIsAcceptService] = useState(false);
  const [isAcceptTimeline, setIsAcceptTimeline] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogSelected, setDialogSelected] = useState("timeline");

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role

  const history = useHistory();
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const employerId = params.get("emp_id") || session_token?.employer_id || ""

  const { campaignId } = useParams<{ campaignId: string }>();

  const campStatus = campaignInfo?.status;
  const prdReviewType = campaignInfo?.product_review_type;
  const isProduct = prdReviewType === "send" || prdReviewType === "send_return";
  const isCampPrivate = campaignInfo?.campaign_type === "private";
  const isPlace = campaignInfo?.review_type === "place";

  useEffect(() => {
    if (campaignInfo) {
      setCreateJob((prev) => {
        return {
          ...prev,
          campaign_id: campaignInfo.campaign_long_id,
          term_and_condition: campaignInfo.brief.term_and_condition,
        };
      });
    }
  }, [campaignInfo]);

  const handleCreateJob = (
    e: { target: { checked: boolean } },
    order: number
  ) => {
    const campaign = [...createJob.term_and_condition];
    const campaignIndex = campaign.map((item) => {
      if (item.order === order) {
        return {
          ...item,
          status: e.target.checked,
        };
      }
      return item;
    });
    setCreateJob({ ...createJob, term_and_condition: campaignIndex });
  };

  const handleAcceptCondition = (key: string, checked: boolean) => {
    setOpenDialog(false);
    setDialogSelected(key);
    if (key === "timeline") setIsAcceptTimeline(!checked);
    if (key === "service") setIsAcceptService(!checked);
  };

  const handleOpenConditionDialog = (key: string) => {
    setOpenDialog(true);
    setDialogSelected(key);
  };

  const campaignDetailList = [
    {
      id: "campaignOverview",
      title: "Campaign Overview",
      subTutle: "ภาพรวมแคมเปญ",
      component: <CampaignOverview campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -350,
    },
    {
      id: "campaignBrief",
      title: "Campaign Brief",
      subTutle: "รายละเอียดแคมเปญ",
      component: <CampaignBrief campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -200,
    },
    {
      id: "doOrDoNot",
      title: "Do and Don't",
      subTutle: "สิ่งที่ต้องทำและสิ่งที่ห้ามทำ",
      component: <DoOrDoNot campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "targetAudience",
      title: "Target Audience",
      subTutle: "กลุ่มเป้าหมาย",
      component: <TargetAudience campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "budgetAndReward",
      title: "Budget and Reward",
      subTutle: "สิ่งที่อินฟลูเอนเซอร์จะได้รับ",
      component: <BudgetAndReward campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "termsAndConditions",
      title: "Terms and Conditions",
      subTutle: "โปรดอ่านรายละเอียดและตรวจสอบข้อมูลก่อนทำการสมัคร",
      component: (
        <TermsAndConditions
          campaignInfo={campaignInfo}
          role="employer"
          handleCreateJob={handleCreateJob}
          campaignStatus={campStatus}
        />
      ),
      xs: 9,
      offset: -185,
    },
  ];

  const campaignDetailForSm = [
    {
      ...campaignDetailList[4],
      md: 7,
      class: "budgetAndReward",
    },
    {
      id: "timeline",
      title: "Timeline",
      subTutle: "",
      component: <Timeline campaignInfo={campaignInfo} />,
      md: 5,
      class: "timeline",
    },
  ];

  campaignDetailList.splice(4, screenSm ? 1 : 0);
  const campaignDetailListForSm = screenSm
    ? campaignDetailForSm.reverse()
    : campaignDetailForSm;

  const onAnnounceCampaign = async () => {
    setIsLoading(true);

    CampaignAPI.updateCampaignStatus(
      { status: "influencer_listing" },
      campaignId
    ).then(() => {
      setDialogSelected("success");
      setOpenDialog(true);
    }).catch((error) => {
      console.log("announce campaign error : ", error)
      SwalCustom.fire({
        icon: "error",
        title: "Oops !",
        text: `มีบางอย่างผิดพลาด`,
        showCancelButton: true,
        cancelButtonText: "Close",
        showConfirmButton: false,
      });
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const goEditCampaign = () => {
    if (role === "employer") {
      history.push(`/campaigns/edit?c_id=${campaignId}`);
    } else {
      history.push(
        `/campaigns/edit?emp_id=${employerId}&c_id=${campaignId}`
      );
    }
  };

  if (isLoading) return <Loading />

  return (
    <div>
      <Box
        sx={{
          ...classes.campDetailWrap,
          borderRadius: {
            xs: "16px",
            sm:
              campStatus === "listing" || campStatus === "briefing"
                ? "16px"
                : "0 16px 16px 16px",
          },
          mt: campStatus === "listing" ? "20px" : 0,
        }}
      >
        {campStatus === "briefing" && isCampPrivate && (
          <PrivateStep activeStep={1} />
        )}
        <Box display={{ xs: "none", lg: "inherit" }}>
          <Typography variant="h5" color={hBlack[200]}>
            Campaign Details
          </Typography>
          <Typography variant="subtitle1" color={hBlack[300]}>
            แสดงรายละเอียดของแคมเปญ
          </Typography>
          <Divider sx={{ my: "24px" }} />
        </Box>

        <Grid container>
          <Grid item xs={12} md={12} lg={8} xl={8.5}>
            <Grid container spacing={4} sx={classes.campDetailGridCon}>
              {campaignDetailListForSm.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={item.md}
                  sx={classes.campaignDetailsForSmBox}
                >
                  <Box
                    className={item.class}
                    sx={{
                      ...classes.budgetAndTimeline,
                      alignItems: {
                        sm: item.id !== "timeline" ? "start" : "center",
                        md: "initial",
                      },
                      display: item.id === "timeline" ? "flex" : "inintial",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box width="100%">
                        <Typography variant="h6">{item.title}</Typography>
                        <Typography variant="body2" color="#5C5C5C">
                          {item.subTutle}
                        </Typography>
                        {item.id === "budgetAndReward" && (
                          <Divider sx={{ width: "100%", my: "20px" }} />
                        )}
                      </Box>
                    </Box>
                    {item.component}
                  </Box>
                </Grid>
              ))}
              {campaignDetailList.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Box sx={classes.campDetailCard}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="h6" color={hBlack[200]}>
                          {item.title}
                        </Typography>
                        <Typography variant="subtitle1" color={hBlack[300]}>
                          {item.subTutle}
                        </Typography>
                      </Box>
                      {campStatus === "briefing" && (
                        <Link
                          to={item.id}
                          spy={true}
                          smooth={true}
                          offset={item.offset}
                          duration={50}
                        >
                          <Button
                            onClick={() => goEditCampaign()}
                            sx={{ width: "80px", maxHeight: "35px" }}
                            variant="outlined"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                        </Link>
                      )}
                    </Box>
                    <Divider sx={classes.divider} />
                    {item.component}
                  </Box>
                </Grid>
              ))}
              {campStatus === "briefing" && !isCampPrivate && (
                <>
                  <Grid item xs={12}>
                    {[
                      {
                        key: "timeline",
                        label:
                          "ข้าพเจ้าได้อ่านและทำความเข้าใจ เกี่ยวกับระยะเวลาของแคมเปญ(Timeline) เรียบร้อยแล้ว",
                        checked: isAcceptTimeline,
                      },
                      {
                        key: "service",
                        label:
                          "ข้าพเจ้ายอมรับข้อกำหนดและเงื่อนไขการใช้บริการของ HashU",
                        checked: isAcceptService,
                      },
                    ].map((li, index) => (
                      <Box key={index} display="flex" alignItems="center">
                        <Checkbox
                          checked={li.checked}
                          onClick={() => handleOpenConditionDialog(li.key)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ color: "#8C499C" }}
                        />
                        <Box display="flex">
                          <Typography>{li.label}</Typography>
                          <Typography
                            onClick={() => handleOpenConditionDialog(li.key)}
                            color="primary"
                            ml={1}
                            sx={{ cursor: "pointer" }}
                          >
                            อ่านเพิ่มเติม
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={12} sx={classes.accnouceDateButton}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!isAcceptTimeline || !isAcceptService}
                      endIcon={<SendIcon />}
                      onClick={onAnnounceCampaign}
                    >
                      ประกาศรับสมัครอินฟลูเอนเซอร์
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3.5} lg={4} xl={3.5}>
            <Grid container display="flex" justifyContent="center">
              {campStatus === "briefing" ? (
                <>
                  <Grid item xs={11} sx={classes.timelineDetailComp}>
                    <Box sx={classes.timelineTitleForEditBox}>
                      <Box sx={classes.timelineTitleForEdit}>
                        <Box height="100%">
                          <Typography variant="h6" color={hBlack[200]}>
                            Timeline
                          </Typography>
                          <Typography variant="subtitle1" color={hBlack[300]}>
                            ระยะเวลาของแคมเปญ
                          </Typography>
                        </Box>
                        <Link
                          to={"timeline"}
                          spy={true}
                          smooth={true}
                          // offset={item.offset}
                          duration={50}
                        >
                          <Button
                            onClick={() =>goEditCampaign()}
                            sx={classes.timelineEditBtn}
                            variant="outlined"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                        </Link>
                      </Box>
                      <Divider sx={{ bgcolor: "#EBDFFF" }} />
                      <Box sx={classes.timelineForEditBox}>
                        <Box width="75%">
                          <Timeline campaignInfo={campaignInfo} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={11} mt="23px">
                    <CampaignCard
                      isCampaign
                      name={campaignInfo?.name}
                      status={campaignInfo?.status}
                      limit={campaignInfo?.job_limit}
                      platform={campaignInfo?.platform}
                      update={campaignInfo?.updated_at}
                      category={campaignInfo?.category}
                      contentType={campaignInfo?.content_type}
                      companyUrl={campaignInfo?.company?.media?.url}
                      coverUrl={campaignInfo?.brief?.cover_image?.url}
                      eachInf={campaignInfo?.budget?.each_influencer}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={3.5} lg={8} sx={classes.timelineTitleBox}>
                  <Box mt="15px" sx={{ width: { xs: "75%", lg: "100%" } }}>
                    <Box sx={classes.timelineTitle}>
                      <Typography variant="h6" mr={2}>
                        Timeline
                      </Typography>
                      <InfoIcon
                        onClick={() => {
                          setOpenDialog(true);
                          setDialogSelected("timeline");
                        }}
                      />
                    </Box>
                    <Timeline campaignInfo={campaignInfo} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          {campStatus === "briefing" && isCampPrivate && (
            <Box sx={{ width: "100%" }}>
              <Divider sx={{ my: "20px" }} />
              <Box display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  onClick={() => {
                    if (role === "employer") {
                      history.push(`/campaigns/${campaignId}/info?st=private-influencer-list`)
                    } else {
                      history.push(`/campaigns/${campaignId}/info?st=private-influencer-list&emp_id=${employerId}`)
                    }
                  }}
                  sx={classes.nextBtn}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
      {dialogSelected === "timeline" ? (
        <TimelineDetailDialog
          open={openDialog}
          onClose={setOpenDialog}
          handleAcceptCondition={handleAcceptCondition}
          timeline={campaignInfo?.timeline}
          readOnly={campStatus !== "briefing"}
          isPlace={isPlace}
          isProduct={isProduct}
          isNoShipping={prdReviewType === "no_shipping"}
          orgUrl={campaignInfo?.organisation?.attachment?.url}
          period={campaignInfo?.period}
          isCampaignPrivate={isCampPrivate}
        />
      ) : dialogSelected === "service" ? (
        <TermsAndConditionsDialog
          open={openDialog}
          onClose={setOpenDialog}
          handleAcceptCondition={handleAcceptCondition}
        />
      ) : dialogSelected === "infuffient-balance" ? (
        <InfufficientBalanceDialog open={openDialog} onClose={setOpenDialog} />
      ) : (
        <CreateCampaignSuccessDialog
          open={openDialog}
          onClose={setOpenDialog}
          campaignName={campaignInfo?.name}
          campaignId={campaignInfo?.campaign_long_id}
        />
      )}
    </div>
  );
}
