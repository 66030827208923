// mui and style
import { Box, Button, Grid, Typography } from "@mui/material";
import { HomePricingStyle as classes } from "./HomePricing.style";
// component and image
import Faq from "@components/home-pricing/Faq";
import FAQImage from "@assets/images/home/pricing-and-plan-page/faq.png";
// other
import { useHistory } from "react-router-dom";
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";

export default function HomePricing() {
  const bp = useBreakpoint();
  const history = useHistory();

  return (
    <Box sx={classes.contentMain}>
      <Box pt={[15, 20, 20, 30]}>
        <Box my={15} px={[5, 10, 15, 30]}>
          <Faq />
        </Box>
        <Box px={15}>
          <Grid
            container
            columns={8}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              xs={8}
              sm={4}
              md={4}
              lg={4}
            >
              <Box textAlign={bp === "xs" ? "center" : "start"}>
                <Typography
                  variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
                >
                  Question ?
                </Typography>
                <br />
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body1",
                    "body2",
                    "body2",
                    "body1"
                  )}
                  color={"text.secondary"}
                >
                  Feel free to contact us if you have any further question.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  sx={classes.contactUsButton}
                  onClick={() => {
                    history.push("/home/contact");
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              xs={8}
              sm={4}
              md={4}
              lg={4}
            >
              <img
                src={FAQImage}
                alt="faq"
                style={{
                  width: setValueByBreakpoint(
                    bp,
                    "280px",
                    "296px",
                    "296px",
                    "445px"
                  ),
                  objectFit: "contain",
                }}
              ></img>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
