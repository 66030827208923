import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { PaymentAPI } from "@api/global";
import { IPaymentMethodState } from "./type";

const initialState: IPaymentMethodState = {
  paymentMethodList: null,
  walletBalance: null,
  loading: false,
  error: "",
};

export const getPaymentMethodAsync = createAsyncThunk(
  "paymentMethodAsync",
  async () => {
    try {
      const response = await PaymentAPI.getPaymentMethod();
      return response;
    }
    catch (err){
      throw(err)
    }
  }
)

export const getWalletMiddleWareAsync = createAsyncThunk(
  "walletMiddleWareAsync",
  async (params:{ employer_id: string, campaign_id: string }) => {
    try {
      const response = await PaymentAPI.getWalletMiddleWare(params);
      return response;
    }
    catch (err){
      throw(err)
    }
  }
)

export const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setWalletBalance: (state, { payload }) => {
      state.walletBalance = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethodAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPaymentMethodAsync.fulfilled, (state, action) => {
        state.paymentMethodList = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getPaymentMethodAsync.rejected, (state, action) => {
        state.paymentMethodList = null;
        state.loading = false;
        state.error = action.error.message || "";
      })
      .addCase(getWalletMiddleWareAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getWalletMiddleWareAsync.fulfilled, (state, action) => {
        state.walletBalance = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getWalletMiddleWareAsync.rejected, (state, action) => {
        state.walletBalance = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
}); 

export const { setWalletBalance } = PaymentSlice.actions;

export const selectPaymentMethod = (state: RootState) => state.payment.paymentMethodList;
export const selectWalletBalance = (state: RootState) => state.payment.walletBalance;
export const selectPaymentOnload = (state: RootState) => state.payment.loading;

export default PaymentSlice.reducer; 