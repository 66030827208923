import { useEffect } from "react";
// mui, icon and theme
import { ThemeProvider } from "@mui/material";
import Theme from "./theme";
// firebase
import firebaseApp from "./firebase";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
// routes
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
// css
import "./App.css";
import CryptoJS from "crypto-js"
// utils
import { FIREBASE_VAPID_KEY } from "./utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// api
import { NotificationAPI } from "@api/global";
// redux
import { useAppDispatch } from "@store/hook";
import { setEmployerNotification, setInfluencerNotification } from "@slices/Notification.slice";

function App() {
  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();

  const signgUpAnonymously = () => signInAnonymously(getAuth(firebaseApp));
  const messaging = getMessaging(firebaseApp);

  useEffect(() => {
    const generateToken = () => {
      if (localStorage.getItem("notification_token") !== null || !session_token?.user_id) return

      signgUpAnonymously();
      getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
        .then( async (token) => {
          await NotificationAPI.addFCMClient({ user_id:session_token.user_id, token: token })
          const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(token), "secret key").toString();
          localStorage.setItem("notification_token", encryptedToken)
          NotificationAPI.getNotificationList().then((noti) => {
            if (session_token.role === "influencer") {
              dispatch(setInfluencerNotification(noti));
            } else if (session_token.role === "employer") {
              dispatch(setEmployerNotification(noti));
            }
          });
        })
        .catch((error) => console.log("get token error : ", error));
    }
    generateToken()
  })

  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
