import { useState } from "react";
// mui and icons
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import SaveIcon from "@mui/icons-material/Save";
// style and component
import { useProductReturnStyle as classes } from "../JobApproved.style";
import ShippingProductDialog from "../../../job-dialog/ShippingProductDialog";
// type and api
import {
  shippingTypeOption,
  IInitMyJobsJobTabForm,
} from "../../../JobsTab.type";
import { JobAPI } from "@api/global";
import { IProductReturnProps } from "../../../JobsTab.type";
// redux
import {
  setInfluencerJobInfo,
  selectInfluencerJobInfo,
} from "@slices/InfluencerJobInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import { CompanyIconSelected } from "@global/icon-selected/CompanyIconSelected";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import BasicSelectCustom from "@global/basic-select";
import TextFieldCustom from "@global/text-field";
import Loading from "@global/loading/Loading";
import { useParams } from "react-router-dom";

export default function ProductReturn(props: IProductReturnProps) {
  const {
    form: { register, handleSubmit, watch, setValue },
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [openShippingProduct, setOpenShippingProduct] = useState(false);

  const { jobId } = useParams<{ jobId: string }>();

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const productReturn = jobInfo?.product_return;
  const isProductReturn = productReturn?.tracking_no;

  const onHandleChange = (
    e: { target: { value: string } },
    keyName: string
  ) => {
    const { value } = e.target;
    setValue(keyName as any, value);
  };

  const onDraftProductReturn = async (value: IInitMyJobsJobTabForm) => {
    setIsLoading(true);
    const data = {
      product_return: value.product_return,
    };
    await JobAPI.updateInfluencerJob(jobId, data)
      .then((response) => {
        setIsLoading(false);
        dispatch(setInfluencerJobInfo(response));
      })
      .catch((error) => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  if (isLoading) return <Loading />
  
  return (
    <Box sx={classes.productCon}>
      <Box sx={classes.title}>
        <Box>
          <Typography>Product Return</Typography>
          <Typography>อินฟลูเอนเซอร์ส่งสินค้ากลับให้ผู้จ้างงาน</Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AllInboxIcon />}
          sx={classes.shippingProBtn}
          onClick={() => setOpenShippingProduct(true)}
        >
          Shipping Product
        </Button>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Box p={["24px 0 24px 0", "24px"]}>
        <Box sx={classes.address}>
          <Typography>ที่อยู่ในการส่งของกลับ</Typography>
          <Typography>{`${productReturn?.contact}, ${productReturn?.phone}`}</Typography>
          <Typography>{productReturn?.address}</Typography>
        </Box>
        <Grid container spacing={[2, 5]} my={["5px", "15px"]}>
          <Grid item xs={12} sm={3.5} md={3} mt={[0, 2]}>
            <Typography>Shipping Company</Typography>
          </Grid>
          <Grid item xs={12} sm={8.5} md={9}>
            {!isProductReturn ? (
              <BasicSelectCustom
                className="select-plateform"
                id="shipping-type-select"
                value={watch("product_return.shipping_type")}
                onChange={(e) => {
                  onHandleChange(e, "product_return.shipping_type");
                }}
                data={shippingTypeOption}
                valueKey="value"
                labelKey="label"
                iconKey="img"
                iconType="imageUrl"
                placeholder="Select"
                fullWidth
                sx={{ mb: "-10px" }}
              />
            ) : (
              <Box display="flex" alignItems="center" mt={[0, 2]}>
                <CompanyIconSelected
                  company={productReturn?.shipping_type}
                  width="24px"
                  height="24px"
                />
                <Typography textTransform="capitalize" ml={2}>
                  {productReturn?.shipping_type}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={3.5} md={3} mt={5}>
            <Typography>Tracking No.</Typography>
          </Grid>
          <Grid item xs={12} sm={8.5} md={9} mt={[0, 3]}>
            {!isProductReturn ? (
              <TextFieldCustom
                variant="outlined"
                placeholder="Please enter text..."
                fullWidth
                inputProps={{
                  ...register(`product_return.tracking_no`),
                }}
              />
            ) : (
              <Typography
                mt={[0, 2]}
                sx={{ textDecoration: "underline", color: "#893DBD" }}
              >
                {productReturn?.tracking_no}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={3.5} md={3} mt={3}>
            <Typography>Note</Typography>
          </Grid>
          <Grid item xs={12} sm={8.5} md={9} mt={[0, 3]}>
            {!isProductReturn ? (
              <TextFieldCustom
                variant="outlined"
                multiline
                minRows={4}
                placeholder="Please enter text..."
                fullWidth
                inputProps={{
                  ...register(`product_return.note`),
                }}
              />
            ) : (
              <Typography>
                {productReturn?.note ? productReturn?.note : "-"}
              </Typography>
            )}
          </Grid>
        </Grid>
        {!isProductReturn && (
          <Box sx={classes.saveBtn}>
            <Button
              onClick={() =>
                handleSubmit((e: IInitMyJobsJobTabForm) =>
                  onDraftProductReturn(e)
                )()
              }
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={
                !watch("product_return.shipping_type") ||
                !watch("product_return.tracking_no")
                  ? true
                  : false
              }
            >
              บันทึก
            </Button>
          </Box>
        )}
      </Box>

      <ShippingProductDialog
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
        jobEmployerDetail={null}
        jobInfluencerDetail={jobInfo}
      />
    </Box>
  );
}
