import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Chip,
  Grid,
  Radio,
  Button,
  Avatar,
  Drawer,
  Divider,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
// style and icon
import { useMyJobsFilterStyle as classes } from "./MyJobs.style";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// datas and types
import {
  platformList,
  reviewTypeList,
  sortDateBudgetList,
} from "@views/job-board/JobBoard.constants";
import { IInfluencerJobQueryParams, IMyJobsFilterProps } from "./MyJobs.type";
import { useHistory, useLocation } from "react-router-dom";

export default function MyJobsFilter(props: IMyJobsFilterProps) {
  const { openFilter, setOpenFilter } = props;

  const [filterList, setFilterList] = useState<IInfluencerJobQueryParams>();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const search = params.get("q") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const page = Number(params.get("page")) || 1;

  useEffect(() => {
    setFilterList((prev) => {
      return {
        ...prev,
        page: page,
        q: search,
        status: status,
        sort: sort,
        order_by: orderBy,
        platform: platform,
        type: type,
      };
    });
  }, [page, search, status, sort, orderBy, platform, type]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenFilter(open);
    };

  const handleFilter = (data: IInfluencerJobQueryParams) => {
    setFilterList((prev) => {
      return { ...prev, ...data };
    });
  };

  const onClearFilter = () => {
    history.replace("/my-jobs");
    setOpenFilter(false);
  };

  const onApplyFilter = () => {
    if (!filterList) return;
    const queryString = Object.keys(filterList)
      .map((key) => `${key}=${filterList[key as keyof IInfluencerJobQueryParams]}`)
      .join("&");

    history.replace(`/my-jobs?${queryString}`);
    setOpenFilter(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openFilter}
      onClose={toggleDrawer(false)}
      sx={classes.drawer}
    >
      <Box sx={classes.drawerCon}>
        <Box sx={classes.filterTitle}>
          <Typography variant="h6" color="primary" ml={3.5}>
            Filter
          </Typography>
          <ClearOutlinedIcon onClick={() => setOpenFilter(false)} />
        </Box>
        <Box sx={classes.titleItem}>
          <Typography>Sort by</Typography>

          <FormControl>
            <RadioGroup sx={{ minHeight: "90px" }}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  {sortDateBudgetList.date.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      sx={{ width: "100%" }}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilter({
                              order_by: "date",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={7}>
                  {sortDateBudgetList.budget.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      sx={{ width: "100%" }}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilter({
                              order_by: "budget",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider sx={classes.line} />
        <Box sx={classes.titleItem}>
          <Typography>Platform</Typography>
          <Box>
            <Chip
              label="All"
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  filterList?.platform === "" ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor: filterList?.platform === "" ? "#F4ECFF" : "#fff",
                minWidth: "60px",
                color: filterList?.platform === "" ? "#893DBD" : "#545454",
              }}
              onClick={() => handleFilter({ platform: "" })}
            />
            {platformList.map((item, index) => (
              <Chip
                key={index}
                avatar={<Avatar src={item.url} />}
                label={item.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.platform === item.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor:
                    filterList?.platform === item.key ? "#F4ECFF" : "#fff",
                  color:
                    filterList?.platform === item.key ? "#893DBD" : "#545454",
                }}
                onClick={() => handleFilter({ platform: item.key })}
              />
            ))}
          </Box>
        </Box>
        <Divider sx={classes.line} />
        <Box sx={classes.titleItem}>
          <Typography>Review</Typography>
          <Box>
            {reviewTypeList.map((type, index) => (
              <Chip
                key={index}
                label={type.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.type === type.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.type === type.key ? "#F4ECFF" : "#fff",
                  color: filterList?.type === type.key ? "#893DBD" : "#545454",
                  minWidth: "60px",
                }}
                onClick={() => handleFilter({ type: type.key })}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            ...classes.filterBottom,
            ...classes.filterBtnMyJob,
          }}
        >
          <Typography ml={3.5} onClick={onClearFilter}>
            Clear All
          </Typography>
          <Button
            variant="contained"
            onClick={onApplyFilter}
            sx={{ ...classes.buttonApply, mr: ["10px", "15px"] }}
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
