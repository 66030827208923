// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Avatar,
  Typography,
  DialogContent,
} from "@mui/material";
// type and style
import { useRegistCampaignSuccessDialogStyle as classes } from "../JobBoard.style";
import { useHistory } from "react-router-dom";

export default function RejectOfferingDialog(props: { open: boolean }) {
  const { open } = props;

  const history = useHistory();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent>
        <Box sx={classes.content}>
          <Avatar
            src={require("@assets/images/infographic/influencer/job-board/private-influ-buy-their-own-stuff.png")}
          />

          <Box sx={classes.contentTxt}>
            <Typography variant="h5">คุณตอบรับข้อเสนอของเรา</Typography>
            <Typography>
              กดปุ่มด้านล่างเพื่อค้นหาแคมเปญอื่นๆที่คุณสนใจ
            </Typography>
          </Box>
          <Stack direction="row" spacing={4} sx={classes.applyButton}>
            <Button
              variant="contained"
              onClick={() => history.push("/job-board")}
              sx={{ maxWidth: "282px" }}
            >
              สมัครแคมเปญอื่นๆ
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
