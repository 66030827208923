import { useEffect, useState } from "react";
// mui and icon
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// component
import StatusCandidate from "./StatusCandidate";
import CandidateInfluencerCard from "./CandidateInfluencerCard";
import InfluencerListFilter from "./influencer-list-filter/InfluencerListFilter";
// theme and style
import { hBlack } from "@theme/index";
import { useCampaignInfluencerListStyle as classes } from "../InfluencerList.style";
// type and api
import {
  IInitEstimateSummary,
  IInfluencerActiveInfoParams,
} from "../InfluencerList.type";
import { IInfluencerActiveInfoRes, IItemsRes } from "@api/job/type";
import { JobAPI } from "@api/global";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import {
  selectCheckSelected,
  updateCheckSelected,
} from "@slices/InfluencerActiveList.slice";
// data
import {
  initialParams,
  initEstimateSummary,
} from "./influencer-list-filter/InfluencerListFilterData";
// other
import Loading from "@global/loading/Loading";
import PaginationCustom from "@global/pagination-custom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function InfluencerList() {
  const { session_token } = getTokenFromStorage();
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const history = useHistory();
  const dispatch = useAppDispatch();
  const checkSelected = useAppSelector(selectCheckSelected);

  const [candidateInfluencer, setCandidateInfluencer] = useState<IInfluencerActiveInfoRes>();
  const [openFilterInfluencer, setOpenFilterInfluencer] = useState(false);
  const [checkLimit, setCheckLimit] = useState(false);
  const [estimateSummary, setEstimateSummary] = useState<IInitEstimateSummary>(initEstimateSummary);
  const [campaignInfluencerListParams, setCampaignInfluencerListParams] = useState<IInfluencerActiveInfoParams>(initialParams);
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [openInfluencerSelected, setOpenInfluencerSelected] = useState(false);

  const checkStatus = candidateInfluencer?.summary.is_selecting;

  useEffect(() => {
    setCampaignInfluencerListParams((prev) => {
      return {
        ...prev,
        page: paginate,
        item_per_page: rowsPerPage
      };
    });
  }, [paginate, rowsPerPage]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    JobAPI.getCampaignInfluencerList(employerId, campaignId, campaignInfluencerListParams)
      .then((response: IInfluencerActiveInfoRes) => {
        if (isMounted) {
          if (
            checkSelected.employerId === employerId &&
            checkSelected.campaignId === campaignId
          ) {
            response.items.forEach((item) => {
              const findIndex = checkSelected.item.findIndex((find) => {
                return find.jobId === item.job_id;
              });
              if (findIndex !== -1) {
                response.items[findIndex].is_selecting =
                  checkSelected.item[findIndex].isSelected;
              }
            });
          }

          let checkNowLength = 0;
          response.items.forEach((item) => {
            if (!item.is_selecting) return;
            checkNowLength++;
          });
          if (checkNowLength < response.summary.job_limit) {
            setCheckLimit(false);
          } else {
            setCheckLimit(true);
          }

          setCandidateInfluencer(response);
          dispatch(
            updateCheckSelected({
              employerId,
              campaignId: campaignId,
              item: response.items.map((item) => {
                return {
                  jobId: item.job_id,
                  isSelected: item.is_selecting,
                };
              }),
            })
          );
          const summary: any[] = [];

          const newData = {
            followers: 0,
            likes: 0,
            comments: 0,
            shares: 0,
          };
          response.items.forEach((item, index) => {
            if (!item.is_selecting) return;
            summary[index] = item;
            newData.followers += item.followers;
            newData.likes += item.avg_like;
            newData.comments += item.avg_comment;
            newData.shares += item.avg_share;
          });

          const lengthSummary = summary.filter((item) => {
            return item.is_selecting;
          });

          setEstimateSummary({
            ...newData,
            totalInfluencerSelected: lengthSummary?.length,
          });
        }
      })
      .finally(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false
    }

  // eslint-disable-next-line
  }, [campaignInfluencerListParams]);

  const handleClick = (id: string) => {
    if (!candidateInfluencer) return;
    const findIndex = candidateInfluencer.items.findIndex(
      (item) => item.job_id === id
    );
    if (findIndex === -1) return;

    const newCandidate = candidateInfluencer;

    let checkItemLength = 0;

    candidateInfluencer.items.forEach((item) => {
      if (!item.is_selecting) return;
      checkItemLength++;
    });

    if (checkItemLength < candidateInfluencer.summary.job_limit) {
      newCandidate.items[findIndex].is_selecting =
        !newCandidate.items[findIndex].is_selecting;
    } else {
      newCandidate.items[findIndex].is_selecting = false;
    }

    let checkNowLength = 0;
    newCandidate.items.forEach((item) => {
      if (!item.is_selecting) return;
      checkNowLength++;
    });
    if (checkNowLength < candidateInfluencer.summary.job_limit) {
      setCheckLimit(false);
    } else {
      setCheckLimit(true);
    }

    setCandidateInfluencer({ ...newCandidate });
    dispatch(
      updateCheckSelected({
        ...checkSelected,
        item: newCandidate.items.map((item) => ({
          jobId: item.job_id,
          isSelected: item.is_selecting,
        })),
      })
    );

    const summary: any[] = [];

    const newData = {
      followers: 0,
      likes: 0,
      comments: 0,
      shares: 0,
    };
    newCandidate.items.forEach((item, index) => {
      if (!item.is_selecting) return;
      summary[index] = item;
      newData.followers += item.followers;
      newData.likes += item.avg_like;
      newData.comments += item.avg_comment;
      newData.shares += item.avg_share;
    });

    const lengthSummary = summary.filter((item) => {
      return item.is_selecting;
    });

    setEstimateSummary({
      ...newData,
      totalInfluencerSelected: lengthSummary?.length,
    });
  };

  const handleViewInfluencer = (influId: string, item: IItemsRes) => {
    history.push(
      `/influencers/${influId}/profile?platform=${item.platform}&limit=${candidateInfluencer?.summary.job_limit}&job=${item.job_id}`
    );
  };

  const toggleFilterInfluDrawers = (open: boolean) => {
    setOpenFilterInfluencer(open);
  };

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const toggleInfluSelectedDrawers = (open: boolean) => {
    setOpenInfluencerSelected(open);
  };

  const clearInfluencer = () => {
    toggleInfluSelectedDrawers(false);
    setIsLoading(true);
    if (!candidateInfluencer) return;

    setCandidateInfluencer({
      ...candidateInfluencer,
      items: candidateInfluencer.items.map((item) => {
        return { ...item, is_selecting: false };
      }),
    });
    setEstimateSummary(initEstimateSummary);
    setCheckLimit(false);
    dispatch(
      updateCheckSelected({
        ...checkSelected,
        item: checkSelected.item.map((item) => ({
          jobId: item.jobId,
          isSelected: false,
        })),
      })
    );
    setIsLoading(false);
  };

  if (isLoading) return <Loading />

  return (
    <>
      <div>
        <Typography variant="h5" color={hBlack[200]}>
          Influencer List
        </Typography>
        <Typography variant="subtitle1" color={hBlack[300]}>
          อินฟลูเอนเซอร์ที่คุณเลือกเพื่อจ้างงาน
        </Typography>
      </div>

      <Divider sx={{ my: "24px" }} />

      <StatusCandidate
        candidateInfluencer={candidateInfluencer}
        setCandidateInfluencer={setCandidateInfluencer}
        estimateSummary={estimateSummary}
        onClick={handleClick}
        openInfluencerSelected={openInfluencerSelected}
        toggleInfluSelectedDrawers={toggleInfluSelectedDrawers}
        clearInfluencer={clearInfluencer}
      />

      <Box sx={classes.influeRegisListsTitle}>
        <Typography>รายชื่อผู้สมัครเข้าร่วมแคมเปญ</Typography>
        <Button
          variant="outlined"
          endIcon={<FilterAltIcon />}
          onClick={() => {
            toggleFilterInfluDrawers(true);
          }}
        >
          Filters
        </Button>
      </Box>

      <InfluencerListFilter
        openFilterInfluencer={openFilterInfluencer}
        toggleDrawers={toggleFilterInfluDrawers}
        campaignType={"public"}
        queryParams={campaignInfluencerListParams}
        setCampaignInfluencerListParams={setCampaignInfluencerListParams}
      />

      <div>
        {candidateInfluencer?.items?.length ? (
          <Grid container spacing={5}>
            {candidateInfluencer?.items.map((item: IItemsRes, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2} key={index}>
                  <CandidateInfluencerCard
                    item={item}
                    checkLimit={checkLimit}
                    onClick={handleClick}
                    handleViewInfluencer={handleViewInfluencer}
                    checkStatus={!!checkStatus}
                    candidateInfluencer={candidateInfluencer}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box sx={classes.showImageNoData}>
            <img
              src={require("@assets/images/infographic/admin/influencer-selected/waiting-influencer.png")}
              alt="no campaign"
            />
            <Box sx={classes.boxTextImageNoData}>
              <Typography variant="h5" my="8px">
                กำลังรับสมัครผู้เข้าร่วมแคมเปญ...
              </Typography>
              <Typography>
                ขณะนี้อยู่ในขั้นตอนการรับสมัครอินฟลูเอนเซอร์เข้าร่วมแคมเปญ
              </Typography>
            </Box>
          </Box>
        )}
      </div>

      <Box sx={classes.pagination}>
        <PaginationCustom
          page={paginate}
          count={
            paginate > 0
              ? Math.ceil(Number(candidateInfluencer?.total_item || 0) / rowsPerPage)
              : 0
          }
          onPaginationChange={handleChangePage}
          rowsPerPageData={[]}
          rowsPerPage={rowsPerPage}
          onRowPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
