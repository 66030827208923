import { useState } from "react";
// mui and icon
import { Box, Button, Divider, Icon, Stack, Typography } from "@mui/material";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
// style and type
import { useReviewPostCaptionStyle as classes } from "../../EmployerJob.style";
import { IPostCaptionProps } from "../../EmployerJob.type";
// component
import ConversionTracking from "./ConversionTracking";
// others
import nl2br from "react-nl2br";
import Linkify from "react-linkify";
import TextFieldCustom from "@global/text-field";

export default function PostCaption(props: IPostCaptionProps) {
  const {
    isJobRejected,
    jobInfo,
    form: { register, watch, setValue },
  } = props;
  const [hadleOpenConvDialog, setHandleOpenConvDialog] = useState(false);

  const description = jobInfo?.description;
  const descIsApproved = watch("description.is_approve") === "approved";
  const descIsNotApproved = watch("description.is_approve") === "not_approved";

  const handleCloseConv = () => {
    setHandleOpenConvDialog(false);
  };

  return (
    <Box sx={classes.imgCardCon}>
      <Box sx={classes.imgCardTitleBox}>
        <div>
          <Typography variant="h6">
            {isJobRejected
              ? "Captions Feedback"
              : "Review Captions to Optimize Posts"}
          </Typography>
          <Typography variant="subTitle1" color="#505050">
            {isJobRejected
              ? "ความคิดเห็นเกี่ยวกับแคปชั่น"
              : "ตรวจสอบแคปชั่นเพื่อเพิ่มประสิทธิภาพของโพสต์"}
          </Typography>
        </div>
        {jobInfo?.campaign_info.objective === "conversion" && (
          <Button
            variant="contained"
            startIcon={<OpenInNewRoundedIcon />}
            sx={classes.convButton}
            onClick={() => setHandleOpenConvDialog(true)}
            disabled={jobInfo.conversion.coupon_name ? false : true}
          >
            Conversion
          </Button>
        )}
      </Box>
      <Divider sx={classes.divider} />
      <div>
        <Linkify>
          <Typography>{nl2br(description?.description)}</Typography>
        </Linkify>
      </div>
      {!isJobRejected && description?.is_approve !== "approved" ? (
        <>
          <Stack direction="row" spacing={2} sx={{ my: "16px" }}>
            <Button
              fullWidth
              variant={descIsApproved ? "contained" : "outlined"}
              color="success"
              startIcon={
                <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
              }
              onClick={() => setValue("description.is_approve", "approved")}
              sx={{
                width: "160px",
                border: descIsApproved ? "none" : "1px solid #4CAF50",
                color: descIsApproved ? "#fff" : "#4CAF50",
                fontSize: "16px",
                "&:hover": {
                  border: descIsApproved ? "none" : "1px solid #4CAF50",
                },
                maxHeight: "38px",
              }}
            >
              อนุมัติ
            </Button>
            <Button
              fullWidth
              variant={descIsNotApproved ? "contained" : "outlined"}
              color="error"
              startIcon={
                <Icon className="ico-hu-close" sx={{ fontSize: "20px" }} />
              }
              onClick={() => setValue("description.is_approve", "not_approved")}
              sx={{
                width: "160px",
                border: descIsNotApproved ? "none" : "1px solid #E30D18",
                color: descIsNotApproved ? "#fff" : "#E30D18",
                fontSize: "16px",
                "&:hover": {
                  border: descIsNotApproved ? "none" : "1px solid #E30D18",
                },
                maxHeight: "38px",
              }}
            >
              ไม่อนุมัติ
            </Button>
          </Stack>
          <Box>
            <Typography
              variant="title2"
              color={descIsNotApproved ? "error" : undefined}
              mb="8px"
            >
              ความคิดเห็นจากผู้จ้างงาน :
            </Typography>
            <TextFieldCustom
              variant="outlined"
              multiline
              minRows={4}
              placeholder="โปรดระบุความคิดเห็น..."
              fullWidth
              disabled={!descIsNotApproved}
              sx={classes.noteEdit}
              inputProps={{
                ...register(`description.note`),
              }}
            />
          </Box>
        </>
      ) : description?.is_approve === "not_approved" ? (
        <Box mt="24px">
          <Typography variant="title2" color="error">
            ความคิดเห็นจากผู้จ้างงาน :
          </Typography>
          <Box sx={classes.influComment}>
            <Typography>
              {!isJobRejected ? watch("description.note") : description?.note}
            </Typography>
          </Box>
        </Box>
      ) : description?.is_approve === "approved" ? (
        <Box display="flex" alignItems="center" mt="24px">
          <Icon className="ico-hu-check" color="success" />
          <Typography color="#4CAF50" variant="title1" ml={2}>
            Caption ได้รับการอนุมัติแล้ว
          </Typography>
        </Box>
      ) : null}

      <ConversionTracking
        openConvDialog={hadleOpenConvDialog}
        closeConvDialog={handleCloseConv}
        conversion={jobInfo?.conversion}
      />
    </Box>
  );
}
