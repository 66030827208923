// mui and style
import { Box, Icon, Typography, Button } from "@mui/material";
import { useVertifyEmail as classes } from "./verifyEmail.styles";
// other
import { useHistory } from "react-router-dom";

export default function VerifyEmailFailed() {
  const history = useHistory();

  const backToVerify = () => history.push(`/`);

  return (
    <div>
      <Box sx={classes.boxDialog}>
        <Box sx={classes.boxIconFailed}>
          <Icon className="ico-hu-mail" sx={classes.mailIconFailed} />
        </Box>
        <Box sx={classes.boxContent}>
          <Typography variant="h4">Verification failed</Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: "16px", fontWeight: "400" }}
          >
            Please try again, or click on the button below to receive a new
            verification mail
          </Typography>
        </Box>
        <Box sx={classes.boxButton}>
          <Button onClick={() => backToVerify()} sx={classes.resendButton}>
            Try again
          </Button>
        </Box>
      </Box>
    </div>
  );
}
