import { useEffect, useState } from "react";
// mui
import { Link, Typography } from "@mui/material";
// style and icon
import { useCampaignChargeStyle as classes } from "./Charge.style";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// api and type
import { PaymentAPI } from "@api/global";
import { ITransactionCampaign } from "./Charge.type";
import { ICampaignWalletBalance } from "../campaign-detail/Detail.type";
// component
import Loading from "@components/global/loading/Loading";
import WalletBalance from "@components/campaign/campaign-charge/WalletBalance";
import WalletNotBalance from "@components/campaign/campaign-charge/WalletNotBalance";
// redux
import { useAppDispatch } from "@store/hook";
import { getPaymentMethodAsync } from "@slices/PaymentMethod.slice";
import { getInfluencerPrivateCampaign } from "@slices/CampaignPrivate.slice";
// others
import { CampaignInfoContext } from "../campaign-detail/index";
import { useHistory, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function CampaignCharge() {
  const [isLoading, setIsLoading] = useState(false);
  const [campaignWalletBalance, setCampaignWalletBalance] = useState<ICampaignWalletBalance | null>(null)
  const [tranCampaignInfo, setTranCampaignInfo] = useState<ITransactionCampaign | null>(null)

  const history = useHistory();
  const { campaignId } = useParams<{ campaignId: string }>();

  const isWalletBalance = (campaignWalletBalance?.total || 0) >= 0;
  const { session_token } = getTokenFromStorage();

  const dispatch = useAppDispatch();

  useEffect(() => {
    let isMounted = true;

    const getAPI = () => {
      setIsLoading(true);
      
      try {
        dispatch(getPaymentMethodAsync());
        dispatch(getInfluencerPrivateCampaign({campaignId: campaignId, employerId: session_token?.employer_id || "", params: { page:1, item_per_page: 1000} }))
        PaymentAPI.campaignWallet({ employer_id: session_token?.employer_id || "", campaign_id: campaignId })
        .then((response) => {
          if (isMounted) {
            setCampaignWalletBalance(response)
          }
        })
        PaymentAPI.getTransactionInfo({campaign_id: campaignId}).then((response) => {
          setTranCampaignInfo(response)
        })
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    getAPI();

    return () => {
      isMounted = false
    }
  },[dispatch, campaignId, session_token?.employer_id])

  if (isLoading) return <Loading />
  
  return (
    <CampaignInfoContext.Provider value={{ campaignWalletBalance, tranCampaignInfo }}>
      <div className="container-main">
        <Link
          onClick={() => history.goBack()}
          underline="none"
          sx={classes.goBack}
        >
          <KeyboardArrowLeftIcon />
          <span>Back</span>
        </Link>
        <div>
          <Typography variant="h5">Campaign Charge</Typography>
          <Typography variant="subtitle1">ค่าใช้จ่ายของแคมเปญ</Typography>
        </div>
        {isWalletBalance ? <WalletBalance /> : <WalletNotBalance/> }
      </div>
    </CampaignInfoContext.Provider>
  );
}
