import { useEffect, useState } from "react";
// component
import HomeInfluencer from "@components/home-influencer/HomeInfluencer";
import CreateAccountDialog from "@components/authentication-dialog/CreateAccountDialog";
// other
import { IDevice } from "../global/global.types";
import useBreakpoint from "@utils/hooks/useBreakpoint";

export default function HomeInfluencerPage() {
  const [device, setDevice] = useState<IDevice>("mobile");
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  
  const bp = useBreakpoint();

  useEffect(() => {
    if (bp === "xs") {
      setDevice("mobile");
    } else if (bp === "sm" || bp === "md") {
      setDevice("tablet");
    } else {
      setDevice("desktop");
    }
  }, [bp]);

  return (
    <div>
      <HomeInfluencer
        device={device}
        breakpoint={bp}
        setOpenCreateAccount={setOpenCreateAccount}
      />

      <CreateAccountDialog
        open={openCreateAccount}
        setOpen={setOpenCreateAccount}
        accountSelected="influencer"
      />
    </div>
  );
}
