import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Dialog,
  Divider,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useInfluencerAddressStyle as classes } from "../InfluencerProfile.style";
// api and type
import { InfluencerAPI } from "@api/global";
import { IAddress } from "@api/influencer/type";
import { IAddressForm } from "../InfluencerProfile.type";
// hook-form
import {
  BillingFormSchema,
  ShippingFormSchema,
} from "../InfluencerProfile.shema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// redux
import {
  setInfluencerProfile,
  selectInfluencerProfile,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// other
import {
  formatTaxId,
  formatPhoneNumber,
} from "@utils/helpers/formateNumber.helpers";
import { useHistory } from "react-router-dom";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import EditInfluencerAddress from "../edit-influencer-profile/EditAddress";

export const initAddressForm: IAddressForm = {
  fullname: "",
  tax_id: "",
  phone: "",
  address: "",
  postcode: "",
  province: null,
  district: null,
  subdistrict: null,
};

function Address(props: {
  name: "billing_address" | "shipping_address";
  handleUpdateAddress: (
    addressType: "billing_address" | "shipping_address"
  ) => void;
  address?: IAddress;
}) {
  const { name, handleUpdateAddress, address } = props;

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box sx={classes.addressIconWrap}>
          <IconButton aria-label="save" color="primary" size="small">
            {name === "shipping_address" ? (
              <Icon className="ico-hu-shipping-outline" />
            ) : (
              <Icon className="ico-hu-billing" />
            )}
          </IconButton>
          <Typography
            variant="body1"
            fontWeight={500}
            textTransform="capitalize"
          >
            {name.replaceAll("_", " ")}
          </Typography>
        </Box>
        <Box>
          <IconButton
            aria-label="save"
            color="primary"
            size="small"
            sx={classes.rateCardEditIcon}
            onClick={() => handleUpdateAddress(name)}
          >
            {address?.fullname ? (
              <Icon className="ico-hu-edit" sx={{ fontSize: "15px" }} />
            ) : (
              <Icon className="ico-hu-add" sx={{ fontSize: "15px" }} />
            )}
          </IconButton>
        </Box>
      </Box>
      {address && address.fullname ? (
        <Box sx={classes.addressDetailBox}>
          <Typography variant="body1" color={"common.black"}>
            {`${address?.fullname} , ` || ""}
            {name === "shipping_address"
              ? address?.phone && `${formatPhoneNumber(address?.phone)} ,`
              : address?.tax_id && `${formatTaxId(address?.tax_id)}`}
          </Typography>
          <Typography variant="body1" color={"common.black"}>
            {address?.address &&
              `${address?.address}, 
            ${address?.subdistrict?.name_th}, 
            ${address?.district?.name_th}, 
            ${address?.province?.name_th}, 
            ${address?.postcode}`}
          </Typography>
        </Box>
      ) : (
        <Box sx={classes.addressDetailBox}>
          <Typography
            onClick={() => handleUpdateAddress(name)}
            variant="body1"
            color={"text.secondary"}
            sx={{ cursor: "pointer" }}
          >
            + Add {name === "shipping_address" ? "Shipping" : "Billing"} Address
          </Typography>
        </Box>
      )}
    </div>
  );
}

type TAddressType = "billing_address" | "shipping_address";

export default function InfluencerAddress() {
  const [openToUpdateDialog, setOpenToUpdateDialog] = useState(false);
  const [addressType, setAddressType] =
    useState<TAddressType>("shipping_address");

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const shippingInfo = influencerInfo?.address?.shipping_address;
  const billingInfo = influencerInfo?.address?.billing_address;
  const address =
    addressType === "shipping_address" ? shippingInfo : billingInfo;

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const form = useForm<IAddressForm>({
    resolver: yupResolver(
      addressType === "billing_address" ? BillingFormSchema : ShippingFormSchema
    ),
    defaultValues: initAddressForm,
  });

  useEffect(() => {
    if (!address) return;
    form.reset(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAddress = (addressType: TAddressType) => {
    if (!billingInfo || !shippingInfo) return;
    if (addressType === "billing_address") {
      form.reset(billingInfo);
    } else if (addressType === "shipping_address") {
      form.reset(shippingInfo);
    }
    setAddressType(addressType);
    if (xsScreen)
      history.push(
        `/my-profile/mobile/edit-address?type=${addressType.replaceAll(
          "_address",
          ""
        )}`
      );
    if (!xsScreen) setOpenToUpdateDialog(true);
  };

  const onUpdateAddress = (value: IAddressForm) => {
    const body = {
      address: {
        [addressType]: {
          fullname: value.fullname,
          phone: value.phone.replaceAll("-", ""),
          address: value.address,
          postcode: value.postcode,
          province_id: value.province?.id as number,
          district_id: value.district?.id as number,
          subdistrict_id: value.subdistrict?.id as number,
          tax_id: value.tax_id.replaceAll("-", ""),
        },
      },
    };

    InfluencerAPI.updateInfluencerBilling(body)
      .then((response) => {
        dispatch(setInfluencerProfile(response));
        setOpenToUpdateDialog(false);
        SwalCustom.fire({
          icon: "success",
          title: `Updated ${addressType.replaceAll("_", " ")} success`,
          showConfirmButton: false,
          timer: 1600,
        });
      })
      .catch(() => {
        setOpenToUpdateDialog(false);
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
        }).then(() => {
          setOpenToUpdateDialog(true);
        });
      });
  };

  return (
    <Box p="20px">
      <Address
        name="shipping_address"
        address={shippingInfo}
        handleUpdateAddress={handleUpdateAddress}
      />
      <Divider sx={{ my: "16px" }} />
      <Address
        name="billing_address"
        address={billingInfo}
        handleUpdateAddress={handleUpdateAddress}
      />

      {/* dialog */}
      <Dialog
        open={openToUpdateDialog}
        onClose={() => setOpenToUpdateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={classes.dialog}
      >
        <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="title1">Address</Typography>
          <IconButton onClick={() => setOpenToUpdateDialog(false)}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.contentDialog}>
          <EditInfluencerAddress
            form={form}
            addressType={addressType}
            onSubmit={onUpdateAddress}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
