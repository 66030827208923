import * as yup from "yup";

export const MyJobsTabSchema = yup.object().shape({
  edit_type: yup.string(),
  description: yup.object().shape({
    description: yup.string(),
  }),
  delivery_confirmation: yup.object().shape({
    date_of_receipt: yup.date(),
    attachment: yup.array(),
    note: yup.string(),
  }),
  attachments: yup.string(),
})