export const tabs = [
  {
    key: "profile",
    text: "Profile",
  },
  {
    key: "social",
    text: "Social Media",
  },
  {
    key: "campaign",
    text: "Campaign",
  },
  {
    key: "earnings",
    text: "Earnings",
  },
  {
    key: "referrals",
    text: "Referrals",
  },
  {
    key: "status",
    text: "Influencer Status",
  },
];

export const campaignTabs = [
  {
    key: "details",
    text: "Details",
  },
  {
    key: "timeline",
    text: "Job Timeline",
  },
  {
    key: "report",
    text: "Report",
  },
];

export const platformList = [
  {
    id: 1,
    label: "All",
    value: "",
  },
  {
    id: 2,
    label: "Facebook",
    value: "facebook",
  },
  {
    id: 3,
    label: "Instagram",
    value: "instagram",
  },
  {
    id: 4,
    label: "Twitter",
    value: "twitter",
  },
  {
    id: 5,
    label: "Tiktok",
    value: "tiktok",
  },
  {
    id: 6,
    label: "Youtube",
    value: "youtube",
  },
];

export const banStatusList = [
  {
    id: 1,
    label: "ทั้งหมด",
    value: "",
  },
  {
    id: 2,
    label: "ปกติ",
    value: "active",
  },
  {
    id: 3,
    label: "คาดโทษครั้งที่ 1",
    value: "ban1",
  },
  {
    id: 4,
    label: "คาดโทษครั้งที่ 2",
    value: "ban2",
  },
  {
    id: 5,
    label: "คาดโทษครั้งที่ 3",
    value: "ban3",
  },
  {
    id: 6,
    label: "คาดโทษครั้งที่ 4",
    value: "ban4",
  },
  {
    id: 7,
    label: "ลบบัญชีถาวร",
    value: "removed",
  },
];

export const infBanSortBy = [
  {
    id: 1,
    label: "ใหม่ - เก่า",
    value: "DESC",
  },
  {
    id: 2,
    label: "เก่า - ใหม่",
    value: "ASC",
  },
  {
    id: 3,
    label: "Most Followers",
    value: "follower",
  },
];

export const infTearList = [
  {
    id: 1,
    label: "Nano",
    value: "nano",
    startRange: 1000,
    endRange: 10000,
  },
  {
    id: 2,
    label: "Micro",
    value: "micro",
    startRange: 10000,
    endRange: 50000,
  },
  {
    id: 3,
    label: "Mid-Tier",
    value: "mid-tier",
    startRange: 50000,
    endRange: 500000,
  },
  {
    id: 4,
    label: "Macro",
    value: "macro",
    startRange: 500000,
    endRange: 1000000,
  },
  {
    id: 5,
    label: "Mega",
    value: "mega",
    startRange: 1000000,
  },
];

export const jobStatusList = [
  {
    key: "all",
    value: "",
    label: "ทั้งหมด",
  },
  { key: "listing", value: "listing", label: "รอตอบรับ" },
  { key: "working", value: "working", label: "เริ่มงานได้" },
  { key: "first_draft", value: "first_draft", label: "ส่ง Draft แรก" },
  { key: "rejected", value: "rejected", label: "ต้องแก้ไข" },
  {
    key: "waiting_for_approve",
    value: "waiting_for_approve",
    label: "แก้ไขแล้วรอตรวจ",
  },
  { key: "approved", value: "approved", label: "Draft ผ่านแล้ว" },
  { key: "published", value: "published", label: "แนบลิงค์ส่งงาน" },
  { key: "completed", value: "completed", label: "เสร็จสิ้น" },
  { key: "canceled", value: "canceled", label: "ยกเลิก" },
];

export const reviewTypeList = [
  { key: "all", value: " ", label: "ทั้งหมด" },
  { key: "product", value: "product", label: "Product (สินค้า)" },
  { key: "place", value: "place", label: "Place (สถานที่)" },
];

export const campaignSortByList = [
  {
    key: "date_desc",
    value: "date desc",
    label: "เรียงจากใหม่ - เก่า",
  },
  { key: "date_asc", value: "date asc", label: "เรียงจากเก่า - ใหม่" },
  {
    key: "budget_desc",
    value: "budget desc",
    label: "ค่าตอบแทนมาก - น้อย",
  },
  {
    key: "budget_asc",
    value: "budget asc",
    label: "ค่าตอบแทนน้อย - มาก",
  },
];

export const jobStatusMatchToThai = (value?: string) => {
  if (!value) return;
  if (value === "listing") {
    return "รอตอบรับ";
  } else if (value === "working") {
    return "เริ่มงานได้";
  } else if (value === "first_draft") {
    return "ส่ง Draft แรก";
  } else if (value === "rejected") {
    return "ต้องแก้ไข";
  } else if (value === "waiting_for_approve") {
    return "แก้ไขแล้วรอตรวจ";
  } else if (value === "approved") {
    return "Draft ผ่านแล้ว";
  } else if (value === "published") {
    return "แนบลิงค์ส่งงาน";
  } else if (value === "completed") {
    return "เสร็จสิ้น";
  } else if (value === "canceled") {
    return "ยกเลิก";
  } else if (value === "product") {
    return "Product";
  } else if (value === "place") {
    return "Place";
  }
};
