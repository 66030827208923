import { hPink } from "@theme/index";

export const useEmployersStyle = {
  contentWrap: {
    borderRadius: "20px",
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
  },
  searchWrap: {
    display: "flex",
    justifyContent: ["center", "center", "center", "flex-start"],
    border: "1px solid #8C499C",
    height: "40px",
    boxShadow: "none",
  },
  newEmployerBtnWrap: {
    width: "100%",
    display: "flex",
    justifyContent: ["center", "center", "flex-end"],
  },
  newEmployerBtn: {
    mt: ["20px", "20px", 0],
    justifyContent: "space-evenly",
    bgcolor: "#ED1968",
    color: "white",
    width: "168px",
    boxShadow: "0px 4px 10px 0px #00000033",
    borderStyle: "none",
    "&:hover": {
      bgcolor: hPink[200],
      borderStyle: "none",
    },
  },
  pagination: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
};
