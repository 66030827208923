// mui and components
import { Box, useMediaQuery, useTheme } from "@mui/material";
import JobBoardTabsItemSm from "./JobBoardTabsItemSm";
import JobBoardTabsItemLg from "./JobBoardTabsItemLg";
// style
import { IJobBoardTabsProps } from "../JobBoard.type";
import { useHistory, useLocation } from "react-router-dom";
import { IJobBoardParams } from "@views/job-board/JobBoard.type";

export default function JobBoardTabs(props: IJobBoardTabsProps) {
  const { statusTotal, onClose } = props;

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const history = useHistory();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const campaignType = params.get("c_type") || "";
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const rType = params.get("r_type") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const cate = params.get("cate");
  const status = params.get("status") || "";
  const cates = cate ? cate.split(",").map(Number) : [];
  const filterTotal = (campaignType ? 1 : 0) + (page > 1 ? 1 : 0) + (platform ? 1 : 0) + (rType ? 1 : 0) + (`${orderBy}${sort}` !== "dateDESC" ? 1 : 0) + (cates.length ? 1 : 0);

  const jobBoardTabs = [
    {
      label: "ทั้งหมด",
      campaignType: "",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: statusTotal.total,
    },
    {
      label: "ประกาศรับสมัคร",
      campaignType: "public",
      bgColor: "#E5F6FF",
      color: "#0077E4",
      total: statusTotal.public,
    },
    {
      label: "ได้รับข้อเสนอ",
      campaignType: "private",
      bgColor: "#FFEAF4",
      color: "#E8008A",
      total: statusTotal.offering,
    },
  ];

  const onHandleFilterTabStatus = (campaignType: string) => {
    if (campaignType) {
      const queryParams: IJobBoardParams = {
        order_by: orderBy,
        page: 1,
        platform: platform,
        r_type: rType,
        c_type: campaignType,
        sort: sort,
        status: status,
      };
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key as keyof IJobBoardParams]}`)
      .join("&");
    history.replace(`/job-board?${queryString}`);
    } else {
      history.replace(`/job-board`);
    }
  };
  return (
    <>
      {xsScreen ? (
        <Box mt="5px">
          <JobBoardTabsItemSm
            onHandleFilterTabStatus={onHandleFilterTabStatus}
            setHandleOpenFilter={onClose}
            statusTotal={statusTotal}
            tabs={jobBoardTabs}
          />
        </Box>
      ) : (
        <Box display="flex">
          <JobBoardTabsItemLg
            onHandleFilterTabStatus={onHandleFilterTabStatus}
            setHandleOpenFilter={onClose}
            tabs={jobBoardTabs}
            filterTotal={filterTotal}
          />
        </Box>
      )}
    </>
  );
}
