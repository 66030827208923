import { useContext, useEffect, useState } from "react";
// mui
import {
  Box,
  Radio,
  Button,
  Dialog,
  RadioGroup,
  Typography,
  FormControl,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
// style and icon
import { useOrganizingYourWalletStyle as classes } from "./CreateTeam.style";
import imageSuccess from "@assets/images/infographic/influencer/job-board/private-accept-invitation.png";
// other
import { useHistory } from "react-router-dom";
import CreateTeamStep from "./CreateTeamStep";
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { PaymentAPI, TeamAPI } from "@api/global";
import TeamContext from "@contexts/Team";

export default function OrganizingYourWallet() {
  const teamContext = useContext(TeamContext);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [transfer, setTransfer] = useState("no");
  const [employerWallet, setEmployerWallet] = useState(0)

  const history = useHistory();
  const employerInfo = useAppSelector(selectEmployerInfo);
  const teamInfo = employerInfo?.team;

  const formatter = Intl.NumberFormat('en', { notation: "standard"})

  useEffect(() => {
    let isMounted = true;

    PaymentAPI.checkBalance(true, employerInfo?.employer_full_id).then((wallet) => {
      if (isMounted) {
        setEmployerWallet(wallet)
      }
    })

    return () => {
      isMounted = false
    }

    // eslint-disable-next-line
  }, [])

  const onSubmit = () => {
    if (transfer === "yes") {
      const body = {
        employer_id: employerInfo?.employer_full_id || "",
        team_id: teamInfo?.id || ""
      }
      PaymentAPI.transfer(body).then(() => {
        setOpenDialog(true)
        TeamAPI.updateTeamProgress(teamInfo?.id || "", {create: true, member:true, transfer_wallet:true })
      })
    } else {
      setOpenDialog(true)
      TeamAPI.updateTeamProgress(teamInfo?.id || "", {create: true, member:true, transfer_wallet:true })
    }
  }

  const onGoBack = () => {
    teamContext?.setIsLoading(true);
    TeamAPI.updateTeamProgress(teamContext?.teamInfo?.id || "", { create: true, member:false, transfer_wallet:false }).then(() => {
      teamContext?.setStep("add-member");
    }).finally(() => {
      teamContext?.setIsLoading(false);
    })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="70%">
        <CreateTeamStep steped={teamContext?.step || null} />
        <Box sx={classes.contentWrap}>
          <Typography variant="h5" mt="42px" mb="24px">
            Organizing Your Wallet
          </Typography>
          <Box sx={classes.walletWrap}>
            <Box p="24px">
              <Typography variant="h6">My Wallet</Typography>
              <Typography variant="h4" mt="20px">
                {formatter.format(employerWallet)} บาท
              </Typography>
            </Box>
          </Box>

          <Box sx={classes.detailWrap}>
            <Typography variant="title1">
              คุณต้องการย้ายเงินจาก "My Wallet (บัญชีส่วนตัว)" ไปยัง "Team
              Wallet (บัญชีของทีม)" หรือไม่ ?
            </Typography>
            <Typography variant="subTitle1">
              หมายเหตุ การย้ายเงินเข้าสู่ Team Wallet
              จะเกิดขึ้นเฉพาะผู้สร้างทีมเท่านั้น
            </Typography>
          </Box>

          <FormControl sx={{ width: "100%", mt: "20px" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              sx={classes.selectWrap}
              onChange={(e) => setTransfer(e.target.value)}
            >
              <div>
                <FormControlLabel
                  value="yes"
                  checked={transfer === "yes"}
                  control={<Radio />}
                  label="ใช่ ฉันต้องการย้ายเงินไปยัง Team Wallet (กระเป๋าเงินของทีม)"
                />
              </div>
              <div>
                <FormControlLabel
                  value="no"
                  checked={transfer === "no"}
                  control={<Radio />}
                  label="ไม่ ฉันต้องการเก็บเงินใน My Wallet (กระเป๋าเงินส่วนตัว)"
                />
              </div>
            </RadioGroup>
          </FormControl>

          <Box sx={classes.buttonWrap}>
            <Button onClick={onGoBack} sx={{ p: "8px 24px" }}>
              <Typography variant="buttonM" mx={10}>
                ย้อนกลับ
              </Typography>
            </Button>
            <Button onClick={onSubmit} sx={classes.buttonSuccess}>
              <Typography variant="buttonM" mx={10}>
                เสร็จสิ้น
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box padding="40px 80px">
            <img
              alt="create-team-success"
              src={imageSuccess}
              style={{ width: "320px", height: "320px" }}
            />
            <Box m="32px 0">
              <Box display="flex" justifyContent="center">
                <Typography variant="h5">สร้างทีมสำเร็จ</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography>ทีม "{teamInfo?.name || ""}" ได้ถูกสร้างเรียบร้อย</Typography>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center">
              <Button onClick={() => history.replace("/team")} fullWidth>
                <Typography variant="buttonM">สร้างทีมสำเร็จ</Typography>
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
