import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  // Grid,
  Radio,
  Paper,
  Drawer,
  Button,
  Slider,
  // Divider,
  Checkbox,
  TextField,
  InputBase,
  FormGroup,
  RadioGroup,
  Typography,
  IconButton,
  FormControl,
  // InputAdornment,
  FormControlLabel,
} from "@mui/material";
// icon and style
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useListingTabFilterStyle as classes } from "../ListingTab.style";
// type and api
import {
  // TRange,
  IListingTabFilter,
  IInfluecerListQueryParams,
} from "../ListingTab.type";
import { AppInformationAPI } from "@api/global";
import { IProvince } from "@api/app-information/type";
// other
import {
  banStatusList,
  // infTearList,
  platformList,
} from "../../dashboard.constants";
import { useHistory, useLocation } from "react-router-dom";

export default function ListingTabFilter(props: IListingTabFilter) {
  const { open, onClose, categoryList } = props;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const banStatus = params.get("status") || "";
  const gender = params.get("gender") || "";
  const search = params.get("q") || "";
  const cate = params.get("cat");
  const categories = cate ? cate.split(",").map(Number) : [];
  const age = params.get("age");
  const ages = age ? age.split(",") : [];
  const province = params.get("prov");
  const provinces = province ? province.split(",").map(Number) : [];

  const paramList: IInfluecerListQueryParams = {
    page: page,
    platform: platform,
    status: banStatus,
    cat: categories,
    gender: gender,
    age: ages.length ? ages : ["0", "100"],
    prov: provinces,
    q: search,
  };

  const [searchLocation, setSearchLocation] = useState("");
  const [provinceList, setProvinceList] = useState<IProvince[]>([]);
  // const [range, setRange] = useState(initRange);
  const [filterParams, setFilterParams] =
    useState<IInfluecerListQueryParams>(paramList);

  useEffect(() => {
    let isMounted = true;
    const getProvinceList = () => {
      AppInformationAPI.getProvinceList()
        .then((response) => {
          if (isMounted) {
            setProvinceList(response);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.log("get province list error : ", err);
          }
        });
    };
    getProvinceList();

    return () => {
      isMounted = false;
    };
  }, []);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      onClose(open);
    };

  const handleSearchLocationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setSearchLocation(value);
  };

  const handleRangeSlideChange = (newValue: number | number[]) => {
    const value = newValue as number[];
    let data = { ...filterParams };
    data.age = value.map((num) => num.toString());
    setFilterParams(data);
  };

  const handleRangeInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const value = e.target.value;
    let data = { ...filterParams };
    if (index === 0) {
      const ageCrr = data.age?.[1];
      data.age = [value, ageCrr || "100"];
      console.log("data", data);
      setFilterParams(data);
    } else if (index === 1) {
      const ageCrr = data.age?.[1];
      data.age = [ageCrr || "0", value];
      setFilterParams(data);
    }
  };

  const handleSelectCategory = (value: number) => {
    const category = [...categoryList.map((cat) => cat.id)];
    if (category.includes(value)) {
      if (filterParams.cat?.includes(value)) {
        setFilterParams((prev) => {
          return {
            ...prev,
            cat: (filterParams.cat || []).filter((cat) => cat !== value),
          };
        });
      } else {
        setFilterParams((prev) => {
          return {
            ...prev,
            cat: [
              ...(prev?.cat || []),
              ...category.filter((cat) => cat === value),
            ],
          };
        });
      }
    } else {
      setFilterParams((prev) => {
        return { ...prev, cat: [] };
      });
    }
  };

  const handleSelectProvince = (value: number) => {
    const province = [...provinceList.map((cat) => cat.id)];
    if (province.includes(value)) {
      if (filterParams.prov?.includes(value)) {
        setFilterParams((prev) => {
          return {
            ...prev,
            prov: (filterParams.prov || []).filter((prov) => prov !== value),
          };
        });
      } else {
        setFilterParams((prev) => {
          return {
            ...prev,
            prov: [
              ...(prev?.prov || []),
              ...province.filter((prov) => prov === value),
            ],
          };
        });
      }
    } else {
      setFilterParams((prev) => {
        return { ...prev, prov: [] };
      });
    }
  };

  const filterProvinceList = provinceList.filter((prov) =>
    prov.name_en.toLowerCase().includes(searchLocation.toLowerCase())
  );

  const onClearFilter = () => {
    history.replace('/influencers-dashboard?tab=list')
    onClose(false);
  };

  const onApplyFilter = () => {
    const params: IInfluecerListQueryParams = {
      ...paramList, ...filterParams, page: 1,
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof IInfluecerListQueryParams]}`)
      .join("&");
    history.replace(`/influencers-dashboard?tab=list&${queryString}`);
    onClose(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={classes.drawer}
    >
      <Box sx={classes.drawerCon}>
        <Box sx={classes.filterTitle}>
          <Typography variant="h6" color="primary" ml={3.5}>
            Filter
          </Typography>
          <ClearOutlinedIcon onClick={() => onClose(false)} />
        </Box>
        <Box m="15px 5px 70px 5px">
          <Typography variant="h6">Influencer</Typography>
          <Box mt="5px">
            <Typography variant="title2">Social Media Platforms</Typography>
            <FormControl sx={{ ml: "10px" }}>
              <RadioGroup sx={{ minHeight: "90px" }}>
                <div>
                  {platformList.map((platform, index) => (
                    <FormControlLabel
                      key={index}
                      value={platform.value}
                      control={
                        <Radio
                          value={platform.value}
                          checked={platform.value === filterParams.platform}
                          onChange={(e) =>
                            setFilterParams((prev) => {
                              return { ...prev, platform: e.target.value };
                            })
                          }
                          inputProps={{ "aria-label": platform.label }}
                        />
                      }
                      label={platform.label}
                      sx={{ width: "48%" }}
                    />
                  ))}
                </div>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt="10px">
            <Typography variant="title2">Status</Typography>
            <FormControl sx={{ ml: "10px" }}>
              <RadioGroup sx={{ minHeight: "90px" }}>
                <div>
                  {banStatusList.map((status, index) => (
                    <FormControlLabel
                      key={index}
                      value={status.value}
                      checked={status.value === filterParams.status}
                      control={
                        <Radio
                          value={status.value}
                          onChange={(e) =>
                            setFilterParams((prev) => {
                              return { ...prev, status: e.target.value };
                            })
                          }
                          inputProps={{ "aria-label": status.label }}
                        />
                      }
                      label={status.label}
                      sx={{ width: "48%" }}
                    />
                  ))}
                </div>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt="10px">
            <Typography variant="title2">Category</Typography>
            <FormGroup>
              <Box sx={{ display: "flex", flexWrap: "wrap", ml: "10px" }}>
                {categoryList.map((cat, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        size="small"
                        value={cat.id}
                        onChange={() => handleSelectCategory(cat.id)}
                        checked={filterParams.cat?.includes(cat.id)}
                      />
                    }
                    label={
                      <Typography
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {cat.name_en}
                      </Typography>
                    }
                    sx={{ width: "48%" }}
                  />
                ))}
              </Box>
            </FormGroup>
          </Box>
          <Box mt="10px">
            <Typography variant="title2">Influencer Gender</Typography>
            <Box display="flex" columnGap="8px" mt="10px">
              {[
                { label: "All", value: "" },
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Lqbtq+", value: "lqbtq+" },
              ].map((gender, index) => (
                <Box
                  key={index}
                  sx={{
                    border: `1px solid ${
                      filterParams.gender === gender.value
                        ? "#8C499C"
                        : "#E0E0E0"
                    } `,
                    borderRadius: "50px",
                    p: "4px 16px 4px 16px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setFilterParams((prev) => {
                      return { ...prev, gender: gender.value };
                    })
                  }
                >
                  <Typography>{gender.label}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box mt="15px">
            <Typography variant="title2">Influencer Age</Typography>
            <Box mt="5px" mx="15px">
              <Slider
                value={(filterParams.age || []).map((age) => Number(age))}
                defaultValue={[10, 30]}
                getAriaLabel={(index) => (index === 0 ? "Min" : "Max")}
                valueLabelDisplay="auto"
                onChange={(_e, newValue) => handleRangeSlideChange(newValue)}
              />
            </Box>
            <Box display="flex" columnGap="50px" mt="5px" mx="15px">
              {[...Array(2)].map((_, index) => (
                <TextField
                  key={index}
                  value={filterParams.age?.[index]}
                  sx={classes.textField}
                  variant="outlined"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => {
                    if (Number(e.target.value) > 100) return;
                    handleRangeInputChange(e, index);
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box mt="15px">
            <Typography variant="title2">Location</Typography>
            <Box mt="5px">
              <Paper sx={classes.searchLocation}>
                <InputBase
                  sx={{ ml: 4, flex: 1, borderRadius: "50px" }}
                  placeholder="search"
                  inputProps={{
                    "aria-label": "addmin-console-search-earning",
                  }}
                  value={searchLocation}
                  onChange={handleSearchLocationChange}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <Icon className="ico-hu-search" />
                </IconButton>
              </Paper>
            </Box>
            <FormGroup sx={{ mt: "10px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", ml: "10px" }}>
                {filterProvinceList.slice(0, 10).map((prov, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        size="small"
                        value={prov.id}
                        onChange={() => handleSelectProvince(prov.id)}
                        checked={filterParams.prov?.includes(prov.id)}
                      />
                    }
                    label={
                      <Typography
                        textTransform="capitalize"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {prov.name_en}
                      </Typography>
                    }
                    sx={{ width: "48%" }}
                  />
                ))}
              </Box>
            </FormGroup>
          </Box>
          {/* <Box mt="15px">
            <Typography variant="title2">Influencer Age</Typography>
            <Box mt="5px" mx="15px">
              <Slider
                value={range.tear.map((t) => Number(t))}
                defaultValue={[1000, 10000]}
                getAriaLabel={(index) => (index === 0 ? "Min" : "Max")}
                valueLabelDisplay="auto"
                onChange={(_e, newValue) =>
                  handleRangeSlideChange(newValue, "tear")
                }
                min={1000}
                max={100000}
              />
            </Box>
            <Box display="flex" columnGap="50px" mt="5px" mx="15px">
              {[...Array(2)].map((_, index) => (
                <TextField
                  key={index}
                  value={range.tear[index]}
                  sx={classes.textField}
                  variant="outlined"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => handleRangeInputChange(e, index, "tear")}
                />
              ))}
            </Box>
            <Box sx={{ ml: "5px", mt: "10px" }}>
              {infTearList.map((item, index) => (
                <Grid container key={index}>
                  <Grid item xs={4}>
                    <Box display="flex" alignItems="center">
                      <Radio
                        size="small"
                        onChange={() => {}}
                        value={item.value}
                      />
                      <Typography>{item.label}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box display="flex" alignItems="center" height="100%">
                      {item.endRange ? (
                        <Typography>
                          {item.startRange} - {item.endRange}
                        </Typography>
                      ) : (
                        <Typography>{item.startRange} +</Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
          <Box mt="15px">
            <Typography variant="title2">Est. Post Price</Typography>
            <Box mt="5px" mx="15px">
              <Slider
                value={range.post_price.map((p) => Number(p))}
                defaultValue={[1000, 10000]}
                getAriaLabel={(index) => (index === 0 ? "Min" : "Max")}
                valueLabelDisplay="auto"
                onChange={(_e, newValue) =>
                  handleRangeSlideChange(newValue, "post_price")
                }
                min={1000}
                max={100000}
              />
            </Box>
            <Box display="flex" columnGap="50px" mt="5px" mx="15px">
              {[...Array(2)].map((_, index) => (
                <TextField
                  key={index}
                  value={range.post_price[index]}
                  sx={classes.textField}
                  variant="outlined"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) =>
                    handleRangeInputChange(e, index, "post_price")
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">THB</InputAdornment>
                    ),
                  }}
                />
              ))}
            </Box>
          </Box> */}
          {/* <Divider sx={{ my: "20px" }} />
          <Typography variant="h6">Performance</Typography>
          <Box mt="15px">
            <Typography variant="title2">Engagement Rate</Typography>
            <Box mt="5px" mx="15px">
              <Slider
                value={range.eng_rate.map((e) => Number(e))}
                defaultValue={[0, 100]}
                getAriaLabel={(index) => (index === 0 ? "Min" : "Max")}
                valueLabelDisplay="auto"
                onChange={(_e, newValue) =>
                  handleRangeSlideChange(newValue, "eng_rate")
                }
                min={0}
                max={100}
              />
            </Box>
            <Box display="flex" columnGap="50px" mt="5px" mx="15px">
              {[...Array(2)].map((_, index) => (
                <TextField
                  key={index}
                  value={range.eng_rate[index]}
                  sx={classes.textField}
                  variant="outlined"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => handleRangeInputChange(e, index, "eng_rate")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              ))}
            </Box>
          </Box>
          <Divider sx={{ my: "20px" }} />
          <Typography variant="h6">Target Audience</Typography>
          <Box mt="10px">
            <Typography variant="title2">Audience Gender</Typography>
            <Box display="flex" columnGap="8px" mt="10px">
              {["all", "male", "female", "lqbtq+"].map((gender, index) => (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "50px",
                    p: "4px 16px 4px 16px",
                    cursor: "pointer",
                  }}
                >
                  <Typography textTransform="capitalize">{gender}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box mt="15px">
            <Typography variant="title2">Audience Age</Typography>
            <Box mt="5px" mx="15px">
              <Slider
                value={range.audience_age.map((age) => Number(age))}
                defaultValue={[10, 30]}
                getAriaLabel={(index) => (index === 0 ? "Min" : "Max")}
                valueLabelDisplay="auto"
                onChange={(_e, newValue) =>
                  handleRangeSlideChange(newValue, "audience_age")
                }
              />
            </Box>
            <Box display="flex" columnGap="50px" mt="5px" mx="15px">
              {[...Array(2)].map((_, index) => (
                <TextField
                  key={index}
                  value={range.audience_age[index]}
                  sx={classes.textField}
                  variant="outlined"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) =>
                    handleRangeInputChange(e, index, "audience_age")
                  }
                />
              ))}
            </Box>
          </Box>
          <Box mt="15px">
            <Typography variant="title2">Audience Location</Typography>
            <Box mt="5px">
              <Paper sx={classes.searchLocation}>
                <InputBase
                  sx={{ ml: 4, flex: 1, borderRadius: "50px" }}
                  placeholder="search"
                  inputProps={{
                    "aria-label": "addmin-console-search-earning",
                  }}
                  value={searchLocation}
                  onChange={handleSearchLocationChange}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <Icon className="ico-hu-search" />
                </IconButton>
              </Paper>
            </Box>
            <FormGroup sx={{ mt: "10px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", ml: "10px" }}>
                {filterProvinceList.slice(0, 10).map((province, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox size="small" />}
                    label={
                      <Typography
                        textTransform="capitalize"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {province.name_en.toLowerCase()}
                      </Typography>
                    }
                    sx={{ width: "48%" }}
                  />
                ))}
              </Box>
            </FormGroup>
          </Box> */}
        </Box>
        <Box sx={classes.filterBottom}>
          <Typography onClick={onClearFilter} ml={3}>
            Clear All
          </Typography>
          <Button
            variant="contained"
            onClick={onApplyFilter}
            sx={classes.applyBtn}
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
