// mui and style
import {
  Box,
  Grid,
  Icon,
  Stack,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useInfluencerSocialDetailStyle as classes } from "../InfluencerSocial.style";
// type and context
import { TPlatform } from "../InfluencerSocial.type";
import { ISocialAccountRes } from "@api/influencer/type";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// util
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint as setVariant } from "@utils/helpers/setValueByBreakpoint.helpers";

export default function OverviewTimeline(props: { platform: TPlatform }) {
  const { platform } = props;

  const bp = useBreakpoint();
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const influencerSocial = useAppSelector(selectInfluencerSocial);
  const socialnfo = (influencerSocial?.[platform] || [])[0];
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialnfo?.social_account || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  let totalEng = Math.round((accountInfo?.engagement_rate || 0) * 100) / 100;

  return (
    <Box sx={classes.container}>
      <div>
        <Typography variant="body1" fontWeight="bold">
          Profile Overview
        </Typography>
        <Box sx={classes.boxOverviewMain}>
          <Box sx={classes.overviewBox}>
            <Stack
              sx={classes.overviewStack}
              style={{ borderRight: "1px solid #E5E5E5" }}
            >
              <Typography
                variant={setVariant(bp, "h6", "h6", "h6", "h5")}
                sx={classes.textOverviewTimeline}
              >
                {totalEng}%
              </Typography>
              <Typography
                variant={setVariant(
                  bp,
                  "subTitle2",
                  "subTitle2",
                  "subTitle2",
                  "body1"
                )}
                color="common.black"
              >
                {mdScreen ? "EGR" : "Engagement Rate"}
              </Typography>
            </Stack>
            <Stack sx={classes.overviewStack}>
              <Typography
                variant={setVariant(bp, "h6", "h6", "h6", "h5")}
                sx={classes.textOverviewTimeline}
              >
                {accountInfo?.avg_post_per_week}
              </Typography>
              <Typography
                variant={setVariant(
                  bp,
                  "subTitle2",
                  "subTitle2",
                  "subTitle2",
                  "body1"
                )}
                color="common.black"
              >
                Post per week
              </Typography>
            </Stack>
          </Box>
        </Box>
      </div>
      <Box sx={classes.boxAverage}>
        <Typography variant="title2" pl={4}>
          Average on recent posts
        </Typography>
        <Grid container px={4} pt={3}>
          <Grid item xs className="d-flex j-c-center a-i-center">
            {platform === "facebook" ? (
              <Icon className="ico-hu-like" sx={classes.averageIcon} />
            ) : platform === "twitter" ? (
              <Icon className="ico-hu-retweet" sx={classes.averageIcon} />
            ) : platform === "instagram" || platform === "tiktok" ? (
              <Icon className="ico-hu-love" sx={classes.averageIcon} />
            ) : platform === "youtube" ? (
              <Icon className="ico-hu-view" sx={classes.averageIcon} />
            ) : null}
            <Typography
              variant={setVariant(bp, "h6", "h6", "h6", "h5")}
              fontWeight="bold"
              pl={1}
            >
              {platform === "twitter"
                ? numberFormat.format(accountInfo?.avg_share || 0)
                : numberFormat.format(accountInfo?.avg_like || 0)}
            </Typography>
          </Grid>
          <Grid item xs className="d-flex j-c-center a-i-center">
            {platform === "twitter" ? (
              <Icon className="ico-hu-love" sx={classes.averageIcon} />
            ) : platform === "youtube" ? (
              <Icon className="ico-hu-like" sx={classes.averageIcon} />
            ) : (
              <Icon className="ico-hu-comment" sx={classes.averageIcon} />
            )}
            <Typography
              variant={setVariant(bp, "h6", "h6", "h6", "h5")}
              fontWeight="bold"
              pl={1}
            >
              {platform === "twitter" || platform === "youtube"
                ? numberFormat.format(accountInfo?.avg_like || 0)
                : numberFormat.format(accountInfo?.avg_comment || 0)}
            </Typography>
          </Grid>
          <Grid item xs className="d-flex j-c-center a-i-center">
            {platform === "twitter" || platform === "youtube" ? (
              <Icon className="ico-hu-comment" sx={classes.averageIcon} />
            ) : (
              <Icon className="ico-hu-share" sx={classes.averageIcon} />
            )}
            <Typography
              variant={setVariant(bp, "h6", "h6", "h6", "h5")}
              fontWeight="bold"
              pl={1}
            >
              {platform === "twitter" || platform === "youtube"
                ? numberFormat.format(accountInfo?.avg_comment || 0)
                : numberFormat.format(accountInfo?.avg_share || 0)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
