export const useBeforeUpgradePlanStyle = {
  boxTitle: {
    display: "flex",
    alignItems: "center",
    mt: "10px",
  },
  planSelecedBox: {
    mt: "12px",
    width: "488px",
    display: "flex",
    "& button": {
      border: "none",
      fontSize: "16px",
      "&:hover": { border: "none" },
    },
    border: "1px solid #8C499C",
    p: "2px",
    borderRadius: "50px",
    bgcolor: "#fff",
  },
  numTeamBox: {
    mt: "12px",
    width: "180px",
    height: "40px",
    border: "1px solid #8C499C",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxTable: {
    bgcolor: "#fff",
    borderRadius: "20px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
    p: "20px",
    mt: "32px",
  },
  tContainer: {
    boxShadow: "none",
    borderRadius: 0,
  },
  tHRow: {
    "& th, tr": { height: "64px", p: 0 },
  },
  tBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& td, tr": { p: 2.5 },
  },
  tBTRow: {
    "& td, & th": {
      height: "60px",
      py: 0,
    },
  },
  tBRow: {
    "& td, & th": {
      height: "80px",
      py: 0,
    },
  },
  tRowIsCurrent: {
    bgcolor: "#F5EEFF",
    color: "#8C499C",
    cursor: "pointer",
    borderRadius: "12px 12px 0px 0px",
    borderBottom: "1px solid #8C499C",
  },
  tRowIsNotCurrent: {
    cursor: "pointer",
    borderRadius: "12px 12px 0px 0px",
  },
  platformIcon: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 2,
  },
  tBRowBottom: {
    height: "80px",
    "& td": {
      border: "none",
    },
  },
  upgradBtn: {
    fontSize: "16px",
    "&:not(.Mui-disabled)": {
      border: "2px solid #8C499C",
    },
  },
};

export const useCurrentPlanSummaryStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  conentWrap: {
    p: "24px",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
  },
  currentTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > span": {
      bgcolor: "#E7FFE8",
      borderRadius: "50px",
      px: "15px",
      py: "5px",
      color: "#4CAF50",
    },
  },
  firstCurrentWrap: {
    mt: "24px",
    display: "flex",
    columnGap: "12px",
  },
  firstCurrItem1: {
    border: "1px solid #E0E0E0",
    p: "16px",
    width: "100%",
    borderRadius: "8px",
    "& > div": {
      display: "inline-flex",
      borderRadius: "4px",
      p: "10px",
      alignItems: "center",
      bgcolor: "#E5F6FF",
      color: "#0077E4",
    },
  },
  firstCurrItem2: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    width: "100%",
    "& > div": {
      border: "1px solid #E0E0E0",
      p: "16px",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
    },
  },
  secondCurrentWrap: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    mt: "24px",
  },
  thirdCurrentWrap: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    p: "6px",
    my: "12px",
  },
  forthCurrentWrap: {
    borderBottom: "1px solid #E0E0E0",
    pb: "24px",
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      mb: "8px",
    },
  },
  fifthCurrentWrap: {
    mt: "24px",
    display: "flex",
    columnGap: "8px",
    "& button": {
      fontSize: "16px",
      whiteSpace:"nowrap"
    },
    "& button:first-of-type": {
      width: "40%",
    },
    "& button:nth-of-type(2), button:nth-of-type(3)": {
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};

export const useCurrentPlanListStyle = {
  con: {
    p: "24px",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
  },
  tableCon: {
    borderRadius: 0,
    boxShadow: "none",
  },
  thRow: {
    "& th, tr": { height: "64px", p: 0 },
  },
  thd: {
    bgcolor: "#E5F6FF",
    color: "#0077E4",
    borderRadius: "12px 12px 0 0",
    borderBottom: "1px solid #0077E4",
  },
  tb: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& td, tr": { p: 2.5 },
  },
  tbr: {
    "& td, & th": {
      height: "80px",
      py: 0,
    },
  },
  platform: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 2,
  },
};

export const useChangePlanDialogStyle = {
  dialog: {
    "& .css-1q2uuof-MuiPaper-root-MuiDialog-paper,.css-x29yx8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    overflowX: "initial",
  },
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "92%",
    mt: "10px",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
  contentDialog: {
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
    width: "92%",
  },
  contentBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: "16px",
  },
  planListBox: {
    border: "1px solid #E0E0E0",
    p: "24px",
    borderRadius: "8px",
    mt: "16px",
  },
  titleBox: {
    display: "inline-flex",
    color: "#0077E4",
    bgcolor: "#E5F6FF",
    py: "6px",
    px: "10px",
    borderRadius: "4px",
  },
  totalBox: {
    borderBottom: "1px solid #E0E0E0",
    pb: "16px",
    mb: "16px",
    mt: "10px",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: 2,
    "& > button:last-of-type": { border: "none" },
  },
};

export const usePaymentCompletedDialogStyle = {
  checkIcon: {
    width: "140px",
    height: "140px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wallet: {
    display: "flex",
    justifyContent: "space-between",
    py: "24px",
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    my: "20px",
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    "& > button": {
      width: "240px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};
