import { hPurple } from "@theme/index";
import { useMultiInputImage } from "@components/my-jobs/jobs-detail/jobs-tab/JobsTab.style";

export const useCampaignOverviewStyle = {
  organization: {
    width: "100%",
    height: "40px",
    borderRadius: "50px",
    bgcolor: "#F5F6F8",
    display: "flex",
    alignItems: "center",
  },
  orgLogo: {
    width: "100px",
    height: "100px",
    borderRadius: "8px",
  },
  companyLogo: {
    "& .label-upload": {
      border: "1.5px solid #E0E0E0",
      bgcolor: "#fff",
      borderRadius: "8px",
    },
  },
  companyImg: {
    "& img": {
      width: "100px",
      height: "100px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  campOverRadio1Item: {
    "& p:first-of-type": { display: "none" },
  },
  optionRadio: {
    "& .css-m5f4gq-MuiTypography-root": {
      fontSize: "14px",
    },
    "& > label": {
      "& > span:last-of-type": {
        fontSize: "14px",
      },
    },
  },
  optionLabelRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
  optionRadioErr: {
    color: "red",
    fontSize: "12px",
    ml: "12px",
  },
};

export const useCampaignBriefDetailStyle = {
  plateform: {
    "& .select-plateform + p": { mt: "12px", mb: "-10px" },
  },
  doOrEmphasisTextBox: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    mt: "12px",
    border: "1px solid #F5F6F8",
    position: "relative",
    "& p:first-of-type": {
      padding: "12px 0 0 12px",
      fontWeight: "500",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  copyIcon: {
    position: "absolute",
    top: "168px",
    right: "10px",
    width: "32px",
    height: "32px",
    bgcolor: "#fff",
  },
};

export const useCampaignTargetAudience = {
  ageTitle: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "6px",
      ml: "10px",
    },
    "& p": {
      ml: "8px",
      fontSize: "14px",
    },
  },
};

export const useCampaignBudgetAndRewardStyle = {
  eachInflue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    bgcolor: "#E0E0E0",
    borderRadius: "50px",
    height: "40px",
    px: "15px",
    mt: "10px",
  },
  rewardOpen: {
    display: "flex",
    alignItems: "center",
    mt: "5px",
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      mr: "8px",
      color: "#8C499C",
    },
    "& p": { fontSize: "14px" },
  },
  formControlLabel: {
    "& .css-m5f4gq-MuiTypography-root": { fontSize: "14px" },
    "& .MuiSvgIcon-root": { fontSize: "18px" },
  },
  compensation: {
    mt: "9px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  productAndService: {
    border: "1px solid #8C499C",
    borderRadius: "8px",
    width: "296px",
    height: "88px",
    alignItems: "center",
    position: "relative",
    "& div:first-of-type": {
      borderRadius: "4px",
      width: "64px",
      height: "64px",
      ml: "12px",
    },
  },
  productAndServiceheader: {
    ml: "8px",
    whiteSpace: "normal",
    "& p:first-of-type": {
      fontSize: "16px",
      mb: "5px",
      overflow: "hidden",
      width: "170px",
      textOverflow: "ellipsis",
    },
    "& p:last-of-type": {
      fontSize: "12px",
      color: "#5C5C5C",
      width: "170px",
      whiteSpace: "normal",
      wordBreak: "break-all",
      maxHeight: "35px",
      textOverflow: "ellipsis",
      display: "-webkit-box !important",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
  },
  productAndServiceIcon: {
    top: 0,
    right: "5px",
    position: "absolute",
    svg: {
      width: "24px",
      height: "24px",
    },
  },
  addProductAndServiceButton: {
    mt: "20px",
    border: "none",
    backgroundColor: "#EBDFFF",
    color: "#8C499C",
    fontWeight: "500",
    "&:hover": {
      border: "none",
      color: "#ffff",
    },
  },
};

export const useRewardToInfluencerStyle = {
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: "8px",
      right: "8px",
    },
  },
  specifyingProductDetails: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    height: "140px",
    "& p:first-of-type": {
      pt: "8px",
      pl: "12px",
      color: "#8C499C",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  dialogAction: {
    display: "flex",
    justifyContent: "center",
    mt: "-30px",
    mb: "10px",
    "& button": {
      border: "none",
      "&:hover": { border: "none" },
      width: "212px",
    },
  },
};

export const useTermsAndConditionStyle = {
  IconButton: {
    width: "25px",
    height: "25px",
  },
  textfield: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "20px",
    },
  },
  textExample: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    mt: "12px",
    border: "1px solid #F5F6F8",
    position: "relative",
    "& p:first-of-type": {
      color: "#8C499C",
      padding: "12px 0 0 12px",
      fontWeight: "500",
    },
    "& ul": {
      fontSize: "14px",
    },
  },
  coppyIcon: {
    position: "absolute",
    top: "150px",
    right: "10px",
    width: "32px",
    height: "32px",
    bgcolor: "#fff",
  },
  addIcon: {
    width: "23px",
    height: "23px",
    borderRadius: "50%",
    bgcolor: "#4CAF50",
    color: "#fff",
  },
  addFieldBtn: {
    width: "100%",
    borderRadius: "25px",
    backgroundColor: "primary.main",
    "&:hover": {
      backgroundColor: "primary.300",
    },
  },
};

export const useTimelineStyle = {
  timelineLeft: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    border: "3px solid #EBDFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#fff",
  },
  timelineRigthBox: {
    minHeight: "90px",
    ml: "20px",
  },
  stepLabelIconActive: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#fff",
    color: "#8C499C",
    border: "2px solid #EBDFFF",
  },
  lineIsActive: {
    ml: "20px",
    borderLeft: "3px solid #EBDFFF",
    pb: 3,
  },
  announceDateBox: {
    height: "94%",
    "& .css-zryk4f-MuiFormControl-root-MuiTextField-root": {
      width: "47%",
    },
  },
  timelineDevider: {
    position: "absolute",
    top: 0,
    left: "19px",
    height: "830%",
    zIndex: -1,
    mt: 1,
    borderWidth: "2px",
    borderColor: "#EBDFFF",
  },
  announceDate: {
    border: "2px dashed #EBDFFF",
    borderRadius: "8px",
    p: "20px",
    ml: "20px",
  },
  draftConnect: {
    ml: "23px",
    borderLeft: "3px solid #ECECEC",
  },
  dividerTimeline: {
    height: "900px",
    position: "absolute",
    borderColor: "#ECECEC",
    borderWidth: "1.5px",
  },
  draftBox: {
    borderRadius: "50px",
    width: "236px",
    height: "40px",
    bgcolor: "#F5F6F8",
    display: "flex",
    alignItems: "center",
  },
  completedDate: {
    borderRadius: "50px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    width: "250px",
    bgcolor: "#F5F6F8",
    "& > p": {
      color: "#5C5C5C",
      ml: "10px",
      fontSize: "14px",
    },
  },
  closeDate: {
    width: "48%",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  },
  dateDisabled: {
    width: "42%",
    height: "40px",
    borderRadius: "50px",
    px: "13px",
    bgcolor: "#F5F6F8",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export const useProductDetailsStyle = {
  prodImg: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    ml: "10px",
    "& svg": { fontSize: "5px", mr: "8px" },
  },
  exmpDescBox: {
    height: "128px",
    borderRadius: "12px",
    bgcolor: "#F5F6F8",
    mt: "12px",
    p: "16px",
  },
  exmpDesc: {
    display: "flex",
    "& li": {
      color: "#5C5C5C",
      fontSize: "14px",
    },
  },
};

export const useReturnProductStyle = {
  checkboxIcon: {
    "& .MuiSvgIcon-root": { fontSize: "16px" },
  },
  productDetailsLabel: {
    ml: "5px",
    "& .MuiTypography-root": { fontSize: "14px" },
  },
  address: {
    minHeight: "128px",
    borderRadius: "12px",
    bgcolor: "#F5F6F8",
    padding: "16px",
    "& p:first-of-type": { color: "#8C499C" },
    "& p:nth-of-type(2)": { mt: "8px" },
    "& p:last-of-type": { color: "#5C5C5C", mt: "5px" },
  },
  nameAndPhone: {
    "& .MuiSvgIcon-root": {
      fontSize: "5px",
      mr: "8px",
      ml: "8px",
    },
    display: "flex",
    alignItems: "center",
  },
  postcode: {
    height: "40px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    pl: "15px",
    fontSize: "14px",
    bgcolor: "#dddddd",
  },
};

export const useMultiImagesInputStyle = {
  labelUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    background: "#fff",
    width: "100px",
    height: "100px",
    border: "2px dashed #9E9E9E",
    boxSizing: "border-box",
    borderRadius: "4px",
    color: " gray",
    position: "relative",
  },
  imageDataBox: {
    "& > button": {
      bgcolor: "black",
      color: "#fff",
      "&:hover": {
        bgcolor: "black",
      },
      zIndex: 0,
    },
  },
  removeImgBtn: {
    top: "-6px",
    right: "-7px",
    maxWidth: "20px",
    minWidth: "20px",
    maxHeight: "20px",
    minHeight: "20px",
    position: "absolute",
  },
  imageDataList: {
    display: "inline-block",
    cursor: "pointer",
    "& > img": {
      height: "100px",
      width: "100px",
      objectFit: "cover",
      borderRadius: "8px",
    },
  },
};

export const useImageInputStyle = {
  removeImgBtn: {
    position: "absolute",
    color: "black",
    width: "25px",
    height: "25px",
    bgcolor: "#000",
    top: "-6px",
    right: "-7px",
    maxWidth: "20px",
    minWidth: "20px",
    maxHeight: "20px",
    minHeight: "20px",
    "&:hover": {
      bgcolor: "#a2a2a2",
    },
    zIndex: 0,
  },
  labelUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    bgcolor: "#fff",
    width: "100px",
    height: "100px",
    border: "2px dashed gray",
    boxSizing: "border-box",
    borderRadius: "4px",
    color: "gray",
  },
  image: {
    cursor: "pointer",
    "& > img": {
      height: "100px",
      width: "100px",
      objectFit: "cover",
      borderRadius: "10px",
    },
  },
};

export const useSelectMultiCateStyle = {
  menu: {
    color: "common.black",
    border: "2px solid",
    borderColor: "primary.main",
    overflow: "auto",
    maxHeight: "180px",
  },
  menuItem: {
    alignItems: "center",
    paddingLeft: "8px",
    "& svg": {
      fontSize: "20px",
      mr: 1,
    },
    "&.Mui-selected": {
      color: "primary.main",
      bgcolor: hPurple[100],
    },
  },
  chip: {
    height: "26px",
  },
  selectRender: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1.5,
    marginBlock: "0px",
    paddingBlock: "0px",
  },
};

export const useMultiVideosInputStyle = {
  videosItem: {
    width: "100px",
    "& > button": {
      bgcolor: "black",
      minHeight: "20px",
      maxHeight: "20px",
      maxWidth: "20px",
      color: "#fff",
      "&:hover": {
        bgcolor: "black",
      },
      zIndex: 9,
      top: -9,
      right: -9,
      position: "absolute",
    },
    "& video": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      borderRadius: "8px",
      cursor: "pointer",
    },
  },
  removeBtn: useMultiInputImage.removeBtn,
  iconPlayVideo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
  },
  labelUpload: {
    ...useMultiInputImage.labelUpload,
    width: "100px",
    height: "100px",
    border: "2px dashed #9E9E9E",
    borderRadius: "4px",
  },
  uploadBtn: useMultiInputImage.uploadBtn,
};

export const useSelectGenderLocationStyle = {
  container: {
    position: "relative",
    mb: "10px",
    "& .css-26l3qy-menu": {
      mt: "-1px",
      borderRadius: "0 0 15px 15px",
      border: "1px solid #8C499C",
      position: "absolute",
      "& div:last-of-type": {
        borderRadius: "0 0 13.7px 13.7px",
        border: "1px solid #fff",
      },
    },
  },
};

export const useDateInputStyle = {
  container: {
    "& .react-datepicker__day--disabled": {
      color: "#999",
      borderRadius: "50%",
    },
    "& .react-datepicker__day": {
      borderRadius: "50%",
    },
    "& .react-datepicker__day--selected": {
      bgcolor: "primary.main",
      color: "#fff",
    },
    "& .react-datepicker__day--keyboard-selected": {
      bgcolor: "#ebdfff",
    },
  },
};

export const useDateRangeInputStyle = {
  container: {
    "& .react-datepicker__day--disabled": {
      color: "#999",
      borderRadius: "50%",
    },
    "& .react-datepicker__day": {
      borderRadius: "50%",
      width: "27px",
      height: "27px",
    },
    "& .react-datepicker__day--selected": {
      bgcolor: "primary.main",
      color: "#fff",
    },
    "& .react-datepicker__day--in-range:not(.react-datepicker__day--selected), .react-datepicker__day--keyboard-selected":
      {
        bgcolor: "#ebdfff",
      },
  },
};

export const useTimelineOverdueStyle = {
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  discBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: "10px",
  },
  img: {
    width: {
      xs: "250px",
      sm: "320px",
    },
    height: {
      xs: "250px",
      sm: "320px",
    },
    borderRadius: 0,
    mt: {
      sm: "25px",
    },
  },
  btnBox: {
    mb: "15px",
    minHeight: "40px",
    justifyContent: "center",
    "& > button": { width: "100%", maxWidth: "282px" },
    "& > button:last-of-type": {
      border: "1px solid #8C499C",
    },
  },
};
