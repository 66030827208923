// mui and icon
import { Box, Grid, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// chartJs and type
import {
  regionStatsOptionsChart,
  provinceRankingOptionsChart,
} from "../../OverviewTab.chart";
import { Chart, Pie } from "react-chartjs-2";
import { IInfluencerDistributionStatsProps } from "../../OverviewTab.type";

export default function InfluencerDistributionStats(
  props: IInfluencerDistributionStatsProps
) {
  const { areaDist } = props;
  const regionList = areaDist?.pi_chart || [];
  const provinceRankingList = areaDist?.hist_chart || [];

  const regionStatsData = {
    labels: regionList.map((reg) => reg.label),
    datasets: [
      {
        data: regionList.map((reg) => reg.value.number),
        backgroundColor: regionList.map((reg) => reg.color),
        borderWidth: 1,
        borderColor: "#fff",
        color: "#fff",
      },
    ],
  };

  const provinceRankingData = {
    labels: provinceRankingList.map((pro) => pro.label),
    datasets: [
      {
        data: provinceRankingList.map((pro) => pro.value),
        backgroundColor: "#893DBD",
        borderWidth: 1,
        borderColor: "#fff",
        color: "#fff",
        barPercentage: 0.5,
      },
    ],
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <Box width="100%">
          <Typography variant="title1">ภูมิภาค</Typography>
          <Box display="flex" flexDirection="column" alignItems="center" my={5}>
            <Box width="200px" height="200px" my="24px">
              <Pie options={regionStatsOptionsChart} data={regionStatsData} />
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={4}
              flexWrap="wrap"
              width="80%"
              rowGap={3}
            >
              {regionList.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                >
                  <CircleIcon sx={{ color: item.color, fontSize: "12px" }} />
                  <Typography variant="body2">
                    {item.label?.replace("ภาค", "")}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box width="100%">
          <Typography variant="title1">
            10 จังหวัดแรกที่มีจำนวนสูงสุด
          </Typography>
          <Box width="98%" height="310px" my="30px">
            <Chart
              type="bar"
              options={provinceRankingOptionsChart}
              data={provinceRankingData}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
