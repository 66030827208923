import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Stack,
  Dialog,
  useTheme,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
// icon and style
import Divider from "@mui/material/Divider";
import CancelIcon from "@mui/icons-material/Cancel";
import { InfluencerMyEarningStyles as classes } from "./Earning.style";
// type
import { IInfluencerMyEarningProps } from "./Earning.type";
import { IBankAccountForm } from "@components/influencer-profile/InfluencerProfile.type";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BankAccountFormSchema } from "@components/influencer-profile/InfluencerProfile.shema";
// redux
import { useAppDispatch } from "@store/hook";
import { updateInfluencerBankAccountAsync } from "@slices/InfluencerProfile.slice";
// component
import { initBankAccountForm } from "@components/influencer-profile/influencer-default/BankAccount";
import EditInfluencerBankAccount from "@components/influencer-profile/edit-influencer-profile/EditBankAccount";
// other
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { formatTaxId } from "@utils/helpers/formateNumber.helpers";
import { useHistory } from "react-router-dom";

export default function InfluencerEarning(props: IInfluencerMyEarningProps) {
  const { summary, influencerProfile } = props;
  const [isEditBankAccountOpen, setIsEditBankAccountOpen] = useState(false);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));
  
  const history = useHistory()

  const dispatch = useAppDispatch();
  const bankAccount = influencerProfile?.bank_account;

  const form = useForm<IBankAccountForm>({
    resolver: yupResolver(BankAccountFormSchema),
    defaultValues: initBankAccountForm,
  });

  useEffect(() => {
    if (!bankAccount) return;
    const data = {
      account_name: bankAccount.account_name,
      account_number: bankAccount.account_number,
      bank: bankAccount.bank,
      account_type: bankAccount.account_type,
      tax_id: bankAccount.tax_id,
    };
    form.reset(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccount]);

  const openEditBankAccountDialog = () => {
    if (xsScreen) {
      history.push("/my-profile/mobile/edit-bank-account")
    } else {
      setIsEditBankAccountOpen(true);
    }
  };

  const mapAccountType = (type?: string | null) => {
    switch (type) {
      case "saving":
        return "Savings Account";
      case "current":
        return "Current Account";
      default:
        break;
    }
  };

  const onUpdateBankAccount = (value: IBankAccountForm) => {
    if (!value.bank) return;
    let body = {
      bank_account: {
        account_name: value.account_name,
        account_number: value.account_number,
        bank_id: value.bank.id,
        tax_id: value.tax_id.split("-").join(""),
        account_type: value.account_type,
        id_card_image_url: value.id_card_image_url,
        book_bank_image_url: value.book_bank_image_url,
      },
    };

    dispatch(updateInfluencerBankAccountAsync(body))
      .then(() => {
        setIsEditBankAccountOpen(false);
        SwalCustom.fire({
          icon: "success",
          title: "Updated Bank account success",
          showConfirmButton: false,
          timer: 1600,
        });
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
          timer: 1600,
        });
      });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={classes.totalEarningBox}>
            <Box className="d-flex a-i-center j-c-center" mb={4}>
              <Icon className="ico-hu-money-outline" />
              <Typography variant="h6" ml={2}>
                Total Earning
              </Typography>
            </Box>
            <Box sx={classes.totalEearning}>
              <Typography>
                {summary?.total_earning_amount.toFixed(2)}
              </Typography>
              <span>THB</span>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Stack direction="row" sx={classes.brandsBox}>
            <Box sx={classes.brands}>
              <Box mb={-1}>
                <Icon className="ico-hu-brand-outline" />
                <Typography variant="h6" ml={2}>
                  Brands
                </Typography>
              </Box>
              <Typography variant="h3">{summary?.total_brand}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem sx={classes.brandsLine} />
            <Box sx={classes.brands}>
              <Box mb={-1}>
                <Icon className="ico-hu-job" />
                <Typography variant="h6" ml={2}>
                  Jobs
                </Typography>
              </Box>
              <Typography variant="h3">{summary?.total_job}</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box sx={classes.bankAccountBox} px={5}>
            <div className="d-flex j-c-space-between">
              <Box className="d-flex a-i-center j-c-center" mt={3}>
                <Icon className="ico-hu-bank" color="primary" />
                <Typography variant="title1" ml={2}>
                  บัญชีธนาคาร
                </Typography>
              </Box>
              <Box>
                {influencerProfile && bankAccount && (
                  <Icon
                    className="ico-hu-edit"
                    onClick={openEditBankAccountDialog}
                    color="primary"
                    fontSize="small"
                    sx={{ mt: "15px", cursor: "pointer" }}
                  />
                )}
              </Box>
            </div>
            {bankAccount?.account_name ? (
              <Box sx={{ py: ["3px", "4px"] }}>
                <Typography
                  variant="body1"
                  color="common.black"
                  sx={classes.textSize}
                >
                  {`${bankAccount?.account_name}, ${bankAccount?.account_number}`}
                </Typography>
                <Typography color="common.black" sx={classes.textSize}>
                  {`${bankAccount?.bank?.name_en} ,`}
                  {mapAccountType(bankAccount?.account_type)}
                </Typography>
                <Typography color="common.black" sx={classes.textSize}>
                  {bankAccount?.tax_id
                    ? `Tax ID: ${formatTaxId(bankAccount?.tax_id)}`
                    : ""}
                </Typography>
              </Box>
            ) : (
              <Box pt={4} onClick={openEditBankAccountDialog}>
                <Typography variant="body1" color={"text.secondary"}>
                  + เพิ่มบัญชีธนาคาร
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* dialog */}
      <Dialog
        open={isEditBankAccountOpen}
        onClose={() => setIsEditBankAccountOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={classes.dialog}
      >
        <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="title1">Bank Account</Typography>
          <IconButton onClick={() => setIsEditBankAccountOpen(false)}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.contentDialog}>
          <EditInfluencerBankAccount
            form={form}
            onSubmit={onUpdateBankAccount}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
