import { useState } from "react";
// mui
import {
  Box,
  Icon,
  Paper,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import { useWalletTabStyle as classes } from "./Wallettab.style";
import { TableRowCustom } from "../../../employer-expense/Expense.style";
import { TableCellCustom } from "../../../influencer-earning/Earning.style";
// other
import { ITransactionHistoryList } from "../Member.type";
import hahsuLogo from "@assets/images/logo/hashu-logo512.png";
import TransactionInfoDialog from "./wallet-dialog/TransactionInfo";
import dayjs from "dayjs";

export const payTypeMatchIcon = (type?: string) => {
  return type === "Refund"
    ? "ico-hu-refund"
    : type === "Charge"
    ? "ico-hu-money"
    : type === "Top Up"
    ? "ico-hu-topup"
    : "ico-hu-lock";
};

export const payTypeMatchColor = (type?: string) => {
  return type === "Refund"
    ? "#0077E4"
    : type === "Charge"
    ? "#E30D18"
    : type === "Top Up"
    ? "#4CAF50"
    : "#FB8C00";
};

export default function WalletTransaction(props: {
  transaction: ITransactionHistoryList | null;
}) {
  const { transaction } = props;

  const [openTransaction, setOpenTransaction] = useState(false);
  const [transactionIndex, setTransactionIndex] = useState(0);

  const numberFormat = new Intl.NumberFormat("en", {
    minimumIntegerDigits: 2,
  });

  const onViewTransactionInfo = (index:number) => {
    setTransactionIndex(index);
    setOpenTransaction(true);
  }

  return (
    <>
      <Typography variant="h6">Wallet Transaction History</Typography>
      {transaction?.data.item.length ? (
        <TableContainer component={Paper} sx={{ mt: "20px" }}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                {[
                  "วันที่และเวลา",
                  "ดำเนินการโดย",
                  "รายการ",
                  "ประเภท",
                  "จำนวนเงิน (บาท)",
                  "View",
                ].map((cell, index) => (
                  <TableCellCustom
                    key={index}
                    align={
                      index === 5 ? "center" : index === 4 ? "right" : "left"
                    }
                  >
                    {cell}
                  </TableCellCustom>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {transaction?.data.item.map((row, index) => (
                <TableRowCustom key={index}>
                  <TableCellCustom>
                    <Box>
                      <Typography variant="title1">{row.code}</Typography>
                      <Typography variant="body2">
                        {dayjs(row.created_at).format("DD-MM-YYYY HH:mm")}
                      </Typography>
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={
                          row.managed_by === null
                            ? hahsuLogo
                            : row.managed_by.profile_image_url
                        }
                        sx={{ width: "32px", height: "32px" }}
                      />
                      <Typography ml="12px">
                        {row.managed_by === null
                          ? "HashU"
                          : row.managed_by.fullname}
                      </Typography>
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom>
                    <Typography textTransform="capitalize">
                    {row.description.replace(/[_-]/g, " ")}
                    </Typography>
                  </TableCellCustom>

                  <TableCellCustom>
                    <Box display="flex" alignItems="center" columnGap="8px">
                      <Icon
                        className={payTypeMatchIcon(
                          row.pay_type.title_employer
                        )}
                        sx={{ color: payTypeMatchColor(row.pay_type.title_employer) }}
                      />
                      <Typography>{row.pay_type.title_employer}</Typography>
                    </Box>
                  </TableCellCustom>

                  <TableCellCustom align="right">
                    <Typography
                      variant="title1"
                      fontWeight={500}
                      color={payTypeMatchColor(row.pay_type.title_employer)}
                    >
                      {row.pay_type.title_employer === "Refund"
                        ? "+ "
                        : row.pay_type.title_employer === "Charge"
                        ? "- "
                        : ""}
                      {numberFormat.format(row.amount)}.00
                    </Typography>
                  </TableCellCustom>

                  <TableCellCustom align="center">
                    <IconButton
                      onClick={() => onViewTransactionInfo(index)}
                      sx={{
                        border: "2px solid #8C499C",
                        color: "#8C499C",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Icon className="ico-hu-visability" />
                    </IconButton>
                  </TableCellCustom>
                </TableRowCustom>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box mt="36px" sx={classes.boxNoData}>
          <img
            src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
            alt="no campaign"
          />
          <Box sx={classes.boxDetailNoData}>
            <Typography variant="h5" my="8px">
              ไม่พบผลการค้นหา
            </Typography>
            <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
          </Box>
        </Box>
      )}

      {/* dialog */}
      <TransactionInfoDialog
        open={openTransaction}
        onClose={setOpenTransaction}
        transactionInfo={transaction?.data.item?.[transactionIndex]}
      />
    </>
  );
}
