import { hGrey, hPink, hPurple, hWhite } from "@theme/index";
// chartJs
import {
  Title,
  Legend,
  Filler,
  Tooltip,
  BarElement,
  ArcElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  BarController,
  LineController,
  Chart as ChartJS,
} from "chart.js";

ChartJS.register(
  Title,
  Legend,
  Filler,
  Tooltip,
  BarElement,
  ArcElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  BarController,
  LineController
);

const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

export const regisPieOptionsChart = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: hWhite[100],
      formatter: (value: any, ctx: any) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
          let percentage = ((value / sum) * 100).toFixed(2) + "%";
          return percentage;
        }
      },
    },
  },
};

export const regisBarOptionsChart = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
      position: "bottom" as const,
      labels: {
        usePointStyle: true,
        generateLabels: (chart: any) => {
          return chart.data.datasets.map((dataset: any, _index: number) => ({
            text: dataset.label,
            fillStyle: dataset.backgroundColor,
            strokeStyle: dataset.borderColor,
            pointStyle: "circle",
            hidden: false,
            border: 0,
          }));
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        callback: (val: any, _index: number) => {
          return numberFormat.format(val as number);
        },
      },
    },
  },
};

export const referralStatsBarChart = {
  chartAreaBorder: [
    {
      id: "chartAreaBorder",
      afterDatasetsDraw(chart: any, _args: any, _options: any) {
        const {
          ctx,
          chartArea: { bottom, left, width },
        } = chart;
        ctx.save();
        ctx.strokeStyle = hGrey[800];
        ctx.strokeRect(left - 7, bottom, width + 14, 1);
        ctx.restore();
      },
    },
  ],
  Multioptions: {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      right: {
        beginAtZero: true,
        position: "right" as const,
        title: {
          display: false,
          text: "Males | Females",
        },
        grid: {
          drawOnChartArea: false,
        },
        grace: "500",
        ticks: {
          callback: function (val: any, _index: any, _vals: any) {
            return numberFormat.format(val);
          },
        },
      },
      left: {
        beginAtZero: true,
        position: "left" as const,
        title: {
          display: false,
          text: "Totals",
        },
        grace: "500",
        ticks: {
          callback: function (val: any, _index: any, _vals: any) {
            return numberFormat.format(val);
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom" as const,
        onClick: (_click: any, legendItem: any, legend: any) => {
          const datasets = legend.legendItems.map(
            (dataset: any, _index: number) => {
              return dataset.text;
            }
          );
          const index = datasets.indexOf(legendItem.text);
          if (legend.chart.isDatasetVisible(index) === true) {
            legend.chart.hide(index);
          } else {
            legend.chart.show(index);
          }
        },
        labels: {
          usePointStyle: true,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, _index: number) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              pointStyle: "circle",
              hidden: false,
            }));
          },
        },
      },
    },
  },
  MultiData: {
    datasets: [
      {
        type: "line" as const,
        label: "Females",
        backgroundColor: hPink[300],
        borderColor: hPink[300],
        borderWidth: 2,
        fill: false,
      },
      {
        type: "line" as const,
        label: "Males",
        backgroundColor: hPurple[300],
        borderColor: hPurple[300],
        borderWidth: 2,
        fill: false,
        yAxisID: "right",
      },
      {
        type: "bar" as const,
        label: "All",
        backgroundColor: "#EBDFFF",
        borderRadius: 8,
        yAxisID: "left",
        barPercentage: 0.4,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: hWhite[100],
        formatter: (value: any, ctx: any) => {
          let datasets = ctx.chart.data.datasets;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
            let percentage = ((value / sum) * 100).toFixed(2) + "%";
            return percentage;
          }
        },
      },
    },
  },
  data: {
    labels: ["Female", "Male", "Other"],
    datasets: [
      {
        label: "# of Votes",
        backgroundColor: [hPink[300], hPurple[300], hGrey[500]],
        borderColor: hWhite[100],
        borderWidth: 2,
      },
    ],
  },
};

export const typeOfInfluStatsOptionsChart = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
      labels: {
        usePointStyle: true,
        generateLabels: (chart: any) => {
          return chart.data.labels.map((label: any, index: number) => ({
            text: label,
            fillStyle: chart.data.datasets[0].backgroundColor[index],
            hidden: false,
            index: index,
            pointStyle: "circle",
            borderWidth: 0,
          }));
        },
        boxWidth: 10,
        font: {
          size: 14,
        },
      },
    },
    datalabels: {
      color: hWhite[100],
      formatter: (value: any, ctx: any) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
          let percentage = ((value / sum) * 100).toFixed(2) + "%";
          return percentage;
        }
      },
      font: {
        size: 14,
        weight: 500,
      },
    },
  },
};

export const provinceRankingOptionsChart = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
      position: "bottom" as const,
      onClick: (_click: any, legendItem: any, legend: any) => {
        const datasets = legend.legendItems.map(
          (dataset: any, _index: number) => {
            return dataset.text;
          }
        );
        const index = datasets.indexOf(legendItem.text);
        if (legend.chart.isDatasetVisible(index) === true) {
          legend.chart.hide(index);
        } else {
          legend.chart.show(index);
        }
      },
      labels: {
        usePointStyle: true,
        generateLabels: (chart: any) => {
          return chart.data.datasets.map((dataset: any, _index: number) => ({
            text: dataset.label,
            fillStyle: dataset.backgroundColor,
            strokeStyle: dataset.borderColor,
            pointStyle: "circle",
            hidden: false,
          }));
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        callback: (val: any, _index: number) => {
          return numberFormat.format(val as number);
        },
      },
      grid: {
        borderDash: [5, 5],
      },
    },
  },
};

export const regionStatsOptionsChart = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
      labels: {
        usePointStyle: true,
        generateLabels: (chart: any) => {
          return chart.data.labels.map((label: any, index: number) => ({
            text: label,
            fillStyle: chart.data.datasets[0].backgroundColor[index],
            hidden: false,
            index: index,
            pointStyle: "circle",
            borderWidth: 0,
          }));
        },
        boxWidth: 10,
        font: {
          size: 14,
        },
      },
    },
    datalabels: {
      color: hWhite[100],
      formatter: (value: any, ctx: any) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
          let percentage = ((value / sum) * 100).toFixed(2) + "%";
          return percentage;
        }
      },
    },
  },
};
