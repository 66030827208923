export const useJobBoardStyle = {
  jobboardCon: {
    display: "flex",
    flexDirection: "row",
    mt: "5px",
    mx: {
      xs: "12px",
      sm: 0,
    },
  },
  jobboardCard: {
    cursor: "pointer",
  },
  noCampaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  noCampDiscBox: {
    maxWidth: "400px",
    textAlign: "center",
    mt: "15px",
  },
  paginationCon: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    "& .MuiGrid-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none", md: "flex" },
    },
    "& .css-1gdk4t4-MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root":
      {
        width: {
          xs: "36px",
          md: "40px",
        },
        height: {
          xs: "36px",
          md: "40px",
        },
      },
    bottom: 30,
  },
  headerTabbox: {
    mt: "-30px",
    position: {
      xs: "sticky",
      sm: "inherit",
    },
    top: 0,
    zIndex: 100,
    pb: {
      xs: "20px",
      sm: 0,
    },
  },
  headerTab: {
    background: {
      xs: "linear-gradient(to bottom, #8C499C 77%, #f5f6f8 23%)",
      sm: "linear-gradient(to bottom, #f5f6f8 77%, #f5f6f8 23%)",
    },
    mx: "-10px",
    px: {
      xs: "20px",
      sm: "10px",
    },
    pt: "35px",
    position: "relative",
  },
  myJobTitle: {
    color: ["#fff", "#000"],
    mt: "-10px",
    mb: "25px",
  },
};
