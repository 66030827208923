// mui and style
import { Avatar, Box, Button, Icon, Typography } from "@mui/material";
import { useSyncFacebookStyle as classes } from "../InfluencerSocial.style";
// util
import { SYNC_FACEBOOK } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// asset
import FacebookLogo from "@assets/images/social/f-logo-rgb-blue-512.png";
import { grey } from "@mui/material/colors";

export default function SyncFacebook() {
  const { access_token } = getTokenFromStorage();

  const onLoginFacebook = () => {
    window.location.replace(
      `${SYNC_FACEBOOK}/facebook/login?access_token=${access_token}`
    );
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.syncSocial} py={3}>
        <Avatar
          alt="platform-Logo"
          src={FacebookLogo}
          sx={classes.platformLogo}
        />
      </Box>
      <Box textAlign="center" width="40%" pt={4}>
        <Typography variant="h5" color={grey[700]}>
          Connect with Facebook
        </Typography>
      </Box>
      <Box textAlign="center" py={5} mx="10px">
        <Typography variant="body1">
          Amplify your chance to reach out to more brands and followers
        </Typography>
        <Typography variant="body1">
          Simply add more of your social channels below.
        </Typography>
      </Box>
      <Box py={5}>
        <Button sx={classes.buttonSyncSocial} onClick={onLoginFacebook}>
          <Icon className="ico-hu-facebook" sx={{ mr: ["-24px", "-48px"] }} />
          <Typography variant="buttonM">Continue with Facebook</Typography>
        </Button>
      </Box>
    </Box>
  );
}
