// mui and style
import { Box, Grid, Icon, Stack, Typography } from "@mui/material";
import { useInfluencerSocialDetailStyle as classes } from "../InfluencerSocial.style";
// type
import { ISocialAccountRes } from "@api/influencer/type";
import { TPlatform } from "@components/influencer-social/InfluencerSocial.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// other
import { useLocation } from "react-router-dom";

export default function OverviewTimeline() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const platform = params.get("platform") || "";

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const socialInfo = useAppSelector(selectInfluencerSocial);
  const platformData = (platform: TPlatform | string) => {
    const platformMapping: Record<string, string> = {
      tiktok: "tiktok",
      facebook: "facebook",
      instagram: "instagram",
      twitter: "twitter",
      youtube: "youtube",
    };

    const platformKey = platformMapping[platform];
    return socialInfo?.[platformKey]?.[0];
  };
  const socialAccount = platformData(platform)?.social_account;
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialAccount || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  let totalEng = Math.round((accountInfo?.engagement_rate || 0) * 100) / 100;

  return (
    <Box sx={classes.container}>
      <div>
        <Typography variant="body1" fontWeight="bold">
          Profile Overview
        </Typography>
        <Box sx={classes.overviewMainWrap}>
          <Box sx={classes.overviewWrap}>
            <Stack
              sx={classes.overviewStack}
              style={{ borderRight: "1px solid #E5E5E5" }}
            >
              <Typography variant="h5" sx={classes.textOverviewTimeline}>
                {totalEng}%
              </Typography>
              <Typography color="common.black">Engagement Rate</Typography>
            </Stack>
            <Stack sx={classes.overviewStack}>
              <Typography variant="h5" sx={classes.textOverviewTimeline}>
                {accountInfo?.avg_post_per_week}
              </Typography>
              <Typography color="common.black">Post per week</Typography>
            </Stack>
          </Box>
        </Box>
      </div>
      <Box sx={classes.averageWrap}>
        <Typography variant="title2" pl={4}>
          Average on recent posts
        </Typography>
        <Grid container px={4} pt={3}>
          <Grid item xs className="d-flex j-c-center a-i-center">
            {platform === "facebook" ? (
              <Icon className="ico-hu-like" sx={classes.averageIcon} />
            ) : platform === "twitter" ? (
              <Icon className="ico-hu-retweet" sx={classes.averageIcon} />
            ) : platform === "instagram" || platform === "tiktok" ? (
              <Icon className="ico-hu-love" sx={classes.averageIcon} />
            ) : platform === "youtube" ? (
              <Icon className="ico-hu-view" sx={classes.averageIcon} />
            ) : null}
            <Typography variant="h5" fontWeight="bold" pl={1}>
              {numberFormat.format(
                accountInfo?.[
                  platform === "twitter" ? "avg_share" : "avg_like"
                ] || 0
              )}
            </Typography>
          </Grid>
          <Grid item xs className="d-flex j-c-center a-i-center">
            {platform === "twitter" ? (
              <Icon className="ico-hu-love" sx={classes.averageIcon} />
            ) : platform === "youtube" ? (
              <Icon className="ico-hu-like" sx={classes.averageIcon} />
            ) : (
              <Icon className="ico-hu-comment" sx={classes.averageIcon} />
            )}
            <Typography variant="h5" fontWeight="bold" pl={1}>
              {platform === "twitter" || platform === "youtube"
                ? numberFormat.format(accountInfo?.avg_like || 0)
                : numberFormat.format(accountInfo?.avg_comment || 0)}
            </Typography>
          </Grid>
          <Grid item xs className="d-flex j-c-center a-i-center">
            {platform === "twitter" || platform === "youtube" ? (
              <Icon className="ico-hu-comment" sx={classes.averageIcon} />
            ) : (
              <Icon className="ico-hu-share" sx={classes.averageIcon} />
            )}
            <Typography variant="h5" fontWeight="bold" pl={1}>
              {platform === "twitter" || platform === "youtube"
                ? numberFormat.format(accountInfo?.avg_comment || 0)
                : numberFormat.format(accountInfo?.avg_share || 0)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
