import { useEditInformationStyle as classes } from "../employer-detail/Detail.style";

export const useCreateEmployerStyle = {
  dialog: classes.dialog,
  dialogContent: {
    maxHeight: "70vh",
    "& .css-1xhj18k": {
      flexDirection: "row !important",
    },
  },
  profileBox: {
    ...classes.profileBox,
    width: ["140px", "150px"],
    height: ["140px", "150px"],
  },
  iconInput: classes.iconInput,
  iconButton: {
    color: "grey.500",
    fontSize: "18px",
  },
  uploadImgBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #9E9E9E",
    width: "150px",
    height: "150px",
    borderRadius: "8px",
  },
  deleteBtn: {
    position: "absolute",
    right: "-10px",
    top: "-10px",
    height: "25px",
    maxWidth: "25px",
    minWidth: "25px",
    p: 0,
    bgcolor: "common.black",
    border: "none",
    "&:hover": {
      bgcolor: "common.black",
      border: "none",
    },
    zIndex: 100,
  },
  circularBox: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "150px",
  },
  orgLogo: {
    borderRadius: "4px",
    width: "150px",
    height: "150px",
    objectFit: "cover" as "cover",
  },
};
