import { useEffect, useState } from "react";
// mui and style
import { Box } from "@mui/material";
import { useSelectGenderLocationStyle as classes } from "../CampaignBrief.style";
// creatable and type
import CreatableSelect from "react-select/creatable";
import { ISelectGenderLocationProps, IOptions } from "../CampaignBrief.type";

export default function SelectGenderLocation(
  props: ISelectGenderLocationProps
) {
  const {
    name,
    options,
    keyName,
    defaultValue,
    form: { setValue },
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    if (name === "gender" && defaultValue) {
      const genderDefaultList = defaultValue as string[];
      const arrayList = options.filter((item1) => {
        return genderDefaultList.some((item2) => {
          return item1.value === item2;
        });
      });
      if (arrayList.length === 3) {
        const allOptions = [{ id: 1, label: "ทั้งหมด", value: "all" }];
        setSelectedOptions(allOptions);
      } else {
        setSelectedOptions(arrayList);
      }
    } else if (name === "location" && defaultValue) {
      const locationDefaultList = defaultValue as number[];
      const arrayList = options.filter((item1) => {
        return locationDefaultList.some((item2) => {
          return item1.id === item2;
        });
      });
      setSelectedOptions(arrayList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white",
      border: "1px solid #8C499C",
      borderRadius: state.menuIsOpen ? "15px 15px 0 0" : "20px",
      ":hover": {
        border: "1px solid #8C499C",
      },
      fontSize: "14px",
      fontWeight: 300,
      cursor: "pointer",
      borderColor: "#8C499C",
      boxShadow: "none",
    }),
    option: (provided: any) => ({
      ...provided,
      color: "#8C499C",
      ":hover": {
        backgroundColor: "rgba(140, 73, 156, 0.5)",
        color: "white",
      },
      backgroundColor: "none",
      cursor: "pointer",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#8C499C",
      borderRadius: "20px",
      padding: "1px 2px 1px 2px",
      color: "#fff",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "white",
      paddingRight: "5px",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "white",
      backgroundColor: "#8C499C",
      borderRadius: "50%",
      cursor: "pointer",
      ":hover": {
        color: "white",
      },
    }),
  };

  function handleSelectChange(newValue: any, action: any) {
    if (action.name === "gender") {
      const allOption = newValue.find((item: IOptions) => item.value === "all");
      if (action.action === "select-option" && action.option.value === "all") {
        const filteredOptions = options.filter(
          (item: IOptions) => item.value !== "all"
        );
        setSelectedOptions(allOption);
        setValue(
          keyName as any,
          filteredOptions.map((item: IOptions) => item.value)
        );
      } else {
        const filteredOptions = newValue.filter(
          (item: IOptions) => item.value !== "all"
        );
        const allGenderList = filteredOptions.map(
          (item: IOptions) => item.value
        );
        if (filteredOptions.length === 3) {
          const allOptions = [{ id: 1, label: "All", value: "all" }];
          setSelectedOptions(allOptions);
        } else {
          setSelectedOptions(filteredOptions);
        }

        setValue(keyName as any, allGenderList);
      }
    } else {
      setValue(keyName as any, newValue);
      setSelectedOptions(newValue);
    }
  }

  return (
    <Box sx={classes.container}>
      <CreatableSelect
        isMulti
        name={name}
        options={options}
        styles={customStyles}
        value={selectedOptions}
        // defaultValue={defaultValue}
        onChange={handleSelectChange}
      />
    </Box>
  );
}
