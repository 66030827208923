import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// type and api
import { ReportAPI } from "@api/global";
import { IInitInfluencerJobReportState } from "./type";


const initialState: IInitInfluencerJobReportState = {
  data: null,
  error: "",
  loading: false
};

export const getInfluencerJobReportAsync = createAsyncThunk(
  "influencerReport",
  async (params:{ job_id: string, chart_type?: string, date?: { start: string, end: string }}) => {
    try {
      const response = await ReportAPI.getInfluencerJobReport(params);
      return response;
    }
    catch (err){
      throw(err)
    }
  }
)

export const InfluencerJobReportSlice = createSlice({
  name: "influencerReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerJobReportAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getInfluencerJobReportAsync.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false;
        state.error = "";
      })
      .addCase(getInfluencerJobReportAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
}); 

export const selectInfluencerJobReport = (state: RootState) => state.influencerJobReport.data;
export const selectInfluencerJobReportOnload = (state: RootState) => state.influencerJobReport.loading;

export default InfluencerJobReportSlice.reducer