const card = {
  display: "flex",
  height: "160px",
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: "0px 4px 10px 0px #0000000D",
};

export const useInfluencerInfoStyles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    mt: "20px",
    columnGap: "25px",
    rowGap: "20px",
  },
  leftCard: {
    ...card,
    width: {
      xs: "100%",
      lg: "65%",
    },
  },
  rightCard: {
    ...card,
    flexDirection: "column",
    width: {
      xs: "100%",
      lg: "35%",
    },
  },
  avatar: {
    borderRadius: "8px",
    width: "120px",
    height: "120px",
    objectFit: "cover",
    m: "20px",
  },
  leftCardBoxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    m: "25px 20px 25px 0",
  },
  category: {
    display: "flex",
    alignItems: "center",
    mt: "10px",
    "& > svg": {
      fontSize: "4px",
      alignItems: "center",
      mx: "8px",
      color: "#E0E0E0",
    },
    "& > svg:last-of-type": {
      display: "none",
    },
  },
  activeStatus: {
    bgcolor: "#E7FFE8",
    color: "#4CAF50",
    borderRadius: "50px",
    p: "4px 16px 4px 16px",
  },
};
