import { useState } from "react";
import { useParams } from "react-router-dom";
// mui and style
import { Box, Typography, Button, Icon, CircularProgress } from "@mui/material";
import { useVertifyEmail as classes } from "./vertifyEmail.style";
// api and components
import { AuthenticationAPI } from "@api/global";

export default function VertifyEmail() {
  const { email }: any = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const resendVerifyEmail = async () => {
    setIsLoading(true);
    const body = {
      email: email,
      grant_type: "resend",
    };

    try {
      const response = await AuthenticationAPI.resendVerifyEmail(body);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.backgroundLiner}></Box>
      <Box sx={classes.dialog}>
        <Box sx={classes.iconWrap}>
          <Icon className="ico-hu-mail" sx={classes.mailIcon} />
        </Box>
        <Box sx={classes.contentWrap}>
          <Typography variant="h4">Please verify your email</Typography>
          <Typography variant="body1" sx={{ marginTop: "24px" }}>
            You're almost there! We sent an email to
          </Typography>
          <Typography variant="body1" sx={classes.textEmail}>
            {email}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Just click on the link in that email to complete your sign up.
          </Typography>
          <Typography variant="body1">
            If you don't see it, please check again in your spam folder.
          </Typography>
          <Typography variant="body1" sx={classes.textDanger}>
            ***Please verify email within 15 minutes.***
          </Typography>
        </Box>
        <Box sx={classes.buttonWrap}>
          <Typography variant="caption">Still can't find the email?</Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={() => resendVerifyEmail()}
              sx={classes.resendButton}
            >
              Resend Email
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
