// mui and icons
import {
  Box,
  Grid,
  Stack,
  Avatar,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import {
  ReplyOutlined,
  MapsUgcOutlined,
  Star as StarIcon,
  Reply as ReplyIcon,
  ThumbUpOffAltOutlined,
  ThumbUp as ThumbUpIcon,
  Assessment as AssessmentIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  AccountCircle as AccountCircleIcon,
  ReplyOutlined as ReplyOutlinedIcon,
  MarkUnreadChatAlt as MarkUnreadChatAltIcon,
} from "@mui/icons-material";
// type and style
import { IInfluencerPostReportProps } from "./ReportTab.type";
import { useInfluencerPostReportStyle as classes } from "./ReportTab.style";
// others
import { useHistory, useParams } from "react-router-dom";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";
import { FacebookImageFormat } from "@global/image-format/ImageFormat";

export default function InfluencerPostReport(
  props: IInfluencerPostReportProps
) {
  const { socialPosts } = props;

  const history = useHistory();
  const { campaignId } = useParams<{ campaignId: string }>();

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Box sx={classes.container}>
      <Box sx={classes.postReportTitle}>
        <Typography mb={1.5}>Influencer Post Report</Typography>
        <Typography>รายงานแสดงข้อมูลของแคมแปนของอินฟลูเอนเซอร์</Typography>
      </Box>
      <Divider />
      <Grid container mt={6} spacing={2} mx={0.5} sx={classes.gridRoot}>
        {socialPosts?.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <Box sx={classes.postReportGrid}>
              <Box sx={classes.postReportTop}>
                <Avatar
                  className="influencerImage"
                  src={item.profile_image_url}
                />
                <Box ml="10px">
                  <span className="influencerName">
                    <SocialIconSelected
                      platform={item.platform}
                      height="20px"
                      width="20px"
                    />
                    <Typography mx={[1, 2]}>{item.name}</Typography>
                    {item.is_recommended && <StarIcon />}
                  </span>
                  <span className="influencerFollower">
                    <AccountCircleIcon />
                    <Typography>
                      {numberFormat.format(item.follower)} Follower
                    </Typography>
                  </span>
                </Box>
              </Box>
              <Box m={5}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        ...classes.postReportBottomLeft,
                        maxHeight:
                          item.objective === "conversion" ? "96.5%" : "343px",
                      }}
                    >
                      <Box sx={classes.socialProfile}>
                        <Avatar src={item.profile_image_url} />
                        <Typography ml={3}>{item.name}</Typography>
                      </Box>
                      <Typography
                        sx={{
                          ...classes.desc,
                          WebkitLineClamp:
                            item.objective === "conversion" ? 7 : 3,
                        }}
                      >
                        {item.post.description}
                      </Typography>
                      <FacebookImageFormat
                        item={item.post.attachments}
                        height="200px"
                      />
                      <Stack
                        direction="row"
                        spacing={3}
                        sx={classes.socialTotalBox}
                      >
                        {[
                          {
                            total: item.post.report.like,
                            icon: <ThumbUpIcon />,
                          },
                          {
                            total: item.post.report.comment,
                            icon: <MarkUnreadChatAltIcon />,
                          },
                          {
                            total: item.post.report.share,
                            icon: <ReplyIcon />,
                          },
                        ].map((item, index) => (
                          <Box key={index} sx={classes.socialIcon}>
                            {item.icon}
                            <Typography ml="8px">
                              {numberFormat.format(item.total ? item.total : 0)}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={classes.postReportBottomRight}>
                      <Box sx={classes.postReportFirstCard}>
                        <Typography variant="h6" color="primary" ml={6}>
                          Overall
                        </Typography>
                        <div>
                          <Typography variant="h5">
                            {numberFormat.format(item.post.report.reach)}
                            <span>Reach</span>
                          </Typography>
                          <Typography variant="h5">
                            {numberFormat.format(item.post.report.impression)}
                            <span>Impression</span>
                          </Typography>
                        </div>
                      </Box>
                      <Box my={3} sx={classes.postReportSecondCard}>
                        <Typography variant="h6" color="primary" ml={6} mt={4}>
                          Engagement
                        </Typography>
                        <div>
                          <Typography
                            sx={classes.postReportSecondCardTop}
                            variant="h5"
                          >
                            {`${item.post.report.engagement_rate.toFixed(2)} % `}
                            <span>Engagement Rate</span>
                          </Typography>
                          <Divider sx={classes.divider} />
                          <Box
                            sx={classes.postReportSecondCardBottom}
                            mx={8}
                            my={6}
                          >
                            {[
                              {
                                total: item.post.report.like,
                                icon: <ThumbUpOffAltOutlined />,
                              },
                              {
                                total: item.post.report.comment,
                                icon: <MapsUgcOutlined />,
                              },
                              {
                                total: item.post.report.share,
                                icon: <ReplyOutlined />,
                              },
                            ].map((engagement, index) => (
                              <Box key={index}>
                                {engagement.icon}
                                <Typography>
                                  {numberFormat.format(engagement.total)}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </div>
                      </Box>
                      {item.objective === "conversion" && (
                        <Box sx={classes.postReportThirdCard}>
                          <Typography
                            variant="h6"
                            color="primary"
                            ml={6}
                            mt={5}
                          >
                            Conversion
                          </Typography>
                          <div>
                            <Typography
                              sx={classes.postReportThirdCardTop}
                              variant="h5"
                              my={4}
                            >
                              <span>Conversion Rate</span>
                            </Typography>
                            <Divider sx={classes.divider} />
                            <Box sx={classes.postReportThirdCardBottom} my={6}>
                              <div>
                                <ReplyOutlinedIcon />
                                <Typography>
                                  {/* {numberFormat.format(item.conversion.click_count)} */}
                                </Typography>
                              </div>
                              <div>
                                <ReplyOutlinedIcon />
                                <Typography>
                                  {/* {numberFormat.format(item.conversion.income)} THB */}
                                </Typography>
                              </div>
                            </Box>
                          </div>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={classes.postReportButton}>
                <Button
                  onClick={() => window.open(item.post.published_url, "_blank")}
                  startIcon={<RemoveRedEyeIcon />}
                  variant="outlined"
                >
                  View Post
                </Button>
                <Button
                  onClick={() =>
                    history.push(
                      `/influencers/jobs/${item.job_id}/post-report?c_id=${campaignId}`
                    )
                  }
                  startIcon={<AssessmentIcon />}
                  variant="contained"
                >
                  View Report
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
