export const error_message: { [key: string]: string } = {
  "IG-0000": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "IG-0001": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "IG-0002": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "IG-0003": "ไม่สามารถดึงข้อมูลจาก Instagram ได้ กรุณาลองใหม่อีกครั้ง",
  "IG-0004": "ไม่พบบัญชีผู้ใช้งานในระบบ กรุณาลองใหม่อีกครั้ง",
  "IG-0005": "บัญชีนี้ถูกใช้งาน โดยบัญชีผู้อื่นในระบบแล้ว",
  "IG-0006": "ไม่สามารถบันทึกบัญชีผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง",
  "IG-0007": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "IG-0008":
    "กรุณาตรวจสอบ Facebook Page ของท่าน ว่าท่านมีสิทธิ์เป็น Admin ของ Page หรือไม่?",
  "IG-0009":
    "กรุณาตรวจสอบการเชื่อมต่อระหว่าง Instagram Business Account กับ Facebook Page และตรวจสอบสิทธิ์ Admin Facebook Page",
  "IG-0010": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "IG-0011": "จำนวน Follower และจำนวน Post ต่ำกว่าระบบกำหนด",
  "TK-0000": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TK-0001": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TK-0002": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TK-0003": "ไม่สามารถดึงข้อมูลจาก TikTok ได้ กรุณาลองใหม่อีกครั้ง",
  "TK-0004": "ไม่พบบัญชีผู้ใช้งานในระบบ กรุณาลองใหม่อีกครั้ง",
  "TK-0005": "บัญชีนี้ถูกใช้งาน โดยบัญชีผู้อื่นในระบบแล้ว",
  "TK-0006": "ไม่สามารถบันทึกบัญชีผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง",
  "TK-0007": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0000": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0001": "ไม่สามารถเชื่อมต่อ Twitter ได้ กรุณาลองใหม่อีกครั้ง",
  "TW-0002": "ไม่สามารถเชื่อมต่อ Twitter ได้ กรุณาลองใหม่อีกครั้ง",
  "TW-0003": "ไม่สามารถเชื่อมต่อ Twitter ได้ กรุณาลองใหม่อีกครั้ง",
  "TW-0004": "ไม่พบบัญชีผู้ใช้งานในระบบ กรุณาลองใหม่อีกครั้ง",
  "TW-0005": "บัญชีนี้ถูกใช้งาน โดยบัญชีผู้อื่นในระบบแล้ว",
  "TW-0006": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0007": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0008": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0009": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0010": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0011": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0012": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "TW-0013": "มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  "fb-be-001": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-be-002": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-cb-001": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-db-001": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-db-002": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-db-003": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-db-004": "ไม่พบผู้ใช้ กรุณาตรวจสอบข้อมูลของท่านอีกครั้ง",
  "fb-db-005": "ไม่พบผู้ใช้ กรุณาตรวจสอบข้อมูลของท่านอีกครั้ง",
  "fb-db-006": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-db-007": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-db-008": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-ext-001":
    "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-gcp-001":
    "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-gcp-002":
    "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-gh-001": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-gh-002": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-gh-003": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-gh-004": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-gh-005": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-gh-006": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-gh-007": "ไม่สามารถดึงข้อมูลจาก Facebook ได้ กรุณาลองใหม่อีกครั้ง",
  "fb-rd-001": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-rd-002": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-rd-003": "มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลของท่านแล้วลองใหม่อีกครั้ง",
  "fb-rd-004": "Session ของท่านหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
  "fb-rq-001": "ไม่พบ Facebook Page ที่ทำการเชื่อมต่อ กรุณาลองใหม่อีกครั้ง",
  "fb-sync-001":
    "กรุณาตรวจสอบ Facebook Page ของท่าน ว่าท่านมีสิทธิ์เป็น Admin ของ Page หรือไม่?",
};
