import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { DashboardAPI } from "@api/global";
import { IInitDashboardOverviewState } from "./type";
import { IOverviewParams } from "@api/dashboard/type";

const initialState: IInitDashboardOverviewState = {
  data: null,
  loading: false,
};

export const getDashboardOverviewAsync = createAsyncThunk(
  "dashboardOverview",
  async (params: IOverviewParams | undefined) => {
    try {
      const response = await DashboardAPI.getOverview(params);
      return response;
    } catch (err) {
      throw err;
    }
  }
); 

export const DashboardOverviewSlice = createSlice({
  name: "dashboardOverviewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardOverviewAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardOverviewAsync.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(getDashboardOverviewAsync.rejected, (state) => {
        state.data = null;
        state.loading = false;
      });
  },
});

export const selectDashboardOverview = (state: RootState) => state.dashboardOverview.data;
export const selectDashboardOverviewOnload = (state: RootState) => state.dashboardOverview.loading;

export default DashboardOverviewSlice.reducer;
