import { useEffect, useRef, useState } from "react";
// mui and style
import { Box, Grid, Icon, Button, Tooltip, Typography } from "@mui/material";
import { useCampaignBriefStyle as classes } from "./CampaignDetail.style";
// types and icon
import { ICampaignDetailProps } from "./CampaignDetail.type";
import { SocialIconSelected } from "../icon-selected/SocialIconSelected";
// components
import ImageList from "./ImageList";
import VideoList from "./VideoList";
import ViewMultiVideoDialog from "../view-video-dialog/ViewMultiVideoDialog";
// others
import nl2br from "react-nl2br";
import { hBlack } from "@theme/index";

export default function CampaignBrief(props: ICampaignDetailProps) {
  const { campaignInfo } = props;
  const [hashtagHeight, setHashtagHeight] = useState(0);
  const [copyHashtag, setCopyHashtag] = useState("Copy");
  const [viewVideoDialog, setViewVideoDialog] = useState(false);

  const brief = campaignInfo?.brief;
  const contentMedia = brief?.content.media;
  const moodAndTone = brief?.mood_and_tone.media;
  const hashtagRef = useRef<HTMLDivElement>(null);

  const onCopyHashtag = () => {
    const hashtag = campaignInfo?.hashtag?.map((item) => `#${item.value}`);
    const hashtagList = hashtag?.toString().replace(/,/gi, " ");
    navigator.clipboard.writeText(hashtagList ? hashtagList : "");
    setCopyHashtag("Copied");
  };

  useEffect(() => {
    const hashtagBox = hashtagRef.current?.clientHeight;
    setHashtagHeight(hashtagBox ? hashtagBox : 0);
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]} fontWeight="500">
          Platform
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", 0]}>
        <Box display="flex" alignItems="center">
          <SocialIconSelected platform={campaignInfo?.platform} />
          <Typography variant="body1" textTransform="capitalize" ml="5px">
            {campaignInfo?.platform || ""}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Content Type
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} xl={9} height="100%">
        <Typography variant="body1" textTransform="capitalize">
          {campaignInfo ? campaignInfo.content_type.replace(/_/g, " ") : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Brief Image
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", "-10px"]}>
        <ImageList images={contentMedia?.images} />
      </Grid>
      {contentMedia?.videos.length ? (
        <>
          <Grid item xs={12} sm={4} xl={3} mt={[0, "-15px"]}>
            <Typography variant="title1" color={hBlack[200]}>
              Brief Video
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} xl={9} mt={["-15px", "-10px"]}>
            <VideoList videos={contentMedia?.videos} />
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Description
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", 0]}>
        <Typography variant="body1">
          {nl2br(brief?.content.description)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Mood and Tone
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", "-10px"]} height="100%">
        {moodAndTone?.images.length ? (
          <ImageList images={moodAndTone.images} mb={2} />
        ) : null}
        <Typography variant="body1" my={2.2}>
          {nl2br(brief?.mood_and_tone.description)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} xl={3} mt={-2}>
        <Typography variant="title1" color={hBlack[200]}>
          Key Message
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", -2]}>
        <Typography variant="body1">{nl2br(brief?.key_message)}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Hashtag
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", 0]}>
        {campaignInfo?.hashtag.length ? (
          <Box
            ref={hashtagRef}
            sx={{
              ...classes.hashtagBox,
              borderRadius: hashtagHeight > 50 ? "15px" : "50px",
              alignItems: hashtagHeight > 50 ? "end" : "center",
            }}
          >
            <Box>
              {campaignInfo?.hashtag?.map((item, index) => (
                <Button key={index} sx={classes.hashtag} variant="outlined">
                  #{item.value}
                </Button>
              ))}
            </Box>
            <Box mb={hashtagHeight > 50 ? 2 : 0} sx={classes.hashtagCopyBox}>
              <Tooltip title={copyHashtag} placement="top">
                <Icon
                  className="ico-hu-copy"
                  color="primary"
                  onClick={onCopyHashtag}
                  onMouseOut={() => setCopyHashtag("Copy")}
                />
              </Tooltip>
            </Box>
          </Box>
        ) : null}
      </Grid>
      <ViewMultiVideoDialog
        open={viewVideoDialog}
        onClose={setViewVideoDialog}
        videos={contentMedia?.videos}
      />
    </Grid>
  );
}
