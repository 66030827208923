const BASE_URL = process.env.REACT_APP_BASE_URL ?? "";
const MOCK_API_URL = process.env.REACT_APP_MOCK_API_URL ?? "";
const API_KEY = process.env.REACT_APP_API_KEY ?? "";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? "";
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ?? "";
const JWT_SECRET = process.env.REACT_APP_JWT_SECRET_KEY ?? "";
const SOCIAL_URL = process.env.REACT_APP_SOCIAL_URL ?? "";
const SOCIAL_API_KEY = process.env.REACT_APP_SOCIAL_API_KEY ?? "";
//Twitter
const TWITTER_BEARER_TOKEN = process.env.REACT_APP_TWITTER_BEARER_TOKEN ?? "";
const TWITTER_API_KEY = process.env.REACT_APP_TWITTER_API_KEY ?? "";
const TWITTER_API_SECRET_KEY = process.env.REACT_APP_TWITTER_API_SECRET_KEY ?? "";
const TWITTER_ACCESS_TOKEN = process.env.REACT_APP_TWITTER_ACCESS_TOKEN ?? "";
const TWITTER_ACCESS_TOKEN_SECRET = process.env.REACT_APP_TWITTER_ACCESS_TOKEN_SECRET ?? "";
const TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID ?? "";
const TWITTER_CLIENT_SECRET = process.env.REACT_APP_TWITTER_CLIENT_SECRET ?? "";
// Social
const SYNC_FACEBOOK = process.env.REACT_APP_SYNC_FACEBOOK ?? "";
const SYNC_INSTAGRAM_AND_TWITTER = process.env.REACT_APP_SYNC_TWITTER_AND_INSTAGRAM ?? "";
// Dashboard
const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL ?? "";
const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY ?? "";
// Payment
const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL ?? "";
// Firebase
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID ?? "";
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY ?? "";
const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY ?? "";
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "";
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "";
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "";
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? "";
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "";

export {
  BASE_URL,
  API_KEY,
  CLIENT_ID,
  JWT_SECRET,
  MOCK_API_URL,
  CLIENT_SECRET,
  SOCIAL_API_KEY,
  //Twitter
  TWITTER_API_KEY,
  TWITTER_CLIENT_ID,
  TWITTER_BEARER_TOKEN,
  TWITTER_ACCESS_TOKEN,
  TWITTER_CLIENT_SECRET,
  TWITTER_API_SECRET_KEY,
  TWITTER_ACCESS_TOKEN_SECRET,
  //Social
  SOCIAL_URL,
  SYNC_FACEBOOK,
  SYNC_INSTAGRAM_AND_TWITTER,
  // Dashboard
  MAP_API_KEY,
  DASHBOARD_URL,
  // Payment
  PAYMENT_URL,
  // Firebase
  FIREBASE_APP_ID,
  FIREBASE_API_KEY,
  FIREBASE_VAPID_KEY,
  FIREBASE_PROJECT_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID
};
