export const useMemberStyles = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  paperSearch: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    height: "40px",
    padding: "0 10px",
    border: "1px solid #8C499C",
    boxShadow: "none",
  },
  searchList: {
    borderRadius: "0 0 20px 20px",
    bgcolor: "#fff",
    position: "absolute",
    top: 41,
    width: "100%",
    zIndex: 2,
    border: "1px solid #8C499C",
    maxHeight: "190px",
    overflowY: "scroll",
    MsOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  searchedMemberList: {
    columnGap: "10px",
    alignItems: "center",
    p: "10px",
    cursor: "pointer",
    "&:hover": { bgcolor: "#F5EEFF" },
  },
  permTxt: {
    bgcolor: "#F5F6F8",
    p: "10px 16px 10px 16px",
    borderRadius: "50px",
    textAlign:"center"
  },
  tableRow: { 
    "&:last-child td, &:last-child th": { 
      border: 0 
    } 
  },
  permission: { 
    ml: "4px", 
    cursor: "pointer" 
  },
};
