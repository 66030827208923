import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  CardContent,
} from "@mui/material";
// icons and style
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useTermsAndConditionStyle as classes } from "../CampaignBrief.style";
// data and type
import { termAndConditionList } from "../campaignBrief.constants";
import { ICreateCampaignProps } from "../CampaignBrief.type";
// others
import TextFieldCustom from "@global/text-field";
import WrapInputWithLabel from "@global/wrap-input-label";

export default function TermsAndConditions(props: ICreateCampaignProps) {
  const {
    form: {
      formState: { errors },
      watch,
      setValue,
    },
  } = props;

  const [condition, setCondition] = useState("");

  const conditionList = watch("brief.term_and_condition");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    const data = [...conditionList];
    data[index].message = event.target.value;
    setValue("brief.term_and_condition", data);
  };

  const handleInputCondition = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCondition(event.target.value);
  };

  const handleAddCondition = () => {
    const data = {
      order: conditionList.length + 1,
      message: condition,
    };
    setValue("brief.term_and_condition", [...conditionList, data]);
    setCondition("");
  };

  const handleRemoveConditon = (index: number) => {
    const data = [...conditionList];
    data.splice(index, 1);
    setValue("brief.term_and_condition", data);
  };

  return (
    <CardContent>
      <WrapInputWithLabel
        labelWidth="200px"
        title={
          <Typography>
            Terms and Conditions<span className="require">*</span>
          </Typography>
        }
      >
        <Stack spacing={2}>
          {conditionList.map((cond, index) => (
            <Box key={index}>
              <TextFieldCustom
                variant="outlined"
                multiline
                fullWidth
                value={cond.message}
                sx={classes.textfield}
                InputProps={{
                  onChange: (e) => handleInputChange(e, index),
                  endAdornment: (
                    <IconButton
                      aria-label="add an alarm"
                      component="label"
                      sx={classes.IconButton}
                      onClick={() => handleRemoveConditon(index)}
                    >
                      <RemoveCircleIcon color="error" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          ))}

          <Box>
            <TextFieldCustom
              variant="outlined"
              multiline
              fullWidth
              value={condition}
              sx={classes.textfield}
              error={!!errors?.brief?.term_and_condition}
              InputProps={{
                onChange: handleInputCondition,
                endAdornment: (
                  <IconButton
                    aria-label="add an alarm"
                    component="label"
                    onClick={handleAddCondition}
                    sx={classes.IconButton}
                  >
                    <AddIcon sx={classes.addIcon} />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Stack>
      </WrapInputWithLabel>

      <Grid container justifyContent="flex-end">
        <Grid item xs={9}>
          <Box sx={classes.textExample}>
            <Typography>ตัวอย่าง “ข้อตกลงและเงื่อนไข”</Typography>
            <ul>
              {termAndConditionList.map((data, index) => (
                <li key={index}>{data}</li>
              ))}
            </ul>
            <Tooltip title="Copy" placement="top">
              <IconButton
                onClick={() => {
                  const condition = termAndConditionList.map((cond, index) => {
                    return {
                      message: cond,
                      order: index + conditionList.length,
                    };
                  });
                  setValue("brief.term_and_condition", [
                    ...conditionList,
                    ...condition,
                  ]);
                }}
                sx={classes.coppyIcon}
              >
                <FileCopyOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
}
