import { RootState } from "@store/store";
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
// api and type
import { CampaignAPI } from "@api/global";
import { ICampaignListState } from "./type";
import { ICampaignListParams } from "@api/campaign/type";

const initialState: ICampaignListState = {
  campaignList: null,
  total_item: 0,
  item_per_page: 0,
  page: 0,
  loading: false,
  briefing: 0,
  influencer_listing: 0,
  published: 0,
  reported: 0,
  completed: 0,
  canceled: 0,
  total: 0,
};

export const getCampaignListAsync = createAsyncThunk(
  "getCampaignList",
  async (params: ICampaignListParams) => {
    try {
      const response = await CampaignAPI.getCampaignList(params);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const CampaignListSlice = createSlice({
  name: "campaignList",
  initialState,
  reducers: {
    setCampaignList: (state, { payload }) => {
      state.campaignList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignListAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignListAsync.fulfilled, (state, action) => {
        state.campaignList = action.payload.item;
        state.total_item = action.payload.total_item;
        state.item_per_page = action.payload.item_per_page;
        state.page = action.payload.page;
        state.briefing = action.payload.briefing;
        state.influencer_listing = action.payload.influencer_listing;
        state.published = action.payload.published;
        state.reported = action.payload.reported;
        state.completed = action.payload.completed;
        state.canceled = action.payload.canceled;
        state.total = action.payload.total;
        state.loading = false;
      })
      .addCase(getCampaignListAsync.rejected, (state) => {
        state.campaignList = null;
        state.loading = false;
      });
  },
});

export const selectCampaignList = (state: RootState) => state.campaignList;

export const selectCampaignListPagination = (state: RootState) => {
  return {
    page: state.campaignList.page,
    perPage: state.campaignList.item_per_page,
    totalItems: state.campaignList.total_item,
  };
};

export default CampaignListSlice.reducer;
