import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material";

const card = {
  bgcolor: "#fff",
  borderRadius: "16px",
  boxShadow: "0px 4px 10px 0px #3535350D",
};

const cardHeader = {
  bgcolor: "primary.main",
  textAlign: "center",
  height: "42px",
  padding: "0",
  fontWeight: "bold",
  borderRadius: "16px 16px 0px 0px",
};

export const useMyProfileStyle = {
  card: card,
  cardHeader: cardHeader,
  tabItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "8px",
    width: "100%",
    borderRadius: ["15px 15px 0px 0px", "20px 20px 0px 0px"],
    cursor: "pointer",
  },
  label: {
    display: { xs: "none", sm: "block" },
    fontWeight: 400,
    textTransform: "capitalize",
  },
  profileWrap: {
    display: { md: "none" },
    m: {
      xs: "-30px -10px 20px -15px",
      sm: "-30px -27px 20px -25px",
    },
    height: { xs: "300px", sm: "360px" },
    backgroundSize: "cover",
    position: "relative",
  },
  profileItemWrap: {
    background: "linear-gradient(180deg, rgba(55, 55, 55, 0) 0%, #000000 100%)",
    minHeight: "164px",
    width: "100%",
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  editProfile: {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    bgcolor: "#fff",
    "&:hover": {
      bgcolor: "#fff",
    },
  },
  goBack: {
    position: "absolute",
    left: "25px",
    top: "20px",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    bgcolor: "#fff",
    "&:hover": {
      bgcolor: "#fff",
    },
  },
  componentWrap: {
    boxShadow: "0px 2px 24px 0px #76768005",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
};

export const SaveButton = styled(Button)<ButtonProps>(() => ({
  border: "none",
  maxWidth: 300,
  width: "85%",
  "&:hover": {
    border: "none",
  },
}));


export const useMyProfileForMobileStyle = {
  ...useMyProfileStyle,
  editProfile:{
    ...useMyProfileStyle.editProfile,
    left: "25px",
    top: "20px",
  },
  componentWrap:{
    boxShadow: "0px 2px 24px 0px #76768005",
    borderRadius: "8px",
    backgroundColor: "#fff",
  }
}

export const useEditRateCardForMobileStyle = {
  content: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.05)",
    p: "16px",
    mt: "20px",
    bgcolor: "#fff",
  },
};

export const useEditMyProfileForMobileStyle = {
  content: {
    bgcolor: "#fff",
    p: "12px",
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #76768005",
    mt: "15px",
  },
};

export const useEditMyPortfolioForMobileStyle = {
  content: {
    bgcolor: "#fff",
    p: "12px",
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #76768005",
    mt: "15px",
  },
};

export const useEditBankAccountForMobileStyle = {
  content: {
    bgcolor: "#fff",
    p: "12px",
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #76768005",
    mt: "15px",
  },
};
export const useEditAddressForMobileStyle = {
  content: {
    bgcolor: "#fff",
    p: "12px",
    borderRadius: "8px",
    boxShadow: "0px 2px 24px 0px #76768005",
    mt: "15px",
  },
};
