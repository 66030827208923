import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Chip,
  Grid,
  Radio,
  Button,
  Avatar,
  Drawer,
  Divider,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
// redux
import {
  getCategoryAsync,
  selectCategoryList,
} from "@slices/CategoryList.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// icon and style
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useFilterJobBoardStyle as classes } from "./JobBoard.style";
// type
import { IJobBoardFilterProps } from "./JobBoard.type";
import { IJobBoardParams } from "@views/job-board/JobBoard.type";
// others
import {
  platformList,
  reviewTypeList,
  sortDateBudgetList,
} from "@views/job-board/JobBoard.constants";
import { useHistory, useLocation } from "react-router-dom";

export default function JobBoardFilter(props: IJobBoardFilterProps) {
  const { open, onClose } = props;

  const [filterList, setFilterList] = useState<IJobBoardParams>();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const reviewType = params.get("r_type") || "";
  const campaignType = params.get("c_type") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const cate = params.get("cate");
  const categories = cate ? cate.split(",").map(Number) : [];

  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(selectCategoryList);

  useEffect(() => {
    setFilterList((prev) => {
      return {
        ...prev,
        page: page,
        sort: sort,
        order_by: orderBy,
        platform: platform,
        c_type: campaignType,
        r_type: reviewType,
        cate: categories,
      };
    });
    // eslint-disable-next-line
  }, [page, sort, orderBy, platform, campaignType, reviewType]);

  useEffect(() => {
    dispatch(getCategoryAsync());
  }, [dispatch]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      onClose(open);
    };

  const handleFilter = (data: IJobBoardParams) => {
    setFilterList((prev) => {
      return { ...prev, ...data };
    });
  };

  const handleSelectCategory = (value: number) => {
    if (!filterList) return;
    const categories = [...categoryList.map((cat) => cat.id)];
    if (categories.includes(value)) {
      if (filterList.cate?.includes(value)) {
        setFilterList((prev) => {
          return {
            ...prev,
            cate: (filterList.cate || []).filter((cat) => cat !== value),
          };
        });
      } else {
        setFilterList((prev) => {
          return {
            ...prev,
            cate: [...(prev?.cate || []), value],
          };
        });
      }
    } else {
      setFilterList((prev) => {
        return { ...prev, cate: [] };
      });
    }
  };

  const onClearFilter = () => {
    history.replace("/job-board");
    onClose(false);
  };

  const onApplyFilter = () => {
    if (!filterList) return;
    const queryString = Object.keys(filterList)
      .map((key) => `${key}=${filterList[key as keyof IJobBoardParams]}`)
      .join("&");

    history.replace(`/job-board?${queryString}`);
    onClose(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={classes.drawer}
    >
      <Box sx={classes.drawerCon} position="relative">
        <Box sx={classes.filterTitle}>
          <Typography variant="h6" color="primary" ml={3}>
            Filter
          </Typography>
          <ClearOutlinedIcon onClick={() => onClose(false)} />
        </Box>
        <Box sx={classes.titleItem}>
          <Typography>Sort by</Typography>
          <Grid container>
            <FormControl>
              <RadioGroup sx={{ height: "90px" }}>
                <Grid item xs={4} sm={5}>
                  {sortDateBudgetList.date.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilter({
                              order_by: "date",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
                <Grid item xs={8} sm={7}>
                  {sortDateBudgetList.budget.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilter({
                              order_by: "budget",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        <Divider sx={classes.line} />
        <Box sx={classes.titleItem}>
          <Typography>Platform</Typography>
          <div>
            <Chip
              label="All"
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  filterList?.platform === "" ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor: filterList?.platform === "" ? "#F4ECFF" : "#fff",
                minWidth: "60px",
                color: filterList?.platform === "" ? "#893DBD" : "#545454",
              }}
              onClick={() => handleFilter({ platform: "" })}
            />
            {platformList.map((item, index) => (
              <Chip
                key={index}
                avatar={<Avatar src={item.url} />}
                label={item.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.platform === item.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor:
                    filterList?.platform === item.key ? "#F4ECFF" : "#fff",
                  color:
                    filterList?.platform === item.key ? "#893DBD" : "#545454",
                }}
                onClick={() => handleFilter({ platform: item.key })}
              />
            ))}
          </div>
        </Box>
        <Divider sx={classes.line} />
        <Box sx={classes.titleItem}>
          <Typography>Review</Typography>
          <div>
            {reviewTypeList.map((type, index) => (
              <Chip
                key={index}
                label={type.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.r_type === type.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.r_type === type.key ? "#F4ECFF" : "#fff",
                  color:
                    filterList?.r_type === type.key ? "#893DBD" : "#545454",
                  minWidth: filterList?.r_type === "" ? "60px" : "60px",
                }}
                onClick={() => handleFilter({ r_type: type.key })}
              />
            ))}
          </div>
        </Box>
        <Divider sx={classes.line} />
        <Box sx={classes.titleItem} mb="50px">
          <Typography>Category</Typography>
          <Box>
            <Chip
              label="All"
              onClick={() => handleSelectCategory(0)}
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  (filterList?.cate || []).length <= 0 ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor:
                  (filterList?.cate || []).length <= 0 ? "#F4ECFF" : "#fff",
                color:
                  (filterList?.cate || []).length <= 0 ? "#893DBD" : "#545454",
              }}
            />
            {categoryList?.map((cate) => (
              <Chip
                key={cate.id}
                label={cate.name_en}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.cate?.includes(cate.id) ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.cate?.includes(cate.id)
                    ? "#F4ECFF"
                    : "#fff",
                  color: filterList?.cate?.includes(cate.id)
                    ? "#893DBD"
                    : "#545454",
                }}
                onClick={() => handleSelectCategory(cate.id)}
              />
            ))}
          </Box>
        </Box>
        <Box sx={classes.filterBottom}>
          <Typography onClick={onClearFilter} ml={3}>
            Clear All
          </Typography>
          <Button
            variant="contained"
            onClick={onApplyFilter}
            sx={classes.buttonApply}
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
