export const useCampaignDetailTabStyle = {
  campDetailWrap: {
    bgcolor: { xs: "inherit", lg: "#fff" },
    boxShadow: { xs: "inherit", lg: "0px 2px 8px rgba(0, 0, 0, 0.1)" },
    padding: { xs: "0px", lg: "24px" },
    px: { xs: "0px", lg: "24px" },
    py: { xs: "0px", lg: "20px" },
  },
  campDetailCard: {
    border: { xs: "inherit", lg: "2px solid #EBDFFF" },
    borderRadius: "12px",
    padding: "24px",
    bgcolor: { xs: "#fff", lg: "inherit" },
    boxShadow: { xs: "0px 2px 8px rgba(0, 0, 0, 0.1)", lg: "inherit" },
  },
  divider: {
    my: "24px",
    mx: {
      xs: 0,
      lg: "-24px",
    },
    bgcolor: "#EBDFFF",
  },
  campDetailAccept: {
    ml: "20px",
    display: "flex",
    alignItems: "center",
    mb: "16px",
  },
  accnouceDateButton: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      fontSize: "18px",
      "&:hover": { border: "none" },
      maxHeight: "48px",
      minHeight: "48px",
      width: "365px",
      border: "none",
    },
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    mt: "30px",
    width: "100%",
    "& button": {
      width: "170px",
      minHeight: "40px",
      bgcolor: "#EBDFFF",
      border: "2px solid #EBDFFF",
      "& :hover": { border: "none" },
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  submitButtonForInflu: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    mt: "15px",
    mb: "8px",
    "& button": {
      border: "none",
      fontSize: "18px",
      maxHeight: "48px",
      width: "262px",
      "&:hover": {
        border: "none",
      },
    },
  },
  timelineDetailComp: {
    display: { xs: "none", lg: "inherit" },
    justifyContent: "center",
  },
  timelineTitleForEditBox: {
    border: "2px solid #EBDFFF",
    borderRadius: {
      xs: "12px",
      md: "0 0 0 12px",
      lg: "12px",
    },
    width: "100%",
  },
  timelineTitleForEdit: {
    display: "flex",
    justifyContent: "space-between",
    height: "104px",
    alignItems: "center",
    px: "20px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  timelineEditBtn: {
    width: "80px",
    maxHeight: "35px",
    mt: "-15px",
  },
  timelineForEditBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: "30px",
  },
  timelineTitleBox: {
    display: { xs: "none", lg: "inherit" },
    flexDirection: "column",
    alignItems: "center",
  },
  timelineTitle: {
    display: "flex",
    alignItems: "center",
    height: "20%",
    "& > svg": {
      cursor: "pointer",
      color: "#5C5C5C",
    },
  },
  nextBtn: {
    width: "200px",
    border: "none",
    "&:hover": { border: "none" },
  },
  campDetailGridCon: {
    "& .budgetAndReward": {
      borderRadius: {
        xs: "12px",
        md: "0 0 0 12px",
        lg: "12px",
      },
      zIndex: 5,
    },
    "& .timeline": {
      borderRadius: {
        xs: "12px",
        sm: "0 12px 12px 12px",
        md: "0 12px 12px 0",
        lg: "12px",
      },
      ml: {
        xs: 0,
        md: "-20px",
        lg: 0,
      },
      boxShadow: {
        md: "6px 2px 8px rgba(0, 0, 0, 0.1)",
        lg: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      },
      zIndex: 4,
    },
  },
  campaignDetailsForSmBox: {
    height: {
      xs: "100%",
      md: "initial",
    },
    display: { xs: "initial", lg: "none" },
  },
  budgetAndTimeline: {
    height: {
      xs: "100%",
      md: "88%",
    },
    border: { xs: "inherit", lg: "2px solid #EBDFFF" },
    borderRadius: "12px",
    padding: "24px",
    bgcolor: { xs: "#fff", lg: "inherit" },
    boxShadow: { xs: "0px 2px 8px rgba(0, 0, 0, 0.1)", lg: "inherit" },
    flexDirection: "column",
  },
};
