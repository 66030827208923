import React, { useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// style and redux
import { useInviteFriendsNoActivityStyle as classes } from "../MyReferral.style";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
import { useAppSelector } from "@store/hook";

export default function Completed() {
  const [referralCode, setReferralCode] = useState("Copy");
  const [referralLink, setReferralLink] = useState("Copy");
  const influInfo = useAppSelector(selectInfluencerProfile);
  const referrer = influInfo?.referer;

  return (
    <Box>
      <Box sx={classes.total}>
        <Typography variant="h3">{referrer?.general.referrals}</Typography>
        <Typography>Total Points</Typography>
      </Box>
      <Box>
        <Typography variant="title1">
          ชวนเพื่อนๆมาเป็นส่วนหนึ่งกับ “HASHU”
        </Typography>
        <Grid container spacing={3} my="16px">
          <Grid item xs={12} sm={6}>
            <Typography mb="8px">Copy referral code</Typography>
            <Box sx={classes.referralCodeLink}>
              <Typography variant="h6" color="#8C499C" ml="16px">
                {referrer?.code}
              </Typography>
              <Tooltip title={referralCode} placement="top">
                <IconButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(referrer?.code ? referrer.code : "")
                      .then(() => setReferralCode("Copied"));
                  }}
                  onMouseOut={() => setReferralCode("Copy")}
                  sx={{ mr: "8px" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mb="8px">Copy referral link</Typography>
            <Box sx={classes.referralCodeLink}>
              <Typography variant="h6" color="#8C499C" ml="16px">
                {referrer?.link}
              </Typography>
              <Tooltip title={referralLink} placement="top">
                <IconButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(referrer?.link ? referrer.link : "")
                      .then(() => setReferralLink("Copied"));
                  }}
                  onMouseOut={() => setReferralLink("Copy")}
                  sx={{ mr: "8px" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt="32px">
        <Typography variant="title1">ขั้นตอนการรับคะแนน</Typography>
        <Grid container mt={0} spacing={5}>
          {[
            "แชร์รหัสอ้างอิงของคุณกับเพื่อนๆ",
            "เพื่อนสมัครใช้งาน HashU โดยกรอกรหัสอ้างอิงหรือใช้ลิงค์เชิญของคุณ",
            "จากนั้นเพิ่มข้อมูลส่วนตัวและเชื่อมต่อโซเชียลมีเดียช่องทางใดก็ได้ 1 บัญชี ",
            "เพียงเท่านี้ คุณก็ได้รับคะแนนจาก HashU แล้ว (เพื่อน 1 คน = 1 คะแนน)",
          ].map((item, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                xl={1}
                position={index === 0 ? "relative" : undefined}
                sx={classes.timelineBtnBox}
              >
                {index === 0 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={classes.timelineDivider}
                  />
                )}

                <Box sx={classes.timelineBtn}>
                  <Typography variant="title1">{index + 1}</Typography>
                </Box>
              </Grid>
              <Grid item xs={10} xl={11} display="flex" alignItems="center">
                <Typography variant="body1">{item}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", my: "32px" }}>
        <Typography variant="title1" color="#8C499C">
          “คะแนนของคุณจะใช้ทำอะไรได้บ้าง รอติดตามข่าวสารและกิจกรรมกับเราได้เลย“
        </Typography>
      </Box>
    </Box>
  );
}
