import React, { useContext, useEffect, useRef, useState } from "react";
// mui
import {
  Box,
  Icon,
  Grid,
  Paper,
  Select,
  Avatar,
  MenuItem,
  InputBase,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// style
import { useMemberStyles as classes } from "../Member.style";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
// api and type
import { TeamAPI } from "@api/global";
import { IEmployerParameters } from "@api/team/type";
import { IMemberAdded, IMemberDialogProps } from "../Member.type";
// other
import TeamContext from "@contexts/Team";
import { permissionList } from "../Member.constants";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";

export default function AddMember(props: IMemberDialogProps) {
  const { open, onClose, onOpenToReadPermission } = props;
  const teamContext = useContext(TeamContext);
  const teamInfo = teamContext?.teamInfo;

  const [employerParameterList, setEmployerParameterList] = useState<IEmployerParameters[]>([]);
  const [memberAddedList, setMemberAddedList] = useState<IMemberAdded[]>([]);
  const [openSearchList, setOpenSearchList] = useState(false);
  const [search, setSearch] = useState("");
  const [isError, setIsError] = useState(false);

  const searchListRef = useRef<HTMLDivElement>(null);
  const memberLimit = teamInfo?.package?.campaign_management.member_limit || 0;

  useEffect(() => {
    if (!teamInfo?.id) return;

    let isMounted = true;
    TeamAPI.getEmployerParameters(teamInfo.id)
      .then((response) => {
        if (isMounted) {
          setEmployerParameterList(response);
        }
      })
      .catch((err) => {
        if (isMounted) {
          console.log("can not get employer parameters error : ", err);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [teamInfo?.id]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchListRef.current && !searchListRef.current.contains(event.target as Node)) {
        setOpenSearchList(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [searchListRef]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleAddMember = (member: IEmployerParameters) => {
    const totalMember = (memberAddedList?.length || 0) + (teamInfo?.employer?.length || 0);
    if (totalMember >= memberLimit) return;
    const memberList = memberAddedList ? [...memberAddedList] : [];
    const memberSelected: IMemberAdded = {
      id: member.id,
      email: member.email,
      full_name: member.full_name,
      profile_image_url: member.profile_image_url,
      team_role_id: 1,
    };
    setMemberAddedList([...memberList, memberSelected]);
  };

  const handlePermissionChange = (memberId: string, id: number) => {
    const memberList = memberAddedList ? [...memberAddedList] : [];
    const memberIndex = memberList.findIndex((mem) => mem.id === memberId);
    memberList[memberIndex].permission = id;
    setMemberAddedList(memberList);
  };

  const handleRemoveMember = (memberId: string) => {
    const memberList = memberAddedList ? [...memberAddedList] : [];
    setMemberAddedList(memberList.filter((mem) => mem.id !== memberId));
  };

  const onAddMember = () => {
    if (memberAddedList?.filter((mem) => mem.permission === undefined).length) {
      setIsError(true);
      return;
    }

    teamContext?.setIsLoading(true)
    const newData = memberAddedList?.map((member) => {
      return {
        id: member.id,
        team_role_id: 1,
        permission: member.permission || 0,
      };
    });

    TeamAPI.addTeamMember(teamInfo?.id || "", { employers: newData })
      .then((response) => {
        teamContext?.setTeamInfo(response)
        setMemberAddedList([])
        onClose(false);
        teamContext?.setIsLoading(false);
      })
      .catch(() => {
        teamContext?.setIsLoading(false);
      });
  };

  const memberSearchedList = employerParameterList.filter((emp) =>
    emp.full_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <DialogScrollingCustom
        open={open}
        onClose={() => onClose(false)}
        title="เพิ่มสมาชิก"
        sx={classes.dialog}
      >
        <Box p="24px" minHeight="200px">
          <Box display="flex" flexDirection="column">
            <Typography variant="title1">
              เพิ่มสมาชิกในทีม (
              {memberAddedList.length +
                (teamInfo?.package?.campaign_management?.member || 0)}
              /{teamInfo?.package?.campaign_management?.member_limit})
            </Typography>
            <Typography variant="subTitle1">
              ต้องเป็นสมาชิก HashU เท่านั้น
            </Typography>
            <Box my="16px" position="relative">
              <Paper
                component="form"
                onClick={() => setOpenSearchList(true)}
                sx={{
                  ...classes.paperSearch,
                  borderRadius:
                    openSearchList && memberSearchedList.length
                      ? "20px 20px 0 0"
                      : "50px",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by name"
                  inputProps={{ "aria-label": "Search by name" }}
                  onChange={handleSearchChange}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <Icon className="ico-hu-search" />
                </IconButton>
              </Paper>
              {openSearchList && memberSearchedList.length ? (
                <Box ref={searchListRef} sx={classes.searchList}>
                  {memberSearchedList.map((mem, index) => (
                    <Box
                      key={index}
                      display={
                        memberAddedList?.find((m) => m.id === mem.id)
                          ? "none"
                          : "flex"
                      }
                      onClick={() => handleAddMember(mem)}
                      sx={classes.searchedMemberList}
                    >
                      <Avatar src={mem.profile_image_url} />
                      <div>
                        <Typography variant="title1">
                          {mem.full_name}
                        </Typography>
                        <Typography variant="body2">{mem.email}</Typography>
                      </div>
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
          </Box>

          <Grid container rowGap={5}>
            <Grid item xs={8}>
              <Typography variant="title1">Email</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                columnGap={2}
              >
                <Typography variant="title1">Permission</Typography>{" "}
                <Icon
                  onClick={onOpenToReadPermission}
                  className="ico-hu-info-circle-outline"
                  sx={{ ml: "4px", cursor: "pointer" }}
                />
              </Box>
            </Grid>

            {/* current team member */}
            {teamInfo?.employer?.map((member, index) => (
              <React.Fragment key={index}>
                <Grid item xs={8} display="flex" alignItems="center">
                  <Box mr="12px">
                    <Avatar src={member?.profile_image_url} />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="title1">{member?.fullname}</Typography>
                    <Typography variant="caption">{member?.email}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={classes.permTxt}>
                    {permissionList.find((perm) => perm.id === member?.permission_id)?.label}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}

            {/* new team member */}
            {memberAddedList?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={8} display="flex" alignItems="center">
                  <Box mr="12px">
                    <Avatar src={item?.profile_image_url} />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="title1">{item.full_name}</Typography>
                    <Typography variant="caption">{item.email}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center" columnGap={2}>
                    <Select
                      id={`permission-${item.id}-select`}
                      sx={{ maxHeight: "40px", width: "100%", maxWidth: "150px" }}
                    >
                      {permissionList.map((per, perIndex) => (
                        <MenuItem
                          placeholder="select"
                          key={perIndex}
                          value={per.id}
                          onClick={() =>
                            handlePermissionChange(item.id, per.id)
                          }
                        >
                          {per.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton onClick={() => handleRemoveMember(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  {isError && item.permission === undefined && (
                    <Box mt="5px">
                      <Typography color="red">Permission is require</Typography>
                    </Box>
                  )}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <DialogActions sx={{ justifyContent:"center" }}>
          <SaveButton
            disabled={memberAddedList.length ? false : true}
            onClick={onAddMember}
            variant="contained"
            type="submit"
            startIcon={<Icon className="ico-hu-send" />}
            sx={{ mb:"10px" }}
          >
            <Typography variant="button">ส่งคำเชิญ</Typography>
          </SaveButton>
        </DialogActions>
      </DialogScrollingCustom>
    </>
  );
}
