import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Theme,
  styled,
  Select,
  Avatar,
  SxProps,
  MenuItem,
  SelectProps,
  FormControl,
  FormHelperText,
} from "@mui/material";

interface IBasicSelectProps extends SelectProps {
  id: string;
  value: any;
  onChange: (event: any) => void;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  data: any[];
  valueKey: string;
  labelKey: string;
  iconKey?: string;
  iconType?: "icon" | "imageUrl";
  sx?: SxProps<Theme>;
  endValue?: { value: string; total: string }[];
}

const SelectBasic = styled(Select)<SelectProps>(({ theme, ...props }) => {
  const initialStyle = {
    transition: "border-radius 0.1s",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      color: "#8C499C",
      "& > svg.MuiSvgIcon-root": {
        fontSize: "20px",
        marginRight: "4px",
        paddingBottom: "2px",
      },
      "& > .MuiAvatar-root > svg.MuiSvgIcon-root": {
        fontSize: "20px",
      },
    },
  };
  if (props.disabled) {
    return {
      ...initialStyle,
      backgroundColor: theme.palette.grey[100],
      "& .MuiSelect-select": {
        ...initialStyle["& .MuiSelect-select"],
        WebkitTextFillColor:
          props.value && props.value !== ""
            ? theme.palette.common.black
            : theme.palette.grey[300],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[100]} !important`,
      },
    };
  } else if (props.error) {
    return {
      ...initialStyle,
    };
  } else {
    return {
      ...initialStyle,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      },
    };
  }
});

const style = {
  menu: {
    color: "common.black",
    borderColor: "primary.main",
    pl: "-10px",
    maxHeight: "175px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "& > ul": {
      pt: 0,
      pb: 0,
      maxHeight: "170px",
      borderRadius: "0px",
      "& li:first-of-type": {
        borderStyle: "solid solid none solid",
        borderWidth: "2px",
        borderColor: "primary.main",
      },
      "& li": {
        borderStyle: "none solid none solid",
        borderWidth: "2px",
        borderColor: "primary.main",
      },
      "& li:last-of-type": {
        borderStyle: "none solid solid solid",
        borderWidth: "2px",
        borderRadius: "0 0 20px 20px",
      },
    },
  },
  menuItem: {
    alignItems: "center",
    "& > svg.MuiSvgIcon-root": {
      fontSize: "20px",
      mr: 1,
    },
    border: "1px solid red",
  },
  imageIcon: {
    width: 20,
    height: 20,
    marginRight: "10px",
    fontSize: "14px",
  },
  textLabel: {
    marginLeft: "10px",
  },
};

const Placeholder = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box component="div" color="grey.300">
      {children}
    </Box>
  );
};

export default function BasicSelectCustom({
  id,
  value,
  onChange,
  placeholder,
  error,
  helperText,
  fullWidth = false,
  data = [],
  valueKey,
  labelKey,
  iconKey,
  iconType = "icon",
  sx,
  endValue,
  ...props
}: IBasicSelectProps) {
  const [width, setWidth] = useState<number | string>(0);

  const target = document.getElementById(id) as HTMLElement;

  useEffect(() => {
    setWidth(target ? (target as HTMLElement).offsetWidth : "auto");
  }, [target]);

  const onSelectOpen = (method: string) => {
    if (method === "open") {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px 20px 0px 0px"
      );
    } else {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px"
      );
    }
  };

  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      sx={{
        "& .css-51hdve-MuiModal-root-MuiPopover-root-MuiMenu-root:nth-of-type(2)":
          {
            border: "1px solid red",
          },
      }}
    >
      <SelectBasic
        id={id}
        value={
          data.includes(value) || value !== null || value !== 0 ? value : ""
        }
        onChange={onChange}
        size="small"
        color="primary"
        variant="outlined"
        displayEmpty
        onOpen={() => onSelectOpen("open")}
        onClose={() => onSelectOpen("close")}
        sx={sx && (sx as object)}
        {...props}
        renderValue={
          value ? undefined : () => <Placeholder>{placeholder}</Placeholder>
        }
        MenuProps={{
          PaperProps: {
            sx: {
              ...style.menu,
              width: typeof width === "number" ? width - 4 : width,
            },
          },
        }}
      >
        {data.length > 0 ? (
          data.map((d, index) => {
            return (
              <MenuItem key={index} value={d[valueKey]} sx={style.menuItem}>
                {iconKey ? (
                  iconType === "icon" ? (
                    d[iconKey]
                  ) : (
                    <Avatar
                      variant="rounded"
                      alt={d[labelKey]}
                      src={d[iconKey]}
                      sx={style.imageIcon}
                    />
                  )
                ) : undefined}
                <Box display="flex" justifyContent="space-between" width="100%">
                  <span style={{ marginLeft: iconKey ? "10px" : 0 }}>
                    {d[labelKey]}
                  </span>
                  {endValue && (
                    <span>
                      {endValue?.find((v) => v.value === d[valueKey])?.total}
                    </span>
                  )}
                </Box>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>
            <span>No data</span>
          </MenuItem>
        )}
      </SelectBasic>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
