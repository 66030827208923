import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { JobAPI } from "@api/global";
import { IInitInfluencerJobParams, IInitInfluencerJobState } from "./type";

const initialState: IInitInfluencerJobState = {
  item: null,
  item_per_page: 0,
  page: 0,
  total_page: 0,
  total_item: 0,
  total: 0,
  listing: 0,
  offering: 0,
  working: 0,
  waiting_for_approve: 0,
  approved: 0,
  published: 0,
  completed: 0,
  rejected: 0,
  first_draft: 0,
  canceled: 0,
  loading: false,
};

export const getInfluencerJobListAsync = createAsyncThunk(
  "influencerJobListAsync",
  async (req: { influencerId: string; params: IInitInfluencerJobParams }) => {
    try {
      const response = await JobAPI.getInfluencerJobList(
        req.influencerId,
        req.params
      );
      // await console.log("response", response)
      return await response;
    } catch (err) {
      throw err;
    }
  }
);

export const InfluencerJobListSlice = createSlice({
  name: "influencerJobSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerJobListAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInfluencerJobListAsync.fulfilled, (state, { payload }) => {
        state.item = payload.item;
        console.log("payload", payload)
        state.item_per_page = payload.item_per_page;
        state.page = 100;
        state.total_page = payload.total_page;
        state.total_item = payload.total_item;
        state.total = payload.total;
        state.listing = payload.listing;
        state.offering = payload.offering;
        state.working = payload.working;
        state.waiting_for_approve = payload.waiting_for_approve;
        state.approved = payload.approved;
        state.published = payload.published;
        state.completed = payload.completed;
        state.rejected = payload.rejected;
        state.canceled = payload.canceled;
        state.first_draft = payload.first_draft;
        state.loading = false;
      })
      .addCase(getInfluencerJobListAsync.rejected, (state) => {
        state.item = null;
        state.loading = false;
      });
  },
});

export const selectInfluencerJobList = (state: RootState) => state.influencerJobList;
export const selectInfluencerJobListOnload = (state: RootState) => state.influencerJobList.loading;

export default InfluencerJobListSlice.reducer;