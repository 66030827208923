export const useDateRangeInputCustomStyle = {
  container: {
    "& .react-datepicker__day--disabled": {
      color: "#999",
      borderRadius: "50%",
    },
    "& .react-datepicker__week": {
      display: "flex",
    },
    "& .react-datepicker__day": {
      borderRadius: "50%",
      width: "27px",
      height: "27px",
    },
    "& .react-datepicker__day--selected": {
      bgcolor: "primary.main",
      color: "#fff",
    },
    "& .react-datepicker__day--in-range:not(.react-datepicker__day--selected), .react-datepicker__day--keyboard-selected":
      {
        bgcolor: "#ebdfff",
      },
  },
};
