// mui
import {
  Box,
  Card,
  Grid,
  Icon,
  Stack,
  Avatar,
  CardMedia,
  Typography,
  CardContent,
} from "@mui/material";
// icon and style
import CircleIcon from "@mui/icons-material/Circle";
import { useCampaignStyle as classes } from "./Campaign.style";
// other
import { ICampaignProps } from "./Campaign.type";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";
// dayJs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default function CampaignCard(props: ICampaignProps) {
  const { campaignInfo } = props;

  const numberFormat = Intl.NumberFormat("en");

  const getCampaignStatus = () => {
    if (campaignInfo.status === "published") {
      return (
        <Box
          sx={{
            ...classes.boxStatus,
            backgroundColor: "#893DBD",
          }}
        >
          <Typography>กำลังดำเนินการ</Typography>
          <div>
            <Typography variant="caption">
              {dayjs(campaignInfo.updated_at).fromNow()}
            </Typography>
          </div>
        </Box>
      );
    } else if (campaignInfo.status === "influencer_listing") {
      return (
        <Box
          sx={{
            ...classes.boxStatus,
            backgroundColor: "#E8008A",
          }}
        >
          <Typography>รับสมัคร</Typography>
          <div>
            <Typography variant="caption">
              {dayjs(campaignInfo.updated_at).fromNow()}
            </Typography>
          </div>
        </Box>
      );
    } else if (campaignInfo.status === "reported") {
      return (
        <Box
          sx={{
            ...classes.boxStatus,
            backgroundColor: "#0077E4",
          }}
        >
          <Typography>รายงาน</Typography>
          <div>
            <Typography variant="caption">
              {dayjs(campaignInfo.updated_at).fromNow()}
            </Typography>
          </div>
        </Box>
      );
    } else if (campaignInfo.status === "briefing") {
      return (
        <Box
          sx={{
            ...classes.boxStatus,
            backgroundColor: "#FB8C00",
          }}
        >
          <Typography>ร่างแคมเปญ</Typography>
          <div>
            <Typography variant="caption">
              {dayjs(campaignInfo.updated_at).fromNow()}
            </Typography>
          </div>
        </Box>
      );
    } else if (campaignInfo.status === "canceled") {
      return (
        <Box
          sx={{
            ...classes.boxStatus,
            backgroundColor: "#9E9E9E",
          }}
        >
          <Typography>ยกเลิก</Typography>
          <div>
            <Typography variant="caption">
              {dayjs(campaignInfo.updated_at).fromNow()}
            </Typography>
          </div>
        </Box>
      );
    } else if (campaignInfo.status === "completed") {
      return (
        <Box
          sx={{
            ...classes.boxStatus,
            backgroundColor: "#4CAF50",
          }}
        >
          <Typography>เสร็จสิ้น</Typography>
          <div>
            <Typography variant="caption">
              {dayjs(campaignInfo.updated_at).fromNow()}
            </Typography>
          </div>
        </Box>
      );
    } else {
      return <Typography>Fail</Typography>;
    }
  };

  const cardDetailBottom = [
    {
      label: campaignInfo.content_type,
      icon: <SocialIconSelected platform={campaignInfo.platform} />,
    },
    {
      label: `${numberFormat.format(Math.round(campaignInfo.avg_budget))} THB`,
      icon: <Icon className="ico-hu-money-bag" sx={classes.icon} />,
    },
    {
      label: `${campaignInfo.job_count}/${campaignInfo.job_limit}`,
      icon: <Icon className="ico-hu-person-circle" sx={classes.icon} />,
    },
  ];

  return (
    <Card sx={classes.card}>
      <CardMedia
        component="img"
        height="210px"
        image={campaignInfo?.cover_image.url}
        alt="green iguana"
        sx={{ objectFit: "cover" }}
      />
      {getCampaignStatus()}
      <Box sx={classes.cardTop}>
        <Avatar
          src={campaignInfo?.company.media.url}
          sx={classes.companyLogo}
        />
      </Box>

      <CardContent>
        <Typography variant="h6" sx={classes.campaignName}>
          {campaignInfo?.name}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={classes.category}
          divider={<CircleIcon sx={classes.categoryDivider} />}
        >
          {campaignInfo.category.map((cat) => (
            <Typography key={cat.id} sx={classes.categoryItems}>
              {cat.name_en}
            </Typography>
          ))}
        </Stack>
        <Grid container sx={{ mt: "12px" }}>
          {cardDetailBottom.map((item, index) => (
            <Grid item key={index} xs={4}>
              <Stack spacing={1} sx={classes.cardDetailBottom}>
                {item.icon}
                <Typography variant="body2">{item.label}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
