// mui and route
import { Box, Card, CardContent, Icon, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function StartTab(props: any) {
  const { item, index } = props;

  const history = useHistory();

  const goTutorials = (index: number) => {
    history.push(`/home/support/tutorials?${index}`);
  };

  return (
    <Card
      sx={{
        py: "25px",
        border: "1px #000000",
        cursor: "pointer",
      }}
      onClick={() => goTutorials(index)}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          width="85%"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50px",
              backgroundColor: "#F4ECFF",
              color: "#893DBD",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <Icon className={`ico-hu-${item.icon}`} />
          </Box>
          <Typography>{item.title}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
