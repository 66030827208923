import { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  Badge,
  Stack,
  Avatar,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
// icon
import CircleIcon from "@mui/icons-material/Circle";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import TransgenderOutlinedIcon from "@mui/icons-material/TransgenderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// component and style
import InfluencerSyncSocialData from "./SyncData";
import CategoryDialog from "@components/category-dialog";
import { useInfluencerSocialHeaderStyle as classes } from "../InfluencerSocial.style";
// redux
import {
  logoutTiktok,
  logOutYoutube,
  logoutTwitter,
  logOutFacebook,
  logOutInstagram,
  selectInfluencerSocial,
} from "@slices/InfluencerSocial.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// api and type
import { SocialAPI } from "@api/global";
import { TPlatform } from "../InfluencerSocial.type";
// other
import { ISocialAccountRes } from "@api/influencer/type";
import Loading from "@components/global/loading/Loading";

export default function InfluencerSocialHeader(props: { platform: TPlatform }) {
  const { platform } = props;

  const [editCategory, setEditCategory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("md"));

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const dispatch = useAppDispatch();
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const socialnfo = (influencerSocial?.[platform] || [])[0];
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialnfo?.social_account || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  useEffect(() => {
    if (accountInfo?.category.length === 0) {
      setEditCategory(true);
    }
  }, [accountInfo]);

  const socialLogout = () => {
    setIsLoading(true);
    try {
      if (socialnfo?.platform === "tiktok") {
        SocialAPI.signOutSocial("tiktok", socialnfo?.social_id).then(() => {
          dispatch(logoutTiktok());
          setIsLoading(false);
        });
      } else if (socialnfo?.platform === "twitter") {
        SocialAPI.signOutSocial("twitter", socialnfo?.social_id).then(() => {
          dispatch(logoutTwitter());
          setIsLoading(false);
        });
      } else if (socialnfo?.platform === "facebook") {
        SocialAPI.signOutFacebook(socialnfo?.social_id).then(() => {
          dispatch(logOutFacebook());
          setIsLoading(false);
        });
      } else if (socialnfo?.platform === "instagram") {
        SocialAPI.signOutSocial("instagram", socialnfo?.social_id).then(() => {
          dispatch(logOutInstagram());
          setIsLoading(false);
        });
      } else if (socialnfo?.platform === "youtube") {
        SocialAPI.signOutYoutube(socialnfo?.social_id).then(() => {
          dispatch(logOutYoutube());
          setIsLoading(false);
        });
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />

  return (
    <>
      <Box sx={classes.boxHeader}>
        <InfluencerSyncSocialData
          name={accountInfo?.name}
          profile={accountInfo}
          logOut={socialLogout}
          updated={accountInfo?.latest_sync_date}
          platform={socialnfo?.platform || ""}
          onClose={setEditCategory}
        />
        <Box sx={classes.boxImage}>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={
              socialnfo?.platform === "facebook" && (
                <Avatar
                  sx={{ bgcolor: "#1A77F1", width: "30px", height: "30px" }}
                >
                  <AssistantPhotoIcon sx={classes.iconAvatar} />
                </Avatar>
              )
            }
            sx={classes.badge}
          >
            <Avatar
              alt="profile-social-images"
              src={accountInfo?.profile_image}
              variant="rounded"
              sx={classes.avatar}
            />
          </Badge>
        </Box>

        <Box sx={classes.contentCon}>
          <Box
            display="flex"
            width={["100%", "78%", "60%", "80%"]}
            justifyContent={["center", "flex-start"]}
          >
            <Typography variant="h6" sx={classes.textEllipsis}>
              {accountInfo?.name}
              {accountInfo?.recommended && (
                <Icon
                  className="ico-hu-star"
                  fontSize="inherit"
                  sx={{ color: "#FFD54F", ml: 1 }}
                />
              )}
            </Typography>
          </Box>

          <Stack
            direction={["column", "row"]}
            spacing={[4, 2]}
            divider={xsScreen ? "" : <CircleIcon />}
            sx={classes.stackDetail}
          >
            <Box sx={classes.boxStackDetail}>
              <AccountCircleOutlinedIcon sx={classes.iconInStack} />
              <Typography fontSize="14px">
                {numberFormat.format(accountInfo?.follower || 0)}
              </Typography>
              <Typography fontSize="14px" color="grey.700">
                Followers
              </Typography>
            </Box>
            <Box sx={classes.boxStackDetail}>
              <PeopleOutlinedIcon sx={classes.iconInStack} />
              <Typography fontSize="14px" color="grey.700">
                Age :
              </Typography>
              <Typography fontSize="14px" ml="6px">
                {accountInfo?.target_age?.min} - {accountInfo?.target_age?.max}
              </Typography>
            </Box>
            <Box sx={classes.boxStackDetail}>
              <TransgenderOutlinedIcon sx={classes.iconInStack} />
              <Typography fontSize="14px" color="grey.700">
                Gender :
              </Typography>
              {accountInfo?.target_gender.length === 3 ? (
                <Typography fontSize="14px" ml="6px">
                  All
                </Typography>
              ) : (
                accountInfo?.target_gender.map((gender, index) => (
                  <Typography
                    key={index}
                    fontSize="14px"
                    ml="6px"
                    textTransform="capitalize"
                  >
                    {gender}
                    {index !== 2 && ","}
                  </Typography>
                ))
              )}
            </Box>
          </Stack>

          <Box display="flex" gap={2} m="12px 0 4px 0">
            <Box
              gap={3}
              justifyContent={["center", "flex-start"]}
              sx={classes.boxCategory}
            >
              {accountInfo?.category.map((cate) => (
                <Box sx={classes.itemsCategory} key={cate.id}>
                  {cate.name_en}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Category Dialog */}
      <CategoryDialog
        profile={accountInfo}
        open={editCategory}
        onClose={setEditCategory}
        socialAccountId={accountInfo?.social_account_id}
        platform={socialnfo?.platform || ""}
      />
    </>
  );
}
