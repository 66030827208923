// mui and icon
import { Grid, Typography } from "@mui/material";
import PackagePlanSummary from "./PackagePlanSummary";
import PackagePlanList from "./PackagePlanList";

export default function PackagePlan() {
  return (
    <div>
      <div>
        <Typography variant="h5">Subscription Plan</Typography>
        <Typography variant="subTitle1">การสมัครสมาชิก</Typography>
      </div>
      <Grid container spacing="24px" mt="5px">
        <Grid item xs={4}>
          <PackagePlanSummary />
        </Grid>
        <Grid item xs={8}>
          <PackagePlanList />
        </Grid>
      </Grid>
    </div>
  );
}
