import React, { useState } from "react";
// mui
import {
  Box,
  Dialog,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
  DialogContent,
} from "@mui/material";
// icon and style
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useCampaignBrieCompfStyle as classes } from "./Campaign.style";

export default function CampaignTabSm(props: any) {
  const {
    campaignTabs,
    selectStatusTab,
    handleSelectJobStatus,
    onClose,
  } = props;

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={classes.container}>
      <Box onClick={() => setOpen(true)} sx={classes.selectCampaignTabStatus}>
        <Typography variant="body1">
          {`${selectStatusTab.text} ${
            selectStatusTab.total === 0 ? "" : `(${selectStatusTab.total})`
          }`}
        </Typography>
        <ArrowDropDownIcon />
      </Box>

      <Divider sx={classes.divider} orientation="vertical" flexItem />

      <Box
        sx={classes.selectCampaignTabTitle}
        onClick={() => onClose(true)}
      >
        <MenuIcon sx={{ fontSize: "16px" }} />
        <Typography ml={2}>Filters</Typography>
      </Box>

      <Dialog
        open={open}
        onClick={() => setOpen(false)}
        fullWidth={fullScreen}
        sx={classes.dialogCon}
      >
        <DialogContent>
          {campaignTabs.slice(0, campaignTabs.length - 3).map(
            (item: any, index: number) => (
              <Box
                key={index}
                onClick={() => handleSelectJobStatus(item)}
                sx={classes.dialogMenu}
              >
                <Typography>
                  {`${item.text} ${item.total === 0 ? "" : `(${item.total})`}`}
                </Typography>
              </Box>
            )
          )}
          {campaignTabs.slice(4, campaignTabs.length).map(
            (item: any, index: number) => (
              <React.Fragment key={index}>
                {item.key === "canceled" && <Divider sx={{ my: "10px" }} />}
                <Box
                  onClick={() => handleSelectJobStatus(item)}
                  sx={{ ...classes.dialogMenuCanceled }}
                >
                  <Typography>
                    {`${item.text} ${
                      item.total === 0 ? "" : `(${item.total})`
                    }`}
                  </Typography>
                </Box>
              </React.Fragment>
            )
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
