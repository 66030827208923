import { useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and style
import { useReferralsStatsStyle as classes } from "../../OverviewTab.style";
import { IReferralsStatsProps } from "../../OverviewTab.type";
// chartJs and other
import {
  regisPieOptionsChart,
  regisBarOptionsChart,
} from "../../OverviewTab.chart";
import { Bar, Pie } from "react-chartjs-2";

export default function ReferralsStats(props: IReferralsStatsProps) {
  const { stats } = props;

  const [totalSelected, setTotalSelected] = useState("total");

  const colorMatchGender = (gender: string) => {
    switch (gender) {
      case "female":
        return "#FB79C3";
      case "male":
        return "#1D9BF0";
      case "lgbtq":
        return "#893DBD";
      default:
        return "#9E9E9E";
    }
  };

  const genderMatchTxt = (gender: string) => {
    if (gender === "lgbtq") {
      return "LGBT+";
    } else if (gender === "none") {
      return "N/A";
    } else {
      return gender;
    }
  };

  const piChart = stats?.pi_chart || [];
  const histChart = stats?.hist_chart || [];

  function createData(
    label: string,
    color: string,
    total?: string,
    width?: string,
    without?: string
  ) {
    return { label, color, total, width, without };
  }

  const numberFormat = (val?: { number: number; percent: number }) => {
    if (val) {
      const number = val.number === 0 ? 0 : Intl.NumberFormat("en").format(val.number) || 0;
      return `${number} (${val.percent.toFixed(2)}%)` || "";
    }
  };

  const statsList = piChart.map((stat) => {
    return createData(
      stat.label,
      colorMatchGender(stat.label),
      numberFormat(stat.total),
      numberFormat(stat.with),
      numberFormat(stat.without)
    );
  });

  const pieData = {
    labels: piChart.map((pi) => pi.label),
    datasets: [
      {
        label: "# of Votes",
        data: piChart.map((pi) => pi.total.number),
        backgroundColor: piChart.map((pi) => colorMatchGender(pi.label)),
        borderWidth: 1,
        borderColor: "#fff",
        color: "#fff",
      },
    ],
  };

  const barData = {
    labels: histChart?.[3]?.value.map((val) => val.label),
    datasets: [
      {
        label: "N/A",
        data: histChart?.[3]?.value.map((val) => val.value),
        backgroundColor: "#9E9E9E",
        borderWidth: 1,
        width: 50,
        barPercentage: 0.3,
        fill: true,
      },
      {
        label: "LGBT+",
        data: histChart?.[2]?.value.map((val) => val.value),
        backgroundColor: "#893DBD",
        borderWidth: 1,
        width: 50,
        barPercentage: 0.3,
        fill: true,
      },
      {
        label: "Male",
        data: histChart?.[1]?.value.map((val) => val.value),
        backgroundColor: "#1D9BF0",
        borderWidth: 1,
        width: 50,
        barPercentage: 0.3,
        fill: true,
      },
      {
        label: "Female",
        data: histChart?.[0]?.value.map((val) => val.value),
        backgroundColor: "#FB79C3",
        borderWidth: 1,
        width: 50,
        barPercentage: 0.3,
        fill: true,
        borderRadius: 4,
      },
    ],
  };

  return (
    <Box sx={classes.title}>
      <Typography variant="h6">Registration & Referrals </Typography>
      <Typography variant="subTitle2">การลงทะเบียนกับการอ้างอิง</Typography>
      <Grid container my="20px" spacing="12px">
        {[
          {
            key: "total",
            label: "Total Registered",
            total: stats?.total,
            icon: "ico-hu-group",
            color: "#893DBD",
            iconColor: "primary",
          },
          {
            key: "widthRef",
            label: "With Referrals",
            total: stats?.refferals,
            icon: "ico-hu-campaign-done",
            color: "#4CAF50",
            iconColor: "success",
          },
          {
            key: "withoudRef",
            label: "Without Referrals",
            total: stats?.not_refferals,
            icon: "ico-hu-campaign-cancel",
            color: "error",
            iconColor: "error",
          },
        ].map((item, index) => (
          <Grid key={index} item xs={4}>
            <Box
              onClick={() => setTotalSelected(item.key)}
              sx={
                totalSelected === item.key
                  ? classes.totalSelectedBox
                  : classes.totalUnSelectedBox
              }
            >
              <Box ml="16px">
                <Typography variant="subTitle1">{item.label}</Typography>
                <Typography variant="h5" color={item.color}>
                  {Intl.NumberFormat("en").format(item.total || 0)}
                </Typography>
              </Box>
              <Box
                bgcolor={totalSelected === item.key ? "#fff" : "#F5F6F8"}
                sx={classes.total}
              >
                <Icon
                  color={item.iconColor as any}
                  className={item.icon}
                  fontSize="large"
                />
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Stack direction="row" sx={classes.statsPie} spacing={15}>
            <Box width="200px" height="200px">
              <Pie options={regisPieOptionsChart} data={pieData} />
            </Box>
            <Box width={totalSelected === "total" ? "100%" : undefined}>
              <TableContainer>
                <Table
                  sx={
                    totalSelected === "total"
                      ? classes.tableTotal
                      : classes.tableUnTotal
                  }
                >
                  {totalSelected === "total" && (
                    <TableHead>
                      <TableRow sx={classes.tHead}>
                        {["", "Total", "With Ref.", "Without Ref."].map(
                          (row, index) => (
                            <TableCell key={index} align="right">
                              {row}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {statsList.map((row, index) => (
                      <TableRow key={index} sx={classes.tBody}>
                        <TableCell component="th" scope="row">
                          <Box display="flex" alignItems="center">
                            <CircleIcon
                              sx={{
                                fontSize: "12px",
                                mr: "12px",
                                color: row.color,
                              }}
                            />
                            <Typography
                              variant="title2"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {genderMatchTxt(row.label)}
                            </Typography>
                          </Box>
                        </TableCell>
                        {totalSelected === "total" && (
                          <TableCell align="right">
                            <Typography variant="title2">
                              {row.total}
                            </Typography>
                          </TableCell>
                        )}
                        {(totalSelected === "widthRef" ||
                          totalSelected === "total") && (
                          <TableCell align="right">{row.width}</TableCell>
                        )}
                        {(totalSelected === "withoudRef" ||
                          totalSelected === "total") && (
                          <TableCell align="right">{row.without}</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box mx="30px" height={{ xs: "423px", xl: "400px" }} width="95%">
              <Bar options={regisBarOptionsChart} data={barData} />
            </Box>
            <Stack direction="row" spacing={4} mt="20px">
              {piChart.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                >
                  <CircleIcon
                    sx={{
                      color: colorMatchGender(item.label),
                      fontSize: "12px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {genderMatchTxt(item.label)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
