import { Card } from "@mui/material";
import React from "react";
import Eight from "./Eight";
import Five from "./Five";
import Four from "./Four";
import Introduction from "./Introduction";
import Seven from "./Seven";
import Six from "./Six";
import Three from "./Three";
import Two from "./Two";

export default function Influencer() {
  return (
    <Card sx={{ width: "95%", height: "100%" }}>
      <Introduction />
      <Two />
      <Three />
      <Four />
      <Five />
      <Six />
      <Seven />
      <Eight />
    </Card>
  );
}
