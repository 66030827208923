// mui and style
import { Box, Typography } from "@mui/material";
import { useCurrentNoActivityStyle as classes } from "../MyReferral.style";

export default function NoActivity() {
  return (
    <Box sx={classes.con}>
      <img
        src={require("@assets/images/referral/no-activity.png")}
        alt="no activity"
      />
      <Box sx={{ textAlign: "center", my: "24px" }}>
        <Typography variant="h5">ไม่มีกิจกรรมในขณะนี้</Typography>
        <Typography>รอติดตามข่าวสารและกิจกรรมใหม่ๆของเราได้เลย</Typography>
      </Box>
    </Box>
  );
}
