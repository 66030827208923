import React, { useEffect, useState } from "react";
// mui and icons
import {
  Box,
  Grid,
  Menu,
  Paper,
  Stack,
  Divider,
  MenuItem,
  InputBase,
  IconButton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EventCard from "@components/home-events/EventsCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// style
import { useEventsStyle as classes } from "./Events.style";
// api and type
import { AppInformationAPI } from "@api/global";
import { IActivityList } from "./HomeEvent.type";
import { IGetActivityListParams } from "@api/app-information/type";
// other
import { useHistory } from "react-router-dom";
import Loading from "@components/global/loading/Loading";

const initParams: IGetActivityListParams = {
  page: 1,
  item_per_page: 100,
  order_by: "DESC",
};

export default function HomeEvents() {
  const [activityList, setActivityList] = useState<IActivityList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cateAnchorEl, setCateAnchorEl] = useState<null | HTMLElement>(null);
  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const [params, setParams] = useState(initParams);

  const cateOpen = Boolean(cateAnchorEl);
  const dateOpen = Boolean(dateAnchorEl);
  const history = useHistory();

  const handleOpenCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.id;
    if (id === "category-box") {
      setCateAnchorEl(event.currentTarget);
    } else {
      setDateAnchorEl(event.currentTarget);
    }
  };

  const handleCloseCategory = () => {
    setCateAnchorEl(null);
    setDateAnchorEl(null);
  };

  useEffect(() => {
    function fetchData() {
      setIsLoading(true);
      AppInformationAPI.getActivityList(params)
        .then((response) => {
          setActivityList(response.item);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    fetchData();
  }, [params]);

  const handleSearchKeyword = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams({
      ...params,
      keyword: e.target.value,
    });
  };

  const handleSelectCategory = (cate: string) => {
    handleCloseCategory();
    setParams({
      ...params,
      status: cate,
    });
  };

  const handleSelectDate = (date: string) => {
    handleCloseCategory();
    setParams({
      ...params,
      date: date,
    });
  };

  const onViewEventsDetail = (activityId: string) => {
    history.push(`/home/news-and-events/${activityId}`);
  };

  if (isLoading) return <Loading />

  return (
    <Box sx={classes.conatiner}>
      <Box sx={classes.titleWrap}>
        <Typography variant="h3">News & Events</Typography>
        <Typography fontSize="24px" mt="8px">
          ข่าวสารและกิจกรรม
        </Typography>
      </Box>
      <Box
        id="back-to-top-anchor"
        display="flex"
        justifyContent="center"
        my="40px"
      >
        <Paper sx={classes.paper}>
          <Stack
            direction="row"
            width="100%"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ my: "15px", display: ["none", "initial"] }}
              />
            }
          >
            <Box sx={classes.searchWrap}>
              <InputBase
                sx={{ ml: 4, flex: 1 }}
                placeholder="ค้นหาจาก Keyword..."
                inputProps={{ "aria-label": "search keyword" }}
                value={params.keyword}
                onChange={handleSearchKeyword}
              />
              <IconButton
                type="button"
                sx={{ p: "10px", height: "45px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Box>
            <Box
              id="category-box"
              aria-controls={cateOpen ? "category-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={cateOpen ? "true" : undefined}
              onClick={handleOpenCategory}
              sx={classes.selectWrap}
              component="button"
            >
              <Typography textTransform="capitalize" whiteSpace="nowrap">
                {params.status === undefined
                  ? "ประเภท"
                  : params.status === ""
                  ? "All"
                  : params.status}
              </Typography>
              <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Menu
              id="category-menu"
              anchorEl={cateAnchorEl}
              open={cateOpen}
              onClose={handleCloseCategory}
              MenuListProps={{
                "aria-labelledby": "category-box",
              }}
              sx={classes.menuSelect}
            >
              {[
                "all",
                "new event",
                "feature update",
                "community",
                "maintenance",
              ].map((cate, index) => (
                <MenuItem
                  onClick={() =>
                    handleSelectCategory(cate === "all" ? "" : cate)
                  }
                  sx={classes.menuItem}
                  key={index}
                >
                  <Typography fontSize="14px" textTransform="capitalize">
                    {cate}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>

            <Box
              id="date-Box"
              aria-controls={dateOpen ? "date-menu" : undefined}
              aria-haspopup="true"
              onClick={handleOpenCategory}
              component="button"
              sx={classes.selectWrap}
            >
              <Typography>{params.date ? params.date : "เลือกวัน"}</Typography>
              <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Menu
              id="date-menu"
              anchorEl={dateAnchorEl}
              open={dateOpen}
              onClose={handleCloseCategory}
              MenuListProps={{
                "aria-labelledby": "date-box",
              }}
              sx={classes.menuSelect}
            >
              {[
                "Any date",
                "Today",
                "Tomorrow",
                "This week",
                "Next week",
                "This month",
                "Next month",
              ].map((cate, index) => (
                <MenuItem
                  onClick={() => handleSelectDate(cate)}
                  sx={classes.menuItem}
                  key={index}
                >
                  {cate}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Paper>
      </Box>
      <Grid container spacing={["20px", "20px", "40px"]}>
        {activityList.map((event, index) => (
          <Grid item xs={12} sm={6} md={12} key={index}>
            <Box
              onClick={() => onViewEventsDetail(event.id)}
              sx={classes.cardWrap}
            >
              <EventCard event={event} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
