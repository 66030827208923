import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { InfluencerData as data } from "./InfluencerData";

export default function Influencer() {
  return (
    <Card sx={{ width: { xs: "95%", xl: "50%" }, p: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "30px",
        }}
      >
        <Typography variant="h4">ข้อกำหนดการใช้งาน</Typography>
      </Box>

      {/* first condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.first.title}
        </Typography>
        {data.first.item1}
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.first.item2.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        {[data.first.item3, data.first.item4, data.first.item5].map(
          (item, index) => (
            <Typography key={index} mb={3}>
              {item}
            </Typography>
          )
        )}
      </Box>

      {/* second condition */}
      <Box>
        <Typography variant="h6">{data.second.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.second.item1.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
      </Box>

      {/* third condition */}
      <Box>
        <Typography variant="h6">{data.third.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.third.item1.map((item, index) => (
            <li key={index}>
              <Typography>{item.detail}</Typography>
              <Box component="ul" my="15px">
                {item.detailItem.map((detailItem, detailIndex) => (
                  <li key={detailIndex}>
                    <Typography>{detailItem}</Typography>
                  </li>
                ))}
              </Box>
            </li>
          ))}
        </Box>
      </Box>

      {/* fourth condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={3}>
          {data.fourth.title}
        </Typography>
        <Typography mb={4}>{data.fourth.item1}</Typography>
        <Typography>{data.fourth.item2}</Typography>
      </Box>

      {/* fifth condition */}
      <Box>
        <Typography variant="h6" mb={3}>
          {data.fifth.title}
        </Typography>
        <Typography>{data.fifth.item1}</Typography>
        <Typography mt={4.5}>{data.fifth.item2.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.fifth.item2.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        {[
          data.fifth.item3,
          data.fifth.item4,
          data.fifth.item5,
          data.fifth.item6,
        ].map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* sixth condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={3}>
          {data.sixth.title}
        </Typography>
        <Typography mb={3}>{data.sixth.item1}</Typography>
        <Typography>{data.sixth.item2.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.sixth.item2.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        <Typography mb={3}>{data.sixth.item3}</Typography>
        <Typography>{data.sixth.item4.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.sixth.item4.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        <Typography>{data.sixth.item5}</Typography>
      </Box>

      {/* seventh condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.seventh.title}
        </Typography>
        <Typography>{data.seventh.item1}</Typography>
      </Box>

      {/* eighth condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.eighth.title}
        </Typography>
        <Typography>{data.eighth.item1.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.eighth.item1.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        <Typography>{data.eighth.item2.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.eighth.item2.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
      </Box>

      {/* ninth condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.ninth.title}
        </Typography>
        <Typography mb={3}>{data.ninth.item1}</Typography>
        <Typography mb={3}>{data.ninth.item2}</Typography>
        <Typography>{data.ninth.item3.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.ninth.item3.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        {[data.ninth.item4, data.ninth.item5, data.ninth.item6].map(
          (item, index) => (
            <Typography key={index} mb={3}>
              {item}
            </Typography>
          )
        )}
      </Box>

      {/* tenth condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.tenth.title}
        </Typography>
        <Typography mb={3}>{data.tenth.item1.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.tenth.item1.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        {[data.tenth.item2, data.tenth.item3].map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* eleventh condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.eleventh.title}
        </Typography>
        <Typography>{data.eleventh.item1}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.eleventh.item2.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
      </Box>

      {/* twelft condition */}
      <Box mb={4}>
        <Typography variant="h6">{data.twelfth.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.twelfth.item1.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
      </Box>

      {/* thirteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.thirteen.title}
        </Typography>
        {[data.thirteen.item1, data.thirteen.item2].map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* fourteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.fourteen.title}
        </Typography>
        {[data.fourteen.item1, data.fourteen.item2].map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.fourteen.item3.map((item, index) => (
            <li key={index}>
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </li>
          ))}
        </Box>
        {data.fourteen.item4}
      </Box>

      {/* fifteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.fifteen.title}
        </Typography>
        <Typography sx={{ textDecoration: "underline" }}>
          {data.fifteen.item1.title}
        </Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.fifteen.item1.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        <Typography sx={{ textDecoration: "underline" }}>
          {data.fifteen.item2.title}
        </Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.fifteen.item2.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
        <Typography sx={{ textDecoration: "underline" }}>
          {data.fifteen.item3.title}
        </Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.fifteen.item3.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
      </Box>

      {/* sixteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.sixteen.title}
        </Typography>
        <Typography>{data.sixteen.item1}</Typography>
      </Box>

      {/* seventeen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.seventeen.title}
        </Typography>
        <Typography mb={3} sx={{ textDecoration: "underline" }}>
          {data.seventeen.item1.title}
        </Typography>
        {data.seventeen.item1.itemDetail.map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
        <Typography mb={3} sx={{ textDecoration: "underline" }}>
          {data.seventeen.item2.title}
        </Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          <li>{data.seventeen.item2.itemDetail}</li>
        </Box>
      </Box>

      {/* eighteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.eighteen.title}
        </Typography>
        {data.eighteen.item.map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* ninteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.ninteen.title}
        </Typography>
        {data.ninteen.item.map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* twenteen condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.twenteen.title}
        </Typography>
        <Typography>{data.twenteen.item1}</Typography>
      </Box>

      {/* twenty one condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.twentyOne.title}
        </Typography>
        {data.twentyOne.item.map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* twenty two condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.twentyTwo.title}
        </Typography>
        <Typography mb={3}>{data.twentyTwo.item}</Typography>
      </Box>

      {/* twenty three condition */}
      <Box mb={4}>
        <Typography variant="h6" mb={4}>
          {data.twentyThree.title}
        </Typography>
        {data.twentyThree.item.map((item, index) => (
          <Typography key={index} mb={3}>
            {item}
          </Typography>
        ))}
      </Box>

      {/* twenty four condition */}
      <Box>
        <Typography variant="h6" mb={4}>
          {data.twentyFour.title}
        </Typography>
        <Typography>{data.twentyFour.item.title}</Typography>
        <Box
          component="ul"
          sx={{ "& li": { fontSize: "16px", fontWeight: 300 } }}
          my="15px"
        >
          {data.twentyFour.item.itemDetail.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </Box>
      </Box>
    </Card>
  );
}
