import React, { useEffect, useState } from "react";
// mui 
import {
  Box,
  Icon,
  Badge,
  Stack,
  Button,
  Drawer,
  Avatar,
  AppBar,
  Divider,
  Toolbar,
  Popover,
  Typography,
  IconButton,
} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useHeaderBarStyle as classes } from "./HeaderBar.style";
// api and type
import { AuthenticationAPI, NotificationAPI } from "@api/global";
import { IMenu } from "../sidebar/Sidebar.types";
// icon
import MenuIcon from "@mui/icons-material/Menu";
import { KeyboardArrowDown } from "@mui/icons-material";
import logo from "@assets/images/logo/hashu-logo2.svg";
// component
import ItemSideBar from "../sidebar/ItemSideBar";
import NotificationDialog from "@components/global/notification/NotificationDialog";
// util
import { compareDate } from "@utils/helpers/dateComparations.helper";
import { getOperatingSystem } from "@utils/helpers/getOperatingSystem";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// other
import { useAuth } from "@contexts/Auth";
import { useHistory } from "react-router-dom";
import allMenu from "../sidebar/menus_list.json";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import NotificationSnackbar, { INotificationInfo } from "@components/global/notification/NotiSnackbar";
import { selectEmployerNotifications, selectInfluencerNotifications, setEmployerNotification, setInfluencerNotification } from "@slices/Notification.slice";
// firebase
import firebaseApp from "../../firebase";
import { getMessaging, onMessage } from "firebase/messaging";

function UserCard() {
  const history = useHistory();
  const { signOut } = useAuth();
  const { session_token } = getTokenFromStorage();

  const onLogout = async () => {
    try {
      await AuthenticationAPI.signOut();
      const next = () => history.push("/");
      signOut(next);
    } catch (error) {
      const next = () => history.push("/");
      signOut(next);
    }
  };

  return (
    <Box sx={{ width: "230px", p: "10px" }}>
      <Box display="flex" justifyContent="center">
        {session_token?.profile_image_url ? (
          <Avatar
            alt="user-profile-img"
            src={session_token?.profile_image_url}
            sx={classes.profileAvatar}
          />
        ) : (
          <Avatar sx={classes.profileAvatar}>
            {session_token?.fullname.charAt(0)}
          </Avatar>
        )}
      </Box>
      <Typography align="center" fontWeight={700}>
        {session_token?.fullname || ""}
      </Typography>
      <Typography variant="subtitle2" align="center">
        ({session_token?.code || ""})
      </Typography>

      <Box mt="10px">
        <Box sx={classes.boxMenu} onClick={() => history.push(`/`)}>
          <Box sx={classes.boxMenuIcon}>
            <Icon className="ico-hu-home" />
          </Box>
          <Box width="70%">
            <Typography variant="caption">HashU Homepage</Typography>
          </Box>
        </Box>

        {session_token?.role === "influencer" && (
          <Box
            sx={classes.boxMenu}
            onClick={() => history.push(`/my-referral`)}
          >
            <Box sx={classes.boxMenuIcon}>
              <Icon className="ico-hu-group" />
            </Box>
            <Box width="70%">
              <Typography variant="caption">My Referral</Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Box sx={classes.boxMenuLogout} onClick={onLogout}>
          <Box sx={classes.boxMenuIcon}>
            <Icon className="ico-hu-logout" />
          </Box>
          <Box width="70%">
            <Typography variant="caption">ออกจากระบบ</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function NotificationCard() {
  const influencerNotifications = useAppSelector(selectInfluencerNotifications);
  const employerNotifications = useAppSelector(selectEmployerNotifications);

  const dispatch = useAppDispatch()
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const onMakeAReadNotification = (id:string, landingPage:string) => {
    NotificationAPI.makeAsRead({ id, read: true }).then(() => {
      NotificationAPI.getNotificationList().then((response) => {
        if (role === "influencer") {
          dispatch(setInfluencerNotification(response))
          window.open(landingPage, "_self")
        } else if (role === "admin" || role === "employer") {
          dispatch(setEmployerNotification(response))
          window.open(landingPage, "_self")
        }
      })
    })
  }

  const onMakeReadAllNotification = () => {
    NotificationAPI.makeAsReadAll({ user_id: session_token?.user_id || "" }).then(() => {
      NotificationAPI.getNotificationList().then((response) => {
        if (role === "influencer") {
          dispatch(setInfluencerNotification(response))
        } else if (role === "admin" || role === "employer") {
          dispatch(setEmployerNotification(response))
        }
      })
    })
  }

  return (
    <Box sx={{ width: "320px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p="15px">
        <Typography variant="title1">Notification</Typography>
        <Typography onClick={onMakeReadAllNotification} variant="caption" color="primary" sx={{ cursor:"pointer" }}>
          Mark all as read
        </Typography>
      </Box>
      <Stack 
        divider={<Divider />} 
        sx={classes.notiContent}
      >
        {role === "influencer" ?
          influencerNotifications?.map((noti, index) => (
            <Box 
              key={index}
              display="flex"
              gap={3}
              sx={{ 
                cursor:"pointer",
                p: "15px",
                bgcolor: !noti.read ? "#F5F6F8" : "#fff",
                borderLeft: !noti.read ? "4px solid #8C499C" : "none",
              }}
              onClick={() => onMakeAReadNotification(noti.notification_id, noti.landing.page)}
            >
              <Avatar src={noti.image_url || require("@assets/images/people/man.jpg")} sx={{ width:"40px", height:"40px" }} />
              <div>
                <Typography 
                  fontSize="14px" 
                  className="block-ellipsis"
                >
                  {noti.title} <span style={{ fontWeight: 500 }}>{noti.message}</span>
                </Typography>
                <Typography fontSize="12px">{compareDate(noti.updated_at)}</Typography>
              </div>
            </Box>
          )) : 
          employerNotifications?.map((noti, index) => (
            <Box 
              key={index}
              display="flex"
              gap={3}
              sx={{ 
                cursor:"pointer",
                p: "15px",
                bgcolor: !noti.read ? "#F5F6F8" : "#fff",
                borderLeft: !noti.read ? "3px solid #8C499C" : "none",
              }}
              onClick={() => onMakeAReadNotification(noti.notification_id, noti.landing.page)}
            >
              <Avatar
                src={noti.image_url}
                sx={{ width:"40px", height:"40px" }} 
              />
              <div>
                <Typography 
                  fontSize="14px"
                  className="block-ellipsis"
                >
                  {noti.title} <span style={{ fontWeight: 500 }}>{noti.message}</span>
                </Typography>
                <Typography fontSize="12px">{compareDate(noti.updated_at)}</Typography>
              </div>
            </Box>
          ))
        } 
      </Stack>
    </Box>
  )
}

function HeaderBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSelected, setMenuSelected] = useState<null | "notification" | "user">(null);
  const [menuMobileState, setMenuMobileState] = useState(false);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [openNotiDialog, setOpenNotiDialog] = useState(false)

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState<INotificationInfo>();

  const dispatch = useAppDispatch()
  const influencerNotifications = useAppSelector(selectInfluencerNotifications);
  const employerNotifications = useAppSelector(selectEmployerNotifications);

  const history = useHistory();
  const auth = useAuth();
  const { session_token } = getTokenFromStorage();

  const messaging = getMessaging(firebaseApp);

  useEffect(() => {
    const pathSplitted = history.location.pathname.split("/");
    pathSplitted.shift(); // * ไม่เอาค่าว่างมานับ index: 0 : sidebar
    const mainPath = "/" + pathSplitted[0];
    const menuList: IMenu[] =
      auth.role === "influencer"
        ? allMenu.influencer
        : auth.role === "employer"
        ? allMenu.employer
        : allMenu.admin;

    for (const menu of menuList) {
      if (menu.path === mainPath) {
        menu.isActive = false;
      }
    }
    setMenus([...menuList]);
  }, [auth.role, history]);

  useEffect(() => {
    if ("Notification" in window) {
      onMessage(messaging, (message) => {
        const data:any = message?.data;
        const notification:any = message?.notification;
        setNotificationInfo({ 
          title: data?.title || notification?.title, 
          body: data?.text || notification?.body, 
          image: data?.icon_url || notification?.image,
          redirect_url: data?.redirect_url
        })
        setOpenNotification(true)
        NotificationAPI.getNotificationList().then((noti) => {
          if (session_token?.role === "influencer") {
            dispatch(setInfluencerNotification(noti));
          } else if (session_token?.role === "admin" || session_token?.role === "employer") {
            dispatch(setEmployerNotification(noti));
          }
        });
      })
    }

    // eslint-disable-next-line
  },[]);

  const handleOpenAnchor = (
    event: React.MouseEvent<HTMLElement>,
    menuName: "notification" | "user"
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMenuSelected(menuName);
  };

  const open = Boolean(anchorEl);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setMenuMobileState(open);
  };

  const setDefaultMenu = () => {
    for (const menu of menus) {
      menu.isActive = false;
    }
  };

  const goPage = (path?: string) => {
    if (path !== undefined) {
      history.push(path);
    } else {
      history.push("/");
    }
  };

  const handleOpenMenu = (index: number) => {
    setDefaultMenu();
    let path = "";
    if (menus) {
      menus[index].isActive = true;
      path = menus[index].path;
      setMenus([...menus]);
      goPage(path);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        color="primary"
        elevation={1}
        sx={classes.appBar}
      >
        <Toolbar sx={classes.toolbar}>
          <Box sx={classes.logoBox} onClick={toggleDrawer(true)}>
            <MenuIcon />
          </Box>
          <Box sx={classes.boxToobar}>
            <IconButton onClick={(event) => {
              if ((getOperatingSystem() === "iOS" && !("Notification" in window)) || Notification.permission !== "granted") {
                setOpenNotiDialog(true)
              } else {
                handleOpenAnchor(event, "notification")
              }
            }}>
              {session_token?.role === "influencer" ? (
                <Badge
                  badgeContent={influencerNotifications?.filter((noti) => !noti.read).length}
                  color="error"
                >
                  <NotificationsIcon sx={{ color:"#fff", fontSize:"25px" }} />
                </Badge>
              ) : (
                <Badge
                  badgeContent={employerNotifications?.filter((noti) => !noti.read).length}
                  color="error"
                >
                  <NotificationsIcon sx={{ color:"#fff", fontSize:"25px" }} />
                </Badge>
              )}
            </IconButton>

            {/* noti */}
            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={classes.headerBarButton}
              onClick={(event) => handleOpenAnchor(event, "user")}
            >
              {session_token?.profile_image_url ? (
                <Box mr={2}>
                  <Avatar src={session_token?.profile_image_url} alt="profile" />
                </Box>
              ) : (
                <Box mr={2}>
                  <Avatar className="avatar-img">
                    {session_token?.fullname.charAt(0)}
                  </Avatar>
                </Box>
              )}
              <Box sx={classes.boxFullname}>
                <Typography variant="subtitle2" color="common.white">
                  {session_token?.fullname.split(" ")[0]}
                </Typography>
              </Box>
              <Box sx={classes.boxIcon}>
                <KeyboardArrowDown style={{ fontSize: "16px" }} />
              </Box>
            </Button>
            <Popover
              sx={classes.popover}
              open={open}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {menuSelected === "user" ? <UserCard /> : <NotificationCard/>}
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={menuMobileState}
        onClose={toggleDrawer(false)}
        sx={classes.drawer}
      >
        <Box
          onClick={toggleDrawer(false)}
          sx={classes.sidebarContent}
        >
          <Box sx={classes.sidebarLogo}>
            <img src={logo} alt="Hashu Logo" width={"52px"} />
          </Box>
          <ItemSideBar menus={menus} handleOpenMenu={handleOpenMenu} />
        </Box>
      </Drawer>

      <NotificationDialog
        open={openNotiDialog}
        onClose={() => setOpenNotiDialog(false)}
      />

      <NotificationSnackbar
        autoHideDuration={5000}
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        data={notificationInfo}
      />
    </div>
  );
}

export default HeaderBar;
