import { useEffect, useState } from "react";
// mui and icons
import {
  Box,
  Fab,
  Icon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// api and type
import { AppInformationAPI } from "@api/global";
import { IMultiImagesInputProps } from "../CampaignBrief.type";
import { IFile } from "@views/campaign/create-campaign/CreateCampaign.type";
// style and others
import { useMultiImagesInputStyle as classes } from "../CampaignBrief.style";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";

export default function MultiImagesInput(props: IMultiImagesInputProps) {
  const {
    keyName,
    images,
    errors,
    form: { setValue },
  } = props;

  const [imageList, setImageList] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setImageList(images || []);
  }, [images]);

  const onInputImage = async (ev: any) => {
    const files = ev.target.files;
    const uploadPromises = [];

    setIsLoading(true);

    if (files) {
      for (const file of files) {
        const fileData = {
          file: file,
          destination: "company",
          permission: "public",
        };
        const result = await AppInformationAPI.uploadAttachment(fileData)
          .then((response) => {
            return {
              file_id: "",
              file_name: response.file_name,
              file_type: response.file_type,
              url: response.public_url as string,
              description: response.destination,
              purpose: "",
            };
          })
          .catch((err) => {
            setIsLoading(false);
            SwalCustom.fire({
              icon: "error",
              title: "Oops !",
              text: `${err.response.data.error.message}`,
              confirmButtonColor: "#E30D18",
              confirmButtonText: "ตกลง",
            });
          });
        uploadPromises.push(result);
      }
    }

    Promise.all(uploadPromises)
      .then((results) => {
        setIsLoading(false);
        if (results[0]) {
          setImageList((imageList: any) => [...imageList, ...results]);
          setValue(keyName as any, [...imageList, ...results]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const errMsg = error.response.data.error.message;
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${errMsg ? errMsg : "มีบางอย่างผิดพลาด"}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  const handleRemoveImage = (index: number) => {
    const data = [...imageList];
    data.splice(index, 1);
    setImageList(data);
    setValue(keyName as any, data);
  };

  const handleChangeImage = async (e: any, fileIindex: number) => {
    const files = e.target.files[0];
    const data = {
      file: files,
      destination: "company",
      permission: "public",
    };
    const imgData = [...imageList];
    await AppInformationAPI.uploadAttachment(data)
      .then((response) => {
        imgData[fileIindex].file_name = response.file_name;
        imgData[fileIindex].url = response.public_url
          ? response.public_url
          : "";
        imgData[fileIindex].file_type = response.file_type;
        setImageList(imgData);
        setValue(keyName as any, imgData);
      })
      .catch((err) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${err.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  return (
    <Stack direction="row" columnGap={4} rowGap={4} flexWrap="wrap">
      {(imageList || []).map((item, index) => (
        <Box key={index} position="relative" sx={classes.imageDataBox}>
          <Fab onClick={() => handleRemoveImage(index)} sx={classes.removeImgBtn}>
            <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
          </Fab>
          <Box component="label" sx={classes.imageDataList}>
            <img src={item.url} alt="item" />
            <input
              type="file"
              accept="image/*"
              id="input-file"
              style={{ display: "none" }}
              onChange={(e) => handleChangeImage(e, index)}
            />
          </Box>
        </Box>
      ))}

      <Box>
        <Box
          component="label"
          sx={{ ...classes.labelUpload, borderColor: errors ? "red" : "grey" }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb="-5px">
                <Icon
                  className="ico-hu-p-photo"
                  sx={{ fontSize: "38px", color: "#616161" }}
                />
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Icon
                  className="ico-hu-upload"
                  sx={{ fontSize: "10px", mt: "3px" }}
                />
                <span style={{ fontSize: "12px" }}>Image</span>
              </Stack>
              <input
                type="file"
                multiple
                accept="image/*"
                id="input-file"
                onChange={onInputImage}
                style={{ display: "none" }}
              />
            </>
          )}
        </Box>

        {errors && (
          <Typography color="error" fontSize="12px" mt={1}>
            {errors?.message}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
