// mui and icon
import { Box, Icon, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and type
import { useCandidateCardStyle as classes } from "../InfluencerList.style";
import { CardInfluencerSelectedProps } from "../InfluencerList.type";
import { ICategory } from "@api/app-information/type";
// other
import { useHistory } from "react-router-dom";
import GetPlatform from "@global/get-platform";

export default function AwenessCard(props: CardInfluencerSelectedProps) {
  const { item } = props;
  const history = useHistory();

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const goViewInfluencer = (influId: string) => {
    history.push(`/influencers/${influId}/profile?platform=${item.platform}&status=view`);
  };

  return (
    <Box
      sx={{ position: "relative" }}
      onClick={() => goViewInfluencer(item.influencer_id)}
    >
      <Box sx={classes.boxImage}>
        <img
          src={item.social_account_images[0]}
          className="first-image"
          alt="firstimage"
          style={{ border: "3px solid #fff" }}
        />
        <img
          src={item.social_account_images[1]}
          className="last-image"
          alt="lastimage"
          style={{ border: "3px solid #fff" }}
        />
      </Box>
      <Box sx={classes.boxDetail}>
        <Box sx={{ padding: "12px" }}>
          <Box display="flex">
            <GetPlatform platform={item.platform} />
            <Typography sx={classes.textEllipsis}>{item.name}</Typography>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            divider={<CircleIcon />}
            sx={classes.stackCategory}
          >
            {item.category.map((cat:ICategory) => (
              <Typography key={cat.id} sx={classes.textCategory}>
                {cat.name_en}
              </Typography>
            ))}
          </Stack>

          <Stack direction="row" sx={classes.stackStatus}>
            <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
              <Icon className="ico-hu-group" sx={{ fontSize: "16px" }} />
              <Typography ml={1.5}>
                {numberFormat.format(item.followers)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
              <Icon
                className="ico-hu-engagement-rate"
                sx={{ fontSize: "16px" }}
              />
              <Typography ml={1.5}>
                {item.engagement_rate.toFixed(2)}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
