// mui and style
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useEventDetailTabStyle as classes } from "../../Events.style";
// type and others
import { IEventDertailsTabProps } from "../../Events.type";
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint as setVariant } from "@utils/helpers/setValueByBreakpoint.helpers";

export default function EventDetailsTab(props: IEventDertailsTabProps) {
  const { index, TabPanel, tabSelected } = props;
  const bp = useBreakpoint();

  return (
    <TabPanel index={index} value={tabSelected}>
      <Box sx={classes.conatiner}>
        <div>
          <Typography
            variant={setVariant(bp, "h5", null, null, "h4")}
            textAlign="center"
            mb="24px"
          >
            งานเปิดตัว HashU สุดยิ่งใหญ่ ผ่าน Facebook Live
          </Typography>
          <Typography
            fontSize={{ xs: "16px", lg: "20px" }}
            lineHeight={{ xs: "24px", lg: "32px" }}
          >
            Lorem ipsum dolor sit amet consectetur. Ut ut nunc lectus euismod
            quam purus parturient ultrices. Rutrum tristique aliquet quam cursus
            morbi aliquet euismod laoreet. Non nec condimentum lectus maecenas
            tempor. Sit leo enim consequat amet. Turpis pellentesque volutpat
            elit faucibus nibh viverra tortor tincidunt. Ut vitae dictumst elit
            nulla quam cursus proin praesent velit. Potenti sapien volutpat
            pharetra hac. Nisl tempor sagittis eget amet nullam est in pulvinar.
            In justo sed at orci porttitor libero.
          </Typography>
        </div>
        <Grid container my="32px" rowGap="16px">
          {[
            {
              label: "วันที่เริ่มกิจกรรม",
              subLabel: "01/02/2023 - 7:00 น.",
            },
            {
              label: "วันสิ้นสุดกิจกรรม",
              subLabel: "08/02/2023 - 10:00 น.",
            },
            {
              label: "สถานที่",
              subLabel: "-",
            },
          ].map((item, index) => (
            <Grid key={index} item xs={12} sm={4}>
              <Box display="flex" flexDirection="column">
                <Typography
                  color="#616161"
                  variant={setVariant(bp, "title2", null, null, "h6")}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant={setVariant(bp, "title1", null, null, "body1")}
                >
                  {item.subLabel}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box mt={["32px", "48px"]}>
          <Typography variant={setVariant(bp, "h6", null, null, "h5")}>
            รายละเอียดการเข้าร่วมกิจกรรม
          </Typography>
          <Box component="ul">
            {[
              "Lorem ipsum dolor sit amet consectetur.",
              "Venenatis laoreet massa pellentesque viverra tristique ipsum odio. Mattis odio sit et faucibus. Magna vitae in consequat sagittis nam mattis vel elementum vulputate. Volutpat amet faucibus fusce vitae tellus fringilla.",
              "Sit tempus eget diam et. Pulvinar vitae pellentesque vitae diam metus at. Imperdiet suscipit cum magna ullamcorper mauris vitae sit quam. Eu non at eleifend convallis morbi eget semper.",
              "In aliquet pellentesque felis faucibus enim in duis id nulla.",
            ].map((item, index) => (
              <Typography
                component="li"
                key={index}
                fontSize={{ xs: "16px", lg: "20px" }}
                lineHeight={{ xs: "24px", lg: "32px" }}
                ml="-10px"
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
        <Grid container mt={0} mb="80px" spacing={{ xs: "25px", md: "80px" }}>
          <Grid item xs={12}>
            <Typography variant="h6" mb="24px" mt="20px">
              รางวัล
            </Typography>
            <Box sx={classes.rewardBox}>
              <Avatar sx={{ width: "320px", height: "320px" }} />
              <Typography
                variant={setVariant(bp, "h5", null, null, "h4")}
                mt="20px"
              >
                รางวัลที่ 1
              </Typography>
              <Typography>
                11กดหกด5หก4ด4กหหLorem ipsum dolor sit amet consectetur.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={classes.rewardBox}>
              <Avatar sx={classes.secondRewardImg} />
              <Typography
                variant={setVariant(bp, "h5", null, null, "h4")}
                mt="20px"
              >
                รางวัลที่ 2
              </Typography>
              <Typography>
                11กดหกด5หก4ด4กหหLorem ipsum dolor sit amet consectetur.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={classes.rewardBox}>
              <Avatar sx={classes.thirdRewardImg} />
              <Typography
                variant={setVariant(bp, "h5", null, null, "h4")}
                mt="20px"
              >
                รางวัลที่ 3
              </Typography>
              <Typography>
                11กดหกด5หก4ด4กหหLorem ipsum dolor sit amet consectetur.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={classes.rewardBox}>
              <Avatar sx={classes.fourthRewardImg} />
              <Typography
                variant={setVariant(bp, "h5", null, null, "h4")}
                mt="20px"
              >
                รางวัลที่ 4
              </Typography>
              <Typography>
                11กดหกด5หก4ด4กหหLorem ipsum dolor sit amet consectetur.{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={classes.rewardBox}>
              <Avatar sx={classes.fourthRewardImg} />
              <Typography
                variant={setVariant(bp, "h5", null, null, "h4")}
                mt="20px"
              >
                รางวัลที่ 5
              </Typography>
              <Typography>
                11กดหกด5หก4ด4กหหLorem ipsum dolor sit amet consectetur.{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Typography variant={setVariant(bp, "h6", null, null, "h5")}>
            การรับรางวัล
          </Typography>
          <Box component="ul">
            {[
              "Lorem ipsum dolor sit amet consectetur.",
              "Venenatis laoreet massa pellentesque viverra tristique ipsum odio. Mattis odio sit et faucibus. Magna vitae in consequat sagittis nam mattis vel elementum vulputate. Volutpat amet faucibus fusce vitae tellus fringilla.",
              "Sit tempus eget diam et. Pulvinar vitae pellentesque vitae diam metus at. Imperdiet suscipit cum magna ullamcorper mauris vitae sit quam. Eu non at eleifend convallis morbi eget semper.",
              "In aliquet pellentesque felis faucibus enim in duis id nulla.",
            ].map((item, index) => (
              <Typography
                component="li"
                key={index}
                fontSize={{ xs: "16px", lg: "20px" }}
                lineHeight={{ xs: "24px", lg: "32px" }}
                ml="-10px"
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </TabPanel>
  );
}
