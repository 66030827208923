import { instance } from "@api/global/instance";
import { IAllInfluencerExpeseParams } from "./type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class ExpenseAPIs {
  async importExpense(body:any) {
    const { access_token } = getTokenFromStorage()
    
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post("/expense/import", body, { headers });
    return data.result.data;
  }

  async newImportExpense(body:any) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    };
    
    const file = new FormData();
    for (const key in body) {
      file.append(key, body[key])
    }

    const { data } = await instance.post("/expense/new-import", file, { headers });
    return data.result.data;
  }

  async newExportExpense(params?: { start_date:string, end_date:string, status: string }) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get("/expense/new-export", { headers, params });
    return data.result.data;
  }

  async exportExpense(params?: { start_date:string, end_date:string, status: string }) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get("/expense/export", { headers, params });
    return data.result.data;
  }

  async getAllExpenseList(params: IAllInfluencerExpeseParams) {
    const { access_token } = getTokenFromStorage()
    
    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json",
    };
    const { data } = await instance.get("/expense", { headers, params });
    return data.result.data;
  };
}