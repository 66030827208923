import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import qs from "qs";

export class NotificationAPIs {
  async getNotificationList() {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    };
    const { data } = await instance.get(`/notifications`, { headers });
    return data.result.data;
  }

  async addFCMClient(body:{ user_id: string, token: string }) {
    const headers = {
      "X-API-Key": API_KEY
    };
    body.token = btoa(body.token);
    const { data } = await instance.post(`/notifications/fcm`, body, { headers });
    return data;
  }

  async makeAsRead(params:{ id:string, read:boolean }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    };
    const { data } = await instance.patch(`/notifications/read`, null, { headers, params });
    return data;
  }

  async makeAsReadAll(params:{ user_id:string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    };
    const { data } = await instance.patch(`/notifications/read-all`, null, { headers, params });
    return data;
  }

  async lineOA(req:{ link_token:string, access_token:string }) {
    const headers = {
      "X-API-Key": API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var body = qs.stringify({
      link_token: req.link_token,
      token: req.access_token,
    });

    const { data } = await instance.post(`/notifications/line/login`, body, { headers });
    return data.result.data;
  }
}