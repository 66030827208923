import { useState } from "react";
// mui
import {
  Box,
  Tab,
  Icon,
  Tabs,
  Paper,
  Button,
  Divider,
  useTheme,
  InputBase,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
// icon and style
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useCampaignTabsItemLgStyle as classes } from "@views/campaign/Campaign.style";
// route and type
import { useHistory, useLocation } from "react-router-dom";
import { ICampaignParams } from "@views/campaign/Campaign.type";

export default function CampaignTabLg(props: any) {
  const { campaignTabs, handleChangeStatus, onClose } = props;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const searched = params.get("q") || "";
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const cate = params.get("cate");
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const categories = cate ? cate.split(",").map(Number) : [];
  const filterTotal = (status ? 1 : 0) + (searched ? 1 : 0) + (platform ? 1 : 0) + (type ? 1 : 0) + (categories.length ? 1 : 0) + (`${orderBy}${sort}` !== "dateDESC" ? 1 : 0);

  const [search, setSearch] = useState(searched);
  const [tabSelected, setTabSelected] = useState(0);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

  const queryParams: ICampaignParams = {
    page: 1,
    q: search,
    status: status,
    sort: sort,
    order_by: orderBy,
    platform: platform,
    type: type,
    cate: categories,
  };

  const handleChangeTab = (_event: any, newValue: number) => {
    setTabSelected(newValue);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setSearch(value.trim());

    if (value.trim() !== "") return;
    const param = { ...queryParams };
    param["q"] = "";

    const queryString = Object.keys(param)
      .map((key) => `${key}=${param[key as keyof ICampaignParams]}`)
      .join("&");

    history.replace(`/campaigns?${queryString}`);
  };

  const onSearched = () => {
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key as keyof ICampaignParams]}`)
      .join("&");

    history.replace(`/campaigns?${queryString}`);
  };

  return (
    <>
      <Tabs
        onChange={handleChangeTab}
        value={smScreen ? tabSelected : false}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        variant="scrollable"
        aria-label="scrollable auto tabs example"
        scrollButtons="auto"
        sx={classes.tabs}
      >
        {campaignTabs.map((tab: any, index: number) => (
          <Tab
            key={index}
            id={`my-jobs-tab-${tab.id}`}
            aria-haspopup="true"
            label={
              <Box display="flex" justifyContent="center" width="100%">
                <Typography
                  sx={{
                    color: tab.key === status ? tab.color : "#5C5C5C",
                  }}
                >
                  {`${tab.text} ${
                    Number(tab.total) > 0 ? `(${tab.total})` : ""
                  }`}
                </Typography>
              </Box>
            }
            onClick={() => handleChangeStatus(tab.id)}
            sx={{
              ...classes.tabButton,
              "&:hover": {
                border: `1px solid ${tab.color}`,
                bgcolor: tab.bgColor,
                "& div p, div .MuiSvgIcon-root": {
                  color: tab.color,
                },
              },
              color: tab.key === status ? tab.color : "#5C5C5C",
              border: `1px solid ${tab.key === status ? tab.color : "#9E9E9E"}`,
              bgcolor: tab.key === status ? tab.bgColor : "#fff",
              borderRadius: "50px",
              minWidth: "auto",
            }}
          />
        ))}
      </Tabs>

      {!mdScreen && (
        <>
          <Divider
            sx={classes.divider}
            orientation="vertical"
            variant="middle"
            flexItem
          />

          <Box display="flex" justifyContent="flex-start">
            <Paper sx={classes.paper}>
              <InputBase
                sx={{ ml: 4, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search campaign" }}
                value={search}
                onChange={handleSearchChange}
              />
              <IconButton
                onClick={onSearched}
                sx={{ p: "10px", width: "30px" }}
                aria-label="search"
              >
                <Icon
                  className="ico-hu-search"
                  sx={{ pr: "2px", fontSize: "20px" }}
                />
              </IconButton>
            </Paper>
          </Box>

          <Button
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => onClose(true)}
            sx={classes.tabFilter}
          >
            <Typography whiteSpace="nowrap">
              Filter {filterTotal || ""}
            </Typography>
          </Button>
        </>
      )}
    </>
  );
}
