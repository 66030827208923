import { useEffect, useState } from "react";
// mui and component
import { Box, Grid, Icon, Typography } from "@mui/material";
import SpecifyAmount from "@components/payment/SpecifyAmount";
import PaymentMethod from "@components/payment/PaymentMethod";
import PaymentResultDialog from "@components/subscription/subscription-dialog/PaymentResult";
// api and type
import { PaymentAPI } from "@api/global";
import { IPaymentResult } from "@components/subscription/Subscription.type";
// redux
import {
  checkBalanceAsync,
  selectEmployerInfo,
  selectEmployerInfoOnload,
} from "@slices/EmployerInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getPaymentMethodAsync } from "@slices/PaymentMethod.slice";
// other
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loading from "@components/global/loading/Loading";

export default function TopUp() {
  const [amount, setAmount] = useState("100");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentResult, setPaymentResult] = useState<IPaymentResult>();

  const dispatch = useAppDispatch();
  const employerInfo = useAppSelector(selectEmployerInfo);
  const isLoading = useAppSelector(selectEmployerInfoOnload);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("order_id");
  const emplpyerId = params.get("emp_id") || employerInfo?.employer_full_id || "";
  const teamId = params.get("t_id") || employerInfo?.team?.id || "";

  const { plan } = useParams<{ plan:string }>()
  const { redirect } = location.state as { redirect:string } || {};

  useEffect(() => {
    if (!orderId) return;
    PaymentAPI.getPaymentResult(orderId).then((response) => {
      setPaymentResult(response);
      setPaymentSuccess(true);
    });
  }, [orderId]);

  useEffect(() => {
    const params = {
      isPersonal: plan === "personal",
      team_id: teamId,
      employer_id: emplpyerId,
    };
    dispatch(getPaymentMethodAsync());
    dispatch(checkBalanceAsync(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const goBack = () => {
    if (redirect) {
      history.push(redirect)
    } else if (plan === "personal") {
      history.push(`/employers/${emplpyerId}/info`)
    } else {
      history.push(`/team?tab=wallet`)
    }
  }

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <Box
        onClick={goBack}
        display="inline-flex"
        alignItems="center"
        sx={{ cursor: "pointer" }}
      >
        <Icon className="ico-hu-chevron-left" fontSize="small" />
        <Typography variant="buttonS" ml={1}>
          Back
        </Typography>
      </Box>
      <Box my="15px">
        <Typography variant="h5">Top Up</Typography>
        <Typography variant="subtitle1">เติมเงินเข้า Wallet</Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <SpecifyAmount amount={amount} setAmount={setAmount} />
        </Grid>
        <Grid item xs={8}>
          <PaymentMethod amount={amount} />
        </Grid>
      </Grid>
      <PaymentResultDialog
        open={paymentSuccess}
        onClose={setPaymentSuccess}
        data={paymentResult}
        redirect={plan === "personal" ? `/employers/${emplpyerId}/info` : `/team?tab=wallet`}
      />
    </div>
  );
}
