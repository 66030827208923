import { useEffect } from "react";
// mui
import { Box, Typography } from "@mui/material";

export default function Start(props: any) {
  const { TabPanel, tabSelected } = props;

  const register = "https://storage.googleapis.com/hashu-bucket-uat/media/videos/register.mp4";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TabPanel value={tabSelected} index={0}>
      <Box p={{ xs:"0", md:"0 40px" }}>
        <Typography variant="h5">การสร้างบัญชี Hashu</Typography>
        <Box mt="12px">
          {[
            `1. กดปุ่ม "Sign Up" และเลือก "Influencer" เพื่อทำการสร้างบัญชี`,
            `2. กรอกข้อมูลส่วนตัว อีเมลสำหรับการลงชื่อใช้งาน และรหัสผู้แนะนำ(ถ้ามี)`,
            `3. กดปุ่ม "Register" ระบบจะส่งอีเมลยืนยันการสร้างบัญชี HashU ตามที่ได้ลงทะเบียนไว้`,
            `4. ตรวจสอบอีเมลและยืนยันการลงทะเบียนในอีเมลของคุณ`,
            `5. หลังการยืนยันการสมัคร ให้กดปุ่ม "Sign in" เพื่อเข้าสู่ระบบและเริ่มต้นใช้งาน HashU ได้เลย`
          ].map((text, index) => (
            <Typography key={index}>
              {text}
            </Typography>
          ))}
        </Box>

        <Box
          sx={{
            mt: "24px",
            mb: "24px",
            "& video": {
              width: "100%",
            },
          }}
        >
          <video height="auto" controls src={register} />
        </Box>
      </Box>
    </TabPanel>
  );
}
