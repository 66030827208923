import React from "react";
// mui and icon
import {
  Box,
  Icon,
  Paper,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// type and style
import { IKeyReport, IKeyManagement } from "../Subscription.type";
import { useCurrentPlanListStyle as classes } from "../Subscription.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// other
import { subscriptionPlan } from "../Subscription.constants";
import { platformMatchIcon } from "@views/subscription/plan-list";

export default function PackagePlanList() {
  const employerInfo = useAppSelector(selectEmployerInfo);
  const isTeamPlan = employerInfo?.package_plan === "team";
  const teamPackage = employerInfo?.team?.package;
  const personalPackage = employerInfo?.package;
  const currentPackage = isTeamPlan ? teamPackage : personalPackage;

  const displayValue = (value?: number | boolean) => {
    if (value) {
      return <CheckCircleIcon fontSize="small" color="success" />;
    } else if ((Number(value) || 0) > 0) {
      return value;
    } else return "";
  };

  const TableBodyRow = (props: {
    keyType: string;
    subKeyType?: string;
    keyManagement?: IKeyManagement;
    keyNotification?: "is_line_chat";
    keyReport?: "is_report" | "is_report_export";
  }) => {
    const { keyType, subKeyType, keyManagement, keyNotification, keyReport } =
      props;

    const text = (txt?: any) => {
      return (
        <Typography variant="title1" textTransform="capitalize">
          {txt}
        </Typography>
      );
    };
    if (keyType === "management" && keyManagement) {
      const typeValue = currentPackage?.campaign_management[keyManagement];
      if (typeof typeValue === "number") {
        return text(typeValue);
      } else {
        const value = (typeValue || []).find((p) => p.title === subKeyType);
        if (!value) return null;
        return text(displayValue(value.id));
      }
    } else if (keyType === "report" && keyReport) {
      const typeValue = currentPackage?.report[keyReport];
      if (!typeValue) return null;
      return text(displayValue(typeValue));
    } else if (keyType === "notification" && keyNotification) {
      const typeValue = currentPackage?.notification[keyNotification];
      if (!typeValue) return null;
      return text(displayValue(typeValue));
    } else {
      return null;
    }
  };

  return (
    <Box sx={classes.con}>
      <TableContainer sx={classes.tableCon} component={Paper}>
        <Table sx={{ minWidth: "300px" }}>
          <TableHead>
            <TableRow sx={classes.thRow}>
              <TableCell />
              <TableCell sx={classes.thd}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Icon
                    className="ico-hu-person"
                    fontSize="small"
                    sx={{ mr: "8px" }}
                  />

                  <Typography variant="h6" textTransform="capitalize">
                    {currentPackage?.title}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={classes.tb}>
            <TableRow sx={classes.tbr}>
              <TableCell align="left">
                <Box ml="10px">
                  <Typography variant="title1">
                    Available Social Platforms
                  </Typography>
                  <Typography variant="body2">
                    จำนวนแคมเปญที่สร้างได้ต่อเดือน
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  bgcolor:
                    currentPackage?.title === "free" ? "#F5EEFF" : undefined,
                }}
              >
                <Stack sx={classes.platform}>
                  {currentPackage?.campaign_management.platform?.map(
                    (item, i) => (
                      <React.Fragment key={i}>
                        {platformMatchIcon(item.title)}
                      </React.Fragment>
                    )
                  )}
                </Stack>
              </TableCell>
            </TableRow>
            {subscriptionPlan.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow sx={classes.tbr}>
                  <TableCell align="left" sx={{ bgcolor: "#F5F6F8" }}>
                    <Box display="flex" alignItems="center" ml="10px">
                      <Typography variant="h6" textTransform="capitalize">
                        {row.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ bgcolor: "#F5F6F8" }} />
                </TableRow>
                {row.item.map((irow, iIndex) => (
                  <TableRow key={iIndex}>
                    <TableCell align="left">
                      <Box display="flex" flexDirection="column" ml="10px">
                        <Typography
                          variant="title1"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {irow.label}
                        </Typography>
                        <Typography variant="body2" color="#505050">
                          {irow.subLabel}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ "& td": { minWidth: "170px" } }}
                    >
                      <TableBodyRow
                        keyType={row.key}
                        subKeyType={irow.subKeyType}
                        keyManagement={irow.keyValue as IKeyManagement}
                        keyReport={irow.keyValue as IKeyReport}
                        keyNotification={irow.keyValue as "is_line_chat"}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
            <TableRow sx={classes.tbr}>
              <TableCell align="left" sx={{ bgcolor: "#F5F6F8" }}>
                <Box ml="10px">
                  <Typography variant="h6">Team Management</Typography>
                  <Typography variant="body2" color="red">
                    **การใช้งานปัจจุบันยังไม่ได้รองรับระบบทีม กดปุ่ม
                    “เปลี่ยนแผน” เพื่ออัปเกรดการใช้งาน**
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ bgcolor: "#F5F6F8" }} />
            </TableRow>
            {[
              {
                label: "Member Limit",
                subLabel: "จำนวนสมาชิกในทีมทั้งหมด",
                value: currentPackage?.team_management.usage_limit,
              },
              {
                label: "Able to add member more (Maximum User Limit)",
                subLabel: "จำนวนสมาชิกที่สามารถเพิ่มได้ (จำนวนสูงสุด)",
                value: `${currentPackage?.team_management.team_limit} (${currentPackage?.team_management.usage_limit})`,
              },
            ].map((row, index) => (
              <React.Fragment key={index}>
                <TableRow sx={classes.tbr}>
                  <TableCell align="left">
                    <Box ml="10px">
                      <Typography variant="h6">{row.label}</Typography>
                      <Typography variant="body2">{row.subLabel}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ "& td": { minWidth: "170px" } }}
                  >
                    <Typography variant="body2" fontWeight={500}>
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
