// mui and style
import { Box, Grid, Icon, Typography } from "@mui/material";
import { usePrivateStatusStyles as classes } from "./PrivateStatus.style";
// type and util
import { ISelectingMoreProps } from "./PrivateStatus.type";

export default function SelectingMore(props: ISelectingMoreProps) {
  const { item, toggleDrawers, privateSelected, workingList } = props;

  const numberFormat = Intl.NumberFormat("en");

  const tatalRateCard =
    workingList &&
    workingList.reduce(
      (total: any, influencer: any) => total + influencer.rate_card,
      0
    );
  const totalBudget = item && item.total_budget - tatalRateCard;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={10}>
        <Box sx={classes.boxStatus}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography>ยื่นข้อเสนอแล้ว</Typography>
              <Typography variant="h5" color="#893DBD" className="lastText">
                {item && item.offering + item.working + item.cancel}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography>ปฏิเสธ</Typography>
              <Typography variant="h5" color="#9E9E9E" className="lastText">
                {item && item.cancel}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Typography>ตอบรับข้อเสนอ</Typography>
              <Typography variant="h5" color="#4CAF50" className="lastText">
                {item && item.working}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography>ยอดเงินคงเหลือ</Typography>
              <Typography variant="h5" color="#893DBD" className="lastText">
                {numberFormat.format(Math.round(totalBudget || 0))} THB
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} lg={2}>
        <Box
          sx={classes.boxButton}
          onClick={() => {
            toggleDrawers(true);
          }}
        >
          <Icon className="ico-hu-person" />
          <Typography variant="buttonM">
            รายชื่อ ({privateSelected?.length})
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
