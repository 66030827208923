// mui
import {
  Box,
  Fab,
  Grid,
  Icon,
  Button,
  Drawer,
  Divider,
  Typography,
} from "@mui/material";
// style and type
import { useInfluencerSelectedStyle as classes } from "../InfluencerList.style";
import { IInfluencerSelectedProps } from "../InfluencerList.type";
import { IItemsRes } from "@api/job/type";

export default function InfluencerSelected(props: IInfluencerSelectedProps) {
  const {
    openInfluencerSelected,
    toggleInfluSelectedDrawers,
    candidateInfluencer,
    newEstimateSummary,
    onClick,
    selectedInfluencerWithAPI,
    clearInfluencer,
  } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const influencerSelected = candidateInfluencer?.items.filter(
    (item) => item.is_selecting
  );

  return (
    <Drawer
      anchor="right"
      open={openInfluencerSelected}
      onClose={() => toggleInfluSelectedDrawers(false)}
      sx={classes.drawer}
    >
      <Box sx={{ height: "100%" }}>
        <Box sx={{ ...classes.boxBetween, padding: "24px" }}>
          <Typography variant="h5" color="#893DBD">
            ผู้ที่ได้รับการคัดเลือก
          </Typography>
          <Icon
            className="ico-hu-close"
            onClick={() => toggleInfluSelectedDrawers(false)}
            sx={classes.close}
          />
        </Box>

        <Divider sx={{ color: "#9E9E9E" }} />

        <Box sx={{ ...classes.boxBetween, padding: "24px" }}>
          <Typography variant="title1" color="#E8008A">
            {newEstimateSummary.totalInfluencerSelected} /{" "}
            {candidateInfluencer?.summary.job_limit}
          </Typography>
        </Box>

        <Box sx={classes.boxEstimate}>
          <Box sx={{ width: "100%" }}>
            <Typography>Follower</Typography>
            <Typography variant="h6" color="#893DBD">
              {numberFormat.format(newEstimateSummary?.followers)}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography>Likes</Typography>
            <Typography variant="h6" color="#893DBD">
              {numberFormat.format(newEstimateSummary?.likes)}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography>Comments</Typography>
            <Typography variant="h6" color="#893DBD">
              {numberFormat.format(newEstimateSummary?.comments)}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography>Shares</Typography>
            <Typography variant="h6" color="#893DBD">
              {numberFormat.format(newEstimateSummary?.shares)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ margin: "24px" }}>
          <Grid container spacing={5}>
            {influencerSelected?.map((item: IItemsRes) => {
              return (
                <Grid item xs={6} sm={6} md={4} key={item.job_id}>
                  <Box sx={classes.boxInfluencerCard}>
                    <Fab
                      onClick={() => onClick(item.job_id)}
                      color="primary"
                      aria-label="clearInfluencer"
                      sx={classes.fab}
                    >
                      <Icon
                        className="ico-hu-close"
                        sx={{ fontSize: "16px" }}
                      />
                    </Fab>
                    <img
                      alt="influencer-selected"
                      src={item.social_account_images[0]}
                    />
                    <Box
                      sx={{
                        ...classes.boxCenter,
                        padding: "0 12px",
                      }}
                    >
                      <Typography variant="body1" sx={classes.textEllipsis}>
                        {item.name}
                      </Typography>
                    </Box>
                    <Box sx={classes.boxCenter}>
                      <Icon className="ico-hu-group" sx={classes.iconGroup} />
                      <Typography color="#616161">
                        {numberFormat.format(item.followers)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      <Box sx={{ ...classes.boxBetween, padding: "16px" }}>
        <Typography
          variant="title1"
          color="#893DBD"
          sx={{ cursor: "pointer" }}
          onClick={clearInfluencer}
        >
          Clear
        </Typography>
        <Button
          variant="contained"
          disabled={influencerSelected?.length ? false : true}
          onClick={selectedInfluencerWithAPI}
        >
          ยืนยันผู้เข้าร่วมแคมเปญ {newEstimateSummary.totalInfluencerSelected} /{" "}
          {candidateInfluencer?.summary.job_limit}
        </Button>
      </Box>
    </Drawer>
  );
}
