// mui and icons
import { Box, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
// style and type
import {
  EventTab,
  EventTabSm,
  EventTabsSm,
  useEventDetailTabsStyle as classes,
} from "../../Events.style";
import { IEventTabsProps } from "../../Events.type";

export default function EventDetailTabs(props: IEventTabsProps) {
  const { handleChangeTab, handleActiveTab, tabSelected } = props;

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.down("sm"));

  const eventTabs = [
    { key: "detaials", label: "Details" },
    {
      key: "leaderboard",
      label: "Leaderboard",
    },
  ];

  return (
    <Box mt="25px" mb={["25px", 0]}>
      {/* tabs for mobile */}
      <Box sx={classes.eventTabsSm}>
        <EventTabsSm
          value={screenSm ? tabSelected : false}
          onChange={handleChangeTab}
          centered
        >
          <EventTabSm label="Details" />
          <EventTabSm label="Leaderboard" />
        </EventTabsSm>
      </Box>

      {/* tabs for desktop */}
      {!screenSm && (
        <Tabs
          value={tabSelected}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={classes.eventTabLg}
        >
          {eventTabs.map((tab, index) => (
            <EventTab
              key={index}
              label={<Typography variant="subtitle1">{tab.label}</Typography>}
              {...handleActiveTab(tab.key)}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
}
