
// mui and style
import { Box, Typography } from "@mui/material";
import { useInfluencerDashboardStyle as classes } from "./Dashboard.stye";
// components
import ListingTab from "@components/influencer-dashboard/listing-tab/ListingTab";
import OverviewTab from "@components/influencer-dashboard/overview-tab/OverviewTab";
// other
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function InfluencerDashboard() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "overview";

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const handleChangeTab = (tab: string) => {
    history.replace(`/influencers-dashboard?tab=${tab}`);
  };

  return (
    <div className="container-main">
      <div>
        <Typography variant="h5">Influencer</Typography>
        <Typography variant="subTitle1">อินฟลูเอนเซอร์</Typography>
      </div>

      <Box sx={classes.tabBox}>
        {["overview", "list"].map((tab, index) => (
          <Box
            key={index}
            component="button"
            sx={tabSelected === tab ? classes.tabSelected : classes.tab}
            onClick={() => handleChangeTab(tab)}
          >
            <Typography
              textTransform="capitalize"
              fontWeight={tabSelected === tab ? 500 : 400}
            >
              {tab}
            </Typography>
          </Box>
        ))}
      </Box>

      {tabSelected === "list" ? <ListingTab /> : <OverviewTab />}
    </div>
  );
}
