import { styled } from "@mui/material/styles";
import { hWhite, hPurple } from "@theme/index";
import { Button, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const employerExpenseTabStyle = {
  container: {
    borderRadius: ["15px", "15px", "none"],
    bgcolor: ["#fff", "#fff", "none"],
    boxShadow: [
      "0px 4px 8px rgba(0, 0, 0, 0.1)",
      "0px 4px 8px rgba(0, 0, 0, 0.1)",
      "none",
    ],
  },
  date: {
    display: "flex",
    width: "100%",
    "& .MuiOutlinedInput-root, .MuiInputBase-root": {
      width: "100%",
      height: "40px",
    },
    "& label": {
      height: "100%",
      margin: ["-6px 3px", "-3px 3px", "-3px 5px"],
      fontSize: "14px",
    },
  },
  selectStatus: {
    width: "100%",
    my: ["10px", 0],
    pl: [0, "10px"],
    "& .css-pe0pe4-MuiFormControl-root": {
      width: ["100%", "70%", "65%", "60%"],
    },
  },
  exportAndCertWrap: {
    width: "100%",
    "& .css-h4ero1-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
      color: "#9E9E9E",
      border: "none",
      bgcolor: "#E0E0E0",
    },
  },
  exportBtn: {
    width: {
      xs: "100%",
      md: "auto",
    },
    minWidth: "200px",
    height: "40px",
  },
  importBtn: {
    width: {
      xs: "100%",
      md: "auto",
    },
    minWidth: "200px",
    height: "40px",
    "& > span": {
      fontSize: ["16px", "14px"],
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    bgcolor: "#8C499C",
    color: "#fff",
    columnGap: "5px",
    cursor: "pointer",
  },
  search: {
    display: "flex",
    border: "1px solid #8C499C",
    height: "40px",
    boxShadow: "none",
    width: ["100%", "100%", "70%"],
  },
  selectBox: {
    "& button": {
      width: {
        xs: "100%",
        sm: "130px",
        lg: "150px",
        xl: "200px",
      },
      display: "flex",
      justifyContent: "space-between",
      px: "20px",
      maxHeight: "40px",
    },
  },
  menuDate: {
    "& > .MuiPaper-root": {
      border: "1.5px solid #8C499C",
      mt: "10px",
      minHeight: "374px",
      width: "550px",
      borderRadius: "20px",
      px:"15px"
    },
  },
  menuDateGridCon: {
    // mx:"10px",
    "& .datepicker-calendar-container": {
      border: "none",
      boxShadow: "none",
      // ml: "-5px",
    },
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    borderRadius: "4px",
    "&:hover": {
      bgcolor: "#EBDFFF",
      color: "#8C499C",
    },
    ml: "10px",
  },
  calendarIcon: {
    cursor: "pointer",
    color: "#8C499C",
  },
  txtFieldDate: {
    mt: "10px",
    mb: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      borderColor: "#8C499C",
    },
  },
};

export const status = {
  status: {
    display: "flex",
    width: "80px",
    height: "24px",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    margin: [0, 0, "auto"],
  },
};

export const eyeIcon = {
  border: "1px solid #1AA1F1",
  backgroundColor: "white",
  margin: "auto",
  cursor: "pointer",
  color: "#1AA1F1",
};

const subscription = {
  width: "100%",
  height: "28px",
  borderRadius: "50px",
  bgcolor: "#EBDFFF",
  color: "#8C499C",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 500,
  maxWidth: "150px",
};

export const employerExpense = {
  campaignIcon: {
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },
  statusPaid: {
    ...status.status,
    backgroundColor: "#DBFFDC",
    color: "#4CAF50",
  },
  statusPending: {
    ...status.status,
    backgroundColor: "#FFEEDF",
    color: "#FB8C00",
  },
  eyeIconPaid: {
    ...eyeIcon,
  },
  eyeIconPending: {
    ...eyeIcon,
    color: "#9E9E9E",
    border: "none",
    backgroundColor: "#E0E0E0",
  },
  noExpese: {
    bgcolor: "#fff",
    borderRadius: "16px",
    height: {
      xs: "75vh",
      xl: "80vh",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      width: { xs: "400px", xl: "500px" },
      height: { xs: "400px", xl: "500px" },
    },
  },
  card: {
    width: "100%",
    maxWidth: "400px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    bgcolor: "#fff",
    p: "15px",
  },
  recipientIcon: { width: "16px", height: "16px", mr: "5px" },
  cardTable: {
    "& .cardTable": {
      display: "flex",
      justifyContent: "space-between",
      height: "40px",
      alignItems: "center",
      px: "15px",
      "&:first-of-type": {
        mt: "10px",
        backgroundColor: "#F5F6F8",
        borderRadius: "8px",
      },
      "&:last-of-type": {
        mb: "10px",
        backgroundColor: "#F5F6F8",
        borderRadius: "8px",
      },
      "& p": {
        fontSize: "14px",
      },
      "& p:first-of-type": {
        color: "#5C5C5C",
      },
    },
    "& .css-ed2ajq-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
      color: "#9E9E9E",
      border: "none",
      backgroundColor: "#E0E0E0",
    },
    "& .css-ed2ajq-MuiButtonBase-root-MuiButton-root": {
      color: "#1AA1F1",
    },
  },
  sFree: {
    ...subscription,
    bgcolor: "#EBDFFF",
    color: "#8C499C",
  },
  sBasic: {
    ...subscription,
    bgcolor: "#E5F6FF",
    color: "#0077E4",
  },
  sProfessional: {
    ...subscription,
    bgcolor: "#FFD4ED",
    color: "#ED1968",
  },
  sEnterprise: {
    ...subscription,
    bgcolor: "#EBDFFF",
    color: "#8C499C",
  },
  viewEmployer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    border: "1px solid #8C499C",
    backgroundColor: "white",
    cursor: "pointer",
    borderRadius: "50%",
  },
  deleteEmployer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    border: "1px solid #FF8484",
    backgroundColor: "white",
    cursor: "pointer",
    borderRadius: "50%",
  },
  email: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWith: {
      xs: "185px",
      md: "100%",
    },
    fontSize: {
      xs: "12px",
      sm: "14px",
    },
  },
  button: {
    width: "140px",
    backgroundColor: "#8C499C",
    color: "#fff",
    ":hover": {
      border: "2px solid #8C499C",
      color: "#8C499C",
    },
  },
  taxButton: {
    width: "140px",
    backgroundColor: "#E8008A",
    color: "#fff",
    ":hover": {
      border: "2px solid #E8008A",
      color: "#E8008A",
    },
  },
};

export const DocumentButton = styled(Button)({
  backgroundColor: hPurple[200],
  color: hWhite[100],
  height: ["40px"],
  padding: "0 15px",
  "&:hover": {
    backgroundColor: hPurple[300],
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: hPurple[300],
  },
});

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
    // border: `1px solid red`,
    overFlow: "hidden",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiTableCell-body": {
    // border: "1px solid red",
  },
}));

export const TableCellCustom = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: hPurple[200],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
