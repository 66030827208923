import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { instance } from "../global/instance";
import { ICampaignListParams, ICreateCampaignReq, IUpdateCampaignReq } from "./type";

export class CampaignAPIs {
  async createCampaign(body: ICreateCampaignReq) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`/campaigns`, body, { headers });
    return data.result.data;
  };

  async updateCampaign(body: IUpdateCampaignReq, campaignId:string) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json",
    };
    const { data } = await instance.patch(`/campaigns/${campaignId}`, body, { headers });
    return data.result.data;
  };

  async getCampaignInfo(campaignId:string) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/campaigns/${campaignId}`, { headers });
    return data.result.data;
  };

  async getCampaignList(params: ICampaignListParams){
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/campaigns`, { headers, params });
    return data.result.data;
  };

  async updateCampaignStatus(body: { status: string }, campaignId: string){
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.patch(`/campaigns/${campaignId}/status`, body, { headers });
    return data.result.data;
  }

  async getGoogleMapLocation(params:{ url: string }){
    const { access_token } = getTokenFromStorage()
    
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/campaigns/map/location`, { headers, params });
    return data.result.data;
  }
}