// mui and style
import { Box, Grid, Divider, Typography } from "@mui/material";
import { useInfCampaignReportTabStyle as classes } from "../Campaign.style";
// component
import Engagement from "@global/campaign-report/Engagement";
import Conversion from "@global/campaign-report/Conversion";
import InfluencerPost from "@global/campaign-report/InfluencerPost";
import CampaignPerformance from "@global/campaign-report/CampaignPerformance";
import LocationDistribution from "@global/campaign-report/LocationDistribution";
import AgeAndGenderDistribution from "@global/campaign-report/AgeAndGenderDistribution";
import ReportTotalList from "@components/my-jobs/jobs-detail/report-tab/ReportTotalList";
// Redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobReport } from "@slices/InfluencerJobReport.slice";

export default function ReportTab() {
  const jobReportInfo = useAppSelector(selectInfluencerJobReport);

  const tabPanelList = [
    {
      id: "campaignPerformance",
      component: (
        <CampaignPerformance
          columns={2}
          performance={jobReportInfo?.impression}
        />
      ),
      xs: 12,
      md: 8,
      data: jobReportInfo?.impression,
    },
    {
      id: "influencerPost",
      component: <InfluencerPost socialPost={jobReportInfo?.social_post} />,
      xs: 4,
      md: 4,
      data: jobReportInfo?.social_post,
    },
    {
      id: "engagement",
      component: <Engagement engagement={jobReportInfo?.engagement} />,
      xs: 12,
      md: 12,
      data: jobReportInfo?.engagement,
    },
    {
      id: "conversion",
      component: <Conversion />,
      xs: 12,
      md: 12,
    },
    {
      id: "ageAndGenderDistribution",
      component: <AgeAndGenderDistribution />,
      xs: 12,
      md: 12,
    },
    {
      id: "locationDistribution",
      component: <LocationDistribution />,
      xs: 12,
      md: 12,
    },
  ];

  return (
    <Box sx={classes.container}>
      <div>
        <Typography variant="h5">Campaign Report</Typography>
        <Typography variant="subTitle1">รายงานแสดงข้อมูลของแคมเปญ</Typography>
      </div>

      <Divider sx={classes.divider} />

      <ReportTotalList
        overview={jobReportInfo?.overview}
        publishedLink={jobReportInfo?.social_post?.post?.published_url}
      />

      <Grid container spacing={5} mt="24px">
        {tabPanelList.map(
          (tab, index) =>
            tab.data && (
              <Grid item xs={tab.xs} md={tab.md} key={index}>
                <Box height="100%">
                  {tab.component}
                </Box>
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
}
