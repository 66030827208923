import { useMyJobsDetailStyle } from "@views/my-jobs/jobs-detail/JobsDetail.style";

export const useInfluencerInfoStyle = {
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& span": {
      color: "black",
      fontSize: "14px",
    },
    "& svg": {
      color: "black",
      fontSize: "18px",
    },
  },

  tab: useMyJobsDetailStyle.tab,
  tabBox: useMyJobsDetailStyle.tabBox,
  tabSelected: useMyJobsDetailStyle.tabSelected,
};
