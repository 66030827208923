import { useEffect, useState } from "react";
// mui
import { Box, Typography } from "@mui/material";
// other
import { SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ICompany } from "@api/app-information/type";

interface IOptionList extends ICompany {}

interface ISearchNameInput {
  placeholder?: string;
  isClearable?: boolean;
  option: IOptionList[];
  setValue: Function;
  getValues: Function;
  keyName: string;
  [any: string]: any;
  defaultValue: IOptionList;
}

export default function SearchNameInputCustom(props: ISearchNameInput) {
  const {
    placeholder,
    option,
    setValue,
    employerId,
    getValues,
    clearErrors,
    error,
    keyName,
    setError,
    defaultValue,
  } = props;
  const [valueDefault, setValueDefault] = useState<IOptionList>();

  const colourStyles = {
    control: (styles: any, { menuIsOpen }: any) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: menuIsOpen ? "15px 15px 0 0" : "50px",
      border: error ? "1px solid red" : "1px solid #8C499C",
      boxShadow: "#8C499C",
      "&:hover": {
        border: error ? "1px solid red" : "1px solid #8C499C",
      },
      cursor: "text",
      "& .css-tj5bde-Svg": {
        cursor: "pointer",
      },
      fontSize: "14px",
      fontWeight: 300,
      paddingLeft: "3px",
    }),
    option: (styles: any) => {
      return {
        ...styles,
        color: "#8C499C",
        marginTop: "-3.5px",
        marginBottom: "-4px",
        overflow: "hidden",
        backgroundColor: "#fff",
        ":hover": {
          backgroundColor: "#EBDFFF",
        },
      };
    },
  };

  const handleChange = (value: SingleValue<IOptionList>) => {
    if (!value?.id) {
      setValue(keyName, {
        ...defaultValue,
        id: 0,
        name: value?.value as string,
        employer_id: employerId as string,
        media: {
          ...defaultValue.media,
          file_id: "",
        },
      });
    } else {
      setValue(keyName, {
        id: value.id,
        name: value.value,
        employer_id: value.employer_id,
        media: {
          ...value.media,
        },
      });
    }
    if (getValues(`${keyName}.name`)) {
      clearErrors(keyName);
    } else {
      setError(`${keyName}.name`);
    }
  };

  useEffect(() => {
    const list = {
      ...defaultValue,
      label: defaultValue.name,
      value: defaultValue.name,
    };
    setValueDefault(list);
  }, [defaultValue]);

  return (
    <Box
      sx={{
        "& .css-1s2u09g-control": {
          border: "1px solid #8C499C",
          borderRadius: "50px",
        },
        "& .css-1pahdxg-control": {
          borderColor: "#8C499C",
          boxShadow: "none",
          borderRadius: "50px",
        },
        "& .css-14el2xx-placeholder": {
          fontSize: "14px",
          fontWeight: 300,
        },
        "& .css-26l3qy-menu": {
          mt: "-1px",
          borderRadius: "0 0 15px 15px",
          border: "1px solid #8C499C",
          "& div:last-of-type": {
            borderRadius: "0 0 13.7px 13.7px",
            border: "1px solid #fff",
          },
        },
      }}
    >
      <CreatableSelect
        isClearable
        placeholder={placeholder}
        options={option}
        onChange={(value) => handleChange(value)}
        styles={colourStyles}
        value={valueDefault && valueDefault.name ? valueDefault : null}
      />
      {error && (
        <Typography
          color="error"
          sx={{ fontSize: "12px", ml: "12px", mt: "3px" }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
}
