// mui and style
import {
  Stack,
  Icon,
  Button,
  Divider,
  Typography,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import { useSignInDialogStyle as classes } from "./authentication.style";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgetPasswordSchema } from "./AuthenticationForm.schema";
// type and api
import {
  IForgetPasswordSchema,
  IForgetPasswordDialogProps,
} from "./AuthenDialog.types";
import { AuthenticationAPI } from "@api/global";
// other
import { useHistory } from "react-router";
import { hAlertColor } from "@theme/index";
import TextFieldCustom from "../global/text-field";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import DialogAuthentication from "../global/dialog-authentication";

const initForgetPassword: IForgetPasswordSchema = {
  email: "",
};

export default function ForgetPasswordDialog(props: IForgetPasswordDialogProps) {
  const { open, setOpenForgetPassword, openResetPassword, openSignIn } = props;

  const {
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<IForgetPasswordSchema>({
    resolver: yupResolver(ForgetPasswordSchema),
    defaultValues: initForgetPassword,
  });

  const history = useHistory();

  const handleCloseDialog = () => {
    setOpenForgetPassword(false);
  };
  
  const onSubmit = async (value: IForgetPasswordSchema) => {
    const email = value.email;
    
    try {
      await AuthenticationAPI.fogotPassword(value);
      history.replace(`?email=${email}`);
      openResetPassword();
    } catch (error: any) {
      let data = error.response.data;
      switch (data.error.message) {
        case "error, can't query to updated the recovery_code":
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "ไม่พบอีเมลของคุณ",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
          break;
      }
    }
  };

  const handleChangeInput = (e: any, email: keyof IForgetPasswordSchema) => {
    setValue(email, e.target.value);
    clearErrors(email);
  };

  return (
    <DialogAuthentication
      title="Forgot Password?"
      open={open}
      onClose={handleCloseDialog}
      sx={classes.authenDialog}
    >
      <DialogContent sx={classes.dialogContent}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <Typography variant="body1" color="grey.700" align="center">
            Please enter your email address below <br />
            The system will send you a new password <br />
            through your email.
          </Typography>
          <TextFieldCustom
            value={getValues("email")}
            onChange={(e) => handleChangeInput(e, "email")}
            variant="outlined"
            placeholder="Email"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="ico-hu-email-solid"
                    sx={{ ...classes.textFieldIcon, paddingRight: "2px" }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Button type="submit" fullWidth>
            <Typography variant="body1" fontWeight="bold">
              Reset Password
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
      <Divider sx={classes.divider} />
      <DialogContent sx={classes.dialogContent}>
        <Stack>
          <Typography variant="body1" align="center">
            Already have an account?
          </Typography>
          <Typography
            color="primary"
            variant="body1"
            fontWeight="bold"
            align="center"
            onClick={() => openSignIn()}
            sx={classes.createAccountLink}
          >
            Sign In
          </Typography>
        </Stack>
      </DialogContent>
    </DialogAuthentication>
  );
}
