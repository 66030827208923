import { useState } from "react";
// mui and icons
import {
  Box,
  Fab,
  Icon,
  Stack,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// style and type
import { AppInformationAPI } from "@api/global";
import { IMultiVideoInputProps } from "./JobDefault.type";
// style and others
import { useMultiVideoInputStyle as classes } from "./JobDefault.style";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import ReactPlayer from "react-player";

export default function MuitiVideoInput(props: IMultiVideoInputProps) {
  const { keyName, setValue, errors, videos, showVideoIndex, disableIsActive } =
    props;

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const xsScreen = theme.breakpoints.between("xs", "sm");

  const onInputVideo = async (ev: any) => {
    const files = ev.target.files;
    const uploadPromises = [];
    const maxSizeInMB = 25;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    setIsLoading(true);

    for (const file of files) {
      const fileData = {
        file,
        destination: "company",
        permission: "public",
      };

      if (file?.type !== "video/mp4") {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: "ประเภทของ video ควรเป็น mp4",
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });

        return;
      }

      if (file && file.size > maxSizeInBytes) {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: "ขนาดไฟล์ไม่เกิน 25 MB",
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      }

      const uploadPromise = AppInformationAPI.uploadAttachment(fileData)
        .then((resonse) => {
          const result = {
            file_id: "",
            file_name: resonse.file_name,
            file_type: resonse.file_type,
            url: resonse.public_url as string,
            destination: resonse.destination,
            purpose: "",
            is_approve: "new",
          };
          return result;
        })
        .catch((error) => {
          console.log("error", error)
          const errMsg = error.response.data.error.message;
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${errMsg ? errMsg : "มีบางอย่างผิดพลาด"}`,
            confirmButtonColor: "#E30D18",
            confirmButtonText: `Close`,
          });
        });

      uploadPromises.push(uploadPromise);
    }

    Promise.all(uploadPromises)
      .then((results) => {
        setIsLoading(false);
        if (results[0]) {
          setValue(keyName as any, [...videos, ...results]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const errMsg = error.response.data.error.message;
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${errMsg ? errMsg : "มีบางอย่างผิดพลาด"}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  const onHandleChangeVideo = async (e: any, fileIindex: number) => {
    const files = e.target.files[0];
    const data = {
      file: files,
      destination: "company",
      permission: "public",
    };
    const videoData = [...videos];
    if (data) {
      await AppInformationAPI.uploadAttachment(data)
        .then((response) => {
          videoData[fileIindex].file_name = response.file_name;
          videoData[fileIindex].url = response.public_url
            ? response.public_url
            : "";
          videoData[fileIindex].file_type = response.file_type;
          setValue(keyName as any, videoData);
        })
        .catch((err) => {
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${err.response.data.error.message}`,
            confirmButtonColor: "#E30D18",
            confirmButtonText: "ตกลง",
          });
        });
    }
  };

  const onHandleRemove = (index: number) => {
    const file = [...videos];
    file.splice(index, 1);
    setValue(keyName as any, file);
  };

  const onHandleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const onHandleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onHandleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetIndex: number
  ) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData("text/plain"));
    const videoItems = [...videos];
    const [removedImage] = videoItems.splice(sourceIndex, 1);
    videoItems.splice(targetIndex, 0, removedImage);
    setValue(keyName, videoItems);
  };

  return (
    <Stack
      direction="row"
      columnGap={4}
      rowGap={4}
      flexWrap="wrap"
      mr={[0, 10]}
    >
      {videos.map((video, index) => (
        <Box
          key={index}
          draggable
          onDragStart={(e) => onHandleDragStart(e, index)}
          onDragOver={(e) => onHandleDragOver(e)}
          onDrop={(e) => onHandleDrop(e, index)}
        >
          <Box
            component="label"
            position="relative"
            height={["100px", "120px"]}
            sx={
              disableIsActive && video.is_approve === "approved"
                ? classes.videoIsActive
                : classes.videoIsNotActive
            }
          >
            {disableIsActive && video.is_approve === "approved" ? (
              <Fab sx={classes.removeBtn}>
                <Icon className="ico-hu-check" sx={{ fontSize: "16px" }} />
              </Fab>
            ) : (
              <>
                <input
                  type="file"
                  accept="video/mp4"
                  id="input-file"
                  onChange={(e) => onHandleChangeVideo(e, index)}
                  style={{ display: "none" }}
                />
                <Fab
                  onClick={() => onHandleRemove(index)}
                  sx={classes.removeBtn}
                >
                  <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
                </Fab>
              </>
            )}
            <Box sx={classes.iconPlayVideo}>
              <Icon
                sx={{
                  fontSize: "40px",
                  color: "#fff",
                }}
                className="ico-hu-play-circle-outline"
              />
            </Box>
            <ReactPlayer
              url={video.url}
              width={xsScreen ? "100px" : "120px"}
              height={xsScreen ? "100px" : "120px"}
              loop={true}
              muted={true}
            />
          </Box>

          {showVideoIndex && (
            <Typography variant="body2" textAlign="center" mt={1}>
              {`วิดีโอที่ ${index + 1}`}
            </Typography>
          )}
        </Box>
      ))}
      <Box height={["100px", "120px"]} width={["100px", "initial", "120px"]}>
        <Box
          component="label"
          sx={{ ...classes.labelUpload, borderColor: errors ? "red" : "grey" }}
        >
          {isLoading ? (
            <CircularProgress size="60px" color="primary" />
          ) : (
            <>
              <Box mb="-8px">
                <Icon
                  className="ico-hu-p-vdo"
                  sx={{ fontSize: { xs: "30px", sm: "48px" }, ml: "-3px" }}
                />
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Icon className="ico-hu-upload" sx={{ fontSize: "10px" }} />
                <span style={{ fontSize: "12px" }}>Video</span>
              </Stack>
              <input
                type="file"
                multiple
                accept="video/mp4"
                id="input-file"
                onChange={onInputVideo}
                style={{ display: "none" }}
              />
            </>
          )}
        </Box>
      </Box>
      {errors && (
        <Typography color="error" fontSize="12px" m="10px 0 0 11px">
          {errors.message}
        </Typography>
      )}
    </Stack>
  );
}
