import { useContext, useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Avatar,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// api and type
import { PaymentAPI } from "@api/global";
import { IPaymentMethodData } from "@slices/type";
// style and redux
import { useAppSelector } from "@store/hook";
import { selectPaymentMethod } from "@slices/PaymentMethod.slice";
import { usePaymentMethodStyle as classes } from "./Offering.style";
// other
import { useParams } from "react-router-dom";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { CampaignInfoContext } from "@views/campaign/campaign-detail";

export default function PaymentMethod() {
  const [expanded, setExpanded] = useState<string | false>(false);
  const campaignWallet = useContext(CampaignInfoContext)?.campaignWalletBalance;

  const { campaignId } = useParams<{ campaignId: string }>();

  const paymentMethodList = useAppSelector(selectPaymentMethod);
  const isWalletBalance = (campaignWallet?.total || 0) >= 0;

  const employerInfo = useAppSelector(selectEmployerInfo);
  const isPersonal = employerInfo?.package_plan === "personal";

  const internetBanking = paymentMethodList?.find((method) => method.id === 1);
  const mobileBanking = paymentMethodList?.find((method) => method.id === 2);
  const creditCard = paymentMethodList?.find((method) => method.id === 3);
  const eWallet = paymentMethodList?.find((method) => method.id === 4);
  const qrPayment = paymentMethodList?.find((method) => method.id === 5);

  const numFormater = Intl.NumberFormat("en", {
    notation: "standard",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleChangeExpand = (panel: string) => (_event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChillPayPublisher = (data?: IPaymentMethodData) => {
    const plan = isPersonal
      ? { employer_id: employerInfo.employer_full_id }
      : { team_id: employerInfo?.team?.id };

    const body = {
      ...plan,
      amount: (campaignWallet?.total || 0) * -1,
      description: "hold-campaign",
      pay_type: {
        id: 2,
      },
      pay_method: {
        id: data?.id || 0,
      },
      campaign: {
        id: campaignId
      },
      attachment: null,
      payment_plan: "personal"
    };
    PaymentAPI.chillpayPublisher(body)
      .then((response) => {
        if (response.PaymentUrl) {
          window.location.replace(response.PaymentUrl);
        } else {
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${response.Message}`,
            showCancelButton: true,
            cancelButtonText: "Close",
            showConfirmButton: false,
          })
        }
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `มีบางอย่างผิดพลาด`,
          showCancelButton: true,
          cancelButtonText: "Close",
          showConfirmButton: false,
        });
      });
  };

  return (
    <div>
      <Typography variant="h6">
        {`${!isWalletBalance ? "เลือก" : ""}ช่องทางการชำระเงิน`}
      </Typography>
      {isWalletBalance ? (
        <Box sx={classes.isWalletBalanceBox}>
          <Typography variant="h6">HashU Wallet</Typography>
          <Typography variant="h4" mt="16px">
            {numFormater.format(campaignWallet?.campaign_price || 0)} THB
          </Typography>
        </Box>
      ) : (
        <Box sx={classes.accordiun}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangeExpand("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ height: "64px", p: "24px" }}
            >
              <Typography variant="title1">QR Payment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} p="0 15px 15px 15px">
                {qrPayment?.data.map((li, index) => (
                  <Grid item key={index} xs={4} xl={3}>
                    <Box
                      onClick={() => onChillPayPublisher(li)}
                      sx={classes.accordionDetails}
                    >
                      <Avatar
                        src={li.image_logo_url?.[0]}
                        sx={classes.qrPaymentImg}
                      >
                        {li.title.charAt(0)}
                      </Avatar>
                      <Typography ml="10px" textTransform="capitalize">
                        {li.title.replace("_", " ")}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChangeExpand("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ height: "64px", p: "24px" }}
            >
              <Typography variant="title1">e-Wallet</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} p="0 15px 15px 15px">
                {eWallet?.data.map((li, index) => (
                  <Grid item xs={4} xl={3} key={index}>
                    <Box
                      onClick={() => onChillPayPublisher(li)}
                      sx={classes.accordionDetails}
                    >
                      <Avatar
                        src={li.image_logo_url?.[0]}
                        sx={classes.methodImg}
                      >
                        {li.title.replace("epayment_", "").charAt(0)}
                      </Avatar>
                      <Typography ml="10px" textTransform="capitalize">
                        {li.title.replace("epayment_", "")}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChangeExpand("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ height: "64px", p: "24px" }}
            >
              <Typography variant="title1">Credit / Debit Card</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} p="0 15px 15px 15px">
                {creditCard?.data.map((li, index) => (
                  <Grid item xs={4} xl={3} key={index}>
                    <Box
                      onClick={() => onChillPayPublisher(li)}
                      sx={classes.accordionDetails}
                    >
                      <Avatar
                        src={li.image_logo_url?.[0]}
                        sx={classes.craditImg}
                      >
                        {li.title.charAt(0)}
                      </Avatar>
                      <Typography ml="10px" textTransform="capitalize">
                        {li.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChangeExpand("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ height: "64px", p: "24px" }}
            >
              <Typography variant="title1">Mobile Banking</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} p="0 15px 15px 15px">
                {mobileBanking?.data.map((li, index) => (
                  <Grid item xs={4} xl={3} key={index}>
                    <Box
                      onClick={() => onChillPayPublisher(li)}
                      sx={classes.accordionDetails}
                    >
                      <Avatar
                        src={li.image_logo_url?.[0]}
                        sx={classes.methodImg}
                      >
                        {li.title.charAt(0)}
                      </Avatar>
                      <Typography ml="10px" textTransform="capitalize">
                        {li.title.replace("_", " ")}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChangeExpand("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ height: "64px", p: "24px" }}
            >
              <Typography variant="title1">Internet Banking</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} p="0 15px 15px 15px">
                {internetBanking?.data.map((li, index) => (
                  <Grid item xs={4} xl={3} key={index}>
                    <Box
                      onClick={() => onChillPayPublisher(li)}
                      sx={classes.accordionDetails}
                    >
                      <Avatar
                        src={li.image_logo_url?.[0]}
                        sx={classes.methodImg}
                      >
                        {li.title.charAt(0)}
                      </Avatar>
                      <Typography ml="10px" textTransform="capitalize">
                        {li.title.replace("_", " ")}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </div>
  );
}
