import { useContext, useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Button,
  Avatar,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
// theme and style
import { hAlertColor } from "@theme/index";
import { useSettingTabStyle as classes } from "./SettingTab.style";
// component
import TransferOwnershipDialog from "./setting-dialog/TransferOwnership";
// global
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
// context and api
import TeamContext from "@contexts/Team";
import { AppInformationAPI, TeamAPI } from "@api/global";
// other
import { useHistory } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

interface IAttachment {
  file_name: string;
  file_type: string;
  url: string;
  purpose: string;
  description: string;
}

const initialAttachment: IAttachment = {
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};

export default function SettingTab() {
  const teamContext = useContext(TeamContext);
  const teamInfo = teamContext?.teamInfo;

  const [teamName, setTeamName] = useState("");
  const [isTeamNameChange, setIsTeamNameChange] = useState(false);
  const [memberIdSelected, setMemberIdSelected] = useState("");
  const [openTransferOwnership, setOpenTransferOwnership] = useState(false);
  const [attachment, setAttachment] = useState<IAttachment>(initialAttachment);
  const [isImageChange, setIsImageChange] = useState(false);

  const history = useHistory();
  const { session_token } = getTokenFromStorage();
  const memberInfo = teamInfo?.employer?.find((mem) => mem.id === session_token?.employer_id)

  useEffect(() => {
    if (!teamInfo) return;
    if (teamInfo.attachment !== null) {
      setAttachment(teamInfo.attachment);
    }
    if (teamInfo?.name) {
      setTeamName(teamInfo.name)
    }
  }, [teamInfo]);

  const memberList = teamInfo?.employer?.filter(
    (li) => li.role !== "Team Lead" && li.status !== "invited"
  );

  const memberSelected = teamInfo?.employer?.find((m) => m.id === memberIdSelected);
  const teamLead = teamInfo?.employer?.find((m) => m.role === "Team Lead");

  const handleChangeTeamName = (name: string) => {
    setTeamName(name);
    setIsTeamNameChange(teamInfo?.name !== name);
  };

  const onInputImage = async (e: any) => {
    teamContext?.setIsLoading(true);
    let data = {
      file: e.target.files[0],
      destination: "setting-team",
      permission: "public",
    };

    const result = await AppInformationAPI.uploadAttachment(data);
    setAttachment({
      file_name: result.file_name,
      file_type: result.file_type,
      url: result.public_url || "",
      purpose: "",
      description: result.destination,
    });
    setIsImageChange(true);
    teamContext?.setIsLoading(false);
  };

  const updateTeamWithAPI = async () => {
    teamContext?.setIsLoading(true);

    const body = {
      name: teamName,
      description: "",
      attachment: attachment,
    };

    TeamAPI.updateTeam(teamInfo?.id || "", body).then((response) => {
      teamContext?.setTeamInfo(response)
      teamContext?.setIsLoading(false);
      SwalCustom.fire({
        icon: "success",
        title: "เปลี่ยนแปลงสำเร็จ",
        text: `คุณได้ทำการเปลี่ยนแปลง Team`,
        confirmButtonColor: hAlertColor.success,
        confirmButtonText: "ตกลง",
      }).then(() => {
        setIsTeamNameChange(false);
        setIsImageChange(false);
      });
    }).catch(() => {
      teamContext?.setIsLoading(false);
    })
  };

  const changeTeamLead = async () => {
    teamContext?.setIsLoading(true);

    const data = {
      employer: {
        id: memberIdSelected,
        team_role_id: 2,
      },
    };

    try {
      const response = await TeamAPI.updateTeam(teamInfo?.id || "", data);
      teamContext?.setTeamInfo(response);
      SwalCustom.fire({
        icon: "success",
        title: "โอนสิทธิ์สำเร็จ",
        text: `คุณได้โอนสิทธิ์ให้ "${memberSelected?.fullname}" เป็น Team Lead คนใหม่`,
        confirmButtonColor: hAlertColor.success,
        confirmButtonText: "ตกลง",
      }).then(() => {
        setMemberIdSelected("");
      });
    } finally {
      teamContext?.setIsLoading(false);
    }
  };

  return (
    <Box sx={classes.container}>
      <div>
        <Typography variant="h5">Team Setting</Typography>
        <Typography variant="subTitle1">ตั้งค่าทีม</Typography>
      </div>
      <Divider sx={{ m: "24px 0" }} />
      <Box display="flex" flexDirection="column" rowGap="24px">
        <Box sx={classes.card}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item xs={8}>
              <Box width="150px">
                <Typography variant="title1">โลโก้ทีม</Typography>
                <label onChange={onInputImage}>
                  <Avatar src={attachment.url} sx={classes.compLogo} />
                  <input
                    disabled={
                      session_token?.email === teamLead?.email ? false : true
                    }
                    type="file"
                    accept="image/*"
                    id="input-file"
                    style={{ display: "none" }}
                  />
                </label>
              </Box>
              <Box display="flex" flexDirection="column" my="20px">
                <Typography variant="title1">ชื่อทีม</Typography>
                <TextFieldCustom
                  variant="outlined"
                  placeholder="ชื่อทีม"
                  fullWidth
                  value={teamName}
                  onChange={(e) => handleChangeTeamName(e.target.value)}
                  sx={{ mt: "6px", maxWidth: "500px" }}
                />
              </Box>
              <Box display="flex" alignItems="center" columnGap="20px">
                <Button
                  onClick={updateTeamWithAPI}
                  variant="contained"
                  sx={classes.submitBtn}
                  disabled={!isImageChange && !isTeamNameChange}
                >
                  บันทึกการเปลี่ยนแปลง
                </Button>
                {isTeamNameChange && (
                  <Typography
                    variant="buttonM"
                    color="#8C499C"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setTeamName(teamInfo?.name || "");
                      setIsTeamNameChange(false);
                    }}
                  >
                    ยกเลิก
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.card}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6">โอนสิทธิ์การเป็นเจ้าของทีม</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                คุณสามารถเลื่อนระดับสมาชิกในทีมของคุณให้เป็น “Team Lead” ได้
                โดยหลังจาก การเปลี่ยนแปลงคุณจะเปลี่ยนสถานะไปเป็น “Member”
                และไม่สามารถย้อนกลับ การเปลี่ยนแปลงนี้ได้อีก
              </Typography>

              <Box display="flex" flexDirection="column" my="20px">
                <Typography variant="title1">Team Lead คนใหม่</Typography>
                <BasicSelectCustom
                  disabled={
                    session_token?.email === teamLead?.email ? false : true
                  }
                  className="select-member"
                  id="member-select"
                  value={memberIdSelected}
                  onChange={(e) => setMemberIdSelected(e.target.value)}
                  data={memberList || []}
                  valueKey="id"
                  labelKey="fullname"
                  iconKey="icon"
                  placeholder="เลือกจากสมาชิกในทีม"
                  fullWidth
                  sx={{ mt: "6px", maxWidth: "500px" }}
                />
                {memberSelected && (
                  <Box sx={classes.memberSelected}>
                    <Box display="flex">
                      <Avatar
                        src={memberSelected.profile_image_url}
                        sx={{ width: "48px", height: "48px" }}
                      />
                      <Box sx={classes.memberSelectedName}>
                        <Typography variant="title1">
                          {memberSelected.fullname}
                        </Typography>
                        <Typography variant="body2">
                          {memberSelected.email}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() => setMemberIdSelected("")}
                      sx={{ width: "40px", height: "40px" }}
                    >
                      <Icon className="ico-hu-close" />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Box display="flex" alignItems="center" columnGap="20px">
                <Button
                  variant="contained"
                  disabled={memberSelected === undefined}
                  sx={classes.submitBtn}
                  onClick={changeTeamLead}
                >
                  โอนสิทธิ์การเป็นเจ้าของทีม
                </Button>
                {memberSelected && (
                  <Typography
                    variant="buttonM"
                    color="#8C499C"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setMemberIdSelected("")}
                  >
                    ยกเลิก
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.card}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6">ลบทีม</Typography>
            </Grid>
            <Grid item xs={8}>
              <Box sx={classes.deleteInfoBox}>
                <Typography variant="title1" color="#E30D18">
                  คุณต้องการลบทีมใช่หรือไม่ ?
                </Typography>
                <Typography mt="5px">
                  เมื่อคุณดำเนินการลบทีม สมาชิกของทั้งหมดจะถูกออกจากทีม
                  และระบบจะดำเนินการลบข้อมูลแคมเปญ ประวัติการทำงาน
                  ธุรกรรมทางการเงิน รวมถึงข้อมูลอื่นๆทั้งหมด
                  และไม่สามารถกู้คืนข้อมูลได้อีก
                </Typography>
              </Box>
              <Button
                onClick={() => history.push("/team/delete")}
                variant="contained"
                disabled={memberInfo?.permission_id !== 0}
                sx={classes.deleteBtn}
              >
                ลบทีม
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* dialog */}
      <TransferOwnershipDialog
        open={openTransferOwnership}
        onClose={setOpenTransferOwnership}
        teamLead={teamLead || null}
        newLead={memberSelected || null}
      />
    </Box>
  );
}
