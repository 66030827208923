import { useContext, useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Table,
  Avatar,
  Select,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  FormControl,
  DialogActions,
} from "@mui/material";
import { useMemberStyles as classes } from "../Member.style";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
// api and type
import { TeamAPI } from "@api/global";
import { IMemberDialogProps, IPermission } from "../Member.type";
// other
import TeamContext from "@contexts/Team";
import Loading from "@global/loading/Loading";
import { permissionList } from "../Member.constants";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";

export default function EditMemberPermission(props: IMemberDialogProps) {
  const { open, onClose, onOpenToReadPermission } = props;
  const teamContext = useContext(TeamContext);
  const teamInfo = teamContext?.teamInfo;

  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IPermission[]>([]);

  useEffect(() => {
    if (!teamInfo) return;
    const data = teamInfo.employer?.filter((item) => item.role !== "Team Lead");
    setPermission(data || []);
  }, [teamInfo]);

  const handleChange = (index: number, id: number) => {
    const permList = [...permission];
    const perm = { ...permList[index], permission_id: id };
    permList[index] = perm;
    setPermission(permList);
  };

  const onUpdatePermission = async (data: IPermission[]) => {
    setIsLoading(true);

    const newData = data.map((item) => {
      return {
        employer_id: item.id,
        permission: item.permission_id,
      };
    });

    const reqBody = {
      employers: newData,
    };

    TeamAPI.updateTeamMemberPermission(teamInfo?.id || "", reqBody)
      .then((response) => {
        onClose(false);
        teamContext?.setTeamInfo(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading />;

  return (
    <DialogScrollingCustom
      open={open}
      onClose={() => onClose(false)}
      title="แก้ไข Permission"
      sx={classes.dialog}
    >
      {permission.length ? (
        <>
          <Box p="24px">
            <Typography variant="title1">แก้ไข Permission</Typography>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      Permission
                      <Icon
                        onClick={onOpenToReadPermission}
                        className="ico-hu-info-circle-outline"
                        sx={classes.permission}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {permission.map((item, i) => (
                  <TableRow key={item.id} sx={classes.tableRow}>
                    <TableCell component="th" scope="row">
                      <Box display="flex" alignItems="center">
                        <Box mr="12px">
                          <Avatar src={item.profile_image_url} />
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="title1">
                            {item.fullname}
                          </Typography>
                          <Typography variant="caption">
                            {item.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell width="45%">
                      <FormControl fullWidth sx={{ maxHeight: "40px" }}>
                        <Select
                          id={`permission-${item.id}-select`}
                          value={permission[i].permission_id}
                        >
                          {permissionList &&
                            permissionList.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.id}
                                onClick={() => handleChange(i, item.id)}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <DialogActions sx={{ justifyContent: "center" }}>
            <SaveButton
              onClick={() => onUpdatePermission(permission)}
              variant="contained"
              type="submit"
              startIcon={<Icon className="ico-hu-save" />}
              sx={{ mb: "10px" }}
            >
              <Typography variant="button">Save</Typography>
            </SaveButton>
          </DialogActions>
        </>
      ) : (
        <Box p="24px">
          <Typography textAlign="center">ไม่มีสมาชิค</Typography>
        </Box>
      )}
    </DialogScrollingCustom>
  );
}
