const texEllip = {
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: " ellipsis",
};

export const useCampaignCardStyle = {
  card: {
    position: "relative",
    boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
    minWidth: {
      xs: "300px",
      sm: "inherit",
    },
  },
  cardTop: {
    "& > span": {
      position: "absolute",
      top: 20,
      right: 20,
      color: "#fff",
    },
  },
  companyLogo: {
    position: "absolute",
    top: "16px",
    left: "16px",
    width: "40px",
    height: "40px",
    borderRadius: "4px",
  },
  jobStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: ["40px", "32px"],
    px: "15px",
    "& > p": {
      ...texEllip,
      fontWeight: 500,
      fontSize: ["16px", "16px"],
      letterSpacing: ["0.2px", "0.15px"],
      lineHeight: "24px",
    },
  },
  updateTime: texEllip,
  category: {
    display: "flex",
    alignItems: "center",
    mb: "5px",
    minHeight: "20px",
  },
  cateDivider: {
    fontSize: "4px",
    color: "#505050",
  },
  cateItems: {
    overflow: "hidden",
    height: "20px",
    fontSize: "12px",
  },
  jobName: {
    fontSize: "18px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  content: {
    mt: "12px",
  },
  platform: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      fontSize: "14px",
      ml: "5px",
      textTransform: "capitalize",
    },
  },
  avgBudget: {
    display: "flex",
    alignItems: "center",
    "& > .MuiSvgIcon-root": {
      mr: "5px",
      width: "20px",
      height: "20px",
    },
    "& > p": {
      fontSize: "14px",
    },
  },
  reviewType: {
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
      color: "#616161",
    },
  },
  reviewTypeTitle: {
    ml: "5px",
    "& > p": {
      overflow: "hidden",
      width: "250px",
      textOverflow: "ellipsis",
      color: "#5C5C5C",
      whiteSpace: "nowrap",
    },
  },
  cardBottom: {
    display: "flex",
    justifyContent: "space-between",
    height: "50px",
    mb: "-23px",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > p": {
        fontSize: "14px",
        ml: "5px",
      },
    },
    "& > p": {
      my: "auto",
      fontSize: "14px",
    },
  },
  offeringStatus: {
    bgcolor: "#FFEAF4",
    color: "#E8008A",
  },
  announceStatus: {
    color: "#0077E4",
    bgcolor: "#E5F6FF",
  },
  listingStatus: {
    color: "#893DBD",
    bgcolor: "#F4ECFF",
  },
  approvedStatus: {
    bgcolor: "#E7FFE8",
    color: "#4CAF50",
  },
  publishedStatus: {
    bgcolor: "#E5F6FF",
    color: "#0077E4",
  },
  completedStatus: {
    bgcolor: "#EAEAFF",
    color: "#4A489D",
  },
  rejectStatus: {
    bgcolor: "#9E9E9E",
    color: "#fff",
  },
  registedStatus: {
    color: "#212121",
    bgcolor: "#E0E0E0",
  },
  waitingStatus: {
    bgcolor: "#FFFBD4",
    color: "#FB8C00",
  },
  icon: {
    width: "24px",
    height: "24px",
    mt: "-3px",
    color: "#5C5C5C",
  },
  cardDetailBottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    "& > p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "90%",
      textOverflow: "ellipsis",
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
};
