import { createSvgIcon } from "@mui/material/utils";

export const HotIcon = createSvgIcon(
  <>
    <svg
      id="uuid-650eeaca-3abc-408a-ac69-dd3a8f10bc8f"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <path d="M41.85,47.36c-2.96-.61-6.57,10.22-2.57,9.78,2.97,.63,6.58-10.24,2.57-9.78Z" />
      <path d="M61.97,25.74c1.56,5.27-2.73,12.69-7.56,9.55-7.81-5.87,9.81-13.84-.9-23.17-5.05,15.68-20.78,2.9-12.13-10.12-10.29,.32-13.07,14.83-8.78,20.64,5.16,6.98,3.33,15.53-4.02,14.59-5.34-.69-7.24-7.6-3.82-13.95-31.59,14.86-8.86,63.07,23.02,53.48,21.31-5.73,30.73-33.89,14.19-51.01ZM30.33,59.77c.22,.33-3.37,.51-3.06,.14-.08-.14,1.78-5.88,1.42-5.86-.14,.11-4.84,0-4.83,.34l-1.52,5.74c.05,.45-2.67,.23-2.87,.34-.67,.03,3.77-14.29,3.6-14.49,.03-.13,.13-.22,.26-.23,.13,.14,3-.41,2.79,.09l-1.46,5.58c-.16,.35,4.68-.19,4.75-.08,.34,.04,1.5-5.59,1.65-5.72-.03-.45,2.67-.23,2.89-.34,.13,0,.21,.08,.18,.21l-3.78,14.29Zm14.34-3.53c-1.91,4.31-10.85,5.58-10.31-.65,.11-1.31,1.22-5.74,2.11-7.32,1.92-4.32,10.85-5.58,10.31,.65-.11,1.31-1.22,5.74-2.11,7.32Zm15.04-9.66c-.07,.57-3.87,.08-4.05,.52l-3.08,11.67c.04,.46-2.68,.23-2.89,.34-.59,0,3.06-11.67,2.92-11.88,.04-.45-4.02,.43-3.88-.17,.16-.18,.46-2.59,.87-2.5l10.54-.47c.53-.11-.51,2.34-.43,2.48Z" />
    </svg>
  </>,
  "HotIcon"
);
