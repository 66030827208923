import { useEffect, useState } from "react";
// mui, icon and style
import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEditAddressForMobileStyle as classes } from "../../MyProfile.style";
// component
import EditInfluencerAddress from "@components/influencer-profile/edit-influencer-profile/EditAddress";
import { IAddressForm } from "@components/influencer-profile/InfluencerProfile.type";
// hook-form
import {
  BillingFormSchema,
  ShippingFormSchema,
} from "@components/influencer-profile/InfluencerProfile.shema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// redux
import {
  selectInfluencerProfile,
  getInfluencerProfileAsync,
  updateInfluencerBillingAsync,
  selectInfluencerProfileOnload,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// other
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation } from "react-router-dom";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import { initAddressForm } from "@components/influencer-profile/influencer-default/Address";

type TAddressType = "billing_address" | "shipping_address";

export default function EditAddressForMobile() {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const addressType = `${params.get("type")}_address` || "billing_address";

  const form = useForm<IAddressForm>({
    resolver: yupResolver(
      addressType === "billing_address" ? BillingFormSchema : ShippingFormSchema
    ),
    defaultValues: initAddressForm,
  });

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerProfile = useAppSelector(selectInfluencerProfile);
  const influencerLoading = useAppSelector(selectInfluencerProfileOnload);
  const shippingInfo = influencerProfile?.address.shipping_address;
  const billingInfo = influencerProfile?.address.billing_address;
  const address =
    addressType === "shipping_address" ? shippingInfo : billingInfo;

  useEffect(() => {
    if (!address) return;
    form.reset(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getInfluencerProfileAsync({}))
  },[dispatch])

  const onUpdateAddress = (value: IAddressForm) => {
    setIsLoading(true);
    const body = {
      address: {
        [addressType]: {
          fullname: value.fullname,
          phone: value.phone.replaceAll("-", ""),
          address: value.address,
          postcode: value.postcode,
          province_id: value.province?.id as number,
          district_id: value.district?.id as number,
          subdistrict_id: value.subdistrict?.id as number,
          tax_id: value.tax_id.replaceAll("-", ""),
        },
      },
    };

    dispatch(updateInfluencerBillingAsync(body))
      .then(async () => {
        setIsLoading(false);
        await SwalCustom.fire({
          icon: "success",
          title: `Updated ${addressType.replaceAll("_", " ")} success`,
          showConfirmButton: false,
          timer: 1600,
        });
        history.push("/my-profile/mobile");
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
        });
      });
  };

  if (isLoading || influencerLoading) return <Loading />

  return (
    <div className="container-main">
      <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt="-10px">
        <KeyboardArrowLeftIcon
          onClick={() => history.goBack()}
          sx={{ position: "absolute", left: 0 }}
        />
        <Typography variant="h6">Address</Typography>
      </Box>
      <Box sx={classes.content}>
        <EditInfluencerAddress
          form={form}
          addressType={addressType as TAddressType}
          onSubmit={onUpdateAddress}
        />
      </Box>
    </div>
  );
}
