import { useEffect, useRef, useState } from "react";
// mui and style
import { Box, Stack, Typography } from "@mui/material";
import { useRegistrationFunnelStyle as classes } from "../OverviewTab.style";
// type
import { IRegistrationFunnelProps } from "../OverviewTab.type";

export default function RegistrationFunnel(props: IRegistrationFunnelProps) {
  const { funnel } = props;

  const [statsWidth, setStatsWidth] = useState(0);

  const statsRef = useRef<HTMLDivElement>(null);
  const numberFormat = Intl.NumberFormat("en");

  useEffect(() => {
    const handleResize = () => {
      const width = statsRef.current?.clientWidth;
      setStatsWidth(width ? width : 0);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const funnelList = [
    {
      key: "register",
      title: "Start Registration",
      reach: funnel?.start.reached,
      dropOff: funnel?.start.drop_off,
      statsColor: "#EBDFFF",
      class: classes.firstStats,
    },
    {
      key: "completeRegister",
      title: "Complete Registration",
      reach: funnel?.completed.reached,
      dropOff: funnel?.completed.drop_off,
      statsColor: "#D5A4F6",
      class: classes.secondStats,
    },
    {
      key: "profile",
      title: "Visit My Profile",
      reach: funnel?.visted_profile.reached,
      dropOff: funnel?.visted_profile.drop_off,
      statsColor: "#BC83E3",
      class: classes.thirdStats,
    },
    {
      key: "syncSocial",
      title: "Sync Social Media",
      reach: funnel?.sync_social.reached,
      dropOff: funnel?.sync_social.drop_off,
      statsColor: "#893DBD",
      class: classes.fourthStats,
    },
    {
      key: "referral",
      title: "Get Referral Code",
      reach: funnel?.got_refferal_code.reached,
      dropOff: funnel?.got_refferal_code.drop_off,
      statsColor: "#542485",
      class: classes.fithStats,
    },
  ];

  return (
    <Box sx={classes.title}>
      <Typography variant="h6">Registration Funnel</Typography>
      <Typography variant="subTitle2">ขั้นตอนการลงทะเบียน</Typography>
      <Stack direction="row" minHeight="450px" my="16px">
        {funnelList.map((funnel) => (
          <Box key={funnel.key} sx={classes.funnelDetailBox}>
            <Box p="16px">
              <Typography variant="subTitle1">{funnel.title}</Typography>
              <Typography variant="h4" color="#893DBD" fontWeight={400}>
                {numberFormat.format(funnel.reach?.number || 0)}
              </Typography>
            </Box>
            <Box ref={statsRef} sx={classes.statsBox}>
              <Box
                sx={{
                  ...funnel.class,
                  borderLeft: `${statsWidth}px solid ${funnel.statsColor}`,
                }}
              />
            </Box>
            <Stack direction="row" sx={classes.statsTotolBottom}>
              {funnel.key === "referral" ? (
                <Box>
                  <Typography variant="subTitle2">Conversion</Typography>
                  <Typography variant="title2" color="#4CAF50">
                    {`${numberFormat.format(funnel?.reach?.number || 0)} (${
                      funnel?.reach?.percent
                    }%)`}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="subTitle2">Reached</Typography>
                  <Typography variant="title2">
                    {`${numberFormat.format(funnel.reach?.number || 0)} (${
                      funnel.reach?.percent
                    }%)`}
                  </Typography>
                </Box>
              )}

              {funnel.key !== "referral" && (
                <Box>
                  <Typography variant="subTitle2">Drop off</Typography>
                  <Typography variant="title2">
                    {`${numberFormat.format(funnel.dropOff?.number || 0)} (${
                      funnel?.dropOff?.percent
                    }%)`}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
