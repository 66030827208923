// mui and style
import { Box, Divider, Icon, Typography } from "@mui/material";
import { useCaptionStyle as classes } from "./JobDefault.style";
// component and type
import Conversion from "./Conversion";
import { ICaptionProps } from "./JobDefault.type";
// others
import nl2br from "react-nl2br";
import Linkify from "react-linkify";
import TextFieldCustom from "@global/text-field";

export default function Caption(props: ICaptionProps) {
  const { jobInfo, isDraft, register } = props;

  const conversion = jobInfo?.conversion;
  const descIsApprove = jobInfo?.description?.is_approve;

  return (
    <Box sx={classes.captionCon}>
      <Box sx={classes.title}>
        <Typography>Caption</Typography>
        <Typography>ข้อความหรือแคปชั่นการโพสต์</Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Box p={["24px 0 24px 0", "24px"]}>
        {jobInfo?.campaign_info.objective === "conversion" && (
          <Conversion conversion={conversion} />
        )}
        {!isDraft ? (
          <Linkify>
            <Typography mb={2}>
              {nl2br(jobInfo?.description?.description)}
            </Typography>
          </Linkify>
        ) : (
          <>
            <Linkify>
              <Typography mb={2}>
                {nl2br(jobInfo?.description?.description)}
              </Typography>
            </Linkify>
            <Box>
              <TextFieldCustom
                variant="outlined"
                multiline
                minRows={5}
                placeholder="Please enter text..."
                fullWidth
                inputProps={
                  register
                    ? {
                        ...register(`description.description`),
                      }
                    : undefined
                }
              />
            </Box>
          </>
        )}
        {descIsApprove === "not_approved" && jobInfo?.description?.note ? (
          <Box sx={classes.captionEditingDesc}>
            <Typography>ความคิดเห็นจากผู้จ้างงาน :</Typography>
            <Typography>{nl2br(jobInfo?.description?.note)}</Typography>
          </Box>
        ) : (
          descIsApprove === "approved" && (
            <Box display="flex" alignItems="center" mt="24px">
              <Icon className="ico-hu-check" color="success" />
              <Typography color="#4CAF50" variant="title1" ml={2}>
                Caption ได้รับการอนุมัติแล้ว
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}
