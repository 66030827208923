import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useNotificationStyle as classes } from "./Notification.style";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export default function NotificationDialog({ open, onClose }: { open: boolean, onClose: (val:boolean) => void }) {
  const [browserName, setBrowserName] = useState('');
  const [stepCount, setStepCount] = useState(1)
  
  useEffect(() => {
    const userAgent = navigator.userAgent;
    
    if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      setBrowserName('chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowserName('firefox');
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      setBrowserName('safari');
    } else if (userAgent.includes('Edg')) {
      setBrowserName('edge');
    } else {
      setBrowserName('Unknown');
    }
  }, []);
  
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={classes.dialog}
    >
      <DialogContent sx={classes.contentDialog}>
        <NotificationsActiveIcon color="primary" sx={{ fontSize:"50px", transform: "rotate(10deg)" }} />
        <CloseIcon onClick={() => onClose(false)} sx={{position:'absolute', top: "20px", right: "20px", cursor:"pointer"}} />
        <Typography variant='h6' textAlign="center">
          วิธีการรับข่าวสารแจ้งเตือนจาก HashU
        </Typography>
        {browserName === "safari" ? (
          <>
            {stepCount === 1 ? (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", mt:"10px" }}}>
                <Typography>
                  1. ผู้ใช้งานเข้าไปที่การตั้งค่า <span style={{ fontWeight:500 }}>‘System Setting’</span>
                </Typography>
                <img src={require('@assets/images/notification/safari/safari-step1.png')} alt='chrom-step1' />
              </Box>
            ) : stepCount === 2 ? (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", mt:"10px" }}}>
                <Typography>
                  2. ผู้ใช้งานกดเลือก <span style={{ fontWeight:500 }}>‘Notifications’</span>  และค้นหา <span style={{ fontWeight:500 }}>‘Safari’</span>
                </Typography>
                <img src={require('@assets/images/notification/safari/safari-step2.png')} alt='chrom-step1' />
              </Box>
            ) : (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", mt:"10px" }}}>
                <Typography>
                  3. หลังจากนั้นเข้าไปที่ <span style={{ fontWeight:500 }}>‘Safiri’</span> และกดเปิดตัว <span style={{ fontWeight:500 }}>‘Allow notifications’</span>
                </Typography>
                <img src={require('@assets/images/notification/safari/safari-step3.png')} alt='chrom-step1' />
              </Box>
            )}
          </>
        ) : browserName === "firefox" ? (
          <>
            {stepCount === 1 ? (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", height:"200px", mt:"10px" }}}>
                <Typography>
                  1. ผู้ใช้งานกดปุ่ม <span style={{ fontWeight:500 }}>‘View site information’</span> ที่ช่องลิงก์ URL
                </Typography>
                <img src={require('@assets/images/notification/firefox/firefox-step1.png')} alt='chrom-step1' />
              </Box>
            ) : (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", mt:"10px" }}}>
                <Typography>
                  2. ผู้ใช้งานกดเลือก <span style={{ fontWeight:500 }}>‘Allow’</span> เพื่อรับการแจ้งเตือนจาก HashU 
                </Typography>
                <img src={require('@assets/images/notification/firefox/firefox-step2.png')} alt='chrom-step1' />
              </Box>
            )}
          </>
        ) : browserName === "edge" ? (
          <>
            {stepCount === 1 ? (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", height:"200px", mt:"10px" }}}>
                <Typography>
                  1. ผู้ใช้งานกดปุ่ม <span style={{ fontWeight:500 }}>‘View site information’</span> ที่ช่องลิงก์ URL
                </Typography>
                <img src={require('@assets/images/notification/edge/edge-step1.png')} alt='chrom-step1' />
              </Box>
            ) : (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", mt:"10px" }}}>
                <Typography>
                  2. ผู้ใช้งานกดเลือก <span style={{ fontWeight:500 }}>‘Allow’</span> เพื่อรับการแจ้งเตือนจาก HashU 
                </Typography>
                <img src={require('@assets/images/notification/edge/edge-step2.png')} alt='chrom-step1' />
              </Box>
            )}
          </>
        ) : (
          <>
            {stepCount === 1 ? (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", height:"200px", mt:"10px" }}}>
                <Typography>
                  1. ผู้ใช้งานกดปุ่ม <span style={{ fontWeight:500 }}>‘View site information’</span> ที่ช่องลิงก์ URL
                </Typography>
                <img src={require('@assets/images/notification/chrom/chrom-step1.png')} alt='chrom-step1' />
              </Box>
            ) : (
              <Box mx="10px" sx={{"& > img":{ objectFit:"cover", width:"100%", mt:"10px" }}}>
                <Typography>
                  2. ผู้ใช้งานกดเปิด <span style={{ fontWeight:500 }}>‘Notifications’</span> เพื่อรับการแจ้งเตือนจาก HashU 
                </Typography>
                <img src={require('@assets/images/notification/chrom/chrom-step2.png')} alt='chrom-step1' />
              </Box>
            )}
        </>
        )}

        <Box display="flex" columnGap={[1, 10, 20, 30]}>
          <Button
            onClick={() => setStepCount((prev) => prev - 1)}
            sx={{ px:"40px", maxHeight:"35px" }}
            disabled={stepCount === 1}
          >
            <Typography fontWeight={400}>Previous</Typography>
          </Button>
          <Button
            variant='contained'
            onClick={() => setStepCount((prev) => prev + 1)}
            sx={{ border:"none", px:"50px", "&:hover":{ border:"none" }, maxHeight:"35px" }}
            disabled={stepCount === (browserName === "safari" ? 3 : 2)}
          >
            <Typography fontWeight={400}>Next{stepCount}</Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
