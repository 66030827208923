import { useState } from "react";
// mui and style
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { useBudgetAndRewardStyle as classes } from "./CampaignDetail.style";
// component
import BudgetAndRewardDialog from "./detail-dialog/BudgetAndReward";
// types and other
import { ICampaignDetailProps } from "./CampaignDetail.type";

export default function BudgetAndReward(props: ICampaignDetailProps) {
  const { campaignInfo } = props;

  const [openBudgetAndReward, setOpenBudgetAndReward] = useState(false);

  const numberFormat = Intl.NumberFormat("en");

  const budget = campaignInfo?.budget;
  const isCampaignPrivate = campaignInfo?.campaign_type === "private";

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={4} md={12} lg={4} xl={3}>
        <Typography variant="body1" fontWeight="500" mt={1}>
          ค่าตอบแทนในการรีวิว
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={12}
        lg={8}
        xl={9}
        mt={["-5px", 0, "-10px", 0]}
      >
        <Button variant="contained" sx={classes.compensation}>
          {isCampaignPrivate ? (
            "ขึ้นอยู่กับ Rate Card ของ Influencer"
          ) : (
            <>
              {numberFormat.format(Math.round(budget?.each_influencer || 0)) +
                " THB"}
            </>
          )}
        </Button>
      </Grid>
      {budget?.reward?.length ? (
        <>
          <Grid item xs={12} sm={4} md={12} lg={4} xl={3}>
            <Typography variant="body1" fontWeight="500">
              สินค้าและบริการ
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} sx={classes.productAndServiceGrid}>
            {budget?.reward &&
              budget.reward.map((reward, index) => (
                <Box
                  key={index}
                  sx={classes.productAndServiceCon}
                  onClick={() => setOpenBudgetAndReward(!openBudgetAndReward)}
                >
                  <Avatar src={reward.image.url} />
                  <Box sx={classes.productAndService}>
                    <Typography>{reward.name}</Typography>
                    <Typography>{reward.description}</Typography>
                  </Box>
                  <BudgetAndRewardDialog
                    open={openBudgetAndReward}
                    handleOpen={setOpenBudgetAndReward}
                    campaignDetail={reward}
                  />
                </Box>
              ))}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
