export const useBeforeUpgradePlanStyle = {
  titleWrap: {
    display: "flex",
    alignItems: "center",
    mt: "10px",
  },
  planSelecedWrap: {
    mt: "12px",
    width: "488px",
    display: "flex",
    "& button": {
      border: "none",
      fontSize: "16px",
      "&:hover": { border: "none" },
    },
    border: "1px solid #8C499C",
    p: "2px",
    borderRadius: "50px",
    bgcolor: "#fff",
  },
  numTeamWrap: {
    mt: "12px",
    width: "180px",
    height: "40px",
    border: "1px solid #8C499C",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableWrap: {
    bgcolor: "#fff",
    borderRadius: "20px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
    p: "20px",
    mt: "32px",
  },
  tContainer: {
    boxShadow: "none",
    borderRadius: 0,
  },
  tHRow: {
    "& th, tr": { height: "64px", p: 0 },
  },
  tBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& td, tr": { p: 2.5 },
  },
  tBTRow: {
    "& td, & th": {
      height: "60px",
      py: 0,
    },
  },
  tBRow: {
    "& td, & th": {
      height: "80px",
      py: 0,
    },
  },
  tRowIsCurrent: {
    bgcolor: "#F5EEFF",
    color: "#8C499C",
    cursor: "pointer",
    borderRadius: "12px 12px 0px 0px",
    borderBottom: "1px solid #8C499C",
  },
  tRowIsNotCurrent: {
    cursor: "pointer",
    borderRadius: "12px 12px 0px 0px",
  },
  platformIcon: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 2,
  },
  tBRowBottom: {
    height: "80px",
    "& td": {
      border: "none",
    },
  },
  upgradBtn: {
    fontSize: "16px",
    "&:not(.Mui-disabled)": {
      border: "2px solid #8C499C",
    },
  },
};