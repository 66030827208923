export const useCancelCampaignStyle = {
  card: {
    bgcolor: "#fff",
    p: "24px",
    mt: "24px",
    borderRadius: { xs: "8px", lg: "16px" },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  historyList: {
    m: "16px 0 24px 0",
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      mb: "8px",
    },
  },
  depositBox: {
    bgcolor: "#F5F6F8",
    borderRadius: "8px",
    p: "12px",
    "& > div:first-of-type": { mb: "8px" },
  },
  infRegistCampaign: {
    mt: "16px",
    "& > div:not(div:last-of-type)": { mb: "10px" },
    "& > div:last-of-type": {
      "& > p": {
        fontWeight: 500,
      },
    },
  },
};

export const useCancelCampaignSuccessDialogStyle = {
  dialog: {
    "& .css-fkeb6g-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "16px",
      p: "20px",
      width: "560px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconSuccess: {
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "4px solid rgba(76, 175, 80, 0.5)",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    columnGap: 2,
    mt: "20px",
    "& > button": {
      maxWidth: "200px",
      fontSize: "16px",
    },
  },
};

export const useCancelCampaignDialogStyle = {
  dialog: useCancelCampaignSuccessDialogStyle.dialog,
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > .MuiAvatar-root": {
      width: "320px",
      height: "320px",
      borderRadius: "8px",
    },
  },
  btnBox: {
    display: "flex",
    width: "100%",
    columnGap: 2,
    mt: "20px",
    "& > button:last-of-type": {
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
  },
};
