import React from "react";
// mui and icon
import { Avatar, Box, IconButton, Snackbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

export interface INotificationInfo {
  title?: string;
  body?: string;
  image?: string;
  redirect_url?: string;
}

interface INotiSnackbarProps {
  open: boolean;
  onClose: (val: boolean) => void;
  data?: INotificationInfo | null;
  autoHideDuration?: number;
}

export default function NotificationSnackbar(props: INotiSnackbarProps) {
  const { open, onClose, data, autoHideDuration } = props;

  const handleCloseSnackbar = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(false);
  };
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{
        p: "15px",
        borderRadius: "10px",
        bgcolor: "#353535",
        m: {
          xs: "126px 10px 0 10px",
          sm: "55px 0 0 0",
        },
        minWidth: "280px",
        boxShadow: "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#fff",
          cursor:"pointer"
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => window.open(data?.redirect_url, "_self")}
        >
          {data?.image ? (
            <Avatar
              src={data.image}
              sx={{ borderRadius: "8px", height: "45px", width: "45px" }}
            />
          ) : (
            <InfoIcon />
          )}
          <Box
            mx={4}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography variant="title1">{data?.title}</Typography>
            <Typography variant="body2">{data?.body}</Typography>
          </Box>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Box>
    </Snackbar>
  );
}
