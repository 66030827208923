import { useState, useEffect } from "react";
// mui
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  CardContent,
} from "@mui/material";
// data and react hook
import {
  plateformList,
  emphasisOrProhibitionList,
  descriptionAndMoodToneList,
} from "../campaignBrief.constants";
import { Controller } from "react-hook-form";
// icons and style
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useCampaignBriefDetailStyle as classes } from "../CampaignBrief.style";
// type and api
import {
  IContentType,
  ICampaignBriefFormKey,
} from "@views/campaign/create-campaign/CreateCampaign.type";
import { ICreateCampaignProps } from "../CampaignBrief.type";
import { IHashtag } from "@api/app-information/type";
import { AppInformationAPI } from "@api/global";
// componwnts
import ImageInput from "./ImageInput";
import MultiImagesInput from "./MultiImagesInput";
import MuitiVideosInput from "./MultiVideosInput";
// redux
import {
  getContentTypeAsync,
  selectContentTypelist,
} from "@slices/ContentTypeList.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// others
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import WrapInputWithLabel from "@global/wrap-input-label";
import CreatableSelectCustom from "@global/creatable-select-custom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function CampaignBrief(props: ICreateCampaignProps) {
  const { form } = props;

  const [copy, setCopy] = useState("copy");
  const [optionHashtag, setOptionHashtag] = useState<IHashtag[]>([]);
  const [optionContentType, setOptionContentType] = useState<IContentType[]>([]);

  const {
    watch,
    control,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = form;

  const watchContentType = watch("content_type_id");
  const watchPlatForm = getValues("platform") as string;

  const { session_token } = getTokenFromStorage();

  const dispatch = useAppDispatch();
  const contentTypeList = useAppSelector(selectContentTypelist);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const isTeam = employerInfo?.package_plan === "team";
  const packages = isTeam ? employerInfo.team?.package : employerInfo?.package;
  const campManage = packages?.campaign_management;

  const platformIsActived = plateformList.filter(
    (p) => !campManage?.platform.some((sp) => sp.title === p.value)
  );

  useEffect(() => {
    dispatch(getContentTypeAsync());
  }, [dispatch]);

  const handleChangeInput = (
    e: { target: { value: string } },
    keyName: ICampaignBriefFormKey
  ) => {
    const { value } = e.target;
    if (keyName === "platform") {
      setValue(keyName, value);
    } else {
      // eslint-disable-next-line array-callback-return
      const data = contentTypeList?.find((item) => {
        if (item.name === value) return item;
      });
      if (data) setValue(keyName, data);
    }
  };

  useEffect(() => {
    let isMounted = true;
    AppInformationAPI.getHashtagList()
      .then((response) => {
        if (isMounted) {
          const hashtagLists = response.map((item: IHashtag) => {
            return {
              id: item.id,
              label: item.value,
              value: item.value,
              slug: item.slug,
            };
          });
          setOptionHashtag(hashtagLists);
        }
      })
      .catch((err) => {
        if (isMounted) {
          console.log("err can not get hashtag error : ", err);
        }
      });

    return () => {
      isMounted = false
    }
  }, []);

  useEffect(() => {
    if (contentTypeList && watchPlatForm) {
      const filterList = contentTypeList.filter(
        (item) => item.platform === watchPlatForm
      );
      setOptionContentType(filterList);
    } else {
      setOptionContentType([]);
    }
  }, [contentTypeList, watchPlatForm]);

  return (
    <CardContent>
      <Stack spacing={4.5} sx={classes.plateform}>
        <WrapInputWithLabel title="Platform" required>
          <BasicSelectCustom
            className="select-plateform"
            id="platform-select"
            value={watch("platform")}
            onChange={(e) => handleChangeInput(e, "platform")}
            data={plateformList}
            valueKey="value"
            labelKey="label"
            iconKey="icon"
            placeholder="Select"
            fullWidth
            error={!!errors?.platform}
            helperText={errors?.platform?.message}
            sx={{ mb: "-10px" }}
            endValue={
              session_token?.role === "admin"
                ? undefined
                : platformIsActived.map((p) => {
                    return { value: p.value, total: "+490.00 บาท" };
                  })
            }
          />
        </WrapInputWithLabel>

        <WrapInputWithLabel title="Content Type" required>
          <BasicSelectCustom
            id="contentType-select"
            value={watchContentType.name}
            onChange={(e) => handleChangeInput(e, "content_type_id")}
            data={optionContentType}
            valueKey="name"
            labelKey="name"
            placeholder="Select"
            fullWidth
            error={!!errors?.content_type_id?.name}
            helperText={errors?.content_type_id?.name?.message}
          />
        </WrapInputWithLabel>

        <WrapInputWithLabel title="Cover image" required>
          <ImageInput
            form={form}
            keyName="brief.cover_image"
            defaultImg={getValues("brief.cover_image.url")}
            errors={errors?.brief?.cover_image?.url}
          />
        </WrapInputWithLabel>

        <WrapInputWithLabel title="Brief Image" required>
          <MultiImagesInput
            form={form}
            keyName="brief.content.media.images"
            errors={errors.brief?.content?.media?.images}
            images={getValues("brief.content.media.images")}
          />
        </WrapInputWithLabel>

        <WrapInputWithLabel title="Brief Video">
          <MuitiVideosInput
            form={form}
            keyName="brief.content.media.videos"
            errors={errors.brief?.content?.media?.videos}
            videos={watch("brief.content.media.videos")}
          />
        </WrapInputWithLabel>

        {descriptionAndMoodToneList.map((item, index) => (
          <WrapInputWithLabel key={index} title={item.title} required>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              multiline
              minRows={5}
              error={
                item.title === "Description"
                  ? !!errors?.brief?.content?.description
                  : !!errors?.brief?.mood_and_tone?.description
              }
              helperText={
                item.title === "Description"
                  ? errors?.brief?.content?.description?.message
                  : errors?.brief?.mood_and_tone?.description?.message
              }
              inputProps={{
                ...register(`brief.${item.id}` as ICampaignBriefFormKey, {
                  required: true,
                }),
              }}
            />
          </WrapInputWithLabel>
        ))}

        <WrapInputWithLabel title="Mood and Tone image">
          <MultiImagesInput
            form={form}
            keyName="brief.mood_and_tone.media.images"
            errors={errors?.brief?.mood_and_tone?.media?.images}
            images={getValues("brief.mood_and_tone.media.images")}
          />
        </WrapInputWithLabel>

        <WrapInputWithLabel title="Key Message" required>
          <TextFieldCustom
            variant="outlined"
            placeholder="Please enter text..."
            fullWidth
            multiline
            minRows={5}
            error={!!errors?.brief?.key_message}
            helperText={errors?.brief?.key_message?.message}
            inputProps={{
              ...register("brief.key_message", { required: true }),
            }}
          />
        </WrapInputWithLabel>

        <WrapInputWithLabel title="Hashtag">
          <Controller
            name="hashtag"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <CreatableSelectCustom
                  options={optionHashtag}
                  onChange={onChange}
                  value={value}
                  type="hashtag"
                  status="hashtag"
                  prefix="#"
                />
              );
            }}
          />
        </WrapInputWithLabel>

        {emphasisOrProhibitionList.map((item, index) => (
          <WrapInputWithLabel key={index} title={<>{item.label}</>} required>
            <TextFieldCustom
              variant="outlined"
              multiline
              minRows={5}
              placeholder="Please enter text..."
              fullWidth
              error={
                item.id === "emphasis"
                  ? !!errors?.brief?.emphasis
                  : !!errors?.brief?.prohibition
              }
              helperText={
                item.id === "emphasis"
                  ? errors?.brief?.emphasis?.message
                  : errors?.brief?.prohibition?.message
              }
              inputProps={{
                ...register(`brief.${item.id}` as ICampaignBriefFormKey, {
                  required: true,
                }),
              }}
            />

            <Box sx={classes.doOrEmphasisTextBox}>
              <Typography
                color={item.id === "emphasis" ? "success.main" : "error"}
              >
                {item.subLabel}
              </Typography>
              <ul>
                {item.textItems.map((data, index) => (
                  <li key={index}>{data.replace(/-/gi, "")}</li>
                ))}
              </ul>
              <Tooltip title={copy} placement="top">
                <IconButton
                  onClick={() => {
                    navigator.clipboard
                      .writeText(item.textItems.toString().replace(/,/gi, ""))
                      .then(() => setCopy("Copied"));
                  }}
                  onMouseOut={() => {
                    setCopy("Copy");
                  }}
                  sx={
                    item.id === "emphasis"
                      ? classes.copyIcon
                      : { ...classes.copyIcon, top: "127px" }
                  }
                >
                  <FileCopyOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          </WrapInputWithLabel>
        ))}
      </Stack>
    </CardContent>
  );
}
