import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
// icon and style
import CloseIcon from "@mui/icons-material/Close";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
import { useCreateEmployerStyle as classes } from "./CreateEmployer.style";
// react-hook and schema
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateEmployerFormSchema } from "./CreateEmployer.schema";
// components
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";
import SelectMultiCategory from "@components/campaign/create-campaign/campaign-brief/brief-default/SelectMultiCategory";
// types
import {
  ICreateEmployerFormSchema,
  ICreateEmployerDialogProps,
} from "../EmployerList.type";
import { ICreateEmployerForm } from "./CreateEmployer.type";
// data
import {
  initImage,
  optionType,
  initEmployerForm,
  optionBusinessSize,
} from "./EmployerData";
// others
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";

export default function CreateEmployerDialog(
  props: ICreateEmployerDialogProps
) {
  const {
    open,
    onClose,
    optionCategory,
    onCreateEmployer,
    imageOrgLogo,
    setImageName,
    handleUploadImage,
    isUploadImgLoading,
    setImageOrgLogo,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    watch,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<ICreateEmployerForm>({
    resolver: yupResolver(CreateEmployerFormSchema),
    defaultValues: initEmployerForm,
  });

  const handleChangeInput = (
    e: any,
    nameChange: keyof ICreateEmployerFormSchema
  ) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  const handleDeleteImage = () => {
    setImageOrgLogo(initImage);
  };

  return (
    <DialogScrollingCustom
      open={open}
      onClose={onClose}
      title="New Employer"
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onCreateEmployer)}>
        <DialogContent sx={classes.dialogContent}>
          <Box p="2 2 0 2">
            <Box display="flex" flexDirection="row">
              <Icon className="ico-hu-s-acc-business" sx={{ mr: "8px" }} />
              <Typography variant="title1" fontWeight="600">
                Employer Information
              </Typography>
            </Box>
            <Stack px={5} pt={4}>
              <Grid container pb={5}>
                <Grid item xs={12} md={4}>
                  <Typography variant="title2">
                    Organization Logo<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {imageOrgLogo.url === "" ? (
                    <Box
                      sx={classes.profileBox}
                      component="label"
                      onClick={() => setImageName("imageOrgLogo")}
                      onChange={handleUploadImage}
                    >
                      <Box sx={classes.uploadImgBox}>
                        {isUploadImgLoading ? (
                          <CircularProgress size={70} thickness={3} />
                        ) : (
                          <>
                            <Icon
                              className="ico-hu-p-photo"
                              sx={{ fontSize: "70px", color: "#616161" }}
                            />
                            <Box display="flex" alignItems="center">
                              <Icon
                                className="ico-hu-upload"
                                sx={{ fontSize: "10px" }}
                              />
                              <Typography variant="caption" ml={1}>
                                Images
                              </Typography>
                            </Box>
                          </>
                        )}
                        <input
                          hidden
                          name="idCardImageUrl"
                          accept="image/*"
                          multiple
                          type="file"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box position="relative" width="150px">
                      <Button
                        variant="contained"
                        onClick={handleDeleteImage}
                        sx={classes.deleteBtn}
                      >
                        <CloseIcon sx={{ fontSize: "20px" }} />
                      </Button>
                      <Box
                        sx={classes.profileBox}
                        component="label"
                        onClick={() => setImageName("imageOrgLogo")}
                        onChange={handleUploadImage}
                      >
                        <Box position="relative">
                          <img
                            src={imageOrgLogo.url}
                            alt="organization-logo"
                            style={classes.orgLogo}
                          />
                          {isUploadImgLoading && (
                            <Box sx={classes.circularBox}>
                              <CircularProgress size={70} thickness={3} />
                            </Box>
                          )}
                        </Box>
                        <input
                          hidden
                          name="idCardImageUrl"
                          accept="image/*"
                          multiple
                          type="file"
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Organization Name<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("name")}
                    onChange={(e) => handleChangeInput(e, "name")}
                    type="text"
                    placeholder="Organization Name"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Full Name<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("fullname")}
                    onChange={(e) => handleChangeInput(e, "fullname")}
                    type="text"
                    placeholder="Full Name"
                    fullWidth
                    error={!!errors.fullname}
                    helperText={errors.fullname?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Size<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <BasicSelectCustom
                    id="business-size-select"
                    value={getValues("size")}
                    onChange={(e) => handleChangeInput(e, "size")}
                    data={optionBusinessSize}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select"
                    fullWidth
                    error={!!errors.size}
                    helperText={errors.size?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Type<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <BasicSelectCustom
                    id="type-select"
                    value={getValues("type")}
                    onChange={(e) => handleChangeInput(e, "type")}
                    data={optionType}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select"
                    fullWidth
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="title2">
                      Categories<span className="require">*</span> :
                    </Typography>
                    <Typography variant="caption" color="#5C5C5C">
                      (เลือกได้สูงสุด 3 ประเภท)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <SelectMultiCategory
                    id="multi-select-category"
                    keyName={"category" as any}
                    value={watch("category")}
                    setValue={setValue as any}
                    placeholder="Select"
                    data={optionCategory}
                    fullWidth
                    valueKey="id"
                    labelKey="name_en"
                    error={!!errors.category}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Divider />

            <Box display="flex" flexDirection="row" pt={4}>
              <Icon
                className="ico-hu-people-top-card"
                sx={{ marginRight: "8px" }}
              />
              <Typography variant="body1" fontWeight="600">
                Contact
              </Typography>
            </Box>
            <Stack px={5} pt={4}>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Email<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("email")}
                    onChange={(e) => handleChangeInput(e, "email")}
                    type="text"
                    placeholder="Email"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className="ico-hu-email-solid"
                          />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Password<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("password")}
                    onChange={(e) => handleChangeInput(e, "password")}
                    type={!!showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!!showPassword ? (
                              <Icon className="ico-hu-visability-off-solid" />
                            ) : (
                              <Icon className="ico-hu-visability-on-solid" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Confirm Password<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("confirmPassword")}
                    onChange={(e) => handleChangeInput(e, "confirmPassword")}
                    type={!!showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {!!showConfirmPassword ? (
                              <Icon className="ico-hu-visability-off-solid" />
                            ) : (
                              <Icon className="ico-hu-visability-on-solid" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">
                    Phone<span className="require">*</span> :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={formatPhoneNumber(watch("phone")) || ""}
                    onChange={(e) => handleChangeInput(e, "phone")}
                    type="text"
                    placeholder="Phone"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                </Grid>
              </Grid>
              <Grid container pb={3}>
                <Grid item display="flex" alignItems="center" xs={12} md={4}>
                  <Typography variant="title2">Line ID :</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldCustom
                    value={getValues("line_id")}
                    onChange={(e) => handleChangeInput(e, "line_id")}
                    type="text"
                    placeholder="Line ID"
                    fullWidth
                    error={!!errors.line_id}
                    helperText={errors.line_id?.message}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions sx={{ display:"flex", justifyContent:"center" }}>
          <SaveButton
            variant="contained"
            type="submit"
            startIcon={<Icon className="ico-hu-save" />}
            sx={{ my: "5px" }}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
    </DialogScrollingCustom>
  );
}
