// api and type
import { JobAPI } from "@api/global";
import { IInfluencerListState } from "./type";
import { IInfluencerActiveListParams } from "@api/job/type";
// redux
import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState: IInfluencerListState = {
  influencerActiveInfo: null,
  loading: false,
  checkSelected: {
    employerId: "",
    campaignId: "",
    item: [],
  },
};

export const getInflunencerActiveListAsync = createAsyncThunk(
  "getInfluencerActiveList",
  async (req: {
    employerId: string;
    campaignId: string;
    params: IInfluencerActiveListParams;
  }) => {
    try {
      const response = await JobAPI.influencerListActive(req.employerId, req.campaignId, req.params);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const InfluencerActiveInfoSlice = createSlice({
  name: "InfluencerActiveInfoSlice",
  initialState,
  reducers: {
    setInfluencerActiveInfo: (state, { payload }) => {
      state.influencerActiveInfo = payload;
    },
    updateCheckSelected: (state, { payload }) => {
      state.checkSelected = payload;
    },
    setSelectInfluencerActive: (state, { payload }) => {
      state.influencerActiveInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Select Influencer Active
      .addCase(getInflunencerActiveListAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInflunencerActiveListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.influencerActiveInfo = action.payload;
      })
      .addCase(getInflunencerActiveListAsync.rejected, (state) => {
        state.loading = false;
        state.influencerActiveInfo = null;
      });
  },
});

export const { updateCheckSelected, setInfluencerActiveInfo, setSelectInfluencerActive } = InfluencerActiveInfoSlice.actions;

export const selectCheckSelected = (state: RootState) => state.influencerActiveInfo.checkSelected;

export default InfluencerActiveInfoSlice.reducer;
