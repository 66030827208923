import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Seven() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>7. Use, maintenance, protection of personal data.</Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>
        The Company may use the following automatic data collection
        technologies:
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        7.1 Save information about visiting the website or cookies or browser
        cookies
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        Cookies are small files stored on your computer's hard drive. You may
        refuse to accept browser cookies made by activating the appropriate
        settings on your browser. However, if you select a setting like this,
        you may not be able to access some of the HashU Platform because the
        HashU Platform creates Cookies coming up, so you have to adjust the
        browser's rejection of the Cookies that the HashU Platform created.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>7.2 Web Beacons</Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The Company’s emails may contain small electronic files known name in
        Web Beacons operators are sometimes known as gifs, pixel tags, and
        single-pixel gifs, which allows the Company to count users receiving the
        Company’s emails.
      </Typography>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          7.3 Choices on how to use the HashU Platform and disclose your
          information.
        </Typography>
        <Typography sx={{ marginTop: "20px" }}>
          The Company is committed to providing various options to you for the
          personal information you provide to the Company. The Company has
          established mechanisms for you to provide control over your
          information as follows:
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          ● Tracking technologies and advertising You can set your browser to
          reject all or some browser cookies or to alert you when there are
          transmission cookies. To study how to deal with the settings for Flash
          Cookies, please visit our website and locations for Flash Player on
          the HashU Platform of Adobe's HashU Platform. If you cancel work or
          reject cookies, please note that some parts of this website may not be
          accessible or not working properly.
        </Typography>
        <Typography>
          ● Disclosure of your information for third party advertising If you do
          not want the Company to share your personal information with third
          parties that are not affiliated company or non-representative for the
          promotion, you can withdraw your consent. You can also revoke your
          consent at any time by logging in to the HashU Platform and making
          adjustments in the user preferences section of your account, by
          selecting or unchecking the relevant settings box, or by sending an
          email to Info@hashu.co with your wishes.
        </Typography>
        <Typography>
          ● Offer promotion If you do not wish your email address/contact
          information to be used for promotional purposes or our services or of
          a third party, you can choose to withdraw your consent or log in to
          the HashU Platform to modify the user preferences of your account by
          selecting or canceling related settings box or by sending an email to
          Info@hashu.co with your wishes. If you receive promotional emails
          from the Company, you may send a reply email to request that you
          cancel sending other emails in the future. Such cancellation does not
          apply to previously provided information due to purchase, warranty
          registration, services or products, or other transactions.
        </Typography>
        <Typography>
          ● Targeting advertising Where you have given the Company your consent
          to collect, use, and disclose to deliver ads based on the audience's
          preferences, which is the advertiser's target audience. (advertisers'
          target-follower preferences), you can choose to revoke your consent.
          You can adjust user preferences for advertisements in your account by
          selecting or deselecting the corresponding settings or by sending an
          email to Info@hashu.co with your wishes. The Company has no
          collection control measures or use of your information by third
          parties to perform interest-based advertising. However, these third
          parties may do so that you can choose not to have your information
          collected or being used in this way.
        </Typography>
      </Box>
    </Box>
  );
}
