import axios from "axios";
import store from "@store/store";
import { resetStore } from "@store/store";
import { healthCheck } from "./../../index";
import { AuthenticationAPI } from "@api/global";
import { BASE_URL } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

export const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use((config) => {
  return config;
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: unknown, token: string | null) => {
  failedQueue.forEach((queue) => {
    if (error) {
      queue.reject(error);
    } else {
      queue.resolve(token);
    }
  });
  failedQueue = [];
};

const interceptor = instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status, data },
    } = error;

    const originalRequest = config;
    if (
      status === 400 &&
      data.error.trace_id === "check-expires" &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.header["Authorization"] = "Bearer " + token;
            return axios.request(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const { refresh_token } = getTokenFromStorage();
      return new Promise(async (resolve, reject) => {
        try {
          const result = await AuthenticationAPI.refreshToken({
            refresh_token: refresh_token,
          });
          isRefreshing = false;

          localStorage.setItem(
            "token",
            JSON.stringify({
              access_token: result.access_token,
              refresh_token: result.refresh_token,
              session_token: JSON.parse(result.access_token),
            })
          );
          originalRequest.headers["Authorization"] =
            "Bearer " + result.access_token;
          resolve(axios(originalRequest));
        } catch (err) {
          processQueue(err, null);
          reject(err);
        }
      });
    } else if (
      (status === 400 && data.error.message === "error, token format is invalid") ||
      (status === 400 && data.error.message === "error, token had expired") ||
      (status === 400 && data.error.message === "error, user not found") ||
      (status === 401 && data.error.message === "error, token format is invalid") ||
      (status === 401 && data.error.message === "error, token had expired") ||
      (status === 401 && data.error.message === "error, user not found")
    ) {
      clearInterval(healthCheck);
      SwalCustom.fire({
        icon: "error",
        title: "หมดเวลาเข้าใช้งานเว็บไซต์",
        text: "กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
        confirmButtonColor: "#0277bd",
        confirmButtonText: "ตกลง",
        customClass: {
          confirmButton: "prompt-font",
        },
      }).then(() => {
        store.dispatch(resetStore());
        localStorage.clear();
        setTimeout(() => {
          window.location.assign("/");
        }, 500);
      });
    }
    axios.interceptors.response.eject(interceptor);
    return Promise.reject(error);
  }
);
