const card = {
  display: "flex",
  height: "160px",
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: "0px 4px 10px 0px #0000000D",
};

const packagePlan = {
  p: "4px 16px 4px 16px",
  bgcolor: "#E0E0E0",
  borderRadius: "50px",
  ml: "12px",
  "& > p": {
    color: "#505050",
  },
};

export const useEmployerDetailStyle = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    mt: "20px",
    columnGap: "25px",
    rowGap: "20px",
  },
  leftCard: {
    ...card,
    width: {
      xs: "100%",
      lg: "65%",
    },
  },
  rightCard: {
    ...card,
    flexDirection: "column",
    width: {
      xs: "100%",
      lg: "35%",
    },
  },
  avatar: {
    borderRadius: "8px",
    width: "120px",
    height: "120px",
    objectFit: "cover",
    m: "20px",
  },
  leftCardBoxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    m: "25px 20px 25px 0",
  },
  category: {
    display: "flex",
    alignItems: "center",
    mt: "10px",
    "& > svg": {
      fontSize: "4px",
      alignItems: "center",
      mx: "8px",
      color: "#E0E0E0",
    },
    "& > svg:last-of-type": {
      display: "none",
    },
  },
  free: packagePlan,
  basic: {
    ...packagePlan,
    bgcolor: "#E5F6FF",
    "& > p": {
      color: "#0077E4",
    },
  },
  professional: {
    ...packagePlan,
    bgcolor: "#FFD4ED",
    "& > p": {
      color: "#ED1968",
    },
  },
  enterprise: {
    ...packagePlan,
    bgcolor: "#EBDFFF",
    "& > p": {
      color: "#893DBD",
    },
  },
  transaction: {
    width: "120px",
    height: "50%",
    border: "1px solid #8C499C",
    borderRadius: "4px",
    color: "#8C499C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "5px",
    cursor:"pointer"
  },
};

export const useEmployerInformationStyle = {
  boxInformation: { border: "2px solid #E8DFFF", borderRadius: "12px" },
  boxHeader: {
    display: "flex",
    justifyContent: ["center", "space-between"],
    alignItems: "center",
    m: "20px",
  },
  button: { width: "100px", justifyContent: "space-evenly" },
  divider: { border: "1px solid #E8DFFF" },
  avatar: {
    borderRadius: "8px",
    width: "120px",
    height: "120px",
    objectFit: "cover",
  },
};

export const useCampaignTabStyle = {
  cmapaignNoData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    marginTop: "32px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  textNoCampaign: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
  container: {
    bgcolor: "#fff",
    borderRadius: "0px 20px 20px 20px;",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    p: "24px",
  },
  campTabTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  search: {
    width: "480px",
    display: "flex",
    border: "1px solid #8C499C",
    height: "40px",
    boxShadow: "none",
  },
  filter: {
    borderColor: "#E0E0E0",
    width: "103px",
    border: "1px solid #8C499C",
    ml: "15px",
    fontSize: "16px",
    "&:hover": {
      border: "1px solid #8C499C",
    },
  },
  addCampButtom: {
    "& button": {
      border: "none",
      maxHeight: "40px",
      "&:hover": {
        border: "none",
      },
    },
  },
  campLists: {
    display: "flex",
    justifyContent: "center",
  },
  pagination: {
    "& .MuiGrid-root": {
      display: "flex",
      justifyContent: "center",
    },
  },
};

export const useCampaignFilterStyle = {
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: "25px 0 0 25px",
    },
  },
  drawerCon: {
    borderRadius: "8px",
    width: ["100px", "390px"],
    px: "18px",
  },
  filterTitle: {
    padding: "12px",
    position: "fixed",
    bgcolor: "#fff",
    width: "400px",
    borderRadius: "25px 0 0 0",
    borderBottom: "2px solid #E0E0E0",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    right: 0,
  },
  titleItem: {
    padding: "12px",
    "& > p": {
      fontWeight: 600,
      mb: "12px",
    },
  },
  line: {
    margin: "5px 13px",
    borderColor: "1px solid #E0E0E0",
  },
  chip: {
    minWidth: "100px",
    margin: "0 12px 12px 0",
    border: "1px solid #5C5C5C",
    "&:hover": {
      border: "1px solid #fff",
      color: "#893DBD",
      bgcolor: "#F4ECFF",
    },
    bgcolor: "#fff",
  },
  filterBottom: {
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    bottom: 0,
    bgcolor: "#fff",
    borderTop: "2px solid #E0E0E0",
    width: "400px",
    borderRadius: "0 0 0 26px",
    "& > p": {
      color: "#893DBD",
      cursor: "pointer",
    },
    right: 0,
  },
  buttonApply: {
    width: "118px",
    height: "32px",
    borderRadius: "50px",
    boxShadow: "0px 4px 10px",
    backgroundColor: "#893DBD",
    color: "white",
    "&:hover": {
      color: "#893DBD",
      backgroundColor: "#F4ECFF",
    },
  },
};

const dialog = {
  "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
    minWidth: ["90%", "90%", "90%", "670px"],
    margin: [1, 2, 3],
  },
}

const dialogContent = {
  marginTop: "10px",
  paddingTop: 3,
  paddingBottom: 0,
}

export const useEditShippingStyle = {
  dialog: dialog,
  dialogContent: dialogContent,
};

export const useEditInformationStyle = {
  dialog: dialog,
  dialogContent: dialogContent,
  profileBox: {
    borderRadius: "4px",
    width: ["150px", "170px"],
    height: ["150px", "170px"],
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
    "& img": {
      width: ["150px", "170px"],
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  uploadImgBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #9E9E9E",
    width: "150px",
    height: "150px",
    borderRadius: "8px",
  },
  orgLogo: {
    borderRadius: "4px",
    width: "150px",
    height: "150px",
    objectFit: "cover" as "cover",
  },
  circularBox: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "150px",
  },
  iconInput: {
    color: "grey.500",
    fontSize: "16px",
    paddingRight: "2px",
  },
  dialogAction: {
    justifyContent: "center",
    marginTop: 3,
    marginBottom: 3,
  },
};

export const useEditBillingStyle = {
  dialog: dialog,
  dialogContent: dialogContent,
  dialogAction: {
    justifyContent: "center",
    marginTop: 2,
    paddingBottom: 5,
  },
};

export const useProfileTabStyle = {
  tabPanelBox: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "common.white",
    py: 4,
    px: 6,
    borderRadius: "0px 20px 20px 20px;",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  },
}