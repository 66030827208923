import { useEffect, useState } from "react";
// mui and icon
import { Badge, Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// theme and style
import { hGrey } from "@theme/index";
import { categoryDialogStyle as classes } from "./Category.style";
// component
import ItemsCategory from "./ItemsCategory";

export default function Category(props: any) {
  const { item, categorySelected, setCategorySelected } = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onHandleAdd = () => {
    if (categorySelected?.length < 3) {
      setCategorySelected((categorySelected: any) => [
        ...categorySelected,
        item,
      ]);
    }
  };

  const onHandleDelete = () => {
    const categoryFilter = categorySelected?.filter((categorySelected: any) => {
      return categorySelected.id !== item.id;
    });
    setCategorySelected(categoryFilter);
  };

  useEffect(() => {
    const getValue = categorySelected?.find(
      (categorySelected: any) => categorySelected.id === item.id
    );
    setIsSelected(Boolean(getValue));
  }, [categorySelected, item.id]);

  return (
    <>
      {isSelected === true ? (
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <Box sx={classes.iconBadge}>
              <CheckCircleIcon sx={{ color: "#8C499C" }} />
            </Box>
          }
          sx={classes.categoryBadge}
        >
          <Box
            width={["140px", "162px"]}
            sx={classes.boxCategorySelected}
            onClick={onHandleDelete}
          >
            <ItemsCategory itemsCategory={item.value} />
            <Typography fontSize="14px" fontWeight="500">
              {item.name_en}
            </Typography>
          </Box>
        </Badge>
      ) : (
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <Box sx={classes.iconBadge}>
              <CheckCircleIcon sx={{ color: "#9E9E9E" }} />
            </Box>
          }
          sx={classes.categoryBadge}
        >
          <Box
            width={["140px", "162px"]}
            sx={classes.itemsCategory}
            onClick={onHandleAdd}
          >
            <ItemsCategory itemsCategory={item.value} />
            <Typography fontSize="14px" color={`${hGrey[900]}`}>
              {item.name_en}
            </Typography>
          </Box>
        </Badge>
      )}
    </>
  );
}
