import { useEffect, useState } from "react";
// mui, style and icon
import { Box, Grid, Typography } from "@mui/material";
import { useEmployerReviewPostStyle as classes } from "./ReviewPost.style";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
// api and type
import { JobAPI } from "@api/global";
import { IIsApproved } from "@api/app-information/type";
import { IInitEmployerReviewPostForm } from "./ReviewPost";
// components
import PostVideo from "@components/employer-job/employer-review-post/post-video/PostVideo";
import PostTextOnly from "@components/employer-job/employer-review-post/post-text-only/PostTextOnly";
import PostPhotoAlbum from "@components/employer-job/employer-review-post/post-photo-album/PostPhotoAlbum";
import PostSinglePhoto from "@components/employer-job/employer-review-post/post-single-photo/PostSinglePhoto";
import InfluencerSocialPost from "@components/employer-job/employer-review-post/post-default/InfluencerSocialPost";
// redux
import {
  setEmployerJobInfo,
  selectEmployerJobInfo,
  getEmployerJobInfoAsync,
  selectEmployerJobInfoOnload,
} from "@slices/EmployerJobInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import { useForm } from "react-hook-form";
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

const initEmployerReviewPostForm: IInitEmployerReviewPostForm = {
  job_id: "",
  employer_id: "",
  description: {
    description: "",
    is_approve: "",
    note: "",
  },
  attachments: [],
  comment: "",
  external_file: [],
};

export default function EmployerReviewPost() {
  const [loading, setLoading] = useState(false);

  const form = useForm<IInitEmployerReviewPostForm>({
    defaultValues: initEmployerReviewPostForm,
  });

  const { setValue } = form;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search)
  const qCampaignId = params.get("c_id") || ""
  const { employerId, jobId } = useParams<{ employerId: string, jobId: string }>();

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectEmployerJobInfo);
  const isLoading = useAppSelector(selectEmployerJobInfoOnload) || loading;
  const isJobRejected = jobInfo?.status === "rejected";
  const contentType = jobInfo?.content_type.title || "";

  const goBack = () => {
    if (isJobRejected) {
      SwalCustom.fire({
        title: "ยืนยันที่จะออกจากหน้านี้",
        text: `ข้อมูลการ Review Post (${jobInfo?.social_account?.influencer_name}) จะหายไป`,
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonColor: "#E30D18",
        confirmButtonColor: "#4caf50",
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.goBack();
        }
      });
    } else {
      history.goBack()
    }
  };

  useEffect(() => {
    const params = {
      employer_id: employerId,
      job_id: jobId,
    };
    dispatch(getEmployerJobInfoAsync(params));
  }, [dispatch, employerId, jobId]);

  useEffect(() => {
    if (jobInfo) {
      // set ค่าเริ่มต้นของ job เพื่อทำการตรวจงาน
      const description = jobInfo.description;
      const attachmentList = jobInfo.attachments;

      // cotent type 'single photo' คือเป็นการ approved แค่รูปเดียวเท่านั้น
      if (/(single photo)/.test(contentType)) {
        const isAttAprove = attachmentList.filter((att) => att.is_approve === "approved").length >= 1;
        const attachment = attachmentList.map((att) => {
          return {
            ...att,
            is_approve: isAttAprove ? "approved" : ("new" as IIsApproved),
            note: "",
          };
        });
        setValue("attachments", attachment);
      } else {
        // check influencer ได้มีการ upload attachment ใหม่หรือเปล่า
        const isNewAttachment = attachmentList.filter((att) => att.is_approve === "new").length;

        // ถ้ามี เปลี่ยน is_approve เป็น 'new' เพื่อทำการตรวจใหม่ทั้งหมดอีกครั้ง
        // 1. attachment คือไฟล์ที่ user upload ขึ้นมาจากเครื่อง
        // 2. url || external_file เป็นลิงค์ที่ user แนบจากด้านนอก

        const attachment = attachmentList.map((att) => {
          return {
            ...att,
            is_approve: isNewAttachment ? "new" : att.is_approve,
            note: "",
          };
        });
        const urls = jobInfo.external_file?.map((url) => {
          return {
            ...url,
            is_approve: isNewAttachment ? "new" : url.is_approve,
            note: "",
          };
        });
        setValue("attachments", attachment);
        setValue("external_file", urls || []);
      }

      // status 'waiting_for_approve' หรือ !== 'approved' เป็นการเช็ค job ที่ยังไม่ได้รับการ approved
      // หรือยังอยู่ในขั้นตอน ส่งงาน, แก้ไขงาน 1, 2
      // ถ้าอยู่ในขั้นตอนนี้ set เป็น 'new' เพื่อทำการตรวจใหม่

      setValue("description", {
        ...description,
        is_approve:
          jobInfo.status === "waiting_for_approve" &&
          description.is_approve !== "approved"
            ? "new"
            : description.is_approve,
        note: jobInfo.status === "waiting_for_approve" ? "" : description.note,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobInfo]);

  const onRejectJob = (value: IInitEmployerReviewPostForm) => {
    setLoading(true);

    const desc = value.description;
    const data = {
      ...value,
      job_id: jobId,
      employer_id: employerId,
      description: {
        ...desc,
        // เช็คถ้า description หรือ caption ถ้า status 'approved' แล้ว ลบ note || comment ก่อนหน้านี้ออก
        note: desc.is_approve === "approved" ? "" : desc.note,
      },
    };

    JobAPI.addEmployerJobRecord(data)
      .then((response) => {
        setLoading(false);
        SwalCustom.fire({
          icon: "success",
          title: "Success !",
          text: `คุณได้ทำการส่ง Feedback ให้กับ ${jobInfo?.social_account?.name} เรียบร้อยแล้ว`,
          confirmButtonColor: "#4CAF50",
          confirmButtonText: `กลับไปหน้า "รอตรวจงาน" `,
          showCancelButton: true,
          cancelButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(
              `/campaigns/${qCampaignId}/info?tab=job&emp_id=${employerId}`
            );
          } else {
            dispatch(setEmployerJobInfo(response));
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  const onCancelJob = () => {
    setLoading(true);
    const data = {
      status: "canceled",
    };

    JobAPI.updateJobStatus(jobId, data)
      .then((response) => {
        setLoading(false);
        SwalCustom.fire({
          icon: "success",
          title: "Success !",
        }).then(() => {
          dispatch(setEmployerJobInfo(response));
        });
      })
      .catch((error) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const onApproveJob = (value: IInitEmployerReviewPostForm) => {
    setLoading(true);

    const data = {
      ...value,
      job_id: jobId,
      employer_id: employerId,
      attachments: value.attachments.filter(
        (att) => att.is_approve === "approved"
      ),
      job_status: "approved",
    };

    JobAPI.addEmployerJobRecord(data)
      .then(() => {
        history.push(
          `/employers/${employerId}/jobs/${jobId}/draft-approval?c_id=${qCampaignId}`
        );
      })
      .catch((error) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      })
      .finally(() => {
        setLoading(false);
      })
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Box p={2}>
        <Box sx={classes.goBack}>
          <span onClick={goBack}>
            <ChevronLeftOutlinedIcon />
            <span>Back</span>
          </span>
        </Box>
        <Box mb="24px">
          <Typography variant="h5">
            {isJobRejected
              ? "Employer's Feedback for Influencer"
              : "Reviewing Influencer's Draft Post"}
          </Typography>
          <Typography variant="subTitle1">
            {isJobRejected
              ? "ความคิดเห็นของผู้ว่าจ้างถึงอินฟลูเอนเซอร์"
              : "ตรวจ Draft ของอินฟลูเอนเซอร์"}
          </Typography>
        </Box>
        <Box sx={classes.reviewPostCon}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              {jobInfo?.content_type.type === "video" ? (
                <PostVideo
                  form={form}
                  jobInfo={jobInfo}
                  onCancelJob={onCancelJob}
                  onRejectJob={onRejectJob}
                  onApproveJob={onApproveJob}
                />
              ) : /(single photo)/.test(contentType) ? (
                <PostSinglePhoto
                  form={form}
                  jobInfo={jobInfo}
                  onApproveJob={onApproveJob}
                  onCancelJob={onCancelJob}
                  onRejectJob={onRejectJob}
                />
              ) : /(photo album)/.test(contentType) ? (
                <PostPhotoAlbum
                  form={form}
                  jobInfo={jobInfo}
                  onApproveJob={onApproveJob}
                  onCancelJob={onCancelJob}
                  onRejectJob={onRejectJob}
                />
              ) : /(text only)/.test(contentType) ? (
                <PostTextOnly
                  form={form}
                  jobInfo={jobInfo}
                  onApproveJob={onApproveJob}
                  onCancelJob={onCancelJob}
                  onRejectJob={onRejectJob}
                />
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <InfluencerSocialPost jobInfo={jobInfo} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
