import { useMyJobsDetailStyle } from "@views/my-jobs/jobs-detail/JobsDetail.style";

export const useListingStyle = {
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },
  tab: useMyJobsDetailStyle.tab,
  tabBox: useMyJobsDetailStyle.tabBox,
  tabSelected: useMyJobsDetailStyle.tabSelected,
  // Influencer List
  card: {
    padding: "24px",
    borderRadius: "12px",
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
  },
  header: {
    display: "flex",
    justifyContent: { xs: "start", md: "space-between" },
    columnGap: "10px",
    flexWrap: "wrap",
    mt: "14px",
  },
  filterBtn: {
    minHeight: "40px",
    borderWidth: "1px",
    "&:hover": { borderWidth: "1px" },
  },
  boxImage: {
    widht: "100%",
    height: "320px",
    borderRadius: "8px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "& > img": {
      objectFit: "cover",
      width: "100%",
      height: "320px",
      borderRadius: "16px",
      cursor: "pointer",
    },
  },
  boxDetail: {
    position: "absolute",
    bottom: "0px",
    width: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(60,60,60,0), rgba(60,60,60,1))",
    borderRadius: "0 0 16px 16px",
    color: "#fff",
  },
  boxPlatform: {
    display: "flex",
    padding: "0 12px 8px 12px",
  },
  statusActive: {
    color: "green",
    fontWeight: "bold",
  },
  statusBan: {
    color: "orange",
    fontWeight: "bold",
  },
  statusBan3: {
    color: "red",
    fontWeight: "bold",
  },
  statusDelete: {
    color: "black",
    fontWeight: "bold",
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "320px",
    borderRadius: "12px",
    cursor: "pointer",
  },
  pagination: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
  boxTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  search: {
    minWidth: "480px",
    display: "flex",
    border: "1px solid #8C499C",
    height: "40px",
    boxShadow: "none",
  },
  exportButton: {
    width: "140px",
    backgroundColor: "#8C499C",
    color: "#fff",
    ":hover": {
      border: "2px solid #8C499C",
      color: "#8C499C",
    },
  },
};

export const useListingTabFilterStyle = {
  dialogCon: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "12px",
    },
  },
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: [0, "25px 0 0 25px"],
    },
    overflowY: "initial",
  },
  drawerCon: {
    borderRadius: "8px",
    width: ["91.5%", "390px"],
    px: "18px",
  },
  filterTitle: {
    padding: "12px",
    position: "sticky",
    bgcolor: "#fff",
    borderRadius: "25px 0 0 0",
    borderBottom: "2px solid #E0E0E0",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    left: 0,
    top: 0,
    mx: "-18px",
    "& > .MuiSvgIcon-root": {
      cursor: "pointer",
      mr: "5px",
    },
  },
  filterBottom: {
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: {
      xs: "95%",
      sm: "410px",
    },
    bottom: 0,
    bgcolor: "#fff",
    borderTop: "2px solid #E0E0E0",
    borderRadius: {
      xs: 0,
      sm: "0 0 0 26px",
    },
    "& > p": {
      color: "#893DBD",
      cursor: "pointer",
    },
    right: 0,
  },
  applyBtn: {
    width: "118px",
    height: "32px",
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
  textField: {
    width: "100%",
    overflow: "visible",
    maxHeight: "40px",
    "& input": { height: "40px" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8C499C",
      },
      "&:hover fieldset": {
        borderColor: "#8C499C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8C499C",
      },
    },
  },
  searchLocation: {
    width: "100%",
    display: "flex",
    border: "1px solid #8C499C",
    height: "40px",
    boxShadow: "none",
    borderRadius:"50px !important"
  },
};
