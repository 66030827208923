import { useState } from "react";
// mui and style
import { Box, Grid } from "@mui/material";
import { useProfileTabStyle as classes } from "../Detail.style";
// component
import Billing from "./Billing";
import Shipping from "./Shipping";
import Information from "./Information";
import EditBilling from "../../edit-employer/EditBilling";
import EditShipping from "../../edit-employer/EditShipping";
import EditEmployer from "../../edit-employer/EditEmployer";
// type
import { IEmployerInfoRes } from "@api/employer/type";

export default function ProfileTab({
  employerInfo,
  setEmployerInfo,
}: {
  employerInfo: IEmployerInfoRes | null;
  setEmployerInfo: (data: IEmployerInfoRes) => void;
}) {
  const [isEditEmployer, setIsEditEmployer] = useState(false);
  const [isEditShipping, setIsEditShipping] = useState(false);
  const [isEditBilling, setIsEditBilling] = useState(false);

  const handleCloseEditEmployer = () => {
    setIsEditEmployer(false);
  };

  const handleCloseShipping = () => {
    setIsEditShipping(false);
  };

  const handleCloseBilling = () => {
    setIsEditBilling(false);
  };

  return (
    <>
      <Box
        borderRadius={["20px", "0px 20px 20px 20px"]}
        sx={classes.tabPanelBox}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={5}>
            <Information
              employerInfo={employerInfo || null}
              setOpen={setIsEditEmployer}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Shipping
              employerInfo={employerInfo || null}
              setOpen={setIsEditShipping}
            />
            <Billing
              employerInfo={employerInfo || null}
              setOpen={setIsEditBilling}
            />
          </Grid>
        </Grid>
      </Box>

      <EditEmployer
        open={isEditEmployer}
        employerInfo={employerInfo}
        setEmployerInfo={setEmployerInfo}
        onClose={handleCloseEditEmployer}
      />

      <EditShipping
        open={isEditShipping}
        employerInfo={employerInfo}
        setEmployerInfo={setEmployerInfo}
        onClose={handleCloseShipping}
      />

      <EditBilling
        employerInfo={employerInfo}
        setEmployerInfo={setEmployerInfo}
        open={isEditBilling}
        onClose={handleCloseBilling}
      />
    </>
  );
}
