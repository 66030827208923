// mui
import {
  Box,
  Card,
  Grid,
  Icon,
  Stack,
  Avatar,
  CardMedia,
  Typography,
  CardContent,
} from "@mui/material";
// icons
import CircleIcon from "@mui/icons-material/Circle";
// style and others
import { useCampaignCardStyle as classes } from "./CampaignCard.style";
import { SocialIconSelected } from "../icon-selected/SocialIconSelected";
// dayJs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.locale("en");

export default function CampaignCard(props: any) {
  const {
    name,
    limit,
    status,
    update,
    profile,
    eachInf,
    platform,
    jobCount,
    category,
    coverUrl,
    avgBudget,
    companyUrl,
    isCampaign,
    contentType,
    profileName,
  } = props;

  const numberFormat = Intl.NumberFormat("en");

  const contentTypeMatchIcon = (type?: string, platform?: string) => {
    const contentType = type || "";
    const color =
      platform === "facebook"
        ? "#1A77F1"
        : platform === "tiktok" || platform === "twitter"
        ? "#000"
        : platform === "youtube"
        ? "#FF0000"
        : "#893DBD";
    if (/(text only)/.test(contentType)) {
      return (
        <Icon
          fontSize="small"
          className="ico-hu-p-text"
          sx={{ color: color }}
        />
      );
    } else if (/(single photo)/.test(contentType)) {
      return (
        <Icon
          fontSize="small"
          className="ico-hu-p-photo"
          sx={{ color: color }}
        />
      );
    } else if (/(photo album)/.test(contentType)) {
      return (
        <Icon
          fontSize="small"
          className="ico-hu-p-album-photo"
          sx={{ color: color }}
        />
      );
    } else if (/(video)/.test(contentType)) {
      return <Icon className="ico-hu-p-vdo" sx={{ color: color }} />;
    }
  };

  const cardDetailBottom = [
    {
      label: (contentType || "")
        .replace("photo", "")
        .replace("only", "")
        .replace("video", ""),
      icon: (
        <Stack spacing={1} direction="row" alignItems="center" mb={0}>
          <SocialIconSelected platform={platform} />
          {contentTypeMatchIcon(contentType, platform)}
        </Stack>
      ),
    },
    {
      label: `${numberFormat.format(
        Math.round(avgBudget ? avgBudget : eachInf)
      )} THB`,
      icon: <Icon className="ico-hu-money-bag" sx={classes.icon} />,
    },
    {
      label: !isCampaign ? profileName : `${jobCount || 0}/${limit}`,
      icon: !isCampaign ? (
        <Avatar src={profile} sx={classes.icon}>{profileName.charAt(0)}</Avatar>
      ) : (
        <Icon className="ico-hu-person-circle" sx={classes.icon} />
      ),
    },
  ];

  const statusMatchBgcolor = (status?: string) => {
    if (isCampaign) {
      const bgcolor =
        status === "canceled"
          ? "#9E9E9E"
          : status === "completed"
          ? "#4CAF50"
          : status === "reported"
          ? "#0077E4"
          : status === "published"
          ? "#893DBD"
          : status === "influencer_listing"
          ? "#E8008A"
          : status === "public"
          ? "#E5F6FF"
          : status === "private"
          ? "#FFEAF4"
          : "#FB8C00";
      return bgcolor;
    } else {
      const bgcolor =
        status === "waiting_for_approve"
          ? "#FFFBD4"
          : status === "rejected"
          ? "#FFEAF4"
          : status === "approved"
          ? "#E7FFE8"
          : status === "published"
          ? "#E5F6FF"
          : status === "completed"
          ? "#EAEAFF"
          : status === "canceled"
          ? "#9E9E9E"
          : status === "briefing"
          ? "#FB8C00"
          : "#F4ECFF";
      return bgcolor;
    }
  };

  const statusMatchTxt = (status?: string) => {
    if (isCampaign) {
      const txt = (
        <Typography
          variant="title2"
          color={
            status === "public"
              ? "#0077E4"
              : status === "private"
              ? "#E8008A"
              : "#fff"
          }
        >
          {status === "canceled"
            ? "ยกเลิก"
            : status === "completed"
            ? "เสร็จสิ้น"
            : status === "reported"
            ? "รายงาน"
            : status === "published"
            ? "กำลังดำเนินการ"
            : status === "influencer_listing"
            ? "รับสมัคร"
            : status === "public"
            ? "ประกาศรับสมัคร"
            : status === "private"
            ? "ได้รับข้อเสนอ"
            : "ร่างแคมเปญ"}
        </Typography>
      );
      return txt;
    } else {
      const txt =
        status === "working" ? (
          <Typography color="#893DBD">เริ่มงานได้</Typography>
        ) : status === "first_draft" ? (
          <Typography color="#893DBD">ส่ง Draft แรก</Typography>
        ) : status === "listing" ? (
          <Typography color="#893DBD">รอตอบรับ</Typography>
        ) : status === "waiting_for_approve" ? (
          <Typography color="#FB8C00">แก้ไขแล้ว รอตรวจ</Typography>
        ) : status === "rejected" ? (
          <Typography color="#E8008A">ต้องแก้ไข</Typography>
        ) : status === "approved" ? (
          <Typography color="#4CAF50">Draft ผ่านแล้ว</Typography>
        ) : status === "published" ? (
          <Typography color="#0077E4">แนบลิงค์ส่งงาน</Typography>
        ) : status === "completed" ? (
          <Typography color="#4A489D">เสร็จสิ้น</Typography>
        ) : status === "canceled" ? (
          <Typography color="#fff">ยกเลิก</Typography>
        ) : status === "briefing" ? (
          <Typography color="#fff">ร่างแคมเปญ</Typography>
        ) : (
          "-"
        );
      return txt;
    }
  };

  return (
    <Card sx={classes.card}>
      <CardMedia
        component="img"
        height="210px"
        image={coverUrl}
        alt="green iguana"
        sx={{ objectFit: "cover" }}
      />
      <Box sx={classes.cardTop}>
        <Avatar src={companyUrl} sx={classes.companyLogo} />
      </Box>
      <Box
        sx={{
          ...classes.jobStatus,
          bgcolor: statusMatchBgcolor(status),
        }}
      >
        {statusMatchTxt(status)}
        <Typography variant="caption" sx={classes.updateTime} mt={1}>
          {dayjs(update).format("DD/MM/YYYY - HH:mm น.")}
        </Typography>
      </Box>
      <CardContent>
        <Typography variant="h6" sx={classes.jobName}>
          {name}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={classes.category}
          divider={<CircleIcon sx={classes.cateDivider} />}
        >
          {category?.map((cate: any, index: number) => (
            <Typography variant="caption" sx={classes.cateItems} key={index}>
              {cate.name_en.split("&", 1)}
            </Typography>
          ))}
        </Stack>
        <Grid container sx={classes.content}>
          {cardDetailBottom.map((item, index) => (
            <Grid item key={index} xs={4}>
              <Stack spacing={1} sx={classes.cardDetailBottom}>
                <Box display="flex" alignItems="center" height="30px">
                  {item.icon}
                </Box>
                <Typography variant="body2">{item.label}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
