// mui and style
import { Box, Divider, Typography } from "@mui/material";
import { useAdditionalCommentStyle as classes } from "./JobDefault.style";
// type
import Linkify from "react-linkify";
import nl2br from "react-nl2br";

export default function AdditionalComment(props: { comment?: string }) {
  const { comment } = props;
  return (
    <Box sx={classes.datePostBox}>
      <Box sx={classes.datePostTitle}>
        <Typography>Additional Comment</Typography>
        <Typography>ความคิดเห็นเพิ่มเติมถึงอินฟลูเอนเซอร์</Typography>
      </Box>
      <Divider sx={classes.divider} />
      <Box sx={classes.comment}>
        <Linkify>
          <Typography>{nl2br(comment)}</Typography>
        </Linkify>
      </Box>
    </Box>
  );
}
