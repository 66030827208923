import { useState } from "react";
// mui
import {
  Icon,
  Stack,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignInFormSchema } from "./AuthenticationForm.schema";
// component
import DialogAuthentication from "../global/dialog-authentication";
import TextFieldCustom from "../global/text-field";
// api and type
import { AuthenticationAPI, NotificationAPI } from "@api/global";
import { ISignInForm } from "./AuthenticationForm";
import { ISignInDialogProps, ISignInFormSchema } from "./AuthenDialog.types";
// theme and style
import { hAlertColor } from "@theme/index";
import { useSignInDialogStyle as classes } from "./authentication.style";
// redux
import {
  setEmployerNotification,
  setInfluencerNotification,
} from "@slices/Notification.slice";
import { useAppDispatch } from "@store/hook";
// util
import { decodeJWT } from "@utils/helpers/decodeJWT";
import { FIREBASE_VAPID_KEY } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// other
import CryptoJS from "crypto-js";
import Loading from "@global/loading/Loading";
import { useAuth } from "../../contexts/Auth";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
// firebase
import firebaseApp from "../../firebase";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

const initSignInForm: ISignInForm = {
  email: "",
  password: "",
};

export default function SignInDialog({
  open,
  setOpenSignIn,
  openSelectAccountType,
  openForgetPassword,
}: ISignInDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const linkToken = params.get("linkToken");
  const isLineOA = params.get("action") === "link-account";

  const { signIn } = useAuth();

  const {
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInForm>({
    resolver: yupResolver(SignInFormSchema),
    defaultValues: initSignInForm,
  });

  const dispatch = useAppDispatch();

  // firebase clound messeging
  const signgUpAnonymously = () => signInAnonymously(getAuth(firebaseApp));
  const messaging = getMessaging(firebaseApp);

  const handleCloseDialog = () => {
    setOpenSignIn(false);
  };

  const handleChangeInput = (e: any, name: keyof ISignInFormSchema) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onSignIn = (data: ISignInForm) => {
    setIsLoading(true);

    AuthenticationAPI.signIn(data)
      .then((response) => {
        setIsLoading(false);
        const { access_token, refresh_token, session_token } = response;
        const decoded = decodeJWT(session_token);
        const token = JSON.stringify({
          access_token,
          refresh_token,
          session_token: decoded ?? null,
        });

        signIn(token, async () => {
          const { session_token, access_token } = getTokenFromStorage();
          if (access_token === "") {
            const resendEmail = session_token?.email;
            history.push(`/verify-email/${resendEmail}`);
          } else {
            if (session_token) {
              if (isLineOA) {
                NotificationAPI.lineOA({
                  link_token: linkToken || "",
                  access_token: access_token,
                })
                  .then((url) => {
                    window.open(url);
                  })
                  .catch(() => {
                    SwalCustom.fire({
                      icon: "error",
                      title: "อุ๊ปป !!",
                      text: "มีบางอย่างผิดพลาด",
                      confirmButtonColor: "#0277bd",
                      confirmButtonText: "ตกลง",
                      customClass: {
                        confirmButton: "prompt-font",
                      },
                    }).then((response) => {
                      if (response.isConfirmed) {
                        setOpenSignIn(true);
                      }
                    });
                  })
                  .finally(() => {
                    setOpenSignIn(false);
                  });
              } else {
                const role = session_token.role;
                if (role === "influencer" || role === "employer") {
                  history.push("/my-profile");
                } else if (role === "admin") {
                  history.push("/admin-dashboard");
                }
              }

              if ("Notification" in window) {
                signgUpAnonymously();
                getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
                  .then(async (token) => {
                    await NotificationAPI.addFCMClient({
                      user_id: session_token.user_id,
                      token: token,
                    });
                    const encryptedToken = CryptoJS.AES.encrypt(
                      JSON.stringify(token),
                      "secret key"
                    ).toString();
                    localStorage.setItem("notification_token", encryptedToken);
                    NotificationAPI.getNotificationList().then((noti) => {
                      if (session_token.role === "influencer") {
                        dispatch(setInfluencerNotification(noti));
                      } else if (session_token.role === "employer") {
                        dispatch(setEmployerNotification(noti));
                      }
                    });
                  })
                  .catch(() => {
                    if (session_token.role === "influencer") {
                      dispatch(setInfluencerNotification(null));
                    } else if (session_token.role === "employer") {
                      dispatch(setEmployerNotification(null));
                    }
                  });
              } else {
                if (session_token.role === "influencer") {
                  dispatch(setInfluencerNotification(null));
                } else if (session_token.role === "employer") {
                  dispatch(setEmployerNotification(null));
                }
              }
            }
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        let data = error.response.data;
        if (data.status_code === 400) {
          switch (data.error.message) {
            case "error, email format is invalid (example@hasumail.com)":
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "รูปแบบอีเมลล์ไม่ถูกต้อง",
                confirmButtonColor: hAlertColor.error,
                confirmButtonText: "Close",
              });
              break;
            case "error, user not found":
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "ไม่พบผู้ใช้บัญชีนี้",
                confirmButtonColor: hAlertColor.error,
                confirmButtonText: "Close",
              });
              break;
            case "error, password is invalid":
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "รหัสผ่านไม่ถูกต้อง",
                confirmButtonColor: hAlertColor.error,
                confirmButtonText: "Close",
              });
              break;
          }
        } else {
          SwalCustom.fire({
            icon: "error",
            text: "Something when wrong !",
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#0277BD",
            customClass: {
              confirmButton: "prompt-font",
            },
          });
        }
      });
  };

  if (isLoading) return <Loading />;

  return (
    <DialogAuthentication
      title="Sign in"
      open={open}
      onClose={handleCloseDialog}
      sx={classes.authenDialog}
    >
      <DialogContent sx={classes.dialogContent}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSignIn)}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <TextFieldCustom
            value={getValues("email")}
            onChange={(e) => handleChangeInput(e, "email")}
            variant="outlined"
            placeholder="Email"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="ico-hu-mail"
                    sx={{ fontSize: "18px", color: "#757575" }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextFieldCustom
            value={getValues("password")}
            onChange={(e) => handleChangeInput(e, "password")}
            type={!!showPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Password"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!!showPassword ? (
                      <Icon
                        className="ico-hu-visability_off"
                        sx={{ color: "#757575" }}
                      />
                    ) : (
                      <Icon
                        className="ico-hu-visability"
                        sx={{ color: "#757575" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <Button type="submit" fullWidth>
            <Typography variant="body1" fontWeight="bold">
              Sign in
            </Typography>
          </Button>
        </Stack>
        <Typography
          color="grey.800"
          variant="body1"
          align="center"
          onClick={() => openForgetPassword()}
          sx={classes.forgetPasswordLink}
        >
          Forget your password?
        </Typography>
      </DialogContent>
      <Divider sx={classes.divider} />
      <DialogContent sx={classes.dialogContent}>
        <Stack>
          <Typography variant="body1" align="center">
            Don't have an account?
          </Typography>
          <Typography
            color="primary"
            variant="body1"
            fontWeight="bold"
            align="center"
            onClick={() => openSelectAccountType()}
            sx={classes.createAccountLink}
          >
            Create an Account
          </Typography>
        </Stack>
      </DialogContent>
    </DialogAuthentication>
  );
}
