import { useEffect } from "react";
// mui and icon
import { Box, Icon, Typography } from "@mui/material";
import SyncInstagram from "./SyncInstagram";

export default function SyncSocia(props: any) {
  const { TabPanel, tabSelected, index, social } = props;

  const tiktokUrl = "https://storage.googleapis.com/hashu-bucket-uat/media/videos/tiktok.mp4";
  const twitterUrl = "https://storage.googleapis.com/hashu-bucket-uat/media/videos/twitter.mp4";
  const facebookUrl = "https://storage.googleapis.com/hashu-bucket-uat/media/videos/facebook.mp4";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TabPanel value={tabSelected} index={index}>
      <Box p={{ xs:"0", md:"0 40px" }}>
        <Typography variant="h5">การเชื่อมต่อ {social}</Typography>
        {social === "Instagram" ? (
          <SyncInstagram />
        ) : (
          <>
            {social !== "Twitter" && (
              <Box
                sx={{
                  marginTop: "16px",
                  backgroundColor: "#F5EEFF",
                  padding: "24px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        color: "#8C499C",
                        backgroundColor: "#fff",
                        marginRight: "16px",
                      }}
                    >
                      <Icon className="ico-hu-announce" />
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="h6">เงื่อนไขการเชื่อมต่อ</Typography>
                    <Typography>
                      บัญชี {social} ของคุณต้องเป็น{" "}
                      {social === "Facebook" ? (
                        <>"Facebook Page"</>
                      ) : (
                        <>"บัญชีสาธารณะ"</>
                      )}{" "}
                      เท่านั้น
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <Box mt="16px">
              <Typography>1. กดปุ่ม "Sign In" เพื่อเข้าสู่ระบบ</Typography>
              <Typography>
                2. กดเมนู My Profile และมองหาแถบ {social} จากนั้นคลิกปุ่ม{" "}
                "Continue With {social}" เพื่อดำเนินการเชื่อมต่อ
              </Typography>
              <Typography>
                3. อนุญาตและให้สิทธิ์ HashU เข้าถึงบัญชี {social} ของคุณ
              </Typography>
              <Typography>
                4. จากนั้นเลือก 3 ลักษณะคอนเทนท์
                และกลุ่มเป้าหมายที่เกี่ยวข้องกับบัญชีมากที่สุด แล้วคลิกปุ่ม{" "}
                "Verify {social} Account"
              </Typography>
              <Typography>5. เชื่อมต่อ {social} สำเร็จ</Typography>
            </Box>

            <Box
              sx={{
                mt: "24px",
                mb: "24px",
                "& video": {
                  width: "100%",
                },
              }}
            >
              <video
                height="auto"
                controls
                src={
                  social === "Tiktok"
                    ? tiktokUrl
                    : social === "Twitter"
                    ? twitterUrl
                    : social === "Facebook"
                    ? facebookUrl
                    : ""
                }
              />
            </Box>
          </>
        )}
      </Box>
    </TabPanel>
  );
}
