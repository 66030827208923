import { useContext, useState } from "react";
// mui
import {
  Box,
  Icon,
  Paper,
  Button,
  Divider,
  InputBase,
  IconButton,
  Typography,
} from "@mui/material";
// theme and style
import { hAlertColor } from "@theme/index";
import { useTeamManagementStyle as classes } from "../../TeamManagement.style";
// component
import TableMembers from "./TableMembers";
// api and context
import { TeamAPI } from "@api/global";
import TeamContext from "@contexts/Team";
// component
import AddMember from "./AddMember";
import EditMemberPermission from "./EditPermission";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// other
import Loading from "@global/loading/Loading";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import DialogPermission from "@components/team-management/create-team/DialogPermission";

export default function MembersTab() {
  const teamContext = useContext(TeamContext);
  const teamInfo = teamContext?.teamInfo;

  const [openToEditmember, setOpenToEditmember] = useState(false);
  const [openToReadPermForEdit, setOpenToReadPermForEdit] = useState(false);
  const [openToReadPermForAdd, setOpenToReadPermForAdd] = useState(false);
  const [openToAddMember, setOpenToAddMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const employerInfo = useAppSelector(selectEmployerInfo);
  const findEmployer = teamInfo?.employer?.find(
    (find) => find.email === employerInfo?.email
  );

  const handleDeleteEmployer = (empId: string) => {
    const teamId = teamInfo?.id || "";
    const findData = teamInfo?.employer?.find((find) => find.id === empId);

    SwalCustom.fire({
      icon: "question",
      title: "ต้องการลบสมาชิกทีมนี้ใช่หรือไม่ ?",
      text: `ลบ "${findData?.fullname}" ออกจากทีม`,
      confirmButtonColor: "#8C499C",
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ยืนยัน",
    }).then(async (response) => {
      if (response.isConfirmed) {
        setIsLoading(true);
        TeamAPI.removeTeamMember(teamId, { employer_id: empId })
          .then((response) => {
            teamContext?.setTeamInfo(response);
            SwalCustom.fire({
              icon: "success",
              title: "ลบสมาชิกสำเร็จ",
              text: `คุณได้ลบ "${findData?.fullname}" ออกจากทีม`,
              confirmButtonColor: hAlertColor.success,
              confirmButtonText: "ลบสมาชิกออกจากทีมสำเร็จ",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setSearch(value);
  };

  const openToReadPermissionInfoForAdd = () => {
    setOpenToAddMember(false);
    setOpenToReadPermForAdd(true);
  };

  const openToReadPermissionInfoForEdit = () => {
    setOpenToEditmember(false);
    setOpenToReadPermForEdit(true);
  };

  const closeToReadPermissionInfoForAdd = () => {
    setOpenToReadPermForAdd(false);
    setOpenToAddMember(true);
  };

  const closeToReadPermissionInfoForEdit = () => {
    setOpenToReadPermForEdit(false);
    setOpenToEditmember(true);
  };

  if (isLoading) return <Loading />;

  return (
    <Box sx={classes.card}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h5">Members</Typography>
        <Typography variant="subTitle1">รายชื่อสมาชิกทีม</Typography>
      </Box>

      <Divider sx={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Box width="30%">
          <Paper component="form" sx={classes.paperSearch}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by name, email"
              inputProps={{ "aria-label": "Search by name, email" }}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <Icon className="ico-hu-search" />
            </IconButton>
          </Paper>
        </Box>
        <Box>
          <Button
            disabled={findEmployer?.role === "Member" ? true : false}
            sx={classes.buttonPermission}
            onClick={() => setOpenToEditmember(true)}
          >
            <Icon className="ico-hu-edit" sx={{ marginRight: "4px" }} />
            แก้ไข Permission
          </Button>
          <Button
            disabled={
              findEmployer?.role === "Member"
                ? true
                : teamInfo?.package?.campaign_management.member ===
                  teamInfo?.package?.campaign_management.member_limit
                ? true
                : false
            }
            sx={classes.buttonAdd}
            onClick={() => setOpenToAddMember(true)}
          >
            <Icon className="ico-hu-add-person" sx={{ marginRight: "4px" }} />
            เพิ่มสมาชิก
          </Button>
        </Box>
      </Box>

      <Box mt="24px">
        <TableMembers
          handleDeleteEmployer={handleDeleteEmployer}
          findRoleEmployer={findEmployer?.role || ""}
          search={search}
        />
      </Box>

      <EditMemberPermission
        open={openToEditmember}
        onClose={() => setOpenToEditmember(false)}
        onCloseToReadPermission={closeToReadPermissionInfoForEdit}
        onOpenToReadPermission={openToReadPermissionInfoForEdit}
      />

      <AddMember
        open={openToAddMember}
        onClose={() => setOpenToAddMember(false)}
        onCloseToReadPermission={closeToReadPermissionInfoForAdd}
        onOpenToReadPermission={openToReadPermissionInfoForAdd}
      />

      <DialogPermission
        open={openToReadPermForAdd}
        onClose={closeToReadPermissionInfoForAdd}
      />

      <DialogPermission
        open={openToReadPermForEdit}
        onClose={closeToReadPermissionInfoForEdit}
      />
    </Box>
  );
}
