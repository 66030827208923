// mui and icon
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
// style, api and tyep
import { useConfirmGoodsReceivedStyle as classes } from "../JobWorking.style";
import { IInitConfirmGoodsReceivedForm } from "../../../JobsTab.type";
import { JobAPI } from "@api/global";
// redux and components
import { useDispatch } from "react-redux";
import { setInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
import MultiImageInput from "../../../job-default/MultiImageInput";
import DateInput from "../../../job-default/DateInput";
// others
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import TextFieldCustom from "@global/text-field";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

export const initConfirmGoodsReceivedForm: IInitConfirmGoodsReceivedForm = {
  delivery_confirmation: {
    date_of_receipt: "",
    attachment: [],
    note: "",
  },
};

export default function ConfirmGoodsReceived() {
  const form = useForm<IInitConfirmGoodsReceivedForm>({
    defaultValues: initConfirmGoodsReceivedForm,
  });

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const dispatch = useDispatch();
  const { jobId } = useParams<{ jobId: string }>();

  const attachment = watch("delivery_confirmation.attachment");

  const onConfirmGoodsReceived = async (
    value: IInitConfirmGoodsReceivedForm
  ) => {
    const data = {
      delivery_confirmation: {
        ...value.delivery_confirmation,
        date_of_receipt: dayjs(
          value.delivery_confirmation.date_of_receipt
        ).format("YYYY-MM-DD"),
      },
    };
    await JobAPI.updateInfluencerJob(jobId, data)
      .then((response) => {
        dispatch(setInfluencerJobInfo(response));
      })
      .catch((error) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  return (
    <Box sx={classes.confirmCon}>
      <Box sx={classes.title}>
        <Typography>Confirm Goods Received</Typography>
        <Typography>โปรดยืนยันสินค้าที่ได้รับจากผู้จ้างงาน</Typography>
      </Box>
      <Divider sx={{ bgcolor: "#EBDFFF", mt: ["20px", 0] }} />
      <Grid container p={["24px 0 24px 0", "24px"]} spacing={5}>
        <Grid item xs={3.5}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography variant="body2">Date of receipt</Typography>
          </Box>
        </Grid>
        <Grid item xs={8.5}>
          <DateInput
            keyName="delivery_confirmation.date_of_receipt"
            form={form}
            placeholder="วว/ดด/ปปปป"
            errors={errors?.delivery_confirmation}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={3.5}>
          <Typography variant="body2">Photos</Typography>
        </Grid>
        <Grid item xs={8.5}>
          <MultiImageInput
            keyName="delivery_confirmation.attachment"
            setValue={setValue}
            images={attachment ? attachment : []}
            errors={errors?.delivery_confirmation}
          />
        </Grid>
        <Grid item xs={3.5}>
          <Typography variant="body2">Note</Typography>
        </Grid>
        <Grid item xs={8.5}>
          <TextFieldCustom
            variant="outlined"
            multiline
            minRows={4}
            placeholder="Please enter text..."
            fullWidth
            inputProps={{
              ...register(`delivery_confirmation.note`, { required: false }),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={classes.listingBtn}>
            <Button
              onClick={() =>
                handleSubmit((e: IInitConfirmGoodsReceivedForm) =>
                  onConfirmGoodsReceived(e)
                )()
              }
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={
                !watch("delivery_confirmation.date_of_receipt") ||
                !watch("delivery_confirmation.attachment").length
                  ? true
                  : false
              }
            >
              บันทึกและเริ่มงานได้
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
