import { useEffect, useState } from "react";
// mui and icon
import { Box, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// redux
import { useAppSelector } from "@store/hook";
import { selectActivity } from "@slices/Activity.slice";
// style and other
import { useBannerStyle as classes } from "./Events.style";
import { useHistory } from "react-router-dom";
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint as setVariant } from "@utils/helpers/setValueByBreakpoint.helpers";

const initTime = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export default function EventsBanner() {
  const [timeLeft, setTimeLeft] = useState(initTime);

  const bp = useBreakpoint();
  const history = useHistory();
  const activity = useAppSelector(selectActivity);

  useEffect(() => {
    const countDownDate = new Date(activity?.end ? activity.end : "").getTime();

    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(intervalId);
        setTimeLeft(initTime);
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const countdownDetailList = [
    {
      label: "วัน",
      timeLeft: timeLeft.days,
    },
    {
      label: "ชั่วโมง",
      timeLeft: timeLeft.hours,
    },
    {
      label: "นาที",
      timeLeft: timeLeft.minutes,
    },
    {
      label: "วินาที",
      timeLeft: timeLeft.seconds,
    },
  ];

  return (
    <Box
      style={{
        background: `url(${activity?.attachments[0].url}) no-repeat center / cover`,
        // backgroundSize: "cover",
      }}
      sx={classes.conatiner}
      id="back-to-top-anchor"
    >
      <IconButton onClick={goBack} sx={classes.goBackBtn}>
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Box sx={classes.countDownBox}>
        <Stack direction="row" sx={classes.countDown}>
          {countdownDetailList.map((item, index) => (
            <Box key={index} sx={classes.timeLeftBox}>
              <Typography variant={setVariant(bp, "body2", "body1")}>
                {item.label}
              </Typography>
              <Typography
                variant="h4"
                fontSize={["28px", "36px"]}
                color="#8C499C"
              >
                {item.timeLeft}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
