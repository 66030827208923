import { styled } from "@mui/material/styles";
import { Button, TableRow } from "@mui/material";
import { hPink, hWhite, hPurple } from "@theme/index";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const InfluencerMyEarningStyles = {
  totalEarningBox: {
    height: {
      xs: "130px",
      sm: "150px",
      md: "180px",
    },
    borderRadius: {
      xs: "8px",
      md: "16px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hPink[400],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    color: hWhite[100],
    "& svg": {
      width: "24px",
      height: "24px",
      fill: hWhite[100],
    },
  },
  totalEearning: {
    display: "flex",
    "& p": {
      fontSize: ["30px", "35px", "44px", "48px"],
      marginRight: "6px",
    },
    "& span": {
      alignSelf: "flex-end",
      marginBottom: ["6px", "9px", "13px"],
    },
  },
  brandsBox: {
    color: hWhite[100],
    height: ["130px", "150px", "180px"],
    borderRadius: {
      xs: "8px",
      sm: "16px",
    },
    justifyContent: "space-evenly",
    alignItems: "center",
    background: hPurple[300],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  brands: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    "& div": {
      display: "flex",
      "& img": { width: "24px", height: "24px", marginRight: "10px" },
      justifyContent: "center",
      alignItems: "center",
    },
  },
  brandsLine: {
    width: "2px",
    height: "80%",
    backgroundColor: "#fff",
    margin: "auto 0",
    borderRadius: "2px",
  },
  bankAccountBox: {
    minHeight: ["130px", "150px", "180px"],
    height: "100%",
    borderRadius: {
      xs: "8px",
      md: "16px",
    },
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: hWhite[100],
  },
  bankAccountCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
    marginTop: "15px",
  },
  textSize: {
    opacity: 0.8,
    my: ["5px", "4px", "2px", "6px"],
    fontSize: ["12px", "14px", "14px", "16px"],
  },
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
  contentDialog: {
    width: "89%",
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "89%",
    mt: "10px",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
};

const status = {
  status: {
    display: "flex",
    width: "80px",
    height: "28px",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    margin: [0, 0, "auto"],
  },
};

export const InfluencerHistoryStyle = {
  campaignIcon: {
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },
  statusPaid: {
    ...status.status,
    backgroundColor: "#DBFFDC",
    color: "#4CAF50",
  },
  statusPending: {
    ...status.status,
    backgroundColor: "#FFEEDF",
    color: "#FB8C00",
  },
  cardContent: { borderRadius: "16px", backgroundColor: "#fff" },
  recipientIcon: { width: "16px", height: "16px", mr: "5px" },
  cardTable: {
    mt: "10px",
    "& .cardTable": {
      display: "flex",
      justifyContent: "space-between",
      height: "30px",
      alignItems: "center",
      "& p": {
        fontSize: "14px",
      },
    },
  },
  pagination: {
    "& .MuiTablePagination-displayedRows, .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-select, .MuiInputBase-root": {
      border: "1.5px solid #9E9E9E",
      borderRadius: "50px",
      width: "80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiInputBase-root": {
      border: 0,
    },
    "& svg:first-of-type": {
      width: ["25px", "25px", "25px"],
      height: ["25px", "25px", "25px"],
      borderRadius: "50%",
      border: "1.5px solid #9E9E9E",
      "&:hover": {
        color: hWhite[100],
        backgroundColor: hPink[300],
        border: "none",
      },
    },
  },
};

export const useInfluencerHistoryStyle = {
  card: {
    "& > .css-dhuk9u-MuiPaper-root-MuiAccordion-root": {
      maxWidth: "none",
    },
    "& > .css-1nv18j2-MuiPaper-root-MuiAccordion-root": {
      bgcolor: "initial",
      boxShadow: "none",
    },
  },
  accordion: {
    width: "100%",
    maxWidth: "400px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    minHeight: "80px",
    "& > div:first-of-type": {
      minHeight: "80px",
      px: "15px",
      bgcolor: "#fff",
      borderRadius: "8px",
    },
    "& > div:last-of-type": {
      minHeight: "80px",
      px: "8px",
      bgcolor: "#fff",
      borderRadius: "0 0 8px 8px",
    },
  },
  tableBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
  },
  campaignIcon: {
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },
  receipName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  cardTable: {
    mt: "10px",
    "& .cardTable": {
      display: "flex",
      justifyContent: "space-between",
      height: "30px",
      alignItems: "center",
      "& p": {
        fontSize: "14px",
      },
    },
  },
  statusPaid: {
    ...status.status,
    backgroundColor: "#DBFFDC",
    color: "#4CAF50",
  },
  statusPending: {
    ...status.status,
    backgroundColor: "#FFEEDF",
    color: "#FB8C00",
  },
  button: {
    bgcolor: "#8C499C",
    color: "#fff",
    ":hover": {
      border: "2px solid #8C499C",
      color: "#8C499C",
    },
    maxHeight:"40px"
  },
  taxButton: {
    bgcolor: "#E8008A",
    color: "#fff",
    ":hover": {
      border: "2px solid #E8008A",
      color: "#E8008A",
    },
    maxHeight:"40px"
  },
  influName: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  earning: {
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: "12px",
    mb: "15px",
  },
};

export const useEditBankAccountDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root .MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  dialogContent: {
    paddingTop: 3,
    paddingBottom: 0,
  },

  uploadImageBox: {
    borderRadius: "4px",
    width: "170px",
    height: "170px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
  },

  uploadImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px dashed gray",
    width: "150px",
    height: "150px",
    borderRadius: "4px",
    "& img": {
      opacity: 0.5,
      width: "70px",
    },
  },
};

export const DocumentButton = styled(Button)({
  backgroundColor: hPurple[200],
  color: hWhite[100],
  height: ["40px"],
  padding: "0 15px",
  "&:hover": {
    backgroundColor: hPurple[300],
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: hPurple[300],
  },
});

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableCellCustom = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: hPurple[200],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
