// mui and style
import {
  Box,
  Icon,
  Divider,
  Typography,
  DialogContent,
} from "@mui/material";
import { useTransactionHistoryDialogStyle as classes } from "../Wallettab.style";
// type and other
import {
  payTypeMatchIcon,
  payTypeMatchColor,
} from "../WalletTransaction";
import { ITransactionInfo } from "../../Member.type";
// other
// import { depositInfo, percentPrice } from "@utils/helpers/payment";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";
import dayjs from "dayjs";

export default function TransactionInfoDialog(props: {
  open: boolean;
  onClose: (val: boolean) => void;
  transactionInfo?: ITransactionInfo;
}) {
  const { open, onClose, transactionInfo } = props;

  const numberFormat = new Intl.NumberFormat("en", {
    minimumIntegerDigits: 2,
  });

  const paymentStatusMatchColor = (status?: string) => {
    if (status === "Pending") {
      return "#FB8C00";
    } else {
      return "#4CAF50";
    }
  };

  return (
    <DialogScrollingCustom
      title="ประวัติรายการ"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogContent sx={{ m: "20px 0 20px 0" }}>
        <Box sx={classes.title}>
          <Box display="flex">
            <Icon
              className={payTypeMatchIcon(transactionInfo?.pay_type.title_employer)}
              sx={{
                color: payTypeMatchColor(transactionInfo?.pay_type.title_employer),
                fontSize: "60px",
                mr: "16px",
              }}
            />
            <div>
              <Typography textTransform="capitalize">
                {transactionInfo?.description.replace(/[_-]/g, " ")}
              </Typography>
              <Typography variant="h5">
                {numberFormat.format(transactionInfo?.amount || 0)} บาท
              </Typography>
            </div>
          </Box>
          {transactionInfo?.campaign && (
            <>
              <Divider sx={{ my: "16px" }} />
              <Box display="flex" flexDirection="column" rowGap="10px">
                {[
                  { label: "Campaign Name", value: transactionInfo?.campaign?.name },
                  { label: "Campaign ID", value: transactionInfo?.campaign?.code },
                  { label: "Campaign Budget", value: `${numberFormat.format(transactionInfo?.amount || 0)} บาท` },
                ].map((li, index) => (
                  <Box key={index} display="flex" justifyContent="space-between">
                    <Typography variant="title1">{li.label}</Typography>
                    <Typography>{li.value}</Typography>
                  </Box>
                ))}
              </Box>
              {/* {!/Hold/.test(transactionInfo?.pay_type.title_employer || "") && (
                <Box component="ul" sx={classes.depositBox}>
                  {[
                    { label: `ค่ามัดจำ ${depositInfo(transactionInfo.amount)}%`, value: `${percentPrice("deposit", transactionInfo.amount || 0)} บาท` },
                    { label: "ยอดสำรองจ่าย", value: `${percentPrice("amount", transactionInfo.amount || 0)} บาท` },
                  ].map((li, index) => (
                    <Box key={index} display="flex" justifyContent="space-between">
                      <Typography variant="body2" component="li" ml="20px">
                        {li.label}
                      </Typography>
                      <Typography variant="body2">{li.value}</Typography>
                    </Box>
                  ))}
                </Box>
              )} */}
            </>
          )}
        </Box>
        <Box
          sx={{
            ...classes.detailBox,
            border: "1px solid #E0E0E0",
            p: "24px",
            borderRadius: "8px",
          }}
        >
          {[
            { label: "วันที่และเวลา", value: dayjs(transactionInfo?.created_at).format("DD-MM-YYYY HH:mm") },
            { label: "เลขที่รายการ", value: transactionInfo?.code },
            { label: "ประเภท", value: transactionInfo?.pay_type.title_employer },
            {
              label: "สถานะ",
              value: (
                <span
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                    color: paymentStatusMatchColor(transactionInfo?.payment_status.title),
                  }}
                >
                  {transactionInfo?.payment_status.title}
                </span>
              ),
            },
            { label: "ดำเนินการโดย", value: transactionInfo?.managed_by?.fullname },
            { label: "ช่องทางการเติมเงิน", value: "xxxx xxxx xxxx 1234" },
          ].map((li, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography variant="title1">{li.label}</Typography>
              <Typography>{li.value}</Typography>
            </Box>
          ))}
          <Box sx={classes.amountBox}>
            <Typography variant="title1">จำนวนเงิน</Typography>
            <Typography variant="title1">
              {numberFormat.format(transactionInfo?.amount || 0)} บาท
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </DialogScrollingCustom>
  );
}
