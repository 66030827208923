import { useState } from "react";
// mui and style
import { Box, Stack, Avatar, Divider, Typography, Icon } from "@mui/material";
import { useJobApprovePostStyle as classes } from "../JobApproved.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// others
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import ReactPlayer from "react-player";
import Linkify from "react-linkify";
import nl2br from "react-nl2br";

export default function PostDetail() {
  const [openAttachment, setOpenAttachment] = useState(false);

  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const attList = jobInfo?.attachments || [];
  const urlList = jobInfo?.external_file || [];
  const contentType = jobInfo?.content_type.type;
  const isVideoType = contentType === "video";

  return (
    <Box sx={classes.postDetailCon}>
      <Box sx={classes.title}>
        <Typography>Approved Content</Typography>
        <Typography>เนื้อหาที่ได้รับการอนุมัติ</Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Box p={["24px 0 24px 0", "24px"]}>
        <Box>
          <Typography color="primary" fontWeight="500">
            {isVideoType ? "Videos" : "Images"}
          </Typography>
          <Stack
            direction="row"
            columnGap={[2, 5]}
            rowGap={[2, 5]}
            flexWrap="wrap"
            mt={3}
          >
            {isVideoType
              ? attList.length
                ? attList.map((att, index) => (
                    <Box
                      key={index}
                      onClick={() => setOpenAttachment(true)}
                      height={["150px", "240px"]}
                      width={["150px", "240px"]}
                    >
                      <Box sx={classes.draftVideo}>
                        <Box sx={{ ...classes.iconPlayVido, borderRadius: 0 }}>
                          <Icon
                            sx={{
                              fontSize: "40px",
                              color: "#fff",
                            }}
                            className="ico-hu-play-circle-outline"
                          />
                        </Box>
                        <ReactPlayer
                          url={att.url}
                          width="100%"
                          height="100%"
                          loop={true}
                          muted={true}
                        />
                      </Box>
                    </Box>
                  ))
                : urlList.map((url, index) => (
                    <Box
                      key={index}
                      display={
                        url.is_approve !== "approved" ? "none" : "initial"
                      }
                      sx={classes.urlBox}
                      onClick={() => window.open(url.url, "_blank")}
                    >
                      <Box sx={classes.urlIcon}>
                        <Icon className="ico-hu-conversion" />
                        <Typography variant="body2" mt="15px">
                          Video URL
                        </Typography>
                      </Box>
                      <Box sx={classes.urlImg}>
                        <img src={urlLogo} alt="item" />
                      </Box>
                    </Box>
                  ))
              : attList.map((att, index) => (
                  <Box
                    key={index}
                    onClick={() => setOpenAttachment(true)}
                    height={["150px", "240px"]}
                    width={["150px", "240px"]}
                  >
                    <Avatar
                      src={att.url}
                      sx={classes.imgItem}
                      alt="attachment"
                    />
                  </Box>
                ))}
          </Stack>
        </Box>
        <Box sx={classes.captionBox}>
          <Typography variant="title1">Caption</Typography>
          {jobInfo?.description?.description && (
            <Box my="10px">
              <Linkify>
                <Typography>
                  {nl2br(jobInfo?.description.description)}
                </Typography>
              </Linkify>
            </Box>
          )}
        </Box>
      </Box>
      {jobInfo?.comment && (
        <>
          <Divider sx={{ m: [0, "0 24px 0 24px"] }} />
          <Box m={["24px 0 24px 0", "24px"]}>
            <Typography variant="title1" mb="8px">
              Message to Influencer
            </Typography>
            <Box display="flex" mt="16px">
              <Avatar
                sx={{ width: "48px", height: "48px" }}
                src={jobInfo.campaign_info.organisation?.attachment?.url}
              />
              <Box sx={classes.comment}>
                <Linkify>
                  <Typography>{nl2br(jobInfo?.comment)}</Typography>
                </Linkify>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {isVideoType ? (
        <ViewMultiVideoDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          videos={attList}
        />
      ) : (
        <ViewMultiImageDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          images={attList}
        />
      )}
    </Box>
  );
}
