// mui and style
import { Box, Icon, Typography } from "@mui/material";
import { useListingStyle as classes } from "../ListingTab.style";
// type and react-route
import { IInfluencerData } from "@api/dashboard/type";
import { useHistory } from "react-router-dom";
// others
import defaultImage from "@assets/images/logo/hashu-1024x1024.png";

export default function CardInfluencer({ item }: { item: IInfluencerData }) {
  const history = useHistory();

  const banStatusMatch = (value: string) => {
    const banStatus = (
      title: string,
      style: "statusBan" | "statusBan3" | "statusDelete" | "statusActive"
    ) => {
      return <Typography sx={classes[style]}>● {title}</Typography>
    };

    if (value === "2 weeks") {
      return banStatus("คาดโทษครั้งที่ 1", "statusBan");
    } else if (value === "1 months") {
      return banStatus("คาดโทษครั้งที่ 2", "statusBan");
    } else if (value === "2 months" || value === "3 months") {
      return banStatus("คาดโทษครั้งที่ 3", "statusBan3");
    } else if (value === "permanent") {
      return banStatus("ลบบัญชีถาวร", "statusDelete");
    } else {
      return banStatus("ปกติ", "statusActive");
    }
  };

  const platformMatch = (value: string) => {
    if (value === "tiktok") {
      return <Icon className="ico-hu-tiktok" sx={{ color: "#fff" }} />
    } else if (value === "twitter") {
      return <Icon className="ico-hu-twitter" />
    } else if (value === "facebook") {
      return <Icon className="ico-hu-facebook" sx={{ color: "#1A77F1" }} />
    } else if (value === "instagram") {
      return <Icon className="ico-hu-instagram" sx={{ color: "#893DBD" }} />
    } else if (value === "instagram") {
      return <Icon className="ico-hu-youtube" sx={{ color: "#FF0000" }} />
    } else {
      return null
    }
  };

  const goInfluencerInfo = (influencerId: string) => {
    history.push(`/influencers/${influencerId}/info`);
  };

  return (
    <Box position="relative">
      <Box
        sx={classes.boxImage}
        onClick={() => goInfluencerInfo(item.id)}
      >
        {item.profile_image_url ? 
          <img src={item.profile_image_url} alt="influencer-profile" />
        : (
          <img
            src={defaultImage}
            alt="influencer-profile"
            style={{ width:"70%", height:"70%" }}
          />
        )}
      </Box>

      <Box sx={classes.boxDetail}>
        <Box padding="12px">
          <Typography variant="title1">{item.fullname}</Typography>
          {banStatusMatch(item.usage_status)}
        </Box>
        <Box sx={classes.boxPlatform}>
          {item.platforms.length ? item.platforms.map((platform, index) => (
            <Box key={index} mr="4px" onClick={() => window.open(platform.url)} sx={{cursor:"pointer"}}>
              {platformMatch(platform.name)}
            </Box>
          )) : (
            <Box mr="4px">
              <Icon className="ico-hu-remove" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
