// mui and type
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { IRenewDialogProps } from "../Subscription.type";
// other
import dayjs from "dayjs";

export default function RenewDialog(props: IRenewDialogProps) {
  const { open, onClose, handleSubmit, nextPayment } = props;

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ p: "30px" }}>
        <Typography variant="h5" textAlign="center" mb="16px">
          ระงับการต่อรอบบิลอัตโนมัติ
        </Typography>
        <ul>
          {[
            <>
              แคมเปญที่เกิดขึ้นก่อนวันที่{" "}
              <span style={{ fontWeight: 500 }}>
                {dayjs(nextPayment).format("DD/MM/YYYY")}
              </span>{" "}
              สามารถดำเนินการต่อได้ตามปกติจนกว่าแคมเปญ จะสิ้นสุดลง
            </>,
            <>
              การใช้งานหลังวันที่{" "}
              <span style={{ fontWeight: 500 }}>
                {dayjs(nextPayment).format("DD/MM/YYYY")}{" "}
              </span>
              ไม่สามารถสร้างแคมเปญเพิ่มเติมได้ จนกว่าจะมีการชำระเงิน
              หรือปรับเปลี่ยน Plan
            </>,
          ].map((li, index) => (
            <Typography key={index} component="li">
              {li}
            </Typography>
          ))}
        </ul>
        <Typography display="block" variant="title1" textAlign="center">
          ต้องการดำเนินการต่อหรือไม่ ?
        </Typography>
        <Box
          sx={{
            display: "flex",
            mt: "30px",
            columnGap: "10px",
            "& > button:last-of-type": { border: "none" },
          }}
        >
          <Button onClick={() => handleSubmit(true)} fullWidth>
            ไม่ คงการต่อรอบบิลไว้
          </Button>
          <Button
            onClick={() => handleSubmit(false)}
            variant="contained"
            fullWidth
          >
            ใช่ ต้องการระงับการต่อรอบบิล
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
