// mui and other
import { Box, Avatar, Snackbar, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";

interface ISnackBarProps {
  open: boolean;
  onClose: (value: boolean) => void;
  data: {
    campaignName: string;
    organizationUrl: string;
    path: string;
  } | null;
}

export default function SnackBar(props: ISnackBarProps) {
  const { open, onClose, data } = props;

  const history = useHistory();

  const handleViewInterestCampaign = () => {
    history.push(data?.path ? data.path : "");
    onClose(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        minWidth: {
          xs: "350px",
          sm: "500px",
        },
        borderRadius: "8px",
        boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.2)",
        height: "72px",
        bgcolor: "#fff",
      }}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          justifyContent: "space-between",
          alignItem: "start",
        }}
      >
        <Box
          onClick={handleViewInterestCampaign}
          sx={{
            m: "12px",
            display: "flex",
            alignItems: "center",
            width: "80%",
            cursor: "pointer",
          }}
        >
          <Avatar
            src={data?.organizationUrl}
            sx={{ borderRadius: "4px", width: "48px", height: "48px" }}
          />
          <Box display="flex" flexDirection="column" ml="12px" width="80%">
            <Typography variant="subTitle2">แคมเปญที่คุณกำลังสนใจ</Typography>
            <Typography
              variant="title1"
              color="#8C499C"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {`"${data?.campaignName}"`}
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => onClose(false)}
          sx={{
            width: "20px",
            height: "20px",
            m: "8px",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Snackbar>
  );
}
