// mui and style
import { Box, Divider, Typography } from "@mui/material";
import { useCancelCampaignStyle as classes } from "./CancelCampaign.style";
import { useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";

export default function CancelCampaignDetail() {
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const isCampaignPrivate = campaignInfo?.campaign_type === "private";

  const textMatchColor = (accept: number, need: number) => {
    if (accept < need) {
      return <span style={{ color: "#4CAF50" }}>คืนค่ามัดจำ</span>;
    } else {
      return <span style={{ color: "#E30D18" }}>หักค่ามัดจำ</span>;
    }
  };

  return (
    <Box sx={classes.card}>
      <Box>
        <Typography variant="h6">ประวัติการชำระเงิน</Typography>
        <Box sx={classes.historyList}>
          {[
            { label: "Transaction Date", value: "01/05/2023 10:30" },
            { label: "Transaction No.", value: "123456789123" },
            { label: "ช่องทางการชำระเงิน", value: "QRpayment" },
            { label: "Campaign Budget", value: "40,000.00 บาท" },
          ].map((li, index) => (
            <Box key={index}>
              <Typography>{li.label}</Typography>
              <Typography variant="title1">{li.value}</Typography>
            </Box>
          ))}
          <Box component="ul" sx={classes.depositBox}>
            {[
              { label: "ค่ามัดจำ 15%", value: "6,000.00 บาท" },
              { label: "ยอดสำรองจ่าย", value: "34,000.00 บาท" },
            ].map((li, index) => (
              <Box key={index} display="flex" justifyContent="space-between">
                <Typography component="li" ml="25px">
                  {li.label}
                </Typography>
                <Typography>{li.value}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt="24px">
        <Typography variant="h6">อินฟลูเอนเซอร์สมัครเข้าร่วมแคมเปญ</Typography>
        <Box sx={classes.infRegistCampaign}>
          {[
            {
              label: isCampaignPrivate ? "จำนวนที่ตอบรับข้อเสนอ" : "จำนวนที่สมัครเข้าร่วมแคมเปญ",
              value: "64 คน",
            },
            { label: "จำนวนที่ต้องการ", value: "20 คน" },
            {
              label: "จำนวนผู้สมัคร “มากกว่า” ที่กำหนด ",
              value: textMatchColor(64, 20),
            },
          ].map((li, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography>{li.label}</Typography>
              <Typography variant="title1">{li.value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Box>
        <Typography variant="h6">การคืนเงิน</Typography>
        <Box component="ul" display="flex" justifyContent="space-between">
          <Typography component="li">ยอดสำรองจ่าย</Typography>
          <Typography variant="title1">34,000.00 บาท</Typography>
        </Box>
      </Box>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Box mt="16px">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" color="primary">
            ได้รับเงินคืนเข้า Wallet
          </Typography>
          <Typography variant="h6" color="primary">
            34,000.00 บาท
          </Typography>
        </Box>
        <Typography variant="caption">หมายเหตุ</Typography>
        <Typography variant="caption" ml="12px">
          ระบบจะดำเนินการคืนเงินเข้า Wallet ของคุณภายใน 24 ชั่วโมง
        </Typography>
      </Box>
    </Box>
  );
}
