import { useState } from "react";
// mui and icons
import {
  Box,
  Fab,
  Icon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// style and type
import { useMultiImageInputStyle as classes } from "./JobDefault.style";
import { IMultiImageInputProps } from "./JobDefault.type";
// api and others
import { AppInformationAPI } from "@api/global";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";

export default function MultiImagesInput(props: IMultiImageInputProps) {
  const { keyName, images, errors, setValue, showImageIndex, disableIsActive } =
    props;
  const [isLoading, setIsLoading] = useState(false);

  const onInputImage = async (ev: any) => {
    const files = ev.target.files;
    const uploadPromises = [];

    setIsLoading(true);

    if (files) {
      for (const file of files) {
        const fileData = {
          file: file,
          destination: "company",
          permission: "public",
        };
        const result = await AppInformationAPI.uploadAttachment(fileData)
          .then((response) => {
            return {
              file_id: "",
              file_name: response.file_name,
              file_type: response.file_type,
              url: response.public_url as string,
              description: response.destination,
              purpose: "",
              is_approve: "new",
            };
          })
          .catch((err) => {
            setIsLoading(false);
            SwalCustom.fire({
              icon: "error",
              title: "Oops !",
              text: `${err.response.data.error.message}`,
              confirmButtonColor: "#E30D18",
              confirmButtonText: "ตกลง",
            });
          });
        uploadPromises.push(result);
      }
    }

    Promise.all(uploadPromises)
      .then((results) => {
        setIsLoading(false);
        if (results[0]) {
          setValue(keyName as any, [...images, ...results]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const errMsg = error.response.data.error.message;
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${errMsg ? errMsg : "มีบางอย่างผิดพลาด"}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  const onHandleRemove = (index: number) => {
    const data = [...images];
    data.splice(index, 1);
    setValue(keyName as any, data);
  };

  const onHandleChangeImage = async (e: any, fileIindex: number) => {
    const files = e.target.files[0];
    const data = {
      file: files,
      destination: "company",
      permission: "public",
    };
    const imgData = [...images];
    await AppInformationAPI.uploadAttachment(data)
      .then((response) => {
        imgData[fileIindex].file_name = response.file_name;
        imgData[fileIindex].url = response.public_url
          ? response.public_url
          : "";
        imgData[fileIindex].file_type = response.file_type;
        setValue(keyName as any, imgData);
      })
      .catch((err) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${err.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  const onHandleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const onHandleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onHandleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetIndex: number
  ) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData("text/plain"));
    const newImages = [...images];
    const [removedImage] = newImages.splice(sourceIndex, 1);
    newImages.splice(targetIndex, 0, removedImage);
    setValue(keyName, newImages);
  };

  return (
    <Stack
      direction="row"
      columnGap={4}
      rowGap={4}
      flexWrap="wrap"
      mr={[0, 10]}
    >
      {images.map((image, index) => (
        <Box
          key={index}
          position="relative"
          onDragStart={(e) => onHandleDragStart(e, index)}
          onDragOver={(e) => onHandleDragOver(e)}
          onDrop={(e) => onHandleDrop(e, index)}
          sx={
            disableIsActive && image.is_approve === "approved"
              ? classes.imageIsActiveBox
              : classes.imageIsNotActiveBox
          }
        >
          {disableIsActive && image.is_approve === "approved" ? (
            <Fab sx={classes.removeImgBtn}>
              <Icon className="ico-hu-check" sx={{ fontSize: "16px" }} />
            </Fab>
          ) : (
            <Fab
              onClick={() => onHandleRemove(index)}
              sx={classes.removeImgBtn}
            >
              <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
            </Fab>
          )}
          <Box display="flex" flexDirection="column">
            <Box component="label" sx={classes.imageDataList}>
              <img src={image.url} alt="item" />
              {image.is_approve !== "approved" && (
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  id="input-file"
                  onChange={(e) => onHandleChangeImage(e, index)}
                  style={{ display: "none" }}
                />
              )}
              {showImageIndex && (
                <Typography variant="body2" textAlign="center">
                  {`รูปที่ ${index + 1}`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      ))}

      <Box>
        <Box
          component="label"
          sx={{ ...classes.labelUpload, borderColor: errors ? "red" : "grey" }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb="-5px">
                <Icon
                  className="ico-hu-p-photo"
                  sx={{ fontSize: "38px", color: "#616161" }}
                />
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Icon
                  className="ico-hu-upload"
                  sx={{ fontSize: "10px", mt: "3px" }}
                />
                <span style={{ fontSize: "12px" }}>Image</span>
              </Stack>
              <input
                type="file"
                multiple
                accept="image/png, image/jpg, image/jpeg"
                id="input-file"
                onChange={onInputImage}
                style={{ display: "none" }}
              />
            </>
          )}
        </Box>

        {errors && (
          <Typography color="error" fontSize="12px" m="10px 0 0 11px">
            {errors?.message}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
