// mui
import { Box, Divider, Grid } from "@mui/material";
// components
import SocialPost from "@components/view-influencer-profile/influencer-social/social-default/SocialPost";
import InfluencerRateCard from "@components/view-influencer-profile/influencer-social/social-default/RateCard";
import SocialScreenshort from "@components/view-influencer-profile/influencer-social/social-default/ScreenShort";
import OverviewTimeline from "@components/view-influencer-profile/influencer-social/social-default/OverviewTimeline";
import InfluencerSocialHeader from "@components/view-influencer-profile/influencer-social/social-default/SocialHeader";
// style
import { useOtherPlatformStyle as classes } from "./SocialTab.style"

export default function OtherPlatform() {

  return (
    <div>
      <InfluencerSocialHeader status="view" />
      <Divider />
      <Grid container sx={classes.container} wrap="nowrap">
        <Grid item xs={12} sm={6} lg={5}>
          <Box display="flex" flexDirection="column" rowGap={["16px", "30px"]}>
            <OverviewTimeline />
            <InfluencerRateCard />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={7}>
          <Box display="flex" flexDirection="column" rowGap={["16px", "30px"]}>
            <SocialPost />
            <SocialScreenshort />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
