import { useMyJobsTabStyle } from "../../JobsTab.style";
import { useConfirmGoodsReceivedStyle } from "../job-working/JobWorking.style";

export const useJobApprovedStyle = {
  jobTabsCon: {
    bgcolor: {
      sm: "#fff",
    },
    borderRadius: {
      xs: "8px",
      sm: "0px 16px 16px 16px",
    },
    p: {
      sm: "24px",
    },
  },
  jobTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      "& p:first-of-type": { fontSize: "24px", fontWeight: "500" },
      "& p:last-of-type": {
        fontSize: "16px",
        color: "#5C5C5C",
      },
    },
  },
  shippingProBtn: {
    maxHeight: "36px",
    width: "191px",
    border: "none",
    bgcolor: "#F4ECFF",
    color: "#8C499C",
    "&:hover": {
      bgcolor: "#8C499C",
      color: "#fff",
      border: "none",
    },
    display: {
      xs: "none",
      sm: "flex",
    },
  },
  divider: {
    my: "24px",
    display: {
      xs: "none",
      sm: "flex",
    },
  },
};

export const useJobApprovedDatePostStyle = {
  datePostCon: useConfirmGoodsReceivedStyle.confirmCon,
  title: useConfirmGoodsReceivedStyle.title,
};

export const useJobApprovePostStyle = {
  postDetailCon: useConfirmGoodsReceivedStyle.confirmCon,
  title: useConfirmGoodsReceivedStyle.title,
  comment: {
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
    p: "12px",
    width: "100%",
    ml: "16px",
    minHeight: "50px",
  },
  imgItem: {
    height: {
      xs: "150px",
      sm: "240px",
    },
    width: {
      xs: "150px",
      sm: "240px",
    },
    borderRadius: "4px",
    cursor: "pointer",
  },
  captionBox: {
    mt: "20px",
    "& > div": {
      bgcolor: "#F5F6F8",
      borderRadius: "8px",
      p: "16px",
    },
  },
  draftVideo: {
    height: {
      xs: "150px",
      sm: "240px",
    },
    width: {
      xs: "150px",
      sm: "240px",
    },
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    objectFit: "cover",
    "& > .css-1637l5p": {
      borderRadius: "0px",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    maxWith: "350px",
  },
  urlBox: {
    height: {
      xs: "150px",
      sm: "240px",
    },
    width: {
      xs: "150px",
      sm: "240px",
    },
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "40px",
    },
  },
  urlImg: {
    "& > img": {
      width: {
        xs: "100px",
        sm: "120px",
      },
      height: {
        xs: "100px",
        sm: "120px",
      },
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const useProductReturnStyle = {
  productCon: useConfirmGoodsReceivedStyle.confirmCon,
  title: {
    ...useMyJobsTabStyle.jobTitle,
    p: {
      sm: "24px",
    },
    "& > div > p:first-of-type": {
      fontSize: "18px",
      fontWeight: "500",
    },
  },
  shippingProBtn: useMyJobsTabStyle.shippingProBtn,
  address: {
    bgcolor: "#F5F6F8",
    borderRadius: "8px",
    p: "16px",
    "& p:first-of-type": { color: "#8C499C", fontWeight: "500" },
    "& p:nth-of-type(2)": {
      mt: "10px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
      fontSize: "14px",
      mt: "5px",
    },
  },
  saveBtn: {
    display: "flex",
    justifyContent: "center",
    mt: "25px",
    "& button": { border: "none", width: ["100%", "240px"], maxHeight: "40px" },
    "& button:hover": { border: "none" },
  },
};

export const useJobPublishedLinkStyle = {
  publishedCon: {
    ...useConfirmGoodsReceivedStyle.confirmCon,
    p: {
      xs: "24px 24px 1px 24px",
      sm: 0,
    },
  },
  title: useConfirmGoodsReceivedStyle.title,
  platform: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      textTransform: "capitalize",
      ml: "10px",
    },
  },
  publishLink: {
    height: "40px",
    borderRadius: "50px",
    bgcolor: "#F4ECFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ml: "20px",
      width: {
        xs: "250px",
        sm: "350px",
      },
      cursor: "pointer",
    },
  },
  publishBtn: {
    display: "flex",
    justifyContent: "center",
    mb: "20px",
    "& button": {
      width: ["100%", "240px"],
      height: "40px",
      border: "none",
      fontSize: "15px",
    },
    "& button:hover": { border: "none" },
  },
  copyBtn: {
    width: "30px",
    minHeight: "20px",
    maxHeight: "30px",
    bgcolor: "#fff",
    mr: "10px",
  },
};
