import React, { useState } from "react";
// mui
import {
  Box,
  Menu,
  Grid,
  Icon,
  Button,
  useTheme,
  MenuItem,
  Typography,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
// style and icons
import { useFilterStyle as classes } from "../CampaignReport.style";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
// api and type
import { ReportAPI } from "@api/global";
import { ICampaignReportFilterLgProps } from "../campaignReport.type";
// others
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { useHistory, useParams } from "react-router-dom";
import TextFieldCustom from "@global/text-field";
import DatePicker from "react-datepicker";
import { hGrey } from "@theme/index";
import dayjs from "dayjs";

export default function CampaignReportFilterLg(
  props: ICampaignReportFilterLgProps
) {
  const { filterDate, toggleDrawer, publishedLink } = props;
  let date = new Date();

  const [anchorElFilterDate, setAnchorElFilterDate] = useState<null | HTMLElement>(null);
  const [anchorElDownload, setAnchorElDownload] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7) as any
  );
  const [endDate, setEndDate] = useState(date as any);
  const [filterDateSelected, setFilterDateSelected] = useState("weekly");

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.down("md"));

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;
  const isAdmin = role === "admin" || role === "employer";

  const history = useHistory();
  const { jobId, campaignId } = useParams<{
    jobId: string;
    campaignId: string;
  }>();

  const filterDateOpen = Boolean(anchorElFilterDate);
  const downloadOpen = Boolean(anchorElDownload);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    if (name === "filterDate") {
      setAnchorElFilterDate(event.currentTarget);
    } else {
      setAnchorElDownload(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorElFilterDate(null);
  };

  const handleDowloadReport = async () => {
    setAnchorElDownload(null);
    await ReportAPI.campaignPostReportAsCSV(campaignId).then((response) => {
      if (response !== null) {
        window.open(response.public_url, "_blank");
      }
    });
  };

  const onFilterDate = (
    filterType: string,
    type: string,
    start: any,
    end: any
  ) => {
    const chartType = {
      job_id: jobId,
      chart_type: type,
    };
    const filterSelf = {
      job_id: jobId,
      date: {
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD"),
      },
    };
    if (filterType === "chartType") {
      filterDate(chartType, "chartType");
    } else if (filterType === "more1Month") {
      filterDate(filterSelf, "more1Month");
    }
    setStartDate(start);
    setEndDate(end);
    setFilterDateSelected(type);
  };

  const onFilterDateSelf = (status: string, date: any) => {
    const data = {
      job_id: jobId,
      date: {
        start: dayjs(status === "start" ? date : startDate).format(
          "YYYY-MM-DD"
        ),
        end: dayjs(status === "end" ? date : endDate).format("YYYY-MM-DD"),
      },
    };
    setFilterDateSelected("filterSelf");
    filterDate(data, "filterSelf");
  };

  const filterDateList = [
    {
      label: "สัปดาห์นี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "weekly",
    },
    {
      label: "เดือนนี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "monthly",
    },
    {
      label: "2 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 2,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "2month",
    },
    {
      label: "3 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 3,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "3month",
    },
    {
      label: "กำหนดเอง",
      value: {
        start: "",
        end: "",
      },
      filterType: "filterSelf",
      type: "filterSelf",
    },
  ];

  return (
    <Box sx={role === "influencer" ? classes.reportInf : classes.reportEmp}>
      <div>
        <Typography variant="h5">
          {isAdmin ? "Project Summary Report" : "Campaign Report"}
        </Typography>
        <Typography variant="subTitle1" color={hGrey[900]}>
          รายงานแสดงข้อมูลของแคมเปญ
        </Typography>
      </div>
      <Box sx={classes.selectCon}>
        <Box sx={classes.selectBox}>
          <Button
            id="filter-date-button"
            aria-controls={filterDateOpen ? "filter-date-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={filterDateOpen ? "true" : undefined}
            onClick={
              screenSm
                ? toggleDrawer(true)
                : (e) => handleOpenMenu(e, "filterDate")
            }
            endIcon={<CalendarMonthIcon />}
          >
            From
          </Button>
          <Menu
            id="filter-date-menu"
            anchorEl={anchorElFilterDate}
            open={filterDateOpen}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "filter-date-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={classes.menuDate}
          >
            <Grid container spacing={5} mt={-2} sx={classes.menuDateGridCon}>
              <Grid item xs={2.4}>
                {filterDateList.map((item, index) => (
                  <MenuItem
                    sx={{
                      ...classes.menuItem,
                      bgcolor:
                        item.type === filterDateSelected ? "#EBDFFF" : "#fff",
                    }}
                    key={index}
                    onClick={() => {
                      if (item.filterType !== "filterSelf") {
                        onFilterDate(
                          item.filterType,
                          item.type,
                          item.value.start,
                          item.value.end
                        );
                      } else {
                        setFilterDateSelected(item.filterType);
                      }
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Grid>

              <Grid item xs={4.7}>
                <Typography variant="title1">วันที่เริ่มต้น :</Typography>
                <TextFieldCustom
                  fullWidth
                  value={dayjs(startDate).format("DD/MM/YYYY")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon sx={classes.calendarIcon} />
                      </InputAdornment>
                    ),
                  }}
                  sx={classes.txtFieldDate}
                />
                <DatePicker
                  calendarClassName="datepicker-calendar-container"
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                    onFilterDateSelf("start", date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  startDate={startDate}
                  endDate={endDate}
                  value={startDate}
                  selectsStart
                  inline
                />
              </Grid>
              <Grid item xs={4.7}>
                <Typography variant="title1">วันที่สิ้นสุด :</Typography>
                <TextFieldCustom
                  fullWidth
                  value={dayjs(endDate).format("DD/MM/YYYY")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon sx={classes.calendarIcon} />
                      </InputAdornment>
                    ),
                  }}
                  sx={classes.txtFieldDate}
                />
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  calendarClassName="datepicker-calendar-container"
                  onChange={(date: any) => {
                    setEndDate(date);
                    onFilterDateSelf("end", date);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  value={endDate}
                  inline
                />
              </Grid>
            </Grid>
          </Menu>
        </Box>
        <Box sx={classes.filterButtomBox}>
          {!isAdmin && (
            <Button
              onClick={() => history.push("/my-earning")}
              color="secondary"
              startIcon={<Icon className="ico-hu-money" />}
            >
              <Typography>My Earnings</Typography>
              <Typography>My Earnings</Typography>
            </Button>
          )}

          {!isAdmin ? (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Icon className="ico-hu-visability" />}
              onClick={() => window.open(publishedLink, "_blank")}
            >
              View Post
            </Button>
          ) : (
            <>
              <Button
                id="download-button"
                variant="contained"
                color="secondary"
                startIcon={<Icon className="ico-hu-download" />}
                aria-controls={downloadOpen ? "download-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={downloadOpen ? "true" : undefined}
                onClick={(e) => handleOpenMenu(e, "download")}
              >
                <Typography>Download Report</Typography>
                <Typography>Report</Typography>
              </Button>
              <Menu
                id="download-menu"
                anchorEl={anchorElDownload}
                open={downloadOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "download-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={classes.downloadBotton}
              >
                {["CSV (.csv)"].map((menu, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleDowloadReport}
                    sx={classes.downloadMenuItem}
                  >
                    {menu}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
