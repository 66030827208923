import { useEffect } from "react";
// mui, icon and style
import { Box, Link, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useInfluencerCampaignStyle as classes } from "./Campaign.style";
// component
import Header from "@components/influencer-dashboard/influencer-campaign/Header";
import MyJobsDetailTab from "@components/my-jobs/jobs-detail/detail-tab/DetailTab";
import ReportTab from "@components/influencer-dashboard/influencer-campaign/report-tab/ReportTab";
import TimelineTab from "@components/influencer-dashboard/influencer-campaign/timeline-tab/TimelineTab";
// redux
import {
  selectInfluencerJobInfo,
  getInfluencerJobInfoAsync,
  selectInfluencerJobInfoOnload,
} from "@slices/InfluencerJobInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import { scrollToTop } from "@utils/helpers/scrollToTop";
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";

const campaignTabs = [
  {
    key: "details",
    text: "Details",
  },
  {
    key: "timeline",
    text: "Job Timeline",
  },
  {
    key: "report",
    text: "Report",
  },
];

export default function InfluencerCampaign() {
  const { influencerId } = useParams<{ influencerId: string }>();

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const isLoading = useAppSelector(selectInfluencerJobInfoOnload);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "details";
  const jobId = params.get("id") || "";

  useEffect(() => {
    const req = {
      jobId: jobId,
      influencerId: influencerId,
    };
    dispatch(getInfluencerJobInfoAsync(req));
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, dispatch]);

  const handleTabChangeTab = (tab: string) => {
    history.replace(`/influencers/${influencerId}/jobs?id=${jobId}&tab=${tab}`);
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Link
        underline="none"
        onClick={() => history.goBack()}
        sx={classes.goBack}
      >
        <KeyboardArrowLeftIcon />
        <span>Back</span>
      </Link>

      <Header />

      <Box sx={classes.tabBox}>
        {campaignTabs.map((tab, index) => (
          <Box
            key={index}
            onClick={() => handleTabChangeTab(tab.key)}
            sx={tabSelected === tab.key ? classes.tabSelected : classes.tab}
          >
            <Typography
              textTransform="capitalize"
              fontWeight={tabSelected === tab.key ? 500 : 400}
            >
              {tab.text}
            </Typography>
          </Box>
        ))}
      </Box>

      {tabSelected === "details" ? (
        <MyJobsDetailTab role="influencer" jobInfo={jobInfo} />
      ) : tabSelected === "timeline" ? (
        <TimelineTab />
      ) : tabSelected === "report" ? (
        <ReportTab />
      ) : null}
    </div>
  );
}
