import { useState } from "react";
// mui
import { Box, Icon, Stack, Divider, Typography } from "@mui/material";
// type and style;
import { IJobWorkingDraftPostprops } from "../../../JobsTab.type";
import { useDraftImagesStyle as classes } from "../JobWorking.style";
// components
import MultiUrlInput from "../../../job-default/MultiUrlInput";
import MuitiInputVideo from "../../../job-default/MultiVideoInput";
// others
import ReactPlayer from "react-player";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";

export default function Videos(props: IJobWorkingDraftPostprops) {
  const { videos, form, isDraft, urls } = props;

  const {
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [viewDraftVideo, setViewDraftVideo] = useState(false);

  const isLenght = (length?: number) => {
    if (length && length > 0) return true;
    return false;
  };

  return (
    <Box sx={classes.imageCon}>
      <Box sx={classes.title}>
        <Typography>Video</Typography>
        <Typography>
          อัพโหลดวิดีโอการรีวิวสินค้า(ไฟล์วิดีโอไม่เกิน 8 mb)
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0], mr: ["24px", 0] }} />
      <Box m={["24px 0 24px 0", "24px"]}>
        {isDraft ? (
          <>
            {isLenght(videos?.length) && (
              <Box mt="24px">
                <Typography variant="title2">อัปโหลดจากคอมพิวเตอร์</Typography>
                <Stack direction="row" sx={classes.isDraftBox}>
                  {videos?.map((item, index) => (
                    <Box key={index}>
                      <Box
                        onClick={() => setViewDraftVideo(true)}
                        sx={classes.draftVideo}
                      >
                        <Box sx={classes.iconPlayVido}>
                          <Icon
                            sx={{
                              fontSize: "40px",
                              color: "#fff",
                            }}
                            className="ico-hu-play-circle-outline"
                          />
                        </Box>
                        <ReactPlayer
                          url={item.url}
                          width="100%"
                          height="100%"
                          loop={true}
                          muted={true}
                        />
                      </Box>
                      <Box display="flex" justifyContent="center" mt="12px">
                        <Typography variant="title2" textAlign="center">
                          {index + 1}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
            {isLenght(urls?.length) && (
              <Box mt="24px">
                <Typography variant="title2">
                  แนบลิงค์ URL หรือแหล่งที่มาของวิดีโอ
                </Typography>
                <Stack direction="row" sx={classes.isDraftBox}>
                  {urls?.map((url, index) => (
                    <Box
                      key={index}
                      onClick={() => window.open(url.url, "_blank")}
                    >
                      <Box sx={classes.urlBox}>
                        <Box sx={classes.urlIcon}>
                          <Icon className="ico-hu-conversion" />
                          <Typography variant="body2" mt="15px">
                            Video URL
                          </Typography>
                        </Box>
                        <Box sx={classes.urlImg}>
                          <img src={urlLogo} alt="item" />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="center" mt="12px">
                        <Typography variant="title2" textAlign="center">
                          {index + 1}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <>
            <div>
              <Box display="flex" flexDirection="column" mb="12px" mr="16px">
                <Typography variant="subTitle2">
                  การอัปโหลดวิดีโอ สามารถดำเนินการได้ 2 แบบดังนี้
                </Typography>
                <Typography variant="subTitle2" ml={3} mt={1}>
                  1.อัปโหลดจากคอมพิวเตอร์ ขนาดไฟล์ไม่เกิน 25 MB
                  และรองรับสกุลไฟล์ .mp4
                </Typography>
              </Box>
              <Box sx={classes.draftBox}>
                <MuitiInputVideo
                  keyName="attachments"
                  setValue={setValue}
                  videos={watch("attachments")}
                  errors={errors.attachments}
                  showVideoIndex
                />
              </Box>
            </div>
            <Box mt="20px">
              <Typography variant="subTitle2" ml={3}>
                2.แนบลิงค์ URL หรือแหล่งที่มาของวิดีโอ
              </Typography>
              <Box mt={[0, "12px"]} sx={classes.draftBox}>
                <MultiUrlInput
                  keyName="external_file"
                  setValue={setValue}
                  urls={watch("external_file") || []}
                  errors={errors.attachments}
                  showUrlIndex
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <ViewMultiVideoDialog
        open={viewDraftVideo}
        onClose={setViewDraftVideo}
        videos={videos}
      />
    </Box>
  );
}
