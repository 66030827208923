import { useEffect, useRef, useState } from "react";
// mui
import { Box, Avatar, Typography } from "@mui/material";
// type and other
import { IImageListProps } from "./CampaignDetail.type";
import { useImageListStyle as classes } from "./CampaignDetail.style";
import ViewMultiImageDialog from "../view-image-dialog/ViewMultiImageDialog";

export default function ImageList(props: IImageListProps) {
  const { images, mb } = props;

  const [imgHeight, setImgHeight] = useState(0);
  const [viewImg, setViewImg] = useState(false);
  const [initImgIndex, setInitImgIndex] = useState(0);
  const imgList = images || [];

  const imgRef = useRef<HTMLDivElement>(null);
  const imgLength = imgList.length;

  useEffect(() => {
    const imgWidth = imgRef.current?.clientWidth;
    setImgHeight(imgWidth ? imgWidth : 0);
  }, []);

  const handleViewImage = (imgIndex: number) => {
    setInitImgIndex(imgIndex);
    setViewImg(true);
  };

  return (
    <>
      {/* for xs screem */}
      <Box display={["flex", "none"]} sx={classes.imageListBox} mb={mb}>
        {imgList
          .slice(
            0,
            imgLength === 1 || imgLength === 3 ? 1 : imgLength === 4 ? 4 : 3
          )
          .map((img, index) => (
            <Box
              component="img"
              ref={imgRef}
              key={index}
              onClick={() => handleViewImage(index)}
              src={img.url}
              sx={{
                ...classes.imageList,
                maxHeight: { xs: `${imgHeight}px`, sm: "150px" },
                minHeight: { xs: `${imgHeight}px`, sm: "150px" },
                borderRadius:
                  imgLength === 1
                    ? "8px"
                    : imgLength === 2
                    ? index === 0
                      ? "8px 0 0 8px"
                      : "0 8px 8px 0"
                    : imgLength > 3
                    ? index === 0
                      ? "8px 0 0 0"
                      : index === 1
                      ? "0 8px 0 0"
                      : index === 2
                      ? "0 0 0 8px"
                      : "0 0 8px 0"
                    : "8px 0 0 8px",
              }}
            />
          ))}
        {((imgLength !== 1 && imgLength !== 2 && imgLength !== 4) ||
          imgLength > 4) && (
          <Box
            height={{ xs: `${imgHeight}px`, sm: "150px" }}
            sx={{
              ...classes.imageMoreBox,
              borderRadius:
                imgLength === 1 || imgLength === 3
                  ? "0 8px 8px 0"
                  : "0 0 8px 0",
            }}
          >
            <Avatar
              className="imgItem4"
              src={imgList && imgList?.[imgList.length - 1]?.url}
            />
            <Box
              sx={classes.breifImgMoreThane4}
              onClick={() => setViewImg(true)}
            >
              <Typography>
                +{" "}
                {imgLength - imgLength === 1 || imgLength === 3
                  ? 1
                  : imgLength - 4}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* for sm screen */}
      <Box display={["none", "flex", "none"]} sx={classes.imageListBox} mb={mb}>
        {imgList.slice(0, imgLength === 2 ? 2 : 1).map((img, index) => (
          <Box
            component="img"
            key={index}
            onClick={() => handleViewImage(index)}
            src={img.url}
            sx={{
              ...classes.imageList,
              borderRadius:
                imgLength > 1
                  ? index === 0
                    ? "8px 0 0 8px"
                    : index === imgLength
                    ? "0 8px 8px 0"
                    : "0 8px 8px 0"
                  : "8px",
            }}
          />
        ))}
        {imgLength !== 2 && imgLength > 1 && (
          <Box
            height={{ xs: `${imgHeight}px`, sm: "150px" }}
            sx={{
              ...classes.imageMoreBox,
              borderRadius: imgLength > 1 ? "0 8px 8px 0" : "8px",
            }}
          >
            <Avatar
              className="imgItem4"
              src={imgList && imgList?.[imgList.length - 1]?.url}
            />
            <Box
              sx={classes.breifImgMoreThane4}
              onClick={() => setViewImg(true)}
            >
              <Typography>+ {imgLength - 2}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* for md screen */}
      <Box
        display={["none", "none", "flex", "flex", "none"]}
        sx={classes.imageListBox}
        mb={mb}
      >
        {imgList.slice(0, imgLength === 3 ? 3 : 2).map((img, index) => (
          <Box
            component="img"
            key={index}
            onClick={() => handleViewImage(index)}
            src={img.url}
            sx={{
              ...classes.imageList,
              borderRadius:
                imgLength === 3
                  ? index === 0
                    ? "8px 0 0 8px"
                    : index === imgLength - 1
                    ? "0 8px 8px 0"
                    : "0"
                  : imgLength > 3
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0"
                  : imgLength === 2
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0 8px 8px 0"
                  : "8px",
            }}
          />
        ))}
        {imgLength > 3 && (
          <Box
            height={{ xs: `${imgHeight}px`, sm: "150px" }}
            sx={{
              ...classes.imageMoreBox,
              borderRadius: imgLength > 1 ? "0 8px 8px 0" : "8px",
            }}
          >
            <Avatar
              className="imgItem4"
              src={imgList && imgList?.[imgList.length - 1]?.url}
            />
            <Box
              sx={classes.breifImgMoreThane4}
              onClick={() => setViewImg(true)}
            >
              <Typography>+ {imgLength - 3}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* for lg screen */}
      <Box
        display={{ xs: "none", xl: "flex" }}
        sx={classes.imageListBox}
        mb={mb}
      >
        {imgList.slice(0, imgLength === 4 ? 4 : 3).map((img, index) => (
          <Box
            component="img"
            key={index}
            onClick={() => handleViewImage(index)}
            src={img.url}
            sx={{
              ...classes.imageList,
              borderRadius:
                imgLength === 3 || imgLength === 4
                  ? index === 0
                    ? "8px 0 0 8px"
                    : index === imgLength - 1
                    ? "0 8px 8px 0"
                    : "0"
                  : imgLength > 3
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0"
                  : imgLength === 2
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0 8px 8px 0"
                  : "8px",
            }}
          />
        ))}
        {imgLength > 4 && (
          <Box
            height={{ xs: `${imgHeight}px`, sm: "150px" }}
            sx={{
              ...classes.imageMoreBox,
              borderRadius: imgLength > 1 ? "0 8px 8px 0" : "8px",
            }}
          >
            <Avatar
              className="imgItem4"
              src={imgList && imgList?.[imgList.length - 1]?.url}
            />
            <Box
              sx={classes.breifImgMoreThane4}
              onClick={() => setViewImg(true)}
            >
              <Typography>+ {imgLength - 4}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      <ViewMultiImageDialog
        open={viewImg}
        onClose={setViewImg}
        images={imgList}
        initImgIndex={initImgIndex}
      />
    </>
  );
}
