// mui
import { Box, Icon, Typography } from "@mui/material";
// style and type
import {
  MenusBox,
  IconsBox,
  useItemSidebarStyle as classes,
} from "./Sidebar.styles";
import { IMenuProps } from "./Sidebar.types";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";

export default function ItemSideBar(props: IMenuProps) {
  const { menus, handleOpenMenu } = props;

  const employerInfo = useAppSelector(selectEmployerInfo)
  const teamRole = employerInfo?.team_role;
  
  const pathname = window.location.pathname;

  return (
    <Box sx={classes.menuItems}>
      {menus &&
        menus.map(({ title, icon, path }, index) => {
          if (title === "Campaign" && teamRole?.permission === "financial") return null
          return (
            <MenusBox
              key={index}
              sx={{
                fontWeight: pathname === path ? "bold" : "normal",
              }}
            >
              <Box sx={classes.iconBox}>
                <IconsBox
                  onClick={() => handleOpenMenu(index)}
                  sx={{
                    bgcolor: pathname === path ? "primary.main" : "#F5F6F8",
                  }}
                >
                  <Box
                    sx={{
                      ...classes.icon,
                      color: pathname === path ? "#FFFFFF" : "#5C5C5C",
                    }}
                  >
                    <Icon className={icon} fontSize="inherit" />
                  </Box>
                </IconsBox>
                <Typography
                  fontSize="14px"
                  fontWeight={pathname === path ? 500 : 300}
                >
                  {title}
                </Typography>
              </Box>
            </MenusBox>
          )
        })}
    </Box>
  );
}
