import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  Stack,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import { hAlertColor } from "../../theme";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import BasicSelectCustom from "./../../components/global/basic-select/index";
import MultipleSelectCustom from "./../../components/global/multiple-select/index";
import TextFieldCustom from "./../../components/global/text-field/index";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import PaginationCustom from "../../components/global/pagination-custom";
import SwalCustom from "../../components/global/sweet-alert-custom/SwalCustom";

function Global() {
  const [selectVal, setSelectVal] = useState("");
  const [multipleVal, setMultipleSelectVal] = useState<any[]>([]);

  const selectId = "select-custom-1";
  const multipleId = "multiple-custom-1";
  const data = [
    {
      id: 1,
      value: 1,
      label: "Test 1",
    },
    {
      id: 2,
      value: 2,
      label: "Test 2",
      icon: <AccountCircleIcon />,
    },
    {
      id: 3,
      value: 3,
      label: "Test 3",
    },
    {
      id: 4,
      value: 4,
      label: "Test 4",
    },
  ];

  const handleChangeSelect = (e: any) => {
    setSelectVal(e.target.value);
  };

  // Pagination and Row per page
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageData = [10, 25, 50, 100];
  const totalPage = 10;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleChangeRowsPerPage = (event: SelectChangeEvent<any>) => {
    setRowsPerPage(event.target.value as number);
    if (page !== 1) setPage(1);
  };

  // SweetAlert
  const SwalAlert = (type: string) => {
    switch (type) {
      case "success":
        SwalCustom.fire({
          icon: "success",
          title: "Success !",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          confirmButtonColor: hAlertColor.success,
          confirmButtonText: "Close",
        });
        break;
      case "error":
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Seems like somthing went wrong !",
          confirmButtonColor: hAlertColor.error,
          confirmButtonText: "Close",
        });
        break;
      case "warning":
        SwalCustom.fire({
          icon: "warning",
          title: "Warning !",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          confirmButtonColor: hAlertColor.warning,
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        });
        break;
      case "info":
        SwalCustom.fire({
          icon: "info",
          title: "Notification !",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          confirmButtonColor: hAlertColor.info,
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        });
        break;
      case "question":
        SwalCustom.fire({
          icon: "question",
          title: "Question ?",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          confirmButtonColor: hAlertColor.question,
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        });
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="container-main">
        <Stack>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Basic Select</Box>
            <Box width={300}>
              <BasicSelectCustom
                id={selectId}
                value={selectVal}
                onChange={handleChangeSelect}
                placeholder={"Placeholder"}
                data={data}
                valueKey="value"
                labelKey="label"
                iconKey="icon"
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Basic Select (Disable)</Box>
            <Box width={300}>
              <BasicSelectCustom
                id={selectId}
                value={1}
                onChange={handleChangeSelect}
                placeholder={"Placeholder"}
                data={data}
                valueKey="value"
                labelKey="label"
                iconKey="icon"
                fullWidth
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Multiple Select</Box>
            <Box width={300}>
              <MultipleSelectCustom
                id={multipleId}
                value={multipleVal}
                setValue={setMultipleSelectVal}
                placeholder={"Placeholder"}
                data={data}
                fullWidth
                valueKey="value"
                labelKey="label"
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Multiple Select (Disable)</Box>
            <Box width={300}>
              <MultipleSelectCustom
                id={multipleId}
                value={[1, 2]}
                setValue={setMultipleSelectVal}
                placeholder={"Placeholder"}
                data={data}
                fullWidth
                disabled
                valueKey="value"
                labelKey="label"
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Text Field</Box>
            <Box width={300}>
              <TextFieldCustom
                variant="outlined"
                placeholder="Placeholder"
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Text Field (Disable)</Box>
            <Box width={300}>
              <TextFieldCustom
                variant="outlined"
                placeholder="Placeholder"
                fullWidth
                disabled
                value={"Test Text Field Disabled"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Textarea</Box>
            <Box width={300}>
              <TextFieldCustom
                variant="outlined"
                multiline
                // rows={3}
                minRows={3}
                placeholder="Placeholder"
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Textarea (Disable)</Box>
            <Box width={300}>
              <TextFieldCustom
                variant="outlined"
                multiline
                rows={3}
                placeholder="Placeholder"
                fullWidth
                disabled
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>
              Pagination and <br /> Row per Page
            </Box>
            <Box>
              <PaginationCustom
                count={totalPage}
                page={page}
                onPaginationChange={handleChange}
                rowsPerPageData={rowsPerPageData}
                rowsPerPage={rowsPerPage}
                onRowPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Sweet Alert</Box>
            <Box display="flex" gap={2}>
              <Button
                onClick={() => SwalAlert("success")}
                color="success"
                sx={{ width: 100 }}
              >
                Success
              </Button>
              <Button
                onClick={() => SwalAlert("error")}
                color="error"
                sx={{ width: 100 }}
              >
                Error
              </Button>
              <Button
                onClick={() => SwalAlert("warning")}
                color="warning"
                sx={{ width: 100 }}
              >
                Warning
              </Button>
              <Button
                onClick={() => SwalAlert("info")}
                color="info"
                sx={{ width: 100 }}
              >
                Info
              </Button>
              <Button
                onClick={() => SwalAlert("question")}
                color="primary"
                sx={{ width: 100 }}
              >
                Question
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box width={200}>Drag and Drop</Box>
            <Box display="flex" gap={2} width={500}></Box>
          </Box>
        </Stack>
      </div>
    </React.Fragment>
  );
}

export default Global;
