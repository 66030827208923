import { useEmployerReviewPostStyle } from "../employer-review-post/ReviewPost.style";

export const useEmployerDraftApprovalStyle = {
  goBack: useEmployerReviewPostStyle.goBack,
  draftCon: useEmployerReviewPostStyle.reviewPostCon,
  reportBtn: {
    display: "flex",
    justifyContent: "center",
    "& > button": {
      width: "200px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
  submitDraftBtn: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    width: "100%",
    "& button": {
      color: "#fff",
      border: "none",
      "&:hover": { border: "none" },
      width: "240px",
      maxHeight: "36px",
    },
  },
};
