import { hWhite } from "@theme/index";
import { styled, Tab, TabProps } from "@mui/material";

import {
  useTabJobBoardStyle,
  useFilterJobBoardStyle,
} from "../job-board/JobBoard.style";

export const useCampaignStyle = {
  container: {
    ml: ["-100px", 0],
    mr: ["-10px", 0],
  },
  campaignTab: {
    minHeight: ["65px", "34px", "44px"],
    maxHeight: ["65px", "34px", "44px"],
    borderRadius: ["8px", "18px 12px 0 0"],
    margin: ["15px 0 20px 0", "15px 0 3px 0"],
    "& .css-i4bv87-MuiSvgIcon-root, .css-bpy6lo > .MuiTab-iconWrapper, .css-wvkgta > .MuiTab-iconWrapper, .css-vubbuv":
      {
        display: ["flex", "none"],
        width: "20px",
        height: "20px",
      },
    "& .css-m5f4gq-MuiTypography-root": {
      fontSize: ["12px", "14px"],
    },
    "& .css-heg063-MuiTabs-flexContainer": {
      justifyContent: ["space-between", "start"],
      height: ["70px", 0],
      ml: [0, "-10px"],
    },
    "& .css-16fhvdp .css-k008qs, .css-k008qs": {
      justifyContent: ["space-between", "start"],
    },
    display: "flex",
    justifyContent: "space-between",
  },
};

export const useMyJobsTabsStyle = {
  ...useTabJobBoardStyle,
};

export const useMyJobsTabsItemLgStyle = {
  ...useTabJobBoardStyle,
  tabs: {
    "& .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .MuiTabs-scrollButtons": {
      display: "none",
    },
  },
};

export const useMyJobstabsItemSmStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
    bgcolor: "#fff",
    borderRadius: "8px",
    padding: "8px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  selectJobTabStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    ml: "8px",
  },
  divider: {
    mx: "15px",
    height: "25px",
    mt: "7px",
  },
  selectJobTabTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100px",
    mr: "8px",
  },
  dialogCon: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "12px",
    },
  },
  dialogMenu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    borderRadius: "5px",
    "&:hover": {
      bgcolor: "#F4ECFF",
    },
  },
  dialogMenuCanceled: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    borderRadius: "5px",
    "&:hover": {
      bgcolor: "#F4ECFF",
    },
  },
};

export const useMyJobsFilterStyle = {
  ...useFilterJobBoardStyle,
};

export const useMyJobsDetailTabsStyle = {
  myJobsTab: useCampaignStyle.campaignTab,
};

export const MyjobsTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hWhite[100],
  borderRadius: "8px",
  minHeight: "40px",
  maxHeight: "40px",
  minWidth: "50px",
  maxWidth: "100%",
  fontSize: "26px",
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  "& > .MuiTab-iconWrapper": {
    marginBottom: 0,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: "#fff",
    boxShadow: "initial",
    minWidth: "70px",
    maxWidth: "100%",
    width: "23%",
    maxHeight: "58px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "16%",
    borderRadius: "20px 20px 0px 0px",
    minWidth: "132px",
    maxWidth: "132px",
    minHeight: "34px",
    maxHeight: "34px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  },
  [theme.breakpoints.up("md")]: {
    width: ["20%", "20%", "19.52%"],
    minWidth: "160px",
    maxWidth: "160px",
    minHeight: "44px",
    maxHeight: "44px",
    fontSize: "16px",
  },
}));
