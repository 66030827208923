import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Eight() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>8. Amendment of Privacy Policy</Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>
        The Company reserves the right to amend or change the Privacy Policy
        (including Revised Edition) in accordance with the rules, regulations,
        announcements, or laws that will occur in the future. The Company will
        announce any changes to this Privacy Policy on the HashU Platform
        website. If there is a material change on how to take care of that
        personal information, the Company will remind on the main the HashU
        Platform, the date of the Privacy Policy was last updated it will be
        displayed at the top of the page of the HashU Platform. The Company
        would like to remind you that for your benefit, you will be required to
        monitor the HashU Platform regularly to be aware of any changes that
        might happen, including the policy (if any), and you have to make sure
        (as the duty) the emails provided on the HashU Platform are up to
        date and can be used to send and receive messages as usual.
      </Typography>
    </Box>
  );
}
