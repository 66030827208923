// mui
import {
  Box,
  Button,
  Dialog,
  Avatar,
  Typography,
  DialogContent,
} from "@mui/material";
// type and style
import { IRegistCampaignSuccessDialogProps } from "../JobBoard.type";
import { useRegistCampaignSuccessDialogStyle as classes } from "../JobBoard.style";
// other
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export default function RegistCampaignSuccessDialog(
  props: IRegistCampaignSuccessDialogProps
) {
  const { open, onClose, data } = props;

  const isPrivate = data.campaign_type === "private";
  const history = useHistory();

  const handleClose = () => {
    onClose(false);
    history.replace(`/influencers/jobs/${data.job_id}/info`);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent>
        <Box sx={classes.content}>
          {isPrivate ? (
            <Avatar
              src={require("@assets/images/infographic/influencer/job-board/private-accept-invitation.png")}
            />
          ) : (
            <Avatar
              src={require("@assets/images/infographic/influencer/job-board/public-regist-campaign.png")}
            />
          )}
          <Box sx={classes.contentTxt}>
            {isPrivate ? (
              <>
                <Typography variant="h5">คุณตอบรับข้อเสนอของเรา</Typography>
                <Typography>
                  รอการยืนยันการเริ่มแคมเปญจากผู้จ้างงานภายในวันที่{" "}
                  {dayjs(data.publish_date).format("DD/MM/YYYY")}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5">สมัครเข้าร่วมแคมเปญสำเร็จ</Typography>
                <Typography>
                  ผลการคัดเลือกจะประกาศภายในวันที่{" "}
                  {dayjs(data.publish_date).format("DD/MM/YYYY")}
                </Typography>
              </>
            )}
          </Box>
          <Box sx={classes.applyButton}>
            <Button onClick={handleClose}>ปิด</Button>
            <Button
              variant="contained"
              onClick={() => history.push("/job-board")}
            >
              สมัครแคมเปญอื่นๆ
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
