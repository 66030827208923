// mui and style
import { Box, Button, Grid, Typography } from "@mui/material";
import { useCampaignInfluencerListStyle as classes } from "../InfluencerList.style";
// icon and type
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { IBeforSelectProps } from "../InfluencerList.type"
// other
import dayjs from "dayjs";

export default function BeforSelected(props: IBeforSelectProps) {
  const { handleOpenSelectInfluDialog, candidateInfluencer } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>ประกาศรับสมัคร</Typography>
          <Typography variant="h5" color={"#893DBD"}>
            {dayjs(candidateInfluencer?.summary?.start_recruiting_date).format(
              "DD/MM/YYYY"
            )}{" "}
            -{" "}
            {dayjs(candidateInfluencer?.summary?.end_recruiting_date).format(
              "DD/MM/YYYY"
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>ประกาศผลการคัดเลือกภายในวันที่</Typography>
          <Typography variant="h5" color={"#893DBD"}>
            {dayjs(candidateInfluencer?.summary?.announce_date).format(
              "DD/MM/YYYY"
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนที่สมัคร</Typography>
          <Typography variant="h5" color={"#893DBD"}>
            {candidateInfluencer?.total_item}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนที่ต้องการ</Typography>
          <Typography variant="h5" color={"#893DBD"}>
            {candidateInfluencer?.summary?.job_limit}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <Button
          sx={classes.selectInflueButton}
          onClick={handleOpenSelectInfluDialog}
          fullWidth
          disabled={candidateInfluencer?.items.length === 0 ? true : false}
        >
          <PersonAddAltIcon />
          <Typography>ต้องการเลือกอินฟลูเอนเซอร์</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
