export const useMyJobDetailTabStyle = {
  jobDetailCon: {
    bgcolor: { xs: "inherit", lg: "#fff" },
    padding: { xs: "0px", lg: "24px" },
  },
  jobDetailCard: {
    border: { xs: "inherit", lg: "2px solid #EBDFFF" },
    borderRadius: "12px",
    padding: "24px",
    bgcolor: { xs: "#fff", lg: "inherit" },
    boxShadow: { xs: "0px 2px 8px rgba(0, 0, 0, 0.1)", lg: "inherit" },
  },
  divider: {
    my: "24px",
    mx: {
      xs: 0,
      lg: "-24px",
    },
    bgcolor: "#EBDFFF",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    mt: "30px",
    width: "100%",
    "& button": {
      width: "170px",
      minHeight: "40px",
      bgcolor: "#EBDFFF",
      border: "2px solid #EBDFFF",
      "& :hover": { border: "none" },
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  timelineTitleBox: {
    display: { xs: "none", lg: "inherit" },
    flexDirection: "column",
    alignItems: "center",
  },
  timelineTitle: {
    display: "flex",
    alignItems: "center",
    height: "20%",
    "& > svg": {
      cursor: "pointer",
      color: "#5C5C5C",
    },
  },
  jobDetailGridCon: {
    "& .budgetAndReward": {
      borderRadius: {
        xs: "12px",
        md: "0 0 0 12px",
        lg: "12px",
      },
      zIndex: 5,
    },
    "& .timeline": {
      borderRadius: {
        xs: "12px",
        sm: "0 12px 12px 12px",
        md: "0 12px 12px 0",
        lg: "12px",
      },
      ml: {
        xs: 0,
        md: "-20px",
        lg: 0,
      },
      boxShadow: {
        md: "6px 2px 8px rgba(0, 0, 0, 0.1)",
        lg: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      },
      zIndex: 4,
    },
  },
  jobDetailsForSmBox: {
    height: {
      xs: "100%",
      md: "initial",
    },
    display: { xs: "initial", lg: "none" },
  },
  budgetAndTimeline: {
    height: {
      xs: "100%",
      md: "91.5%",
      lg: "88%",
    },
    border: { xs: "inherit", lg: "2px solid #EBDFFF" },
    borderRadius: "12px",
    padding: "24px",
    bgcolor: { xs: "#fff", lg: "inherit" },
    boxShadow: { xs: "0px 2px 8px rgba(0, 0, 0, 0.1)", lg: "inherit" },
    flexDirection: "column",
  },
};
