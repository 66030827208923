import { useState, useEffect } from "react";
// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from "@mui/material";
import { useTutorialStyle as classes } from "./Tutorial.style";
// api and util
import { InfluencerAPI } from "@api/global";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// redux
import {
  setInfluencerProfile,
  getInfluencerProfileAsync,
  setInfluencerRegisterStatus,
  selectInfluencerRegisterStatus,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";

export default function Tutorial() {
  const [activeStep, setActiveStep] = useState(4);

  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id;
  const registerStatus = useAppSelector(selectInfluencerRegisterStatus);

  useEffect(() => {
    dispatch(getInfluencerProfileAsync({ influencerId }));
  }, [dispatch, influencerId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const tutorialList = [
    {
      tutorial_img: "assets/images/infographic/influencer/tutorial/welcome.png",
      title: "เปิดบ้าน HashU พร้อมกิจกรรมใหม่",
      description:
        "คุณสามารถเข้าร่วมกิจกรรมนี้ได้ เพียงดำเนินการ 3 ขั้นตอนดังนี้",
    },
    {
      tutorial_img: "assets/images/infographic/influencer/tutorial/welcome.png",
      title: "Step 1 : Edit Your Profile",
      description: "แก้ไขโปรไฟล์ของตัวเอง ใส่รูปภาพและตรวจสอบข้อมูลให้ครบถ้วน",
    },
    {
      tutorial_img: "assets/images/infographic/influencer/tutorial/welcome.png",
      title: "Step 2 : Sync Social Media",
      description:
        "เชื่อมต่อ Social Media ช่องทางใดก็ได้ 1 บัญชี ไม่ว่าจะเป็น Facebook Instagram TikTok หรือ Twitter",
    },
    {
      tutorial_img: "assets/images/infographic/influencer/tutorial/welcome.png",
      title: "Step 3 : My Referral",
      description:
        "เข้าหน้า My Referral นำรหัสอ้างอิงที่ได้รับไปแชร์ให้เพื่อนคนอื่นๆ ได้เลย",
    },
    {
      tutorial_img: "assets/images/infographic/influencer/tutorial/welcome.png",
      title: "ยินดีต้อนรับเข้าสู่ HashU",
      description:
        "HashU คอมมูนิตี้สุดคูลของเหล่าอินฟลูเอนเซอร์สุดเจ๋งที่เราจะใช้พลังแห่งการบอกต่อมาเขย่าวงการไปด้วยกัน",
    },
  ];

  const onExploreHashU = async () => {
    await InfluencerAPI.updateInfluencerRegistStatus()
      .then((response) => {
        dispatch(setInfluencerProfile(response));
      })
      .finally(() => {
        dispatch(setInfluencerRegisterStatus(true));
      });
  };

  return (
    <Dialog open={!registerStatus} sx={classes.dialog}>
      <DialogContent>
        <Box>
          <Box sx={classes.image}>
            <img
              src={require(`../../${tutorialList[activeStep].tutorial_img}`)}
              alt="tutorial"
            />
          </Box>
          <Typography variant="h5" my="8px" textAlign="center">
            {tutorialList[activeStep].title}
          </Typography>
          <Typography color="#505050" my="8px" textAlign="center">
            {tutorialList[activeStep].description}
          </Typography>
        </Box>
        {/* <MobileStepper
          variant="dots"
          steps={4}
          position="static"
          activeStep={activeStep}
          sx={classes.mobileStepper}
          backButton={undefined}
          nextButton={undefined}
        /> */}
        <Stack direction="row" spacing={2} sx={classes.backNetxbtn}>
          {/* {activeStep > 0 && (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )} */}
          <Button
            onClick={activeStep === 4 ? () => onExploreHashU() : handleNext}
            variant="contained"
          >
            {activeStep === 4 ? "Explore HashU" : "Next"}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
