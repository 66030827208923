import React, { useEffect, useState } from "react";
// mui and icons
import {
  Box,
  Grid,
  Chip,
  Radio,
  Button,
  Drawer,
  Avatar,
  Divider,
  FormGroup,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// style and type
import { useCampaignFilterStyle as classes } from "../Detail.style";
import { ICampaignParams } from "@views/campaign/Campaign.type";
// constant
import {
  campaignTypeList,
  filterStatusList,
  sortDateBudgetList,
} from "@views/campaign/Campaign.constants";
import { platformList } from "@views/job-board/JobBoard.constants";
// redux
import { useAppSelector } from "@store/hook";
import { selectCategoryList } from "@slices/CategoryList.slice";
// other
import { useHistory, useLocation, useParams } from "react-router-dom";

export default function CampaignTabFilter(props: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const { open, setOpen } = props;

  const [filterList, setFilterList] = useState<ICampaignParams>();

  const categoryList = useAppSelector(selectCategoryList);

  const { employerId } = useParams<{ employerId: string }>();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const qStatus = params.get("status") || "";
  const qSearch = params.get("q") || "";
  const qOrderBy = params.get("order_by") || "date";
  const qSort = params.get("sort") || "DESC";
  const qPlatform = params.get("platform") || "";
  const qType = params.get("type") || "";
  const qPage = Number(params.get("page")) || 1;
  const qCategories = params.get("cate");
  const categories = qCategories ? qCategories.split(",").map(Number) : [];

  useEffect(() => {
    setFilterList((prev) => {
      return {
        ...prev,
        page: qPage,
        q: qSearch,
        status: qStatus,
        sort: qSort,
        order_by: qOrderBy,
        platform: qPlatform,
        type: qType,
        cate: categories,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qPage, qSearch, qStatus, qSort, qOrderBy, qPlatform, qType, qCategories]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" 
      || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleFilterCampaign = (data: ICampaignParams) => {
    setFilterList((prev) => {
      return { ...prev, ...data };
    });
  };

  const handleSelectCategory = (value: number) => {
    if (!filterList) return;
    const categories = [...categoryList.map((cat) => cat.id)];
    if (categories.includes(value)) {
      if (filterList.cate?.includes(value)) {
        setFilterList((prev) => {
          return {
            ...prev,
            cate: (filterList.cate || []).filter((cat) => cat !== value),
          };
        });
      } else {
        setFilterList((prev) => {
          return {
            ...prev,
            cate: [
              ...(prev?.cate || []),
              ...categories.filter((cat) => cat === value),
            ],
          };
        });
      }
    } else {
      setFilterList((prev) => {
        return { ...prev, cate: [] };
      });
    }
  };

  const onApplyFilterCampaign = () => {
    if (!filterList) return;
    const queryString = Object.keys(filterList)
      .map((key) => `${key}=${filterList[key as keyof ICampaignParams]}`)
      .join("&");

    history.replace(
      `/employers/${employerId}/info?tab=campaign&${queryString}`
    );
    setOpen(false);
  };

  const onClearFilterCampaign = () => {
    history.replace(`/employers/${employerId}/info?tab=campaign`);
    setOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={classes.drawer}
    >
      <Box sx={classes.drawerCon}>
        <Box sx={classes.filterTitle}>
          <Typography variant="h6" color="primary" ml={3}>
            Filter
          </Typography>
          <ClearOutlinedIcon
            onClick={() => setOpen(false)}
            sx={{ mr: "5px" }}
          />
        </Box>

        {/* Filter Sort */}
        <Box sx={classes.titleItem} mt="64px">
          <Typography>Sort by</Typography>
          <FormControl>
            <RadioGroup sx={{ minHeight: "90px" }}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  {sortDateBudgetList.date.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilterCampaign({
                              order_by: "date",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={7}>
                  {sortDateBudgetList.budget.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilterCampaign({
                              order_by: "budget",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>

        <Divider sx={classes.line} />

        {/* Filter Status */}
        <Box sx={classes.titleItem}>
          <Typography>Status</Typography>
          <Grid container>
            {filterStatusList.map((status, index) => (
              <Grid item xs={6} key={index}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={() => handleFilterCampaign({ status: status.value })}
                        checked={status.value === filterList?.status}
                      />
                    }
                    label={status.label}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={classes.line} />

        {/* Filter Type */}
        <Box sx={classes.titleItem}>
          <Typography>Campaign Type</Typography>
          <div>
            {campaignTypeList.map((item, index) => (
              <Chip
                key={index}
                label={item.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.type === item.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.type === item.key ? "#F4ECFF" : "#fff",
                  color: filterList?.type === item.key ? "#893DBD" : "#545454",
                  minWidth: "60px",
                }}
                onClick={() => {
                  handleFilterCampaign({ type: item.key });
                }}
              />
            ))}
          </div>
        </Box>

        <Divider sx={classes.line} />

        {/* Filter Platform */}
        <Box sx={classes.titleItem}>
          <Typography>Platform</Typography>
          <div>
            <Chip
              label="All"
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  filterList?.platform === "" ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor: filterList?.platform === "" ? "#F4ECFF" : "#fff",
                minWidth: "60px",
                color: filterList?.platform === "" ? "#893DBD" : "#545454",
              }}
              onClick={() => {
                handleFilterCampaign({ platform: "" });
              }}
            />
            {platformList.map((item, index) => (
              <Chip
                key={index}
                avatar={<Avatar src={item.url} />}
                label={item.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.platform === item.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor:
                    filterList?.platform === item.key ? "#F4ECFF" : "#fff",
                  color:
                    filterList?.platform === item.key ? "#893DBD" : "#545454",
                }}
                onClick={() => {
                  handleFilterCampaign({ platform: item.key });
                }}
              />
            ))}
          </div>
        </Box>

        <Divider sx={classes.line} />

        <Box sx={{ ...classes.titleItem, marginBottom: "50px" }}>
          <Typography>Category</Typography>
          <div>
            <Chip
              label="All"
              onClick={() => handleSelectCategory(0)}
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  (filterList?.cate || []).length <= 0 ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor:
                  (filterList?.cate || []).length <= 0 ? "#F4ECFF" : "#fff",
                color:
                  (filterList?.cate || []).length <= 0 ? "#893DBD" : "#545454",
              }}
            />
            {categoryList.map((category) => (
              <Chip
                key={category.id}
                label={category.name_en}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.cate?.includes(category.id)
                      ? "#F4ECFF"
                      : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.cate?.includes(category.id)
                    ? "#F4ECFF"
                    : "#fff",
                  color: filterList?.cate?.includes(category.id)
                    ? "#893DBD"
                    : "#545454",
                }}
                onClick={() => handleSelectCategory(category.id)}
              />
            ))}
          </div>
        </Box>
        <Box sx={classes.filterBottom}>
          <Typography onClick={onClearFilterCampaign} ml={3}>
            Clear All
          </Typography>
          <Button onClick={onApplyFilterCampaign} sx={classes.buttonApply}>
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
