import { useState } from "react";
// mui
import { Box, Icon, Typography } from "@mui/material";
// style and types
import { useReviewPostInfluencerStyle as classes } from "../../EmployerJob.style";
import { IInfluencerSocialPostProps } from "../../EmployerJob.type";
// others
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";
import { FacebookImageFormat } from "@global/image-format/ImageFormat";
import { VideoFormat } from "@global/video-format/ViedoFormat";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import Linkify from "react-linkify";
import nl2br from "react-nl2br";

export default function InfluencerSocialPost(
  props: IInfluencerSocialPostProps
) {
  const { jobInfo, watch, attachments } = props;
  const [openImage, setOpenImage] = useState(false);

  const isVideoType = jobInfo?.content_type.type === "video";
  const urls = jobInfo?.external_file || [];
  const attachment = (watch && watch("attachments")) || attachments || jobInfo?.attachments || [];

  return (
    <Box sx={classes.influPostCon}>
      <Box sx={classes.postProfile}>
        <img
          src={jobInfo?.social_account?.profile_image_url}
          alt="social profile"
        />
        <Typography ml="5px">
          {jobInfo?.social_account?.name}
        </Typography>
      </Box>
      <div>
        <Linkify>
          <Typography>
            {nl2br(
              watch
                ? watch("description.description") || ""
                : jobInfo?.description?.description
            )}
          </Typography>
        </Linkify>
      </div>
      {attachment.length ? (
        <Box onClick={() => setOpenImage(true)} sx={{ cursor: "pointer" }}>
          {isVideoType ? (
            <VideoFormat item={[attachment[0]]} height="380px" />
          ) : (
            <FacebookImageFormat item={attachment || []} height="380px" />
          )}
        </Box>
      ) : urls.length ? (
        <Box
          sx={classes.urlBox}
          onClick={() => window.open(urls[0].url, "_blank")}
        >
          <Box sx={classes.urlIcon}>
            <Icon className="ico-hu-conversion" />
            <Typography mt="15px">Video URL</Typography>
          </Box>
          <Box sx={classes.urlImg}>
            <img src={urlLogo} alt="item" />
          </Box>
        </Box>
      ) : null}

      {isVideoType ? (
        <ViewMultiVideoDialog
          open={openImage}
          onClose={setOpenImage}
          videos={jobInfo?.attachments}
        />
      ) : (
        <ViewMultiImageDialog
          open={openImage}
          onClose={setOpenImage}
          images={jobInfo?.attachments}
        />
      )}
    </Box>
  );
}
