import { useState } from "react";
// mui and style
import { Box, Button, Divider, Icon, Typography } from "@mui/material";
import { useJobWorkingStyle as classes } from "./JobWorking.style";
// type and components
import { IMyJobTabProps } from "../../JobsTab.type";
import JobDraftPost from "./job-draft-post/JobDraftPost";
import JobReceiptProduct from "./job-receipt-product/JobReceiptProduct";
import ShippingProductDialog from "../../job-dialog/ShippingProductDialog";

export default function JobWorking(props: IMyJobTabProps) {
  const { isProductReturn, isConfirmDelivery, jobsInfo } = props;
  const [openShippingProduct, setOpenShippingProduct] = useState(false);

  return (
    <Box sx={classes.jobTabsCon}>
      <Box sx={classes.jobTitle}>
        <Box display={{ xs: "none", sm: "initial" }}>
          <Typography>Job</Typography>
          <Typography>กรอกข้อมูลเพื่อส่งงาน</Typography>
        </Box>
        {isProductReturn && (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-product-received" />}
            sx={classes.shippingProBtn}
            onClick={() => setOpenShippingProduct(true)}
          >
            Shipping Product
          </Button>
        )}
      </Box>
      <Divider sx={classes.divider} />
      {!isConfirmDelivery && isProductReturn ? (
        <JobReceiptProduct />
      ) : (
        <JobDraftPost />
      )}
      <ShippingProductDialog
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
        jobInfluencerDetail={jobsInfo}
      />
    </Box>
  );
}
