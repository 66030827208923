import React, { useEffect, useState } from "react";
// mui and icons
import {
  Box,
  Grid,
  Paper,
  Button,
  useTheme,
  InputBase,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// style and component
import { useCampaignTabStyle as classes } from "../Detail.style";
import CampaignCard from "@global/campaign-card/CampaignCard";
import CampaignCardFilter from "./CampaignTabFilter";
// api and type
import { EmployerAPI } from "@api/global";
import { IEmployerCampaignParams } from "@api/employer/type";
import { ICampaignParams } from "@views/campaign/Campaign.type";
import { IEmployerCampaignsRes as ICampaignList } from "@api/job/type";
import { ICampaignTabProps } from "@views/employer/employer-detail/Detail.type";
// others
import Loading from "@global/loading/Loading";
import PaginationCustom from "@global/pagination-custom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function CampaignTab(props: ICampaignTabProps) {
  const { employerInfo } = props;

  const history = useHistory();
  const { employerId } = useParams<{ employerId: string }>();
  const employerID = employerId || employerInfo?.employer_full_id || "";

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const qSearch = params.get("q") || "";
  const qPage = Number(params.get("page")) || 1;
  const qOrderBy = params.get("order_by") || "date";
  const qSort = params.get("sort") || "DESC";
  const qPlatform = params.get("platform") || "";
  const qType = params.get("type") || "";
  const qStatus = params.get("status") || "";
  const qCategories = params.get("cate");
  const categories = qCategories ? qCategories.split(",").map(Number) : [];
  const filterTotal = (qStatus ? 1 : 0) + (qSearch ? 1 : 0) + (qPlatform ? 1 : 0) + (qType ? 1 : 0) + (categories.length ? 1 : 0) + (`${qOrderBy}${qSort}` !== "dateDESC" ? 1 : 0);

  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down("xl"));

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role

  const [isLoading, setIsLoading] = useState(false);
  const [campaignList, setCampaignList] = useState<ICampaignList | null>(null);
  const [openCampaignFilter, setOpenCampaignFilter] = useState(false);
  const [rowsPerPageData, setRowsPerPageData] = useState([4, 8, 12]);
  const [rowsPerPage, setRowsPerPage] = useState(screen ? 8 : 12);
  const [search, setSearch] = useState(qSearch);

  let queryParams: ICampaignParams = {
    page: qPage,
    q: qSearch,
    sort: qSort,
    order_by: qOrderBy,
    type: qType,
    platform: qPlatform,
    status: qStatus,
    cate: categories
  };

  useEffect(() => {
    setRowsPerPage(screen ? 8 : 10);
    setRowsPerPageData([screen ? 4 : 5, screen ? 8 : 12, screen ? 12 : 15]);
  }, [screen]);

  useEffect(() => {
    let isMounted = true;

    const employerCampaign = (
      employerId: string,
      params: IEmployerCampaignParams
    ) => {
      setIsLoading(true);

      EmployerAPI.getEmployerCamapign(employerId, params)
        .then((response) => {
          if (isMounted) {
            setCampaignList(response);
            setIsLoading(false);
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    };

    let params: IEmployerCampaignParams = {
      item_per_page: rowsPerPage,
      page: qPage,
      total_item: 10,
      search: qSearch,
      sort: qSort,
      order_by: qOrderBy,
      campaign_type: qType,
      platform: qPlatform,
      status: qStatus,
      category: categories,
    };
    employerCampaign(employerID, params);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerID, rowsPerPage, qPage, qSearch, qSort, qOrderBy, qType, qPlatform, qStatus]);

  const handleChangePage = (_e: unknown, pageValue: number) => {
    history.replace(
      `/employers/${employerID}/info?tab=campaign&q=${qSearch}&page=${pageValue}`
    );
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value.trim());

    if (value.trim() !== "") return;
    const param = {...queryParams}
    param["page"] = 1

    const queryString = Object.keys(param)
    .map((key) => `${key}=${param[key as keyof ICampaignParams]}`)
    .join("&");
    history.replace(`/employers/${employerID}/info?tab=campaign&${queryString}`);
  };

  const onSearch = () => {
    const param = {...queryParams}
    param["q"] = search
    param["page"] = 1
    
    const queryString = Object.keys(param)
    .map((key) => `${key}=${param[key as keyof ICampaignParams]}`)
    .join("&");
    history.replace(`/employers/${employerID}/info?tab=campaign&${queryString}`);
  };

  const goCreateCampaign = () => {
    if (role === "employer") {
      history.push(`/campaigns/create`);
    } else {
      history.push(`/campaigns/create?emp_id=${employerId}`);
    }
  };

  const goCampaignDetail = (employerId: string, campaignId: string) => {
    if (role === "employer") {
      history.push(`/campaigns/${campaignId}/info`);
    } else {
      history.push(`/campaigns/${campaignId}/info?emp_id=${employerId}`);
    }
  };

  if (isLoading) return <Loading />

  return (
    <>
      <Box sx={classes.container}>
        <Box sx={classes.campTabTop}>
          <div className="d-flex">
            <Paper sx={classes.search}>
              <InputBase
                sx={{ ml: 4, flex: 1 }}
                placeholder="Search by campaign name, company, campaign id"
                inputProps={{ "aria-label": "search name" }}
                value={search}
                onChange={handleSearchChange}
              />
              <IconButton
                onClick={onSearch}
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              onClick={() => setOpenCampaignFilter(true)}
              sx={classes.filter}
              startIcon={<FilterAltIcon />}
              color="primary"
            >
              <Typography whiteSpace="nowrap">
                Filter {filterTotal || ""}
            </Typography>
            </Button>
          </div>
          <Box sx={classes.addCampButtom}>
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={goCreateCampaign}
            >
              New Campaign
            </Button>
          </Box>
        </Box>
        {campaignList?.item.length ? (
          <Grid container spacing={5} mt="5px" mb="25px">
            {campaignList?.item.map((item, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2.4}
                sx={classes.campLists}
              >
                <Box
                  width="100%"
                  sx={{ cursor: "pointer" }}
                  onClick={() => goCampaignDetail(employerId, item.id)}
                >
                  <CampaignCard
                    isCampaign={true}
                    name={item.name}
                    status={item.status}
                    limit={item.job_limit}
                    category={item.category}
                    platform={item.platform}
                    jobCount={item.job_count}
                    avgBudget={item.avg_budget}
                    coverUrl={item.cover_image?.url}
                    contentType={item.content_type}
                    companyUrl={item.company?.media?.url}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={classes.cmapaignNoData}>
            <img
              src={require("@assets/images/infographic/admin/influencer-selected/waiting-influencer.png")}
              alt="no campaign"
            />
            <Box sx={classes.textNoCampaign}>
              <Typography variant="h5" my="8px">
                กำลังรับสมัครผู้เข้าร่วมแคมเปญ...
              </Typography>
              <Typography>
                ขณะนี้อยู่ในขั้นตอนการรับสมัครอินฟลูเอนเซอร์เข้าร่วมแคมเปญ
              </Typography>
            </Box>
          </Box>
        )}
        {campaignList && campaignList.item.length > 0 && (
          <Box sx={classes.pagination}>
            <PaginationCustom
              page={qPage}
              count={
                qPage > 0
                  ? Math.ceil((campaignList?.total_item || 0) / rowsPerPage)
                  : 0
              }
              onPaginationChange={handleChangePage}
              rowsPerPageData={rowsPerPageData}
              rowsPerPage={rowsPerPage}
              onRowPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Box>

      <CampaignCardFilter
        open={openCampaignFilter}
        setOpen={setOpenCampaignFilter}
      />
    </>
  );
}
