import { useEffect, useState } from "react";
// mui and component
import { Box, Grid, Icon, Typography } from "@mui/material";
import Billing from "@components/subscription/payment/Billing";
import EditBilling from "@components/global/edit-billing/EditBilling";
import PaymentMethod from "@components/subscription/payment/PaymentMethod";
import PaymentSummary from "@components/subscription/payment/PaymentSummary";
import PaymentResultDialog from "@components/subscription/subscription-dialog/PaymentResult";
// redux
import {
  selectPackageSelected,
  getWalletBalanceAsync,
  selectPackageListOnload,
} from "@slices/PackageInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { getPaymentMethodAsync } from "@slices/PaymentMethod.slice";
// api and type
import { PaymentAPI } from "@api/global";
import { IPaymentResult } from "@components/subscription/Subscription.type";
// other
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function SubscriptionPaymant() {
  const [addOn, setAddOn] = useState(0);
  const [prepay, setPrepay] = useState(1);
  const [editBilling, setEditBilling] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentResult, setPaymentResult] = useState<IPaymentResult>();

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const plan = params.get("plan");
  const orderId = params.get("order_id");

  const revStringToNum = (number?: string) => {
    return Number(number?.replaceAll(",", "") || 0);
  };

  const packageSelected = useAppSelector(selectPackageSelected)
  const isLoading = useAppSelector(selectPackageListOnload);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const packagePlan = employerInfo?.package_plan || plan;
  const isPersonal = packagePlan === "personal";
  // package
  const teamPackage = employerInfo?.team?.package;
  const personalPackage = employerInfo?.package;
  const packageList = isPersonal ? personalPackage : teamPackage;
  // billing
  const personalBilling = employerInfo?.address.billing_address;
  const teamBilling = employerInfo?.team?.billing_address;
  const billing = isPersonal ? personalBilling : teamBilling;
  // price
  const price = isPersonal ? packageList?.fees.price : packageList?.fees.price_team;
  // grand total
  const grandTotal = (revStringToNum(packageSelected?.price) + addOn * 740) * prepay;

  useEffect(() => {
    dispatch(getPaymentMethodAsync());
    dispatch(
      getWalletBalanceAsync({
        isPersonal: isPersonal,
        employer_id: session_token?.employer_id,
        team_id: employerInfo?.team?.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, session_token?.employer_id]);

  useEffect(() => {
    if (!orderId) return;
    PaymentAPI.getPaymentResult(orderId).then((response) => {
      setPaymentResult(response);
      setPaymentSuccess(true);
    });
  }, [orderId]);

  const handleEditBilling = (value: any) => {
    console.log("value", value);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <Box
        onClick={() => history.push("/subscription")}
        display="inline-flex"
        alignItems="center"
        sx={{ cursor: "pointer" }}
      >
        <Icon className="ico-hu-chevron-left" fontSize="small" />
        <Typography variant="buttonS" ml={1}>
          Back
        </Typography>
      </Box>
      <Box my="24px">
        <Typography variant="h5">Subscription Payment</Typography>
        <Typography variant="subTitle1">ชำระค่าสมัครสมาชิก</Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <PaymentSummary
            prepay={prepay}
            setPrepay={setPrepay}
            addOn={addOn}
            setAddOn={setAddOn}
            isPersonal={isPersonal}
            packageList={packageList}
            price={price}
            grandTotal={grandTotal}
          />
        </Grid>
        <Grid item xs={8} display="flex" flexDirection="column" rowGap={6}>
          <Billing openEditBilling={setEditBilling} />
          <PaymentMethod grandTotal={grandTotal} prepay={prepay} />
        </Grid>
      </Grid>

      {/* dialog */}
      <PaymentResultDialog
        open={paymentSuccess}
        onClose={setPaymentSuccess}
        data={paymentResult}
        redirect="/subscription/payment"
      />
      <EditBilling
        open={editBilling}
        onClose={() => setEditBilling(false)}
        onEditBilling={handleEditBilling}
        billing={billing}
      />
    </div>
  );
}
