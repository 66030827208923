import { styled, Tab, TabProps, Tabs } from "@mui/material";
import { hWhite } from "@theme/index";

export const useBannerStyle = {
  conatiner: {
    width: {
      xs: "113%",
      sm: "100%",
    },
    borderRadius: {
      sm: "12px",
      md: "24px",
    },
    height: {
      xs: "302px",
      md: "480px",
    },
    bgcolor: "rgba(140, 73, 156, 0.5)",
    position: "relative",
    m: {
      xs: "70px -30px 0 -21px",
      sm: "100px 0 0 0",
      lg: "initial",
    },
  },
  goBackBtn: {
    m: "24px",
    width: "32px",
    height: "32px",
    bgcolor: "#fff",
    "& > .MuiSvgIcon-root": {
      fontSize: "30px",
      color: "#000",
    },
    "&:hover": {
      bgcolor: "#fff",
    },
  },
  countDownBox: {
    position: "absolute",
    right: 0,
    width: "100%",
    bottom: 0,
    display: "flex",
    justifyContent: {
      xs: "center",
      md: "end",
    },
  },
  countDown: {
    width: {
      xs: "100%",
      sm: "500px",
    },
    borderRadius: "12px",
    bgcolor: "#fff",
    height: "86px",
    p: "12px",
    justifyContent: "space-between",
    boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.05)",
    m: {
      xs: "0 12px -55px 12px",
      md: "32px",
    },
  },
  timeLeftBox: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const useEventsCardStyle = {
  card: {
    position: "relative",
    "& > img": {
      objectFit: "cover",
      height: "280px",
      width: "100%",
      borderRadius: {
        xs: "12px",
        md: "12px 0px 0px 12px",
      },
    },
  },
  cardEventBtn: {
    position: "absolute",
    top: "20px",
    left: "20px",
    bgcolor: "#ED1968",
    borderRadius: "8px",
    p: "8px 16px 8px 16px",
    color: "#fff",
    opacity: 0.5,
  },
  descBox: {
    p: "20px",
    height: "240px",
    "& > p": {
      minHeight: "75px",
      display: "-webkit-box",
      maxWidth: "100%",
      height: "72px",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: " ellipsis",
      color: "#505050",
    },
  },
  dateAndLocation: {
    display: "flex",
    color: "#616161",
    alignItems: "center",
  },
  viewBox: {
    display: { xs: "none", md: "initial" },
    height: "100%",
    "& > div": {
      display: "flex",
      alignItems: "center",
      height: "23%",
    },
  },
};

export const useEventDetailTabsStyle = {
  container: {
    ml: ["-100px", 0],
    mr: ["-10px", 0],
  },
  eventTabsSm: {
    display: {
      xs: "flex",
      sm: "none",
    },
    justifyContent: "center",
    width: "100%",
    mt: "-15px",
  },
  eventTabLg: {
    minHeight: "44px",
    maxHeight: "44px",
    borderRadius: "18px 12px 0 0",
    margin: "15px 0 3px 0",
    "& .css-i4bv87-MuiSvgIcon-root, .css-bpy6lo > .MuiTab-iconWrapper, .css-wvkgta > .MuiTab-iconWrapper, .css-vubbuv":
      {
        width: "20px",
        height: "20px",
      },
    "& .css-m5f4gq-MuiTypography-root": {
      fontSize: ["12px", "14px"],
    },
    display: "flex",
    justifyContent: "space-between",
  },
};

export const EventTabsSm = styled(Tabs)({
  "&.MuiTabs-root": {
    borderRadius: "30px",
    width: "98%",
    minHeight: "40px",
    maxHeight: "40px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
});

export const EventTabSm = styled(Tab)({
  backgroundColor: "#fff",
  color: "#8C499C",
  width: "200px",
  minHeight: "40px",
  maxHeight: "40px",
  borderRadius: "30px",
  margin: "0px -20px 0px",
  "&.Mui-selected": {
    backgroundColor: "#8C499C",
    zIndex: "1",
    color: "#fff",
  },
});

export const EventTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hWhite[100],
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  minHeight: "44px",
  maxHeight: "44px",
  minWidth: "160px",
  maxWidth: "160px",
  fontSize: "26px",
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  "& > .MuiTab-iconWrapper": {
    marginBottom: 0,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: "#fff",
    minWidth: "160px",
    maxWidth: "160px",
    minHeight: "44px",
    maxHeight: "44px",
    borderRadius: "20px 20px 0px 0px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  },
  [theme.breakpoints.up("md")]: {
    width: ["20%", "20%", "19.52%"],
    minWidth: "160px",
    maxWidth: "160px",
    minHeight: "44px",
    maxHeight: "44px",
    fontSize: "16px",
  },
}));

export const useEventDetailTabStyle = {
  conatiner: {
    bgcolor: "#fff",
    p: {
      xs: "20px",
      sm: "24px",
      md: "32px",
      lg: "60px",
    },
    borderRadius: {
      xs: 0,
      sm: "0 20px 20px 20px",
    },
    m: {
      xs: "-5px -20px 0 -20px",
      sm: "-5px 0 0 0",
    },
  },
  rewardBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > .MuiAvatar-root": {
      borderRadius: "12px",
    },
  },
  secondRewardImg: {
    width: { xs: "100%", md: "280px" },
    maxWidth: {
      xs: "320px",
      sm: "280px",
    },
    height: {
      xs: "320px",
      sm: "280px",
    },
  },
  thirdRewardImg: {
    width: { xs: "100%", md: "280px" },
    maxWidth: {
      xs: "320px",
      sm: "280px",
    },
    height: {
      xs: "320px",
      sm: "280px",
    },
  },
  fourthRewardImg: {
    width: { xs: "100%", md: "240px" },
    maxWidth: {
      xs: "320px",
      sm: "240px",
    },
    height: {
      xs: "320px",
      sm: "240px",
    },
  },
};

export const useEventLeaderboardtabStyle = {
  container: {
    background: "linear-gradient(180deg, #FFFFFF 0%, #EBDFFF 100%)",
    p: {
      xs: "20px",
      md: "60px",
    },
    borderRadius: {
      sm: "0 20px 20px 20px",
    },
    m: {
      xs: "-5px -20px -20px -21px",
      sm: "-4px 0 0 0",
    },
  },
  billboardTitleBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "& > span:first-of-type": {
      fontSize: ["36px", "60px"],
      fontStyle: "italic",
      fontWeight: 600,
      color: "#542485",
      textShadow: "2px 2px 18px rgba(84,36,133,0.5)",
      WebkitTextStroke: "1px #fff",
    },
    "& > span:last-of-type": {
      fontSize: ["36px", "60px"],
      fontStyle: "italic",
      fontWeight: 600,
      color: "#542485",
      textShadow: "2px 2px 18px rgba(84,36,133,0.5)",
      WebkitTextStroke: "1px #fff",
    },
    "& > .MuiSvgIcon-root": {
      fontSize: ["45px", "60px"],
      color: "#FB8C00",
      transform: "rotate(5deg)",
      ml: "12px",
    },
  },
};

export const useLeaderboardPodiumStyle = {
  rankingPodium: {
    display: "flex",
    mt: {
      xs: "150px",
      sm: "300px",
      md: "325px",
    },
    mb: "35px",
    "& .total": {
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "80%",
      height: {
        xs: "32px",
        sm: "56px",
      },
      mx: "auto",
      mt: "5px",
      mb: {
        xs: "12px",
        sm: "27px",
      },
    },
    "& .cube-face1": {
      position: "absolute",
      width: {
        xs: "100px",
        sm: "175px",
        md: "200px",
      },
      height: {
        xs: "140px",
        sm: "340px",
        md: "380px",
      },
      lineHeight: "180px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .cube-face2": {
      position: "absolute",
      width: {
        xs: "100px",
        sm: "175px",
        md: "200px",
      },
      height: {
        xs: "120px",
        sm: "290px",
        md: "330px",
      },
      lineHeight: "160px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .cube-face3": {
      position: "absolute",
      width: {
        xs: "100px",
        sm: "175px",
        md: "200px",
      },
      height: {
        xs: "100px",
        sm: "260px",
        md: "300px",
      },
      lineHeight: "140px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .cube-face4": {
      position: "absolute",
      width: "200px",
      height: "250px",
      lineHeight: "120px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .cube-face5": {
      position: "absolute",
      width: "200px",
      height: "225px",
      lineHeight: "100px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .cube-face1-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#FB8C00",
      borderLeft: "1px solid #fff",
      borderRight: "1px solid #fff",
      bottom: 0,
    },
    "& .cube-face1-top": {
      transform: {
        xs: "rotateX(73deg) rotateY(0deg) translateZ(126px) rotateZ(1deg)",
        sm: "rotateX(48deg) rotateY(0deg) translateZ(133px) rotateZ(1deg)",
        md: "rotateX(45deg) rotateY(0deg) translateZ(133px) rotateZ(1deg)",
      },
      width: {
        xs: "93px",
        sm: "146px",
        md: "166.5px",
      },
      ml: {
        xs: "5px",
        sm: "16px",
        md: "18px",
      },
      mt: {
        xs: "35.8px",
        sm: "-187px",
        md: "-228.5px",
      },
      bgcolor: "#FB8C00",
      opacity: 0.6,
      borderLeft: "1px solid #fff",
      height: "200px",
    },
    "& .cube-face2-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#542485",
      borderLeft: "1px solid #fff",
      bottom: 0,
    },
    "& .cube-face2-top": {
      transform: {
        xs: "rotateX(75deg) translateZ(117px) rotateZ(10deg) rotateY(-1.8deg)",
        sm: "rotateX(52deg) translateZ(117px) rotateZ(10deg) rotateY(-1.8deg)",
        md: "rotateX(48deg) translateZ(117px) rotateZ(10deg) rotateY(-1.8deg)",
      },
      width: {
        xs: "100px",
        sm: "200px",
      },
      ml: {
        xs: "19px",
        sm: "25px",
      },
      bgcolor: "#542485",
      opacity: 0.6,
      top: {
        xs: "50px",
        sm: "-153px",
        md: "-197px",
      },
      height: "200px",
    },
    "& .cube-face3-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#ED1968",
      borderRight: "1px solid #fff",
      borderLeft: "1px solid #fff",
      height: {
        xs: "100px",
        sm: "250px",
        md: "290px",
      },
      bottom: 0,
    },
    "& .cube-face3-top": {
      transform: {
        xs: "rotateX(78deg) translateZ(105px)  rotateZ(-7.2deg) rotateY(2deg)",
        sm: "rotateX(58deg) translateZ(105px)  rotateZ(-10.5deg) rotateY(2deg)",
        md: "rotateX(50deg) translateZ(105px)  rotateZ(-10.5deg) rotateY(2deg)",
      },
      ml: {
        xs: "-9.8px",
        sm: "-8.1px",
        md: "-5.5px",
      },
      bgcolor: "#ED1968",
      borderLeft: "1px solid #fff",
      opacity: 0.6,
      top: {
        xs: "63px",
        sm: "-117px",
        md: "-170px",
      },
      width: {
        xs: "96px",
        sm: "161px",
        md: "180px",
      },
      height: "200px",
    },
    "& .cube-face4-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#893DBD",
      zIndex: 1,
      height: "257px",
      bottom: 0,
      width: "200px",
      right: 0,
    },
    "& .cube-face4-top": {
      transform:
        "rotateX(55deg) translateZ(132.3px) rotateZ(10deg) rotateY(-1.5deg)",
      width: "200px",
      ml: "26px",
      bgcolor: "#893DBD",
      opacity: 0.6,
      top: "-100px",
      height: "200px",
    },
    "& .cube-face5-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#4A489D",
      zIndex: -1,
      height: "225px",
      bottom: 0,
      borderLeft: "1px solid #fff",
    },
    "& .cube-face5-top": {
      transform:
        "rotateX(58deg) translateZ(113px) rotateZ(-12deg) rotateY(2deg)",
      width: "176px",
      ml: "-7px",
      bgcolor: "#4A489D",
      opacity: 0.6,
      top: "-100px",
      borderLeft: "1px solid #fff",
    },
    justifyContent: "center",
    alignItems: "center",
  },
  scene: {
    perspective: "390px",
  },
  cube: {
    width: {
      xs: "100px",
      sm: "175px",
      md: "200px",
    },
    height: "180px",
    position: " relative",
    transformStyle: "preserve-3d",
    transform: "translateZ(-100px)",
  },
  podiumDetail4: {
    position: "absolute",
    top: -350,
    left: 50,
  },
  podiumDetail2: {
    position: "absolute",
    top: {
      xs: -100,
      sm: -395,
      md: -475,
    },
    left: {
      xs: 30,
      sm: 35,
    },
  },
  podiumDetail1: {
    position: "absolute",
    top: {
      xs: -150,
      sm: -510,
      md: -585,
    },
    left: 5,
  },
  podiumDetail3: {
    position: "absolute",
    top: {
      xs: -70,
      sm: -340,
      md: -430,
    },
    left: 15,
  },
  podiumDetail5: {
    position: "absolute",
    top: -305,
    left: 15,
  },
  pepleRanking4: {
    border: "1.5px solid #893DBD",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pepleRanking2: {
    border: "1.5px solid #542485",
    borderRadius: "50%",
    width: {
      xs: "64px",
      sm: "130px",
      md: "150px",
    },
    height: {
      xs: "64px",
      sm: "130px",
      md: "150px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pepleRanking1: {
    border: "1.5px solid #FB8C00",
    borderRadius: "50%",
    width: {
      xs: "88px",
      sm: "180px",
      md: "200px",
    },
    height: {
      xs: "88px",
      sm: "180px",
      md: "200px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > img": {
      position: "absolute",
      top: -10,
      right: {
        xs: 5,
        sm: 20,
      },
      width: { xs: "25px", sm: "45px" },
      height: { xs: "25px", sm: "45px" },
      zIndex: 10,
      transform: "rotate(30deg)",
    },
    "& > .MuiAvatar-root": {
      width: {
        xs: "80px",
        sm: "172px",
        md: "192px",
      },
      height: {
        xs: "80px",
        sm: "172px",
        md: "192px",
      },
    },
  },
  pepleRanking3: {
    border: "1.5px solid #ED1968",
    borderRadius: "50%",
    width: {
      xs: "56px",
      sm: "130px",
      md: "150px",
    },
    height: {
      xs: "56px",
      sm: "130px",
      md: "150px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: { xs: "48px", sm: "122px", md: "142px" },
      height: { xs: "48px", sm: "122px", md: "142px" },
    },
  },
  pepleRanking5: {
    border: "1.5px solid #4A489D",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  numberRaking: {
    bgcolor: "#fff",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: ["14px", "32px"],
  },
};

export const useLeaderboardCardStyle = {
  card: {
    borderRadius: "12px",
    p: "20px",
  },
  profileBox: {
    display: "flex",
    justifyContent: "center",
    "& > img": {
      width: {
        xs: "80px",
        md: "120px",
      },
      height: {
        xs: "80px",
        md: "120px",
      },
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  ratingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
};
