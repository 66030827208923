import React from "react";
// mui
import { Box, Avatar, Divider, Typography } from "@mui/material";
// icon and style
import CircleIcon from "@mui/icons-material/Circle";
import { useInfluencerInfoStyles as classes } from "./InfluencerInfo.style";
// redux and util
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";

export default function Header() {
  const profileInfo = useAppSelector(selectInfluencerProfile);

  const profileImage = profileInfo?.media.images.find(
    (img) => img.purpose === "profile_image"
  );

  return (
    <Box sx={classes.container}>
      <Box sx={classes.leftCard}>
        <Avatar alt="Employer" src={profileImage?.url} sx={classes.avatar} />
        <Box sx={classes.leftCardBoxContent}>
          <Box>
            <Typography variant="h6">{profileInfo?.fullname}</Typography>
            <Box sx={classes.category}>
              {[
                `ID : ${profileInfo?.influencer_id}`,
                `Age : ${profileInfo?.age}`,
                profileInfo?.gender,
                profileInfo?.nationality,
              ].map((item, index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="body2"
                    color="#616161"
                    textTransform="capitalize"
                  >
                    {item}
                  </Typography>
                  <CircleIcon />
                </React.Fragment>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box display="flex" columnGap="30px">
            {[
              { label: "Registered", value: "22/03/2023" },
              { label: "Email", value: profileInfo?.email },
              {
                label: "Phone",
                value: formatPhoneNumber(profileInfo?.phone || "0"),
              },
            ].map((item, index) => (
              <Box
                key={index}
                maxWidth="33.3%"
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  whiteSpace="nowrap"
                  mr={1}
                >
                  {item.label} :
                </Typography>
                <Typography
                  variant="body2"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={classes.rightCard}>
        <Typography variant="h6" m="20px 0 0 20px">
          สถานะผู้ใช้งาน
        </Typography>
        <Box m="10px 20px 20px 20px">
          <Box display="flex" alignItems="center">
            <Typography variant="title2" width="100px">
              สถานะ
            </Typography>
            <Typography variant="title2" sx={classes.activeStatus}>
              ปกติ
            </Typography>
          </Box>
          <Box display="flex" mt="10px">
            <Typography variant="title2" width="100px">
              หมายเหตุ
            </Typography>
            <Typography variant="title2">-</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
