import { instance } from "../global/instance";
import { API_KEY, PAYMENT_URL } from "@utils/constants/api.constants";
import { IChillpayPublisherBody, IHoldingAndChargeBody, ITransHistoryParams } from "./type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class PaymentAPIs {
  async getWalletMiddleWare(body: {
    campaign_id: string;
    employer_id: string;
  }) {
    const { access_token } = getTokenFromStorage();
    
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`${PAYMENT_URL}/payment/campaign/wallet`, body, { headers });
    return data;
  };

  async chillpayPublisher(body: IChillpayPublisherBody) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`${PAYMENT_URL}/payment/publisher`, body, { headers });
    return data;
  };

  async getPaymentMethod(groupId?: number) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "X-Api-Key": API_KEY,
    };
    const { data } = await instance.get(`${PAYMENT_URL}/payment/method`, { headers, params: groupId });
    return data;
  };

  async charge(body: IHoldingAndChargeBody) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.post(`${PAYMENT_URL}/payment/charge`, body, { headers });
    return data;
  };

  async getTransactionList(params: ITransHistoryParams) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "X-Api-Key": API_KEY,
    };
    const { data } = await instance.get(`${PAYMENT_URL}/payment/transactions`, { headers, params });
    return data;
  };

  async getTransactionInfo(params: { id?: string, campaign_id?: string}) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "X-Api-Key": API_KEY,
    };
    const { data } = await instance.get(`${PAYMENT_URL}/payment/transaction`, { headers, params });
    return data;
  };

  async getPaymentResult(orderId?: string) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "X-Api-Key": API_KEY,
    };
    const { data } = await instance.get(`${PAYMENT_URL}/payment/${orderId}/result`, { headers });
    return data;
  };

  async checkBalance(isPersonal:boolean, employer_id?: string, team_id?: string ) {
    const { access_token } = getTokenFromStorage();
    
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const params = isPersonal ? { employer_id: employer_id } : { team_id: team_id };

    const { data } = await instance.get(`${PAYMENT_URL}/payment/balance`, { headers, params });
    return data.balance;
  };

  async checkWalletBalance(employerId:string) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`
    }
    const { data } = await instance.get(`${PAYMENT_URL}/payment/balance`, { headers, params: { employer_id: employerId } });
    return data;
  };

  async transfer(body: { employer_id: string, team_id: string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const { data } = await instance.post(`${PAYMENT_URL}/payment/transfer`, body, { headers });
    return data;
  };

  async campaignWallet(body: { employer_id: string, campaign_id: string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`${PAYMENT_URL}/payment/campaign/wallet`, body, { headers });
    return data;
  };

  async holding(body: IHoldingAndChargeBody) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`${PAYMENT_URL}/payment/hold`, body, { headers });
    return data;
  };
}