import { styled } from "@mui/system";
import { darken } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { hNavyBlue, hPink, hWhite, hYellow } from "@theme/index";
// asset
import BannerMainImg from "@assets/images/home/marketer-page/cover/d-marketer-page-cover.png";
import BannerMainImgMobile from "@assets/images/home/marketer-page/cover/p-marketer-page-cover.png";
import BecomeDesk from "@assets/images/home/marketer-page/become-marketer/d-get-start-with-hashu-01.png";
import BecomeMobile from "@assets/images/home/marketer-page/become-marketer/p-get-start-with-hashu-01.png";

export const useHomeMarketerStyle = {
  containerBox: {
    height: ["calc(100vh - 30px)", "480px", "480px", "620px", "100vh"],
    backgroundImage: [
      `url("${BannerMainImgMobile}")`,
      `url("${BannerMainImg}")`,
    ],
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  coverBox: {
    display: "flex",
    justifyContent: ["center", "start"],
    alignItems: ["flex-end", "center"],
    height: "inherit",
    paddingTop: 4,
    paddingInline: [6, 6, 12, 14, 36],
  },
  yellowColor: {
    color: hYellow[100],
  },
  navyBlueColor: {
    color: hNavyBlue[100],
  },
  pinkColor: {
    color: hPink[300],
  },
  infographicBox: {
    height: ["auto", null, null, null, "100vh"],
    background: `linear-gradient(296.45deg, #f1efff 40%, #f9e4ea 102.18%)`,
    paddingBlock: [10, 7, 10, 0],
  },
  engagementBox: {
    height: ["640px", "480px", "480px", "620px", "100vh"],
    backgroundImage: [`url("${BecomeMobile}")`, `url("${BecomeDesk}")`],
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  engagementStack: {
    display: "flex",
    justifyContent: ["flex-end", "center"],
    alignItems: ["center", "flex-end"],
    height: "inherit",
    paddingInline: [6, 6, 12, 14, 36],
  },
  carouselBox: {
    height: ["auto", null, null, null, "100vh"],
    py: [10, 10, 10, 30, 0],
    bgcolor: hWhite[200],
  },
  contentBox: {
    display: "flex",
    height: "inherit",
    width: "inherit",
    paddingBottom: ["35px", "50px", "50px", "50px"],
  },
  paper: {
    boxShadow: "0px 0px 24px 2px rgba(229, 229, 229, 0.4)",
  },
  headerText: {
    color: hNavyBlue[100],
  },
  engagementCarouselBox: {
    display: "flex",
    jusjustifyContent: "center",
    alignItems: "center",
    height: 68,
  },
  category: {
    display: "flex",
    cursor: "pointer",
    columnGap: "115px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    MsOverflowStyle: "none",
    overflow: "MozScrollbarsNone",
    "::-webkit-scrollbar": {
      width: "0.3em",
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
  },
};

export const ButtonPricingAndPlan = styled(Button)<ButtonProps>(
  ({ theme }) => ({
    color: theme.palette.common.white,
    minWidth: "282px",
    minHeight: "42px",
    borderRadius: "50px",
    backgroundImage: "none",
    backgroundColor: hNavyBlue[100],
    "&:hover": {
      backgroundColor: darken(hNavyBlue[100], 0.3),
    },
  })
);
