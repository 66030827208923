import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Five() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>
        5. Whom does the Company allow access to your information?
      </Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Box>
        <Typography>
          5.1 Use, disclosure, or transmission of information
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          In addition to collecting the personal information, the Company is
          obliged to use, disclose or transmit your information to the
          Influencer or the Marketer or any other person related to the HashU
          Platform whether in the country or abroad, including:
        </Typography>
        <Typography>● Subsidiaries, Affiliates, Group companies.</Typography>
        <Typography>● Government agency.</Typography>
        <Typography>
          ● The authorized person of the Company to operate under the HashU
          Platform and their business partners.
        </Typography>
        <Typography>
          ● contactors, service providers, or other third parties that support
          the Company's business enter into a contact to provide and/or receive
          services from the Company, whether located in the country or abroad.
          Such entities agree to comply with this Privacy Policy, including
          without limitation Influencer Management Company, Influencer Manager (
          if you are applying for service under Influencer Management Company or
          Influencer Manager), the technology company, telecommunication
          company, software development company, information technology security
          company, communication or PR Company, logistic company.
        </Typography>
        <Typography>
          ● Buyer or other successors, in the event of a merger, divestment,
          general restructuring, reorganization, liquidation or sale, or other
          transfer of partial assets or all of us whether by doing business as
          usual or as part of any bankruptcy, liquidation, or similar process
          which personal data held by the Company in connection with the HashU
          Platform for users is included in the transferred assets.
        </Typography>
        <Typography>
          ● Third parties who desire to market product or service to you when
          you consent to disclose your information for such purposes.
        </Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          5.2 Sending or transferring information to persons in other countries
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          From time to time, the Company may need to send or transfer your
          information to persons in other countries in order to carry out the
          purposes specified in the Privacy Policy. However, the Company will
          use its best efforts to disclose or transfer your personal information
          to such persons or servers in other countries that are a destination
          country with adequate personal data protection standards or equivalent
          to Thailand. However, suppose the destination country has insufficient
          standards for personal data protection or lower than Thailand. In that
          case, the Company will implement measures to ensure that the transfer
          of your personal safety and the transferee has appropriate personal
          data protection standards and the transfer of such data to following
          the Personal Data Protection Act.
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          In the event that the Company requests for your consent to transfer
          personal data abroad by informing you to consider and have received
          your consent. You can revoke such consent through the channels
          specified by the Company in Section 2.
        </Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          5.3 Use of Cookies by third parties and other tracking technologies.
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          Some contents and applications, including advertisements on the HashU
          Platform, are operated by third parties, including advertising
          networks (ad networks) and server, content provider, and application
          service providers. These third parties may use cookies solely or in
          conjunction with other websites, beacons, or tracking technologies to
          collect information about you when you use the HashU Platform. The
          information they collect may relate to your personal information, or
          service providers may collect information. These include personal
          information about online activities over time or from the HashU
          Platforms and other online services. They may use this information to
          provide ads that match their interests (behavioral) of the content of
          interest. The Company will request your consent once again.
        </Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>5.4 Other Disclosures</Typography>
        <Typography>
          ● To comply with a court order, laws, or legal process, including to
          act on the request of legal authorities or governmental.
        </Typography>
        <Typography>
          ● To enforce the Company’s Terms of Use and other contacts, including
          invoicing and billing.
        </Typography>
        <Typography>
          ● If the Company deems that disclosure is necessary or appropriate for
          protecting rights, property, or the safety of the Company, its
          customers, or other persons also includes exchanging information
          between the Company and other organizations for fraud prevention
          purposes and reducing the risk of default (credit risk).
        </Typography>
        <Typography>
          ● To achieve objectives related to historical documentation or
          archival for public benefit or relating to research studies or
          statistics which have provided appropriate safeguards.
        </Typography>
        <Typography>
          ● To protect the rights and freedoms of personal data subjects.
        </Typography>
        <Typography>
          ● To prevent or suppress a danger to the life, body, or health of a
          person.
        </Typography>
        <Typography>
          ● It is necessary to perform duties in carrying out the mission for
          the Company's public interest or performing duties in the exercise of
          state powers given to the Company.
        </Typography>
      </Box>
    </Box>
  );
}
