import { styled, Tab, Tabs } from "@mui/material";

export const ReferralTabs = styled(Tabs)({
  "&.MuiTabs-root": {
    borderRadius: "30px",
    width: "100%",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
});

export const ReferralTab = styled(Tab)({
  backgroundColor: "rgba(235, 223, 255, 0.4)",
  color: "#fff",
  width: "200px",
  minHeight: "40px",
  maxHeight: "40px",
  borderRadius: "30px",
  margin: "0px -20px 0px",
  "&.Mui-selected": {
    backgroundColor: "#fff",
    zIndex: "1",
  },
});

export const useMyReferralStyle = {
  titleBox: {
    display: { xs: "flex", lg: "initial" },
    bgcolor: {
      xs: "#8C499C",
      lg: "initial",
    },
    m: {
      xs: "-28px -10px 0 -10px",
      sm: "-28px -28px 0 -30px",
      md: "-28px -28px 0 -30px",
      lg: "initial",
    },
    px: {
      xs: "10px",
      sm: "30px",
      lg: "initial",
    },
    position: {
      xs: "sticky",
      lg: "initial",
    },
    top: 0,
    zIndex: 5,
  },
  backBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: {
      md: "45px",
    },
    height: {
      xs: "20px",
      md: "40px",
      lg: "initial",
    },
    mt: {
      xs: "16px",
      lg: 0,
    },
    color: {
      xs: "#fff",
      lg: "initial",
    },
    position: {
      xs: "absolute",
      md: "initial",
    },
    top: 10,
    left: {
      xs: 15,
      sm: 30,
    },
    "& > .MuiSvgIcon-root": {
      fontSize: { xs: "18px", md: "12px" },
    },
    "& > span": {
      display: { xs: "none", md: "initial" },
    },
  },
  title: {
    mt: "16px",
    mb: "12px",
    width: "100%",
    textAlign: { xs: "center", lg: "initial" },
    "& > h5": {
      pr: { sm: "40px", lg: 0 },
      color: { xs: "#fff", lg: "initial" },
    },
  },
  tabBox: {
    display: {
      xs: "flex",
      lg: "none",
    },
    justifyContent: "center",
    width: "100%",
    mt: "20px",
  },
  bgSticky: {
    height: "50px",
    position: "sticky",
    top: 130,
    m: {
      xs: "0 -9.5px -50px -10px",
      sm: "0 -28px -50px -30px",
    },
    bgcolor: {
      xs: "#8C499C",
      lg: "initial",
    },
    zIndex: 1,
  },
};
