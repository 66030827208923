import { ITokenStorage } from "@contexts/type";

export function getTokenFromStorage() {
  let result: ITokenStorage = {
    access_token: "",
    refresh_token: "",
    session_token: null,
  };
  const tokenStorage = localStorage.getItem("token");
  if (tokenStorage) {
    const token: ITokenStorage = JSON.parse(tokenStorage);
    result = token;
  }
  return result;
}
