import React from "react";
// mui and style
import { Box, Grid, Typography } from "@mui/material";
import { useCampaignOverviewStyle as classes } from "./CampaignDetail.style";
// component and type
import ImageList from "./ImageList";
import { ICampaignDetailProps } from "./CampaignDetail.type";
// others
import nl2br from "react-nl2br";

export default function CampaignOverview(props: ICampaignDetailProps) {
  const { campaignInfo } = props;

  const isProduct = campaignInfo?.review_type === "product";
  const location = campaignInfo?.location;
  const isListing = campaignInfo?.status === "listing";
  const clearPhone = location?.phone.replace(/\D/g, "");
  const formatPhone = clearPhone?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  const productReviewType = (type?: string) => {
    if (type === "send") {
      return "การส่งสินค้ากลับไปรีวิว ไม่ต้องส่งสินค้ากลับ";
    } else if (type === "send_return") {
      return "การส่งสินค้ากลับไปรีวิว ต้องส่งสินค้ากลับ";
    } else if (type === "no_shipping") {
      return "อินฟลูเอนเซอร์สามารถสินค้ามารีววิวได้เอง";
    } else {
      return "";
    }
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1">Category</Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} sx={classes.category}>
        {campaignInfo?.category?.map((cate, index) => (
          <React.Fragment key={index}>
            <Typography component="span" variant="body1">
              {cate.name_en}
            </Typography>
            <span>,&nbsp;&nbsp;</span>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1">Review</Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-15px", 0]}>
        <Typography variant="body1">
          {campaignInfo?.review_type || ""}
        </Typography>
      </Grid>
      {isProduct ? (
        <>
          <Grid item xs={12} sm={4} xl={3}>
            <Typography variant="title1">Product Details</Typography>
          </Grid>
          <Grid item xs={12} sm={8} xl={9} mt={["-15px", "-10px"]}>
            {campaignInfo?.product?.images?.length ? (
              <ImageList images={campaignInfo?.product.images} />
            ) : null}
          </Grid>
          <Box component="ul" sx={classes.ul}>
            <Box sx={classes.detailItem} mb={2}>
              <Grid item xs={12} sm={4} xl={3}>
                <Typography component="li" variant="title1" ml={3}>
                  Product Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} xl={9}>
                <Typography variant="body1" ml={[3, 0]}>
                  {campaignInfo?.product?.name || "-"}
                </Typography>
              </Grid>
            </Box>
            <Box sx={classes.detailItem}>
              <Grid item xs={12} sm={4} xl={3}>
                <Typography component="li" variant="title1" ml={3}>
                  Description
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} xl={9}>
                <Typography variant="body1" ml={[3, 0]}>
                  {nl2br(campaignInfo?.product?.description || "-")}
                </Typography>
              </Grid>
            </Box>
          </Box>
          <Grid item xs={12} sm={4} xl={3}>
            <Typography variant="title1">Review Product</Typography>
          </Grid>
          <Grid item xs={12} sm={8} xl={9} mt={["-15px", 0]}>
            <Typography variant="body1">
              {productReviewType(campaignInfo?.product_review_type)}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="title1">Location Details</Typography>
          </Grid>
          <Box component="ul" sx={classes.ul}>
            <Box sx={classes.detailItem} mb={2}>
              <Grid item xs={12} sm={4} xl={3}>
                <Typography component="li" variant="title1" ml={3}>
                  Google Map Location
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} xl={9}>
                <Typography
                  variant="body1"
                  onClick={() => window.open(location?.url, "_blank")}
                  ml={[3, 0]}
                  sx={classes.mapUrl}
                >
                  {location?.url}
                </Typography>
                <Box
                  sx={classes.map}
                  onClick={() => window.open(location?.url, "_blank")}
                >
                  <img src={location?.static_map_url} alt="thai map" />
                </Box>
              </Grid>
            </Box>
            <Box sx={classes.detailItem} mb={2}>
              <Grid item xs={12} sm={4} xl={3}>
                <Typography component="li" variant="title1" ml={3}>
                  Location Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} xl={9}>
                <Typography variant="body1" ml={[3, 0]} sx={classes.txtEl}>
                  {location?.name}
                </Typography>
              </Grid>
            </Box>
            <Box sx={classes.detailItem} mb={2}>
              <Grid item xs={12} sm={4} xl={3}>
                <Typography component="li" variant="title1" ml={3}>
                  Address
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} xl={9}>
                <Typography variant="body1" ml={[3, 0]}>
                  {location?.address}
                </Typography>
              </Grid>
            </Box>
            {!isListing && (
              <>
                <Box sx={classes.detailItem} mb={2}>
                  <Grid item xs={12} sm={4} xl={3}>
                    <Typography component="li" variant="title1" ml={3}>
                      Contact Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} xl={9}>
                    <Typography variant="body1" ml={[3, 0]}>
                      {location?.contact || "-"}
                    </Typography>
                  </Grid>
                </Box>
                <Box sx={classes.detailItem} mb={2}>
                  <Grid item xs={12} sm={4} xl={3}>
                    <Typography component="li" variant="title1" ml={3}>
                      Phone
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} xl={9}>
                    <Typography variant="body1" ml={[3, 0]}>
                      {formatPhone || "-"}
                    </Typography>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Grid>
  );
}
