import { Avatar, styled } from "@mui/material";
import { hPurple } from "@theme/index";

const card = {
  bgcolor: "#fff",
  borderRadius: "16px",
  boxShadow: "0px 4px 10px 0px #3535350D",
};

const cardHeader = {
  bgcolor: "primary.main",
  textAlign: "center",
  height: "42px",
  padding: "0",
  fontWeight: "bold",
  borderRadius: "16px 16px 0px 0px",
};

export const useInfluencerSocialStyle = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    bgcolor: { sm: "#fff" },
    borderRadius: "20px",
    boxShadow: { sm: "0px 4px 10px 0px #3535350D" },
    py: { sm: 4 },
    px: { sm: 6 },
    columnGap: "30px",
    rowGap: { xs: "16px", sm: "30px" },
  },
  card: card,
  cardHeader: cardHeader,
};

export const useInfluencerSocialHeaderStyle = {
  card: card,
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },
  // Header
  cardHeader: cardHeader,
  containerMain: {
    width: "100%",
    display: "flex",
  },
  imageWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconAvatar: {
    width: "18px",
    height: "18px",
  },
  badge: {
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },
  avatar: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  nameWrap: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
  },
  stackDetail: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    color: "#9E9E9E",
    "& .MuiSvgIcon-root": {
      fontSize: "4px",
    },
    columnGap: "10px",
  },
  stackDetailWrap: {
    display: "inline-flex",
    alignItems: "center",
    color: "#616161",
  },
  iconInStack: {
    marginRight: "6px",
    width: "16px",
    height: "16px",
  },
  textAgeStack: {
    marginLeft: "6px",
    marginRight: ["0", "12px"],
  },
  stackGender: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "4px",
    },
  },
  itemsCategory: {
    fontSize: "14px",
    fontWeight: 500,
    height: "28px",
    backgroundColor: "#EBDFFF",
    color: "#8C499C",
    px: "10px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
  },
  buttonWrap: {
    padding: "24px 24px 0 0",
    width: "35%",
  },
  cancelInfluencer: {
    backgroundColor: "red",
    color: "#fff",
    ":hover": {
      color: "red",
    },
  },
  selecteInfluencer: {
    backgroundColor: "green",
    color: "#fff",
    ":hover": {
      color: "green",
    },
  },
  categoryWrap: {
    mb: "4px",
    mt: "12px",
  },
};

export const SmallAvatar = styled(Avatar)(() => ({
  width: "25px",
  height: "25px",
}));

export const useRateCardStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  rateCardListBox: {
    border: { sm: "1px solid #E0E0E0" },
    borderRadius: "12px",
    p: { sm: "16px" },
    mt: { xs: "8px", sm: "3px" },
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
};

export const useInfluencerSocialDetailStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  // Information Overview
  overviewMainWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "92px",
    borderRadius: "10px",
    mt: 3,
    border: "1px solid #E0E0E0",
  },
  overviewWrap: {
    display: "flex",
    height: "70px",
    width: "100%",
  },
  overviewStack: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  textOverviewTimeline: {
    color: "#ED1968",
    fontWeight: "bold",
  },
  averageWrap: {
    borderRadius: "10px",
    py: 4,
    border: "1px solid #E0E0E0",
  },
  averageIcon: {
    fontSize: "28px",
    color: hPurple[200],
  },
};

export const useSocialPostStyle = {
  popularPostCon: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  popularPostSlideCon: {
    cursor: "pointer",
    "& .MuiGrid-root": { maxWidth: "initial" },
  },
  popularPostVdo: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    objectFit: "cover",
  },
  popularPostImg: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  popularPostAvg: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
    height: "50px",
    alignItems: "end",
    py: "5px",
    borderRadius: "0 0 8px 8px",
  },
  recentPostCon: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  recentPostSlideCon: {
    cursor: "pointer",
    "& .MuiGrid-root": { maxWidth: "initial" },
  },
  recentPostVdo: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    objectFit: "cover",
  },
  recentPostImg: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  recentPostAvg: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
    height: "50px",
    alignItems: "end",
    py: "5px",
    borderRadius: "0 0 8px 8px",
  },
};

export const useSocialScreenshortStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  contentBox: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    p: "24px",
  },
  tiktokSlides: {
    position: "relative",
    height: { xs: "500px", xl: "700px" },
    mt: "12px",
  },
  tiktokAvg: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "10px",
  },
  tiktokDesc: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    color: "#fff",
  },
  att1: {
    height: { xs: "340px", sm: "500px", xl: "700px" },
    width: "100%",
    borderRadius: "4px",
  },
  att2: {
    height: { xs: "340px", sm: "500px", xl: "700px" },
    width: "50%",
  },
  att3: {
    height: { xs: "340px", sm: "500px", xl: "100%" },
    width: "50%",
  },
  txtOnly: {
    bgcolor: "#F8F8FF",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: "20px",
    height: { xs: "340px", sm: "500px", xl: "100%" },
  },
  totalAvg: {
    display: "flex",
    alignItems: "center",
    rowGap: "10px",
    mt: "12px",
    columnGap: "12px",
  },
};
