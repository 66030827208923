import React from "react";
// mui and style
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useProfileTabStyle as classes } from "./ProfileTab.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";

export default function InfluencerProfile() {
  const profileInfo = useAppSelector(selectInfluencerProfile);

  return (
    <React.Fragment>
      <Grid container p="20px" rowGap="20px">
        <Grid item xs={12} sm={3}>
          <Typography variant="title1">รูปโปรไฟล์</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Avatar
            src={profileInfo?.media?.images[0]?.url}
            sx={{ ...classes.avatar, borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="title1">รูปภาพต่างๆ</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box display="flex">
            <Avatar
              src={profileInfo?.media?.images[1]?.url}
              sx={{
                ...classes.avatar,
                borderRadius: "10px 0 0 10px",
              }}
            />
            <Avatar
              src={profileInfo?.media?.images[2]?.url}
              variant="square"
              sx={classes.avatar}
            />
            <Avatar
              src={profileInfo?.media?.images[3]?.url}
              variant="square"
              sx={{
                ...classes.avatar,
                borderRadius: "0 10px 10px 0",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container p="20px" rowGap="20px">
        {[
          { label: "ชื่อ นาสกุล", value: profileInfo?.fullname },
          { label: "วันเกิด", value: profileInfo?.birth_date },
          { label: "เพศ", value: profileInfo?.gender },
          { label: "สัญชาติ", value: profileInfo?.nationality },
          { label: "เบอร์โทรศัพท์", value: profileInfo?.phone },
          { label: "Line ID", value: profileInfo?.line_id },
          { label: "แนะนำตัวเอง", value: profileInfo?.description },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={3}>
              <Typography variant="title1">{item.label}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography>{item.value}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}
