import * as yup from "yup";

export const CampaignFormSchema = yup.object().shape({
  audience: yup.object().shape({
    gender: yup.string(),
    location: yup.string(),
    age: yup.object().shape({
      min: yup.string(),
      max: yup.string(),
    }),
  }),
  influencer: yup.object().shape({
    gender: yup.string().required("Please enter data."),
    location: yup.string().required("Please enter data."),
    age: yup.object().shape({
      min: yup.string(),
      max: yup.string(),
    }),
    followers: yup.object().shape({
      min: yup.string(),
      max: yup.string(),
    }),
  }),
  timeline: yup.object().shape({
    recruiting: yup.object().shape({
      startDate: yup.string().required("Please enter data."),
      endDate: yup.string().required("Please enter data."),
    }),
    campaign: yup.object().shape({
      startDate: yup.string().required("Please enter data."),
      endDate: yup.string().required("Please enter data."),
    }),
    annouceDate: yup.string().required("Please enter data."),
    postingPeriod: yup.number().required("Please enter data."),
  }),
  campaign: yup.object().shape({
    campaignType: yup.string().required("Please enter data."),
    logoCompany: yup.mixed().required("Image is required"),
    companyName: yup.string().required("Please enter data."),
    organization: yup.string().required("Please enter data."),
    logoOrganization: yup.mixed().required("Image is required"),
    campaignName: yup.string().required("Please enter data."),

    selectBy: yup.string().required("Please enter data."),
    method: yup.string().required("Please enter data."),
    objective: yup.string().required("Please enter data."),
    review: yup.string().required("Please enter data."),
  }),
  budget: yup.object().shape({
    total: yup.string().required("Please enter data."),
    influencerAmount: yup.string().required("Please enter data."),
    eachInfluencer: yup.string().required("Please enter data."),
  }),
  target: yup.object().shape({
    totalFollowers: yup.string(),
    reach: yup.string(),
  }),
  content: yup.object().shape({
    platform: yup.string().required("Please enter data."),
    briefImage: yup.object().shape({
      images: yup.mixed().required("Image is required"),
      video: yup.mixed().required("Image is required"),
    }),

    contentType: yup.string().required("Please enter data."),
    category: yup.string().required("Please enter data."),
    hashtag: yup.string().required("Please enter data."),
    moodAndTone: yup.string().required("Please enter data."),
    moodAndToneImage: yup.object().shape({
      images: yup.mixed().required("Image is required"),
      video: yup.mixed().required("Image is required"),
    }),
    description: yup.string().required("Please enter data."),
    keyMessage: yup.string().required("Please enter data."),
    emphasis: yup.string().required("Please enter data."),
    prohibition: yup.string().required("Please enter data."),
  }),
  termAndCondition: yup.array().required("Please enter data."),
});
