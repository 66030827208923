export const useCampaignStyle = {
  card: {
    position: "relative",
    boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
    minWidth: {
      xs: "300px",
      sm: "inherit",
    },
  },
  cardTop: {
    "& > span": {
      position: "absolute",
      top: 20,
      right: 20,
      color: "#fff",
    },
  },
  companyLogo: {
    position: "absolute",
    top: "16px",
    left: "16px",
    width: "40px",
    height: "40px",
    borderRadius: "4px",
  },
  boxStatus: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 12px 0 12px",
    color: "white",
  },
  campaignName: {
    fontSize: "18px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  category: {
    display: "flex",
    alignItems: "center",
    mb: "5px",
    minHeight: "20px",
  },
  categoryDivider: {
    fontSize: "4px",
    color: "#505050",
  },
  categoryItems: {
    overflow: "hidden",
    height: "20px",
    fontSize: "12px",
  },
  cardDetailBottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    "& > p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "90%",
      textOverflow: "ellipsis",
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
    mt: "-3px",
    color: "#5C5C5C",
  },
  avatarCompany: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    position: "absolute",
    padding: "16px",
  },
  stackCategory: {
    display: "flex",
    alignItems: "center",
    color: "#9E9E9E",
    "& .MuiSvgIcon-root": {
      fontSize: "6px",
    },
  },
  textEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    marginTop: "8px",
  },
  itemLeft: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
  },
  avatarOrganization: {
    width: "24px",
    height: "24px",
    objectFit: "cover",
    marginRight: "12px",
  },
};

export const useCampaignBrieCompfStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "40px",
    bgcolor: "#fff",
    borderRadius: "8px",
    padding: "8px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  selectCampaignTabStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    ml: "8px",
  },
  divider: {
    mx: "15px",
    height: "25px",
    mt: "7px",
  },
  dialogCon: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "12px",
    },
  },
  dialogMenu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    borderRadius: "5px",
    "&:hover": {
      bgcolor: "#F4ECFF",
    },
  },
  selectCampaignTabTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100px",
    mr: "8px",
  },
  dialogMenuCanceled: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    borderRadius: "5px",
    "&:hover": {
      bgcolor: "#F4ECFF",
    },
  },
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: [0, "25px 0 0 25px"],
    },
    overflowY: "initial",
  },
  drawerCon: {
    borderRadius: "8px",
    width: ["91.5%", "390px"],
    px: "18px",
  },
  filterTitle: {
    padding: "12px",
    position: "sticky",
    bgcolor: "#fff",
    borderRadius: "25px 0 0 0",
    borderBottom: "2px solid #E0E0E0",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    left: 0,
    top: 0,
    mx: "-18px",
    "& > .MuiSvgIcon-root": {
      cursor: "pointer",
      mr: "5px",
    },
  },
  titleItem: {
    padding: "12px",
    "& > p": {
      fontWeight: 600,
      mb: "10px",
    },
    overflowY: "initial",
  },
  line: {
    margin: "5px 13px",
    border: "1px solid #E0E0E0",
  },
  chip: {
    minWidth: "60px",
    margin: "0 12px 12px 0",
    border: "1px solid #5C5C5C",
    "&:hover": {
      border: "1px solid #F4ECFF",
      color: "#893DBD",
      bgcolor: "#F4ECFF",
    },
  },
  filterBottom: {
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: {
      xs: "95%",
      sm: "400px",
    },
    bottom: 0,
    bgcolor: "#fff",
    borderTop: "2px solid #E0E0E0",
    borderRadius: {
      xs: 0,
      sm: "0 0 0 26px",
    },
    "& > p": {
      color: "#893DBD",
      cursor: "pointer",
    },
    right: 0,
  },
  buttonApply: {
    width: "118px",
    height: "32px",
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
  containnerMain: {
    padding: ["88px 20px 20px 20px", "88px 28px 28px 120px"],
  },
  //   filter
  boxFilter: {
    padding: "12px",
  },
  Header: {
    fontWeight: 600,
    marginBottom: "12px",
  },
  lineMain: {
    border: "1px solid #E0E0E0",
  },
};

export const useNewCampaignDialogStyle = {
  dialog: {
    "& .css-1q2uuof-MuiPaper-root-MuiDialog-paper,.css-x29yx8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    overflowX: "initial",
  },
  title: {
    bgcolor: "#8C499C",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "92%",
    mt: "10px",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
  dialogTitle: {
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
    width: "92%",
  },
  createCampDetailBox: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    background: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    p: "24px",
    alignItems: "center",
    my: "16px",
  },
  btn: {
    display: "flex",
    mt: "32px",
    width: "80%",
    columnGap: 2,
    "& > button": { fontSize: "16px" },
    "& > button:last-of-type": { border: "none" },
  },
};

export const useCampaignCardStyle = {
  headerWrap: {
    my: "24px",
    bgcolor: "#fff",
    borderRadius: "12px",
    p: "24px",
    boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
  },
  planWrap: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
    bgcolor: "#F5EEFF",
    color: "#8C499C",
    p: "6px 10px 6px 10px",
    borderRadius: "4px",
  },
  serach: {
    width: "100%",
    maxWidth: "250px",
    display: "flex",
    border: "1px solid #E0E0E0",
    height: "37px",
    boxShadow: "none",
  },
};