// mui
import {
  Box,
  Grid,
  Icon,
  Stack,
  Button,
  Divider,
  Typography,
} from "@mui/material";
// type and style
import { IReportTotalListProps } from "./ReportTab.type";
import { useReportTotalListStyle as classes } from "./ReportTab.style";
// others
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export default function ReportInfluencerTotal(props: IReportTotalListProps) {
  const { overview, publishedLink } = props;

  const history = useHistory();
  const numberFormat = Intl.NumberFormat("en");

  const totalList = [
    {
      label: "วันที่โพสต์",
      subLabel: overview?.posting_at
        ? dayjs(overview.posting_at).format("DD/MM/YYYY")
        : "-",
      icon: (
        <Icon
          color="primary"
          fontSize="large"
          className="ico-hu-calendar-date"
        />
      ),
    },
    {
      label: "ระยะเวลาการโพสต์",
      subLabel: `${overview?.posting_period || "0"} days`,
      icon: <Icon color="primary" fontSize="large" className="ico-hu-time" />,
    },
    {
      label: "วันเสร็จสิ้น",
      subLabel: overview?.completed_at
        ? dayjs(overview.completed_at).format("DD/MM/YYYY")
        : "-",
      icon: <Icon color="primary" fontSize="large" className="ico-hu-time" />,
    },
    {
      label: "วันรับเงิน",
      subLabel: overview?.receive_budget_at
        ? dayjs(overview.receive_budget_at).format("DD/MM/YYYY")
        : "-",
      icon: <Icon color="primary" fontSize="large" className="ico-hu-bank" />,
    },
    {
      label: "รายได้ที่จะได้รับ",
      subLabel: numberFormat.format(overview?.budget || 0),
      icon: (
        <Icon color="primary" fontSize="large" className="ico-hu-money-bag" />
      ),
    },
  ];

  return (
    <Grid container spacing={3}>
      {totalList.map((item, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2.4}>
          <Box sx={classes.filterDetailBox}>
            <Box sx={classes.label}>
              <Typography>{item.label}</Typography>
              <Typography>{item.subLabel}</Typography>
            </Box>
            <Box sx={classes.icon}>{item.icon}</Box>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} display={{ sm: "none" }}>
        <Divider sx={{ my: "10px" }} />
        <Stack
          direction="row"
          spacing={2}
          width="100%"
          mt={5}
          sx={{ "& button:last-of-type": { border: "none" } }}
        >
          <Button fullWidth onClick={() => history.push("/my-earning")}>
            My Earning
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => window.open(publishedLink, "_blank")}
          >
            View Post
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
