import { useState } from "react";
// mui
import { Avatar, Box, Button, Icon, Typography } from "@mui/material";
import { useOldTeamStyle as classes } from "./TeamManagement.style";
// api and type
import { TeamAPI } from "@api/global";
import { IOldTeamProps } from "@views/team-management/Team.type";
// other
import Loading from "@global/loading/Loading";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";

export default function OldTeam(props: IOldTeamProps) {
  const { open, onClose, invitation, index } = props;

  const [isLoading, setIsLoading] = useState(false);

  const invitationData = (invitation && invitation[index]) || [];

  const actionInvitation = (invitation_id: string, action: string) => {
    setIsLoading(true);
    const reqBody = {
      invitation_id,
      action,
    };
    
    TeamAPI.acceptAndRejectInvitation(reqBody)
      .then(() => {
        onClose(index);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading />;

  return (
    <DialogScrollingCustom
      open={open}
      onClose={() => onClose(index)}
      title="ย้ายทีม"
      sx={classes.dialog}
    >
      <Box padding="24px">
        <Box display="flex">
          {/* Current Team */}
          <Box width="100%">
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">ทีมปัจจุบัน</Typography>
            </Box>
            <Box sx={classes.teamBox}>
              <div>
                <Avatar
                  src={invitationData?.current_team?.team_profile_url}
                  variant="square"
                  className="team-peofile"
                />
                <Box display="flex" justifyContent="center" m="16px 0">
                  <Typography variant="h6">
                    {invitationData?.current_team?.team_name}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Avatar
                    src={invitationData?.current_team?.leader_profile_url}
                    sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                  />
                  <Typography>
                    {invitationData?.current_team?.leader_name}
                  </Typography>
                </Box>
              </div>
            </Box>
          </Box>

          {/* New Team */}
          <Box display="flex" alignItems="center" padding="0 16px">
            <Icon className="ico-hu-arrow-right" sx={{ fontSize: "32px" }} />
          </Box>
          <Box width="100%">
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">ทีมใหม่</Typography>
            </Box>
            <Box sx={classes.teamBox}>
              <div>
                <Avatar
                  src={invitationData?.invite_team?.team_profile_url}
                  variant="square"
                  className="team-peofile"
                />
                <Box display="flex" justifyContent="center" m="16px 0">
                  <Typography variant="h6">
                    {invitationData?.invite_team?.team_name}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Avatar
                    src={invitationData?.invite_team?.leader_profile_url}
                    sx={{ width: "24px", height: "24px", mr: "8px" }}
                  />
                  <Typography>
                    {invitationData?.invite_team?.leader_name}
                  </Typography>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>

        <Box mt="32px">
          <Box display="flex" justifyContent="center">
            <Typography variant="h6">คุณต้องการย้ายทีมใช่หรือไม่ ?</Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt="16px">
            <Button
              sx={{ width: "250px", padding: "8px 16px" }}
              onClick={() => onClose(index)}
            >
              <Typography variant="buttonM">ยกเลิก</Typography>
            </Button>
            <Box width="3%"></Box>
            <Button
              sx={{ width: "250px", padding: "8px 16px" }}
              onClick={() => {
                actionInvitation(invitationData?.id, "accept");
              }}
            >
              <Typography variant="buttonM">ใช่ ฉันต้องการย้ายทีม</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogScrollingCustom>
  );
}
