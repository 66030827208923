import { 
  IEarningParams,
  IBankAccountReq,
  IReferralParams,
  IBillingAddressBody,
  IShippingAddressBody,
  IInfluencerBanStatusParams,
  IUpdateInfluencerProfileBody,
  IUpdateInfluencerCategoryBody,
  IUpdateInfluencerRateCardBody
} from "./type";
import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class InfluencerAPIs {
  async getInfluencerBanStatus(influencerId:string, params: IInfluencerBanStatusParams) {
    const { access_token } = getTokenFromStorage()
    
    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get(`/influencers/${influencerId}/ban-status`, { headers, params });
    return data.result.data;
  }

  async getInfluencerBanSummary(influencerId: string ) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get(`/influencers/${influencerId}/ban-summary`, { headers });
    return data.result.data;
  }

  async getInfluencerReferral(influencerId:string, params: IReferralParams) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get(`/influencers/${influencerId}/referral`, { headers, params });
    return data.result.data;
  }

  async getInfluencerEarningList(influencerId: string, params: IEarningParams ) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/influencers/${influencerId}/earnings`, { headers, params });
    return data.result.data;
  }

  async getInfluencerEarningSummary(influencerId: string) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/influencers/${influencerId}/earnings/summary`, { headers });
    return data.result.data;
  }

  async importEarningAsCSV(influencerId:string, body:any) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`/influencers/${influencerId}/earnings/import`, body, { headers });
    return data.result.data;
  }

  async exportEarningAsCSV(influencerId:string) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get(`/influencers/${influencerId}/earnings/export`, { headers });
    return data.result.data;
  }

  async updateInfluencerCategory(body: IUpdateInfluencerCategoryBody) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const influencerID = session_token?.influencer_id;
    
    const { data } = await instance.patch(`/influencers/${influencerID}/socials`, body, { headers });
    return data.result.data;
  }

  async getInfluencerSocial(influecerId?: string) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const influencerID = influecerId || session_token?.influencer_id;

    const { data } = await instance.get(`/influencers/${influencerID}`, { headers, params: { grant_type: "social" } });
    return data.result.data;
  }

  async getInfluencerProfile(influencerId: string) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/influencers/${influencerId}`, { headers, params: { grant_type: "profile" } });
    return data.result.data;
  }

  async updateInfluencerProfile(body: IUpdateInfluencerProfileBody) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/influencers/${influencerID}`, body, { headers });
    return data.result.data;
  }

  async updateInfluencerShipping(body: IShippingAddressBody) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/influencers/${influencerID}`, body, { headers });
    return data.result.data;
  }

  async updateInfluencerBilling(body: IBillingAddressBody) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/influencers/${influencerID}`, body, { headers });
    return data.result.data;
  }

  async updateInfluencerPortfolio(body: any) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/influencers/${influencerID}`, body, { headers });
    return data.result.data;
  }

  async deleteInfluencerPortfolio(params:{ port_id:string }) {
    const { access_token, session_token } = getTokenFromStorage()
    
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.delete(`/influencers/${influencerID}/portolio`, { headers, params });
    return data.result.data;
  }

  async updateInfluencerRegistStatus() {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/referer/${influencerID}/register-status`, null, { headers });
    return data.result.data;
  }

  async updateInfluencerRateCard(body: IUpdateInfluencerRateCardBody) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/influencers/${influencerID}/rate-card`, body, { headers });
    return data.result.data;
  }

  async updateInfluencerBankAccount(body: { bank_account: IBankAccountReq }) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json",
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/influencers/${influencerID}`, body, { headers });
    return data.result.data;
  };
}