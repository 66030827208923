import { useEffect } from "react";
// mui
import {
  Box,
  Icon,
  Grid,
  Step,
  Avatar,
  Stepper,
  StepLabel,
  Typography,
  CardContent,
  StepContent,
  InputAdornment,
} from "@mui/material";
// style and type
import { useTimelineStyle as classes } from "../CampaignBrief.style";
import { ICreateCampaignProps } from "../CampaignBrief.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// others
import DateInput from "./DateInput";
import TextFieldCustom from "@global/text-field";
import DateRangeInputCustom from "@global/date-range-input-custom/DateRangeInputCustom";
// images
import influProfile from "@assets/images/people/influencer.png";
import locationImage from "@assets/images/campaign/location.png";
import productImage from "@assets/images/campaign/sent-product.png";
//  dayJs
import dayjs from "dayjs";
import "dayjs/locale/th";
dayjs.locale("th");

export default function Timeline(props: ICreateCampaignProps) {
  const { form } = props;

  const {
    watch,
    setValue,
    formState: { errors },
  } = form;

  const employerInfo = useAppSelector(selectEmployerInfo);
  const employerProfile = employerInfo?.organisation?.attachment?.url;
  const isCampaignPrivate = watch("campaign_type") === "private";

  const isPlace = watch("review_type") === "place";
  const isNoShipping = watch("product_review_type") === "no_shipping";
  const isSendProduct =
    watch("product_review_type") === "send_return" ||
    watch("product_review_type") === "send";
  const periodWatch = new Date(watch("timeline.end_publish_date"));
  const returnProdMinDate = dayjs(watch("timeline.announce_date")).format(
    "YYYY-MM-DD"
  );
  const publishMinDate = dayjs(watch("timeline.announce_date"))
    .add(isSendProduct ? 36 : isNoShipping ? 21 : 25, "day")
    .format("YYYY-MM-DD");
  const timeline = watch("timeline");
  const checkDraftTimline = isPlace
    ? timeline.end_shipping_date
    : isSendProduct
    ? timeline.shipping_date
    : timeline.announce_date;
  const draftPhase = watch("timeline.draft_phase");

  const fontWeight = (txt: string, color?: string) => {
    return <span style={{ fontWeight: 500, color: color }}>{txt}</span>;
  };

  const culDraftTimline = (day: number) => {
    const totalDay = isSendProduct ? day + 5 : day;
    return checkDraftTimline
      ? dayjs(checkDraftTimline).add(totalDay, "day").format("YYYY-MM-DD")
      : "";
  };

  useEffect(() => {
    if (!checkDraftTimline) return;
    const draftPhases = [
      { start: 5, end: 8 },
      { start: 8, end: 11 },
      { start: 11, end: 14 },
      { start: 14, end: 17 },
      { start: 17, end: 20 },
    ].map((draft) => {
      return {
        start: culDraftTimline(draft.start),
        end: culDraftTimline(draft.end),
      };
    });
    setValue("timeline.draft_phase", draftPhases);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDraftTimline]);

  const dateFormat = (date?: string) => {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  };

  const draftPhaseStepLabel = (label: string, role: string) => {
    return (
      <StepLabel
        sx={{ p: 0 }}
        icon={
          <Avatar
            src={role === "influencer" ? influProfile : employerProfile}
            sx={{ width: "48px", height: "48px", zIndex: 1 }}
          />
        }
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="title1" color="#231F20" ml={3}>
            {label}
          </Typography>
        </Box>
      </StepLabel>
    );
  };

  return (
    <CardContent>
      <Grid container>
        <Stepper
          orientation="vertical"
          sx={{ width: "100%" }}
          connector={<></>}
        >
          {isCampaignPrivate ? (
            <>
              <Step expanded>
                <StepLabel
                  sx={{ p: 0 }}
                  icon={
                    <Icon
                      className="ico-hu-announce"
                      sx={classes.stepLabelIconActive}
                    />
                  }
                >
                  <Typography color="primary" variant="title1" ml={2}>
                    ตรวจสอบรายชื่ออินฟลูเอนเซอร์ที่ตอบรับคำเชิญ
                  </Typography>
                </StepLabel>
                <StepContent sx={classes.lineIsActive}>
                  <Box sx={classes.announceDate}>
                    <Box display="flex">
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography variant="title2">
                          กำหนดช่วงยื่นข้อเสนอ
                        </Typography>
                        <span className="require">*</span>
                      </Grid>
                      <Grid item xs={8}>
                        <DateRangeInputCustom
                          setValue={setValue}
                          watch={watch}
                          startKeyName="timeline.start_recruiting_date"
                          endKeyName="timeline.end_recruiting_date"
                          placeholderFirst="วันที่เริ่มยื่นข้อเสนอ"
                          placeholderSecond="วันสุดท้ายที่ยื่นข้อเสนอ"
                          startError={
                            errors?.timeline?.start_recruiting_date?.message
                          }
                          endError={
                            errors?.timeline?.end_recruiting_date?.message
                          }
                          minDate={dayjs(new Date()).format("YYYY-MM-DD")}
                        />
                      </Grid>
                    </Box>
                    <Box display="flex" my="16px">
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography variant="title2">
                          วันสุดท้ายที่อินฟลูตอบรับข้อเสนอได้
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Box sx={classes.dateDisabled}>
                          <Typography>
                            {watch("timeline.end_recruiting_date")
                              ? dayjs(watch("timeline.end_recruiting_date"))
                                  .add(1, "day")
                                  .format("DD/MM/YYYY")
                              : "วันสุดท้ายที่ยื่น + 1"}
                          </Typography>
                          <Icon
                            className="ico-hu-calendar-date"
                            sx={{ color: "#616161" }}
                          />
                        </Box>
                      </Grid>
                    </Box>
                    <Box>
                      <Box>
                        <Typography variant="title2" color="primary">
                          การเลือกและยื่นข้อเสนอ
                        </Typography>
                        <Box component="ul" mt={2}>
                          {[
                            <span>
                              วันที่เริ่มยื่นข้อเสนอ
                              ระบบจะดำเนินการส่งข้อเสนอให้แก่
                              {fontWeight(" “อินฟลูเอนเซอร์ตัวจริง” ")}
                              หรือคนที่คุณอยากร่วมงานด้วยมากที่สุด และ
                              {fontWeight(" “รายชื่อตัวสำรอง” ")}
                              จะได้รับข้อเสนอหลังผ่านไปแล้ว 24 ชั่วโมง
                            </span>,
                            <span>
                              ในระหว่าง
                              {fontWeight(
                                " “ช่วงการตอบรับข้อเสนอจากอินฟลูเอนเซอร์” "
                              )}
                              ผู้จ้างงานสามารถเพิ่มรายชื่อตัวสำรองได้ตลอดระยะเวลา
                              โดยรายชื่อเพิ่มเติมจะจัดอยู่ในกลุ่มที่เป็นตัวสำรองหรือกลุ่มที่ยังไม่ได้ยื่นข้อเสนอ
                              เราจะดำเนินการยื่นข้อเสนอในรอบถัดไป
                            </span>,
                            <span>
                              รอบในการยื่นข้อเสนอให้แก่อินฟลูเอนเซอร์จะจัดกลุ่มและยื่นให้ในทุกๆ
                              24 ชั่วโมง
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              variant="body2"
                              component="li"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="title2" color="primary">
                          การชำระเงิน
                        </Typography>
                        <Box component="ul" mt={2}>
                          <Typography variant="body2" component="li">
                            ระบบทำการคำนวณราคาของแคมเปญจากผลรวม Rate Card ของ
                            {fontWeight(" “รายชื่ออินฟลูเอนเซอร์ตัวจริง” ")}
                            เป็นหลัก
                            โดยผู้จ้างงานต้องชำระค่ามัดจำตามอัตราราคาแคมเปญหลังจากการสร้างแคมเปญเสร็จสิ้น
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </StepContent>
              </Step>
              <Step expanded>
                <StepLabel
                  sx={{ p: 0 }}
                  icon={
                    <Icon
                      className="ico-hu-group"
                      sx={classes.stepLabelIconActive}
                    />
                  }
                >
                  <Typography color="primary" variant="title1" ml={2}>
                    ช่วงยื่นข้อเสนอให้อินฟลูเอนเซอร์
                  </Typography>
                </StepLabel>
                <StepContent sx={classes.lineIsActive}>
                  <Box sx={classes.announceDate}>
                    <Box display="flex">
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography variant="title2">
                          กำหนดช่วงยื่นข้อเสนอ
                        </Typography>
                        <span className="require">*</span>
                      </Grid>
                      <Grid item xs={8}>
                        <Box width="48%">
                          <DateInput
                            form={form}
                            keyName="timeline.announce_date"
                            placeholder="วันที่ตรวจสอบรายชื่อ"
                            minDate={dayjs(
                              watch("timeline.end_recruiting_date")
                            )
                              .add(1, "day")
                              .format("YYYY-MM-DD")}
                            errors={errors?.timeline?.announce_date?.message}
                            disabled={
                              !timeline.start_recruiting_date ||
                              !timeline.end_recruiting_date
                            }
                          />
                        </Box>
                      </Grid>
                    </Box>
                    <Box mt="16px">
                      <Box>
                        <Typography variant="title2" color="primary">
                          ตอบรับครบจำนวนก่อนกำหนดเวลา
                        </Typography>
                        <Box component="ul" mt={2}>
                          <Typography variant="body2" component="li">
                            เมื่ออินฟลูเอนเซอร์ตอบรับ
                            {fontWeight(" “ครบตามจำนวนที่กำหนด” ", "#4CAF50")}
                            ก่อนวันสุดท้ายของการตอบรับข้อเสนอ
                            ระบบจะปิดการรับสมัคร โดยอัตโนมัติ
                            ผู้จ้างงานสามารถตรวจสอบรายชื่อและชำระเงินได้ทันที
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="title2" color="primary">
                          สิ้นสุดการตอบรับข้อเสนอ
                        </Typography>
                        <Box component="ul" mt={2}>
                          <Typography variant="body2" component="li">
                            อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                            {fontWeight(" “ครบตามจำนวนที่กำหนด” ", "#4CAF50")}
                            ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                          </Typography>
                          <Box component="ul" ml={-4}>
                            {[
                              <span>
                                {fontWeight("เริ่มแคมเปญ ")}
                                ตรวจสอบรายชื่อและชำระเงินตามจริง
                                โดยระบบจะคำนวณราคาจาก Rate Card
                                ของอินฟลูเอนเซอร์ ที่ตอบตกลงรับงาน
                              </span>,
                              <span>
                                {fontWeight("ยกเลิกแคมเปญ")} ระบบดำเนินการ
                                {fontWeight(" “คืนเงินมัดจำ” ")}
                                และคืนเงินสำรองจ่ายกลับไปยัง Wallet
                                ของผู้จ้างงาน
                              </span>,
                            ].map((li, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                component="li"
                              >
                                {li}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                        <Box component="ul" mt={2}>
                          <Typography variant="body2" component="li">
                            อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                            {fontWeight(" “น้อยกว่าจำนวนที่กำหนด” ", "#E30D18")}
                            เช่น ผู้จ้างงานต้องการผู้เข้าร่วมแคมเปญจำนวน 10 คน
                            แต่มีผู้ตอบรับเข้าร่วมแคมเปญเพียง 5 คน
                            ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                          </Typography>
                          <Box component="ul" ml={-4}>
                            {[
                              <span>
                                {fontWeight("เริ่มแคมเปญ ")}
                                ตรวจสอบรายชื่อและชำระเงินตามจริง
                                โดยระบบจะคำนวณราคาจาก Rate Card
                                ของอินฟลูเอนเซอร์ ที่ตอบตกลงรับงาน
                              </span>,
                              <span>
                                {fontWeight("ยกเลิกแคมเปญ")} ระบบดำเนินการ
                                {fontWeight(" “คืนเงินมัดจำ” ")}
                                และเงินสำรองจ่ายกลับไปยัง Wallet ของผู้จ้างงาน
                              </span>,
                            ].map((li, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                component="li"
                              >
                                {li}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="title2" color="primary">
                          ไม่ดำเนินการตรวจสอบและชำระเงินในเวลาที่กำหนด
                        </Typography>
                        <Box component="ul" mt={2}>
                          <Typography variant="body2" component="li">
                            ระบบจะทำการยกเลิกแคมเปญโดยอัตโนมัติ โดยแบ่งได้เป็น 2
                            กรณีดังนี้
                          </Typography>
                          <Box component="ul" ml={-4}>
                            {[
                              <span>
                                {fontWeight("กรณีที่ 1 ")}
                                อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                                {fontWeight(" “ครบจำนวน” ", "#4CAF50")}ที่กำหนด
                                ระบบ “ตัดเงินมัดจำ” และคืนเงินสำรองจ่ายกลับไปยัง
                                Wallet ของผู้จ้างงาน
                              </span>,
                              <span>
                                {fontWeight("กรณีที่ 2 ")}
                                อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                                {fontWeight(" “น้อยกว่า” ", "#E30D18")}ที่กำหนด
                                ระบบ “คืนเงินมัดจำและเงินสำรองจ่าย” กลับไปยัง
                                Wallet ของผู้จ้างงาน
                              </span>,
                            ].map((li, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                component="li"
                              >
                                {li}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </StepContent>
              </Step>
            </>
          ) : (
            <>
              <Step expanded>
                <StepLabel
                  sx={{ p: 0 }}
                  icon={
                    <Icon
                      className="ico-hu-group"
                      sx={classes.stepLabelIconActive}
                    />
                  }
                >
                  <Typography color="primary" variant="title1" ml={2}>
                    ประกาศรับสมัคร
                  </Typography>
                </StepLabel>
                <StepContent sx={classes.lineIsActive}>
                  <Box display="flex" sx={classes.announceDate}>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        กำหนดช่วงประกาศรับสมัคร
                      </Typography>
                      <span className="require">*</span>
                    </Grid>
                    <Grid item xs={8}>
                      <DateRangeInputCustom
                        setValue={setValue}
                        watch={watch}
                        startKeyName="timeline.start_recruiting_date"
                        endKeyName="timeline.end_recruiting_date"
                        placeholderFirst="วันที่เริ่มเปิดรับสมัคร"
                        placeholderSecond="วันสิ้นสุดการรับสมัคร"
                        startError={
                          errors?.timeline?.start_recruiting_date?.message
                        }
                        endError={
                          errors?.timeline?.end_recruiting_date?.message
                        }
                        minDate={dayjs(new Date()).format("YYYY-MM-DD")}
                      />
                    </Grid>
                  </Box>
                </StepContent>
              </Step>

              <Step expanded>
                <StepLabel
                  sx={{ p: 0 }}
                  icon={
                    <Icon
                      className="ico-hu-announce"
                      sx={classes.stepLabelIconActive}
                    />
                  }
                >
                  <Typography color="primary" variant="title1" ml={2}>
                    ประกาศผลผู้เข้าร่วมแคมเปญ
                  </Typography>
                </StepLabel>
                <StepContent sx={classes.lineIsActive}>
                  <Box sx={classes.announceDate}>
                    <Box display="flex">
                      <Grid item xs={4} display="flex" alignItems="center">
                        <Typography variant="title2">
                          ประกาศผลผู้ผ่านคัดเลือกภายในวันที่
                        </Typography>
                        <span className="require">*</span>
                      </Grid>
                      <Grid item xs={8}>
                        <Box width="48%">
                          <DateInput
                            form={form}
                            keyName="timeline.announce_date"
                            placeholder="วันประกาศผล"
                            minDate={dayjs(
                              watch("timeline.end_recruiting_date")
                            )
                              .add(1, "day")
                              .format("YYYY-MM-DD")}
                            errors={errors?.timeline?.announce_date?.message}
                            disabled={
                              !timeline.start_recruiting_date ||
                              !timeline.end_recruiting_date
                            }
                          />
                        </Box>
                      </Grid>
                    </Box>
                    <Box
                      component="img"
                      width="100%"
                      src={require(`@assets/images/campaign/announce-campaign.png`)}
                      alt="announce campaign"
                    />
                    <Box component="ul">
                      <Box>
                        <Typography variant="title2" color="primary" ml={-7}>
                          ต้องการปิดรับสมัครก่อนกำหนดเวลา
                        </Typography>
                        <Typography variant="body2" component="li" mt={2}>
                          ผู้จ้างงานสามารถเลือกอินฟลูเอนเซอร์ก่อนวันปิดรับสมัครได้
                          เพียงทำการ {fontWeight("เลือก ")}
                          อินฟลูเอนเซอร์ที่ต้องการ จากนั้นทำการ
                          {fontWeight(" “ยืนยันรายชื่อและชำระค่าบริการ”")}
                          แล้วระบบจะทำการ
                          {fontWeight(" “ประกาศผลผู้ผ่านการคัดเลือกทันที”")}
                        </Typography>
                      </Box>

                      <Box mt="24px">
                        <Typography variant="title2" color="primary" ml={-7}>
                          ครบกำหนดและปิดรับสมัคร
                        </Typography>
                        <Typography variant="body2" component="li" mt={2}>
                          ผู้สมัคร
                          {fontWeight(
                            " “ประกาศผลผู้ผ่านการคัดเลือกทันที” ",
                            "#4CAF50"
                          )}
                          จำนวนที่กำหนด ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                        </Typography>
                        <Box ml={7} mt={2}>
                          {[
                            <span>
                              {fontWeight("เริ่มแคมเปญ ")}
                              โดยคัดเลือกผู้สมัครและประกาศผลภายในเวลาที่กำหนด
                            </span>,
                            <span>
                              {fontWeight("ยกเลิกแคมเปญ")} ระบบดำเนินการ
                              “ตัดเงินมัดจำ” และคืนเงินสำรองจ่ายกลับไปยัง Wallet
                              ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                        <Typography variant="body2" component="li" mt={2}>
                          ผู้สมัคร {fontWeight("“น้อยกว่า”", "#E30D18")}{" "}
                          จำนวนที่กำหนด เช่น
                          ผู้จ้างงานต้องการผู้เข้าร่วมแคมเปญจำนวน 20 คน
                          แต่มีผู้สมัครเข้าร่วมแคมเปญ เพียง 15 คน
                          ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                        </Typography>
                        <Box ml={7} mt={2}>
                          {[
                            <span>
                              {fontWeight("เริ่มแคมเปญ")} “เลือก”
                              อินฟลูเอนเซอร์และ “ชำระเงินตามจริง”
                              โดยระบบจะคำนวณค่าใช้จ่ายตามจำนวนผู้ผ่านการคัดเลือก
                              และคืนเงินส่วนต่างกลับไปยัง Wallet ของผู้จ้างงาน
                            </span>,
                            <span>
                              {fontWeight("ยกเลิกแคมเปญ ")}
                              ระบบดำเนินการคืนเงินมัดจำและเงินสำรองจ่ายกลับไปยัง
                              Wallet ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>

                      <Box mt="24px">
                        <Typography variant="title2" color="primary" ml={-7}>
                          “ไม่เลือก” ผู้เข้าร่วมแคมเปญในเวลาที่กำหนด
                        </Typography>
                        <Typography variant="body2" ml={-7} mt={2}>
                          ระบบจะทำการยกเลิกแคมเปญโดยอัตโนมัติ โดยแบ่งได้เป็น 2
                          กรณีดังนี้
                        </Typography>
                        {[
                          <span>
                            {fontWeight("กรณีที่ 1 ")}
                            อินฟลูเอนเซอร์สมัครเข้าร่วมแคมเปญ “มากกว่า” ที่กำหนด
                            ระบบ “ตัดเงินมัดจำ” และคืนเงินส่วนต่างกลับไปยัง
                            Wallet ของผู้จ้างงาน
                            ผู้จ้างงานสามารถเลือกอินฟลูเอนเซอร์ก่อนวันปิดรับสมัครได้
                          </span>,
                          <span>
                            {fontWeight("กรณีที่ 2 ")}
                            อินฟลูเอนเซอร์สมัครเข้าร่วมแคมเปญ “น้อยกว่า”
                            ที่กำหนด ระบบ “คืนเงินมัดจำและเงินสำรองจ่าย”
                            กลับไปยัง Wallet ของผู้จ้างงาน
                          </span>,
                        ].map((li, index) => (
                          <Typography
                            key={index}
                            variant="body2"
                            component="li"
                            mt={2}
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Typography variant="caption" ml={5}>
                      หมายเหตุ การคืนเงินเข้า Wallet ระบบจะดำเนินการภายใน 24
                      ชั่วโมง
                    </Typography>
                  </Box>
                </StepContent>
              </Step>
            </>
          )}

          {(isSendProduct || isPlace) && (
            <Step expanded>
              <StepLabel
                sx={{ p: 0 }}
                icon={
                  <Icon
                    className={
                      isPlace ? "ico-hu-location" : "ico-hu-shipping-outline"
                    }
                    sx={classes.stepLabelIconActive}
                  />
                }
              >
                <Typography color="primary" variant="title1" ml={2}>
                  {isPlace
                    ? "กำหนดวันและเวลาในการเข้าไปถ่ายทำหรือรีวิวสถานที่"
                    : "การจัดส่งสินค้าให้แก่อินฟลูเอนเซอร์"}
                </Typography>
              </StepLabel>
              <StepContent sx={classes.lineIsActive}>
                <Box sx={classes.announceDate}>
                  <Box display="flex">
                    <Grid item xs={4}>
                      <Box mt={2}>
                        <Typography variant="title2">
                          {isPlace
                            ? "กำหนดวันการถ่ายรีวิว"
                            : "จะจัดส่งสินค้าภายในวันที่"}
                        </Typography>
                        <span className="require">*</span>
                      </Box>
                    </Grid>

                    <Grid item xs={8}>
                      {isPlace ? (
                        <DateRangeInputCustom
                          setValue={setValue}
                          watch={watch}
                          startKeyName="timeline.shipping_date"
                          endKeyName="timeline.end_shipping_date"
                          placeholderFirst="วันที่เริ่มการถ่ายรีวิว"
                          placeholderSecond="วันที่สิ้นสุดการถ่ายรีวิว"
                          startError={errors?.timeline?.shipping_date?.message}
                          endError={
                            errors?.timeline?.end_shipping_date?.message
                          }
                          minDate={dayjs(watch("timeline.announce_date"))
                            .add(1, "day")
                            .format("YYYY-MM-DD")}
                        />
                      ) : (
                        <Box width="48%">
                          <DateInput
                            form={form}
                            keyName="timeline.shipping_date"
                            placeholder="วันจัดส่งสินค้า"
                            minDate={returnProdMinDate}
                            errors={errors?.timeline?.shipping_date?.message}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Box>
                  <Box
                    component="img"
                    width="100%"
                    src={isPlace ? locationImage : productImage}
                    alt="shipping"
                  />

                  <Box component="ul" ml={-5}>
                    <Typography component="li" variant="body2">
                      {isPlace
                        ? "ผู้จ้างกำหนดช่วงวันในการถ่ายรีวิว โดยอินฟลูเอนเซอร์ต้องทำการนัดหมายกับผู้จ้างงานก่อนเข้าไปถ่ายทำทุกครั้ง"
                        : "อินฟลูเอนเซอร์ควรได้รับสินค้าภายใน 5 วันหลังทำการจัดส่งโดยระยะเวลาในการจัดส่งขึ้นอยู่กับบริษัทและประเภทการจัดส่ง"}
                    </Typography>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          )}

          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={
                <Icon
                  className="ico-hu-content"
                  sx={classes.stepLabelIconActive}
                />
              }
            >
              <Typography color="primary" variant="title1" ml={2}>
                การตรวจ Draft จากอินฟลูเอนเซอร์
              </Typography>
            </StepLabel>
            <StepContent sx={classes.lineIsActive}>
              <Box sx={classes.announceDate}>
                {draftPhaseStepLabel(
                  "อินฟลูเอนเซอร์ส่ง Draft ครั้งแรก",
                  "influencer"
                )}
                <StepContent sx={classes.draftConnect}>
                  <Box ml={5}>
                    <Box display="flex">
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Typography variant="title2">
                          ส่ง Draft ครั้งแรกภายในวันที่
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={classes.draftBox}>
                          <Typography ml={5}>
                            {dateFormat(draftPhase?.[0]?.start)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <ul>
                      {(isNoShipping
                        ? [
                            "อินฟลูเอนเซอร์ต้องส่ง Draft ครั้งแรก ภายใน 5 วัน นับจากวันที่ประกาศผลผู้ผ่านคัดเลือก",
                            "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                          ]
                        : isSendProduct
                        ? [
                            "อินฟลูเอนเซอร์ต้องส่ง Draft ครั้งแรก ภายใน 10 วัน นับจากวันที่กำหนดส่งสินค้า",
                            "หลังจากได้รับของอินฟลูเอนเซอร์ต้องตรวจสอบสินค้าและแนบหลักฐานการรับก่อนเริ่มงานทุกครั้ง",
                            "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                          ]
                        : [
                            "อินฟลูเอนเซอร์ต้องส่ง Draft ครั้งแรก ภายใน 5 วัน นับจากวันสุดท้ายที่กำหนดให้ถ่ายรีวิวสถานที่",
                            "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                          ]
                      ).map((li, index) => (
                        <Typography
                          component="li"
                          key={index}
                          variant="body2"
                          ml={-5}
                        >
                          {li}
                        </Typography>
                      ))}
                    </ul>
                  </Box>
                </StepContent>

                {draftPhaseStepLabel(
                  "ผู้จ้างงานตรวจ Draft ครั้งที่ 1",
                  "employer"
                )}
                <StepContent sx={classes.draftConnect}>
                  <Box ml={5}>
                    <Box display="flex">
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Typography variant="title2">
                          ให้ Feedback ครั้งที่ 1/2 ภายในวันที่
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={classes.draftBox}>
                          <Typography ml={5}>
                            {dateFormat(draftPhase?.[1]?.start)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <ul>
                      {[
                        "ผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่กำหนดการส่ง Draft ครั้งแรก",
                        "หากผู้จ้างงานไม่ตรวจงานภายในระยะเวลาที่กำหนด ระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ",
                      ].map((li, index) => (
                        <Typography
                          component="li"
                          key={index}
                          variant="body2"
                          ml={-5}
                        >
                          {li}
                        </Typography>
                      ))}
                    </ul>
                  </Box>
                </StepContent>

                {draftPhaseStepLabel(
                  "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 1",
                  "influencer"
                )}
                <StepContent sx={classes.draftConnect}>
                  <Box ml={5}>
                    <Box display="flex">
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Typography variant="title2">
                          ส่ง Draft แก้ครั้งที่ 1/2 ภายในวันที่
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={classes.draftBox}>
                          <Typography ml={5}>
                            {dateFormat(draftPhase?.[2]?.start)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <ul>
                      {[
                        "อินฟลูเอนเซอร์ต้องส่ง Draft แก้ครั้งที่ 1/2 ภายใน 3 วัน หลังจากได้รับ Feedback ครั้งที่ 1/2 จากผู้จ้างงาน",
                        "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ ",
                      ].map((li, index) => (
                        <Typography
                          component="li"
                          key={index}
                          variant="body2"
                          ml={-5}
                        >
                          {li}
                        </Typography>
                      ))}
                    </ul>
                  </Box>
                </StepContent>

                {draftPhaseStepLabel(
                  "ผู้จ้างงานตรวจ Draft ครั้งที่ 2",
                  "employer"
                )}
                <StepContent sx={classes.draftConnect}>
                  <Box ml={5}>
                    <Box display="flex">
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Typography variant="title2">
                          ให้ Feedback ครั้งที่ 2/2 ภายในวันที่
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={classes.draftBox}>
                          <Typography ml={5}>
                            {dateFormat(draftPhase?.[3]?.start)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <ul>
                      {[
                        "ผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่กำหนดการส่ง Draft แก้ครั้งที่ 1/2",
                        "หากผู้จ้างงานไม่ตรวจงานภายในระยะเวลาที่กำหนด ระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ",
                      ].map((li, index) => (
                        <Typography
                          component="li"
                          key={index}
                          variant="body2"
                          ml={-5}
                        >
                          {li}
                        </Typography>
                      ))}
                    </ul>
                  </Box>
                </StepContent>

                {draftPhaseStepLabel(
                  "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 2",
                  "influencer"
                )}
                <StepContent sx={classes.draftConnect}>
                  <Box ml={5}>
                    <Box display="flex">
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Typography variant="title2">
                          ส่ง Draft แก้ครั้งที่ 2/2 ภายในวันที่
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={classes.draftBox}>
                          <Typography ml={5}>
                            {dateFormat(draftPhase?.[4]?.start)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <ul>
                      {[
                        "อินฟลูเอนเซอร์ต้องส่ง Draft แก้ครั้งที่ 2/2 ภายใน 3 วัน หลังจากได้รับ Feedback ครั้งที่ 2/2 จากผู้จ้างงาน",
                        "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                      ].map((li, index) => (
                        <Typography
                          component="li"
                          key={index}
                          variant="body2"
                          ml={-5}
                        >
                          {li}
                        </Typography>
                      ))}
                    </ul>
                  </Box>
                </StepContent>

                {draftPhaseStepLabel(
                  "ผู้จ้างงานตรวจ Draft ครั้งสุดท้าย",
                  "employer"
                )}
                <StepContent sx={{ ml: "23px", border: "none" }}>
                  <Box ml={6}>
                    <Box display="flex">
                      <Grid item xs={5} display="flex" alignItems="center">
                        <Typography variant="title2">
                          อนุมัติ Draft ครั้งสุดท้ายภายในวันที่
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box sx={classes.draftBox}>
                          <Typography ml={5}>
                            {dateFormat(draftPhase?.[4]?.end)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <Box
                      component="ul"
                      sx={{
                        "& li:nth-of-type(2)": {
                          ml: !isNoShipping ? 2 : undefined,
                        },
                      }}
                    >
                      {(isNoShipping
                        ? [
                            <span>
                              ช่วงโพสต์และแนบลิงค์ส่งงาน คือ การกำหนดช่วงวัน
                              ที่อินฟลูเอนเซอร์ทั้งหมดต้องโพสต์บนแพลตฟอร์ม
                            </span>,
                            <span>
                              ผู้จ้างงาน สามารถระบุ
                              {fontWeight(
                                " “วันและเวลาเฉพาะ(ภายในช่วงวันของโพสต์และแนบลิงค์ส่งงาน)” "
                              )}
                              ให้แก่อินฟลูเอนเซอร์รายบุคคลได้ หลังจากอนุมัติ
                              Draft ให้แก่อินฟลูเอนเซอร์นั้นๆ"
                            </span>,
                            <span>
                              อินฟลูเอนเซอร์ต้องแนบลิงค์ส่งงานของตัวเองภายใน 2
                              ชั่วโมงหลังเผยแพร่โพสต์
                            </span>,
                          ]
                        : [
                            "ในการตรวจครั้งสุดท้าย ผู้จ้างงานสามารถให้ความคิดเห็นโดยรวมแก่อินฟลูเอนเซอร์ได้เท่านั้น โดยผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 2/2",
                            "หาก Draft แก้ครั้งที่ 2/2 ของอินฟลูเอนเซอร์ “ไม่ผ่าน” ผู้จ้างงานสามารถ “ยกเลิกงานอินฟลูเอนเซอร์” ได้ โดยอินฟลูเอนเซอร์จะได้รับค่าจ้างตามอัตราค่ามัดจำแคมเปญ ของค่าจ้างทั้งหมดที่อินฟลูเอนเซอร์จะได้รับ",
                            "กรณีที่ผู้จ้างงาน ไม่ตรวจ Draft ภายในระยะเวลาที่กำหนดระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ.",
                          ]
                      ).map((li, index) => (
                        <Typography
                          component="li"
                          key={index}
                          variant="body2"
                          ml={-5}
                        >
                          {li}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </StepContent>
              </Box>
            </StepContent>
          </Step>

          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={
                <Icon
                  className="ico-hu-publishing"
                  sx={classes.stepLabelIconActive}
                />
              }
            >
              <Typography color="primary" variant="title1" ml={2}>
                ช่วงโพสต์และแนบลิงค์ส่งงาน
              </Typography>
            </StepLabel>
            <StepContent sx={classes.lineIsActive}>
              <Box sx={classes.announceDate}>
                <Box display="flex">
                  <Grid item xs={3.5} display="flex" alignItems="center">
                    <Typography variant="title2">
                      ช่วงโพสต์และแนบลิงค์ส่งงาน
                    </Typography>
                    <span className="require">*</span>
                  </Grid>
                  <Grid item xs={8.5}>
                    <DateRangeInputCustom
                      setValue={setValue}
                      watch={watch}
                      startKeyName="timeline.start_publish_date"
                      endKeyName="timeline.end_publish_date"
                      placeholderFirst="วันที่เริ่มโพสต์งาน"
                      placeholderSecond="วันสุดท้ายที่โพสต์งาน"
                      minDate={publishMinDate}
                      startError={errors?.timeline?.start_publish_date?.message}
                      endError={errors?.timeline?.end_publish_date?.message}
                      disabled={
                        !timeline.start_recruiting_date ||
                        !timeline.end_recruiting_date ||
                        !timeline.announce_date
                      }
                    />
                  </Grid>
                </Box>
                <Box display="flex" mt="16px">
                  <Grid item xs={3.5} display="flex" alignItems="center">
                    <Typography variant="title2">ระยะเวลาในการโพสต์</Typography>
                    <span className="require">*</span>
                  </Grid>
                  <Grid item xs={8.5}>
                    <TextFieldCustom
                      variant="outlined"
                      placeholder="ระยะเวลาในการโพสต์"
                      type="number"
                      fullWidth
                      value={watch("timeline.posting_period") || ""}
                      onChange={(e) => {
                        setValue(
                          "timeline.posting_period",
                          Number(e.target.value)
                        );
                        setValue(
                          "timeline.completed_date",
                          dayjs(periodWatch).add(
                            Number(e.target.value),
                            "day"
                          ) as any
                        );
                      }}
                      error={!!errors?.timeline?.posting_period}
                      helperText={errors?.timeline?.posting_period?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">วัน</InputAdornment>
                        ),
                      }}
                      sx={{ ...classes.closeDate, width: "47.5%" }}
                    />
                  </Grid>
                </Box>
              </Box>
            </StepContent>
          </Step>

          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={
                <Icon
                  className="ico-hu-campaign-done"
                  sx={classes.stepLabelIconActive}
                />
              }
            >
              <Typography color="primary" variant="title1" ml={2}>
                สิ้นสุดแคมเปญ
              </Typography>
            </StepLabel>
            <StepContent sx={{ ml: "25px" }}>
              <Box display="flex" alignItems="center" sx={classes.announceDate}>
                <Grid item xs={3.5}>
                  <Typography variant="title2">วันสิ้นสุดแคมเปญ</Typography>
                </Grid>
                <Grid item xs={8.5}>
                  <Box sx={classes.completedDate}>
                    <Typography>
                      {watch("timeline.completed_date")
                        ? dayjs(watch("timeline.completed_date")).format(
                            "DD/MM/YYYY"
                          )
                        : "วันสุดท้าย + posting period"}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Grid>
    </CardContent>
  );
}
