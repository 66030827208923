import React, { useState } from "react";
// component
import HomeHeaderBarMobile from "./HomeHeaderBarMobile";
import HomeHeaderBarDesktop from "./HomeHeaderBarDesktop";
import SignInDialog from "../authentication-dialog/SignInDialog";
import SelectAccountDialog from "../authentication-dialog/SelectAccountDialog";
import ForgetPasswordDialog from "../authentication-dialog/ForgetPasswordDialog";
import ResetPasswordDialog from "./../authentication-dialog/ResetPasswordDialog";
// other
import useBreakpoint from "@utils/hooks/useBreakpoint";
import CreateAccountDialog from "../authentication-dialog/CreateAccountDialog";
import { IAccountSelected } from "../authentication-dialog/AuthenDialog.types";

export const menuList: { label: string; value: string; path: string }[] = [
  {
    label: "Influencer",
    value: "influencer",
    path: "/home/influencer",
  },
  {
    label: "Marketer",
    value: "marketer",
    path: "/home/marketer",
  },
  {
    label: "News & Events",
    value: "news&events",
    path: "/home/news-and-events",
  },
  {
    label: "Support",
    value: "support",
    path: "/home/support",
  },
  // {
  //   label: "FAQ",
  //   value: "pricing",
  //   path: "/home/pricing",
  // },
  {
    label: "Contact Us",
    value: "contact",
    path: "/home/contact",
  },
];

export default function HomeHeaderBar() {
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSelectAccount, setOpenSelectAccount] = useState(false);
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  const [accountSelected, setAccountSelected] = useState<IAccountSelected | null>(null);
  const [opeForgetPassword, setOpenForgetPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  const bp = useBreakpoint();

  const onOpenSelectAccountType = () => {
    setOpenSignIn(false);
    setOpenSelectAccount(true);
  };

  const onOpenCreateAccountDialog = (type: IAccountSelected) => {
    setOpenSignIn(false);
    setOpenSelectAccount(false);
    setAccountSelected(type);
    setOpenCreateAccount(true);
  };

  const onOpenForgetPassword = () => {
    setOpenSignIn(false);
    setOpenForgetPassword(true);
  };

  const onOpenResetPassword = () => {
    setOpenSignIn(false);
    setOpenForgetPassword(false);
    setOpenResetPassword(true);
  };

  const openSigIn = () => {
    setOpenSignIn(true);
    setOpenForgetPassword(false);
  };

  return (
    <React.Fragment>
      {bp === "xl" || bp === "lg" ? (
        <HomeHeaderBarDesktop setOpenSignIn={setOpenSignIn} />
      ) : (
        <HomeHeaderBarMobile
          breakpoint={bp}
          setOpenSignIn={setOpenSignIn}
        />
      )}

      {/* SignIn Dialog */}

      {/* -- UAT comment here -- */}
      <SignInDialog
        open={openSignIn}
        setOpenSignIn={setOpenSignIn}
        openSelectAccountType={onOpenSelectAccountType}
        openForgetPassword={onOpenForgetPassword}
      />
      {/* -- End comment -- */}

      {/* Select Account Dialog */}
      <SelectAccountDialog
        open={openSelectAccount}
        setOpen={setOpenSelectAccount}
        handleCreateAccount={onOpenCreateAccountDialog}
      />
      {/* Create Account Dialog */}
      {accountSelected !== null && (
        <CreateAccountDialog
          open={openCreateAccount}
          setOpen={setOpenCreateAccount}
          accountSelected={accountSelected}
        />
      )}

      {/* Forget Password Dialog */}
      <ForgetPasswordDialog
        open={opeForgetPassword}
        setOpenForgetPassword={setOpenForgetPassword}
        openResetPassword={onOpenResetPassword}
        openSignIn={openSigIn}
      />

      {/* Reset Password Dialog */}
      <ResetPasswordDialog
        open={openResetPassword}
        setOpenResetPassword={setOpenResetPassword}
        openSignIn={openSigIn}
      />
    </React.Fragment>
  );
}
