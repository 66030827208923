import dayjs from "dayjs";
import * as yup from "yup";

const string2Num = (data: string | number) => {
  if(data === null){
    return 0
  }else if(typeof data === "string"){
    return +data.replace(/,/gi, "")
  }else{
    return data
  }
}

const date2String = (date: string | Date) => {
  if(date){
    return dayjs(date).format("YYYY-MM-DD")
  }else{
    return ""
  }
}

const isProductReviewType = (type?: string) => {
  if(type === "send_return"){
    return true
  }else {
    return false
  }
}

export const createCampaignFormSchema = yup.object().shape({
  company: yup.object().shape({
    name: yup.string().required("Comapny name is require"),
    media: yup.object().shape({
      url: yup.string().required("Company logo is require"),
    }),
  }),
  name: yup.string().required("Campaign name is require"),
  campaign_type: yup.string().required("Campaign Type is require"),
  objective: yup.string().required("Objective is require"),
  review_type: yup.string().required("Review type is require"),
  product_review_type: yup.string().test("product_review_type",  function(value) {
    const ctx = this as any;
    if(ctx.from[1]?.value?.review_type === "product"){
      return value === undefined || value !== ""
    }else {
      return true
    }
  }),
  platform: yup.string().required("Platform is require"),
  job_limit: yup.number(),
  content_type_id: yup.object().shape({
    name: yup.string().required("content type is require")
  }),
  category_id: yup
    .array()
    .required("Category is require")
    .min(1, "Category is require")
    .max(3, "Category should be maximum 3 category"),
  brief: yup.object().shape({
    content: yup.object().shape({
      media: yup.object().shape({
        images: yup.array().required("Brief image is require").min(1, "Brief image is require"),
      }),
      description: yup.string().required("Description is require"),
    }),
    mood_and_tone: yup.object().shape({
      media: yup.object().shape({
        // images: yup.array(),
        // videos: yup.array()
      }),
      description: yup.string().required("Mood and Tone is require"),
    }),
    cover_image: yup.object().shape({
      url:yup.string().required("Cover image is require")
    }),
    key_message: yup.string().required("Key message is require"),
    emphasis: yup.string().required("Emphasis is require"),
    prohibition: yup.string().required("Prohibition is require"),
    term_and_condition: yup
      .array()
      .required("Term and condition is require")
      .min(1, "Term and condition is require")
      .transform((_, value) => {
        const conditonList = value.filter((cond:{order:number, message:string}) => cond.message !== "" );
        return conditonList.map((cond:{order:number, message:string}, index:number) => {
          return {
            ...cond,
            order: index + 1
          }
        })
      }),
  }),
  product: yup.object().nullable().shape({
    images: yup.array().nullable().test("images", "Images is require", function(value) {
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "product"){
        const isValue = value || [];
        return isValue.length ? true : false
      }else {
        return true
      }
    }),
    name: yup.string().nullable().test("name", "Product name is require", function(value) {
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "product"){
        const isValidValue = value !== undefined || value !== ""
        return isValidValue
      }else {
        return true
      }
    }),
    description: yup.string().nullable().test("description", "Description is require", function(value){
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "product"){
        const isValidValue = value !== undefined || value !== ""
        return isValidValue
      }else {
        return true
      }
    }),
    return_product_address: yup.object().nullable().shape({
      fullname: yup.string().test("fullname", "Full Name is Require", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          return value === undefined || value !== ""
        }else {
          return true
        }
      }).typeError("Full Name is require"),
      phone: yup.string().test("phone", "Phone Number is Require", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          return value === undefined || value !== ""
        }else {
          return true
        }
      }).typeError("Phone is require"),
      postcode: yup.string().test("postcode", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          return value === undefined || value !== ""
        }else {
          return true
        }
      }),
      province_id: yup.number().nullable().test("province_id","Province is require", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          return value !== undefined
        }else {
          return true
        }
      }).transform((_, value) => string2Num(value)),
      district_id: yup.number().nullable().test("district_id", "District is require", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          const isValid = value === undefined || value === 0
          return !isValid
        }else {
          return true
        }
      }).transform((_, value) => string2Num(value)),
      subdistrict_id: yup.number().nullable().test("subdistrict_id", "SubDistrict is require", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          const isValid = value === undefined || value === 0
          return !isValid
        }else {
          return true
        }
      }).transform((_, value) => string2Num(value)),
      address: yup.string().test("address","Address is require", function(value) {
        const ctx = this as any;
        if(isProductReviewType(ctx.from[2]?.value?.product_review_type)){
          return value !== undefined
        }else {
          return true
        }
      }),
    })
  }),
  audience: yup.object().shape({
    target: yup.object().shape({
      age: yup.object().shape({
        min: yup
          .number()
          .transform((_, value) => string2Num(value))
          .min(0, "Target Age should be 0 - 100")
          .max(100, "Target Age should be 0 - 100")
          .test("min", "Min should be less than Max", function(value){
            const maxVal = this.parent.max;
            const max = string2Num(maxVal);
            return value === undefined || max === undefined || value <= max;
          }),
        max: yup
          .number()
          .transform((_, value) => string2Num(value))
          .min(0, "Target Age should be 0 - 100")
          .max(100, "Target Age should be 0 - 100")
          .test("max", "Max should be more than min", function(value) {
            const minVal = this.parent.min;
            const min = string2Num(minVal);
            return value === undefined || min === undefined || value >= min;
          })
      }),
      follower: yup.object().shape({
        min: yup.number().transform(() => {
          return 0;
        }),
        max: yup.number().transform(() => {
          return 0;
        }),
      }),
      gender: yup.array(),
      location: yup.array(),
    }),
    influencer: yup.object().shape({
      age: yup.object().shape({
        min: yup
          .number()
          .transform((_, value) => string2Num(value))
          .min(0, "influencer Age should be 0 - 100")
          .max(100, "influencer Age should be 0 - 100")
          .test("min", "Min should be less than Max", function(value){
            const maxVal = this.parent.max;
            const max = string2Num(maxVal);
            return value === undefined || max === undefined || value <= max;
          }),
        max: yup
          .number()
          .transform((_, value) => string2Num(value))
          .min(0, "influencer Age should be 0 - 100 ")
          .max(100, "influencer Age should be 0 - 100")
          .test("max", "Max should be more than min", function(value) {
            const minVal = this.parent.min;
            const min = string2Num(minVal);
            return value === undefined || min === undefined || value >= min;
          }),
      }),
      follower: yup.object().shape({
        min: yup
          .number()
          .transform((_, value) => string2Num(value))
          .min(1000, "Min should be more than 1,000 follower")
          .test('min', 'Min should be less than Max', function (
            value
          ) {
            const maxVal = this.parent.max; 
            const max = string2Num(maxVal);
            return value === undefined || max === undefined || value <= max; //
          }),
        max: yup
          .number()
          .transform((_, value) => string2Num(value))
          .test('max', 'Max should be more than Min', function(value){
            const minVal = this.parent.min;
            const min = string2Num(minVal);
            return value === undefined || min === undefined || value >= min
          })
      }),
      gender: yup.array(),
      location: yup.array(),
    }),
  }),
  location: yup.object().nullable().shape({
    name: yup.string().test("name", "Name is require", function(value){
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "place"){
        return value === undefined || value !== ""
      }else {
        return true
      }
    }).typeError("Location Name is require"),
    contact: yup.string().test("contact", "Contact is require", function(value){
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "place"){
        return value === undefined || value !== ""
      }else {
        return true
      }
    }).typeError("Contact Name is require"),
    address: yup.string().test("address", "Address is require", function(value){
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "place"){
        return value === undefined || value !== ""
      }else {
        return true
      }
    }).typeError("Address is require"),
    phone: yup.string().test("phone", "Phone is require", function(value){
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "place"){
        return value === undefined || value !== ""
      }else {
        return true
      }
    }).typeError("Phone is require"),
    url: yup.string().test("url", "Url is require", function(value){
      const ctx = this as any;
      if(ctx.from[1]?.value?.review_type === "place"){
        return value === undefined || value !== ""
      }else {
        return true
      }
    }).typeError("Google Map Url is require")
  }),
  budget: yup.object().shape({
    total: yup
      .number()
      .required("Campaign Budget is require")
      .transform((_, value) => string2Num(value))
      .test("each_influnecer", "Each Influencer should be more than 200 Bath", function (value){
        const ctx = this as any;
        const eachInfluVal = this.parent.each_influencer;
        const isCampaignPrivate = ctx.from[1].value.campaign_type === "private"
        const eachInflu = string2Num(eachInfluVal);
        if(isCampaignPrivate){
          return value === undefined || value >= 2000
        }else{
          return eachInflu === undefined || eachInflu > 200
        }
      }),
    influencer_amount: yup
      .number()
      .required("Influencer amount is require")
      .transform((_, value) => string2Num(value))
      .test("each_influnecer", "Each Influencer should be more than 200 Bath", function (value){
        const ctx = this as any;
        const eachInfluVal = this.parent.each_influencer;
        const isCampaignPrivate = ctx.from[1].value.campaign_type === "private"
        const eachInflu = string2Num(eachInfluVal);
        if(isCampaignPrivate){
          return value === undefined || value !== 0
        }else {
          return eachInflu === undefined || eachInflu > 200
        }
      }),
    each_influencer: yup
      .number()
      .transform((_, value) => string2Num(value)),
      // .min(200, "Each Influencer should be more than 200 Bath"),
    reward: yup.array(),
  }),
  timeline: yup.object().shape({
    start_recruiting_date: yup
      .string()
      .required("Start recruiting date is require")
      .transform((_, value) => {
        return date2String(value)
      }),
    end_recruiting_date: yup
      .string()
      .required("End recruiting date is require")
      .transform((_, value) => {
        return date2String(value)
      }),
    announce_date: yup
      .string()
      .required("Announce date is require")
      .transform((_, value) => {
        return date2String(value);
      }),
    start_publish_date: yup
      .string()
      .required("Start publish date is require")
      .transform((_, value) => {
        return date2String(value)
      }),
    end_publish_date: yup
      .string()
      .required("End publish date is require")
      .transform((_, value) => {
        return date2String(value)
      }),
    completed_date: yup
      .string()
      .transform((_, value) => {
        return date2String(value)
      }),
    posting_period: yup
      .number()
      .required("Posting period is require")
      .typeError("Posting period is require"),
    shipping_date: yup
      .string()
      .transform((_, value) => {
        return date2String(value)
      }).test("shipping_date", "Shipping is require", function(value){
        const ctx = this as any
        const isNoshipping = ctx.from[1]?.value?.product_review_type === "no_shipping"
        const isPlace = ctx.from[1]?.value?.review_type === "place"
        if(isPlace || (!isPlace && !isNoshipping)){
          const isValidValue = value !== undefined || value !== ""
          return isValidValue
        }else {
          return true
        }
      }),
    end_shipping_date: yup
      .string()
      .transform((_, value) => {
        return date2String(value)
      }).test("end_shipping_date", "End Shipping is require", function(value){
        const ctx = this as any
        const isNoshipping = ctx.from[1]?.value?.product_review_type === "no_shipping"
        const isPlace = ctx.from[1]?.value?.review_type === "place"
        if(isPlace || (!isPlace && !isNoshipping)){
          const isValidValue = value !== undefined || value !== ""
          return isValidValue
        }else {
          return true
        }
      })
  }),
});