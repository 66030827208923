import { useContext } from "react";
// mui
import {
  Box,
  Chip,
  Icon,
  Paper,
  Stack,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import {
  TableRowCustom,
  TableCellCustom,
} from "@components/employer-expense/Expense.style";
// context and type
import TeamContext from "@contexts/Team";
import { ITableMembersProps } from "../Member.type";

export default function TableMembers(props: ITableMembersProps) {
  const { findRoleEmployer, handleDeleteEmployer, search } = props

  const teamContext = useContext(TeamContext);
  const teamInfo = teamContext?.teamInfo;

  const memberList = teamInfo?.employer?.filter((emp) =>
    emp.fullname.toLowerCase().includes(search.toLowerCase()) || emp.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCellCustom>
              เพิ่มสมาชิก ({teamInfo?.employer?.length}/
              {teamInfo?.package?.campaign_management.member_limit})
            </TableCellCustom>
            <TableCellCustom align="left">ตำแหน่ง</TableCellCustom>
            <TableCellCustom align="center">Financial</TableCellCustom>
            <TableCellCustom align="center">Campaign</TableCellCustom>
            <TableCellCustom align="center">สถานะ</TableCellCustom>
            <TableCellCustom align="center">จัดการ</TableCellCustom>
          </TableRow>
        </TableHead>

        <TableBody>
          {memberList?.map((item, index) => (
            <TableRowCustom key={index}>
              <TableCellCustom>
                <Stack direction="row" alignItems="center">
                  <Avatar src={item.profile_image_url} />
                  <Box ml={2.5}>
                    <Typography variant="title1" color="#8C499C">
                      {item?.fullname}
                    </Typography>
                    <Typography fontSize={["12px", "14px"]}>
                      {item?.email}
                    </Typography>
                  </Box>
                </Stack>
              </TableCellCustom>

              <TableCellCustom align="left">
                <Typography>{item?.role}</Typography>
              </TableCellCustom>

              <TableCellCustom align="center">
                {item?.permission.financial && (
                  <Icon
                    className="ico-hu-check-circle"
                    sx={{ color: "#8C499C" }}
                  />
                )}
              </TableCellCustom>

              <TableCellCustom align="center">
                {item?.permission.campaign && (
                  <Icon
                    className="ico-hu-check-circle"
                    sx={{ color: "#8C499C" }}
                  />
                )}
              </TableCellCustom>

              <TableCellCustom align="center">
                {item?.status === "active" ? (
                  <Chip
                    label="Active"
                    sx={{ backgroundColor: "#E7FFE8", color: "#4CAF50" }}
                  />
                ) : (
                  <Chip
                    label="Invited"
                    sx={{ backgroundColor: "#FFFBD4", color: "#FB8C00" }}
                  />
                )}
              </TableCellCustom>

              <TableCellCustom align="center">
                {(item?.role !== "Team Lead" ||
                  findRoleEmployer !== "Member") && (
                  <Icon
                    className="ico-hu-delete"
                    sx={{ color: "#FF8484", cursor: "pointer" }}
                    onClick={() => handleDeleteEmployer(item.id)}
                  />
                )}
              </TableCellCustom>
            </TableRowCustom>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
