// mui and icons
import {
  Box,
  Grid,
  Stack,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// style and type
import { useTimelineDialogStyle as classes } from "../CampaignDetail.style";
import { ITimelineDialogProps } from "../CampaignDetail.type";
// others
import dayjs from "dayjs";

export default function TimelinePlaceDialog(props: ITimelineDialogProps) {
  const { open, onClose, timeline } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>Timeline</Typography>
        <Typography>ระยะเวลาของแคมเปญ</Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.dialogContentBox}>
          <Grid container>
            <Grid item xs={12} mb={3}>
              <Box mt={5}>
                <Typography variant="title1" color="primary">
                  วันและเวลาในการเข้าไปถ่ายทำหรือรีวิวสถานที่
                </Typography>
                <Box display="flex" flexDirection={["column", "row"]} mt={5}>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center">
                    <Typography variant="title2">
                      ช่วงที่อินฟลูเอนเซอร์เข้าไปถ่ายรีวิว
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} mt={[2, 0]}>
                    <Stack direction="row" spacing={4} sx={classes.openDate}>
                      <Typography variant="title2">
                        {dayjs(timeline?.start_recruiting_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        -
                      </Box>
                      <Typography variant="title2">
                        {dayjs(timeline?.end_recruiting_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Box
              width="100%"
              component="img"
              src={require("@assets/images/campaign/location.png")}
            />
            <Box component="ul" ml={-4}>
              <Typography variant="body2" component="li">
                หลังประกาศผลผู้ผ่านเข้าร่วมแคมเปญ อินฟลูเอนเซอร์{" "}
                <span style={{ fontWeight: 500 }}>“ต้องติดต่อผู้จ้างงาน”</span>{" "}
                เพื่อทำการนัดหมายเข้าไปรีวิวสถานที่
              </Typography>
            </Box>
            <Box mt="32px" width="100%">
              <Typography variant="title1" color="primary">
                การส่ง Draft ครั้งแรก
              </Typography>
              <Box
                display="flex"
                mt={3}
                width="100%"
                flexDirection={["column", "row"]}
              >
                <Grid item xs={12} sm={4} display="flex" alignItems="center">
                  <Typography variant="title2">
                    ส่ง Draft ครั้งแรกภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} mt={[2, 0]}>
                  <Box sx={classes.openDate}>
                    <Typography variant="title2">
                      {dayjs(timeline?.end_recruiting_date)
                        .add(5, "day")
                        .format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Box>
            <Box component="ul" ml={-4}>
              {[
                "โดยการส่ง Draft ครั้งแรก ต้องส่งภายใน 5 วันนับจากวันสุดท้ายที่สามารถเข้าไปรีวิวสถานที่ได้",
                "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะดำเนินการยกเลิกงานโดยอัตโนมัติ",
              ].map((li, index) => (
                <Typography key={index} component="li" variant="body2">
                  {li}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
