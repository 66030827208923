// mui and type
import { Box, Grid, Typography } from "@mui/material";
import { IStatusInfluencerSelectedProps } from "../InfluencerList.type";

export default function EstimateSummary(props: IStatusInfluencerSelectedProps) {
  const { summary } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <div>
      <Typography variant="h6">Estimate Summary</Typography>
      <Box sx={{ border: "1px solid #EBDFFF", borderRadius: "12px", mt:"15px" }}>
        <Grid container>
          {[
            {
              label: "จำนวนที่เลือก",
              value: summary?.job_count || 0
            },
            {
              label: "Followers",
              value: numberFormat.format(summary?.total_followers || 0)
            },
            {
              label: "Likes",
              value: numberFormat.format(summary?.total_likes || 0)
            },
            {
              label: "Comments",
              value: numberFormat.format(summary?.total_comments || 0)
            },
            {
              label: "Shares",
              value: numberFormat.format(summary?.total_shares || 0)
            },
            {
              label: "Budget",
              value: numberFormat.format(summary?.total_budget || 0)
            },
          ].map((item, index) => (
            <Grid item key={index} xs={6} sm={2}>
              <Box m="20px 0 20px 20px">
                <Typography variant="body1">{item.label}</Typography>
                <Typography variant="h5" fontWeight="600" color="#893DBD">
                  {item.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
