import { getMessaging, getToken } from "@firebase/messaging";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FIREBASE_VAPID_KEY } from "@utils/constants/api.constants";
import { getOperatingSystem } from "@utils/helpers/getOperatingSystem";
import firebaseApp from "../../firebase";
import { NotificationAPI } from "@api/global";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { useAppDispatch } from "@store/hook";
import { setEmployerNotification, setInfluencerNotification } from "@slices/Notification.slice";

export default function Notifications() {
  const [token, setToken] = useState("");
  const [permission, setPermission] = useState("")
  const [browserName, setBrowserName] = useState('');

  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();

  const messaging = getMessaging(firebaseApp);
  const activateMessages = () => {
    if (Notification.permission === "granted") {
      getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
      })
        .then((token) => {
          console.log("Token:", token);
          setToken(token);
        })
        .catch((error) => {
          console.log("Failed to retrieve token:", error);
          setToken("Failed to retrieve token:");
        });
    } else if (Notification.permission === "denied") {
      console.log("Notification permission denied");
      setToken("Notification permission denied");
    } else {
      getToken(messaging, {
        vapidKey: "FIREBASE_VAPID_KEY",
      })
        .then((token) => {
          console.log("Token:", token);
          setToken(token);
        })
        .catch((error) => {
          console.log("Failed to retrieve token:", error);
          setToken("Failed to retrieve token:");
        });
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          getToken(messaging, {
            vapidKey: "FIREBASE_VAPID_KEY",
          })
            .then((token) => {
              console.log("Token:", token);
              setToken(token);
            })
            .catch((error) => {
              console.log("Failed to retrieve token:", error);
              setToken("Failed to retrieve token:");
            });
        } else {
          console.log("Notification permission denied");
          setToken("Notification permission denied");
        }
      });
    }
  };

  useEffect(() => {
    function requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.log("เบราว์เซอร์นี้ไม่รองรับการแจ้งเตือน");
      } else {
        setPermission(Notification.permission)
        Notification.requestPermission()
      }
    }
    requestNotificationPermission()
  }, [])

  useEffect(() => {
    const userAgent = navigator.userAgent;
    
    if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      setBrowserName('chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowserName('firefox');
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      setBrowserName('safari');
    } else if (userAgent.includes('Edg')) {
      setBrowserName('edge');
    } else {
      setBrowserName('Unknown');
    }
  }, []);

  const sendNotification = (message:{ title:string, body:string }) => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(message.title, {
          body: message.body
        })

        notification.onclick = () => {
          window.open("/my-jobs", "_blank")
        }
      }
    });
  };

  const onAddFCM = () => {
    NotificationAPI.addFCMClient({ user_id:session_token?.user_id || "", token: localStorage.getItem("notification_token") || "" }).then((res) => {
      console.log("response", res)
    }).catch((err) => {
      console.log("error", err)
    })
  }

  const getNotificationList = () => {
    NotificationAPI.getNotificationList().then((noti) => {
      if (session_token?.role === "influencer") {
        dispatch(setInfluencerNotification(noti));
      } else if (session_token?.role === "employer") {
        dispatch(setEmployerNotification(noti));
      }
    });
  }

  return (
    <Box
      className="container-main"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h5">
        Browser: {getOperatingSystem()}
      </Typography>
      <Button
        variant="contained"
        onClick={activateMessages}
        sx={{ my: "20px" }}
      >
        Generate Token
      </Button>
      <Button
        variant="contained"
        onClick={() => sendNotification({title:"test", body:"test"})}
        sx={{ my: "20px" }}
      >
        Send Notification
      </Button>
      <Typography>Token : {token} </Typography>
      <Typography>Operation : {browserName}</Typography>
      <Typography>Permission : {permission}</Typography>
      <Button onClick={() => navigator.clipboard.writeText(token)}>Coppy</Button>
      <Button onClick={onAddFCM}>Add FCM</Button>
      <Button onClick={getNotificationList}>Get Notifications</Button>
    </Box>
  );
}
