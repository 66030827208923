import { useEffect, useState } from "react";
// mui and icon
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
// style and component
import { useEmployerDraftApprovalStyle as classes } from "./DraftApproved.style";
import DatePost from "@components/employer-job/job-draft-approved/DatePost";
import ProductReturn from "@components/employer-job/job-draft-approved/ProductReturn";
import PublishedLink from "@components/employer-job/job-draft-approved/PublishedLink";
import AdditionalComment from "@components/employer-job/job-draft-approved/AdditionalComment";
import ReviewPostInfluencer from "@components/employer-job/employer-review-post/post-default/InfluencerSocialPost";
// redux
import {
  selectEmployerJobInfo,
  getEmployerJobInfoAsync,
  selectEmployerJobInfoOnload,
} from "@slices/EmployerJobInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// api and type
import { JobAPI } from "@api/global";
import { IInitJobForEmployerForm } from "./DraftApproved.type";
// others
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loading from "@components/global/loading/Loading";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

export const initJobForEmployerForm: IInitJobForEmployerForm = {
  product_shipping: {
    note: "",
    address: "",
    contact: "",
    tracking_no: "",
    shipping_type: "",
  },
  conversion: {
    lead_link: "",
    coupon: "",
    coupon_name: "",
    start_validity_period: "",
    end_validity_period: "",
  },
  publish_range: {
    published_at: "",
    end_published_at: "",
    completed_at: "",
    start_time: "",
    end_time: "",
    note: "",
  },
};

export default function JobDraftApproved() {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectEmployerJobInfo);
  const isLoading = useAppSelector(selectEmployerJobInfoOnload) || loading;
  const campaignInfo = jobInfo?.campaign_info;
  const publishedRange = jobInfo?.published_range;
  const jobStatus = jobInfo?.status;
  const isDraftPost = campaignInfo?.status === "published" && publishedRange?.published_at;
  
  const location = useLocation()
  const history = useHistory();
  const params = new URLSearchParams(location.search)
  const qCampaignId = params.get("c_id") || ""
  const { employerId, jobId } = useParams<{ employerId: string, jobId: string }>();

  const form = useForm<IInitJobForEmployerForm>({
    defaultValues: initJobForEmployerForm,
  });

  const { handleSubmit, watch } = form;

  useEffect(() => {
    const params = {
      employer_id: employerId,
      job_id: jobId,
    };
    dispatch(getEmployerJobInfoAsync(params));
  }, [dispatch, employerId, jobId]);

  const goBack = () => {
    if (jobStatus !== "published" && jobStatus !== "approved") {
      SwalCustom.fire({
        title: "ออกจากหน้านี้โดยไม่บันทึก",
        text: `ข้อมูลการ Review Post (${jobInfo?.social_account?.influencer_name}) จะหายไป`,
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonColor: "#E30D18",
        confirmButtonColor: "#4caf50",
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.goBack();
        }
      });
    } else {
      history.goBack()
    }
  };

  const onSubmitPost = (value: IInitJobForEmployerForm) => {
    setLoading(true);

    const range = value.publish_range;
    const data = {
      publish_range: {
        start_time: range ? `${range.start_time}:00` : "",
        end_time: range ? `${range.end_time}:00` : "",
        published_at: range
          ? dayjs(range.published_at).format("YYYY-MM-DD")
          : "",
        end_published_at: range
          ? dayjs(range.end_published_at).format("YYYY-MM-DD")
          : "",
        completed_at: "",
        note: range ? range.note : "",
      },
    };

    JobAPI.updateEmployerJob(employerId, jobId, data)
      .then((response) => {
        setLoading(false);
        SwalCustom.fire({
          icon: "success",
          title: "Success !",
          text: `Draft ของ ${response.social_account.name} ได้รับการอนุมัติแล้ว`,
          confirmButtonColor: "#4CAF50",
          confirmButtonText: `กลับไปหน้า "รอการตรวจสอบ" `,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/campaigns/${qCampaignId}/info?emp_id=${employerId}&tab=job`);
          }
        });
      })
      .catch((error) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: error ? `${error.message}` : "มีบางอย่างผิดพลาด",
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      })
      .finally(() => {
        setLoading(false);
      })
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Box sx={classes.goBack}>
        <span onClick={goBack}>
          <ChevronLeftOutlinedIcon />
          <span>Back</span>
        </span>
      </Box>
      <Box mb="24px">
        <Typography variant="h5">
          {jobStatus === "approved"
            ? "Draft Approval"
            : jobStatus === "published"
            ? "Published Post"
            : "Review Post"}
        </Typography>
        <Typography variant="subTitle1">
          {jobStatus === "approved"
            ? "Draft ของอินฟลูเอนเซอร์ผ่านแล้ว"
            : jobStatus === "published"
            ? "อินฟลูเอนเซอร์แนบลิ้งคส่งงาน"
            : "ตรวจงานอินฟลูเอนเซอร์"}
        </Typography>
      </Box>
      <Box sx={classes.draftCon}>
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <DatePost form={form} />
              </Grid>
              {jobInfo?.comment && (
                <Grid item xs={12}>
                  <AdditionalComment comment={jobInfo?.comment} />
                </Grid>
              )}
              {(jobStatus === "published" || jobStatus === "approved") &&
                campaignInfo?.product_review_type === "send_return" && (
                  <Grid item xs={12}>
                    <ProductReturn />
                  </Grid>
                )}
              {jobStatus === "published" && (
                <Grid item xs={12}>
                  <PublishedLink
                    plateform={campaignInfo?.platform || ""}
                    publishedLink={jobInfo?.published_link}
                  />
                </Grid>
              )}

              <Box sx={classes.submitDraftBtn}>
                {/* กรณีที่ approved job แต่ยังไม่ยืนยันวันโพสต์งาน */}
                {!isDraftPost ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon className="ico-hu-calendar-date" />}
                    onClick={() => handleSubmit(onSubmitPost)()}
                    disabled={
                      watch("publish_range.published_at") &&
                      watch("publish_range.start_time") &&
                      watch("publish_range.end_time")
                        ? false
                        : true
                    }
                  >
                    ยืนยันวันโพสต์งาน
                  </Button>
                ) : jobStatus === "published" ? (
                  <Button
                    variant="contained"
                    startIcon={<Icon className="ico-hu-report-outline" />}
                    onClick={() =>
                      history.push(`/influencers/jobs/${jobId}/post-report?c_id=${qCampaignId}`)
                    }
                  >
                    View Report
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <ReviewPostInfluencer jobInfo={jobInfo} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
