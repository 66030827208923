// mui
import { Box, Icon, Typography } from "@mui/material";

export default function Professional() {
  return (
    <div>
      <Box mt="16px" bgcolor="#F5EEFF" p="24px">
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              color: "#8C499C",
              bgcolor: "#fff",
              mr: "16px",
            }}
          >
            <Icon className="ico-hu-announce" />
          </Box>
          <div>
            <Typography variant="h6">เงื่อนไขการเชื่อมต่อ</Typography>
            <Typography>
              บัญชี Insagram ของคุณต้องเชื่อมต่อกับ Facebook Page เท่านั้น
            </Typography>
          </div>
        </Box>
      </Box>

      <Box mt="20px">
        <Typography variant="h6">
          ขั้นตอนการเชื่อมต่อบัญชี Instagram กับ Facebook Page
        </Typography>
        <Box mt="8px">
          <Box
            display="flex"
            alignItems="center"
            sx={{ mt: "8px", cursor: "pointer", color: "#8C499C" }}
            onClick={() => {
              window.open(
                "https://help.instagram.com/502981923235522/?helpref=uf_share"
              );
            }}
          >
            <Typography>ตั้งค่าบัญชีธุรกิจบน Instagram</Typography>
            <Icon
              className="ico-hu-open"
              sx={{ marginLeft: "8px", fontSize: "16px" }}
            />
          </Box>
          <div>
            <Typography mt="4px">
              1. เข้า Instagram แล้วกดไปที่การตั้งค่า
            </Typography>
            <Typography>
              2. จากนั้นคลิกปุ่ม "เปลี่ยนไปใช้งานบัญชีมืออาชีพ"
              และเลือกประเภทของบัญชี
            </Typography>
            <Typography>
              3. อ่านรายละเอียดบัญชีมืออาชีพที่เลือก จากนั้นคลิกปุ่ม "ถัดไป"
            </Typography>
            <Typography>
              4. เลือกหมวดหมู่อธิบายความเป็นคุณ แล้วคลิกปุ่ม "เรียบร้อย"
            </Typography>
            <Typography>5. การตั้งค่าเป็นบัญชีแบบธุรกิจสำเร็จ</Typography>
          </div>
        </Box>
        <Box mt="20px">
          <Box
            display="flex"
            alignItems="center"
            sx={{ color: "#8C499C", cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://www.facebook.com/business/help/898752960195806"
              );
            }}
          >
            <Typography>
              การเชื่อมต่อบัญชี Instagarm กับ Facebook Page
            </Typography>
            <Icon
              className="ico-hu-open"
              sx={{ marginLeft: "8px", fontSize: "16px" }}
            />
          </Box>
          <div>
            <Typography sx={{ marginTop: "4px" }}>
              1. เปิด Facebook Page ของคุณ คลิกที่ "จัดการ"
            </Typography>
            <Typography>
              2. มองหา บัญชีลิงค์ {">"} Instagram และคลิกที่ "เชื่อมต่อบัญชี"
            </Typography>
            <Typography>
              3. กดที่ปุ่ม "เชื่อมต่อ" อ่านรายละเอียดและกดปุ่ม "ยืนยัน" ตามลำดับ
            </Typography>
            <Typography>
              4. บัญชี Instagram เชื่อมต่อ Facebook Page เรียบร้อยแล้ว
            </Typography>
          </div>
        </Box>
      </Box>

      <Box mt="40px">
        <Typography variant="h6">
          ขั้นตอนการเชื่อมต่อบัญชี Perfessional Account
        </Typography>
        <Box mt="8px">
          {[
            `1. กดเมนู My Profile และมองหาแถบ Instagram จากนั้นคลิกปุ่ม "Professional Account"`,
            `2. เลือก "ฉันเชื่อมต่อแล้ว"`,
            `3. คลิกปุ่ม "Continue with Facebook" เพื่อทำการเชื่อมต่อ Instagram กับ HashU`,
            `4. เลือกบัญชี Instagram Business ที่ต้องการเชื่อมต่อบัญชีและอนุญาตให้ HashU ดำเนินการในสิทธิ์ต่างๆ แล้วกด "ตกลง"`,
            ` 5. เมื่อทำการเชื่อมต่อสำเร็จ ให้เลือก 3
            คอนเทนท์และกลุ่มเป้าหมายที่เกี่ยวข้องกับบัญชีมากที่สุด แล้วคลิกปุ่ม "Verify Instagram Account"`,
            `6. การเชื่อมต่อ Instagram แบบ Professional Account เสร็จสิ้น`
          ].map((text, index) => (
            <Typography key={index}>
              {text}
            </Typography>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          mt: "24px",
          mb: "24px",
          "& video": {
            width: "100%",
          },
        }}
      >
        <video
          height="auto"
          controls
          src="https://storage.googleapis.com/hashu-bucket-uat/media/videos/professional.mp4"
        />
      </Box>
    </div>
  );
}
