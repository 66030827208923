// mui
import { Box, Typography } from "@mui/material";
import { useDialogPermissionStyle as classes } from "./CreateTeam.style"
// global
import DialogScrollingCustom from "@global/dialog-scrolling-custom";

export default function DialogPermission({ open, onClose } : { open:boolean, onClose: () => void}) {

  return (
    <DialogScrollingCustom
      open={open}
      onClose={onClose}
      title="Permission"
      sx={classes.dialog}
    >
      <Box padding="32px 40px">
        <Box>
          <Typography variant="h6">Financial & Campaign</Typography>
          <Box mt="8px">
            <Typography>
              • ผู้ใช้งานที่ได้รับสิทธิ์ Financial & Campaign
              สามารถเข้าถึงและจัดการ เรื่องการเงินและแคมเปญในระบบได้
            </Typography>
          </Box>
        </Box>

        <Box m="32px 0">
          <Typography variant="h6">Financial Only</Typography>
          <Box mt="8px">
            <Typography>
              • ผู้ใช้งานสามารถเข้าถึงและจัดการได้เฉพาะเรื่อง "การเงิน" เท่านั้น
            </Typography>
            <Typography>
              • สามารถดูและจัดการข้อมูลการชำระเงิน เช่น การเติมเงินเข้า Team
              Wallet การชำระค่าบริการเพื่อประกาศแคมเปญ การชำระค่าสมาชิกหรือ
              Subscription ดูประวัติการเงิน เป็นต้น
            </Typography>
            <Typography>• ไม่สามารถสร้างและจัดการแคมเปญได้</Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6">Campaign Only</Typography>
          <Box mt="8px">
            <Typography>
              • ผู้ใช้สามารถเข้าถึงและจัดการได้เฉพาะเรื่อง "แคมเปญ" เท่านั้น
            </Typography>
            <Typography>
              • สิทธิ์ในการดูแลแคมเปญ เช่น การสร้างแคมเปญ การเลือกอินฟลูเอนเซอร์
              การตรวจงานและให้ฟีดแบคหรือข้อเสนอแนะแก่อินฟลูเอนเซอร์
              ติดตามผลและรายงานของแคมเปญ เป็นต้น
            </Typography>
            <Typography>• ไม่สามารถดูและจัดการข้อมูลการชำระเงินได้</Typography>
          </Box>
        </Box>
      </Box>
    </DialogScrollingCustom>
  );
}
