import { useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Paper,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  InputBase,
  TableHead,
  IconButton,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import {
  TableRowCustom,
  TableCellCustom,
} from "@components/employer-expense/Expense.style";
import { useEarningListStyle as classes } from "./EarningTab.style";
// type and api
import {
  IEarningInfo,
  IEarningParams,
  IEarningSummary,
} from "@api/influencer/type";
import { InfluencerAPI } from "@api/global";
// others
import { useParams } from "react-router-dom";
import BasicSelectCustom from "@global/basic-select";
import PaginationCustom from "@global/pagination-custom";
import GetPlatformColor from "@global/get-platform/GetPlatformColor";

const statusList = [
  {
    key: "",
    value: "",
    label: "All",
  },
  {
    key: "pending",
    value: "pending",
    label: "Pending",
  },
  {
    key: "paid",
    value: "paid",
    label: "Paid",
  },
];

const initEarningParams = {
  item_per_page: 10,
  page: 1,
  order_by: "transaction_id",
  order: "asc",
  search: "",
  status: "",
};

export default function EarningList({ setIsLoading }: { setIsLoading: (value: boolean) => void }) {
  const [earningInfo, setEarningInfo] = useState<IEarningInfo>();
  const [earningSummary, setEarningSummary] = useState<IEarningSummary>();
  const [earningParams, setEarningParams] = useState<IEarningParams>(initEarningParams);
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { influencerId } = useParams<{ influencerId: string }>();
  const earningList = earningInfo?.item || [];

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;

    InfluencerAPI.getInfluencerEarningList(influencerId, earningParams)
      .then((response) => {
        if (isMounted) {
          setEarningInfo(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          console.log("can not get earning list error : ", err);
          setIsLoading(false);
        }
      });

      return () => {
        isMounted = false
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earningParams, influencerId]);

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;

    InfluencerAPI.getInfluencerEarningSummary(influencerId)
      .then((response) => {
        if (isMounted) {
          setEarningSummary(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          console.log("can not get earning summary error : ", err);
        }
      });

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = (field: "status" | "search", value: string) => {
    let params = { ...earningParams };
    params[field] = value;
    setEarningParams(params);
  };

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
    setEarningParams((prev) => {
      return {
        ...prev,
        page: pageValue
      }
    })
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
    setEarningParams((prev) => {
      return {
        ...prev,
        item_per_page: newRowPerPage
      }
    })
  };

  return (
    <Box p="24px">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Box sx={classes.boxStatus}>
            <Box>
              <Typography>Total Earnings</Typography>
              <Typography variant="h4" color="#893DBD">
                {earningSummary?.total_earning_amount} THB
              </Typography>
            </Box>
            <Box sx={classes.boxIcon}>
              <Icon className="ico-hu-money" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={classes.boxStatus}>
            <Box>
              <Typography>Brands</Typography>
              <Typography variant="h4" color="#893DBD">
                {earningSummary?.total_brand}
              </Typography>
            </Box>
            <Box sx={classes.boxIcon}>
              <Icon className="ico-hu-brand" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={classes.boxStatus}>
            <Box>
              <Typography>Jobs</Typography>
              <Typography variant="h4" color="#893DBD">
                {earningSummary?.total_job}
              </Typography>
            </Box>
            <Box sx={classes.boxIcon}>
              <Icon className="ico-hu-job" />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={classes.boxHistiroy}>
        <Box width="100%">
          <Typography variant="title1">History</Typography>
        </Box>
        <Box gap={2} display="flex" width="100%">
          <Paper sx={classes.search}>
            <InputBase
              sx={{ ml: 4, flex: 1 }}
              placeholder="search by campaign name, platform"
              inputProps={{
                "aria-label": "addmin-console-search-earning",
              }}
              value={earningParams.search || ""}
              onChange={(e) => handleFilter("search", e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <Icon className="ico-hu-search" />
            </IconButton>
          </Paper>
          <BasicSelectCustom
            id="admin-console-select-earning"
            value={earningParams.status || ""}
            onChange={(e) => handleFilter("status", e.target.value)}
            data={statusList}
            valueKey="value"
            labelKey="label"
            placeholder="select status"
            sx={{ width: "240px" }}
          />
        </Box>
      </Box>
      {earningList.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {[
                  "แคมเปญ",
                  "ผู้รับรายได้",
                  "วันสิ้นสุดแคมเปญ",
                  "วันที่ได้รับเงิน",
                  "จำนวนเงิน",
                  "สถานะ",
                  "เอกสาร",
                ].map((title, index) => (
                  <TableCellCustom
                    key={index}
                    align={index <= 3 ? "left" : "center"}
                  >
                    {title}
                  </TableCellCustom>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {earningList.map((item, index) => (
                <TableRowCustom key={index}>
                  <TableCellCustom align="left">
                    <Stack direction="row" alignItems="center">
                      <Avatar src={item.brand_logo_image_url} />
                      <Box ml={2.5}>
                        <Typography variant="title1" color="#8C499C">
                          {item.campaign_name}
                        </Typography>
                        <Typography variant="body2">
                          Transaction ID : {item.transaction_id}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>

                  <TableCellCustom align="left">
                    <Box display="flex" alignItems="center">
                      <GetPlatformColor platform={item.platform} />
                      <Typography sx={{ ml: "4px" }}>
                        {item.fullname}
                      </Typography>
                    </Box>
                  </TableCellCustom>

                  <TableCellCustom align="left">
                    <Typography>{item.completed_date || "-"}</Typography>
                  </TableCellCustom>

                  <TableCellCustom align="left">
                    <Typography>{item.receipt_date || "-"}</Typography>
                  </TableCellCustom>

                  <TableCellCustom align="center">
                    <Typography variant="title1">
                      {item.amount} THB
                    </Typography>
                  </TableCellCustom>

                  <TableCellCustom align="center">
                    {item.status === "pending" ? (
                      <Box sx={classes.pending}>
                        <Typography variant="body2">Pending</Typography>
                      </Box>
                    ) : (
                      <Box sx={classes.paid}>
                        <Typography variant="body2">Paid</Typography>
                      </Box>
                    )}
                  </TableCellCustom>

                  <TableCellCustom align="center">
                    <Button
                      onClick={() => window.open(item.reciept_info.pdf_url)}
                      sx={classes.button}
                      disabled={item.status === "pending" ? true : false}
                    >
                      <Icon
                        className="ico-hu-download-circle"
                        sx={{ mr: "4px" }}
                      />
                      <Typography variant="buttonM">ใบเสร็จ</Typography>
                    </Button>
                    {item.amount >= 1000 && (
                      <Button
                        onClick={() => window.open(item.tax_info.pdf_url)}
                        sx={classes.taxButton}
                        disabled={item.status === "pending" ? true : false}
                      >
                        <Icon
                          className="ico-hu-download-circle"
                          sx={{ mr: "4px" }}
                        />
                        <Typography variant="buttonM">หัก ณ ที่จ่าย</Typography>
                      </Button>
                    )}
                  </TableCellCustom>
                </TableRowCustom>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noEarning}>
          <img
            src={require("@assets/images/infographic/influencer/search-not-found.png")}
            alt="no campaign"
          />
          <Box sx={classes.textNoEarning}>
            <Typography variant="h5" my="8px">
              ไม่พบผลการค้นหา
            </Typography>
            <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
          </Box>
        </Box>
      )}

      {earningList.length ? (
        <Box sx={classes.pagination}>
          <PaginationCustom
            page={paginate}
            count={paginate > 0 ? Math.ceil((earningInfo?.total_item || 0) / rowsPerPage) : 0}
            onPaginationChange={handleChangePage}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </Box>
  );
}
