// mui and style
import { Box, Button, Divider, Grid, Icon, Typography } from "@mui/material";
import { useEmployerInformationStyle as classes } from "../Detail.style";
// type and util
import { IEmployerDetailProps } from "../Detail.type";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";

export default function Shipping(props: IEmployerDetailProps) {
  const { employerInfo, setOpen } = props;

  const addressInfo = employerInfo?.address.shipping_address;

  return (
    <Box sx={classes.boxInformation}>
      <Box flexDirection={["column", "row"]} sx={classes.boxHeader}>
        <Typography variant="h6">Shipping Address</Typography>
        <Box mt={["12px", 0]}>
          <Button onClick={() => setOpen(true)} sx={classes.button}>
            {addressInfo?.province === null ? (
              <>
                <Icon className="ico-hu-add" />
                <Typography variant="buttonM">Add</Typography>
              </>
            ) : (
              <>
                <Icon className="ico-hu-edit" />
                <Typography variant="buttonM">Edit</Typography>
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Divider sx={classes.divider} />
      <Grid container m="24px" rowGap="20px">
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ชื่อ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography color="#5C5C5C">
            {addressInfo?.fullname || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">เบอร์โทรศัพท์</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography color="#5C5C5C">
            {addressInfo?.phone
              ? formatPhoneNumber(addressInfo?.phone || "")
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ที่อยู่ในการจัดส่ง</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          {addressInfo?.province ? (
            <Typography color="#5C5C5C">
              {addressInfo?.address || ""},{" "}
              {addressInfo?.subdistrict?.name_th || ""},{" "}
              {addressInfo?.district?.name_th || ""},{" "}
              {addressInfo?.province?.name_th || ""},{" "}
              {addressInfo?.postcode || ""}
            </Typography>
          ) : (
            <Typography color="#5C5C5C">-</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
