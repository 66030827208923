// mui and icon
import {
  Box,
  Theme,
  Paper,
  Dialog,
  Button,
  styled,
  SxProps,
  Typography,
  DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// asset
import logo from "@assets/images/logo/hashu-logo2.svg";

interface IDialogCustomProps extends DialogProps {
  title?: string;
  onClose: () => void;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  sx?: SxProps<Theme>;
}

const DialogCustomStyle = styled(Dialog)<DialogProps>(({ theme }) => ({
  "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const style = {
  absoluteBox: {
    position: "absolute",
    top: "1px",
    right: "2px",
    m: 0,
    p: 0,
  },
  closeButton: {
    position: "relative",
    height: "30px",
    maxWidth: "30px",
    minWidth: "30px",
    p: 0,
    bgcolor: "common.black",
    border: "none",
    "&:hover": {
      bgcolor: "common.black",
      border: "none",
    },
  },
  closeIcon: { fontSize: "20px" },
  paperBox: {
    backgroundColor: "transparent",
    margin: 3,
  },
  paper: {
    backgroundColor: "common.white",
    margin: 0,
    padding: "16px",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    m: 0,
    p: "8px 0 0px",
    width: "100%",
  },
  logoImg: {
    height: 120,
  },
};

function DialogAuthentication({
  title,
  onClose,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  sx,
  children,
  ...props
}: IDialogCustomProps) {
  return (
    <DialogCustomStyle
      onClose={(_, reason) => {
        if (!!disableEscapeKeyDown && !disableBackdropClick) {
          if (reason !== "backdropClick") {
            onClose();
          }
        } else if (!disableEscapeKeyDown && !!disableBackdropClick) {
          if (reason !== "escapeKeyDown") {
            onClose();
          }
        } else if (!disableEscapeKeyDown && !disableBackdropClick) {
          onClose();
        }
      }}
      scroll="body"
      {...props}
      sx={sx && (sx as object)}
    >
      <Box sx={style.absoluteBox}>
        <Button variant="contained" onClick={onClose} sx={style.closeButton}>
          <CloseIcon sx={style.closeIcon} />
        </Button>
      </Box>
      <Box sx={style.paperBox}>
        <Paper elevation={3} sx={style.paper}>
          <Box sx={style.dialogTitle}>
            <img src={logo} alt="Hashu Logo" style={style.logoImg} />
            {title && (
              <Typography variant="h4" fontWeight="bold" color="primary" pt={2}>
                {title}
              </Typography>
            )}
          </Box>
          {children}
        </Paper>
      </Box>
    </DialogCustomStyle>
  );
}

export default DialogAuthentication;
