import React, { useEffect, useState } from "react";
// mui and style
import { Box, Grid, Typography } from "@mui/material";
import { adminDashboardStyles as classes } from "@views/admin-dashboard/Dashboard.style";
// icon
// import CircleIcon from "@mui/icons-material/Circle";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// type and api
import {
  IRevenue,
  // IOrganization,
  IRevenueParams,
  IDashboardSummary,
  // IOrganizationParams,
} from "@api/dashboard/type";
import { DashboardAPI } from "@api/global";
// component
import BasicSelectCustom from "@components/global/basic-select";
import GetPlatformColor from "@components/global/get-platform/GetPlatformColor";
// other
import { options, optionsInfluencers } from "./Dashboard.chart";
import { Bar, Doughnut } from "react-chartjs-2";
import dayjs from "dayjs";

let date = new Date();
const revenueFilterDate = [
  {
    key: "day",
    value: "day",
    label: "Daily",
    start_date: dayjs(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 6
      ).toString()
    ).format("YYYY-MM-DD"),
    end_date: dayjs(date).format("YYYY-MM-DD"),
  },
  {
    key: "month",
    value: "month",
    label: "Month",
    start_date: dayjs(
      new Date(
        date.getFullYear(),
        date.getMonth() - 6,
        date.getDate()
      ).toString()
    ).format("YYYY-MM-DD"),
    end_date: dayjs(date).format("YYYY-MM-DD"),
  },
];

export default function DashboardStats(props: { summary?: IDashboardSummary }) {
  const { summary } = props;

  const [revenue, setRevenue] = useState<IRevenue | null>(null);
  // const [orgList, setOrgList] = useState<IOrganization | null>(null);
  const [revParams, setRevParams] = useState<IRevenueParams>({
    start_date: revenueFilterDate[0].start_date || "",
    end_date: revenueFilterDate[0].end_date || "",
    type_date: "day",
  });
  // const [orgParams, setOrgParams] = useState<IOrganizationParams>({
  //   item_per_page: 7,
  //   page: 1,
  // });
  
  // const totalPaginate = (orgList?.total_item || 0) / (orgList?.item_per_page || 0);
  const numberFormat = Intl.NumberFormat("en");

  useEffect(() => {
    let isMounted = true;
    const getRevenueList = (params: IRevenueParams) => {
      DashboardAPI.getRevenue(params)
        .then((response) => {
          if (isMounted) {
            setRevenue(response);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.log("get revenue list error:", err);
          }
        });
    };
    getRevenueList(revParams);

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revParams]);

  // useEffect(() => {
  //   let isMounted = true;
  //   const getOrganizationList = () => {
  //     DashboardAPI.getOrganization(orgParams)
  //       .then((response) => {
  //         if (isMounted) {
  //           setOrgList(response);
  //         }
  //       })
  //       .catch((err) => {
  //         if (isMounted) {
  //           console.log("can not get organization list error : ", err);
  //         }
  //       });
  //   };
  //   getOrganizationList();

  //   return () => {
  //     isMounted = false;
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orgParams]);

  const totalRevenue = [
    {
      key: "income",
      title: "Income",
      value: `${numberFormat.format(revenue?.total_income || 0)}`,
      color: "#542485",
    },
    {
      key: "expense",
      title: "Expense",
      value: `${numberFormat.format(revenue?.total_expense || 0)}`,
      color: "#ED1968",
    },
    {
      key: "profit",
      title: "Profile",
      value: `${numberFormat.format(revenue?.total_profit || 0)}`,
      color: "#FB79C3",
    },
  ];

  const labels = revenue?.item.map((item) => item.date);
  const dataRevenue = {
    labels,
    datasets: [
      {
        label: "Income",
        data: revenue?.item.map((item) => item.income),
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Expense",
        data: revenue?.item.map((item) => item.expense),
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Profit",
        data: revenue?.item.map((item) => item.profit),
        backgroundColor: "rgb(53, 162, 235)",
      },
    ],
  };

  const dataPlatform = {
    labels: ["Facebook", "Instagram", "Twitter", "Youtube", "Tiktok"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          summary?.influencer_total_info.facebook,
          summary?.influencer_total_info.instagram,
          summary?.influencer_total_info.twitter,
          summary?.influencer_total_info.youtube,
          summary?.influencer_total_info.tiktok,
        ],
        backgroundColor: [
          "#3b5998",
          "#d62976",
          "rgba(29, 155, 240, 1)",
          "#FF0000",
          "#282828",
        ],
        borderColor: [
          "#3b5998",
          "#d62976",
          "rgba(29, 155, 240, 1)",
          "#FF0000",
          "#282828",
        ],
        borderWidth: 1,
        color: "#fff",
      },
    ],
  };

  const platformList = [
    {
      platform: "facebook",
      label: "Facebook",
      value: `${summary?.influencer_total_info?.facebook || 0}`,
    },
    {
      platform: "instagram",
      label: "Instagram",
      value: `${summary?.influencer_total_info?.instagram || 0}`,
    },
    {
      platform: "twitter",
      label: "Twitter",
      value: `${summary?.influencer_total_info?.twitter || 0}`,
    },
    {
      platform: "youtube",
      label: "Youtube",
      value: `${summary?.influencer_total_info?.youtube || 0}`,
    },
    {
      platform: "tiktok",
      label: "Tiktok",
      value: `${summary?.influencer_total_info?.tiktok || 0}`,
    },
  ];

  const handleSortRevenue = (type: string) => {
    if (type === "month") {
      setRevParams({
        start_date: revenueFilterDate[1].start_date,
        end_date: revenueFilterDate[1].end_date,
        type_date: type,
      });
    } else {
      setRevParams({
        start_date: revenueFilterDate[0].start_date,
        end_date: revenueFilterDate[0].end_date,
        type_date: type,
      });
    }
  };

  // const handlePageChange = (type: "increment" | "decrement") => {
  //   setOrgParams((prev) => ({
  //     ...prev,
  //     page: type === "increment" ? prev.page + 1 : prev.page - 1,
  //   }));
  // };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={9}>
        <Box sx={classes.cardWrap} height="485px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Total Revenue</Typography>
            <BasicSelectCustom
              disabled={(revenue?.item || []).length <= 0}
              id="admin-console-select-sort-referral"
              value={revParams.type_date}
              onChange={(e) => handleSortRevenue(e.target.value)}
              data={revenueFilterDate}
              valueKey="value"
              labelKey="label"
              placeholder="select"
              sx={{ width: "150px" }}
            />
          </Box>
          <Grid container spacing={4} my="14px">
            {totalRevenue.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Box borderRadius="20px">
                  <Typography>{item.title}</Typography>
                  <Typography variant="h6" color={item.color}>
                    {item.value}
                  </Typography>
                  <Typography variant="body2">+ 10% last week</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box mt="16px"  sx={{"& > canvas":{ maxHeight:"315px" }}}>
            <Bar options={options} data={dataRevenue} />
          </Box>
        </Box>
      </Grid>

      {/* <Grid item xs={12} md={6} lg={3}>
        <Box sx={classes.cardWrap} height="485px" position="relative">
          <Typography variant="h6">Recent Organization</Typography>
          <Box display="flex" flexDirection="column" rowGap="10px" mt="16px">
            {(orgList?.item || []).map((item, index) => (
              <Box key={index} display="flex" columnGap="16px">
                <Avatar
                  sx={{ width: "48px", height: "48px", borderRadius: "8px" }}
                  src={item.organization_image_url}
                >
                  {item.name.charAt(0)}
                </Avatar>
                <div>
                  <Typography fontWeight={400}>{item.name}</Typography>
                  <Typography fontSize="12px" color="#0000008A">
                    {item.email}
                  </Typography>
                </div>
              </Box>
            ))}
          </Box>
          <Box sx={classes.orgPaginateWrap}>
            <Box
              ml="16px"
              display="flex"
              sx={{ cursor: "pointer", "&:hover": { color: "#542485" } }}
              onClick={() => {
                if (orgParams.page <= 1) return;
                handlePageChange("decrement");
              }}
            >
              <KeyboardArrowLeftIcon />
              <Typography sx={{ cursor: "pointer" }}>Prev</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
              width="30%"
            >
              {[...Array(Math.ceil(isNaN(totalPaginate) ? 0 : totalPaginate))].map((_, index) => (
                <CircleIcon
                  key={index}
                  sx={{
                    fontSize: "10px",
                    color: (orgList?.page || 0) === index + 1 ? "#ED1968" : "#E0E0E0",
                  }}
                />
              ))}
            </Box>
            <Box
              mr="16px"
              display="flex"
              sx={{ cursor: "pointer", "&:hover": { color: "#542485" } }}
              onClick={() => {
                if ((orgList?.page || 0) >= totalPaginate) return;
                handlePageChange("increment");
              }}
            >
              <Typography>Next</Typography>
              <KeyboardArrowRightIcon />
            </Box>
          </Box>
        </Box>
      </Grid> */}

      <Grid item xs={12} md={6} lg={3}>
        <Box sx={classes.cardWrap} height="485px">
          <Typography variant="h6">Influencers</Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="4px"
          >
            <Box width="80%" my="15px" maxHeight="230px" maxWidth="230px">
              <Doughnut options={optionsInfluencers} data={dataPlatform} />
            </Box>
            <Grid container rowGap="10px" width="60%">
              {platformList.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={9}>
                    <Box display="flex" columnGap="10px">
                      <GetPlatformColor platform={item.platform} />
                      <Typography textAlign="center">{item.label}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography textAlign="center">{item.value}</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
