import React, { useState } from "react";
// mui and icons
import {
  Box,
  Tab,
  Tabs,
  Menu,
  Button,
  Divider,
  MenuItem,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// style, type and other
import {
  IMyJobsStatusTabs,
  IMyJobsTabsItemLgProps,
  IInfluencerJobQueryParams,
} from "../MyJobs.type";
import { useHistory, useLocation } from "react-router-dom";
import { useMyJobsTabsItemLgStyle as classes } from "../MyJobs.style";

export default function MyJobsTabsItemLg(props: IMyJobsTabsItemLgProps) {
  const {
    myJobsTabs,
    selectStatusTab,
    myJobsTabsStatus,
    setHandleOpenFilter,
    handleSelectJobStatus,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const search = params.get("q") || "";
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const filterTotal = (status ? 1 : 0) + (search ? 1 : 0) + (platform ? 1 : 0) + (type ? 1 : 0) + (`${orderBy}${sort}` !== "dateDESC" ? 1 : 0);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const queryParams: IInfluencerJobQueryParams = {
    order_by: orderBy,
    page: page,
    platform: platform,
    type: type,
    sort: sort,
    status: status,
  };

  const handleSelectStatus = (data: IMyJobsStatusTabs) => {
    handleSelectJobStatus(data);
    setAnchorEl(null);

    const param = { ...queryParams };
    param["status"] = data.key;
    param["page"] = 1

    const queryString = Object.keys(param)
      .map((key) => `${key}=${param[key as keyof IInfluencerJobQueryParams]}`)
      .join("&");

    history.replace(`/my-jobs?${queryString}`);
  };

  const statusMatchTabIndex = (status: string | null) => {
    switch (status) {
      case "listing":
        return 1;
      case "working":
        return 2;
      case null:
        return 0;
      case "completed":
        return 4;
      case "canceled":
        return 5;
      default:
        return 3;
    }
  };

  return (
    <>
      <Tabs
        value={smScreen ? statusMatchTabIndex(status) : false}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        variant="scrollable"
        aria-label="scrollable auto tabs example"
        scrollButtons="auto"
        sx={classes.tabs}
      >
        {myJobsTabs.map((tab, index) => (
          <Tab
            key={index}
            id={`my-jobs-tab-${tab.id}`}
            aria-controls={open ? "my-jobs-tab-select-status" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            label={
              <Box
                display="flex"
                justifyContent={
                  tab.id === "select-status" ? "space-between" : "center"
                }
                width="100%"
              >
                <Typography
                  sx={{
                    color: (status || "") === tab.key ? tab.color : "#5C5C5C",
                  }}
                >
                  {`${tab.text} ${
                    Number(tab.total) > 0 ? `(${tab.total})` : ""
                  }`}
                </Typography>
                {tab.id === "select-status" && (
                  <ArrowDropDownIcon
                    sx={{
                      color: status === tab.key ? tab.color : "#5C5C5C",
                    }}
                  />
                )}
              </Box>
            }
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (tab.id === "select-status") {
                setAnchorEl(event.currentTarget);
              } else {
                const param = { ...queryParams };
                param["status"] = tab.key;

                const queryString = Object.keys(param)
                  .map(
                    (key) =>
                      `${key}=${param[key as keyof IInfluencerJobQueryParams]}`
                  )
                  .join("&");

                history.replace(`/my-jobs?${queryString}`);
              }
            }}
            sx={{
              ...classes.tabButton,
              "&:hover": {
                border: `1px solid ${tab.color}`,
                bgcolor: tab.bgColor,
                "& div p, div .MuiSvgIcon-root": {
                  color: tab.color,
                },
              },
              color: (status || "") === tab.key ? tab.color : "#5C5C5C",
              border: `1px solid ${
                (status || "") === tab.key ? tab.color : "#E0E0E0"
              }`,
              bgcolor: (status || "") === tab.key ? tab.bgColor : "#fff",
              borderRadius:
                tab.id === "select-status" && open ? "20px 20px 0 0" : "50px",
              minWidth: tab.id === "select-status" ? "185px" : "auto",
            }}
          />
        ))}
      </Tabs>
      <Divider
        sx={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
      />
      <Button
        startIcon={<FilterAltOutlinedIcon />}
        onClick={() => setHandleOpenFilter(true)}
        sx={classes.tabFilter}
      >
        Filter {filterTotal || ""}
      </Button>
      <Menu
        id="my-jobs-tab-select-status"
        aria-labelledby="my-jobs-tab-select-status"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            mt: "1px",
            minWidth: "182px",
            border: `1.5px solid ${selectStatusTab.color}`,
          },
        }}
      >
        {myJobsTabsStatus.map((tab, index) => (
          <MenuItem key={index} onClick={() => handleSelectStatus(tab)}>
            {`${tab.text} ${tab.total ? "(" + tab.total + ")" : ""}`}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
