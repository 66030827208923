import { useEffect, useState } from "react";
// mui and style
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useJobBoardStyle as classes } from "./JobBoard.style";
// api and type
import {
  ITotalFilter,
  IJobBoardFilter,
  IJobBoardParams,
} from "./JobBoard.type";
import { JobAPI } from "@api/global";
import { IGetJobBoardForInfluRes } from "@api/job/type";
// component
import JobBoardFilter from "@components/job-board/JobBoardFilter";
import PaginationCustom from "@components/global/pagination-custom";
import CampaignCard from "@components/global/campaign-card/CampaignCard";
import JobBoardTabs from "@components/job-board/job-board-tabs/JobBoardTabs";
// other
import { initStatusTotal } from "./JobBoard.constants";
import { scrollToTop } from "@utils/helpers/scrollToTop";
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation } from "react-router-dom";

export default function Jobboard() {
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const mdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const reviewType = params.get("r_type") || "";
  const campaignType = params.get("c_type") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";

  const [jobBoardInfo, setJobBoardInfo] =
    useState<IGetJobBoardForInfluRes | null>(null);
  const [statusTotal, setStatusTotal] = useState<ITotalFilter>(initStatusTotal);
  const [rowsPerPageData, setRowsPerPageData] = useState<number[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    xsScreen ? 20 : mdScreen ? 15 : lgScreen ? 16 : 15
  );

  const queryParams: IJobBoardParams = {
    order_by: orderBy,
    page: page,
    platform: platform,
    r_type: reviewType,
    c_type: campaignType,
    sort: sort,
    status: status,
  };

  useEffect(() => {
    setRowsPerPage(xsScreen ? 20 : mdScreen ? 15 : lgScreen ? 16 : 15);
    const screen = xsScreen
      ? [20]
      : mdScreen
      ? [15, 21, 27]
      : lgScreen
      ? [16, 20, 24]
      : [15, 20, 25];
    setRowsPerPageData(screen);
  }, [lgScreen, mdScreen, xsScreen]);

  useEffect(() => {
    let isMounted = true;
    function getJobBoardList() {
      setIsLoading(true);
      const params: IJobBoardFilter = {
        order_by: orderBy,
        page: page,
        platform: platform,
        item_per_page: rowsPerPage,
        review_type: reviewType,
        campaign_type: campaignType,
        sort: sort,
      };
      JobAPI.getJobBoard(params)
        .then((response) => {
          if (isMounted) {
            setJobBoardInfo(response);
            setStatusTotal((prev) => {
              return {
                ...prev,
                status: campaignType || "",
                offering: response.offering,
                public: response.public,
                total: response.total,
              };
            });
            setIsLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    }
    scrollToTop();
    getJobBoardList();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, page, platform, rowsPerPage, sort, reviewType, campaignType]);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    const param = { ...queryParams };
    param["page"] = pageValue;

    const queryString = Object.keys(param)
      .map((key) => `${key}=${param[key as keyof IJobBoardParams]}`)
      .join("&");

    history.replace(`/job-board?${queryString}`);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleViewJobBoardDetail = (campaignType: string, id: string) => {
    history.push(`/job-board/${id}/info?c_type=${campaignType}`);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <JobBoardFilter open={openFilter} onClose={setOpenFilter} />
      <Box sx={classes.headerTabbox}>
        <Box sx={classes.headerTab}>
          <Typography variant="h5" sx={classes.myJobTitle}>
            Job Board
          </Typography>
          <JobBoardTabs onClose={setOpenFilter} statusTotal={statusTotal} />
        </Box>
      </Box>
      <Box sx={classes.jobboardCon}>
        <Grid container spacing={5}>
          {(jobBoardInfo?.item || []).length ? (
            jobBoardInfo?.item.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                <Box
                  sx={classes.jobboardCard}
                  onClick={() => {
                    if (item.campaign_type === "private") {
                      handleViewJobBoardDetail("private", item.job_id);
                    } else {
                      handleViewJobBoardDetail("public", item.campaign_id);
                    }
                  }}
                >
                  <CampaignCard
                    isCampaign={true}
                    name={item.name}
                    limit={item.job_limit}
                    update={item.updated_at}
                    platform={item.platform}
                    category={item.category}
                    jobCount={item.job_count}
                    avgBudget={item.avg_budget}
                    status={item.campaign_type}
                    contentType={item.content_type}
                    coverUrl={item.cover_image?.url}
                    companyUrl={item.company?.media?.url}
                  />
                </Box>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              minHeight={{ xs: "65vh", sm: "73vh", xl: "80vh" }}
            >
              <Box sx={classes.noCampaign}>
                {!statusTotal.status ? (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
                      alt="no campaign"
                    />
                    <Box sx={classes.noCampDiscBox}>
                      <Typography variant="h5" my="8px">
                        ไม่พบแคมเปญ
                      </Typography>
                      <Typography>
                        คุณยังไม่ได้เข้าร่วมแคมเปญ ไปหน้า “Job Board”
                        เพื่อหาค้นหาและสมัครเข้าร่วมแคมเปญที่คุณสนใจกันเลย
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/search-not-found.png")}
                      alt="search not found"
                    />
                    <Box sx={classes.noCampDiscBox}>
                      <Typography variant="h5" my="8px">
                        ไม่พบผลการค้นหา
                      </Typography>
                      <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      {(jobBoardInfo?.item || []).length ? (
        <Box sx={classes.paginationCon}>
          <PaginationCustom
            page={page}
            count={
              page > 0
                ? Math.ceil((jobBoardInfo?.total_item || 0) / rowsPerPage)
                : 0
            }
            onPaginationChange={handleChangePage}
            rowsPerPageData={rowsPerPageData}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </div>
  );
}
