export const useAddShippingStyle = {
  callBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },
  card: {
    padding: "24px",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px 0px #3535350D",
    bgcolor: "#fff",
    mt:"29px"
  },
  boxImage: {
    position: "relative",
    width: "100%",
    "& > img": {
      width: "100%",
      borderRadius: "10px",
      height: "100%",
      objectFit: "cover",
    },
  },
  boxDetail: {
    color: "#fff",
    position: "absolute",
    bottom: 0,
    left: 1,
    width: "100%",
    borderRadius: "0 0 10px 10px",
    backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))",
  },
  stackCategory: {
    mb: "4px",
    alignItems: "center",
    flexDirection:"row",
    columnGap:2,
    color: "#fff",
    "& .MuiSvgIcon-root": {
      fontSize: "6px",
    },
  },
  textCategory: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "12px",
  },
  buttonSubmit: {
    minWidth: "240px",
    backgroundColor: "#8C499C",
    boxShadow: "0px 4px 10px",
    color: "white",
    "&:hover": {
      color: "#8C499C",
    },
  },
  //Add Shipping
  boxMain: {
    border: "2px solid #EBDFFF",
    borderRadius: "12px",
  },
  boxAddress: {
    backgroundColor: "#F5EEFF",
    padding: "16px",
    borderRadius: "8px",
  },
  leadLink:{
    p: "10px 16px 10px 16px",
    borderRadius: "50px",
    bgcolor: "#F5F6F8",
  }
};
