// mui and style
import { Avatar, Box, Card, Typography } from "@mui/material";
import { useOrganizationDetailStyle as classes } from "./CampaignDetail.style";
// type
import { IOrganizationDetailProps } from "./CampaignDetail.type";

export default function OrganizationDetail(props: IOrganizationDetailProps) {
  const { campaignInfo } = props;

  return (
    <Card sx={classes.cardCon}>
      <Box sx={classes.orgLogo}>
        <Avatar
          variant="square"
          src={campaignInfo?.organisation?.attachment?.url}
        />
      </Box>
      <Box sx={classes.orgDetail}>
        <Typography variant="h6">
          {campaignInfo?.organisation?.name}
        </Typography>
        <Typography variant="body1">
          {campaignInfo?.employer_email}
        </Typography>
      </Box>
    </Card>
  );
}
