// mui
import { Avatar, Box, Icon, Stack, Typography } from "@mui/material";
// type and style
import { IInfluencerPostProps } from "./campaignReport.type";
import { useInfluencerPostStyle as classes } from "./CampaignReport.style";
// util
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// others
import { FacebookImageFormat } from "../image-format/ImageFormat";

export default function InfluencerPost(props: IInfluencerPostProps) {
  const { socialPost } = props;

  const post = socialPost?.post;
  const { session_token } = getTokenFromStorage();
  const isInfluencer = session_token?.role === "influencer";

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Box height={isInfluencer ? "94%" : "95.2%"} sx={classes.con}>
      <Box sx={classes.socialProfile}>
        <Avatar src={socialPost?.profile_image_url} />
        <Typography ml={3}>{socialPost?.name}</Typography>
      </Box>
      <Typography
        sx={{ ...classes.desc, WebkitLineClamp: isInfluencer ? 3 : 11 }}
      >
        {post?.description}
      </Typography>
      <FacebookImageFormat item={post?.attachments || []} height="400px" />
      <Stack
        direction="row"
        spacing={5}
        sx={{ position: "absolute", bottom: 15 }}
      >
        {[
          {
            total: post?.report?.like,
            icon: <Icon className="ico-hu-like" />,
          },
          {
            total: post?.report?.comment,
            icon: <Icon className="ico-hu-comment" />,
          },
          {
            total: post?.report?.share,
            icon: <Icon className="ico-hu-share-m" />,
          },
        ].map((item, index) => (
          <Box display="flex" key={index}>
            {item.icon}
            <Typography ml="8px">{numberFormat.format(item.total || 0)}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
