import { useEffect, useState } from "react";
// mui and icons
import { Button, Grid, Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
// style and components
import { useJobDraftPostStyle as classes } from "../JobWorking.style";
import ReviewPostInfluencer from "../../../job-default/ReviewPostInfluencer";
import SubmitDraftSuccessDialog from "../../../job-dialog/SubmitDraftSuccessDialog";
import Caption from "../../../job-default/Caption";
import Place from "../../../job-default/Place";
import Images from "./Images";
import Videos from "./Videos";
// api and type
import { JobAPI } from "@api/global";
import { IIsApproved } from "@api/app-information/type";
import { IInitJobDraftPostForm } from "../../../JobsTab.type";
// redux
import {
  setInfluencerJobInfo,
  selectInfluencerJobInfo,
} from "@slices/InfluencerJobInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import { useForm } from "react-hook-form";
import Loading from "@global/loading/Loading";
import { useHistory, useParams } from "react-router-dom";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export const initJobDraftPostForm: IInitJobDraftPostForm = {
  job_id: "",
  influencer_id: "",
  employer_id: "",
  is_draft: true,
  description: {
    description: "",
    is_approve: "new",
    note: "",
  },
  attachments: [],
  external_file: [],
};

export default function JobDraftPost() {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDraft, setIsEditDraft] = useState(false);
  const [openSubmitDraftSuccess, setOpenSubmitDraftSuccess] = useState(false);

  const form = useForm<IInitJobDraftPostForm>({
    defaultValues: initJobDraftPostForm,
  });

  const { handleSubmit, setValue, reset, watch } = form;

  const history = useHistory();
  const { jobId } = useParams<{ jobId: string }>();

  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id || "";

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const isDraft = isEditDraft ? !isEditDraft : jobInfo?.is_draft || false;
  const contentType = jobInfo?.content_type;
  const isTextOnly = /(text only)/.test(contentType?.title || "");
  const isVideoType = contentType?.type === "video";
  const isPlace = jobInfo?.campaign_info.review_type === "place";

  useEffect(() => {
    if (jobInfo) {
      setValue("attachments", jobInfo.attachments);
      setValue("description", jobInfo.description);
      setValue("external_file", jobInfo.external_file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobInfo]);

  const onDraftJob = async (value: IInitJobDraftPostForm, isDraft: boolean) => {
    if (
      value.attachments.length <= 0 &&
      (value.external_file || [])?.length <= 0 &&
      !isTextOnly
    ) {
      SwalCustom.fire({
        icon: "error",
        title: "Oops !",
        text: "กรุณาอัปโหลดวิดีโอหรือแนบลิงก์ URL",
        confirmButtonColor: "#E30D18",
        confirmButtonText: `Close`,
      });
      return;
    }
    if (!value.description?.description && !isTextOnly) {
      SwalCustom.fire({
        icon: "error",
        title: "Oops !",
        text: "กรุณากรอกข้อความหรือแคปชั่นการโพสต์",
        confirmButtonColor: "#E30D18",
        confirmButtonText: `Close`,
      });
      return;
    }

    const data = {
      ...value,
      job_id: jobId,
      influencer_id: influencerId,
      is_draft: isDraft,
      attachments: value.attachments.map((att, index) => {
        return {
          ...att,
          order: index + 1,
          is_approve: "new" as IIsApproved,
        };
      }),
      external_file: value.external_file?.map((ext, index) => {
        return {
          ...ext,
          order: index + 1,
          is_approve: "new" as IIsApproved,
        };
      }),
    };
    setIsLoading(true);
    await JobAPI.addInfluencerJobRecord(data)
      .then((response) => {
        setIsLoading(false);
        reset(response);
        if (isDraft) {
          SwalCustom.fire({
            icon: "success",
            title: "Success !",
            text: "บันทึก Draft เรียบร้อย",
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "กลับไปหน้า My Jobs",
            showCancelButton: true,
            cancelButtonText: "Close",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/my-jobs");
            } else if (result.isDismissed) {
              setIsEditDraft(false);
              dispatch(setInfluencerJobInfo(response));
            }
          });
        } else {
          setOpenSubmitDraftSuccess(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading />

  return (
    <Grid container spacing={5}>
      {isPlace && (
        <Grid item xs={12}>
          <Place place={jobInfo.campaign_info.location} />
        </Grid>
      )}
      <Grid item xs={12} lg={8}>
        <Grid container spacing={5}>
          {!isTextOnly && (
            <Grid item xs={12}>
              {isVideoType ? (
                <Videos
                  form={form}
                  isDraft={isDraft}
                  videos={jobInfo?.attachments}
                  urls={watch("external_file")}
                />
              ) : (
                <Images
                  form={form}
                  isDraft={isDraft}
                  images={jobInfo?.attachments}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Caption
              jobInfo={jobInfo}
              register={form.register}
              isDraft={!isDraft}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              display={["flex", "none", "none", "flex"]}
              direction="row"
              spacing={5}
              sx={isDraft ? classes.submitBtn : classes.isDraftBtn}
            >
              {isDraft ? (
                <>
                  <Button
                    variant="contained"
                    startIcon={<EditRoundedIcon />}
                    onClick={() => setIsEditDraft(true)}
                  >
                    แก้ไข
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<ArrowCircleRightRoundedIcon />}
                    onClick={() =>
                      handleSubmit((e: IInitJobDraftPostForm) =>
                        onDraftJob(e, false)
                      )()
                    }
                  >
                    ส่ง Draft ให้ผู้จ้าง
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() =>
                      handleSubmit((e: IInitJobDraftPostForm) =>
                        onDraftJob(e, true)
                      )()
                    }
                  >
                    บันทึก Draft
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<ArrowCircleRightRoundedIcon />}
                    onClick={() =>
                      handleSubmit((e: IInitJobDraftPostForm) =>
                        onDraftJob(e, false)
                      )()
                    }
                  >
                    ส่ง Draft ให้ผู้จ้าง
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        mt={[0, -5, -5, 0]}
        display={["none", "initial"]}
      >
        <ReviewPostInfluencer
          jobDetailForInflu={jobInfo}
          watch={watch}
          fileType={jobInfo?.content_type.type}
          isEditDraft={!isDraft}
        />
        <Stack
          display={["none", "flex", "flex", "none"]}
          direction="row"
          spacing={5}
          mt={5}
          sx={isDraft ? classes.submitBtn : classes.isDraftBtn}
        >
          {isDraft ? (
            <>
              <Button
                variant="contained"
                startIcon={<EditRoundedIcon />}
                onClick={() => setIsEditDraft(true)}
              >
                แก้ไข
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowCircleRightRoundedIcon />}
                onClick={() =>
                  handleSubmit((e: IInitJobDraftPostForm) =>
                    onDraftJob(e, false)
                  )()
                }
              >
                ส่ง Draft ให้ผู้จ้าง
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() =>
                  handleSubmit((e: IInitJobDraftPostForm) =>
                    onDraftJob(e, true)
                  )()
                }
              >
                บันทึก Draft
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowCircleRightRoundedIcon />}
                onClick={() =>
                  handleSubmit((e: IInitJobDraftPostForm) =>
                    onDraftJob(e, false)
                  )()
                }
              >
                ส่ง Draft ให้ผู้จ้าง
              </Button>
            </>
          )}
        </Stack>
      </Grid>
      <SubmitDraftSuccessDialog
        open={openSubmitDraftSuccess}
        onClose={setOpenSubmitDraftSuccess}
      />
    </Grid>
  );
}
