export const initConditionList = [
  {
    label:
      "ข้าพเจ้าได้อ่านและทำความเข้าใจ เกี่ยวกับระยะเวลาของแคมเปญ (Timeline) เรียบร้อยแล้ว",
    isActive: false,
    case: "timeline",
  },
  {
    label: "ข้าพเจ้ายอมรับข้อกำหนดและเงื่อนไขการใช้บริการของ HashU",
    isActive: false,
    case: "service",
  },
];

export const initPrivateSummary = {
  followers: 0,
  likes: 0,
  comments: 0,
  shares: 0,
  budgets: 0,
  privateSelected: 0,
};

export const initInfluencerListParams = {
  page: 1,
  item_per_page: 20,
};
