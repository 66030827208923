import { useState } from "react";
// mui and style
import { Box, Button, Divider, Grid, Icon, Typography } from "@mui/material";
import { useJobOthersStyle as classes } from "./JobOthers.style";
// type and components
import { IMyJobTabProps } from "../../JobsTab.type";
import Images from "./job-draft-post/Images";
import Videos from "./job-draft-post/Videos";
import Caption from "../../job-default/Caption";
import PlaceDetailDialog from "../../job-dialog/PlaceDetailDialog";
import AdditionalComment from "../../job-default/AdditionalComment";
import ShippingProductLg from "../../job-dialog/ShippingProductDialog";
import ReviewPostInfluencer from "../../job-default/ReviewPostInfluencer";

export default function JobOthers(props: IMyJobTabProps) {
  const { isConfirmDelivery, jobsInfo } = props;

  const [viewPlaceDialog, setViewPlaceDialog] = useState(false);
  const [openShippingProduct, setOpenShippingProduct] = useState(false);

  const contentType = jobsInfo?.content_type;
  const isVideoType = contentType?.type === "video";
  const isTextOnly = /(text only)/.test(contentType?.title || "");
  const isPlace = jobsInfo?.campaign_info.review_type === "place";

  const attachments = (jobsInfo?.attachments || []).filter(
    (att) => att.is_approve === "new" || att.is_approve === "approved"
  );
  const externalFile = (jobsInfo?.external_file || []).filter(
    (ext) => ext.is_approve === "new" || ext.is_approve === "approved"
  );

  return (
    <Box sx={classes.jobTabsCon}>
      <Box sx={classes.jobTitle}>
        <Box display={{ xs: "none", sm: "initial" }}>
          <Typography>Job</Typography>
          <Typography>กรอกข้อมูลเพื่อส่งงาน</Typography>
        </Box>
        {isPlace ? (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-location" />}
            sx={classes.shippingProBtn}
            onClick={() => setViewPlaceDialog(true)}
          >
            Location
          </Button>
        ) : isConfirmDelivery ? (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-product-received" />}
            sx={classes.shippingProBtn}
            onClick={() => setOpenShippingProduct(true)}
          >
            Shipping Product
          </Button>
        ) : null}
      </Box>
      <Divider sx={classes.divider} />
      <Grid container spacing={5}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={5}>
            {jobsInfo?.comment && (
              <Grid item xs={12}>
                <AdditionalComment comment={jobsInfo?.comment} />
              </Grid>
            )}
            {!isTextOnly && (
              <Grid item xs={12}>
                {isVideoType ? (
                  <Videos attachments={attachments} urls={externalFile} />
                ) : (
                  <Images attachments={attachments} />
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Caption jobInfo={jobsInfo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} display={["none", "initial"]}>
          <ReviewPostInfluencer
            jobDetailForInflu={jobsInfo}
            fileType={jobsInfo?.content_type.type}
            attachments={attachments}
          />
        </Grid>
      </Grid>
      <ShippingProductLg
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
        jobInfluencerDetail={jobsInfo}
      />
      <PlaceDetailDialog
        openPlace={viewPlaceDialog}
        setOpenPlace={setViewPlaceDialog}
        place={jobsInfo?.campaign_info.location}
      />
    </Box>
  );
}
