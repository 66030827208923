export const useWalletBalanceStyle = {
  container: {
    mt: "24px",
    p: "24px",
    borderRadius: "15px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
  },
  boxCompensation: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    padding: "24px",
  },
  dividerCompensation: { margin: "16px 0", border: "1px dashed #EBDFFF" },
  boxDeposit: {
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#F5F6F8",
    marginTop: "14px",
  },
  dividerExpenditure: { margin: "24px 0" },
  boxButton: { display: "flex", justifyContent: "center", marginTop: "24px" },
  buttonStart: {
    width: ["100%", "20%"],
    backgroundColor: "#893DBD",
    color: "white",
    border: "2px solid #893DBD",
    "&:hover": {
      backgroundColor: "#893DBD",
      border: "2px solid #893DBD",
      color: "white",
      boxShadow: 3,
    },
  },
}

export const useWalletNotBalanceStyle = {
  container: {
    mt: "24px",
    p: "24px",
    borderRadius: "15px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
  },
  summaryWrap:{
    border: "1px solid #E0E0E0",
    borderRadius:"12px",
    padding: "24px"
  },
  depositeWrap:{
    bgcolor:"#F5F6F8",
    borderRadius: "8px",
    p:"12px"
  },
  accordiun: {
    mt: "15px",
    "& .css-g9zaru-MuiPaper-root-MuiAccordion-root.Mui-expanded": {
      m: 0,
    },
    "& > .Mui-expanded:not(.Mui-expanded:first-of-type)": {
      borderTop: "1px solid #E0E0E0",
    },
    "& .MuiAccordion-root:first-of-type": {
      borderRadius: "10px 10px 0 0",
    },
    "& .MuiAccordion-root:last-of-type": {
      borderRadius: "0 0 10px 10px",
    },
  },
  accordionDetails: {
    border: "1px solid #E0E0E0",
    p: "12px",
    borderRadius: "8px",
    height: "52px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  isWalletBalanceBox: {
    border: "1px solid #E0E0E0",
    p: "24px",
    borderRadius: "12px",
    mt: "16px",
  },
  qrPaymentImg: {
    width: "40%",
    borderRadius: "8px",
    "& > img": {
      objectFit: "contain",
    },
  },
  craditImg: {
    width: "55px",
    height: "40px",
    borderRadius: "8px",
    "& > img": {
      objectFit: "contain",
    },
  },
  methodImg: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
}

export const useChargeSuccessStyle = {
  dialogContent: {
    m: { xs: "10px 15px 15px 15px", xl: "24px 40px 24px 40px" },
  },
  dialogAction: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mt: "-30px",
    mb: "10px",
    "& button": {
      border: "none",
      "&:hover": { border: "none" },
      width: "212px",
    },
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > h5": {
      my: { xs: "15px", xl: "24px" },
    },
  },
  iconSuccess: {
    width: {
      xs: "120px",
      xl: "133px",
    },
    height: {
      xs: "120px",
      xl: "133px",
    },
    color: "#4CAF50",
  },
  detailBoxList: {
    display: "flex",
    flexDirection: "column",
    rowGap: 3,
  },
};

export const useChargeNotSuccessStyle = {
  dialogAction: {
    display: "flex",
    justifyContent: "center",
    mt: "-30px",
    mb: "10px",
    "& button": {
      border: "none",
      "&:hover": { border: "none" },
      width: "212px",
    },
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconNotSuccess: {
    width: "133px",
    height: "133px",
    color: "#E30D18",
  },
};

export const useDepositRateStyle = {
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      boxShadow: "none",
      overflowY: "initial",
    },
    "& .css-x29yx8, .css-bjtku8": {
      boxShadow: "none",
      overflowY: "initial",
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderBottom: "1px solid #EBDFFF",
    "& p:first-of-type": {
      fontSize: "20px",
      fontWeight: "500",
      color: "#8C499C",
      mb: "4px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
      fontSize: "14px",
    },
    height: "30px",
    bgcolor: "#8C499C",
  },
  iconBtn: {
    position: "absolute",
    right: "-18px",
    top: "-18px",
    "& > svg": {
      bgcolor: "#fff",
      borderRadius: "50%",
      fill: "black",
      fontSize: "30px",
    },
  },
  tableCon: {
    borderRadius: "12px 12px 0px 0px",
    boxShadow: "none",
  },
  tableBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
  },
};