import { useContext, useEffect, useState } from "react";
// mui
import {
  Box,
  Paper,
  Stack,
  Table,
  Avatar,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import {
  TableRowCustom,
  TableCellCustom,
} from "@components/employer-expense/Expense.style";
import { useLogsStyle as classes } from "./Logs.style";
// type and api
import {
  ITeamActivityLogsRes,
  ITeamActivityLogsParams,
} from "@api/team/type";
import { TeamAPI } from "@api/global";
// other
import TeamContext from "@contexts/Team";
import PaginationCustom from "@global/pagination-custom";
import dayjs from "dayjs";

export default function LogsTab() {
  const teamContext = useContext(TeamContext);
  const teamInfo = teamContext?.teamInfo;
  
  const [logParams, setLogParams] = useState<ITeamActivityLogsParams>({
    item_per_page: 10,
    page: 1,
    order: "id",
    order_by: "desc",
  });
  const [itemLogs, setItemLogs] = useState<ITeamActivityLogsRes>();
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setLogParams({
      item_per_page: rowsPerPage,
      page: paginate,
      order: "id",
      order_by: "desc",
    });
  }, [paginate, rowsPerPage]);

  useEffect(() => {
    if (!teamInfo?.id) return;
    TeamAPI.getTeamActivityLogs(teamInfo.id, logParams).then((response) => {
      setItemLogs(response);
    });
  }, [teamInfo?.id, logParams]);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  return (
    <Box sx={classes.card}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h5">Activity Log</Typography>
        <Typography variant="subTitle1">บันทึกกิจกรรม</Typography>
      </Box>
      <Divider sx={classes.divider} />
      {itemLogs?.item !== null ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCellCustom>วันที่และเวลา</TableCellCustom>
                <TableCellCustom align="left">ดำเนินการโดย</TableCellCustom>
                <TableCellCustom align="left">ประเภท</TableCellCustom>
                <TableCellCustom align="left">รายละเอียด</TableCellCustom>
              </TableRow>
            </TableHead>

            <TableBody>
              {itemLogs?.item.map((item, index) => (
                <TableRowCustom key={index}>
                  <TableCellCustom align="left">
                    <Typography>
                      {dayjs(item.create_at).format("DD/MM/YYYY HH:MM:ss")}
                    </Typography>
                  </TableCellCustom>

                  <TableCellCustom align="left">
                    <Stack direction="row" alignItems="center">
                      <Avatar src={item.profile_image_url} />
                      <Box ml={2.5}>
                        <Typography>{item.employer_management_name}</Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>

                  <TableCellCustom align="left">
                    <Typography>{item.activity}</Typography>
                  </TableCellCustom>

                  <TableCellCustom align="left">
                    <Typography>{item.information}</Typography>
                  </TableCellCustom>
                </TableRowCustom>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.boxNoItems}>
          <img
            src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
            alt="no campaign"
          />
          <Box sx={classes.boxDetaiNoItems}>
            <Typography variant="h5" my="8px">
              ไม่พบผลการค้นหา
            </Typography>
            <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
          </Box>
        </Box>
      )}

      {itemLogs?.item !== null ? (
        <Box sx={classes.paggination}>
          <PaginationCustom
            page={paginate}
            count={
              paginate > 0
                ? Math.ceil((itemLogs?.total_item || 0) / rowsPerPage)
                : 0
            }
            onPaginationChange={handleChangePage}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </Box>
  );
}
