// mui
import { Avatar } from "@mui/material";
//icon
import Tiktok from "@assets/images/social/tiktok-only.png";
import Twitter from "@assets/images/social/twitter_5969020.png";
import Youtube from "@assets/images/social/youtube-only.png";
import Facebook from "@assets/images/social/facebook-only.png";
import Instagram from "@assets/images/social/instagram-only.png";

export default function GetPlatformColor(props: any) {
  const { platform } = props;
  return (
    <div>
      {platform === "facebook" ? (
        <Avatar
          src={Facebook}
          alt="facebook-color"
          sx={{ width: "28px", height: "28px" }}
        />
      ) : platform === "twitter" ? (
        <Avatar
          src={Twitter}
          alt="twitter-color"
          sx={{ width: "28px", height: "28px" }}
        />
      ) : platform === "instagram" ? (
        <Avatar
          src={Instagram}
          alt="instagram-color"
          sx={{ width: "28px", height: "28px" }}
        />
      ) : platform === "youtube" ? (
        <Avatar
          src={Youtube}
          alt="youtube-color"
          sx={{ width: "28px", height: "28px" }}
        />
      ) : platform === "tiktok" ? (
        <Avatar
          src={Tiktok}
          alt="tiktok-color"
          sx={{ width: "28px", height: "28px" }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
