// mui and style
import { Box, Button, Divider, Grid, Icon, Typography } from "@mui/material";
import { useEmployerInformationStyle as classes } from "../Detail.style";
// type and util
import { IEmployerDetailProps } from "../Detail.type";
import { formatTaxId } from "@utils/helpers/formateNumber.helpers";

export default function Billing(props: IEmployerDetailProps) {
  const { employerInfo, setOpen } = props;

  const address = employerInfo?.address.billing_address;

  return (
    <Box mt="24px" sx={classes.boxInformation}>
      <Box flexDirection={["column", "row"]} sx={classes.boxHeader}>
        <Typography variant="h6">Billing Address</Typography>
        <Box mt={["12px", 0]}>
          <Button onClick={() => setOpen(true)} sx={classes.button}>
            {address?.province === null ? (
              <>
                <Icon className="ico-hu-add" />
                <Typography variant="buttonM">Add</Typography>
              </>
            ) : (
              <>
                <Icon className="ico-hu-edit" />
                <Typography variant="buttonM">Edit</Typography>
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Divider sx={classes.divider} />
      <Grid container m="24px" rowGap="20px">
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ชื่อ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography color="#5C5C5C">{address?.fullname || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">เลขประจำตัวผู้เสียภาษี</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography color="#5C5C5C">
            {address?.tax_id ? formatTaxId(address?.tax_id || "") : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ที่อยู่</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          {address?.province ? (
            <Typography color="#5C5C5C">
              {address?.address || ""}, {address?.subdistrict?.name_th || ""},{" "}
              {address?.district?.name_th || ""},{" "}
              {address?.province?.name_th || ""}, {address?.postcode || ""}
            </Typography>
          ) : (
            <Typography color="#5C5C5C">-</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
