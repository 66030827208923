import { Avatar } from "@mui/material";

export function CompanyIconSelected(props: {
  company?: string;
  width?: string;
  height?: string;
}) {
  const { company, width, height } = props;

  const style = {
    width: width ? width : "20px",
    height: height ? height : "20px",
    borderRadius: "5px",
  };

  if (company?.match(/(dhl)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/rdhl.png")}
      />
    );
  } else if (company?.match(/(j&t|j and t)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/rj&t.jpeg")}
      />
    );
  } else if (company?.match(/(shopee)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/kerry.jpeg")}
      />
    );
  } else if (company?.match(/(flash)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/rflash.jpeg")}
      />
    );
  } else if (company?.match(/(thai)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/thai-post.jpeg")}
      />
    );
  } else if (company?.match(/(kerry)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/kerry.jpeg")}
      />
    );
  } else if (company?.match(/(scg)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/rscg.jpeg")}
      />
    );
  } else if (company?.match(/(speed-d|speed)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/speed-d.jpeg")}
      />
    );
  } else if (company?.match(/(nim)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/rnim.jpeg")}
      />
    );
  } else if (company?.match(/(ninja van|ninjavan)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/ninjavan.jpeg")}
      />
    );
  } else if (company?.match(/(grab)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/grab.png")}
      />
    );
  } else if (company?.match(/(lineman|line man)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/lineman.png")}
      />
    );
  } else if (company?.match(/(lala move|lalamove|lala)/i)) {
    return (
      <Avatar
        sx={style}
        src={require("@assets/images/tarcking/lineman.png")}
      />
    );
  } else {
    return <Avatar sx={style} />;
  }
}
