// mui and style
import { Box, Button, Grid, Icon, Stack } from "@mui/material";
import { usePostSinglePhotoStyle as classes } from "../../EmployerJob.style";
// components and type
import PostCaption from "../post-default/PostCaption";
import PostPhotoAlbumImage from "./PostPhotoAlbumImage";
import AdditionalComment from "../post-default/AdditionalComment";
import { IEmployerReviewPostProps } from "../../EmployerJob.type";
// other
import { useHistory } from "react-router-dom";

export default function PostPhotoAlbum(props: IEmployerReviewPostProps) {
  const {
    form,
    jobInfo,
    onCancelJob,
    onRejectJob,
    onApproveJob,
  } = props;

  const { handleSubmit, watch } = form;

  const history = useHistory();

  const jobStatus = jobInfo?.status;
  const isJobRejected = jobStatus === "rejected";
  const isJobCancled = jobStatus === "canceled";
  const comment = jobInfo?.comment;
  const draftCount = jobInfo?.draft_count || 0;
  const campaignInfo = jobInfo?.campaign_info;
  const attachments = watch("attachments");
  const description = watch("description");

  const attNotApproveList = attachments.filter(
    (att) => att.is_approve === "not_approved"
  );

  const isDraftRejected =
    attachments.filter((att) => att.is_approve === "not_approved").length ||
    description.is_approve === "not_approved";

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PostPhotoAlbumImage
          form={form}
          jobInfo={jobInfo}
          isJobRejected={isJobRejected}
        />
      </Grid>
      <Grid item xs={12}>
        <PostCaption
          form={form}
          jobInfo={jobInfo}
          isJobRejected={isJobRejected}
        />
      </Grid>
      <Grid item xs={12}>
        {/* check comment, status "first_draft" = ตรวจงานครั้งแรก และ "waiting_for_approve" = แก้ไขแล้วรอตรวจ */}
        {(comment ||
          (isJobRejected && comment) ||
          jobStatus === "first_draft" ||
          jobStatus === "waiting_for_approve") && (
          <AdditionalComment
            isJobRejected={isJobRejected || isJobCancled}
            register={form.register}
            comment={comment}
            orgUrl={campaignInfo?.organisation?.attachment?.url}
          />
        )}
      </Grid>
      {!isJobRejected && !isJobCancled ? (
        <Grid item xs={12} display="flex" justifyContent="center">
          {/* การตรวจไม่ควรเกิน 2 ครั้ง ครั้งที่2 เป็นการตรวจครั้งสุดท้าย ว่าจะให้ผ่านหรือไม่ */}
          {draftCount < 2 ? (
            !isDraftRejected ? (
              <Button
                startIcon={
                  <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
                }
                // ทำการ check attachment กับ description ว่ามีผ่านหรือเปล่า ถ้าไม่มีให้มีทำการ disabled ปุ่ม 
                disabled={
                  attachments.filter((att) => att.is_approve !== "drop" && att.is_approve !== "approved").length 
                  || description.is_approve !== "approved"
                  ? true
                  : false
                }
                color="success"
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => handleSubmit(onApproveJob)()}
              >
                Draft ผ่านแล้วโพสต์ได้เลย
              </Button>
            ) : (
              <Button
                startIcon={
                  <Icon className="ico-hu-comment" sx={{ fontSize: "20px" }} />
                }
                // ทำการ check attachment กับ description ว่ามีต้องแก้ไขหรือเปล่า
                disabled={
                  attachments.filter((att) => att.is_approve === "not_approved" && !att.note).length 
                  || ((description.is_approve === "not_approved" || description.is_approve === "new") && !description.note)
                  ? true
                  : false
                }
                color="error"
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => handleSubmit(onRejectJob)()}
              >
                ส่ง Feedback ครั้งที่ {draftCount + 1}/2
              </Button>
            )
          ) : (
            <Stack direction="row" alignItems="center" spacing={2}>
              {!isDraftRejected ? (
                <Button
                  startIcon={
                    <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
                  }
                  color="success"
                  variant="contained"
                  sx={classes.submitBtn}
                  disabled={
                    attNotApproveList.length 
                    || description.is_approve !== "approved"
                    ? true
                    : false
                  }
                  onClick={() => handleSubmit(onApproveJob)()}
                >
                  Draft ผ่านการอนุมัติ
                </Button>
              ) : (
                <Button
                  startIcon={
                    <Icon className="ico-hu-close" sx={{ fontSize: "20px" }} />
                  }
                  color="error"
                  variant="contained"
                  disabled={
                    attNotApproveList.length ||
                    (description.is_approve === "not_approved" && !description.note)
                    ? true
                    : false
                  }
                  sx={classes.submitBtn}
                  onClick={() => onCancelJob()}
                >
                  ยกเลิกการจ้างงาน
                </Button>
              )}
            </Stack>
          )}
        </Grid>
      ) : (
        !isJobCancled && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="outlined"
                onClick={() => history.goBack()}
                sx={{ width: "250px" }}
              >
                กลับไปหน้า "รอแก้ไข"
              </Button>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
}
