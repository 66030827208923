
import { Box, CardHeader, Typography } from "@mui/material";
import InfluencerProfileInfo from "./profile-default/ProfileInfo";
import { useInfluencerProfileStyle as classes } from "./InfluencerProfile.style";
import InfluencerPortfolio from "./profile-default/Portfolio";

export default function InfluencerProfile() {
  return (
    <Box display="flex" flexDirection="column" rowGap="20px">
      {[
        { title: "My Profile", component: <InfluencerProfileInfo /> },
        { title: "My Portfolio", component: <InfluencerPortfolio /> },
      ].map((item, index) => (
        <Box key={index} sx={classes.card}>
          <CardHeader
            sx={classes.cardHeader}
            title={
              <Typography
                variant="body1"
                color="common.white"
                fontWeight="inherit"
              >
                {item.title}
              </Typography>
            }
          />
          {item.component}
        </Box>
      ))}
    </Box>
  );
}
