import React, { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Stack,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
import { useEditBillingStyle as classes } from "./EditBilling.style";
// api and type
import { IShippingAddress } from "@api/employer/type";
import { IDistrict, ISubdistrict } from "@api/app-information/type";
import { IAddressForm } from "@components/influencer-profile/InfluencerProfile.type";
// react-hook
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BillingFormSchema } from "@components/influencer-profile/InfluencerProfile.shema";
// others
import TextFieldCustom from "../text-field";
import BasicSelectCustom from "../basic-select";
import DialogScrollingCustom from "../dialog-scrolling-custom";
// json
import provinces from "@assets/json/province.json";
import districts from "@assets/json/district.json";
import subdistricts from "@assets/json/subDistrict.json";

const initBillingAddressForm: IAddressForm = {
  fullname: "",
  province: null,
  district: null,
  subdistrict: null,
  postcode: "",
  address: "",
  tax_id: "",
  phone: "",
};

export default function EditBillings(props: {
  open: boolean;
  onClose: () => void;
  onEditBilling: (value: any) => void;
  billing?: IShippingAddress;
}) {
  const { open, onClose, onEditBilling, billing } = props;

  const {
    watch,
    setValue,
    register,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddressForm>({
    resolver: yupResolver(BillingFormSchema),
    defaultValues: billing || initBillingAddressForm,
  });

  const [districtsList, setDistrictsList] = useState<IDistrict[]>([]);
  const [subdistrictsList, setSubdistrictsList] = useState<ISubdistrict[]>([]);

  useEffect(() => {
    if (billing) {
      const districtList = districts.filter(
        (district: IDistrict) => district.province_id === billing.province?.id
      );
      if (districtList) {
        setDistrictsList(districtList);
      }
  
      const subdistrictList = subdistricts.filter((subdistrict: ISubdistrict) =>
        subdistrict.district_id === billing.district?.id
      );
      if (subdistrictList) {
        setSubdistrictsList(subdistrictList);
      }
    }

    // eslint-disable-next-line
  }, [billing]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IAddressForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const handleTextIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = value
      .replace(/\s/g, "")
      .replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, "$1-$2-$3-$4-$5")
      .slice(0, 17);
    setValue("tax_id", formattedValue);
  };

  const handleChangeAddress = (
    e: { target: { value: number } },
    addressName: string
  ) => {
    const addressId = e.target.value;
    if (addressName === "province") {
      const provinceSelect = provinces.find(
        (province) => province.id === addressId
      );
      setValue("province", provinceSelect || null);
      setValue("district", null);
      setValue("subdistrict", null);
      setValue("postcode", "");
      const districtMatch = districts.filter((district: { province_id: number }) =>
          district.province_id === addressId
      );
      const districtList = districtMatch.map((district: IDistrict) => ({
        id: district.id,
        province_id: district.province_id,
        name_en: district.name_en,
        name_th: district.name_th,
      }));
      if (districtList) {
        setDistrictsList(districtList);
      }
    } else if (addressName === "district") {
      const districtSelect = districtsList.find(
        (district) => district.id === addressId
      );
      setValue("district", districtSelect || null);
      setValue("subdistrict", null);
      setValue("postcode", "");

      const subdistrict = subdistricts.filter((subdistrict: { district_id: number }) =>
        subdistrict.district_id === addressId
      );

      const subdistrictList = subdistrict?.map((subdistrict: ISubdistrict) => ({
        id: subdistrict.id,
        province_id: subdistrict.province_id,
        district_id: subdistrict.district_id,
        postcode: subdistrict.postcode,
        name_en: subdistrict.name_en,
        name_th: subdistrict.name_th,
      }));
      if (subdistrictList) {
        setSubdistrictsList(subdistrictList);
      }
    } else {
      const subdistrictSelect = subdistricts.find(
        (subdistrict: { id: number }) => subdistrict.id === addressId
      );
      setValue("subdistrict", subdistrictSelect || null);
      setValue("postcode", subdistrictSelect?.postcode || "");
    }
  };

  return (
    <DialogScrollingCustom
      title="Address"
      open={open}
      onClose={onClose}
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onEditBilling)}>
        <DialogContent sx={classes.dialogContent}>
          <Box px={2} py={0}>
            <Box display="flex" alignItems="center" gap={1}>
              <Icon className="ico-hu-bill-payment" color="primary" />
              <Typography fontWeight={500} ml={1}>
                Billing Address
              </Typography>
            </Box>
            <Stack my={2} rowGap={3}>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  ชื่อ<span className="require">*</span>
                </Typography>
                <TextFieldCustom
                  value={watch("fullname")}
                  inputProps={{
                    ...register("fullname", {
                      required: true,
                    }),
                  }}
                  variant="outlined"
                  placeholder="ชื่อ"
                  fullWidth
                  error={!!errors.fullname}
                  helperText={errors.fullname?.message}
                />
              </Box>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  เลขประจำตัวผู้เสียภาษี<span className="require">*</span>
                </Typography>
                <TextFieldCustom
                  value={watch("tax_id")}
                  onChange={handleTextIdChange}
                  variant="outlined"
                  placeholder="เลขประจำตัวผู้เสียภาษี"
                  fullWidth
                  error={!!errors.tax_id}
                  helperText={errors.tax_id?.message}
                />
              </Box>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  จังหวัด<span className="require">*</span>
                </Typography>
                <BasicSelectCustom
                  id="address-province-select-1"
                  value={watch("province.id") || ""}
                  onChange={(e) => handleChangeAddress(e, "province")}
                  data={provinces}
                  valueKey="id"
                  labelKey="name_th"
                  placeholder="จังหวัด"
                  fullWidth
                  error={!!errors.province}
                  helperText={(errors.province as any)?.message}
                />
              </Box>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  อำเภอ/เขต<span className="require">*</span>
                </Typography>
                <BasicSelectCustom
                  id="address-district-select-1"
                  value={watch("district.id") || ""}
                  onChange={(e) => handleChangeAddress(e, "district")}
                  data={districtsList}
                  valueKey="id"
                  labelKey="name_th"
                  placeholder="อำเภอ/เขต"
                  fullWidth
                  error={!!errors.district}
                  helperText={(errors.district as any)?.message}
                />
              </Box>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  ตำบล/แขวง<span className="require">*</span>
                </Typography>
                <BasicSelectCustom
                  id="address-sub-district-select-1"
                  value={watch("subdistrict.id") || ""}
                  onChange={(e) => handleChangeAddress(e, "subdistrict")}
                  data={subdistrictsList}
                  valueKey="id"
                  labelKey="name_th"
                  placeholder="ตำบล/แขวง"
                  fullWidth
                  error={!!errors.subdistrict}
                  helperText={(errors.subdistrict as any)?.message}
                />
              </Box>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  รหัสไปรษณีย์<span className="require">*</span>
                </Typography>
                <TextFieldCustom
                  value={watch("postcode")}
                  onChange={(e) => handleChange(e, "postcode")}
                  variant="outlined"
                  placeholder="รหัสไปรษณีย์"
                  fullWidth
                  error={!!errors.postcode}
                  helperText={errors.postcode?.message}
                />
              </Box>
              <Box display="flex">
                <Typography display="block" variant="title1" mt={2} width="45%">
                  ที่อยู่<span className="require">*</span>
                </Typography>
                <TextFieldCustom
                  value={getValues("address")}
                  onChange={(e) => handleChange(e, "address")}
                  variant="outlined"
                  multiline
                  rows={3}
                  placeholder="บ้านเลขที่, ตรอก, ซอย, หมู่ที่, ถนน"
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              </Box>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            mt: 2,
            pb: 5,
          }}
        >
          <SaveButton
            type="submit"
            variant="contained"
            startIcon={<Icon className="ico-hu-save" />}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
    </DialogScrollingCustom>
  );
}
