// mui and style
import { Box, Button, Grid, Icon, Stack } from "@mui/material";
import { usePostSinglePhotoStyle as classes } from "../../EmployerJob.style";
// type and component
import { IEmployerReviewPostProps } from "../../EmployerJob.type";
import AdditionalComment from "../post-default/AdditionalComment";
import PostCaption from "../post-default/PostCaption";
// other
import { useHistory } from "react-router-dom";

export default function PostTextOnly(props: IEmployerReviewPostProps) {
  const {
    form,
    jobInfo,
    onCancelJob,
    onRejectJob,
    onApproveJob,
  } = props;

  const { handleSubmit, watch } = form;

  const history = useHistory();

  const isJobRejected = jobInfo?.status === "rejected";
  const isJobCancled = jobInfo?.status === "canceled";
  const campaignInfo = jobInfo?.campaign_info;
  const draftCount = jobInfo?.draft_count || 0;
  const description = watch("description");

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PostCaption
          form={form}
          jobInfo={jobInfo}
          isJobRejected={isJobRejected}
        />
      </Grid>
      <Grid item xs={12}>
        {(jobInfo?.comment ||
          (isJobRejected && jobInfo?.comment) ||
          jobInfo?.status === "first_draft" ||
          jobInfo?.status === "waiting_for_approve") && (
          <AdditionalComment
            isJobRejected={isJobRejected || isJobCancled}
            register={form.register}
            comment={jobInfo?.comment}
            orgUrl={campaignInfo?.organisation?.attachment?.url}
          />
        )}
      </Grid>
      {!isJobRejected && !isJobCancled ? (
        <Grid item xs={12} display="flex" justifyContent="center">
          {draftCount < 2 ? (
            description.is_approve === "approved" ? (
              <Button
                startIcon={
                  <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
                }
                disabled={description.is_approve !== "approved" ? true : false}
                color="success"
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => handleSubmit(onApproveJob)()}
              >
                Draft ผ่านแล้วโพสต์ได้เลย
              </Button>
            ) : (
              <Button
                startIcon={
                  <Icon className="ico-hu-comment" sx={{ fontSize: "20px" }} />
                }
                disabled={
                  (description.is_approve === "not_approved" || description.is_approve === "new") 
                  && !description.note 
                  ? true
                  : false
                }
                color="error"
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => handleSubmit(onRejectJob)()}
              >
                ส่ง Feedback ครั้งที่ {draftCount + 1}/2
              </Button>
            )
          ) : (
            <Stack direction="row" alignItems="center" spacing={2}>
              {description.is_approve === "approved" ? (
                <Button
                  startIcon={
                    <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
                  }
                  color="success"
                  variant="contained"
                  sx={classes.submitBtn}
                  disabled={
                    description.is_approve !== "approved" ? true : false
                  }
                  onClick={() => handleSubmit(onApproveJob)()}
                >
                  Draft ผ่านการอนุมัติ
                </Button>
              ) : (
                <Button
                  startIcon={
                    <Icon className="ico-hu-close" sx={{ fontSize: "20px" }} />
                  }
                  color="error"
                  variant="contained"
                  disabled={
                    description.is_approve === "not_approved" &&
                    !description.note
                      ? true
                      : false
                  }
                  sx={classes.submitBtn}
                  onClick={() => onCancelJob()}
                >
                  ยกเลิกการจ้างงาน
                </Button>
              )}
            </Stack>
          )}
        </Grid>
      ) : (
        !isJobCancled && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="outlined"
                onClick={() => history.goBack()}
                sx={{ width: "250px" }}
              >
                กลับไปหน้า "รอแก้ไข"
              </Button>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
}
