import React, { useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Button,
  Divider,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useEditInfluencerRateCardStyle as classes } from "../InfluencerProfile.style";
// api and type
import { InfluencerAPI } from "@api/global";
import { IRateCard } from "@api/influencer/type";
import { IInitUpdateRateCardForm } from "@views/influencer-profile/MyProfile.type";
// redux
import { useAppDispatch } from "@store/hook";
import { updateInfluencerRateCard } from "@slices/InfluencerSocial.slice";
// other
import { UseFormReturn } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import TextFieldCustom from "@components/global/text-field";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

export default function EditInfluencerRateCard(props: {
  form: UseFormReturn<IInitUpdateRateCardForm, any>;
  setIsLoading: (value: boolean) => void;
}) {
  const { form, setIsLoading } = props;
  const [isUpdated, setIsUpdated] = useState(false);

  const { handleSubmit, watch, setValue } = form;

  const { push } = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get("platform") || "facebook";

  const dispatch = useAppDispatch();

  const filterRateCard = (type: string) => {
    return watch("rate_card")
      .filter((rate) => rate.content_type.includes(type))
      .sort((a, b) => a.content_type_id - b.content_type_id);
  };

  const numFormater = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
  });

  const textFieldValue = (value: string) => {
    if (Number(value) === 0) {
      return "";
    } else {
      return numFormater.format(Number(value.replace(/,/g, "")));
    }
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const { value } = e.target;
    const data = [...watch("rate_card")];
    const dataIndex = data.findIndex((item) => item.content_type_id === id);
    if (Number(value.replace(/,/g, ""))) {
      setValue(`rate_card.${dataIndex}.value`, value);
    } else if (!value) {
      setValue(`rate_card.${dataIndex}.value`, value);
    }
  };

  const onUpdateRateCard = async (value: IInitUpdateRateCardForm) => {
    const payload = {
      ...value,
      rate_card: value.rate_card.map((item) => {
        return {
          id: item.id,
          content_type_id: item.content_type_id,
          value: Number(item.value.replace(/,/g, "")),
        };
      }),
    };
    setIsUpdated(true);
    setIsLoading(true);
    if (isUpdated) return;
    InfluencerAPI.updateInfluencerRateCard(payload)
      .then((response) => {
        const ratCard = response.rate_card.map((item: IRateCard) => {
          return {
            id: item.id,
            content_type_id: item.content_type_id,
            value: item.price.value.toString(),
            content_type: item.content_type,
          };
        });
        const data = {
          platform: params.get("platform") || undefined,
          data: response.rate_card,
        };
        dispatch(updateInfluencerRateCard(data));
        setValue("rate_card", ratCard);
        setIsUpdated(false);
        setIsLoading(false);
        push(`/my-profile?tab=${tab}`);
      })
      .catch(() => {
        setIsUpdated(false);
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: "There are somthing wrong",
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onUpdateRateCard)}>
      <Box sx={classes.fomCon}>
        <Box m="0 0 24px 0">
          <Typography variant="h6">Rate Card</Typography>
          <Typography variant="subTitle2">
            คุณจะได้รับค่าตอบแทนตาม Rate Card ที่คุณกำหนด เมื่อผู้จ้างงาน
            “ยื่นข้อเสนอ” ให้คุณเท่านั้น
          </Typography>
        </Box>
        {filterRateCard("video").length ? (
          <div>
            <Typography variant="title1" textTransform="capitalize">
              Video
            </Typography>
            <Box display="flex" flexDirection="column" rowGap="12px" mt="8px">
              {filterRateCard("video").map((item, index) => (
                <Box key={index}>
                  <Box display="flex" alignItems="center" mb="8px">
                    <Icon className="ico-hu-p-vdo" />
                    <Typography
                      variant="title2"
                      ml="5px"
                      textTransform="capitalize"
                    >
                      {item?.content_type?.replace("video ", "")}
                    </Typography>
                  </Box>
                  <TextFieldCustom
                    value={textFieldValue(item.value)}
                    variant="outlined"
                    placeholder="rate card..."
                    fullWidth
                    onChange={(e) => onInputChange(e, item.content_type_id)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">THB</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ))}
            </Box>
            <Divider sx={{ my: "16px" }} />
          </div>
        ) : null}
        {filterRateCard("photo").length ? (
          <div>
            <Typography variant="title1" textTransform="capitalize">
              Photo
            </Typography>
            <Box display="flex" flexDirection="column" rowGap="12px" mt="8px">
              {filterRateCard("photo").map((item, index) => (
                <Box key={index}>
                  <Box display="flex" alignItems="center" mb="8px">
                    <Icon className="ico-hu-p-photo" />
                    <Typography
                      variant="title2"
                      ml="5px"
                      textTransform="capitalize"
                    >
                      {item?.content_type?.replace("video ", "")}
                    </Typography>
                  </Box>
                  <TextFieldCustom
                    value={textFieldValue(item.value)}
                    variant="outlined"
                    placeholder="rate card..."
                    fullWidth
                    onChange={(e) => onInputChange(e, item.content_type_id)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">THB</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ))}
            </Box>
            <Divider sx={{ my: "16px" }} />
          </div>
        ) : null}
        {filterRateCard("text").length ? (
          <div>
            <Typography variant="title1" textTransform="capitalize">
              Text
            </Typography>
            {filterRateCard("text").map((item, index) => (
              <Box key={index} mt="8px">
                <Box display="flex" alignItems="center" mb="8px">
                  <Icon className="ico-hu-p-text" />
                  <Typography
                    variant="title2"
                    ml="5px"
                    textTransform="capitalize"
                  >
                    {item?.content_type?.replace("video ", "")}
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={textFieldValue(item.value)}
                  variant="outlined"
                  placeholder="rate card..."
                  fullWidth
                  onChange={(e) => onInputChange(e, item.content_type_id)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">THB</InputAdornment>
                    ),
                  }}
                />
              </Box>
            ))}
          </div>
        ) : null}
      </Box>
      <Box sx={classes.submit}>
        <Button
          type="submit"
          variant="contained"
          disabled={isUpdated}
          startIcon={<Icon className="ico-hu-save" fontSize="small" />}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
