export const useCampaignJobTabStyle = {
  campJobCon: {
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "0px 20px 20px 20px",
    px: "24px",
    py: "20px",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    "& .MuiGrid-root": {
      display: "flex",
      justifyContent: "center",
    },
  },
  postCard: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    padding: "20px",
  },
  editButton: {
    bgcolor: "#FFFBD4",
    color: "#FB8C00",
    fontSize: "14px",
    mb: "20px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    borderRadius: "4px",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    mb: "16px",
    "& > img": {
      width: "56px",
      height: "56px",
      borderRadius: "8px",
      objectFit: "cover",
    },
    "& > div": {
      ml: "12px",
      maxWidth: {
        lg: "190px",
        xl: "230px",
      },
    },
  },
  cardHeaderTop: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "20px",
      fontWeight: "500",
      ml: "8px",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  cardHeaderBottom: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: "15px",
      height: "15px",
      ml: "3px",
    },
    "& p": {
      fontSize: "14px",
      ml: "12px",
    },
  },
  descPost: {
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontSize: "16px",
    minHeight: "75px",
    cursor: "pointer",
  },
  postImageItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  urlBox: {
    height: "300px",
    width: "100%",
    borderRadius: "8px",
    position: "relative",
    my: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "80px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardInfluPost: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .profile-image": {
      width: "100%",
      height: "268px",
      borderRadius: "4px",
      objectFit: "cover",
    },
  },
  imgItems: {
    display: "flex",
    justifyContent: "space-between",
    mt: "6px",
    "& img": {
      width: "85.6px",
      height: "85.6px",
      borderRadius: "4px",
      objectFit: "cover",
    },
  },
  productReturnBox: {
    border: "1px solid #8C499C",
    borderRadius: "8px",
    height: "90px",
    padding: "18px",
  },
  noProductReturnBox: {
    borderRadius: "8px",
    height: "90px",
    padding: "18px",
    bgcolor: "#F5F6F8",
  },
  returnProduct: {
    display: "flex",
    alignItems: "center",
  },
  returnProductComp: {
    display: "flex",
    alignItems: "center",
    columnGap:"15px",
    "& p:first-of-type": {
      my: "10px",
      color: "#5C5C5C",
    },
    "& p:last-of-type": {
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        width: "20px",
        height: "20px",
        borderRadius: "4px",
        mr: "8px",
      },
    },
  },
  trackingNo: {
    display: "flex",
    columnGap:"15px",
    alignItems: "center",
    mt: "-10px",
    "& p:first-of-type": {
      my: "10px",
      color: "#5C5C5C",
    },
    "& p:last-of-type": {
      my: "10px",
      color: "#5C5C5C",
    },
  },
  returnProdTitle: {
    display: "flex",
    alignItems: "center",
    "& > p":{
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  returnProdDate: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p:first-of-type": {
      my: "10px",
      color: "#5C5C5C",
    },
  },
  returnProdDetail: {
    display: "flex",
    justifyContent: "space-between",
    "& p:first-of-type": {
      color: "#5C5C5C",
    },
    "& p:last-of-type": {
      color: "#8C499C",
      fontWeight: 400,
      cursor: "pointer",
      "&:hover": { color: "#ED1968" },
    },
  },
  viewPostBtn: {
    display:"flex",
    alignItems:"center",
    border: "none",
    bgcolor: "#EBDFFF",
    color: "#8C499C",
    "&:hover": {
      border: "none",
      color: "#fff",
    },
    "& > span:nth-of-type(2)":{
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  reportBtn: {
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
  checkBtn: {
    maxHeight: "36px",
    bgcolor: "#EBDFFF",
    border: "none",
    color: "#8C499C",
    "&:hover": {
      border: "none",
      color: "#fff",
    },
    mt: "20px",
  },
};

export const useShippingProductDialogStyle = {
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    width: "89%",
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  trackingCode: {
    textDecoration: "underline",
    color: "#8C499C",
  },
  photos: {
    position: "relative",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: "110px",
      height: "110px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  photosItem: {
    position: "absolute",
    right: 15,
    width: "110px",
    height: "110px",
    borderRadius: "8px",
    bgcolor: "#000",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
  closeBtn: {
    mt: "20px",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
      maxHeight: "32px",
    },
  },
};
