import { useState } from "react";
// mui and image
import { Box, Grid, Icon, Link, Stack, Typography } from "@mui/material";
import HashuWhite from "@assets/images/logo/hashu-logo1.svg";
// style
import { ButtonTrial } from "./HomeFooter.styles";
import { useFooterStyle as classes } from "./HomeFooter.styles";
// type and component
import { IBreakpoint } from "@views/global/global.types";
import CreateAccountDialog from "../authentication-dialog/CreateAccountDialog";
// util
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";

function SocialContact({ bp }: { bp: IBreakpoint }) {
  return (
    <Box
      display="flex"
      justifyContent={["center", "flex-start"]}
      alignItems="center"
      gap={[8, 2, 2, 4]}
    >
      <Icon
        className="ico-hu-facebook"
        onClick={() => {
          window.open("https://www.facebook.com/HashU.co");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
          marginRight: ["0", "12px"],
        }}
      />
      <Icon
        className="ico-hu-instagram"
        onClick={() => {
          window.open("https://www.instagram.com/influ.hashu");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
          marginRight: ["0", "12px"],
        }}
      />
      <Icon
        className="ico-hu-tiktok"
        onClick={() => {
          window.open("https://www.tiktok.com/@hashu.co.official");
        }}
        sx={{
          color: "common.white",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
          marginRight: ["0", "12px"],
        }}
      />
      <Icon
        className="ico-hu-line"
        onClick={() => {
          window.open("https://lin.ee/dez0phm");
        }}
        sx={{
          color: "common.white",
          borderRadius: "4px",
          fontSize: setValueByBreakpoint(bp, "24px", "20px", "20px", "24px"),
          cursor: "pointer",
        }}
      />
    </Box>
  );
}

export default function HomeFooter() {
  const { session_token } = getTokenFromStorage();
  const [openCreateAccount, setOpenCreateAccount] = useState(false);

  const bp = useBreakpoint();

  return (
    <Box sx={classes.mainBox}>
      <Box px={[5, 5, 10, 30]} pt={10} pb={2}>
        <Grid container>
          <Grid item xs={12} sm={3} md={3} lg={4}>
            <Stack width={["auto", "fit-content"]}>
              <Box
                textAlign={setValueByBreakpoint(bp, "center", "left")}
                mb={[8, 4]}
              >
                <Link href="/" underline="hover">
                  <img
                    src={HashuWhite}
                    alt="Hashu White"
                    style={{
                      width: "auto",
                      height: setValueByBreakpoint(
                        bp,
                        "96px",
                        "120px",
                        "120px",
                        "140px"
                      ),
                    }}
                  />
                </Link>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Stack gap={3} mb={[14, 0]}>
              <Typography
                variant={setValueByBreakpoint(bp, "h5", null, null, "h4")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={2}
              >
                Explore
              </Typography>
              <Link href="/home/influencer" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Influencer
                </Typography>
              </Link>
              <Link href="/home/marketer" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Marketer
                </Typography>
              </Link>
              <Link href="/home/pricing" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Pricing
                </Typography>
              </Link>
              <Link href="/home/contact" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Contact Us
                </Typography>
              </Link>
              <Link href="/privacy" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Privacy Policy
                </Typography>
              </Link>
              <Link href="/terms-and-conditions" underline="hover">
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body2",
                    null,
                    null,
                    "body1"
                  )}
                  color="common.white"
                  align={setValueByBreakpoint(bp, "center", "left")}
                >
                  Terms and conditions
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Stack gap={3} mb={[14, 0]}>
              <Typography
                variant={setValueByBreakpoint(bp, "h5", null, null, "h4")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={2}
              >
                สมัครทดลองใช้
              </Typography>
              <Typography
                variant={setValueByBreakpoint(bp, "body2", null, null, "body1")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={[2, 3]}
              >
                แพลตฟอร์มรวมอินฟลูเอนเซอร์คุณภาพ<br/> ตอบโจทย์ทุกธุรกิจ
                รันแคมเปญได้เองอย่างมืออาชีพ <br/>
                และเข้าถึงกลุ่มเป้าหมายได้อย่างมีประสิทธิภาพ
              </Typography>
              {!session_token && (
                <Box textAlign={setValueByBreakpoint(bp, "center", "left")}>
                  <ButtonTrial
                    variant="contained"
                    disableElevation
                    onClick={() => setOpenCreateAccount(true)}
                  >
                    <Typography
                      variant="button"
                      color="secondary"
                      fontWeight="bold"
                    >
                      ทดลองใช้
                    </Typography>
                  </ButtonTrial>
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <Stack gap={3}>
              <Typography
                variant={setValueByBreakpoint(bp, "h5", null, null, "h4")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={2}
              >
                ติดต่อเรา
              </Typography>
              <Typography
                variant={setValueByBreakpoint(bp, "body2", null, null, "body1")}
                color="common.white"
                align={setValueByBreakpoint(bp, "center", "left")}
                mb={[2, 3]}
              >
                Info@hashu.co
                <br />
                Tel. 081-969-9888
              </Typography>

              <Box textAlign={setValueByBreakpoint(bp, "center", "left")}>
                {bp !== "xs" && <SocialContact bp={bp} />}
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {bp === "xs" && <SocialContact bp={bp} />}
        <Box mt={[10, 6, 6, 3]} mb={3}>
          <Typography
            component="p"
            variant={setValueByBreakpoint(
              bp,
              "caption",
              "caption",
              "caption",
              "body2"
            )}
            color="common.white"
            align={setValueByBreakpoint(bp, "center", "left")}
          >
            Copyright &copy; 2022 Hashu All Rights Reserved.
          </Typography>
        </Box>
      </Box>

      {/* -- UAT comment here -- */}
      <CreateAccountDialog
        open={openCreateAccount}
        setOpen={setOpenCreateAccount}
        accountSelected={"marketer"}
      />
      {/* -- End comment -- */}
    </Box>
  );
}
