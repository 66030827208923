// mui and icon
import { Box, Button, Icon, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// style and image
import { useInstagramProfessionalStyle as classes } from "../../../InfluencerSocial.style";
import Instagram from "@assets/images/social/ig-glyph-gradient.png";
// type and util
import { IProfessionalConnected } from "../../../InfluencerSocial.type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { SYNC_INSTAGRAM_AND_TWITTER } from "@utils/constants/api.constants";

export default function InstagramProfConnected(props: IProfessionalConnected) {
  const { setConnected, setContinues, continues } = props;

  const syncInstagram = () => {
    const { access_token } = getTokenFromStorage();
    window.location.replace(
      `${SYNC_INSTAGRAM_AND_TWITTER}/instagram/login?token=${access_token}`
    );
  };

  return (
    <>
      <Box
        sx={{ position: "absolute", cursor: "pointer" }}
        onClick={
          continues
            ? () => setContinues && setContinues(false)
            : () => setConnected && setConnected(false)
        }
      >
        <ArrowBackIosIcon />
      </Box>
      <Stack sx={classes.container}>
        <Box sx={classes.syncSocial} py={3}>
          <img
            src={Instagram}
            alt="facebook_sync"
            style={{
              height: "72px",
              width: "72px",
            }}
          />
        </Box>
        <Box textAlign="center" width="40%" pt={4}>
          <Typography variant="h5" color="inherit">
            Connect with Instagram
          </Typography>
        </Box>
        <Box textAlign="center" py={5}>
          <Typography variant="body1">
            Amplify your chance to reach out to more brands and followers
          </Typography>
          <Typography variant="body1">
            Simply add more of your social channels below.
          </Typography>
        </Box>
        <Box textAlign="center" py={5}>
          <Button sx={classes.buttonSyncInstagram} onClick={syncInstagram}>
            <Icon
              className="ico-hu-facebook"
              sx={{ marginRight: ["-24px", "-48px"] }}
            />
            <Typography variant="buttonM">Continue with Facebook</Typography>
          </Button>
        </Box>
      </Stack>
    </>
  );
}
