import { useEffect, useState } from "react";
// mui
import { Box, Button, Typography } from "@mui/material";
// api and type
import { TeamAPI } from "@api/global";
import { IInvitationMember } from "./Team.type";
import { ITeamInfo } from "@api/team/type";
// component
import OldTeam from "@components/team-management/OldTeam";
import Member from "@components/team-management/member/Member";
import Invitations from "@components/team-management/Invitations";
import CreateTeam from "@components/team-management/create-team/CreateTeam";
// redux
import { useAppDispatch } from "@store/hook";
import { getEmployerInfoAsync } from "@slices/EmployerInfo.slice";
import { getWalletBalanceAsync } from "@slices/PackageInfo.slice";
// other
import TeamContext from "@contexts/Team";
import Loading from "@components/global/loading/Loading";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import TeamSkeleton from "@components/global/skeleton/Team";

export default function TeamManagement() {
  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  const employerId = session_token?.employer_id;

  const [invitation, setInvitation] = useState<IInvitationMember[]>([]);
  const [openInvitation, setOpenInvitation] = useState(false);
  const [openOldTeam, setOpenOldTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [oldTeamIndex, setOldTeamIndex] = useState(0);
  const [teamInfo, setTeamInfo] = useState<ITeamInfo | null>(null);
  const [step, setStep] = useState(teamInfo?.id ? "create-team" : "")

  useEffect(() => {
    dispatch(getEmployerInfoAsync(employerId || ""));
  }, [dispatch, employerId]);

  useEffect(() => {
    let isMounted = true
    setIsSkeleton(true)
    const fetchData = async () => {
      try {
        await TeamAPI.getOneTeam().then((response) => {
          if (isMounted) {
            setTeamInfo(response)
          }
        })
        await TeamAPI.getInvitationList().then((response) => {
          if (isMounted) {
            setInvitation(response);
          }
        })
      } finally {
        if (isMounted) {
          setIsSkeleton(false)
        }
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  },[])

  useEffect(() => {
    if (!teamInfo?.id) return;
    
    setIsSkeleton(true)
    TeamAPI.getTeamProgress(teamInfo.id).then((response) => {
      if(!response.create) {
        setStep("create-team")
      }else if (!response.member) {
        setStep("add-member")
      }else if (!response.transfer_wallet) {
        setStep("organizing-wallet")
      }
    }).finally(() => {
      setIsSkeleton(false)
    })
  },[teamInfo])

  useEffect(() => {
    if (!teamInfo?.id) return;
    dispatch(
      getWalletBalanceAsync({
        isPersonal: false,
        team_id: teamInfo.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenOldTeam = (index: number) => {
    setOldTeamIndex(index);
    setOpenOldTeam(true);
  };

  const handleCloseOldTeam = (index: number) => {
    setOldTeamIndex(index);
    setOpenOldTeam(false);
  };

  const actionInvitation = (invitation_id: string, action: string) => {
    setIsLoading(true);
    const body = {
      invitation_id,
      action,
    };

    TeamAPI.acceptAndRejectInvitation(body)
      .then((response) => {
        setInvitation(response);
        setOpenInvitation(false);
        TeamAPI.getOneTeam().then((response) => {
          setTeamInfo(response);
          dispatch(getEmployerInfoAsync(employerId || ""));
          setIsLoading(false);
        })
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading />;
  
  return (
    <TeamContext.Provider  value={{ teamInfo, setTeamInfo, step, setStep, isLoading, setIsLoading }}>
      <div className="container-main">
        <div>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Typography variant="h5">Team Management</Typography>
              <Typography variant="subtitle1">การจัดการทีม</Typography>
            </div>
            <div>
              <Button
                disabled={invitation === null}
                sx={{
                  p: "8px 24px",
                  bgcolor: "#8C499C",
                  color: "#fff",
                  ":hover": {
                    color: "#8C499C",
                  },
                }}
                onClick={() => setOpenInvitation(true)}
              >
                <Typography variant="buttonM">
                  {invitation?.length
                    ? `ได้รับคำเชิญร่วมทีม (${invitation?.length})`
                    : "ได้รับคำเชิญร่วมทีม"}
                </Typography>
              </Button>
            </div>
          </Box>
          <Box mt="24px">
            {isSkeleton ? (
              <TeamSkeleton />
            ) : !teamInfo || !teamInfo?.id || step === "create-team" || step === "add-member" || step === "organizing-wallet" ? (
              <CreateTeam />
            ) : (
              <Member />
            )}
            </Box>
        </div>

        <Invitations
          open={openInvitation}
          onClose={() => setOpenInvitation(false)}
          invitation={invitation}
          handleOpenOldTeam={handleOpenOldTeam}
          actionInvitation={actionInvitation}
        />

        <OldTeam
          open={openOldTeam}
          onClose={handleCloseOldTeam}
          invitation={invitation}
          index={oldTeamIndex}
        />
      </div>
    </TeamContext.Provider>
  );
}
