// mui
import { Box, Button, Icon, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
// icon and style
import { useSyncTwitterStyle as classes } from "../InfluencerSocial.style";
// utils
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { SYNC_INSTAGRAM_AND_TWITTER } from "@utils/constants/api.constants";

export default function SyncTwitter() {
  const { access_token } = getTokenFromStorage();

  const onLoginTwitter = () => {
    window.location.replace(
      `${SYNC_INSTAGRAM_AND_TWITTER}/twitter/login?token=${access_token}`
    );
  };

  return (
    <Stack sx={classes.container}>
      <div>
        <Icon className="ico-hu-twitter" sx={classes.twitterLogo} />
      </div>
      <Box textAlign="center" width="40%" pt={4}>
        <Typography variant="h5" color={grey[700]}>
          Connect with Twitter
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" textAlign="center" py={5}>
        <Typography variant="body1" mx="10px" width={{ lg: "70%" }}>
          Amplify your chance to reach out to more brands and followers Simply
          add more of your social channels below.
        </Typography>
      </Box>
      <Box py={5}>
        <Button sx={classes.buttonSyncSocial} onClick={onLoginTwitter}>
          <Icon className="ico-hu-twitter" sx={{ mr: ["-32px", "-52px"] }} />
          <Typography variant="buttonM">Continue with Twitter</Typography>
        </Button>
      </Box>
    </Stack>
  );
}
