export const permissionList = [
  {
    id: 0,
    value: "financial_and_campaign",
    label: "Financial and Campaign",
  },
  {
    id: 1,
    value: "financial",
    label: "Financial Only",
  },
  {
    id: 2,
    value: "campaign",
    label: "Campaign Only",
  },
];
