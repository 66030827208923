import { IBreakpoint } from "@views/global/global.types";

export const setValueByBreakpoint = (
  breakpoint: IBreakpoint,
  v_xs: any,
  v_sm?: any,
  v_md?: any,
  v_lg?: any,
  v_xl?: any
): any => {
  switch (breakpoint) {
    case "xs":
      return v_xs;
    case "sm":
      return v_sm || v_xs;
    case "md":
      return v_md || v_sm || v_xs;
    case "lg":
      return v_lg || v_md || v_sm || v_xs;
    case "xl":
      return v_xl || v_lg || v_md || v_sm || v_xs;
    default:
      return v_xs;
  }
};
