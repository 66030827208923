import React, { useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Avatar,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// api and type
import { PaymentAPI } from "@api/global";
import { IPaymentMethodData } from "@slices/type";
// style and redux
import { usePaymentMethodStyle as classes } from "../subscription/payment/SubscriptionPayment.style";
import { selectPaymentMethod } from "@slices/PaymentMethod.slice";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { useAppSelector } from "@store/hook";
// other
import { useLocation } from "react-router-dom";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function PaymentMethod(props: { amount: string }) {
  const { amount } = props;

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const { session_token } = getTokenFromStorage();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const plan = params.get("plan") || "";
  const employerId = params.get("emp_id") || session_token?.employer_id || ""
  const teamId = params.get("t_id") || ""

  const employerInfo = useAppSelector(selectEmployerInfo);
  const paymentMethodList = useAppSelector(selectPaymentMethod);

  const internetBanking = paymentMethodList?.find((method) => method.id === 1);
  const mobileBanking = paymentMethodList?.find((method) => method.id === 2);
  const creditCard = paymentMethodList?.find((method) => method.id === 3);
  const eWallet = paymentMethodList?.find((method) => method.id === 4);
  const qrPayment = paymentMethodList?.find((method) => method.id === 5);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onChillPayPublisher = (data?: IPaymentMethodData) => {
    const body = {
      amount: Number(amount.replace(/\D/g, "")),
      description: "top-up",
      pay_type: {
        id: 2,
      },
      pay_method: {
        id: data?.id || 0,
      },
      attachment: null,
      payment_plan: plan
    };
    PaymentAPI.chillpayPublisher(
      teamId
        ? { ...body, team_id: employerInfo?.team?.id }
        : { ...body, employer_id: employerId }
    )
      .then((response) => {
        if (response.PaymentUrl) {
          window.location.replace(response.PaymentUrl);
        } else {
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${response.Message}`,
            showCancelButton: true,
            cancelButtonText: "Close",
            showConfirmButton: false,
          })
        }
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `มีบางอย่างผิดพลาด`,
          showCancelButton: true,
          cancelButtonText: "Close",
          showConfirmButton: false,
        });
      });
  };
  return (
    <Box sx={classes.con}>
      <Typography variant="h6">เลือกช่องทางการเติมเงิน</Typography>
      <Box sx={classes.accordiun}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ height: "64px", p: "24px" }}
          >
            <Typography variant="title1">QR Payment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} p="0 15px 15px 15px">
              {qrPayment?.data.map((li, index) => (
                <Grid item key={index} xs={4} xl={3}>
                  <Box
                    onClick={() => onChillPayPublisher(li)}
                    sx={classes.accordionDetails}
                  >
                    <Avatar
                      src={li.image_logo_url?.[0]}
                      sx={classes.qrPaymentImg}
                    >
                      {li.title.charAt(0)}
                    </Avatar>
                    <Typography ml="10px" textTransform="capitalize">
                      {li.title.replace("_", " ")}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ height: "64px", p: "24px" }}
          >
            <Typography variant="title1">e-Wallet</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} p="0 15px 15px 15px">
              {eWallet?.data.map((li, index) => (
                <Grid item xs={4} xl={3} key={index}>
                  <Box
                    onClick={() => onChillPayPublisher(li)}
                    sx={classes.accordionDetails}
                  >
                    <Avatar src={li.image_logo_url?.[0]} sx={classes.methodImg}>
                      {li.title.replace("epayment_", "").charAt(0)}
                    </Avatar>
                    <Typography ml="10px" textTransform="capitalize">
                      {li.title.replace("epayment_", "")}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ height: "64px", p: "24px" }}
          >
            <Typography variant="title1">Credit / Debit Card</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} p="0 15px 15px 15px">
              {creditCard?.data.map((li, index) => (
                <Grid item xs={4} xl={3} key={index}>
                  <Box
                    onClick={() => onChillPayPublisher(li)}
                    sx={classes.accordionDetails}
                  >
                    <Avatar src={li.image_logo_url?.[0]} sx={classes.craditImg}>
                      {li.title.charAt(0)}
                    </Avatar>
                    <Typography ml="10px" textTransform="capitalize">
                      {li.title}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ height: "64px", p: "24px" }}
          >
            <Typography variant="title1">Mobile Banking</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} p="0 15px 15px 15px">
              {mobileBanking?.data.map((li, index) => (
                <Grid item xs={4} xl={3} key={index}>
                  <Box
                    onClick={() => onChillPayPublisher(li)}
                    sx={classes.accordionDetails}
                  >
                    <Avatar src={li.image_logo_url?.[0]} sx={classes.methodImg}>
                      {li.title.charAt(0)}
                    </Avatar>
                    <Typography ml="10px" textTransform="capitalize">
                      {li.title.replace("_", " ")}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ height: "64px", p: "24px" }}
          >
            <Typography variant="title1">Internet Banking</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} p="0 15px 15px 15px">
              {internetBanking?.data.map((li, index) => (
                <Grid item xs={4} xl={3} key={index}>
                  <Box
                    onClick={() => onChillPayPublisher(li)}
                    sx={classes.accordionDetails}
                  >
                    <Avatar src={li.image_logo_url?.[0]} sx={classes.methodImg}>
                      {li.title.charAt(0)}
                    </Avatar>
                    <Typography ml="10px" textTransform="capitalize">
                      {li.title.replace("_", " ")}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
