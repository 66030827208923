// mui and icon
import { Box, Stack, Typography, InputAdornment } from "@mui/material";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
// type and style
import { IDateRangeInputProps } from "../CampaignBrief.type";
import { useDateRangeInputStyle as classes } from "../CampaignBrief.style";
// others
import TextFieldCustom from "@global/text-field";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export default function DateRangeInput(props: IDateRangeInputProps) {
  const {
    endKeyName,
    startError,
    endError,
    startMinDate,
    startKeyName,
    placeholderFirst,
    placeholderSecond,
    form: { setValue, watch },
  } = props;

  const startDateVal = new Date(watch(startKeyName as any));
  const endDateVal = new Date(watch(endKeyName as any));

  const isDate = (date: Date | string) => {
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return null;
    }
    return dateObj;
  };

  const handleChangeDate = (date: Date | null, keyName: any) => {
    if (date !== null) return setValue(keyName, date);
  };

  return (
    <Stack direction="row" spacing={2} sx={classes.container}>
      <Box width="100%">
        <Box className="datepicker-container">
          <DatePicker
            selected={isDate(startDateVal)}
            startDate={isDate(startDateVal)}
            endDate={isDate(endDateVal)}
            onChange={(date) => handleChangeDate(date, startKeyName)}
            calendarClassName="datepicker-calendar-container"
            dateFormat="dd/MM/yyyy"
            minDate={startMinDate ? new Date(startMinDate) : undefined}
            customInput={
              <div className="input-container">
                <TextFieldCustom
                  fullWidth
                  placeholder={placeholderFirst}
                  value={
                    isDate(startDateVal)
                      ? dayjs(startDateVal).format("DD/MM/YYYY")
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon
                          sx={{
                            cursor: "pointer",
                            color: startError ? "red" : "#8C499C",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderRadius: "50px",
                        borderColor: startError ? "red" : "#8C499C",
                      },
                  }}
                />
              </div>
            }
          />
        </Box>
        {startError && (
          <Typography
            variant="caption"
            color="error.main"
            fontWeight={400}
            ml="15px"
          >
            {startError}
          </Typography>
        )}
      </Box>
      <span
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        -
      </span>
      <Box width="100%">
        <Box className="datepicker-container">
          <DatePicker
            name={endKeyName}
            dateFormat="dd/MM/yyyy"
            selected={isDate(endDateVal)}
            calendarClassName="datepicker-calendar-container"
            onChange={(date) => handleChangeDate(date, endKeyName)}
            selectsEnd
            startDate={isDate(startDateVal)}
            endDate={isDate(endDateVal)}
            minDate={startMinDate ? new Date(startMinDate) : undefined}
            monthsShown={2}
            customInput={
              <div className="input-container">
                <TextFieldCustom
                  fullWidth
                  value={
                    isDate(endDateVal)
                      ? dayjs(endDateVal).format("DD/MM/YYYY")
                      : ""
                  }
                  placeholder={placeholderSecond}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon
                          sx={{
                            cursor: "pointer",
                            color: endError ? "red" : "#8C499C",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderRadius: "50px",
                        borderColor: endError ? "red" : "#8C499C",
                      },
                  }}
                />
              </div>
            }
          />
        </Box>
        {endError && (
          <Typography
            variant="caption"
            color="error.main"
            fontWeight={400}
            ml="15px"
          >
            {endError}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
