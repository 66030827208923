// mui and style
import {
  Box,
  Icon,
  Stack,
  Avatar,
  Divider,
  Typography,
  DialogContent,
} from "@mui/material";
import { useCampaignDetailDialogStyle as classes } from "../Wallettab.style";
// others
import DialogScrollingCustom from "@global/dialog-scrolling-custom";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";

export default function CampaignDetailDialog(props: {
  open: boolean;
  onClose: (val: boolean) => void;
}) {
  const { open, onClose } = props;

  const detailList = [
    {
      label: "ID : 000321",
      icon: <Icon className="ico-hu-campaign-id" />,
    },
    {
      label: "Private",
      icon: <Icon className="ico-hu-campaign-type" />,
    },
    {
      label: "Facebook",
      icon: <SocialIconSelected platform="facebook" color="#505050" />,
    },
  ];

  return (
    <DialogScrollingCustom
      title="รายละเอียดแคมเปญ"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogContent sx={classes.content}>
        <Box sx={classes.cardBox}>
          {[...Array(2)].map((_, index) => (
            <Box key={index} sx={classes.cardBoxItem}>
              <Box display="flex" alignItems="center">
                <Avatar sx={classes.imgCover} />
                <Box>
                  <Typography variant="h6">
                    Lazada Shop Marathon 12.12
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={3}
                    divider={
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    }
                    mt="10px"
                    display={["none", "flex"]}
                  >
                    {detailList.map((item, index) => (
                      <Box key={index} sx={classes.detailList}>
                        {item.icon}
                        <Typography variant="body1" color="#505050">
                          {item.label}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Box>
              <Divider sx={{ my: "20px" }} />
              <Box display="flex" justifyContent="space-between">
                {[
                  { label: "ค่ามัดจำ 15%", value: "6,000.00" },
                  { label: "ยอดสำรองจ่าย", value: "34,000.00" },
                  { label: "รวมยอดสำรองจ่าย", value: "40,000.00" },
                ].map((item, index) => (
                  <Box key={index}>
                    <Typography variant="body2">{item.label}</Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {item.value} บาท
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </DialogScrollingCustom>
  );
}
