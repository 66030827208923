import { useState } from "react";
// mui
import { Box, Stack, Avatar, Divider, Typography } from "@mui/material";
// type and style;
import { IJobWorkingDraftPostprops } from "../../../JobsTab.type";
import { useDraftImagesStyle as classes } from "../JobWorking.style";
// components and others
import MultiImageInput from "../../../job-default/MultiImageInput";
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";

export default function Images(props: IJobWorkingDraftPostprops) {
  const { images, form, isDraft } = props;

  const {
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [viewDraftImage, setViewDraftImage] = useState(false);

  return (
    <Box sx={classes.imageCon}>
      <Box sx={classes.title}>
        <Typography>Images</Typography>
        <Typography>
          อัพโหลดรูปภาพการรีวิวสินค้า(ไฟล์รูปภาพไม่เกิน 8 mb)
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0], mr: ["24px", 0] }} />
      <Box
        sx={!isDraft ? classes.draftBox : { m: ["24px 24px 24px 0", "24px"] }}
      >
        {isDraft ? (
          <Stack direction="row" sx={classes.isDraftBox}>
            {images?.map((img, index) => (
              <Box key={index} width={{ xs: "47.4%", sm: "initial" }}>
                <Avatar
                  src={img.url}
                  sx={classes.imgItem}
                  onClick={() => setViewDraftImage(true)}
                />
                <Typography variant="body2" textAlign="center" mt="12px">
                  {`รูปที่ ${index + 1}`}
                </Typography>
              </Box>
            ))}
          </Stack>
        ) : (
          <Box width="100%">
            <MultiImageInput
              keyName="attachments"
              setValue={setValue}
              images={watch("attachments")}
              errors={errors.attachments}
              showImageIndex
            />
          </Box>
        )}
      </Box>
      <ViewMultiImageDialog
        open={viewDraftImage}
        onClose={setViewDraftImage}
        images={images}
      />
    </Box>
  );
}
