import { useState } from "react";
// mui and style
import { Box, Divider, Typography } from "@mui/material";
import { useEarningsTabStyle as classes } from "./EarningTab.style";
// components
import BankAccount from "./BankAccount";
import EarningList from "./EarningList";
import BillingAddress from "./BillingAddress";
// others
import Loading from "@global/loading/Loading";

export default function EarningsTab() {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) return <Loading />;

  return (
    <Box sx={classes.container}>
      {[
        { title: "Bank Account", component: <BankAccount /> },
        {
          title: "Billing Address",
          component: <BillingAddress />,
        },
        {
          title: "Earnings",
          component: <EarningList setIsLoading={setIsLoading} />,
        },
      ].map((item, index) => (
        <Box key={index} sx={classes.boxDetail}>
          <Typography variant="h6" p="24px">
            {item.title}
          </Typography>
          <Divider />
          {item.component}
        </Box>
      ))}
    </Box>
  );
}
