// mui
import { Button, Divider, Icon, Tab, Tabs, Typography } from "@mui/material";
// style and type
import { useTabJobBoardStyle as classes } from "../JobBoard.style";
import { IJobBoardTabsItemLgProps } from "../JobBoard.type";
import { useLocation } from "react-router-dom";

export default function JobBoardTabsItemLg(props: IJobBoardTabsItemLgProps) {
  const { tabs, setHandleOpenFilter, onHandleFilterTabStatus, filterTotal } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const campaignType = params.get("c_type") || "";
  
  const typeMatchTabIndex = (type: string) => {
    switch (type) {
      case "public":
        return 1;
      case "private":
        return 2;
      default:
        return 0;
    }
  };

  return (
    <>
      <Tabs
        value={typeMatchTabIndex(campaignType)}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        variant="scrollable"
        aria-label="scrollable auto tabs example"
        scrollButtons="auto"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={
              <Typography
                sx={{
                  color: campaignType === tab.campaignType ? tab.color : "#5C5C5C",
                  "&:hover": {
                    color: tab.color,
                  },
                }}
              >
                {`${tab.label} ${
                  Number(tab.total) > 0 ? `(${tab.total})` : ""
                }`}
              </Typography>
            }
            onClick={() => onHandleFilterTabStatus(tab.campaignType)}
            sx={{
              ...classes.tabButton,
              border: `1px solid ${
                campaignType === tab.campaignType ? tab.color : "#E0E0E0"
              }`,
              bgcolor: campaignType === tab.campaignType ? tab.bgColor : "#ffff",
              "&:hover": {
                border: `1px solid ${tab.color}`,
                bgcolor: tab.bgColor,
                color: tab.color,
              },
            }}
          />
        ))}
      </Tabs>
      <Divider
        sx={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
      />
      <Button
        startIcon={<Icon className="ico-hu-filter" />}
        onClick={() => setHandleOpenFilter(true)}
        sx={classes.tabFilter}
      >
        Filter {filterTotal || ""}
      </Button>
    </>
  );
}
