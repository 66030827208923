import { useEffect, useState } from "react";
// mui and style
import { Box, Button, Typography } from "@mui/material";
import { useMyEarningStyle as classes } from "./MyEarning.style";
// component
import InfluencerEarning from "@components/influencer-earning/EarningTab";
import InfluencerHistorySm from "@components/influencer-earning/HistorySm";
import InfluencerHistoryLg from "@components/influencer-earning/HistoryLg";
// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile, getInfluencerProfileAsync } from "@slices/InfluencerProfile.slice";
// api and type
import { InfluencerAPI } from "@api/global";
import { IEarningInfo, IEarningSummaryRes } from "@api/influencer/type";
// others
import { useHistory } from "react-router-dom";
import { scrollToTop } from "@utils/helpers/scrollToTop";
import Loading from "@components/global/loading/Loading";
import PaginationCustom from "@components/global/pagination-custom";

export default function MyEarning() {
  const history = useHistory();
  const dispatch = useDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);

  const [isLoading, setIsLoading] = useState(false);
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [earningInfo, setEarningInfo] = useState<IEarningInfo | null>(null);
  const [earningSummary, setEarningSummary] = useState<IEarningSummaryRes>();

  const earningList = earningInfo?.item || [];

  useEffect(() => {
    scrollToTop();
    dispatch(getInfluencerProfileAsync({}));
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    const getEarningList = () => {
      setIsLoading(true);

      InfluencerAPI.getInfluencerEarningList(influencerInfo?.influencer_long_id || "", {
        item_per_page: rowsPerPage,
        page: paginate,
        order_by: "transaction_id",
        order: "desc",
      })
        .then((response) => {
          if (isMounted) {
            setEarningInfo(response);
            setIsLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    };

    const getEarningSummary = () => {
      setIsLoading(true);
      InfluencerAPI.getInfluencerEarningSummary(
        influencerInfo?.influencer_long_id || ""
      )
        .then((response) => {
          if (isMounted) {
            setEarningSummary(response);
            setIsLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    };

    getEarningList();
    getEarningSummary();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, paginate]);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <div>
        <Box sx={classes.myEarningTitle}>
          <Typography variant="h5" mb={3}>
            My Earning
          </Typography>
        </Box>
        <InfluencerEarning
          summary={earningSummary || null}
          influencerProfile={influencerInfo}
        />
      </div>
      <Box mt={3}>
        <Typography variant="h6" mt={5} mb={3}>
          Earnings History
        </Typography>
        {earningList.length ? (
          <>
            {/* for mobile and tablet screen */}
            <Box display={{ md: "none" }}>
              <InfluencerHistorySm earningList={earningList} />
            </Box>
            {/* for destop screen */}
            <Box display={{ xs: "none", md: "initial" }}>
              <InfluencerHistoryLg earningList={earningList} />
            </Box>
          </>
        ) : (
          <Box sx={classes.noCampaign}>
            <img
              src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
              alt="no campaign"
            />
            <Box sx={classes.noCampDiscBox}>
              <Typography variant="h5" my="8px">
                ไม่พบประวัติรายได้
              </Typography>
              <Typography>
                คุณยังไม่ได้เข้าร่วมแคมเปญ ไปหน้า “Job Board”
                เพื่อหาค้นหาและสมัครเข้าร่วมแคมเปญที่คุณสนใจกันเถอะ
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => history.push("/job-board")}
              sx={classes.goToJobBoardBtn}
            >
              ไปหน้า Job Board
            </Button>
          </Box>
        )}
      </Box>

      {earningList.length ? (
        <Box sx={classes.paginationCon}>
          <PaginationCustom
            page={paginate}
            count={paginate > 0 ? Math.ceil((earningInfo?.total_item || 0) / rowsPerPage) : 0}
            onPaginationChange={handleChangePage}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </div>
  );
}
