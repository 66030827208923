import { useState } from "react";
// mui and style
import { Box, Icon, Stack, Divider, Typography } from "@mui/material";
import { useJobOthersImagesStyle as classes } from "../JobOthers.style";
// type and others
import { IJobOthersVideosProps } from "../../../JobsTab.type";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";
import ReactPlayer from "react-player";

export default function Videos(props: IJobOthersVideosProps) {
  const { attachments, urls } = props;

  const [viewDraftVideo, setViewDraftVideo] = useState(false);

  const isLenght = (length?: number) => {
    if (length && length > 0) return true;
    return false;
  };

  return (
    <Box sx={classes.imageCon}>
      <Box sx={classes.title}>
        <Typography>Videos</Typography>
        <Typography>
          อัพโหลดวิดีโอการรีวิวสินค้า (ไฟล์วิดีโอไม่เกิน 8 mb)
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0], mr: ["24px", 0] }} />
      <Box m={[0, "24px"]}>
        {isLenght(attachments?.length) && (
          <Box mt={"24px"}>
            <Typography variant="title2">อัปโหลดจากคอมพิวเตอร์</Typography>
            <Stack direction="row" mb="24px" sx={classes.draftBox}>
              {attachments?.map((att, index) => (
                <Box key={index}>
                  <Box
                    onClick={() => setViewDraftVideo(true)}
                    sx={classes.draftVideo}
                  >
                    <Box sx={classes.iconPlayVido}>
                      <Icon
                        sx={{
                          fontSize: "40px",
                          color: "#fff",
                        }}
                        className="ico-hu-play-circle-outline"
                      />
                    </Box>
                    <ReactPlayer
                      url={att.url}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={true}
                    />
                  </Box>
                  <Typography variant="body2" textAlign="center" mt="10px">
                    {att?.order}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        )}
        {isLenght(urls?.length) && (
          <Box mt="24px" mb={[5, 0]}>
            <Typography variant="title2">
              แนบลิงค์ URL หรือแหล่งที่มาของวิดีโอ
            </Typography>
            <Stack direction="row" sx={classes.isDraftBox}>
              {urls?.map((url, index) => (
                <Box key={index} onClick={() => window.open(url.url, "_blank")}>
                  <Box sx={classes.urlBox}>
                    <Box sx={classes.urlIcon}>
                      <Icon className="ico-hu-conversion" />
                      <Typography variant="body2" mt="15px">
                        Video URL
                      </Typography>
                    </Box>
                    <Box sx={classes.urlImg}>
                      <img src={urlLogo} alt="item" />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="center" mt="12px">
                    <Typography variant="title2" textAlign="center">
                      {index + 1}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>
        )}
      </Box>
      <ViewMultiVideoDialog
        open={viewDraftVideo}
        onClose={setViewDraftVideo}
        videos={attachments}
      />
    </Box>
  );
}
