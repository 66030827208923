export const useMyEarningStyle = {
  myEarningTitle: {
    m: {
      xs: "-28px -10px 15px -10px",
      sm: "-28px -28px 15px -25px",
      md: "-28px -20px 15px -20px",
    },
    bgcolor: {
      xs: "#8C499C",
      md: "initial",
    },
    p: "20px 0 8px 23px",
    "& > h5": {
      color: {
        xs: "#fff",
        md: "initial",
      },
    },
  },
  noCampaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    bgcolor: "#fff",
    borderRadius: "16px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
    py: "40px",
  },
  noCampDiscBox: {
    maxWidth: "400px",
    textAlign: "center",
    mt: "15px",
  },
  goToJobBoardBtn: {
    border: "none",
    mt: "24px",
    width: "240px",
    "&:hover": { border: "none" },
  },
  paginationCon: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
};
