// mui and icons
import {
  Box,
  Card,
  Icon,
  Stack,
  Avatar,
  Divider,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and types
import { useCompanyDetailStyle as classes } from "./CampaignDetail.style";
import { ICompanyDetailProps } from "./CampaignDetail.type";
// others
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";
import dayjs from "dayjs";

export default function CompanyDetail(props: ICompanyDetailProps) {
  const { campaignInfo, jobInfo } = props;

  const data = campaignInfo || jobInfo?.campaign_info;
  const status = jobInfo?.status || campaignInfo?.status;

  const statusColor =
    status === "waiting_for_approve" || status === "briefing"
      ? "#FB8C00"
      : status === "first_draft" ||
        status === "working" ||
        status === "listing" ||
        campaignInfo?.status === "published"
      ? "#893DBD"
      : status === "rejected"
      ? "#E30D18"
      : status === "offering" || status === "influencer_listing"
      ? "#E8008A"
      : status === "approved"
      ? "#4CAF50"
      : status === "completed"
      ? "#4A489D"
      : status === "canceled"
      ? "#9E9E9E"
      : "#0077E4";

  const statusLabel =
    status === "offering"
      ? "ได้รับข้อเสนอ"
      : jobInfo?.status === "briefing"
      ? "ประกาศรับสมัคร"
      : status === "first_draft"
      ? "ส่ง Draft แรก"
      : status === "working"
      ? "เริ่มงานได้"
      : status === "rejected"
      ? "ต้องแก้ไข"
      : status === "waiting_for_approve"
      ? "แก้ไขแล้ว รอตรวจ"
      : status === "approved"
      ? "Draft ผ่านแล้ว"
      : jobInfo?.status === "published"
      ? "แนบลิงค์ส่งงาน"
      : status === "completed"
      ? "เสร็จสิ้น"
      : status === "canceled"
      ? "ยกเลิก"
      : status === "listing"
      ? "รอตอบรับ"
      : status === "influencer_listing"
      ? "รับสมัคร"
      : campaignInfo?.status === "published"
      ? "กำลังดำเนินการ"
      : "ร่างแคมเปญ";

  const statusBgcolor =
    status === "waiting_for_approve" || status === "briefing"
      ? "#FFFBD4"
      : status === "rejected" ||
        status === "influencer_listing" ||
        status === "offering"
      ? "#FFEAF4"
      : status === "approved"
      ? "#E7FFE8"
      : jobInfo?.status === "published"
      ? "#E5F6FF"
      : status === "completed" || campaignInfo?.status === "published"
      ? "#EAEAFF"
      : status === "canceled"
      ? "#9E9E9E"
      : "#F4ECFF";

  const campaignList = [
    {
      label: `Campaign ID : ${data?.campaign_id.replace("CAMP-", "")}`,
      icon: <Icon className="ico-hu-campaign-id" />,
    },
    {
      label: `${dayjs(data?.updated_at).format("DD-MM-YYYY")}`,
      icon: <Icon className="ico-hu-calendar-date" />,
    },
    {
      label: ` ${data?.campaign_type}`,
      icon: <Icon className="ico-hu-campaign-type" />,
    },
    {
      label: ` ${data?.content_type.replace(/_/gi, " ")}`,
      icon: <SocialIconSelected platform={data?.platform} color="#505050" />,
    },
  ];

  return (
    <Card sx={classes.cardCon}>
      <Box display="flex" height={["220px", "160px", "160px", "100%"]}>
        <Box sx={classes.companyLogo}>
          <Avatar variant="square" src={data?.company?.media?.url} />
        </Box>
        <Box sx={classes.companyDetail}>
          <Box sx={classes.companyDetailItem}>
            <div>
              <Typography variant="h6">
                {data?.name}
                <Typography component="span" variant="body1">
                  ({data?.company?.name})
                </Typography>
              </Typography>
            </div>
            <Box
              sx={{
                ...classes.compJobStatus,
                "& svg, span": {
                  color: statusColor,
                  whiteSpace: "nowrap",
                },
              }}
            >
              <CircleIcon />
              <Typography variant="title1" ml={1}>
                {statusLabel}
              </Typography>
            </Box>
          </Box>
          <Divider sx={classes.dividerMdScreen} />
          <Stack
            direction="row"
            spacing={3}
            divider={
              <Divider orientation="vertical" variant="middle" flexItem />
            }
            mt="10px"
            display={["none", "flex"]}
          >
            {campaignList.map((item, index) => (
              <Box key={index} sx={classes.compJobItemList}>
                {item.icon}
                <Typography variant="body1" color="#505050">
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Stack>
          <Box display={["initial", "none"]}>
            <Divider sx={{ width: "100%", my: "20px" }} />
            <Box display="flex" alignItems="center">
              {campaignList[1].icon}
              <Typography ml={1.5} mb={1}>
                {campaignList[1].label}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1} ml={0.4}>
              <SocialIconSelected platform={data?.platform} />
              <Typography ml={2.5} mb={1}>
                {data?.campaign_type}
              </Typography>
            </Box>
          </Box>
          <Box bgcolor={statusBgcolor} sx={classes.jobStatusSm}>
            <Typography color={statusColor} fontWeight={500}>
              {statusLabel}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
