import { instance } from "@api/global/instance";
import { ICreateHashtagBody, IGetActivityListParams, IUploadAttachmentBody } from "./type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class AppInformationAPIs {
  async getBankList() {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const { data } = await instance.get(`/info/banks`, { headers });
    return data.result.data;
  };

  async uploadAttachment(body: IUploadAttachmentBody) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "multipart/form-data", 
    };

    let formData = new FormData();
    formData.append("file", body.file);
    formData.append("destination", body.destination);
    formData.append("permission", body.permission);

    const { data } = await instance.post(`/files/upload`, formData, { headers });
    return data.result.data;
  };

  async createHashtag(body: ICreateHashtagBody[]) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json",
    };
    const { data } = await instance.patch(`/info/hashtags`, body, { headers });
    return data.result.data;
  };

  async getCompanyListByEmployer(employerId:string) {
    const { access_token, session_token } = getTokenFromStorage()

    const employerID = employerId || session_token?.employer_id;

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json",
    };
    const { data } = await instance.get(`/info/${employerID}/company`, { headers });
    return data.result.data;
  };

  async getCategoryList() {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/info/categories`, { headers });
    return data.result.data;
  };

  async getHashtagList() {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/info/hashtags`, { headers });
    return data.result.data;
  };

  async getProvinceList() {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/info/provinces`, { headers });
    return data.result.data;
  };

  async getContentType() {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const { data } = await instance.get(`/info/content-types`, { headers });
    return data.result.data;
  };

  async getActivityInfo(params: { page: number, item_per_page: number }) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/info/activity`, { headers, params });
    return data.result.data;
  };

  async getActivityList(params: IGetActivityListParams) {
    const { data } = await instance.get(`/info/activity-list`, { params });
    return data.result.data;
  };

  async getAddressList() {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/info/all`, { headers });
    return data.result.data;
  };
}