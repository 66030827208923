export const useEventDetailStyle = {
  conatiner: {
    p: {
      xs: "0 20px 20px 20px",
      sm: "0 40px 40px 40px",
      lg: "150px 125px 80px 120px",
    },
    border: "1px solid #f5f6f8",
  },
};
