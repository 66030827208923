import { RootState } from "@store/store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// api and type
import { IInfluencerSocialState } from "./type";
import { InfluencerAPI } from "@api/global";

const initialState: IInfluencerSocialState = {
  data: null,
  loading: false,
};

export const getInfluencerSocialAsync = createAsyncThunk(
  "influencerSocialInfo",
  async (params:{ influencerId?: string }) => {
    try {
      const response = await InfluencerAPI.getInfluencerSocial(params.influencerId);
      return response;
    } catch (err) {
      console.log("catch", err);
      throw err;
    }
  }
);

export const influencerSocialSlice = createSlice({
  name: "influencerSocial",
  initialState,
  reducers: {
    setInfluencerSocialLoading: (state, { payload }) => {
      state.data = payload;
    },
    logoutTiktok: (state) => {
      const data = state.data;
      state.data = data ? { ...data, tiktok: [] } : null;
    },
    logoutTwitter: (state) => {
      const data = state.data;
      state.data = data ? { ...data, twitter: [] } : null;
    },
    logOutFacebook: (state) => {
      const data = state.data;
      state.data = data ? { ...data, facebook: [] } : null;
    },
    logOutInstagram: (state) => {
      const data = state.data;
      state.data = data ? { ...data, instagram: [] } : null;
    },
    logOutYoutube: (state) => {
      const data = state.data;
      state.data = data ? { ...data, youtube: [] } : null;
    },
    updateInfluencerSocial: (state, { payload }) => {
      state.data = payload;
    },
    updateInfluencerRateCard: (state, {payload} ) => {
      if(state.data !== null){
        state.data[payload.platform][0].social_account[0].rate_card = payload.data
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerSocialAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInfluencerSocialAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getInfluencerSocialAsync.rejected, (state) => {
        state.data = null;
        state.loading = false;
      });
  },
});

export const {
  logoutTiktok,
  logoutTwitter,
  logOutYoutube,
  logOutFacebook,
  logOutInstagram,
  updateInfluencerSocial,
  updateInfluencerRateCard,
  setInfluencerSocialLoading,
} = influencerSocialSlice.actions;

export const selectInfluencerSocial = (state: RootState) => state.influencerSocial.data;
export const selectInfluencerSocialOnload = (state: RootState) => state.influencerSocial.loading;

export default influencerSocialSlice.reducer;
