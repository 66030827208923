import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { EmployerAPI, PaymentAPI } from "@api/global";
import { IEmployerInfoState } from "./type";

const initialState: IEmployerInfoState = {
  data: null,
  balance: 0,
  loading: false,
  error: "",
};

export const getEmployerInfoAsync = createAsyncThunk(
  "getEmployerInfoAsync",
  async (employerId?: string) => {
    try {
      const response = await EmployerAPI.getEmployerInfo(employerId);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const checkBalanceAsync = createAsyncThunk(
  "checkWalletBalance",
  async (req: { isPersonal: boolean; employer_id?: string; team_id?: string; }) => {
    try {
      const response = await PaymentAPI.checkBalance(req.isPersonal, req.employer_id, req.team_id);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const employerInfoSlice = createSlice({
  name: "employerJob",
  initialState,
  reducers: {
    setEmployerInfo: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerInfoAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getEmployerInfoAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getEmployerInfoAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      })
      .addCase(checkBalanceAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(checkBalanceAsync.fulfilled, (state, action) => {
        state.balance = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(checkBalanceAsync.rejected, (state, action) => {
        state.balance = 0;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const { setEmployerInfo } = employerInfoSlice.actions;

export const selectEmployerInfo = (state: RootState) => state.employerInfo.data;
export const selectEmployerInfoOnload = (state: RootState) => state.employerInfo.loading;
export const selectEmployerBalance = (state: RootState) => state.employerInfo.balance;

export default employerInfoSlice.reducer;
