// mui and style
import { Box, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "../CampaignPrivate.style";

export default function BudgetStatus({
  use,
  total,
}: {
  use: number;
  total: number;
}) {
  let result = (use / total) * 100;

  const numberFormat = Intl.NumberFormat("en");

  return (
    <Box sx={{ margin: "24px 0" }}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography>วงเงินที่ใช้ไป</Typography>
          <Typography variant="h6" color="#893DBD">
            {numberFormat.format(Math.round(use))}
          </Typography>
        </Box>
        <Box>
          <Typography>คงเหลือ</Typography>
          <Typography variant="h6" color="#FB8C00">
            {numberFormat.format(Math.round(total - use))}
          </Typography>
        </Box>
      </Box>
      <Box sx={classes.boxBudget}>
        <Box
          sx={{
            width: result >= 100 ? "100%" : `${result}%`,
            ...classes.boxUseBudget,
          }}
        ></Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="cen">
        <Typography>
          {numberFormat.format(total)}
          THB
        </Typography>
      </Box>
    </Box>
  );
}
