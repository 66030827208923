// mui
import { Box, Icon, InputAdornment, Typography } from "@mui/material";
import { useCampaignTabFilterStyle as classes } from "./CampaignTab.style";
// others
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
// data
import {
  platformList,
  jobStatusList,
  reviewTypeList,
  campaignSortByList,
  jobStatusMatchToThai,
} from "../../dashboard.constants";
// type
import { initJobFilter } from "./CampaignTab";
import { ICampaignTabFilterProps } from "./CampaignTab.type";

type TField = "status" | "platform" | "review_type" | "search";

export default function CampaignTabFilter(props: ICampaignTabFilterProps) {
  const { handleFilterJob, myJobsFilter } = props;

  const handleSelectChange = (value: string, field: TField) => {
    let data = { ...myJobsFilter };
    data[field] = value;
    handleFilterJob(data);
  };

  const onUnselect = (field: TField) => {
    let data = { ...myJobsFilter };
    data[field] = "";
    handleFilterJob(data);
  };

  const handleSortBy = (value: string) => {
    const [order_by, sort] = value.split(" ");
    handleFilterJob({
      ...myJobsFilter,
      order_by: order_by,
      sort: sort,
    });
  };

  const onClearAllFilter = () => {
    handleFilterJob(initJobFilter);
  };

  return (
    <Box my="24px">
      <Box display="flex" columnGap="12px">
        <Box width="30%">
          <TextFieldCustom
            sx={{ width: "100%" }}
            placeholder="Search by campaign, name, id, company"
            value={myJobsFilter.search}
            onChange={(e) => handleSelectChange(e.target.value, "search")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="ico-hu-search"
                    sx={{ paddingRight: "2px" }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" columnGap="12px" width="70%">
          {[
            {
              id: "select-status",
              label: "Status",
              value: myJobsFilter.status,
              field: "status",
              data: jobStatusList,
            },
            {
              id: "select-platform",
              label: "Platform",
              value: myJobsFilter.platform,
              field: "platform",
              data: platformList,
            },
            {
              id: "select-review",
              label: "Review Type",
              value: myJobsFilter.review_type,
              field: "review_type",
              data: reviewTypeList,
            },
          ].map((item, index) => (
            <BasicSelectCustom
              key={index}
              id={item.id}
              value={item.value}
              onChange={(e) =>
                handleSelectChange(e.target.value, item.field as TField)
              }
              data={item.data}
              valueKey="value"
              labelKey="label"
              placeholder={item.label}
              fullWidth
            />
          ))}
          <BasicSelectCustom
            id="select-sort"
            value={`${myJobsFilter.order_by} ${myJobsFilter.sort}`}
            onChange={(e) => handleSortBy(e.target.value)}
            data={campaignSortByList}
            valueKey="value"
            labelKey="label"
            placeholder="Sort By"
            fullWidth
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", m: "28px 0", alignItems: "center" }}>
        <Typography variant="title1">Actives Filters</Typography>
        {[
          {
            value: jobStatusMatchToThai(myJobsFilter.status),
            field: "status",
          },
          { value: myJobsFilter.platform, field: "platform" },
          {
            value: myJobsFilter.review_type,
            field: "review_type",
          },
        ].map((item, index) => {
          if (!item.value || item.value === "") return null;
          return (
            <Box key={index} sx={classes.boxFilter}>
              <Typography fontSize="16px" fontWeight="500">
                {item.value}
              </Typography>
              <Icon
                className="ico-hu-close"
                onClick={() => onUnselect(item.field as TField)}
                sx={classes.icon}
              />
            </Box>
          );
        })}
        {["status", "platform", "review_type", "search"].map((field, index) => {
          if (myJobsFilter[field as TField] !== "") {
            if (index === 0) {
              return (
                <Typography
                  key={index}
                  onClick={() => onClearAllFilter()}
                  sx={classes.clearBtn}
                >
                  Clear
                </Typography>
              );
            }
          }
          return null;
        })}
      </Box>
    </Box>
  );
}
