import { useEffect, useState } from "react";
// mui and style
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "../CampaignPrivate.style";
// component
import CardOrganizing from "../CardOrganizing";
import BudgetStatus from "../status/BudgetStatus";
import PrivateEstimate from "../../campaign-detail/influencer-list-tab/estimate-summary/PrivateEstimate";
// type
import { IPrivateItemRes } from "@api/job/type";
import { IPrivateSummary } from "../PrivateCampaign.type";
// Redux
import {
  getInfluencerPrivateCampaign,
  selectInfluencerPrivateCampaign,
} from "@slices/CampaignPrivate.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
// utils and route
import { scrollToTop } from "@utils/helpers/scrollToTop";
import { useHistory, useLocation, useParams } from "react-router-dom";
// data
import { initPrivateSummary } from "../PrivateCampaignData";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function PrivateInfluencerList() {
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;
  
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const numberFormat = Intl.NumberFormat("en");

  const dispatch = useAppDispatch();
  const influencerPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);
  const campaignInfo = useAppSelector(selectCampaignInfo);

  const totalBudget = Number(influencerPrivateInfo?.total_budget);

  const [working, setWorking] = useState<IPrivateItemRes[] | undefined>([]);
  const [privateSummary, setPrivateSummary] = useState<IPrivateSummary>(initPrivateSummary);

  useEffect(() => {
    const params = {
      employerId: employerId,
      campaignId: campaignId,
      params: {
        page: 1,
        item_per_page: 20,
      },
    };
    dispatch(getInfluencerPrivateCampaign(params));
    scrollToTop();
  }, [dispatch, employerId, campaignId]);

  useEffect(() => {
    const workingList = influencerPrivateInfo?.item?.filter((item) => {
      return item.status === "listing";
    });
    setWorking(workingList);
  }, [influencerPrivateInfo?.item]);

  useEffect(() => {
    const followers = working?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.follower)),
      0
    );
    const likes = working?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.avg_like)),
      0
    );
    const comments = working?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.avg_comment)),
      0
    );
    const shares = working?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.avg_share)),
      0
    );
    const budgets = working?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.rate_card)),
      0
    );
    setPrivateSummary({
      followers: followers,
      likes: likes,
      comments: comments,
      shares: shares,
      budgets: budgets,
      privateSelected: Number(working?.length),
    });
  }, [working]);

  const handleTrackProposalStatus = () => {
    if (role === "employer") {
      history.push(`/campaigns/${campaignId}/track-proposal-status`);
    } else {
      history.push(`/campaigns/${campaignId}/track-proposal-status?emp_id=${employerId}`);
    }
  };

  let getBudGet = (budget: number) => {
    if (budget === 0) {
      return `0/${numberFormat.format(totalBudget)}`;
    } else {
      return `${numberFormat.format(
        Math.round(privateSummary.budgets)
      )}/${numberFormat.format(totalBudget)}`;
    }
  };

  return (
    <div>
      <div>
        <Typography variant="h5">Influencer List</Typography>
        <Typography variant="subtitle1">
          อินฟลูเอนเซอร์ที่คุณเลือกเพื่อจ้างงาน
        </Typography>
      </div>
      <Divider sx={classes.divider} />
      <Box display="flex" justifyContent="space-between">
        <div>
          <Typography variant="h6">
            รายชื่ออินฟลูเอนเซอร์ที่ตอบรับข้อเสนอ
          </Typography>
          <Typography>
            Estimate Summary
            เป็นค่าประมาณการที่คำนวณจากอินฟลูเอนเซอร์ที่ตอบรับงาน
          </Typography>
        </div>
        <div>
          <Button onClick={handleTrackProposalStatus}>
            ติดตามสถานะการยื่นข้อเสนอ
          </Button>
        </div>
      </Box>
      <Box sx={classes.divider}>
        <PrivateEstimate
          amountTitle={"จำนวนที่ตอบรับ"}
          amount={`${privateSummary.privateSelected}/${campaignInfo?.job_limit}`}
          summary={privateSummary}
          budget={getBudGet(privateSummary.budgets)}
        />
      </Box>
      <BudgetStatus
        use={privateSummary.budgets}
        total={Number(influencerPrivateInfo?.total_budget || 0)}
      />
      {working?.length ? (
        <Grid container spacing={4}>
          {working?.map((item, index) => (
            <Grid item xs={6} sm={2} lg={1.2} xl={1} key={index}>
              <CardOrganizing item={item} handleClick={() => {}} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={classes.boxNoContent}>
          <img
            src={require("@assets/images/infographic/admin/influencer-selected/waiting-influencer.png")}
            alt="no campaign"
          />
          <Box sx={classes.textNoContent}>
            <Typography variant="h5" my="8px">
              กำลังรอคำตอบรับจากอินฟลูเอนเซอร์
            </Typography>
            <Typography>
              ขณะนี้ระบบได้ดำเนินการยื่นข้อเสนอให้แก่อินฟลูเอนเซอร์แล้ว
              อยู่ในขั้นตอนการรอการตอบรับงานจากอินฟลูเอนเซอร์
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}
