// mui and style
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useProductReturnStyle as classes } from "../EmployerJob.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerJobInfo } from "@slices/EmployerJobInfo.slice";
// other
import { CompanyIconSelected } from "@global/icon-selected/CompanyIconSelected";

export default function ProductReturn() {
  const jobInfo = useAppSelector(selectEmployerJobInfo);
  const productReturn = jobInfo?.product_return;

  return (
    <Box sx={classes.productBox}>
      <Box sx={classes.productTitle}>
        <Typography>Product Return</Typography>
        <Typography>อินฟลูเอนเซอร์ส่งสินค้ากลับให้ผู้จ้างงาน</Typography>
      </Box>
      <Divider sx={classes.divider} />
      <div>
        <Box sx={classes.content}>
          <Typography variant="subTitle1" fontWeight={500} color="#8C499C">
            ที่อยู่ในการส่งของกลับ
          </Typography>
          <Typography mt="8px">
            {`${productReturn?.contact}, ${productReturn?.phone}`}
          </Typography>
          <Typography variant="body2" color="#505050">
            {productReturn?.address}
          </Typography>
        </Box>
        <Grid container spacing={4} mt={3}>
          <Grid item xs={2.5}>
            <Typography fontWeight={500}>Shipping Company</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Box display="flex" alignItems="center">
              {productReturn?.shipping_type ? (
                <>
                  <CompanyIconSelected company={productReturn?.shipping_type} />
                  <Typography
                    fontWeight={400}
                    ml={2}
                    textTransform="capitalize"
                  >
                    {productReturn?.shipping_type}
                  </Typography>
                </>
              ) : (
                "-"
              )}
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Typography fontWeight={500}>Tracking No.</Typography>
          </Grid>
          <Grid item xs={8.5}>
            {productReturn?.tracking_no ? (
              <Typography
                fontWeight={500}
                color="#8C499C"
                sx={{ textDecoration: "underline" }}
              >
                {productReturn?.tracking_no}
              </Typography>
            ) : (
              "-"
            )}
          </Grid>
          <Grid item xs={2.5}>
            <Typography fontWeight={500}>Note</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <Typography>
              {productReturn?.note || "-"}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
