import { hGrey, hPurple, hWhite } from "@theme/index";

export const useCampaignPerfomance = {
  container: {
    height: "100%",
    width: "100%",
    borderRadius: "12px",
    border: `2px solid ${hPurple[100]}`,
  },
  campTitle: {
    m: ["20px 15px 0 15px", "24px 0 0 24px"],
    "& p": {
      fontSize: ["16px", "20px", "24px"],
      fontWeight: "600",
    },
  },
  campGrid: {
    m: ["20px 15px 0 15px", "24px 25px 0 24px"],
  },
  campCard: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: ["88px", "88px", "110px"],
    borderRadius: "8px",
    border: `1px solid ${hPurple[100]}`,
  },
  campCardBoxIcon: {
    display: ["none", "flex"],
    justifyContent: "end",
    alignItems: "center",
    pr: "15px",
  },
  campCardIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: ["48px", "48px", "60px"],
    height: ["48px", "48px", "60px"],
    borderRadius: "50%",
    backgroundColor: hWhite[200],
  },
  campChart: {
    m: ["30px 0 20px 15px", "55px 0 10px 24px", "45px 0 25px 24px"],
    width: ["90%", "90%", "90%", "95.5%"],
    height: "54%",
    minHeight: ["350px", "350px", "350px", "400px"],
  },
};

export const useAgeAndGenderDistributionStyle = {
  container: {
    ...useCampaignPerfomance.container,
    marginTop: ["-60px", 0],
  },
  ageAndGenderTitle: {
    ...useCampaignPerfomance.campTitle,
  },
  ageAndGenderGrid: {
    m: ["20px 15px 0 15px", "24px 0 10px 24px"],
  },
  ageAndGenderGridCon: {
    display: "flex",
    alignItems: "center",
    flexDirection: { xs: "column", sm: "row", md: "column" },
    justifyContent: ["flex-start", "center", "flex-start"],
  },
  pieChart: {
    mt: ["24px", "15px"],
    mb: [0, "15px"],
    width: "180px",
    height: "180px",
  },
  ageAndGenderTotalCon: {
    width: ["90%", "45%", "30%", "80%"],
    mt: ["30px", "10px"],
    ml: [0, "50px", 0],
  },
  circleIcon: {
    width: "12px",
    height: "12px",
    mr: "10px",
  },
  barChart: {
    m: "24px 25px 24px 0px",
    width: ["100%", "97%"],
    height: ["350px", "350px", "350px", "400px"],
  },
};

export const useInfluencerPostStyle = {
  con: {
    borderRadius: "12px",
    border: `2px solid #EBDFFF`,
    p: "20px",
    position: "relative",
  },
  socialProfile: {
    display: "flex",
    alignItems: "center",
    mb: "16px",
    "& > img": { objectFit: "cover" },
    "& > p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  desc: {
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
};

export const useEngagementStyle = {
  container: {
    ...useCampaignPerfomance.container,
    marginTop: ["-20px", 0],
  },
  engTitle: {
    ...useCampaignPerfomance.campTitle,
  },
  engGrid: {
    ...useCampaignPerfomance.campGrid,
  },
  engCard: {
    ...useCampaignPerfomance.campCard,
  },
  engSubtitle: {
    color: hGrey[900],
    display: ["none", "block", "block", "none", "block"],
    fontSize: ["14px", "14px", "16px"],
  },
  engCardBoxIcon: {
    ...useCampaignPerfomance.campCardBoxIcon,
    pr: ["15px", "15px", "15px", "10px"],
  },
  engCardIcon: {
    ...useCampaignPerfomance.campCardIcon,
    width: ["48px", "48px", "60px", "48px"],
    height: ["48px", "48px", "60px", "48px"],
  },
  chartLineBox: {
    m: ["30px 0 0 15px", "30px 0 0 15px", "30px 0 0 15px", "30px 0 0 25px"],
    width: ["90%", "95%", "95%", "95.7%"],
    height: ["350px", "350px", "350px", "400px"],
  },
  chartCheckboxBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    m: "15px auto",
  },
  chartCheckbox: {
    height: "25px",
    m: [0, "10px 8px 20px 8px"],
  },
};

export const useConversionStyle = {
  container: {
    ...useCampaignPerfomance.container,
    marginTop: ["-40px", 0],
  },
  convTitile: {
    ...useCampaignPerfomance.campTitle,
  },
  convGrid: {
    ...useCampaignPerfomance.campGrid,
  },
  convCard: {
    ...useCampaignPerfomance.campCard,
  },
  convSubtitle: {
    ...useEngagementStyle.engSubtitle,
  },
  convCardBoxIcon: {
    ...useCampaignPerfomance.campCardBoxIcon,
    justifyContent: ["center", "end"],
  },
  convCardIcon: {
    ...useCampaignPerfomance.campCardIcon,
    "& svg": {
      fontSize: ["16px", "16px", "28px", "35px"],
    },
  },
  convChart: {
    margin: [
      "30px 0 0 15px",
      "30px 0 0 15px",
      "30px 0 0 15px",
      "30px 0 0 25px",
    ],
    width: ["90%", "95%", "95%", "95.5%"],
    height: ["350px", "350px", "350px", "400px"],
  },
  chartCheckboxBox: {
    ...useEngagementStyle.chartCheckboxBox,
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    width: ["65%", "100%"],
  },
  chartCheckbox: {
    height: "25px",
    m: [0, "10px 8px 20px 8px"],
  },
};

export const useLocationDistributionStyle = {
  container: {
    ...useCampaignPerfomance.container,
    marginTop: ["-80px", 0],
  },
  locatTitle: {
    ...useCampaignPerfomance.campTitle,
  },
  chart: {
    m: ["20px 0 0 0", "35px 0 30px 24px", "35px 0 40px 24px"],
    width: ["100%", "100%", "700px"],
    height: ["350px", "350px", "400px"],
  },
  locatGridBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: ["100%", "100%", "37%"],
    mt: ["25px", "35px"],
    mb: [0, "30px"],
  },
  locatGrid: {
    display: "flex",
    width: ["85%", "95%", "75%"],
    height: "80%",
  },
};

export const useFilterStyle = {
  reportEmp: {
    display: "flex",
    justifyContent: { md: "space-between" },
    flexDirection: {
      sm: "column",
      md: "row",
    },
    alignItems: { md: "center" },
    width: "100%",
  },
  reportInf: {
    display: {
      xs: "none",
      sm: "flex",
    },
    flexDirection: {
      sm: "column",
      md: "row",
    },
    width: "100%",
    p: "24px",
    alignItems: {
      md: "center",
    },
    justifyContent: "space-between",
  },
  selectCon: {
    display: ["none", "flex"],
    alignItems: { md: "center" },
    mt: { sm: 5, md: 0, lg: 0 },
  },
  selectBox: {
    "& button": {
      width: {
        sm: "100%",
        md: "200px",
      },
      display: "flex",
      justifyContent: "space-between",
      px: "20px",
      maxHeight: {
        sm: "35px",
        md: "40px",
      },
      minHeight: {
        sm: "35px",
        md: "40px",
      },
    },
    width: {
      sm: "100%",
      md: "initial",
    },
  },
  menuDate: {
    "& > .MuiPaper-root": {
      border: "1.5px solid #8C499C",
      mt: "10px",
      minHeight: "374px",
      width: "773px",
      borderRadius: "20px",
    },
  },
  menuDateGridCon: {
    "& .datepicker-calendar-container": {
      border: "none",
      boxShadow: "none",
      ml: "-5px",
    },
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    borderRadius: "4px",
    "&:hover": {
      bgcolor: "#EBDFFF",
      color: "#8C499C",
    },
    ml: "10px",
  },
  calendarIcon: {
    cursor: "pointer",
    color: "#8C499C",
  },
  txtFieldDate: {
    mt: "10px",
    mb: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      borderColor: "#8C499C",
    },
  },
  filterButtomBox: {
    display: "flex",
    justifyContent: "space-evenly",
    "& svg": { width: ["16px", "16px", "20px"] },
    "& button:first-of-type": {
      width: ["100%", "100%", "188px"],
      height: ["32px", "32px", "40px"],
      ml: "10px",
      "& p:first-of-type": {
        display: ["none", "none", "block"],
        fontSize: "14px",
        fontWeight: 500,
      },
      "& p:last-of-type": {
        display: ["block", "block", "none"],
        fontSize: "13px",
        fontWeight: 500,
      },
    },
    "& button:last-of-type": {
      ml: "10px",
      height: ["32px", "32px", "40px"],
      width: ["100%", "80%", "188px"],
      color: "white",
      shadow: 1,
      border: "none",
      "&:hover": {
        border: "none",
      },
      "& p:first-of-type": {
        display: ["none", "none", "block"],
        fontSize: "14px",
      },
      "& p:last-of-type": {
        display: ["block", "block", "none"],
        fontSize: "13px",
      },
    },
    width: {
      sm: "100%",
      md: "initial",
    },
  },
  downloadBotton: {
    "& > .MuiPaper-root": {
      border: "1.5px solid #8C499C",
      mt: "8px",
      minHeight: "50px",
      width: "185px",
      borderRadius: "20px",
    },
  },
  downloadMenuItem: {
    "&:hover": {
      color: "#8C499C",
      fontWeight: 500,
      bgcolor: "#EBDFFF",
    },
  },
  filterMobileScreen: {
    mb: "10px",
    mt: "-16px",
    display: "none",
    alignItems: "center",
    justifyContent: "space-between",
  },
  reportButton: {
    height: 32,
    border: "none",
    "&:hover": { border: "none" },
  },
  filterPeriodBox: {
    "& .css-165kgp7-MuiInputAdornment-root .MuiSvgIcon-root": {
      color: hPurple[200],
      fontSize: "25px",
    },
  },
  drawer: {
    display: ["block", "none"],
    "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
      borderRadius: "20px 0 0 20px",
      height: "100%",
      maxHeight: "800px",
    },
  },
  drawerItemTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    p: "15px 20px",
    "& p": {
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
  filterDaysBox: {
    width: "260px",
    p: "15px 20px 0 20px",
    position: "relative",
  },
  formLabel: {
    fontWeight: "bold",
    color: "black",
    "&:hover": { fontWeight: "bold" },
  },
  applyFilter: {
    mb: "-15px",
    pb: "15px",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    bottom: "-210px",
    width: "100%",
    "& hr": { m: "15px -20px" },
    "& div:first-of-type": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "& button": {
        border: "none",
        "&:hover": { border: "none" },
      },
    },
  },
};

export const useCampaignReportFilterSmStyle = {
  drawer: {
    "& > .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
      borderRadius: {
        xs: 0,
        sm: "25px 0 0 26px",
      },
    },
  },
  filterTitle: {
    p: "12px 12px 12px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    bgcolor: "#fff",
    zIndex: 1000,
  },
  filterDateList: {
    height: "36px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      bgcolor: "#EBDFFF",
      "& p": {
        color: "#8C499C",
      },
    },
  },
  datePicker: {
    mt: "10px",
    alignItems: "center",
    "& .datepicker-calendar-container": {
      border: "none",
      boxShadow: "none",
      "& .react-datepicker__month-container": {
        width: "100%",
      },
    },
  },
  calendarIcon: {
    cursor: "pointer",
    color: "#8C499C",
  },
  txtFieldDate: {
    mb: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      borderColor: "#8C499C",
    },
  },
  applyBox: {
    p: "12px 12px 12px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    bgcolor: "#fff",
    zIndex: 1000,
  },
  applyBtn: {
    width: "118px",
    maxHeight: "32px",
    border: "none",
    "&:hover": { border: "none" },
  },
};
