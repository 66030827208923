import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { TeamAPI } from "@api/global";
import { ITeamState } from "./type";

const initialState: ITeamState = {
  data: null,
  loading: false,
  error: "",
};

export const getTeamAsync = createAsyncThunk(
  "getTeamAsync",
  async () => {
    try {
      const response = await TeamAPI.getOneTeam();
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const TeamSlice = createSlice({
  name: "teamSlice",
  initialState,
  reducers: {
    setTeamInfo: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getTeamAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getTeamAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const { setTeamInfo } = TeamSlice.actions;

export const selectTeamInfo = (state: RootState) => state.teamInfo.data;
export const selectTeamInfoOnload = (state: RootState) => state.teamInfo.loading;

export default TeamSlice.reducer;
