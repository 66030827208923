import React, { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Icon,
  Paper,
  Stack,
  Table,
  Avatar,
  Button,
  Switch,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  TableContainer,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// type
import { IKeyReport, IKeyManagement } from "./PlanList.type";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectPackageList, setPackageSelected } from "@slices/PackageInfo.slice";
import { getEmployerInfoAsync, selectEmployerInfo, selectEmployerInfoOnload } from "@slices/EmployerInfo.slice";
// route and constants
import { useHistory, useParams } from "react-router-dom";
import { subscriptionPlan } from "@components/subscription/Subscription.constants";
// style and util
import { useBeforeUpgradePlanStyle as classes } from "./PlanList.style";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// component
import Loading from "@components/global/loading/Loading";
import ChangePlanDialog from "@components/subscription/subscription-dialog/ChangePlan";

export const platformMatchIcon = (platform?: string) => {
  if (!platform) return;
  const icon =
    platform === "facebook"
      ? "facebook-icon.png"
      : platform === "instagram"
      ? "ig-icon.png"
      : platform === "tiktok"
      ? "tiktok-icon.png"
      : platform === "twitter"
      ? "twitter_5969020.png"
      : platform === "youtube"
      ? "youtube-icon.png"
      : null;
  if (!icon) return;
  return (
    <Avatar
      src={require(`@assets/images/social/${icon}`)}
      alt="platform"
      sx={{ width: "24px", height: "24px", borderRadius: 0 }}
    >
      {platform.charAt(0)}
    </Avatar>
  );
};

export default function PackagePlanList() {
  const { plan, type } = useParams<{ plan:string; type:string }>()
  const { goBack, replace, push } = useHistory();
  const { session_token } = getTokenFromStorage();

  const dispatch = useAppDispatch()
  const employerInfo = useAppSelector(selectEmployerInfo);
  const packageList = useAppSelector(selectPackageList);
  const isLoading = useAppSelector(selectEmployerInfoOnload);

  const planList = Object.values(packageList || []);
  const teamPackage = employerInfo?.team?.package;
  const personalPackage = employerInfo?.package;
  const packagePlan = plan === "team" ? teamPackage : personalPackage;

  const [numTeam, setNumTeam] = useState(2);
  const [isYearlyPlan, setIsYearlyPlan] = useState(packagePlan?.plan === 'yearly');
  const [openChangeDialog, setOpenChangeDialog] = useState(false);
  const [upgradeType, setUpgradeType] = useState("upgrade");

  useEffect(() => {
    dispatch(getEmployerInfoAsync(session_token?.employer_id || ""));
  }, [dispatch, session_token?.employer_id]);

  const numberFormat = Intl.NumberFormat("en", {
    maximumFractionDigits: 3,
  });

  const displayValue = (value?: number | boolean) => {
    if (value) {
      return <CheckCircleIcon fontSize="small" color="success" />;
    } else if ((Number(value) || 0) > 0) {
      return value;
    } else return "";
  };

  const TableHeadRow = (props: { title: string }) => {
    const { title } = props;
    return (
      <TableRow sx={classes.tBTRow}>
        <TableCell align="left" sx={{ bgcolor: "#F5F6F8" }}>
          <Box display="flex" alignItems="center" ml="10px">
            <Typography variant="h6" textTransform="capitalize">
              {title}
            </Typography>
          </Box>
        </TableCell>
        {[...Array(4)].map((_, i) => (
          <TableCell key={i} sx={{ bgcolor: "#F5F6F8" }} />
        ))}
      </TableRow>
    );
  };

  const TableBodyRow = (props: {
    keyType: string;
    plan: string;
    subKeyType?: string;
    keyManagement?: IKeyManagement;
    keyNotification?: "is_line_chat";
    keyReport?: "is_report" | "is_report_export";
  }) => {
    const {
      keyType,
      plan,
      subKeyType,
      keyManagement,
      keyNotification,
      keyReport,
    } = props;

    const text = (txt?: any) => {
      return (
        <Typography variant="title1" textTransform="capitalize">
          {txt}
        </Typography>
      );
    };
    const planList = (packageList || []).find((p) => p.title === plan);
    if (keyType === "management" && keyManagement) {
      const typeValue = planList?.campaign_management[keyManagement];
      if (typeof typeValue === "number") {
        return text(typeValue);
      } else {
        const value = (typeValue || []).find((p) => p.title === subKeyType);
        if (!value) return null;
        return text(displayValue(value.id));
      }
    } else if (keyType === "report" && keyReport) {
      const typeValue = planList?.report[keyReport];
      if (!typeValue) return null;
      return text(displayValue(typeValue));
    } else if (keyType === "notification" && keyNotification) {
      const typeValue = planList?.notification[keyNotification];
      if (!typeValue) return null;
      return text(displayValue(typeValue));
    } else {
      return null;
    }
  };

  const teamManageValue = (plan: string, isTeam: boolean) => {
    const value = planList.find((item) => item.title === plan);
    const team = value?.team_management;
    if (isTeam) {
      if (team?.team_limit === 0) {
        return "Unlimited";
      } else if (team?.team_limit === -1) {
        return 0;
      } else {
        return `${team?.team_limit} (${team?.usage_limit})`;
      }
    } else {
      return plan === "personal" ? 1 : team?.usage_limit;
    }
  };

  const feeValue = (
    pk: { value: string; limit: number },
    price: "price" | "price_team" | "price_yearly"
  ) => {
    const value = planList.find((item) => item.title === pk.value);
    const fee = value?.fees[price];
    const extraTeam = 1 + (numTeam - pk.limit);
    if (fee === 0) return "Free";
    const feePrice =
      (fee || 0) * (isYearlyPlan ? 10 : 1) +
      (extraTeam > 0 ? extraTeam * (isYearlyPlan ? 740 * 10 : 740) : 0);
    return numberFormat.format(feePrice);
  };

  const handleChangePlan = (plan:string) => {
    replace(`/subscription/${type}/${plan}`)
  }

  const onChangePlan = (
    val: boolean,
    planSelected: string,
    price: string,
    member: number,
    packageId: number,
    type: string
  ) => {
    const data = {
      title: planSelected,
      member: plan === "personal" ? 1 : member,
      plan: isYearlyPlan ? "yearly" : "monthly",
      price: price,
      package_plan: plan,
      package_id: packageId,
    };

    if (plan === "team" && employerInfo?.team === null) {
      push("/team", { redirect_path: "/subscription/replan/team" })
    }

    dispatch(setPackageSelected(data))
    setUpgradeType(type)
    setOpenChangeDialog(val);
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Box sx={classes.titleWrap}>
        <Box
          display="inline-flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={goBack}
        >
          <Icon className="ico-hu-chevron-left" fontSize="small" />
          <Typography variant="title1">Back</Typography>
        </Box>
        <Typography
          sx={{ width: "100%" }}
          display="block"
          variant="h4"
          fontWeight={400}
          textAlign="center"
        >
          Choose Your Subscription Plan
        </Typography>
      </Box>
      <Box sx={classes.tableWrap}>
        <TableContainer sx={classes.tContainer} component={Paper}>
          <Table sx={{ "& td": { minWidth: "170px" } }}>
            <TableHead>
              <TableRow sx={classes.tHRow}>
                {["", "free", "basic", "professional", "enterprise"].map(
                  (row, index) => (
                    <TableCell
                      key={index}
                      sx={
                        classes[
                          (packagePlan?.title || "free") === row
                            ? "tRowIsCurrent"
                            : "tRowIsNotCurrent"
                        ]
                      }
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {(packagePlan?.title || "free") === row && (
                          <Icon
                            className="ico-hu-person"
                            fontSize="small"
                            sx={{ mr: "8px" }}
                          />
                        )}
                        <Typography variant="h6" textTransform="capitalize">
                          {row}
                        </Typography>
                      </Box>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody sx={classes.tBody}>
              <TableRow sx={classes.tBRow}>
                <TableCell align="left">
                  <Box display="flex" flexDirection="column" ml="10px">
                    <Typography
                      variant="title1"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Available Social Platforms
                    </Typography>
                    <Typography variant="body2" color="#505050">
                      จำนวนแคมเปญที่สร้างได้ต่อเดือน
                    </Typography>
                  </Box>
                </TableCell>
                {["free", "basic", "professional", "enterprise"].map((row, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{
                      bgcolor:
                        row === (packagePlan?.title || "free")
                          ? "#F5EEFF"
                          : undefined,
                    }}
                  >
                    <Stack sx={classes.platformIcon}>
                      {planList.find((p) => p.title === row)?.campaign_management.platform.map((platform, index) => (
                        <React.Fragment key={index}>
                          {platformMatchIcon(platform.title)}
                        </React.Fragment>
                      ))}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>

              {subscriptionPlan.map((row, index) => (
                <React.Fragment key={index}>
                  <TableHeadRow title={row.name} />
                  {row.item.map((irow, iIndex) => (
                    <TableRow key={iIndex} sx={classes.tBRow}>
                      <TableCell align="left">
                        <Box display="flex" flexDirection="column" ml="10px">
                          <Typography
                            variant="title1"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {irow.label}
                          </Typography>
                          <Typography variant="body2" color="#505050">
                            {irow.subLabel}
                          </Typography>
                        </Box>
                      </TableCell>
                      {["free", "basic", "professional", "enterprise"].map(
                        (plan, pIndex) => (
                          <TableCell
                            key={pIndex}
                            align="center"
                            sx={{
                              minWidth: "120px",
                              maxWidth: "120px",
                              bgcolor:
                                (packagePlan?.title || "free") === plan
                                  ? "#F5EEFF"
                                  : undefined,
                            }}
                          >
                            <TableBodyRow
                              keyType={row.key}
                              subKeyType={irow.subKeyType}
                              keyManagement={irow.keyValue as IKeyManagement}
                              keyReport={irow.keyValue as IKeyReport}
                              keyNotification={irow.keyValue as "is_line_chat"}
                              plan={plan}
                            />
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}

              <TableHeadRow title="Team Management" />
            </TableBody>
          </Table>

          <Box
            display="flex"
            justifyContent="center"
            my="24px"
            columnGap="20px"
          >
            <Box textAlign="center">
              <Typography variant="title1" color="#505050">
                เลือกรูปแบบการใช้งานที่เหมาะกับคุณ
              </Typography>

              <Box sx={classes.planSelecedWrap}>
                {[
                  { label: "Personal (ส่วนตัว)", value: "personal" },
                  { label: "Team (ทีม)", value: "team" },
                ].map((item, index) => (
                  <Button
                    key={index}
                    variant={plan === item.value ? "contained" : "outlined"}
                    onClick={() => handleChangePlan(item.value)}
                    fullWidth
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>
            </Box>
            {plan === "team" && (
              <div>
                <Typography variant="title1" color="#505050">
                  โปรดระบุจำนวนสมาชิกของทีม
                </Typography>

                <Box sx={classes.numTeamWrap}>
                  <IconButton
                    disabled={numTeam <= 2}
                    onClick={() => setNumTeam(numTeam - 1)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  {numTeam}
                  <IconButton onClick={() => setNumTeam(numTeam + 1)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              </div>
            )}
          </Box>

          <Table sx={{ "& td": { minWidth: "170px" } }}>
            <TableBody sx={classes.tBody}>
              {[
                { label: "Member Limit", subLabel: "จำนวนสมาชิกในทีมทั้งหมด" },
                {
                  label: "Able to add member more (Maximum User Limit)",
                  subLabel: "จำนวนสมาชิกที่สามารถเพิ่มได้ (จำนวนสูงสุด)",
                  display: plan === "personal" ? "none" : undefined,
                },
              ].map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ ...classes.tBRow, display: row.display }}
                >
                  <TableCell align="left" sx={{ maxWidth: "200px" }}>
                    <Box display="flex" flexDirection="column" ml="10px">
                      <Typography
                        variant="title1"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.label}
                      </Typography>
                      <Typography variant="body2" color="#505050">
                        {row.subLabel}
                      </Typography>
                    </Box>
                  </TableCell>
                  {["free", "basic", "professional", "enterprise"].map(
                    (plan, pIndex) => (
                      <TableCell
                        key={pIndex}
                        align="center"
                        sx={{
                          minWidth: "120px",
                          bgcolor:
                            (packagePlan?.title || "free") === plan
                              ? "#F5EEFF"
                              : undefined,
                        }}
                      >
                        <Typography variant="title1" textTransform="capitalize">
                          {teamManageValue(plan, index === 1)}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
              <TableRow sx={classes.tBTRow}>
                <TableCell align="left" sx={{ bgcolor: "#F5F6F8" }}>
                  <Box display="flex" alignItems="center" ml="10px">
                    <Typography variant="h6" textTransform="capitalize">
                      fees
                    </Typography>
                  </Box>
                </TableCell>
                {[...Array(Object.keys(planList).length)].map((_, i) => (
                  <TableCell key={i} sx={{ bgcolor: "#F5F6F8" }} />
                ))}
              </TableRow>

              <TableRow sx={{ height: "80px" }}>
                <TableCell align="left">
                  <Box display="flex" alignItems="center">
                    <Switch
                      checked={isYearlyPlan}
                      onChange={(e) => setIsYearlyPlan(e.target.checked)}
                    />
                    <Box display="flex" flexDirection="column" ml="10px">
                      <Typography variant="title1" textTransform="capitalize">
                        Pay annually (Get 2 months free)
                      </Typography>
                      <Typography variant="body2" mt="5px">
                        จ่ายรายปี (รับ 2 เดือนฟรี)
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                {[
                  { value: "free", limit: 1 },
                  { value: "basic", limit: 3 },
                  { value: "professional", limit: 5 },
                  { value: "enterprise", limit: 10 },
                ].map((pk, index) => (
                  <TableCell key={index} align="center">
                    <Typography variant="h6">
                      {feeValue(pk, plan === "personal" ? "price" : "price_team")}
                    </Typography>
                    <Typography variant="subTitle2">
                      {isYearlyPlan ? "บาท/ปี" : "บาท/เดือน"}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>

              <TableRow sx={classes.tBRowBottom}>
                <TableCell />
                {[
                  { id: 1, value: "free", limit: 1, disabledLimit: 2 },
                  { id: 2, value: "basic", limit: 3, disabledLimit: 3 },
                  { id: 3, value: "professional", limit: 5, disabledLimit: 6 },
                  { id: 4, value: "enterprise", limit: 10, disabledLimit: 100 },
                ].map((pk, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{
                      bgcolor:
                        (packagePlan?.title || "free") === pk.value
                          ? "#F5EEFF"
                          : undefined,
                      borderRadius: "0 0 12px 12px",
                    }}
                  >
                    <Button
                      variant={
                        index < (packagePlan?.id || 0)
                          ? "outlined"
                          : "contained"
                      }
                      fullWidth
                      disabled={
                        (plan === "team" &&
                          numTeam > pk.disabledLimit) ||
                        (packagePlan?.title || "free") === pk.value
                      }
                      onClick={() =>
                        onChangePlan(
                          true,
                          pk.value,
                          feeValue(pk, plan === "personal" ? "price" : "price_team"),
                          pk.limit >= numTeam ? pk.limit : numTeam,
                          pk.id,
                          index < (packagePlan?.id || 0) ? "downgrade" : "upgrade"
                        )
                      }
                      sx={classes.upgradBtn}
                    >
                      {(packagePlan?.title || "free") === pk.value
                        ? "Current Plan"
                        : numTeam > pk.disabledLimit && plan === "team"
                        ? "Unvaliabled"
                        : index < (packagePlan?.id || 0)
                        ? "Downgrade"
                        : "Upgrade"}
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ChangePlanDialog
        open={openChangeDialog}
        onClose={setOpenChangeDialog}
        packagePlan={packagePlan}
        plan={plan}
        upgradeType={upgradeType}
      />
    </div>
  );
}
