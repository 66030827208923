import { useState } from "react";
// mui and icons
import { Box, Icon, Grid, Stack, Divider, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// style and type
import { useImagesStyle as classes } from "../JobRejected.style";
import { IJobRejectedVideos } from "../../../JobsTab.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// components and others
import MultiUrlInput from "../../../job-default/MultiUrlInput";
import MuitiVideoInput from "../../../job-default/MultiVideoInput";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import ReactPlayer from "react-player";

export default function Videos(props: IJobRejectedVideos) {
  const {
    form: {
      watch,
      setValue,
      formState: { errors },
    },
    isDraft,
    isEditJob,
    newAttList,
    attRejectedList,
    urlRejectedList,
  } = props;

  const [viewDraftVideo, setViewDraftVideo] = useState(false);

  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const contentType = jobInfo?.content_type;
  const attList = jobInfo?.attachments || [];
  const attApprovedList = attList.filter(
    (att) => att.is_approve === "approved"
  );
  const attNotApproveList = attRejectedList.filter(
    (att) => att.is_approve === "not_approved"
  );
  const newUrlList = (jobInfo?.external_file || []).filter(
    (url) => url.is_approve !== "drop" && url.is_approve !== "not_approved"
  );
  const attsWatch = watch("attachments") || [];
  const isVideo = /(video)/.test(contentType?.type || "");
  const isDraftAtt = isVideo
    ? !attApprovedList.length && isEditJob
    : isEditJob && attNotApproveList.length;

  return (
    <Box sx={classes.imageCon}>
      <Box sx={classes.title}>
        <Typography>Videos</Typography>
        <Typography>
          อัพโหลดวิดีโอการรีวิวสินค้า (ไฟล์วิดีโอไม่เกิน 8 mb)
          วันที่อินฟลูเอนเซอร์โพสต์งาน
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0], mr: ["24px", 0] }} />
      {isDraft ? (
        <Box m="24px">
          {newAttList.length ? (
            <Box mb="24px">
              <Typography variant="title2">อัปโหลดจากคอมพิวเตอร์</Typography>
              <Stack
                direction="row"
                flexWrap="wrap"
                rowGap={[2, 7]}
                columnGap={[2, 5]}
                mt="12px"
              >
                {newAttList.map((att, index) => (
                  <Box
                    key={index}
                    onClick={() => setViewDraftVideo(true)}
                    sx={classes.draftVideo}
                    width="120px"
                    height="120px"
                  >
                    <Box sx={classes.iconPlayVido}>
                      <Icon
                        sx={{
                          fontSize: "40px",
                          color: "#fff",
                        }}
                        className="ico-hu-play-circle-outline"
                      />
                    </Box>
                    <ReactPlayer
                      url={att.url}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={true}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          ) : null}
          {newUrlList.length ? (
            <Box>
              <Typography variant="title2">
                แนบลิงค์ URL หรือแหล่งที่มาของวิดีโอ
              </Typography>
              <Stack direction="row" sx={classes.isDraftBox}>
                {newUrlList.map((_url, index) => (
                  <Box key={index}>
                    <Box sx={classes.urlBox}>
                      <Box sx={classes.urlIcon}>
                        <Icon className="ico-hu-conversion" />
                        <Typography variant="body2" mt="15px">
                          Video URL
                        </Typography>
                      </Box>
                      <Box sx={classes.urlImg}>
                        <img src={urlLogo} alt="item" />
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" mt="12px">
                      <Typography variant="title2" textAlign="center">
                        {index + 1}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
      ) : (
        <>
          {attRejectedList.length ? (
            <Grid container direction="column" spacing={5} p="24px">
              <Box sx={classes.titleImgItem}>
                <CancelIcon />
                <Typography>วิดีโอที่ต้องแก้ไข</Typography>
              </Box>
              <Typography variant="title1" ml="20px" mb="12px">
                อัปโหลดไฟล์จากคอมพิวเตอร์
              </Typography>
              <Stack
                direction="row"
                columnGap={[7, 4, 7]}
                rowGap={7}
                flexWrap="wrap"
                mr={5}
                ml={[0, 5]}
              >
                {attRejectedList.map((att, index) => (
                  <Box key={index} sx={classes.imgItemEditingGrid}>
                    <Typography variant="title2" mb="8px">
                      {`วิดีโอที่ ${att.order}`}
                    </Typography>
                    <Box
                      onClick={() => setViewDraftVideo(true)}
                      sx={classes.imgItemEditing}
                    >
                      <Box sx={classes.iconPlayVido}>
                        <Icon
                          sx={{
                            fontSize: "40px",
                            color: "#fff",
                          }}
                          className="ico-hu-play-circle-outline"
                        />
                      </Box>
                      <ReactPlayer
                        url={att.url}
                        width="100%"
                        loop={true}
                        muted={true}
                      />
                    </Box>

                    <Box sx={classes.imgItemEditingDesc}>
                      <Typography>ความคิดเห็นจากผู้จ้างงาน :</Typography>
                      <Typography>{att.note}</Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Grid>
          ) : null}
          {urlRejectedList.length ? (
            <Grid container direction="column" spacing={5} p="24px">
              <Typography variant="title1" ml="20px" mb="12px">
                แนบลิงค์ URL
              </Typography>
              <Stack
                direction="row"
                columnGap={[7, 4, 7]}
                rowGap={7}
                flexWrap="wrap"
                mr={5}
                ml={[0, 5]}
              >
                {urlRejectedList.map((att, index) => (
                  <Box key={index} sx={classes.imgItemEditingGrid}>
                    <Typography variant="title2" mb="8px">
                      {`วิดีโอที่ ${att.order}`}
                    </Typography>
                    <Box
                      sx={{
                        ...classes.urlBox,
                        width: "240px",
                        height: "240px",
                      }}
                    >
                      <Box sx={classes.urlIcon}>
                        <Icon className="ico-hu-conversion" />
                        <Typography variant="body2" mt="15px">
                          Video URL
                        </Typography>
                      </Box>
                      <Box sx={classes.urlImg}>
                        <img src={urlLogo} alt="item" />
                      </Box>
                    </Box>

                    <Box sx={classes.imgItemEditingDesc}>
                      <Typography>ความคิดเห็นจากผู้จ้างงาน :</Typography>
                      <Typography>{att.note}</Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Grid>
          ) : null}
          {isDraftAtt ? (
            <>
              <Divider sx={{ m: "0 24px 24px 24px" }} />
              <Box p={["0 24px 24px 0", "0 24px 24px 24px"]}>
                <Box display="flex" mb="15px">
                  <AddCircleRoundedIcon color="primary" />
                  <Typography color="primary" fontWeight="500" ml={1.5}>
                    เพิ่มวิดีโอ
                  </Typography>
                </Box>
                <Box display="flex" mb="20px">
                  <Typography textAlign="center">เครื่องหมาย </Typography>
                  <Icon
                    className="ico-hu-check-circle"
                    color="success"
                    sx={{ mx: "5px" }}
                  />{" "}
                  <Typography textAlign="center">
                    บนวิดีโอ คือ วิดีโอ ทีได้รับการอนุมัติจากผู้จ้างงานแล้ว
                  </Typography>
                </Box>
                <div>
                  <Box display="flex" flexDirection="column" mb="12px">
                    <Typography variant="subTitle2">
                      การอัปโหลดวิดีโอ สามารถดำเนินการได้ 2 แบบดังนี้
                    </Typography>
                    <Typography variant="subTitle2" ml={2}>
                      1.อัปโหลดจากคอมพิวเตอร์ ขนาดไฟล์ไม่เกิน 25 MB
                      และรองรับสกุลไฟล์ .mp4
                    </Typography>
                  </Box>
                  <Box sx={classes.multiImgInputBox}>
                    <MuitiVideoInput
                      keyName="attachments"
                      setValue={setValue}
                      videos={attsWatch}
                      errors={errors.attachments}
                      showVideoIndex
                      disableIsActive
                    />
                  </Box>
                </div>
                <Box>
                  <Box mt="20px" mb="12px">
                    <Typography variant="subTitle2" ml={2}>
                      2.แนบลิงค์ URL หรือแหล่งที่มาของวิดีโอ
                    </Typography>
                  </Box>
                  <Box sx={classes.multiImgInputBox}>
                    <MultiUrlInput
                      keyName="external_file"
                      setValue={setValue}
                      urls={watch("external_file") || []}
                      errors={errors.attachments}
                      showUrlIndex
                    />
                  </Box>
                </Box>
              </Box>
            </>
          ) : attApprovedList.length ? (
            <>
              <Divider sx={{ m: "0 24px 24px 24px" }} />
              <Box p={["0 24px 24px 0", " 0 24px 24px 24px"]}>
                <Box sx={classes.imgItemApprove}>
                  <CheckCircleIcon />
                  <Typography>วิดีโอที่ได้รับการอนุมัติแล้ว</Typography>
                </Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  rowGap={[2, 7]}
                  columnGap={[2, 5]}
                >
                  {attApprovedList.map((item, index) => (
                    <Box key={index}>
                      <Typography variant="title1">
                        {`วิดีโอที่ ${item.order}`}
                      </Typography>
                      <Box
                        onClick={() => setViewDraftVideo(true)}
                        sx={classes.draftVideo}
                        width="120px"
                        height="120px"
                        mt={2}
                      >
                        <Box sx={classes.iconPlayVido}>
                          <Icon
                            sx={{
                              fontSize: "40px",
                              color: "#fff",
                            }}
                            className="ico-hu-play-circle-outline"
                          />
                        </Box>
                        <ReactPlayer
                          url={item.url}
                          width="100%"
                          height="100%"
                          loop={true}
                          muted={true}
                        />
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </>
          ) : null}
        </>
      )}
      <ViewMultiVideoDialog
        open={viewDraftVideo}
        onClose={setViewDraftVideo}
        videos={jobInfo?.attachments || attApprovedList || attRejectedList}
      />
    </Box>
  );
}
