// mui and classes
import { Box, Button, Grid, Icon, Stack } from "@mui/material";
import { usePostSinglePhotoStyle as classes } from "../../EmployerJob.style";
// components and type
import PostCaption from "../post-default/PostCaption";
import PostSinglePhotoImage from "./PostSinglePhotoImage";
import AdditionalComment from "../post-default/AdditionalComment";
import { IEmployerReviewPostProps } from "../../EmployerJob.type";
// other
import { useHistory } from "react-router-dom";

export default function PostSinglePhoto(props: IEmployerReviewPostProps) {
  const {
    form,
    jobInfo,
    onCancelJob,
    onRejectJob,
    onApproveJob,
  } = props;

  const { handleSubmit, watch } = form;

  const history = useHistory();

  const isJobRejected = jobInfo?.status === "rejected";
  const isJobCancled = jobInfo?.status === "canceled";
  const draftCount = jobInfo?.draft_count || 0;
  const campaignInfo = jobInfo?.campaign_info;
  const attachments = watch("attachments") ;
  const description = watch("description");
  const isDraftApproved =
    description.is_approve === "approved" &&
    attachments.filter((att) => att.is_approve === "approved").length;

  const attRejectedList = attachments.filter(
    (att) => (att.is_approve === "not_approved" && att.note) ||
    att.is_approve === "drop"
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PostSinglePhotoImage
          form={form}
          jobInfo={jobInfo}
          isJobRejected={isJobRejected}
        />
      </Grid>
      <Grid item xs={12}>
        <PostCaption
          form={form}
          jobInfo={jobInfo}
          isJobRejected={isJobRejected}
        />
      </Grid>
      <Grid item xs={12}>
        {(jobInfo?.comment ||
          (isJobRejected && jobInfo?.comment) ||
          jobInfo?.status === "first_draft" ||
          jobInfo?.status === "waiting_for_approve") && (
          <AdditionalComment
            isJobRejected={isJobRejected || isJobCancled}
            register={form.register}
            comment={jobInfo?.comment}
            orgUrl={campaignInfo?.organisation?.attachment?.url}
          />
        )}
      </Grid>
      {!isJobRejected && !isJobCancled ? (
        <Grid item xs={12} display="flex" justifyContent="center">
          {draftCount < 2 ? (
            isDraftApproved ? (
              <Button
                startIcon={
                  <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
                }
                disabled={
                  attachments.filter((att) => att.is_approve !== "drop" && att.is_approve !== "approved").length 
                  || description.is_approve !== "approved"
                  ? true
                  : false
                }
                color="success"
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => handleSubmit(onApproveJob)()}
              >
                Draft ผ่านแล้วโพสต์ได้เลย
              </Button>
            ) : (
              <Button
                startIcon={
                  <Icon className="ico-hu-comment" sx={{ fontSize: "20px" }} />
                }
                disabled={
                  attachments.filter((att) => (att.is_approve === "not_approved" && !att.note) || att.is_approve === "new").length 
                  || description.is_approve === "new"
                  || (description.is_approve === "not_approved" && !description.note)
                  ? true
                  : false
                }
                color="error"
                variant="contained"
                sx={classes.submitBtn}
                onClick={() => handleSubmit(onRejectJob)()}
              >
                ส่ง Feedback ครั้งที่ {draftCount + 1}/2
              </Button>
            )
          ) : (
            <Stack direction="row" alignItems="center" spacing={2}>
              {attachments.filter((att) => att.is_approve === "approved").length 
              && description.is_approve === "approved" ? (
                <Button
                  startIcon={
                    <Icon className="ico-hu-check" sx={{ fontSize: "20px" }} />
                  }
                  color="success"
                  variant="contained"
                  sx={classes.submitBtn}
                  onClick={() => handleSubmit(onApproveJob)()}
                >
                  Draft ผ่านการอนุมัติ
                </Button>
              ) : (
                <Button
                  startIcon={
                    <Icon className="ico-hu-close" sx={{ fontSize: "20px" }} />
                  }
                  color="error"
                  variant="contained"
                  disabled={
                    !attRejectedList.length 
                    || (description.is_approve === "not_approved" && !description.note) 
                    || description.is_approve === "new"
                    ? true
                    : false
                  }
                  sx={classes.submitBtn}
                  onClick={() => onCancelJob()}
                >
                  ยกเลิกการจ้างงาน
                </Button>
              )}
            </Stack>
          )}
        </Grid>
      ) : (
        !isJobCancled && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="outlined"
                onClick={() => history.goBack()}
                sx={{ width: "250px" }}
              >
                กลับไปหน้า "รอแก้ไข"
              </Button>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
}
