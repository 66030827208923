export const useInfluencerProfileCardStyle = {
  postReportCard: {
    border: "2px solid #EBDFFF",
    borderRadius: "12px",
    padding: "24px",
    display: "flex",
    "& > .MuiAvatar-root": {
      width: "100px",
      height: "100px",
      borderRadius: "8px",
      mr: "22px",
    },
  },
  cardBoxRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardRigthItem1: {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: "22px",
      height: "22px",
      mr: "8px",
    },
    "& p": {
      fontSize: "20px",
      fontWeight: 500,
    },
  },
  circle: {
    fontSize: "6px",
    color: "#E0E0E0",
  },
  cardRigthItem2: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "16px",
      color: "#616161",
    },
    "& p": {
      fontSize: "14px",
      color: "#616161",
      ml: "4px",
    },
  },
  chip: {
    maxHeight: "28px",
    bgcolor: "#EBDFFF",
    color: "#8C499C",
  },
};

export const useInfluencerPostReportStyle = {
  accountName: {
    display: "flex",
    alignItems: "center",
    width: "55%",
    "& > p": {
      mr: "20px",
      fontSize: "14px",
    },
  },
  groupBy: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      fontSize: "14px",
      width: "80px",
    },
    "& > fieldset": {
      borderColor: "red",
      border: "1px solid red",
    },
  },
  groupByBtn: {
    height: "40px",
    width: "200px",
    mr: "20px",
    justifyContent: "space-between",
    borderWidth: "1px",
  },
  menuDate: {
    "& > .MuiPaper-root": {
      border: "1.5px solid #8C499C",
      mt: "10px",
      height: "374px",
      width: "773px",
      borderRadius: "20px",
    },
  },
  menuDateGridCon: {
    "& .datepicker-calendar-container": {
      border: "none",
      boxShadow: "none",
      ml: "-5px",
    },
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    borderRadius: "4px",
    "&:hover": {
      bgcolor: "#EBDFFF",
      color: "#8C499C",
    },
    ml: "10px",
  },
  calendarIcon: {
    cursor: "pointer",
    color: "#8C499C",
  },
  txtFieldDate: {
    mt:"5px",
    mb: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      borderColor: "#8C499C",
    },
  },
  downloadBtn: {
    width: "188px",
    border: "none",
    "&:hover": { border: "none" },
  },
  downloadMenu: {
    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
      border: "1px solid #ED1968",
      pt: 0,
      pb: 0,
      borderRadius: "0 0 20px 20px",
    },
  },
  menuItems1: {
    width: "186px",
    "&:hover": { bgcolor: "#FFEAF4", color: "#ED1968" },
  },
  menuItems2: {
    width: "186px",
    "&:hover": {
      bgcolor: "#FFEAF4",
      color: "#ED1968",
      borderRadius: "0 0 20px 20px",
    },
  },
};

export const useDraftApprovalStyle = {
  datePostHeader: {
    mb: "24px",
    "& p:first-of-type": {
      fontWeight: "bold",
      fontSize: "24px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
    },
  },
  datePostBox: {
    padding: "20px",
    borderRadius: "12px",
    border: "1px solid #EBDFFF",
  },
  datePostTitle: {
    "& p:first-of-type": {
      fontSize: "20px",
      fontWeight: "bold",
    },
    "& p:last-of-type": {
      fontSize: "14px",
      color: "#5C5C5C",
      mt: 1,
    },
  },
  publishedBox: {
    height: "68px",
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
    display: "flex",
    p: "16px",
    alignItems: "center",
    mb: "26px",
  },
  publishedIcon: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    bgcolor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mr: "20px",
  },
  divider: {
    bgcolor: "#EBDFFF",
    margin: "20px -20px 28px -20px",
  },
  dateInput: {
    "& > div": { width: "100%" },
    "& .css-zryk4f-MuiFormControl-root-MuiTextField-root": {
      width: "100%",
    },
    "& .css-xtyker": {
      width: "100%",
    },
  },
  publishDate: {
    "& .react-datepicker__day--disabled": {
      color: "#999",
      borderRadius: "50%",
    },
    "& .react-datepicker__day": {
      borderRadius: "50%",
    },
    "& .react-datepicker__day--selected": {
      bgcolor: "primary.main",
      color: "#fff",
    },
    "& .react-datepicker__day--keyboard-selected": {
      bgcolor: "#ebdfff",
    },
  },
  timePicker: {
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      width: "100%",
      height: "40px",
    },
    "& .css-1xhj18k": {
      flexDirection: "column",
    },
    "& .css-1alogc1-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#8c499c",
      },
    "& .time-picker": {
      height: "40px",
    },
  },
  submitDraftBtn: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    width: "100%",
    "& button": {
      color: "#fff",
      border: "none",
      "&:hover": { border: "none" },
      width: "280px",
      maxHeight: "36px",
    },
  },
};

export const useAdditionalCommentStyle = {
  datePostBox: useDraftApprovalStyle.datePostBox,
  datePostTitle: useDraftApprovalStyle.datePostTitle,
  divider: useDraftApprovalStyle.divider,
  comment: {
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
    p: "16px",
    minHeight: "50px",
    width: "100%",
  },
};

export const useProductReturnStyle = {
  productBox: useDraftApprovalStyle.datePostBox,
  productTitle: useDraftApprovalStyle.datePostTitle,
  divider: useDraftApprovalStyle.divider,
  content: {
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
    p: "12px",
  },
};

export const usePublishLinkStyle = {
  productBox: useDraftApprovalStyle.datePostBox,
  productTitle: useDraftApprovalStyle.datePostTitle,
  divider: useDraftApprovalStyle.divider,
  platform: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      textTransform: "capitalize",
      ml: "10px",
    },
  },
  publishLink: {
    height: "40px",
    borderRadius: "50px",
    bgcolor: "#F4ECFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ml: "20px",
      width: {
        xs: "250px",
        sm: "350px",
      },
    },
  },
  publishBtn: {
    display: "flex",
    justifyContent: "center",
    mb: "20px",
    "& button": {
      width: ["100%", "240px"],
      height: "40px",
      border: "none",
      fontSize: "15px",
    },
    "& button:hover": { border: "none" },
  },
  copyBtn: {
    width: "30px",
    minHeight: "20px",
    maxHeight: "30px",
    bgcolor: "#fff",
    mr: "10px",
  },
};

export const useShippingProductDialogStyle = {
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    width: "89%",
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  trackingCode: {
    textDecoration: "underline",
    color: "#8C499C",
  },
  photos: {
    position: "relative",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: "110px",
      height: "110px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  photosItem: {
    position: "absolute",
    right: 15,
    width: "110px",
    height: "110px",
    borderRadius: "8px",
    bgcolor: "#000",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
  closeBtn: {
    mt: "20px",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
      maxHeight: "32px",
    },
  },
};

export const useConversionTrackingStyle = {
  dialog: useShippingProductDialogStyle.dialog,
  dialogTitle: {
    ...useShippingProductDialogStyle.dialogTitle,
    width: "89%",
    mt: "10px",
  },
  contentDialog: useShippingProductDialogStyle.contentDialog,
  linkForInflue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    borderRadius: "50px",
    px: "15px",
    bgcolor: "#F4ECFF",
    "& > p": {
      color: "#893DBD",
      width: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& > svg": {
      cursor: "pointer",
    },
  },
  closeBtn: useShippingProductDialogStyle.closeBtn,
};

export const useReviewPostInfluencerStyle = {
  influPostCon: {
    padding: "20px",
    borderRadius: "12px",
    border: "1px solid #EBDFFF",
  },
  postProfile: {
    display: "flex",
    alignItems: "center",
    mb: "16px",
    "& > img": {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  postImgProfile: {
    my: "16px",
    "& > img": {
      width: "100%",
      height: "380px",
      borderRadius: "4px",
    },
  },
  postImgItem2: {
    display: "flex",
    height: "380px",
    "& img": { width: "190px", objectFit: "cover" },
    justifyContent: "center",
    "& img:first-of-type": { borderRadius: "4px 0 0 4px" },
    "& img:last-of-type": { borderRadius: "0 4px 4px 0" },
  },
  postImgItem3: {
    display: "flex",
    height: "380px",
    "& > img": {
      width: "50%",
      objectFit: "cover",
      borderRadius: "4px 0 0 4px",
    },
    "& > div": {
      "& img": { height: "186.5px", width: "190px", ml: "5px" },
      "& img:first-of-type": { borderRadius: "0 4px 0 0" },
      "& img:last-of-type": { borderRadius: "0 0 4px 0" },
    },
  },
  postImgItem4: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "380px",
      height: "380px",
      borderRadius: "4px",
    },
    "& > div": {
      "& img": {
        width: "120px",
        borderRadius: "4px",
      },
    },
  },
  postImgItemMoreThan4: {
    position: "absolute",
    width: "120px",
    height: "125px",
    right: 0,
    borderRadius: "4px",
    bgcolor: "#000",
    opacity: 0.2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
  urlBox: {
    height: "350px",
    width: "100%",
    borderRadius: "8px",
    position: "relative",
    my: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
    cursor: "pointer",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "80px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const useReviewPostImageStyle = {
  imgCardCon: {
    padding: "24px",
    border: "1px solid #EBDFFF",
    borderRadius: "12px",
  },
  imgCardTitle: {
    "& p:first-of-type": {
      fontSize: "20px",
      fontWeight: "bold",
    },
    "& p:last-of-type": {
      fontSize: "14px",
      color: "#5C5C5C",
      mt: 1,
    },
  },
  divider: {
    bgcolor: "#EBDFFF",
    margin: "20px -25px 32px -25px",
  },
  imgToEditTxt: {
    color: "#E30D18",
    fontSize: "16px",
    fontWeight: 500,
  },
  imgItems: {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "93%",
      height: "280px",
      borderRadius: "4px",
      objectFit: "cover",
    },
    ml: "-15px",
  },
  videoBox: {
    position: "relative",
    mt: 2,
    "& video": { objectFit: "cover" },
    height: {
      xs: "250px",
      lg: "270px",
      xl: "320px",
    },
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
  },
  iconPlayVdo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    "& > .material-icons": {
      fontSize: "40px",
      color: "#fff",
    },
  },
  noteEdit: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px",
      border: "1px solid red",
    },
  },
  influComment: {
    minHeight: "75px",
    bgcolor: "rgba(227, 13, 24, 0.05)",
    borderRadius: "12px",
    padding: "16px",
    mt: "20px",
    "& p:first-of-type": {
      color: "#E30D18",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& p:last-of-type": {
      color: "rgba(0, 0, 0, 0.87)",
      mt: "12px",
    },
  },
  draftVideo: {
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    "& video": {
      objectFit: "cover",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
  },
  imgItemsPost: {
    width: "120px",
    height: "120px",
    borderRadius: "4px",
    mt: 2,
  },
  videoItems: {
    mt: 2,
    position: "relative",
    height: "250px",
    "& > div": {
      height: "100%",
      width: "100%",
      "& video": {
        objectFit: "cover",
      },
    },
  },
};

export const useReviewPostCaptionStyle = {
  imgCardCon: useReviewPostImageStyle.imgCardCon,
  imgCardTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgCardTitle: useReviewPostImageStyle.imgCardTitle,
  convButton: {
    maxHeight: "36px",
    width: "144px",
    fontWeight: "500",
    bgcolor: "#F4ECFF",
    color: "#8C499C",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      bgcolor: "#F4ECFF",
      border: "none",
    },
  },
  divider: useReviewPostImageStyle.divider,
  influComment: {
    minHeight: "75px",
    bgcolor: "rgba(227, 13, 24, 0.05)",
    borderRadius: "12px",
    padding: "12px",
    mt: "4px",
  },
  noteEdit: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px",
      border: "1px solid red",
    },
  },
};

export const usePostSinglePhotoStyle = {
  submitBtn: {
    width: "280px",
    border: "none",
    color: "#fff",
    fontSize: "16px",
    "&:hover": { border: "none" },
    height: "40px",
  },
};

export const usePostSinglePhotoImageStyle = {
  imgCardCon: {
    padding: "24px",
    border: "1px solid #EBDFFF",
    borderRadius: "12px",
  },
  divider: {
    bgcolor: "#EBDFFF",
    margin: "20px -25px 32px -25px",
  },
  imgToEditTxt: {
    color: "#E30D18",
    fontSize: "16px",
    fontWeight: 500,
  },
  imgItems: {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100%",
      height: {
        xs: "250px",
        lg: "270px",
        xl: "320px",
      },
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  noteEdit: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px",
      border: "1px solid #FB8C00",
    },
  },
  influComment: {
    minHeight: "75px",
    bgcolor: "rgba(227, 13, 24, 0.05)",
    borderRadius: "8px",
    padding: "10px",
    mt: "4px",
  },
  draftVideo: {
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    "& video": {
      objectFit: "cover",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
  },
  imgItemsPost: {
    width: "100%",
    height: {
      xs: "250px",
      lg: "270px",
      xl: "320px",
    },
    borderRadius: "8px",
    mt: 2,
  },
  videoItems: {
    mt: 2,
    position: "relative",
    height: "250px",
    "& > div": {
      height: "100%",
      width: "100%",
      "& video": {
        objectFit: "cover",
      },
    },
  },
  menu: {
    "& .MuiPaper-root": {
      borderRadius: "18px",
      mt: "-33px",
    },
    "& ul": {
      border: "1.5px solid #8C499C",
      borderRadius: "18px",
      width: "158px",
      overflow: "hidden",
      "& li:hover": {
        bgcolor: "#F4ECFF",
        color: "#8C499C",
      },
      "& li:first-of-type": {
        mt: -2,
      },
      "& li:last-of-type": {
        mb: -2,
      },
    },
  },
  videoBox: useReviewPostImageStyle.videoBox,
  iconPlayVdo: useReviewPostImageStyle.iconPlayVdo,
  urlBox: {
    height: {
      xs: "250px",
      lg: "270px",
      xl: "320px",
    },
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
    cursor: "pointer",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "60px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};