import { useState } from "react";
// mui and style
import { Box, Stack, Avatar, Divider, Typography } from "@mui/material";
import { useJobOthersImagesStyle as classes } from "../JobOthers.style";
// type and others
import { IJobOthersImagesProps } from "../../../JobsTab.type";
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";

export default function Images(props: IJobOthersImagesProps) {
  const { attachments } = props;

  const [viewDraftImage, setViewDraftImage] = useState(false);

  return (
    <Box sx={classes.imageCon}>
      <Box sx={classes.title}>
        <Typography>Images</Typography>
        <Typography>
          อัพโหลดรูปภาพการรีวิวสินค้า (ไฟล์รูปภาพไม่เกิน 8 mb)
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0], mr: ["24px", 0] }} />
      <Stack direction="row" sx={classes.draftBox}>
        {attachments?.map((att, index) => (
          <Box key={index} sx={{ width: { xs: "47.5%", sm: "initial" } }}>
            <Avatar
              src={att.url}
              sx={classes.imgItem}
              onClick={() => setViewDraftImage(true)}
            />
            <Typography variant="body2" textAlign="center" mt="12px">
              {`รูปที่ ${att?.order}`}
            </Typography>
          </Box>
        ))}
      </Stack>
      <ViewMultiImageDialog
        open={viewDraftImage}
        onClose={setViewDraftImage}
        images={attachments}
      />
    </Box>
  );
}
