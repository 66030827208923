import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { page: any } = {
  page: "home",
};

const HomeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomePage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    },
  },
});

export const { setHomePage } = HomeSlice.actions;

export default HomeSlice.reducer;
