// mui and style
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useShippingProductToInfluencerStyle as classes } from "../JobWorking.style";
// redux and others
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
import { CompanyIconSelected } from "@global/icon-selected/CompanyIconSelected";

export default function ShippingProductToInfluencer() {
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const shipping = jobInfo?.shipping_address;

  return (
    <Box sx={classes.shippingCon}>
      <Box sx={classes.title}>
        <Typography>Shipping Product to Influencer</Typography>
        <Typography>
          ผู้จ้างงานส่งสินค้าสำหรับการรีวิวให้อินฟลูเอนเซอร์
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Grid container spacing={5} p={["24px 0 24px 0", "24px"]}>
        <Grid item xs={3.5}>
          <Typography>Shipping Type</Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Box sx={classes.postCodeLabel}>
            {shipping?.shipping_type ? (
              <>
                <CompanyIconSelected company={shipping?.shipping_type} />
                <Typography>{shipping?.shipping_type}</Typography>
              </>
            ) : (
              "-"
            )}
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Typography>Tracking No.</Typography>
        </Grid>
        <Grid item xs={8.5}>
          {shipping?.tracking_no ? (
            <Typography sx={classes.postCode}>
              {shipping?.tracking_no}
            </Typography>
          ) : (
            "-"
          )}
        </Grid>
        <Grid item xs={3.5}>
          <Typography>Deliver to</Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Box sx={classes.address}>
            {shipping?.contact && shipping?.address ? (
              <>
                <Typography>{shipping?.contact}</Typography>
                <Typography>{shipping?.address}</Typography>
              </>
            ) : (
              "-"
            )}
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Typography>Note</Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography fontWeight="400">
            {shipping?.note || "-"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
