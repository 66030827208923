import React, { useContext, useEffect, useRef, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Grid,
  Paper,
  Avatar,
  Button,
  Select,
  MenuItem,
  InputBase,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAddMemberTeamStyle as classes } from "./CreateTeam.style";
// component
import CreateTeamStep from "./CreateTeamStep";
import DialogPermission from "./DialogPermission";
// api and type
import { TeamAPI } from "@api/global";
import { IEmployerParameters } from "@api/team/type";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// other
import { IMemberAdded } from "../member/Member.type";
import { useLocation } from "react-router-dom";
import { permissionList } from "../member/Member.constants";
import TeamContext from "@contexts/Team";

export default function AddTeamMember() {
  const teamContext = useContext(TeamContext);
  
  const [employerParameterList, setEmployerParameterList] = useState<IEmployerParameters[]>([]);
  const [memberAddedList, setMemberAddedList] = useState<IMemberAdded[] | null>(null);
  const [openPermission, setOpenPermission] = useState(false);
  const [openSearchList, setOpenSearchList] = useState(false);
  const [search, setSearch] = useState("");
  const [isError, setIsError] = useState(false);

  const employerInfo = useAppSelector(selectEmployerInfo);
  const memberLimit = teamContext?.teamInfo?.package?.campaign_management.member_limit || 0;

  // console.log("team", teamContext?.teamInfo)
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const teamId = params.get("t_id") || employerInfo?.team?.id;
  
  const searchListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!teamId) return;
    let isMounted = true;

    TeamAPI.getEmployerParameters(teamId || "")
      .then((response) => {
        if (isMounted) {
          setEmployerParameterList(response);
        }
      })
      .catch((err) => {
        if (isMounted) {
          console.log("can not get employer parameters error : ", err);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [teamId]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchListRef.current &&
        !searchListRef.current.contains(event.target as Node)
      ) {
        setOpenSearchList(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [searchListRef]);

  const handlePermissionChange = (memberId: string, id: number) => {
    const memberList = memberAddedList ? [...memberAddedList] : [];
    const memberIndex = memberList.findIndex((mem) => mem.id === memberId);
    memberList[memberIndex].permission = id;
    setMemberAddedList(memberList);
  };

  const handleClosePermission = () => {
    setOpenPermission(false);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleAddMember = (member: IEmployerParameters) => {
    if ((memberAddedList?.length || 0) >= memberLimit - 1) return;
    const memberList = memberAddedList ? [...memberAddedList] : [];
    const memberSelected: IMemberAdded = {
      id: member.id,
      email: member.email,
      full_name: member.full_name,
      profile_image_url: member.profile_image_url,
      team_role_id: 1,
    };
    setMemberAddedList([...memberList, memberSelected]);
  };

  const handleRemoveMember = (memberId: string) => {
    const memberList = memberAddedList ? [...memberAddedList] : [];
    setMemberAddedList(memberList.filter((mem) => mem.id !== memberId));
  };

  const onGoBack = () => {
    teamContext?.setIsLoading(true);
    TeamAPI.updateTeamProgress(teamId || "", { create: false, member:false, transfer_wallet:false }).then(() => {
      teamContext?.setStep("create-team");
    }).finally(() => {
      teamContext?.setStep("create-team");
      teamContext?.setIsLoading(false);
    })
  }

  const onSkips = () => {
    teamContext?.setIsLoading(true);
    TeamAPI.updateTeamProgress(teamId || "", { create: true, member:true, transfer_wallet:false }).then(() => {
      teamContext?.setStep("organizing-wallet");
    }).finally(() => {
      teamContext?.setStep("organizing-wallet");
      teamContext?.setIsLoading(false);
    })
  }

  const onAddMember = () => {
    if (memberAddedList === null) {
      onSkips();
    } else {
      teamContext?.setIsLoading(true)
      if (memberAddedList?.filter((mem) => mem.permission === undefined).length) {
        setIsError(true);
        return;
      }
  
      const newData = memberAddedList?.map((member) => {
        return {
          id: member.id,
          team_role_id: 1,
          permission: member.permission || 0,
        };
      });
  
      TeamAPI.addTeamMember(teamId || "", { employers: newData })
        .then((response) => {
          teamContext?.setTeamInfo(response)
          TeamAPI.updateTeamProgress(response.id, {create: true, member:true, transfer_wallet:false }).then(() => {
            teamContext?.setStep("organizing-wallet");
          }).finally(() => {
            teamContext?.setStep("organizing-wallet");
          })
        })
        .finally(() => {
          teamContext?.setIsLoading(false);
        })
    }
  };

  const memberSearchedList = employerParameterList.filter((emp) =>
    emp.email.toLowerCase().includes(search.toLowerCase()) || emp.full_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box sx={classes.addTeamMemberWrap}>
        <CreateTeamStep steped={teamContext?.step || null} />
        <Typography variant="h5" mt="42px" mb="24px">
          Add Team Members
        </Typography>
        <Box width="100%">
          <Typography variant="h6">
            เพิ่มสมาชิกในทีม ({(memberAddedList?.length || 0) + 1}/{memberLimit})
          </Typography>
          <Typography variant="subTitle1">
            ต้องเป็นสมาชิก HashU เท่านั้น
          </Typography>
          <Box my="16px" position="relative">
            <Paper
              component="form"
              onClick={() => setOpenSearchList(true)}
              sx={{
                ...classes.paperSearch,
                borderRadius:
                  openSearchList && memberSearchedList.length
                    ? "20px 20px 0 0"
                    : "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by name"
                inputProps={{ "aria-label": "Search by name" }}
                onChange={handleSearchChange}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Icon className="ico-hu-search" />
              </IconButton>
            </Paper>
            {openSearchList && memberSearchedList.length ? (
              <Box
                ref={searchListRef}
                sx={classes.searchList}
              >
                {memberSearchedList.map((mem, index) => (
                  <Box
                    key={index}
                    display={
                      memberAddedList?.find((m) => m.id === mem.id)
                        ? "none"
                        : "flex"
                    }
                    onClick={() => handleAddMember(mem)}
                    sx={classes.searchedMemberList}
                  >
                    <Avatar src={mem.profile_image_url} />
                    <div>
                      <Typography variant="title1">{mem.full_name}</Typography>
                      <Typography variant="body2">{mem.email}</Typography>
                    </div>
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>

        <Grid container rowGap={5}>
          <Grid item xs={8}>
            <Typography variant="title1">Email</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              columnGap={2}
            >
              <Typography variant="title1">Permission</Typography>{" "}
              <Icon
                onClick={() => setOpenPermission(true)}
                className="ico-hu-info-circle-outline"
                sx={{ ml: "4px", cursor: "pointer" }}
              />
            </Box>
          </Grid>
          <Grid item xs={8} display="flex">
            <Box mr="12px">
              <Avatar src={employerInfo?.organisation.attachment.url} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="title1">{employerInfo?.fullname}</Typography>
              <Typography variant="caption">{employerInfo?.email}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" sx={classes.teamLeadTxt}>
              Team Lead
            </Typography>
          </Grid>
          {memberAddedList?.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={8} display="flex" alignItems="center">
                <Box mr="12px">
                  <Avatar src={item?.profile_image_url} />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="title1">{item.full_name}</Typography>
                  <Typography variant="caption">{item.email}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" alignItems="center" columnGap={2}>
                  <Select
                    id={`permission-${item.id}-select`}
                    sx={{ maxHeight: "40px", width: "100%" }}
                  >
                    {permissionList.map((per, perIndex) => (
                      <MenuItem
                        placeholder="select"
                        key={perIndex}
                        value={per.id}
                        onClick={() => handlePermissionChange(item.id, per.id)}
                      >
                        {per.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton onClick={() => handleRemoveMember(item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
                {isError && item.permission === undefined && (
                  <Box display="flex" justifyContent="center" mt="5px">
                    <Typography color="red">Permission is require</Typography>
                  </Box>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        <Box sx={classes.buttonAddTeamWrap}>
          <Button onClick={onGoBack}>
            <Typography variant="buttonM" mx={10}>
              ย้อนกลับ
            </Typography>
          </Button>
          <Box display="flex" alignItems="center">
            <Typography
              variant="title1"
              sx={classes.textNext}
              onClick={onSkips}
            >
              ข้ามไปก่อน
            </Typography>
            <Button
              variant="contained"
              onClick={onAddMember}
              sx={{ border: "none", "&:hover": { border: "none" } }}
            >
              <Typography variant="buttonM" mx={10}>
                ถัดไป
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <DialogPermission
        open={openPermission}
        onClose={handleClosePermission}
      />
    </Box>
  );
}
