// mui and style
import { Box, Button, Icon, Typography } from "@mui/material";
import { useCampaignStatusStyle as classes } from "./CampaignStatus.style";
// api and type
import { TeamAPI } from "@api/global";
import { ITeamInfo } from "@api/team/type";
// other
import { hAlertColor } from "@theme/index";
import { useHistory } from "react-router-dom";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
// import TextFieldCustom from "@global/text-field";

export default function CampaignStatus({ teamInfo }: { teamInfo: ITeamInfo | null }) {

  const history = useHistory()
  const onTerminateTeam = () => {
    if (!teamInfo?.id) return

    SwalCustom.fire({
      icon: "question",
      title: "ต้องการลบทีมนี้ใช่หรือไม่ ?",
      text: `ลบทีม "${teamInfo.name}" `,
      confirmButtonColor: "#8C499C",
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ยืนยัน",
    }).then(async (response) => {
      if (response.isConfirmed) {
        TeamAPI.terminateTeam(teamInfo.id).then(() => {
          SwalCustom.fire({
            icon: "success",
            title: "ลบ Team สำเร็จ",
            text: `คุณได้ทำการลบทีม ${teamInfo.name}`,
            confirmButtonColor: hAlertColor.success,
            confirmButtonText: "ตกลง",
          }).then(() => {
            history.replace("/team")
          })
        }).catch(() => {
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "There are somting wrong",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
        })
      }
    });
  }

  return (
    <div>
      <Box sx={classes.card}>
        <Typography variant="h6" textAlign="center">
          สถานะแคมเปญปัจจุบัน
        </Typography>
        <Box display="flex" justifyContent="space-evenly" my="20px">
          {[
            { label: "ร่างแคมเปญ", total: teamInfo?.briefing || 0, icon: "comment" },
            { label: "รับสมัคร", total: teamInfo?.influencer_listing || 0, icon: "comment" },
            { label: "กำลังดำเนินการ", total: teamInfo?.published || 0, icon: "draft" },
            { label: "เสร็จสิ้น", total: teamInfo?.completed || 0, icon: "money" },
          ].map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box sx={classes.statusList}>
                <Icon
                  className={`ico-hu-${item.icon}`}
                  sx={{ fontSize: "36px", color: "#505050" }}
                />
              </Box>
              <Typography variant="h5" my="10px" color="#505050">
                {item.total}
              </Typography>
              <Typography variant="subTitle2">{item.label}</Typography>
            </Box>
          ))}
        </Box>
        <Box sx={classes.haveToDo}>
          <Typography variant="title1" color="#E30D18">
            สิ่งที่ต้องตรวจสอบก่อนลบทีม
          </Typography>
          <ul>
            <Typography component="li" variant="body2">
              ต้องไม่มีแคมเปญที่อยู่ในสถานะ “รับสมัคร” หรือ “กำลังดำเนินการอยู่”
              หากมีโปรดดำเนินการให้เสร็จสิ้น
            </Typography>
          </ul>
        </Box>
      </Box>
      <Box sx={classes.card}>
        <Typography variant="title1" color="#E30D18">
          คำเตือน
        </Typography>
        <ul>
          {[
            "ระบบจะดำเนินการลบข้อมูลแคมเปญ ประวัติการทำงาน ธุรกรรมทางการเงิน รวมถึงข้อมูลอื่นๆทั้งหมด และไม่สามารถกู้คืนข้อมูลได้อีก",
            "สมาชิกทั้งหมดจะถูกลบออกจากทีม",
            "ยอดเงินใน Team Wallet จะถูกโอนไปยัง My Wallet ของคุณโดยอัตโนมัติ",
          ].map((li, index) => (
            <Typography key={index} component="li" variant="body2">
              {li}
            </Typography>
          ))}
        </ul>
      </Box>
      {/* <Box sx={classes.card}>
        <Typography variant="title1">
          โปรดระบุชื่อทีมของคุณเพื่อดำเนินการลบทีม
        </Typography>
        <TextFieldCustom
          placeholder="Type something..."
          fullWidth
          sx={{ mt: "16px" }}
        />
      </Box> */}
      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={onTerminateTeam} sx={classes.submitBtn}>
          ยืนยันการลบทีม
        </Button>
      </Box>
    </div>
  );
}
