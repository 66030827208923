export const useCampaignStatusStyle = {
  card: {
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05);",
    bgcolor: "#fff",
    my: "16px",
  },
  statusList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    bgcolor: "#F5F6F8",
  },
  haveToDo: {
    p: "12px 12px 2px 12px",
    borderRadius: "8px",
    bgcolor: "#FFF3F3",
  },
  submitBtn: {
    fontSize: "16px",
    border: "none",
    bgcolor: "#E30D18",
    "&:hover": { border: "none", bgcolor: "#E30D18" },
    width: "240px",
    mt: "15px",
  },
};
