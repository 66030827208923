import { useEffect, useState } from "react";
// mui
import { Box, useMediaQuery, useTheme } from "@mui/material";
// type, components and other
import { IMyJobsStatusTabs, IMyJobsTabsProps } from "../MyJobs.type";
import MyJobsTabsItemLg from "./JobsTabsItemLg";
import MyJobstabsItemSm from "./JobsTabsItemSm";
import { useHistory, useLocation } from "react-router-dom";

export default function MyJobsTabs(props: IMyJobsTabsProps) {
  const { jobList, setOpenFilter } = props;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status");

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const [selectStatusTab, setSelectStatusTab] = useState({
    id: "",
    key: "",
    text: "ทั้งหมด",
    bgColor: "#F4ECFF",
    color: "#893DBD",
    total: jobList?.first_draft || 0,
  });

  const handleSelectJobStatus = (data: IMyJobsStatusTabs) => {
    setSelectStatusTab(data);
    history.replace(`/my-jobs?status=${data.key}`);
  };

  useEffect(() => {
    if (smScreen) {
      const tab = myJobsTabs.concat(myJobsTabsStatus);
      tab.splice(3, 1);
      const tabFilter = tab.find((tab) => tab.key === status);
      const selectTab = {
        id: "",
        key: "",
        text: "ทั้งหมด",
        bgColor: "#F4ECFF",
        color: "#893DBD",
        total: jobList?.total || 0,
      };
      setSelectStatusTab(tabFilter ? tabFilter : selectTab);
    } else {
      const tab = myJobsTabsStatus.find((tab) => tab.key === status);
      const selectTab = {
        id: "select-status",
        key: "in-progress",
        text: "กำลังดำเนินการ",
        bgColor: "#F4ECFF",
        color: "#893DBD",
        total: 0,
      };
      setSelectStatusTab(tab ? tab : selectTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smScreen]);

  const myJobsTabs = [
    {
      id: "",
      key: "",
      text: "ทั้งหมด",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: jobList?.total || 0,
    },
    {
      id: "listing",
      key: "listing",
      text: "รอตอบรับ",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: jobList?.listing || 0,
    },
    {
      id: "working",
      key: "working",
      text: "เริ่มงานได้",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: jobList?.working || 0,
    },
    {
      id: "select-status",
      key: selectStatusTab.key,
      text: selectStatusTab.text,
      bgColor: selectStatusTab.bgColor,
      color: selectStatusTab.color,
      total: selectStatusTab.total,
    },
    {
      id: "completed",
      key: "completed",
      text: "เสร็จสิ้น",
      bgColor: "#EAEAFF",
      color: "#4A489D",
      total: jobList?.completed || 0,
    },
    {
      id: "canceled",
      key: "canceled",
      text: "ยกเลิก",
      bgColor: "#9E9E9E",
      color: "#FFFFFF",
      total: jobList?.canceled || 0,
    },
  ];

  const myJobsTabsStatus = [
    {
      id: "first-draft",
      key: "first_draft",
      text: "ส่ง Draft แรก",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: jobList?.first_draft || 0,
    },
    {
      id: "rejected",
      key: "rejected",
      text: "ต้องแก้ไข",
      bgColor: "#FFEAF4",
      color: "#E8008A",
      total: jobList?.rejected || 0,
    },
    {
      id: "waiting-for-approve",
      key: "waiting_for_approve",
      text: "แก้ไขแล้ว รอตรวจ",
      bgColor: "#FFFBD4",
      color: "#FB8C00",
      total: jobList?.waiting_for_approve || 0,
    },
    {
      id: "approved",
      key: "approved",
      text: "Draft ผ่านแล้ว",
      bgColor: "#E7FFE8",
      color: "#4CAF50",
      total: jobList?.approved || 0,
    },
    {
      id: "published",
      key: "published",
      text: "แนบลิงค์ส่งงาน",
      bgColor: "#E5F6FF",
      color: "#0077E4",
      total: jobList?.published || 0,
    },
  ];

  return (
    <>
      {xsScreen ? (
        <Box mt="5px">
          <MyJobstabsItemSm
            myJobsTabs={myJobsTabs}
            myJobsTabsStatus={myJobsTabsStatus}
            selectStatusTab={selectStatusTab}
            handleSelectJobStatus={handleSelectJobStatus}
            setHandleOpenFilter={setOpenFilter}
          />
        </Box>
      ) : (
        <Box mt="24px" display="flex">
          <MyJobsTabsItemLg
            myJobsTabs={myJobsTabs}
            handleSelectJobStatus={handleSelectJobStatus}
            myJobsTabsStatus={myJobsTabsStatus}
            selectStatusTab={selectStatusTab}
            setHandleOpenFilter={setOpenFilter}
          />
        </Box>
      )}
    </>
  );
}
