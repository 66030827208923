// mui
import { Box } from "@mui/material";
// component and context
import SyncTwitter from "./SyncTwitter";
import InfluencerSocialHeader from "../default/Header";
import InfluencerSocialPending from "../default/Pending";
import InfluencerSocialInfo from "../default/Information";
// mui
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";

export default function InfluencerTwitterTab() {
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const twitterInfo = influencerSocial?.twitter

  return (
    <div>
      {!twitterInfo || !twitterInfo.length ? (
        <SyncTwitter/>
      ) : twitterInfo[0].social_account.length === 0 ? (
        <InfluencerSocialPending platform="twitter" />
      ) : (
        twitterInfo && (
          <Box display="flex" flexDirection="column" rowGap="20px">
            <InfluencerSocialHeader platform="twitter" />
            <InfluencerSocialInfo
              platform="twitter"
              email={twitterInfo[0].email}
            />
          </Box>
        )
      )}
    </div>
  );
}
