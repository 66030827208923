import { useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Divider,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
import { usePaymentSummaryStyle as classes } from "./SubscriptionPayment.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { selectPackageSelected, selectWalletBalance } from "@slices/PackageInfo.slice";
// type
import { IPaymentSummaryProps } from "../Subscription.type";


export default function PaymentSummary(props: IPaymentSummaryProps) {
  const {
    price,
    addOn,
    prepay,
    setAddOn,
    setPrepay,
    isPersonal,
    grandTotal,
    packageList,
  } = props;

  const [isPrepay, setIsPrepay] = useState(false);
  const [isAddOn, setIsAddOn] = useState(false);

  const packageSelected = useAppSelector(selectPackageSelected)
  const walletBalance = useAppSelector(selectWalletBalance);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const topUp = grandTotal - walletBalance;
  const isWalletBalance = topUp <= 0;

  const numberFormat = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const handleAddOn = (isAddOn: boolean) => {
    setIsAddOn(isAddOn);
    if (!isAddOn) setAddOn(0);
  };

  const handlePrepay = (isPrepay: boolean) => {
    setIsPrepay(isPrepay);
    if (!isPrepay) setPrepay(1);
  };

  return (
    <Box sx={classes.con}>
      <Box sx={classes.planBox}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">แผนปัจจุบัน</Typography>
          <Box sx={classes.currentIcon}>
            <Icon
              className="ico-hu-person"
              fontSize="small"
              sx={{ mr: "8px" }}
            />
            <Typography variant="title1" textTransform="capitalize">
              {packageList?.title || "Free"}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box display="flex" flexDirection="column" rowGap={2}>
          {[
            {
              label: `${packageList?.title || "Free"} Plan (${
                employerInfo?.package_plan
              })`,
              value: `${numberFormat.format(
                (price || 0) * (packageList?.plan === "yearly" ? 10 : 1)
              )} บาท`,
            },
            {
              label: "สมาชิก",
              value: `${employerInfo?.team?.employer.length || 0} คน`,
            },
            {
              label: "รอบบิล",
              value: `${packageList?.plan === "yearly" ? "รายปี" : "รายเดือน"}`,
            },
          ].map((li, index) => (
            <Box
              key={index}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography textTransform="capitalize">{li.label}</Typography>
              <Typography variant="title1">{li.value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" my="8px">
        <Icon
          className="ico-hu-arrow-down"
          sx={{ fontSize: "32px", color: "#616161" }}
        />
      </Box>
      <Box sx={classes.planBox}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">แผนใหม่</Typography>
          <Box sx={classes.upgradePlanIcon}>
            <Icon
              className="ico-hu-person"
              fontSize="small"
              sx={{ mr: "8px" }}
            />
            <Typography variant="title1" textTransform="capitalize">
              {packageSelected?.title}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box display="flex" flexDirection="column" rowGap={2}>
          {[
            {
              label: `${packageSelected?.title} Plan (${packageSelected?.package_plan})`,
              value: `${packageSelected?.price} บาท`,
            },
            { label: "สมาชิก", value: `${packageSelected?.member} คน` },
            {
              label: "รอบบิล",
              value: `ราย${packageSelected?.plan === "yearly" ? "ปี" : "เดือน"}`,
            },
          ].map((li, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography textTransform="capitalize">{li.label}</Typography>
              <Typography variant="title1">{li.value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {!isPersonal && (
        <Box mt="24px">
          <Typography variant="title1">Add-Ons</Typography>
          <Box sx={classes.summaryBox}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" p="5px">
                <Checkbox
                  onChange={(e) => handleAddOn(e.target.checked)}
                  checked={isAddOn}
                  sx={classes.addOnCheckBox}
                />
                <Box>
                  <Typography variant="title1">เพิ่มสมาชิกในทีม</Typography>
                  <Typography variant="body2">
                    {isAddOn && addOn > 0
                      ? numberFormat.format(740 * addOn)
                      : numberFormat.format(740)}{" "}
                    บาท/เดือน
                  </Typography>
                </Box>
              </Box>
              <Box sx={classes.addOnBox}>
                <IconButton
                  disabled={addOn <= 0}
                  onClick={() => setAddOn(addOn - 1)}
                >
                  <Icon className="ico-hu-remove" fontSize="small" />
                </IconButton>
                <Typography variant="h6" mx="10px">
                  {addOn}
                </Typography>
                <IconButton
                  disabled={!isAddOn}
                  onClick={() => setAddOn(addOn + 1)}
                >
                  <Icon className="ico-hu-add" fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box mt="24px">
        <Typography variant="h6">สรุปการชำระเงิน</Typography>
        {packageSelected?.plan === "monthly" && (
          <Box sx={classes.summaryBox}>
            <Box display="flex" alignItems="center">
              <Checkbox
                onChange={(e) => handlePrepay(e.target.checked)}
                checked={isPrepay}
              />
              <Typography variant="title1">ชำระเงินล่วงหน้า</Typography>
            </Box>
            {isPrepay && (
              <Box display="flex" columnGap="7px" ml="40px" mb="12px">
                {[...Array(5)].map((_, i) => (
                  <Box
                    onClick={() => setPrepay(i + 2)}
                    key={i}
                    sx={
                      prepay === i + 2 ? classes.prepaySelected : classes.prepay
                    }
                  >
                    {i + 2}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <div>
        <Box display="flex" justifyContent="space-between" mb="6px">
          <Typography>ยอดรวมทั้งหมด</Typography>
          <Typography variant="title1">
            {numberFormat.format(grandTotal)} บาท
          </Typography>
        </Box>
        {isWalletBalance ? (
          <>
            <Divider sx={{ my: "16px", borderStyle: "dashed" }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" color="#8C499C">
                ชำระเงิน
              </Typography>
              <Typography variant="h5" color="#8C499C">
                {numberFormat.format(grandTotal)} บาท
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Divider sx={{ my: "16px" }} />
            <Box display="flex" justifyContent="space-between">
              <Typography>My Wallet</Typography>
              <Typography variant="title1">
                {numberFormat.format(walletBalance)} บาท
              </Typography>
            </Box>
            <Divider sx={{ my: "16px", borderStyle: "dashed" }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" color="#8C499C">
                Top Up
              </Typography>
              <Typography variant="h5" color="#8C499C">
                {numberFormat.format(topUp)} บาท
              </Typography>
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}
