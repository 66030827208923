import { useEffect, useState } from "react";
// mui, icon and style
import { Box, Link, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEmployerDetailStyle as classes } from "./Detail.style";
// api and type
import { EmployerAPI } from "@api/global";
import { IEmployerInfoRes } from "@api/employer/type";
// component
import ProfileTab from "@components/employer/employer-detail/profile-tab/ProfileTab";
import EmployerDetailHeader from "@components/employer/employer-detail/DetailHeader";
import CampaignTab from "@components/employer/employer-detail/campaign-tab/CampaignTab";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getCategoryAsync, selectCategoryOnload } from "@slices/CategoryList.slice";
// others
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";

export default function EmployerDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [employerInfo, setEmployerInfo] = useState<IEmployerInfoRes>();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "profile";
  const { employerId } = useParams<{ employerId: string }>();
  
  const dispatch = useAppDispatch();
  const categoryLoading = useAppSelector(selectCategoryOnload)
  
  useEffect(() => {
    dispatch(getCategoryAsync());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    const getEmployerInfo = () => {
      setIsLoading(true);

      EmployerAPI.getEmployerInfo(employerId)
        .then((response) => {
          if (isMounted) {
            setEmployerInfo(response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.log("can not get employer detail ", err);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
    }

    getEmployerInfo();

    return () => {
      isMounted = false;
    };
  }, [employerId]);

  const handleChangeTab = (tab: string) => {
    history.replace(`/employers/${employerId}/info?tab=${tab}`);
  };

  if (isLoading || categoryLoading) return <Loading />

  return (
    <div className="container-main">
      <Link
        onClick={() => history.goBack()}
        underline="none"
        sx={classes.goBack}
      >
        <KeyboardArrowLeftIcon />
        <span>Back</span>
      </Link>
      <div>
        <Typography variant="h5">Employer Information</Typography>
        <Typography variant="subtitle1" color="#5C5C5C">
          รายละเอียดผู้จ้างงาน
        </Typography>
      </div>

      <EmployerDetailHeader employerInfo={employerInfo || null} />

      <Box mt="24px">
        <Box sx={classes.tabBox}>
          {["profile", "campaign"].map((tab, index) => (
            <Box
              key={index}
              component="button"
              disabled={tab === "subscription"}
              sx={
                tabSelected === tab
                  ? classes.tabSelected
                  : tab === "subscription"
                  ? classes.tabDisabled
                  : classes.tab
              }
              onClick={() => handleChangeTab(tab)}
            >
              <Typography
                textTransform="capitalize"
                fontWeight={tabSelected === tab ? 500 : 400}
              >
                {tab}
              </Typography>
            </Box>
          ))}
        </Box>
        {tabSelected === "campaign" ? (
          <CampaignTab employerInfo={employerInfo || null} />
        ) : (
          <ProfileTab
            employerInfo={employerInfo || null}
            setEmployerInfo={setEmployerInfo}
          />
        )}
      </Box>
    </div>
  );
}
