import React, { useState } from "react";
// mui and icon
import {
  Box,
  Icon,
  Step,
  Stack,
  styled,
  Avatar,
  Stepper,
  StepLabel,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
// styles and type
import {
  ColorlibStepIconRoot,
  useCampaignBriefTabStyle as classes,
} from "./CampaignBriefTabs.style";
import { ICreateCampaignProps } from "../campaign-brief/CampaignBrief.type";
// others
import { Link } from "react-scroll";
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "#F5F6F8",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "#F5F6F8",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Icon className="ico-hu-campaign-overview" sx={{ fontSize: "18px" }} />,
    2: <Icon className="ico-hu-campaign-brief" sx={{ fontSize: "18px" }} />,
    3: <Icon className="ico-hu-target-audience" sx={{ fontSize: "20px" }} />,
    4: <Icon className="ico-hu-budget" sx={{ fontSize: "18px" }} />,
    5: <Icon className="ico-hu-terms-cons" sx={{ fontSize: "18px" }} />,
    6: <Icon className="ico-hu-time-outline" sx={{ fontSize: "14px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  {
    id: "campaignOverview",
    title: "Overview",
    offset: -350,
  },
  {
    id: "campaignBrief",
    title: "Brief",
    offset: -185,
  },
  {
    id: "targetAudience",
    title: "Target",
    offset: -185,
  },
  {
    id: "budgetAndReward",
    title: "Budget",
    offset: -185,
  },
  {
    id: "termsAndConditions",
    title: "T&Cs",
    offset: -185,
  },
  {
    id: "timeline",
    title: "Timline",
    offset: -185,
  },
];

export default function CampaignBriefTab(props: ICreateCampaignProps) {
  const {
    form: { getValues, watch },
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  
  const employerInfo = useAppSelector(selectEmployerInfo);

  const isCampaignPrivate = getValues("campaign_type") === "private";
  const condition = getValues("brief.term_and_condition").length > 1;
  const budgedAndReward =
    !getValues("budget.total") || !getValues("budget.influencer_amount");
  const overview =
    !getValues("company.name") ||
    !getValues("name") ||
    !getValues("company.media.url") ||
    !getValues("objective") ||
    !getValues("category_id").length ||
    !getValues("campaign_type") ||
    !getValues("review_type");
  const brief =
    !watch("platform") ||
    !watch("content_type_id.name") ||
    !watch("brief.cover_image") ||
    !watch("brief.content.media.images").length ||
    !watch("brief.content.description") ||
    !watch("brief.mood_and_tone.description") ||
    !watch("brief.key_message") ||
    !watch("brief.emphasis") ||
    !watch("brief.prohibition");
  const audienceTarget =
    !getValues("audience.target.age.min") ||
    !getValues("audience.target.age.max") ||
    !getValues("audience.target.location").length;
  const audienceInf =
    !getValues("audience.influencer.age.min") ||
    !getValues("audience.influencer.age.max") ||
    !getValues("audience.influencer.follower.min") ||
    !getValues("audience.influencer.follower.max") ||
    !getValues("audience.influencer.location").length;
  const audience = isCampaignPrivate
    ? audienceTarget
    : audienceTarget || audienceInf;
  const timeline =
    !getValues("timeline.start_recruiting_date") ||
    !getValues("timeline.end_recruiting_date") ||
    !getValues("timeline.announce_date") ||
    !getValues("timeline.start_publish_date") ||
    !getValues("timeline.end_publish_date") ||
    !getValues("timeline.posting_period");

  return (
    <Box sx={classes.tabsCon}>
      <Box sx={classes.organization}>
        {employerInfo?.organisation && (
          <>
            <Avatar src={employerInfo?.organisation?.attachment?.url} />
            <Typography>{employerInfo?.organisation?.name}</Typography>
          </>
        )}
      </Box>
      <Stack
        sx={{
          ...classes.tabButton,
          "& .MuiStep-root:first-of-type ": {
            "& .css-16wza54": {
              bgcolor: overview ? "#f0f0f0" : "#8C499C",
              color: !overview ? "#f0f0f0" : "#8C499C",
            },
            "& .css-16ubnlw-MuiStepLabel-labelContainer, .css-1ndtz50-MuiStepLabel-label.Mui-active":
              {
                color: !overview ? "#8C499C" : "rgba(0, 0, 0, 0.6)",
              },
          },
          "& .MuiStep-root:nth-of-type(3)": {
            "& .css-16wza54": {
              bgcolor: brief ? "#f0f0f0" : "#8C499C",
              color: !brief ? "#f0f0f0" : "#8C499C",
            },
            "& .css-16ubnlw-MuiStepLabel-labelContainer, .css-1ndtz50-MuiStepLabel-label.Mui-active":
              {
                color: !brief ? "#8C499C" : "rgba(0, 0, 0, 0.6)",
              },
          },
          "& .MuiStep-root:nth-of-type(5)": {
            "& .css-16wza54": {
              bgcolor: audience ? "#f0f0f0" : "#8C499C",
              color: !audience ? "#f0f0f0" : "#8C499C",
            },
            "& .css-16ubnlw-MuiStepLabel-labelContainer, .css-1ndtz50-MuiStepLabel-label.Mui-active":
              {
                color: !audience ? "#8C499C" : "rgba(0, 0, 0, 0.6)",
              },
          },
          "& .MuiStep-root:nth-of-type(7)": {
            "& .css-16wza54": {
              bgcolor: budgedAndReward ? "#f0f0f0" : "#8C499C",
              color: !budgedAndReward ? "#f0f0f0" : "#8C499C",
            },
            "& .css-16ubnlw-MuiStepLabel-labelContainer, .css-1ndtz50-MuiStepLabel-label.Mui-active":
              {
                color: !budgedAndReward ? "#8C499C" : "rgba(0, 0, 0, 0.6)",
              },
          },
          "& .MuiStep-root:nth-of-type(9)": {
            "& .css-16wza54": {
              bgcolor: condition ? "#8C499C" : "#f0f0f0",
              color: !condition ? "#8C499C" : "#f0f0f0",
            },
            "& .css-16ubnlw-MuiStepLabel-labelContainer, .css-1ndtz50-MuiStepLabel-label.Mui-active":
              {
                color: condition ? "#8C499C" : "rgba(0, 0, 0, 0.6)",
              },
          },
          "& .MuiStep-root:nth-of-type(11)": {
            "& .css-16wza54": {
              bgcolor: timeline ? "#f0f0f0" : "#8C499C",
              color: !timeline ? "#f0f0f0" : "#8C499C",
            },
            "& .css-16ubnlw-MuiStepLabel-labelContainer, .css-1ndtz50-MuiStepLabel-label.Mui-active":
              {
                color: !timeline ? "#8C499C" : "rgba(0, 0, 0, 0.6)",
              },
          },
        }}
        spacing={4}
      >
        <Stepper
          // alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label.id} className="nav-item">
              <Link
                to={`${label.id}`}
                spy={true}
                smooth={true}
                offset={label.offset}
                duration={50}
              >
                <StepLabel
                  onClick={() => {
                    setActiveStep(index);
                  }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {label.title}
                </StepLabel>
              </Link>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}
