import { IReplanPackageBody } from "./type";
import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class PackageAPIs {
  async getOnePackage() {
    const headers = {
      "X-API-Key": API_KEY
    };
    const { data } = await instance.get(`/package`, { headers });
    return data.result.data;
  }

  async replanPackage(body: IReplanPackageBody) {
    const { access_token } = getTokenFromStorage();
    
    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    };
    const { data } = await instance.patch(`/package/replan`, body, { headers });
    return data.result.data;
  }
}