import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Button,
  Avatar,
  Divider,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import BorderAllIcon from '@mui/icons-material/BorderAll';
import { useEmployerProfileStyle as classes } from "./Profile.style";
// component
import EditBilling from "@components/employer/edit-employer/EditBilling";
import EditShipping from "@components/employer/edit-employer/EditShipping";
import EditEmployer from "@components/employer/edit-employer/EditEmployer";
// api and type
import { EmployerAPI } from "@api/global";
import { IEmployerDetail } from "@api/employer/type";
// redux
import { useAppDispatch } from "@store/hook";
import { getCategoryAsync } from "@slices/CategoryList.slice";
// util
import {
  formatTaxId,
  formatPhoneNumber,
} from "@utils/helpers/formateNumber.helpers";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// other
import Loading from "@global/loading/Loading";

export default function EmployerProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [employerInfo, setEmployerInfo] = useState<IEmployerDetail>();
  const [editEmployer, setEditEmployer] = useState(false);
  const [editShipping, setEditShipping] = useState(false);
  const [editBilling, setEditBilling] = useState(false);

  const { session_token } = getTokenFromStorage();
  const dispatch = useAppDispatch();

  const shipping = employerInfo?.address.shipping_address;
  const billing = employerInfo?.address.billing_address;

  useEffect(() => {
    dispatch(getCategoryAsync());
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    EmployerAPI.getEmployerInfo(session_token?.employer_id || "")
      .then((response) => {
        if (isMounted) {
          setEmployerInfo(response);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [session_token?.employer_id]);

  useEffect(() => {
    function requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.log("เบราว์เซอร์นี้ไม่รองรับการแจ้งเตือน");
      } else {
        if (Notification.permission === "granted") return
        Notification.requestPermission()
      }
    }

    requestNotificationPermission()
  }, [])

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Box display="flex" gap="24px">
        <Box sx={classes.card}>
          <CardHeader
            sx={classes.cardHeader}
            title={
              <Typography
                variant="body1"
                color="common.white"
                fontWeight="inherit"
              >
                My Profile
              </Typography>
            }
          />

          <Box m="24px">
            <Box display="flex" justifyContent="center">
              <Avatar
                variant="square"
                src={employerInfo?.organisation.attachment.url}
                alt="emplyer-my-profile"
                sx={classes.avatar}
              />
            </Box>
            <Box mt="20px">
              <Typography fontWeight="bold">Personal Information</Typography>
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-person" sx={{ color: "#8C499C" }} />
                <Box ml="10px">
                  <Typography variant="title2">Fullname :</Typography>
                  <Typography>{employerInfo?.fullname}</Typography>
                </Box>
              </Box>
              <Divider sx={classes.divider} />
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-building" sx={{ color: "#8C499C" }} />
                <Box ml="10px">
                  <Typography variant="title2">Organization :</Typography>
                  <Typography>{employerInfo?.organisation.name}</Typography>
                </Box>
              </Box>
              <Divider sx={classes.divider} />
              <Box display="flex" alignItems="center">
                <BorderAllIcon color="primary" />
                <Box ml="10px">
                  <Typography variant="title2">Type :</Typography>
                  <Typography textTransform="capitalize">
                    {employerInfo?.organisation.type}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={classes.divider} />
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-business" sx={{ color: "#8C499C" }} />
                <Box ml="10px">
                  <Typography variant="title2">Business :</Typography>
                  <Typography textTransform="capitalize">
                    {employerInfo?.organisation.size}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt="20px">
              <Typography fontWeight="bold">Contact</Typography>
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-phone" sx={{ color: "#8C499C" }} />
                <Box ml="10px">
                  <Typography variant="title2">Phone Number :</Typography>
                  <Typography>
                    {formatPhoneNumber(employerInfo?.phone || "")}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={classes.divider} />
              <Box display="flex" alignItems="center">
                <Icon
                  className="ico-hu-line_circle"
                  sx={{ color: "#8C499C" }}
                />
                <Box ml="10px">
                  <Typography variant="title2">Line ID :</Typography>
                  <Typography>{employerInfo?.line_id}</Typography>
                </Box>
              </Box>
              <Divider sx={classes.divider} />
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-mail" sx={{ color: "#8C499C" }} />
                <Box ml="10px">
                  <Typography variant="title2">Email :</Typography>
                  <Typography>{employerInfo?.email}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={classes.boxCardAction}>
              <Button
                onClick={() => setEditEmployer(true)}
                fullWidth
                sx={{ maxWidth: "300px" }}
              >
                <Icon className="ico-hu-edit" sx={{ mr: "4px" }} />
                <Typography fontWeight={500}>Edit Profile</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.card}>
          <CardHeader
            sx={classes.cardHeader}
            title={
              <Typography
                variant="body1"
                color="common.white"
                fontWeight="inherit"
              >
                Address
              </Typography>
            }
          />

          <Box m="24px">
            <Box display="flex" justifyContent="space-between">
              <Box sx={classes.boxAddress}>
                <IconButton aria-label="save" color="primary" size="small">
                  <Icon className="ico-hu-shipping-outline" />
                </IconButton>
                <Typography variant="body1" fontWeight="bold">
                  Shipping Address
                </Typography>
              </Box>
              <div>
                <IconButton
                  aria-label="save"
                  color="primary"
                  size="small"
                  sx={classes.iconButton}
                  onClick={() => setEditShipping(true)}
                >
                  {shipping?.province === null ? (
                    <Icon className="ico-hu-add" sx={{ fontSize: "15px" }} />
                  ) : (
                    <Icon className="ico-hu-edit" sx={{ fontSize: "15px" }} />
                  )}
                </IconButton>
              </div>
            </Box>

            {shipping?.province !== null ? (
              <Box pt="8px">
                <Typography variant="body1" color={"common.black"}>
                  {`${shipping?.fullname} , `}
                  {`${formatPhoneNumber(shipping?.phone || "")} ,`}
                </Typography>
                <Typography variant="body1" color={"common.black"}>
                  {`${shipping?.address}, 
                  ${shipping?.subdistrict?.name_th}, 
                  ${shipping?.district?.name_th}, 
                  ${shipping?.province?.name_th}, 
                  ${shipping?.postcode}`}
                </Typography>
              </Box>
            ) : (
              <Box pt="8px">
                <Typography variant="body1" color={"text.secondary"}>
                  + Add Shipping Address
                </Typography>
              </Box>
            )}
            <Divider sx={{ margin: "40px 0" }} />
            <Box display="flex" justifyContent="space-between">
              <Box sx={classes.boxAddress}>
                <IconButton aria-label="save" color="primary" size="small">
                  <Icon className="ico-hu-billing" />
                </IconButton>
                <Typography variant="body1" fontWeight="bold">
                  Billing Address
                </Typography>
              </Box>
              <Box>
                <IconButton
                  aria-label="save"
                  color="primary"
                  size="small"
                  sx={classes.iconButton}
                  onClick={() => setEditBilling(true)}
                >
                  {billing?.province === null ? (
                    <Icon className="ico-hu-add" sx={{ fontSize: "15px" }} />
                  ) : (
                    <Icon className="ico-hu-edit" sx={{ fontSize: "15px" }} />
                  )}
                </IconButton>
              </Box>
            </Box>

            {billing?.province !== null ? (
              <Box pt="8px">
                <Typography variant="body1" color={"common.black"}>
                  {`${billing?.fullname} , `}
                  {`${formatTaxId(billing?.tax_id || "")} ,`}
                </Typography>
                <Typography variant="body1" color={"common.black"}>
                  {`${billing?.address}, 
                  ${billing?.subdistrict?.name_th}, 
                  ${billing?.district?.name_th}, 
                  ${billing?.province?.name_th}, 
                  ${billing?.postcode}`}
                </Typography>
              </Box>
            ) : (
              <Box pt="8px">
                <Typography variant="body1" color={"text.secondary"}>
                  + Add Billing Address
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <EditEmployer
        employerInfo={employerInfo || null}
        setEmployerInfo={setEmployerInfo}
        open={editEmployer}
        onClose={() => setEditEmployer(false)}
      />

      <EditShipping
        employerInfo={employerInfo || null}
        setEmployerInfo={setEmployerInfo}
        open={editShipping}
        onClose={() => setEditShipping(false)}
      />

      <EditBilling
        employerInfo={employerInfo || null}
        setEmployerInfo={setEmployerInfo}
        open={editBilling}
        onClose={() => setEditBilling(false)}
      />
    </div>
  );
}
