import React, { useEffect, useState } from "react";
// mui, icon and style
import {
  Box,
  Grid,
  Button,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useMyJobsReportTabStyle as classes } from "./ReportTab.style";
// redux and types
import {
  selectInfluencerJobReport,
  getInfluencerJobReportAsync,
} from "@slices/InfluencerJobReport.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";
import { IInitParams } from "./ReportTab.type";
// components
import ReportTotalList from "./ReportTotalList";
import Engagement from "@global/campaign-report/Engagement";
import Conversion from "@global/campaign-report/Conversion";
import InfluencerPost from "@global/campaign-report/InfluencerPost";
import CampaignPerformance from "@global/campaign-report/CampaignPerformance";
import LocationDistribution from "@global/campaign-report/LocationDistribution";
import AgeAndGenderDistribution from "@global/campaign-report/AgeAndGenderDistribution";
import CampaignReportFilterSm from "@components/global/campaign-report/report-filter/ReportFilterSm";
import CampaignReportFilterLg from "@components/global/campaign-report/report-filter/ReportFilterLg";
// other
import { useParams } from "react-router-dom";

export default function CampaignReportTab() {
  const [tabValue, setTabValue] = useState(0);
  const [openDrawerSm, setOpenDrawerSm] = useState(false);

  const dispatch = useDispatch();
  const jobReportInfo = useAppSelector(selectInfluencerJobReport);

  let date = new Date();
  const { jobId } = useParams<{ jobId: string }>();

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  useEffect(() => {
    const params = {
      job_id: jobId,
      chart_type: "weekly",
    };
    if (jobId) dispatch(getInfluencerJobReportAsync(params));
  }, [jobId, dispatch]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenDrawerSm(open);
    };

  const filterDateList = [
    {
      label: "สัปดาห์นี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "weekly",
    },
    {
      label: "เดือนนี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "monthly",
    },
    {
      label: "2 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 2,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "2month",
    },
    {
      label: "3 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 3,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "3month",
    },
    {
      label: "กำหนดเอง",
      value: {
        start: "",
        end: "",
      },
      filterType: "filterSelf",
      type: "filterSelf",
    },
  ];

  const onFilterDate = (params: IInitParams) => {
    dispatch(getInfluencerJobReportAsync(params));
  };

  const tabPanelList = [
    {
      id: "campaignPerformance",
      value: tabValue,
      component: (
        <CampaignPerformance
          columns={2}
          performance={jobReportInfo?.impression}
        />
      ),
      xs: 12,
      md: 8,
      data: jobReportInfo?.impression,
    },
    {
      id: "influencerPost",
      value: tabValue,
      component: <InfluencerPost socialPost={jobReportInfo?.social_post} />,
      xs: 4,
      md: 4,
      data: jobReportInfo?.social_post,
    },
    {
      id: "engagement",
      value: tabValue,
      component: <Engagement engagement={jobReportInfo?.engagement} />,
      xs: 12,
      md: 12,
      data: jobReportInfo?.engagement,
    },
    {
      id: "conversion",
      value: tabValue,
      component: <Conversion />,
      xs: 12,
      md: 12,
    },
    {
      id: "ageAndGenderDistribution",
      value: tabValue,
      component: <AgeAndGenderDistribution />,
      xs: 12,
      md: 12,
    },
    {
      id: "locationDistribution",
      value: tabValue,
      component: <LocationDistribution />,
      xs: 12,
      md: 12,
    },
  ];

  tabPanelList.splice(1, xsScreen ? 1 : 0);

  return (
    <>
      <CampaignReportFilterSm
        toggleDrawer={toggleDrawer}
        openDrawerSm={openDrawerSm}
        filterDateList={filterDateList}
        filterDate={onFilterDate}
        setOpenDrawerSm={setOpenDrawerSm}
      />
      <Box sx={classes.tabPanelInfBox}>
        <Box sx={classes.tabfilter}>
          <CampaignReportFilterLg
            filterDate={onFilterDate}
            toggleDrawer={toggleDrawer}
            publishedLink={jobReportInfo?.social_post?.post?.published_url}
          />
        </Box>
        <Divider sx={{ m: "0 24px 24px 24px", display: ["none", "initial"] }} />
        <Box sx={classes.reportFilterDetailBoxInf}>
          <ReportTotalList
            overview={jobReportInfo?.overview}
            publishedLink={jobReportInfo?.social_post?.post?.published_url}
          />
        </Box>
        <Box sx={classes.influPostInf}>
          <InfluencerPost socialPost={jobReportInfo?.social_post} />
        </Box>
        <Box sx={classes.filterDateInfSm}>
          <Typography>โปรดระบุช่วงเวลา</Typography>
          <Button
            sx={classes.btnFromInfSm}
            fullWidth
            endIcon={<CalendarMonthIcon />}
            onClick={toggleDrawer(true)}
          >
            From
          </Button>
        </Box>
        <Box sx={classes.campReportBox}>
          <Box sx={classes.campReport}>
            <Typography variant="body1" color="primary" mb={2} fontWeight="600">
              รายงานแคมเปญ
            </Typography>
            <Box display="flex" columnGap={2} mt={3}>
              {["Performance", "Engagement"].map((tab, index) => (
                <Box
                  key={index}
                  onClick={() => setTabValue(index)}
                  sx={tabValue === index ? classes.tabSelected : classes.tab}
                >
                  {tab}
                </Box>
              ))}
            </Box>
          </Box>
          <Grid container spacing={5} mt={{ xs: 0, sm: "-18px", md: "-32px" }}>
            {tabPanelList.map(
              (tab, index) =>
                tab.data && (
                  <Grid item xs={tab.xs} md={tab.md} key={index}>
                    <Box display={["initial", "none"]}>
                      {tabValue === index && tab.component}
                    </Box>
                    <Box display={["none", "block"]} height="100%">
                      {tab.component}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
