export const formatPhoneNumber = (phone: string) => {
  let cleaned = ("" + phone).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return phone;
};

export const formatTaxId = (taxId: string) => {
  let cleaned = ("" + taxId).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}-${match[5]}`;
  }
};