import { useState } from "react";
// mui
import {
  Box,
  Alert,
  Stack,
  Button,
  Avatar,
  Collapse,
  Typography,
  CircularProgress,
} from "@mui/material";
// icon and style
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDefaultStyle as classes } from "../InfluencerSocial.style";
// image
import IGVerify from "@assets/images/other/instagram-verify.png";
import Instagram from "@assets/images/social/ig-glyph-gradient.png";
// api and type
import { SocialAPI } from "@api/global";
import { IUnofficialDefault } from "../InfluencerSocial.type";
// other
import { useForm } from "react-hook-form";
import TextFieldCustom from "@global/text-field";

export default function UnofficialDefault(props: IUnofficialDefault) {
  const { platform, checkComponents } = props;
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<{ url: string }>();

  const [verifyCode, setVerifyCode] = useState("");
  const [username, setUsername] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSyncInstagram, setIsSyncInstagram] = useState(false);

  const clipboard = () => {
    navigator.clipboard.writeText(verifyCode);
  };

  const handleChange = (e: any) => {
    setValue("url", e.target.value);
    clearErrors("url");
  };

  const onSubmitGetVertifyCode = async (data: { url: string }) => {
    try {
      setIsLoading(true);
      const reqBody = {
        url: data.url,
      };

      const response = await SocialAPI.getVertifyInstagramCode(reqBody);
      const verifyCode = response.verify_code;
      await sleep(300);
      setIsLoading(false);
      setVerifyCode(verifyCode);
      setUsername(response.username);
    } catch (error: any) {
      setIsLoading(false);
      const error_message = error.response.data.error.message;
      if (error_message === "URL domain must be instagram.com") {
        window.scrollTo(0, 0);
        setAlertMessage("URL ไม่ถูกต้อง");
        setShowAlert(true);
      } else if (error_message === "can not get Instagram user profile") {
        window.scrollTo(0, 0);
        setAlertMessage("ไม่พบบัญชีผู้ใช้บน Instagram");
        setShowAlert(true);
      } else if (
        error_message === "this Instagram account has already taken in HashU"
      ) {
        window.scrollTo(0, 0);
        setAlertMessage("มีคนใช้ Instagram Account นี้แล้ว");
        setShowAlert(true);
      } else if (error_message === "account has amount of post lower than 1") {
        window.scrollTo(0, 0);
        setAlertMessage("จำนวนโพสต์น้อยเกินไป");
        setShowAlert(true);
      } else if (
        error_message === "account has amount of follower lower than 1"
      ) {
        window.scrollTo(0, 0);
        setAlertMessage("จำนวนผู้ติดตามน้อยเกินไป");
        setShowAlert(true);
      } else if (
        error_message === "account has amount of follower lower than 500"
      ) {
        window.scrollTo(0, 0);
        setAlertMessage("จำนวนผู้ติดตามน้อยเกินไป");
        setShowAlert(true);
      } else {
        window.scrollTo(0, 0);
        setAlertMessage("มีบางอย่างไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
        setShowAlert(true);
      }
    }
  };

  const syncDataInstagram = async (data: any) => {
    try {
      setIsSyncInstagram(true);
      const reqBody = {
        username: data,
      };
      await SocialAPI.verifyInstagramAccount(reqBody);
      await sleep(5000);
      setIsSyncInstagram(false);
      window.location.reload();
    } catch (error: any) {
      setIsSyncInstagram(false);
      const error_message = error.response.data.error.message;
      if (error_message === "verify code has not existed") {
        window.scrollTo(0, 0);
        setAlertMessage("ไม่พบ Verify Code");
        setShowAlert(true);
      } else {
        window.scrollTo(0, 0);
        setAlertMessage("การยืนยันตัวตนไม่สำเร็จ กรุณายืนยันตัวตนใหม่อีกครั้ง");
        setShowAlert(true);
      }
    }
  };

  return (
    <Box sx={classes.container}>
      <Collapse sx={{ width: "100%" }} in={showAlert}>
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "24px", color: "red" }}
        >
          {alertMessage}
        </Alert>
      </Collapse>

      {platform === "instagram" && (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => checkComponents && checkComponents(false)}
        >
          <ArrowBackIosIcon />
        </Box>
      )}

      <Stack sx={classes.card}>
        <Box sx={classes.Account}>
          {platform === "instagram" ? (
            <img
              src={Instagram}
              alt="instagram-logo"
              style={{ height: "72px", width: "72px" }}
            />
          ) : (
            <Avatar alt="Other Logo" src={""} />
          )}
          <Typography>ขั้นตอนการเชื่อมต่อบัญชี {platform}</Typography>
        </Box>

        {platform === "instagram" && (
          <Box
            sx={{
              mb: "24px",
              "& video": {
                width: "100%",
              },
            }}
          >
            <video
              height="auto"
              controls
              src="https://storage.googleapis.com/hashu-bucket-uat/media/videos/Connect%20Tiktok%20Desktop%20v.2%2Bmusic.mp4"
            />
          </Box>
        )}

        <form
          style={{ width: "100%" }}
          onSubmit={handleSubmit(onSubmitGetVertifyCode)}
        >
          <Box sx={classes.fillURLBox}>
            <Typography className="fillUrlTitle">
              1. กรอก URL บัญชี {platform} ของคุณ
            </Typography>
            <Box sx={classes.fillURL}>
              <Typography>
                {platform} URL<span>*</span> :
              </Typography>
              <TextFieldCustom
                value={getValues("url")}
                onChange={handleChange}
                variant="outlined"
                placeholder="url"
                fullWidth
                error={!!errors.url}
                helperText={errors.url?.message}
              />
            </Box>
          </Box>

          <Box sx={classes.verifyCodeBox}>
            <Typography className="verifyCodeTitle">
              2. กดปุ่มเพื่อขอรหัสการยืนยันตัวตน
            </Typography>
            <Box sx={classes.verifyCode}>
              <Typography className="verifyCode">
                รหัสสำหรับการยืนยันตัวตน
              </Typography>
              <div>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ margin: "8px 0" }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button type="submit" variant="contained" color="secondary">
                    กดที่นี้ เพื่อขอรหัสการยืนยันตัวตน
                  </Button>
                )}

                {verifyCode && (
                  <Box sx={classes.buttonVerifyCode}>
                    <Typography>{verifyCode}</Typography>
                    <ContentCopyIcon
                      onClick={clipboard}
                      sx={classes.coppyVerifyCode}
                    />
                  </Box>
                )}

                <Typography>
                  โปรดใช้รหัสภายใน 30 นาที หากเกินเวลาที่กำหนด
                  คุณต้องทำการขอรหัสใหม่อีกครั้ง
                </Typography>
              </div>
            </Box>
          </Box>

          <Box sx={classes.verifyCodeToBioBox} mt={5}>
            <Typography className="verifyCodeToBioTitle">
              3. นำรหัสการยืนยันตัวตนไประบุในบรรทัดสุดท้ายบน {platform} Bio
              ของคุณ
            </Typography>
            <Box sx={classes.verifyCodeToBio}>
              <Typography>
                ตำแหน่งที่ต้องระบุรหัสสำหรับการยืนยันตัวตน
              </Typography>
              <img alt="instagram-verify" src={IGVerify} />
            </Box>
          </Box>
        </form>

        <Box sx={classes.verifyAccount}>
          <Typography>
            4. หลังจากนำรหัสไประบุบน {platform} Bio แล้ว ให้กดปุ่ม "Verify{" "}
            {platform}
            Account" ด้านล่างเพื่อทำการดึงข้อมูล
          </Typography>
          {isSyncInstagram ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ margin: "8px 0" }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={() => syncDataInstagram(username)}
            >
              Verify {platform} Account
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
