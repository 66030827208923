import { hBlack, hGrey, hPink } from "@theme/index";

export const useCompanyDetailStyle = {
  cardCon: {
    borderRadius: { xs: "8px", lg: "16px" },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    "& > div": {
      p: {
        xs: "20px",
        lg: "17px",
      },
      display: "flex",
      justifyContent: "space-between",
    },
    position: {
      xs: "relative",
      lg: "initial",
    },
    overflow: "visible",
  },
  companyLogo: {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root, img": {
      width: ["48px", "48px", "80px"],
      height: ["48px", "48px", "80px"],
      borderRadius: [0, 0, "8px"],
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
      objectFit: "cover",
    },
    position: {
      xs: "absolute",
      lg: "initial",
    },
    top: -25,
    border: "2px solid #fff",
    borderRadius: ["4px", "4px", "8px", 0],
  },
  companyDetail: {
    display: "flex",
    flexDirection: "column",
    ml: {
      xs: 0,
      lg: "20px",
    },
    width: "100%",
    height: "100%",
  },
  companyDetailItem: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: {
      xs: "20px",
      md: "50px",
      lg: "10px",
    },
    "& h6": {
      width: "100%",
      display: "-webkit-box",
      lineHeight: 1,
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& > h6 span": {
      color: hBlack[300],
      marginLeft: "5px",
      display: {
        xs: "none",
        md: "initial",
      },
    },
    flexDirection: {
      xs: "column",
      md: "row",
    },
    "& > span": {
      display: {
        md: "none",
      },
    },
  },
  compJobStatus: {
    display: ["none", "none", "flex"],
    alignItems: "center",
    alignSelf: "flex-start",
    "& svg, p": { color: hPink[300] },
    "& svg": { fontSize: "8px" },
    "& p": { fontSize: ["14px", "14px", "16px"], ml: "5px" },
    ml: 10,
  },
  compJobItemList: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      fontSize: ["16px", "20px"],
    },
    "& > p": {
      fontSize: ["14px", "16px"],
      ml: "5px",
      color: "#212121",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& > .css-1cfuqz3-MuiAvatar-root": {
      width: "20px",
      height: "20px",
    },
    "& > span": { color: "#505050" },
  },
  socialBox: {
    display: {
      xs: "none",
      lg: "flex",
      xl: "none",
    },
    justifyContent: "space-between",
    mt: 3,
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        color: hGrey[900],
        width: "18px",
        height: "18px",
      },
      "& p": {
        color: hGrey[900],
        fontSize: "14px",
      },
    },
  },
  compJobSecondStatus: {
    display: {
      xs: "none",
      lg: "flex",
      xl: "none",
    },
    justifyContent: "space-between",
    mt: 3,
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        color: hGrey[900],
        width: "18px",
        height: "18px",
      },
      "& p": {
        color: hGrey[900],
        fontSize: "14px",
      },
    },
  },
  dividerMdScreen: {
    width: "100%",
    my: "15px",
    mt: "30px",
    display: ["none", "block", "block", "none"],
  },
  jobStatusSm: {
    height: "32px",
    mt: "14px",
    borderRadius: "4px",
    minHeight: "32px",
    display: ["flex", "none"],
    alignItems: "center",
    justifyContent: "center",
  },
};

export const useOrganizationDetailStyle = {
  cardCon: {
    display: "flex",
    borderRadius: { xs: "8px", lg: "16px" },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  orgLogo: {
    display: "flex",
    alignItems: "center",
    margin: ["15px", "20px"],
    "& .MuiAvatar-root, img": {
      width: ["60px", "60px", "70px", "80px"],
      height: ["60px", "60px", "70px", "80px"],
      borderRadius: "8px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
    },
  },
  orgDetail: {
    display: "flex",
    flexDirection: "column",
    margin: "18px 0",
    "& > h6": {
      mt: [0, 0, "5px", "10px"],
    },
    "& > p": {
      mt: ["5px", "5px", "8px"],
      textOverflow: "ellipsis",
    },
  },
};
