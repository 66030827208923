import { useState } from "react";
// mui and style
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useInviteFriendsStyle as classes } from "../MyReferral.style";
// components and api
import InComplete from "./InComplete";
import Completed from "./Completed";
import { InfluencerAPI } from "@api/global";
// redux
import {
  selectInfluencerProfile,
  getInfluencerProfileAsync,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import TextFieldCustom from "@global/text-field";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function InviteFriends() {
  const [referralCode, setReferralCode] = useState("");

  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id;
  const referralStatus = influencerInfo?.referer.status;
  const activity =
    referralStatus?.edit_profile &&
    referralStatus.register &&
    referralStatus.sync_social
      ? true
      : false;

  const onSaveReferralCode = () => {
    const data = {
      referral_code: referralCode,
    };
    InfluencerAPI.updateInfluencerProfile(data)
      .then(() => {
        dispatch(getInfluencerProfileAsync({ influencerId }));
      })
      .catch((err) => {
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: `${err.response.data.error.message}`,
          showConfirmButton: true,
        });
      });
  };

  return (
    <Grid item xs={12} lg={6}>
      <Box sx={classes.card} position="relative">
        <Box display={{ xs: "none", lg: "initial" }}>
          <Typography variant="h6">Invite Friends</Typography>
          <Divider sx={{ my: "16px" }} />
        </Box>
        {activity ? <Completed /> : <InComplete />}
        {!influencerInfo?.referer.is_referrals && (
          <Box sx={classes.codeBox}>
            <Typography variant="title1">
              Do you have a referral code from a friend?
            </Typography>
            <Divider sx={{ my: "16px" }} />
            <Box>
              <Typography variant="title2" mb="6px">
                Enter referral code
              </Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                <TextFieldCustom
                  onChange={(e) => setReferralCode(e.target.value)}
                  fullWidth
                  placeholder="Referral Code"
                />
                <Button
                  variant="contained"
                  onClick={() => onSaveReferralCode()}
                  sx={classes.codeBtn}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
