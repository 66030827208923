// mui and style
import {
  Box,
  Button,
  Dialog,
  Avatar,
  Typography,
  DialogContent,
} from "@mui/material";
import { useCancelCampaignDialogStyle as classes } from "../CancelCampaign.style";
// type
import { ICancelCampaignDialogProps } from "../CancelCampaign.type";

export default function CancelCampaignDialog(
  props: ICancelCampaignDialogProps
) {
  const { open, isInfApplyMoreThanSpacify, onClose, onCancelCampaign } = props;

  return (
    <Dialog open={open} sx={classes.dialog}>
      <DialogContent>
        <Box sx={classes.content}>
          <Avatar
            src={require(`@assets/images/infographic/admin/influencer-list/inf-apply-${
              isInfApplyMoreThanSpacify ? "more" : "less"
            }-than-specified.png`)}
          />
          <Box my="10px" textAlign="center">
            <Typography variant="h5">
              คุณต้องการยกเลิกแคมเปญนี้จริงๆเหรอ
            </Typography>
            <Typography>
              หากคุณต้องการยกเลิก กดปุ่ม “ยืนยันการยกเลิก” เพื่อดำเนินการต่อ
            </Typography>
          </Box>
          <Box sx={classes.btnBox}>
            <Button fullWidth onClick={() => onClose(false)}>
              ยังไม่ใช่ตอนนี้
            </Button>
            <Button variant="contained" onClick={onCancelCampaign} fullWidth>
              ยืนยันการยกเลิก
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
