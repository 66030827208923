import { useState } from "react";
// mui and icons
import { Box, Fab, Icon, Stack, Typography } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// style and type
import { IMultiUrlInputProps } from "./JobDefault.type";
import { useMultiVideoInputStyle as classes } from "./JobDefault.style";
// others
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import UploadUrlDialog from "../job-dialog/UploadUrlDialog";

export default function MultiUrlInput(props: IMultiUrlInputProps) {
  const { keyName, setValue, errors, urls, showUrlIndex, disableIsActive } = props;

  const [handleDraftUrl, setHandleDraftUrl] = useState(false);

  const onHandleRemove = (index: number) => {
    const file = [...urls];
    file.splice(index, 1);
    setValue(keyName, file);
  };

  const onHandleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const onHandleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onHandleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetIndex: number
  ) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData("text/plain"));
    const videoItems = [...urls];
    const [removedImage] = videoItems.splice(sourceIndex, 1);
    videoItems.splice(targetIndex, 0, removedImage);
    setValue(keyName, videoItems);
  };

  return (
    <Stack
      direction="row"
      columnGap={4}
      rowGap={4}
      flexWrap="wrap"
      mr={[0, 10]}
    >
      {urls.map((url, index) => (
        <Box
          key={index}
          draggable
          onDragStart={(e) => onHandleDragStart(e, index)}
          onDragOver={(e) => onHandleDragOver(e)}
          onDrop={(e) => onHandleDrop(e, index)}
        >
          <Box
            component="label"
            position="relative"
            height={["100px", "120px"]}
            sx={
              disableIsActive && url.is_approve
                ? classes.videoIsActive
                : classes.videoIsNotActive
            }
          >
            {disableIsActive && url.is_approve ? (
              <Fab sx={classes.removeBtn}>
                <Icon className="ico-hu-check" sx={{ fontSize: "16px" }} />
              </Fab>
            ) : (
              <Fab onClick={() => onHandleRemove(index)} sx={classes.removeBtn}>
                <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
              </Fab>
            )}
            <Box sx={classes.urlBox}>
              <Box sx={classes.urlIcon}>
                <Icon className="ico-hu-conversion" />
                <Typography variant="body2" mt="15px">
                  Video URL
                </Typography>
              </Box>
              <Box sx={classes.urlImg}>
                <img src={urlLogo} alt="item" />
              </Box>
            </Box>
          </Box>

          {showUrlIndex && (
            <Typography variant="body2" textAlign="center" mt={1}>
              {index + 1}
            </Typography>
          )}
        </Box>
      ))}
      <Box
        display="flex"
        mt={[0, 0, 0, 0, 2]}
        width={["47%", "initial", "120px"]}
      >
        <Box
          mt={urls.length ? "8px" : 0}
          sx={classes.labelUpload}
          onClick={() => setHandleDraftUrl(true)}
        >
          <Box mb="-5px">
            <Icon
              className="ico-hu-conversion"
              sx={{ fontSize: { xs: "30px", sm: "48px" }, ml: "-3px" }}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Icon className="ico-hu-attachment" sx={{ fontSize: "16px" }} />
            <span style={{ fontSize: "12px" }}>Paste URL</span>
          </Stack>
        </Box>
      </Box>
      {errors && (
        <Typography color="error" fontSize="12px" m="10px 0 0 11px">
          {errors.message}
        </Typography>
      )}
      <UploadUrlDialog
        open={handleDraftUrl}
        onClose={setHandleDraftUrl}
        urls={urls}
        setValue={setValue}
      />
    </Stack>
  );
}
