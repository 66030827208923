import { useEffect, useState } from "react";
// mui, icon and style
import { Checkbox, Box, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTermsAndConditionsDetailStyle as classes } from "./CampaignDetail.style";
// type
import { ITermsAndCondition } from "@api/employer/type";
import { ITermsAndConditionsDetailProps } from "./CampaignDetail.type";

export default function TermsAndConditions(
  props: ITermsAndConditionsDetailProps
) {
  const { role, campaignInfo, campaignStatus, handleCreateJob } = props;

  const [conditionList, setConditionList] = useState<ITermsAndCondition[]>([]);

  const condition = campaignInfo?.brief.term_and_condition || [];
  const isInfListing = role === "influencer" && campaignStatus === "influencer_listing";

  useEffect(() => {
    const termAndCondition = condition.map((cond) => {
      return {
        order: cond.order,
        message: cond.message,
        status: true,
      };
    });
    setConditionList(termAndCondition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfo]);

  return (
    <div>
      {conditionList.map((cond, index) => (
        <Box sx={classes.checkBox} key={index}>
          {isInfListing ? (
            <Checkbox
              onChange={(e) => handleCreateJob && handleCreateJob(e, index)}
              sx={{ width: "30px", mr: "10px", mt: -0.4 }}
            />
          ) : (
            <CheckBoxIcon sx={{ color: "#9E9E9E", mr: "10px" }} />
          )}
          <Typography variant="body1">
            {`${index + 1}. ${cond.message}`}
          </Typography>
        </Box>
      ))}
    </div>
  );
}
