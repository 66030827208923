import { useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Grid,
  Menu,
  Stack,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { usePostSinglePhotoImageStyle as classes } from "../../EmployerJob.style";
// types and others
import { IPostSinglePhotoImageProps } from "../../EmployerJob.type";
import { IIsApproved } from "@api/app-information/type";
import TextFieldCustom from "@global/text-field";

export default function PostSinglePhotoImage(
  props: IPostSinglePhotoImageProps
) {
  const {
    jobInfo,
    isJobRejected,
    form: { watch, register, setValue },
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const open = Boolean(anchorEl);
  const attachments = jobInfo?.attachments || [];

  const attApprovedList = attachments.filter(
    (att) => att.is_approve === "approved"
  );

  const attNoApprovedList = attachments.filter(
    (att) => att.is_approve === "not_approved" || att.is_approve === "drop"
  );

  const handleEditSelected = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleCloseEditSelected = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const onSelectEdit = (key: string, isApprove: IIsApproved) => {
    handleEditImage(key, isApprove);
    handleCloseEditSelected();
  };

  const handleEditImage = (key: string, isApprove: IIsApproved) => {
    const attList = [...watch("attachments")];
    const isUnSelectAproved = attList.filter(
      (att) => att.is_approve === "approved" && att.file_id === key).length 
      && isApprove === "approved"
      ? true
      : false;
    const isUnselectEdit =
      attList.filter((att) => att.is_approve === "approved").length === 1 &&
      isApprove !== "approved"
        ? true
        : false;
    const attachments = attList.map((att) => {
      return {
        ...att,
        is_approve:
          isUnSelectAproved || isUnselectEdit
            ? "new"
            : isApprove === "approved"
            ? "drop"
            : att.is_approve,
        note: isUnSelectAproved
          ? ""
          : isApprove === "approved"
          ? "สามารถเอารูปออกได้เลย"
          : att.note,
      };
    });
    const attIndex = attachments.findIndex((att) => att.file_id === key);
    attachments[attIndex].is_approve = isUnSelectAproved ? "new" : isApprove;
    attachments[attIndex].note = isApprove === "drop" ? "สามารถเอารูปออกได้เลย" : "";
    setValue("attachments", attachments);
  };

  const isEditButton = (isApprove?: IIsApproved) => {
    if (isApprove === "not_approved" || isApprove === "drop") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box sx={classes.imgCardCon}>
      <div>
        <Typography variant="h6">
          {isJobRejected
            ? "Comments on the Video"
            : "Choosing the Perfect Photo for Your Campaign"}
        </Typography>
        <Typography variant="subTitle1" color="#505050">
          {isJobRejected
            ? "ความคิดเห็นเกี่ยวกับวิดีโอ"
            : "เลือก 1 รูปภาพที่ดีที่สุดสำหรับแคมเปญของคุณ"}
        </Typography>
      </div>
      <Divider sx={classes.divider} />
      <Grid container spacing={4}>
        {isJobRejected && attNoApprovedList.length ? (
          <>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-close" color="error" />
                <Typography sx={classes.imgToEditTxt} ml={2}>
                  รูปภาพที่ไม่ได้รับการอนุมัติ
                </Typography>
              </Box>
            </Grid>
            {attNoApprovedList.map((att, index) => (
              <Grid item xs={4} key={index}>
                <Typography variant="title1">
                  {`รูปที่ ${att.order}`}
                </Typography>
                <Box sx={classes.imgItems} mt={2}>
                  <img src={att.url} alt="item" />
                </Box>
                <Box mt="16px">
                  <Typography variant="title2" color="error">
                    ความคิดเห็นจากผู้จ้างงาน :
                  </Typography>
                  <Box sx={classes.influComment}>
                    <Typography>{att?.note}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
            <Divider sx={{ ...classes.divider, mt: -3 }} />
          </>
        ) : (
          !attApprovedList.length &&
          watch("attachments").map((att, index) => (
            <Grid item xs={4} key={index}>
              <Typography variant="title1">{`รูปที่ ${att.order}`}</Typography>
              <Box sx={classes.imgItems} mt={2}>
                <img src={att.url} alt="item" />
              </Box>
              {!isJobRejected ? (
                <>
                  <Stack direction="row" spacing={2} m="16px 0 16px 0">
                    <Button
                      fullWidth
                      variant={
                        att.is_approve === "approved" ? "contained" : "outlined"
                      }
                      color="success"
                      startIcon={
                        <Icon
                          className="ico-hu-check"
                          sx={{ fontSize: "16px" }}
                        />
                      }
                      onClick={() => handleEditImage(att.file_id, "approved")}
                      sx={{
                        border:
                          att.is_approve !== "approved"
                            ? "1px solid #4CAF50"
                            : "none",
                        color:
                          att.is_approve === "approved" ? "#fff" : "#4CAF50",
                        fontSize: "14px",
                        "&:hover": {
                          border:
                            att.is_approve !== "approved"
                              ? "1px solid #4CAF50"
                              : "none",
                        },
                        maxHeight: "33px",
                      }}
                    >
                      อนุมัติ
                    </Button>
                    <Button
                      fullWidth
                      id={`select-edit-button-${att.file_id}`}
                      aria-controls={
                        open ? `select-edit-menu-${att.file_id}` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant={
                        isEditButton(att.is_approve) ? "contained" : "outlined"
                      }
                      color={
                        att.is_approve === "not_approved" ? "warning" : "error"
                      }
                      startIcon={
                        <Icon
                          className="ico-hu-close"
                          sx={{ fontSize: "16px" }}
                        />
                      }
                      onClick={(e) => handleEditSelected(e, index)}
                      sx={{
                        border: isEditButton(att.is_approve)
                          ? "none"
                          : "1px solid #E30D18",
                        color: isEditButton(att.is_approve)
                          ? "#fff"
                          : "#E30D18",
                        fontSize: "14px",
                        "&:hover": {
                          border: isEditButton(att.is_approve)
                            ? "none"
                            : "1px solid #E30D18",
                        },
                        maxHeight: "33px",
                      }}
                    >
                      {att.is_approve === "drop"
                        ? "เอาออก"
                        : att.is_approve === "not_approved"
                        ? "ต้องแก้ไข"
                        : "ไม่อนุมัติ"}
                    </Button>
                    <Menu
                      id={`select-edit-button-${att.file_id}`}
                      anchorEl={anchorEl}
                      open={selectedIndex === index}
                      onClose={handleCloseEditSelected}
                      MenuListProps={{
                        "aria-labelledby": `select-edit-menu-${att.file_id}`,
                      }}
                      sx={classes.menu}
                    >
                      <MenuItem
                        onClick={() =>
                          onSelectEdit(att.file_id, "not_approved")
                        }
                      >
                        <Typography variant="body2">ต้องแก้ไข</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => onSelectEdit(att.file_id, "drop")}
                      >
                        <Typography variant="body2">เอาออก</Typography>
                      </MenuItem>
                    </Menu>
                  </Stack>
                  <Box>
                    <Typography
                      variant="title2"
                      color={
                        att.is_approve === "not_approved"
                          ? "#FB8C00"
                          : undefined
                      }
                      mb="8px"
                    >
                      ความคิดเห็นจากผู้จ้างงาน :
                    </Typography>
                    <TextFieldCustom
                      variant="outlined"
                      multiline
                      minRows={3}
                      placeholder="โปรดระบุความคิดเห็น..."
                      fullWidth
                      disabled={att.is_approve !== "not_approved"}
                      sx={classes.noteEdit}
                      inputProps={{
                        ...register(`attachments.${index}.note`),
                      }}
                    />
                  </Box>
                </>
              ) : (
                <Box sx={classes.influComment}>
                  <Typography variant="title2">
                    ความคิดเห็นจากผู้จ้างงาน :
                  </Typography>
                  <Typography variant="body2">{att.note}</Typography>
                </Box>
              )}
            </Grid>
          ))
        )}
      </Grid>
      {jobInfo?.status === "rejected" || attApprovedList.length ? (
        <>
          {attNoApprovedList.length ? <Divider sx={{ my: "32px" }} /> : ""}
          <Box>
            <Box display="flex" alignItems="center">
              <Icon className="ico-hu-check" color="success" />
              <Typography color="#4CAF50" variant="title1" ml={2}>
                รูปภาพที่ได้รับอนุมัติ
              </Typography>
            </Box>
            <Grid container spacing={4} mt="5px">
              {attApprovedList.length ? (
                attApprovedList.map((item, index) => (
                  <Grid item key={index} xs={4}>
                    <Typography variant="title1">
                      {`รูปที่ ${item.order}`}
                    </Typography>
                    <Avatar
                      key={index}
                      sx={classes.imgItemsPost}
                      src={item.url}
                    />
                  </Grid>
                ))
              ) : (
                <Typography ml={5}>ไม่พบรูปภาพที่ได้รับการอนุมัติ</Typography>
              )}
            </Grid>
          </Box>
        </>
      ) : null}
    </Box>
  );
}
