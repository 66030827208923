export const subscriptionPlan = [
  {
    key: "management",
    name: "Campaign Management",
    item: [
      {
        label: "Campaign limit per month",
        subLabel: "จำนวนแคมเปญที่สร้างได้ต่อเดือน",
        keyValue: "campaign_limit_per_month",
      },
      {
        label: "Amount of influencer in campaign",
        subLabel: "จำนวนอินฟลูเอนเซอร์ต่อ 1 แคมเปญ",
        keyValue: "campaign_influencer",
      },
      {
        label: "Campaign Type : Public",
        subLabel: "สร้างแคมเปญและประกาศรับสมัครอินฟลูเอนเซอร์",
        keyValue: "campaign_type",
        subKeyType: "public",
      },
      {
        label: "Campaign Type : Private",
        subLabel: "สร้างแคมเปญและยื่นข้อเสนอให้อินฟลูเอนเซอร์โดยตรง",
        keyValue: "campaign_type",
        subKeyType: "private",
      },
      {
        label: "Campaign Review Type : Product",
        subLabel: "รูปแบบการรีวิวของแคมเปญ : การรีวิวสินค้า",
        keyValue: "review_type",
        subKeyType: "product",
      },
      {
        label: "Campaign Review Type : Place",
        subLabel: "รูปแบบการรีวิวของแคมเปญ : การรีวิวสถานที่",
        keyValue: "review_type",
        subKeyType: "place",
      },
      {
        label: "Objective : Awareness",
        subLabel:
          "การตลาดที่มุ่งเน้นให้สินค้าหรือบริการเป็นที่รู้จัก",
        keyValue: "campaign_objective",
        subKeyType: "awareness",
      },
      {
        label: "Objective : Conversion",
        subLabel: "วัดการตอบสนองของลูกค้าต่อสินค้าหรือบริการ",
        keyValue: "campaign_objective",
        subKeyType: "conversion",
      },
    ]
  },
  {
    key: "report",
    name: "Report",
    item: [
      {
        label: "Campaign Report Accessibility",
        subLabel: "การเข้าถึงรายงานของแคมเปญ",
        keyValue: "is_report",
        subKeyType: "is_report",
      },
      {
        label: "Campaign Report Export",
        subLabel: "การนำออกข้อมูลรายงานแคมเปญ",
        keyValue: "is_report_export",
        subKeyType: "is_report_export",
      },
    ]
  },
  {
    key: "notification",
    name: "Notification",
    item: [
      {
        label: "LINE application",
        subLabel: "การแจ้งเตือนผ่านไลน์",
        keyValue: "is_line_chat",
        subKeyType: "is_line_chat",
      },
    ]
  }
]
