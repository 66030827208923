// mui and icons
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// other
import { useEventsCardStyle as classes } from "./Events.style";
import { IEventsCardProps } from "./Events.type";
import dayjs from "dayjs";

export default function EventsCard(props: IEventsCardProps) {
  const { event } = props;

  const numberFormat = Intl.NumberFormat("en");

  return (
    <>
      <Grid item sm={12} md={4.5} lg={3.5} xl={4}>
        <Box sx={classes.card}>
          <img src={event.attachments[0]?.url} alt="event" />
          <Box sx={classes.cardEventBtn}>{event.status}</Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={7.5} lg={8.5} xl={8}>
        <Box sx={classes.descBox}>
          <Typography variant="h6">{event.name}</Typography>
          <Typography>{event.description}</Typography>
          <Stack spacing={2} my="16px">
            <Box sx={classes.dateAndLocation}>
              <CalendarMonthIcon />
              <Typography variant="subTitle2" ml="10px">
                {`${dayjs(event.date_range.Start).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(event.date_range.End).format("DD/MM/YYYY")}`}
              </Typography>
            </Box>
            <Box sx={classes.dateAndLocation}>
              <LocationOnIcon />
              <Typography variant="subTitle2" ml="10px">
                {event.platform}
              </Typography>
            </Box>
          </Stack>
          <Box sx={classes.viewBox}>
            <Divider />
            <div>
              <RemoveRedEyeIcon />
              <Typography variant="subTitle2" ml="10px" color="#616161">
                {numberFormat.format(event.view)} Views
              </Typography>
            </div>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
