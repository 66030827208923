import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
// type and api
import { IAddressState } from "./type";
import { AppInformationAPI } from "@api/global";

const initialState: IAddressState = {
  data: null,
  loading: false,
};

export const getAddressAsync = createAsyncThunk("address", async () => {
  try {
    const response = await AppInformationAPI.getAddressList();
    return response;
  } catch (err) {
    console.log("catch", err);
    throw err;
  }
});

export const addressInfoSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    addressList: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressAsync.pending, (state) => {
        state.loading = false;
      })
      .addCase(getAddressAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getAddressAsync.rejected, (state) => {
        state.data = null;
        state.loading = false;
      });
  },
});

export const selectAddressInfo = (state: RootState) => state.address.data;
export const selectAddressOnload = (state: RootState) => state.address.loading;

export default addressInfoSlice.reducer;
