import { IChartArea } from "./campaignReport.type";
import { hGrey, hPink, hPurple, hWhite } from "@theme/index";

const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

export const campaignPerformanceChart = {
  chartAreaBorder: [
    {
      id: "chartAreaBorder",
      afterDatasetsDraw(chart: any, _args: any, _options: any) {
        const {
          ctx,
          chartArea: { bottom, left, width },
        } = chart;
        ctx.save();
        ctx.strokeStyle = hGrey[800];
        ctx.strokeRect(left - 7, bottom, width + 14, 1);
        ctx.restore();
      },
    },
  ],
  options: {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (val: any, _index: number) => {
            return numberFormat.format(val as number);
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom" as const,
        onClick: (_click: any, legendItem: any, legend: any) => {
          const datasets = legend.legendItems.map(
            (dataset: any, _index: number) => {
              return dataset.text;
            }
          );
          const index = datasets.indexOf(legendItem.text);
          if (legend.chart.isDatasetVisible(index) === true) {
            legend.chart.hide(index);
          } else {
            legend.chart.show(index);
          }
        },
        labels: {
          usePointStyle: true,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, _index: number) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              pointStyle: "circle",
              hidden: false,
            }));
          },
        },
        datalabels: {
          color: "white",
          display: false,
        },
      },
      title: {
        display: false,
        text: "Reach | Impression",
        position: "left" as const,
      },
    },
  },
  data: {
    datasets: [
      {
        fill: true,
        label: "Reach",
        data: [70000, 60000, 50000, 30000, 30000, 60000, 30000],
        backgroundColor: hPink[300],
        borderColor: hPink[300],
        borderRadius: 8,
        borderWidth: 1,
        categoryPercentage: 0.4,
        barPercentage: 0.9,
        scales: {
          grace: "500",
        },
      },
      {
        fill: true,
        label: "Impression",
        data: [80000, 30000, 40000, 30000, 50000, 50000, 10000],
        backgroundColor: hPurple[300],
        borderColor: hPurple[300],
        borderRadius: 8,
        borderWidth: 1,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
      },
    ],
  },
};

export const ageAndGenderDistributionChart = {
  chartAreaBorder: campaignPerformanceChart.chartAreaBorder,
  Multioptions: {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      right: {
        beginAtZero: true,
        position: "right" as const,
        title: {
          display: false,
          text: "Males | Females",
        },
        grid: {
          drawOnChartArea: false,
        },
        grace: "500",
        ticks: {
          callback: function (val: any, _index: any, _vals: any) {
            return numberFormat.format(val);
          },
        },
      },
      left: {
        beginAtZero: true,
        position: "left" as const,
        title: {
          display: false,
          text: "Totals",
        },
        grace: "500",
        ticks: {
          callback: function (val: any, _index: any, _vals: any) {
            return numberFormat.format(val);
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom" as const,
        onClick: (_click: any, legendItem: any, legend: any) => {
          const datasets = legend.legendItems.map(
            (dataset: any, _index: number) => {
              return dataset.text;
            }
          );
          const index = datasets.indexOf(legendItem.text);
          if (legend.chart.isDatasetVisible(index) === true) {
            legend.chart.hide(index);
          } else {
            legend.chart.show(index);
          }
        },
        labels: {
          usePointStyle: true,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, _index: number) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              pointStyle: "circle",
              hidden: false,
            }));
          },
        },
      },
    },
  },
  MultiData: {
    datasets: [
      {
        type: "line" as const,
        label: "Females",
        backgroundColor: hPink[300],
        borderColor: hPink[300],
        borderWidth: 2,
        fill: false,
      },
      {
        type: "line" as const,
        label: "Males",
        backgroundColor: hPurple[300],
        borderColor: hPurple[300],
        borderWidth: 2,
        fill: false,
        yAxisID: "right",
      },
      {
        type: "bar" as const,
        label: "All",
        backgroundColor: "#EBDFFF",
        borderRadius: 8,
        yAxisID: "left",
        barPercentage: 0.4,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: hWhite[100],
        formatter: (value: any, ctx: any) => {
          let datasets = ctx.chart.data.datasets;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
            let percentage = ((value / sum) * 100).toFixed(2) + "%";
            return percentage;
          }
        },
      },
    },
  },
  data: {
    labels: ["Female", "Male", "Other"],
    datasets: [
      {
        label: "# of Votes",
        backgroundColor: [hPink[300], hPurple[300], hGrey[500]],
        borderColor: hWhite[100],
        borderWidth: 2,
      },
    ],
  },
};

export const EngagementChart = {
  chartAreaBorder: campaignPerformanceChart.chartAreaBorder,
  options: {
    ...campaignPerformanceChart.options,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (val: any, _index: number) => {
            return numberFormat.format(val as number);
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        ...campaignPerformanceChart.options.plugins.legend,
        display: false,
        labels: {
          usePointStyle: true,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, _index: number) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              pointStyle: "rect",
              hidden: false,
            }));
          },
        },
      },
    },
  },
  data: {
    datasets: [
      {
        fill: true,
        id: 1,
        label: "Impression",
        data: [30000, 80000, 40000, 30000, 50000, 50000, 10000],
        borderColor: hPurple[300],
        backgroundColor: "rgb(84, 36, 133,0.2)",
        // yAxisID: "left",
      },
      {
        fill: true,
        id: 2,
        label: "Reach",
        data: [63000, 95000, 50000, 30000, 30000, 60000, 30000],
        borderColor: hPink[300],
        backgroundColor: "rgba(232, 0, 138, 0.2)",
        // yAxisID: "right",
      },
      {
        fill: true,
        id: 3,
        label: "Share",
        data: [53000, 50000, 35000, 10000, 30000, 60000, 30000],
        borderColor: "#959595",
        backgroundColor: "rgba(224 , 224, 224, 0.4)",
      //   yAxisID: "percentage",
      },
    ],
  },
};

export const conversionChart = {
  chartAreaBorder: campaignPerformanceChart.chartAreaBorder,
  options: {
    ...campaignPerformanceChart.options,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      left: {
        beginAtZero: true,
        position: "left" as const,
        title: {
          display: false,
          text: "Conversion Rate",
        },
        grace: "500",
        ticks: {
          callback: (val: any, _index: number) => {
            return numberFormat.format(val as number);
          },
        },
      },
      right: {
        grace: "500",
        beginAtZero: true,
        position: "right" as const,
        title: {
          display: false,
          text: "Clicks",
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: (val: any, _index: number) => {
            return numberFormat.format(val as number);
          },
        },
      },
    },
    plugins: {
      ...campaignPerformanceChart.options.plugins,
      legend: {
        ...campaignPerformanceChart.options.plugins.legend,
        display: false,
        labels: {
          usePointStyle: true,
          generateLabels: (chart: any) => {
            return chart.data.datasets.map((dataset: any, _index: number) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              pointStyle: "rect",
              hidden: false,
            }));
          },
        },
      },
    },
  },
  data: {
    datasets: [
      {
        type: "line" as const,
        label: "Conversion Rate",
        borderColor: hPink[300],
        backgroundColor: hPink[300],
        borderWidth: 1,
        data: [],
        // data: [300, 900, 1100, 1400, 1810, 530, 545],
        // fill: true,
        yAxisID: "left",
        barPercentage: 0.5,
      },
      // {
      //   type: "line" as const,
      //   label: "Const Per Click(CPC)",
      //   borderColor: "#ED1968",
      //   backgroundColor: "#ED1968",
      //   borderWidth: 2,
      //   fill: false,
      //   data: [230, 200, 1300, 1420, 1610, 210, 445],
      // },
      {
        type: "bar" as const,
        label: "Click",
        backgroundColor: hPurple[300],
        borderColor: "white",
        borderWidth: 1,
        borderRadius: 8,
        data: [],
        // data: [500, 1000, 1500, 2000, 2500, 230, 545],
        fill: true,
        yAxisID: "right",
        barPercentage: 0.4,
      },
    ],
  },
};

export const locationDistribution = {
  chartAreaBorder: [
    {
      id: "chartAreaBorder",
      afterDatasetsDraw(
        chart: {
          ctx: any;
          chartArea: IChartArea;
        },
        _args: any,
        _options: any
      ) {
        const {
          ctx,
          chartArea: { top, left, height },
        } = chart;
        ctx.save();
        ctx.strokeStyle = hGrey[800];
        ctx.strokeRect(left, top, 1, height + 7);
        ctx.restore();
      },
    },
  ],
  options: {
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      bottom: {
        beginAtZero: true,
        position: "bottom" as const,
        ticks: {
          callback: (val: any, _index: number) => {
            return numberFormat.format(val);
          },
        },
      },
    },
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  },
  data: {
    datasets: [
      {
        id: 1,
        label: "Click",
        backgroundColor: hPurple[300],
        borderRadius: 8,
        xAxisID: "bottom",
        barPercentage: 0.5,
      },
    ],
  },
};