// mui an icon
import {
  Box,
  Grid,
  Icon,
  Stack,
  Table,
  Paper,
  Avatar,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import MatchPlatformIcon from "@components/global/get-platform";
// style
import {
  TableRowCustom,
  TableCellCustom,
  employerExpense as classes,
} from "./Expense.style";
// other
import { IExpenseInfo } from "@views/expense/expense.type";
import dayjs from "dayjs";

export default function EmployerExpenseList(props:{ expenseList: IExpenseInfo[] | null}) {
  const { expenseList } = props
  return (
    <Box mt={3}>
      {/* display for desktop */}
      {expenseList?.length ? (
        <TableContainer
          component={Paper}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Table sx={{ minWidth: [980, 1100] }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCellCustom>แคมเปญ</TableCellCustom>
                <TableCellCustom>ผู้รับรายได้</TableCellCustom>
                {["วันสิ้นสุดแคมเปญ", "จำนวนเงิน", "สถานะ", "เอกสาร"].map(
                  (row, index) => (
                    <TableCellCustom key={index} align="center">
                      {row}
                    </TableCellCustom>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {expenseList?.map((row, index) => (
                <TableRowCustom key={index}>
                  <TableCellCustom align="left">
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        sx={classes.campaignIcon}
                        alt="Campaign"
                        src={row.brand_logo_image_url}
                      />
                      <Box ml={2.5}>
                        <Typography variant="title1" color="#8C499C">
                          {row.campaign_name}
                        </Typography>
                        <Typography
                          fontSize={["12px", "14px"]}
                          fontWeight={400}
                        >
                          Transaction ID : {row.transaction_id}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Stack
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Typography variant="title1">{row.fullname}</Typography>
                      <Box display="flex" alignItems="center">
                        <MatchPlatformIcon platform={row.platform} />
                        <Typography fontWeight={400} textTransform="capitalize">
                          {row.platform}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Typography variant="title1">
                      {row.completed_date ? dayjs(row.completed_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Typography variant="title1">
                      {`${new Intl.NumberFormat("jp-JP", {
                        minimumFractionDigits: 2,
                      }).format(row.amount)} THB`}
                    </Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Typography
                      textTransform="capitalize"
                      sx={row.status === "paid" ? classes.statusPaid : classes.statusPending }
                    >
                      {row.status}
                    </Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Button
                      onClick={() => window.open(row.reciept_info.pdf_url)}
                      sx={classes.button}
                      disabled={row.status === "pending"}
                    >
                      <Icon
                        className="ico-hu-download-circle"
                        sx={{ mr: "4px" }}
                      />
                      <Typography variant="buttonM">ใบเสร็จ</Typography>
                    </Button>
                    {row.amount >= 1000 && (
                      <Button
                        onClick={() => window.open(row.tax_info.pdf_url)}
                        sx={classes.taxButton}
                        disabled={row.status === "pending"}
                      >
                        <Icon
                          className="ico-hu-download-circle"
                          sx={{ mr: "4px" }}
                        />
                        <Typography variant="buttonM">หัก ณ ที่จ่าย</Typography>
                      </Button>
                    )}
                  </TableCellCustom>
                </TableRowCustom>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={classes.noExpese}>
          <img
            src={require("@assets/images/infographic/influencer/my-earnings/no-payment-history.png")}
            alt="expense"
          />
          <Typography variant="h5" mt="24px">
            ไม่พบค่าใช้จ่าย
          </Typography>
        </Box>
      )}

      {/* display for mobile */}
      <Grid container my={1} display={{ md: "none" }} spacing={5}>
        {expenseList?.map((row, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            display="flex"
            justifyContent="center"
          >
            <Box sx={classes.card}>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
                mb={2.5}
              >
                <span>{`ID : ${row.transaction_id}`}</span>
                <Typography
                  textTransform="capitalize"
                  sx={row.status === "paid" ? classes.statusPaid : classes.statusPending}
                >
                  {row.status}
                </Typography>
              </Box>
              <Divider sx={{ my: "10px" }} />
              <Box display="flex" alignItems="center">
                <Avatar
                  src={row.brand_logo_image_url}
                  sx={classes.campaignIcon}
                />
                <Box ml={3}>
                  <Typography
                    variant="title1"
                    color="primary"
                    sx={{ fontSize: "16px", fontWeigth: "500" }}
                  >
                    {row.campaign_name}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <MatchPlatformIcon platform={row.platform} />
                    <Typography textTransform="capitalize">
                      {row.platform}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={classes.cardTable}>
                <Box className="cardTable">
                  <Typography variant="title1">ผู้รับรายได้</Typography>
                  <Typography color="primary">{row.fullname}</Typography>
                </Box>
                <Box className="cardTable">
                  <Typography variant="title1">วันสิ้นสุดแคมเปญ</Typography>
                  <Typography>
                    {row.completed_date ? dayjs(row.completed_date).format("DD-MM-YYYY") : "-"}
                  </Typography>
                </Box>
                <Box className="cardTable">
                  <Typography variant="title1">จำนวนเงิน</Typography>
                  <Typography>
                    {`${new Intl.NumberFormat("jp-JP", {
                      minimumFractionDigits: 2,
                    }).format(row.amount)} THB`}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={classes.cardTable}
              >
                <Typography variant="title1" fontSize="15px" ml={4}>
                  หัก ณ ที่จ่าย
                </Typography>
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={row.status !== "paid"}
                  >
                    ใบเสร็จ
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    disabled={row.status !== "paid"}
                  >
                    หัก ณ ที่จ่าย
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
