export const leaderboardCardMockData = [
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:1234
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:6,
    point:4555
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:4,
    point:56566
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:67555
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:45,
    point:6677
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  {
    profile_url:"https://i.pinimg.com/564x/91/62/ef/9162efb4bdebac5650b6b884bb16918f.jpg",
    name:"Judtem ",
    rating:5,
    point:24455
  },
  
]