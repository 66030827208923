// mui and style
import { Box, Divider, Typography } from "@mui/material";
import { useProfileTabStyle as classes } from "./ProfileTab.style";
// components
import Portfolio from "./Portfolio";
import ShippingAddress from "./ShippingAddress";
import InfluencerProfile from "./InfluencerProfile";

export default function ProfileTab() {
  return (
    <Box sx={classes.container}>
      {[
        { lable: "Influencer Information", component: <InfluencerProfile /> },
        { lable: "Shipping Address", component: <ShippingAddress /> },
        { lable: "Portfolio", component: <Portfolio /> },
      ].map((item, index) => (
        <Box key={index} sx={classes.boxBorder}>
          <Typography variant="h6" p="20px">
            {item.lable}
          </Typography>
          <Divider sx={classes.divider} />
          {item.component}
        </Box>
      ))}
    </Box>
  );
}
