import React from "react";
// mui and icon
import { Box, Icon, Avatar, Divider, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and type
import { useEmployerDetailStyle as classes } from "./Detail.style";
import { IEmployerInfoRes } from "@api/employer/type";
import { ICategory } from "@api/app-information/type";
import { TPackage } from "./Detail.type";
// util
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
import { useHistory, useParams } from "react-router-dom";

export default function HeaderDetail({
  employerInfo,
}: {
  employerInfo: IEmployerInfoRes | null;
}) {

  const history = useHistory()
  const { employerId } = useParams<{employerId:string}>()

  const numFormater = Intl.NumberFormat("en", {
    notation: "standard",
    maximumFractionDigits: 2,
  });

  // check package
  const isTeam = employerInfo?.package_plan === "team";
  const packageName = isTeam
    ? employerInfo.team?.package.title || "free"
    : employerInfo?.package.title || "free";

  const goTopUp = () => {
    history.push(`/payment/top-up/personal?emp_id=${employerId}`)
  }
  
  const goPaymentTransaction = () => {
    history.push(`/employers/${employerId}/transaction`)
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.leftCard}>
        <Avatar
          alt="Employer"
          src={employerInfo?.organisation.attachment?.url || ""}
          sx={classes.avatar}
        />
        <Box sx={classes.leftCardBoxContent}>
          <div>
            <Typography variant="h6">
              {employerInfo?.organisation.name}
            </Typography>
            <Box sx={classes.category}>
              {employerInfo?.organisation?.category.map((cat:ICategory, i:number) => (
                <React.Fragment key={i}>
                  <Typography variant="body2" color="#616161">
                    {cat?.name_en}
                  </Typography>
                  <CircleIcon />
                </React.Fragment>
              ))}
            </Box>
          </div>
          <Divider />
          <Box display="flex" columnGap="30px">
            {[
              { label: "Employer ID", value: employerInfo?.employer_id },
              { label: "Email", value: employerInfo?.email },
              {
                label: "Phone",
                value: formatPhoneNumber(
                  (employerInfo?.phone || "").toString()
                ),
              },
            ].map((item, index) => (
              <Box
                key={index}
                maxWidth="33.3%"
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  whiteSpace="nowrap"
                  mr={1}
                >
                  {item.label} :
                </Typography>
                <Typography
                  variant="body2"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={classes.rightCard}>
        <Box display="flex" alignItems="center" m="20px 0 0 20px">
          <Typography variant="h6">Wallet</Typography>
          <Box sx={classes[packageName as TPackage]}>
            <Typography
              variant="body2"
              textTransform="capitalize"
              fontWeight={500}
            >
              {packageName}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" m="0 20px 20px 20px">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Typography variant="h4" color="#8C499C" fontWeight="400" mt={1}>
              {numFormater.format(employerInfo?.wallet || 0)} THB
            </Typography>
            <Typography variant="subtitle2" color="#505050">
              Balance
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" rowGap="10px">
            <Box onClick={goTopUp} sx={classes.transaction}>
              <Icon className="ico-hu-topup" />
              <Typography variant="body1">Top-Up</Typography>
            </Box>
            <Box onClick={goPaymentTransaction} sx={classes.transaction}>
              <Icon className="ico-hu-history" />
              <Typography variant="body1">History</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
