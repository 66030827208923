import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Button,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
// api and style
import { InfluencerAPI } from "@api/global";
import { categoryDialogStyle as classes } from "../Category.style";
// redux
import {
  getCategoryAsync,
  selectCategoryList,
} from "@slices/CategoryList.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// component
import Gender from "../Genders";
import Category from "../Category";
import { IGender } from "../Category.type";
// others
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TextFieldCustom from "@global/text-field";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";

const initCategory = {
  id: 0,
  name_en: "",
  name_th: "",
  value: "",
};
const initGender: any = {
  id: 0,
  value: "",
  title: "",
};
const initCategoryDialog: any = {
  social_account_category: [initCategory],
  min: 0,
  max: 0,
  target_gender: [initGender],
};

export default function SelectCategory() {
  const history = useHistory();
  const data: any = history.location.state as any;
  const platform = data.platform;
  const socialAccountId = data.profile.social_account_id;
  
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(selectCategoryList);

  const {
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<any>({
    // resolver: yupResolver(BankAccountFormSchema),
    defaultValues: initCategoryDialog,
  });

  const genderList = [
    { id: "male", value: "male", title: "Male" },
    { id: "female", value: "female", title: "Female" },
    { id: "lgbtq", value: "lgbtq", title: "LGBTQ+" },
  ];

  const [categorySelected, setCategorySelected] = useState<any>([]);
  const [genderSelected, setGenderSelected] = useState<any>([]);

  useEffect(() => {
    dispatch(getCategoryAsync());
  }, [dispatch]);

  useEffect(() => {
    if (data?.profile) {
      const form = {
        social_account_category: data?.profile?.category,
        min: data?.profile?.target_age?.min,
        max: data?.profile?.target_age?.max,
        target_gender: data?.profile?.target_gender,
      };
      reset(form);
      // Category
      if (data?.profile?.category?.length === 0) {
        setCategorySelected([]);
      } else {
        setCategorySelected(data?.profile?.category);
      }
      // Gender
      if (data?.profile?.target_gender?.length === 0) {
        setGenderSelected([]);
      } else {
        setGenderSelected(data?.profile?.target_gender);
      }
    }
  }, [data?.profile, reset]);

  const handleChange = (e: any, name: any) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onSubmit = async (data: any) => {
    const newData = categorySelected.map((item: any) => {
      return {
        platform: platform,
        category_id: item.id,
      };
    });

    try {
      const reqBody = {
        social_account_id: socialAccountId,
        social_account_category: newData,
        target_age: {
          min: Number(data.min),
          max: Number(data.max),
        },
        target_gender: genderSelected,
      };
      InfluencerAPI.updateInfluencerCategory(reqBody);
      await SwalCustom.fire({
        icon: "success",
        title: "Updated category success",
        showConfirmButton: false,
        timer: 1600,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Box className="container-main">
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => history.goBack()}>
          <Icon className="ico-hu-chevron-left" />
        </IconButton>
        <Typography variant="h6" textAlign="center" width="100%" ml={-3}>
          Categories
        </Typography>
      </Box>
      <Box p="0px 12px 1px">
        <Box 
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.05)",
            p: "16px",
            mt: "20px",
            bgcolor: "#fff",
          }}
        >
          <Avatar
            src={data?.profile.profile_image}
            sx={{ width: "48px", height: "48px", borderRadius: "5px" }}
          />
          <Box ml="16px">
            <Typography variant="title1">{data?.profile.name}</Typography>
            <Typography variant="body2" textTransform="uppercase">{data?.platform}</Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box 
            sx={{ 
              mt: "20px",            
              borderRadius: "8px",
              boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.05)",
              p: "16px",
              bgcolor: "#fff",
            }}>
            <Box sx={classes.boxTextTitle}>
              <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
                Select Categories
              </Typography>
              <Typography variant="body2" sx={{ marginTop: "8px" }}>
                โปรดเลือก 3 ลักษณะคอนเทนต์ที่เกี่ยวข้องกับคุณมากที่สุด
              </Typography>
            </Box>
            <Box sx={{ padding: "0px 8px" }}>
              <Grid container spacing={6}>
                {categoryList.map((category) => (
                  <Grid item xs={6} key={category.id}>
                    <Category
                      item={category}
                      categorySelected={categorySelected}
                      setCategorySelected={setCategorySelected}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box 
            sx={{ 
              mt: "20px", 
              borderRadius: "8px",
              boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.05)",
              p: "16px",
              bgcolor: "#fff",
            }}>
            <Box sx={classes.boxTextTitle2}>
              <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
                Target Audience
              </Typography>
              <Typography variant="body2" sx={{ marginTop: "8px" }}>
                โปรดระบุกลุ่มเป้าหมายของคุณ
              </Typography>
            </Box>
            <Box>
              <Grid container sx={classes.gridAge}>
                <Grid item xs={12}>
                  Age :
                </Grid>
                <Grid item xs={5}>
                  <TextFieldCustom
                    value={getValues("min")}
                    onChange={(e) => {
                      handleChange(e, "min");
                    }}
                    type="number"
                    variant="outlined"
                    placeholder="min age"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box sx={classes.lineCenter}>-</Box>
                </Grid>
                <Grid item xs={5}>
                  <TextFieldCustom
                    value={getValues("max")}
                    onChange={(e) => {
                      handleChange(e, "max");
                    }}
                    type="number"
                    variant="outlined"
                    placeholder="min age"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  Gender :
                </Grid>
                {genderList.map((gender: IGender) => (
                  <Grid item xs={4} key={gender.id}>
                    <Gender
                      item={gender}
                      genderSelected={genderSelected}
                      setGenderSelected={setGenderSelected}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" sx={{ margin: "32px 0" }}>
            {data?.profile?.category?.length === 0 ? (
              <Button
                variant="contained"
                type="submit"
                sx={{ minWidth: "300px", border:"none", "&:hover":{ border:"none"} }}
              >
                Verify {data?.platform} Account
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                sx={{ minWidth: "300px", border:"none", "&:hover":{ border:"none"} }}
                startIcon={<Icon className="ico-hu-save" />}
              >
                Save
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}
