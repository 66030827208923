// mui and icons
import {
  Box,
  Grid,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";
// type and style
import { ITimelineAnnounceDialogProps } from "../CampaignDetail.type";
import { useTimelineDialogStyle as classes } from "../CampaignDetail.style";
// others
import { timelineSubmitDraftList } from "@components/campaign/create-campaign/campaign-brief/campaignBrief.constants";
import dayjs from "dayjs";

export default function TimelineAnnounceDialog(
  props: ITimelineAnnounceDialogProps
) {
  const { open, onClose, announceDate } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>Timeline</Typography>
        <Typography>ประกาศผลผู้เข้าร่วมแคมเปญ</Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.dialogContentBox}>
          <Grid container spacing={5}>
            <Grid item xs={12} mb={3}>
              <Box>
                <Typography
                  color="primary"
                  fontWeight="500"
                  mb="10px"
                  mt="16px"
                >
                  ประกาศผลผู้เข้าร่วมแคมเปญ
                </Typography>
                <Box display="flex">
                  <Grid item xs={5} sx={classes.selectInfluDate}>
                    <CircleIcon />
                    <Typography>
                      ผลผู้ประกาศคัดเลือกจะถูกประกาศภายในวันที่
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography sx={classes.annouceDate}>
                      {dayjs(announceDate).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Box sx={{ height: "96%" }}>
                <Typography color="primary" fontWeight="500" mb="10px">
                  หลังประกาศผู้เข้าร่วมเคมเปญ
                </Typography>
                <Grid item xs={12}>
                  <ul>
                    <Typography component="li" fontWeight={500}>
                      การรีวิวสินค้า
                    </Typography>
                    <ul>
                      <Typography
                        component="li"
                        sx={{ listStyleType: "initial" }}
                      >
                        jbdbdbdh
                      </Typography>
                      <Typography
                        component="li"
                        style={{ listStyleType: "initial" }}
                      >
                        jbdbdbdh
                      </Typography>
                    </ul>
                  </ul>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <Typography component="li" fontWeight={500}>
                      การรีวิวสถานที่
                    </Typography>
                    <ul>
                      <Typography
                        component="li"
                        style={{ listStyleType: "initial" }}
                      >
                        jbdbdbdh
                      </Typography>
                    </ul>
                  </ul>
                  <Box sx={{ "& > img": { width: "100%" } }}>
                    <img
                      src={require(`@assets/images/campaign/post-campaign.png`)}
                      alt="post campaign"
                    />
                  </Box>
                </Grid>

                <Box sx={classes.selectInfluAndAnnounce}>
                  {timelineSubmitDraftList.map((item, index) => (
                    <ul key={index}>
                      <li>{item.label}</li>
                      {item.text.map((txtItem, txtIndex) => (
                        <li key={txtIndex}>{txtItem}</li>
                      ))}
                    </ul>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
