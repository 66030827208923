import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Three() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>
        3. The Company collects your information for what purpose?
      </Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>
        The Company collects your information and use it for the following
        purposes:
      </Typography>
      <Box>
        <Typography> ● For creating a username.</Typography>
        <Typography>● For hiring and working on the HashU Platform.</Typography>
        <Typography>
          ● For presenting the HashU Platform and content on the HashU Platform
          to you.
        </Typography>
        <Typography>
          ● For providing information, products, or services that you request
          from the Company.
        </Typography>
        <Typography>
          ● For contacting between you and the Company and/or other people
          regarding the Company's products and service and of the third-party,
          which may include presenting your information in different places for
          advertising and promoting yourself according to the HashU Platform's
          working style throughout your continued use of the HashU Platform.
        </Typography>
        <Typography>
          ●For concluding a statistical database about the Company's services.
        </Typography>
        <Typography>
          ● For providing notices to you about account / subscription, including
          notices of expiration and renewal.
        </Typography>
        <Typography>
          ● To fulfill the Company's obligations and enforce our rights arising
          from any contact that the Company has done to you, including invoicing
          and billing.
        </Typography>
        <Typography>
          ● To notify you of any changes on the HashU Platform or any products
          or services presented or provided through the HashU Platform.
        </Typography>
        <Typography>
          ● To enable you to participate in the use of the HashU Platform in a
          special feature.
        </Typography>
        <Typography>
          ● For disclosure to the Company's group and its partners (meaning
          companies in which HashU holds shares or a company that acquires
          shares of HashU or a company that enters into a business contact with
          HashU) for the benefit of offering products and services to you.
        </Typography>
        <Typography>
          ● To collect the data as the basis for designing new services,
          selecting the proper marketing campaign, and presenting the new
          marketing campaign.
        </Typography>
        <Typography>
          ● For the payment or collection of the contact, issue the involved
          document, job alerts, delivery of products for review; and / or
        </Typography>
        <Typography>
          ● To disclose to the Revenue Department and comply with the duty of
          tax withholder and retain data for a period specified by the laws.
        </Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          Suppose the Company uses automatic data collection technologies. In
          that case, it may allow the Company to obtain a statistical database
          that includes personal information. The Company may maintain or link
          such information with personal information collected by the Company by
          other means or receiving information from third parties. The
          information obtained will help the Company to improve the HashU
          Platform to provide better services and more in line with the needs,
          which enables the Company to perform the following actions:
        </Typography>
        <Typography>
          ● Evaluate the number and usage patterns of your Company's followers.
        </Typography>
        <Typography>
          ● Keep information about your preferences. It allows the Company to
          adapt the HashU Platform to suit your interests.
        </Typography>
        <Typography>● Speed up your search.</Typography>
        <Typography>
          ● Remember when you use the HashU Platform again.
        </Typography>
      </Box>
    </Box>
  );
}
