export const useViewMultiVideoDialogStyle = {
  dialog: {
    "& .css-fkeb6g-MuiPaper-root-MuiDialog-paper, .css-t255vf": {
      borderRadius: 0,
      position: "initial",
      bgcolor: "initial",
      boxShadow: "none",
      m: {
        xs: 0,
      },
    },
    "& .MuiDialog-container": {
      position: "relative",
      display: "flex",
    },
    bgcolor: {
      xs: "rgba(0,0,0,1)",
      md: "initial",
    },
  },
  closeIcon: {
    position: "absolute",
    top: {
      xs: "20px",
      md: "40px",
    },
    right: {
      xs: "20px",
      md: "40px",
    },
    color: "#fff",
    zIndex: 100,
    fontSize: {
      xs: "30px",
      md: "32px",
    },
    cursor: "pointer",
  },
  prevAndNextBox: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: {
      xs: "none",
      sm: "flex",
    },
    justifyContent: "space-between",
    alignItems: "center",
  },
  prevBtn: {
    color: "#fff",
    fontSize: {
      sm: "40px",
      md: "50px",
    },
    ml: {
      sm: "20px",
      md: "50px",
    },
    cursor: "pointer",
  },
  nextBtn: {
    color: "#fff",
    fontSize: {
      sm: "40px",
      md: "50px",
    },
    mr: {
      sm: "20px",
      md: "50px",
    },
    cursor: "pointer",
  },
  total: {
    position: "absolute",
    bottom: "30px",
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  slideVidosBoxForMobile: {
    // height: "82vh",
    bgcolor: "none",
    display: {
      xs: "flex",
      md: "none",
    },
    justifyContent: "center",
    alignItems: "center",
    "& .mySwiper": {
      height: "75vh",
      maxWidth: "500px",
      width: {
        xs: "100vw",
        sm: "100%",
      },
      "& .swiperSlide": {
        width: {
          xs: "100vw",
          sm: "100%",
        },
        maxWidth: "600px",
        display: "flex",
        alignItems: "center",
      },
    },
  },
  slideVideosBoxForDesktop: {
    borderRadius: "0px",
    p: 0,
    display: {
      xs: "none",
      md: "initial",
    },
    "& > div:last-of-type": {
      height: "80vh !important",
      width: "100% !important",
    },
    "& video": {
      height: "98% !important",
      width: "100%",
      objectFit: "cover",
      cursorY: "none",
    },
  },
};
