import { useState } from "react";
// mui and style
import { Box, Icon, Stack, Avatar, Divider, Typography } from "@mui/material";
import { useReviewPostInfluencerStyle as classes } from "./JobDefault.style";
// type and image
import { IReviewPostInfluencerProps } from "./JobDefault.type";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
// others
import { VideoFormat } from "@global/video-format/ViedoFormat";
import { FacebookImageFormat } from "@global/image-format/ImageFormat";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";
import Linkify from "react-linkify";
import nl2br from "react-nl2br";

export default function ReviewPostInfluencer(
  props: IReviewPostInfluencerProps
) {
  const {
    watch,
    fileType,
    isEditDraft,
    attachments,
    jobDetailForInflu,
    jobDetailForEmployer,
  } = props;

  const [onViewImage, setViewImage] = useState(false);
  const data = jobDetailForEmployer || jobDetailForInflu;

  const isVideoType = fileType === "video";
  const attachmentWatch = watch ? watch("attachments") : [];
  const attachmentsList = attachments || data?.attachments || [];
  const urls = data?.external_file || [];
  const urlWatch = watch ? watch("external_file") : [];
  const contentType = data?.content_type.title;
  const isTextOnly = /(text only)/.test(contentType || "");

  return (
    <Box sx={classes.influPostCon}>
      <Box maxWidth="400px" width="100%">
        <Box display={{ sm: "none" }}>
          <Typography variant="h6">Post Preview</Typography>
          <Typography variant="subTitle2">ตัวอย่างการโพสต์</Typography>
          <Divider sx={{ my: "16px" }} />
        </Box>
        <Box sx={classes.postProfile}>
          <img
            src={data?.social_account.profile_image_url}
            alt="social profile"
          />
          <Typography ml="5px">{data?.social_account.name}</Typography>
        </Box>
        <Box>
          <Linkify>
            <Typography
              sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {watch && isEditDraft ? (
                watch("description.description") ? (
                  nl2br(watch("description.description"))
                ) : (
                  <>
                    hashu... <br />
                    <span style={{ color: "blue" }}>#hashu</span>
                  </>
                )
              ) : (
                nl2br(data?.description?.description)
              )}
            </Typography>
          </Linkify>
        </Box>
        {watch && isEditDraft && !isTextOnly ? (
          attachmentWatch.length ? (
            <Box onClick={() => setViewImage(true)} sx={{ cursor: "pointer" }}>
              {data?.content_type.type === "video" ? (
                <VideoFormat item={[attachmentWatch[0]]} height="380px" />
              ) : (
                <FacebookImageFormat item={attachmentWatch} height="380px" />
              )}
            </Box>
          ) : urlWatch.length ? (
            <Box
              sx={classes.urlBox}
              onClick={() => window.open(urlWatch[0].url, "_blank")}
            >
              <Box sx={classes.urlIcon}>
                <Icon className="ico-hu-conversion" />
                <Typography mt="15px">Video URL</Typography>
              </Box>
              <Box sx={classes.urlImg}>
                <img src={urlLogo} alt="item" />
              </Box>
            </Box>
          ) : (
            <Box mt={6.2} sx={classes.notImgPostBox}>
              <Avatar
                src={require("@assets/images/logo/hashu-1024x1024.png")}
                sx={{ width: "200px", height: "200px" }}
              />
            </Box>
          )
        ) : data && !isTextOnly ? (
          <Box sx={{ cursor: "pointer" }}>
            {data?.content_type.type === "video" ? (
              attachmentsList.length ? (
                <Box onClick={() => setViewImage(true)}>
                  <VideoFormat item={[attachmentsList[0]]} height="380px" />
                </Box>
              ) : (
                <Box
                  sx={classes.urlBox}
                  onClick={() => window.open(urls[0].url, "_blank")}
                >
                  <Box sx={classes.urlIcon}>
                    <Icon className="ico-hu-conversion" />
                    <Typography variant="body2" mt="15px">
                      Video URL
                    </Typography>
                  </Box>
                  <Box sx={classes.urlImg}>
                    <img src={urlLogo} alt="item" />
                  </Box>
                </Box>
              )
            ) : (
              <Box onClick={() => setViewImage(true)}>
                <FacebookImageFormat
                  item={attachmentsList || []}
                  height="380px"
                />
              </Box>
            )}
          </Box>
        ) : (
          ""
        )}

        <Stack direction="row" spacing={8} mt={2}>
          {[
            { icon: "ico-hu-like", total: "0" },
            { icon: "ico-hu-comment", total: "0" },
            { icon: "ico-hu-share-m", total: "0" },
          ].map((item, index) => (
            <Box key={index} display="flex">
              <Icon className={item.icon} />
              <Typography ml="7px">{item.total}</Typography>
            </Box>
          ))}
        </Stack>
        {isVideoType ? (
          <ViewMultiVideoDialog
            open={onViewImage}
            onClose={setViewImage}
            videos={watch ? attachmentWatch : attachmentsList}
          />
        ) : (
          <ViewMultiImageDialog
            open={onViewImage}
            onClose={setViewImage}
            images={watch ? attachmentWatch : attachmentsList}
          />
        )}
      </Box>
    </Box>
  );
}
