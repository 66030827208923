// mui and icon
import { Box, Fab, Icon, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and type
import { useCampaignPrivateStyles as classes } from "./CampaignPrivate.style";
import { ICardPrivateProps } from "./PrivateCampaign.type";

export default function CardPrivate(props: ICardPrivateProps) {
  const { item, handleClick, goViewInfluencer } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const totalEngagement = Math.round(item.engagement_rate * 100) / 100;

  return (
    <Box position="relative">
      <Fab
        onClick={() => {
          handleClick(item.influencer_id);
        }}
        color={item.status !== "" ? "success" : "primary"}
        sx={item.status !== "" ? classes.fabSelected : classes.fab}
      >
        {item.status !== "" ? (
          <>
            <Icon className="ico-hu-check" sx={{ color: "#fff" }} />
            <Typography className="showText" sx={classes.showText}>
              Remove
            </Typography>
          </>
        ) : (
          <>
            <Icon className="ico-hu-add" />
            <Typography className="showText" sx={classes.showText}>
              Select
            </Typography>
          </>
        )}
      </Fab>

      <Box
        sx={{
          ...classes.rateCard,
          backgroundColor: item.status !== "" ? "#4CAF50" : "#893DBD",
        }}
      >
        <Typography variant="title2">
          {Intl.NumberFormat("en").format(Math.round(item.rate_card))} THB
        </Typography>
      </Box>

      <Box sx={classes.boxImages}>
        <img
          src={item.profile_image_url}
          className="first-image"
          alt="firstimage"
        />
      </Box>

      <Box
        sx={classes.boxDetail}
        onClick={() => goViewInfluencer(item.influencer_id, item)}
      >
        <Box sx={{ padding: "12px" }}>
          <Box display="flex">
            <Typography sx={classes.textEllipsis}>{item.name}</Typography>
          </Box>

          {item.category === null ? (
            <Typography>-</Typography>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              divider={<CircleIcon />}
              sx={classes.stackCategory}
            >
              {item.category.map((cat: string, index: number) => (
                <Typography key={index} sx={classes.textCategory}>
                  {cat}
                </Typography>
              ))}
            </Stack>
          )}

          <Box display="flex">
            <Box display="flex" alignItems="center" width="100%">
              <Icon className="ico-hu-group" sx={{ fontSize: "16px" }} />
              <Typography ml={1.5}>
                {numberFormat.format(item.follower)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" width="100%">
              <Icon
                className="ico-hu-engagement-rate"
                sx={{ fontSize: "16px" }}
              />
              <Typography ml={1.5}>{totalEngagement}%</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
