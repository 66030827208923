import { Box } from "@mui/material";

export default function NoPage() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <h1>404 Page Not Found</h1>
        <Box
          component="img"
          width={["200px","300px"]}
          src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
        />
      </Box>
    </Box>
  );
}
