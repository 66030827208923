import React, { useEffect, useState } from "react";
// mui, icon and style
import { Box, Tab, Tabs, Grid, Typography, useTheme } from "@mui/material";
import { useCampaignReportTabStyle as classes } from "./ReportTab.style";
// api and types
import { ReportAPI } from "@api/global";
import { IGetInfluencerReportRes } from "@api/report/type";
import { IInitParams } from "@global/campaign-report/campaignReport.type";
// components
import CampaignSummaryInfo from "./SummaryInfo";
import InfluencerPostReport from "./InfluencerPostReport";
import Engagement from "@global/campaign-report/Engagement";
import Conversion from "@global/campaign-report/Conversion";
import CampaignPerformance from "@global/campaign-report/CampaignPerformance";
import LocationDistribution from "@global/campaign-report/LocationDistribution";
import AgeAndGenderDistribution from "@global/campaign-report/AgeAndGenderDistribution";
import CampaignReportFilterSm from "@components/global/campaign-report/report-filter/ReportFilterSm";
import CampaignReportFilterLg from "@components/global/campaign-report/report-filter/ReportFilterLg";
// others
import { useParams } from "react-router-dom";
import Loading from "@global/loading/Loading";

export default function CampaignReportTab() {
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerSm, setOpenDrawerSm] = useState(false);
  const [campaignReport, setCampaignReport] = useState<IGetInfluencerReportRes | null>(null);

  let date = new Date();
  const { campaignId } = useParams<{ campaignId: string }>();

  const theme = useTheme();
  const xsScreen = theme.breakpoints.between("xs", "sm");

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;
    
    const params = {
      campaign_id: campaignId,
      chart_type: "weekly",
    };

    ReportAPI.getEmployerReport(params)
      .then((response) => {
        if (isMounted) {
          setCampaignReport(response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.log("get employer report error : ", error)
          setIsLoading(false);
        }
      });
    
    return () => {
      isMounted = false
    }
  }, [campaignId]);

  const tabHandleChange = (_event: any, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpenDrawerSm(open);
  };

  const filterDateList = [
    {
      label: "สัปดาห์นี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "weekly",
    },
    {
      label: "เดือนนี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "monthly",
    },
    {
      label: "2 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 2,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "2month",
    },
    {
      label: "3 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 3,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "3month",
    },
    {
      label: "กำหนดเอง",
      value: {
        start: "",
        end: "",
      },
      filterType: "filterSelf",
      type: "filterSelf",
    },
  ];

  const onFilterDate = (data: IInitParams, filterType: string) => {
    if (filterType === "chartType") {
      const params = {
        campaign_id: campaignId,
        chart_type: data.chart_type,
      };
      setIsLoading(true);
      ReportAPI.getEmployerReport(params)
        .then((response) => {
          setCampaignReport(response);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      const params = {
        campaign_id: campaignId,
        date: data.date,
      };
      setIsLoading(false);
      ReportAPI.getEmployerReport(params)
        .then((response) => {
          setIsLoading(false);
          setCampaignReport(response);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const tabPanelList = [
    {
      id: "campaignPerformance",
      value: tabValue,
      component: (
        <CampaignPerformance
          columns={6}
          performance={campaignReport?.impression}
        />
      ),
      xs: 12,
      md: 12,
      data: campaignReport?.impression,
    },
    {
      id: "engagement",
      value: tabValue,
      component: <Engagement engagement={campaignReport?.engagement} />,
      xs: 12,
      md: 12,
      data: campaignReport?.engagement,
    },
    {
      id: "conversion",
      value: tabValue,
      component: <Conversion />,
      xs: 12,
      md: 12,
    },
    {
      id: "ageAndGenderDistribution",
      value: tabValue,
      component: <AgeAndGenderDistribution />,
      xs: 12,
      md: 12,
    },
    {
      id: "locationDistribution",
      value: tabValue,
      component: <LocationDistribution />,
      xs: 12,
      md: 12,
    },
  ];

  if (isLoading) return <Loading />

  return (
    <>
      <CampaignReportFilterSm
        toggleDrawer={toggleDrawer}
        openDrawerSm={openDrawerSm}
        filterDateList={filterDateList}
        filterDate={onFilterDate}
        setOpenDrawerSm={setOpenDrawerSm}
      />
      <div>
        <Box sx={classes.tabPanelEmpWrap}>
          <Box sx={classes.tabfilter}>
            <CampaignReportFilterLg
              filterDate={onFilterDate}
              toggleDrawer={toggleDrawer}
            />
          </Box>
          <Box sx={classes.campSumInfo}>
            <CampaignSummaryInfo
              budget={campaignReport?.campaign.total_budget}
              jobCount={campaignReport?.campaign.job_count}
            />
          </Box>

          <div>
            <Box sx={classes.campReport}>
              <Typography
                variant="body1"
                color="primary"
                mb={2}
                fontWeight="600"
              >
                รายงานแคมเปญ
              </Typography>
              <Tabs
                value={xsScreen ? false : tabValue}
                onChange={tabHandleChange}
                variant="scrollable"
                scrollButtons={false}
                indicatorColor="primary"
                sx={classes.tabPanel}
              >
                {[
                  "Performance",
                  "Engagement",
                  "Conversion",
                  "Age & Gende",
                  "Location",
                ].map((tab, index) => (
                  <Tab
                    key={index}
                    sx={classes.tab}
                    label={tab}
                    onClick={() => setTabValue(index)}
                  />
                ))}
              </Tabs>
            </Box>
            <Grid
              container
              spacing={5}
              mt={{ xs: 0, sm: "-18px", md: "-32px" }}
            >
              {tabPanelList.map(
                (tab, index) =>
                  tab.data && (
                    <Grid item xs={tab.xs} md={tab.md} key={index}>
                      <Box display={["initial", "none"]}>
                        <Box>{tab.component}</Box>
                      </Box>
                      <Box display={["none", "block"]} height="100%">
                        {tab.component}
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        </Box>
        {campaignReport?.social_posts && (
          <Box
            sx={{
              ...classes.tabPanelEmpWrap,
              ...classes.InfluPostReport,
            }}
          >
            <InfluencerPostReport socialPosts={campaignReport?.social_posts} />
          </Box>
        )}
      </div>
    </>
  );
}
