// mui
import {
  Box,
  Icon,
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
  Tooltip as TooltipMui,
} from "@mui/material";
// type and chartJs
import { Chart } from "react-chartjs-2";
import { conversionChart } from "./CampaignReport.chart";
// style and color
import { hPink, hPurple } from "@theme/index";
import { useConversionStyle as classes } from "./CampaignReport.style";

export default function Conversion() {
  // const labels = [
  //   "24/08/2022",
  //   "25/08/2022",
  //   "26/08/2022",
  //   "27/08/2022",
  //   "28/08/2022",
  //   "29/08/2022",
  //   "30/08/2022",
  // ];

  const labels: string[] = [];

  const options = {
    ...conversionChart.options,
    scales: {
      ...conversionChart.options.scales,
      left: {
        ...conversionChart.options.scales.left,
        title: {
          // display: width === "xs" ? false : true,
          text: "Likes",
        },
      },
      right: {
        ...conversionChart.options.scales.right,
        title: {
          // display: width === "xs" ? false : true,
          text: "Comments",
        },
      },
    },
  };

  const data = {
    labels,
    ...conversionChart.data,
  };

  const conversionCheckboxGhraph = [
    {
      label: "Conversion Rate",
      color: "secondary",
      value: "0",
    },
    {
      label: "Clicks",
      color: "primary",
      value: "1",
    },
    {
      label: "Const Per Click (CPC)",
      color: "success",
      value: "2",
    },
  ];

  const conversionData = [
    {
      title: "CVR",
      subTitle: "Conversion Rate",
      total: 0,
      icon: (
        <Icon
          className="ico-hu-conversion-rate"
          fontSize="large"
          color="secondary"
        />
      ),
    },
    {
      title: "Clicks",
      subTitle: "Clicks",
      total: 0,
      icon: <Icon className="ico-hu-click" fontSize="large" color="primary" />,
    },
    {
      title: "CPC",
      subTitle: "Cost per click",
      total: 0,
      icon: (
        <Icon
          className="ico-hu-cost-per-click"
          fontSize="large"
          color="primary"
        />
      ),
    },
  ];

  return (
    <Box sx={classes.container}>
      <Box sx={classes.convTitile}>
        <Typography>Conversion</Typography>
      </Box>

      <Box sx={classes.convGrid}>
        <Grid container spacing={4}>
          {conversionData.map((conversion, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              display="flex"
              justifyContent="center"
            >
              <Box sx={classes.convCard}>
                <Grid item xs={12} sm={8}>
                  <Box sx={{ mx: "20px" }}>
                    <TooltipMui
                      sx={{ display: ["block", "none"] }}
                      placement="top"
                      title={
                        conversion.title === "CVR"
                          ? "Conversion Rate"
                          : conversion.title === "CPC"
                          ? "Const Per Click"
                          : conversion.title
                      }
                    >
                      <Typography fontSize={["14px", "16px"]}>
                        {conversion.title}
                      </Typography>
                    </TooltipMui>
                    <Typography sx={classes.convSubtitle}>
                      {conversion.subTitle}
                    </Typography>

                    <Typography
                      fontSize={["24px", "24px", "24px", "34px"]}
                      fontWeight="600"
                      color={
                        conversion.title === "CVR"
                          ? hPink[300]
                          : conversion.title === "Clicks"
                          ? hPurple[300]
                          : hPurple[400]
                      }
                    >
                      {new Intl.NumberFormat("jp-JP", {
                        minimumFractionDigits:
                          conversion.title === "CVR" ? 2 : 0,
                      }).format(conversion.total)}
                      {conversion.title === "CVR" ? "%" : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={classes.convCardBoxIcon}>
                  <Box
                    color={
                      conversion.title === "Conversion Rate"
                        ? hPink[300]
                        : conversion.title === "Clicks"
                        ? hPurple[300]
                        : hPurple[400]
                    }
                    sx={classes.convCardIcon}
                  >
                    {conversion.icon}
                  </Box>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={classes.convChart}>
        <Chart
          type="bar"
          data={data}
          options={options}
          plugins={conversionChart.chartAreaBorder}
        />
      </Box>
      <Box>
        <Box sx={classes.chartCheckboxBox}>
          {conversionCheckboxGhraph.map((checkbox, index) => (
            <FormControlLabel
              key={index}
              sx={classes.chartCheckbox}
              control={
                <Checkbox
                  // onClick={(e: any) => checkboxChart(e.target.value)}
                  value={checkbox.value}
                  size="small"
                  color={
                    checkbox.label === "Conversion Rate"
                      ? "secondary"
                      : checkbox.label === "Clicks"
                      ? "primary"
                      : "success"
                  }
                  defaultChecked
                />
              }
              label={checkbox.label}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
