export const useWalletTabStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    "& > div:first-of-type": {
      borderRadius: "0 12px 12px 12px",
    },
    "& > div:last-of-type": {
      borderRadius: "12px",
    },
  },
  card: {
    p: "24px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05)",
    bgcolor:"#fff"
  },

  pagination: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },

  boxNoData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },

  boxDetailNoData: { maxWidth: "400px", textAlign: "center", mt: "15px" },
};

const walletBoxItem = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

export const useTeamWalletStyle = {
  walletBox: {
    borderRadius: " 12px",
    border: "2px solid #8C499C",
    p: "24px",
    height: "180px",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "24px",
  },
  firstWalletBoxItem: {
    ...walletBoxItem,
    "& > button": {
      borderColor: "#8C499C",
      width: "100%",
      fontSize: "16px",
      maxWidth: "260px",
      "&:hover": {
        borderColor: "#8C499C",
      },
    },
  },
  secondWalletBoxItem: {
    ...walletBoxItem,
    "& > button": {
      width: "100%",
      fontSize: "16px",
      maxWidth: "260px",
    },
  },
  thirdWalletBoxItem: {
    ...walletBoxItem,
    bgcolor: "#F5F6F8",
    borderRadius: "12px",
  },
  billingBox: {
    borderRadius: "12px",
    p: "24px",
    bgcolor: "#fff",
    border: "2px solid #E0E0E0",
    mt: "24px",
  },
};

export const useCampaignDetailDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  content: {
    my: "10px",
    py: 3,
    pb: 0,
  },
  cardBox: {
    m: "20px 10px 30px 10px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  cardBoxItem: {
    border: "1px solid #E0E0E0",
    p: "20px",
    borderRadius: "12px",
  },
  imgCover: {
    width: "64px",
    height: "64px",
    borderRadius: "8px",
    mr: "16px",
  },
  detailList: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      fontSize: ["16px", "20px"],
    },
    "& > p": {
      fontSize: ["14px", "16px"],
      ml: "5px",
      color: "#212121",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& > .css-1cfuqz3-MuiAvatar-root": {
      width: "20px",
      height: "20px",
    },
    "& > span": { color: "#505050" },
  },
};

export const useTransactionHistoryDialogStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  title: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    p: "20px",
  },
  depositBox: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    p: "12px",
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
  },
  detailBox: {
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
    my: "24px",
  },
  amountBox: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    py: "12px",
  },
};
