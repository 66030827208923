import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Button,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useMyJobsStyle as classes } from "./MyJobs.style";
// api and type
import { JobAPI } from "@api/global";
import { IInfluencerJobParams, IInfluencerJobList } from "./MyJobs.type";
import { IInfluencerJobQueryParams } from "@components/my-jobs/MyJobs.type";
// component
import MyJobsFilter from "@components/my-jobs/MyJobsFilter";
import MyJobsTabs from "@components/my-jobs/jobs-tabs/JobsTabs";
import CampaignCard from "@components/global/campaign-card/CampaignCard";
// others
import Loading from "@components/global/loading/Loading";
import { scrollToTop } from "@utils/helpers/scrollToTop";
import { useHistory, useLocation } from "react-router-dom";
import PaginationCustom from "@components/global/pagination-custom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function MyJobs() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";

  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id || "";

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const mdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const [rowsPerPageData, setRowsPerPageData] = useState<number[]>([]);
  const [jobList, setJobList] = useState<IInfluencerJobList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(lgScreen ? 16 : mdScreen ? 15 : xsScreen ? 20 : 15);

  const queryParams: IInfluencerJobQueryParams = {
    order_by: orderBy,
    page: page,
    platform: platform,
    type: type,
    sort: sort,
    status: status,
  };

  useEffect(() => {
    setRowsPerPage(lgScreen ? 16 : mdScreen ? 15 : xsScreen ? 20 : 15);
    const screen = xsScreen
      ? [20]
      : mdScreen
      ? [15, 21, 27]
      : lgScreen
      ? [16, 20, 24]
      : [15, 20, 25];
    setRowsPerPageData(screen);
  }, [lgScreen, mdScreen, xsScreen]);

  useEffect(() => {
    let isMounted = true;
    const getInfluencerJobList = () => {
      setIsLoading(true);
      const params: IInfluencerJobParams = {
        order_by: orderBy,
        page: page,
        platform: platform,
        item_per_page: rowsPerPage,
        review_type: type,
        // search: search,
        sort: sort,
        status: status,
      };
      JobAPI.getInfluencerJobList(influencerId, params)
        .then((response) => {
          if (isMounted) {
            setIsLoading(false);
            setJobList(response);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    };
    getInfluencerJobList();
    scrollToTop();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [influencerId, orderBy, page, platform, type, sort, status, rowsPerPage]);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    const param = { ...queryParams };
    param["page"] = pageValue;

    const queryString = Object.keys(param)
      .map((key) => `${key}=${param[key as keyof IInfluencerJobQueryParams]}`)
      .join("&");

    history.replace(`/my-jobs?${queryString}`);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const goInfluencerJobDetail = (jobId: string) => {
    history.push(`/influencers/jobs/${jobId}/info`);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <MyJobsFilter openFilter={openFilter} setOpenFilter={setOpenFilter} />
      <Box sx={classes.headerTabbox}>
        <Box sx={classes.headerTab}>
          <Typography variant="h5" sx={classes.myJobTitle}>
            My Jobs
          </Typography>
          <MyJobsTabs
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            jobList={jobList}
          />
        </Box>
      </Box>
      <Box mx={["12px", 0]}>
        <Grid container spacing={5}>
          {jobList?.item?.length ? (
            jobList.item.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                <Box
                  sx={classes.myjobCard}
                  onClick={() => goInfluencerJobDetail(item.job_id)}
                >
                  <CampaignCard
                    name={item.name}
                    update={item.updated_at}
                    status={item.job_status}
                    platform={item.platform}
                    category={item.category}
                    avgBudget={item.avg_budget}
                    contentType={item.content_type}
                    coverUrl={item.cover_image?.url}
                    companyUrl={item.company?.media?.url}
                    profileName={item.social_account?.name}
                    profile={item.social_account?.profile_image_url}
                  />
                </Box>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              minHeight={{ xs: "65vh", sm: "73vh", xl: "80vh" }}
            >
              <Box sx={classes.noCampaign}>
                {status === "" ? (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
                      alt="no campaign"
                    />
                    <Box sx={classes.noCampDiscBox}>
                      <Typography variant="h5" my="8px">
                        ไม่พบแคมเปญ
                      </Typography>
                      <Typography>
                        คุณยังไม่ได้เข้าร่วมแคมเปญ ไปหน้า “Job Board”
                        เพื่อหาค้นหาและสมัครเข้าร่วมแคมเปญที่คุณสนใจกันเลย
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => history.push("/job-board")}
                      sx={classes.goToJobBoardBtn}
                    >
                      ไปหน้า Job Board
                    </Button>
                  </>
                ) : (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/search-not-found.png")}
                      alt="search not found"
                    />
                    <Box sx={classes.noCampDiscBox}>
                      <Typography variant="h5" my="8px">
                        ไม่พบผลการค้นหา
                      </Typography>
                      <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={classes.paginationCon}>
        {jobList?.item?.length ? (
          <PaginationCustom
            page={page}
            count={page > 0 ? Math.ceil(jobList.total_item / rowsPerPage) : 0}
            onPaginationChange={handleChangePage}
            rowsPerPageData={rowsPerPageData}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Box>
    </div>
  );
}
