export const initCampaignFilter = {
  page: 1,
  item_per_page: 12,
  campaign_type: "",
  platform: "",
  review_type: "",
  category: [],
  sort: "DESC",
  order_by: "date",
  total_item: 0,
};

export const sortDateBudgetList = {
  date: [
    {
      value: "dateDESC",
      label: "ใหม่ - เก่า",
      sort: "DESC",
    },
    {
      value: "dateASC",
      label: "เก่า - ใหม่",
      sort: "ASC",
    },
  ],
  budget: [
    {
      value: "budgetDESC",
      label: "ค่าตอบแทน มาก - น้อย",
      sort: "DESC",
    },
    {
      value: "budgetASC",
      label: "ค่าตอบแทน น้อย - มาก",
      sort: "ASC",
    },
  ],
};

export const campaignTypeList = [
  {
    key: "",
    label: "All",
  },
  {
    key: "public",
    label: "ประกาศรับสมัคร",
  },
  {
    key: "private",
    label: "ยื่นข้อเสนอให้",
  },
];

export const reviewTypeList = [
  {
    key: "",
    label: "All",
  },
  {
    key: "product",
    label: "Product (สินค้า)",
  },
  {
    key: "place",
    label: "Place (สถานที่)",
  },
];

export const initFilterTotal = {
  status: "ทั้งหมด",
  briefing: 0,
  influencer_listing: 0,
  published: 0,
  reported: 0,
  completed: 0,
  canceled: 0,
  total: 0,
};

export const filterStatusList = [
  {
    label: "All",
    value: "",
  },
  {
    label: "ร่างแคมเปญ",
    value: "briefing",
  },
  {
    label: "รับสมัคร",
    value: "influencer_listing",
  },
  {
    label: "กำลังดำเนินการ",
    value: "published",
  },
  {
    label: "รายงาน",
    value: "reported",
  },
  {
    label: "เสร็จสิ้น",
    value: "completed",
  },
  {
    label: "ยกเลิก",
    value: "canceled",
  },
];
