// mui and style
import { Avatar, Box, Divider, Typography } from "@mui/material";
import { useAdditionalCommentStyle as classes } from "../../EmployerJob.style";
// others
import { IAdditionalCommentProps } from "@components/employer-job/EmployerJob.type";
import TextFieldCustom from "@global/text-field";
import Linkify from "react-linkify";
import nl2br from "react-nl2br";

export default function AdditionalComment(props: IAdditionalCommentProps) {
  const { register, isJobRejected, comment, orgUrl } = props;
  return (
    <Box sx={classes.datePostBox}>
      <div>
        <Typography variant="h6">Message to Influencer</Typography>
        <Typography variant="subTitle1" color="#505050">
          ข้อความถึงอินฟลูเอนเซอร์
        </Typography>
      </div>
      <Divider sx={classes.divider} />
      {isJobRejected ? (
        <Box display="flex">
          <Box mr="16px">
            <Avatar src={orgUrl} sx={{ width: "48px", height: "48px" }} />
          </Box>
          <Box sx={classes.comment}>
            <Linkify>
              <Typography>{nl2br(comment)}</Typography>
            </Linkify>
          </Box>
        </Box>
      ) : (
        <Box display="flex">
          <Box mr="16px">
            <Avatar src={orgUrl} sx={{ width: "48px", height: "48px" }} />
          </Box>
          <TextFieldCustom
            variant="outlined"
            multiline
            minRows={4}
            placeholder="โปรดระบุความคิดเห็น..."
            fullWidth
            inputProps={{
              ...register(`comment`),
            }}
          />
        </Box>
      )}
    </Box>
  );
}
