import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { InfluencerAPI } from "@api/global";
import { IInfluencerProfileState } from "./type";
import { IUpdateInfluencerProfileBody, IShippingAddressBody } from "@api/influencer/type";
// util
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

const initialState: IInfluencerProfileState = {
  data: null,
  loading: false,
  registerStatus: true,
};

export const getInfluencerProfileAsync = createAsyncThunk(
  "influencerProfile",
  async (params:{ influencerId?: string }) => {
    try {
      const { session_token } = getTokenFromStorage();
      const response = await InfluencerAPI.getInfluencerProfile(params.influencerId || session_token?.influencer_id || "");
      return response;
    } catch (err) {
      console.log("catch", err);
      throw err;
    }
  }
);
export const updateInfluencerProfileAsync = createAsyncThunk(
  "updateInfluencerProfile",
  async (body: IUpdateInfluencerProfileBody) => {
    try {
      const response = await InfluencerAPI.updateInfluencerProfile(body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const updateInfluencerShippingAsync = createAsyncThunk(
  "updateInfluencerShipping",
  async (body: IShippingAddressBody) => {
    try {
      const response = await InfluencerAPI.updateInfluencerShipping(body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const updateInfluencerBillingAsync = createAsyncThunk(
  "updateInfluencerBilling",
  async (body: any) => {
    try {
      const response = await InfluencerAPI.updateInfluencerBilling(body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const updateInfluencerBankAccountAsync = createAsyncThunk(
  "updateInfluencerBankAccount",
  async (body: any) => {
    try {
      const response = await InfluencerAPI.updateInfluencerBankAccount(body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const updateInfluencerPortfolioAsync = createAsyncThunk(
  "updateInfluencerPorfolio",
  async (body: any) => {
    try {
      const response = await InfluencerAPI.updateInfluencerPortfolio(body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const deleteInfluencerPortfolioAsync = createAsyncThunk(
  "deleteInfluencerPorfolio",
  async (params: { port_id:string }) => {
    try {
      const response = await InfluencerAPI.deleteInfluencerPortfolio(params);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const InfluencerProfileSlice = createSlice({
  name: "influencerProfile",
  initialState,
  reducers: {
    setInfluencerProfile: (state, { payload }) => {
      state.data = payload;
      state.registerStatus = true
    },
    setDeleteInfluencerPortfolio: (state, { payload }) => {
      if (state.data) {
        state.data.portfolio = payload;
      }
    },
    setInfluencerRegisterStatus: (state, { payload }) => {
      state.registerStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Profile
      .addCase(getInfluencerProfileAsync.pending, (state) => {
        state.loading = true;
        state.registerStatus = true;
      })
      .addCase(getInfluencerProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.registerStatus = action.payload.referer.status.register;
      })
      .addCase(getInfluencerProfileAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
        state.registerStatus = true;
      })
      // Update Profile
      .addCase(updateInfluencerProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfluencerProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateInfluencerProfileAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
      })
      // Update Shipping
      .addCase(updateInfluencerShippingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfluencerShippingAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateInfluencerShippingAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
      })
      // Update Billing
      .addCase(updateInfluencerBillingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfluencerBillingAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateInfluencerBillingAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
      })
      // Update Bank Account
      .addCase(updateInfluencerBankAccountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfluencerBankAccountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateInfluencerBankAccountAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
      })
      // Update Portfolio
      .addCase(updateInfluencerPortfolioAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfluencerPortfolioAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateInfluencerPortfolioAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
      })
      // delete Portfolio
      .addCase(deleteInfluencerPortfolioAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteInfluencerPortfolioAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (state.data) {
          state.data.portfolio = action.payload;
        }
      })
      .addCase(deleteInfluencerPortfolioAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setInfluencerProfile, setDeleteInfluencerPortfolio, setInfluencerRegisterStatus } = InfluencerProfileSlice.actions;

export const selectInfluencerProfile = (state: RootState) => state.influencerProfile.data
export const selectInfluencerProfileOnload = (state: RootState) => state.influencerProfile.loading;
export const selectInfluencerRegisterStatus = (state: RootState) => state.influencerProfile.registerStatus;

export default InfluencerProfileSlice.reducer;
