export const useEmployerProfileStyle = {
  card: {
    bgcolor: "#fff",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px 0px #3535350D",
    border: "1px solid #fff",
    width:"100%",
    height:"100%"
  },
  cardHeader: {
    bgcolor: "primary.main",
    textAlign: "center",
    height: "42px",
    padding: "0",
    fontWeight: "bold",
    borderRadius: "16px 16px 0px 0px",
  },

  avatar: { width: "200px", height: "200px", borderRadius: "10px" },

  divider: { margin: "10px 0" },

  boxCardAction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    my:"15px",
  },

  boxAddress: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },

  iconButton: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
};
