import { useContext } from "react";
// mui
import { Box, Typography } from "@mui/material";
// component
import LogsTab from "./logs-tab/LogsTab";
import HeaderMember from "./HeaderMember";
import WalletTab from "./wallet-tab/WalletTab";
import MembersTab from "./members-tab/MembersTab";
import SettingTab from "./setting-tab/SettingTab";
// context and style
import TeamContext from "@contexts/Team";
import { useTeamManagementStyle as classes } from "../TeamManagement.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// other
import { useHistory, useLocation } from "react-router-dom";

export default function Member() {
  const teamContext = useContext(TeamContext);
  
  const employerInfo = useAppSelector(selectEmployerInfo)
  const teamRole = employerInfo?.team_role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "members";

  const handleTabChange = (tab: string) => {
    history.replace(`/team?tab=${tab}`);
  };

  return (
    <div>
      <HeaderMember teamInfo={teamContext?.teamInfo} />
      <Box mt="24px" sx={classes.tabBox}>
        {[
          {
            title: "members",
            display: true
          },
          {
            title: "wallet",
            display: teamRole?.permission !== "campaign"
          },
          {
            title: "logs",
            display: true
          },
          {
            title: "setting",
            display: teamRole?.role === "teamlead"
          },
        ].map((tab, index) => {
          return (
            <Box
              onClick={() => tab.display && handleTabChange(tab.title)}
              key={index}
              sx={
                tab.display 
                ? tabSelected === tab.title ? classes.tabSelected : classes.tab
                : classes.tabDisabled
              }
            >
              <Typography
                textTransform="capitalize"
                fontWeight={tabSelected === tab.title ? 500 : 400}
              >
                {tab.title}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {tabSelected === "members" ? (
        <MembersTab />
      ) : tabSelected === "wallet" ? (
        <WalletTab />
      ) : tabSelected === "logs" ? (
        <LogsTab />
      ) : tabSelected === "setting" ? (
        <SettingTab />
      ) : null}
    </div>
  );
}
