// mui
import { Box, Grid, Typography } from "@mui/material";
// component
import StartTab from "./StartTab";
import Faq from "../home-pricing/Faq";
// util
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";

export default function TabMenu() {
  const bp = useBreakpoint();

  const tabMenu = [
    { title: "เริ่มต้นการใช้งาน", icon: "p-boosts" },
    { title: "การเชื่อมต่อ Social Media", icon: "conversion" },
    { title: "การจัดการบัญชี", icon: "person" },
  ];

  return (
    <Box my={35}>
      <div>
        <Typography
          variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
          sx={{ marginBottom: "24px" }}
        >
          Influencer
        </Typography>
        <Grid container spacing={5}>
          {tabMenu.map((item, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <StartTab item={item} index={index} />
            </Grid>
          ))}
        </Grid>
      </div>
      <Box mt="60px">
        <Typography
          variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
          mb="24px"
        >
          FAQ
        </Typography>
        <Faq />
      </Box>
    </Box>
  );
}
