import { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  Badge,
  Stack,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
// icon
import StarIcon from "@mui/icons-material/Star";
import CircleIcon from "@mui/icons-material/Circle";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import TransgenderOutlinedIcon from "@mui/icons-material/TransgenderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// styles
import {
  SmallAvatar,
  useInfluencerSocialHeaderStyle as classes,
} from "../InfluencerSocial.style";
// asset
import tiktokLogo from "@assets/images/social/tiktok-only.png";
import twitterLogo from "@assets/images/social/twitter_5969020.png";
import instagramLogo from "@assets/images/social/instagram-only.png";
import youtubeLogo from "@assets/images/social/youtube-only.png";
// theme and type
import { hAlertColor } from "@theme/index";
import { TPlatform } from "@components/influencer-social/InfluencerSocial.type";
// router-dom and util
import { useHistory, useLocation, useParams } from "react-router-dom";
// redux
import {
  setCheckSelectedPrivate,
  selecteCheckSelectPrivate,
} from "@slices/CampaignPrivate.slice";
import {
  selectCheckSelected,
  updateCheckSelected,
} from "@slices/InfluencerActiveList.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// other
import Loading from "@components/global/loading/Loading";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";

export default function InfluencerSocialHeader({
  status,
}: {
  status?: string;
}) {
  const [checkLimit, setCheckLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { influencerId } = useParams<{ influencerId: string }>();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const viewStatus = params.get("status") || status;
  const jobId = params.get("job");
  const limit = params.get("limit");
  const campaignType = params.get("c_type");
  const platform = params.get("platform") || "";

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const socialInfo = useAppSelector(selectInfluencerSocial);

  const platformData = (platform: TPlatform | string) => {
    const platformMapping: Record<string, string> = {
      tiktok: "tiktok",
      facebook: "facebook",
      instagram: "instagram",
      twitter: "twitter",
      youtube: "youtube",
    };

    const platformKey = platformMapping[platform];
    return socialInfo?.[platformKey]?.[0];
  };
  const socialAccount = platformData(platform)?.social_account[0];

  const dispatch = useAppDispatch();
  const checkSelected = useAppSelector(selectCheckSelected);
  const PrivateCheckSelected = useAppSelector(selecteCheckSelectPrivate);

  const testtest = checkSelected.item?.find((find) => {
    return find.jobId === jobId;
  });

  const privateInflu = PrivateCheckSelected.item?.find(
    (find) => find.influId === influencerId
  );

  useEffect(() => {
    let checkLimit = 0;

    checkSelected.item.forEach((item) => {
      if (item.isSelected) {
        checkLimit = checkLimit + 1;
      }
    });
    setCheckLimit(checkLimit >= Number(limit) && !testtest?.isSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectInflue = () => {
    setIsLoading(true);
    dispatch(
      updateCheckSelected({
        ...checkSelected,
        item: checkSelected.item.map((item) =>
          item.jobId === jobId ? { ...item, isSelected: true } : item
        ),
      })
    );
    setIsLoading(false);
    SwalCustom.fire({
      icon: "success",
      title: "เลือกอินฟลูเอนเซอร์สำเร็จ",
      text: `คุณได้เพิ่ม "${socialAccount?.name}" ในรายชื่อที่ผ่านการคัดเลือก`,
      confirmButtonColor: hAlertColor.success,
      confirmButtonText: "กลับไปหน้า `Influencer List`",
    }).then(() => history.goBack());
  };

  const cancelSelect = () => {
    setIsLoading(true);
    SwalCustom.fire({
      icon: "question",
      title: "ต้องการลบอินฟลูเอนเซอร์นี้ใช่หรือไม่ ?",
      text: `ลบ "${socialAccount?.name}" ออกจากรายชื่อที่ผ่านการคัดเลือก`,
      confirmButtonColor: "#8C499C",
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ยืนยัน",
    }).then(async (response) => {
      if (response.isConfirmed) {
        dispatch(
          updateCheckSelected({
            ...checkSelected,
            item: checkSelected.item.map((item) =>
              item.jobId === jobId ? { ...item, isSelected: false } : item
            ),
          })
        );
        SwalCustom.fire({
          icon: "success",
          title: "ลบอินฟลูเอนเซอร์สำเร็จ",
          text: `คุณได้ลบ "${socialAccount?.name}" ในรายชื่อที่ผ่านการคัดเลือก`,
          confirmButtonColor: hAlertColor.success,
          confirmButtonText: "กลับไปหน้า `Influencer List`",
        }).then(() => history.goBack());
      }
    });
    setIsLoading(false);
  };

  const privateSelect = () => {
    setIsLoading(true);
    dispatch(
      setCheckSelectedPrivate({
        ...PrivateCheckSelected,
        item: PrivateCheckSelected.item.map((item) =>
          item.influId === influencerId ? { ...item, status: "stealth" } : item
        ),
      })
    );
    SwalCustom.fire({
      icon: "success",
      title: "เลือกอินฟลูเอนเซอร์สำเร็จ",
      text: `คุณได้เพิ่ม "${socialAccount?.name}" ในรายชื่อที่ผ่านการคัดเลือก`,
      confirmButtonColor: hAlertColor.success,
      confirmButtonText: "กลับไปหน้า `Influencer List`",
    }).then(() => history.goBack());
    setIsLoading(false);
  };

  const PrivateCancelSelect = () => {
    setIsLoading(true);
    SwalCustom.fire({
      icon: "question",
      title: "ต้องการลบอินฟลูเอนเซอร์นี้ใช่หรือไม่ ?",
      text: `ลบ "${socialAccount?.name}" ออกจากรายชื่อที่ผ่านการคัดเลือก`,
      confirmButtonColor: "#8C499C",
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ยืนยัน",
    }).then(async (response) => {
      if (response.isConfirmed) {
        dispatch(
          setCheckSelectedPrivate({
            ...PrivateCheckSelected,
            item: PrivateCheckSelected.item.map((item) =>
              item.influId === influencerId ? { ...item, status: "" } : item
            ),
          })
        );
        SwalCustom.fire({
          icon: "success",
          title: "ลบอินฟลูเอนเซอร์สำเร็จ",
          text: `คุณได้ลบ "${socialAccount?.name}" ในรายชื่อที่ผ่านการคัดเลือก`,
          confirmButtonColor: hAlertColor.success,
          confirmButtonText: "กลับไปหน้า `Influencer List`",
        }).then(() => history.goBack());
      }
    });
    setIsLoading(false);
  };

  const privateSelectMore = () => {
    setIsLoading(false);
    dispatch(
      setCheckSelectedPrivate({
        ...PrivateCheckSelected,
        item: PrivateCheckSelected.item.map((item) =>
          item.influId === influencerId
            ? { ...item, status: "selecting_more" }
            : item
        ),
      })
    );
    SwalCustom.fire({
      icon: "success",
      title: "เลือกอินฟลูเอนเซอร์สำเร็จ",
      text: `คุณได้เพิ่ม "${socialAccount?.name}" ในรายชื่อที่ผ่านการคัดเลือก`,
      confirmButtonColor: hAlertColor.success,
      confirmButtonText: "กลับไปหน้า `Influencer List`",
    }).then(() => {
      history.goBack();
    });
    setIsLoading(false);
  };

  if (isLoading) return <Loading />

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        sx={{
          ...classes.containerMain,
          flexDirection: ["column", "row"],
          padding: "24px",
        }}
      >
        <Box sx={classes.imageWrap}>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={
              platform === "facebook" ? (
                <SmallAvatar sx={{ bgcolor: "#1A77F1" }}>
                  <AssistantPhotoIcon sx={classes.iconAvatar} />
                </SmallAvatar>
              ) : (
                <SmallAvatar
                  alt="instagram icon"
                  src={
                    platform === "tiktok"
                      ? tiktokLogo
                      : platform === "twitter"
                      ? twitterLogo
                      : platform === "instagram"
                      ? instagramLogo
                      : platform === "youtube"
                      ? youtubeLogo
                      : ""
                  }
                />
              )
            }
            sx={classes.badge}
          >
            <Avatar
              alt="profile-social-images"
              src={socialAccount?.profile_image}
              variant="rounded"
              sx={classes.avatar}
            />
          </Badge>
        </Box>

        <Box sx={{ ml: ["0", "24px"], width: "100%" }}>
          <Box
            sx={{
              ...classes.nameWrap,
              justifyContent: ["center", "flex-start"],
            }}
          >
            <Typography variant="h6">{socialAccount?.name}</Typography>
            {socialAccount?.recommended && (
              <StarIcon fontSize="inherit" htmlColor="#FFD54F" sx={{ ml: 1 }} />
            )}
          </Box>

          <Stack
            direction={["column", "row"]}
            divider={<CircleIcon />}
            sx={classes.stackDetail}
          >
            <Box sx={classes.stackDetailWrap}>
              <AccountCircleOutlinedIcon sx={classes.iconInStack} />
              <Typography fontSize="14px">
                {numberFormat.format(socialAccount?.follower || 0)}
              </Typography>
              <Typography fontSize="14px" color="grey.700">
                Followers
              </Typography>
            </Box>
            <Box sx={classes.stackDetailWrap}>
              <PeopleOutlinedIcon sx={classes.iconInStack} />
              <Typography fontSize="14px" color="grey.700">
                Age :
              </Typography>
              <Typography fontSize="14px" ml="6px">
                {socialAccount?.target_age?.min} -{" "}
                {socialAccount?.target_age?.max}
              </Typography>
            </Box>
            <Box sx={classes.stackDetailWrap}>
              <TransgenderOutlinedIcon sx={classes.iconInStack} />
              <Typography fontSize="14px" color="grey.700">
                Gender :
              </Typography>
              {socialAccount?.target_gender.length === 3 ? (
                <Typography fontSize="14px" ml="6px">
                  All
                </Typography>
              ) : (
                socialAccount?.target_gender.map((gender, index) => (
                  <Typography
                    key={index}
                    fontSize="14px"
                    ml="6px"
                    textTransform="capitalize"
                  >
                    {gender}
                    {index !== 2 && ","}
                  </Typography>
                ))
              )}
            </Box>
          </Stack>

          {/* Category */}
          <Box display="flex" gap={2} sx={classes.categoryWrap}>
            <Box
              gap={3}
              width="100%"
              display="flex"
              flexWrap="wrap"
              justifyContent={["center", "flex-start"]}
            >
              {socialAccount?.category.map((cate, index) => (
                <Box key={index} sx={classes.itemsCategory}>
                  {cate.name_en}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {viewStatus === "view" ? null : campaignType === "private" ? (
        privateInflu?.status === "" ? (
          <Box sx={classes.buttonWrap}>
            <Button
              onClick={privateSelect}
              sx={classes.selecteInfluencer}
              fullWidth
            >
              <Icon className="ico-hu-add-circle" sx={{ marginRight: "4px" }} />
              เลือกอินฟลูเอนเซอร์
            </Button>
          </Box>
        ) : (
          <Box sx={classes.buttonWrap}>
            <Button
              onClick={PrivateCancelSelect}
              sx={classes.cancelInfluencer}
              fullWidth
            >
              <Icon
                className="ico-hu-remove-circle"
                sx={{ marginRight: "4px" }}
              />
              ไม่เลือกอินฟลูเอนเซอร์
            </Button>
          </Box>
        )
      ) : campaignType === "selecting-more" ? (
        privateInflu?.status === "" ? (
          <Box sx={classes.buttonWrap}>
            <Button
              onClick={privateSelectMore}
              sx={classes.selecteInfluencer}
              fullWidth
            >
              <Icon className="ico-hu-add-circle" sx={{ marginRight: "4px" }} />
              เลือกอินฟลูเอนเซอร์
            </Button>
          </Box>
        ) : (
          <Box sx={classes.buttonWrap}>
            <Button
              onClick={PrivateCancelSelect}
              sx={classes.cancelInfluencer}
              fullWidth
            >
              <Icon
                className="ico-hu-remove-circle"
                sx={{ marginRight: "4px" }}
              />
              ไม่เลือกอินฟลูเอนเซอร์
            </Button>
          </Box>
        )
      ) : checkLimit ? (
        <Box sx={classes.buttonWrap}>
          <Button fullWidth disabled>
            <Icon className="ico-hu-add-circle" sx={{ marginRight: "4px" }} />
            เลือกอินฟลูเอนเซอร์
          </Button>
        </Box>
      ) : testtest?.isSelected ? (
        <Box sx={classes.buttonWrap}>
          <Button
            onClick={cancelSelect}
            sx={classes.cancelInfluencer}
            fullWidth
          >
            <Icon
              className="ico-hu-remove-circle"
              sx={{ marginRight: "4px" }}
            />
            ไม่เลือกอินฟลูเอนเซอร์
          </Button>
        </Box>
      ) : (
        <Box sx={classes.buttonWrap}>
          <Button
            onClick={selectInflue}
            sx={classes.selecteInfluencer}
            fullWidth
          >
            <Icon className="ico-hu-add-circle" sx={{ marginRight: "4px" }} />
            เลือกอินฟลูเอนเซอร์
          </Button>
        </Box>
      )}
    </Box>
  );
}
