export const useViewInfluencerProfileStyle = {
  callback: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: {
      xs: "10%",
      md: "4%",
    },
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
    "& > span": {
      display: {
        xs: "none",
        md: "initial",
      },
    },
    "& > svg": {
      bgcolor: {
        xs: "#fff",
        md: "initial",
      },
      borderRadius: "50%",
      fontSize: {
        xs: "30px",
        md: "16px",
      },
    },
  },
}