import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { JobAPI } from "@api/global";
import { IEmployerJobInfoState } from "./type";

const initialState: IEmployerJobInfoState = {
  data: null,
  loading: false,
  error: "",
};

export const getEmployerJobInfoAsync = createAsyncThunk(
  "employerJobInfoAsync",
  async (req:{ employer_id:string, job_id:string }) => {
    try {
      const response = await JobAPI.getEmployerJobInfo(req.employer_id, req.job_id);
      return response;
    }
    catch (err){
      throw(err)
    }
  }
)

export const EmployerJobInfoSlice = createSlice({
  name: "employerJobInfoSlice",
  initialState,
  reducers: {
    setEmployerJobInfo: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerJobInfoAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getEmployerJobInfoAsync.fulfilled, (state, action) => {
        const data = action.payload;
        state.data = data
        state.loading = false;
        state.error = "";
      })
      .addCase(getEmployerJobInfoAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
}); 

export const { setEmployerJobInfo } = EmployerJobInfoSlice.actions

export const selectEmployerJobInfo = (state: RootState) => state.employerJobInfo.data;
export const selectEmployerJobInfoOnload = (state: RootState) => state.employerJobInfo.loading;

export default EmployerJobInfoSlice.reducer;