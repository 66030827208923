// mui and style
import { Grid, Typography } from "@mui/material";
// react-hook
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
// util
import { formatTaxId } from "@utils/helpers/formateNumber.helpers";

export default function BillingAddress() {
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const billingInfo = influencerInfo?.address.billing_address;

  return (
    <Grid container spacing={4} p="24px">
      <Grid item xs={4}>
        <Typography variant="title1">ประเภทบุคคล</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>บุคคลธรรมดา</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">ชื่อ</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{billingInfo?.fullname || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">เลขประจำตัวผู้เสียภาษี</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {billingInfo?.tax_id ? formatTaxId(billingInfo?.tax_id || "") : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">ที่อยู่</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {billingInfo?.province
            ? `${billingInfo?.address} ${
                billingInfo?.subdistrict?.name_th || ""
              } ${billingInfo?.district?.name_th || ""} ${
                billingInfo?.province?.name_th || ""
              } ${billingInfo?.postcode}`
            : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
}
