import { useMyJobsTabStyle } from "../../JobsTab.style";
import { useConfirmGoodsReceivedStyle } from "../job-working/JobWorking.style";

export const useJobRejectedStyle = {
  jobTabsCon: useMyJobsTabStyle.jobTabsCon,
  jobTitle: useMyJobsTabStyle.jobTitle,
  shippingProBtn: useMyJobsTabStyle.shippingProBtn,
  divider: {
    my: "24px",
    display: {
      xs: "none",
      sm: "flex",
    },
  },
  submitBtn: {
    justifyContent: "center",
    "& button": {
      maxHeight: "40px",
      width: "240px",
    },
    "& button:first-of-type": {
      border: "2px solid #8C499C",
      bgcolor: "#F4ECFF",
      color: "primary.main",
    },
    "& button:last-of-type": {
      border: "none",
    },
  },
  editSubmitBtn: {
    justifyContent: "center",
    "& button": {
      maxHeight: "40px",
      width: "240px",
    },
    "& button:first-of-type": {
      border: "2px solid #8C499C",
      bgcolor: "#fff",
      color: "primary.main",
    },
    "& button:last-of-type": {
      border: "none",
    },
  },
  draftBtn: {
    justifyContent: "center",
    "& button": {
      maxHeight: "40px",
      width: "240px",
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
    "& button:first-of-type": {
      bgcolor: "#F4ECFF",
      color: "#893DBD",
    },
  },
};

export const useImagesStyle = {
  imageCon: {
    border: {
      sm: "2px solid #EBDFFF",
    },
    borderRadius: "12px",
    bgcolor: "#fff",
    p: {
      xs: "24px 0 0 24px",
      sm: 0,
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  title: useConfirmGoodsReceivedStyle.title,
  imgItem: {
    width: {
      xs: "47.5%",
      sm: "120px",
    },
    height: {
      xs: "150px",
      sm: "120px",
    },
    borderRadius: "4px",
    cursor: "pointer",
    mt: 2,
  },
  imgItemEditing: {
    width: {
      xs: "100%",
      sm: "220px",
      md: "240px",
    },
    maxWidth: "350px",
    height: {
      xs: "300px",
      sm: "220px",
      md: "240px",
    },
    borderRadius: "4px",
    "& video": {
      maxWidth: {
        xs: "100%",
        sm: "220px",
        md: "240px",
      },
      maxHeight: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
      objectFit: "cover",
    },
    overflow: "hidden",
  },
  multiImgInputBox: {
    border: "2px dashed #E0E0E0",
    bgcolor: "rgba(0, 0, 0, 0.03)",
    borderRadius: "8px",
    p: "16px",
  },
  editViewBtn: {
    color: "#8C499C",
    bgcolor: "#fff",
  },
  titleImgItem: {
    display: "flex",
    color: "red",
    m: {
      xs: "15px 0 15px 0",
      sm: "15px",
    },
    "& > p": { ml: "5px" },
  },
  draftBox: {
    columnGap: "10px",
    rowGap: "10px",
    flexWrap: "wrap",
    ml: { xs: 0, sm: "20px" },
    mt: "20px",
    width: "100%",
  },
  draftVideo: {
    width: { xs: "150px", sm: "120px" },
    height: { xs: "150px", sm: "120px" },
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    "& video": {
      objectFit: "cover",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    maxWith: "350px",
    maxHeight: "240px",
  },
  isDraftBox: {
    columnGap: "20px",
    rowGap: "20px",
    flexWrap: "wrap",
    width: "100%",
    mt: "12px",
  },
  attIsActiveBox: {
    position: "relative",
    "& > button": {
      bgcolor: "#4CAF50",
      color: "#fff",
      "&:hover": {
        bgcolor: "#4CAF50",
      },
    },
  },
  attApprovedIcon: {
    top: 0,
    right: "-6px",
    maxWidth: "20px",
    minWidth: "20px",
    maxHeight: "20px",
    minHeight: "20px",
    position: "absolute",
  },
  imgItemEditingGrid: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: {
      xs: "100%",
      sm: "220px",
      md: "240px",
    },
    "& .viewImage": {
      display: "none",
    },
    maxWidth: "300px",
    "&:hover .viewImage": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      width: {
        xs: "100%",
        sm: "220px",
        md: "240px",
      },
      maxWidth: "350px",
      height: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
    },
    "&:hover .viewBgImage": {
      position: "absolute",
      top: 0,
      width: "100%",
      maxWidth: "350px",
      height: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
      bgcolor: "#000",
      borderRadius: "4px",
      opacity: 0.3,
    },
  },
  urlBox: {
    height: "120px",
    width: "120px",
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "40px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgItemEditingDesc: {
    bgcolor: "#FDF3F3",
    width: "90%",
    maxWidth: "330px",
    borderRadius: "8px",
    mt: "16px",
    padding: "12px",
    "& p:first-of-type": {
      color: "red",
      fontWeight: "500",
      fontSize: "14px",
      mb: "12px",
    },
  },
  imgItemApprove: {
    display: "flex",
    color: "#4CAF50",
    mb: "24px",
    "& > p": { fontWeight: "500", ml: "5px" },
  },
};

export const useCaptionStyle = {
  captionCon: useConfirmGoodsReceivedStyle.confirmCon,
  title: useConfirmGoodsReceivedStyle.title,
  captionEditingDesc: {
    bgcolor: "#FDF3F3",
    borderRadius: "8px",
    mt: "16px",
    padding: "12px",
    "& p:first-of-type": {
      color: "red",
      fontWeight: "500",
      fontSize: "14px",
      mb: "12px",
    },
  },
};
