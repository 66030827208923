// mui and style
import {
  Box,
  Stack,
  Dialog,
  Avatar,
  Button,
  Typography,
  DialogContent,
} from "@mui/material";
import { useTimelineOverdueStyle as classes } from "../CampaignBrief.style";
// type
import { ITimelineOverdueDialogProps } from "../CampaignBrief.type";

const initTimeline = {
  draft_phase: [],
  start_recruiting_date: "",
  end_recruiting_date: "",
  announce_date: "",
  start_publish_date: "",
  end_publish_date: "",
  completed_date: "",
  posting_period: null,
  shipping_date: "",
  end_shipping_date: "",
};

export default function TimelineOverdueDialog(
  props: ITimelineOverdueDialogProps
) {
  const { open, onClose, setValue } = props;

  const handleEditTimeline = () => {
    setValue("timeline", initTimeline);
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={classes.dialog}
    >
      <DialogContent sx={classes.contentDialog}>
        <Box sx={classes.discBox}>
          <Avatar
            src={require("@assets/images/infographic/influencer/job-board/no-campaign.png")}
            sx={classes.img}
          />
          <Box textAlign="center" my="32px">
            <Typography variant="h5">Timeline ได้เลยกำหนดเวลา</Typography>
            <Typography>กดปุ่มด้านล่างเพื่อแก้ไข Timeline ของแคมเปญ</Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={3} sx={classes.btnBox}>
          <Button variant="contained" onClick={handleEditTimeline}>
            Edit Timeline
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
