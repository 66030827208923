
// mui
import { Avatar, Box, Button, Icon, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
// style and image
import { useSyncTiktokStyle as classes } from "../InfluencerSocial.style";
import tiktokLogo from "@assets/images/social/tiktok-only.png";
// util
import { SOCIAL_URL } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function SyncTiktok() {
  const onLoginTitok = () => {
    const { access_token } = getTokenFromStorage();
    window.location.replace(
      `${SOCIAL_URL}/tiktok/login?token=${access_token}`
    );
  };

  return (
    <Stack sx={classes.container}>
      <Box sx={classes.syncSocial} py={3}>
        <Avatar
          alt="platform-Logo"
          src={tiktokLogo}
          sx={classes.platformLogo}
        />
      </Box>
      <Box textAlign="center" width="40%" pt={4}>
        <Typography variant="h5" color={grey[700]}>
          Connect with Tiktok
        </Typography>
      </Box>
      <Box textAlign="center" py={5} mx="10px">
        <Typography variant="body1">
          Amplify your chance to reach out to more brands and followers
        </Typography>
        <Typography variant="body1">
          Simply add more of your social channels below.
        </Typography>
      </Box>
      <Box py={5}>
        <Button sx={classes.buttonSyncSocial} onClick={onLoginTitok}>
          <Icon
            className="ico-hu-tiktok"
            sx={{ mr: ["-32px", "-52px"] }}
          />
          <Typography variant="buttonM">Continue with Tiktok</Typography>
        </Button>
      </Box>
    </Stack>
  );
}
