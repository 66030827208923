// mui and style
import { Box, Icon, Stack, Divider, Typography } from "@mui/material";
import { useInfluencerPortfolioStyle as classes } from "../InfluencerProfile.style";
//  type
import { IInfluencerPortfolio } from "@api/influencer/type";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
// other
import nl2br from "react-nl2br";

export default function InfluencerPortfolio() {
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const portfolioInfo = influencerInfo?.portfolio;
  return (
    <Box p="20px">
      <Box display="flex" justifyContent="space-between">
        <Box sx={classes.portfolioIconBox}>
          <Icon
            className="ico-hu-my-portfolio"
            color="primary"
            sx={{ fontSize: "18px" }}
          />
          <Typography variant="title1" ml={1}>
            My Portfolio
          </Typography>
        </Box>
      </Box>
      {(portfolioInfo || []).length === 0 ? (
        <Typography variant="body1" mt="10px" color={"text.secondary"}>
          ไม่มี portfolio
        </Typography>
      ) : (
        portfolioInfo.map((port: IInfluencerPortfolio, index: number) => (
          <Box key={index} my={2}>
            <Box display="flex" justifyContent="space-between">
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => window.open(port.url)}
                width={{ xs: "80%", sm: "100%", md: "80%", lg: "100%" }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={classes.profileTitle}
                >
                  {port.title}
                </Typography>
                <Icon
                  className="ico-hu-open"
                  color="primary"
                  sx={{ fontSize: "15px" }}
                />
              </Stack>
            </Box>
            <Typography
              color="rgba(0, 0, 0, 0.54)"
              sx={classes.profileUrl}
              onClick={() => window.open(port.url)}
            >
              {port.url}
            </Typography>
            <Typography mb={2} sx={classes.prodileDesc}>
              {nl2br(port.description)}
            </Typography>
            {index === portfolioInfo.length - 1 ? null : <Divider />}
          </Box>
        ))
      )}
    </Box>
  );
}
