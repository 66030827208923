import { useMyJobsTabStyle } from "../../JobsTab.style";
import { useConfirmGoodsReceivedStyle } from "../job-working/JobWorking.style";

export const useJobOthersImagesStyle = {
  imageCon: useConfirmGoodsReceivedStyle.confirmCon,
  title: useConfirmGoodsReceivedStyle.title,
  imgItem: {
    width: {
      xs: "100%",
      sm: "120px",
    },
    height: {
      xs: "150px",
      sm: "120px",
    },
    borderRadius: "4px",
    cursor: "pointer",
  },
  draftBox: {
    columnGap: "10px",
    rowGap: "10px",
    flexWrap: "wrap",
    m: {
      xs: "20px 0 24px 0",
      sm: "20px",
    },
  },
  isDraftBox: {
    columnGap: "10px",
    rowGap: "10px",
    flexWrap: "wrap",
    width: "100%",
    mt: "12px",
  },
  urlBox: {
    height: "120px",
    width: "120px",
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "40px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  draftVideo: {
    width: "120px",
    height: "120px",
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    "& > .css-1637l5p": {
      borderRadius: "0px",
    },
    "& video": {
      objectFit: "cover",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: 0,
    maxWith: "350px",
  },
};

export const useJobOthersStyle = {
  jobTabsCon: useMyJobsTabStyle.jobTabsCon,
  jobTitle: useMyJobsTabStyle.jobTitle,
  shippingProBtn: useMyJobsTabStyle.shippingProBtn,
  divider: {
    my: "24px",
    display: {
      xs: "none",
      sm: "flex",
    },
  },
};
