import React, { useEffect, useState } from "react";
// mui and icons
import {
  Box,
  Grid,
  Menu,
  // Fade,
  Stack,
  Button,
  MenuItem,
  Typography,
  InputAdornment,
} from "@mui/material";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// type and api
import {
  IAccountName,
  IInfluencerPostReportFilterProps,
} from "../EmployerJob.type";
import { ReportAPI } from "@api/global";
import { IGetAccountListRes } from "@api/report/type";
// style and others
import { useInfluencerPostReportStyle as classes } from "../EmployerJob.style";
import BasicSelectCustom from "@global/basic-select";
import TextFieldCustom from "@global/text-field";
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export default function InfluencerPostReportFilter(
  props: IInfluencerPostReportFilterProps
) {
  const { params, setParams, publishLink, filterDate } = props;
  const [anchorElGroup, setAnchorElGroup] = useState<null | HTMLElement>(null);
  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);
  const [filterDateSelected, setFilterDateSelected] = useState("weekly");
  const [socialAccountList, setSocialAccountList] = useState<IAccountName[]>([]);

  let openGroup = Boolean(anchorElGroup);
  let openReport = Boolean(anchorElReport);
  let date = new Date();
  
  const location = useLocation()
  const sParams = new URLSearchParams(location.search)
  const qCampaignId = sParams.get("c_id") || ""
  const { jobId } = useParams<{ jobId: string }>();

  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7) as any
  );
  const [endDate, setEndDate] = useState(date as any);

  useEffect(() => {
    let isMounted = true
    const getAccountList = () => {
      ReportAPI.getAccountReportList(qCampaignId).then((response) => {
        if (isMounted) {
          const result = response.map((item: IGetAccountListRes) => {
            if (item.job_id === jobId) {
              setParams({
                ...params,
                job_id: item.job_id,
              });
            }
            return {
              id: item.id,
              name: item.name,
              value: item.job_id,
              label: item.name,
              img: item.profile_image_url,
            };
          });
          setSocialAccountList(result);
        }
      });
    }
    getAccountList();

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qCampaignId]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, type: string) => {
    if (type === "report") {
      setAnchorElReport(event.currentTarget);
    } else {
      setAnchorElGroup(event.currentTarget);
    }
  };

  const handleCloseMenu = (type: string) => {
    if (type === "report") {
      setAnchorElReport(null);
    } else {
      setAnchorElGroup(null);
    }
  };

  const handleSelectAccountChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    setParams({ ...params, job_id: value });
  };

  const onFilterDate = (
    filterType: string,
    type: string,
    start: any,
    end: any
  ) => {
    const chartType = {
      job_id: params.job_id,
      chart_type: type,
    };
    const filterSelf = {
      job_id: params.job_id,
      date: {
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD"),
      },
    };
    if (filterType === "chartType") {
      filterDate(chartType);
      setParams(chartType);
    } else if (filterType === "more1Month") {
      filterDate(filterSelf);
      setParams(filterSelf);
    }
    setStartDate(start);
    setEndDate(end);
    setFilterDateSelected(type);
  };

  const onFilterDateSelf = (status: string, date: any) => {
    const data = {
      job_id: params.job_id,
      date: {
        start: dayjs(status === "start" ? date : startDate).format(
          "YYYY-MM-DD"
        ),
        end: dayjs(status === "end" ? date : endDate).format("YYYY-MM-DD"),
      },
    };
    setFilterDateSelected("filterSelf");
    filterDate(data);
    setParams(data);
  };

  const filterDateList = [
    {
      label: "สัปดาห์นี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "weekly",
    },
    {
      label: "เดือนนี้",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "monthly",
    },
    {
      label: "2 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 2,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "2month",
    },
    {
      label: "3 เดือน",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 3,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "3month",
    },
    {
      label: "กำหนดเอง",
      value: {
        start: "",
        end: "",
      },
      filterType: "filterSelf",
      type: "filterSelf",
    },
  ];

  const onDownloadReport = () => {
    ReportAPI.campaignPostReportAsCSV(qCampaignId).then((response) => {
      if (response !== null) {
        window.open(response.public_url, "_blank");
      }
    });
  }

  return (
    <>
      <Grid item xs={8}>
        <Box display="flex">
          <Box sx={classes.groupBy}>
            <Typography>Group By</Typography>
            <Button
              id="group-by-select"
              onClick={(e) => handleOpenMenu(e, "group")}
              sx={classes.groupByBtn}
              endIcon={<ArrowDropDownIcon />}
            >
              Select
            </Button>
            <Menu
              anchorEl={anchorElGroup}
              open={openGroup}
              onClose={() => handleCloseMenu("group")}
              MenuListProps={{
                "aria-labelledby": "group-by-select",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={classes.menuDate}
            >
              <Grid container spacing={5} mt={-2} sx={classes.menuDateGridCon}>
                <Grid item xs={2.4}>
                  {filterDateList.map((item, index) => (
                    <MenuItem
                      sx={{
                        ...classes.menuItem,
                        bgcolor:
                          item.type === filterDateSelected ? "#EBDFFF" : "#fff",
                      }}
                      key={index}
                      onClick={() => {
                        if (item.filterType !== "filterSelf") {
                          onFilterDate(
                            item.filterType,
                            item.type,
                            item.value.start,
                            item.value.end
                          );
                        } else {
                          setFilterDateSelected(item.filterType);
                        }
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Grid>

                <Grid item xs={4.7}>
                  <Typography variant="title1">วันที่เริ่มต้น:</Typography>
                  <TextFieldCustom
                    fullWidth
                    value={dayjs(startDate).format("DD/MM/YYYY")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon sx={classes.calendarIcon} />
                        </InputAdornment>
                      ),
                    }}
                    sx={classes.txtFieldDate}
                  />
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date);
                      onFilterDateSelf("start", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    startDate={startDate}
                    endDate={endDate}
                    value={startDate}
                    selectsStart
                    inline
                    calendarClassName="datepicker-calendar-container"
                  />
                </Grid>
                <Grid item xs={4.7}>
                  <Typography variant="title1">วันที่สิ้นสุด:</Typography>
                  <TextFieldCustom
                    fullWidth
                    value={dayjs(endDate).format("DD/MM/YYYY")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon sx={classes.calendarIcon} />
                        </InputAdornment>
                      ),
                    }}
                    sx={classes.txtFieldDate}
                  />
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    calendarClassName="datepicker-calendar-container"
                    onChange={(date: any) => {
                      setEndDate(date);
                      onFilterDateSelf("end", date);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    value={endDate}
                    inline
                  />
                </Grid>
              </Grid>
            </Menu>
          </Box>
          <Box sx={classes.accountName}>
            <Typography whiteSpace="nowrap">Account Name</Typography>
            <BasicSelectCustom
              id="account-name-select"
              value={params.job_id}
              onChange={handleSelectAccountChange}
              data={socialAccountList}
              valueKey="value"
              labelKey="label"
              iconKey="img"
              iconType="imageUrl"
              placeholder="Select"
              sx={{ width: "320px" }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="row" spacing={3} justifyContent="end">
          <Button
            color="secondary"
            startIcon={<VisibilityOutlinedIcon />}
            onClick={() =>
              publishLink ? window.open(publishLink, "_blank") : undefined
            }
            sx={{ width: "188px" }}
          >
            View Post
          </Button>
          <Button
            // id="fade-button"
            // aria-controls={openReport ? "fade-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={openReport ? "true" : undefined}
            // onClick={(e) => handleOpenMenu(e, "report")}
            variant="contained"
            color="secondary"
            sx={{
              ...classes.downloadBtn,
              borderRadius: openReport ? "20px 20px 0 0" : "50px",
            }}
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={onDownloadReport}
          >
            Download Report
          </Button>
          {/* <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorElReport}
            open={openReport}
            onClose={() => handleClose("report")}
            TransitionComponent={Fade}
            sx={classes.downloadMenu}
          >
            <MenuItem
              sx={classes.menuItems1}
              onClick={() => handleClose("report")}
            >
              PDF (.pdf)
            </MenuItem>
            <MenuItem
              sx={classes.menuItems2}
              onClick={() => handleClose("report")}
            >
              CSV (.csv)
            </MenuItem>
          </Menu> */}
        </Stack>
      </Grid>
    </>
  );
}
