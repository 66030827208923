import React from "react";
// mui
import {
  Box,
  Card,
  Stack,
  styled,
  Divider,
  CardHeader,
  Typography,
  CardContent,
} from "@mui/material";
import { common } from "@mui/material/colors";
// theme and style
import { hColorful, hGrey, hPink, hSocialColor } from "@theme/index";
import { HomeContactUsStyle as classes } from "./HomeContactUs.style";
// image
import lineIcon from "@assets/images/social/line-icon.png";
import tiktokIcon from "@assets/images/social/tiktok-icon.png";
import facebookIcon from "@assets/images/social/facebook-icon.png";
import instagramIcon from "@assets/images/social/instagram-icon.png";
import contactUsImage from "@assets/images/home/contact-us/contact-us.png";
// other
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "center",
  color: common.black,
  width: "100%",
}));

function Contact() {
  const bp = useBreakpoint();
  const items = [
    { label: "Phone", value: "081-9699888" },
    { label: "E-mail", value: "Info@hashu.co" },
  ];

  return (
    <Card sx={{ marginY: 10, boxShadow: "unset" }}>
      <CardContent>
        <Stack
          direction={bp === "xs" ? "column" : "row"}
          divider={
            <Divider
              orientation={bp === "xs" ? "horizontal" : "vertical"}
              flexItem
            />
          }
          justifyContent="space-between"
          spacing={5}
        >
          {items.map((item, idx) => {
            return (
              <Item key={idx}>
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body1",
                    "body1",
                    "body1",
                    "h6"
                  )}
                  sx={{ color: hPink[300] }}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant={setValueByBreakpoint(bp, "h6", "h6", "h6", "h5")}
                >
                  {item.value}
                </Typography>
              </Item>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}

function TalkWithUs() {
  const bp = useBreakpoint();

  const talkWithUsItems = [
    {
      header: "Live Chat",
      buttons: [
        {
          id: "line",
          icon: lineIcon,
          label: "@hashu.co",
          color: `${hSocialColor["line"]}`,
          url: "https://lin.ee/dez0phm",
        },
        {
          id: "facebook",
          icon: facebookIcon,
          label: "HashU.co",
          color: `${hSocialColor["facebook"]}`,
          url: "https://www.facebook.com/HashU.co",
        },
      ],
    },
    {
      header: "Following us",
      buttons: [
        {
          id: "instagram",
          icon: instagramIcon,
          label: "@influ.hashu",
          color: undefined,
          url: "https://www.instagram.com/influ.hashu/",
        },
        {
          id: "tiktok",
          icon: tiktokIcon,
          label: "HashU.Co",
          color: `${hSocialColor["tiktok"]}`,
          url: "https://www.tiktok.com/@hashu.co.official",
        }
      ],
    },
  ];

  return (
    <Card
      sx={{
        boxShadow: "unset",
        paddingTop: 7,
        paddingBottom: [7, 7, 28, 7, 7],
      }}
    >
      <CardHeader
        title={
          <Typography
            sx={{
              background: `linear-gradient(296.45deg, ${hColorful[100]}, ${hColorful[200]})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            textAlign="center"
            variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
            fontWeight="medium"
          >
            Talk with us
          </Typography>
        }
      />
      <CardContent sx={{ paddingTop: "unset" }}>
        <Box
          display="flex"
          flexDirection={bp === "xs" ? "column-reverse" : undefined}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={["100%", "40%", "40%", "30%"]}
            display="flex"
            justifyContent="center"
          >
            <img
              src={`${contactUsImage}`}
              alt="faq"
              style={{
                width: setValueByBreakpoint(
                  bp,
                  "200px",
                  "200px",
                  "200px",
                  "240px"
                ),
                objectFit: "contain",
              }}
            />
          </Box>
          <Box width={["100%", "40%", "40%", "30%"]}>
            {talkWithUsItems.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "space-between",
                      justifyContent: "center",
                      paddingY: "20px",
                    }}
                  >
                    <Typography
                      variant={setValueByBreakpoint(
                        bp,
                        "body2",
                        "h6",
                        "h6",
                        "h6"
                      )}
                      sx={{ color: hGrey[700] }}
                    >
                      {item.header}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {item.buttons.map((but, index) => (
                        <Box
                          key={index}
                          m="0 10px"
                          sx={{ cursor: "pointer" }}
                          onClick={() => window.open(but.url)}
                        >
                          <Box display="flex" justifyContent="center">
                            <img
                              src={`${but.icon}`}
                              alt="social-icon"
                              width="30px"
                            />
                          </Box>
                          <Typography variant="subTitle2">
                            {but.label}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function ContactUs() {
  const bp = useBreakpoint();

  return (
    <Box sx={classes.contentMain}>
      <Box id="back-to-top-anchor" sx={classes.headerBackground}></Box>
      <Box width={["100%", "100%", "90%", "80%"]}>
        <Box pt={[15, 20, 20, 30]}>
          <Box textAlign="center">
            <Typography
              variant={setValueByBreakpoint(bp, "h4", "h3", "h3", "h2")}
            >
              Contact Us
            </Typography>
            <br />
            <Typography
              variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
              color={"text.secondary"}
            >
              Get in touch and let us know how we can help
            </Typography>
          </Box>
        </Box>
        <Box>
          <Contact />
          <TalkWithUs />
        </Box>
      </Box>
    </Box>
  );
}
