import React, { useState } from "react";
// mui and style
import { Box, CircularProgress, Grid, Icon, Typography } from "@mui/material";
import { useEditBankAccountStyle as classes } from "../InfluencerProfile.style";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
// type and api
import {
  IBankAccountForm,
  IUpdateBankAccountProps,
} from "../InfluencerProfile.type";
import { AppInformationAPI } from "@api/global";
// context and util
import { formatTaxId } from "@utils/helpers/formateNumber.helpers";
import { accountType } from "@utils/constants/accountType.constants";
// other
import TextFieldCustom from "@components/global/text-field";
import BasicSelectCustom from "@components/global/basic-select";
// redux
import { useAppSelector } from "@store/hook";
import { selectBankList } from "@slices/BankList.slice";

export default function EditInfluencerBankAccount(props: IUpdateBankAccountProps) {
  const { form, onSubmit } = props;

  const [imageName, setImageName] = useState("");
  const [isUploadAtt, setIsUploadAtt] = useState(false)

  const bankList = useAppSelector(selectBankList);

  const {
    watch,
    setValue,
    register,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = form;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IBankAccountForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const handleSelectBank = (e: { target: { value: number } }) => {
    const bankId = e.target.value;
    const bankSelect = (bankList || []).find((bank) => bank.id === bankId);
    setValue("bank", bankSelect || null);
    clearErrors("bank");
  };

  const handleChangeUploadImages = async (e: any) => {
    setIsUploadAtt(true)
    let data = {
      file: e.target.files[0],
      destination: "test",
      permission: "public",
    };
    const result = await AppInformationAPI.uploadAttachment(data);

    if (result) {
      if (imageName === "copyofthainationalid") {
        const attachment = {...watch("id_card_image_url")}
        setValue("id_card_image_url", {
          file_id: attachment?.file_id || "",
          purpose: "citizen_id_image",
          file_name: result.file_name,
          file_type: result.file_type,
          url: result.public_url || result.private_url || "",
          description: attachment?.description || "",
        });
      } else if (imageName === "bookbank") {
        const attachment = {...watch("id_card_image_url")}
        setValue("book_bank_image_url",{
          file_id: attachment?.file_id || "",
          purpose: "bank_account_book_image",
          file_name: result.file_name,
          file_type: result.file_type,
          url: result.public_url || result.private_url || "",
          description: attachment?.description || "",
        });
      }
    }

    setIsUploadAtt(false)
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      mt="20px"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Icon className="ico-hu-bank" color="primary" />
        <Typography variant="body1" fontWeight="bold">
          Bank Account
        </Typography>
      </Box>
      <Box px={2} pt={3} my={2} display="flex" flexDirection="column" maxHeight={{ md:"60vh" }} overflow="scroll">
        {/* Account Name */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ชื่อบัญชี<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Account Name"
              fullWidth
              inputProps={{
                ...register("account_name", {
                  required: true,
                }),
              }}
              error={!!errors.account_name}
              helperText={errors.account_name?.message}
            />
          </Grid>
        </Grid>
        {/* Account Number */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              เลขบัญชี<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Account Number"
              fullWidth
              inputProps={{
                ...register("account_number", {
                  required: true,
                }),
              }}
              error={!!errors.account_number}
              helperText={errors.account_number?.message}
            />
          </Grid>
        </Grid>
        {/* Bank */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ธนาคาร<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <BasicSelectCustom
              id="bank-bank-select-1"
              value={watch("bank.id") || ""}
              onChange={handleSelectBank}
              valueKey="id"
              labelKey="name_th"
              iconKey="logo_image_url"
              iconType="imageUrl"
              placeholder="Select Bank"
              fullWidth
              data={bankList || []}
              error={!!errors.bank}
              helperText={(errors.bank as any)?.message}
            />
          </Grid>
        </Grid>
        {/* Account Type */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ประเภทบัญชี<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <BasicSelectCustom
              id="bank-accountType-select-1"
              value={watch("account_type") || ""}
              onChange={(e) => handleInputChange(e, "account_type")}
              data={accountType}
              valueKey="value"
              labelKey="label"
              placeholder="Account Type"
              fullWidth
              error={!!errors.account_type}
              helperText={errors.account_type?.message}
            />
          </Grid>
        </Grid>
        {/* Tax ID */}
        <Grid container pb={5}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ID card / Passport<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              value={formatTaxId(watch("tax_id"))}
              onChange={(e) => handleInputChange(e, "tax_id")}
              variant="outlined"
              placeholder="Tax ID"
              fullWidth
              error={!!errors.tax_id}
              helperText={errors.tax_id?.message}
            />
          </Grid>
        </Grid>
        {/* id book image */}
        <Grid container pb={5}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              สำเนาบัตรประชาชน<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <Box
              sx={classes.uploadImageBox}
              component="label"
              onClick={() => setImageName("copyofthainationalid")}
              onChange={handleChangeUploadImages}
              position="relative"
            >
              {(isUploadAtt && imageName === "copyofthainationalid") && (
                <Box sx={classes.curcular}>
                  <CircularProgress size="60px" />
                </Box>
              )}
              {!watch("id_card_image_url") ? (
                <Box sx={classes.uploadImage}>
                  <Box display="flex" flexDirection="column">
                    <Icon className="ico-hu-p-photo" />
                    <Typography>+ JPG/PNG</Typography>
                  </Box>
                </Box>
              ) : (
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "5px"
                  }}
                  src={watch("id_card_image_url")?.url}
                  alt="Copy of Thai National ID"
                />
              )}
              <input
                hidden
                name="idCardImageUrl"
                accept="image/*"
                multiple
                type="file"
              />
            </Box>
          </Grid>
        </Grid>
        {/* tax image */}
        <Grid container pb={5}>
          <Grid item xs={12} md={3.5} xl={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              สำเนาหน้าสมุดบัญชีธนาคาร<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={8.5} xl={9} mt={[1.5, 1.5, 0]}>
            <Box
              sx={classes.uploadImageBox}
              component="label"
              onClick={() => setImageName("bookbank")}
              onChange={handleChangeUploadImages}
              position="relative"
            >
              {(isUploadAtt && imageName === "bookbank") && (
                <Box sx={classes.curcular}>
                  <CircularProgress size="60px" />
                </Box>
              )}
              {!watch("book_bank_image_url") ? (
                <Box sx={classes.uploadImage}>
                  <Box display="flex" flexDirection="column">
                    <Icon className="ico-hu-p-photo" />
                    <Typography>+ JPG/PNG</Typography>
                  </Box>
                </Box>
              ) : (
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "5px"
                  }}
                  src={watch("book_bank_image_url")?.url}
                  alt="Book Bank"
                />
              )}
              <input
                hidden
                name="bookbank"
                accept="image/*"
                multiple
                type="file"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <SaveButton
          type="submit"
          variant="contained"
          disabled={
            !getValues("account_name") ||
            !getValues("account_number") ||
            !getValues("tax_id") ||
            !getValues("bank")
              ? true
              : false
          }
          startIcon={<Icon className="ico-hu-save" />}
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
      </Box>
    </Box>
  );
}
