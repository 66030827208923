import { useEffect } from "react";
// mui and icons
import { Box, Icon, Link, Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
// components
import JobsTab from "@components/my-jobs/jobs-detail/jobs-tab/JobsTab";
import CompanyDetail from "@components/campaign/campaign-detail/CompanyDetail";
import DetailTab from "@components/my-jobs/jobs-detail/detail-tab/DetailTab";
import ReportTab from "@components/my-jobs/jobs-detail/report-tab/ReportTab";
import ReviewTab from "@components/my-jobs/jobs-detail/review-tab/MyJobsReviewTab";
// redux
import {
  selectInfluencerJobInfo,
  getInfluencerJobInfoAsync,
  selectInfluencerJobInfoOnload,
} from "@slices/InfluencerJobInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// style and react route
import { useMyJobsDetailStyle as classes } from "./JobsDetail.style";
import { useHistory, useLocation, useParams } from "react-router-dom";
// others
import Loading from "@components/global/loading/Loading";
import { scrollToTop } from "@utils/helpers/scrollToTop";
import BackToTop from "@components/global/back-to-top/BackToTop";

const tabList = [
  { key: "details", label: "Details", icon: "ico-hu-campaign-brief" },
  { key: "job", label: "Job", icon: "ico-hu-my-job" },
  { key: "report", label: "Report", icon: "ico-hu-report" },
  // { key: "review", label: "Review", icon: <SummarizeOutlined /> },
];

export default function MyJobsDetail() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "details";

  const { jobId } = useParams<{ jobId: string }>();

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const isLoading = useAppSelector(selectInfluencerJobInfoOnload);
  const isJobListing = jobInfo?.status === "listing";
  const isReport = jobInfo?.status === "published" || jobInfo?.status === "completed";

  useEffect(() => {
    dispatch(getInfluencerJobInfoAsync({ jobId: jobId }));
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, dispatch]);

  const handleTabChange = (tab: string) => {
    history.replace(`/influencers/jobs/${jobId}/info?tab=${tab}`);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <BackToTop />
      <Box
        id="back-to-top-anchor"
        sx={{
          ...classes.myJobsCon,
          backgroundImage: {
            xs: `url(${jobInfo?.campaign_info.brief.cover_image.url})`,
            md: "initial",
          },
          objectFit: "cover",
        }}
      >
        <Link
          onClick={() => history.goBack()}
          underline="none"
          sx={classes.callback}
        >
          <KeyboardArrowLeft />
          <span>Back</span>
        </Link>
      </Box>
      <CompanyDetail jobInfo={jobInfo} />
      {!isJobListing && (
        <Box sx={classes.tabBox}>
          {tabList.map((tab, index) => (
            <Box
              key={index}
              component="button"
              disabled={tab.key === "report" && !isReport ? true : false}
              sx={
                tabSelected === tab.key
                  ? classes.tabSelected
                  : tab.key === "report" && !isReport
                  ? classes.tabDisabled
                  : classes.tab
              }
              onClick={() => handleTabChange(tab.key)}
            >
              <Icon className={tab.icon} color="primary" />
              <Typography fontWeight={tabSelected === tab.key ? 500 : 400}>
                {tab.label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Box display={tabSelected === "details" ? "initial" : "none"}>
        <DetailTab role="influencer" jobInfo={jobInfo} />
      </Box>
      {!isJobListing && (
        <>
          <Box display={tabSelected === "job" ? "initial" : "none"}>
            <JobsTab />
          </Box>
          <Box display={tabSelected === "report" ? "initial" : "none"}>
            <ReportTab />
          </Box>
          <Box display={tabSelected === "review" ? "initial" : "none"}>
            <ReviewTab />
          </Box>
        </>
      )}
    </div>
  );
}
