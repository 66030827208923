// mui
import { Box, Typography } from "@mui/material";
// redux
import { IInfluencerJobList } from "@views/my-jobs/MyJobs.type";

export default function Statistics({ jobList } : { jobList: IInfluencerJobList | null }) {
  let totalSuccess = Math.round(((jobList?.completed || 0) / (jobList?.total || 0)) * 100);

  return (
    <div>
      <Typography variant="h6">Statistics</Typography>
      <Box display="flex" justifyContent="space-between" p="20px">
        {[
          { label: "Total", value: jobList?.total || 0 },
          { label: "รอตอบรับ", value: jobList?.listing || 0 },
          { label: "เริ่มงานได้", value: jobList?.working || 0 },
          { label: "กำลังดำเนินการ", value: jobList?.first_draft || 0 },
          { label: "เสร็จสิ้น", value: jobList?.completed || 0, color: "#4A489D" },
          { label: "ยกเลิก", value: jobList?.rejected || 0, color: "#231F20" },
          {
            label: "% ความสำเร็จ",
            value: `${isNaN(totalSuccess) ? 0 : totalSuccess}%`,
            color: "#4CAF50",
          },
        ].map((item, index) => (
          <Box key={index} display="flex" flexDirection="column">
            <Typography variant="subTitle2">{item.label}</Typography>
            <Typography variant="h4" color={item.color || "primary"}>
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
}
