import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Chip,
  Grid,
  Radio,
  Button,
  Drawer,
  Avatar,
  Divider,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
// icon and style
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useCampaignBrieCompfStyle as classes } from "./Campaign.style";
// type
import { IFilterCampaignProps } from "./Campaign.type";
import { ICampaignParams } from "@views/campaign/Campaign.type";
// constants
import { platformList } from "@utils/constants/platform.constants";
import { campaignTypeList, sortDateBudgetList } from "@views/campaign/Campaign.constants";
// redux
import { useAppSelector } from "@store/hook";
import { selectCategoryList } from "@slices/CategoryList.slice";
// other
import { useHistory, useLocation } from "react-router-dom";

export default function CampaignFilter(props: IFilterCampaignProps) {
  const { open, onClose } = props;

  const [filterList, setFilterList] = useState<ICampaignParams>();

  const categoryList = useAppSelector(selectCategoryList);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const search = params.get("q") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const page = Number(params.get("page")) || 1;
  const cate = params.get("cate");
  const categories = cate ? cate.split(",").map(Number) : [];

  useEffect(() => {
    setFilterList((prev) => {
      return {
        ...prev,
        page: page,
        q: search,
        status: status,
        sort: sort,
        order_by: orderBy,
        platform: platform,
        type: type,
        cate: categories,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, status, sort, orderBy, platform, type, cate]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    onClose(open);
  };

  const handleFilterCampaign = (data: ICampaignParams) => {
    setFilterList((prev) => {
      return { ...prev, ...data };
    });
  };

  const handleSelectCategory = (value: number) => {
    if (!filterList) return;
    const categories = [...categoryList.map((cat) => cat.id)];
    if (categories.includes(value)) {
      if (filterList.cate?.includes(value)) {
        setFilterList((prev) => {
          return {
            ...prev,
            cate: (filterList.cate || []).filter((cat) => cat !== value),
          };
        });
      } else {
        setFilterList((prev) => {
          return {
            ...prev,
            cate: [...(prev?.cate || []), value],
          };
        });
      }
    } else {
      setFilterList((prev) => {
        return { ...prev, cate: [] };
      });
    }
  };

  const onClearFilter = () => {
    history.replace("/campaigns");
    onClose(false);
  };

  const onApplyFilter = () => {
    if (!filterList) return;
    const queryString = Object.keys(filterList)
      .map((key) => `${key}=${filterList[key as keyof ICampaignParams]}`)
      .join("&");

    history.replace(`/campaigns?${queryString}`);
    onClose(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={classes.drawer}
    >
      <Box sx={classes.drawerCon}>
        <Box sx={classes.filterTitle}>
          <Typography variant="h6" color="primary" ml={3.5}>
            Filter
          </Typography>
          <ClearOutlinedIcon onClick={() => onClose(false)} />
        </Box>

        <Box sx={classes.titleItem}>
          <Typography>Sort by</Typography>
          <FormControl>
            <RadioGroup sx={{ minHeight: "90px" }}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  {sortDateBudgetList.date.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilterCampaign({
                              order_by: "date",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={7}>
                  {sortDateBudgetList.budget.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          checked={
                            `${filterList?.order_by}${filterList?.sort}` ===
                            item.value
                          }
                          onChange={() => {
                            handleFilterCampaign({
                              order_by: "budget",
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>

        <Divider sx={classes.line} />

        <Box sx={classes.titleItem}>
          <Typography>Campaign Type</Typography>
          <div>
            {campaignTypeList.map((item, index) => (
              <Chip
                key={index}
                label={item.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.type === item.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.type === item.key ? "#F4ECFF" : "#fff",
                  color: filterList?.type === item.key ? "#893DBD" : "#545454",
                  minWidth: "60px",
                }}
                onClick={() => handleFilterCampaign({ type: item.key })}
              />
            ))}
          </div>
        </Box>

        <Divider sx={classes.line} />

        <Box sx={classes.titleItem}>
          <Typography>Platform</Typography>
          <div>
            <Chip
              label="All"
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  filterList?.platform === "" ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor: filterList?.platform === "" ? "#F4ECFF" : "#fff",
                minWidth: "60px",
                color: filterList?.platform === "" ? "#893DBD" : "#545454",
              }}
              onClick={() => handleFilterCampaign({ platform: "" })}
            />
            {platformList.map((item, index) => (
              <Chip
                key={index}
                avatar={<Avatar src={item.url} />}
                label={item.label}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.platform === item.key ? "#F4ECFF" : "#9e9e9e"
                  }`,
                  bgcolor:
                    filterList?.platform === item.key ? "#F4ECFF" : "#fff",
                  color:
                    filterList?.platform === item.key ? "#893DBD" : "#545454",
                }}
                onClick={() => handleFilterCampaign({ platform: item.key })}
              />
            ))}
          </div>
        </Box>

        <Divider sx={classes.line} />

        <Box sx={classes.titleItem}>
          <Typography>Category</Typography>
          <div>
            <Chip
              label="All"
              onClick={() => handleSelectCategory(0)}
              sx={{
                ...classes.chip,
                border: `1px solid ${
                  (filterList?.cate || []).length <= 0 ? "#F4ECFF" : "#9e9e9e"
                }`,
                bgcolor:
                  (filterList?.cate || []).length <= 0 ? "#F4ECFF" : "#fff",
                color:
                  (filterList?.cate || []).length <= 0 ? "#893DBD" : "#545454",
              }}
            />
            {categoryList?.map((category) => (
              <Chip
                key={category?.id}
                label={category?.name_en}
                sx={{
                  ...classes.chip,
                  border: `1px solid ${
                    filterList?.cate?.includes(category.id)
                      ? "#F4ECFF"
                      : "#9e9e9e"
                  }`,
                  bgcolor: filterList?.cate?.includes(category.id)
                    ? "#F4ECFF"
                    : "#fff",
                  color: filterList?.cate?.includes(category.id)
                    ? "#893DBD"
                    : "#545454",
                }}
                onClick={() => handleSelectCategory(category.id)}
              />
            ))}
          </div>
        </Box>

        <Box sx={classes.filterBottom}>
          <Typography onClick={onClearFilter} ml={3}>
            Clear All
          </Typography>
          <Button
            variant="contained"
            onClick={onApplyFilter}
            sx={classes.buttonApply}
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
