import { useEffect, useState } from "react";
// mui and type
import { Box, Grid, Typography } from "@mui/material";
import { IOverviewParams } from "@api/dashboard/type";
// component
import RegistrationFunnel from "./overview-tab-default/RegistrationFunnel";
import OverviewTabFilterDate from "./overview-tab-default/OverviewTabFilterDate";
import ReferralsStats from "./overview-tab-default/registration-referrals/ReferralsStats";
import TypeOfInfluencer from "./overview-tab-default/type-of-influencer/TypeOfInfluencer";
import ReferralsRanking from "./overview-tab-default/registration-referrals/ReferralsRanking";
import InfluencerDistribution from "./overview-tab-default/influencer-distribution/InfluencerDistribution";
// redux
import {
  selectDashboardOverview,
  getDashboardOverviewAsync,
  selectDashboardOverviewOnload,
} from "@slices/DashboardOverview.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";
// other
import Loading from "@global/loading/Loading";

export default function OverviewTab() {
  const [params, setParams] = useState<IOverviewParams>();

  const dispatch = useDispatch();
  const data = useAppSelector(selectDashboardOverview);
  const isLoading = useAppSelector(selectDashboardOverviewOnload);

  useEffect(() => {
    dispatch(getDashboardOverviewAsync(params));
  }, [dispatch, params]);

  const componentList = [
    {
      component: <RegistrationFunnel funnel={data?.funnel} />,
      xs: 12,
    },
    {
      component: <ReferralsStats stats={data?.refferal_stats} />,
      xs: 8.5,
    },
    {
      component: <ReferralsRanking ranking={data?.ranking} />,
      xs: 3.5,
    },
    {
      component: (
        <TypeOfInfluencer
          param={params}
          setParam={setParams}
          tiersDist={data?.tiers_distribution}
          socialDist={data?.social_distribution}
          cateDistTier={data?.category_distribution_tiers}
          cateDistPlatform={data?.category_distribution_paltform}
        />
      ),
      xs: 12,
    },
    {
      component: (
        <InfluencerDistribution
          param={params}
          setParam={setParams}
          areaDist={data?.area_distribution}
        />
      ),
      xs: 12,
    },
  ];

  if (isLoading) return <Loading />

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Dashboard</Typography>
        <OverviewTabFilterDate setParam={setParams} />
      </Box>
      <Grid container my="5px" spacing="24px">
        {componentList.map((comp, index) => (
          <Grid key={index} item xs={comp.xs}>
            {comp.component}
          </Grid>
        ))}
      </Grid>
    </>
  );
}
