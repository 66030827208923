import { configureStore, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import TeamSlice from "@slices/Team.slice";
import BankListSlice from "@slices/BankList.slice";
import ActivitySlice from "@slices/Activity.slice";
import CategorySlice from "@slices/CategoryList.slice"
import PaymentSlice from "@slices/PaymentMethod.slice";
import AddressInfoSlice from "@slices/AddressInfo.slice";
import PackageInfoSlice from "@slices/PackageInfo.slice";
import CampaignInfoSlice from "@slices/CampaignInfo.slice";
import CampaignListSlice from "@slices/CampaignList.slice";
import EmployerInfoSlice from "@slices/EmployerInfo.slice";
import NotificationSlice from "@slices/Notification.slice";
import InfluencerSocial from "@slices/InfluencerSocial.slice";
import EmployerJobListSlice from "@slices/EmployerJobList.slice";
import ContentTypeListSlice from "@slices/ContentTypeList.slice";
import EmployerJobInfoSlice from "@slices/EmployerJobInfo.slice";
import InfluencerProfileSlice from "@slices/InfluencerProfile.slice";
import InfluencerJobListSlice from "@slices/InfluencerJobList.slice";
import DashboardOverviewSlice from "@slices/DashboardOverview.slice";
import InfluencerJobInfoSlice from "@slices/InfluencerJobInfo.slice";
import InfluencerJobReportSlice from "@slices/InfluencerJobReport.slice";
import InfluencerActiveInfoSlice from "@slices/InfluencerActiveList.slice";
import InfluencerPrivateCampaignSlice from "@slices/CampaignPrivate.slice";

const reducers = combineReducers({
  // influencer
  influencerSocial: InfluencerSocial,
  influencerJobInfo: InfluencerJobInfoSlice,
  influencerProfile: InfluencerProfileSlice,
  influencerJobList: InfluencerJobListSlice,
  influencerJobReport: InfluencerJobReportSlice,
  influencerActiveInfo: InfluencerActiveInfoSlice,
  influencerPrivateCampaign: InfluencerPrivateCampaignSlice,
  // employer
  teamInfo: TeamSlice,
  payment: PaymentSlice,
  employerInfo: EmployerInfoSlice,
  employerJobList: EmployerJobListSlice,
  employerJobInfo: EmployerJobInfoSlice,
  // campaign
  campaignList: CampaignListSlice,
  campaignInfo: CampaignInfoSlice,
  // notification
  notificationInfo: NotificationSlice,
  // others
  activity: ActivitySlice,
  bankList: BankListSlice,
  category: CategorySlice,
  address: AddressInfoSlice,
  packageInfo: PackageInfoSlice,
  contentType: ContentTypeListSlice,
  dashboardOverview: DashboardOverviewSlice,
});

export const resetStore = createAction("reset");

const rootReducer = (state: any, action: any) => {
  if (resetStore.match(action)) {
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["home"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
