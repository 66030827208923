import { useState } from "react";
// mui and icons
import {
  Box,
  Grid,
  Button,
  Dialog,
  Tooltip,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
// style, type and other
import { useConversionTrackingStyle as classes } from "../../EmployerJob.style";
import { IConversionTrackingProps } from "../../EmployerJob.type";
import dayjs from "dayjs";

export default function ConversionTracking(props: IConversionTrackingProps) {
  const { openConvDialog, closeConvDialog, conversion } = props;
  const [copy, setCopy] = useState({ publish: "Copy", coupone: "Copy" });

  const onCopyText = (name: string, text?: string) => {
    if (text) {
      if (name === "publish") {
        navigator.clipboard.writeText(text);
        setCopy({ ...copy, publish: "Copied" });
      } else if (name === "coupone") {
        navigator.clipboard.writeText(text);
        setCopy({ ...copy, coupone: "Copied" });
      }
    } else {
      setCopy({ publish: "Copy", coupone: "Copy" });
    }
  };

  return (
    <Dialog
      open={openConvDialog}
      onClose={closeConvDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
        <Typography>Shipping Product</Typography>
        <IconButton onClick={closeConvDialog}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.contentDialog}>
        <Box my="20px" mx="10px">
          <Box>
            <Typography fontSize="20px" color="#8C499C">
              Conversion Tracking
            </Typography>
            <Typography fontSize="14px" color="#5C5C5C">
              การวัดผลทางการตลาดที่เกิดขึ้นจากการโฆษณา
            </Typography>
          </Box>
          <Grid container spacing={4} mt="15px">
            <Grid item xs={4} mt={2}>
              <Typography fontWeight="bold">Link for Influencer</Typography>
            </Grid>
            <Grid item xs={8}>
              <Box sx={classes.linkForInflue} style={{ wordBreak: "normal" }}>
                <Typography>{conversion?.lead_link}</Typography>
                <Tooltip title={copy.publish} placement="top">
                  <OpenInNewRoundedIcon
                    color="primary"
                    onClick={() => onCopyText("publish", conversion?.lead_link)}
                    onMouseOut={() => onCopyText("mouseOut")}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight="bold">Coupon</Typography>
            </Grid>
            <ul style={{ width: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={3.8} mt={2} fontWeight="bold">
                  <li>Coupon Code</li>
                </Grid>
                <Grid item xs={8.2}>
                  <Box sx={classes.linkForInflue}>
                    <Typography>{conversion?.coupon}</Typography>
                    <Tooltip title={copy.coupone} placement="top">
                      <OpenInNewRoundedIcon
                        color="primary"
                        onClick={() =>
                          onCopyText("coupone", conversion?.coupon)
                        }
                        onMouseOut={() => onCopyText("mouseOut")}
                      />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={3.8} fontWeight="bold">
                  <li>Coupon Name</li>
                </Grid>
                <Grid item xs={8.2}>
                  <Typography>{conversion?.coupon_name}</Typography>
                </Grid>
                <Grid item xs={3.8} fontWeight="bold">
                  <li>Validity Period</li>
                </Grid>
                <Grid item xs={8.2}>
                  <Typography>{`${dayjs(
                    conversion?.start_validity_period
                  ).format("DD/MM/YYYY")} - ${dayjs(
                    conversion?.end_validity_period
                  ).format("DD/MM/YYYY")}`}</Typography>
                </Grid>
                <Grid item xs={3.8} fontWeight="bold">
                  <li>Description</li>
                </Grid>
                <Grid item xs={8.2}>
                  <Typography>{conversion?.note}</Typography>
                </Grid>
              </Grid>
            </ul>
          </Grid>
          <Box sx={classes.closeBtn}>
            <Button onClick={closeConvDialog} variant="outlined">
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
