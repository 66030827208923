import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  Paper,
  Button,
  InputBase,
  Typography,
  IconButton,
} from "@mui/material";
// theam and style
import { hAlertColor } from "@theme/index";
import { useEmployersStyle as classes } from "./Employer.style";
// component
import EmployerList from "@components/employer/EmployerList";
import { initImage } from "@components/employer/create-employer/EmployerData";
import CreateEmployerDialog from "@components/employer/create-employer/CreateEmployer";
import { ICreateEmployerForm } from "@components/employer/create-employer/CreateEmployer.type";
// api and type
import { AppInformationAPI, EmployerAPI } from "@api/global";
import { IAttachments, ICategory } from "@api/app-information/type";
import { IEmployerListParams, IEmployerListRes } from "@api/employer/type";
// other
import { useHistory, useLocation } from "react-router-dom";
import Loading from "@components/global/loading/Loading";
import PaginationCustom from "@components/global/pagination-custom";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

export default function Employer() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const qSearch = params.get("q") || "";
  const qPage = Number(params.get("page")) || 1;
  const qOrderBy = params.get("order_by") || "organisation";
  const qSort = params.get("sort") || "ASC";

  const [search, setSearch] = useState(qSearch);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [employerList, setEmployerList] = useState<IEmployerListRes>();
  const [openToCreateEmployer, setOpenToCreateEmployer] = useState(false);
  const [optionCategory, setOptionCategory] = useState<ICategory[]>([]);
  const [imageOrgLogo, setImageOrgLogo] = useState<IAttachments>(initImage);
  const [isUploadImgLoading, setIsUploadImgLoading] = useState(false);
  const [imageName, setImageName] = useState("");

  const totalItem = employerList?.total_item || 0;

  useEffect(() => {
    const params: IEmployerListParams = {
      item_per_page: rowsPerPage,
      page: qPage,
      order_by: qOrderBy,
      search: qSearch,
      sort: qSort,
    };

    let isMounted = true;
    const getEmployerList = (employerParams: IEmployerListParams) => {
      setIsLoading(true);
      EmployerAPI.getEmployerList(employerParams)
        .then((response) => {
          if (isMounted) {
            setEmployerList(response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.log("get employer list api error : ", err);
            setIsLoading(false);
          }
        });
    };

    getEmployerList(params);

    return () => {
      isMounted = false;
    };
  }, [rowsPerPage, qPage, qSearch, qOrderBy, qSort]);

  useEffect(() => {
    let isMounted = true;
    const getCategory = () => {
      AppInformationAPI.getCategoryList().then((response) => {
        if (isMounted) {
          const item = response.map((cat: ICategory) => {
            return {
              id: cat.id,
              label: cat.name_th,
              value: cat.value,
              name_en: cat.name_en,
              name_th: cat.name_th,
            };
          });
          setOptionCategory(item);
        }
      });
    };

    getCategory();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleChangePage = (_e: unknown, pageValue: number) => {
    history.replace(
      `/employers?q=${qSearch}&page=${pageValue}&order_by=${qOrderBy}&sort=${qSort}`
    );
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value.trim());

    if (!value) {
      history.replace(`/employers?q=${value.trim()}&page=1`);
    }
  };

  const handleDeleteEmployer = (employerId: string) => {
    SwalCustom.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure ?",
      confirmButtonColor: hAlertColor.warning,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (!employerList) return;
        const newItem = employerList?.item.filter(
          (filter) => filter.employer_full_id !== employerId
        );
        setEmployerList({
          ...employerList,
          item: newItem,
          total_item: employerList.total_item || 0 - 1,
        });

        EmployerAPI.deleteEmployer(employerId)
          .then(() => {
            SwalCustom.fire("Deleted!", "Employer has been deleted.", "success").then(() => {
              history.replace(
                `/employers?q=${qSearch}&page=${qPage}&order_by=${qOrderBy}&sort=${qSort}`
              );
            })
          })
          .catch(() => {
            SwalCustom.fire("Opps!", "There are somting wrong", "error");
          })
      }
    });
  };

  const handleUploadImage = (e: any) => {
    setIsUploadImgLoading(true);
    let body = {
      file: e.target.files[0],
      destination: "organisation",
      permission: "public",
    };

    AppInformationAPI.uploadAttachment(body).then((response) => {
      const imageData = {
        file_name: response.file_name,
        file_type: response.file_type,
        url: response.public_url || response.private_url ,
      };
      if (imageName === "imageOrgLogo") {
        setImageOrgLogo({
          ...imageOrgLogo,
          file_id: imageOrgLogo.file_id === "" ? "" : imageOrgLogo.file_id,
          purpose:
            imageOrgLogo.purpose === ""
              ? "organization_logo_image"
              : imageOrgLogo.purpose,
          ...imageData,
        });
      }
    }).finally(() => {
      setIsUploadImgLoading(false);
    })
  };

  const goEmployerInfo = (employerId: string) => {
    history.push(`/employers/${employerId}/info`);
  };

  const onCreateEmployer = async (data: ICreateEmployerForm) => {
    setIsLoading(true);
    try {
      const reqBody = {
        created_by: data.created_by,
        fullname: data.fullname,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        phone: data.phone,
        line_id: data.line_id,
        organisation: {
          id: data.id,
          name: data.name,
          size: data.size,
          type: data.type,
          attachment: imageOrgLogo,
          category: data.category,
        },
      };

      const response = await EmployerAPI.createEmployer(reqBody);
      if (!response) return;

      const updateItem = [...(employerList?.item || []), response];
      setEmployerList({
        item: updateItem,
        item_per_page: rowsPerPage,
        total_item: employerList?.total_item || 0,
        page: qPage,
        total_page: employerList?.total_page || 0,
      });
      setOpenToCreateEmployer(false);
    } catch (error: any) {
      const data = error.response.data;
      switch (data.error.message) {
        case "error, can't insert a category":
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, Please insert a category !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
          break;
        case "error, this email is already taken":
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, Email is already taken !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = () => history.replace(`/employers?q=${search}&page=1`);

  if (isLoading) return <Loading />
  
  return (
    <div className="container-main">
      <Box mb="24px">
        <Typography variant="h5">Employer List</Typography>
        <Typography variant="body1">รายชื่อผู้จ้างงาน</Typography>
      </Box>
      <Box sx={classes.contentWrap}>
        <Box
          display="flex"
          p="24px"
          flexDirection={["column", "column", "row"]}
          justifyContent={["center", "center", "space-between"]}
        >
          <Box sx={{ width: { xs: "100%", lg: "700px" } }}>
            <Paper sx={classes.searchWrap}>
              <InputBase
                sx={{ ml: 4, flex: 1 }}
                placeholder="Search by id, name, organization, phone, email"
                inputProps={{ "aria-label": "search google maps" }}
                value={search}
                onChange={handleSearchChange}
              />
              <IconButton
                onClick={onSearch}
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <Icon className="ico-hu-search" />
              </IconButton>
            </Paper>
          </Box>
          <Box sx={classes.newEmployerBtnWrap}>
            <Button
              onClick={() => setOpenToCreateEmployer(true)}
              sx={classes.newEmployerBtn}
            >
              <Icon className="ico-hu-add-circle" />
              New Employer
            </Button>
          </Box>
        </Box>
        <EmployerList
          employerList={employerList?.item || []}
          goEmployerInfo={goEmployerInfo}
          handleDeleteEmployer={handleDeleteEmployer}
        />
      </Box>

      <Box sx={classes.pagination}>
        <PaginationCustom
          page={qPage}
          count={qPage > 0 ? Math.ceil(totalItem / rowsPerPage) : 0}
          onPaginationChange={handleChangePage}
          rowsPerPageData={[]}
          rowsPerPage={rowsPerPage}
          onRowPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <CreateEmployerDialog
        open={openToCreateEmployer}
        onClose={() => setOpenToCreateEmployer(false)}
        optionCategory={optionCategory}
        onCreateEmployer={onCreateEmployer}
        imageOrgLogo={imageOrgLogo}
        setImageOrgLogo={setImageOrgLogo}
        setImageName={setImageName}
        handleUploadImage={handleUploadImage}
        isUploadImgLoading={isUploadImgLoading}
      />
    </div>
  );
}
