export const usePaymentMethodStyle = {
  con: {
    borderRadius: "12px",
    p: "24px",
    bgcolor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  accordiun: {
    mt: "15px",
    "& .css-g9zaru-MuiPaper-root-MuiAccordion-root.Mui-expanded, .css-xg56bm.Mui-expanded":
      {
        m: 0,
      },
    "& > .Mui-expanded:not(.Mui-expanded:first-of-type)": {
      borderTop: "1px solid #E0E0E0",
    },
    "& .MuiAccordion-root:first-of-type": {
      borderRadius: "10px 10px 0 0",
    },
    "& .MuiAccordion-root:last-of-type": {
      borderRadius: "0 0 10px 10px",
    },
  },
  accordionDetails: {
    border: "1px solid #E0E0E0",
    p: "12px",
    borderRadius: "8px",
    height: "52px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  isWalletBalanceBox: {
    border: "1px solid #E0E0E0",
    p: "24px",
    borderRadius: "12px",
    mt: "16px",
  },
  payBtn: {
    display: "flex",
    justifyContent: "center",
    "& > button": {
      border: "none",
      "&:hover": { border: "none" },
      fontSize: "16px",
      width: "300px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  qrPaymentImg: {
    width: "40%",
    borderRadius: "8px",
    "& > img": {
      objectFit: "contain",
    },
  },
  craditImg: {
    width: "55px",
    height: "40px",
    borderRadius: "8px",
    "& > img": {
      objectFit: "contain",
    },
  },
  methodImg: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
};

export const useBillingStyle = {
  con: {
    borderRadius: "12px",
    p: "24px",
    bgcolor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
};

export const usePaymentSummaryStyle = {
  con: {
    borderRadius: "12px",
    p: "24px",
    bgcolor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  billingCycle: {
    borderRadius: "8px",
    border: "2px solid ",
    background: "#FFF",
    display: "flex",
    p: "12px",
    mt: "8px",
    cursor: "pointer",
  },
  billingCycleRadio: {
    width: "20px",
    height: "20px",
    mt: "5px",
    mr: "12px",
  },
  summaryBox: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    p: "6px",
    my: "16px",
    cursor: "pointer",
  },
  prepay: {
    border: "1px solid #E0E0E0",
    py: "3px",
    px: "20px",
    borderRadius: "50px",
  },
  prepaySelected: {
    border: "1px solid #E0E0E0",
    py: "3px",
    px: "20px",
    borderRadius: "50px",
    borderColor: "#fff",
    bgcolor: "#8C499C",
    color: "#fff",
  },
  planBox: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    p: "16px",
  },
  currentIcon: {
    p: "6px 12px 6px 10px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    bgcolor: "#F5EEFF",
    color: "#8C499C",
  },
  upgradePlanIcon: {
    p: "6px 12px 6px 10px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    bgcolor: "#E5F6FF",
    color: "#0077E4",
  },
  addOnCheckBox: {
    width: "20px",
    height: "20px",
    m: "5px 10px 10px 5px",
  },
  addOnBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "50px",
    my: "7px",
    minWidth: "110px",
  },
};
