import * as yup from "yup";

export const CreateEmployerFormSchema = yup.object().shape({
  name: yup.string().required("Please enter your organization name."),
  size: yup.string().required("Please select your business size."),
  type: yup.string().required("Please select your type."),
  category: yup
    .array()
    .of(yup.number())
    .min(1, "Please select at least one category."),
  fullname: yup
    .string()
    .required("Please enter your full name.")
    .matches(
      /^([A-z\u0E01-\u0E5B]+\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Full name is not valid"
    ),
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
  password: yup
    .string()
    .required("Please enter your password.")
    .matches(
      /^[A-Za-z0-9]+$/,
      "ต้องเป็นตัวอักษรตัวใหญ่หรือตัวเล็กหรือตัวเลขในภาษาอังกฤษเท่านั้น"
    )
    .matches(
      /^.{4,16}$/,
      "ต้องมีความยาวตั้งแต่ 4 ตัวอักษร และไม่เกิน 16 ตัวอักษร"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password.")
    .oneOf([yup.ref("password")], "Passwords do not match.")
    .matches(
      /^[A-Za-z0-9]+$/,
      "ต้องเป็นตัวอักษรตัวใหญ่หรือตัวเล็กหรือตัวเลขในภาษาอังกฤษเท่านั้น"
    )
    .matches(
      /^.{4,16}$/,
      "ต้องมีความยาวตั้งแต่ 4 ตัวอักษร และไม่เกิน 16 ตัวอักษร"
    ),
  phone: yup
    .string()
    .required("Please enter your phone.")
    .matches(
      /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
      "Phone number should be 10 digits"
    ),
});
