import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
// style and icon
import { useAddShippingStyle as classes } from "./AddShipping.style";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
// type and util
import { IShippingAddress } from "@api/job/type";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
// other
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import { shippingTypeOption } from "./ShippingTypeData";

interface IShippinProductProps {
  item: IShippingAddress | null;
  watch: any;
  setValue: any;
  clearErrors: any;
}

export default function ShippingProduct(props: IShippinProductProps) {
  const { item, setValue, clearErrors, watch } = props;

  const [copy, setCopy] = useState("copy");

  const handleChangeInput = (e: any, nameChange: string) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  return (
    <Box sx={classes.boxMain}>
      <Box padding="24px">
        <Typography variant="h5">Shipping Product to Influencer</Typography>
        <Typography variant="caption">
          ผู้จ้างงานส่งสินค้าสำหรับการรีวิวให้อินฟลูอินเซอร์
        </Typography>
      </Box>

      <Divider sx={{ border: "1px solid #EBDFFF" }} />

      <Box padding="24px">
        <Box sx={classes.boxAddress}>
          <Box display="flex" justifyContent="space-between">
            <Typography>ที่อยู่ในการจัดส่ง</Typography>
            <Tooltip title={copy} placement="top">
              <IconButton
                onClick={() => {
                  const contactInfo = `${item?.contact}, ${formatPhoneNumber(item?.phone || "")}`;
                  const fullAddress = item?.address;
                  const textToCopy = `${contactInfo}\n${fullAddress}`;

                  navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => setCopy("Copied"));
                }}
                onMouseOut={() => setCopy("Copy")}
                sx={{ bgcolor: "white" }}
              >
                <FileCopyOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Typography>
              {item?.contact}, {formatPhoneNumber(item?.phone || "")}
            </Typography>
            <Typography variant="caption">{item?.address}</Typography>
          </Box>
        </Box>
      </Box>

      <Box padding="24px">
        <Grid container pb={3}>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="body2">Shipping Company</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <BasicSelectCustom
              id="shipping-type-select"
              value={watch("product_shipping.shipping_type")}
              onChange={(e) =>
                handleChangeInput(e, "product_shipping.shipping_type")
              }
              data={shippingTypeOption}
              valueKey="value"
              labelKey="label"
              iconKey="img"
              iconType="imageUrl"
              placeholder="Select your shipping company"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container pb={3}>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="body2">Tracking No.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldCustom
              value={watch("product_shipping.tracking_no")}
              onChange={(e) => handleChangeInput(e, "product_shipping.tracking_no")}
              type="text"
              placeholder="Enter your tracking no."
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container pb={3}>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="body2">Note</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldCustom
              value={watch("product_shipping.note")}
              onChange={(e) => handleChangeInput(e, "product_shipping.note")}
              type="text"
              multiline
              minRows={3}
              placeholder="Enter your note"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
