import { useEffect, useState } from "react";
// mui, icon and style
import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEditMyPortfolioForMobileStyle as classes } from "../../MyProfile.style";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MyPortfolioFormSchema } from "@components/influencer-profile/InfluencerProfile.shema";
// route and type
import { useHistory, useLocation } from "react-router-dom";
import { IMyPortfolioForm } from "@components/influencer-profile/InfluencerProfile.type";
// component
import EditInfluencerPortfolio from "@components/influencer-profile/edit-influencer-profile/EditPortfolio";
import { initMyPortfolioForm } from "@components/influencer-profile/influencer-default/MyPortfolio";
// redux
import {
  selectInfluencerProfile,
  selectInfluencerProfileOnload,
  deleteInfluencerPortfolioAsync,
  updateInfluencerPortfolioAsync,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// other
import Loading from "@components/global/loading/Loading";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

export default function EditMyPortfolioForMobile() {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const portID = params.get("id");

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerProfile = useAppSelector(selectInfluencerProfile);
  const influencerLoading = useAppSelector(selectInfluencerProfileOnload);
  const portfolioInfo: IMyPortfolioForm[] = influencerProfile?.portfolio;

  const form = useForm<IMyPortfolioForm>({
    resolver: yupResolver(MyPortfolioFormSchema),
    defaultValues: initMyPortfolioForm,
  });

  useEffect(() => {
    if (!portID || !portfolioInfo) return;
    const data = portfolioInfo.find((port) => port.id === portID);
    if (data) form.reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portID, portfolioInfo]);

  const onDeletePortfolio = (portID: string) => {
    SwalCustom.fire({
      title: "Are you sure?",
      text: "You want to delete this portfolio!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4CAF50",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          dispatch(deleteInfluencerPortfolioAsync({ port_id:portID }))
            .then(async () => {
              setIsLoading(false);
              await SwalCustom.fire({
                icon: "success",
                title: "Delete portfolio success",
                showConfirmButton: false,
                timer: 1600,
              });
              history.push("/my-profile/mobile");
            })
            .catch(() => {
              setIsLoading(false);
              SwalCustom.fire({
                icon: "error",
                title: "Opps !",
                text: "Error, There are something when wrong",
                showConfirmButton: true,
                timer: 1600,
              });
            });
        } catch (error: unknown) {
          setIsLoading(false);
          await SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, There are something when wrong",
            showConfirmButton: true,
            timer: 1600,
          });
        }
      }
    });
  };

  const onUpdatePortfolio = (value: IMyPortfolioForm) => {
    setIsLoading(true);
    const body = {
      portfolio: [value],
    };
    dispatch(updateInfluencerPortfolioAsync(body))
      .then(async () => {
        setIsLoading(false);
        await SwalCustom.fire({
          icon: "success",
          title: "Updated portfolio success",
          showConfirmButton: false,
          timer: 1600,
        });
        history.push("/my-profile/mobile");
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
          timer: 1600,
        });
      });
  };

  if (isLoading || influencerLoading) return <Loading />

  return (
    <div className="container-main">
      <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt="-10px">
        <KeyboardArrowLeftIcon
          onClick={() => history.goBack()}
          sx={{ position: "absolute", left: 0 }}
        />
        <Typography variant="h6">My Portfolio</Typography>
      </Box>
      <Box sx={classes.content}>
        <EditInfluencerPortfolio
          form={form}
          onSubmit={onUpdatePortfolio}
          onDeletePortfolio={onDeletePortfolio}
        />
      </Box>
    </div>
  );
}
