// mui and style
import {
  Box,
  Grid,
  Icon,
  Stack,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { usePlaceStyle as classes } from "./JobDefault.style";
// type
import { IPlaceProps } from "./JobDefault.type";

export default function Place(props: IPlaceProps) {
  const { place } = props;

  const clearPhone = place?.phone.replace(/\D/g, "");
  const formatPhone = clearPhone?.replace(/(\d{3})(\d{4})(\d{3})/, "$1-$2-$3");

  return (
    <Box sx={classes.placeCon}>
      <Box sx={classes.title}>
        <Typography>Place</Typography>
        <Typography>สถานที่ในการรีวิว</Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Stack
        gap={[5, 8, 10]}
        flexDirection={{ xs: "column", lg: "row" }}
        p={["24px 0 24px 0", "24px"]}
      >
        <Box sx={classes.map}>
          <img src={place?.static_map_url} alt="static map" />
        </Box>
        <Box sx={classes.detailBox}>
          <Box>
            <Typography variant="h5">{place?.name}</Typography>
            <Box display="flex" mt="14px">
              <Icon className="ico-hu-location" color="primary" />
              <Box ml={5}>
                <Typography variant="title1" color="primary">
                  Address
                </Typography>
                <Typography mt={1}>{place?.address}</Typography>
              </Box>
            </Box>
            <Box display="flex" mt="14px">
              <Icon className="ico-hu-phone" color="primary" />
              <Box ml={5} width="100%">
                <Box display="flex" flexDirection="column">
                  <Typography variant="title1" color="primary">
                    Contact
                  </Typography>
                  <Typography variant="subTitle2" mt={1}>
                    โปรดทำการติดต่อเจ้าหน้าที่เพื่อทำการนัดหมาย
                    ก่อนเดินทางไปยังสถานที่จริง
                  </Typography>
                </Box>
                <Box component="ul" display="flex" gap={[10, 5]}>
                  <Box width="100%">
                    {[
                      { label: "Contact Name", txt: place?.contact },
                      { label: "Phone", txt: formatPhone },
                    ].map((contact) => (
                      <Grid
                        container
                        key={contact.label}
                        display="flex"
                        ml={[-5, 0]}
                        mb={2}
                      >
                        <Grid item xs={12} sm={4} md={3}>
                          <Typography component="li">
                            {contact.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={9}>
                          <Typography variant="title1">
                            {contact.txt}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Stack
            gap={3}
            sx={classes.btn}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Button
              variant="outlined"
              startIcon={<Icon className="ico-hu-location" />}
              onClick={() => window.open(place?.url, "_blank")}
            >
              เส้นทาง
            </Button>
            <a href={`tel:+66${place?.phone.slice(1)}`}>
              <Button
                variant="contained"
                startIcon={<Icon className="ico-hu-phone" />}
                fullWidth
              >
                ทำการนัดหมาย
              </Button>
            </a>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
