import React from "react";
import { Box, Icon, Stack } from "@mui/material";
import ReactPlayer from "react-player";

interface IVideoProps {
  item: {
    file_name: string;
    url: string;
  }[];
  height: string;
}

export const VideoFormat = (props: IVideoProps) => {
  const { item, height } = props;

  const itemHeight = height.replace(/px/, "");

  const style = {
    postImgProfile: {
      my: "16px",
    },
    bgVideo: {
      position: "absolute",
      borderRadius: "4px",
      bgcolor: "rgba(0,0,0,0.3)",
      top: 0,
      left: 0,
      // width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > .ico-hu-play-circle-outline": {
        fontSize: "50px",
        color: "#fff",
      },
    },
    postVideoItem1: {
      "& video": {
        // width: "100%",
        // height: height,
        borderRadius: "4px",
        objectFit: "cover",
      },
      position: "relative",
    },
    postImgItem2: {
      display: "flex",
      height: height,
      justifyContent: "center",
      "& div:first-of-type": {
        "& > video, > div": {
          borderRadius: "4px 0 0 4px",
        },
      },
      "& div:last-of-type": {
        "& > video, > div": {
          borderRadius: "0 4px 4px 0",
        },
      },
      "& > div": {
        "& video": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        width: "100%",
        height: "100%",
        position: "relative",
      },
    },
    postImgItem3: {
      display: "flex",
      height: height,
      "& > div:first-of-type": {
        borderRadius: "4px 0 0 4px",
        "& video": {
          width: "100%",
          ml: 0,
          objectFit: "cover",
          borderRadius: "4px 0 0 4px",
          height:
            Number(itemHeight) < 280
              ? Number(itemHeight) + 3
              : Number(itemHeight),
        },
      },
      "& > div:last-of-type": {
        width: "100%",
        overflow: "hidden",
        "& > div": {
          height: "50%",
          "& video": {
            height: "98.5%",
            width: "95%",
            ml: "5px",
            objectFit: "cover",
          },
        },
        "& > div:first-of-type div ": {
          borderRadius: "0 4px 0 0",
          // border: "1px solid red",
        },
        "& > div:last-of-type div": {
          borderRadius: "0 0 4px 0",
          mt: "1.5px",
        },
      },
    },
    postImgItem4: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > div:first-of-type": {
        width: "60%",
        mr: "6px",
        "& video": {
          // width: "100%",
          // height: height,
          borderRadius: "4px",
          objectFit: "cover",
        },
      },
      "& > div:last-of-type": {
        justifyContent: "space-between",
        width: "40%",
        "& div": {
          borderRadius: "4px",
          "& video": {
            borderRadius: "4px",
            objectFit: "cover",
            height: Number(itemHeight) / 3 - 7,
          },
        },
      },
    },
    postImgItemMoreThan4: {
      position: "absolute",
      width: "100%",
      height: `calc((${height} / 3) - 3px)`,
      bottom: 0,
      left: 0,
      borderRadius: "4px",
      bgcolor: "rgba(0,0,0,0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > p": {
        color: "#fff",
        fontSize: "26px",
      },
    },
  };

  return (
    <Box sx={style.postImgProfile}>
      {item.length === 1 ? (
        <Box sx={style.postVideoItem1}>
          <Box width="100%" height="100%" sx={style.bgVideo}>
            <Icon className="ico-hu-play-circle-outline" />
          </Box>
          <ReactPlayer
            url={item[0]?.url}
            width="100%"
            height={height}
            loop={true}
            muted={true}
          />
        </Box>
      ) : item.length === 2 ? (
        <Box sx={style.postImgItem2}>
          {item.map((itemImg, index) => (
            <Box key={index}>
              <Box width="100%" height="100%" sx={style.bgVideo}>
                <Icon className="ico-hu-play-circle-outline" />
              </Box>
              <ReactPlayer
                url={itemImg.url}
                width="100%"
                height="100%"
                loop={true}
                muted={true}
              />
            </Box>
          ))}
        </Box>
      ) : item.length === 3 ? (
        <Box sx={style.postImgItem3}>
          <Box
            position="relative"
            sx={{ "& > div": { borderRadius: "4px 0 0 4px" } }}
          >
            <Box width="100%" height="100%" sx={style.bgVideo}>
              <Icon className="ico-hu-play-circle-outline" />
            </Box>
            <ReactPlayer
              url={item[0].url}
              height="100%"
              width="100%"
              loop={true}
              muted={true}
            />
          </Box>
          <Box>
            {item.slice(1).map((itemImg, index) => (
              <Box key={index} position="relative">
                <Box width="93%" height="100%" ml={1} sx={style.bgVideo}>
                  <Icon className="ico-hu-play-circle-outline" />
                </Box>
                <ReactPlayer
                  url={itemImg.url}
                  height="100%"
                  width="95%"
                  loop={true}
                  muted={true}
                  style={{ overflow: "hidden" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : item.length >= 4 ? (
        <Box sx={style.postImgItem4}>
          <Box position="relative" mt={1}>
            <Box width="100%" height="100%" sx={style.bgVideo}>
              <Icon className="ico-hu-play-circle-outline" />
            </Box>
            <ReactPlayer
              url={item[0].url}
              width="100%"
              height={height}
              loop={true}
              muted={true}
            />
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            mt={0.5}
            rowGap={1}
            sx={{
              position: "relative",
              // overflow: "hidden",
              height: height,
            }}
          >
            {item.slice(1, 4).map((itemImg, indexImg) => (
              <React.Fragment key={indexImg}>
                <Box
                  position="relative"
                  sx={{
                    "& video": {
                      borderRadius: "4px",
                      // border: "1px solid red",
                      height: "100%",
                      width: "100%",
                    },
                  }}
                >
                  <Box width="100%" height="100%" sx={style.bgVideo}>
                    <Icon className="ico-hu-play-circle-outline" />
                  </Box>
                  <ReactPlayer
                    url={itemImg.url}
                    width="100%"
                    height={`calc((${height} / 3) - 3px)`}
                    loop={true}
                    muted={true}
                  />
                </Box>
                {item.length > 4 && (
                  <Box sx={style.postImgItemMoreThan4}>
                    <p>+ {item.length - 4}</p>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
