export const useEditBillingStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  dialogContent: {
    marginTop: "10px",
    paddingTop: 3,
    paddingBottom: 0,
  },
  profileContainerBox: {
    width: "160px",
    height: "160px",
  },
  profileBox: {
    borderRadius: "4px",
    width: ["150px", "170px"],
    height: ["150px", "170px"],
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
    "& img": {
      width: ["150px", "170px"],
    },
  },
  profileButton: {
    position: "absolute",
    bottom: "-6px",
    right: "-12px",
    height: "30px",
    maxWidth: "30px",
    minWidth: "30px",
    bgcolor: "primary.main",
    border: "none",
    p: 0,
    fontSize: "20px",
    "&:hover": {
      bgcolor: "primary.main",
      border: "none",
    },
  },
  imagesContainerBox: {
    width: "120px",
    height: "120px",
    marginBottom: 1,
  },
  imagesBox: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
      borderRadius: "10px",
    },
  },
  imagesButton: {
    position: "absolute",
    bottom: "-6px",
    right: "-12px",
    height: "26px",
    maxWidth: "26px",
    minWidth: "26px",
    bgcolor: "primary.main",
    border: "none",
    p: 0,
    fontSize: "16px",
    "&:hover": {
      bgcolor: "primary.main",
      border: "none",
    },
  },
  addCategoryButton: {
    "& .MuiSvgIcon-root": {
      color: "common.white",
    },
  },
  categoryListBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1.5,
    marginBlock: "0px",
    paddingTop: "0px",
  },
  categoryChip: {
    height: "27px",
    "& .MuiSvgIcon-root.MuiChip-deleteIcon": {
      fontSize: "13px",
    },
  },
  visibilityOffIcon: { color: "grey.500" },

  iconButton: {
    color: "grey.500",
    fontSize: "18px",
  },
  iconInput: {
    color: "grey.500",
    fontSize: "16px",
    paddingRight: "2px",
  },
};
