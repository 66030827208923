// mui and style
import { useState } from "react";
import {
  Box,
  Icon,
  Grid,
  Menu,
  Stack,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { usePostSinglePhotoImageStyle as classes } from "../../EmployerJob.style";
// type
import { IPostSinglePhotoImageProps } from "../../EmployerJob.type";
import { IAttachments, IIsApproved } from "@api/app-information/type";
// others
import ReactPlayer from "react-player";
import TextFieldCustom from "@global/text-field";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";

export default function PostVideoList(props: IPostSinglePhotoImageProps) {
  const {
    jobInfo,
    isJobRejected,
    form: { watch, register, setValue },
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<string | null>(null);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [video, setVideo] = useState<IAttachments[] | []>([]);

  const open = Boolean(anchorEl);
  const attachments = jobInfo?.attachments || [];
  const urls = jobInfo?.external_file || [];

  const attApprovedList = attachments.filter(
    (att) => att.is_approve === "approved"
  );

  const attRejectedList = attachments.filter(
    (att) => att.is_approve === "not_approved" || att.is_approve === "drop"
  );
  
  const urlRejectedList = urls.filter(
    (url) => url.is_approve === "not_approved" || url.is_approve === "drop"
  );

  const handleEditSelected = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(id);
  };

  const handleCloseEditSelected = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleSelectToEditJob = (
    key: string,
    isApprove: IIsApproved,
    isVideo?: boolean
  ) => {
    handleEditVideo(key, isApprove, isVideo);
    handleCloseEditSelected();
  };

  const handleEditVideo = (
    key: string,
    isApprove: IIsApproved,
    isVideo?: boolean
  ) => {
    const attList = [...watch("attachments")];
    const urlList = [...watch("external_file")];
    const videoList = attList || urlList;
    const isUnSelectAproved =
      videoList.filter((att) => att.is_approve === "approved" && att.file_id === key).length 
      && isApprove === "approved"
      ? true
      : false;
    const isUnselectEdit =
      videoList.filter((att) => att.is_approve === "approved").length === 1 
      && isApprove !== "approved"
      ? true
      : false;
    const attachments = attList.map((att) => {
      return {
        ...att,
        is_approve:
          isUnSelectAproved || isUnselectEdit
            ? "new"
            : isApprove === "approved"
            ? "drop"
            : att.is_approve,
        note: isUnSelectAproved
          ? ""
          : isApprove === "approved"
          ? "สามารถเอาวิดีโอออกได้เลย"
          : att.note,
      };
    });
    const urls = urlList.map((url) => {
      return {
        ...url,
        is_approve:
          isUnSelectAproved || isUnselectEdit
            ? "new"
            : isApprove === "approved"
            ? "drop"
            : url.is_approve,
        note: isUnSelectAproved
          ? ""
          : isApprove === "approved"
          ? "สามารถเอาวิดีโอออกได้เลย"
          : url.note,
      };
    });
    if (isVideo) {
      const attIndex = attachments.findIndex((att) => att.file_id === key);
      attachments[attIndex].is_approve = isUnSelectAproved ? "new" : isApprove;
      attachments[attIndex].note = isApprove === "drop" ? "สามารถเอาวิดีโอออกได้เลย" : "";
      setValue("attachments", attachments);
      setValue("external_file", urls);
    } else {
      const urlIndex = urls.findIndex((url) => url.file_id === key);
      urls[urlIndex].is_approve = isUnSelectAproved ? "new" : isApprove;
      urls[urlIndex].note = isApprove === "drop" ? "สามารถเอาวิดีโอออกได้เลย" : "";
      setValue("external_file", urls);
      setValue("attachments", attachments);
    }
  };

  const isEditButton = (isApprove?: IIsApproved) => {
    if (isApprove === "not_approved" || isApprove === "drop") {
      return true;
    } else {
      return false;
    }
  };

  const handleViewVideo = (vdo: IAttachments) => {
    setOpenVideoDialog(true);
    setVideo([vdo]);
  };

  return (
    <Box sx={classes.imgCardCon}>
      <div>
        <Typography variant="h6">
          {isJobRejected
            ? "Comments on the Video"
            : "Choosing the Perfect video for Your Campaign"}
        </Typography>
        <Typography variant="subTitle1" color="#505050">
          {isJobRejected
            ? "ความคิดเห็นเกี่ยวกับวิดีโอ"
            : "เลือก 1 วิดีโอที่ดีที่สุดสำหรับแคมเปญของคุณ"}
        </Typography>
      </div>
      <Divider sx={classes.divider} />
      {isJobRejected && attRejectedList.length ? (
        <div>
          <Typography variant="title1" color="#8C499C">
            วิดีโออัปโหลดจากคอมพิวเตอร์
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-close" color="error" />
                <Typography sx={classes.imgToEditTxt} ml={2}>
                  วิดีโอที่ไม่ได้รับการอนุมัติ
                </Typography>
              </Box>
            </Grid>
            {attRejectedList.map((att, index) => (
              <Grid item xs={4} key={index}>
                <Typography variant="title1">{`วิดีโอที่ ${att.order}`}</Typography>
                <Box onClick={() => handleViewVideo(att)} sx={classes.videoBox}>
                  <Box sx={{ ...classes.iconPlayVdo, width: "100%" }}>
                    <Icon className="ico-hu-play-circle-outline" />
                  </Box>
                  <ReactPlayer
                    url={att.url}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={true}
                  />
                </Box>
                <Box mt="16px">
                  <Typography variant="title2" color="error">
                    ความคิดเห็นจากผู้จ้างงาน :
                  </Typography>
                  <Box sx={classes.influComment}>
                    <Typography>{att?.note}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
            <Divider sx={{ ...classes.divider, mt: -3 }} />
          </Grid>
        </div>
      ) : watch("attachments").length ? (
        <div>
          <Typography variant="title1" color="#8C499C">
            วิดีโออัปโหลดจากคอมพิวเตอร์
          </Typography>
          <Grid container spacing={4} mt="12px">
            {!attApprovedList.length &&
              watch("attachments").map((att, index) => (
                <Grid item xs={4} key={index}>
                  <Typography variant="title1">{`วิดีโอที่ ${att.order}`}</Typography>
                  <Box
                    onClick={() => handleViewVideo(att)}
                    sx={classes.videoBox}
                  >
                    <Box sx={{ ...classes.iconPlayVdo, width: "100%" }}>
                      <Icon className="ico-hu-play-circle-outline" />
                    </Box>
                    <ReactPlayer
                      url={att.url}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={true}
                    />
                  </Box>

                  {!isJobRejected ? (
                    <>
                      <Stack direction="row" spacing={2} m="16px 0 16px 0">
                        <Button
                          fullWidth
                          variant={
                            att.is_approve === "approved"
                              ? "contained"
                              : "outlined"
                          }
                          color="success"
                          startIcon={
                            <Icon
                              className="ico-hu-check"
                              sx={{ fontSize: "16px" }}
                            />
                          }
                          onClick={() =>
                            handleEditVideo(att.file_id, "approved", true)
                          }
                          sx={{
                            border:
                              att.is_approve !== "approved"
                                ? "1px solid #4CAF50"
                                : "none",
                            color:
                              att.is_approve === "approved"
                                ? "#fff"
                                : "#4CAF50",
                            fontSize: "14px",
                            "&:hover": {
                              border:
                                att.is_approve !== "approved"
                                  ? "1px solid #4CAF50"
                                  : "none",
                            },
                            maxHeight: "33px",
                          }}
                        >
                          อนุมัติ
                        </Button>
                        <Button
                          fullWidth
                          id={`select-edit-button-${att.file_id}`}
                          aria-controls={
                            open ? `select-edit-menu-${att.file_id}` : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant={
                            isEditButton(att.is_approve)
                              ? "contained"
                              : "outlined"
                          }
                          color={
                            att.is_approve === "not_approved"
                              ? "warning"
                              : "error"
                          }
                          startIcon={
                            <Icon
                              className="ico-hu-close"
                              sx={{ fontSize: "16px" }}
                            />
                          }
                          onClick={(e) => handleEditSelected(e, att.file_id)}
                          sx={{
                            border: isEditButton(att.is_approve)
                              ? "none"
                              : "1px solid #E30D18",
                            color: isEditButton(att.is_approve)
                              ? "#fff"
                              : "#E30D18",
                            fontSize: "14px",
                            "&:hover": {
                              border: isEditButton(att.is_approve)
                                ? "none"
                                : "1px solid #E30D18",
                            },
                            maxHeight: "33px",
                          }}
                        >
                          {att.is_approve === "drop"
                            ? "เอาออก"
                            : att.is_approve === "not_approved"
                            ? "ต้องแก้ไข"
                            : "ไม่อนุมัติ"}
                        </Button>
                        <Menu
                          id={`select-edit-button-${att.file_id}`}
                          anchorEl={anchorEl}
                          open={selectedIndex === att.file_id}
                          onClose={handleCloseEditSelected}
                          MenuListProps={{
                            "aria-labelledby": `select-edit-menu-${att.file_id}`,
                          }}
                          sx={classes.menu}
                        >
                          <MenuItem
                            onClick={() =>
                              handleSelectToEditJob(att.file_id, "not_approved", true)
                            }
                          >
                            <Typography variant="body2">ต้องแก้ไข</Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleSelectToEditJob(att.file_id, "drop", true)
                            }
                          >
                            <Typography variant="body2">เอาออก</Typography>
                          </MenuItem>
                        </Menu>
                      </Stack>
                      <Box>
                        <Typography
                          variant="title2"
                          color={
                            att.is_approve === "not_approved"
                              ? "#FB8C00"
                              : undefined
                          }
                          mb="8px"
                        >
                          ความคิดเห็นจากผู้จ้างงาน :
                        </Typography>
                        <TextFieldCustom
                          variant="outlined"
                          multiline
                          minRows={3}
                          placeholder="โปรดระบุความคิดเห็น..."
                          fullWidth
                          disabled={att.is_approve !== "not_approved"}
                          sx={classes.noteEdit}
                          inputProps={{
                            ...register(`attachments.${index}.note`),
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <Box sx={classes.influComment}>
                      <Typography variant="title2">
                        ความคิดเห็นจากผู้จ้างงาน :
                      </Typography>
                      <Typography variant="body2">{att.note}</Typography>
                    </Box>
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
      ) : null}
      {isJobRejected && urlRejectedList.length ? (
        <Box mt="32px">
          <Typography variant="title1" color="#8C499C">
            แนบลิงค์ URL
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-close" color="error" />
                <Typography sx={classes.imgToEditTxt} ml={2}>
                  วิดีโอที่ไม่ได้รับการอนุมัติ
                </Typography>
              </Box>
            </Grid>
            {urlRejectedList.map((att, index) => (
              <Grid item xs={4} key={index}>
                <Typography variant="title1">{`รูปที่ ${att.order}`}</Typography>
                <Box
                  sx={classes.urlBox}
                  onClick={() => window.open(att.url, "_blank")}
                >
                  <Box sx={classes.urlIcon}>
                    <Icon className="ico-hu-conversion" />
                    <Typography variant="body2" mt="15px">
                      Video URL
                    </Typography>
                  </Box>
                  <Box sx={classes.urlImg}>
                    <img src={urlLogo} alt="item" />
                  </Box>
                </Box>

                <Box mt="16px">
                  <Typography variant="title2" color="error">
                    ความคิดเห็นจากผู้จ้างงาน :
                  </Typography>
                  <Box sx={classes.influComment}>
                    <Typography>{att?.note}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
            <Divider sx={{ ...classes.divider, mt: -3 }} />
          </Grid>
        </Box>
      ) : urls.filter((ext) => ext.is_approve === "new").length ? (
        <Box mt="32px">
          <Typography variant="title1" color="#8C499C">
            แนบลิงค์ URL
          </Typography>
          <Grid container spacing={4} mt="10px">
            {watch("external_file").map((ext, index) => (
              <Grid item xs={4} key={index}>
                <Typography variant="title1">{`วิดีโอที่ ${ext.order}`}</Typography>
                <Box
                  sx={classes.urlBox}
                  onClick={() => window.open(ext.url, "_blank")}
                >
                  <Box sx={classes.urlIcon}>
                    <Icon className="ico-hu-conversion" />
                    <Typography variant="body2" mt="15px">
                      Video URL
                    </Typography>
                  </Box>
                  <Box sx={classes.urlImg}>
                    <img src={urlLogo} alt="item" />
                  </Box>
                </Box>

                {!isJobRejected ? (
                  <>
                    <Stack direction="row" spacing={2} m="16px 0 16px 0">
                      <Button
                        fullWidth
                        variant={
                          ext.is_approve === "approved"
                            ? "contained"
                            : "outlined"
                        }
                        color="success"
                        startIcon={
                          <Icon
                            className="ico-hu-check"
                            sx={{ fontSize: "16px" }}
                          />
                        }
                        onClick={() =>
                          handleEditVideo(ext.file_id, "approved", false)
                        }
                        sx={{
                          border:
                            ext.is_approve !== "approved"
                              ? "1px solid #4CAF50"
                              : "none",
                          color:
                            ext.is_approve === "approved" ? "#fff" : "#4CAF50",
                          fontSize: "14px",
                          "&:hover": {
                            border:
                              ext.is_approve !== "approved"
                                ? "1px solid #4CAF50"
                                : "none",
                          },
                          maxHeight: "33px",
                        }}
                      >
                        อนุมัติ
                      </Button>
                      <Button
                        fullWidth
                        id={`select-edit-button-${ext.file_id}`}
                        aria-controls={
                          open ? `select-edit-menu-${ext.file_id}` : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        variant={
                          isEditButton(ext.is_approve)
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          ext.is_approve === "not_approved"
                            ? "warning"
                            : "error"
                        }
                        startIcon={
                          <Icon
                            className="ico-hu-close"
                            sx={{ fontSize: "16px" }}
                          />
                        }
                        onClick={(e) => handleEditSelected(e, ext.file_id)}
                        sx={{
                          border: isEditButton(ext.is_approve)
                            ? "none"
                            : "1px solid #E30D18",
                          color: isEditButton(ext.is_approve)
                            ? "#fff"
                            : "#E30D18",
                          fontSize: "14px",
                          "&:hover": {
                            border: isEditButton(ext.is_approve)
                              ? "none"
                              : "1px solid #E30D18",
                          },
                          maxHeight: "33px",
                        }}
                      >
                        {ext.is_approve === "drop"
                          ? "เอาออก"
                          : ext.is_approve === "not_approved"
                          ? "ต้องแก้ไข"
                          : "ไม่อนุมัติ"}
                      </Button>
                      <Menu
                        id={`select-edit-button-${ext.file_id}`}
                        anchorEl={anchorEl}
                        open={selectedIndex === ext.file_id}
                        onClose={handleCloseEditSelected}
                        MenuListProps={{
                          "aria-labelledby": `select-edit-menu-${ext.file_id}`,
                        }}
                        sx={classes.menu}
                      >
                        <MenuItem
                          onClick={() =>
                            handleSelectToEditJob(ext.file_id, "not_approved", false)
                          }
                        >
                          <Typography variant="body2">ต้องแก้ไข</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleSelectToEditJob(ext.file_id, "drop", false)
                          }
                        >
                          <Typography variant="body2">เอาออก</Typography>
                        </MenuItem>
                      </Menu>
                    </Stack>
                    <Box>
                      <Typography
                        variant="title2"
                        color={
                          ext.is_approve === "not_approved"
                            ? "#FB8C00"
                            : undefined
                        }
                        mb="8px"
                      >
                        ความคิดเห็นจากผู้จ้างงาน :
                      </Typography>
                      <TextFieldCustom
                        variant="outlined"
                        multiline
                        minRows={3}
                        placeholder="โปรดระบุความคิดเห็น..."
                        fullWidth
                        disabled={ext.is_approve !== "not_approved"}
                        sx={classes.noteEdit}
                        inputProps={{
                          ...register(`external_file.${index}.note`),
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <Box sx={classes.influComment}>
                    <Typography variant="title2">
                      ความคิดเห็นจากผู้จ้างงาน :
                    </Typography>
                    <Typography variant="body2">{ext.note}</Typography>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
      {jobInfo?.status === "rejected" || attApprovedList.length ? (
        <>
          {attRejectedList.length ? <Divider sx={{ my: "32px" }} /> : ""}
          <Box>
            <Box display="flex" alignItems="center">
              <Icon className="ico-hu-check" color="success" />
              <Typography color="#4CAF50" variant="title1" ml={2}>
                วิดีโอที่ได้รับอนุมัติ
              </Typography>
            </Box>
            <Grid container spacing={4} mt="5px">
              {attApprovedList.length ? (
                attApprovedList.map((item, index) => (
                  <Grid item key={index} xs={4}>
                    <Typography variant="title1">
                      {`วิดีโอที่ ${item.order}`}
                    </Typography>
                    <Avatar
                      key={index}
                      sx={classes.imgItemsPost}
                      src={item.url}
                    />
                  </Grid>
                ))
              ) : (
                <Typography ml={5}>ไม่พบวิดีโอที่ได้รับการอนุมัติ</Typography>
              )}
            </Grid>
          </Box>
        </>
      ) : null}
      <ViewMultiVideoDialog
        open={openVideoDialog}
        onClose={setOpenVideoDialog}
        videos={video}
      />
    </Box>
  );
}
