import {
  IEditEmployerForm,
  IBillingAddressForm,
  IShippingAddressForm,
} from "../edit-employer/EditEmployer.type";
import { ICreateEmployerForm } from "./CreateEmployer.type";
import { IAttachments } from "@api/app-information/type";

export const initBillingAddressForm: IBillingAddressForm = {
  fullname: "",
  province: null,
  district: null,
  subdistrict: null,
  postcode: "",
  address: "",
  tax_id: "",
};

export const initShippingAddressForm: IShippingAddressForm = {
  fullname: "",
  phone: "",
  province: null,
  district: null,
  subdistrict: null,
  postcode: "",
  address: "",
};

export const initImage: IAttachments = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};

export const initEmployerForm: ICreateEmployerForm = {
  created_by: "admin",
  fullname: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  line_id: "",
  id: 0,
  name: "",
  size: "",
  type: "",
  category: [],
  attachment: initImage,
};

export const initEditEmployerForm: IEditEmployerForm = {
  fullname: "",
  email: "",
  phone: "",
  line_id: "",
  name: "",
  size: "",
  type: "",
  category: [],
  attachment: initImage,
};

export const optionBusinessSize = [
  {
    id: 1,
    value: "small",
    label: "Small Business",
  },
  {
    id: 2,
    value: "medium",
    label: "Medium Business",
  },
  {
    id: 3,
    value: "large",
    label: "Large Business",
  },
];

export const optionType = [
  {
    id: 1,
    value: "brand or company",
    label: "Brand or Company",
  },
  {
    id: 2,
    value: "agency",
    label: "Agency",
  },
  {
    id: 3,
    value: "individual",
    label: "Individual",
  },
  {
    id: 4,
    value: "enterprise",
    label: "Enterprise",
  },
  {
    id: 5,
    value: "other",
    label: "Other",
  },
];
