// mui and style
import { Box, Icon, Typography, Button, Link } from "@mui/material";
import { useVertifyEmail as classes } from "./verifyEmail.styles";
// other
import { useHistory } from "react-router-dom";

export default function VerifyEmailSuccess() {
  const history = useHistory();

  const backToHome = () => history.push("/");

  return (
    <div>
      <Box sx={classes.boxDialog}>
        <Box sx={classes.boxIconSuccess}>
          <Icon className="ico-hu-mail" sx={classes.mailIconFailed} />
        </Box>
        <Box sx={classes.boxContent}>
          <Typography variant="h4">Email Verification success</Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: "16px", fontWeight: "400" }}
          >
            You have successfully create a HashU account
          </Typography>
        </Box>
        <Box sx={classes.boxButton}>
          <Button
            onClick={() => {
              backToHome();
            }}
            sx={classes.resendButton}
          >
            Sign in
          </Button>
          <Link href={"/"} sx={{ ...classes.backHompage, marginTop: "15px" }}>
            Back to Homepage
          </Link>
        </Box>
      </Box>
    </div>
  );
}
