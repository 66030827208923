import { useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Paper,
  Stack,
  Table,
  Avatar,
  Divider,
  Tooltip,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import { useReferralsTabStyle as classes } from "./ReferralsTab.style";
import { TableCellCustom } from "@components/employer-expense/Expense.style";
import { TableRowCustom } from "@components/influencer-earning/Earning.style";
// api and type
import { InfluencerAPI } from "@api/global";
import { IDataReferral, IReferralParams } from "@api/influencer/type";
// other
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Loading from "@global/loading/Loading";
import PaginationCustom from "@global/pagination-custom";

export const sortList = [
  {
    key: "",
    value: "",
    label: "All",
  },
  {
    key: "date",
    value: "date",
    label: "Date",
  },
  {
    key: "email",
    value: "email",
    label: "E-mail",
  },
  {
    key: "user_id",
    value: "user_id",
    label: "User ID",
  },
  {
    key: "name",
    value: "name",
    label: "User Name",
  },
];

export default function ReferralsTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [copy, setCopy] = useState("copy");
  const [referralList, setReferralList] = useState<IDataReferral>();
  const [referralParams, serReferralParams] = useState<IReferralParams>({
    item_per_page: 10,
    page: 1,
    order_by: "date",
    order: "asc",
  });

  const { influencerId } = useParams<{ influencerId: string }>();
  const totalItem = referralList?.total_item || 0;

  const numberFormat = Intl.NumberFormat("en");

  useEffect(() => {
    serReferralParams({
      item_per_page: rowsPerPage,
      page: paginate,
      order_by: "date",
      order: "asc",
    });
  }, [paginate, rowsPerPage]);

  useEffect(() => {
    getReferral(influencerId, referralParams);
  }, [referralParams, influencerId]);

  const getReferral = async (
    influencerId: string,
    referralParams: IReferralParams
  ) => {
    setIsLoading(true);

    InfluencerAPI.getInfluencerReferral(influencerId, referralParams)
      .then((response) => {
        setReferralList(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("can not get referral error : ", err);
      });
  };

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleSort = (order_by: string, order: string) => {
    serReferralParams((prev) => {
      return {
        ...prev,
        order_by: order_by,
        order: order,
      };
    });
  };

  if (isLoading) return <Loading />;

  return (
    <Box sx={classes.container}>
      <Box sx={classes.boxDetail}>
        <Box padding="24px">
          <Typography variant="h6">Referral Program</Typography>
        </Box>
        <Divider />
        <Grid container spacing={4} p="24px">
          {[
            {
              label: "Total Earnings",
              value: referralList?.total_referer,
              icon: "ico-hu-group",
            },
            {
              label: "Total Points",
              value: referralList?.total_point,
              icon: "ico-hu-star",
            },
            {
              label: "Current Ranking",
              value: numberFormat.format(referralList?.referer_ranking || 0),
              icon: "ico-hu-person",
            },
          ].map((item, index) => (
            <Grid item key={index} xs={4}>
              <Box sx={classes.boxStatus}>
                <Box>
                  <Typography>{item.label}</Typography>
                  <Typography variant="h4" color="#893DBD">
                    {item.value}
                  </Typography>
                </Box>
                <Box sx={classes.boxIcon}>
                  <Icon className={item.icon} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4} p="24px">
          {[
            {
              label: "Coppy referral code",
              value: referralList?.referer_code,
            },
            {
              label: "Coppy referral link",
              value: referralList?.referer_link,
            },
          ].map((item, index) => (
            <Grid item xs={6} key={index}>
              <Box>
                <Typography>{item.label}</Typography>
                <Box sx={classes.boxCode}>
                  <Typography variant="h6">{item.value}</Typography>
                  <Tooltip title={copy} placement="top">
                    <Icon
                      className="ico-hu-copy"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(item.value || "")
                          .then(() => setCopy("Copied"));
                      }}
                      onMouseOut={() => setCopy("Copy")}
                      sx={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt="24px" sx={classes.boxDetail}>
        <Box padding="24px">
          <Typography variant="h6">Referral Program</Typography>
        </Box>

        {(referralList?.item || []).length ? (
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {[
                    { label: "Date", orderBy: "date" },
                    { label: "User Name", orderBy: "name" },
                    { label: "User ID", orderBy: "user_id" },
                    { label: "E-mail", orderBy: "email" },
                  ].map((item, index) => {
                    const sorted = `${referralParams.order_by}_${referralParams.order}`;
                    let isDESC = sorted === `${item.orderBy}_desc`;
                    return (
                      <TableCellCustom key={index}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="title2">{item.label}</Typography>
                          <Icon
                            className={`ico-hu-arrow-${isDESC ? "up_1" : "up"}`}
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleSort(item.orderBy, isDESC ? "asc" : "desc");
                            }}
                          />
                        </Box>
                      </TableCellCustom>
                    );
                  })}
                  <TableCellCustom align="center">Points</TableCellCustom>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralList?.item.map((item, index) => (
                  <TableRowCustom key={index}>
                    <TableCellCustom align="left">
                      <Typography>
                        {dayjs(item.date).format("DD/MM/YYYY - HH:mm")}
                      </Typography>
                    </TableCellCustom>
                    <TableCellCustom align="left">
                      <Stack direction="row" alignItems="center">
                        <Avatar
                          variant="square"
                          src={item.influencer_profile_url}
                        />
                        <Box ml={2.5}>
                          <Typography variant="title1" color="#8C499C">
                            {item.fullname}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCellCustom>
                    {[`ID : ${item.user_id}`, item.email, item.point].map(
                      (item, i) => (
                        <TableCellCustom
                          key={i}
                          align={i === 2 ? "center" : "left"}
                        >
                          <Typography>{item}</Typography>
                        </TableCellCustom>
                      )
                    )}
                  </TableRowCustom>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={classes.noReferral}>
            <img
              src={require("@assets/images/infographic/influencer/search-not-found.png")}
              alt="no campaign"
            />
            <Box sx={classes.textNoReferral}>
              <Typography variant="h5" my="8px">
                ไม่พบผลการค้นหา
              </Typography>
              <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
            </Box>
          </Box>
        )}
      </Box>

      {(referralList?.item || []).length ? (
        <Box sx={classes.paginate}>
          <PaginationCustom
            page={paginate}
            count={paginate > 0 ? Math.ceil(totalItem / rowsPerPage) : 0}
            onPaginationChange={handleChangePage}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </Box>
  );
}
