import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Dialog,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useInfluencerBankAccountStyle as classes } from "../InfluencerProfile.style";
// type
import { IBankAccountForm } from "../InfluencerProfile.type";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BankAccountFormSchema } from "../InfluencerProfile.shema";
// redux
import {
  selectInfluencerProfile,
  updateInfluencerBankAccountAsync,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// component
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import EditInfluencerBankAccount from "../edit-influencer-profile/EditBankAccount";
// other
import { useHistory } from "react-router-dom";
import { formatTaxId } from "@utils/helpers/formateNumber.helpers";

export const initBankAccountForm: IBankAccountForm = {
  account_name: "",
  account_number: "",
  bank: null,
  account_type: null,
  tax_id: "",
  id_card_image_url: null,
  book_bank_image_url: null,
};

export default function InfluencerBankAccount() {
  const [openToUpdateBankDialog, setOpenToUpdateBankDialog] = useState(false);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const bankAccountInfo = influencerInfo?.bank_account;

  const form = useForm<IBankAccountForm>({
    resolver: yupResolver(BankAccountFormSchema),
    defaultValues: initBankAccountForm,
  });

  useEffect(() => {
    if (!bankAccountInfo) return;
    const data = {
      account_name: bankAccountInfo.account_name,
      account_number: bankAccountInfo.account_number,
      bank: bankAccountInfo.bank,
      account_type: bankAccountInfo.account_type,
      tax_id: bankAccountInfo.tax_id,
      id_card_image_url: bankAccountInfo.id_card_image_url,
      book_bank_image_url: bankAccountInfo.book_bank_image_url,
    };
    form.reset(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountInfo]);

  const mapAccountType = (type?: string) => {
    switch (type) {
      case "saving":
        return "บัญชีออมทรัพย์";
      case "current":
        return "บัญชีกระแสรายวัน";
      default:
        break;
    }
  };

  const onUpdateBankAccount = (value: IBankAccountForm) => {
    if (!value.bank) return;
    let body = {
      bank_account: {
        account_name: value.account_name,
        account_number: value.account_number,
        bank_id: value.bank.id,
        tax_id: value.tax_id.split("-").join(""),
        account_type: value.account_type,
        id_card_image_url: value.id_card_image_url,
        book_bank_image_url: value.book_bank_image_url,
      },
    };

    dispatch(updateInfluencerBankAccountAsync(body))
      .then(() => {
        setOpenToUpdateBankDialog(false);
        SwalCustom.fire({
          icon: "success",
          title: "Updated Bank account success",
          showConfirmButton: false,
          timer: 1600,
        });
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
          timer: 1600,
        });
      });
  };

  const onEditBankAccount = () => {
    if (xsScreen) history.push("/my-profile/mobile/edit-bank-account");
    if (!xsScreen) setOpenToUpdateBankDialog(true);
  };

  return (
    <Box p="20px">
      <Box display="flex" justifyContent="space-between">
        <Box sx={classes.bankAccountIconBox}>
          <Icon className="ico-hu-bank" color="primary" />
          <Typography variant="body1" ml={1} fontWeight="bold">
            Bank Account
          </Typography>
        </Box>
        <Box>
          <IconButton
            aria-label="save"
            color="primary"
            size="small"
            sx={classes.bankAccountCardEditIcon}
            onClick={onEditBankAccount}
          >
            {bankAccountInfo === null ? (
              <Icon className="ico-hu-add" sx={{ fontSize: "15px" }} />
            ) : (
              <Icon className="ico-hu-edit" sx={{ fontSize: "15px" }} />
            )}
          </IconButton>
        </Box>
      </Box>
      {bankAccountInfo && bankAccountInfo.account_name ? (
        <Box pt="8px">
          <Typography variant="body1" color={"common.black"}>
            {bankAccountInfo?.account_name &&
              `${bankAccountInfo?.account_name} , `}
            {bankAccountInfo?.account_number &&
              `${bankAccountInfo?.account_number} ,`}
          </Typography>
          <Typography variant="body1" color={"common.black"}>
            {`${bankAccountInfo?.bank?.name_th} ,` || ""}
            {bankAccountInfo?.account_type !== null &&
              mapAccountType(bankAccountInfo?.account_type)}
          </Typography>
          <Typography variant="body1" color={"common.black"} sx={{ pt: 2 }}>
            {bankAccountInfo?.tax_id &&
              `Tax ID : ${formatTaxId(bankAccountInfo?.tax_id)}`}
          </Typography>
        </Box>
      ) : (
        <Box mt="8px">
          <Typography variant="body1" color={"text.secondary"}>
            + Add Bank Account
          </Typography>
        </Box>
      )}

      <Dialog
        open={openToUpdateBankDialog}
        onClose={() => setOpenToUpdateBankDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={classes.dialog}
      >
        <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="title1">Bank Account</Typography>
          <IconButton onClick={() => setOpenToUpdateBankDialog(false)}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.contentDialog}>
          <EditInfluencerBankAccount
            form={form}
            onSubmit={onUpdateBankAccount}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
