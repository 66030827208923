import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IViewImageDialogProps {
  fileId: string;
  open: boolean;
  onClose: (fileId: string, status: boolean) => void;
  image: string;
}

export default function ViewImageDialog(props: IViewImageDialogProps) {
  const { open, onClose, image, fileId } = props;

  const handleClose = () => {
    onClose(fileId, false);
  };

  return (
    <Dialog
      open={open}
      id="viewImageDialog"
      sx={{
        "& .css-fkeb6g-MuiPaper-root-MuiDialog-paper": {
          borderRadius: 0,
          position: "initial",
        },
        "& .MuiDialog-container": {
          position: "relative",
        },
        "& .css-i4bv87-MuiSvgIcon-root": {
          cursor: "pointer",
          position: "absolute",
          color: "#fff",
        },
        "& .css-i4bv87-MuiSvgIcon-root:first-of-type": {
          right: "30px",
          top: "30px",
          fontSize: "40px",
        },
      }}
    >
      <CloseIcon onClick={handleClose} />

      <DialogContent
        sx={{
          borderRadius: "0px",
          p: 0,
        }}
      >
        <img
          style={{
            objectFit: "cover",
            maxWidth: "600px",
            maxHeight: "500px",
            marginBottom: "-6px",
          }}
          src={image}
          alt="item"
        />
      </DialogContent>
    </Dialog>
  );
}
