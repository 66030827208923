// mui
import {
  Box,
  Grid,
  Icon,
  Button,
  Drawer,
  Divider,
  Typography,
} from "@mui/material";
// style and type
import { useDrawerPrivateInfluencerSelectedStyle as classes } from "./CampaignPrivate.style";
import { IDrawerPrivateInfluencerSelectedProps } from "./PrivateCampaign.type";
// component
import CardOrganizing from "./CardOrganizing";

export default function DrawerPrivateInfluencerSelected(
  props: IDrawerPrivateInfluencerSelectedProps
) {
  const {
    openInfluencerSelected,
    toggleDrawers,
    privateSelected,
    handleClick,
    clearInfluencer,
    selectPrivateOptional,
  } = props;

  return (
    <Drawer
      anchor="right"
      open={openInfluencerSelected}
      onClose={() => toggleDrawers(false)}
      sx={classes.drawer}
    >
      <Box height="100%">
        <Box sx={classes.boxTitle}>
          <Typography variant="h6" color="#893DBD">
            รายชื่อที่ต้องการเพิ่ม
          </Typography>
          <Icon
            className="ico-hu-close"
            onClick={() => toggleDrawers(false)}
            sx={classes.icon}
          />
        </Box>

        <Divider />

        <Box sx={classes.boxTitle}>
          <Typography variant="title1">กลุ่มที่ยังไม่ได้ยื่นข้อเสนอ</Typography>
          <Typography variant="title1" color="#893DBD">
            {privateSelected.length}
          </Typography>
        </Box>

        <Box sx={{ padding: "24px" }}>
          <Grid container spacing={4}>
            {privateSelected?.map((item) => {
              return (
                <Grid item xs={4} key={item.influencer_id}>
                  <CardOrganizing
                    item={item}
                    influencer={privateSelected}
                    handleClick={handleClick}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      <Divider />

      <Box sx={classes.boxAction}>
        <Typography sx={{ cursor: "pointer" }} onClick={clearInfluencer}>
          Clear
        </Typography>
        <Button
          variant="contained"
          sx={{ width: "160px", height: "32px" }}
          onClick={selectPrivateOptional}
        >
          เพิ่มรายชื่อ
        </Button>
      </Box>
    </Drawer>
  );
}
