import { useEffect, useRef, useState } from "react";
// mui and icon
import { Grid, InputAdornment, Typography } from "@mui/material";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
// other
import TextFieldCustom from "../text-field";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

interface IDateRangeInput {
  startKeyName: string;
  endKeyName: string;
  altKeyName?: string;
  register: any;
  setValue: any;
  watch: any;
  clearErrors: any;
  placeholderFirst?: string;
  placeholderSecond?: string;
  [any: string]: any;
}

function DateRangeInput(props: IDateRangeInput) {
  const {
    startKeyName,
    endKeyName,
    setValue,
    register,
    watch,
    clearErrors,
    placeholderFirst,
    placeholderSecond,
    firstError,
    secondError,
    altKeyName,
  } = props;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const firstRef = useRef<any>(null);
  // const secondRef = useRef<any>(null);

  useEffect(() => {
    register(startKeyName, { required: true });
    register(endKeyName, { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={5.7}>
          <DatePicker
            ref={firstRef}
            name={startKeyName}
            dateFormat="dd/MM/yyyy"
            calendarClassName="datepicker-calendar-container "
            selected={startDate}
            onChange={(date: any) => {
              setStartDate(date);
              setValue(startKeyName, dayjs(date).format("YYYY-MM-DD"));
              clearErrors(startKeyName);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            monthsShown={2}
            placeholderText={placeholderFirst}
            value={
              watch(startKeyName) &&
              dayjs(watch(startKeyName)).format("DD/MM/YYYY")
            }
            customInput={
              <TextFieldCustom
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon
                        sx={{
                          cursor: "pointer",
                          color: firstError ? "red" : "#8C499C",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "50px",
                    borderColor: firstError ? "red" : "#8C499C",
                  },
                }}
              />
            }
          />
          {firstError && (
            <Typography
              variant="caption"
              color="error.main"
              fontWeight={400}
              ml="15px"
            >
              {firstError}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={0.6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "40px" }}
        >
          -
        </Grid>
        <Grid item xs={5.7}>
          <DatePicker
            // ref={secondRef}
            name={endKeyName}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            calendarClassName="datepicker-calendar-container"
            onChange={(date: any) => {
              setEndDate(date);
              setValue(endKeyName, dayjs(date).format("YYYY-MM-DD"));
              clearErrors(endKeyName);
              if (altKeyName) {
                setValue(altKeyName, dayjs(date).format("YYYY-MM-DD"));
                clearErrors(altKeyName);
              }
            }}
            value={
              watch(endKeyName) && dayjs(watch(endKeyName)).format("DD/MM/YYYY")
            }
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            monthsShown={2}
            placeholderText={placeholderSecond}
            // open={
            //   !watch(startKeyName)
            //     ? false
            //     : watch(startKeyName) && watch(endKeyName)
            //     ? false
            //     : true
            // }
            customInput={
              <TextFieldCustom
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon
                        sx={{
                          cursor: "pointer",
                          color: secondError ? "red" : "#8C499C",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "50px",
                    borderColor: secondError ? "red" : "#8C499C",
                  },
                }}
              />
            }
          />
          {secondError && (
            <Typography
              variant="caption"
              color="error.main"
              fontWeight={400}
              ml="15px"
            >
              {secondError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DateRangeInput;
