import React, { useState } from "react";
// mui and icon
import {
  Box,
  Fab,
  Grid,
  Icon,
  Stack,
  Avatar,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GetPlatformColor from "@components/global/get-platform/GetPlatformColor";
// type and style
import { IDescription, IJobTimeline } from "@api/job/type";
import { useInfCampaignTimelineInfoStyle as classes } from "../Campaign.style";
// component
import ViewMultiVideoDialog from "@components/global/view-video-dialog/ViewMultiVideoDialog";
import ViewMultiImageDialog from "@components/global/view-image-dialog/ViewMultiImageDialog";
// others
import GetPlatform from "@global/get-platform";
import ReactPlayer from "react-player";
import Linkify from "react-linkify";
import nl2br from "react-nl2br";
import dayjs from "dayjs";

function CardHeader(props: { title: string; subTitle: string }) {
  const { title, subTitle } = props;
  return (
    <>
      <Box padding="24px">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subTitle1">{subTitle}</Typography>
      </Box>
      <Divider />
    </>
  );
}

function Caption(props: { caption?: IDescription }) {
  const { caption } = props;
  if (caption?.description === "") return null;
  return (
    <Box p={"24px"}>
      <Typography variant="title1">Caption</Typography>
      <Box sx={classes.description}>
        <Linkify>
          <Typography mb={2}>{nl2br(caption?.description)}</Typography>
        </Linkify>
      </Box>
      {caption?.is_approve === "not_approved" && caption?.note ? (
        <Box sx={classes.captionEditingDesc}>
          <Typography>ความคิดเห็นจากผู้จ้างงาน :</Typography>
          <Typography>{nl2br(caption.note)}</Typography>
        </Box>
      ) : (
        caption?.is_approve === "approved" && (
          <Box display="flex" alignItems="center" mt="24px">
            <Icon className="ico-hu-check" color="success" />
            <Typography color="#4CAF50" variant="title1" ml={2}>
              Caption ได้รับการอนุมัติแล้ว
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

function InfluencerDraft(props: {
  data: IJobTimeline;
  contentType?: string;
}) {
  const { data, contentType } = props;
  const [openAttachment, setOpenAttachment] = useState(false);
  const draftInfo = data.data?.draft_info;
  const atts = draftInfo?.attachments || [];
  const urls = draftInfo?.external_file || [];
  const isVideoType = contentType === "video";

  const isLenght = (length?: number) => {
    if (length && length > 0) return true;
    return false;
  };

  return (
    <>
      {contentType === "image" && (
        <>
          <CardHeader title="Images" subTitle="รูปภาพการรีวิวสินค้า" />
          <Stack
            direction="row"
            flexWrap="wrap"
            rowGap={7}
            columnGap={5}
            p="24px"
          >
            {atts.map((att, index) => (
              <Box key={index} sx={classes.attIsActiveBox}>
                {att.is_approve === "approved" && (
                  <Fab sx={classes.attApprovedIcon}>
                    <Icon className="ico-hu-check" sx={{ fontSize: "16px" }} />
                  </Fab>
                )}
                <Avatar
                  src={att.url}
                  sx={classes.imgItem}
                  onClick={() => setOpenAttachment(true)}
                />
                <Typography variant="body2" textAlign="center" mt={2}>
                  {`รูปที่ ${att.order}`}
                </Typography>
              </Box>
            ))}
          </Stack>
        </>
      )}
      {contentType === "video" && (
        <>
          <CardHeader title="Videos" subTitle="วิดีโอการรีวิวสินค้า" />
          <Box m="24px">
            {isLenght([...atts, ...urls].length) && (
              <Stack direction="row" mb="24px" sx={classes.draftBox}>
                {atts.map((att, index) => (
                  <Box key={index}>
                    <Box
                      onClick={() => setOpenAttachment(true)}
                      sx={classes.draftVideo}
                    >
                      <Box sx={classes.iconPlayVido}>
                        <Icon
                          sx={{
                            fontSize: "40px",
                            color: "#fff",
                          }}
                          className="ico-hu-play-circle-outline"
                        />
                      </Box>
                      <ReactPlayer
                        url={att.url}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={true}
                      />
                    </Box>
                  </Box>
                ))}
                {urls?.map((url, index) => (
                  <Box
                    key={index}
                    onClick={() => window.open(url.url, "_blank")}
                  >
                    <Box sx={classes.urlBox}>
                      <Box sx={classes.urlIcon}>
                        <Icon className="ico-hu-conversion" />
                        <Typography variant="body2" mt="15px">
                          Video URL
                        </Typography>
                      </Box>
                      <Box sx={classes.urlImg}>
                        <img src={urlLogo} alt="item" />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Stack>
            )}
          </Box>
        </>
      )}
      {isVideoType ? (
        <ViewMultiVideoDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          videos={atts}
        />
      ) : (
        <ViewMultiImageDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          images={atts}
        />
      )}
    </>
  );
}

function EmployerCheckDraft(props: {
  data: IJobTimeline;
  contentType?: string;
}) {
  const { data, contentType } = props;
  const [openAttachment, setOpenAttachment] = useState(false);
  const draftInfo = data.data?.draft_info;
  const atts = draftInfo?.attachments || [];
  const urls = draftInfo?.external_file || [];

  if ([...urls, ...atts].length === 0) return null;
  const attsApproved = atts.filter((att) => att.is_approve === "approved");
  const attsRejected = atts.filter(
    (att) => att.is_approve === "not_approved" || att.is_approve === "drop"
  );
  const urlsRejected = urls.filter(
    (url) => url.is_approve === "not_approved" || url.is_approve === "drop"
  );
  const urlsApproved = urls.filter((url) => url.is_approve === "approved");
  const isVideoType = contentType === "video";

  const isLenght = (length?: number) => {
    if (length && length > 0) return true;
    return false;
  };

  return (
    <Box sx={classes.boxSocial}>
      {contentType === "image" && (
        <>
          <CardHeader title="Images" subTitle="รูปภาพการรีวิวสินค้า" />
          <Box p="24px">
            <Box sx={classes.titleImgItem}>
              <CancelIcon />
              <Typography>รูปภาพที่ต้องแก้ไข</Typography>
            </Box>
            <Stack
              direction="row"
              columnGap={4}
              rowGap={4}
              flexWrap="wrap"
              mx={5}
            >
              {attsRejected.map((att, index) => (
                <Box key={index} sx={classes.imgItemEditingGrid}>
                  <Typography variant="title1" mb="8px">
                    {`รูปที่ ${att.order}`}
                  </Typography>
                  <Avatar
                    src={att.url}
                    onClick={() => setOpenAttachment(true)}
                    sx={classes.imgItemEditing}
                  />
                  <Box sx={classes.imgItemEditingDesc}>
                    <Typography>ความคิดเห็นจากผู้จ้างงาน :</Typography>
                    <Typography>{att.note}</Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
            {isLenght(attsApproved.length) && (
              <>
                <Divider sx={{ m: "24px" }} />
                <Box p="0 24px 24px 24px">
                  <Box sx={classes.imgItemApprove}>
                    <CheckCircleIcon />
                    <Typography>รูปภาพที่ได้รับการอนุมัติแล้ว</Typography>
                  </Box>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    rowGap={[2, 7]}
                    columnGap={[2, 5]}
                  >
                    {attsApproved.map((item, index) => (
                      <Box key={index} mt={-2}>
                        <Typography variant="title1">
                          {`รูปที่ ${item.order}`}
                        </Typography>
                        <Avatar
                          src={item.url}
                          sx={classes.imgItem}
                          onClick={() => setOpenAttachment(true)}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {contentType === "video" && (
        <>
          <CardHeader title="Videos" subTitle="วิดีโอการรีวิวสินค้า" />
          <Box m="24px">
            {isLenght([...attsRejected, ...urlsRejected].length) && (
              <>
                <Box sx={classes.titleImgItem}>
                  <CancelIcon />
                  <Typography>วิดีโอที่ต้องแก้ไข</Typography>
                </Box>
                <Stack direction="row" mb="24px" sx={classes.draftBox}>
                  {attsRejected.map((att, index) => (
                    <Box key={index}>
                      <Box
                        onClick={() => setOpenAttachment(true)}
                        sx={classes.draftVideo}
                      >
                        <Box sx={classes.iconPlayVido}>
                          <Icon
                            sx={{
                              fontSize: "40px",
                              color: "#fff",
                            }}
                            className="ico-hu-play-circle-outline"
                          />
                        </Box>
                        <ReactPlayer
                          url={att.url}
                          width="100%"
                          height="100%"
                          loop={true}
                          muted={true}
                        />
                      </Box>
                      <Box sx={classes.videoComment}>
                        <Typography variant="title2">
                          ความคิดเห็นจากผู้จ้างงาน :
                        </Typography>
                        <Typography
                          variant="body2"
                          width="170px"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          {att.note}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  {urlsRejected?.map((url, index) => (
                    <Box
                      key={index}
                      onClick={() => window.open(url.url, "_blank")}
                    >
                      <Box sx={classes.urlBox}>
                        <Box sx={classes.urlIcon}>
                          <Icon className="ico-hu-conversion" />
                          <Typography variant="body2" mt="15px">
                            Video URL
                          </Typography>
                        </Box>
                        <Box sx={classes.urlImg}>
                          <img src={urlLogo} alt="item" />
                        </Box>
                      </Box>
                      <Box sx={classes.videoComment}>
                        <Typography variant="title2">
                          ความคิดเห็นจากผู้จ้างงาน :
                        </Typography>
                        <Typography
                          variant="body2"
                          width="180px"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          {url.note}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </>
            )}
            {isLenght([...attsApproved, ...urlsApproved].length) && (
              <>
                <Divider sx={{ my: "24px" }} />
                <Box>
                  <Box sx={classes.imgItemApprove}>
                    <CheckCircleIcon />
                    <Typography>รูปภาพที่ได้รับการอนุมัติแล้ว</Typography>
                  </Box>
                  <Stack direction="row" mb="24px" sx={classes.draftBox}>
                    {attsApproved.map((att, index) => (
                      <Box key={index}>
                        <Box
                          onClick={() => setOpenAttachment(true)}
                          sx={classes.draftVideo}
                        >
                          <Box sx={classes.iconPlayVido}>
                            <Icon
                              sx={{
                                fontSize: "40px",
                                color: "#fff",
                              }}
                              className="ico-hu-play-circle-outline"
                            />
                          </Box>
                          <ReactPlayer
                            url={att.url}
                            width="100%"
                            height="100%"
                            loop={true}
                            muted={true}
                          />
                        </Box>
                        <Typography
                          variant="body2"
                          textAlign="center"
                          mt="10px"
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                    ))}
                    {urlsApproved?.map((url, index) => (
                      <Box
                        key={index}
                        onClick={() => window.open(url.url, "_blank")}
                      >
                        <Box sx={classes.urlBox}>
                          <Box sx={classes.urlIcon}>
                            <Icon className="ico-hu-conversion" />
                            <Typography variant="body2" mt="15px">
                              Video URL
                            </Typography>
                          </Box>
                          <Box sx={classes.urlImg}>
                            <img src={urlLogo} alt="item" />
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="10px">
                          <Typography variant="body2" textAlign="center">
                            {index + 1 + attsRejected.length}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {isVideoType ? (
        <ViewMultiVideoDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          videos={atts}
        />
      ) : (
        <ViewMultiImageDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          images={atts}
        />
      )}
    </Box>
  );
}

function EmployerApprovedDraft(props: {
  data: IJobTimeline;
  contentType?: string;
}) {
  const { data, contentType } = props;
  const [openAttachment, setOpenAttachment] = useState(false);
  const draftInfo = data.data?.draft_info;
  const atts = draftInfo?.attachments || [];
  const urlList = draftInfo?.external_file || [];
  const isVideoType = contentType === "video";
  return (
    <Box sx={classes.boxSocial}>
      <CardHeader title="Post" subTitle="รายละเอียดในการโพสต์" />
      <Box p="24px">
        {(isVideoType || contentType === "image") && (
          <Box mb="20px">
            <Typography color="primary" fontWeight="500">
              {isVideoType ? "Videos" : "Images"}
            </Typography>
            <Stack
              direction="row"
              columnGap={[2, 5]}
              rowGap={[2, 5]}
              flexWrap="wrap"
              mt={3}
            >
              {isVideoType
                ? atts.length
                  ? atts.map((att, index) => (
                      <Box
                        key={index}
                        onClick={() => setOpenAttachment(true)}
                        height={["150px", "240px"]}
                        width={["150px", "240px"]}
                      >
                        <Box sx={classes.draftVideo}>
                          <Box
                            sx={{ ...classes.iconPlayVido, borderRadius: 0 }}
                          >
                            <Icon
                              sx={{
                                fontSize: "40px",
                                color: "#fff",
                              }}
                              className="ico-hu-play-circle-outline"
                            />
                          </Box>
                          <ReactPlayer
                            url={att.url}
                            width="100%"
                            height="100%"
                            loop={true}
                            muted={true}
                          />
                        </Box>
                      </Box>
                    ))
                  : urlList.map((url, index) => (
                      <Box
                        key={index}
                        display={
                          url.is_approve !== "approved" ? "none" : "initial"
                        }
                        sx={classes.urlBox}
                        onClick={() => window.open(url.url, "_blank")}
                      >
                        <Box sx={classes.urlIcon}>
                          <Icon className="ico-hu-conversion" />
                          <Typography variant="body2" mt="15px">
                            Video URL
                          </Typography>
                        </Box>
                        <Box sx={classes.urlImg}>
                          <img src={urlLogo} alt="item" />
                        </Box>
                      </Box>
                    ))
                : atts.map((att, index) => (
                    <Box
                      key={index}
                      onClick={() => setOpenAttachment(true)}
                      height="120px"
                      width="120px"
                    >
                      <Avatar
                        src={att.url}
                        sx={classes.imgItem}
                        alt="attachment"
                      />
                    </Box>
                  ))}
            </Stack>
          </Box>
        )}
        <Box sx={classes.captionBox}>
          <Typography variant="title1">Caption</Typography>
          {draftInfo?.description?.description && (
            <Box my="10px">
              <Linkify>
                <Typography>
                  {nl2br(draftInfo?.description.description)}
                </Typography>
              </Linkify>
            </Box>
          )}
        </Box>
      </Box>
      {draftInfo?.comment && (
        <>
          <Divider sx={{ m: [0, "0 24px 0 24px"] }} />
          <Box m={["24px 0 24px 0", "24px"]}>
            <Typography variant="title1" mb="8px">
              Message to Influencer
            </Typography>
            <Box sx={classes.comment}>
              <Linkify>
                <Typography>{nl2br(draftInfo?.comment)}</Typography>
              </Linkify>
            </Box>
          </Box>
        </>
      )}
      {isVideoType ? (
        <ViewMultiVideoDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          videos={atts}
        />
      ) : (
        <ViewMultiImageDialog
          open={openAttachment}
          onClose={setOpenAttachment}
          images={atts}
        />
      )}
    </Box>
  );
}

export default function TimelineInfo(props: {
  data: IJobTimeline;
  contentType?: string;
  platform?: string;
}) {
  const { data, contentType, platform } = props;
  const draftInfo = data.data?.draft_info;
  const empJobInfo = data.data?.job_employer_info;
  const empPublishRangeInfo = data.data?.job_employer_info?.publish_range;
  const productReturnInfo = data.data?.publish_link_info?.product_return;
  const publishLink = data.data?.publish_link_info?.published_link;
  const socialAccountInfo = data.data?.social_account;
  const recieptProductInfo =
    data.data?.reciept_product_info?.delivery_confirmation;

  const formatNumber = Intl.NumberFormat("en", { notation: "compact" });
  return (
    <>
      {data.name.includes("influencer submit") && (
        <Box width="100%">
          {(draftInfo?.attachments || draftInfo?.external_file || []).length ? (
            <Box sx={classes.boxSocial}>
              <InfluencerDraft data={data} contentType={contentType} />
            </Box>
          ) : null}
          {draftInfo?.description?.description !== "" && (
            <Box sx={classes.boxSocial}>
              <CardHeader
                title="Caption"
                subTitle="ข้อความหรือแคปชั่นการโพสต์"
              />
              <Caption caption={draftInfo?.description} />
            </Box>
          )}
        </Box>
      )}
      {(data.name.includes("employer checked") ||
        data.name === "employer final approved" || data.name === "employer approved") && (
        <Box display="flex" flexDirection="column" width="100%">
          {draftInfo?.job_status === "approved" ? (
            <EmployerApprovedDraft data={data} contentType={contentType} />
          ) : (
            <EmployerCheckDraft data={data} contentType={contentType} />
          )}
          {draftInfo?.job_status !== "approved" && (
            <Box sx={classes.boxSocial}>
              <CardHeader
                title="Caption"
                subTitle="ข้อความหรือแคปชั่นการโพสต์"
              />
              <Caption caption={draftInfo?.description} />
            </Box>
          )}
        </Box>
      )}
      {data.name === "employer update product shipping and conversion" && (
        <Box width="100%">
          {empJobInfo?.product_shipping !== null && (
            <Box sx={classes.boxSocial}>
              <CardHeader
                title="Shipping Product to Influencer"
                subTitle="ผู้จ้างงานส่งสินค้าสำหรับการรีวิวให้อินฟลูเอนเซอร์"
              />
              <Grid container spacing={4} p="24px">
                {[
                  {
                    label: "Shipping Type",
                    value: empJobInfo?.product_shipping?.shipping_type,
                  },
                  {
                    label: "Tracking No.",
                    value: empJobInfo?.product_shipping?.tracking_no,
                  },
                  {
                    label: "Deliver to.",
                    value: empJobInfo?.product_shipping?.address,
                  },
                  { label: "Note", value: empJobInfo?.product_shipping?.note },
                ].map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={3}>
                      <Typography variant="title1">{item.label}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>{item.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          )}
          {empJobInfo?.conversion !== null && (
            <Box sx={classes.boxSocial}>
              <CardHeader
                title="Conversion Tracking"
                subTitle="การตอบสนองจากกลุ่มลูกค้าต่อ URL เป้าหมายหรือรหัสคูปอง"
              />
              <Box p="24px">
                <Box>
                  <Typography variant="title1">Lead Link</Typography>
                  <Grid container spacing={4} py="20px">
                    <Grid item xs={3}>
                      <Typography
                        variant="title1"
                        whiteSpace="nowrap"
                        ml="20px"
                      >
                        • Original Link
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography sx={classes.txtEllip}>
                        {empJobInfo?.conversion?.lead_link}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="12px">
                  <Typography variant="title1">Coupon</Typography>
                  <Grid container spacing={4} py="20px">
                    {[
                      {
                        label: "Coupon Code",
                        value: empJobInfo?.conversion?.coupon_name,
                      },
                      {
                        label: "Coupon Name",
                        value: empJobInfo?.conversion?.coupon,
                      },
                      {
                        label: "Validity Period",
                        value: `${empJobInfo?.conversion?.start_validity_period} - ${empJobInfo?.conversion?.end_validity_period}`,
                      },
                    ].map((item, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={3}>
                          <Typography variant="title1" ml="20px">
                            • {item.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography sx={classes.txtEllip}>
                            {item.value}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {data.name === "influencer confirm reciept product" && (
        <Box sx={classes.boxSocial}>
          <CardHeader
            title="Confirm Goods Received"
            subTitle="ยืนยันการรับสินค้า"
          />
          <Grid container spacing={4} p="24px">
            <Grid item xs={3}>
              <Typography variant="title1">Date of Receipt</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{recieptProductInfo?.date_of_receipt}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="title1">Images</Typography>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                columnGap="10px"
                rowGap="10px"
                flexWrap="wrap"
              >
                {recieptProductInfo?.attachment.map((item, index) => (
                  <Avatar
                    key={index}
                    src={item.url}
                    sx={classes.recieptProductImage}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="title1">Note</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{recieptProductInfo?.note}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {data.name === "employer update date post" && (
        <Box sx={classes.boxSocial} width="100%">
          <CardHeader
            title="Date Post"
            subTitle="วันที่อินฟลูเอนเซอรโพสต์งาน"
          />
          <Grid container spacing={5} p={["24px 0 24px 0", "24px"]}>
            <Grid item xs={4} sm={3}>
              <Typography variant="title1">Date Range</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <Typography>
                {empPublishRangeInfo?.published_at
                  ? `${dayjs(empPublishRangeInfo?.published_at).format(
                      "DD/MM/YYYY"
                    )} - ${dayjs(empPublishRangeInfo?.completed_at).format(
                      "DD/MM/YYYY"
                    )}`
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="title1">Time Range</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <Typography>
                {empPublishRangeInfo?.start_time
                  ? `${empPublishRangeInfo.start_time} - ${empPublishRangeInfo.end_time}`
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="title1">Note</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <Typography>{empPublishRangeInfo?.note || "-"}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {data.name === "influencer update publish link" && (
        <Box width="100%">
          {publishLink !== "" && (
            <Box sx={classes.boxSocial}>
              <CardHeader title="Published Link" subTitle="แนบลิงค์ส่งงาน" />
              <Grid container spacing={4} p="24px">
                <Grid item xs={3}>
                  <Box display="flex" alignItems="center">
                    <GetPlatformColor platform={platform} />
                    <Typography variant="title1" sx={{ ml: "8px" }}>
                      {platform}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box sx={classes.boxPublishLink}>
                    <Typography
                      variant="title1"
                      width="90%"
                      sx={classes.txtEllip}
                    >
                      {publishLink}
                    </Typography>
                    <IconButton
                      onClick={() => window.open(publishLink)}
                      sx={classes.boxIcon}
                    >
                      <Icon
                        className="ico-hu-open"
                        color="primary"
                        fontSize="small"
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {productReturnInfo !== null && (
            <Box sx={classes.boxSocial}>
              <CardHeader
                title="Product Return"
                subTitle="อินฟลูเอนเซอร์ส่งสินค้ากลับให้ผู้จ้างงาน"
              />
              <Box padding="24px">
                <Box sx={classes.boxAddress}>
                  <Typography variant="title1" color="#8C499C">
                    ที่อยู่ในการจัดส่ง
                  </Typography>
                  <Box display="flex">
                    <Typography>{productReturnInfo?.contact}</Typography>,{" "}
                    <Typography>{productReturnInfo?.phone}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography>{productReturnInfo?.address}</Typography>
                  </Box>
                </Box>
                <Grid container spacing={4} mt="20px">
                  {[
                    {
                      label: "Shipping Company",
                      value: productReturnInfo?.shipping_type,
                    },
                    {
                      label: "Tracking No.",
                      value: productReturnInfo?.tracking_no,
                    },
                    { label: "Note", value: productReturnInfo?.note },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={3}>
                        <Typography variant="title1">{item.label}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography>{item.value}</Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {data.name === "influencer regist campaign" && (
        <Box sx={classes.boxSocial}>
          <CardHeader
            title="Social Media Account"
            subTitle="บัญชีที่ใช้รับงาน"
          />
          <Box p="24px">
            <Box display="flex" alignItems="center">
              <GetPlatform platform={socialAccountInfo?.platform || ""} />
              <Typography variant="h6">{socialAccountInfo?.name}</Typography>
            </Box>
            <Box display="flex" columnGap="50px" mt="20px">
              {[
                { label: "Followers", value: socialAccountInfo?.follower },
                {
                  label: "Avg. Likes",
                  value: socialAccountInfo?.engagement_rate,
                },
              ].map((item, index) => (
                <Box key={index}>
                  <Typography>{item.label}</Typography>
                  <Typography variant="h5">
                    {formatNumber.format(item.value || 0)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
