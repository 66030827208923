import { useContext } from "react";
// mui and style
import { Box } from "@mui/material";
import { useCreateTeamStyle as classes } from "./CreateTeam.style";
// component
import AddTeamMember from "./AddTeamMember";
import CreateYourTeam from "./CreatYourTeam";
import OrganizingYourWallet from "./OrganizingYourWallet";
// context
import TeamContext from "@contexts/Team";

export default function CreateTeam() {
  const teamContext = useContext(TeamContext);
  const step = teamContext?.step || "create-team";
  
  if (teamContext?.isLoading) {
    return null
  } else {
    return (
      <Box sx={classes.card}>
        {step === "create-team" ? (
          <CreateYourTeam />
        ) : step === "add-member" ? (
          <AddTeamMember />
        ) : step === "organizing-wallet" ? (
          <OrganizingYourWallet />
        ) : null}
      </Box>
    );
  }
}
