import React from "react";
import { Box, Stack } from "@mui/material";

interface IFacebookImageProps {
  item: {
    file_name: string;
    url: string;
  }[];
  height: string;
}

export const FacebookImageFormat = (props: IFacebookImageProps) => {
  const { item, height } = props;

  const itemHeight = height.replace(/px/, "");

  const style = {
    postImgProfile: {
      my: "16px",
      "& > img": {
        width: "100%",
        height: height,
        borderRadius: "4px",
        objectFit: "cover",
      },
    },
    postImgItem2: {
      display: "flex",
      height: height,
      justifyContent: "center",
      "& img": { width: "50%", objectFit: "cover" },
      "& img:first-of-type": { borderRadius: "4px 0 0 4px" },
      "& img:last-of-type": { borderRadius: "0 4px 4px 0" },
    },
    postImgItem3: {
      display: "flex",
      height: height,
      "& > img": {
        width: "60%",
        objectFit: "cover",
        borderRadius: "4px 0 0 4px",
        height:
          Number(itemHeight) < 280
            ? Number(itemHeight) + 3
            : Number(itemHeight),
      },
      "& > div": {
        "& img": {
          height: "49.2%",
          width: "95%",
          ml: "5px",
          objectFit: "cover",
        },
        "& img:first-of-type": { borderRadius: "0 4px 0 0" },
        "& img:last-of-type": { borderRadius: "0 0 4px 0" },
      },
    },
    postImgItem4: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > img": {
        width: "60%",
        height: height,
        borderRadius: "4px",
        objectFit: "cover",
        mr: "6px",
      },
      "& > div": {
        justifyContent: "space-between",
        "& img": {
          width: "98%",
          borderRadius: "4px",
          objectFit: "cover",
          height: Number(itemHeight) / 3 - 7,
        },
        mt: "-3px",
      },
    },
    postImgItemMoreThan4: {
      position: "absolute",
      width: "100%",
      height: Number(itemHeight) / 3 - 7,
      bottom: 0,
      right: 0,
      borderRadius: "4px",
      bgcolor: "#000",
      opacity: 0.2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > p": {
        color: "#fff",
        fontSize: "26px",
      },
    },
  };

  return (
    <Box sx={style.postImgProfile}>
      {item.length === 1 ? (
        <img src={item?.[0]?.url} alt={item?.[0]?.file_name} />
      ) : item.length === 2 ? (
        <Box sx={style.postImgItem2}>
          {item.map((itemImg, index) => (
            <img key={index} src={itemImg?.url} alt={itemImg?.file_name} />
          ))}
        </Box>
      ) : item.length === 3 ? (
        <Box sx={style.postImgItem3}>
          <img src={item?.[0]?.url} alt={item?.[0]?.file_name} />
          <Box>
            {item.slice(1).map((itemImg, index) => (
              <img key={index} src={itemImg?.url} alt={itemImg?.file_name} />
            ))}
          </Box>
        </Box>
      ) : item.length >= 4 ? (
        <Box sx={style.postImgItem4}>
          <img src={item?.[0]?.url} alt={item?.[0]?.file_name} />
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={2.5}
            mt={2.5}
            sx={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            {item.slice(1, 4).map((itemImg, indexImg) => (
              <React.Fragment key={indexImg}>
                <img src={itemImg?.url} alt={itemImg?.file_name} />
                {item.length > 4 && (
                  <Box sx={style.postImgItemMoreThan4}>
                    <p>+ {item.length - 4}</p>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
