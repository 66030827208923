import { hPurple } from "@theme/index";

const card = {
  bgcolor: "#fff",
  borderRadius: "16px",
  boxShadow: "0px 4px 10px 0px #3535350D",
};

const cardHeader = {
  bgcolor: "primary.main",
  textAlign: "center",
  height: "42px",
  padding: "0",
  fontWeight: "bold",
  borderRadius: "16px 16px 0px 0px",
};

const dialog = {
  "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
    bgcolor: "inherit",
    boxShadow: "none",
  },
  "& .css-bjtku8": {
    bgcolor: "inherit",
    boxShadow: "none",
  },
  "& ::-webkit-scrollbar": {
    display: "none",
  },
};

const dialogTitle = {
  bgcolor: "#8C499C",
  color: "#fff",
  height: "22px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: "89%",
  mt: "10px",
  "& button": {
    position: "absolute",
    zIndex: 1,
    top: -17,
    right: -16,
    "& svg": {
      bgcolor: "#fff",
      borderRadius: "50%",
      fill: "black",
      fontSize: "25px",
    },
  },
};

const contentDialog = {
  width: "89%",
  bgcolor: "#fff",
  borderRadius: "0 0 20px 20px",
};

export const useInfluencerProfileInfoStyle = {
  card: card,
  cardHeader: cardHeader,
  imageBox: {
    overflow: "hidden",
    width: "100%",
    minHeight: { xs: "100%", lg: "300px", xl: "350px" },
    maxHeight: { xs: "200px", lg: "360px", xl: "450px" },
    borderRadius: "20px 20px 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .profile": {
      width: "100%",
      height: "100%",
      borderRadius: "20px 20px 0 0",
    },
  },
  profileImg: {
    background: "linear-gradient(180deg, rgba(55, 55, 55, 0) 0%, #000000 100%)",
    minHeight: { xs: "100px", lg: "150px" },
    width: "100%",
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  imageListBox: {
    bgcolor: "grey.100",
    borderRadius: "0 0 20px 20px",
  },
  imageProfileItem: {
    display: "flex",
    borderRadius: "0 0 10px 10px",
    overflow: "hidden",
    "& .profile-item": {
      height: { xs: "90px", lg: "120px", xl: "180px" },
      borderRadius: 0,
      width: "100%",
    },
  },
  descriptionBox: {
    textAlign: "center",
    py: 4,
    px: 5,
    backgroundColor: hPurple[100],
    borderRadius: "5px",
    mt: "20px",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    pb: 4,
    mt: "20px",
  },
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
};

export const useInfluencerBankAccountStyle = {
  card: card,
  cardHeader: cardHeader,
  bankAccountIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  bankAccountCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
};

export const useInfluencerAddressStyle = {
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
  card: card,
  cardHeader: cardHeader,
  addressIconWrap: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  rateCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  addressDetailBox: {
    paddingTop: "8px",
  },
};

export const useInfluencerPortfolioStyle = {
  card: card,
  cardHeader: cardHeader,
  addressIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  rateCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  addressDetailBox: {
    paddingTop: "8px",
  },
  profileTitle: {
    "&:hover": {
      opacity: "0.8",
    },
    maxWidth: "300px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  portfolioCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  profileUrl: {
    "&:hover": {
      color: "rgba(0, 0, 0, 0.4)",
    },
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    WebkitLineClamp: 3,
    lineHeight: 2,
    WebkitBoxOrient: "vertical",
  },
  prodileDesc: {
    width: "85%",
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  portfolioIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  dialog: dialog,
  dialogTitle: dialogTitle,
  contentDialog: contentDialog,
};

export const useEditInfluencerProfileInfoStyle = {
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
  profileContainerBox: {
    width: "160px",
    height: "160px",
    display: "inline-block",
    cursor: "pointer",
  },
  profileBox: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "& .MuiAvatar-root": {
      position: "relative",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
      borderRadius: "10px",
    },
  },
  loading: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imagesContainerBox: {
    width: "120px",
    height: "120px",
    mb: 1,
    display: "inline-block",
    cursor: "pointer",
  },
  imagesBox: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "& > .MuiAvatar-root": {
      position: "relative",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
      borderRadius: "10px",
    },
  },
  imagesButton: {
    position: "absolute",
    bottom: "-6px",
    right: "-12px",
    height: "26px",
    maxWidth: "26px",
    minWidth: "26px",
    bgcolor: "primary.main",
    border: "none",
    p: 0,
    fontSize: "16px",
    "&:hover": {
      bgcolor: "primary.main",
      border: "none",
    },
  },
  profileButton: {
    position: "absolute",
    bottom: "-6px",
    right: "-12px",
    height: "30px",
    maxWidth: "30px",
    minWidth: "30px",
    bgcolor: "primary.main",
    border: "none",
    p: 0,
    fontSize: "20px",
    "&:hover": {
      bgcolor: "primary.main",
      border: "none",
    },
  },
};

export const useEditPortfolioDialogStyle = {
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
  saveTitle: {
    cursor: "pointer",
    "&:hover": { opacity: "0.8" },
    fontSize: "14px",
  },
};

export const useUpdateBankAccountDialogStyle = {
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
  saveButton: {
    width: "14.4px",
    height: "14.4p",
  },
  saveTitle: {
    cursor: "pointer",
    "&:hover": { opacity: "0.8" },
    fontSize: "14px",
  },
};

export const useUpdateAddressDialogStyle = {
  dialog: dialog,
  contentDialog: contentDialog,
  dialogTitle: dialogTitle,
  saveButton: {
    width: "14.4px",
    height: "14.4p",
  },
  saveTitle: {
    cursor: "pointer",
    "&:hover": { opacity: "0.8" },
    fontSize: "14px",
  },
};

export const useEditInfluencerRateCardStyle = {
  fomCon: {
    bgcolor: "#fff",
    borderRadius: "12px",
    p: "16px",
    mt: "16px",
    boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.05)",
  },
  submit:{
    mt: "20px",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "300px",
      border: "none",
      fontSize: "16px",
      "&:hover": { border: "none" },
    },
  }
};

export const useEditBankAccountStyle = {
  uploadImageBox: {
    borderRadius: "4px",
    width: ["150px", "160px"],
    height: ["150px", "160px"],
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
  },
  uploadImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed gray",
    width: "150px",
    height: "150px",
    borderRadius: "4px",
    "& span": {
      opacity: 0.5,
      fontSize: "70px"
    },
  },
  curcular:{
    position:"absolute",
    top:0,
    width: "150px",
    height: "150px",
    display:'flex',
    justifyContent:"center",
    alignItems:"center"
  }
}