import qs from "qs";
import { instance } from "@api/global/instance";
import { ICreateInfluencerBody, IResetPasswordBody, ISignInReq } from "./type";
import { API_KEY, CLIENT_ID, CLIENT_SECRET } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class AuthenticationAPIs {
  async resetPassword(body: IResetPasswordBody) {
    const headers = {
      "X-Api-Key": API_KEY
    };
    const { data } = await instance.patch(`/oauth/reset-password`, body, { headers });
    return data.result.data;
  }

  async fogotPassword(body: { email: string }) {
    const headers = {
      "X-Api-Key": API_KEY
    };
    const { data } = await instance.post(`/oauth/forgot-password`, body, { headers });
    return data.result.data;
  }

  async createInfluencer(body: ICreateInfluencerBody) {
    const headers = {
      "X-Api-Key": API_KEY
    };
    const { data } = await instance.post(`/influencers`, body, { headers });
    return data.result.data;
  }

  async signIn(req: ISignInReq) {
    const headers = {
      "X-Api-Key": API_KEY
    };
    const body = {
      grant_type: "password",
      username: req.email,
      password: req.password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };
    const { data } = await instance.post(`/oauth/token`, body, { headers });
    return data.result.data;
  }

  async signOut() {
    const { access_token, refresh_token } = getTokenFromStorage()
    
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const body = {
      token: refresh_token,
      token_type_hint: "refresh_token",
    };
    const { data } = await instance.post(`/oauth/revoke`, body, { headers });
    return data.result.data;
  }

  async refreshToken(req:{ refresh_token: string }) {
    const headers = {
      "X-Api-Key": API_KEY
    };
    const body = {
      grant_type: "refresh_token",
      refresh_token: req.refresh_token,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };
    const { data } = await instance.post(`/oauth/token`, body, { headers });
    return data.result.data;
  }

  async verifyEmail(req:{ email: string }) {
    const headers = {
      "X-Api-Key": API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    var body = qs.stringify({
      email: req.email,
      grant_type: "verify",
    });
    const { data } = await instance.post(`/oauth/email_verify`, body, { headers });
    return data.result.data;
  }

  async resendVerifyEmail(req:{ email: string }) {
    const headers = {
      "X-Api-Key": API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    var body = qs.stringify({
      email: req.email,
      grant_type: "resend",
    });
    const { data } = await instance.post(`/oauth/email_verify`, body, { headers });
    return data.result.data;
  }
}