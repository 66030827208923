import { useEffect, useState } from "react";
// mui and style
import { Box, Grid, Typography } from "@mui/material";
import { adminDashboardStyles as classes } from "./Dashboard.style";
// api and type
import { DashboardAPI } from "@api/global";
import { IDashboardSummary } from "@api/dashboard/type";
// component
import Loading from "@components/global/loading/Loading";
import CampaignList from "@components/admin-dashboard/CampaignList";
import DashboardStats from "@components/admin-dashboard/DashboardStats";

export default function AdminDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState<IDashboardSummary>();

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0)
    const getDashboardSummary = async () => {
      setIsLoading(true);
      DashboardAPI.getDashboardSummary()
        .then((response) => {
          if (isMounted) {
            setSummary(response);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.log("can not get dashboard summary error : ", err);
          }
        }).finally(() => {
          if(isMounted) setIsLoading(false);
        })
    };
    getDashboardSummary();

    return () => {
      isMounted = false;
    };
  }, []);

  const totalList = [
    {
      key: "campaigns",
      title: "Total Campaigns",
      value: summary?.total_campaign,
      logo: require("@assets/images/infographic/total-campaign.png"),
    },
    {
      key: "accounts",
      title: "Total Accounts",
      value: summary?.total_account,
      logo: require("@assets/images/infographic/total-accounts.png"),
    },
    {
      key: "employers",
      title: "Total Employers",
      value: summary?.total_employer,
      logo: require("@assets/images/infographic/total-employer.png"),
    },
    {
      key: "influencers",
      title: "Total Influencers",
      value: summary?.total_influencer,
      logo: require("@assets/images/infographic/total-influencer.png"),
    },
  ];

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Typography variant="h5">Dashboard</Typography>
      <Box display="flex" flexDirection="column" rowGap="24px" mt="24px">
        <Grid container spacing={4}>
          {totalList.map((item, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <Box
                sx={classes.cardWrap}
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="h4" color="#ED1968">
                    {item.value?.total || 0}
                  </Typography>
                  <Typography variant="body2">
                    + {(item.value?.diff_last_week || 0).toFixed(2)} last week
                  </Typography>
                </div>
                <Box sx={classes.imageWrap}>
                  <img src={item.logo} alt={`header-${item.key}`} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <DashboardStats summary={summary} />
        <CampaignList />
      </Box>
    </div>
  );
}
