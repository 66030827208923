// mui and icon
import {
  Paper,
  Table,
  Dialog,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
} from "@mui/material";
// icon and style
import CancelIcon from "@mui/icons-material/Cancel";
import { useDepositRateStyle as classes } from "../CampaignCharge.style";

export default function DepositRate(props: {
  open: boolean;
  onClose: (val: boolean) => void;
}) {
  const { open, onClose } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      sx={classes.dialog}
      onClose={() => onClose(false)}
    >
      <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
        <Typography variant="title1" color="#fff">
          อัตราค่ามัดจำ
        </Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ m: "32px 24px 32px 24px" }}>
        <TableContainer sx={classes.tableCon} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {["Campaign Budget", "อัตราค่ามัดจำ"].map((row, index) => (
                  <TableCell
                    key={index}
                    align={index === 1 ? "center" : "left"}
                    sx={{ bgcolor: "#F5F6F8" }}
                  >
                    <Typography variant="title1">{row}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={classes.tableBody}>
              {[
                { label: "มากกว่า 100,000 บาท", value: "5%" },
                { label: "มากกว่า 50,000 ถึง 100,000 บาท", value: "10%" },
                { label: "ตั้งแต่ 10,000 ถึง 50,000 บาท", value: "15%" },
                { label: "ไม่เกิน 10,000", value: "20%" },
              ].map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{row.label}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{row.value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
