// mui and style
import { Box, CardHeader, Grid, Typography } from "@mui/material";
import { useInfluencerSocialStyle as classes } from "./InfluencerSocial.style";
// component
import SocialPost from "./social-default/SocialPost";
import InfluencerRateCard from "./social-default/RateCard";
import SocialScreenshort from "./social-default/ScreenShort";
import OverviewTimeline from "./social-default/OverviewTimeline";
import InfluencerSocialHeader from "./social-default/SocialHeader";
// other
import { useLocation } from "react-router-dom";

export default function InfluencerSocial() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const platform = params.get("platform") || "";

  return (
    <Box display="flex" flexDirection="column" rowGap={["16px", "20px"]}>
      <Box sx={classes.card}>
        <CardHeader
          sx={classes.cardHeader}
          title={
            <Typography
              textTransform="capitalize"
              color="#fff"
              fontWeight="inherit"
            >
              {platform}
            </Typography>
          }
        />
        <InfluencerSocialHeader />
      </Box>
      <Grid container sx={classes.container} wrap="nowrap">
        <Grid item xs={12} sm={6} lg={5}>
          <Box display="flex" flexDirection="column" rowGap={["16px", "30px"]}>
            <OverviewTimeline />
            <InfluencerRateCard />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={7}>
          <Box display="flex" flexDirection="column" rowGap={["16px", "30px"]}>
            <SocialPost />
            <SocialScreenshort />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
