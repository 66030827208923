// mui and style
import {
  Box,
  Paper,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import {
  TableRowCustom,
  TableCellCustom,
  useReferralsRankingStyle as classes,
} from "../../OverviewTab.style";
// type
import { IReferralsRankingProps } from "../../OverviewTab.type";

export default function ReferralsRanking(props: IReferralsRankingProps) {
  const { ranking } = props;

  const rankingList = ranking?.data || [];

  return (
    <Box sx={classes.title}>
      <Box p="24px">
        <Typography variant="h6">Top 10 with the most referrals</Typography>
        <Typography variant="subTitle2">
          10 ลำดับแรกที่มีจำนวนการอ้างอิงสูงสุด
        </Typography>
      </Box>
      <TableContainer
        sx={{
          mt: "10px",
          borderRadius: rankingList.length === 10 ? "16px" : "16px 16px 0 0",
          boxShadow: rankingList.length === 10 ? undefined : "none",
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: "300px" }}>
          <TableHead>
            <TableRow>
              <TableCellCustom align="center">Rank</TableCellCustom>
              <TableCellCustom>Influencer Name</TableCellCustom>
              <TableCellCustom align="center">No. Ref.</TableCellCustom>
            </TableRow>
          </TableHead>
          {rankingList.length ? (
            <TableBody sx={classes.tableBody}>
              {rankingList.map((row, index) => (
                <TableRowCustom key={index}>
                  <TableCellCustom align="center">
                    <Box sx={classes.rankNum}>
                      {row.rank === 1 ? (
                        <Avatar
                          src={require("@assets/images/referral/rank-1.png")}
                          sx={{ width: "22px", height: "22px" }}
                        />
                      ) : row.rank === 2 ? (
                        <Avatar
                          src={require("@assets/images/referral/rank-2.png")}
                          sx={{ width: "22px", height: "22px" }}
                        />
                      ) : row.rank === 3 ? (
                        <Avatar
                          src={require("@assets/images/referral/rank-3.png")}
                          sx={{ width: "22px", height: "22px" }}
                        />
                      ) : (
                        ""
                      )}
                      <Typography variant="title1">{row.rank}</Typography>
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom>
                    <Box display="flex">
                      <Avatar
                        src={row.profile_image_url}
                        sx={classes.profile}
                      />
                      <Box ml="12px">
                        <Typography variant="title1" sx={classes.txtEllip}>
                          {row.name}
                        </Typography>
                        <Typography variant="body2" sx={classes.txtEllip}>
                          Code : {row.code}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Typography variant="title1">{row.point}</Typography>
                  </TableCellCustom>
                </TableRowCustom>
              ))}
            </TableBody>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
      {!rankingList.length && (
        <Box sx={classes.noRankBox}>
          <Avatar
            src={require("@assets/images/infographic/influencer/job-board/no-campaign.png")}
            sx={classes.infog}
          />
          <Box mt="24px" mx="20px">
            <Typography variant="h5" textAlign="center">
              ไม่มีการจัดลำดับ
            </Typography>
            <Typography textAlign="center">
              เนื่องจากอินฟลูเอนเซอร์ทั้งหมดมีจำนวน Referral เป็นศูนย์
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
