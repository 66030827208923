import { Select, SelectProps, styled } from "@mui/material";

export const useMyJobsTabStyle = {
  shippingProdSmBtn: {
    display: {
      xs: "flex",
      sm: "none",
    },
    justifyContent: "space-between",
    px: "30px",
    m: "15px 0 17px 0",
    maxHeight: "64px",
    minHeight: "64px",
    borderRadius: "8px",
    border: "none",
    "&:hover": {
      border: "none",
      color: "#fff",
    },
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    color: "#8C499C",
  },
  jobTabsCon: {
    bgcolor: {
      sm: "#fff",
    },
    borderRadius: {
      sm: "0px 16px 16px 16px",
    },
    p: {
      sm: "24px",
    },
    boxShadow: {
      sm: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    },
  },
  jobTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      "& p:first-of-type": { fontSize: "24px", fontWeight: "500" },
      "& p:last-of-type": {
        fontSize: "16px",
        color: "#5C5C5C",
      },
    },
  },
  shippingProBtn: {
    maxHeight: "36px",
    width: "191px",
    border: "none",
    bgcolor: "#F4ECFF",
    color: "#8C499C",
    "&:hover": {
      bgcolor: "#8C499C",
      color: "#fff",
      border: "none",
    },
    display: {
      xs: "none",
      sm: "flex",
    },
  },
  editBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    my: "20px",
    "& button": {
      bgcolor: "#ED1968",
      border: "1px solid #fff",
      color: "#fff",
      maxHeight: "40px",
      width: "240px",
      fontWeight: "500",
      "&:hover": {
        bgcolor: "#FFEAF4",
        color: "#ED1968",
        border: "1px solid #ED1968",
      },
    },
  },
};

export const useMultiInputImage = {
  removeBtn: {
    bgcolor: "black",
    minHeight: "20px",
    maxHeight: "20px",
    maxWidth: "20px",
    color: "#fff",
    "&:hover": {
      bgcolor: "black",
    },
    zIndex: 9,
    top: -9,
    right: -9,
    position: "absolute",
  },
  imagesItem: {
    height: {
      xs: "100%",
      sm: "120px",
    },
    width: {
      xs: "47%",
      sm: "120px",
    },

    "& > img": {
      width: {
        xs: "100%",
        sm: "120px",
      },
      objectFit: "cover",
      borderRadius: "8px",
    },
  },
  removeBtnFab: {
    position: "absolute",
    top: "6px",
    right: "-7px",
    maxWidth: "20px",
    minWidth: "20px",
    maxHeight: "20px",
    minHeight: "20px",
  },
  uploadBtn: {
    position: "absolute",
    bottom: "-10px",
    right: "-10px",
    maxWidth: "28px",
    minWidth: "28px",
    maxHeight: "28px",
    minHeight: "28px",
    zIndex: 9,
  },
  labelUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    position: "relative",
    width: {
      xs: "100%",
      sm: "120px",
    },
    height: {
      xs: "100%",
      sm: "120px",
    },
    minHeight: {
      xs: "120px",
      sm: "100px",
    },
    maxWidth: {
      // xs: "140px",
      sm: "100%",
    },
    border: "1.5px dashed gray",
    boxSizing: " border-box",
    borderRadius: "8px",
    color: "gray",
  },
};

export const useShippingProductSmStyle = {
  shippingProductTitle: {
    p: "16px",
    mt: "15px",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  prductImageBox: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "110px",
      height: "110px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  productImages: {
    position: "absolute",
    right: 0,
    width: "110px",
    height: "110px",
    borderRadius: "8px",
    bgcolor: "#000",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
};

export const useReviewPostInfluencerStyle = {
  influPostCon: {
    padding: "20px",
    borderRadius: "12px",
    border: {
      sm: "2px solid #EBDFFF",
    },
    display: "flex",
    justifyContent: "center",
    bgcolor: {
      xs: "#fff",
      sm: "initial",
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  postProfile: {
    display: "flex",
    alignItems: "center",
    mb: "16px",
    "& > img": {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  notImgPostBox: {
    height: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  postImgProfile: {
    my: "16px",
    "& > img": {
      width: "100%",
      height: "380px",
      borderRadius: "4px",
    },
  },
  postImgItem2: {
    display: "flex",
    height: "380px",
    "& img": { width: "190px", objectFit: "cover" },
    justifyContent: "center",
    "& img:first-of-type": { borderRadius: "4px 0 0 4px" },
    "& img:last-of-type": { borderRadius: "0 4px 4px 0" },
  },
  postImgItem3: {
    display: "flex",
    height: "380px",
    "& > img": {
      width: "50%",
      objectFit: "cover",
      borderRadius: "4px 0 0 4px",
    },
    "& > div": {
      "& img": { height: "186.5px", width: "190px", ml: "5px" },
      "& img:first-of-type": { borderRadius: "0 4px 0 0" },
      "& img:last-of-type": { borderRadius: "0 0 4px 0" },
    },
  },
  postImgItem4: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "380px",
      height: "380px",
      borderRadius: "4px",
    },
    "& > div": {
      "& img": {
        width: "120px",
        borderRadius: "4px",
      },
    },
  },
  postImgItemMoreThan4: {
    position: "absolute",
    width: "120px",
    height: "125px",
    right: 0,
    borderRadius: "4px",
    bgcolor: "#000",
    opacity: 0.2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
};

export const useSubmitDraftSuccessStyle = {
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  discBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: "10px",
  },
  img: {
    width: {
      xs: "250px",
      sm: "320px",
    },
    height: {
      xs: "250px",
      sm: "320px",
    },
    borderRadius: 0,
    mt: {
      sm: "25px",
    },
  },
  btnBox: {
    mb: "15px",
    minHeight: "40px",
    "& > button": { width: "100%" },
    "& > button:last-of-type": {
      border: "1px solid #8C499C",
    },
  },
};

export const useShippingProductDialogLgStyle = {
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    width: "89%",
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  trackingCode: {
    textDecoration: "underline",
    color: "#8C499C",
  },
  photos: {
    position: "relative",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: "110px",
      height: "110px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  photosItem: {
    position: "absolute",
    right: 15,
    width: "110px",
    height: "110px",
    borderRadius: "8px",
    bgcolor: "#000",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
  closeBtn: {
    mt: "20px",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
      maxHeight: "32px",
    },
  },
};

export const SelectBasic = styled(Select)<SelectProps>(
  ({ theme, ...props }) => {
    const initialStyle = {
      transition: "border-radius 0.1s",
      minHeight: "35px",
      maxHeight: "35px",
      "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",
        color: "#8C499C",
        "& > svg.MuiSvgIcon-root": {
          fontSize: "20px",
          marginRight: "4px",
          paddingBottom: "2px",
        },
        "& > .MuiAvatar-root > svg.MuiSvgIcon-root": {
          fontSize: "20px",
        },
      },
    };
    if (props.disabled) {
      return {
        ...initialStyle,
        backgroundColor: theme.palette.grey[100],
        "& .MuiSelect-select": {
          ...initialStyle["& .MuiSelect-select"],
          WebkitTextFillColor:
            props.value && props.value !== ""
              ? theme.palette.common.black
              : theme.palette.grey[300],
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.grey[100]} !important`,
        },
      };
    } else if (props.error) {
      return {
        ...initialStyle,
      };
    } else {
      return {
        ...initialStyle,
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
        },
      };
    }
  }
);

export const useShippingProductDialog = {
  dialogTitle: {
    bgcolor: "#8C499C",
    color: "#fff",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "89%",
    mt: "10px",
    "& button": {
      position: "absolute",
      zIndex: 1,
      top: -17,
      right: -16,
      "& svg": {
        bgcolor: "#fff",
        borderRadius: "50%",
        fill: "black",
        fontSize: "25px",
      },
    },
  },
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    width: "89%",
    bgcolor: "#fff",
    borderRadius: "0 0 20px 20px",
  },
  trackingCode: {
    textDecoration: "underline",
    color: "#8C499C",
  },
  photos: {
    position: "relative",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: "110px",
      height: "110px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  photosItem: {
    position: "absolute",
    right: 0,
    width: "110px",
    height: "110px",
    borderRadius: "8px",
    bgcolor: "#000",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
  closeBtn: {
    mt: "20px",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
      maxHeight: "32px",
    },
  },
};

export const usePlaceDetailDialogStyle = {
  dialog: useShippingProductDialog.dialog,
  dialogTitle: useShippingProductDialog.dialogTitle,
  contentDialog: useShippingProductDialog.contentDialog,
  btn: {
    justifyContent: "center",
    "& button": {
      minWidth: "220px",
    },
    "& > a": {
      "& button": {
        border: "none",
        "&:hover": { border: "none" },
      },
    },
  },
};

export const useUploadUrlStyle = {
  dialog: useSubmitDraftSuccessStyle.dialog,
  contentDialog: {
    ...useSubmitDraftSuccessStyle.contentDialog,
    width: "89%",
  },
  btnBox: {
    ...useSubmitDraftSuccessStyle.btnBox,
    display: "flex",
    justifyContent: "center",
    mt: "44px",
  },
  dialogTitle: useShippingProductDialog.dialogTitle,
  IconButton: {
    width: "25px",
    height: "25px",
  },
  addIcon: {
    width: "23px",
    height: "23px",
    borderRadius: "50%",
    bgcolor: "#4CAF50",
    color: "#fff",
  },
  textfield: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "20px",
    },
  },
};
