import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCampaingTabStyle as classes } from "./CampaignTab.style";
// component
import Statistics from "./Statistics";
import CampaignTabFilter from "./CampaignTabFilter";
import CampaignCard from "@global/campaign-card/CampaignCard";
// type and api
import {
  IInfluencerJobList,
  IInfluencerJobParams,
} from "@views/my-jobs/MyJobs.type";
import { JobAPI } from "@api/global";
// other
import Loading from "@global/loading/Loading";
import { useHistory, useParams } from "react-router-dom";
import PaginationCustom from "@global/pagination-custom";

export const initJobFilter = {
  page: 1,
  item_per_page: 16,
  review_type: "",
  platform: "",
  category: [],
  order_by: "date",
  sort: "desc",
  status: "",
  search: "",
};

export default function CampaignTab() {
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const mdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const { influencerId } = useParams<{ influencerId: string }>();

  const [jobList, setJobList] = useState<IInfluencerJobList | null>(null);
  const [jobFilter, setJobFilter] =
    useState<IInfluencerJobParams>(initJobFilter);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [paginate, setPaginate] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRowsPerPage(xsScreen ? 20 : mdScreen ? 15 : lgScreen ? 16 : 15);
    setPaginate(1);
  }, [lgScreen, mdScreen, xsScreen]);

  useEffect(() => {
    let isMounted = true;
    const params = {
      ...jobFilter,
      page: paginate,
      item_per_page: rowsPerPage,
    };

    JobAPI.getInfluencerJobList(influencerId, params)
      .then((response) => {
        if (isMounted) {
          setJobList(response);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [jobFilter, influencerId, paginate, rowsPerPage]);

  const goJobDetail = (jobId: string) => {
    history.push(`/influencers/${influencerId}/jobs?id=${jobId}`);
  };

  const handleFilterJob = (data: IInfluencerJobParams) => {
    setJobFilter(data);
    setPaginate(1);
  };

  const handlePageChange = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleRowsPerPageChange = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  if (isLoading) return <Loading />;

  return (
    <Box sx={classes.container}>
      <Statistics jobList={jobList} />
      <Divider />
      <CampaignTabFilter
        handleFilterJob={handleFilterJob}
        myJobsFilter={jobFilter}
      />

      <Box mx={["12px", 0]}>
        <Grid container spacing={5}>
          {jobList?.item?.length ? (
            jobList.item?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    goJobDetail(item.job_id);
                  }}
                >
                  <CampaignCard
                    name={item.name}
                    status={item.job_status}
                    coverUrl={item.cover_image?.url}
                    companyUrl={item.company?.media?.url}
                    platform={item.platform}
                    contentType={item.content_type}
                    avgBudget={item.avg_budget}
                    profileName={item.social_account.name}
                    profile={item.social_account?.profile_image_url}
                    category={item.category}
                  />
                </Box>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              minHeight={{ xs: "65vh", sm: "73vh", xl: "80vh" }}
            >
              <Box sx={classes.noContent}>
                {jobFilter.status === "" ? (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
                      alt="no campaign"
                    />
                    <Box sx={classes.textNoContent}>
                      <Typography variant="h5" my="8px">
                        ไม่พบแคมเปญ
                      </Typography>
                      <Typography>
                        คุณยังไม่ได้เข้าร่วมแคมเปญ
                        ค้นหาและสมัครเข้าร่วมแคมเปญที่คุณสนใจกันเลย
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/search-not-found.png")}
                      alt="search not found"
                    />
                    <Box sx={classes.textNoContent}>
                      <Typography variant="h5" my="8px">
                        ไม่พบผลการค้นหา
                      </Typography>
                      <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {jobList?.item?.length ? (
        <Box sx={classes.pagination}>
          <PaginationCustom
            page={paginate}
            count={
              paginate > 0 ? Math.ceil(jobList.total_item / rowsPerPage) : 0
            }
            onPaginationChange={handlePageChange}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      ) : null}
    </Box>
  );
}
