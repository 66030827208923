import { useEffect, useState } from "react";
// mui and style
import { Box, Typography } from "@mui/material";
import { expenseStyles as classes } from "./expense.style";
// api and type
import { ExpenseAPI } from "@api/global";
import { IExpense, IExpenseQueryParams } from "./expense.type";
// component
import Loading from "@components/global/loading/Loading";
import PaginationCustom from "@components/global/pagination-custom";
import ExpenseTab from "@components/employer-expense/ExpenseTab";
import ExpenseList from "@components/employer-expense/ExpenseList";
// other
import { useHistory, useLocation } from "react-router-dom";

export default function Expense() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expenseInfo, setExpenseInfo] = useState<IExpense | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const q = params.get("q") || "";
  const status = params.get("status") || "";
  const page = params.get("page") || "1";
  const sDate = params.get("s_date") || "";
  const eDate = params.get("e_date") || "";

  const queryParams: IExpenseQueryParams = {
    q: q,
    status: status,
    page: page,
    s_date: sDate,
    e_date: eDate
  };

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    ExpenseAPI.getAllExpenseList({
      item_per_page: 10,
      page: Number(page),
      order: "desc",
      order_by: "transaction_id",
      status: status,
      search: q,
      start_date: sDate,
      end_date: eDate
    })
      .then((response) => {
        if (isMounted) {
          setExpenseInfo(response);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [page, q, status, sDate, eDate]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    let params = { ...queryParams };
    params.page = newPage.toString()

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof IExpenseQueryParams]}`)
      .join("&");

    history.replace(`/expense?${queryString}`);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  if (isLoading) return <Loading />
  
  return (
    <div className="container-main">
      <Typography mt={2} mb={4} variant="h5">
        Expense
      </Typography>
      <Box sx={classes.expenseBox}>
        <ExpenseTab setIsLoading={setIsLoading} setExpenseInfo={setExpenseInfo} />
        <ExpenseList expenseList={expenseInfo?.item || []} />
      </Box>

      {expenseInfo?.item?.length ? (
        <Box sx={classes.pagination}>
          <PaginationCustom
            page={Number(page)}
            count={
              Number(page) > 0
                ? Math.ceil((expenseInfo.total_item || 0) / rowsPerPage)
                : 0
            }
            onPaginationChange={handleChangePage}
            rowsPerPageData={[10, 20, 30]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </div>
  );
}
