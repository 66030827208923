// mui and style
import { Box, Divider, InputAdornment, Radio, Typography } from "@mui/material";
import { useSpecifyAmountStyle as classes } from "../team-management/TeamManagement.style";
// other
import { useAppSelector } from "@store/hook";
import TextFieldCustom from "@global/text-field";
import { selectWalletBalance } from "@slices/PackageInfo.slice";

export default function SpecifyAmount(props: {
  amount: string;
  setAmount: (value: string) => void;
}) {
  const { amount, setAmount } = props;

  const walletBalance = useAppSelector(selectWalletBalance);

  const numFormater = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
  });

  const textFieldValue = (value: string) => {
    const numericValue = value.replace(/\D/gi, "");
    if (numericValue === "" || numericValue === "0") return "";
    return numFormater.format(Number(numericValue));
  };

  const revStringToNum = (num: string) => {
    return Number(num.replace(/\D/g, ""));
  };

  const handleAmountChange = (value: string) => {
    setAmount(value);
  };

  return (
    <Box sx={classes.con}>
      <Typography variant="h6">ระบุจำนวนเงิน</Typography>
      <Box my="24px">
        <TextFieldCustom
          value={textFieldValue(amount)}
          variant="outlined"
          placeholder="budget..."
          fullWidth
          onChange={(e) => handleAmountChange(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
          }}
        />
        <Typography variant="body2" textAlign="center" mt="12px">
          ยอดปัจจุบัน {numFormater.format(walletBalance)} บาท
        </Typography>
      </Box>
      <Box>
        <Typography variant="title1">เลือกจำนวนเงิน</Typography>
        <Box sx={classes.specifyAmountBox}>
          {[100, 500, 1000, 5000, 10000].map((num, index) => (
            <Box
              key={index}
              onClick={() => setAmount(textFieldValue(num.toString()))}
              sx={classes.specifyAmount}
            >
              <Radio checked={revStringToNum(amount) === num} value={num} />
              <Typography>{numFormater.format(num)} บาท</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={classes.divider} />
      <Box sx={classes.topUp}>
        <Typography variant="h5">Top Up</Typography>
        <Typography variant="h5">
          {numFormater.format(revStringToNum(amount))} บาท
        </Typography>
      </Box>
    </Box>
  );
}
