export const useEventsStyle = {
  conatiner: {
    m: {
      xs: "0 20px 20px 20px",
      sm: "0 40px 40px 40px",
      lg: "180px 125px 80px 120px",
    },
    border: "1px solid #f5f6f8",
  },
  titleWrap: {
    textAlign: "center",
    "& > h3": {
      mt: { xs: "80px", sm: "100px", lg: 0 },
    },
  },
  paper: {
    display: "flex",
    margin: {
      xs: "20px 0 0 0",
      sm: "20px 20px 0 20px",
    },
    height: "56px",
    borderRadius: "8px",
    maxWidth: "960px",
    width: "100%",
    boxShadow:
      "0px 12px 60px rgba(118, 118, 128, 0.08), 0px -1px 4px rgba(118, 118, 128, 0.02), 0px 2px 24px rgba(118, 118, 128, 0.02)",
  },
  searchWrap: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  selectWrap: {
    display: {
      xs: "none",
      sm: "flex",
    },
    alignItems: "center",
    justifyContent: "space-between",
    width: "200px",
    px: "16px",
    border: "none",
    bgcolor: "#fff",
    cursor: "pointer",
  },
  menuSelect: {
    "& > .MuiPaper-root": {
      width: "150px",
      borderRadius: "8px",
      boxShadow:
        "0px 12px 60px rgba(118, 118, 128, 0.08), 0px -1px 4px rgba(118, 118, 128, 0.02), 0px 2px 24px rgba(118, 118, 128, 0.02)",
    },
  },
  menuItem: {
    "&:hover": {
      color: "#8C499C",
      bgcolor: "#EBDFFF",
      fontWeight: 500,
    },
  },
  cardWrap: {
    display: "flex",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow:
      "0px 12px 60px rgba(118, 118, 128, 0.08), 0px -1px 4px rgba(118, 118, 128, 0.02), 0px 2px 24px rgba(118, 118, 128, 0.02)",
    height: { xs: "536px", md: "280px" },
    flexDirection: {
      xs: "column",
      md: "row",
    },
    cursor: "pointer",
  },
};
