import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Menu,
  Stack,
  Button,
  MenuItem,
  Typography,
  InputAdornment,
} from "@mui/material";
// type and style
import { IOverviewTabFilterDateProps } from "../OverviewTab.type";
import { useOverviewTabFilterDateStyle as classes } from "../OverviewTab.style";
// others
import TextFieldCustom from "@global/text-field";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export default function OverviewTabFilterDate(
  props: IOverviewTabFilterDateProps
) {
  const { setParam } = props;

  let date = new Date();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterDateSelected, setFilterDateSelected] = useState("all");
  const [endDate, setEndDate] = useState<Date | null>(date);
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(2022, 7, 29)
  );

  const dateOpen = Boolean(anchorEl);
  const handleOpenDate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDate = () => {
    setAnchorEl(null);
  };

  const filterDateList = [
    {
      label: "ทั้งหมด",
      value: {
        start: new Date(2022, 7, 29) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "all",
    },
    {
      label: "7 วันที่ผ่านมา",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 7
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "weekly",
    },
    {
      label: "30 วันที่ผ่านมา",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "chartType",
      type: "monthly",
    },
    {
      label: "60 วันที่ผ่านมา",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 2,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "2month",
    },
    {
      label: "90 วันที่ผ่านมา",
      value: {
        start: new Date(
          date.getFullYear(),
          date.getMonth() - 3,
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "3month",
    },
    {
      label: "1 ปีที่ผ่านมา",
      value: {
        start: new Date(
          date.getFullYear() - 1,
          date.getMonth(),
          date.getDate()
        ) as any,
        end: date as any,
      },
      filterType: "more1Month",
      type: "1year",
    },
    {
      label: "กำหนดเอง",
      value: {
        start: "",
        end: "",
      },
      filterType: "filterSelf",
      type: "filterSelf",
    },
  ];

  const onFilterDate = (type: string, start: any, end: any) => {
    setStartDate(start);
    setEndDate(end);
    setFilterDateSelected(type);
  };

  const onApplyFilters = () => {
    handleCloseDate();
    if (filterDateSelected === "all") {
      setParam(undefined);
    } else {
      setParam((prev) => {
        return {
          ...prev,
          date: {
            start: dayjs(startDate).format("YYYY-MM-DD"),
            end: dayjs(endDate).format("YYYY-MM-DD"),
          },
        };
      });
    }
  };

  const onClearFilters = () => {
    setStartDate(new Date(2022, 7, 29));
    setEndDate(date);
    setFilterDateSelected("all");
    handleCloseDate();
  };

  const onSelectDate = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Box sx={classes.filterDateBox}>
      <Button
        id="basic-button"
        aria-controls={dateOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={dateOpen ? "true" : undefined}
        onClick={handleOpenDate}
        endIcon={<Icon color="primary" className="ico-hu-calendar" />}
      >
        {`${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(endDate).format(
          "DD/MM/YYYY"
        )}`}
      </Button>
      <Menu
        id="date-select"
        anchorEl={anchorEl}
        open={dateOpen}
        onClose={handleCloseDate}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={classes.menuDate}
      >
        <Grid container spacing={5} mt={-2} sx={classes.menuDateGridCon}>
          <Grid item xs={3.5}>
            {filterDateList.map((item, index) => (
              <MenuItem
                sx={{
                  ...classes.menuItem,
                  bgcolor:
                    item.type === filterDateSelected ? "#EBDFFF" : "#fff",
                }}
                key={index}
                onClick={() => {
                  if (item.filterType !== "filterSelf") {
                    onFilterDate(item.type, item.value.start, item.value.end);
                  } else {
                    setFilterDateSelected(item.filterType);
                  }
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Grid>
          <Grid item xs={8.1}>
            <Stack direction="row" spacing={3}>
              <TextFieldCustom
                fullWidth
                value={dayjs(startDate).format("DD/MM/YYYY")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon color="primary" className="ico-hu-calendar" />
                    </InputAdornment>
                  ),
                }}
                sx={classes.txtFieldDate}
              />
              <TextFieldCustom
                fullWidth
                value={dayjs(endDate).format("DD/MM/YYYY")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon color="primary" className="ico-hu-calendar" />
                    </InputAdornment>
                  ),
                }}
                sx={classes.txtFieldDate}
              />
            </Stack>
            <Box sx={classes.reactDate}>
              <DatePicker
                calendarClassName="datepicker-calendar-container"
                selected={startDate}
                onChange={onSelectDate}
                dateFormat="dd/MM/yyyy"
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </Box>
          </Grid>
          <Grid item xs={12} mt={-3}>
            <Box display="flex" justifyContent="end" mr="20px" mb={3}>
              <Box sx={classes.applyBtn}>
                <Typography variant="buttonS" onClick={onClearFilters}>
                  Clear
                </Typography>
                <Button variant="contained" onClick={onApplyFilters}>
                  Apply Filters
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Menu>
    </Box>
  );
}
