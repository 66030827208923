// mui and icon
import {
  Box,
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useReturnProductStyle as classes } from "../../CampaignBrief.style";
// type
import { ICreateCampaignProps } from "../../CampaignBrief.type";
import { ICampaignBriefFormKey } from "@views/campaign/create-campaign/CreateCampaign.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// json
import provinces from "@assets/json/province.json";
import districts from "@assets/json/district.json";
import subdistricts from "@assets/json/subDistrict.json";
// others
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import WrapInputWithLabel from "@global/wrap-input-label";

export default function ReturnProduct(props: ICreateCampaignProps) {
  const { form } = props;

  const {
    watch,
    register,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = form;

  const employerInfo = useAppSelector(selectEmployerInfo);
  const shipping = employerInfo?.address.shipping_address;

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: ICampaignBriefFormKey
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onHandleShippingCheck = (e: any) => {
    const check = e.target.checked;
    setValue("product.is_shipping_address", check);
    if (check && employerInfo) {
      const shipping = employerInfo.address.shipping_address;
      const data = {
        fullname: shipping.fullname,
        phone: shipping.phone,
        postcode: shipping.postcode,
        province_id: shipping.province.id,
        district_id: shipping.district.id,
        subdistrict_id: shipping.subdistrict.id,
        address: shipping.address,
      };
      setValue("product.return_product_address", data);
    }
  };

  const handleChangeAddress = (
    e: { target: { value: number } },
    addressName: string
  ) => {
    const addressId = e.target.value;
    if (addressName === "province") {
      const provinceSelect = provinces.find(
        (province) => province.id === addressId
      );
      setValue(
        "product.return_product_address.province_id",
        (provinceSelect?.id as number) || null
      );
      setValue("product.return_product_address.district_id", null);
      setValue("product.return_product_address.subdistrict_id", null);
      setValue("product.return_product_address.postcode", "");

    } else if (addressName === "district") {
      const districtSelect = districts.find(
        (district) => district.id === addressId
      );
      setValue(
        "product.return_product_address.district_id",
        (districtSelect?.id as number) || null
      );
      setValue("product.return_product_address.subdistrict_id", null);
      setValue("product.return_product_address.postcode", "");
      
    } else {
      const subdistrictSelect = subdistricts.find(
        (subdistrict: { id: number }) => subdistrict.id === addressId
      );
      setValue(
        "product.return_product_address.subdistrict_id",
        (subdistrictSelect?.id as number) || null
      );
      setValue(
        "product.return_product_address.postcode",
        subdistrictSelect?.postcode || ""
      );
    }
  };

  return (
    <Grid item xs={12}>
      <WrapInputWithLabel labelWidth="200px" title="Product Details" required>
        <FormControlLabel
          control={
            <Checkbox
              checked={watch("product.is_shipping_address")}
              sx={classes.checkboxIcon}
            />
          }
          onChange={(e) => onHandleShippingCheck(e)}
          disabled={shipping?.fullname ? false : true}
          sx={classes.productDetailsLabel}
          label="ส่งกลับตาม Shipping Address"
        />
      </WrapInputWithLabel>
      {getValues("product.is_shipping_address") ? (
        <WrapInputWithLabel labelWidth="200px" title="">
          <Box sx={classes.address}>
            <Typography>{`${shipping?.fullname}, ${shipping?.phone}`}</Typography>
            <Typography>{`${shipping?.address} ${shipping?.subdistrict?.name_th} ${shipping?.district?.name_th} ${shipping?.province?.name_th} ${shipping?.postcode}`}</Typography>
          </Box>
        </WrapInputWithLabel>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">Full Name</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Full Name"
              fullWidth
              error={!!errors.product?.return_product_address?.fullname}
              helperText={errors.product?.return_product_address?.fullname?.message}
              inputProps={{
                ...register("product.return_product_address.fullname" as ICampaignBriefFormKey, {
                  required: true,
                }),
              }}
            />
          </Grid>

          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">Phone</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Phone"
              fullWidth
              error={!!errors.product?.return_product_address?.phone}
              helperText={errors.product?.return_product_address?.phone?.message}
              inputProps={{
                ...register("product.return_product_address.phone", {
                  required: true,
                }),
              }}
            />
          </Grid>

          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">Province</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <BasicSelectCustom
              id="address-province-select-1"
              value={watch("product.return_product_address.province_id") || ""}
              onChange={(e) => handleChangeAddress(e, "province")}
              data={provinces}
              valueKey="id"
              labelKey="name_th"
              placeholder="Province"
              fullWidth
              error={!!errors.product?.return_product_address?.province_id}
              helperText={
                errors.product?.return_product_address?.province_id?.message
              }
            />
          </Grid>

          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">District</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <BasicSelectCustom
              id="address-district-select-1"
              value={watch("product.return_product_address.district_id") || ""}
              onChange={(e) => handleChangeAddress(e, "district")}
              data={districts.filter(
                (dist) =>
                  dist.province_id ===
                  watch("product.return_product_address.province_id")
              )}
              valueKey="id"
              labelKey="name_th"
              placeholder="District"
              fullWidth
              error={!!errors.product?.return_product_address?.district_id}
              helperText={
                errors.product?.return_product_address?.district_id?.message
              }
            />
          </Grid>

          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">Subdistrict</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <BasicSelectCustom
              id="address-sub-district-select-1"
              value={
                watch("product.return_product_address.subdistrict_id") || ""
              }
              onChange={(e) => handleChangeAddress(e, "subdistrict")}
              data={subdistricts.filter(
                (dist) => dist.district_id ===
                  watch("product.return_product_address.district_id")
              )}
              valueKey="id"
              labelKey="name_th"
              placeholder="Subdistrict"
              fullWidth
              error={!!errors.product?.return_product_address?.subdistrict_id}
              helperText={
                errors.product?.return_product_address?.subdistrict_id?.message
              }
            />
          </Grid>

          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">Zip Code</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box sx={classes.postcode}>
              {getValues("product.return_product_address.postcode")}
            </Box>
          </Grid>

          <Grid item xs={3} mt="5px">
            <Box sx={classes.nameAndPhone}>
              <CircleIcon />
              <Typography variant="body2">Address</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <TextFieldCustom
              value={getValues("product.return_product_address.address")}
              onChange={(e) =>
                handleChangeInput(
                  e,
                  "product.return_product_address.address" as ICampaignBriefFormKey
                )
              }
              variant="outlined"
              multiline
              rows={2}
              placeholder="Address"
              fullWidth
              error={!!errors.product?.return_product_address?.address}
              helperText={
                errors.product?.return_product_address?.address?.message
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
