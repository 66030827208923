// mui
import {
  ButtonSignUpColorful,
  useHomeMainStyle as classes,
} from "./HomeMain.styles";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
// component
import { CarouselContent } from "@global/carousel-content";
// type and util
import { IHomeMainCompProps } from "@views/home-main/HomeMain.type";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";
// image
import StepInfographicImgDesk from "@assets/images/home/homepage/infographic/desktop.svg";
import StepInfographicImgTablet from "@assets/images/home/homepage/infographic/tablet.svg";
import StepInfographicImgMobile from "@assets/images/home/homepage/infographic/phone.svg";
import WhoWeAreImg from "@assets/images/home/homepage/who-we-are/who-we-are.png";
import QuoteLeftImg from "@assets/images/home/icon/quote-left.svg";
import QuoteRightImg from "@assets/images/home/icon/quote-right.svg";
// Logo Icon
import Absorba from "@assets/images/home/homepage/trusted-by/absorba.png";
import Bottoms from "@assets/images/home/homepage/trusted-by/bottoms-up.png";
import Britania from "@assets/images/home/homepage/trusted-by/britania.png";
import CP from "@assets/images/home/homepage/trusted-by/cp.png";
import Fitpub from "@assets/images/home/homepage/trusted-by/fit-pub.png";
import Goodlooking from "@assets/images/home/homepage/trusted-by/good-looking.png";
import Guardforce from "@assets/images/home/homepage/trusted-by/guardforce.png";
import IKEA from "@assets/images/home/homepage/trusted-by/ikea.png";
import Ikigai from "@assets/images/home/homepage/trusted-by/ikigai.png";
import Masterplanmedia from "@assets/images/home/homepage/trusted-by/masterplan-media.png";
import MeatZero from "@assets/images/home/homepage/trusted-by/meat-zero.png";
import Signature from "@assets/images/home/homepage/trusted-by/signature.png";
import WeCleanVR from "@assets/images/home/homepage/trusted-by/we-clean-vr.png";
import Bantapea from "@assets/images/home/homepage/trusted-by/บ้านท่าแพ.png";
// Mock our performance
import MobileImg from "@assets/images/home/homepage/our-performance/meat-zero.png";
import IgImg from "@assets/images/home/icon/instagram.png";
import ReachImg from "@assets/images/home/homepage/our-performance/reach.png";
import ImpressionImg from "@assets/images/home/homepage/our-performance/impression.png";
import EngagementImg from "@assets/images/home/homepage/our-performance/engagement.png";
// Utils
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function HomeMain({
  device,
  breakpoint,
  setOpenSelectAccount,
  setOpenedSignIn,
}: IHomeMainCompProps) {
  
  let numberFormat = new Intl.NumberFormat();

  const { session_token } = getTokenFromStorage();

  const logoList = [
    Absorba,
    Bottoms,
    Britania,
    CP,
    Fitpub,
    Goodlooking,
    Guardforce,
    IKEA,
    Ikigai,
    Masterplanmedia,
    MeatZero,
    Signature,
    WeCleanVR,
    Bantapea,
  ];

  const ourPerformanceList = [
    {
      header: "Success Project",
      subHeader: "Bologna Meat Zero",
      content: `Meat Zero Bologna และ Meet Zero Burger ไก่สไปซี่สินค้าขายดี และสินค้าออกใหม่ 
      ที่สามารถหาซื้อง่ายได้ตาม 7-11 ต้องการสื่อสารให้กับผู้บริโภคทุกไลฟ์สไตล์ 
      และทุกช่วงวัยมากขึ้น เพื่อสร้างการรับรู้ และการเข้าถึงผ่านช่องทาง TikTok`,
      image: MobileImg,
      state: [
        {
          image: IgImg,
          value: 17,
          pronoun: "Influencers",
        },
        {
          image: ReachImg,
          value: 762984,
          pronoun: "Reach",
        },
        {
          image: ImpressionImg,
          value: 898599,
          pronoun: "Impression",
        },
        {
          image: EngagementImg,
          value: 88669,
          pronoun: "Engagement",
        },
      ],
    },
  ];

  return (
    <Stack>
      <Box id="back-to-top-anchor" sx={classes.sectionOneBox}>
        <Box sx={classes.sectionOneBox2}>
          <div>
            <Box pt={[null, 22, 20, 18]}>
              <Typography
                color="secondary"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "body2",
                  "body2",
                  "body2",
                  "h6"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                HashU
              </Typography>
            </Box>
            <Box pb={[2, 4, 4, 6]} sx={classes.yellowText}>
              <Typography
                color="inherit"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "h4",
                  "h4",
                  "h4",
                  "h2"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                Influencer
                <br />
                Marketing Platform
              </Typography>
            </Box>
            <Box pb={[6, 12, 10, 12]}>
              {device === "mobile" ? (
                <Typography color="common.white" variant="body1" align="center">
                  HashU คอมมูนิตี้สุดคูลของเหล่า
                  <br />
                  <span style={classes.yellowText}>Influencers</span> สุดเจ๋ง
                  <br />
                  ที่จะสร้างพลังแห่งการ{" "}
                  <span style={classes.yellowText}>“บอกต่อ”</span>
                  <br />
                  ให้คำพูดเล็ก ๆ มีพลังที่ยิ่งใหญ่
                </Typography>
              ) : (
                <Typography
                  color="common.white"
                  variant={setValueByBreakpoint(
                    breakpoint,
                    "body1",
                    "body1",
                    "body1",
                    "h5"
                  )}
                >
                  HashU คอมมูนิตี้สุดคูลของเหล่า{" "}
                  <span style={classes.yellowText}>Influencers</span> สุดเจ๋ง
                  <br />
                  ที่จะสร้างพลังแห่งการ{" "}
                  <span style={classes.yellowText}>“บอกต่อ”</span> ให้คำพูดเล็ก
                  ๆ มีพลังที่ยิ่งใหญ่
                </Typography>
              )}
            </Box>
            {!session_token && (
              <Box
                textAlign={device === "mobile" ? "center" : undefined}
                pb={20}
              >
                <ButtonSignUpColorful
                  onClick={() => setOpenSelectAccount(true)}
                  sx={{
                    "&:hover": {
                      bgcolor: "violet",
                    },
                  }}
                >
                  <Typography variant="body1">ลงทะเบียนฟรี !!!</Typography>
                </ButtonSignUpColorful>
                <Box sx={{ marginTop: "20px" }}>
                  <Typography variant="body1" color="white">
                    เป็นสมาชิก HashU แล้ว{" "}
                    <Typography
                      variant="title1"
                      onClick={() => setOpenedSignIn(true)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "yellow",
                        },
                      }}
                    >
                      เข้าสู่ระบบ
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            )}
          </div>
        </Box>
      </Box>

      <Box sx={classes.sectionTwoBox}>
        <Stack alignItems="center" height="100%">
          <Box pt={[5, 20]}>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
            >
              Why Hashu ?
            </Typography>
          </Box>
          <Box display="flex" gap={2} pt={2} px={[4, 0]}>
            <Typography
              color="common.black"
              variant={setValueByBreakpoint(breakpoint, "h5", null, null, "h3")}
              fontWeight="bold"
              align="center"
            >
              The Influencer Marketing Platform for{" "}
              <span style={classes.textColorful}>Everyone</span>
            </Typography>
          </Box>
          <Box pt={[4, 6]} pb={[9, 10, 10, 16]} px={8}>
            <Typography
              color="common.black"
              variant={setValueByBreakpoint(
                breakpoint,
                "body1",
                null,
                null,
                "h5"
              )}
              align="center"
            >
              แพลตฟอร์มคอมมูนิตี้ของเหล่า Influencer
              ที่จะให้คุณและเหล่าตัวจี๊ดได้ใกล้ชิดกันมากยิ่งขึ้น
              <br />
              ด้วยเครื่องมือวิเคราะห์ข้อมูลที่แม่นยำแบบ “real-time
              communication”
              <br />
              การสร้างแคมเปญของคุณจะง่าย รวดเร็ว และตรงกลุ่มเป้าหมายที่สุด
            </Typography>
          </Box>
          <img
            src={
              device === "desktop"
                ? StepInfographicImgDesk
                : device === "tablet"
                ? StepInfographicImgTablet
                : StepInfographicImgMobile
            }
            alt="step infographic"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "80%",
                "90%",
                "90%",
                "80%"
              ),
              height: "auto",
            }}
          />
        </Stack>
      </Box>

      {device === "mobile" && (
        <Box display="flex" justifyContent="center" mt={20}>
          <img
            src={WhoWeAreImg}
            alt="Who we are"
            style={classes.whoWeAreMobileImg}
          />
        </Box>
      )}
      <Box sx={classes.sectionThreeContainer}>
        <Box sx={classes.sectionThreeBox}>
          <Paper elevation={0} sx={classes.whoWeArePaper}>
            <Box display="flex" p={[5, 10, 10, 16]} gap={[0, 7, 7, 12]}>
              {device !== "mobile" && (
                <Box display="flex">
                  <img
                    src={WhoWeAreImg}
                    alt="Who we are"
                    style={{
                      width: setValueByBreakpoint(
                        breakpoint,
                        "220px",
                        null,
                        "240px",
                        "340px",
                        "400px"
                      ),
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <Box>
                <Box pb={4}>
                  <Typography
                    variant={setValueByBreakpoint(
                      breakpoint,
                      "body2",
                      null,
                      null,
                      "h6"
                    )}
                    fontWeight="bold"
                    color="secondary"
                    align={setValueByBreakpoint(breakpoint, "center", "left")}
                  >
                    Who We Are
                  </Typography>
                </Box>
                <Box pb={[4, 5, 5, 9]}>
                  <Typography
                    variant={setValueByBreakpoint(
                      breakpoint,
                      "h5",
                      null,
                      null,
                      "h4"
                    )}
                    fontWeight="bold"
                    color="common.black"
                    align={setValueByBreakpoint(breakpoint, "center", "left")}
                  >
                    {device === "mobile"
                      ? "The Influencer Marketing"
                      : "HashU Influencer Marketing Platform"}
                  </Typography>
                </Box>
                <Box pb={[4, 4, 4, 8]}>
                  <Typography
                    variant={setValueByBreakpoint(
                      breakpoint,
                      "body1",
                      null,
                      null,
                      "h6"
                    )}
                    color="common.black"
                  >
                    Influencer Community คนรุ่นใหม่
                    ที่จะมาสร้างสังคมนักรีวิวคุณภาพไปด้วยกัน เปิดกว้างให้กับทุก
                    Passion ผ่านระบบฟิลเตอร์ที่แม่นยำและตรงกลุ่ม
                    ไม่ว่าคุณจะต้องการ Influencer แบบใด เพศ ไหน หรือ ไลฟ์
                    สไตล์อะไร
                    เพียงร่วมแคมเปญกับเราคุณก็จะได้รับงานการตลาดระดับมือโปร
                  </Typography>
                </Box>
                <Box display="flex">
                  <img
                    src={QuoteLeftImg}
                    alt="Quote Left"
                    style={{
                      height: setValueByBreakpoint(
                        breakpoint,
                        "20px",
                        "24px",
                        null,
                        "36px"
                      ),
                    }}
                  />
                </Box>
                <Box display="flex" px={[3, 5, 6, 6]}>
                  <Typography
                    variant={setValueByBreakpoint(
                      breakpoint,
                      "body1",
                      null,
                      null,
                      "h5"
                    )}
                    color="common.black"
                    width="100%"
                    fontStyle="italic"
                    textAlign="center"
                  >
                    เพราะเราเชื่อในพลังของการบอกต่อ (Word Of Mouth Marketing)
                    <br />
                    ผ่าน Influencer คุณภาพ ที่ไม่ใช่แค่การรีวิว
                    แต่เป็นการบอกเล่าสิ่งดีๆ ให้กับ User ตัวจริง
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <img
                    src={QuoteRightImg}
                    alt="Quote Right"
                    style={{
                      height: setValueByBreakpoint(
                        breakpoint,
                        "20px",
                        "24px",
                        null,
                        "36px"
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box sx={classes.ourPerformance}>
        <CarouselContent device={device} maxLength={ourPerformanceList.length}>
          {ourPerformanceList.map((d, index) => (
            <Box
              key={"data-" + index}
              display="flex"
              mx="auto"
              sx={classes.contentBox}
            >
              <Grid
                container
                direction={device === "mobile" ? "column-reverse" : "row"}
                justifyContent="center"
                alignItems="center"
                spacing={setValueByBreakpoint(breakpoint, 2, 0, 4, 8, 12)}
              >
                <Grid
                  item
                  xs={true}
                  sm={true}
                  md={true}
                  lg={true}
                  xl={true}
                  px={[0, 2, 2, 0]}
                  py={[4, 2, 2, 0]}
                >
                  <Paper sx={classes.paper}>
                    <Box px={[5, 7, 7, 12]} py={[6, 7, 7, 10]}>
                      <Typography
                        variant={setValueByBreakpoint(
                          breakpoint,
                          "body2",
                          null,
                          null,
                          "h6"
                        )}
                        color="secondary"
                        fontWeight="bold"
                        textAlign={device === "mobile" ? "center" : "start"}
                        mb={2}
                      >
                        {d.header}
                      </Typography>
                      <Typography
                        variant={setValueByBreakpoint(
                          breakpoint,
                          "h5",
                          null,
                          null,
                          "h4"
                        )}
                        fontWeight="bold"
                        textAlign={device === "mobile" ? "center" : "start"}
                        mb={6}
                        sx={classes.headerText}
                      >
                        {d.subHeader}
                      </Typography>
                      <Typography
                        variant={setValueByBreakpoint(
                          breakpoint,
                          "body1",
                          null,
                          null,
                          "h6"
                        )}
                        color="common.black"
                        textAlign="start"
                        mb={[3, 3, 3, 10]}
                      >
                        {d.content}
                      </Typography>
                      <Grid
                        container
                        justifyContent="center"
                        pb={[2, 4, 4, 12]}
                      >
                        {d.state.map((eng, index) => (
                          <Grid
                            key={index}
                            item
                            xs={6}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                          >
                            <Stack alignItems="center">
                              <Box sx={classes.engagementBox}>
                                <img
                                  src={eng.image}
                                  alt="ig"
                                  style={{
                                    height: setValueByBreakpoint(
                                      breakpoint,
                                      "38px",
                                      "40px",
                                      null,
                                      "58px"
                                    ),
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                              <Typography
                                variant={setValueByBreakpoint(
                                  breakpoint,
                                  "h5",
                                  "h6",
                                  "h5",
                                  "h4"
                                )}
                                fontWeight="bold"
                              >
                                {numberFormat.format(eng.value)}
                              </Typography>
                              <Typography
                                variant={setValueByBreakpoint(
                                  breakpoint,
                                  "body2",
                                  null,
                                  null,
                                  "h6"
                                )}
                                color="grey.500"
                              >
                                {eng.pronoun}
                              </Typography>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  sm="auto"
                  md="auto"
                  lg="auto"
                  xl="auto"
                  px={[0, 0, 0, 3]}
                >
                  <img
                    src={d.image}
                    alt="mobile"
                    style={{
                      width: setValueByBreakpoint(
                        breakpoint,
                        "260px",
                        "250px",
                        "280px",
                        "390px"
                      ),
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </CarouselContent>
      </Box>

      <Box sx={classes.sectionFourBox}>
        <Box sx={classes.trustedByHeaderBox}>
          <Typography
            variant={setValueByBreakpoint(breakpoint, "h5", "h5", "h5", "h3")}
            fontWeight={setValueByBreakpoint(
              breakpoint,
              "bold",
              "bold",
              "bold",
              "normal"
            )}
          >
            Trusted by
          </Typography>
        </Box>
        <Box display="center" justifyContent="center" pb={13}>
          <Grid
            container
            spacing={setValueByBreakpoint(breakpoint, 4, 6, 6, 4)}
            sx={classes.trustedByListGird}
          >
            {logoList.map((logo, index) => (
              <Grid
                key={index}
                item
                xs="auto"
                sx={classes.trustedByListGirdItem}
              >
                <img
                  src={logo}
                  alt={`Logo ${index + 1}`}
                  style={classes.trustedByImg}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
}
