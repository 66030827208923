import { useEffect, useState } from "react";
// mui and style, icon
import { Box, Fab, Icon, Stack, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "./CampaignPrivate.style";
import CircleIcon from "@mui/icons-material/Circle";
// type
import { IPrivateItemRes } from "@api/job/type";
import { ICardInfluencerPrivateProps } from "./PrivateCampaign.type";

export default function CardInfluencerPrivate(
  props: ICardInfluencerPrivateProps
) {
  const { item, privateSelected, setPrivateSelected, handleViewInfluencer } = props;

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const getValue = privateSelected?.find(
      (influe: IPrivateItemRes) => influe.influencer_id === item?.influencer_id
    );
    setIsSelected(Boolean(getValue));
  }, [item?.influencer_id, privateSelected]);

  const handleAddCandidate = () => {
    setPrivateSelected((privateSelected: IPrivateItemRes[]) => [
      ...privateSelected,
      item,
    ]);
  };

  const handleDeleteCandidate = () => {
    const privateFilter = privateSelected?.filter((influe: IPrivateItemRes) => {
      return influe.influencer_id !== item.influencer_id;
    });

    setPrivateSelected(privateFilter);
  };

  const totalEngagement = Math.round(item.engagement_rate * 100) / 100;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Box sx={{ position: "relative" }}>
      <Fab
        color={isSelected ? "success" : "primary"}
        aria-label="add"
        sx={isSelected ? classes.fabSelected : classes.fab}
        onClick={() => (isSelected ? handleDeleteCandidate() : handleAddCandidate())}
      >
        {isSelected ? (
          <>
            <Icon className="ico-hu-check" sx={{ color: "#fff" }} />
            <Typography className="showText" sx={classes.showText}>
              Remove
            </Typography>
          </>
        ) : (
          <>
            <Icon className="ico-hu-add" />
            <Typography className="showText" sx={classes.showText}>
              Select
            </Typography>
          </>
        )}
      </Fab>
      <Box
        sx={{
          ...classes.rateCard,
          backgroundColor: isSelected ? "#4CAF50" : "#893DBD",
        }}
      >
        <Typography variant="title2">
          {Intl.NumberFormat("en").format(Math.round(item.rate_card))} THB
        </Typography>
      </Box>
      <Box
        onClick={() => (isSelected ? handleDeleteCandidate() : handleAddCandidate())}
        sx={classes.boxImages}
      >
        <img
          src={item?.profile_image_url}
          className="first-image"
          alt="firstimage"
        />
      </Box>
      <Box
        sx={classes.boxDetail}
        onClick={() => {
          handleViewInfluencer(item.influencer_id, item);
        }}
      >
        <Box sx={{ padding: "12px" }}>
          <Box display="flex">
            <Typography sx={classes.textEllipsis}>{item.name}</Typography>
          </Box>

          {item.category === null ? (
            <Typography>-</Typography>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              divider={<CircleIcon />}
              sx={classes.stackCategory}
            >
              {item.category.map((cat: string, index: number) => (
                <Typography key={index} sx={classes.textCategory}>
                  {cat}
                </Typography>
              ))}
            </Stack>
          )}

          <Stack direction="row">
            <Box display="flex" alignItems="center" width="100%">
              <Icon className="ico-hu-group" sx={{ fontSize: "16px" }} />
              <Typography ml={1.5}>
                {numberFormat.format(item.follower)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" width="100%">
              <Icon
                className="ico-hu-engagement-rate"
                sx={{ fontSize: "16px" }}
              />
              <Typography ml={1.5}>{totalEngagement} %</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
