import { useEffect, useRef, useState } from "react";
// mui and component
import { Box, Icon, Typography } from "@mui/material";
import ViewMultiVideoDialog from "../view-video-dialog/ViewMultiVideoDialog";
// type and other
import { IVideoListProps } from "./CampaignDetail.type";
import { useVedioListStyle as classes } from "./CampaignDetail.style";
import ReactPlayer from "react-player";

export default function VideoItems(props: IVideoListProps) {
  const { videos, mb } = props;

  const [vdoHeight, setVidoHeight] = useState(0);
  const [viewVdo, setViewVdo] = useState(false);
  const [initVdoIndex, setInitVdoIndex] = useState(0);
  const vdoList = videos || [];

  const vdoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const vdoWidth = vdoRef.current?.clientWidth;
    setVidoHeight(vdoWidth ? vdoWidth : 0);
  }, []);

  const handleViewImage = (vdoIndex: number) => {
    setInitVdoIndex(vdoIndex);
    setViewVdo(true);
  };

  return (
    <>
      {/* for xs screen */}
      <Box
        alignItems="center"
        height="100%"
        flexWrap="wrap"
        display={["flex", "none"]}
        mb={mb}
      >
        {vdoList
          .slice(
            0,
            vdoList.length === 1 || vdoList.length === 3
              ? 1
              : vdoList.length === 4
              ? 4
              : 3
          )
          .map((vdo, index) => (
            <Box
              key={index}
              ref={vdoRef}
              maxHeight={`${vdoHeight}px`}
              minHeight={`${vdoHeight}px`}
              sx={{
                ...classes.breifVideo,
                "& div:last-of-type": {
                  width: "100% !important",
                  height: `${vdoHeight}px !important`,
                },
                overflow: "hidden",
                borderRadius:
                  vdoList.length === 1
                    ? "8px"
                    : vdoList.length === 2
                    ? index === 0
                      ? "8px 0 0 8px"
                      : "0 8px 8px 0"
                    : vdoList.length > 3
                    ? index === 0
                      ? "8px 0 0 0"
                      : index === 1
                      ? "0 8px 0 0"
                      : index === 2
                      ? "0 0 0 8px"
                      : "0 0 8px 0"
                    : "8px 0 0 8px",
              }}
              onClick={() => handleViewImage(index)}
            >
              <Box sx={classes.iconPlayVdo}>
                <Icon className="ico-hu-play-circle-outline" />
              </Box>
              <ReactPlayer url={vdo.url} controls={false} playing={false} />
            </Box>
          ))}
        {((vdoList.length !== 1 &&
          vdoList.length !== 2 &&
          vdoList.length !== 4) ||
          vdoList.length > 4) && (
          <Box height={`${vdoHeight}px`} width="50%" sx={classes.breifImg}>
            <ReactPlayer
              className="imgItem4"
              url={vdoList && vdoList[vdoList.length - 1].url}
              width={`100%`}
              height={`100%`}
              muted={true}
            />
            <Box
              sx={classes.breifVdoMoreThane4}
              onClick={() => setViewVdo(true)}
            >
              <Typography>
                +{" "}
                {vdoList.length - vdoList.length === 1 || vdoList.length === 3
                  ? 1
                  : vdoList.length - 4}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* for sm screen */}
      <Box
        display={["none", "flex", "none"]}
        alignItems="center"
        height="150px"
        flexWrap="wrap"
        mb={mb}
      >
        {vdoList.slice(0, vdoList.length === 2 ? 2 : 1).map((vdo, index) => (
          <Box
            key={index}
            maxHeight="150px"
            minHeight="150px"
            sx={{
              ...classes.breifVideo,
              "& div:last-of-type": {
                width: "150px !important",
                height: "150px !important",
              },
              overflow: "hidden",
              borderRadius:
                vdoList.length > 1
                  ? index === 0
                    ? "8px 0 0 8px"
                    : index === vdoList.length
                    ? "0 8px 8px 0"
                    : "0 8px 8px 0"
                  : "8px",
            }}
            onClick={() => handleViewImage(index)}
          >
            <Box sx={classes.iconPlayVdo}>
              <Icon className="ico-hu-play-circle-outline" />
            </Box>
            <ReactPlayer url={vdo.url} controls={false} playing={false} />
          </Box>
        ))}
        {vdoList.length !== 2 && vdoList.length > 1 && (
          <Box height="150px" sx={classes.breifImg}>
            <ReactPlayer
              className="imgItem4"
              url={vdoList && vdoList[vdoList.length - 1].url}
              width={`100%`}
              height={`100%`}
              muted={true}
            />
            <Box
              sx={classes.breifVdoMoreThane4}
              onClick={() => setViewVdo(true)}
            >
              <Typography>+ {vdoList.length - 2}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* for md screen */}
      <Box
        display={["none", "none", "flex", "flex", "none"]}
        alignItems="center"
        height="150px"
        flexWrap="nowrap"
        mb={mb}
      >
        {vdoList.slice(0, vdoList.length === 3 ? 3 : 2).map((vdo, index) => (
          <Box
            key={index}
            maxHeight="150px"
            minHeight="150px"
            minWidth="150px"
            sx={{
              ...classes.breifVideo,
              "& div:last-of-type": {
                width: "150px !important",
                height: "150px !important",
              },
              overflow: "hidden",
              borderRadius:
                vdoList.length === 3
                  ? index === 0
                    ? "8px 0 0 8px"
                    : index === vdoList.length - 1
                    ? "0 8px 8px 0"
                    : "0"
                  : vdoList.length > 3
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0"
                  : vdoList.length === 2
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0 8px 8px 0"
                  : "8px",
            }}
            onClick={() => handleViewImage(index)}
          >
            <Box sx={classes.iconPlayVdo}>
              <Icon className="ico-hu-play-circle-outline" />
            </Box>
            <ReactPlayer url={vdo.url} controls={false} playing={false} />
          </Box>
        ))}
        {vdoList.length > 3 && (
          <Box height="150px" sx={classes.breifImg}>
            <ReactPlayer
              className="imgItem4"
              url={vdoList && vdoList[vdoList.length - 1].url}
              width="150px"
              height="150px"
              muted={true}
            />
            <Box
              sx={classes.breifVdoMoreThane4}
              onClick={() => setViewVdo(true)}
            >
              <Typography>+ {vdoList.length - 3}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* for lg screen */}
      <Box
        display={{ xs: "none", xl: "flex" }}
        alignItems="center"
        height="150px"
        flexWrap="wrap"
        mb={mb}
      >
        {vdoList.slice(0, vdoList.length === 4 ? 4 : 3).map((vdo, index) => (
          <Box
            key={index}
            maxHeight="150px"
            minHeight="150px"
            sx={{
              ...classes.breifVideo,
              "& div:last-of-type": {
                width: "150px !important",
                height: "150px !important",
              },
              overflow: "hidden",
              borderRadius:
                vdoList.length === 3 || vdoList.length === 4
                  ? index === 0
                    ? "8px 0 0 8px"
                    : index === vdoList.length - 1
                    ? "0 8px 8px 0"
                    : "0"
                  : vdoList.length > 3
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0"
                  : vdoList.length === 2
                  ? index === 0
                    ? "8px 0 0 8px"
                    : "0 8px 8px 0"
                  : "8px",
            }}
            onClick={() => handleViewImage(index)}
          >
            <Box sx={classes.iconPlayVdo}>
              <Icon className="ico-hu-play-circle-outline" />
            </Box>
            <ReactPlayer url={vdo.url} controls={false} playing={false} />
          </Box>
        ))}
        {vdoList.length > 4 && (
          <Box
            height={{ xs: `${vdoHeight}px`, sm: "150px" }}
            sx={classes.breifImg}
          >
            <ReactPlayer
              className="imgItem4"
              url={vdoList && vdoList[vdoList.length - 1].url}
              width={`100%`}
              height={`100%`}
              muted={true}
            />
            <Box
              sx={classes.breifVdoMoreThane4}
              onClick={() => setViewVdo(true)}
            >
              <Typography>+ {vdoList.length - 4}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      <ViewMultiVideoDialog
        open={viewVdo}
        onClose={setViewVdo}
        videos={vdoList}
        initVideoIndex={initVdoIndex}
      />
    </>
  );
}
