import { useEffect } from "react";
// mui and icon
import { Box, Grid, Link, Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
// react route and style
import { useHistory, useParams } from "react-router-dom";
import { useViewInfluencerProfileStyle as classes } from "./Profile.style";
// component
import Loading from "@components/global/loading/Loading";
import InfluencerSocial from "@components/view-influencer-profile/influencer-social/InfluencerSocial";
import InfluencerProfile from "@components/view-influencer-profile/influencer-profile/InfluencerProfile";
// redux
import {
  getInfluencerSocialAsync,
  selectInfluencerSocialOnload,
} from "@slices/InfluencerSocial.slice";
import {
  getInfluencerProfileAsync,
  selectInfluencerProfileOnload,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";

export default function ViewInfluencerProfile() {
  const history = useHistory();
  const { influencerId } = useParams<{ influencerId: string }>();

  const dispatch = useAppDispatch();
  const socialLoading = useAppSelector(selectInfluencerSocialOnload);
  const profileLoading = useAppSelector(selectInfluencerProfileOnload);

  useEffect(() => {
    dispatch(getInfluencerProfileAsync({ influencerId }));
    dispatch(getInfluencerSocialAsync({ influencerId }));
  }, [dispatch, influencerId]);

  if (profileLoading || socialLoading) return <Loading />;

  return (
    <div className="container-main">
      <Link
        onClick={() => history.goBack()}
        underline="none"
        sx={classes.callback}
      >
        <KeyboardArrowLeft />
        <span>Back</span>
      </Link>
      <Box my="20px">
        <Typography variant="h5" sx={{ marginBottom: "8px" }}>
          View Influencer
        </Typography>
        <Typography fontSize="16px">ข้อมูลของอินฟลูเอนเซอร์</Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <InfluencerProfile />
        </Grid>
        <Grid item xs={12} md={8}>
          <InfluencerSocial />
        </Grid>
      </Grid>
    </div>
  );
}
