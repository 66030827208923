import { useEffect, useState } from "react";
// mui and style
import { Box, Fab, IconButton, Typography, Icon } from "@mui/material";
import "./imageInput.css";
// icon
import CloseIcon from "@mui/icons-material/Close";
import imageIcon from "@assets/images/other/photo.svg";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
// api
import { AppInformationAPI } from "@api/global";

interface IImageInput {
  register: Function;
  keyName: string;
  errors: any;
  [any: string]: any;
  // getValues: Function;
  values?: string;
}

function ImageInput(props: IImageInput) {
  const { register, keyName, errors, icon, setValue, imageKey } = props;
  const [imageData, setImageData] = useState<string>("");

  useEffect(() => {
    if (imageKey) {
      setImageData(imageKey);
    }
  }, [imageKey]);

  const onInputImage = async (e: any) => {
    const file = await e.target.files[0];
    const data = {
      file: file,
      destination: "company",
      permission: "public",
    };

    const image = await AppInformationAPI.uploadAttachment(data);

    setImageData(image.public_url as string);
    setValue(keyName, {
      file_id: "",
      file_name: image.file_name,
      file_type: image.file_type,
      url: image.public_url as string,
      destination: image.destination,
      purpose: "",
    });
  };

  const onHandleRemove = () => {
    setImageData("");
    setValue(keyName, "");
  };

  return (
    <Box sx={{ "& > div": { mb: 0 } }}>
      <Box position="relative" width="100px" height="100px" mb={3}>
        {imageData ? (
          <>
            <Box
              component="label"
              display="inline-block"
              sx={{ cursor: "pointer" }}
            >
              <img src={imageData} alt="img" className="image" />
              <input
                className=""
                type="file"
                accept="image/*"
                id="input-file"
                {...register(keyName, {
                  onChange: onInputImage,
                })}
              />
            </Box>

            <Fab
              onClick={onHandleRemove}
              sx={{
                position: "absolute",
                top: "-9px",
                right: "-9px",
                color: "black",
                width: "25px",
                height: "25px",
                minHeight: "25px",
                bgcolor: "#000",
                "&:hover": {
                  bgcolor: "#a2a2a2",
                },
                zIndex: 0,
              }}
            >
              <CloseIcon style={{ color: "#fff", fontSize: "20px" }} />
            </Fab>
          </>
        ) : (
          <div>
            <label
              className="label-upload"
              style={{
                borderColor: errors ? "red" : "",
                width: "100px",
                height: "100px",
              }}
            >
              <div
                style={{
                  width: "40px",
                }}
              >
                <img src={imageIcon} alt="img" style={{ color: "white" }} />
              </div>
              <div>+ Image</div>
              <input
                className=""
                type="file"
                accept="image/*"
                id="input-file"
                {...register(keyName, {
                  onChange: onInputImage,
                })}
              />
            </label>
          </div>
        )}
        {icon && (
          <IconButton
            className={imageData ? "inputButtonData" : "inputButton"}
            style={{
              backgroundColor:
                icon && icon === "camera" ? "#8C499C" : "#ED1968",
            }}
          >
            <label style={{ display: "flex", cursor: "pointer" }}>
              {icon && icon === "camera" ? (
                <CameraAltRoundedIcon
                  style={{ color: "white", fontSize: "16px" }}
                />
              ) : icon && icon === "plus" ? (
                <Icon className="ico-hu-attachment" sx={{ color: "white", fontSize: "16px" }} />
              ) : null}
              <input
                className=""
                type="file"
                accept="image/*"
                id="input-file"
                {...register(keyName, {
                  onChange: onInputImage,
                })}
              />
            </label>
          </IconButton>
        )}
      </Box>

      {errors && (
        <Typography
          variant="caption"
          color="error.main"
          fontWeight={400}
          ml="12px"
        >
          {errors.message}
        </Typography>
      )}
    </Box>
  );
}

export default ImageInput;
