import { useState } from "react";
// mui
import {
  Icon,
  Stack,
  Button,
  Typography,
  IconButton,
  DialogContent,
  InputAdornment,
} from "@mui/material";
// type and api
import {
  IResetPasswordSchma,
  IForgetPasswordSchema,
  IResetPasswordDialogProps,
} from "./AuthenDialog.types";
import { AuthenticationAPI } from "@api/global";
// Component
import DialogAuthentication from "../global/dialog-authentication";
import TextFieldCustom from "../global/text-field";
// route and style
import { useLocation } from "react-router";
import { useSignInDialogStyle as classes } from "./authentication.style";
// hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordSchema } from "./AuthenticationForm.schema";

const initResettPassword: IResetPasswordSchma = {
  email: "",
  recovery_code: "",
  new_password: "",
  confirmPassword: "",
};

export default function ResetPasswordDialog(props: IResetPasswordDialogProps) {
  const { open, setOpenResetPassword, openSignIn } = props;

  const [showPassword, setShowPassword] = useState(false);

  const {
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<IResetPasswordSchma>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: initResettPassword,
  });

  const location = useLocation();
  const parmas = new URLSearchParams(location.search);
  const email = parmas.get("email");

  const handleCloseDialog = () => {
    setOpenResetPassword(false);
  };

  const handleChangeInput = (e: any, name: keyof IResetPasswordSchma) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onResetPassword = async (data: IResetPasswordSchma) => {
    const newData = {
      email: email,
      recovery_code: data.recovery_code,
      new_password: data.new_password,
    };

    try {
      await AuthenticationAPI.resetPassword(newData);
      handleCloseDialog();
      openSignIn();
    } catch (error) {
      console.log("reset password error : ", error)
    }
  };

  const requestRecoverCode = async (value: IForgetPasswordSchema) => {
    try {
      await AuthenticationAPI.fogotPassword(value);
    } catch (error) {
      console.log("forgot password error : ", error)
    }
  };

  return (
    <DialogAuthentication
      title="Reset Password?"
      open={open}
      onClose={handleCloseDialog}
      sx={classes.authenDialog}
    >
      <DialogContent sx={classes.dialogContent}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onResetPassword)}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <TextFieldCustom
            value={getValues("recovery_code")}
            onChange={(e) => handleChangeInput(e, "recovery_code")}
            variant="outlined"
            placeholder="Recover Code"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="ico-hu-code"
                    sx={{ ...classes.textFieldIcon, paddingRight: "2px" }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.recovery_code}
            helperText={errors.recovery_code?.message}
          />
          <TextFieldCustom
            value={getValues("new_password")}
            onChange={(e) => handleChangeInput(e, "new_password")}
            type={!!showPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Password"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!!showPassword ? (
                      <Icon
                        className="ico-hu-visability-off"
                        sx={classes.textFieldIcon}
                      />
                    ) : (
                      <Icon
                        className="ico-hu-visability"
                        sx={classes.textFieldIcon}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.new_password}
            helperText={errors.new_password?.message}
          />
          <TextFieldCustom
            value={getValues("confirmPassword")}
            onChange={(e) => handleChangeInput(e, "confirmPassword")}
            type={!!showPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Confirm Password"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.500",
              },
              "& .MuiOutlinedInput-root .MuiInputAdornment-root": {
                color: "grey.500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      className="ico-hu-visability-on-solid"
                      sx={classes.textFieldIcon}
                    />
                    {!!showPassword ? (
                      <Icon
                        className="ico-hu-visability-off"
                        sx={classes.textFieldIcon}
                      />
                    ) : (
                      <Icon
                        className="ico-hu-visability"
                        sx={classes.textFieldIcon}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />
          <Button type="submit" fullWidth>
            <Typography variant="body1" fontWeight="bold">
              Change Password
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
      <DialogContent>
        <Stack
          component="form"
          onClick={() => {
            requestRecoverCode({
              email: email as string,
            });
          }}
          autoComplete="off"
          gap={5}
          py={1}
          px={5}
        >
          <Typography
            color="grey.800"
            variant="body1"
            align="center"
            sx={classes.forgetPasswordLink}
          >
            Request Recover Code
          </Typography>
        </Stack>
      </DialogContent>
    </DialogAuthentication>
  );
}
