import { useEffect, useState } from "react";
// mui and style
import { Box, Fab, Grid, Icon, IconButton, Typography } from "@mui/material";
import "./multiImageVideoInput.css";
// icon
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import imageIcon from "@assets/images/other/photo.svg";
import CircularProgress from "@mui/material/CircularProgress";
// api and alert
import { AppInformationAPI } from "@api/global";
import SwalCustom from "../sweet-alert-custom/SwalCustom";

interface IMultiImageVideoInput {
  register: Function;
  imageKeyName: string;
  videoKeyName: string;
  imageErrors: any;
  videoErrors: any;
  setValue?: Function;
  [any: string]: any;
  getValues?: Function;
}

export default function MultiImagesVideoInput(props: IMultiImageVideoInput) {
  const {
    register,
    imageKeyName,
    videoKeyName,
    imageErrors,
    videoErrors,
    setValue,
    imageKeyData,
    videoKeyData,
  } = props;
  const [imageData, setImageData] = useState<any>([]);
  const [videoData, setVideoData] = useState("");
  const [videoUploadUrl, setVideoUploadUrl] = useState("");
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    if (!videoData) {
      setVideoData(videoKeyData);
      setVideoUploadUrl(videoKeyData);
    }
    if (imageData.length === 0 || imageData === undefined) {
      setImageData(imageKeyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoKeyData, imageKeyData]);

  const onInputImage = async (ev: any) => {
    const file = await ev.target.files[0];
    const data = {
      file: file,
      destination: "company",
      permission: "public",
    };
    await AppInformationAPI.uploadAttachment(data)
      .then((response) => {
        const data = {
          file_id: "",
          file_name: response.file_name,
          file_type: response.file_type,
          purpose: "",
          url: response.public_url as string,
          destination: response.destination,
        };
        if (imageData.length === 0 || imageData === undefined) {
          setImageData([data]);
        } else {
          setImageData((imageData: any) => [...imageData, data]);
        }

        if (setValue) {
          setValue(imageKeyName, [...imageData, data]);
        }
      })
      .catch((err) => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${err.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  const onHandleRemove = (index: number) => {
    const image = [...imageData];
    image.splice(index, 1);
    setImageData(image);
    if (setValue) {
      setValue(imageKeyName, image);
    }
  };

  const onInputVideo = async (ev: any) => {
    setVideoLoading(true);
    const file = await ev.target.files[0];
    const data = {
      file: file,
      destination: "company",
      permission: "public",
    };
    await AppInformationAPI.uploadAttachment(data)
      .then((response) => {
        setVideoLoading(false);
        setVideoUploadUrl(response.public_url);
        if (setValue) {
          setValue(videoKeyName, [
            {
              file_id: "",
              file_name: response.file_name,
              file_type: response.file_type,
              purpose: "",
              url: response.public_url,
              destination: response.destination,
            },
          ]);
        }
      })
      .catch((err) => {
        setVideoLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${err.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
    if (file) {
      setVideoData(file);
    }
  };

  const onHandleVideoRemove = () => {
    setVideoData("");
    setVideoUploadUrl("");
    if (setValue) {
      setValue(videoKeyName, []);
    }
  };

  const onHandleChangeImage = async (e: any, fileId: string) => {
    const file = await e.target.files[0];
    const data = {
      file: file,
      destination: "company",
      permission: "public",
    };
    const imgData = [...imageData];
    const imgDataIndex = imgData.findIndex((item) => item.file_id === fileId);
    const image = await AppInformationAPI.uploadAttachment(data);
    imgData[imgDataIndex].file_name = image.file_name;
    imgData[imgDataIndex].url = image.public_url ? image.public_url : "";
    imgData[imgDataIndex].file_type = image.file_type;
    setImageData(imgData);
    if (setValue) {
      setValue(imageKeyName, imgData);
    }
  };

  return (
    <Grid container spacing={4} sx={{ width: "100%", mr: 1 }}>
      {imageData &&
        imageData.map((item: any, index: number) => {
          return (
            <Grid item xs={2.4} key={index}>
              <Box
                position="relative"
                width="100px"
                height="100px"
                component="label"
                display="inline-block"
                sx={{ cursor: "pointer" }}
              >
                <img src={item.url} alt="img" className="image" />
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  id="input-file"
                  onChange={(e: any) => onHandleChangeImage(e, item.file_id)}
                />
                <Fab
                  onClick={() => onHandleRemove(index)}
                  sx={{
                    position: "absolute",
                    top: "-9px",
                    right: "-9px",
                    color: "black",
                    width: "25px",
                    height: "25px",
                    minHeight: "25px",
                    bgcolor: "#000",
                    "&:hover": {
                      bgcolor: "#a2a2a2",
                    },
                    zIndex: 1,
                  }}
                >
                  <CloseIcon style={{ color: "#fff", fontSize: "20px" }} />
                </Fab>
              </Box>
            </Grid>
          );
        })}

      <Grid item>
        <div>
          <label
            className="label-upload"
            style={{
              borderColor: imageErrors ? "red" : "",
              width: "100px",
              height: "100px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "40px",
              }}
            >
              <img src={imageIcon} alt="img" style={{ color: "white" }} />
            </div>
            <div>+ Image</div>
            <input
              type="file"
              multiple
              accept="image/*"
              id="input-file"
              {...register(imageKeyName, {
                required: true,
                onChange: onInputImage,
              })}
            />
          </label>
        </div>
        {imageErrors ? (
          <Typography variant="caption" color="error.main" fontWeight={400}>
            {imageErrors?.message}
          </Typography>
        ) : (
          ""
        )}
      </Grid>

      <Grid item>
        <div style={{ marginLeft: "10px" }}>
          <Box position="relative" width="100px" height="100px">
            <div>
              <Box
                component="label"
                className="label-video-upload"
                sx={{
                  borderColor: videoErrors ? "red" : "",
                  width: "100px",
                  height: "100px",
                  borderRadius: "4px",
                  border: !videoUploadUrl ? "1px dashed #9e9e9e" : "none",
                }}
              >
                {!videoUploadUrl ? (
                  <>
                    <div
                      style={{
                        width: "40px",
                        marginBottom: "-5px",
                      }}
                    >
                      {!videoLoading && (
                        <img
                          src={
                            require("@assets/images/other/r-video-clip.svg")
                              .default
                          }
                          alt="vdo"
                          style={{ color: "white" }}
                        />
                      )}
                    </div>
                    <div>
                      {videoLoading ? <CircularProgress /> : <div>+Video</div>}
                    </div>
                  </>
                ) : (
                  <Box
                    sx={{
                      "& > video": {
                        objectFit: "cover",
                        width: "105px",
                        height: "105px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: "4px",
                        mt: "5px",
                        ml: "3px",
                      }}
                    >
                      <Icon
                        sx={{
                          fontSize: "40px",
                          color: "#fff",
                        }}
                        className="ico-hu-play-circle-outline"
                      />
                    </Box>
                    <video src={videoUploadUrl} />
                  </Box>
                )}
                <input
                  className=""
                  type="file"
                  accept="video/*"
                  id="input-file"
                  {...register(videoKeyName, {
                    onChange: onInputVideo,
                  })}
                />
              </Box>
            </div>
            {videoUploadUrl ? (
              <IconButton
                className="videoCancleIcon"
                onClick={onHandleVideoRemove}
                sx={{ position: "absolute", top: "-21px", right: "-21px" }}
              >
                <CancelIcon style={{ color: "black", fontSize: "30px" }} />
              </IconButton>
            ) : null}
          </Box>

          {videoErrors ? (
            <Typography variant="caption" color="error.main" fontWeight={400}>
              {videoErrors.message}
            </Typography>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
}
