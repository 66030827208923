// mui and token
import { useMediaQuery, useTheme } from "@mui/material";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// verify email
import VertifyEmail from "@views/vertify-email/index";
import VerifiedEmail from "@views/vertify-email/VerifiedEmail";
// global
import Global from "@views/global";
import Notification from "@views/notification";
//employer
import Employers from "@views/employer";
import EmployerProfile from "@views/employer-profile";
import EmployerDetail from "@views/employer/employer-detail";
import TransactionHistory from "@views/employer/transaction-history";
// privacy
import Privacy from "@views/privacy";
import TermsAndConditions from "@views/tems-and-conditions";
// influencer dashboard
import InfluencerList from "@views/influencer-dashboard/index";
import InfluencerInfo from "@views/influencer-dashboard/influencer-info";
import InfluencerCampaign from "@views/influencer-dashboard/influencer-campaign/index";
// landing page
import HomeMainPage from "@views/home-main";
import HomeEvents from "@views/home-events";
import HomePricing from "@views/home-pricing";
import HomeSupport from "@views/home-support";
import ContactUs from "@views/home-contact-us";
import HomeInfluencerPage from "@views/home-influencer";
import EventDetail from "@views/home-events/event-detail";
import HomeMarketerPage from "@views/home-marketer/index";
import InfluencerTutorials from "@views/home-support/influencer-tutorials";
// payment
import TopUp from "@views/payment";
import SubscriptionPaymant from "@views/subscription/payment";
// team and subscription
import SubscriptionPlan from "@views/subscription";
import TeamManagement from "@views/team-management";
import PackagePlanList from "@views/subscription/plan-list";
import DeleteTeam from "@views/team-management/delete-team";
// admin dashboard
import AdminDashboard from "@views/admin-dashboard";
// campaign
import Jobboard from "@views/job-board";
import Campaign from "@views/campaign/index";
import CreateCampaign from "@views/campaign/create-campaign";
import CancelCampaign from "@views/campaign/cancel-campaign";
import CampaignDetail from "@views/campaign/campaign-detail";
import JobBoardDetail from "@views/job-board/job-board-detail";
import CampaignCharge from "@views/campaign/campaign-charge";
import TrackProposalStatus from "@views/campaign/campaign-private/TrackProposalStatus";
import SelectingMoreInfluencer from "@views/campaign/campaign-private/SelectingMoreInfluencer";
import AddShipping from "@components/campaign/campaign-detail/influencer-list-tab/start-campaign/add-shipping/AddShipping";
// job
import MyJobs from "@views/my-jobs";
import PlaceDetail from "@views/my-jobs/place-detail";
import MyJobsDetail from "@views/my-jobs/jobs-detail";
import ShippingProduct from "@views/my-jobs/shipping-product";
import JobDraftApproved from "@views/employer-job/job-draft-approved";
import EmployerReviewPost from "@views/employer-job/employer-review-post";
// influencer
import Expense from "@views/expense";
import MyEarning from "@views/my-earning";
import MyReferral from "@views/my-referral";
import InfluencerProfile from "@views/influencer-profile";
import ViewInfluencerProfile from "@views/view-influencer-profile";
import SelectCategory from "@components/category-dialog/mobile/index";
import MyProfileForMobile from "@views/influencer-profile/mobile/my-profile";
import InfluencerPostReport from "@views/employer-job/view-influencer-report";
import EditAddressForMobile from "@views/influencer-profile/mobile/edit-address";
import EditRateCardForMobile from "@views/influencer-profile/mobile/edit-ratecard";
import EditMyProfileForMobile from "@views/influencer-profile/mobile/edit-my-profile";
import EditBankAccountForMobile from "@views/influencer-profile/mobile/edit-bank-account";
import EditMyPortfolioForMobile from "@views/influencer-profile/mobile/edit-my-portfolio";
// auth and route
import { Role } from "@contexts/type";
import { useAuth } from "@contexts/Auth";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import NoPage from "@views/no-page";

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean;
  role?: Role[];
}

export default function RouteList() {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  function CustomRoute({ role, isPrivate, ...rest }: CustomRouteProps) {
    const auth = useAuth();
    if (isPrivate) {
      if (auth.isAuthenticated && role?.includes(auth.role)) {
        return <Route {...rest} />;
      }
      return <Redirect to="/" />;
    }
    return <Route {...rest} />;
  }

  const routeList = [
    // landing page
    {
      path: "/",
      component: HomeMainPage,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/news-and-events",
      component: HomeEvents,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/pricing",
      component: HomePricing,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/support",
      component: HomeSupport,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/contact",
      component: ContactUs,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/news-and-events/:eventId",
      component: EventDetail,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/influencer",
      component: HomeInfluencerPage,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/marketer",
      component: HomeMarketerPage,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/home/support/tutorials",
      component: InfluencerTutorials,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    // campaign
    {
      path: "/job-board",
      component: Jobboard,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/job-board/:id/info",
      component: JobBoardDetail,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/campaigns",
      component: Campaign,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:campaignId/info",
      component: CampaignDetail,
      role: ["employer", "admin"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:type",
      component: CreateCampaign,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:campaignId/cancel-campaign",
      component: CancelCampaign,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:campaignId/track-proposal-status",
      component: TrackProposalStatus,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:campaignId/add-shipping",
      component: AddShipping,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:campaignId/charge",
      component: CampaignCharge,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/campaigns/:campaignId/select-more-influencer",
      component: SelectingMoreInfluencer,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    // employer job
    {
      path: "/employers/:employerId/jobs/:jobId/review-post",
      component: EmployerReviewPost,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/employers/:employerId/jobs/:jobId/draft-approval",
      component: JobDraftApproved,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    // influencer job
    {
      path: "/my-jobs",
      component: MyJobs,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/influencers/jobs/:jobId/info",
      component: MyJobsDetail,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/influencers/jobs/:jobId/post-report",
      component: InfluencerPostReport,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/influencers/:influencerId/jobs/:jobId/place-mobile",
      component: PlaceDetail,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/influencers/:influencerId/jobs/:jobId/shipping-product-mobile",
      component: ShippingProduct,
      role: ["influencer"],
      isPrivate: true,
    },
    // influencer
    {
      path: "/my-profile",
      component: role === "influencer" ? InfluencerProfile : EmployerProfile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-profile/mobile",
      component: MyProfileForMobile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-profile/mobile/edit-profile",
      component: EditMyProfileForMobile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-profile/mobile/edit-bank-account",
      component: EditBankAccountForMobile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-profile/mobile/edit-address",
      component: EditAddressForMobile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-profile/mobile/edit-portfolio",
      component: EditMyPortfolioForMobile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-profile/mobile/edit-ratecard",
      component: EditRateCardForMobile,
      role: ["influencer", "employer"],
      isPrivate: true,
    },
    {
      path: "/my-earning",
      component: MyEarning,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/my-referral",
      component: MyReferral,
      role: ["influencer"],
      isPrivate: true,
    },
    {
      path: "/select-category",
      component: isSmScreen ? InfluencerProfile : SelectCategory,
      role: ["influencer"],
      isPrivate: true,
    },
    // verify email
    {
      path: "/verify-email/:email",
      component: VertifyEmail,
      role: ["influencer"],
      isPrivate: false,
    },
    {
      path: "/verified-email/:email",
      component: VerifiedEmail,
      role: ["influencer", "employer", "admin", "guest"],
      isPrivate: false,
    },
    // globall
    {
      path: "/global",
      component: Global,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/global",
      component: Global,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/notification",
      component: Notification,
      role: ["admin", "employer", "influencer"],
      isPrivate: true,
    },
    // employer
    {
      path: "/employers",
      component: Employers,
      role: ["admin"],
      isPrivate: true,
    },
    {
      path: "/employers/:employerId/info",
      component: EmployerDetail,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/employers/:employerId/transaction",
      component: TransactionHistory,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/expense",
      component: Expense,
      role: ["admin"],
      isPrivate: true,
    },
    // privacy
    {
      path: "/privacy",
      component: Privacy,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    {
      path: "/terms-and-conditions",
      component: TermsAndConditions,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
    // influencer dashboard
    {
      path: "/influencers-dashboard",
      component: InfluencerList,
      role: ["admin"],
      isPrivate: true,
    },
    {
      path: "/influencers/:influencerId/info",
      component: InfluencerInfo,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/influencers/:influencerId/jobs",
      component: InfluencerCampaign,
      role: ["admin"],
      isPrivate: true,
    },
    {
      path: "/influencers/:influencerId/profile",
      component: ViewInfluencerProfile,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    // payment
    {
      path: "/payment/top-up/:plan",
      component: TopUp,
      role: ["employer", "admin"],
      isPrivate: true,
    },
    // team and subscription
    {
      path: "/subscription",
      component: SubscriptionPlan,
      role: ["employer"],
      isPrivate: true,
    },
    {
      path: "/subscription/:type/:plan",
      component: PackagePlanList,
      role: ["employer"],
      isPrivate: true,
    },
    {
      path: "/subscription/:type",
      component: SubscriptionPaymant,
      role: ["employer","admin"],
      isPrivate: true,
    },
    {
      path: "/team",
      component: TeamManagement,
      role: ["employer"],
      isPrivate: true,
    },
    {
      path: "/team/delete",
      component: DeleteTeam,
      role: ["employer"],
      isPrivate: true,
    },
    // admin dashboard
    {
      path: "/admin-dashboard",
      component: AdminDashboard,
      role: ["admin", "employer"],
      isPrivate: true,
    },
    {
      path: "/*",
      component: NoPage,
      role: ["admin", "employer", "influencer", "guest"],
      isPrivate: false,
    },
  ];

  return (
    <Switch>
      {routeList.map((route, index) => (
        <CustomRoute
          key={index}
          path={route.path}
          component={route.component}
          exact
          role={route.role as Role[]}
          isPrivate={route.isPrivate}
        />
      ))}
    </Switch>
  );
}
