import { useState } from "react";
// mui
import { Grid } from "@mui/material";
// other
import TextFieldCustom from "../text-field";
import { Controller } from "react-hook-form";
import { numberWithCommas } from "@utils/helpers/numberWithCommas";

interface IRangeInput {
  startKeyName: string;
  endKeyName: string;
  control: any;
  errors: any;
  setError?: any;
  clearErrors?: any;
  firstPlaceholder?: string;
  secondPlaceholder?: string;
  [any: string]: any;
}

function RangeInput(props: IRangeInput) {
  const {
    startKeyName,
    endKeyName,
    control,
    watch,
    setError,
    clearErrors,
    firstPlaceholder,
    secondPlaceholder,
  } = props;

  const [firstError, setFirstError] = useState<any>();
  const [secondError, setSecondError] = useState<any>();

  const conver2Number = (data: string | number) => {
    if (typeof data === "string") {
      return data.replaceAll(",", "");
    } else {
      return data;
    }
  };

  const firstValue = +watch(startKeyName);
  const secondValue = +watch(endKeyName);

  const onCheckRange = () => {
    if (conver2Number(firstValue) > conver2Number(secondValue)) {
      setError(startKeyName, {
        type: "custom",
        message: `please input data in range`,
      });

      setError(endKeyName, {
        type: "custom",
        message: `please input data in range`,
      });
    } else {
      clearErrors(startKeyName);
      clearErrors(endKeyName);
      setFirstError("");
      setSecondError("");
    }
  };

  return (
    <Grid container>
      <Grid item xs={5} md={5.7}>
        <Controller
          name={startKeyName}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, name } }) => (
            <TextFieldCustom
              variant="outlined"
              placeholder={firstPlaceholder}
              fullWidth
              error={firstError ? true : false}
              helperText={firstError ? firstError?.message : ""}
              // value={watch(startKeyName) && watch(startKeyName) }
              onChange={(ev) => {
                const newNum = numberWithCommas(ev.target.value);
                onChange(newNum);
              }}
              value={value ? value : ""}
              name={name}
              // ref={ref}
              onBlur={() => {
                onCheckRange();
                // getErrorPath();
              }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "50px",
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={2}
        md={0.6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // onClick={getErrorPath}
      >
        -
      </Grid>

      <Grid item xs={5} md={5.7}>
        <Controller
          name={endKeyName}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, name } }) => (
            <TextFieldCustom
              variant="outlined"
              placeholder={secondPlaceholder}
              fullWidth
              error={secondError ? true : false}
              helperText={secondError ? secondError?.message : ""}
              // value={value ? `${value} ${unit ? unit : ""}` : ""}
              value={value ? value : ""}
              onChange={(ev) => {
                const newNum = numberWithCommas(ev.target.value);
                onChange(newNum);
              }}
              name={name}
              // ref={ref}
              onBlur={() => {
                onCheckRange();
                // getErrorPath();
              }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "50px",
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default RangeInput;
