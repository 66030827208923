import React, { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Avatar,
  Dialog,
  Divider,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useInfluencerProfileInfoStyle as classes } from "../InfluencerProfile.style";
// api and type
import { InfluencerAPI } from "@api/global";
import { IAttachments } from "@api/app-information/type";
import { IEditInfluencerProfileForm } from "../InfluencerProfile.type";
// redux
import {
  setInfluencerProfile,
  selectInfluencerProfile,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditInfluencerProfileFormSchema } from "../InfluencerProfile.shema";
// other
import nl2br from "react-nl2br";
import { useHistory } from "react-router-dom";
import EditProfileButton from "@global/edit-profile-button";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import EditInfluencerProfile from "../edit-influencer-profile/EditProfile";
import dayjs from "dayjs";

const initEditInfluencerProfileForm: IEditInfluencerProfileForm = {
  fullname: "",
  birth_date: "",
  gender: "",
  nationality: "",
  phone: "",
  line_id: "",
  description: "",
};

export default function InfluencerProfileInfo() {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [images, setImages] = useState<IAttachments[] | []>([]);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);

  const form = useForm<IEditInfluencerProfileForm>({
    resolver: yupResolver(EditInfluencerProfileFormSchema),
    defaultValues: initEditInfluencerProfileForm,
  });

  useEffect(() => {
    if (!influencerInfo) return;
    const data = {
      fullname: influencerInfo.fullname,
      birth_date: dayjs(influencerInfo.birth_date).format("YYYY-MM-DD"),
      gender: influencerInfo.gender,
      nationality: influencerInfo.nationality,
      phone: influencerInfo.phone,
      line_id: influencerInfo.line_id,
      description: influencerInfo.description,
    };
    setImages(influencerInfo?.media?.images || []);
    form.reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencerInfo]);

  const infProfileImage = (purpose: string, className: string) => {
    const image = (influencerInfo?.media?.images || []).find(
      (img) => img?.purpose === purpose
    );
    if (image) {
      return <Avatar src={image.url} className={className} />;
    } else {
      return <Avatar className={className} />;
    }
  };

  const onUpdateProfile = async (value: IEditInfluencerProfileForm) => {
    setOpenEditDialog(false);
    const imageList = images.map((img) => {
      return {
        ...img,
        file_id: img.file_id.includes("img-") ? "" : img.file_id,
      };
    });
    const data = {
      profile: {
        ...value,
        phone: value.phone.replaceAll("-", ""),
        media: {
          images: imageList,
        },
      },
    };
    try {
      const response = await InfluencerAPI.updateInfluencerProfile(data);
      dispatch(setInfluencerProfile(response));
      SwalCustom.fire({
        icon: "success",
        title: "Updated profile success",
        showConfirmButton: false,
        timer: 1600,
      });
    } catch (error) {
      SwalCustom.fire({
        icon: "error",
        title: "Oops!",
        text: "Error. Something went wrong.",
        showConfirmButton: true,
        timer: 1600,
      });
    }
  };

  return (
    <Box m={{ xs: "20px 20px 12px 20px", lg: "20px" }}>
      <Box display={{ xs: "none", md: "initial" }}>
        <Box m="20px 0px 0 0px" position="relative">
          <Box sx={classes.imageBox}>
            {infProfileImage("profile_image", "profile")}
            <Box sx={classes.profileImg}>
              <Box m="20px">
                <Typography variant="h6" color="#fff">
                  {influencerInfo?.fullname}
                </Typography>
                <Box display="flex" columnGap="10px">
                  {[
                    `age : ${influencerInfo?.age}`,
                    influencerInfo?.gender,
                    influencerInfo?.nationality,
                  ].map((item, index) => (
                    <Typography
                      key={index}
                      variant="title2"
                      textTransform="capitalize"
                      color="#C2A8EF"
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.imageProfileItem}>
          {["frontal", "portrait", "headshot"].map((img, index) => (
            <Box key={index} sx={{ width: "100%" }}>
              {infProfileImage(`influencer_${img}_image`, "profile-item")}
            </Box>
          ))}
        </Box>
        {influencerInfo?.description && (
          <Box sx={classes.descriptionBox}>
            <Typography variant="body2">
              {nl2br(influencerInfo.description)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt="20px">
        <Typography variant="title1">Contact</Typography>
        <Box mt="10px">
          {[
            {
              label: "Phone Number",
              icon: <Icon className="ico-hu-phone" color="primary" />,
              value: influencerInfo?.phone,
            },
            {
              label: "Line ID",
              icon: <Icon className="ico-hu-line" color="primary" />,
              value: influencerInfo?.line_id,
            },
            {
              label: "Email",
              icon: <Icon className="ico-hu-mail" color="primary" />,
              value: influencerInfo?.email,
            },
          ].map((item, index) => (
            <React.Fragment key={index}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box ml={{ lg: "15px" }}>{item.icon}</Box>
                <Box ml="15px">
                  <Typography variant="title2" color="#616161">
                    {item.label} :
                  </Typography>
                  <Typography>{item.value}</Typography>
                </Box>
              </Box>
              {index !== 2 && <Divider sx={{ my: "12px" }} />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Box sx={classes.submitBtn}>
        <EditProfileButton
          startIcon={<Icon className="ico-hu-edit" />}
          onClick={() =>
            xsScreen
              ? history.push("/my-profile/mobile/edit-profile")
              : setOpenEditDialog(true)
          }
        >
          <Typography variant="button" fontWeight="inherit">
            Edit Profile
          </Typography>
        </EditProfileButton>
      </Box>

      {/* dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={classes.dialog}
      >
        <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="title1">My Profile</Typography>
          <IconButton onClick={() => setOpenEditDialog(false)}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.contentDialog}>
          <EditInfluencerProfile
            form={form}
            onSubmit={onUpdateProfile}
            setImages={setImages}
            images={images}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
