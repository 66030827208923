// mui and style
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTypeOfInfluencerStyle as classes } from "../../OverviewTab.style";
// components and type
import InfluencerTiers from "./InfluencerTiers";
import TypeOfInfluencerStats from "./TypeOfInfluencerStats";
import ContentDistribution from "./content-distribution/ContentDistribution";
import { ITypeOfInfluencerProps } from "../../OverviewTab.type";

export default function TypeOfInfluencer(props: ITypeOfInfluencerProps) {
  const {
    param,
    setParam,
    tiersDist,
    socialDist,
    cateDistTier,
    cateDistPlatform,
  } = props;
  return (
    <Box sx={classes.title}>
      <div>
        <Typography variant="h6">Type of Influencers</Typography>
        <Typography variant="subTitle2">ประเภทของอินฟลูเอนเซอร</Typography>
      </div>
      <Divider sx={{ my: "20px" }} />
      <Grid container spacing={13}>
        <Grid item xs={12}>
          <TypeOfInfluencerStats socialDist={socialDist} />
        </Grid>
        <Grid item xs={12}>
          <InfluencerTiers tiersDist={tiersDist} />
        </Grid>
        <Grid item xs={12}>
          <ContentDistribution
            param={param}
            setParam={setParam}
            cateDistTier={cateDistTier}
            cateDistPlatform={cateDistPlatform}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
