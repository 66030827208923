import { useEffect, useState } from "react";
// mui and style
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "./CampaignPrivate.style";
// component
import CardPrivate from "./CardPrivate";
import PrivateStep from "../campaign-detail/PrivateStep";
import InfluencerListStatus from "./status/InfluencerListStatus";
// type and api
import {
  IPrivateItemRes,
  IPrivateCampaignParams,
  ICampaignPrivateItemRes,
} from "@api/job/type";
import { JobAPI } from "@api/global";
import { IPrivateSummary } from "./PrivateCampaign.type";
// data
import {
  initPrivateSummary,
  initInfluencerListParams,
} from "./PrivateCampaignData";
// redux
import {
  setCheckSelectedPrivate,
  selecteCheckSelectPrivate,
} from "@slices/CampaignPrivate.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
// other
import Loading from "@global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function PrivateIndex() {
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";

  const { campaignId } = useParams<{ campaignId: string }>();

  const dispatch = useAppDispatch();
  const checkSelected = useAppSelector(selecteCheckSelectPrivate);
  const campaignInfo = useAppSelector(selectCampaignInfo);

  const [privateInfluencerList, setPrivateInfluencerList] = useState<ICampaignPrivateItemRes>();
  const [estimateSummary, setEstimateSummary] = useState<IPrivateSummary>(initPrivateSummary);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAPI(employerId, campaignId, initInfluencerListParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAPI = async (
    employerId: string,
    campaignId: string,
    params: IPrivateCampaignParams
  ) => {
    setIsLoading(true);

    try {
      setIsLoading(false);
      const response = await JobAPI.getInfluencerPrivate(
        employerId,
        campaignId,
        params
      );
      const newResponse = response;

      // if (
      //   checkSelected.employerId === employerId &&
      //   checkSelected.campaignId === campaignId
      // ) {
      //   response.item.forEach((item: IPrivateItemRes) => {
      //     const findIndex = checkSelected.item.findIndex((find) => {
      //       return find.influId === item.influencer_id;
      //     });
      //     if (findIndex !== -1) {
      //       newResponse.item[findIndex].status =
      //         checkSelected.item[findIndex].status;
      //     }
      //   });
      // }

      setPrivateInfluencerList(response);
      dispatch(
        setCheckSelectedPrivate({
          employerId,
          campaignId: campaignId,
          item: newResponse.item.map((item: IPrivateItemRes) => {
            return {
              influId: item.influencer_id,
              status: item.status,
            };
          }),
        })
      );

      const summary: any[] = [];

      const newData = {
        followers: 0,
        likes: 0,
        comments: 0,
        shares: 0,
        budgets: 0,
      };
      response.item.forEach((item: IPrivateItemRes, index: number) => {
        if (item.status === "") return;
        summary[index] = item;
        newData.followers += item.follower;
        newData.likes += item.avg_like;
        newData.comments += item.avg_comment;
        newData.shares += item.avg_share;
        newData.budgets += item.rate_card;
      });

      const lengthSummary = summary.filter((item) => {
        return item?.status !== "";
      });

      setEstimateSummary({
        ...newData,
        privateSelected: lengthSummary?.length,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (id: string) => {
    if (!privateInfluencerList) return;

    const findIndex = privateInfluencerList.item.findIndex(
      (find) => find.influencer_id === id
    );

    if (findIndex === -1) return;

    const newPrivate = privateInfluencerList;

    setPrivateInfluencerList((prev) => {
      if (!prev) return;
      prev.item[findIndex].status =
        prev.item[findIndex].status === "stealth" ? "" : "stealth";
      return {
        ...prev,
      };
    });

    dispatch(
      setCheckSelectedPrivate({
        ...checkSelected,
        item: newPrivate.item.map((item) => ({
          influId: item.influencer_id,
          status: item.status,
        })),
      })
    );

    const summary: any[] = [];

    const newData = {
      followers: 0,
      likes: 0,
      comments: 0,
      shares: 0,
      budgets: 0,
    };
    newPrivate.item.forEach((item, index) => {
      if (item.status === "") return;
      summary[index] = item;
      newData.followers += item.follower;
      newData.likes += item.avg_like;
      newData.comments += item.avg_comment;
      newData.shares += item.avg_share;
      newData.budgets += item.rate_card;
    });

    const lengthSummary = summary.filter((item) => {
      return item?.status !== "";
    });

    setEstimateSummary({
      ...newData,
      privateSelected: lengthSummary?.length,
    });
  };

  const goOrganizing = () => {
    if (role === "employer") {
      history.push(`/campaigns/${campaignId}/info?st=private-organizing`);
    } else {
      history.push(
        `/campaigns/${campaignId}/info?st=private-organizing&emp_id=${employerId}`
      );
    }
  };

  const selectInfluencer = async () => {
    setIsLoading(true);
    let countLimit = 0;

    const newData: {
      social_account_id: number;
      status: string;
    }[] = [];

    privateInfluencerList?.item.forEach((item) => {
      if (item.status === "") return;
      countLimit++;
      newData.push({
        social_account_id: item.id,
        status:
          countLimit <= (campaignInfo?.job_limit || 0) ? "stealth" : "reserved",
      });
    });

    try {
      await JobAPI.insertJobPrivateCampaign(employerId, campaignId, newData);
      goOrganizing();
    } finally {
      setIsLoading(false);
    }
  };

  const goViewInfluencer = (influencerId: string, item: IPrivateItemRes) => {
    history.push(
      `/influencers/${influencerId}/profile?platform=${item.platform}&c_type=private`
    );
  };

  if (isLoading) return <Loading />

  return (
    <Box sx={classes.privateWrap}>
      <PrivateStep activeStep={2} />

      <Typography variant="h5">Influencer List</Typography>
      <Typography variant="subtitle1">รายชื่ออินฟลูเอนเซอร์</Typography>

      <Box mt="24px">
        <InfluencerListStatus
          privateSummary={estimateSummary}
          totalBudget={privateInfluencerList?.total_budget || 0}
          jobLimit={campaignInfo?.job_limit || 0}
        />
      </Box>

      <Box mt="24px">
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          m="32px 0 24px 15px"
        >
          <Typography variant="h5">รายชื่ออินฟลูเอนเซอร์</Typography>
        </Box>

        <Grid container spacing={5}>
          {privateInfluencerList?.item.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                lg={2.4}
                xl={2}
                key={item.influencer_id}
              >
                <CardPrivate
                  item={item}
                  handleClick={handleClick}
                  goViewInfluencer={goViewInfluencer}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Divider sx={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => history.goBack()} sx={classes.backButton}>
          Back
        </Button>
        <Button
          disabled={
            estimateSummary.privateSelected >= (campaignInfo?.job_limit || 0)
              ? false
              : true
          }
          onClick={() => selectInfluencer()}
          sx={classes.nextButton}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
