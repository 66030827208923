// mui and icon
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// style and other
import { useNewCampaignDialogStyle as classes } from "./Campaign.style";
import { useHistory } from "react-router-dom";

export default function NewCampaignDialog(props: {
  open: boolean;
  onClose: (val: boolean) => void;
}) {
  const { open, onClose } = props;

  const history = useHistory();
  
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.title} id="alert-dialog-title">
        <Typography variant="title1">New Campaign</Typography>
        <IconButton onClick={() => onClose(false)}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogTitle}>
        <Box m="32px">
          <Typography variant="h5" textAlign="center">
            สร้างแคมเปญเพิ่มเติม
          </Typography>
          <Box sx={classes.createCampDetailBox}>
            <Typography>เพิ่ม 1 แคมเปญ</Typography>
            <Box display="flex" alignItems="flex-end">
              <Typography variant="h5" color="#8C499C">
                490.00
              </Typography>
              <Typography mb={1} ml={1}>
                บาท
              </Typography>
            </Box>
          </Box>
          <ul>
            <Typography component="li">
              ขณะนี้คุณได้สร้างแคมเปญครบตามจำนวนที่กำหนดตามแผนการใช้งานปัจจุบันแล้ว
              หากต้องการสร้างแคมเปญ เพิ่มจะมีค่าใช้จ่ายเพิ่ม
              <span style={{ fontWeight: 500 }}> 490.00 บาท </span>
              ต่อแคมเปญ
            </Typography>
          </ul>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            mt="50px"
          >
            <Typography variant="title1">
              ต้องการสร้างแคมเปญเพิ่มหรือไม่
            </Typography>
            <Box sx={classes.btn}>
              <Button fullWidth>ยกเลิก</Button>
              <Button
                variant="contained"
                fullWidth
                onClick={() => history.push(`/campaigns/create`)}
              >
                สร้างแคมเปญ
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
