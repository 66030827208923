import React from "react";
// mui and style
import {
  Box,
  // Tab, Tabs,
  Typography,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
// components
import Influencer from "./influencer";
// import Marketer from "./marketer";

// const AntTabs = styled(Tabs)({
//   "&.MuiTabs-root": {
//     height: "34px",
//     borderRadius: "30px",
//     width: "100%",
//   },

//   "& .MuiTabs-indicator": {
//     display: "none",
//   },
// });

// const TestTab = styled(Tab)({
//   backgroundColor: "#EBDFFF",
//   color: "#8C499C",
//   width: "15%",
//   borderRadius: "30px",
//   margin: "0px -20px 0px",
//   "&.Mui-selected": {
//     backgroundColor: "#8C499C",
//     color: "#fff",
//     zIndex: "1",
//   },
// });

export default function Privacy() {
  // const [value, setValue] = useState(0);

  // const changTabs = (_event: any, newValue: any) => {
  //   setValue(newValue);
  // };

  return (
    <Box>
      <Box
        id="back-to-top-anchor"
        sx={{ display: "flex", justifyContent: "center", my: "40px" }}
      >
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "50px", xl: "60px" },
            fontWeight: 400,
          }}
        >
          Terms And Conditions
        </Typography>
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <AntTabs value={value} onChange={changTabs} centered>
          <TestTab label="INFLUENCER" />
          <TestTab label="MARKETER" />
        </AntTabs>
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TabPanel value={0} index={0}>
          <Box
            sx={{ margin: "20px", display: "flex", justifyContent: "center" }}
          >
            <Influencer />
          </Box>
        </TabPanel>
        {/* <TabPanel value={1} index={1}>
          <Box
            sx={{ margin: "20px", display: "flex", justifyContent: "center" }}
          >
            <Marketer />
          </Box>
        </TabPanel> */}
      </Box>
    </Box>
  );
}

function TabPanel(props: any) {
  const { children, value, index } = props;
  return <Box>{value === index && <h1>{children}</h1>}</Box>;
}
