import { useState } from "react";
// mui
import { Box, Tab, TabProps, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// component
import Marketer from "./marketer/index";
import Influencer from "./Influencer";

const AntTabs = styled(Tabs)({
  "&.MuiTabs-root": {
    height: "34px",

    borderRadius: "30px",
    width: "100%",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const TestTab = styled(Tab)<TabProps>(({ theme }) => ({
  backgroundColor: "#EBDFFF",
  color: "#8C499C",
  borderRadius: "30px",
  margin: "0px -20px 0px",
  "&.Mui-selected": {
    backgroundColor: "#8C499C",
    color: "#fff",
    zIndex: "1",
  },
  [theme.breakpoints.up("xs")]: {
    width: "50%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "15%",
  },
}));

export default function Privacy() {
  const [value, setValue] = useState<any>(0);

  const changTabs = (_event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        id="back-to-top-anchor"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h2">Privacy Policy</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <AntTabs value={value} onChange={changTabs} centered>
          <TestTab label="INFLUENCER" />
          <TestTab label="MARKETER" />
        </AntTabs>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TabPanel value={value} index={0}>
          <Box
            sx={{ margin: "20px", display: "flex", justifyContent: "center" }}
          >
            <Influencer />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{ margin: "20px", display: "flex", justifyContent: "center" }}
          >
            <Marketer />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}

function TabPanel(props: any) {
  const { children, value, index } = props;
  return <Box>{value === index && <h1>{children}</h1>}</Box>;
}
