import { useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Menu,
  Stack,
  Paper,
  Button,
  InputBase,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// api, style and type
import { ExpenseAPI } from "@api/global";
import { employerExpenseTabStyle as classes } from "./Expense.style";
import { IExpense, IExpenseQueryParams } from "@views/expense/expense.type";
// others
import DatePicker from "react-datepicker";
import BasicSelectCustom from "../global/basic-select";
import { useHistory, useLocation } from "react-router-dom";
import TextFieldCustom from "@components/global/text-field";
import dayjs from "dayjs";

const statusList = [
  {
    key: "",
    value: "",
    label: "All",
  },
  {
    key: "pending",
    value: "pending",
    label: "Pending",
  },
  {
    key: "paid",
    value: "paid",
    label: "Paid",
  },
];

export default function EmployerExpenseTab({ setIsLoading, setExpenseInfo }: { setIsLoading:(val:boolean) => void, setExpenseInfo: (val: IExpense) => void  }) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const qSearch = params.get("q") || "";
  const qStatus = params.get("status") || "";
  const qPage = params.get("page") || "1";
  const qStartDate = params.get("s_date") || "";
  const qEndDate = params.get("e_date") || "";

  let date = new Date();
  const initStartDate: any = qStartDate ? new Date(qStartDate) : new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
  const initEndDate: any = qEndDate ? new Date(qEndDate) : new Date()

  const [search, setSearch] = useState(qSearch);
  const [anchorElFilterDate, setAnchorElFilterDate] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  const filterDateOpen = Boolean(anchorElFilterDate);

  const queryParams: IExpenseQueryParams = {
    q: qSearch,
    status: qStatus,
    page: qPage,
    s_date: qStartDate,
    e_date: qEndDate
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFilterDate(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElFilterDate(null);
  };

  const onFilterExpend = (key: keyof IExpenseQueryParams, value: string) => {
    let params = { ...queryParams };
    params[key] = value;

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof IExpenseQueryParams]}`)
      .join("&");

    history.replace(`/expense?${queryString}`);
  };

  const onFilterDate = (type:string) => {
    let params = { ...queryParams };
    if (type === "apply") {
      params.s_date = dayjs(startDate).format("YYYY-MM-DD");
      params.e_date = dayjs(endDate).format("YYYY-MM-DD");
    } else {
      params.s_date = "";
      params.e_date = ""
    }

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof IExpenseQueryParams]}`)
      .join("&");

    history.replace(`/expense?${queryString}`);
  }

  const handleImportAsCSV = (e: any) => {
    setIsLoading(true);
    const file = e.target.files[0];
    ExpenseAPI.newImportExpense({file: file}).then ((response) => {
      setExpenseInfo(response);
      setIsLoading(false);
    }).catch((err) => {
      console.log("error", err)
    })
    .finally(() => {
      setIsLoading(false);
    })
  };

  const onExportAsCSV = () => {
    ExpenseAPI.newExportExpense({ start_date: qStartDate, end_date: qEndDate, status: qStatus })
    .then((response) => {
      window.open(response.public_url, "_blank")
      setIsLoading(false);
    }).catch((err) => {
      console.log("can not get export CSV error : ", err);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <Box sx={classes.container} p={4}>
      <Grid container alignItems="center" mt={2} rowGap={[3, 0, 3, 0]}>
        <Grid item xs={12} sm={12} md={12} lg={7} sx={{ display: { sm: "flex" } }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={3}
            justifyContent={["center", "start"]}
            sx={classes.exportAndCertWrap}
            
          >
            <Paper sx={classes.search}>
              <InputBase
                sx={{ flex: 1, ml:3 }}
                placeholder="search by campaign name, platform"
                inputProps={{
                  "aria-label": "addmin-console-search-earning",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton
                onClick={() => onFilterExpend("q", search)}
                sx={{ p: "10px" }}
              >
                <Icon className="ico-hu-search" />
              </IconButton>
            </Paper>
            <Box minWidth={{ sm: "130px", lg:"150px", xl:"200px"}}>
              <BasicSelectCustom
                id="employer-expense"
                value={qStatus}
                onChange={(e) => onFilterExpend("status", e.target.value)}
                data={statusList}
                valueKey="value"
                labelKey="label"
                placeholder="select status"
                fullWidth
              />
            </Box>
            <Box sx={classes.selectBox}>
              <Button
                id="filter-date-button"
                aria-controls={filterDateOpen ? "filter-date-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterDateOpen ? "true" : undefined}
                onClick={handleOpenMenu}
                endIcon={<CalendarMonthIcon />}
              >
                <Typography variant="title2" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  {(qStartDate || qEndDate) ? `${dayjs(qStartDate).format("DD-MM-YY")} - ${dayjs(qEndDate).format("DD-MM-YY")}` : "From"}
                </Typography>
              </Button>
              <Menu
                id="filter-date-menu"
                anchorEl={anchorElFilterDate}
                open={filterDateOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "filter-date-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={classes.menuDate}
              >
                <Grid container spacing={5} mt={-2} sx={classes.menuDateGridCon}>
                  <Grid item xs={6}>
                    <Typography variant="title1">วันที่เริ่มต้น :</Typography>
                    <TextFieldCustom
                      fullWidth
                      value={dayjs(startDate).format("DD/MM/YYYY")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon sx={classes.calendarIcon} />
                          </InputAdornment>
                        ),
                      }}
                      sx={classes.txtFieldDate}
                    />
                    <DatePicker
                      calendarClassName="datepicker-calendar-container"
                      selected={startDate}
                      onChange={(date: any) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      value={startDate}
                      selectsStart
                      inline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="title1">วันที่สิ้นสุด :</Typography>
                    <TextFieldCustom
                      fullWidth
                      value={dayjs(endDate).format("DD/MM/YYYY")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon sx={classes.calendarIcon} />
                          </InputAdornment>
                        ),
                      }}
                      sx={classes.txtFieldDate}
                    />
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={endDate}
                      calendarClassName="datepicker-calendar-container"
                      onChange={(date: any) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      value={endDate}
                      inline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="end" mb="5px" columnGap={2}>
                      <Button variant="contained" onClick={() => onFilterDate("apply")} sx={{ maxHeight:"35px" }}>
                        Apply
                      </Button>
                      <Button onClick={() => onFilterDate("clear")} sx={{ maxHeight:"35px" }}>
                        Clear
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Menu>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} mt={[0, 4, 0]}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            justifyContent={["center", "start", "flex-end"]}
            sx={classes.exportAndCertWrap}
          >
            <Button
              sx={classes.exportBtn}
              onClick={onExportAsCSV}
            >
              <PublishIcon />
              <Typography variant="buttonM">Export</Typography>
            </Button>
            <Box
              component="label"
              sx={classes.importBtn}
            >
              <input
                id="import-csv-file"
                type="file"
                accept=".csv"
                onChange={handleImportAsCSV}
                style={{ display: "none" }}
              />
              <GetAppIcon/>
              <Typography variant="buttonM">Import</Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
