// mui and components
import { Grid } from "@mui/material";
import ConfirmGoodsReceived from "./ConfirmGoodsReceived";
import ShippingProductToInfluencer from "./ShippingProductToInfluencer";

export default function JobReceiptProduct() {
  return (
    <Grid container spacing={[0, 5]}>
      <Grid item xs={12} md={6}>
        <ShippingProductToInfluencer />
      </Grid>
      <Grid item xs={12} md={6} mt={[2, 0]}>
        <ConfirmGoodsReceived />
      </Grid>
    </Grid>
  );
}
