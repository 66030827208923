export const useTutorialStyle = {
  dialog: {
    "& .css-fkeb6g-MuiPaper-root-MuiDialog-paper": {
      borderRadius: "16px",
      p: "20px",
      width: "560px",
    },
  },
  image: {
    display: "flex",
    justifyContent: "center",
    "& > img": {
      width: {
        xs: "250px",
        sm: "320px",
      },
      height: {
        xs: "250px",
        sm: "320px",
      },
    },
  },
  mobileStepper: {
    bgcolor: "#fff",
    justifyContent: "center",
    my: "15px",
  },
  backNetxbtn: {
    justifyContent: "center",
    mt: "20px",
    "& > button": {
      width: "100%",
      maxWidth: "242px",
      minHeight: {
        xs: "40px",
        sm: "46px",
      },
      maxHeight: {
        xs: "40px",
        sm: "46px",
      },
    },
    "& > button:last-of-type": {
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};
