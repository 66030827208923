import { useState } from "react";
// mui, icon and style
import { Box, Button, Icon, Typography } from "@mui/material";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useMyJobsTabStyle as classes } from "./JobsTab.style";
// components
import JobOthers from "./job-status/job-others/JobOthers";
import JobWorking from "./job-status/job-working/JobWorking";
import JobApproved from "./job-status/job-approved/JobApproved";
import JobRejected from "./job-status/job-rejected/JobRejected";
import ShippingProductDialog from "./job-dialog/ShippingProductDialog";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// others
import { useHistory } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function MyJobsTab() {
  const [openShippingProduct, setOpenShippingProduct] = useState(false);

  const jobInfo = useAppSelector(selectInfluencerJobInfo);

  const history = useHistory();

  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id || "";

  const jobStatus = jobInfo?.status || "";
  const campaignInfo = jobInfo?.campaign_info;
  const isPlace = campaignInfo?.review_type === "place" && jobStatus !== "working";
  const prodReviewType = campaignInfo?.product_review_type;
  const isProductReturn = prodReviewType === "send_return" || prodReviewType === "send";
  const isConfirmDelivery = jobInfo?.delivery_confirmation?.date_of_receipt ? true : false;

  const onViewShippingProductForSm = () => {
    if (isPlace) {
      history.push(`/influencers/${influencerId}/jobs/${jobInfo?.job_id}/place-mobile`);
    } else {
      history.push(
        `/influencers/${influencerId}/jobs/${jobInfo?.job_id}/shipping-product-mobile`
      );
    }
  };

  return (
    <Box>
      {(isConfirmDelivery || isPlace) && (
        <Button
          variant="contained"
          endIcon={<KeyboardArrowRightIcon />}
          fullWidth
          sx={classes.shippingProdSmBtn}
          onClick={onViewShippingProductForSm}
        >
          {isPlace ? (
            <Box display="flex" alignItems="center">
              <Icon className="ico-hu-location" />
              <Typography variant="buttonM" ml={2}>
                Location
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <AllInboxIcon />
              <Typography variant="buttonM" ml={3}>
                Shipping Product
              </Typography>
            </Box>
          )}
        </Button>
      )}
      {jobStatus === "working" ? (
        <JobWorking
          isProductReturn={isProductReturn}
          isConfirmDelivery={isConfirmDelivery}
          jobsInfo={jobInfo}
        />
      ) : jobStatus === "approved" || jobStatus === "published" ? (
        <JobApproved jobsInfo={jobInfo} />
      ) : jobStatus === "rejected" ? (
        <JobRejected isProductReturn={isProductReturn} jobsInfo={jobInfo} />
      ) : (
        <JobOthers isConfirmDelivery={isConfirmDelivery} jobsInfo={jobInfo} />
      )}
      <ShippingProductDialog
        jobInfluencerDetail={jobInfo}
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
      />
    </Box>
  );
}
