import { useEffect, useState } from "react";
// mui and style
import { Box } from "@mui/material";
import { useWalletTabStyle as classes } from "./Wallettab.style";
// component
import TeamWallet from "./TeamWallet";
import WalletTransaction from "./WalletTransaction";
// api and type
import { PaymentAPI } from "@api/global";
import { ITransactionHistoryList } from "../Member.type";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { getWalletBalanceAsync } from "@slices/PackageInfo.slice";
// other
import PaginationCustom from "@global/pagination-custom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function WalletTab() {
  const [transaction, setTransaction] = useState<ITransactionHistoryList | null>(null);
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  const employerInfo = useAppSelector(selectEmployerInfo);
  
  useEffect(() => {
    let isMounted = true
    const params = {
      page: paginate,
      item_per_page: rowsPerPage,
      order_by: "code",
      sort: "DESC",
    };
    if (!employerInfo) return;
    const newParams =
      employerInfo?.package_plan === "team"
        ? { ...params, team_id: employerInfo.team?.id }
        : { ...params, employer_id: session_token?.employer_id };

    PaymentAPI.getTransactionList(newParams)
      .then((res) => {
        if (isMounted) {
          setTransaction(res);
        }
      })

    return () => {
      isMounted = false
    }
  }, [employerInfo, session_token?.employer_id, paginate, rowsPerPage]);

  useEffect(() => {
    dispatch(
      getWalletBalanceAsync({
        isPersonal: false,
        team_id: employerInfo?.team?.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  return (
    <>
      <Box sx={classes.container}>
        {[
          <TeamWallet transaction={transaction} />,
          <WalletTransaction transaction={transaction} />,
        ].map((component, index) => (
          <Box key={index} sx={classes.card}>
            {component}
          </Box>
        ))}
      </Box>

      {transaction?.data.item.length ? (
        <Box sx={classes.pagination}>
          <PaginationCustom
            page={paginate}
            count={
              paginate > 0
                ? Math.ceil((transaction?.data.total_item || 0) / rowsPerPage)
                : 0
            }
            onPaginationChange={handleChangePage}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </>
  );
}
