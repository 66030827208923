import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Icon,
  Typography,
  Tooltip as TooltipMui,
} from "@mui/material";
import { useCampaignPerfomance as classes } from "./CampaignReport.style";
// color and type
import { hGrey, hPink, hPurple } from "@theme/index";
import { ICampaignPerformanceProps } from "./campaignReport.type";
// chartJs
import { Chart } from "react-chartjs-2";
import { campaignPerformanceChart } from "./CampaignReport.chart";
// util
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function CampaignPerformance(props: ICampaignPerformanceProps) {
  const { columns, performance } = props;

  const [labels, setLabels] = useState<string[]>([]);
  const [reach, setReach] = useState<number[]>([]);
  const [impression, setImpression] = useState<number[]>([]);
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  useEffect(() => {
    const labelLists = performance?.chart.map((item) => item.date);
    const reachLists = performance?.chart.map((item) => item.reach);
    const impresionLists = performance?.chart.map((item) => item.impression);
    setLabels(labelLists ? labelLists : []);
    setReach(reachLists ? reachLists : []);
    setImpression(impresionLists ? impresionLists : []);
  }, [performance]);

  const campaignPerformance = [
    {
      title: "Reach",
      total: performance?.reach,
      icon: (
        <Icon
          color="secondary"
          fontSize="large"
          className="ico-hu-reach-outline"
        />
      ),
    },
    {
      title: "Impression",
      total: performance?.impression,
      icon: (
        <Icon
          color="primary"
          fontSize="large"
          className="ico-hu-impression-outline"
        />
      ),
    },
    // {
    //   title: "Contents",
    //   total: performance?.content,
    //   icon: <ContentPaste />,
    // },
    {
      title: "Follows",
      total: performance?.follower,
      icon: <Icon color="primary" fontSize="large" className="ico-hu-group" />,
    },
  ];

  const options = {
    ...campaignPerformanceChart.options,
    plugins: {
      ...campaignPerformanceChart.options.plugins,
      title: {
        display: false,
        text: "Reach | Impression",
        position: "left" as const,
      },
    },
  };

  const data = {
    labels,
    ...campaignPerformanceChart.data,
    datasets: [
      {
        fill: true,
        label: "Reach",
        data: reach,
        backgroundColor: hPink[300],
        borderColor: hPink[300],
        borderRadius: 8,
        borderWidth: 1,
        categoryPercentage: 0.4,
        barPercentage: 0.9,
        scales: {
          grace: "500",
        },
      },
      {
        fill: true,
        label: "Impression",
        data: impression,
        backgroundColor: hPurple[300],
        borderColor: hPurple[300],
        borderRadius: 8,
        borderWidth: 1,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
      },
    ],
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.campTitle}>
        <Typography>Campaign Performance</Typography>
      </Box>
      <Box sx={classes.campGrid}>
        <Grid container spacing={4}>
          {campaignPerformance.map((campaign, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={role === "influencer" ? 6 : 4}
              lg={columns === 2 ? 4 : 3}
            >
              <Box sx={classes.campCard}>
                <Grid item xs={12} sm={6} lg={8}>
                  <Box sx={{ ml: "20px" }}>
                    <TooltipMui placement="top" title={campaign.title}>
                      <Typography
                        fontSize={["14px", "16px"]}
                        color={hGrey[900]}
                      >
                        {campaign.title}
                      </Typography>
                    </TooltipMui>
                    <Typography
                      fontSize={["24px", "24px", "24px", "34px"]}
                      color={
                        campaign.title === "Reach"
                          ? hPink[300]
                          : campaign.title === "Impression"
                          ? hPurple[300]
                          : hPurple[400]
                      }
                      fontWeight="600"
                      mt={2}
                    >
                      {new Intl.NumberFormat("jp-JP").format(
                        campaign.total ? campaign.total : 0
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={6} lg={4} sx={classes.campCardBoxIcon}>
                  <Box
                    color={
                      campaign.title === "Reach"
                        ? hPink[300]
                        : campaign.title === "Impression"
                        ? hPurple[300]
                        : hPurple[400]
                    }
                    sx={classes.campCardIcon}
                  >
                    {campaign.icon}
                  </Box>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={classes.campChart}>
        <Chart
          type="bar"
          options={options}
          data={data}
          plugins={campaignPerformanceChart.chartAreaBorder}
        />
      </Box>
    </Box>
  );
}
