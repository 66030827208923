import * as yup from "yup";

export const jobRejectedFormSchema = yup.object().shape({
  job_id: yup.string(),
  influencer_id: yup.string(),
  employer_id: yup.string(),
  is_draft: yup.boolean(),
  description: yup.object().shape({
    description: yup.string(),
    is_approve: yup.boolean(),
    note: yup.string(),
  }),
  attachments: yup.array().transform((_, value) => {
    const attList = value.map((item:any) => {
      return {
        file_id: item.file_id,
        file_name: item.file_name,
        file_type: item.file_type,
        description:"",
        purpose: item.purpose,
        url: item.url,
        is_approve: item.is_approve
      } 
    })
    return attList
  }),
});