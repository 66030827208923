import { useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Step,
  Stack,
  Button,
  Dialog,
  Avatar,
  Stepper,
  StepLabel,
  IconButton,
  Typography,
  DialogTitle,
  StepContent,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// style and type
import { useTimelineDialogStyle as classes } from "../CampaignDetail.style";
import { ITimelineDetailDialogProps } from "../CampaignDetail.type";
// others
import prodReturn from "@assets/images/campaign/sent-product.png";
import influProfile from "@assets/images/people/influencer.png";
import location from "@assets/images/campaign/location.png";
import dayjs from "dayjs";

const initExpend = [
  "panel-0",
  "panel-1",
  "panel-2",
  "panel-3",
  "panel-4",
  "panel-5",
];

export default function TimelineDetailDialog(
  props: ITimelineDetailDialogProps
) {
  const {
    open,
    orgUrl,
    period,
    onClose,
    isPlace,
    timeline,
    readOnly,
    isProduct,
    isNoShipping,
    isCampaignPrivate,
    handleAcceptCondition,
  } = props;

  const [expanded, setExpanded] = useState<string[]>(initExpend);

  const chekDraftTimline = isPlace
    ? timeline?.end_shipping_date
    : isProduct
    ? timeline?.shipping_date
    : timeline?.announce_date;
  const timelineActive = Object.values(period || {}).filter((status) => status);

  const fontWeight = (txt: string, color?: string) => {
    return <span style={{ color: color, fontWeight: 500 }}>{txt}</span>;
  };

  const isDateActive = (status?: boolean) => {
    if (status) {
      return classes.timelineBoxActive;
    } else {
      return classes.timelineBoxNotActive;
    }
  };

  const isLineActive = (index: number) => {
    if (timelineActive.length === index) {
      return classes.lineIsActive;
    } else if (index < timelineActive.length) {
      return classes.lineIsSuccess;
    } else {
      return classes.lineNotActive;
    }
  };

  const isIconActive = (index: number) => {
    if (timelineActive.length === index) {
      return classes.stepLabelIconActive;
    } else if (index < timelineActive.length) {
      return classes.stepLabelIconIsSuccess;
    } else {
      return classes.stepLabelIconNotActive;
    }
  };

  const culDraftTimline = (day: number, date?: string) => {
    const totalDay = isProduct ? day + 5 : day;
    return date ? dayjs(date).add(totalDay, "day").format("DD/MM/YYYY") : "";
  };

  const handleChangeExpend = (panel: string, isExpanded: boolean) => {
    const paneled = panel.replace(/-disabled/gi, "");
    const expendList = [...expanded];
    const expendedIndex = expendList.findIndex(
      (item) => item.replace(/-disabled/gi, "") === paneled
    );
    expendList[expendedIndex] = isExpanded ? paneled : `${panel}-disabled`;
    setExpanded(expendList);
  };

  const draftPhaseStepLabel = (label: string, role: string) => {
    return (
      <StepLabel
        sx={{ p: 0 }}
        icon={
          <Avatar
            src={role === "influencer" ? influProfile : orgUrl}
            sx={{ width: "48px", height: "48px", zIndex: 1 }}
          />
        }
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="title1" color="#231F20">
            {label}
          </Typography>
        </Box>
      </StepLabel>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>Timeline</Typography>
        <Typography>ระยะเวลาของแคมเปญ</Typography>
        {readOnly && (
          <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Stepper orientation="vertical" sx={classes.stepper} connector={<></>}>
          {/* recruiting */}
          {isCampaignPrivate ? (
            <Step expanded>
              <StepLabel
                sx={{ p: 0, mt: "10px" }}
                icon={<Icon className="ico-hu-announce" sx={isIconActive(1)} />}
              >
                <Box
                  ml={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="title1" color="#8C499C">
                    ช่วงยื่นข้อเสนอให้อินฟลูเอนเซอร์
                  </Typography>
                  {expanded.includes("panel-0") ? (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-0", false)}
                    >
                      <KeyboardArrowUpIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-0", true)}
                    >
                      <KeyboardArrowDownIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              </StepLabel>
              <StepContent sx={isLineActive(1)}>
                <Box
                  display="flex"
                  ml={4}
                  sx={isDateActive(timelineActive.length === 1)}
                >
                  <Grid container>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        กำหนดช่วงตอบรับข้อเสนอ
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack direction="row" spacing={4} alignItems="center">
                        <Box sx={classes.dateBox}>
                          <Typography>
                            {dayjs(timeline?.start_recruiting_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                        <p>-</p>
                        <Box sx={classes.dateBox}>
                          <Typography>
                            {dayjs(timeline?.end_recruiting_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      mt="16px"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="title2">
                        วันสุดท้ายที่อินฟลูตอบรับข้อเสนอได้
                      </Typography>
                    </Grid>
                    <Grid item xs={8} mt="16px">
                      <Stack direction="row" spacing={4} alignItems="center">
                        <Box sx={classes.dateBox}>
                          <Typography>
                            {dayjs(timeline?.end_recruiting_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Box
                      display={
                        expanded.includes("panel-0") ? undefined : "none"
                      }
                    >
                      <Box mt={2}>
                        <Typography variant="title2">
                          การเลือกและยื่นข้อเสนอ
                        </Typography>
                        <Box component="ul" mt={2}>
                          {[
                            <span>
                              วันที่เริ่มยื่นข้อเสนอ
                              ระบบจะดำเนินการส่งข้อเสนอให้แก่
                              {fontWeight(" “อินฟลูเอนเซอร์ตัวจริง” ")}
                              หรือคนที่คุณอยากร่วมงานด้วย มากที่สุด และ
                              {fontWeight(" “รายชื่อตัวสำรอง” ")}
                              จะได้รับข้อเสนอหลังผ่านไปแล้ว 24 ชั่วโมง
                            </span>,
                            <span>
                              ในระหว่าง
                              {fontWeight(
                                " “ช่วงการตอบรับข้อเสนอจากอินฟลูเอนเซอร์” "
                              )}
                              ผู้จ้างงานสามารถเพิ่มรายชื่อตัวสำรอง ได้ตลอด
                              ระยะเวลา
                              โดยรายชื่อเพิ่มเติมจะจัดอยู่ในกลุ่มที่เป็นตัวสำรองหรือกลุ่มที่ยังไม่ได้ยื่นข้อเสนอ
                              เราจะดำเนินการ ยื่นข้อเสนอในรอบถัดไป
                            </span>,
                            <span>
                              รอบในการยื่นข้อเสนอให้แก่อินฟลูเอนเซอร์จะจัดกลุ่มและยื่นให้ในทุกๆ
                              24 ชั่วโมง
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="title2">การชำระเงิน</Typography>
                        <Box component="ul" mt={2}>
                          <Typography component="li" variant="body2">
                            ระบบทำการคำนวณราคาของแคมเปญจากผลรวม Rate Card ของ
                            {fontWeight(" “รายชื่ออินฟลูเอนเซอร์ตัวจริง” ")}
                            เป็นหลัก
                            โดยผู้จ้างงานต้องชำระค่ามัดจำตามอัตราราคาแคมเปญหลังจากการสร้างแคมเปญเสร็จสิ้น
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          ) : (
            <Step expanded>
              <StepLabel
                sx={{ p: 0, mt: "10px" }}
                icon={<Icon className="ico-hu-group" sx={isIconActive(1)} />}
              >
                <Typography variant="title1" ml={2} color="#8C499C">
                  ประกาศรับสมัคร
                </Typography>
              </StepLabel>
              <StepContent sx={isLineActive(1)}>
                <Box
                  display="flex"
                  ml={4}
                  sx={isDateActive(timelineActive.length === 1)}
                >
                  <Grid container>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        ช่วงประกาศรับสมัคร
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack direction="row" spacing={4} alignItems="center">
                        <Box sx={classes.dateBox}>
                          <Typography>
                            {dayjs(timeline?.start_recruiting_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                        <p>-</p>
                        <Box sx={classes.dateBox}>
                          <Typography>
                            {dayjs(timeline?.end_recruiting_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          )}

          {/* annouce */}
          {isCampaignPrivate ? (
            <Step expanded>
              <StepLabel
                sx={{ p: 0 }}
                icon={<Icon className="ico-hu-group" sx={isIconActive(2)} />}
              >
                <Box
                  ml={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="title1" color="#8C499C">
                    ตรวจสอบรายชื่ออินฟลูเอนเซอร์ที่ตอบรับคำเชิญ
                  </Typography>
                  {expanded.includes("panel-1") ? (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-1", false)}
                    >
                      <KeyboardArrowUpIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-1", true)}
                    >
                      <KeyboardArrowDownIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              </StepLabel>
              <StepContent sx={isLineActive(2)}>
                <Box ml={4} sx={isDateActive(timelineActive.length === 2)}>
                  <Grid container>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        ตรวจสอบและชำระเงินภายในวันที่
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={classes.dateBox}>
                        <Typography>
                          {dayjs(timeline?.announce_date).format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    display={expanded.includes("panel-1") ? undefined : "none"}
                  >
                    <Box component="ul" ml="-40px">
                      <Box>
                        <Typography variant="title2">
                          ตอบรับครบจำนวนก่อนกำหนดเวลา
                        </Typography>
                        <Typography
                          component="li"
                          variant="body2"
                          ml="30px"
                          mt={1.5}
                        >
                          เมื่ออินฟลูเอนเซอร์ตอบรับ
                          {fontWeight(" “ครบตามจำนวนที่กำหนด” ", "#4CAF50")}
                          ก่อนวันสุดท้ายของการตอบรับข้อเสนอ ระบบจะปิด
                          การรับสมัครโดยอัตโนมัติ
                          ผู้จ้างงานสามารถตรวจสอบรายชื่อและชำระเงินได้ทันที
                        </Typography>
                      </Box>
                      <Box my="24px">
                        <Typography variant="title2">
                          สิ้นสุดการตอบรับข้อเสนอ
                        </Typography>
                        <Typography
                          component="li"
                          variant="body2"
                          ml="30px"
                          mt={1.5}
                        >
                          อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                          {fontWeight("“มากกว่าหรือเท่ากับ”", "#4CAF50")}
                          “ครบตามจำนวนที่กำหนด”
                          ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                        </Typography>
                        <Box ml="25px">
                          {[
                            <span>
                              {fontWeight("เริ่มแคมเปญ ")}
                              ตรวจสอบรายชื่อและชำระเงินตามจริง
                              โดยระบบจะคำนวณราคาจาก Rate Card ของ
                              อินฟลูเอนเซอร์ที่ตอบตกลงรับงาน
                            </span>,
                            <span>
                              {fontWeight("ยกเลิกแคมเปญ")} ระบบดำเนินการ
                              “ตัดเงินมัดจำ” และคืนเงินสำรองจ่ายกลับไปยัง Wallet
                              ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                              ml="30px"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                        <Typography
                          component="li"
                          variant="body2"
                          ml="30px"
                          mt={1.5}
                        >
                          อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                          {fontWeight(" “น้อยกว่าจำนวนที่กำหนด” ", "#E30D18")}
                          เช่น ผู้จ้างงานต้องการผู้เข้าร่วมแคมเปญจำนวน 10 คน
                          แต่มีผู้ตอบรับเข้าร่วมแคมเปญเพียง 5 คน
                          ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                        </Typography>
                        <Box ml="25px">
                          {[
                            <span>
                              {fontWeight("เริ่มแคมเปญ ")}
                              ตรวจสอบรายชื่อและชำระเงินตามจริง
                              โดยระบบจะคำนวณราคาจาก Rate Card ของ
                              อินฟลูเอนเซอร์ที่ตอบตกลงรับงาน
                            </span>,
                            <span>
                              {fontWeight("ยกเลิกแคมเปญ ")}
                              ยกเลิกแคมเปญ ระบบดำเนินการ “คืนเงินมัดจำ”
                              และเงินสำรองจ่ายกลับไปยัง Wallet ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                              ml="30px"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="title2">
                          ไม่ดำเนินการตรวจสอบและชำระเงินในเวลาที่กำหนด
                        </Typography>
                        <Typography variant="body2" mt={1.5}>
                          ระบบจะทำการยกเลิกแคมเปญโดยอัตโนมัติ โดยแบ่งได้เป็น 2
                          กรณีดังนี้
                        </Typography>
                        <Box ml="25px">
                          {[
                            <span>
                              {fontWeight("กรณีที่ 1 ")}
                              อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                              {fontWeight(" “ครบจำนวน” ", "#4CAF50")}ที่ระบบ
                              “ครบจำนวน” ที่กำหนด ระบบ “ตัดเงินมัดจำ”
                              และคืนเงินสำรองจ่าย กลับไปยัง Wallet ของผู้จ้างงาน
                              กรณีที่ 2 อินฟลูเอนเซอร์ตอบรับข้อเสนอ “น้อยกว่า”
                              ที่กำหนด ระบบ “คืนเงินมัดจำและเงินสำรองจ่าย”
                              กลับไปยัง Wallet ของผู้จ้างงาน
                            </span>,
                            <span>
                              {fontWeight("กรณีที่ 2 ")}
                              อินฟลูเอนเซอร์ตอบรับข้อเสนอ
                              {fontWeight(" “น้อยกว่า”", "#E30D18")} ที่กำหนด
                              ระบบ “ระบบ “คืนเงินมัดจำและเงินสำรองจ่าย”
                              กลับไปยัง Wallet ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Typography variant="body2">
                      หมายเหตุ การคืนเงินเข้า Wallet ระบบจะดำเนินการภายใน 24
                      ชั่วโมง
                    </Typography>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          ) : (
            <Step expanded>
              <StepLabel
                sx={{ p: 0 }}
                icon={<Icon className="ico-hu-announce" sx={isIconActive(2)} />}
              >
                <Box
                  ml={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="title1" color="#8C499C">
                    ประกาศผลผู้เข้าร่วมแคมเปญ
                  </Typography>
                  {expanded.includes("panel-1") ? (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-1", false)}
                    >
                      <KeyboardArrowUpIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-1", true)}
                    >
                      <KeyboardArrowDownIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              </StepLabel>
              <StepContent sx={isLineActive(2)}>
                <Box ml={4} sx={isDateActive(timelineActive.length === 2)}>
                  <Grid container>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        ประกาศผลผู้ผ่านคัดเลือกภายในวันที่
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={classes.dateBox}>
                        <Typography>
                          {dayjs(timeline?.announce_date).format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    display={expanded.includes("panel-1") ? undefined : "none"}
                  >
                    <Box
                      component="img"
                      width="100%"
                      src={require("@assets/images/campaign/announce-campaign.png")}
                      alt="announce campaign"
                    />
                    <Box component="ul" ml="-20px">
                      <Box>
                        <Typography variant="title2">
                          ต้องการปิดรับสมัครก่อนกำหนดเวลา
                        </Typography>
                        <Typography
                          component="li"
                          variant="body2"
                          ml="30px"
                          mt={1.5}
                        >
                          ผู้จ้างงานสามารถเลือกอินฟลูเอนเซอร์ก่อนวันปิดรับสมัครได้
                          เพียงทำการ {fontWeight("“เลือก”")}{" "}
                          อินฟลูเอนเซอร์ที่ต้องการ จากนั้นทำการ{" "}
                          {fontWeight("“ยืนยันรายชื่อและชำระค่าบริการ”")}{" "}
                          แล้วระบบจะทำการ{" "}
                          {fontWeight("“ประกาศผลผู้ผ่านการคัดเลือกทันที”")}{" "}
                        </Typography>
                      </Box>
                      <Box my="24px">
                        <Typography variant="title2">
                          ครบกำหนดและปิดรับสมัคร
                        </Typography>
                        <Typography
                          component="li"
                          variant="body2"
                          ml="30px"
                          mt={1.5}
                        >
                          ผู้สมัคร
                          {fontWeight(" “มากกว่าหรือเท่ากับ” ", "#4CAF50")}
                          จำนวนที่กำหนด ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                        </Typography>
                        <Box ml="25px">
                          {[
                            <span>
                              {fontWeight("เริ่มแคมเปญ ")}
                              โดยคัดเลือกผู้สมัครและประกาศผลภายในเวลาที่กำหนด
                            </span>,
                            <span>
                              {fontWeight("ยกเลิกแคมเปญ ")}ระบบดำเนินการ
                              “ตัดเงินมัดจำ” และคืนเงินสำรองจ่ายกลับไปยัง Wallet
                              ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                              ml="30px"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                        <Typography
                          component="li"
                          variant="body2"
                          ml="30px"
                          mt={1.5}
                        >
                          ผู้สมัคร {fontWeight("“น้อยกว่า” ", "#E30D18")}
                          จำนวนที่กำหนด เช่น
                          ผู้จ้างงานต้องการผู้เข้าร่วมแคมเปญจำนวน 20 คน
                          แต่มีผู้สมัครเข้าร่วมแคมเปญ เพียง 15 คน
                          ผู้จ้างงานสามารถดำเนินการได้ดังนี้
                        </Typography>
                        <Box ml="25px">
                          {[
                            <span>
                              {fontWeight("เริ่มแคมเปญ")} “เลือก”
                              อินฟลูเอนเซอร์และ “ชำระเงินตามจริง”
                              โดยระบบจะคำนวณค่าใช้จ่ายตามจำนวนผู้ผ่านการคัดเลือก
                              และคืนเงินส่วนต่างกลับไปยัง Wallet ของผู้จ้างงาน
                            </span>,
                            <span>
                              {fontWeight("ยกเลิกแคมเปญ ")}
                              ระบบดำเนินการคืนเงินมัดจำและเงินสำรองจ่ายกลับไปยัง
                              Wallet ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                              ml="30px"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="title2">
                          “ไม่เลือก” ผู้เข้าร่วมแคมเปญในเวลาที่กำหนด
                        </Typography>
                        <Typography variant="body2" mt={1.5}>
                          ระบบจะทำการยกเลิกแคมเปญโดยอัตโนมัติ โดยแบ่งได้เป็น 2
                          กรณีดังนี้
                        </Typography>
                        <Box ml="25px">
                          {[
                            <span>
                              {fontWeight("กรณีที่ 1 ")}
                              อินฟลูเอนเซอร์สมัครเข้าร่วมแคมเปญ
                              {fontWeight(" “มากกว่า”", "#4CAF50")} ที่กำหนด
                              ระบบ “ตัดเงินมัดจำ” และคืนเงินส่วนต่างกลับไปยัง
                              Wallet ของผู้จ้างงาน
                            </span>,
                            <span>
                              {fontWeight("กรณีที่ 2 ")}
                              อินฟลูเอนเซอร์สมัครเข้าร่วมแคมเปญ
                              {fontWeight(" “น้อยกว่า”", "#E30D18")} ที่กำหนด
                              ระบบ “คืนเงินมัดจำและเงินสำรองจ่าย” กลับไปยัง
                              Wallet ของผู้จ้างงาน
                            </span>,
                          ].map((li, index) => (
                            <Typography
                              key={index}
                              component="li"
                              variant="body2"
                            >
                              {li}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Typography variant="body2">
                      หมายเหตุ การคืนเงินเข้า Wallet ระบบจะดำเนินการภายใน 24
                      ชั่วโมง
                    </Typography>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          )}

          {/* product or place */}
          {(isProduct || isPlace) && (
            <Step expanded>
              <StepLabel
                sx={{ p: 0 }}
                icon={
                  <Icon
                    className={
                      isPlace ? "ico-hu-location" : "ico-hu-shipping-outline"
                    }
                    sx={isIconActive(3)}
                  />
                }
              >
                <Box
                  ml={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography color="primary" variant="title1">
                    {isPlace
                      ? "กำหนดวันและเวลาในการเข้าไปถ่ายทำหรือรีวิวสถานที่"
                      : "การจัดส่งสินค้าให้แก่อินฟลูเอนเซอร์"}
                  </Typography>
                  {expanded.includes("panel-2") ? (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-2", false)}
                    >
                      <KeyboardArrowUpIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleChangeExpend("panel-2", true)}
                    >
                      <KeyboardArrowDownIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              </StepLabel>
              <StepContent sx={isLineActive(3)}>
                <Box ml={4} sx={isDateActive(timelineActive.length === 3)}>
                  <Grid container>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        {isPlace
                          ? "ช่วงที่อินฟลูเอนเซอร์เข้าไปถ่ายรีวิว"
                          : "จะจัดส่งสินค้าภายในวันที่"}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {isPlace ? (
                        <Stack direction="row" spacing={4} alignItems="center">
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {dayjs(timeline?.shipping_date).format(
                                "DD/MM/YYYY"
                              )}
                            </Typography>
                          </Box>
                          <p>-</p>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {dayjs(timeline?.end_shipping_date).format(
                                "DD/MM/YYYY"
                              )}
                            </Typography>
                          </Box>
                        </Stack>
                      ) : (
                        <Box sx={classes.dateBox}>
                          <Typography>
                            {dayjs(timeline?.shipping_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Box
                    display={expanded.includes("panel-2") ? undefined : "none"}
                  >
                    <Box
                      component="img"
                      width="100%"
                      src={isPlace ? location : prodReturn}
                      alt="post campaign"
                    />
                    <ul>
                      <Typography component="li" variant="body2" ml={-5}>
                        {isPlace
                          ? "ผู้จ้างกำหนดช่วงวันในการถ่ายรีวิว โดยอินฟลูเอนเซอร์ต้องทำการนัดหมายกับผู้จ้างงานก่อนเข้าไปถ่ายทำทุกครั้ง"
                          : "อินฟลูเอนเซอร์ควรได้รับสินค้าภายใน 5 วันหลังทำการจัดส่งโดยระยะเวลาในการจัดส่งขึ้นอยู่กับบริษัทและประเภทการจัดส่ง"}
                      </Typography>
                    </ul>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          )}

          {/* draft_phase */}
          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={<Icon className="ico-hu-content" sx={isIconActive(4)} />}
            >
              <Box
                ml={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="title1" color="#8C499C">
                  การตรวจ Draft จากอินฟลูเอนเซอร์
                </Typography>
                {expanded.includes("panel-3") ? (
                  <IconButton
                    onClick={() => handleChangeExpend("panel-3", false)}
                  >
                    <KeyboardArrowUpIcon color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleChangeExpend("panel-3", true)}
                  >
                    <KeyboardArrowDownIcon color="primary" />
                  </IconButton>
                )}
              </Box>
            </StepLabel>
            <StepContent sx={isLineActive(4)}>
              <Box ml={4} sx={isDateActive(timelineActive.length + 1 === 4)}>
                {expanded.includes("panel-3") ? (
                  <>
                    {draftPhaseStepLabel(
                      "อินฟลูเอนเซอร์ส่ง Draft ครั้งแรก",
                      "influencer"
                    )}
                    <StepContent sx={classes.stepConnect}>
                      <Grid container>
                        <Grid item xs={5} mt={2}>
                          <Typography variant="title2" ml={2.5}>
                            ส่ง Draft ครั้งแรกภายในวันที่
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {culDraftTimline(5, chekDraftTimline)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box component="ul" ml={-3}>
                        {(isNoShipping
                          ? [
                              "อินฟลูเอนเซอร์ต้องส่ง Draft ครั้งแรก ภายใน 5 วัน นับจากวันที่ประกาศผลผู้ผ่านคัดเลือก",
                              "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                            ]
                          : isProduct
                          ? [
                              "อินฟลูเอนเซอร์ต้องส่ง Draft ครั้งแรก ภายใน 10 วัน นับจากวันที่กำหนดส่งสินค้า",
                              "หลังจากได้รับของอินฟลูเอนเซอร์ต้องตรวจสอบสินค้าและแนบหลักฐานการรับก่อนเริ่มงานทุกครั้ง",
                              "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                            ]
                          : [
                              "อินฟลูเอนเซอร์ต้องส่ง Draft ครั้งแรก ภายใน 5 วัน นับจากวันสุดท้ายที่กำหนดให้ถ่ายรีวิวสถานที่",
                              "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                            ]
                        ).map((li, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </StepContent>

                    {draftPhaseStepLabel(
                      "ผู้จ้างงานตรวจ Draft ครั้งที่ 1",
                      "organization"
                    )}
                    <StepContent sx={classes.stepConnect}>
                      <Grid container>
                        <Grid item xs={5} mt={2}>
                          <Typography variant="title2" ml={2.5}>
                            ให้ Feedback ครั้งที่ 1/2 ภายในวันที่
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {culDraftTimline(8, chekDraftTimline)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box component="ul" ml={-3}>
                        {[
                          "ผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่กำหนดการส่ง Draft ครั้งแรก",
                          "หากผู้จ้างงานไม่ตรวจงานภายในระยะเวลาที่กำหนด ระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ",
                        ].map((li, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </StepContent>

                    {draftPhaseStepLabel(
                      "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 1",
                      "influencer"
                    )}
                    <StepContent sx={classes.stepConnect}>
                      <Grid container>
                        <Grid item xs={5} mt={2}>
                          <Typography variant="title2" ml={2.5}>
                            ส่ง Draft แก้ครั้งที่ 1/2 ภายในวันที่
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {culDraftTimline(11, chekDraftTimline)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box component="ul" ml={-3}>
                        {[
                          "อินฟลูเอนเซอร์ต้องส่ง Draft แก้ครั้งที่ 1/2 ภายใน 3 วัน หลังจากได้รับ Feedback ครั้งที่ 1/2 จากผู้จ้างงาน",
                          "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                        ].map((li, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </StepContent>

                    {draftPhaseStepLabel(
                      "ผู้จ้างงานตรวจ Draft ครั้งที่ 2",
                      "organization"
                    )}
                    <StepContent sx={classes.stepConnect}>
                      <Grid container>
                        <Grid item xs={5} mt={2}>
                          <Typography variant="title2" ml={2.5}>
                            ให้ Feedback ครั้งที่ 2/2 ภายในวันที่
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {culDraftTimline(14, chekDraftTimline)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box component="ul" ml={-3}>
                        {[
                          "ผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่กำหนดการส่ง Draft แก้ครั้งที่ 1/2",
                          "หากผู้จ้างงานไม่ตรวจงานภายในระยะเวลาที่กำหนด ระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ",
                        ].map((li, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </StepContent>

                    {draftPhaseStepLabel(
                      "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 2",
                      "influencer"
                    )}
                    <StepContent sx={classes.stepConnect}>
                      <Grid container>
                        <Grid item xs={5} mt={2}>
                          <Typography variant="title2" ml={2.5}>
                            ส่ง Draft แก้ครั้งที่ 2/2 ภายในวันที่
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {culDraftTimline(17, chekDraftTimline)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box component="ul" ml={-3}>
                        {[
                          "อินฟลูเอนเซอร์ต้องส่ง Draft แก้ครั้งที่ 2/2 ภายใน 3 วัน หลังจากได้รับ Feedback ครั้งที่ 2/2 จากผู้จ้างงาน",
                          "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                        ].map((li, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </StepContent>

                    {draftPhaseStepLabel(
                      "ผู้จ้างงานตรวจ Draft ครั้งสุดท้าย",
                      "organization"
                    )}
                    <StepContent sx={{ ml: "23px", border: "none" }}>
                      <Grid container>
                        <Grid item xs={5} mt={2}>
                          <Typography variant="title2" ml={2.5}>
                            อนุมัติ Draft ครั้งสุดท้ายภายในวันที่
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Box sx={classes.dateBox}>
                            <Typography>
                              {culDraftTimline(20, chekDraftTimline)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box component="ul" ml={-3}>
                        {(isNoShipping
                          ? [
                              <span>
                                ช่วงโพสต์และแนบลิงค์ส่งงาน คือ การกำหนดช่วงวัน
                                ที่อินฟลูเอนเซอร์ทั้งหมดต้องโพสต์บนแพลตฟอร์ม
                              </span>,
                              <span>
                                ผู้จ้างงาน สามารถระบุ
                                {fontWeight(
                                  " “วันและเวลาเฉพาะ(ภายในช่วงวันของโพสต์และแนบลิงค์ส่งงาน)” "
                                )}
                                ให้แก่อินฟลูเอนเซอร์รายบุคคลได้ หลังจากอนุมัติ
                                Draft ให้แก่อินฟลูเอนเซอร์นั้นๆ"
                              </span>,
                              <span>
                                อินฟลูเอนเซอร์ต้องแนบลิงค์ส่งงานของตัวเองภายใน 2
                                ชั่วโมงหลังเผยแพร่โพสต์
                              </span>,
                            ]
                          : [
                              "ในการตรวจครั้งสุดท้าย ผู้จ้างงานสามารถให้ความคิดเห็นโดยรวมแก่อินฟลูเอนเซอร์ได้เท่านั้น โดยผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 2/2",
                              "หาก Draft แก้ครั้งที่ 2/2 ของอินฟลูเอนเซอร์ “ไม่ผ่าน” ผู้จ้างงานสามารถ “ยกเลิกงานอินฟลูเอนเซอร์” ได้ โดยอินฟลูเอนเซอร์จะได้รับค่าจ้างตามอัตราค่ามัดจำแคมเปญ ของค่าจ้างทั้งหมดที่อินฟลูเอนเซอร์จะได้รับ",
                              "กรณีที่ผู้จ้างงาน ไม่ตรวจ Draft ภายในระยะเวลาที่กำหนดระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ.",
                            ]
                        ).map((li, index) => (
                          <Typography
                            key={index}
                            component="li"
                            variant="body2"
                            ml={index === 1 && !isNoShipping ? 6 : undefined}
                          >
                            {li}
                          </Typography>
                        ))}
                      </Box>
                    </StepContent>
                  </>
                ) : (
                  <Grid container>
                    <Grid item xs={4} display="flex" alignItems="center">
                      <Typography variant="title2">
                        อนุมัติ Draft ครั้งสุดท้ายภายในวันที่
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={classes.dateBox}>
                        <Typography>
                          {culDraftTimline(21, chekDraftTimline)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </StepContent>
          </Step>

          {/* publish */}
          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={<Icon className="ico-hu-publishing" sx={isIconActive(5)} />}
            >
              <Typography variant="title1" ml={2} color="#8C499C">
                ช่วงโพสต์และแนบลิงค์ส่งงาน
              </Typography>
            </StepLabel>
            <StepContent sx={isLineActive(5)}>
              <Box ml={4} sx={isDateActive(timelineActive.length + 1 === 5)}>
                <Grid container>
                  <Grid item xs={4} mt={2}>
                    <Typography variant="title2">
                      ช่วงโพสต์และแนบลิงค์ส่งงาน
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Stack direction="row" spacing={4} alignItems="center">
                      <Box sx={classes.dateBox}>
                        <Typography>
                          {dayjs(timeline?.start_publish_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </Box>
                      <p>-</p>
                      <Box sx={classes.dateBox}>
                        <Typography>
                          {dayjs(timeline?.end_publish_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container mt={4}>
                  <Grid item xs={4} mt={2}>
                    <Typography variant="title2">ระยะเวลาในการโพสต์</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={classes.dateBox}
                    >
                      <Typography>{timeline?.posting_period}</Typography>
                      <Typography>วัน</Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box component="ul">
                  {[
                    "ช่วงโพสต์และแนบลิงค์ส่งงาน คือ การกำหนดช่วงวัน ที่อินฟลูเอนเซอร์ทั้งหมดต้องโพสต์บนแพลตฟอร์ม",
                    "ผู้จ้างงาน สามารถระบุ “วันและเวลาเฉพาะ (ภายในช่วงวันของโพสต์และแนบลิงค์ส่งงาน)” ให้แก่อินฟลูเอนเซอร์รายบุคคลได้",
                    "หลังจากอนุมัติ Draft ให้แก่อินฟลูเอนเซอร์นั้นๆ อินฟลูเอนเซอร์ต้องแนบลิงค์ส่งงานของตัวเองภายใน 2 ชั่วโมงหลังเผยแพร่โพสต์",
                  ].map((li, index) => (
                    <Typography key={index} component="li" variant="body2">
                      {li}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </StepContent>
          </Step>

          {/* complete */}
          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={
                <Icon
                  className="ico-hu-campaign-done-outline"
                  sx={isIconActive(6)}
                />
              }
            >
              <Typography variant="title1" color="#8C499C" ml={3}>
                วันสิ้นสุดแคมเปญ
              </Typography>
            </StepLabel>
            <StepContent>
              <Box ml={4} sx={isDateActive(timelineActive.length + 1 === 6)}>
                <Grid container>
                  <Grid item xs={4} display="flex" alignItems="center">
                    <Typography variant="title2">
                      วันที่สิ้นสุดแคมเปญ
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={classes.dateBox}>
                      <Typography>
                        {dayjs(timeline?.completed_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        {!readOnly && (
          <Box sx={classes.acceptCondition} mt={5}>
            <Button
              onClick={() =>
                handleAcceptCondition
                  ? handleAcceptCondition("timeline", false)
                  : undefined
              }
              variant="contained"
            >
              อ่านและทำความเข้าใจเรียบร้อยแล้ว
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                handleAcceptCondition
                  ? handleAcceptCondition("timeline", true)
                  : undefined
              }
            >
              ยกเลิก
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
