import { useState } from "react";
// mui, icon and style
import {
  Box,
  Menu,
  Paper,
  Table,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useInfluencerDistributionListStyle as classes } from "../../OverviewTab.style";
// type and others
import { IInfluencerDistributionProps } from "../../OverviewTab.type";
import { createData } from "../type-of-influencer/InfluencerTiers";

const tableHeadList = [
  {
    label: "#",
    table: "",
    sort: "",
    color: "#000",
  },
  {
    label: "จังหวัด",
    table: "area.province",
    sort: "DESC",
    color: "#000",
  },
  {
    label: "ภาค",
    table: "",
    sort: "",
    color: "#000",
  },
  {
    label: "Total",
    table: "area.total",
    sort: "DESC",
    color: "#000",
  },
  {
    label: "Facebook",
    table: "area.facebook",
    sort: "DESC",
    color: "#1A77F1",
  },
  {
    label: "Instagram",
    table: "area.instagram",
    sort: "DESC",
    color: "#893DBD",
  },
  {
    label: "Twitter",
    table: "area.twitter",
    sort: "DESC",
    color: "#1D9BF0",
  },
  {
    label: "Tiktok",
    table: "area.tiktok",
    sort: "DESC",
    color: "#000",
  },
  {
    label: "Youtube",
    table: "area.youtube",
    sort: "DESC",
    color: "#FF0000",
  },
];

const regionList = [
  {
    key: 0,
    label: "All",
  },
  {
    key: 1,
    label: "ภาคเหนือ",
  },
  {
    key: 3,
    label: "ภาคตะวันออกเฉียงเหนือ",
  },
  {
    key: 5,
    label: "ภาคตะวันตก",
  },
  {
    key: 2,
    label: "ภาคกลาง",
  },
  {
    key: 4,
    label: "ภาคตะวันออก",
  },
  {
    key: 6,
    label: "ภาคใต้",
  },
];

export default function InfluencerDistributionList(
  props: IInfluencerDistributionProps
) {
  const { areaDist, setParam, param } = props;

  const [regionFilterSelected, setRegionFilterSelected] = useState("All");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const sort = param?.sort;
  const regionOpen = Boolean(anchorEl);
  const handleOpenRegion = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRegion = () => {
    setAnchorEl(null);
  };

  const handleSelectRegion = (region: string, group: number) => {
    setRegionFilterSelected(region);
    handleCloseRegion();
    setParam((prev) => {
      return {
        ...prev,
        sort: undefined,
        group_region: group,
      };
    });
  };

  const tableList = areaDist?.dist_table;
  const total = tableList?.total || [];
  const fb = tableList?.facebook || [];
  const ig = tableList?.instagram || [];
  const tw = tableList?.twitter || [];
  const ttk = tableList?.tiktok || [];
  const yt = tableList?.youtube || [];

  const infDistList = [...total.map((t) => t)].map((label, i) =>
    createData(label, total?.[i], fb?.[i], ig?.[i], tw?.[i], ttk?.[i], yt?.[i])
  );

  const numberFormat = (val?: { number: number; percent: number }) => {
    if (val) {
      const number = val.number === 0 ? 0 : Intl.NumberFormat("en").format(val.number);
      return `${number || 0} (${val.percent.toFixed(2)}%)`;
    }
  };

  const onSortTable = (table: string, sort: string) => {
    setParam((prev) => {
      return {
        ...prev,
        sort: {
          table: table,
          sort: sort,
        },
      };
    });
  };

  return (
    <Box>
      <TableContainer sx={classes.tableCon} component={Paper}>
        <Table sx={{ minWidth: "300px" }}>
          <TableHead>
            <TableRow>
              {tableHeadList.map((row, index) => (
                <TableCell key={index} sx={{ bgcolor: "#F5F6F8" }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    id="select-region"
                    aria-controls={regionOpen ? "select-region" : undefined}
                    aria-haspopup="true"
                    aria-expanded={regionOpen ? "true" : undefined}
                    onClick={index === 2 ? handleOpenRegion : undefined}
                    justifyContent={
                      index === 0
                        ? "center"
                        : index === 1 || index === 2
                        ? "space-between"
                        : "end"
                    }
                    mx="-8px"
                    sx={{ cursor: index === 2 ? "pointer" : "initial" }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="title2" mr={1.5} color={row.color}>
                        {row.label}
                      </Typography>
                      {index === 2 && (
                        <Typography variant="body2">
                          {`: ${regionFilterSelected}`}
                        </Typography>
                      )}
                    </Box>
                    {row.table === sort?.table && sort?.sort === "ASC" ? (
                      <ArrowUpwardIcon
                        onClick={() => onSortTable(row.table, "DESC")}
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    ) : (
                      index !== 0 && (
                        <ArrowDownwardIcon
                          onClick={() =>
                            index !== 2
                              ? onSortTable(row.table, "ASC")
                              : undefined
                          }
                          fontSize="small"
                          sx={{
                            cursor: "pointer",
                            color:
                              row.table === sort?.table
                                ? "#000"
                                : "rgba(0,0,0,0.5)",
                          }}
                        />
                      )
                    )}
                  </Box>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={regionOpen}
                    onClose={handleCloseRegion}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={classes.menu}
                  >
                    {regionList.map((item, index) => (
                      <MenuItem
                        key={index}
                        sx={
                          item.label === regionFilterSelected
                            ? classes.menuItemSelected
                            : classes.menuItem
                        }
                        onClick={() => handleSelectRegion(item.label, item.key)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={classes.tBody}>
            {infDistList.map((row, index) => (
              <TableRow key={index} sx={classes.tBodyRow}>
                <TableCell align="center">
                  <Typography variant="title1">
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    variant="title1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {row.label?.province}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="title1">{row.label?.region}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="title2">
                    {numberFormat(row.total)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" color="#1A77F1">
                    {numberFormat(row.facebook)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" color="#893DBD">
                    {numberFormat(row.instagram)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" color="#1D9BF0">
                    {numberFormat(row.twitter)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    {numberFormat(row.tiktok)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" color="#FF0000">
                    {numberFormat(row.youtube)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
