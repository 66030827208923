import { useState } from "react";
// mui, icon and style
import { Box, Stack, Avatar, Divider, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useShippingProductStyle as classes } from "./ShippingProduct.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// others
import { CompanyIconSelected } from "@components/global/icon-selected/CompanyIconSelected";
import ViewMultiImageDialog from "@components/global/view-image-dialog/ViewMultiImageDialog";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export default function ShippingProduct() {
  const [viewImageProduct, setViewImageProduct] = useState(false);

  const history = useHistory();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const shipping = jobInfo?.shipping_address;
  const delivery = jobInfo?.delivery_confirmation;

  return (
    <div className="container-main">
      <Box display="flex" alignItems="center" mx={3}>
        <ArrowBackIosIcon
          onClick={() => history.goBack()}
          sx={{ fontSize: "20px" }}
        />
        <Typography variant="h6" width="100%" textAlign="center">
          Shipping Product
        </Typography>
      </Box>
      <Box sx={classes.shippingProductTitle}>
        <Typography variant="h6">Shipping to Influencer</Typography>
        <Typography variant="subTitle2">
          ผู้จ้างงานส่งสินค้าสำหรับการรีวิวให้อินฟลูเอนเซอร์
        </Typography>
        <Divider sx={{ my: "16px" }} />
        <Stack spacing={5}>
          <Box>
            <Typography variant="title1">Shipping Type</Typography>
            {shipping?.shipping_type ? (
              <Box display="flex" alignItems="center">
                <CompanyIconSelected company={shipping?.shipping_type} />
                <Typography mt={1} ml={3}>
                  {shipping?.shipping_type}
                </Typography>
              </Box>
            ) : (
              <Typography mt={1}>-</Typography>
            )}
          </Box>
          <Box>
            <Typography variant="title1">Tracking No.</Typography>
            <Typography color="primary" mt={1}>
              {shipping?.tracking_no || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="title1">Note</Typography>
            <Typography mt={1}>{shipping?.note || "-"}</Typography>
          </Box>
        </Stack>
      </Box>
      <Box sx={classes.shippingProductTitle}>
        <Typography variant="h6">Delivery Confirmation</Typography>
        <Typography variant="subTitle2">
          ยืนยันการรับสินค้าจากผู้จ้างงาน
        </Typography>
        <Divider sx={{ my: "16px" }} />
        <Stack spacing={5}>
          <Box>
            <Typography variant="title1">Date of Receipt</Typography>
            <Typography mt={1}>
              {delivery?.date_of_receipt
                ? dayjs(delivery.date_of_receipt).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="title1">Product Details</Typography>
            <Box
              sx={classes.prductImageBox}
              onClick={() => setViewImageProduct(true)}
            >
              {delivery?.attachment.slice(0, 3).map((item, index) => (
                <Avatar key={index} src={item.url} />
              ))}
              {Number(delivery?.attachment.length) > 3 && (
                <Box sx={classes.productImages}>
                  <Typography>
                    + {Number(delivery?.attachment.length) - 3}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="title1">Note</Typography>
            <Typography mt={1}>{delivery?.note || "-"}</Typography>
          </Box>
        </Stack>
      </Box>
      <ViewMultiImageDialog
        open={viewImageProduct}
        onClose={setViewImageProduct}
        images={delivery?.attachment}
      />
    </div>
  );
}
