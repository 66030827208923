// mui and style
import { Avatar, Box, Grid, Icon, Typography } from "@mui/material";
import { useSocialPostStyle as classes } from "../InfluencerSocial.style";
// type
import { TPlatform } from "../InfluencerSocial.type";
import { ISocialAccountRes } from "@api/influencer/type";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";

export default function SocialPost(props: { platform: TPlatform }) {
  const { platform } = props;

  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const socialnfo = (influencerSocial?.[platform] || [])[0];
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialnfo?.social_account || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  return (
    <Box className="d-flex f-d-column" rowGap={["16px", "30px"]}>
      <Box sx={classes.popularPostCon}>
        <Typography textAlign="end" fontWeight={500}>
          Most Popular Posts
        </Typography>
        <Grid container sx={classes.popularPostSlideCon}>
          <Swiper slidesPerView={3} style={{ width:"100%" }}>
            {accountInfo?.most_popular_post.map((post, index) => (
              <SwiperSlide key={index} style={{ border:"1px solid #fff" }}>
                <Grid item xs={4} ml={index === 0 ? 0 : 2}>
                  <Box
                    onClick={() => window.open(post?.published_url)}
                    sx={{ width: "100%", position: "relative" }}
                    height={
                      platform === "tiktok"
                        ? "255px"
                        : { xs: "130px", mg: "160px", xl: "200px" }
                    }
                  >
                    {post.media.length === 0 ? (
                      <Box
                        className="d-flex j-c-center a-i-center"
                        height="100%"
                      >
                        <Typography
                          textAlign="center"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          width="100px"
                          overflow="hidden"
                        >
                          {post.description}
                        </Typography>
                      </Box>
                    ) : post.media[0]?.file_type === "mp4" ? (
                      <Box
                        component="video"
                        src={post.media[0]?.url}
                        sx={classes.recentPostVdo}
                      />
                    ) : (
                      <Avatar
                        src={post.media[0]?.url}
                        sx={classes.recentPostImg}
                      />
                    )}
                    <Box sx={classes.popularPostAvg}>
                      <Box display="flex" alignItems="center" columnGap="3px">
                        <Icon
                          className="ico-hu-view"
                          sx={{ fontSize: "16px", color: "#fff" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.like}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" columnGap="3px">
                        <Icon
                          className="ico-hu-like"
                          sx={{ fontSize: "16px", color: "#fff" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.like}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" columnGap="3px">
                        <Icon
                          className="ico-hu-comment"
                          sx={{ fontSize: "16px", color: "#fff" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.comment}
                        </Typography>
                      </Box>
                      <Box
                        display={["none", "none", "none", "none", "flex"]}
                        alignItems="center"
                      >
                        <Icon
                          className="ico-hu-share"
                          sx={{ fontSize: "16px" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.share}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Box>
      <Box sx={classes.recentPostCon}>
        <Typography textAlign="end" fontWeight={500}>
          Recent Post
        </Typography>
        <Grid container sx={classes.recentPostSlideCon}>
          <Swiper slidesPerView={3} style={{ width:"100%" }}>
            {accountInfo?.recent_post.map((post, index) => (
              <SwiperSlide key={index} style={{ border:"1px solid #fff" }}>
                <Grid item xs={4} ml={index === 0 ? 0 : 2}>
                  <Box
                    key={index}
                    onClick={() => window.open(post?.published_url)}
                    sx={{ width: "100%", position: "relative" }}
                    height={
                      platform === "tiktok"
                        ? "255px"
                        : { xs: "130px", mg: "160px", xl: "200px" }
                    }
                  >
                    {post.media.length === 0 ? (
                      <Box
                        className="d-flex j-c-center a-i-center"
                        height="100%"
                      >
                        <Typography
                          textAlign="center"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          width="100px"
                          overflow="hidden"
                        >
                          {post.description}
                        </Typography>
                      </Box>
                    ) : post.media[0]?.file_type === "mp4" ? (
                      <Box
                        component="video"
                        src={post.media[0]?.url}
                        sx={classes.recentPostVdo}
                      />
                    ) : (
                      <Avatar
                        src={post.media[0]?.url}
                        sx={classes.recentPostImg}
                      />
                    )}
                    <Box sx={classes.recentPostAvg}>
                      <Box display="flex" alignItems="center" columnGap="3px">
                        <Icon
                          className="ico-hu-view"
                          sx={{ fontSize: "16px", color: "#fff" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.like}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" columnGap="3px">
                        <Icon
                          className="ico-hu-like"
                          sx={{ fontSize: "16px", color: "#fff" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.like}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" columnGap="3px">
                        <Icon
                          className="ico-hu-comment"
                          sx={{ fontSize: "16px", color: "#fff" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.comment}
                        </Typography>
                      </Box>
                      <Box
                        display={["none", "none", "none", "none", "flex"]}
                        alignItems="center"
                      >
                        <Icon
                          className="ico-hu-share"
                          sx={{ fontSize: "16px" }}
                        />
                        <Typography
                          color="#fff"
                          fontWeight={500}
                          fontSize="14px"
                        >
                          {post.share}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Box>
    </Box>
  );
}
