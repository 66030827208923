// mui and style
import { Box, Dialog, Typography, Button } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "../CampaignPrivate.style";
// type
import { IPrivateItemRes } from "@api/job/type";
import { IConfirmPrivateDialogProps } from "../PrivateCampaign.type";
// util and asset
import { addDefaultImageSrc } from "@utils/helpers/addDefaultImageSrc.helpers";
import InfluIsLess from "@assets/images/infographic/admin/influencer-list/inf-apply-less-than-specified.png";
// route and redux
import { useAppDispatch } from "@store/hook";
import { useLocation, useParams } from "react-router-dom";
import { insertJobPrivateCampaign } from "@slices/CampaignPrivate.slice";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function ConfirmPrivateJobDialog(props: IConfirmPrivateDialogProps) {
  const { open, onClose, listing, jobLimit, reserved, handleOffering } = props;

  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const employerId = params.get("emp_id") || session_token?.employer_id || ""
  const { campaignId } = useParams<{ campaignId: string }>();
  
  const onConfirmJobPrivate = (
    data: IPrivateItemRes[],
    reserved: IPrivateItemRes[]
  ) => {
    const newData = data.map((item) => {
      return {
        social_account_id: item.id,
        status: "stealth",
      };
    });
    const newReserved = reserved.map((item) => {
      return {
        social_account_id: item.id,
        status: "reserved",
      };
    });
    const mergedData = newData.concat(newReserved);
    const req = {
      employerId: employerId,
      campaignId: campaignId,
      body: mergedData,
    };
    try {
      dispatch(insertJobPrivateCampaign(req));
      handleOffering();
    } catch (err: any) {
      return err;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{}}>
      <Box sx={classes.boxDialog}>
        <Typography variant="h6">ลดจำนวนที่ต้องการอินฟลูเอนเซอร์</Typography>
        <img
          src={InfluIsLess}
          onError={addDefaultImageSrc}
          alt="private-job-less-than"
        />
        <Typography variant="h3" color={"#893DBD"}>
          {listing?.length} คน
        </Typography>
        <Typography variant="title2">
          จำนวนที่ต้องการเดิมคือ {jobLimit} คน
        </Typography>

        <Box sx={{ marginTop: "16px" }}>
          <Typography>
            • ระบบจะดำเนินการยื่นข้อเสนอให้แก้อินฟลูเอนเซอร์ตาจำนวนที่ลดลง
          </Typography>
          <Typography sx={{ marginLeft: "8px" }}>
            และดำเนินการโดยอิงตาจำนวนข้างต้นเป็นหลัก
          </Typography>
          <Typography>
            •
            การยื่นข้อเสนอให้แก่อินฟลูเอนเซอร์ดำเนินการตำลำดับที่ได้จัดเรียงไว้
          </Typography>
          <Typography sx={{ marginLeft: "8px" }}>
            พร้อมทั้งคำนวณการใช้จ่ายตามจริงที่อินฟลูเอนเซอร์ตอบรับงาน
          </Typography>
        </Box>

        <Typography variant="title1" sx={{ margin: "24px 0" }}>
          ต้องการดำเนินการต่อหรือไม่ ?
        </Typography>

        <Box sx={classes.boxButton}>
          <Button onClick={onClose}>ไม่ กลับไปแก้ไขลำดับ</Button>
          <Button
            onClick={() => onConfirmJobPrivate(listing, reserved)}
          >
            ดำเนินการต่อ
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
