import { IEmployerListParams } from "./type";
import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { ICampaignFilter } from "@views/campaign/Campaign.type";

export class EmployerAPIs {
  async getEmployerList(params: IEmployerListParams) {
    const { access_token } = getTokenFromStorage()
    
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get("/employers", { headers, params});
    return data.result.data;
  }

  async getEmployerInfo(employerId?: string) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/employers/${employerID}`, { headers });
    return data.result.data;
  }

  async createEmployer(body:any) {
    const headers = {
      "X-API-Key": API_KEY,
    };
    const { data } = await instance.post("/employers", body, { headers });
    return data.result.data;
  }

  async updateEmployerInfo(employerId: string, body: any) {

    const { access_token, session_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.patch(`/employers/${employerID}`, body, { headers });
    return data.result.data;
  }

  async deleteEmployer(employerId: string) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.delete(`/employers/${employerID}`, { headers });
    return data;
  }

  async getEmployerCamapign(employerId: string, params: ICampaignFilter) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/employers/${employerID}/campaigns`, { headers, params });
    return data.result.data;
  }
}