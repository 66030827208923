export const useReferralsTabStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
    borderTop: "1px solid #8C499C",
  },
  boxDetail: {
    border: "1px solid #ECECEC",
    borderRadius: "12px",
  },
  boxStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 20px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  boxIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#893DBD",
    width: "52px",
    height: "52px",
    bgcolor: "#F5F6F8",
    borderRadius: "50%",
  },
  boxCode: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F4ECFF",
    color: "#8C499C",
    padding: "12px",
    borderRadius: "8px",
    mt: "8px",
  },
  paginate: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
  noReferral: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    bgcolor: "#fff",
    borderRadius: "16px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
    py: "40px",
  },
  textNoReferral: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
};
