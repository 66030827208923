import { hGrey } from "@theme/index";

export const useHeaderBarStyle = {
  appBar: {
    boxShadow: {
      xs: "initial",
      lg: "0px 2px 2px rgba(0, 0, 0, 0.15)",
    },
    zIndex: 999,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxToobar: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "8px",
  },
  profileAvatar: {
    height: "32px",
    width: "32px",
  },
  headerBarButton: {
    width: "100%",
    border: "2px solid",
    borderColor: "primary.main",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    borderColor: "grey.100",
    ml: "10px",
    "& > svg": {
      fontSize: "30px",
    },
  },
  profileAvatarSidebar: {
    height: "80px",
    width: "80px",
  },
  line: {
    width: "100%",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: `1px solid ${hGrey[100]}`,
    margin: "8px 0",
  },
  iconSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    borderRadius: "10px",
    fontSize: "24px",
    color: "common.white",
    cursor: "pointer",
    marginRight: "12px",
  },
  boxIcon: {
    display: ["none", "none", "flex"],
    alignItems: "center",
    ml: 2,
    color: "common.white",
  },
  boxFullname: {
    display: ["none", "none", "flex"],
    width: "100%",
  },
  popover: {
    mt: "15px",
    "& > .MuiPaper-root": {
      borderRadius: "10px",
    },
  },
  boxMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "4px",
    cursor: "pointer",
  },
  boxMenuIcon: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "12px",
  },
  boxMenuLogout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8px",
    cursor: "pointer",
  },
  drawer: {
    "& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper, .css-wf16b5": {
      width: "120px",
      borderRadius: "0px 32px 32px 0px;",
    },
  },
  sidebarContent: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "block",
    width: "120px",
    minHeight: "560px",
    height: "100%",
    backgroundColor: "common.white",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px 32px 32px 0px;",
    zIndex: 1111,
  },
  sidebarLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    height: "104px",
    borderBottom: "2px solid",
    borderColor: "grey.100",
  },
  notiContent: {
    maxHeight: "400px",
    overflowY: "scroll",
    "&::-webkit-scrollbar-thumb": {
      bgcolor: "#888",
    },
    "&::-webkit-scrollbar-track": {
      bgcolor: "#f1f1f1",
    },
    "&::-webkit-scrollbar": {
      width: "2px",
    },
  },
};
