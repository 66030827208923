// mui and style
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useJobApprovedDatePostStyle as classes } from "../JobApproved.style";
// redux and dayJs
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
import dayjs from "dayjs";

export default function DatePost() {
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const datePost = jobInfo?.published_range;

  return (
    <Box sx={classes.datePostCon}>
      <Box sx={classes.title}>
        <Typography>Date Post</Typography>
        <Typography>วันที่อินฟลูเอนเซอรโพสต์งาน</Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Grid container spacing={5} p={["24px 0 24px 0", "24px"]}>
        <Grid item xs={4} sm={3}>
          <Typography variant="title1">Date Range</Typography>
        </Grid>
        <Grid item xs={8} sm={9}>
          <Typography>
            {datePost?.published_at
              ? `${dayjs(datePost?.published_at).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(datePost?.completed_at).format("DD/MM/YYYY")}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography variant="title1">Time Range</Typography>
        </Grid>
        <Grid item xs={8} sm={9}>
          <Typography>
            {datePost?.start_time
              ? `${datePost.start_time} - ${datePost.end_time}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography variant="title1">Note</Typography>
        </Grid>
        <Grid item xs={8} sm={9}>
          <Typography>{datePost?.note || "-"}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
