// mui and icons
import { Box, Typography, Avatar, Divider, Stack } from "@mui/material";
import { CalendarMonth, Paid, PeopleAltOutlined } from "@mui/icons-material";
// style and redux
import { useCampaignSummaryInfoStyle as classes } from "./ReportTab.style";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
import { useAppSelector } from "@store/hook";
// type and others
import { ICampaignSummaryInfoProps } from "./ReportTab.type";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";
import dayjs from "dayjs";

export default function CampaignSummaryInfo(props: ICampaignSummaryInfoProps) {
  const { budget, jobCount } = props;
  
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const timelineInfo = campaignInfo?.timeline;

  const numberFormat = new Intl.NumberFormat("en", {
    minimumIntegerDigits: 1,
  });

  const publishedDate = new Date(timelineInfo?.end_publish_date ?? "");

  const campaignSummaryInfo = [
    {
      label: `${dayjs(timelineInfo?.end_publish_date).format(
        "DD-MM-YYYY"
      )} - ${dayjs(
        publishedDate.setDate(
          publishedDate.getDate() + (timelineInfo?.posting_period || 0)
        )
      ).format("DD-MM-YYYY")}`,
      icon: <CalendarMonth />,
    },
    {
      label: campaignInfo?.content_type.replaceAll(/_/gi, " "),
      icon: (
        <SocialIconSelected
          platform={campaignInfo?.platform}
          width="16px"
          height="16px"
        />
      ),
    },
    {
      label: `Budget : ${numberFormat.format(budget || 0)} THB`,
      icon: <Paid />,
    },
    {
      label: `Influencers : ${numberFormat.format(jobCount || 0)}`,
      icon: <PeopleAltOutlined />,
    },
  ];

  return (
    <Box sx={classes.container} position="relative">
      <Box
        display="flex"
        flexDirection={["column", "initial"]}
        alignItems={["initial", "center", "start"]}
      >
        <Avatar
          src={campaignInfo?.company.media.url}
          sx={classes.campSumImage}
        />
        <Box sx={classes.campSumBoxTitle}>
          <Typography>{campaignInfo?.name}</Typography>
          <Typography>{campaignInfo?.company.name}</Typography>
        </Box>
      </Box>
      <Box sx={classes.campSumCardBottom}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          divider={<Divider variant="middle" orientation="vertical" flexItem />}
          spacing={[0.7, 3]}
          sx={classes.campSumCardBottomItem}
        >
          {campaignSummaryInfo.map((item, index) => (
            <Box key={index} display="flex" alignItems="center">
              {item.icon}
              <Typography ml={2} fontSize="16px">
                {item.label}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
      <Divider sx={{ display: ["inintial", "inintial", "none"] }} />
      <Box sx={classes.campCompBox}>
        <Avatar src={campaignInfo?.organisation?.attachment?.url} />
        <Typography>{campaignInfo?.organisation?.name}</Typography>
      </Box>
    </Box>
  );
}
