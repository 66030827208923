import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Card,
  Icon,
  Stack,
  Button,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import urlLogo from "@assets/images/logo/hashu-logo512.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// style and types
import { useCampaignJobTabStyle as classes } from "./JobTab.style";
import { ITabPanels, IEmployerJobTabProps, IEmployerJobList, } from "./JobTab.type";
// api and route
import { JobAPI } from "@api/global";
import { useHistory, useLocation, useParams } from "react-router-dom";
// theme and components
import { hBlack } from "@theme/index";
import ShippingProductDialog from "./job-dialog/ShippingProduct";
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";
import ViewMultiVideoDialog from "@global/view-video-dialog/ViewMultiVideoDialog";
// utils
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// others
import Loading from "@global/loading/Loading";
import PaginationCustom from "@global/pagination-custom";
import { VideoFormat } from "@global/video-format/ViedoFormat";
import { FacebookImageFormat } from "@global/image-format/ImageFormat";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";
import { CompanyIconSelected } from "@global/icon-selected/CompanyIconSelected";
// dayJs
import dayjs from "dayjs";

export default function EmployerJobTab(props: IEmployerJobTabProps) {
  const { campaignInfo } = props;

  const [jobList, setJobList] = useState<IEmployerJobList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paginate, setPaginate] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [jobIndex, setJobIndex] = useState(0);
  const [viewImageItem, setViewImageItem] = useState(false);
  const [openShippingProduct, setOpenShippingProduct] = useState(false);

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  const isPlace = campaignInfo?.review_type === "place";
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jobStatus = params.get("status") || "first_draft";
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const contentType = jobList && jobList?.item?.[jobIndex]?.content_type?.type;

  const handleChangeTab = (tab: ITabPanels) => {
    if (role === "employer") {
      history.replace(
        `/campaigns/${campaignId}/info?tab=job&status=${tab.status}`
      );
    } else {
      history.replace(
        `/campaigns/${campaignId}/info?tab=job&emp_id=${employerId}&status=${tab.status}`
      );
    }
  };

  useEffect(() => {
    setRowsPerPage(lgScreen ? 4 : 5);
  }, [lgScreen]);

  useEffect(() => {
    const params = {
      order_by: "date",
      sort: "DESC",
      status: jobStatus,
      page: paginate,
      item_per_page: rowsPerPage,
    }

    let isMounted = true
    JobAPI.getEmployerJobList(employerId, campaignId, params).then((response) => {
      if (isMounted) {
        setJobList(response)
        setIsLoading(false)
      }
    }).catch((error) => {
      if (isMounted) {
        console.log("get job employer list error : ", error)
        setIsLoading(false)
      }
    })

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [paginate, rowsPerPage, jobStatus]);

  const onViewJobDetail = (status: boolean, jobIndex: number) => {
    setOpenShippingProduct(status);
    setJobIndex(jobIndex);
  };

  const onViewImageItem = (status: boolean, jobIndex: number) => {
    setViewImageItem(status);
    setJobIndex(jobIndex);
  };

  const handleChangePage = (_event: unknown, pageValue: number) => {
    setPaginate(pageValue);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const jobStatusList = [
    {
      title: "รอตรวจงาน",
      status: "first_draft",
      total: jobList?.first_draft || 0,
      bgcolor: "#F4ECFF",
      color: "#8C499C",
    },
    {
      title: "ส่ง Feedback",
      status: "rejected",
      total: jobList?.rejected || 0,
      bgcolor: "#FFEAF4",
      color: "#E30D18",
    },
    {
      title: "แก้ไขแล้ว รอตรวจ",
      status: "waiting_for_approve",
      total: jobList?.waiting_for_approve || 0,
      bgcolor: "#FFFBD4",
      color: "#FB8C00",
    },
    {
      title: "Draft ผ่านแล้ว",
      status: "approved",
      total: jobList?.approved  || 0,
      bgcolor: "#E7FFE8",
      color: "#4CAF50",
    },
    {
      title: "แนบลิงค์ส่งงาน",
      status: "published",
      total: jobList?.published || 0,
      bgcolor: "#E5F6FF",
      color: "#0077E4",
    },
  ];

  const goReviewPost = (jobId:string) => {
    history.push(`/employers/${employerId || session_token?.employer_id}/jobs/${jobId}/review-post?c_id=${campaignId}`);
  };

  const goJobApproved = (jobId:string) => {
    history.push(`/employers/${employerId || session_token?.employer_id}/jobs/${jobId}/draft-approval?c_id=${campaignId}`);
  };

  const goInfluencerPostReport = (jobId:string) => {
    history.push(`/influencers/jobs/${jobId}/post-report?c_id=${campaignId}`)
  }

  if (isLoading) return <Loading />
  
  return (
    <>
      <Box sx={classes.campJobCon}>
        <div>
          <Typography variant="h5" color={hBlack[200]}>
            Job
          </Typography>
          <Typography variant="subtitle1" color={hBlack[300]}>
            ขั้นตอนการดำเนินการ
          </Typography>
        </div>
        <Divider sx={{ my: "24px" }} />
        <Stack direction="row" spacing={2} mb="24px">
          {jobStatusList.map((tab, index) => (
            <Button
              key={index}
              onClick={() => handleChangeTab(tab)}
              sx={{
                maxHeight: "32px",
                bgcolor: tab.status === jobStatus ? tab.bgcolor : "#fff",
                border: tab.status === jobStatus ? "none" : "1px solid #E0E0E0",
                color: tab.status === jobStatus ? tab.color : "#5C5C5C",
                "&:hover": {
                  border:
                    tab.status === jobStatus ? "none" : "1px solid #E0E0E0",
                  bgcolor: tab.status === jobStatus ? tab.bgcolor : "#fff",
                },
                px: "15px",
              }}
            >
              {`${tab.title} ${tab.total === 0 ? "" : `(${tab.total})`}`}
            </Button>
          ))}
        </Stack>
        <Grid container spacing={5}>
          {jobList?.item?.map((item, index) => (
            <Grid key={index} item xs={4} lg={3} xl={2.4}>
              <Card sx={classes.postCard}>
                <Box
                  onClick={
                    jobStatus === "rejected"
                      ? () => goReviewPost(item.job_id)
                      : jobStatus === "approved" || jobStatus === "published"
                      ? () => goJobApproved(item.job_id)
                      : undefined
                  }
                >
                  {jobStatus === "working" && (
                    <Box sx={classes.editButton}>
                      แก้ไขแล้ว (ครั้งที่ {item?.draft_count}/2)
                    </Box>
                  )}

                  {jobStatus === "approved" ? (
                    <Box
                      sx={{
                        ...classes.editButton,
                        bgcolor: "#E7FFE8",
                        color: "#231F20",
                        textAlign: "center",
                      }}
                    >
                      <Typography fontSize="12px" fontWeight={300}>
                        {item.published_range.published_at
                          ? `${dayjs(item.published_range.published_at).format(
                              "DD/MM/YY"
                            )} - ${dayjs(item.published_range.end_published_at).format(
                              "DD/MM/YY"
                            )} | ${item.published_range.start_time} - ${
                              item.published_range.end_time
                            }`
                          : "-"}
                      </Typography>
                    </Box>
                  ) : jobStatus === "rejected" ? (
                    <Box
                      sx={{
                        ...classes.editButton,
                        bgcolor: "#FFEAF4",
                        color: "#E30D18",
                        textAlign: "center",
                      }}
                    >
                      <Typography fontSize="14px" fontWeight={500}>
                        {`ส่ง Feedback (ครั้งที่${item.draft_count}/2)`}
                      </Typography>
                    </Box>
                  ) : jobStatus === "waiting_for_approve" ? (
                    <Box
                      sx={{
                        ...classes.editButton,
                        bgcolor: "#FFFBD4",
                        color: "#FB8C00",
                        textAlign: "center",
                      }}
                    >
                      <Typography fontSize="14px" fontWeight={500}>
                        {`แก้ไขแล้ว (ครั้งที่${item.draft_count}/2)`}
                      </Typography>
                    </Box>
                  ) : null}

                  <Box sx={classes.cardHeader}>
                    <img
                      alt="profile"
                      src={item.social_account.profile_image_url}
                    />
                    <div>
                      <Box sx={classes.cardHeaderTop}>
                        <SocialIconSelected
                          platform={item.social_account.platform}
                        />
                        <Typography>{item.social_account.name}</Typography>
                      </Box>
                      <Box sx={classes.cardHeaderBottom}>
                        <AccountCircleOutlinedIcon />
                        <Typography>
                          {numberFormat.format(item.social_account.follower)} followers
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                  <Typography
                    id={item.job_id}
                    sx={{
                      ...classes.descPost,
                      cursor:
                        jobStatus !== "first_draft" ? "pointer" : "initial",
                      WebkitLineClamp: item.content_type.type === "text" ? "13" : "3",
                      minHeight: item.content_type.type === "text" ? "315px" : "75px",
                    }}
                  >
                    {item.description?.description}
                  </Typography>
                </Box>

                {item.content_type.type !== "text" && (
                  <Box sx={classes.postImageItem}>
                    {(item.attachments || []).length ? (
                      <Box onClick={() => onViewImageItem(true, index)}>
                        {contentType === "video" ? (
                          <VideoFormat
                            item={[item.attachments[0]]}
                            height="300px"
                          />
                        ) : (
                          <FacebookImageFormat
                            item={item.attachments}
                            height="300px"
                          />
                        )}
                      </Box>
                    ) : (item.external_file || []).length ? (
                      <Box
                        sx={classes.urlBox}
                        onClick={() => window.open((item.external_file || [])[0].url, "_blank")}
                      >
                        <Box sx={classes.urlIcon}>
                          <Icon className="ico-hu-conversion" />
                          <Typography mt="15px">Video URL</Typography>
                        </Box>
                        <Box sx={classes.urlImg}>
                          <img src={urlLogo} alt="item" />
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                )}
                {(jobStatus === "approved" || jobStatus === "published") &&
                item.product_review_type === "send_return" &&
                !isPlace ? (
                  <Box
                    sx={{ ...classes.productReturnBox, cursor: "pointer" }}
                    onClick={() => goJobApproved(item.job_id)}
                  >
                    <Box sx={classes.returnProduct}>
                      <Icon
                        className="ico-hu-shipping-outline"
                        color="primary"
                      />
                      <Typography variant="title1" ml={2}>
                        Product Returns
                      </Typography>
                    </Box>
                    <Box sx={classes.returnProductComp}>
                      <Typography>Company</Typography>
                      {item.product_return?.shipping_type ? (
                        <Box display="flex" alignItems="center">
                          <CompanyIconSelected
                            company={item.product_return?.shipping_type}
                            height="18px"
                            width="18px"
                          />
                          <Typography ml={2}>
                            {item.product_return?.shipping_type}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                    <Box sx={classes.trackingNo}>
                      <Typography>Tracking No</Typography>
                      <Typography>{item.product_return?.tracking_no || "-"}</Typography>
                    </Box>
                  </Box>
                ) : (item.delivery_confirmation?.date_of_receipt || jobStatus === "first_draft") &&
                  !isPlace &&
                  item.product_review_type !== "no_shipping" ? (
                  <Box sx={classes.productReturnBox}>
                    <Box sx={classes.returnProdTitle}>
                      <Icon
                        className="ico-hu-product-received"
                        color="primary"
                      />
                      <Typography fontWeight={500} ml={2}>
                        Confirm Goods Received
                      </Typography>
                    </Box>
                    <Box sx={classes.returnProdDate}>
                      <Typography>Date</Typography>
                      <Typography>
                        {item.delivery_confirmation?.date_of_receipt
                          ? dayjs(item.delivery_confirmation?.date_of_receipt).format("DD/MM/YYYY")
                          : "-"}
                      </Typography>
                    </Box>
                    <Box sx={classes.returnProdDetail}>
                      <Typography>Detail</Typography>
                      <Typography onClick={() => onViewJobDetail(true, index)}>
                        View Details
                      </Typography>
                    </Box>
                  </Box>
                ) : null}

                {jobStatus === "published" ? (
                  <Stack direction="row" spacing={3} mt="20px">
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<Icon className="ico-hu-view" />}
                      onClick={() => window.open(item.published_link, "_blank")}
                      sx={classes.viewPostBtn}
                      disabled={item.published_link ? false : true}
                    >
                      <span>View Post</span>
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<Icon className="ico-hu-report" />}
                      onClick={() => goInfluencerPostReport(item.job_id)}
                      sx={classes.reportBtn}
                    >
                      Report
                    </Button>
                  </Stack>
                ) : (
                  jobStatus !== "approved" &&
                  jobStatus !== "rejected" && (
                    <Button
                      onClick={() => goReviewPost(item.job_id)}
                      variant="contained"
                      fullWidth
                      sx={classes.checkBtn}
                    >
                      ตรวจงาน
                    </Button>
                  )
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={classes.pagination}>
          {jobList?.item?.length ? (
            <PaginationCustom
              page={paginate}
              count={paginate > 0 ? Math.ceil(jobList.total_item / rowsPerPage) : 0}
              onPaginationChange={handleChangePage}
              rowsPerPageData={[4, 8, 12, 16]}
              rowsPerPage={rowsPerPage}
              onRowPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Box>
      </Box>
      <ShippingProductDialog
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
        jobInfo={jobList ? jobList.item?.[jobIndex] : null}
      />
      {jobList &&
        (contentType === "video" ? (
          <ViewMultiVideoDialog
            open={viewImageItem}
            onClose={setViewImageItem}
            videos={jobList.item?.[jobIndex]?.attachments}
          />
        ) : (
          <ViewMultiImageDialog
            open={viewImageItem}
            onClose={setViewImageItem}
            images={jobList.item?.[jobIndex]?.attachments}
          />
        ))}
    </>
  );
}
