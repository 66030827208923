export const useAddMemberTeamStyle = {
  addTeamMemberWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
  },
  buttonAddTeamWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    mt: "30px",
  },
  textNext: { mr: "25px", color: "#893DBD", cursor: "pointer" },
  teamLeadTxt: {
    bgcolor: "#F5F6F8",
    p: "10px 16px 10px 16px",
    borderRadius: "50px",
  },
  paperSearch: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    height: "40px",
    padding: "0 10px",
    border: "1px solid #8C499C",
    boxShadow: "none",
  },
  searchList: {
    borderRadius: "0 0 20px 20px",
    bgcolor: "#fff",
    position: "absolute",
    top: 42,
    width: "100%",
    zIndex: 2,
    border: "1px solid #8C499C",
    maxHeight: "265px",
    overflowY: "scroll",
    MsOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  searchedMemberList: {
    columnGap: "10px",
    alignItems: "center",
    p: "10px",
    cursor: "pointer",
    "&:hover": { bgcolor: "#F5EEFF" },
  },
};

export const useCreateTeamStyle = {
  card: {
    p: "50px",
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px 0px #0000001A",
    borderRadius: "20px",
  },
  createYourTeamWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
  },
  inputImageWrap: {
    border: "2px dashed #9E9E9E",
    borderRadius: "8px",
    width: "160px",
    height: "160px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "& > img": {
      width: "64px",
      height: "64px",
      borderRadius: "8px",
    },
  },
  icon: { mr: "4px", fontSize: "12px", color: "#616161" },
  textFieldWrap: { mt: "24px", width: "50%" },
  textField: { width: "100%", mt: "6px" },
  buttonWrap: {
    mt: "48px",
    width: "30%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "200px",
    backgroundColor: "#8C499C",
    color: "#fff",
    ":hover": {
      color: "#8C499C",
    },
  },
  isUploadImg: {
    position: "relative",
    "& > .curcular": {
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& > img": {
      borderRadius: "8px",
      width: "160px",
      height: "160px",
      objectFit: "cover",
      cursor: "pointer",
    },
  },
  isNotUploadImg: {
    border: "2px dashed #9E9E9E",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
  },
};

export const useCreateTeamStepStyle = {
  stepTxt: {
    width: "32px",
    height: "32px",
    color: "#8C499C",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const useDialogPermissionStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
};

export const useOrganizingYourWalletStyle = {
  contentWrap: {
    mt: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  walletWrap: {
    mt: "20px",
    width: "100%",
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
  },
  detailWrap:{
    mt: "20px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  selectWrap:{
    "& > div:first-of-type":{
      border: "1px solid #E0E0E0",
      p: "12px",
      borderRadius: "8px",
    },
    "& > div:last-of-type":{
      border: "1px solid #E0E0E0",
      p: "12px",
      borderRadius: "8px",
      mt: "20px",
    },
  },
  buttonWrap:{
    mt: "42px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonSuccess:{
    p: "8px 24px",
    bgcolor: "#8C499C",
    color: "#fff",
    ":hover": {
      color: "#8C499C",
    },
  }
};
