import { useEffect } from "react";
// mui and icon
import { Box, Link, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// component
import Header from "@components/influencer-dashboard/influencer-information/Header";
import SocialTab from "@components/influencer-dashboard/influencer-information/social-tab/SocialTab";
import ProfileTab from "@components/influencer-dashboard/influencer-information/profile-tab/ProfileTab";
import EarningsTab from "@components/influencer-dashboard/influencer-information/earnings-tab/EarningTab";
import CampaignTab from "@components/influencer-dashboard/influencer-information/campaign-tab/CampaignTab";
import ReferralsTab from "@components/influencer-dashboard/influencer-information/referrals-tab/ReferralsTab";
import InfluencerStatus from "@components/influencer-dashboard/influencer-information/status-tab/InfluencerStatusTab";
// react-route and style
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useInfluencerInfoStyle as classes } from "./InfluencerInfo.style";
// redux
import {
  getInfluencerSocialAsync,
  selectInfluencerSocialOnload,
} from "@slices/InfluencerSocial.slice";
import {
  getInfluencerProfileAsync,
  selectInfluencerProfileOnload,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import { tabs } from "@components/influencer-dashboard/dashboard.constants";
import Loading from "@components/global/loading/Loading";

export default function InfluencerInfo() {
  const { influencerId } = useParams<{ influencerId: string }>();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "profile";

  const dispatch = useAppDispatch();
  const profileLoading = useAppSelector(selectInfluencerProfileOnload);
  const socialLoading = useAppSelector(selectInfluencerSocialOnload);

  useEffect(() => {
    dispatch(getInfluencerProfileAsync({ influencerId }));
    dispatch(getInfluencerSocialAsync({ influencerId }));
  }, [dispatch, influencerId]);

  const handleTabChangeTab = (tab: string) => {
    history.replace(`/influencers/${influencerId}/info?tab=${tab}`);
  };

  if (profileLoading || socialLoading) return <Loading />

  return (
    <div className="container-main">
      <div>
        <Link
          onClick={() => history.goBack()}
          underline="none"
          sx={classes.goBack}
        >
          <KeyboardArrowLeftIcon />
          <span>Back</span>
        </Link>
      </div>
      <Typography variant="h5">Influencer Information</Typography>
      <Typography variant="subTitle1">ข้อมูลอินฟลูเอนเซอร์</Typography>

      <Box mt="20px">
        <Header />
      </Box>

      <Box sx={classes.tabBox}>
        {tabs.map((tab, index) => (
          <Box
            key={index}
            onClick={() => handleTabChangeTab(tab.key)}
            sx={tabSelected === tab.key ? classes.tabSelected : classes.tab}
          >
            <Typography
              textTransform="capitalize"
              fontWeight={tabSelected === tab.key ? 500 : 400}
            >
              {tab.text}
            </Typography>
          </Box>
        ))}
      </Box>

      {tabSelected === "profile" ? (
        <ProfileTab />
      ) : tabSelected === "social" ? (
        <SocialTab />
      ) : tabSelected === "campaign" ? (
        <CampaignTab />
      ) : tabSelected === "earnings" ? (
        <EarningsTab />
      ) : tabSelected === "referrals" ? (
        <ReferralsTab />
      ) : tabSelected === "status" ? (
        <InfluencerStatus />
      ) : null}
    </div>
  );
}
