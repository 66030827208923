import React from "react";
// mui
import { Grid, Typography } from "@mui/material";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";

export default function ShippingAddress() {
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const shippingAddress = influencerInfo?.address.shipping_address;

  return (
    <Grid container p="20px" rowGap="20px">
      {[
        { label: "ชื่อ", value: shippingAddress?.fullname },
        { label: "เบอร์โทรศัพท์", value: shippingAddress?.phone },
        { label: "ที่อยู่ในการจัดส่ง", value: shippingAddress?.address },
      ].map((item, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={3}>
            <Typography variant="title1">{item.label}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography>{item.value}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
