// mui and icons
import {
  Box,
  Grid,
  Stack,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// style and type
import { useTimelineDialogStyle as classes } from "../CampaignDetail.style";
import { ITimelineDialogProps } from "../CampaignDetail.type";
// others
import dayjs from "dayjs";

export default function TimelinePublishedDialog(props: ITimelineDialogProps) {
  const { open, onClose, timeline } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>Timeline</Typography>
        <Typography>ช่วงโพสต์และแนบลิงค์ส่งงาน</Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.dialogContentBox}>
          <Grid container>
            <Grid item xs={12} mb={3}>
              <Box>
                <Typography variant="title1" color="primary">
                  ช่วงโพสต์และแนบลิงค์ส่งงาน
                </Typography>
                <Box display="flex" mt={3} flexDirection={["column", "row"]}>
                  <Grid item xs={12} sm={4} display="flex" alignItems="center">
                    <Typography variant="title2">
                      ช่วงอินฟลูเอนเซอร์โพสต์งาน
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={[2, 0]}>
                    <Stack
                      direction="row"
                      spacing={[2, 4]}
                      sx={classes.openDate}
                    >
                      <Typography variant="title2">
                        {dayjs(timeline?.start_recruiting_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        -
                      </Box>
                      <Typography variant="title2">
                        {dayjs(timeline?.end_recruiting_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </Box>
                <Box display="flex" flexDirection={["column", "row"]} mt={4}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="title2">ระยะเวลาในการโพสต์</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} sx={classes.openDate} mt={[2, 0]}>
                    <Typography variant="title2">
                      {timeline?.posting_period} วัน
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Box component="ul" ml={-5}>
              <Typography variant="body2" component="li">
                หลังอนุมัติ Draft
                ผู้จ้างงานจะกำหนดวันและในเวลาโพสต์ให้แก่อินฟลูเอนเซอร์
              </Typography>
              <Typography variant="body2" component="li">
                อินฟลูเอนเซอร์ต้องแนบลิงค์ส่งงานภายใน{" "}
                <span style={{ fontWeight: 500 }}>“2 ชั่วโมง”</span>{" "}
                หลังเผยแพร่โพสต์ และตั้งค่าโพสต์เป็น{" "}
                <span style={{ fontWeight: 500 }}>“สาธารณะ”</span>{" "}
                จนกว่าจะสิ้นสุดระยะเวลาในการโพสต์
                หากอินฟลูเอนเซอร์ซ่อนโพสต์หรือลบโพสต์ก่อนครบเวลาที่กำหนด
                ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงานโดยอัตโนมัติ
              </Typography>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
