export const usePrivateStatusStyles = {
  boxStatus: {
    border: "1px solid #F4ECFF",
    borderRadius: "8px",
    padding: "16px 20px",
    display: "flex",
    alignItems: "center",
    minHeight: "100px",
    ".lastText": {
      marginTop: "8px",
    },
  },
  boxButton: {
    backgroundColor: "#4CAF50",
    borderRadius: "12px",
    minHeight: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    flexDirection: "column",
    padding: "16px 20px",
    cursor: "pointer",
  },
};
