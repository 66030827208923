import { useEffect, useState } from "react";
// component
import HomeMain from "@components/home-main/HomeMain";
import SignInDialog from "@components/authentication-dialog/SignInDialog";
import SelectAccountDialog from "@components/authentication-dialog/SelectAccountDialog";
import CreateAccountDialog from "@components/authentication-dialog/CreateAccountDialog";
import ResetPasswordDialog from "@components/authentication-dialog/ResetPasswordDialog";
import ForgetPasswordDialog from "@components/authentication-dialog/ForgetPasswordDialog";
import CreateEmployerDialog from "@components/authentication-dialog/CreateEmployerDialog";
// type
import { IDevice } from "../global/global.types";
import { IAccountSelected } from "@components/authentication-dialog/AuthenDialog.types";
// hook
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { useLocation } from "react-router-dom";

export default function HomeMainPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isLineOA = params.get("action") === "link-account";

  const [device, setDevice] = useState<IDevice>("mobile");
  const [accountSelected, setAccountSelected] = useState<IAccountSelected | null>(null);
  const [openSelectAccount, setOpenSelectAccount] = useState(false);
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(isLineOA);
  
  const bp = useBreakpoint();

  useEffect(() => {
    if (bp === "xs") {
      setDevice("mobile");
    } else if (bp === "sm" || bp === "md") {
      setDevice("tablet");
    } else {
      setDevice("desktop");
    }
  }, [bp]);

  const handleCreateAccount = (type: IAccountSelected) => {
    setOpenSelectAccount(false);
    setAccountSelected(type);
    setOpenCreateAccount(true);
  };

  const handleOpenSelectAccountType = () => {
    setOpenSignIn(false);
    setOpenSelectAccount(true);
  };

  const handleOpenForgetPassword = () => {
    setOpenSignIn(false);
    setOpenForgetPassword(true);
  };

  const handleOpenResetPassword = () => {
    setOpenSignIn(false);
    setOpenForgetPassword(false);
    setOpenResetPassword(true);
  };

  const handleOpenSigIn = () => {
    setOpenSignIn(true);
    setOpenForgetPassword(false);
  };

  return (
    <div>
      <HomeMain
        device={device}
        breakpoint={bp}
        setOpenSelectAccount={setOpenSelectAccount}
        setOpenedSignIn={setOpenSignIn}
      />

      {/* Select Account Dialog */}
      {/* -- UAT comment here -- */}
      <SelectAccountDialog
        open={openSelectAccount}
        setOpen={setOpenSelectAccount}
        handleCreateAccount={handleCreateAccount}
      />
      {/* -- End comment -- */}

      {/* Create Account Dialog */}
      {accountSelected && accountSelected === "influencer" ? (
        <CreateAccountDialog
          open={openCreateAccount}
          setOpen={setOpenCreateAccount}
          accountSelected={accountSelected}
        />
      ) : accountSelected === "marketer" ? (
        <CreateEmployerDialog
          open={openCreateAccount}
          setOpen={setOpenCreateAccount}
          accountSelected={accountSelected}
        />
      ) : null}

      {/* Signin Dialog */}
      <SignInDialog
        open={openSignIn}
        setOpenSignIn={setOpenSignIn}
        openSelectAccountType={handleOpenSelectAccountType}
        openForgetPassword={handleOpenForgetPassword}
      />

      {/* Forget Password Dialog */}
      <ForgetPasswordDialog
        open={openForgetPassword}
        setOpenForgetPassword={setOpenForgetPassword}
        openResetPassword={handleOpenResetPassword}
        openSignIn={handleOpenSigIn}
      />

      {/* Reset Password Dialog */}
      <ResetPasswordDialog
        open={openResetPassword}
        setOpenResetPassword={setOpenResetPassword}
        openSignIn={handleOpenSigIn}
      />
    </div>
  );
}
