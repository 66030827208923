import tiktok from "@assets/images/social/tiktok-only.png";
import twitter from "@assets/images/social/twitter_5969020.png";
import youtube from "@assets/images/social/youtube-only.png";
import facebook from "@assets/images/social/facebook-only.png";
import instagram from "@assets/images/social/instagram-only.png";

export const platformList = [
  {
    key: "facebook",
    label: "Facebook",
    url: facebook,
  },
  {
    key: "instagram",
    label: "Instagram",
    url: instagram,
  },
  {
    key: "youtube",
    label: "Youtube",
    url: youtube,
  },
  {
    key: "twitter",
    label: "Twitter",
    url: twitter,
  },
  {
    key: "tiktok",
    label: "Tiktok",
    url: tiktok,
  },
];