import jwt_decode from "jwt-decode";
// import { JWT_SECRET } from "../constants/api.constants";

export function decodeJWT(token: string) {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    return undefined;
  }
}
