import React, { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Radio,
  Avatar,
  Typography,
  RadioGroup,
  CardContent,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { useCampaignOverviewStyle as classes } from "../CampaignBrief.style";
// redux
import {
  selectEmployerInfo,
  getEmployerInfoAsync,
} from "@slices/EmployerInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// react route and component
import { useLocation } from "react-router-dom";
import ProductDetails from "../review-type/review-product/ProductDetails";
import PlaceDetails from "../review-type/review-place/PlaceDetails";
import ImageInput from "./ImageInput";
// api and type
import { AppInformationAPI } from "@api/global";
import { ICreateCampaignProps } from "../CampaignBrief.type";
import { ICategory, ICompany } from "@api/app-information/type";
import { ICampaignBriefFormKey } from "@views/campaign/create-campaign/CreateCampaign.type";
// others
import TextFieldCustom from "@global/text-field";
import SelectMultiCategory from "./SelectMultiCategory";
import WrapInputWithLabel from "@global/wrap-input-label";
import SearchNameInputCustom from "@global/search-name-input";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function CampaignOverview(props: ICreateCampaignProps) {
  const { form } = props;
  const dispatch = useAppDispatch();

  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);

  const {
    watch,
    register,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = form;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || "";

  const { session_token } = getTokenFromStorage();

  const employerInfo = useAppSelector(selectEmployerInfo);
  const isTeam = employerInfo?.package_plan === "team";
  const packages = isTeam ? employerInfo.team?.package : employerInfo?.package;
  const campManage = packages?.campaign_management;

  const isPackageActive = (
    crrPackage: string,
    packages?: { id: number; title: string }[]
  ) => {
    if (
      (packages || []).filter((p) => p.title === crrPackage).length ||
      session_token?.role === "admin"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const campaignOverviewRadioList = [
    {
      id: "objective",
      label: "Objective",
      value: "objective",
      errors: errors?.objective?.message,
      options: [
        {
          id: 1,
          label: "Awareness (การทำให้สินค้าหรือบริการของคุณเป็นที่รู้จัก)",
          value: "awareness",
          isActive: isPackageActive(
            "awareness",
            campManage?.campaign_objective
          ),
        },
        {
          id: 2,
          label: "Conversion (การตัดสินใจหรือการตอบรับจากผู้บริโภค)",
          value: "conversion",
          isActive: isPackageActive(
            "conversion",
            campManage?.campaign_objective
          ),
        },
      ],
    },
    {
      id: "campaign_type",
      label: "Campaign Type",
      value: "campaignType",
      errors: errors?.campaign_type?.message,
      options: [
        {
          id: 1,
          label: "Public (การสร้างแคมเปญ และประกาศรับสมัคร Influencer)",
          value: "public",
          isActive: isPackageActive("public", campManage?.campaign_type),
        },
        {
          id: 2,
          label:
            "Private (การสร้างแคมเปญ และยื่นข้อเสนอให้ Influencer เพื่อเข้าร่วมแคมเปญโดยตรง)",
          value: "private",
          isActive: isPackageActive("private", campManage?.campaign_type),
        },
      ],
    },
    {
      id: "review_type",
      label: "Review",
      value: "review",
      errors: errors?.review_type?.message,
      options: [
        {
          id: 1,
          label: "Product (การรีวิวสินค้า)",
          value: "product",
          isActive: isPackageActive("product", campManage?.review_type),
        },
        {
          id: 2,
          label: "Place (การรีวิวสถานที่ หรือสินค้านอกสถานที่)",
          value: "place",
          isActive: isPackageActive("place", campManage?.review_type),
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getEmployerInfoAsync(employerId));
  }, [dispatch, employerId]);

  useEffect(() => {
    let isMounted = true;

    function getCompanyByListsEmployer() {
      AppInformationAPI.getCompanyListByEmployer(employerId).then(
        (response) => {
          if (isMounted) {
            const campainLists = response.map((item: ICompany) => {
              return {
                id: item.id,
                label: item.name,
                value: item.name,
                employer_id: item.employer_id,
                name: item.name,
                media: {
                  ...item.media,
                },
              };
            });
            setCompanyList(campainLists);
          }
        }
      );
    }

    function getCategoryList() {
      AppInformationAPI.getCategoryList().then((response) => {
        if (isMounted) {
          const categoryLists = response.map((item: ICategory) => {
            return {
              id: item.id,
              label: item.name_en,
              value: item.value,
              name_th: item.name_th,
              name_en: item.name_en,
            };
          });
          setCategoryList(categoryLists);
        }
      });
    }

    getCompanyByListsEmployer();
    getCategoryList();

    return () => {
      isMounted = false;
    };
  }, [employerId]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    keyName: ICampaignBriefFormKey,
    _value: string
  ) => {
    const budget = {
      ...getValues("budget"),
      total: null,
      influencer_amount: null,
      each_influencer: null,
    };
    setValue(keyName, (event.target as HTMLInputElement).value);
    setValue("budget", budget);
    setValue("product_review_type", "");
  };

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WrapInputWithLabel labelWidth="200px" title="Organization" required>
            <Box sx={classes.organization}>
              <Typography ml={3} variant="body2">
                {employerInfo?.organisation.name}
              </Typography>
            </Box>
          </WrapInputWithLabel>
        </Grid>

        <Grid item xs={12}>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Organization Logo"
            required
          >
            <Avatar
              src={employerInfo?.organisation.attachment?.url}
              sx={classes.orgLogo}
            />
          </WrapInputWithLabel>
        </Grid>

        <Grid item xs={12}>
          <WrapInputWithLabel labelWidth="200px" title="Company Name" required>
            <SearchNameInputCustom
              keyName="company"
              placeholder="Company Name"
              option={companyList}
              setValue={setValue}
              employerId={employerId}
              getValues={getValues}
              error={errors?.company?.name?.message}
              clearErrors={clearErrors}
              setError={setError}
              defaultValue={watch("company") as ICompany}
            />
          </WrapInputWithLabel>
        </Grid>

        <Grid item xs={12} sx={classes.companyLogo}>
          <WrapInputWithLabel labelWidth="200px" title="Company Logo" required>
            {watch("company.id") === 0 ? (
              <ImageInput
                form={form}
                keyName="company.media"
                defaultImg={watch("company.media.url")}
                errors={errors?.company?.media?.url}
              />
            ) : (
              <Box sx={classes.companyImg}>
                <img src={watch("company.media.url")} alt="company" />
              </Box>
            )}
          </WrapInputWithLabel>
        </Grid>

        <Grid item xs={12}>
          <WrapInputWithLabel labelWidth="200px" title="Campaign Name" required>
            <TextFieldCustom
              variant="outlined"
              placeholder="Campaign Name"
              fullWidth
              value={watch("name")}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              inputProps={{
                ...register("name", {
                  required: true,
                }),
              }}
            />
          </WrapInputWithLabel>
        </Grid>

        <Grid item xs={12}>
          <WrapInputWithLabel
            title="Category"
            subTitle="เลือกได้สูงสุด 3 ประเภท"
            required
          >
            <SelectMultiCategory
              id="multi-select-category"
              keyName={"category_id" as ICampaignBriefFormKey}
              value={watch("category_id")}
              setValue={setValue}
              placeholder="Select..."
              data={categoryList}
              fullWidth
              valueKey="id"
              labelKey="name_en"
              error={!!errors?.category_id}
            />
          </WrapInputWithLabel>
        </Grid>

        {campaignOverviewRadioList.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <Typography fontSize="14px" mt={2} variant="body2">
                {item.label}
                <span className="require">*</span>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl sx={classes.campOverRadio1Item}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={watch(item.id as ICampaignBriefFormKey)}
                  onChange={(event) => {
                    handleChangeInput(
                      event,
                      item.id as ICampaignBriefFormKey,
                      item.value
                    );
                  }}
                  sx={classes.optionRadio}
                >
                  {item.options.map((optItems, optIndex) => (
                    <React.Fragment key={optIndex}>
                      <FormControlLabel
                        key={optIndex}
                        value={optItems.value}
                        control={<Radio sx={classes.optionLabelRadio} />}
                        label={optItems.label}
                        sx={{
                          "& .css-burp3r-MuiButtonBase-root-MuiRadio-root": {
                            color: item.errors ? "red" : "#8C499C",
                          },
                        }}
                        {...register(item.id as ICampaignBriefFormKey)}
                        disabled={optItems.isActive}
                      />
                      {item.errors && (
                        <Typography sx={classes.optionRadioErr}>
                          {item.errors}
                        </Typography>
                      )}
                    </React.Fragment>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </React.Fragment>
        ))}

        {getValues("review_type") === "product" ? (
          <ProductDetails form={form} />
        ) : (
          getValues("review_type") === "place" && <PlaceDetails form={form} />
        )}
      </Grid>
    </CardContent>
  );
}
