import { ISignInRes } from "@api/authentication/type";
import { decodeJWT } from "./decodeJWT";

export function setTokenToStorage(response: ISignInRes) {
  const { access_token, refresh_token, session_token } = response;
  const decoded = decodeJWT(session_token);
  const token = JSON.stringify({
    access_token,
    refresh_token,
    session_token: decoded ?? null,
  });
  localStorage.setItem("token", token);
}
