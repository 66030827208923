const tab = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  cursor: "pointer",
  zIndex: 1,
  border: "none",
  bgcolor: "initial",
  "& > p": {
    fontSize: "16px",
    color: "#231F20",
    m: "8px 15px",
  },
};

export const useInfluencerDashboardStyle = {
  tabBox: {
    display: "flex",
    justifyContent: "start",
    height: "44px",
    borderRadius: 0,
    overflow: "hidden",
    borderBottom: "2px solid #E0E0E0",
    mt:"15px",
    mb:"25px"
  },
  tab: tab,
  tabSelected: {
    ...tab,
    "& > p": {
      fontSize: "16px",
      color: "#8C499C",
      m: "8px 15px",
    },
    borderBottom: "2px solid #8C499C",
  },
};
