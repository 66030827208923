import { useState } from "react";
// mui, icons and style
import {
  Box,
  Fab,
  Grid,
  Button,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useJobPublishedLinkStyle as classes } from "../JobApproved.style";
// api and types
import { JobAPI } from "@api/global";
import { IPublishedLinkProps } from "../../../JobsTab.type";
import { IInitMyJobsJobTabForm } from "../../../JobsTab.type";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import {
  setInfluencerJobInfo,
  selectInfluencerJobInfo,
} from "@slices/InfluencerJobInfo.slice";
import { getInfluencerJobReportAsync } from "@slices/InfluencerJobReport.slice";
// others
import { useParams } from "react-router-dom";
import Loading from "@global/loading/Loading";
import TextFieldCustom from "@global/text-field";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";

export default function PublishedLink(props: IPublishedLinkProps) {
  const {
    form: { register, watch, handleSubmit },
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [copy, setCopy] = useState("Coppy");

  const { jobId } = useParams<{ jobId: string }>();

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const publishedLink = jobInfo?.published_link;
  const platform = jobInfo?.campaign_info.platform;

  const onCopyTxt = (status: string) => {
    if (status === "coppy") {
      setCopy("Coppied");
      navigator.clipboard.writeText(jobInfo?.published_link || "");
    } else {
      setCopy("Coppy");
    }
  };

  const onSubmitJob = async (value: IInitMyJobsJobTabForm) => {
    setIsLoading(true);
    const data = {
      published_link: value.published_link,
    };
    await JobAPI.updateInfluencerJob(jobId, data)
      .then((response) => {
        const params = {
          job_id: jobId,
          chart_type: "weekly",
        };
        setIsLoading(false);
        dispatch(setInfluencerJobInfo(response));
        dispatch(getInfluencerJobReportAsync(params));
      })
      .catch((error) => {
        setIsLoading(false);
        const errMsg = error.response.data.error.message;
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${
            errMsg === "error, post_id is empty"
              ? "Publish link ไม่ถูกต้อง"
              : errMsg
          }`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  if (isLoading) return <Loading />
  
  return (
    <Box sx={classes.publishedCon}>
      <Box sx={classes.title}>
        <Typography>Published Link</Typography>
        <Typography>แนบลิงค์ส่งงาน</Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0] }} />
      <Grid container p={["24px 0 24px 0", "24px"]}>
        <Grid item xs={12} sm={3} mt="7px">
          <Box sx={classes.platform}>
            <SocialIconSelected
              platform={platform}
              width="26px"
              height="26px"
            />
            <Typography>{platform}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} mt={[5, 0]}>
          {publishedLink ? (
            <Box sx={classes.publishLink}>
              <Typography
                onClick={() => window.open(publishedLink, "_blank")}
                color="primary"
              >
                {publishedLink}
              </Typography>
              <Tooltip title={copy} placement="top">
                <Fab
                  sx={classes.copyBtn}
                  onClick={() => onCopyTxt("coppy")}
                  onMouseOut={() => onCopyTxt("coppied")}
                >
                  <ContentCopyIcon color="primary" sx={{ fontSize: "16px" }} />
                </Fab>
              </Tooltip>
            </Box>
          ) : (
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter text..."
              fullWidth
              inputProps={{
                ...register(`published_link`),
              }}
            />
          )}
        </Grid>
      </Grid>
      {!publishedLink && (
        <Box sx={classes.publishBtn} mb={10}>
          <Button
            onClick={() =>
              handleSubmit((e: IInitMyJobsJobTabForm) => onSubmitJob(e))()
            }
            variant="contained"
            color="secondary"
            endIcon={<SendIcon />}
            disabled={!watch("published_link") ? true : false}
          >
            โพสต์แล้วแนบลิงค์ส่งงานเลย
          </Button>
        </Box>
      )}
    </Box>
  );
}
