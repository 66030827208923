// mui
import { Box, Divider, Grid, Typography } from "@mui/material";
// other
import TextFieldCustom from "@global/text-field";
import DateRangeInput from "@global/date-range-input";
// type and style
import { IConversion } from "@api/job/type";
import { useAddShippingStyle as classes } from "./AddShipping.style";

interface IShippinProductProps {
  item: IConversion | null;
  getValues: any;
  setValue: any;
  clearErrors: any;
  register: any;
  watch: any;
}

export default function ConversionTracking(props: IShippinProductProps) {
  const { item, setValue, clearErrors, register, watch } = props;

  const handleChangeInput = (e: any, nameChange: string) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  return (
    <Box sx={classes.boxMain}>
      <Box padding="24px">
        <Typography variant="h5">Conversion Tracking</Typography>
        <Typography variant="caption">
          การตอบสนองจากกลุ่มลูกค้าต่อ URL เป้าหมายหรือรหัสคูปอง
        </Typography>
      </Box>
      <Divider sx={{ border: "1px solid #EBDFFF" }} />
      <Box display="flex" flexDirection="column" rowGap={3} p="20px">
        <Grid container rowGap={5}>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="title2">
              Your Link<span className="require">*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldCustom
              value={watch("conversion.lead_link")}
              onChange={(e) => handleChangeInput(e, "conversion.lead_link")}
              type="text"
              placeholder="Please enter your link"
              fullWidth
            />
          </Grid>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="title2">Link for influencer</Typography>
              <Typography variant="body2">
                ลิงค์ที่แสดงให้กับอินฟลูเอนเซอร์
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body2" sx={classes.leadLink}>
              {item?.lead_link || "Link for influencer"}
            </Typography>
          </Grid>
        </Grid>
        <Typography fontWeight={600}>Coupon</Typography>
        <Grid container rowGap={5} mt={2}>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="title2" ml={3}>● Coupon Code</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldCustom
              value={watch("conversion.coupon")}
              onChange={(e) => handleChangeInput(e, "conversion.coupon")}
              type="text"
              placeholder="Enter your coupon"
              fullWidth
            />
          </Grid>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="title2" ml={3}>● Coupon Name</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldCustom
              value={watch("conversion.coupon_name")}
              onChange={(e) => handleChangeInput(e, "conversion.coupon_name")}
              type="text"
              placeholder="Enter your coupon name"
              fullWidth
            />
          </Grid>
          <Grid item display="flex" alignItems="center" xs={12} md={4}>
            <Typography variant="title2" ml={3}>● Validity Period</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <DateRangeInput
              clearErrors={clearErrors}
              watch={watch}
              register={register}
              startKeyName="conversion.start_validity_period"
              endKeyName="conversion.end_validity_period"
              setValue={setValue}
              placeholderFirst="Start date"
              placeholderSecond="End date"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="title2" ml={3}>● Description</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldCustom
              value={watch("conversion.note")}
              onChange={(e) => handleChangeInput(e, "conversion.note")}
              type="text"
              fullWidth
              multiline
              minRows={3}
              placeholder="Enter your note"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
