// mui and style
import { Box, Fab, Icon, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "./CampaignPrivate.style";
// type
import { IPrivateItemRes } from "@api/job/type";
import { ICardInfluencerPrivateOrganizingProps } from "./PrivateCampaign.type";
// route and utils
import { useHistory, useLocation } from "react-router-dom";
// redux
import {
  setCheckSelectedPrivate,
  selecteCheckSelectPrivate,
} from "@slices/CampaignPrivate.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";

export default function CardOrganizing(
  props: ICardInfluencerPrivateOrganizingProps
) {
  const { item, influencer, setInfluencer, handleClick } = props;

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const pageSelected = params.get("st");

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const dispatch = useDispatch();
  const checkSelected = useAppSelector(selecteCheckSelectPrivate);

  const handleDeleteCandidate = (id: string) => {
    const listingFilter =
      influencer &&
      influencer.listing?.filter((listing: IPrivateItemRes) => {
        return listing.id !== item.id;
      });
    const reservedFilter =
      influencer &&
      influencer.reserved?.filter((reserved: IPrivateItemRes) => {
        return reserved.id !== item.id;
      });
    setInfluencer({
      listing: listingFilter,
      reserved: reservedFilter,
    });

    dispatch(
      setCheckSelectedPrivate({
        ...checkSelected,
        item: checkSelected.item.map((item) =>
          item.influId === id ? { ...item, status: "" } : item
        ),
      })
    );
  };

  return (
    <Box sx={pageSelected === "private-organizing" ? { padding: "24px" } : {}}>
      <Box
        sx={{
          ...classes.boxSelected,
          backgroundColor:
            item.status === "offering"
              ? "#F4ECFF"
              : item.status === "canceled"
              ? "#E0E0E0"
              : "#fff",
        }}
      >
        {pageSelected === "private-organizing" ||
        history.location.pathname.includes("select-more-influencer") ? (
          <Fab
            color="primary"
            aria-label="clearInfluencer"
            sx={classes.fabOrganizing}
            onClick={() => {
              if (pageSelected === "private-organizing") {
                handleDeleteCandidate(item.influencer_id);
              } else {
                handleClick(item.influencer_id);
              }
            }}
          >
            <Icon className="ico-hu-close" sx={{ fontSize: "16px" }} />
          </Fab>
        ) : null}

        <img src={item.profile_image_url} alt="private-listing-dnd" />
        <Box
          onClick={
            pageSelected === "private-organizing"
              ? () => {}
              : item.status !== ""
              ? () => {
                  history.push(
                    `/influencers/${item.influencer_id}/profile?platform=${item.platform}`
                  );
                }
              : () => {}
          }
          sx={{ cursor: "pointer" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{ padding: "0 12px" }}
          >
            <Typography sx={classes.textEllipsis}>{item.name}</Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon className="ico-hu-group" sx={classes.icon} />
            <Typography>{numberFormat.format(item.follower)}</Typography>
          </Box>

          <Box display="flex" justifyContent="center">
            <Typography variant="title2" color="#893DBD">
              {Intl.NumberFormat("en").format(Math.round(item.rate_card))} THB
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
