import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// mui and style
import { Box } from "@mui/material";
import { useVertifyEmail as classes } from "./vertifyEmail.style";
// api and components
import { AuthenticationAPI } from "@api/global";
import LoadingVerified from "@components/vertify-email/LoadingVerified";
import VerifyEmailFailed from "@components/vertify-email/VerifyEmailFailed";
import VerifyEmailSuccess from "@components/vertify-email/VerifyEmailSuccess";

export default function VerifiedEmail() {
  let { email }: any = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [verifiedSuccess, setVerifiedSuccess] = useState<boolean>(true);

  useEffect(() => {
    const verifiedEmail = async () => {
      const body = {
        email: email,
        grant_type: "verify",
      };

      try {
        const response = await AuthenticationAPI.verifyEmail(body);
        setIsLoading(false);
        setVerifiedSuccess(true);
        return response;
      } catch (error) {
        setIsLoading(false);
        setVerifiedSuccess(false);
        console.log("error", error);
      }
    };
    verifiedEmail();
  }, [email]);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.backgroundLiner}></Box>
      <Box sx={classes.dialog}>
        {isLoading ? (
          <LoadingVerified />
        ) : verifiedSuccess ? (
          <VerifyEmailSuccess />
        ) : (
          <VerifyEmailFailed />
        )}
      </Box>
    </Box>
  );
}
