// mui and icon
import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// type and style
import { useChargeSuccessStyle as classes } from "../CampaignCharge.style";
import { IChargeSuccessProps } from "@views/campaign/campaign-charge/Charge.type";
// other
import { useHistory, useLocation } from "react-router-dom";
import { depositInfo, percentPrice } from "@utils/helpers/payment";

export default function ChargeSuccess(props: IChargeSuccessProps) {
  const { open, onClose, campaignPrice } = props;

  const history = useHistory();
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const employerId = params.get("emp_id") || ""
  const campaignId = params.get("c_id") || ""

  const numFormater = Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onCloseDialog = () => {
    onClose(false);
    history.push(`/campaigns/${campaignId}/info?emp_id=${employerId}`);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.titleBox}>
          <CheckCircleIcon sx={classes.iconSuccess} />
          <Typography variant="h5">ทำรายการสำเร็จ</Typography>
        </Box>
        <Box sx={classes.detailBoxList}>
          {[
            { label: "Transaction Date", value: "01/05/2023 10:30 AM." },
            { label: "Transaction No.", value: "123456789123" },
            { label: "ช่องทางการชำระเงิน", value: "QRpayment" },
            {
              label: "Top Up",
              value: "8,000 THB",
              subValue: "Total balance 40,000 THB",
            },
          ].map((li, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography variant="title1">{li.label}</Typography>
              <Box>
                <Typography
                  textAlign="end"
                  color={li.subValue ? "primary" : undefined}
                  fontWeight={li.subValue ? 500 : undefined}
                  mb={li.subValue ? -1 : 0}
                >
                  {li.value}
                </Typography>
                <Typography variant="caption">{li.subValue}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Divider sx={{ my: "24px" }} />
        <Box component="ul" sx={classes.detailBoxList}>
          {[
            {
              label: "Campaign Budget",
              value: `${numFormater.format(campaignPrice)} THB`,
            },
            {
              label: `หักมัดจำ ${depositInfo(campaignPrice)}%`,
              value: `${percentPrice("deposit", campaignPrice)} THB`,
              component: "li",
            },
            {
              label: "ยอดสำรองจ่าย",
              value: `${percentPrice("amount", campaignPrice)} THB`,
              component: "li",
            },
          ].map((li, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography
                variant="title1"
                component={li.component as React.ElementType<any>}
                ml={li.component ? 0 : -10}
              >
                {li.label}
              </Typography>
              <Typography textAlign="end" mb={-1}>
                {li.value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Divider sx={{ mt: "24px" }} />
      </DialogContent>
      <DialogActions sx={classes.dialogAction}>
        <Typography mb="20px">แคมเปญได้ประกาศแล้ว</Typography>
        <Button variant="contained" onClick={onCloseDialog}>
          Campaign Details
        </Button>
      </DialogActions>
    </Dialog>
  );
}
