import { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  Grid,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  InputAdornment,
} from "@mui/material";
// theme and style
import { hAlertColor } from "@theme/index";
import { useCreateAccountDialogStyle as classes } from "./authentication.style";
// api and type
import { AppInformationAPI, EmployerAPI } from "@api/global";
import { ICategory } from "@api/app-information/type";
import { ICreateAccountDialogProps } from "./AuthenDialog.types";
import { ICreateEmployerFormSchema } from "../employer/EmployerList.type";
// others
import Loading from "@global/loading/Loading";
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import DialogAuthentication from "@global/dialog-authentication";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
// react-hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICreateEmployerForm } from "../employer/create-employer/CreateEmployer.type";
import { CreateEmployerFormSchema } from "../employer/create-employer/CreateEmployer.schema";
// Data
import {
  initImage,
  optionType,
  initEmployerForm,
  optionBusinessSize,
} from "../employer/create-employer/EmployerData";
// route and component
import { useHistory } from "react-router-dom";
import SelectMultiCategory from "@components/campaign/create-campaign/campaign-brief/brief-default/SelectMultiCategory";

export default function CreateEmployerDialog(props: ICreateAccountDialogProps) {
  const { open, setOpen } = props;

  const [optionCategory, setOptionCategory] = useState<ICategory[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const {
    formState: { errors },
    watch,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<ICreateEmployerForm>({
    resolver: yupResolver(CreateEmployerFormSchema),
    defaultValues: initEmployerForm,
  });

  useEffect(() => {
    async function getResult() {
      const results = await AppInformationAPI.getCategoryList();
      const items = results.map((cat: ICategory) => {
        return {
          id: cat.id,
          label: cat.name_th,
          value: cat.value,
          name_en: cat.name_en,
          name_th: cat.name_th,
        };
      });
      setOptionCategory(items);
    }
    getResult();
  }, []);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeInput = (
    e: any,
    nameChange: keyof ICreateEmployerFormSchema
  ) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  const onSubmitCreateEmployer = async (data: ICreateEmployerForm) => {
    setIsLoading(true);

    try {
      const reqBody = {
        created_by: "self",
        fullname: data.fullname,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        phone: data.phone,
        line_id: data.line_id,
        organisation: {
          id: data.id,
          name: data.name,
          size: data.size,
          type: data.type,
          attachment: initImage,
          category: data.category,
        },
      };
      const response = await EmployerAPI.createEmployer(reqBody);
      handleCloseDialog();
      SwalCustom.fire({
        icon: "success",
        title: "Successfully",
        text: `สมัครสมาชิกสำเร็จ`,
        confirmButtonColor: hAlertColor.success,
        confirmButtonText: "ตกลง",
      }).then(() => {
        const email = response.email;
        history.push(`/verify-email/${email}`);
      });
    } catch (error: any) {
      const data = error.response.data;
      switch (data.error.message) {
        case "error, can't insert a category":
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, Please insert a category !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
          break;
        case "error, this email is already taken":
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, Email is already taken !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />

  return (
    <DialogAuthentication
      title="Create an Account"
      open={open}
      onClose={handleCloseDialog}
      sx={classes.createAccountDialog}
    >
      <form onSubmit={handleSubmit(onSubmitCreateEmployer)}>
        <Box sx={classes.firstDialogContent}>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Full Name :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("fullname")}
                  onChange={(e) => handleChangeInput(e, "fullname")}
                  type="text"
                  placeholder="Full Name"
                  fullWidth
                  error={!!errors.fullname}
                  helperText={errors.fullname?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Organization :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("name")}
                  onChange={(e) => handleChangeInput(e, "name")}
                  type="text"
                  placeholder="Organization Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Size :
                  </Typography>
                </Box>
                <BasicSelectCustom
                  id="business-size-select"
                  value={getValues("size")}
                  onChange={(e) => handleChangeInput(e, "size")}
                  data={optionBusinessSize}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Select"
                  fullWidth
                  error={!!errors.size}
                  helperText={errors.size?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Type :
                  </Typography>
                </Box>
                <BasicSelectCustom
                  id="type-select"
                  value={getValues("type")}
                  onChange={(e) => handleChangeInput(e, "type")}
                  data={optionType}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Select"
                  fullWidth
                  error={!!errors.type}
                  helperText={errors.type?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Category :
                  </Typography>
                </Box>
                <SelectMultiCategory
                  id="multi-select-category"
                  keyName={"category" as any}
                  value={watch("category")}
                  setValue={setValue as any}
                  placeholder="Select"
                  data={optionCategory}
                  fullWidth
                  valueKey="id"
                  labelKey="name_en"
                  error={!!errors.category}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Phone :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={formatPhoneNumber(getValues("phone"))}
                  onChange={(e) => handleChangeInput(e, "phone")}
                  type="text"
                  placeholder="Phone"
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={classes.secondDialogContent}>
          <Typography variant="body1" fontWeight="bold" mb={4} color="#8C499C">
            Email for Sign in
          </Typography>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Email :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("email")}
                  onChange={(e) => handleChangeInput(e, "email")}
                  variant="outlined"
                  placeholder="Email"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className="ico-hu-mail" />
                      </InputAdornment>
                    ),
                  }}
                  sx={!errors.email ? classes.textFieldGrey : undefined}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Password :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("password")}
                  onChange={(e) => handleChangeInput(e, "password")}
                  type={!!showPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder="Password"
                  fullWidth
                  sx={!errors.password ? classes.textFieldGrey : undefined}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!!showPassword ? (
                            <Icon className="ico-hu-visability-off" />
                          ) : (
                            <Icon className="ico-hu-visability" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Confirm Password :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("confirmPassword")}
                  onChange={(e) => handleChangeInput(e, "confirmPassword")}
                  type={!!showPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder="Confirm Password"
                  fullWidth
                  sx={
                    !errors.confirmPassword ? classes.textFieldGrey : undefined
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!!showPassword ? (
                            <Icon className="ico-hu-visability-off" />
                          ) : (
                            <Icon className="ico-hu-visability" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <DialogContent sx={classes.thirdDialogContent}></DialogContent>

        <Divider sx={classes.divider} />

        <DialogActions sx={classes.dialogAction}>
          <Button type="submit" sx={classes.registorButton}>
            <Typography variant="body1" fontWeight="bold">
              Register
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </DialogAuthentication>
  );
}
