// react-route
import RouteList from "./RouteList";
import { withRouter, useHistory } from "react-router-dom";
// global
import Sidebar from "@components/sidebar";
import Tutorial from "@components/tutorial/Tutorial";
import HeaderBar from "@components/header-bar/HeaderBar";
import HomeFooter from "@components/home-footer/HomeFooter";
import SnackBar from "@components/global/snackbar/SnackBar";
import BackToTop from "@components/global/back-to-top/BackToTop";
import HomeHeaderBar from "@components/home-header-bar/HomeHeaderBar";
// redux
import {
  campDetailSnackbar,
  setSnackBarIsActive,
  campDetailSnackbarIsActive,
} from "@slices/CampaignInfo.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";
// util
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

function Lists() {
  const history = useHistory();
  const currentPath = history.location.pathname;
  
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;
  
  const dispatch = useDispatch();
  const snackbar = useAppSelector(campDetailSnackbar);
  const snackbarIsActive = useAppSelector(campDetailSnackbarIsActive);

  const isHomePath = (path: string): boolean => {
    if (path === "/") return true;
    const homePaths = [
      "/home/influencer",
      "/home/news-and-events",
      "/home/marketer",
      "/home/pricing",
      "/home/support/tutorials",
      "/home/support",
      "/home/contact",
    ];

    return homePaths.some((homePath) => path.startsWith(homePath));
  };

  const isPrivacyPath = (path: string): boolean => {
    const privacyPaths = ["/privacy", "/terms-and-conditions"];
    return privacyPaths.includes(path);
  };

  const onCloseSnackBar = (value: boolean) => {
    dispatch(setSnackBarIsActive(value));
  };

  return (
    <>
      {isHomePath(currentPath) && <HomeHeaderBar />}
      {!isHomePath(currentPath) && !isPrivacyPath(currentPath) && (
        <>
          <HeaderBar />
          {role === "influencer" && <Tutorial />}
          <SnackBar
            open={snackbarIsActive}
            onClose={onCloseSnackBar}
            data={snackbar}
          />
          <Sidebar />
        </>
      )}
      <main>
        <RouteList />
      </main>
      {(isHomePath(currentPath) || isPrivacyPath(currentPath)) && (
        <>
          <HomeFooter />
          <BackToTop />
        </>
      )}
    </>
  );
}

const WithRouter = withRouter(Lists);

function Routes() {
  return <WithRouter />;
}

export default Routes;
