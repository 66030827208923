// mui and icon
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
// style
import { useCancelCampaignSuccessDialogStyle as classes } from "../CancelCampaign.style";

export default function CancelCampaignSuccessDialog(props: {
  open: boolean;
  onClose: (val: boolean) => void;
}) {
  const { open, onClose } = props;
  
  return (
    <Dialog open={open} sx={classes.dialog}>
      <DialogContent>
        <Box sx={classes.contentBox}>
          <Box sx={classes.iconSuccess}>
            <DoneRoundedIcon color="success" sx={{ fontSize: "90px" }} />
          </Box>
          <Box m="25px 0 15px 0" textAlign="center">
            <Typography variant="h5">
              แคมเปญนี้ได้ถูกยกเลิกเรียบร้อยแล้ว
            </Typography>
            <Typography>
              ระบบจะดำเนินการคืนเงินเข้า Wallet ของคุณภายใน 24 ชั่วโมง
            </Typography>
          </Box>
          <Box sx={classes.btn}>
            <Button fullWidth color="success" onClick={() => onClose(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
