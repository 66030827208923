import React, { useState } from "react";
// mui
import {
  Box,
  Icon,
  Button,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
// icon and style
import EditIcon from "@mui/icons-material/Edit";
import { useCurrentPlanSummaryStyle as classes } from "../Subscription.style";
// api and component
import { EmployerAPI, TeamAPI } from "@api/global";
import RenewDialog from "../subscription-dialog/Renew";
import Loading from "@components/global/loading/Loading";
import EditBilling from "@global/edit-billing/EditBilling";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
// redux
import {
  setEmployerInfo,
  selectEmployerInfo,
} from "@slices/EmployerInfo.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";
import { selectWalletBalance } from "@slices/PackageInfo.slice";
// others
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export default function PackagePlanSummary() {
  const [isLoading, setIsLoading] = useState(false)
  const [openBilling, setOpenBilling] = useState(false);
  const [openEditBilling, setOpenEditBilling] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const walletBalance = useAppSelector(selectWalletBalance);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const isTeamPlan = employerInfo?.package_plan === "team";
  // package
  const teamPackage = employerInfo?.team?.package;
  const personalPackage = employerInfo?.package;
  const packages = isTeamPlan ? teamPackage : personalPackage;
  // billing
  const teamBilling = employerInfo?.team?.billing_address;
  const personalBilling = employerInfo?.address.billing_address;
  const billing = isTeamPlan ? teamBilling : personalBilling;

  const numberFormat = Intl.NumberFormat("en", {
    maximumFractionDigits: 3,
  });

  const onRenewTeamPackage = (isRenew: boolean) => {
    const data = {
      team_id: employerInfo?.team?.id || "",
      is_renew: isRenew,
    };
    TeamAPI.renewTeamPackage(data).then((response) => {
      dispatch(setEmployerInfo(response));
    });
  };

  const handleRenewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const check = e.target.checked;
    if (!check) {
      setOpenBilling(true);
    } else {
      onRenewTeamPackage(check);
    }
  };

  const onUnRenewTeamPackage = (isRenew: boolean) => {
    onRenewTeamPackage(isRenew);
    setOpenBilling(false);
  };

  const goTopUp = () => {
    if (isTeamPlan) {
      history.push(`/payment/top-up/team?t_id=${employerInfo.team?.id || ""}`, { redirect: "/subscription" })
    } else {
      history.push(`/payment/top-up/personal?emp_id=${employerInfo?.employer_full_id || ""}`, { redirect: "/subscription" })
    }
  }

  const onEditBilling = (body: any) => {
    setOpenEditBilling(false)
    setIsLoading(true)
  
    const bodyForPersonal = {
      address: {
        billing_address: {
          fullname: body.fullname,
          tax_id: body.tax_id,
          address: body.address,
          province_id: body.province,
          district_id: body.district,
          subdistrict_id: body.subdistrict,
        },
      },
    };

    const api = isTeamPlan ? TeamAPI.updateTeamBilling(employerInfo?.team?.id || "", body) : EmployerAPI.updateEmployerInfo(employerInfo?.employer_full_id || "", bodyForPersonal)

    api.then((response) => {
      dispatch(setEmployerInfo(response))
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
      SwalCustom.fire({
        icon: "error",
        title: "Oops !",
        text: "There are someting wrong",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
      }).then((response) => {
        if (response.isDismissed) {
          setOpenEditBilling(true)
        }
      });
    })
  };

  if (isLoading) return <Loading />

  return (
    <Box sx={classes.container}>
      <Box sx={classes.conentWrap}>
        <Box sx={classes.currentTitle}>
          <Typography variant="h6">แผนปัจจุบัน</Typography>
          <Typography variant="title2">Active</Typography>
        </Box>
        <Box sx={classes.firstCurrentWrap}>
          <Box sx={classes.firstCurrItem1}>
            <div>
              <Icon className="ico-hu-person" fontSize="small" />
              <Typography variant="title1" ml="5px" textTransform="capitalize">
                {packages?.title}
              </Typography>
            </div>
            <Typography variant="h4" mt="10px">
              {numberFormat.format(
                (packages?.fees[isTeamPlan ? "price_team" : "price"] || 0) *
                  (packages?.plan === "yearly" ? 10 : 1)
              )}
            </Typography>
            <Typography variant="subTitle2">
              บาท/{packages?.plan === "yearly" ? "ปี" : "เดือน"}
            </Typography>
          </Box>
          <Box sx={classes.firstCurrItem2}>
            <div>
              <Typography variant="subTitle1">สมาชิก</Typography>
              <Typography variant="h6">
                {`${packages?.campaign_management.member}/${packages?.campaign_management.member_limit}`}
              </Typography>
            </div>
            <div>
              <Typography variant="subTitle1">แคมเปญ</Typography>
              <Typography variant="h6">
                {`${packages?.campaign_management.campaign}/${packages?.campaign_management.campaign_limit_per_month}`}
              </Typography>
            </div>
          </Box>
        </Box>
        <Box sx={classes.secondCurrentWrap}>
          {[
            {
              label: "My Wallet",
              value: `${Intl.NumberFormat('en-US').format(walletBalance)} บาท`,
            },
            {
              label: "รูปแบบการชำระเงิน",
              value: `ราย${packages?.plan === "yearly" ? "ปี" : "เดือน"}`,
            },
            { label: "วิธีการชำระเงิน", value: "HashU Wallet" },
          ].map((item, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography variant="title1">{item.label}</Typography>
              <Typography>{item.value}</Typography>
            </Box>
          ))}
        </Box>
        <Box sx={classes.thirdCurrentWrap}>
          <Checkbox checked={packages?.renew} onChange={handleRenewChange} />
          <Typography>ระบบต่ออายุรอบบิลโดยอัตโนมัติ</Typography>
        </Box>
        <Box sx={classes.forthCurrentWrap}>
          <div>
            <Typography variant="title1">ระยะเวลาของแผน</Typography>
            <Typography>
              {!packages?.start_date || !packages.end_date ? "-" : `${dayjs(packages.start_date).format("DD/MM/YYYY")} - ${dayjs(packages.end_date).format("DD/MM/YYYY")}`}
            </Typography>
          </div>
          <div>
            <Typography variant="title1">ชำระเงินครั้งถัดไป</Typography>
            <Typography>
              {packages?.expires_at ? dayjs(packages?.expires_at).format("DD/MM/YYYY") : "-"}
            </Typography>
          </div>
          <Typography variant="body2">
            ยอดเงินใน Wallet ไม่เพียงพอสำหรับการตัดรอบในครั้งถัดไป
            โปรดเติมเงินเข้า Wallet เพื่อการใช้งานอย่างต่อเนื่อง
          </Typography>
        </Box>
        <Box sx={classes.fifthCurrentWrap}>
          <Button onClick={goTopUp}>เติมเงิน</Button>
          <Button
            onClick={() => history.push(`/subscription/replan/${employerInfo?.package_plan}`)}
            variant="contained"
            fullWidth
          >
            เปลี่ยนแผน
          </Button>
          {!packages?.renew && packages?.status === "pending" && (
            <Button variant="contained" fullWidth>ต่ออายุรอบบิล</Button>
          )}
        </Box>
      </Box>
      <Box sx={classes.conentWrap}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">ข้อมูลใบเสร็จ</Typography>
          <IconButton onClick={() => setOpenEditBilling(true)}>
            <EditIcon color="primary" />
          </IconButton>
        </Box>
        <Box mt="16px">
          <Typography variant="title1">
            {employerInfo?.organisation?.name}
          </Typography>
        </Box>
        <Typography my="8px">{billing?.tax_id}</Typography>
        <Typography>
          {`${billing?.address || ""} ${billing?.subdistrict?.name_th || ""} ${
            billing?.district?.name_th || ""
          } ${billing?.province?.name_th || ""} ${billing?.postcode || ""}`}
        </Typography>
      </Box>

      {/* dialog */}
      <RenewDialog
        open={openBilling}
        onClose={setOpenBilling}
        handleSubmit={onUnRenewTeamPackage}
        nextPayment={packages?.expires_at}
      />
      <EditBilling
        open={openEditBilling}
        onClose={() => setOpenEditBilling(false)}
        onEditBilling={onEditBilling}
        billing={billing}
      />
    </Box>
  );
}
