// mui and icon
import { Box, Button, Grid, Icon, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// style and route
import { usePlaceDetailStyle as classes } from "./PlaceDetail.style";
import { useHistory } from "react-router-dom";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";

export default function PlaceDetail() {
  const history = useHistory();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const place = jobInfo?.campaign_info.location;
  const clearPhone = place?.phone.replace(/\D/g, "");
  const formatPhone = clearPhone?.replace(/(\d{3})(\d{4})(\d{3})/, "$1-$2-$3");

  return (
    <div className="container-main">
      <Box display="flex" alignItems="center" mx={3}>
        <ArrowBackIosIcon
          onClick={() => history.goBack()}
          sx={{ fontSize: "20px" }}
        />
        <Typography variant="h6" width="100%" textAlign="center">
          Location
        </Typography>
      </Box>
      <Box sx={classes.card}>
        <Box sx={{ "& img": { width: "100%", borderRadius: "6px" } }}>
          <img src={place?.static_map_url} alt="static map" />
        </Box>
        <Box>
          <Typography variant="h5" mt="15px">
            {place?.name}
          </Typography>
          <Box display="flex" mt="14px">
            <Icon className="ico-hu-location" color="primary" />
            <Box ml={5}>
              <Typography variant="title1" color="primary">
                Address
              </Typography>
              <Typography mt={1}>{place?.address}</Typography>
            </Box>
          </Box>
          <Box display="flex" mt="14px">
            <Icon className="ico-hu-phone" color="primary" />
            <Box ml={5}>
              <Box display="flex" flexDirection="column">
                <Typography variant="title1" color="primary">
                  Contact
                </Typography>
                <Typography variant="subTitle2" mt={1}>
                  โปรดทำการติดต่อเจ้าหน้าที่เพื่อทำการนัดหมาย
                  ก่อนเดินทางไปยังสถานที่จริง
                </Typography>
              </Box>
              <Box component="ul" display="flex" gap={[10, 5]}>
                <Box>
                  {[
                    { label: "Contact Name", txt: place?.contact },
                    { label: "Phone", txt: formatPhone },
                  ].map((contact) => (
                    <Grid
                      container
                      key={contact.label}
                      display="flex"
                      ml={[-5, 0]}
                      mb={2}
                    >
                      <Grid item xs={12} sm={6}>
                        <Typography component="li">{contact.label}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="title1">{contact.txt}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack
          gap={3}
          sx={classes.btn}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Button
            variant="outlined"
            startIcon={<Icon className="ico-hu-location" />}
            onClick={() => window.open(place?.url, "_blank")}
          >
            เส้นทาง
          </Button>
          <a href={`tel:+66${place?.phone}`}>
            <Button
              variant="contained"
              startIcon={<Icon className="ico-hu-phone" />}
              fullWidth
            >
              ทำการนัดหมาย
            </Button>
          </a>
        </Stack>
      </Box>
    </div>
  );
}
