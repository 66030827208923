export const useJobBoardDetailStyle = {
  jobboardCon: {
    objectFit: "cover",
    m: {
      xs: "-30px -10px -55px -20px",
      sm: "-30px -28px -55px -25px",
      md: "-20px 0 0 -20px",
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: ["240px", "240px", "100%"],
  },
  callback: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: {
      xs: "10%",
      md: "4%",
    },
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
    "& > span": {
      display: {
        xs: "none",
        md: "initial",
      },
    },
    "& > svg": {
      m: {
        xs: "13px 0 0 17px",
        md: "13px 0 13px 13px",
      },
      bgcolor: {
        xs: "#fff",
        md: "initial",
      },
      borderRadius: "50%",
      fontSize: {
        xs: "30px",
        md: "16px",
      },
    },
  },
  campDetailCon: {
    mt: "20px",
    bgcolor: { xs: "inherit", lg: "#fff" },
    boxShadow: { xs: "inherit", lg: "0px 2px 8px rgba(0, 0, 0, 0.1)" },
    padding: { xs: "0px", lg: "24px" },
    borderRadius: "16px",
  },
  gridItemForSm: {
    height: {
      xs: "100%",
      md: "initial",
    },
    display: { xs: "initial", lg: "none" },
  },
  campDetailCard: {
    border: { xs: "inherit", lg: "2px solid #EBDFFF" },
    borderRadius: "12px",
    padding: "24px",
    bgcolor: { xs: "#fff", lg: "inherit" },
    boxShadow: { xs: "0px 2px 8px rgba(0, 0, 0, 0.1)", lg: "inherit" },
  },
  divider: {
    my: "24px",
    mx: {
      xs: 0,
      lg: "-24px",
    },
    bgcolor: "#EBDFFF",
  },
  submitButtonForInflu: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    mt: "15px",
    mb: "8px",
    "& button": {
      border: "none",
      fontSize: "18px",
      maxHeight: "48px",
      width: {
        xs: "100%",
        sm: "262px",
      },
      "&:hover": {
        border: "none",
      },
    },
  },
  conditionsPublicBtn: {
    justifyContent: "center",
    "& button": {
      fontSize: "18px",
      maxHeight: "48px",
      width: "262px",
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
  },
  timelineDetailComp: {
    display: { xs: "none", lg: "inherit" },
    justifyContent: "center",
  },
  budgetAndTimeline: {
    height: {
      xs: "100%",
      md: "91.5%",
      lg: "88%",
    },
    border: { xs: "inherit", lg: "2px solid #EBDFFF" },
    borderRadius: "12px",
    padding: "24px",
    bgcolor: { xs: "#fff", lg: "inherit" },
    boxShadow: { xs: "0px 2px 8px rgba(0, 0, 0, 0.1)", lg: "inherit" },
    flexDirection: "column",
  },
};
