// mui and style
import { Box, Grid, Typography, Icon } from "@mui/material";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
import { useEditPortfolioDialogStyle as classes } from "../InfluencerProfile.style";
// type and other
import { IUpdatePortfolioProps } from "../InfluencerProfile.type";
import TextFieldCustom from "@components/global/text-field";

export default function EditInfluencerPortfolio(props: IUpdatePortfolioProps) {
  const { form, onDeletePortfolio, onSubmit } = props;
  const {
    watch,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = form;

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      my="20px"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Icon className="ico-hu-attachment" color="primary" />
        <Typography variant="body1" fontWeight="bold">
          My Portfolio
        </Typography>
      </Box>
      <Box px={2} mt={4}>
        {/* Title */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ชื่อ Portfolio<span className="require">*</span>:
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter title..."
              fullWidth
              inputProps={{
                ...register("title", {
                  required: true,
                }),
              }}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
          </Grid>
        </Grid>
        {/* Url */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ลิงค์ Portfolio<span className="require">*</span>:
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter Url..."
              fullWidth
              inputProps={{
                ...register("url", {
                  required: true,
                }),
              }}
              error={!!errors.url}
              helperText={errors.url?.message}
            />
          </Grid>
        </Grid>
        {/* Description */}
        <Grid container pb={5}>
          <Grid item xs={12} md={3} mt={[0, 0, 2]}>
            <Typography variant="title2">คำอธิบาย:</Typography>
          </Grid>
          <Grid item xs={12} md={9} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Please enter Description..."
              fullWidth
              multiline
              rows={3}
              inputProps={{
                maxLength: "60",
                ...register("description", {
                  required: true,
                }),
              }}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
            <Typography display="flex" justifyContent="end">
              {`${(watch("description") || []).length}/60`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <SaveButton
          type="submit"
          variant="contained"
          startIcon={<Icon className="ico-hu-save" />}
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
        {getValues("id") && (
          <Typography
            sx={classes.saveTitle}
            color="primary"
            mt={5}
            onClick={() => onDeletePortfolio(getValues("id"))}
          >
            Delete Portfolio
          </Typography>
        )}
      </Box>
    </Box>
  );
}
