// icons
import { YouTube } from "@mui/icons-material";
import { Icon } from "@mui/material";

export const emphasisOrProhibitionList = [
  {
    id: "emphasis",
    label: "Do or Emphasis",
    subLabel: "ตัวอย่าง “สิ่งที่ต้องทำ”",
    textItems: [
      "- ถ่ายสินค้าให้สวยงาม และเห็นชื่อแบรนด์อย่างชัดเจน\n",
      "- ถ่ายรูปตัวเองกับสินค้าหรือบริการ\n",
      "- ห้ามคัดลอกบทความของบุคคลอื่นมาเป็นของตัวเอง\n",
      "- อัพโหลดรูปภาพประกอบการรีวิว 4-5 รูป\n",
      "- บอกปัญหาที่พบก่อนการใช้สินค้าหรือบริการ\n",
      "- บอกความประทับใจขณะทดลองใช้งานสินค้าและบริการ\n",
      "- แนะนำข้อมูล จุดเด่น และช่องทางการจัดจำหน่าย\n",
    ],
  },
  {
    id: "prohibition",
    label: "Don't or Prohibition",
    subLabel: "ตัวอย่าง “สิ่งที่ห้ามทำ”",
    textItems: [
      "- ไม่แสดงพฤติกรรมและทัศนคติที่ไม่เหมาะสมต่อบริบทของสังคม เช่น การใช้คำหยาบ การใช้เนื้อหาที่รุนแรง ลามกอนาจาร ดูหมิ่นและสร้างความเสียหายให้ผู้อื่น\n",
      "- ไม่พูดเชิงลบหรือเปรียบเทียบสินค้าและบริการ กับบริษัทคู่แข่งทางการค้า\n",
      "- ห้ามคัดลอกบทความของบุคคลอื่นมาเป็นของตัวเอง\n",
      "- ไม่ทำการบิดเบือนข้อมูลหรือการพูดเกินความจริง อันเป็นเหตุให้บริษัทเกิดความเสียหาย\n",
    ],
  },
];

export const plateformList = [
  {
    id: 1,
    value: "facebook",
    label: "Facebook",
    icon: (
      <Icon
        className="ico-hu-facebook"
        sx={{ color: "#505050", fontSize: "20px" }}
      />
    ),
  },
  {
    id: 2,
    value: "instagram",
    label: "Instagram",
    icon: (
      <Icon
        className="ico-hu-instagram"
        sx={{ color: "#505050", fontSize: "20px" }}
      />
    ),
  },
  {
    id: 3,
    value: "youtube",
    label: "Youtube",
    icon: <YouTube sx={{ mr: "5px", color: "#505050" }} />,
  },
  {
    id: 4,
    value: "twitter",
    label: "Twitter",
    icon: (
      <Icon
        className="ico-hu-twitter"
        sx={{ color: "#505050", fontSize: "20px" }}
      />
    ),
  },
  {
    id: 5,
    value: "tiktok",
    label: "TikTok",
    icon: (
      <Icon
        className="ico-hu-tiktok"
        sx={{ color: "#505050", fontSize: "20px" }}
      />
    ),
  },
];

export const descriptionAndMoodToneList = [
  {
    id: "content.description",
    title: "Description",
  },
  {
    id: "mood_and_tone.description",
    title: "Mood and Tone",
  },
];

export const genderList = [
  { id: 1, label: "ทั้งหมด", value: "all" },
  { id: 2, label: "ชาย", value: "man" },
  { id: 3, label: "หญิง", value: "women" },
  { id: 4, label: "LGBTQ+", value: "LGBTQ+" },
];

export const specifyingProductList = [
  "คูปองรับประทานอาหาร สำหรับ 2 ท่าน มูลค่า 800 บาท",
  "เสื้อยืด ตรา HashU รุ่น A284 Size S มูลค่า 400 บาท",
  "ห้องพักประเภท วิวทะเล จำนวน 1 คืน (เข้าพักได้ 2 ท่าน)",
  "Gift Voucher Central มูบค่า 500 บาท",
];

export const termAndConditionList = [
  "ให้สิทธิ์บริษัทในการทำซ้ำ ดัดแปลง แก้ไข หรือประชาสัมพันธ์บทความการรีวิวของคุณ ผ่านช่องทางออนไลน์ต่างๆของบริษัทได้โดยคุณไม่สามารถเรียกร้องค่าตอบแทนเพิ่มเติมได้",
  "เปิดบัญชีสาธารณะ และไม่ทำการลบโพสต์ จนกว่าจะครบกำหนดเวลาหากทำการลบโพสต์ก่อน เวลาที่กำหนดคุณจะไม่ได้รับค่าตอบแทนและบริษัทสามารถเรียกร้องค่าเสียหายจากคุณได้",
  "โปรดอ่านและทำความเข้าใจ รายละเอียด รวมถึงสิ่งที่ต้องทำและข้อห้ามในการรีวิวสินค้าหรือ บริการก่อนยอมรับข้อตกลงและเงื่อนไขในการรีวิว",
];

export const timelineSubmitDraftList = [
  {
    label: "ประเภทการรีวิว",
    text: [
      "“สินค้า” หากผู้จ้างงานต้องส่งสินค้าให้กับอินฟลูเอนเซอร์ ระยะเวลาในการจัดส่ง คือ 1-3 วัน ขึ้นอยู่กับระยะทาง และบริษัทขนส่ง",
      "“สถานที่” อินฟลูเอนเซอร์ติดต่อผู้จ้างงานเพื่อทำการนัดหมาย",
    ],
  },
  {
    label: "ระยะเวลาในการตรวจงาน",
    text: [
      "อินฟลูเอนเซอร์ส่ง Draft หรือตัวอย่างในการรีวิว ให้กับผู้จ้างงานตรวจ",
      "หากผู้จ้างงานต้องการให้อินฟลูเอนเซอร์แก้ไข สามารถให้อินฟลูเอนเซอร์แก้ไขงานได้ “2 ครั้ง” เท่านั้น",
      "การอนุมัติ Draft ครั้งสุดท้าย คือ ก่อนวันสุดท้ายที่ต้องโพสต์แคมเปญ “1 วัน”",
    ],
  },
  {
    label:
      "หากไม่มีการจัดส่งสินค้า หรือ Draft ผ่านก่อนวันแรกที่เริ่มโพสต์แคมเปญ อินฟลูเอนเซอร์สามารถโพสต์งานเลยได้ไหม?",
    text: ["ตอบ ได้ โดยผู้จ้างงานคือผู้กำหนดวันให้อินฟลูเอนเซอร์โพสต์งาน"],
  },
];

export const productDescriptionList = {
  firstDesc: [
    "ยี่ห้อ",
    "รุ่น/สูตร/รส/สี",
    "บรรจุภัณฑ์",
    "ขนาด/น้ำหนัก/ปริมาณสุทธิ",
  ],
  secondDesc: ["คำเตือน หรือ ข้อควรระวัง", "มูลค่าสินค้า", "สถานที่จัดจำหน่าย"],
};

export const reviewProductList = [
  {
    id: 1,
    value: "send",
    label: "การส่งสินค้าไปรีวิว ไม่ต้องส่งสินค้ากลับ",
  },
  {
    id: 2,
    value: "send_return",
    label: "การส่งสินค้าไปรีวิว ต้องส่งสินค้ากลับ",
  },
  {
    id: 3,
    value: "no_shipping",
    label: "อินฟลูเอนเซอร์สามารถหาสินค้ามารีวิวได้เอง",
  },
];
