import { useEffect, useState } from "react";
// mui and icon
import { Badge, Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// theme and style
import { hGrey } from "@theme/index";
import { categoryDialogStyle as classes } from "./Category.style";
// component
import ItemsGender from "./ItemsGender";

export default function Gender(props: any) {
  const { item, genderSelected, setGenderSelected } = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onHandleAdd = () => {
    setGenderSelected((genderSelected: any) => [...genderSelected, item.id]);
  };

  const onHandleDelete = () => {
    const genderFilter = genderSelected?.filter((genderSelected: any) => {
      return genderSelected !== item.id;
    });
    setGenderSelected(genderFilter);
  };

  useEffect(() => {
    const getValue = genderSelected?.find(
      (genderSelected: any) => genderSelected === item.id
    );
    setIsSelected(Boolean(getValue));
  }, [genderSelected, item.id]);

  return (
    <>
      {isSelected ? (
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <Box sx={classes.iconBadge}>
              <CheckCircleIcon sx={{ color: "#8C499C" }} />
            </Box>
          }
          sx={classes.categoryBadge}
        >
          <Box
            width={["100px", "100%"]}
            sx={classes.genderSelected}
            onClick={onHandleDelete}
          >
            <ItemsGender itemsGender={item.value} />
            <Typography fontSize="14px" fontWeight="500">
              {item.title}
            </Typography>
          </Box>
        </Badge>
      ) : (
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <Box sx={classes.iconBadge}>
              <CheckCircleIcon sx={{ color: "#9E9E9E" }} />
            </Box>
          }
          sx={classes.categoryBadge}
        >
          <Box
            width={["100px", "100%"]}
            sx={classes.itemsGender}
            onClick={onHandleAdd}
          >
            <ItemsGender itemsGender={item.value} />
            <Typography fontSize="14px" color={`${hGrey[900]}`}>
              {item.title}
            </Typography>
          </Box>
        </Badge>
      )}
    </>
  );
}
