import { useEffect, useState } from 'react'
// mui and style
import { Link } from '@mui/material';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEmployerDetailStyle as classes } from "../employer-detail/Detail.style"
// api and type
import { EmployerAPI, PaymentAPI } from '@api/global';
import { IEmployerInfoRes } from '@api/employer/type';
import { ITransactionHistoryList } from '@components/team-management/member/Member.type';
// component
import Loading from '@components/global/loading/Loading';
import WalletTransaction from '@components/team-management/member/wallet-tab/WalletTransaction';
// other
import { getTokenFromStorage } from '@utils/helpers/getTokenFromStorage';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export default function TransactionHistory() {
  const [transaction, setTransaction] = useState<ITransactionHistoryList | null>(null);
  const [employerInfo, setEmployerInfo] = useState<IEmployerInfoRes | null>(null)
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const teamId = params.get("t_id") || ""
  const { employerId } = useParams<{ employerId: string }>();

  const { session_token } = getTokenFromStorage();

  useEffect(() => {
    let isMounted = true;
    
    const getEmployerInfo = () => {
      setIsLoading(true);

      EmployerAPI.getEmployerInfo(employerId).then((response) => {
        if (isMounted) {
          setEmployerInfo(response)
          setIsLoading(false)
        }
      }).finally(() => {
        if (isMounted) {
          setIsLoading(false)
        }
      })
    }

    getEmployerInfo();

    return () => {
      isMounted = false
    }
  }, [employerId])
  
  useEffect(() => {
    const getTransactionList = () => {
      setIsLoading(true);

      const params = {
        page: 1,
        item_per_page: 100,
        order_by: "transaction_id",
        sort: "DESC",
      };
  
      if (!employerInfo) return;
  
      const newParams = employerInfo?.package_plan === "team"
        ? { ...params, team_id: teamId || employerInfo.team?.id }
        : { ...params, employer_id: employerId || session_token?.employer_id };
  
      PaymentAPI.getTransactionList(newParams)
        .then((res) => {
          setTransaction(res);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    getTransactionList()

  }, [employerInfo, session_token?.employer_id, employerId, teamId]);

  if (isLoading) return <Loading />

  return (
    <div className='container-main'>
      <Link
        onClick={() => history.goBack()}
        underline="none"
        sx={classes.goBack}
      >
        <KeyboardArrowLeftIcon />
        <span>Back</span>
      </Link>
      <WalletTransaction transaction={transaction} />
    </div>
  )
}
