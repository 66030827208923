import { hPurple, hWhite } from "@theme/index";

export const useMyJobsReportTabStyle = {
  tabPanelEmpBox: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "common.white",
    borderRadius: {
      xs: "12px",
      sm: "0 12px 12px 12px",
    },
    py: 4,
    px: 6,
    boxShadow: 3,
    mt: {
      xs: "-10px",
      sm: "-2.5px",
      md: "-4px",
    },
  },
  tabPanelInfBox: {
    display: "flex",
    flexDirection: "column",
    bgcolor: { xs: "initial", sm: "#fff" },
    borderRadius: {
      xs: "12px",
      sm: "0 16px 16px 16px",
    },
  },
  tabfilter: {
    display: "flex",
  },
  campSumInfo: {
    mb: {
      xs: "15px",
      lg: "28px",
    },
  },
  reportFilterDetailBoxInf: {
    bgcolor: "#fff",
    py: {
      xs: "24px",
      sm: 0,
    },
    px: "24px",
    borderRadius: ["8px", "8px", "0 8px 8px 8px"],
    boxShadow: ["0px 2px 8px rgba(0, 0, 0, 0.1)", "initial"],
  },
  influPostInf: {
    bgcolor: "#fff",
    p: {
      xs: "16px 24px 16px 24px",
      sm: "16px 24px 0 24px",
    },
    borderRadius: "8px",
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "initial",
    },
    mt: {
      xs: "15px",
      sm: 0,
      md: "initial",
    },
    display: {
      md: "none",
    },
  },
  filterDateInfSm: {
    bgcolor: "#fff",
    p: "16px",
    borderRadius: "8px",
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "initial",
    },
    display: { sm: "none" },
    mt: "15px",
  },
  btnFromInfSm: {
    display: "flex",
    justifyContent: "space-between",
    px: "15px",
    mt: "8px",
  },
  campReportBox: {
    p: "16px 24px 16px 24px",
    mt: {
      xs: "15px",
      sm: 0,
      md: "15px",
    },
    borderRadius: "8px",
    bgcolor: "#fff",
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "initial",
    },
  },
  campReport: {
    display: ["initial", "none"],
    mt: ["50px", "34px"],
  },
  tab: {
    bgcolor: "rgb(245, 238, 255)",
    color: "rgb(140, 73, 156)",
    borderRadius: "50px",
    px: "16px",
    py: "10px",
  },
  tabSelected: {
    bgcolor: "rgb(140, 73, 156)",
    color: "#fff",
    borderRadius: "50px",
    px: "16px",
    py: "10px",
  },
  InfluPostReport: {
    borderRadius: "20px",
    mt: "20px",
    height: "auto",
  },
  tabPanel: {
    minHeight: "34px !important",
    "& .MuiTabs-indicator": {
      bgcolor: "transparent",
    },
    "& .css-qpb1i6-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1t1s4kk.Mui-selected":
      {
        color: hWhite[100],
        bgcolor: hPurple[200],
      },
    "& button": {
      minHeight: "100% !important",
    },
  },
  // tab: {
  //   color: hPurple[200],
  //   bgcolor: "#F5EEFF",
  //   m: "0 5px",
  //   borderRadius: "50px",
  //   fontSize: "14px",
  // },
};

export const useReportTotalListStyle = {
  filterDetailBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "84px",
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
    px: "20px",
  },
  label: {
    "& p:last-of-type": {
      fontSize: "20px",
      fontWeight: 500,
      color: "#8C499C",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    justifyContent: "center",
    bgcolor: "#fff",
    "& .MuiSvgIcon-root": {
      color: "#8C499C",
      fontSize: "35px",
    },
  },
};
