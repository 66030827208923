import { useEffect } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Avatar,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
// component
import InfluencerTiktokTab from "@components/influencer-social/tiktok-tab/TiktokTab";
import InfluencerYoutubeTab from "@components/influencer-social/youtube-tab/YoutubeTab";
import InfluencerTwitterTab from "@components/influencer-social/twitter-tab/TwitterTab";
import InfluencerAddress from "@components/influencer-profile/influencer-default/Address";
import InfluencerFacebookTab from "@components/influencer-social/facebook-tab/FacebookTab";
import InfluencerInstagramTab from "@components/influencer-social/instagram-tab/InstagramTab";
import InfluencerPortfolio from "@components/influencer-profile/influencer-default/MyPortfolio";
import InfluencerProfileInfo from "@components/influencer-profile/influencer-default/MyProfile";
import InfluencerBankAccount from "@components/influencer-profile/influencer-default/BankAccount";
// redux
import {
  selectInfluencerProfile,
  getInfluencerProfileAsync,
  selectInfluencerProfileOnload,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getInfluencerSocialAsync } from "@slices/InfluencerSocial.slice";
import { getBankListAsync, selectBankListOnload } from "@slices/BankList.slice";
// theme and style
import { hAlertColor } from "@theme/index";
import { useMyProfileStyle as classes } from "./MyProfile.style";
// other
import { error_message } from "./ErrorMessage";
import { useHistory, useLocation } from "react-router";
import Loading from "@components/global/loading/Loading";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

const tabs = [
  {
    value: "tiktok",
    icon: <Icon className="ico-hu-tiktok" />,
    component: <InfluencerTiktokTab />,
  },
  {
    value: "twitter",
    icon: <Icon className="ico-hu-twitter" />,
    component: <InfluencerTwitterTab />,
  },
  {
    value: "facebook",
    icon: <Icon className="ico-hu-facebook" />,
    component: <InfluencerFacebookTab />,
  },
  {
    value: "instagram",
    icon: <Icon className="ico-hu-instagram" />,
    component: <InfluencerInstagramTab />,
  },
  {
    value: "youtube",
    icon: <Icon className="ico-hu-youtube" />,
    component: <InfluencerYoutubeTab />,
  },
];

export default function InfluencerProfile() {
  const dispatch = useAppDispatch();
  const influencerProfile = useAppSelector(selectInfluencerProfile);
  const bankListOnload = useAppSelector(selectBankListOnload);
  const infProfileOnload = useAppSelector(selectInfluencerProfileOnload);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const platform = params.get("platform");
  const error_code = params.get("error_code");
  const tabSelected = params.get("tab") || "tiktok";

  const { session_token } = getTokenFromStorage();

  useEffect(() => {
    if (session_token?.role === "influencer") {
      dispatch(getInfluencerProfileAsync({}));
      dispatch(getInfluencerSocialAsync({}));
      dispatch(getBankListAsync());
    }

    if (platform && error_code) {
      SwalCustom.fire({
        icon: `error`,
        title: "เกิดข้อผิดพลาด !",
        text: `${error_message[error_code]}`,
        confirmButtonColor: hAlertColor.error,
        confirmButtonText: "Close",
      }).then(() => {
        history.push("/my-profile");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history]);
  
  useEffect(() => {
    function requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.log("เบราว์เซอร์นี้ไม่รองรับการแจ้งเตือน");
      } else {
        if (Notification.permission === "granted") return
        Notification.requestPermission()
      }
    }

    requestNotificationPermission()
  }, [])

  const profile = (influencerProfile?.media.images || []).find(
    (img) => img?.purpose === "profile_image"
  );

  const profileItem = (purpose: string) => {
    return (influencerProfile?.media.images || []).find(
      (img) => img?.purpose === purpose
    )?.url;
  };

  if (infProfileOnload || bankListOnload) return <Loading />

  return (
    <div className="container-main">
      <Box
        sx={{
          ...classes.profileWrap,
          backgroundImage: `url(${profile?.url})`,
        }}
      >
        <IconButton
          onClick={() => history.push("/my-profile/mobile")}
          sx={classes.editProfile}
        >
          <Icon className="ico-hu-edit" />
        </IconButton>
        <Box sx={classes.profileItemWrap}>
          <Box m={["0 0 20px 25px", "20px"]}>
            <Typography variant="h6" color="#fff">
              {influencerProfile?.fullname}
            </Typography>
            <Box display="flex" columnGap="10px">
              {[
                `age : ${influencerProfile?.age}`,
                influencerProfile?.gender,
                influencerProfile?.nationality,
              ].map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  textTransform="capitalize"
                  color="#fff"
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Box display="flex" mt="16px">
              {["frontal", "portrait", "headshot"].map((img, index) => (
                <Avatar
                  key={index}
                  src={profileItem(`influencer_${img}_image`)}
                  sx={{ width: "40px", height: "40px", borderRadius: 0 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={6}>
        <Grid item display={{ xs: "none", md: "initial" }} xs={12} md={4}>
          <Box display="flex" flexDirection="column" rowGap="20px">
            {[
              { title: "My Profile", component: <InfluencerProfileInfo /> },
              { title: "Address", component: <InfluencerAddress /> },
              { title: "Bank Account", component: <InfluencerBankAccount /> },
              { title: "My Portfolio", component: <InfluencerPortfolio /> },
            ].map((item, index) => (
              <Box key={index} sx={classes.card}>
                <CardHeader
                  sx={classes.cardHeader}
                  title={
                    <Typography
                      variant="body1"
                      color="common.white"
                      fontWeight="inherit"
                    >
                      {item.title}
                    </Typography>
                  }
                />
                {item.component}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" columnGap={["2px", "5px"]} height="40px">
            {tabs.map((tab, index) => (
              <Box
                key={index}
                onClick={() => history.replace(`my-profile?tab=${tab.value}`)}
                sx={{
                  ...classes.tabItem,
                  bgcolor: tab.value === tabSelected ? "#8C499C" : "#EBDFFF",
                  color: tab.value === tabSelected ? "#fff" : "#8C499C",
                }}
              >
                {tab.icon}
                <Typography sx={classes.label}>{tab.value}</Typography>
              </Box>
            ))}
          </Box>

          {tabs.map((tab, index) => {
            if (tab.value === tabSelected) {
              return <div key={index}>{tab.component}</div>;
            }
            return null;
          })}
        </Grid>
      </Grid>
    </div>
  );
}
