// mui and style
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useInfluencerDistributioStyle as classes } from "../../OverviewTab.style";
// type and components
import { IInfluencerDistributionProps } from "../../OverviewTab.type";
import InfluencerDistributionList from "./InfluencerDistributionList";
import InfluencerDistributionStats from "./InfluencerDistributionStats";

export default function InfluencerDistribution(
  props: IInfluencerDistributionProps
) {
  const { areaDist, setParam, param } = props;
  return (
    <Box sx={classes.title}>
      <Typography variant="h6">Influencer distribution in each area</Typography>
      <Typography variant="subTitle2">
        การกระจายตัวของอินฟลูเอนเซอร์ในแต่ละพื้นที่
      </Typography>
      <Divider sx={{ my: "20px" }} />
      <Stack>
        <InfluencerDistributionStats areaDist={areaDist} />
        <InfluencerDistributionList
          param={param}
          setParam={setParam}
          areaDist={areaDist}
        />
      </Stack>
    </Box>
  );
}
