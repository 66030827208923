// mui
import { Box, Grid, Typography } from "@mui/material";

export default function PrivateEstimate(props: any) {
  const { amountTitle, amount, budget, summary } = props;
  return (
    <Box sx={{ border: "1px solid #EBDFFF", borderRadius: "12px" }}>
      <Grid container>
        <Grid item xs={6} sm={6} lg={2}>
          <Box
            sx={{
              margin: "20px 0 20px 20px",
            }}
          >
            <Typography variant="body1">{amountTitle}</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {amount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={2}>
          <Box
            sx={{
              margin: "20px 0 20px 20px",
            }}
          >
            <Typography variant="body1">Followers</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {summary.followers}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={2}>
          <Box
            sx={{
              margin: "20px 0 20px 20px",
            }}
          >
            <Typography variant="body1">Likes</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {summary.likes}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={2}>
          <Box
            sx={{
              margin: "20px 0 20px 20px",
            }}
          >
            <Typography variant="body1">Comments</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {summary.comments}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={2}>
          <Box
            sx={{
              margin: "20px 0 20px 20px",
            }}
          >
            <Typography variant="body1">Shares</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {summary.shares}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={2}>
          <Box
            sx={{
              margin: "20px 0 20px 20px",
            }}
          >
            <Typography variant="body1">Budget</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {budget}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
