// mui
import {
  Box,
  Paper,
  Stack,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  IconButton,
  TableContainer,
} from "@mui/material";
// style
import {
  TableRowCustom,
  TableCellCustom,
  employerExpense as classes,
} from "../employer-expense/Expense.style";
// icon
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// type and other
import { IEmployerListProps } from "./EmployerList.type";
import { useHistory, useLocation } from "react-router-dom";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";

export default function EmployerList(props: IEmployerListProps) {
  const { employerList, handleDeleteEmployer, goEmployerInfo } = props;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const qSearch = params.get("q") || "";
  const qPage = Number(params.get("page")) || 1;
  const qOrderBy = params.get("order_by") || "organisation";
  const qSort = params.get("sort") || "ASC";

  const numberFormat = Intl.NumberFormat("en", { notation: "standard" });

  const onSortEmployerList = (value?: string) => {
    if (!value) return;

    let sorted = qSort;
    if (sorted === "DESC") {
      sorted = "ASC";
    } else {
      sorted = "DESC";
    }

    history.replace(
      `/employers?q=${qSearch}&page=${qPage}&order_by=${value}&sort=${sorted}`
    );
  };

  const packageMatchBgcolor = (plan: string) => {
    switch (plan) {
      case "free":
        return classes.sFree;
      case "enterprise":
        return classes.sEnterprise;
      case "professional":
        return classes.sProfessional;
      default:
        return classes.sBasic;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ with: "100%", minWidth: "1000px" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {[
              {
                label: "Name",
                value: "organisation",
              },
              {
                label: "Contact",
                value: "employer",
              },
              {
                label: "Business Type",
                value: "type",
              },
              {
                label: "Subscription",
                value: "package",
              },
              {
                label: "Wallet Balance",
                value: "wallet",
              },
              {
                label: "Actions",
              },
            ].map((row, i) => (
              <TableCellCustom
                key={i}
                align={row.label === "Actions" ? "center" : "left"}
              >
                <Box
                  display="flex"
                  justifyContent={
                    row.label === "Actions" ? "center" : "space-between"
                  }
                  alignItems="center"
                >
                  <Typography variant="title2">{row.label}</Typography>
                  {row.label !== "Actions" && (
                    <IconButton onClick={() => onSortEmployerList(row?.value)}>
                      {row?.value === qOrderBy && qSort === "DESC" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          style={{ color: "#fff" }}
                        />
                      )}
                    </IconButton>
                  )}
                </Box>
              </TableCellCustom>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employerList.map((row, index) => (
            <TableRowCustom key={index}>
              <TableCellCustom>
                <Stack direction="row" alignItems="center">
                  <Avatar
                    sx={classes.campaignIcon}
                    alt="Campaign"
                    src={row.organisation.attachment.url}
                  />
                  <Box ml={2.5}>
                    <Typography
                      variant="title1"
                      color="#8C499C"
                      onClick={() => goEmployerInfo(row.employer_full_id)}
                      sx={{ cursor: "pointer" }}
                    >
                      {row.organisation.name}
                    </Typography>
                    <Typography sx={classes.email}>{row.email}</Typography>
                  </Box>
                </Stack>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Stack
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Typography>{row.fullname}</Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">
                      {formatPhoneNumber(row.phone)}
                    </Typography>
                  </Box>
                </Stack>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Stack
                  direction="column"
                  alignItems="start"
                  justifyContent="start"
                >
                  <Typography variant="body1">
                    {row.organisation.type}
                  </Typography>
                </Stack>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Box display="flex" justifyContent="center">
                  <Box sx={packageMatchBgcolor(row.package_plan)}>
                    <Typography textTransform="capitalize">
                      {row.package_plan}
                    </Typography>
                  </Box>
                </Box>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Typography variant="body1" fontWeight="600">
                  {numberFormat.format(row.wallet)} THB
                </Typography>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Box display="flex" justifyContent="center" columnGap="10px">
                  <Box
                    sx={classes.viewEmployer}
                    onClick={() => goEmployerInfo(row.employer_full_id)}
                  >
                    <RemoveRedEyeIcon sx={{ color: "#8C499C" }} />
                  </Box>

                  <Box
                    sx={classes.deleteEmployer}
                    onClick={() => handleDeleteEmployer(row.employer_full_id)}
                  >
                    <DeleteIcon sx={{ color: "#E30D18" }} />
                  </Box>
                </Box>
              </TableCellCustom>
            </TableRowCustom>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
