// mui and style
import {
  Box,
  Icon,
  Grid,
  Button,
  Avatar,
  Divider,
  Typography,
} from "@mui/material";
import { useEmployerInformationStyle as classes } from "../Detail.style";
// type and util
import { IEmployerDetailProps } from "../Detail.type";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";

export default function Information(props: IEmployerDetailProps) {
  const { employerInfo, setOpen } = props;

  const organisationInfo = employerInfo?.organisation;

  return (
    <Box sx={classes.boxInformation}>
      <Box flexDirection={["column", "row"]} sx={classes.boxHeader}>
        <Typography variant="h6">Employer Information</Typography>
        <Box mt={["12px", 0]}>
          <Button onClick={() => setOpen(true)} sx={classes.button}>
            <Icon className="ico-hu-edit" />
            <Typography variant="buttonM">Edit</Typography>
          </Button>
        </Box>
      </Box>
      <Divider sx={classes.divider} />
      <Grid container m="24px" rowGap="20px">
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">โลโก้กิจการ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Avatar src={organisationInfo?.attachment?.url || ""} sx={classes.avatar} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ชื่อกิจการ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="#5C5C5C">
            {organisationInfo?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ขนาดกิจการ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="#5C5C5C">
            {organisationInfo?.size}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ประเภทกิจการ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="#5C5C5C">
            {organisationInfo?.type}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">Category</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <ul style={{ marginLeft: "-15px" }}>
            {organisationInfo?.category.map((cat, index) => (
              <Typography
                component="li"
                key={index}
                variant="body1"
                color="#5C5C5C"
              >
                {cat.name_en}
              </Typography>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">ชื่อผู้ติดต่อ</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="#5C5C5C">
            {employerInfo?.fullname}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">เบอร์โทรศัพท์</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="#5C5C5C">
            {formatPhoneNumber(employerInfo?.phone || "")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="title1">Line ID</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="#5C5C5C">
            {employerInfo?.line_id}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
