// mui and style
import {
  Box,
  Fab,
  Grid,
  Icon,
  Tooltip,
  Divider,
  Typography,
} from "@mui/material";
import { usePublishLinkStyle as classes } from "../EmployerJob.style";
// type and other
import { IPublishedLinkProps } from "../EmployerJob.type";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";

export default function PublishedLink(props: IPublishedLinkProps) {
  const { plateform, publishedLink } = props;

  return (
    <Box sx={classes.productBox}>
      <Box sx={classes.productTitle}>
        <Typography>Published Link</Typography>
        <Typography>แนบลิงค์ส่งงาน</Typography>
      </Box>
      <Divider sx={classes.divider} />
      <Grid container>
        <Grid item xs={12} sm={2.5} mt="7px">
          <Box sx={classes.platform}>
            <SocialIconSelected
              platform={plateform}
              width="26px"
              height="26px"
            />
            <Typography>{plateform}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9.5} mt={[5, 0]}>
          <Box sx={classes.publishLink}>
            <Typography color="primary">{publishedLink}</Typography>
            <Tooltip title="Published Link" placement="top">
              <Fab
                sx={classes.copyBtn}
                onClick={() => window.open(publishedLink, "_blank")}
              >
                <Icon
                  className="ico-hu-open"
                  color="primary"
                  sx={{ fontSize: "16px" }}
                />
              </Fab>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
