import { useEffect, useState } from "react";
// mui and icon
import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// style and component
import { useEditMyProfileForMobileStyle as classes } from "../../MyProfile.style";
import EditInfluencerProfile from "@components/influencer-profile/edit-influencer-profile/EditProfile";
// type
import { IAttachments } from "@api/app-information/type";
import { IEditInfluencerProfileForm } from "@components/influencer-profile/InfluencerProfile.type";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditInfluencerProfileFormSchema } from "@components/influencer-profile/InfluencerProfile.shema";
// redux
import {
  selectInfluencerProfile,
  getInfluencerProfileAsync,
  updateInfluencerProfileAsync,
  selectInfluencerProfileOnload,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// other
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import Loading from "@components/global/loading/Loading";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

const initEditInfluencerProfileForm: IEditInfluencerProfileForm = {
  fullname: "",
  birth_date: "",
  gender: "",
  nationality: "",
  phone: "",
  line_id: "",
  description: "",
};

export default function EditMyProfileForMobile() {
  const [images, setImages] = useState<IAttachments[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const influencerLoading = useAppSelector(selectInfluencerProfileOnload);

  const form = useForm<IEditInfluencerProfileForm>({
    resolver: yupResolver(EditInfluencerProfileFormSchema),
    defaultValues: initEditInfluencerProfileForm,
  });

  useEffect(() => {
    dispatch(getInfluencerProfileAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if (!influencerInfo) return;
    const data = {
      fullname: influencerInfo.fullname,
      birth_date: dayjs(influencerInfo.birth_date).format("YYYY-MM-DD"),
      gender: influencerInfo.gender,
      nationality: influencerInfo.nationality,
      phone: influencerInfo.phone,
      line_id: influencerInfo.line_id,
      description: influencerInfo.description,
    };
    setImages(influencerInfo.media.images);
    form.reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencerInfo]);

  const onUpdateProfile = async (value: IEditInfluencerProfileForm) => {
    setIsLoading(true);
    const imageList = images.map((img) => {
      return {
        ...img,
        file_id: img.file_id.includes("img-") ? "" : img.file_id,
      };
    });
    const body = {
      profile: {
        ...value,
        phone: value.phone.replaceAll("-", ""),
        media: {
          images: imageList,
        },
      },
    };
    try {
      dispatch(updateInfluencerProfileAsync(body));
      setIsLoading(false);
      await SwalCustom.fire({
        icon: "success",
        title: "Updated profile success",
        showConfirmButton: false,
        timer: 1600,
      });
      history.push("/my-profile/mobile");
    } catch (error) {
      setIsLoading(false);
      SwalCustom.fire({
        icon: "error",
        title: "Oops!",
        text: "Error. Something went wrong.",
        showConfirmButton: true,
        timer: 1600,
      });
    }
  };

  if (isLoading || influencerLoading) return <Loading />;
  
  return (
    <div className="container-main">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        mt="-10px"
      >
        <KeyboardArrowLeftIcon
          onClick={() => history.goBack()}
          sx={{ position: "absolute", left: 0 }}
        />
        <Typography variant="h6">My Profile</Typography>
      </Box>
      <Box sx={classes.content}>
        <EditInfluencerProfile
          form={form}
          images={images}
          setImages={setImages}
          onSubmit={onUpdateProfile}
        />
      </Box>
    </div>
  );
}
