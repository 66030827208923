// mui and icon
import { Box, IconButton } from "@mui/material";
import NextIcon from "@mui/icons-material/ArrowForwardIos";

function NextSlideButton(props: {
  nextSlide: () => void;
  height?: string;
  fontSize?: string;
}) {
  return (
    <Box sx={{ position: "relative", top: props.height || "160px" }}>
      <IconButton onClick={props.nextSlide} size="small">
        <NextIcon sx={{ fontSize: props.fontSize || "24px" }} />
      </IconButton>
    </Box>
  );
}

export default NextSlideButton;
