// mui and style
import { Box, Grid, Icon, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "../CampaignPrivate.style";
// type and utils
import { IInfluencerListStatusProps } from "../PrivateCampaign.type";

export default function InfluencerListStatus(
  props: IInfluencerListStatusProps
) {
  const { privateSummary, totalBudget, jobLimit } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} lg={9}>
        <Box sx={classes.mainBox}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={1.5}>
              <Typography>ตัวจริง</Typography>
              <Box display="flex">
                <Typography
                  variant="h5"
                  color={privateSummary.privateSelected ? "#4CAF50" : "#9E9E9E"}
                >
                  {privateSummary.privateSelected === 0
                    ? 0
                    : privateSummary.privateSelected >= jobLimit
                    ? jobLimit
                    : privateSummary.privateSelected}
                </Typography>
                <Typography variant="h5" color="#893DBD">
                  /{jobLimit}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={1.5}>
              <Typography>Followers</Typography>
              <Typography variant="h5" color="#893DBD">
                {numberFormat.format(privateSummary.followers)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={1.5}>
              <Typography>Likes</Typography>
              <Typography variant="h5" color="#893DBD">
                {numberFormat.format(privateSummary.likes)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Typography>Comments</Typography>
              <Typography variant="h5" color="#893DBD">
                {numberFormat.format(privateSummary.comments)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Typography>Shares</Typography>
              <Typography variant="h5" color="#893DBD">
                {numberFormat.format(privateSummary.shares)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3.5}>
              <Typography>Budget</Typography>
              <Box display="flex">
                <Typography
                  variant="h5"
                  color={privateSummary.budgets ? "#4CAF50" : "#9E9E9E"}
                >
                  {privateSummary.budgets
                    ? `${Intl.NumberFormat("en").format(Math.round(privateSummary.budgets))}`
                    : 0}
                </Typography>
                <Typography variant="h5" color="#893DBD">
                  /{Intl.NumberFormat("en").format(totalBudget)} THB
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6} lg={1.5}>
        <Box sx={classes.mainBox}>
          <Typography>ตัวสำรอง</Typography>
          <Typography
            variant="h5"
            color={
              privateSummary.privateSelected > jobLimit ? "#4CAF50" : "#9E9E9E"
            }
          >
            {privateSummary.privateSelected > jobLimit
              ? privateSummary.privateSelected - jobLimit
              : 0}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} lg={1.5}>
        <Box
          sx={{
            ...classes.boxListName,
            backgroundColor:
              privateSummary.privateSelected >= jobLimit
                ? "#4CAF50"
                : "#9E9E9E",
            cursor: privateSummary.privateSelected >= jobLimit ? "pointer" : "",
          }}
          // onClick={
          //   privateSummary.privateSelected >= jobLimit
          //     ? () => {
          //         inserJob(privateSelected);
          //       }
          //     : () => {}
          // }
        >
          <Box>
            <Icon className="ico-hu-user" sx={{ fontSize: "30px" }} />
          </Box>
          <Typography variant="buttonM">
            รายชื่อ ({privateSummary.privateSelected})
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
