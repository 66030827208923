import { useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// hook-form and schema
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateAccountFormSchema } from "./AuthenticationForm.schema";
// type and api
import {
  ICreateAccountFormSchema,
  ICreateAccountDialogProps,
} from "./AuthenDialog.types";
import { AuthenticationAPI } from "@api/global";
import { IRegisterForm } from "./AuthenticationForm";
// theme and style
import { hAlertColor } from "@theme/index";
import { useCreateAccountDialogStyle as classes } from "./authentication.style";
// icon
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// util
import { genderList } from "@utils/constants/genderArray.constants";
import nationationList from "@data/nationalities.json";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
// other
import Loading from "@global/loading/Loading";
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import DialogAuthentication from "@global/dialog-authentication";
// dayJs and route
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";

const initCreateAccountForm: IRegisterForm = {
  fullName: "",
  birthdate: null,
  gender: "none",
  nationality: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
  other_referer_code: "",
};

export default function CreateAccountDialog(props: ICreateAccountDialogProps) {
  const { open, setOpen } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    watch,
    setValue,
    register,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<IRegisterForm>({
    resolver: yupResolver(CreateAccountFormSchema),
    defaultValues: initCreateAccountForm,
  });

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParam = params.get("code") || "";

  useEffect(() => {
    setValue("other_referer_code", searchParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  function compare(a: any, b: any) {
    if (a.nationality < b.nationality) {
      return -1;
    }
    if (a.nationality > b.nationality) {
      return 1;
    }
    return 0;
  }

  const nationalitiesArraySort = nationationList.sort(compare);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeInput = (e: any, name: keyof ICreateAccountFormSchema) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onCreateAccount = async (data: IRegisterForm) => {
    setIsLoading(true);

    const phoneNumber = data.phone.replace(/-/g, "");

    try {
      const reqBody = {
        fullname: data.fullName,
        birth_date: dayjs(data.birthdate).format("YYYY-MM-DD"),
        gender: data.gender || "none",
        nationality: data.nationality,
        phone: phoneNumber,
        email: data.email,
        password: data.password,
        other_referer_code: data.other_referer_code,
      };
      const response = await AuthenticationAPI.createInfluencer(reqBody);
      setIsLoading(true);
      const email = response.email;
      history.push(`/verify-email/${email}`);
    } catch (error: any) {
      setIsLoading(false);
      const data = error.response.data;
      if (data.status_code === 400) {
        switch (data.error.message) {
          case "error, fullname format is invalid (format is xxx xxx or xxx xxx xxx).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Full Name is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, gender format is invalid (gender must be male or female or lgbtq or none).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Gender is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, phone number format is invalid (need number as 10 digit).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Phone Number is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, email format is invalid (example@hasumail.com).":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Email format is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
          case "error, date format is invalid.":
            SwalCustom.fire({
              icon: "error",
              title: "Opps !",
              text: "Error, Date format is invalid !",
              confirmButtonColor: hAlertColor.error,
              confirmButtonText: "Close",
            });
            break;
        }
      } else {
        if (
          data.error.message ===
          `error, insert user error: ERROR: duplicate key value violates unique constraint "user_email_key" (SQLSTATE 23505)`
        ) {
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "อีเมลถูกใช้แล้ว",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
        } else {
          SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Something when wrong !",
            confirmButtonColor: hAlertColor.error,
            confirmButtonText: "Close",
          });
        }
      }
    }
  };

  const handleDateChange = (e: dayjs.Dayjs | null) => {
    const value = dayjs(e).format("MM/DD/YYYY");
    setValue("birthdate", value);
    const isDateValid = (date: string) => {
      const dateFormat = "MM/DD/YYYY";
      const parsedDate = dayjs(date, dateFormat, true);
      const isValid = parsedDate.isValid();
      const isInputSameAsParsed = parsedDate.format(dateFormat) === date;

      return isValid && isInputSameAsParsed;
    };

    if (isDateValid(value)) clearErrors("birthdate");
  };

  if (isLoading) return <Loading />

  return (
    <DialogAuthentication
      title="Create an Account"
      open={open}
      onClose={handleCloseDialog}
      sx={classes.createAccountDialog}
    >
      <form onSubmit={handleSubmit(onCreateAccount)}>
        <Box sx={classes.firstDialogContent}>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Full Name :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("fullName")}
                  onChange={(e) => handleChangeInput(e, "fullName")}
                  register={register("fullName")}
                  variant="outlined"
                  placeholder="Full Name"
                  fullWidth
                  sx={!errors.fullName ? classes.textFieldGrey : undefined}
                  error={!!errors.fullName}
                  helperText={errors.fullName?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Birthdate :
                  </Typography>
                </Box>
                <Box sx={classes.date}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      onChange={handleDateChange}
                      value={
                        watch("birthdate") || errors.birthdate
                          ? dayjs(watch("birthdate"))
                          : null
                      }
                      renderInput={(params) => (
                        <TextFieldCustom
                          sx={
                            errors.birthdate
                              ? classes.dateError
                              : classes.initDate
                          }
                          error={!!errors.birthdate}
                          helperText={errors.birthdate?.message}
                          {...params}
                        />
                      )}
                      PopperProps={{
                        placement: "bottom-end",
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [50, 5],
                            },
                          },
                        ],
                      }}
                      maxDate={dayjs(new Date())}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Gender :
                  </Typography>
                </Box>
                <BasicSelectCustom
                  id="create-acc-gender"
                  value={getValues("gender")}
                  {...register("gender")}
                  onChange={(e) => handleChangeInput(e, "gender")}
                  data={genderList}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Select"
                  fullWidth
                  sx={classes.selectGrey}
                  error={!!errors.gender}
                  helperText={errors.gender?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Nationality :
                  </Typography>
                </Box>
                <BasicSelectCustom
                  id="create-acc-nationality"
                  value={getValues("nationality")}
                  onChange={(e) => handleChangeInput(e, "nationality")}
                  data={nationalitiesArraySort}
                  valueKey="nationality"
                  labelKey="nationality"
                  placeholder="Select"
                  fullWidth
                  sx={classes.selectGrey}
                  error={!!errors.nationality}
                  helperText={errors.nationality?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Phone :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={formatPhoneNumber(getValues("phone"))}
                  onChange={(e) => handleChangeInput(e, "phone")}
                  variant="outlined"
                  placeholder="Phone"
                  fullWidth
                  sx={!errors.phone ? classes.textFieldGrey : undefined}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.secondDialogContent}>
          <Typography variant="body1" fontWeight="bold" mb={4} color="#8C499C">
            Email for Sign in
          </Typography>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Email :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("email")}
                  onChange={(e) => handleChangeInput(e, "email")}
                  variant="outlined"
                  placeholder="Email"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={!errors.email ? classes.textFieldGrey : undefined}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Password :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("password")}
                  onChange={(e) => handleChangeInput(e, "password")}
                  type={!!showPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder="Password"
                  fullWidth
                  sx={!errors.password ? classes.textFieldGrey : undefined}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!!showPassword ? (
                            <VisibilityOffIcon sx={classes.iconButton} />
                          ) : (
                            <VisibilityIcon sx={classes.iconButton} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box width={["140px", "auto"]} mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Confirm Password :
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={getValues("confirmPassword")}
                  onChange={(e) => handleChangeInput(e, "confirmPassword")}
                  type={!!showPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder="Confirm Password"
                  fullWidth
                  sx={
                    !errors.confirmPassword ? classes.textFieldGrey : undefined
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!!showPassword ? (
                            <VisibilityOffIcon sx={classes.iconButton} />
                          ) : (
                            <VisibilityIcon sx={classes.iconButton} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <DialogContent sx={classes.thirdDialogContent}>
          <Typography variant="body1" fontWeight="bold" mb={4} color="#8C499C">
            Do you have a referral code from a friend?
          </Typography>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Box mb={[0, 2]} pl={[0, 2]}>
                  <Typography variant="body2" fontWeight="bold">
                    Enter referral code (optional)
                  </Typography>
                </Box>
                <TextFieldCustom
                  value={watch("other_referer_code")}
                  onChange={(e) => handleChangeInput(e, "other_referer_code")}
                  register={register("other_referer_code")}
                  variant="outlined"
                  placeholder="Referral code"
                  fullWidth
                  sx={!errors.fullName ? classes.textFieldGrey : undefined}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider sx={classes.divider} />
        <DialogActions sx={classes.dialogAction}>
          <Button type="submit" sx={classes.registorButton}>
            <Typography variant="body1" fontWeight="bold">
              Register
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </DialogAuthentication>
  );
}
