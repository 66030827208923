import React, { useRef, useState } from "react";
// mui and icon
import {
  Box,
  Zoom,
  Icon,
  Dialog,
  useTheme,
  Typography,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
// style and react player
import { useViewMultiVideoDialogStyle as classes } from "./ViewMultiVideoDialog.style";
import ReactPlayer from "react-player";
// type
import { IExternalFile } from "@api/app-information/type";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

interface IViewMuitiVideoDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  videos?:
    | {
        url: string;
      }[]
    | null;
  initVideoIndex?: number;
  urls?: IExternalFile[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

SwiperCore.use([Navigation]);

export default function ViewMultiVideoDialog(
  props: IViewMuitiVideoDialogProps
) {
  const { open, onClose, videos, initVideoIndex } = props;
  let initIndex = initVideoIndex ? initVideoIndex : 0;

  const [currentIndex, setCurrentIndex] = useState(initIndex);
  const video = videos ? videos : [];

  const swiperRef = useRef<any>(null);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const handlePrevClick = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleSlideChange = (imgIndex: number) => {
    setCurrentIndex(imgIndex);
  };

  const handleClose = () => {
    onClose(false);
  };

  const playerConfig = {
    file: {
      attributes: {
        controlsList: "nodownload",
        allowFullScreen: false,
      },
    },
  };

  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={Transition}
      sx={classes.dialog}
    >
      {/* close dialog button */}
      <Icon
        className={xsScreen ? "ico-hu-close-circle" : "ico-hu-close"}
        sx={classes.closeIcon}
        onClick={handleClose}
      />

      {/* prev and next button */}
      <Box sx={classes.prevAndNextBox}>
        {/* prev button */}
        <div>
          <Icon
            className="ico-hu-chevron-left-circle"
            sx={{
              ...classes.prevBtn,
              display: currentIndex === 0 ? "none" : "block",
            }}
            onClick={
              xsScreen
                ? handlePrevClick
                : () => setCurrentIndex(currentIndex - 1)
            }
          />
        </div>

        {/* next button */}
        <div>
          <Icon
            className="ico-hu-chevron-right-circle"
            sx={{
              ...classes.nextBtn,
              display: currentIndex === video.length - 1 ? "none" : "block",
            }}
            onClick={
              xsScreen
                ? handleNextClick
                : () => setCurrentIndex(currentIndex + 1)
            }
          />
        </div>
      </Box>

      {/* slide videos for mobile */}
      <Box sx={classes.slideVidosBoxForMobile}>
        <Swiper
          ref={swiperRef}
          className="mySwiper"
          onSlideChange={(swiper) => handleSlideChange(swiper.activeIndex)}
          {...{ initialSlide: currentIndex }}
        >
          {open &&
            video.map((item, index) => (
              <SwiperSlide key={index} className="swiperSlide">
                <ReactPlayer
                  url={item.url}
                  controls={open}
                  width="100%"
                  height="100%"
                  playing={open}
                  loop={open}
                  muted={open}
                  config={playerConfig}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>

      {/* slide videos for desktop */}
      <DialogContent sx={classes.slideVideosBoxForDesktop}>
        {video.length && open ? (
          <ReactPlayer
            url={video[currentIndex]?.url}
            controls={open}
            width="100%"
            height="100%"
            playing={open}
            loop={open}
            muted={!open}
          />
        ) : null}
      </DialogContent>

      {/* total */}
      <Box sx={classes.total}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "18px",
          }}
        >
          {`${currentIndex + 1} จาก ${video.length}`}
        </Typography>
      </Box>
    </Dialog>
  );
}
