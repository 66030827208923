import { useState } from "react";
// mui and icons
import {
  Box,
  Fab,
  Icon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// api and type
import { AppInformationAPI } from "@api/global";
import { IMuitiVideosInputProps } from "../CampaignBrief.type";
// style and others
import { useMultiVideosInputStyle as classes } from "../CampaignBrief.style";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import ReactPlayer from "react-player";

export default function MuitiVideosInput(props: IMuitiVideosInputProps) {
  const {
    keyName,
    errors,
    videos,
    form: { setValue },
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const onInputVideo = async (ev: any) => {
    const files = ev.target.files;
    const uploadPromises = [];

    setIsLoading(true);

    for (const file of files) {
      const fileData = {
        file,
        destination: "company",
        permission: "public",
      };

      const uploadPromise = AppInformationAPI.uploadAttachment(fileData)
        .then((resonse) => {
          const result = {
            file_id: "",
            file_name: resonse.file_name,
            file_type: resonse.file_type,
            url: resonse.public_url as string,
            destination: resonse.destination,
            purpose: "",
            is_approve: false,
          };
          return result;
        })
        .catch((error) => {
          const errMsg = error.response.data.error.message;
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${errMsg ? errMsg : "มีบางอย่างผิดพลาด"}`,
            confirmButtonColor: "#E30D18",
            confirmButtonText: `Close`,
          });
        });

      uploadPromises.push(uploadPromise);
    }

    Promise.all(uploadPromises)
      .then((results) => {
        setIsLoading(false);
        if (results[0]) {
          setValue(keyName as any, [...videos, ...results]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const errMsg = error.response.data.error.message;
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${errMsg ? errMsg : "มีบางอย่างผิดพลาด"}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  const onHandleRemove = (index: number) => {
    const file = [...videos];
    file.splice(index, 1);
    setValue(keyName as any, file);
  };

  return (
    <Stack direction="row" columnGap={4} rowGap={4} flexWrap="wrap">
      {videos &&
        videos.map((item, index) => (
          <Box
            key={index}
            position="relative"
            height="100px"
            sx={classes.videosItem}
          >
            <Fab onClick={() => onHandleRemove(index)} sx={classes.removeBtn}>
              <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
            </Fab>
            <Box sx={classes.iconPlayVideo}>
              <Icon
                sx={{
                  fontSize: "40px",
                  color: "#fff",
                }}
                className="ico-hu-play-circle-outline"
              />
            </Box>
            <ReactPlayer
              url={item.url}
              width="100px"
              height="100px"
              loop={true}
              muted={true}
            />
          </Box>
        ))}
      <Box height="100px" width={["47%", "initial"]}>
        <Box
          component="label"
          sx={{ ...classes.labelUpload, borderColor: errors ? "red" : "grey" }}
        >
          {isLoading ? (
            <CircularProgress size="60px" color="primary" />
          ) : (
            <>
              <Box width="40px" mb="-8px">
                <Icon
                  className="ico-hu-p-vdo"
                  sx={{ fontSize: "48px", ml: "-3px" }}
                />
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Icon className="ico-hu-upload" sx={{ fontSize: "10px" }} />
                <span style={{ fontSize: "12px" }}>Video</span>
              </Stack>
              <input
                type="file"
                multiple
                accept="video/*"
                id="input-file"
                onChange={onInputVideo}
                style={{ display: "none" }}
              />
            </>
          )}
        </Box>
      </Box>
      {errors && (
        <Typography
          color="error"
          fontWeight={300}
          fontSize="12px"
          m="10px 0 0 11px"
        >
          {errors.message}
        </Typography>
      )}
    </Stack>
  );
}
