import { RootState } from "@store/store";
import { createSlice } from "@reduxjs/toolkit";
import { INotificationState } from "./type";

const initialState: INotificationState = {
  employer: null,
  influencer: null,
  notification: null,
  notificationStatus: false,
  loading: false,
  error: "",
};

export const NotificationSlice = createSlice({
  initialState,
  name: "notificationSlice",
  reducers: {
    setEmployerNotification: ( state, { payload }) => {
      state.employer = payload;
    },
    setInfluencerNotification: ( state, { payload }) => {
      state.influencer = payload;
    },
    setOpenNotificationSnackbar: ( state, { payload }) => {
      state.notification = payload;
      state.notificationStatus = true
    },
    setCloseNotificationSnackbar: ( state ) => {
      state.notification = null;
      state.notificationStatus = false
    },
  }
})

export const { setEmployerNotification, setInfluencerNotification, setOpenNotificationSnackbar, setCloseNotificationSnackbar } = NotificationSlice.actions;

export const selectEmployerNotifications = (state: RootState) => state.notificationInfo.employer;
export const selectInfluencerNotifications = (state: RootState) => state.notificationInfo.influencer;
export const selectNotificationSnackbar = (state: RootState) => state.notificationInfo.notificationStatus;
export const selectNotification = (state: RootState) => state.notificationInfo.notification;

export default NotificationSlice.reducer;