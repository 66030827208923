// mui and style
import { styled, Tab, TabProps } from "@mui/material";
import { useTabJobBoardStyle } from "@components/job-board/JobBoard.style";
// theme
import { hPurple } from "@theme/index";

export const useCampaignBriefStyle = {
  containnerMain: {
    p: ["88px 20px 20px 20px", "88px 28px 28px 120px"],
  },
  searchWrap: {
    display: "flex",
    justifyContent: ["center", "center", "center", "flex-start"],
    border: "1px solid #8C499C",
  },
  pagination: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
  hederTabWrap: {
    mt: "-30px",
    position: {
      xs: "sticky",
      sm: "inherit",
    },
    top: 0,
    zIndex: 100,
    pb: {
      xs: "20px",
      sm: 0,
    },
  },
  headerTab: {
    background: {
      xs: "linear-gradient(to bottom, #8C499C 77%, #f5f6f8 23%)",
      sm: "linear-gradient(to bottom, #f5f6f8 77%, #f5f6f8 23%)",
    },
    mx: "-10px",
    px: {
      xs: "20px",
      sm: "10px",
    },
    pt: "35px",
    position: "relative",
  },
  campaignTitle: {
    color: ["#fff", "#000"],
    mt: "-10px",
    mb: "25px",
  },
  noCampaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  noCampDiscWrap: {
    maxWidth: "400px",
    textAlign: "center",
    mt: "15px",
  },
};

export const useCampaignTabsItemLgStyle = {
  ...useTabJobBoardStyle,
  tabs: {
    "& .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .MuiTabs-scrollButtons": {
      display: "none",
    },
  },
};

export const CampaignTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hPurple[100],
  borderRadius: "20px 20px 0px 0px",
  minHeight: "42px",
  maxHeight: "42px",
  "&:not(:last-child)": {
    marginRight: ["0%", "0%", "0.6%"],
  },
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  [theme.breakpoints.up("xs")]: {
    width: "32%",
    fontSize: "14px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("sm")]: {
    width: "16%",
  },
  [theme.breakpoints.up("md")]: {
    width: ["20%", "20%", "19.52%"],
  },
}));
