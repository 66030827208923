import { useContext, useState } from "react";
// mui and style
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useWalletBalanceStyle as classes } from "./CampaignCharge.style";
// slice
import { useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
import { selectInfluencerPrivateCampaign } from "@slices/CampaignPrivate.slice";
// api and util
import { CampaignAPI, PaymentAPI } from "@api/global";
import { depositInfo, percentPrice } from "@utils/helpers/payment";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// others
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CampaignInfoContext } from "@views/campaign/campaign-detail";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import dayjs from "dayjs";

export default function WalletBalance() {
  const [isLoading, setIsLoading] = useState(false)

  const { session_token } = getTokenFromStorage();

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const walletBalance = useContext(CampaignInfoContext)?.campaignWalletBalance;
  const tranCampaignInfo = useContext(CampaignInfoContext)?.tranCampaignInfo;

  const campaignInfo = useAppSelector(selectCampaignInfo);
  const infPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);
  const infSelected = infPrivateInfo?.item?.filter((item) => item.status === "listing").length;

  const numFormater = Intl.NumberFormat("en", {
    notation: "standard",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onStartCampaign = () => {
    setIsLoading(true);
    
    const body = {
      employer_id: employerId,
      description: "",
      campaign: {
        id: campaignId,
      },
    };

    PaymentAPI.charge(body)
      .then(() => {
        CampaignAPI.updateCampaignStatus({ status: "published" }, campaignId)
          .then(() => {
            setIsLoading(false);
            history.push(`/campaigns/${campaignId}/info`)
          }).catch((error) => {
            console.log('error : ', error)
            setIsLoading(false);
          })
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: "There are someting wrong",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Close",
        });
      })
  }

  if (isLoading) return <Loading />
  
  return (
    <div>
      <Grid container sx={classes.container}>
        <Grid item xs={4}>
          <Box sx={classes.boxCompensation}>
            <Typography variant="h6">ค่าตอบแทนในการรีวิว</Typography>
            <Box display="flex" justifyContent="space-between" mt="16px">
              <Typography>จำนวนอินฟลูเอนเซอร์</Typography>
              <Typography variant="title1">
                {infSelected} คน
              </Typography>
            </Box>
            {campaignInfo?.campaign_type !== "private" && (
              <Box display="flex" justifyContent="space-between" mt="8px">
                <Typography>ค่าตอบแทนต่อคน</Typography>
                <Typography variant="title1">
                  {numFormater.format(campaignInfo?.budget.each_influencer || 0)} บาท
                </Typography>
              </Box>
            )}

            <Divider sx={classes.dividerCompensation} />

            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">รวมเป็นเงิน</Typography>
              <Typography variant="h6">
                {numFormater.format(walletBalance?.campaign_price || 0)} บาท
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box padding="24px">
            <Typography variant="h6">ประวัติการชำระเงิน</Typography>
            <Box mt="16px">
              <Box display="flex" justifyContent="space-between">
                <Typography>Transaction Date</Typography>
                <Typography variant="title1">
                  {dayjs(tranCampaignInfo?.created_at).format("DD/MM/YYYY HH:mm")}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt="8px">
                <Typography>Transaction No.</Typography>
                <Typography variant="title1">{tranCampaignInfo?.code}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt="8px">
                <Typography>ช่องทางการชำระเงิน</Typography>
                <Typography variant="title1">HashU</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt="8px">
                <Typography>Campaign Budget</Typography>
                <Typography variant="title1">
                  {numFormater.format(tranCampaignInfo?.campaign.total_budget || 0)} บาท
                </Typography>
              </Box>
              <Box sx={classes.boxDeposit}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">
                    • ค่ามัดจำ {depositInfo(tranCampaignInfo?.campaign.total_budget || 0)}%
                  </Typography>
                  <Typography variant="body2">
                    {percentPrice("deposit", tranCampaignInfo?.campaign.total_budget || 0)} บาท
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt="8px">
                  <Typography variant="body2">• ยอดสำรองจ่าย</Typography>
                  <Typography variant="body2">
                    {percentPrice("amount", tranCampaignInfo?.campaign.total_budget || 0)} บาท
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={classes.boxButton}>
        <Button sx={classes.buttonStart} onClick={onStartCampaign}>
          เริ่มแคมเปญ
        </Button>
      </Box>
    </div>
  );
}
