// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Avatar,
  Typography,
  DialogContent,
} from "@mui/material";
// type and style
import { IInfufficientBalanceDialogProps } from "../CampaignDetail.type";
import { useCreatecampaignSuccessStyle as classes } from "../CampaignDetail.style";
// other
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function InfufficientBalanceDialog(props: IInfufficientBalanceDialogProps) {
  const { open, onClose } = props;

  const { session_token } = getTokenFromStorage();

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const history = useHistory();
  const { campaignId } = useParams<{ campaignId: string }>();
  const employerId = params.get("emp_id") || session_token?.employer_id || ""

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent>
        <Box sx={classes.content}>
          <Avatar
            src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
          />
          <Box sx={classes.contentTxt}>
            <Typography>สร้างแคมเปญไม่สำเร็จ</Typography>
            <Typography>ยอดเงินไม่เพียงพอ กรุณาเติมเงิน</Typography>
          </Box>
          <Stack direction="row" spacing={4} sx={classes.applyButton}>
            <Button onClick={() => onClose(false)}>close</Button>
            <Button
              variant="contained"
              onClick={() => history.push(`/employers/${employerId}/campaigns/${campaignId}/payment`)}
            >
              เติมเงิน
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
