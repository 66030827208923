export const useEmployerReviewPostStyle = {
  goBack: {
    display: ["none", "flex"],
    mb: 4,
    "& > span": {
      display: "flex",
      cursor: "pointer",
      marginLeft: "-8px",
    },
  },
  reviewPostCon: {
    borderRadius: "20px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
    padding: "24px",
  },
  submitBtn: {
    width: "280px",
    border: "none",
    color: "#fff",
    fontSize: "16px",
    "&:hover": { border: "none" },
    height: "40px",
  },
};
