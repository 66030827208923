import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// type and api
import { JobAPI } from "@api/global";
import { IInitInfluencerJobInfoState } from "./type";

const initialState: IInitInfluencerJobInfoState = {
  data: null,
  loading: false,
  error: "",
}

export const getInfluencerJobInfoAsync = createAsyncThunk(
  "influencerJobInfoAsync",
  async (req:{ jobId:string, influencerId?:string }) => {
    try {
      const response = await JobAPI.getInfluencerJobInfo(req.jobId, req.influencerId)
      return response
    } catch(err){
      throw(err)
    }
  }
)

export const InfluencerJobInfoSlice = createSlice({
  name:"influencerJobInfoSlice",
  initialState,
  reducers: {
    setInfluencerJobInfo:(state, {payload}) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerJobInfoAsync.pending,(state) => {
        state.data = null;
        state.loading = true;
      })
      .addCase(getInfluencerJobInfoAsync.fulfilled,(state, { payload } ) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(getInfluencerJobInfoAsync.rejected,(state) => {
        state.data = null;
        state.loading = false;
      })
  } 
}) 

export const { setInfluencerJobInfo } = InfluencerJobInfoSlice.actions;

export const selectInfluencerJobInfo = (state: RootState) => state.influencerJobInfo.data;
export const selectInfluencerJobInfoOnload = (state: RootState) => state.influencerJobInfo.loading;

export default InfluencerJobInfoSlice.reducer
