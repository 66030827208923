export const accountType = [
  {
    id: 1,
    value: "saving",
    label: "บัญชีออมทรัพย์",
  },
  {
    id: 2,
    value: "current",
    label: "บัญชีกระแสรายวัน",
  },
];
