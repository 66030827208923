import React from "react";
// mui and icon
import { Box, Grid, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and color
import { useAgeAndGenderDistributionStyle as classes } from "./CampaignReport.style";
import { hGrey, hPink, hPurple } from "@theme/index";
// chartJs and type
import { Pie, Chart } from "react-chartjs-2";
import { ageAndGenderDistributionChart as ageAndGenderDistChart } from "./CampaignReport.chart";

export default function AgeAndGenderDistribution() {
  const femaleData = [30000, 4000, 30000, 30000, 10000, 3000, 30000];
  const maleData = [20000, 43000, 31000, 30300, 40000, 24000, 39000];
  const allData = [
    femaleData[0] + maleData[0],
    femaleData[1] + maleData[1],
    femaleData[2] + maleData[2],
    femaleData[3] + maleData[3],
    femaleData[4] + maleData[4],
    femaleData[5] + maleData[5],
    femaleData[6] + maleData[6],
  ];

  // const Multilabels = [
  //   "13-17",
  //   "18-24",
  //   "25-34",
  //   "35-44",
  //   "45-54",
  //   "55-64",
  //   "65+",
  // ];
  const Multilabels: string[] = [];

  const Multioptions = {
    ...ageAndGenderDistChart.Multioptions,
    scales: {
      ...ageAndGenderDistChart.Multioptions.scales,
      left: {
        ...ageAndGenderDistChart.Multioptions.scales.left,
        title: {
          // display: width === "xs" ? false : true,
          text: "Males | Females",
        },
      },
      right: {
        ...ageAndGenderDistChart.Multioptions.scales.right,
        title: {
          // display: width === "xs" ? false : true,
          text: "Totals",
        },
      },
    },
  };

  const MultiData = {
    ...ageAndGenderDistChart.MultiData,
    datasets: [
      {
        ...ageAndGenderDistChart.MultiData.datasets[0],
        data: femaleData,
      },
      {
        ...ageAndGenderDistChart.MultiData.datasets[1],
        data: maleData,
      },
      {
        ...ageAndGenderDistChart.MultiData.datasets[2],
        data: allData,
      },
    ],
    labels: [...Multilabels],
  };

  // const total = (total: any, num: any) => {
  //   return Number(total + num);
  // };

  // const femaleTotal = MultiData.datasets[0].data.reduce(total);
  // const maleTotal = MultiData.datasets[1].data.reduce(total);
  // const otherTotal = MultiData.datasets[2].data.reduce(total);

  const data = {
    ...ageAndGenderDistChart.data,
    datasets: [
      {
        ...ageAndGenderDistChart.data.datasets[0],
        data: [],
        // data: [femaleTotal, maleTotal, otherTotal],
      },
    ],
  };

  const zip = data.datasets[0].data.map((e, i) => {
    return [e, data.labels[i]];
  });

  // let percentage = (dataPoint: number) => {
  //   const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
  //   return (dataPoint / sum) * 100;
  // };

  // const totals = data.datasets[0].data.reduce(
  //   (a: number, b: number) => a + b,
  //   0
  // );
  return (
    <Box sx={classes.container}>
      <Box sx={classes.ageAndGenderTitle}>
        <Typography>Age and Gender Distribution</Typography>
      </Box>
      <Box sx={classes.ageAndGenderGrid}>
        <Grid container>
          <Grid item xs={12} lg={3} sx={classes.ageAndGenderGridCon}>
            <Box sx={classes.pieChart}>
              <Pie options={ageAndGenderDistChart.options} data={data} />
            </Box>
            <Box sx={classes.ageAndGenderTotalCon}>
              <Grid container display="flex">
                {zip.map((data, index) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      xs={5}
                      sm={4}
                      display="flex"
                      alignItems="center"
                      mb={2}
                    >
                      <CircleIcon
                        sx={{
                          ...classes.circleIcon,
                          color:
                            data[1] === "Female"
                              ? hPink[300]
                              : data[1] === "Male"
                              ? hPurple[100]
                              : hGrey[500],
                        }}
                      />
                      <Typography>{data[1]}</Typography>
                    </Grid>
                    <Grid item xs={7} sm={8}>
                      {/* <Typography color={hGrey[900]}>
                        {new Intl.NumberFormat("jp-JP").format(
                          data[0] as number
                        )}

                        <span>
                          {" (" +
                            percentage(data[0] as number).toFixed(2) +
                            "%)"}
                        </span>
                      </Typography> */}
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={5} sm={4}>
                  {/* <Typography color={hPurple[300]}>Totals</Typography> */}
                </Grid>
                <Grid item xs={7} sm={8}>
                  {/* <Typography color={hPurple[300]}>
                    {new Intl.NumberFormat("jp-JP").format(totals)}
                  </Typography> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Box sx={classes.barChart}>
              <Chart
                type="bar"
                data={MultiData}
                options={Multioptions}
                plugins={ageAndGenderDistChart.chartAreaBorder}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
