// mui and style
import { Box, Button } from "@mui/material";
import { useCampaignInfluencerListStyle as classes } from "./InfluencerList.style";
// component
import InfluencerList from "./start-campaign/InfluencerList";
import CandidateInfluencer from "./influencer-list/InfluencerList";
import PrivateInfluencerList from "../../campaign-private/private-influencer-list-tab";
// other
import { ICampaignDetailProps } from "../CampaignDetail.type";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function CampaignInfluencerListTab(props: ICampaignDetailProps) {
  const { campaignInfo } = props;
  const status = campaignInfo?.status;
  const campaignType = campaignInfo?.campaign_type;
  
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";

  const { campaignId } = useParams<{ campaignId: string }>();

  const goChargeCampaign = () => {
    if (role === "employer") {
      history.push(`/campaigns/${campaignId}/charge`);
    } else {
      history.push(`/campaigns/${campaignId}/charge?emp_id=${employerId}`);
    }
  };

  return (
    <div>
      <Box sx={classes.campDetailCon}>
        {campaignType === "private" ? (
          status === "briefing" || status === "influencer_listing" ? (
            <PrivateInfluencerList />
          ) : (
            <InfluencerList />
          )
        ) : status === "briefing" || status === "influencer_listing" ? (
          <CandidateInfluencer />
        ) : (
          <InfluencerList />
        )}
      </Box>
      {(campaignType === "private" && status === "influencer_listing") && (
        <Box display="flex" justifyContent="center" sx={{ marginTop: "24px" }}>
          <Button onClick={goChargeCampaign} sx={classes.startPrivate}>
            ชำระเงินและเริ่มแคมเปญ
          </Button>
        </Box>
      )}
    </div>
  );
}
