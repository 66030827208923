import React from "react";
// mui
import { Box, Divider, Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
// style and other
import { useInviteFriendsActivityStyle as classes } from "../MyReferral.style";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
import { useAppSelector } from "@store/hook";

export default function InComplete() {
  const influInfo = useAppSelector(selectInfluencerProfile);
  const referralStatus = influInfo?.referer.status;

  return (
    <>
      <Box sx={classes.image}>
        <img
          src={require("@assets/images/referral/invite-friends.png")}
          alt="invite friends"
        />
      </Box>
      <Box>
        <Typography variant="title1">
          คุณสามารถเชิญเพื่อนเข้าร่วม HashU และสะสมคะแนนได้
          เมื่อคุณดำเนินการครบตาม ข้อกำหนดต่อไปนี้
        </Typography>
        <Grid container mt={0} spacing={5}>
          {/* <Divider
            orientation="vertical"
            flexItem
            sx={classes.timlineDivider}
          /> */}
          {[
            {
              label: "สมัครเข้าใช้งาน HashU",
              status: referralStatus?.register,
            },
            {
              label:
                "เข้าหน้า My Profile เพื่อแก้ไขข้อมูลให้ครบถ้วน (เฉพาะ “แก้ไขโปรโฟล์”)",
              status: referralStatus?.edit_profile,
            },
            {
              label:
                "เชื่อมต่อ Social Media ช่องทางใดก็ได้ 1 บัญชี ไม่ว่าจะเป็น Facebook Instagram TikTok หรือ Twitter",
              status: referralStatus?.sync_social,
            },
          ].map((item, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                lg={2}
                xl={1}
                position={index === 0 ? "relative" : undefined}
                sx={classes.timelineBtnBox}
              >
                {index === 0 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={classes.timlineDivider}
                  />
                )}

                <Box
                  sx={
                    item.status
                      ? classes.timelineTrueBtn
                      : classes.timelineFalseBtn
                  }
                >
                  <CheckIcon />
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                lg={10}
                xl={11}
                display="flex"
                alignItems="center"
              >
                <Typography variant="body1">{item.label}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
}
