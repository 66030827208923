import * as yup from "yup";

export const EditEmployerFormSchema = yup.object().shape({
  name: yup.string().required("Please enter your organization name."),
  size: yup
    .string()
    .required("Please select your business size.")
    .nullable(true),
  type: yup.string().required("Please select your type.").nullable(true),
  fullname: yup.string().required("Please enter your full name."),
  phone: yup
    .string()
    .required("Please enter your phone.")
    .matches(
      /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
      "Phone number should be 10 digits"
    ),
});

export const ShippingAddressFormSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter Full Name.")
    .matches(
      /^([A-z\u0E01-\u0E5B]+\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Full name is not valid"
    ),
  phone: yup
    .string()
    .required("Please enter Phone Number.")
    .matches(
      /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
      "Phone number should be 10 digits"
    ),
  province: yup
    .number()
    .required("Please select your province")
    .test("", "", (value) => (value && value <= 0 ? false : true)),
  district: yup
    .number()
    .required("Please select your district")
    .test("", "", (value) => (value && value <= 0 ? false : true)),
  subdistrict: yup
    .number()
    .required("Please select your sub distric")
    .test("", "", (value) => (value && value <= 0 ? false : true)),
  address: yup.string().required("Please enter Address."),
});

export const BillingAddressFormSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter Full Name.")
    .matches(
      /^([A-z\u0E01-\u0E5B]+\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Full name is not valid"
    ),
  tax_id: yup
    .string()
    .required("Please enter Tax Id.")
    .matches(
      /^\(?([0-9]{1})\)?[-]?([0-9]{4})[-]?([0-9]{5})[-]?([0-9]{2})[-]?([0-9]{1})$/,
      "Tax ID should be 13 digits"
    ),
  province: yup
    .number()
    .required("Please select your province")
    .test("", "", (value) => (value && value <= 0 ? false : true)),
  district: yup
    .number()
    .required("Please select your district")
    .test("", "", (value) => (value && value <= 0 ? false : true)),
  subdistrict: yup
    .number()
    .required("Please select your sub distric")
    .test("", "", (value) => (value && value <= 0 ? false : true)),
  address: yup.string().required("Please enter Address."),
});
