export const usePlaceDetailStyle = {
  card: {
    p: "16px",
    mt: "15px",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  btn: {
    justifyContent: "center",
    "& button": {
      minWidth: "220px",
    },
    "& > a": {
      "& button": {
        border: "none",
        "&:hover": { border: "none" },
      },
    },
  },
};
