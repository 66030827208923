// mui and component
import { Box, Icon, Button, Avatar, Typography } from "@mui/material";
import CompanyDetail from "@components/campaign/campaign-detail/CompanyDetail";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// style and other
import { useInfCampaignHeaderStyle as classes } from "./Campaign.style";
import { useHistory, useParams } from "react-router-dom";

export default function Header() {
  const history = useHistory();
  const { influencerId } = useParams<{ influencerId: string }>();

  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const jobInfo = useAppSelector(selectInfluencerJobInfo);

  const profileImage = influencerInfo?.media.images.find((item: any) => {
    return item.purpose === "profile_image";
  });

  const handleContact = (type: string, value?: string) => {
    window.location.href = `${type}:${value}`;
  };

  const goInfluencerProfile = () => {
    history.push(
      `/influencers/${influencerId}/profile?platform=${jobInfo?.campaign_info.platform}&status=view`
    );
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.infprofileBox}>
        <Box display="flex" alignItems="center" columnGap="16px" m="20px">
          <Avatar
            sx={{ width: "75px", height: "77px", borderRadius: "6px" }}
            src={profileImage?.url}
          />
          <Box sx={classes.infContactBox}>
            <Typography variant="h6">{influencerInfo?.fullname}</Typography>
            <Box display="flex" columnGap="8px">
              <Button
                variant="contained"
                onClick={() => goInfluencerProfile()}
                sx={classes.infprofileBtn}
              >
                <Icon
                  className="ico-hu-person"
                  sx={{ mr: "4px", fontSize: "16px" }}
                />
                <Typography variant="buttonS">Profile</Typography>
              </Button>
              <Button
                onClick={() => handleContact("tel", influencerInfo?.phone)}
                sx={{ maxHeight: "32px" }}
              >
                <Icon className="ico-hu-phone" />
              </Button>
              <Button
                onClick={() => handleContact("mailto", influencerInfo?.email)}
                sx={{ maxHeight: "32px" }}
              >
                <Icon className="ico-hu-mail" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width={{ md: "100%", lg: "65%" }}>
        <CompanyDetail jobInfo={jobInfo} />
      </Box>
    </Box>
  );
}
