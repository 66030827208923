// mui and style
import { Box, Grid } from "@mui/material";
import { useInfluencerSocialInfoStyle as classes } from "../InfluencerSocial.style";
// component and type
import SocialPost from "./Post";
import InfluencerRateCard from "./RateCard";
import SocialScreenshort from "./Screenshot";
import OverviewTimeline from "./OverviewTimeline";
import { TPlatform } from "../InfluencerSocial.type";

export default function InfluencerSocialInfo(props: {
  platform: TPlatform;
  email: string;
}) {
  const { platform, email } = props;
  return (
    <Grid container sx={classes.container} wrap="nowrap">
      <Grid item xs={12} sm={6} lg={5}>
        <Box display="flex" flexDirection="column" rowGap={["16px", "30px"]}>
          <OverviewTimeline platform={platform} />
          <InfluencerRateCard platform={platform} email={email} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" flexDirection="column" rowGap={["16px", "30px"]}>
          <SocialPost platform={platform} />
          <SocialScreenshort platform={platform} />
        </Box>
      </Grid>
    </Grid>
  );
}
