// mui
import { Box, Typography, InputAdornment, Icon } from "@mui/material";
// type and style
import { IDateInputProps } from "../CampaignBrief.type";
import { useDateInputStyle as classes } from "../CampaignBrief.style";
// others
import TextFieldCustom from "@global/text-field";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export default function DateInput(props: IDateInputProps) {
  const {
    keyName,
    minDate,
    errors,
    disabled,
    placeholder,
    form: { watch, setValue },
  } = props;

  const dateVal = new Date(watch(keyName as any));

  const isDate = (date: Date | string) => {
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return null;
    }
    return dateObj;
  };

  const handleChangeDate = (date: Date | null, keyName: any) => {
    if (date !== null) return setValue(keyName, date);
  };

  return (
    <Box width="100%" sx={classes.container}>
      <Box className="datepicker-container">
        <DatePicker
          selected={isDate(dateVal)}
          onChange={(date) => handleChangeDate(date, keyName)}
          calendarClassName="datepicker-calendar-container"
          dateFormat="dd/MM/yyyy"
          minDate={minDate ? new Date(minDate) : undefined}
          startDate={minDate ? new Date(minDate) : undefined}
          disabled={disabled ? true : false}
          customInput={
            <div>
              <TextFieldCustom
                fullWidth
                disabled={disabled ? true : false}
                placeholder={placeholder}
                value={
                  isDate(dateVal) ? dayjs(dateVal).format("DD/MM/YYYY") : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className="ico-hu-calendar-date"
                        sx={{
                          cursor: "pointer",
                          color: disabled
                            ? "#a2a2a2"
                            : errors
                            ? "red"
                            : "#8C499C",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "50px",
                    borderColor: errors ? "red" : "#8C499C",
                  },
                }}
              />
            </div>
          }
        />
      </Box>
      {errors && (
        <Typography
          variant="caption"
          color="error.main"
          fontWeight={400}
          ml="15px"
        >
          {errors}
        </Typography>
      )}
    </Box>
  );
}
