import { hPurple } from "@theme/index";

const card = {
  bgcolor: "#fff",
  borderRadius: "16px",
  boxShadow: "0px 4px 10px 0px #3535350D",
};

const cardHeader = {
  bgcolor: "primary.main",
  textAlign: "center",
  height: "42px",
  padding: "0",
  fontWeight: "bold",
  borderRadius: "16px 16px 0px 0px",
};

export const useInfluencerProfileStyle = {
  card: card,
  cardHeader: cardHeader,
};

export const useInfluencerProfileInfoStyle = {
  card: card,
  cardHeader: cardHeader,
  imageBox: {
    overflow: "hidden",
    width: "100%",
    minHeight: { xs: "100%", lg: "300px", xl: "350px" },
    maxHeight: { xs: "200px", lg: "360px", xl: "450px" },
    borderRadius: "20px 20px 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .profile": {
      width: "100%",
      minHeight: { xs: "100%", lg: "300px", xl: "350px" },
      borderRadius: "20px 20px 0 0",
    },
  },
  profileImg: {
    background: "linear-gradient(180deg, rgba(55, 55, 55, 0) 0%, #000000 100%)",
    minHeight: { xs: "100px", lg: "150px" },
    width: "100%",
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  imageListBox: {
    bgcolor: "grey.100",
    borderRadius: "0 0 20px 20px",
  },
  imageProfileItem: {
    display: "flex",
    borderRadius: "0 0 10px 10px",
    overflow: "hidden",
    "& .profile-item": {
      height: { xs: "90px", lg: "120px", xl: "180px" },
      borderRadius: 0,
      width: "100%",
    },
  },
  descriptionBox: {
    textAlign: "center",
    py: 4,
    px: 5,
    backgroundColor: hPurple[100],
    borderRadius: "5px",
    mt: "20px",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    pb: 4,
    mt: "20px",
  },
};

export const useInfluencerPortfolioStyle = {
  card: card,
  cardHeader: cardHeader,
  addressIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
  rateCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  addressDetailBox: {
    paddingTop: "8px",
  },
  profileTitle: {
    "&:hover": {
      opacity: "0.8",
    },
    maxWidth: "300px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  portfolioCardEditIcon: {
    border: "1px solid",
    borderColor: "primary.main",
    fontSize: "16px",
  },
  profileUrl: {
    "&:hover": {
      color: "rgba(0, 0, 0, 0.4)",
    },
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    WebkitLineClamp: 3,
    lineHeight: 2,
    WebkitBoxOrient: "vertical",
    cursor: "pointer",
  },
  prodileDesc: {
    width: "85%",
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  portfolioIconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "6px",
  },
};
