import { useLocation } from "react-router-dom";
// mui
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// style
import { useCreateTeamStepStyle as classes } from "./CreateTeam.style"

export default function CreateTeamStep({ steped }: { steped: string | null }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const step = params.get("st") || steped || "create-team";

  const currentStep =
    step === "add-member" ? 1 : step === "organizing-wallet" ? 2 : 0;

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      {["Create Your Team", "Add Team members", "Organizing Your Wallet"].map(
        (step, index) => (
          <Box key={index} display="flex" alignItems="center" columnGap="8px">
            {index < currentStep ? (
              <CheckCircleIcon
                color="primary"
                sx={{ width: "33px", height: "33px" }}
              />
            ) : (
              <Typography
                variant="title1"
                sx={{
                  ...classes.stepTxt,
                  border: index === currentStep ? "1px solid #8C499C" : "none",
                  bgcolor:  index > currentStep ? "#F5F6F8" : "#fff"
                }}
              >
                {index + 1}
              </Typography>
            )}
            <Typography
              variant="subTitle1"
              fontWeight={index === currentStep ? 500 : 300}
            >
              {step}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}
