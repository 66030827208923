import { useState } from "react";
// mui
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
// style and asset
import { useInstagramProfessionalStyle as classes } from "../InfluencerSocial.style";
import Instagram from "@assets/images/social/ig-glyph-gradient.png";
// component
import Professional from "./sync-social/professional/Professional";
import UnofficialDefault from "../default/UnofficialDefault";

export default function SyncInstagram() {
  const [professional, setProfessional] = useState(false);
  const [personal, setPersonal] = useState(false);

  return (
    <>
      {professional ? (
        <Professional checkComponents={setProfessional} />
      ) : personal ? (
        <UnofficialDefault platform="instagram" checkComponents={setPersonal} />
      ) : (
        <Stack sx={classes.container}>
          <Box sx={classes.syncSocial}>
            <img
              src={Instagram}
              alt="ig-sync"
              style={{ height: "72px", width: "72px" }}
            />
          </Box>
          <Box textAlign="center" width="40%" pt={4}>
            <Typography variant="h5" color={grey[700]}>
              Connect with Instagram
            </Typography>
          </Box>
          <Box textAlign="center" py={5}>
            <Typography variant="body1" mx="10px">
              Instagram Account ของคุณปัจจุบันเป็น Account แบบไหน ?
            </Typography>
          </Box>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent={["center", "flex-end"]}>
                <Button
                  onClick={() => setProfessional(true)}
                  sx={classes.defaultButton}
                >
                  <Typography variant="buttonM">
                    Professional Account
                  </Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent={["center", "flex-start"]}>
                <Button
                  onClick={() => setPersonal(true)}
                  sx={classes.defaultButton}
                >
                  <Typography variant="buttonM">Personal Account</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
}
