import { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Stack,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRateCardStyle as classes } from "../InfluencerSocial.style";
// component and type
import EditInfluencerRateCard from "./EditRateCard";
import { TPlatform } from "../InfluencerSocial.type";
import { ISocialAccountRes } from "@api/influencer/type";
// redux
import {
  getContentTypeAsync,
  selectContentTypelist,
} from "@slices/ContentTypeList.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// other
import { useHistory, useLocation } from "react-router-dom";

const RateCardItem = (props: {
  platform: TPlatform;
  title: string;
  icon: string;
}) => {
  const { platform, title, icon } = props;

  const contentTypeList = useAppSelector(selectContentTypelist);
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const socialnfo = (influencerSocial?.[platform] || [])[0];
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialnfo?.social_account || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  const numFormater = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
  });

  const platformContenType = contentTypeList?.filter(
    (item) => item?.platform === platform
  );

  const influencerRateCard = accountInfo?.rate_card
    .filter((item) => item?.content_type?.includes(title))
    .sort((a, b) => (a?.content_type_id || 0) - (b?.content_type_id || 0));

  const defaultRateCard = platformContenType
    ?.filter((item) => item?.name?.includes(title))
    .sort((a, b) => (a?.id || 0) - (b?.id || 0));

  const HeaderTitle = () => {
    return (
      <div className="d-flex a-i-center">
        <Icon className={icon} color="primary" />
        <Typography
          variant="title1"
          color="primary"
          textTransform="capitalize"
          ml={2}
        >
          {title}
        </Typography>
      </div>
    );
  };

  return influencerRateCard?.length || 0 ? (
    <div>
      <HeaderTitle />
      <Stack mt="6px" spacing={1} ml={1} rowGap="7px">
        {influencerRateCard?.map((item, index) => (
          <div key={index} className="d-flex j-c-space-between">
            <Typography variant="subTitle1">
              {item.content_type.replace("video ", "")}
            </Typography>
            <Typography variant="title1" color="primary">
              {`${numFormater.format(item.price.value)} ${item.price.currency}`}
            </Typography>
          </div>
        ))}
      </Stack>
    </div>
  ) : defaultRateCard?.length || 0 ? (
    <div>
      <HeaderTitle />
      <Stack mt="6px" spacing={1} ml={1} rowGap="7px">
        {defaultRateCard?.map((item, index) => (
          <div key={index} className="d-flex j-c-space-between">
            <Typography variant="subTitle1">
              {item.name.replace("video ", "")}
            </Typography>
            <Typography variant="title1" color="primary">
              {`${numFormater.format(0)} THB`}
            </Typography>
          </div>
        ))}
      </Stack>
    </div>
  ) : null;
};

export default function InfluencerRateCard(props: {
  platform: TPlatform;
  email: string;
}) {
  const { platform, email } = props;

  const [openUpdateRateCard, setOpenUpdateRateCard] = useState(false);

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get("tab");

  useEffect(() => {
    dispatch(getContentTypeAsync());
  }, [dispatch]);

  const onEditRateCard = () => {
    if (smScreen)
      history.push(`/my-profile/mobile/edit-ratecard?platform=${tab}`);
    if (!smScreen) setOpenUpdateRateCard(true);
  };

  return (
    <Box sx={classes.container}>
      <div className="d-flex j-c-space-between">
        <Typography variant="title1">Rate Card</Typography>
        <Icon
          className="ico-hu-edit"
          color="primary"
          fontSize="small"
          sx={{ cursor: "pointer" }}
          onClick={onEditRateCard}
        />
      </div>
      <Divider sx={{ display: { sm: "none" } }} />
      <Box sx={classes.rateCardListBox}>
        <RateCardItem platform={platform} title="video" icon="ico-hu-p-vdo" />
        <RateCardItem platform={platform} title="photo" icon="ico-hu-p-photo" />
        <RateCardItem platform={platform} title="text" icon="ico-hu-p-text" />
      </Box>

      {/* dialog */}
      <EditInfluencerRateCard
        open={openUpdateRateCard}
        onClose={setOpenUpdateRateCard}
        platform={platform}
        email={email}
      />
    </Box>
  );
}
