// mui and style
import { Box, Grid, Icon, Typography } from "@mui/material";
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
// type and context
import { IAddressForm } from "../InfluencerProfile.type";
import { IUpdateAddressProps } from "../InfluencerProfile.type";
// json
import provinces from "@assets/json/province.json";
import districts from "@assets/json/district.json";
import subdistricts from "@assets/json/subDistrict.json";
// other
import TextFieldCustom from "@components/global/text-field";
import BasicSelectCustom from "@components/global/basic-select";
import { formatPhoneNumber, formatTaxId } from "@utils/helpers/formateNumber.helpers";

export default function EditInfluencerAddress(props: IUpdateAddressProps) {
  const { form, addressType, onSubmit } = props;

  const {
    watch,
    setValue,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = form;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: keyof IAddressForm
  ) => {
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const handleAddressChange = (
    e: { target: { value: number } },
    addressName: string
  ) => {
    watch("district");

    const addressId = e.target.value;
    if (addressName === "province") {
      const provinceSelect = provinces.find(
        (province) => province.id === addressId
      );
      setValue("province", provinceSelect || null);
      setValue("district", null);
      setValue("subdistrict", null);
      setValue("postcode", "");
    } else if (addressName === "district") {
      const districtSelect = districts.find(
        (district) => district.id === addressId
      );
      setValue("district.id", (districtSelect?.id as number) || 0);
      setValue("subdistrict", null);
      setValue("postcode", "");
    } else {
      const subdistrictSelect = subdistricts.find(
        (subdistrict: { id: number }) => subdistrict.id === addressId
      );
      setValue("subdistrict.id", (subdistrictSelect?.id as number) || 0);
      setValue("postcode", subdistrictSelect?.postcode || "");
    }
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      my="20px"
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Icon className="ico-hu-billing" color="primary" />
        <Typography
          variant="body1"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {addressType.replaceAll("_", " ")}
        </Typography>
      </Box>
      <Box px={[1.5, 5]} pt={3}>
        {/* Full Name */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ชื่อ นามสกุล<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Full Name"
              fullWidth
              inputProps={{
                ...register("fullname", {
                  required: true,
                }),
              }}
              error={!!errors.fullname}
              helperText={errors.fullname?.message}
            />
          </Grid>
        </Grid>
        {/* phone number || tax id */}
        <Grid container pb={3}>
          {addressType === "shipping_address" ? (
            <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
              <Typography variant="title2" mt={2}>
                เบอร์โทรศัพท์
                <span className="require">*</span> :
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, -1]}>
              <Typography variant="title2" mt={2}>
                เลขประจำตัวผู้เสียภาษี
                <span className="require">*</span> :
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            {addressType === "shipping_address" ? (
              <TextFieldCustom
                value={formatPhoneNumber(watch("phone"))}
                onChange={(e) => handleInputChange(e, "phone")}
                variant="outlined"
                placeholder="Phone"
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            ) : (
              <TextFieldCustom
                value={formatTaxId(watch("tax_id"))}
                onChange={(e) => handleInputChange(e, "tax_id")}
                variant="outlined"
                placeholder="Tax ID / Citizen ID"
                fullWidth
                error={!!errors.tax_id}
                helperText={errors.tax_id?.message}
              />
            )}
          </Grid>
        </Grid>
        {/* Province */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
            <Typography variant="title2">
              จังหวัด<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            <BasicSelectCustom
              id="address-province-select-1"
              value={watch("province.id") || ""}
              onChange={(e) => handleAddressChange(e, "province")}
              data={provinces}
              valueKey="id"
              labelKey="name_th"
              placeholder="Province"
              fullWidth
              error={!!errors.province}
              helperText={(errors.province as any)?.message}
            />
          </Grid>
        </Grid>
        {/* District */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
            <Typography variant="title2">
              อำเภอ/เขต<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            <BasicSelectCustom
              id="address-district-select-1"
              value={watch("district.id") || ""}
              onChange={(e) => handleAddressChange(e, "district")}
              data={districts.filter((dist) => dist.province_id === watch("province.id"))}
              valueKey="id"
              labelKey="name_th"
              placeholder="District"
              fullWidth
              error={!!errors.district}
              helperText={(errors.district as any)?.message}
            />
          </Grid>
        </Grid>
        {/* Sub District */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ตำบล/แขวง<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            <BasicSelectCustom
              id="address-sub-district-select-1"
              value={watch("subdistrict.id") || ""}
              onChange={(e) => handleAddressChange(e, "subdistrict")}
              data={subdistricts.filter(
                (subdist) => subdist.district_id === watch("district.id")
              )}
              valueKey="id"
              labelKey="name_th"
              placeholder="Subdistrict"
              fullWidth
              error={!!errors.subdistrict}
              helperText={(errors.subdistrict as any)?.message}
            />
          </Grid>
        </Grid>
        {/* Zipcode */}
        <Grid container pb={3}>
          <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
            <Typography variant="title2">
              รหัสไปรษณีย์<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              inputProps={{
                ...register("postcode", {
                  required: true,
                }),
              }}
              variant="outlined"
              placeholder="Zipcode"
              fullWidth
              error={!!errors.postcode}
              helperText={errors.postcode?.message}
              disabled
            />
          </Grid>
        </Grid>
        {/* Address */}
        <Grid container pb={0}>
          <Grid item xs={12} md={3} xl={2.5} mt={[0, 0, 2]}>
            <Typography variant="title2">
              ที่อยู่<span className="require">*</span> :
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} xl={9.5} mt={[1.5, 1.5, 0]}>
            <TextFieldCustom
              inputProps={{
                ...register("address", {
                  required: true,
                }),
              }}
              variant="outlined"
              multiline
              rows={2}
              placeholder="Address"
              fullWidth
              error={!!errors.address}
              helperText={errors.address?.message}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center" mt="20px">
        <SaveButton
          type="submit"
          variant="contained"
          startIcon={<Icon className="ico-hu-save" />}
        >
          <Typography variant="button">Save</Typography>
        </SaveButton>
      </Box>
    </Box>
  );
}
