import React, { useState } from "react";
// mui and component
import { Box, Tab, Tabs } from "@mui/material";
import Professional from "./Professional";
import Personal from "./Personal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SyncInstagram() {
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (_e: any, newValue: number) => {
    setValueTab(newValue);
  };
  
  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "24px" }}>
        <Tabs value={valueTab} onChange={handleChangeTab}>
          <Tab
            label="Professional Account"
            sx={{ fontSize: "16px" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Personal Account"
            sx={{ fontSize: "16px" }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={valueTab} index={0}>
        <Professional />
      </TabPanel>
      <TabPanel value={valueTab} index={1}>
        <Personal />
      </TabPanel>
    </div>
  );
}
