export const useInfCampaignHeaderStyle = {
  container: {
    display: "flex",
    flexDirection: { md: "column", lg: "row" },
    columnGap: "24px",
    rowGap: "45px",
  },
  infprofileBox: {
    width: {
      md: "100%",
      lg: "35%",
    },
    bgcolor: "#fff",
    borderRadius: { xs: "8px", lg: "16px" },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  infContactBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "77px",
    width: "100%",
  },
  infprofileBtn: {
    borderRadius: "50px",
    maxHeight: "32px",
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
};

export const useInfCampaignReportTabStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
  },
  button: {
    bgcolor: "#EBDFFF",
    borderColor: "#EBDFFF",
    padding: "8px 16px",
  },
  divider: { m: "24px 0" },
  boxTime: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "0 20px",
  },
  boxDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "20px",
    cursor: "pointer",
  },
  boxSocial: {
    border: "1px solid #ECECEC",
    borderRadius: "12px",
    mb: "20px",
    ml: "64px",
  },
};

export const useInfCampaignTimelineTabStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
  },
  button: {
    bgcolor: "#EBDFFF",
    borderColor: "#EBDFFF",
    padding: "8px 16px",
    maxHeight: "40px",
  },
  divider: { m: "24px 0" },
  stepper: {
    "& .css-1pe7n21-MuiStepConnector-root": {
      ml: "20px",
      borderLeft: "2px solid #EBDFFF",
      "& span": {
        border: "none",
      },
    },
  },
  icon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "150px",
    mr: "20px",
  },
  titleBox: {
    ml: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export const useInfCampaignTimelineInfoStyle = {
  boxSocial: {
    border: "1px solid #ECECEC",
    borderRadius: "12px",
    mb: "20px",
    width: "100%",
  },
  txtEllip: {
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  recieptProductImage: {
    width: "128px",
    height: "128px",
    borderRadius: "8px",
    objectFit: "cover",
  },
  boxAddress: { bgcolor: "#F5F6F8", borderRadius: "8px", padding: "12px" },
  boxPublishLink: {
    display: "flex",
    justifyContent: "space-between",
    bgcolor: "#EBDFFF",
    borderRadius: "50px",
    padding: "4px 12px",
    color: "#8C499C",
    alignItems: "center",
  },
  boxIcon: {
    width: "30px",
    height: "30px",
    bgcolor: "#fff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  attIsActiveBox: {
    position: "relative",
    "& > button": {
      bgcolor: "#4CAF50",
      color: "#fff",
      "&:hover": {
        bgcolor: "#4CAF50",
      },
    },
  },
  attApprovedIcon: {
    top: "-6px",
    right: "-6px",
    maxWidth: "20px",
    minWidth: "20px",
    maxHeight: "20px",
    minHeight: "20px",
    position: "absolute",
  },
  imgItem: {
    width: "120px",
    height: "120px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  titleImgItem: {
    display: "flex",
    color: "red",
    m: "15px 15px 15px 0",
    "& > p": { ml: "5px" },
  },
  imgItemEditingGrid: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "200px",
    "& .viewImage": {
      display: "none",
    },
    maxWidth: "300px",
    "&:hover .viewImage": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      width: {
        xs: "100%",
        sm: "220px",
        md: "240px",
      },
      maxWidth: "350px",
      height: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
    },
    "&:hover .viewBgImage": {
      position: "absolute",
      top: 0,
      width: "100%",
      maxWidth: "350px",
      height: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
      bgcolor: "#000",
      borderRadius: "4px",
      opacity: 0.3,
    },
  },
  imgItemEditing: {
    width: "200px",
    maxWidth: "350px",
    height: "200px",
    borderRadius: "4px",
    "& video": {
      maxWidth: "200px",
      maxHeight: "200px",
      objectFit: "cover",
    },
    overflow: "hidden",
  },
  imgItemEditingDesc: {
    bgcolor: "#FDF3F3",
    width: "90%",
    maxWidth: "330px",
    borderRadius: "8px",
    mt: "16px",
    padding: "12px",
    "& p:first-of-type": {
      color: "red",
      fontWeight: "500",
      fontSize: "14px",
      mb: "12px",
    },
  },
  draftVideo: {
    height: "190px",
    width: "190px",
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    objectFit: "cover",
    "& > .css-1637l5p": {
      borderRadius: "0px",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    maxWith: "350px",
  },
  urlBox: {
    height: "190px",
    width: "190px",
    borderRadius: "8px",
    position: "relative",
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "40px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80px",
      height: "80px",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  captionBox: {
    "& > div": {
      bgcolor: "#F5F6F8",
      borderRadius: "8px",
      p: "16px",
    },
  },
  comment: {
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
    p: "12px",
    width: "100%",
    mt: "16px",
    minHeight: "50px",
  },
  draftBox: {
    columnGap: "10px",
    rowGap: "10px",
    flexWrap: "wrap",
    my: "20px",
  },
  isDraftBox: {
    columnGap: "10px",
    rowGap: "10px",
    flexWrap: "wrap",
    width: "100%",
    mt: "12px",
  },
  imgItemApprove: {
    display: "flex",
    color: "#4CAF50",
    mb: "24px",
    "& > p": { fontWeight: "500", ml: "5px" },
  },
  captionEditingDesc: {
    bgcolor: "#FDF3F3",
    borderRadius: "8px",
    mt: "16px",
    padding: "12px",
    "& p:first-of-type": {
      color: "red",
      fontWeight: "500",
      fontSize: "14px",
      mb: "12px",
    },
  },
  description: {
    bgcolor: "#F5F6F8",
    borderRadius: "8px",
    p: "16px",
    mt: "12px",
  },
  videoComment:{
    bgcolor: "#FDF3F3",
    width: "170px",
    my: "10px",
    borderRadius: "8px",
    p: "10px",
  }
};
