// mui and style
import { Avatar, Box, Chip, Stack, Typography } from "@mui/material";
import { useInfluencerProfileCardStyle as classes } from "../EmployerJob.style";
// style
import CircleIcon from "@mui/icons-material/Circle";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TransgenderOutlinedIcon from "@mui/icons-material/TransgenderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// type and other
import { IInfluencerProfileCardProps } from "../EmployerJob.type";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";

export default function InfluencerProfileCard(
  props: IInfluencerProfileCardProps
) {
  const { socialAccount } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Box sx={classes.postReportCard}>
      <Avatar src={socialAccount?.profile_image_url} />
      <Box sx={classes.cardBoxRight}>
        <Box sx={classes.cardRigthItem1}>
          <SocialIconSelected platform={socialAccount?.platform} />
          <Typography>{socialAccount?.name}</Typography>
        </Box>
        <Stack
          direction="row"
          spacing={3}
          divider={<CircleIcon sx={classes.circle} />}
          alignItems="center"
        >
          {[
            {
              icon: <AccountCircleOutlinedIcon />,
              title: `${numberFormat.format(socialAccount?.follower || 0)} Followers`,
            },
            {
              icon: <PeopleAltOutlinedIcon />,
              title: `Age : ${socialAccount?.age?.min} - ${socialAccount?.age?.max}`,
            },
            {
              icon: <TransgenderOutlinedIcon />,
              title: `Gender : ${socialAccount?.gender.toString()}`,
            },
          ].map((item, index) => (
            <Box key={index} sx={classes.cardRigthItem2}>
              {item.icon}
              <Typography>{item.title}</Typography>
            </Box>
          ))}
        </Stack>
        <Stack direction="row" spacing={2}>
          {socialAccount?.category?.map((item, index) => (
            <Chip key={index} label={item.name_en} sx={classes.chip} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
