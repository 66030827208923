import { useState } from "react";
// mui
import { Box, Icon, Step, Stepper, StepLabel, Typography } from "@mui/material";
// type and style
import { ICampaignDetailProps } from "./CampaignDetail.type";
import { useTimelineStyle as classes } from "./CampaignDetail.style";
// components
import TimelineDraftDialog from "./detail-dialog/TimelineDraft";
import TimelinePlaceDialog from "./detail-dialog/TimelinePlace";
import TimelineAnnounceDialog from "./detail-dialog/TimelineAnnounce";
import TimelinePublishedDialog from "./detail-dialog/TimelinePublished";
import TimelineSendReturnDialog from "./detail-dialog/TimelineSendProduct";
// others
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import dayjs from "dayjs";

export default function Timeline(props: ICampaignDetailProps) {
  const { campaignInfo } = props;
  const [openDraftDialog, setOpenDraftDialog] = useState(false);
  const [openAnnounceDialog, setOpenAnnounceDialog] = useState(false);
  const [openPublishedDialog, setOpenPublishedDialog] = useState(false);
  const [openReviewTypeDialog, setOpenReviewTypeDialog] = useState(false);

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const isInfluencer = role === "influencer";
  const period = campaignInfo?.period;
  const timeline = campaignInfo?.timeline;
  const draftPhase = timeline?.draft_phase || [];
  const prdReviewType = campaignInfo?.product_review_type;
  const isProduct = prdReviewType === "send" || prdReviewType === "send_return";
  const isPlace = campaignInfo?.review_type === "place";

  const dateFormat = (date?: string) => {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  };

  const timelineList = [
    {
      id: "start_recruiting_date",
      label: "ประกาศรับสมัคร",
      subLabel: `${dateFormat(timeline?.start_recruiting_date)} - ${dateFormat(
        timeline?.end_recruiting_date
      )}`,
      icon: "ico-hu-group",
      readMore: false,
      openReadMore: () => setOpenAnnounceDialog(true),
      isActive: period?.recruiting,
      display: "flex",
    },
    {
      id: "announce_date",
      label: "ประกาศผลผู้เข้าร่วมแคมเปญ",
      subLabel: dateFormat(timeline?.announce_date),
      icon: "ico-hu-announce",
      readMore: false,
      openReadMore: () => setOpenAnnounceDialog(true),
      isActive: period?.annouce,
      display: "flex",
    },
    {
      id: "shipping_date",
      label: isPlace
        ? "วันเข้าไปถ่ายทำหรือรีวิวสถานที่"
        : "จัดส่งสินค้าสำหรับการรีวิว",
      subLabel: isPlace
        ? `${dateFormat(timeline?.shipping_date)} - ${dateFormat(
            timeline?.end_shipping_date
          )}`
        : dateFormat(timeline?.shipping_date),
      icon: isPlace ? "ico-hu-location" : "ico-hu-shipping-outline",
      readMore: isInfluencer,
      openReadMore: () => setOpenReviewTypeDialog(true),
      isActive: period?.shipping,
      display: isPlace || isProduct ? "flex" : "none",
    },
    {
      id: "draft_phase",
      label: "ตรวจ Draft จากอินฟลูเอนเซอร์",
      subLabel: `${dateFormat(draftPhase?.[0]?.start)} - ${dateFormat(
        draftPhase?.[4]?.end
      )}`,
      icon: "ico-hu-content",
      readMore: isInfluencer,
      openReadMore: () => setOpenDraftDialog(true),
      isActive: period?.draft_phase,
      display: isProduct || prdReviewType === "no_shipping" ? "flex" : "none",
    },
    {
      id: "posting_period",
      label: "โพสต์และแนบลิงค์ส่งงาน",
      subLabel: `${dateFormat(timeline?.start_publish_date)} - ${dateFormat(
        timeline?.end_publish_date
      )}`,
      icon: "ico-hu-publishing",
      readMore: isInfluencer,
      openReadMore: () => setOpenPublishedDialog(true),
      isActive: period?.publish,
      display: "flex",
    },
    {
      id: "completed_date",
      label: "สิ้นสุดแคมเปญ",
      subLabel: dateFormat(timeline?.completed_date),
      icon: "ico-hu-campaign-done",
      readMore: false,
      isActive: period?.complete,
      display: "flex",
    },
  ];

  const timelineActive = timelineList.filter((timeline) => timeline.isActive);
  const activeLength =
    isPlace || isProduct ? timelineActive.length : timelineActive.length - 1;

  return (
    <Box sx={classes.con}>
      {timelineList.map((timeline, index) => (
        <Stepper
          key={index}
          orientation="vertical"
          sx={{ display: timeline.display }}
        >
          <Step expanded>
            <StepLabel
              sx={{ p: 0 }}
              icon={
                <Icon
                  className={timeline.icon}
                  sx={
                    activeLength - 1 === index
                      ? classes.stepLabelIconActive
                      : index < activeLength
                      ? classes.stepLabelIconSuccess
                      : classes.stepLabelIconNotActive
                  }
                />
              }
            >
              <Box display="flex" alignItems="center">
                <Typography variant="title1" ml={2} color="#8C499C">
                  {timeline.label}
                </Typography>
                {timeline.readMore && (
                  <Box onClick={timeline.openReadMore}>
                    <Icon
                      className="ico-hu-info-circle-outline"
                      sx={{ ml: "5px", fontSize: "20px", cursor: "pointer" }}
                    />
                  </Box>
                )}
              </Box>
              <Typography variant="body2" ml={2} mt={1}>
                {timeline.subLabel}
              </Typography>
            </StepLabel>
            {timelineList.length - 1 !== index && (
              <Box
                sx={
                  activeLength - 1 === index
                    ? classes.lineIsActive
                    : index < activeLength
                    ? classes.lineSuccess
                    : classes.lineNotActive
                }
              />
            )}
          </Step>
        </Stepper>
      ))}
      <TimelineAnnounceDialog
        open={openAnnounceDialog}
        onClose={setOpenAnnounceDialog}
        announceDate={timeline?.announce_date}
      />
      <TimelinePublishedDialog
        open={openPublishedDialog}
        onClose={setOpenPublishedDialog}
        timeline={timeline}
      />
      <TimelineDraftDialog
        open={openDraftDialog}
        onClose={setOpenDraftDialog}
        timeline={timeline}
        isProduct={isProduct}
        isPlace={isPlace}
        orgUrl={campaignInfo?.organisation?.attachment?.url}
      />
      {campaignInfo?.review_type === "product" ? (
        <TimelineSendReturnDialog
          open={openReviewTypeDialog}
          onClose={setOpenReviewTypeDialog}
          timeline={timeline}
        />
      ) : (
        <TimelinePlaceDialog
          open={openReviewTypeDialog}
          onClose={setOpenReviewTypeDialog}
          timeline={timeline}
        />
      )}
    </Box>
  );
}
