import tiktok from "@assets/images/social/tiktok-only.png";
import youtube from "@assets/images/social/youtube-only.png";
import twitter from "@assets/images/social/twitter_5969020.png";
import facebook from "@assets/images/social/facebook-only.png";
import instagram from "@assets/images/social/instagram-only.png";

export const initJobBoardFilter = {
  page: 1,
  item_per_page: 8,
  campaign_type: "",
  platform: "",
  review_type: "",
  category: [],
  sort: "",
  order_by: "",
};

export const platformList = [
  {
    key: "facebook",
    label: "Facebook",
    url: facebook,
  },
  {
    key: "instagram",
    label: "Instagram",
    url: instagram,
  },
  {
    key: "youtube",
    label: "Youtube",
    url: youtube,
  },
  {
    key: "twitter",
    label: "Twitter",
    url: twitter,
  },
  {
    key: "tiktok",
    label: "Tiktok",
    url: tiktok,
  },
];

export const reviewTypeList = [
  {
    key: "",
    label: "All",
  },
  {
    key: "product",
    label: "Product (สินค้า)",
  },
  {
    key: "place",
    label: "Place (สถานที่)",
  },
];

export const sortDateBudgetList = {
  date: [
    {
      value: "dateDESC",
      label: "ใหม่ - เก่า",
      sort: "DESC",
    },
    {
      value: "dateASC",
      label: "เก่า - ใหม่",
      sort: "ASC",
    },
  ],
  budget: [
    {
      value: "budgetDESC",
      label: "ค่าตอบแทน มาก - น้อย",
      sort: "DESC",
    },
    {
      value: "budgetASC",
      label: "ค่าตอบแทน น้อย - มาก",
      sort: "ASC",
    },
  ],
};

export const campaignTypeList = [
  {
    key: "",
    label: "All",
  },
  {
    key: "public",
    label: "ประกาศรับสมัคร",
  },
  {
    key: "private",
    label: "ยื่นข้อเสนอให้",
  },
];

export const initStatusTotal = {
  status: "ทั้งหมด",
  offering: 0,
  public: 0,
  total: 0,
};
