import { useContext, useState } from "react";
// mui
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Divider, Grid, Typography } from "@mui/material";
// style and sweet alert
import { useWalletNotBalanceStyle as classes } from "./CampaignCharge.style";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
import { selectPaymentMethod } from "@slices/PaymentMethod.slice";
import { selectInfluencerPrivateCampaign } from "@slices/CampaignPrivate.slice";
// api and type
import { PaymentAPI } from "@api/global";
import { IPaymentMethodData } from "@slices/type";
// util
import { depositInfo, percentPrice } from "@utils/helpers/payment";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// other
import { useLocation, useParams } from "react-router-dom";
import { CampaignInfoContext } from "@views/campaign/campaign-detail";
import dayjs from "dayjs";

export default function WalletNotBalance() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const walletBalance = useContext(CampaignInfoContext)?.campaignWalletBalance;
  const isWalletBalance = (walletBalance?.total || 0) >= 0;
  const tranCampaignInfo = useContext(CampaignInfoContext)?.tranCampaignInfo;

  const { session_token } = getTokenFromStorage()

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const paymentMethodList = useAppSelector(selectPaymentMethod);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const campaignInfo = useAppSelector(selectCampaignInfo)
  
  const infPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);
  const infSelected = infPrivateInfo?.item?.filter((item) => item.status === "listing").length;

  const isTeam = employerInfo?.package_plan === "team";

  const internetBanking = paymentMethodList?.find((method) => method.id === 1);
  const mobileBanking = paymentMethodList?.find((method) => method.id === 2);
  const creditCard = paymentMethodList?.find((method) => method.id === 3);
  const eWallet = paymentMethodList?.find((method) => method.id === 4);
  const qrPayment = paymentMethodList?.find((method) => method.id === 5);

  const numFormater = Intl.NumberFormat("en", {
    notation: "standard",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleChangeExpend = (panel: string) => (_event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChillPayPublisher = (data?: IPaymentMethodData) => {
    const plan = isTeam ? { team_id: employerInfo.team?.id || "" } : { employer_id: employerId }
    const body = {
      ...plan,
      amount: (walletBalance?.total || 0) * -1,
      description: "charge-campaign",
      pay_type: {
        id: 2,
      },
      pay_method: {
        id: data?.id || 0,
      },
      attachment: null,
      campaign:{
        id: campaignId
      }
    };
    PaymentAPI.chillpayPublisher(body)
      .then((response) => {
        if (response.PaymentUrl) {
          window.location.replace(response.PaymentUrl);
        } else {
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${response.Message}`,
            showCancelButton: true,
            cancelButtonText: "Close",
            showConfirmButton: false,
          });
        }
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `มีบางอย่างผิดพลาด`,
          showCancelButton: true,
          cancelButtonText: "Close",
          showConfirmButton: false,
        });
      });
  };

  return (
    <Grid container columnGap={7} flexWrap="nowrap" sx={classes.container}>
      <Grid item xs={4}>
        {campaignInfo?.campaign_type === "private" ? (
          <Box sx={classes.summaryWrap}>
            <Typography variant="h6">ประวัติการชำระเงิน</Typography>
            <Box my="10px" display="flex" flexDirection="column" rowGap={2}>
              {[
                {
                  label: "วันที่ทำรายการ",
                  value: dayjs(tranCampaignInfo?.created_at).format("DD/MM/YYYY HH:mm"),
                },
                {
                  label: "เลขที่รายการ",
                  value: tranCampaignInfo?.code,
                },
                {
                  label: "ช่องทางการชำระเงิน",
                  value: "HashU",
                },
                {
                  label: "Campaign Budget",
                  value: `${numFormater.format(tranCampaignInfo?.campaign.total_budget || 0)} บาท`,
                },
              ].map((item, index) => (
                <Box key={index} display="flex" justifyContent="space-between">
                  <Typography>{item.label}</Typography>
                  <Typography fontWeight={500}>{item.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box component="ul" sx={classes.depositeWrap}>
              <Box component="li" ml={6}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">
                    ค่ามัดจำ {depositInfo(tranCampaignInfo?.campaign.total_budget || 0)}%
                  </Typography>
                  <Typography variant="body2">
                    {percentPrice("deposit", tranCampaignInfo?.campaign.total_budget || 0)} บาท
                  </Typography>
                </Box>
              </Box>
              <Box component="li" ml={6}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">ยอดสำรองจ่าย</Typography>
                  <Typography variant="body2">
                    {percentPrice("amount", tranCampaignInfo?.campaign.total_budget || 0)} บาท
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ my: "20px" }} />

            <Typography variant="h6">สรุปค่าใช้จ่าย</Typography>
            <Box my="10px" display="flex" flexDirection="column" rowGap={2}>
              {[
                {
                  label: "จำนวนที่ตอบรับข้อเสนอ",
                  value: `${infPrivateInfo?.item?.filter((item) => item.status === "is_selecting").length} คน`,
                },
                {
                  label: "รวมเป็นเงิน",
                  value: `${numFormater.format(walletBalance?.campaign_price || 0)} บาท`,
                },
                {
                  label: "Campaign Budget",
                  value: `${numFormater.format(walletBalance?.original_budget || 0)} บาท`,
                },
                {
                  label: "Wallet Balance",
                  value: `${numFormater.format(walletBalance?.balance || 0)} บาท`,
                },
              ].map((item, index) => (
                <Box key={index} display="flex" justifyContent="space-between">
                  <Typography>{item.label}</Typography>
                  <Typography fontWeight={500}>{item.value}</Typography>
                </Box>
              ))}
            </Box>
            <Divider sx={{ my: "20px" }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" color="#893DBD">
                Top Up
              </Typography>
              <Typography variant="h5" color="#893DBD">
                {numFormater.format(Math.abs(walletBalance?.total || 0))} บาท
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={classes.summaryWrap}>
          <Typography variant="h6">ค่าตอบแทนในการรีวิว</Typography>
          <Box display="flex" justifyContent="space-between" mt="16px">
            <Typography>จำนวนอินฟลูเอนเซอร์</Typography>
            <Typography variant="title1">
              {infSelected} คน
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mt="8px">
            <Typography>ค่าตอบแทนต่อคน</Typography>
            <Typography variant="title1">
              {numFormater.format(campaignInfo?.budget.each_influencer || 0)} บาท
            </Typography>
          </Box>

          <Divider sx={{ my:"15px" }} />

          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" color="primary">Top Up</Typography>
            <Typography variant="h6" color="primary">
              {numFormater.format(Math.abs(walletBalance?.total || 0))} บาท
            </Typography>
          </Box>
        </Box>
        )}
      </Grid>
      <Grid item xs={8}>
        <Box>
          <Typography variant="h6">
            {`${!isWalletBalance ? "เลือก" : ""}ช่องทางการชำระเงิน`}
          </Typography>
          {isWalletBalance ? (
            <Box sx={classes.isWalletBalanceBox}>
              <Typography variant="h6">HashU Wallet</Typography>
              <Box mt="16px">
                <Typography variant="h4">
                  {numFormater.format(walletBalance?.total || 0)} THB
                </Typography>
                {/* <Typography>12,000.00 THB หมดอายุวันที่ 17/12/2024</Typography> */}
              </Box>
            </Box>
          ) : (
            <Box sx={classes.accordiun}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeExpend("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ height: "64px", p: "24px" }}
                >
                  <Typography variant="title1">QR Payment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4} p="0 15px 15px 15px">
                    {qrPayment?.data.map((li, index) => (
                      <Grid item key={index} xs={4} xl={3}>
                        <Box
                          onClick={() => onChillPayPublisher(li)}
                          sx={classes.accordionDetails}
                        >
                          <Avatar
                            src={li.image_logo_url?.[0]}
                            sx={classes.qrPaymentImg}
                          >
                            {li.title.charAt(0)}
                          </Avatar>
                          <Typography ml="10px" textTransform="capitalize">
                            {li.title.replace("_", " ")}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChangeExpend("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ height: "64px", p: "24px" }}
                >
                  <Typography variant="title1">e-Wallet</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4} p="0 15px 15px 15px">
                    {eWallet?.data.map((li, index) => (
                      <Grid item xs={4} xl={3} key={index}>
                        <Box
                          onClick={() => onChillPayPublisher(li)}
                          sx={classes.accordionDetails}
                        >
                          <Avatar
                            src={li.image_logo_url?.[0]}
                            sx={classes.methodImg}
                          >
                            {li.title.replace("epayment_", "").charAt(0)}
                          </Avatar>
                          <Typography ml="10px" textTransform="capitalize">
                            {li.title.replace("epayment_", "")}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChangeExpend("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ height: "64px", p: "24px" }}
                >
                  <Typography variant="title1">Credit / Debit Card</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4} p="0 15px 15px 15px">
                    {creditCard?.data.map((li, index) => (
                      <Grid item xs={4} xl={3} key={index}>
                        <Box
                          onClick={() => onChillPayPublisher(li)}
                          sx={classes.accordionDetails}
                        >
                          <Avatar
                            src={li.image_logo_url?.[0]}
                            sx={classes.craditImg}
                          >
                            {li.title.charAt(0)}
                          </Avatar>
                          <Typography ml="10px" textTransform="capitalize">
                            {li.title}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChangeExpend("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ height: "64px", p: "24px" }}
                >
                  <Typography variant="title1">Mobile Banking</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4} p="0 15px 15px 15px">
                    {mobileBanking?.data.map((li, index) => (
                      <Grid item xs={4} xl={3} key={index}>
                        <Box
                          onClick={() => onChillPayPublisher(li)}
                          sx={classes.accordionDetails}
                        >
                          <Avatar
                            src={li.image_logo_url?.[0]}
                            sx={classes.methodImg}
                          >
                            {li.title.charAt(0)}
                          </Avatar>
                          <Typography ml="10px" textTransform="capitalize">
                            {li.title.replace("_", " ")}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChangeExpend("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ height: "64px", p: "24px" }}
                >
                  <Typography variant="title1">Internet Banking</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4} p="0 15px 15px 15px">
                    {internetBanking?.data.map((li, index) => (
                      <Grid item xs={4} xl={3} key={index}>
                        <Box
                          onClick={() => onChillPayPublisher(li)}
                          sx={classes.accordionDetails}
                        >
                          <Avatar
                            src={li.image_logo_url?.[0]}
                            sx={classes.methodImg}
                          >
                            {li.title.charAt(0)}
                          </Avatar>
                          <Typography ml="10px" textTransform="capitalize">
                            {li.title.replace("_", " ")}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
