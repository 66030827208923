import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Introduction() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>1. Introduction</Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>
        The Company is Masterplan Media Co., Ltd. who is the intermediate
        between the Influencer and the Marketer. The Company needs to create a
        new powerful communication.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The Company believes that building awareness from the user experience
        coupled with brand engagement is essential to making communication
        meaningful, memorable, and beyond conventional advertising. These will
        cause a new communication phenomenon more efficient and more accurate
        measurement.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        Due to the intermediary between Influencer and Marketer, the Company is
        obliged to collect your information to adopt and/or communicate to
        people interested in participating in the operating system of HashU
        ("HashU Platform"). The Company, Masterplan Media Co., Ltd., is a Data
        Controller in accordance with the Personal Data Protection Act B.E. 2562
        ("Personal Data Protection Act").
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        Concerning the privacy of those of you who are honored to create a new
        communication phenomenon. We have established this Privacy Policy to
        inform all of you who are the Influencer are aware of the following
        matters:
      </Typography>
      <Box>
        <Typography> ● Introduction</Typography>
        <Typography> ● Who is the Company?</Typography>
        <Typography>
          ● The Company collects your information for what purpose?
        </Typography>
        <Typography>
          ● What information does the Company collect to use or transmit?
        </Typography>
        <Typography>
          ● Whom can the Company allow access to your information?
        </Typography>
        <Typography>
          ● How does the Company consider the rights of the person who owns the
          information?
        </Typography>
        <Typography> ● Usage, Maintenance, Protection</Typography>
        <Typography> ● Policy amendment.</Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          This Privacy Policy applies to information the Company has collected
          from:
        </Typography>
        <Typography> ● HashU Platform</Typography>
        <Typography>
          ● Emails, texts, and other electronic messages you communicate with
          the HashU Platform.
        </Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          This Privacy Policy does not apply to information obtained from:
        </Typography>
        <Typography>
          ● Offline channels or through other means operated by the Company or
          third party (including affiliates and its subsidiaries), or
        </Typography>
        <Typography>
          ● The application, the content (including advertisements), or any
          means of third-party (including affiliates and its subsidiaries) may
          link or access the HashU Platform.
        </Typography>
      </Box>

      <Typography sx={{ marginTop: "20px" }}>
        The Company asks you to read this Privacy Policy carefully to understand
        the Company's policy, procedures, and practices for handling your
        information. If you see that the policy, procedures, and practices do
        not meet your desires, you can choose not to use the HashU Platform.
        However, you consider that the Company's policy, procedures, and
        practices, adequately protect your information, the Company would like
        you to confirm your acknowledgment and accept this policy by pressing
        the “Accept” button displayed on your screen before using HashU
        Platform.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The Company would like to inform you whether the Company may resolve
        changes to this Privacy Policy to comply with the rules, regulations,
        announcements, or laws that will occur in the future (Please find in the
        topic Policy Amendment). The Company will do its best to notify you of
        such changes (without regard to the Company's obligations). The Company
        would like to encourage you to read the Privacy Policy from time to time
        to understand the policy, procedures, and practices regarding your
        information. If the HashU Platform provides notice of a policy amendment
        and you still consider that your information is properly protected from
        the amended policy, the Company would like you to accept this Privacy
        Policy (Revised Edition) by pressing the “Accept” button displayed on
        your screen before using HashU Platform further.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        If you are a person under 20 years of age, the Company would like to
        inform you that after you press the "Accept" button, the Company may
        have an additional process for you which the Company will notify you
        according to the contact channel you specified.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        However, in some cases where you give consent to the Company to control
        your personal data, you can withdraw such consent provided to the
        Company at any time by the means and channels set forth by the Company
        in Section 2. Such withdrawal of consent will not affect the Company's
        actions that have been performed prior to receiving the notice of
        withdrawing your consent.
      </Typography>
    </Box>
  );
}
