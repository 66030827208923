// mui and logo
import {
  Box,
  Backdrop,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Logo from "@assets/images/logo/hashu-logo1.svg";
// style
import { useLoadingStyle as classes } from "./Loading.style";

export default function Loading() {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const CircularProgressWithLabel = () => {
    return (
      <Box position="relative" display="inline-flex">
        <Box>
          <CircularProgress
            variant="determinate"
            size={smScreen ? 100 : 200}
            thickness={2.5}
            value={100}
            sx={{ color: "rgba(190, 160, 200, 0.8)" }}
          />
          <CircularProgress
            size={smScreen ? 100 : 200}
            thickness={2.5}
            sx={classes.loadingTop}
          />
        </Box>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={classes.logoLoading}>
            <img src={Logo} alt="loding-pic" />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Backdrop sx={classes.backDrop} open>
      <Box textAlign="center">
        <CircularProgressWithLabel />
      </Box>
    </Backdrop>
  );
}
