import { useEffect } from "react";
// mui
import { Box, CircularProgress, Typography } from "@mui/material";
import { useInfluencerSocialPendingStyle as classes } from "../InfluencerSocial.style";
// redux
import { useAppDispatch } from "@store/hook";
import { getInfluencerSocialAsync } from "@slices/InfluencerSocial.slice";
// type
import { TPlatform } from "../InfluencerSocial.type";

export default function InfluencerSocialPending(props: { platform: TPlatform }) {
  const { platform } = props;

  const dispatch = useAppDispatch();
  let count = 0;

  useEffect(() => {
    async function check() {
      if (count < 12) {
        const influencer = await dispatch(getInfluencerSocialAsync({}));
        const payload: any = influencer.payload;
        if (!payload[platform][0].social_account.length) {
          setTimeout(() => {
            count++;
            check();
          }, 5000);
        }
      }
    }

    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={classes.container}>
      <CircularProgress size={150} />
      <Box mt="24px">
        <Typography variant="h3">กำลังดึงข้อมูล...</Typography>
      </Box>
    </Box>
  );
}
