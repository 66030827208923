export const useFilterJobBoardStyle = {
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: [0, "25px 0 0 25px"],
    },
    overflowY: "initial",
  },
  drawerCon: {
    borderRadius: "8px",
    width: ["91.5%", "390px"],
    px: "18px",
  },
  filterTitle: {
    padding: "12px",
    position: "sticky",
    bgcolor: "#fff",
    borderRadius: "25px 0 0 0",
    borderBottom: "2px solid #E0E0E0",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    left: 0,
    top: 0,
    mx: "-18px",
    "& > .MuiSvgIcon-root": {
      cursor: "pointer",
      mr: "5px",
    },
  },
  titleItem: {
    padding: "12px",
    "& > p": {
      fontWeight: 600,
      mb: "10px",
    },
    overflowY: "initial",
  },
  line: {
    margin: "5px 13px",
    borderColor: "1px solid #E0E0E0",
  },
  filterBottom: {
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: {
      xs: "95%",
      sm: "400px",
    },
    bottom: 0,
    bgcolor: "#fff",
    borderTop: "2px solid #E0E0E0",
    borderRadius: {
      xs: 0,
      sm: "0 0 0 26px",
    },
    "& > p": {
      color: "#893DBD",
      cursor: "pointer",
    },
    right: 0,
  },
  chip: {
    minWidth: "60px",
    margin: "0 12px 12px 0",
    border: "1px solid #5C5C5C",
    "&:hover": {
      border: "1px solid #F4ECFF",
      color: "#893DBD",
      bgcolor: "#F4ECFF",
    },
  },
  buttonApply: {
    width: "118px",
    height: "32px",
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
  filterBtnMyJob: {
    position: "fixed",
    width: {
      xs: "93%",
      sm: "400px",
    },
  },
};

export const useTabJobBoardStyle = {
  divider: {
    height: "20px",
    mr: "11px",
    border: "1.3px solid #7f7f7f",
    bgcolor: "#7f7f7f",
    borderRadius: "10px",
  },
  tabButton: {
    maxHeight: "32px",
    mr: "12px",
    minHeight: "32px",
    borderRadius: "50px",
    border: "1px solid #9E9E9E",
  },
  paper:{
    width: "178px",
    display: "flex",
    border: "1px solid #8C499C",
    height: "30px",
    boxShadow: "none",
  },
  tabFilter: {
    minHeight: "32px",
    maxHeight: "32px",
    borderRadius: "50px",
    color: "#5C5C5C",
    bgcolor: "#ffff",
    border: "1px solid #9E9E9E",
    px: "15px",
    fontSize: "16px",
    fontWeight: 400,
    "&:hover": {
      border: "1px solid #9E9E9E",
    },
    ml: "10px",
  },
};

export const useRegistCampaignSuccessDialogStyle = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    my: "20px",
    mx: "10px",
    "& > .MuiAvatar-root": {
      width: "240px",
      height: "240px",
      borderRadius: "10px",
    },
  },
  contentTxt: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    my: "10px",
  },
  applyButton: {
    mt: "16px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "10px",
    columnGap: "10px",
    "& button:first-of-type": {
      width: "100%",
      maxHeight: "40px",
      fontSize: "14px",
    },
    "& button:last-of-type": {
      width: "100%",
      maxHeight: "40px",
      fontSize: "14px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};
