import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Alert,
  Avatar,
  Button,
  Divider,
  useTheme,
  Collapse,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
// api and type
import { InfluencerAPI } from "@api/global";
import { ICategoryDialog, IGender } from "./Category.type";
// component
import Gender from "./Genders";
import Category from "./Category";
// theme and style
import { categoryDialogStyle as classes } from "./Category.style";
import TextFieldCustom from "@global/text-field/index";
// redux
import {
  getCategoryAsync,
  selectCategoryList,
} from "@slices/CategoryList.slice";
import { useAppSelector, useAppDispatch } from "@store/hook";
import { updateInfluencerSocial } from "@slices/InfluencerSocial.slice";
// other
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SwalCustom from "../global/sweet-alert-custom/SwalCustom";
import DialogScrollingCustom from "@global/dialog-scrolling-custom/index";

const initCategory = {
  id: 0,
  name_en: "",
  name_th: "",
  value: "",
};
const initGender: any = {
  id: 0,
  value: "",
  title: "",
};
const initCategoryDialog: any = {
  social_account_category: [initCategory],
  min: 1,
  max: 0,
  target_gender: [initGender],
};

export default function CategoryDialog(props: ICategoryDialog) {
  const { open, onClose, socialAccountId, platform, profile } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { setValue, handleSubmit, reset, clearErrors, watch } = useForm<any>({
    // resolver: yupResolver(BankAccountFormSchema),
    defaultValues: initCategoryDialog,
  });

  const history = useHistory();
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(selectCategoryList);

  const [categorySelected, setCategorySelected] = useState<any>([]);
  const [genderSelected, setGenderSelected] = useState<any>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const genderList = [
    { id: "male", value: "male", title: "Male" },
    { id: "female", value: "female", title: "Female" },
    { id: "lgbtq", value: "lgbtq", title: "LGBTQ+" },
  ];

  useEffect(() => {
    if (profile) {
      const form = {
        social_account_category: profile.category,
        min: profile.target_age.min,
        max: profile.target_age.max,
        target_gender: profile.target_gender,
      };
      reset(form);
      // Category
      if (profile.category.length === 0) {
        setCategorySelected([]);
      } else {
        setCategorySelected(profile.category);
      }
      // Gender
      if (profile.target_gender.length === 0) {
        setGenderSelected([]);
      } else {
        setGenderSelected(profile.target_gender);
      }
    }
  }, [profile, reset]);

  useEffect(() => {
    dispatch(getCategoryAsync());
  }, [dispatch]);

  const handleChange = (e: any, name: any) => {
    if (Number(e.target.value) > 100) return;
    setValue(name, e.target.value);
    clearErrors(name);
  };

  const onSubmit = async (data: any) => {
    const newData = categorySelected.map((item: any) => {
      return {
        platform: platform.toLowerCase(),
        category_id: item.id,
      };
    });

    try {
      const reqBody = {
        social_account_id: socialAccountId,
        social_account_category: newData,
        target_age: {
          min: Number(data.min),
          max: Number(data.max),
        },
        target_gender: genderSelected,
      };

      InfluencerAPI.updateInfluencerCategory(reqBody).then((response) => {
        handleClose();
        dispatch(updateInfluencerSocial(response));
        SwalCustom.fire({
          icon: "success",
          title: "Updated category success",
          showConfirmButton: false,
          timer: 1600,
        });
      });
    } catch (error: any) {
      const error_message = error.response.data.error.message;
      if (
        error_message ===
        "error, category must less than or equal to 3 but not equal to 0."
      ) {
        setAlertMessage("เลือก category อย่างน้อย 1 แต่ไม่เกิน 3");
        setShowAlert(true);
      } else if (error_message === "error, gender is empty") {
        setAlertMessage("เลือกกลุ่มเป้าหมายที่คุณต้องการ");
        setShowAlert(true);
      } else if (
        error_message === "error, target age value must be at least 1"
      ) {
        setAlertMessage("อายุกลุ่มเป้าหมายตั้งแต่ 1 ขึ้นไป");
        setShowAlert(true);
      } else {
        setAlertMessage("การยืนยันตัวตนไม่สำเร็จ กรุณายืนยันตัวตนใหม่อีกครั้ง");
        setShowAlert(true);
      }
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const goToSelectCategory = () => {
    history.push(`/select-category`, { profile, platform });
  };

  return (
    <DialogScrollingCustom
      title={`${platform} Account`}
      open={open}
      onClose={handleClose}
    >
      {matches ? (
        <Box p="24px">
          <Box display="flex" justifyContent="center">
            <Typography variant="h5">การเชื่อมต่อสำเร็จ</Typography>
          </Box>
          <Typography variant="body1" fontSize="16px">
            ขั้นตอนต่อไปกรุณาเลือกหมวดหมู่ และกลุ่มเป้าหมายของคุณ
          </Typography>
          <Box display="flex" justifyContent="center" m="14px 0 0">
            <Button onClick={goToSelectCategory}>ตกลง</Button>
          </Box>
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p="0px 20px 1px">
            <Box sx={classes.boxProfileDialog}>
              <Avatar
                variant="square"
                src={profile?.profile_image}
                sx={classes.imageProfileDialog}
              />
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  {profile?.name}
                </Typography>
                <Typography fontSize="14px" textTransform="capitalize">
                  {platform}
                </Typography>
              </div>
            </Box>
            <Box sx={{ maxHeight: "65vh", overflow: "auto", px: "10px" }}>
              <Box sx={classes.boxTextTitle}>
                <Typography fontSize="20px" fontWeight={500}>
                  Select Categories
                </Typography>
                <Typography variant="body2" mt="8px">
                  โปรดเลือก 3 ลักษณะคอนเทนต์ที่เกี่ยวข้องกับคุณมากที่สุด
                </Typography>
              </Box>
              <Grid container spacing={4}>
                {categoryList.map((category) => (
                  <Grid item xs={4} key={category.id}>
                    <Category
                      item={category}
                      categorySelected={categorySelected}
                      setCategorySelected={setCategorySelected}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider sx={classes.line} />
              <Box sx={classes.boxTextTitle2}>
                <Typography fontSize="20px" fontWeight={500}>
                  Target Audience
                </Typography>
                <Typography variant="body2" mt="8px">
                  โปรดระบุกลุ่มเป้าหมายของคุณ
                </Typography>
              </Box>
              <Grid container sx={classes.gridAge}>
                <Grid item xs={3}>
                  <Typography>Age :</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box display="flex" alignItems="center" columnGap={2}>
                    <TextFieldCustom
                      value={watch("min")}
                      onChange={(e) => {
                        handleChange(e, "min");
                      }}
                      type="number"
                      variant="outlined"
                      placeholder="min age"
                      fullWidth
                    />
                    -
                    <TextFieldCustom
                      value={watch("max")}
                      onChange={(e) => {
                        handleChange(e, "max");
                      }}
                      type="number"
                      variant="outlined"
                      placeholder="max age"
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container columnGap={4} wrap="nowrap">
                <Grid item xs={3}>
                  <Typography>Gender :</Typography>
                </Grid>
                {genderList.map((gender: IGender) => (
                  <Grid item xs={3} key={gender.id}>
                    <Gender
                      item={gender}
                      genderSelected={genderSelected}
                      setGenderSelected={setGenderSelected}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box display="flex" justifyContent="center" my="25px">
                {profile?.category.length === 0 ? (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={classes.submitBtn}
                  >
                    Verify {platform} Account
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={classes.submitBtn}
                  >
                    <SaveIcon sx={{ mr: "8px" }} />
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      )}
      <Box p="0 24px">
        <Collapse sx={{ width: "100%" }} in={showAlert}>
          <Alert
            variant="outlined"
            severity="error"
            sx={{ mb: "24px", color: "red" }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
      </Box>
    </DialogScrollingCustom>
  );
}
