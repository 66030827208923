import { useState } from "react";
// mui
import { Box, useMediaQuery, useTheme } from "@mui/material";
// component
import CampaignTabLg from "./CampaignTabLg";
import CampaignTabSm from "./CampaignTabSm";
// route and type
import { useHistory, useLocation } from "react-router-dom";
import { ICampaignList, ICampaignParams } from "@views/campaign/Campaign.type";

export default function CampaignAdminTabs(props: { campaignList: ICampaignList | null, onClose:(val:boolean) => void }) {
  const { onClose, campaignList } = props;

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("q") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const cate = params.get("cate");
  const categories = cate ? cate.split(",").map(Number) : [];

  const [selectStatusTab, setSelectStatusTab] = useState({
    id: "",
    key: "",
    text: "ทั้งหมด",
    bgColor: "#F4ECFF",
    color: "#893DBD",
    total: campaignList?.total || 0,
  });

  const campaignTabs = [
    {
      id: "",
      key: "",
      text: "ทั้งหมด",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: campaignList?.total || 0,
    },
    {
      id: "briefing",
      key: "briefing",
      text: "ร่างแคมเปญ",
      bgColor: "#FFFBD4 ",
      color: "#FB8C00",
      total: campaignList?.briefing || 0,
    },
    {
      id: "influencer_listing",
      key: "influencer_listing",
      text: "รับสมัคร",
      bgColor: "#FFEAF4",
      color: "#E8008A",
      total: campaignList?.influencer_listing || 0,
    },
    {
      id: "published",
      key: "published",
      text: "กำลังดำเนินการ",
      bgColor: "#F4ECFF",
      color: "#893DBD",
      total: campaignList?.published || 0,
    },
    {
      id: "reported",
      key: "reported",
      text: "รายงาน",
      bgColor: "#E5F6FF ",
      color: "#0077E4",
      total: campaignList?.reported || 0,
    },
    {
      id: "completed",
      key: "completed",
      text: "เสร็จสิ้น",
      bgColor: "#E7FFE8",
      color: "#4CAF50",
      total: campaignList?.completed || 0,
    },
    {
      id: "canceled",
      key: "canceled",
      text: "ยกเลิก",
      color: "#fff",
      bgColor: "#9E9E9E",
      total: campaignList?.canceled || 0,
    },
  ];

  const handleSelectJobStatus = (data: any) => {
    setSelectStatusTab(data);
    handleChangeStatus(data.key);
  };

  const handleChangeStatus = (status: string) => {
    if (status !== "filter") {
      const params: ICampaignParams = {
        page: 1,
        q: search,
        status: status,
        sort: sort,
        order_by: orderBy,
        platform: platform,
        type: type,
        cate: categories,
      };
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key as keyof ICampaignParams]}`)
        .join("&");

      history.replace(`/campaigns?${queryString}`);
    }
  };

  return (
    <>
      {xsScreen ? (
        <Box display={{ xs: "flex", lg: "none" }} mt="5px">
          <CampaignTabSm
            campaignTabs={campaignTabs}
            selectStatusTab={selectStatusTab}
            handleSelectJobStatus={handleSelectJobStatus}
            onClose={onClose}
          />
        </Box>
      ) : (
        <Box display={["none", "flex"]} height="32px" mb="24px">
          <CampaignTabLg
            campaignTabs={campaignTabs}
            handleChangeStatus={handleChangeStatus}
            onClose={onClose}
          />
        </Box>
      )}
    </>
  );
}
