// mui
import { Box } from "@mui/material";
// component and context
import SyncInstagram from "./SyncInstagram";
import InfluencerSocialHeader from "../default/Header";
import InfluencerSocialPending from "../default/Pending";
import InfluencerSocialInfo from "../default/Information";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";

export default function InfluencerInstagramTab() {
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const instagramInfo = influencerSocial?.instagram;

  return (
    <Box>
      {!instagramInfo ||
      !instagramInfo.length ||
      instagramInfo[0].platform_uid === "" ? (
        <SyncInstagram />
      ) : instagramInfo[0].social_account.length === 0 ? (
        <InfluencerSocialPending platform="instagram" />
      ) : (
        instagramInfo && (
          <Box className="d-flex f-d-column" rowGap="20px">
            <InfluencerSocialHeader platform="instagram" />
            <InfluencerSocialInfo
              platform="instagram"
              email={instagramInfo[0].email}
            />
          </Box>
        )
      )}
    </Box>
  );
}
