export const genderList = [
  {
    value: "none",
    label: "N/A",
  },
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "lgbtq",
    label: "LGBTQ+",
  },
];
