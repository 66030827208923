import { useEffect, useState } from "react";
// mui, icon and style
import {
  Box,
  Card,
  Button,
  Divider,
  CardHeader,
  Typography,
} from "@mui/material";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { useCreateCampaignStyle as classes } from "./CreateCampaign.style";
// component
import Timeline from "@components/campaign/create-campaign/campaign-brief/brief-default/Timeline";
import CampaignBriefTabs from "@components/campaign/create-campaign/campaign-brief-tabs/CampaignBriefTabs";
import CampaignBrief from "@components/campaign/create-campaign/campaign-brief/brief-default/CampaignBrief";
import TargetAudience from "@components/campaign/create-campaign/campaign-brief/brief-default/TargetAudience";
import BudgetAndReward from "@components/campaign/create-campaign/campaign-brief/brief-default/BudgetAndReward";
import CampaignOverview from "@components/campaign/create-campaign/campaign-brief/brief-default/CampaignOverview";
import TimelineOverdueDialog from "@components/campaign/create-campaign/campaign-brief/brief-dialog/TimelineOverdue";
import TermsAndConditions from "@components/campaign/create-campaign/campaign-brief/brief-default/TermsAndConditions";
// api and type
import { CampaignAPI } from "@api/global/index";
import { ICreateCampaignRes } from "@api/campaign/type";
import { IInitCreateCampaignForm } from "./CreateCampaign.type";
// redux and style
import {
  selectCampaignInfo,
  getCampaignInfoAsync,
  selectCampaignInfoOnload,
} from "@slices/CampaignInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getContentTypeAsync, selectContentTypelist } from "@slices/ContentTypeList.slice";
// react-hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCampaignFormSchema } from "./CreateCampaign.schema";
// others
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

const initFile = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  purpose: "",
  description: "",
};

const initAudience = {
  age: {
    min: "",
    max: "",
  },
  gender: [],
  follower: {
    min: "",
    max: "",
  },
  location: [],
};

const initCreateCampaignForm: IInitCreateCampaignForm = {
  company: {
    id: 0,
    name: "",
    employer_id: "",
    media: initFile,
  },
  name: "",
  campaign_type: "",
  objective: "",
  review_type: "",
  product_review_type: "",
  platform: "",
  job_limit: 0,
  content_type_id: {
    id: 0,
    name: "",
    platform: "",
  },
  category_id: [],
  hashtag: [],
  campaign_long_id: "",
  campaign_id: "",
  employer_id: "",
  employer_email: "",
  status: "",
  brief: {
    content: {
      media: {
        images: [],
        videos: [],
      },
      description: "",
    },
    mood_and_tone: {
      media: {
        images: [],
        videos: [],
      },
      description: "",
    },
    cover_image: initFile,
    key_message: "",
    emphasis: "",
    prohibition: "",
    term_and_condition: [],
  },
  product: {
    images: [],
    return_product_address: {
      fullname: "",
      phone: "",
      postcode: "",
      province_id: null,
      district_id: null,
      subdistrict_id: null,
      address: "",
    },
    name: "",
    description: "",
    is_shipping_address: false,
  },
  audience: {
    target: initAudience,
    influencer: initAudience,
  },
  location: {
    name: "",
    coordinates: [],
    contact: "",
    address: "",
    phone: "",
    url: "",
    static_map_url: "",
  },
  budget: {
    total: null,
    influencer_amount: null,
    each_influencer: null,
    reward: [],
  },
  timeline: {
    draft_phase: [],
    start_recruiting_date: "",
    end_recruiting_date: "",
    announce_date: "",
    start_publish_date: "",
    end_publish_date: "",
    completed_date: "",
    posting_period: null,
    shipping_date: "",
    end_shipping_date: "",
  },
};

export default function CreateCampaign() {
  const [loading, setLoading] = useState(false);
  const [openTimelineOverdueDialog, setOpenTimelineOverdueDialog] = useState(false);

  const form = useForm<IInitCreateCampaignForm>({
    resolver: yupResolver(createCampaignFormSchema),
    defaultValues: initCreateCampaignForm,
  });
  const { handleSubmit, reset, setValue } = form;

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const campaignId = params.get("c_id") || "";

  const { type } = useParams<{ type: string; }>();

  const dispatch = useAppDispatch();
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const campaignLoading = useAppSelector(selectCampaignInfoOnload);
  const contentTypeList = useAppSelector(selectContentTypelist);
  const isLoading = campaignLoading || loading;

  useEffect(() => {
    if (type !== "edit") return;
    dispatch(getCampaignInfoAsync(campaignId));
    dispatch(getContentTypeAsync());
  }, [dispatch, campaignId, type]);

  useEffect(() => {
    if (type !== "edit") return;

    const contentType = contentTypeList?.find(
      (ct) => ct.name === campaignInfo?.content_type
    );

    if (campaignInfo !== null && contentType) {
      const isCampaignPrivate = campaignInfo.campaign_type === "private";
      const data: ICreateCampaignRes = {
        ...campaignInfo,
        category_id: campaignInfo?.category.map((item) => item.id),
        content_type_id: contentType,
        budget: {
          ...campaignInfo.budget,
          influencer_amount: isCampaignPrivate
            ? campaignInfo.job_limit
            : campaignInfo.budget.influencer_amount,
        },
      };
      reset(data);

      const endRecrutingDate = new Date(
        campaignInfo.timeline.end_recruiting_date
      );
      const currentDate = new Date();
      if (currentDate > endRecrutingDate) {
        setOpenTimelineOverdueDialog(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, type]);

  const componentList = [
    {
      id: "campaignOverview",
      title: "Campaign Overview",
      subTitle: "ภาพรวมแคมเปญ",
      component: <CampaignOverview form={form} />,
    },
    {
      id: "campaignBrief",
      title: "Campaign Brief",
      subTitle: "รายละเอียดแคมเปญ",
      component: <CampaignBrief form={form} />,
    },
    {
      id: "targetAudience",
      title: "Target Audience",
      subTitle: "กลุ่มเป้าหมาย",
      component: <TargetAudience form={form} />,
    },
    {
      id: "budgetAndReward",
      title: "Budget and Reward",
      subTitle: "สิ่งที่อินฟลูเอนเซอร์จะได้รับ",
      component: <BudgetAndReward form={form} />,
    },
    {
      id: "termsAndConditions",
      title: "Terms and Conditions",
      subTitle: "เงื่อนไขและข้อตกลงในการทำงาน",
      component: <TermsAndConditions form={form} />,
    },
    {
      id: "timeline",
      title: "Timeline",
      subTitle: "ระยะเวลาของแคมเปญ",
      component: <Timeline form={form} />,
    },
  ];

  const onCreateCampaign = async (value: IInitCreateCampaignForm) => {
    const isPlace = value.review_type === "place";
    const isProduct = value.review_type === "product";
    try {
      setLoading(true);
      if (!value.campaign_id) {
        const data = {
          ...value,
          employer_id: employerId,
          content_type_id: value.content_type_id.id,
          location: isPlace ? value.location : null,
          product_review_type: isProduct ? value.product_review_type : "",
          product: isProduct ? value.product : null,
        };
        CampaignAPI.createCampaign(data).then((response) => {
          setLoading(false);
          if (role === "employer") {
            history.push(`/campaigns/${response.campaign_long_id}/info`);
          } else {
            history.push(
              `/campaigns/${response.campaign_long_id}/info?emp_id=${employerId}`
            );
          }
        });
      } else {
        const data = {
          campaign: {
            name: value.name,
            campaign_type: value.campaign_type,
            content_type_id: value.content_type_id.id,
            status: value.status,
            objective: value.objective,
            review_type: value.review_type,
            product_review_type: isProduct
              ? value.product_review_type
              : "no_shipping",
            platform: value.platform,
            job_limit: value.job_limit,
          },
          category_id: value.category_id,
          hashtag: value.hashtag,
          company: value.company,
          product: isProduct ? value.product : null,
          location: isPlace ? value.location : null,
          brief: value.brief,
          audience: {
            target: value.audience.target,
            influencer: value.audience.influencer,
          },
          budget: value.budget,
          timeline: value.timeline,
        };
        CampaignAPI.updateCampaign(data, value.campaign_long_id).then(
          (response) => {
            setLoading(false);
            if (role === "employer") {
              history.push(`/campaigns/${response.campaign_long_id}/info`);
            } else {
              history.push(
                `/campaigns/${response.campaign_long_id}/info?emp_id=${employerId}`
              );
            }
          }
        );
      }
    } catch (err: any) {
      setLoading(false);
      SwalCustom.fire({
        icon: "error",
        title: "Oops !",
        text: `${err.response.data.error.message}`,
        confirmButtonColor: "#E30D18",
        confirmButtonText: "ตกลง",
      });
    }
  };

  if (isLoading) return <Loading />
  
  return (
    <Box
      className="container-main"
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onCreateCampaign)}
    >
      <Box sx={classes.createCampaignTitle}>
        <Typography>Campaign Brief</Typography>
        <Typography>กรุณากรอกข้อมูลเพื่อสร้างแคมเปญ</Typography>
      </Box>
      <Box sx={classes.createCampaignCon}>
        <CampaignBriefTabs form={form} />
        <Box sx={classes.boxContent}>
          {componentList.map((comp, index) => (
            <Card key={index} id={comp.id} sx={classes.cardContainer}>
              <CardHeader
                title={
                  <Box>
                    <Typography variant="h6" mb={-2}>
                      {comp.title}
                    </Typography>
                    <Typography variant="subTitle1" color="#5C5C5C">
                      {comp.subTitle}
                    </Typography>
                  </Box>
                }
              />
              <Divider sx={classes.divider} />
              {comp.component}
            </Card>
          ))}
          <Box sx={classes.saveBtn}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveRoundedIcon />}
              type="submit"
            >
              บันทึกและดูตัวอย่าง
            </Button>
          </Box>
        </Box>
      </Box>
      <TimelineOverdueDialog
        open={openTimelineOverdueDialog}
        onClose={setOpenTimelineOverdueDialog}
        setValue={setValue}
      />
    </Box>
  );
}
