export const useProfileTabStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    height: "100%",
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
    borderTop: "1px solid #8C499C",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  boxBorder: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  divider: {
    backgroundColor: "#E0E0E0",
  },
  avatar: {
    width: "120px",
    height: "120px",
  },
};
