export const useNotificationStyle = {
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
    "& .css-bjtku8": {
      bgcolor: "inherit",
      boxShadow: "none",
    },
  },
  contentDialog: {
    bgcolor: "#fff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "20px",
    position: "relative",
  },
};
