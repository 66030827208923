// mui and style
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useCurrentStyle as classes } from "../MyReferral.style";
// components
import Activity from "./Activity";
import NoActivity from "./NoActivity";
// redux
import { useAppSelector } from "@store/hook";
import { selectActivity } from "@slices/Activity.slice";

export default function CurrentActivity() {
  const activity = useAppSelector(selectActivity);
  return (
    <Grid item xs={12} lg={6}>
      <Box sx={classes.card} position="relative">
        <Box display={{ xs: "none", lg: "initial" }}>
          <Typography variant="h6">Current Activity</Typography>
          <Divider sx={{ my: "16px" }} />
        </Box>
        {activity ? <Activity /> : <NoActivity />}
      </Box>
    </Grid>
  );
}
