import { Box, Skeleton } from "@mui/material";

export default function TeamSkeleton() {
  return (
    <div>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={100}
        sx={{ borderRadius: "20px" }}
      />
      <Box display="flex" my="30px">
        {[...Array(4)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            animation="wave"
            height={50}
            width={150}
            sx={{ borderRadius: "20px 20px 0 0" }}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" rowGap="20px">
        {["100%", "90%", "80%", "70%"].map((width, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            animation="wave"
            width={width}
            height={30}
            sx={{ borderRadius: "20px" }}
          />
        ))}
      </Box>
    </div>
  );
}
