import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Stack,
  Button,
  Avatar,
  Checkbox,
  FormGroup,
  Typography,
  IconButton,
  CardContent,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
// icons and style
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useCampaignBudgetAndRewardStyle as classes } from "../CampaignBrief.style";
// types
import {
  IBudgetAndRewardItem,
  ICreateCampaignProps,
} from "../CampaignBrief.type";
import { ICampaignBriefFormKey } from "@views/campaign/create-campaign/CreateCampaign.type";
// others
import { useLocation } from "react-router-dom";
import { useFieldArray } from "react-hook-form";
import TextFieldCustom from "@global/text-field";
import WrapInputWithLabel from "@global/wrap-input-label";
import { numberWithCommas } from "@utils/helpers/numberWithCommas";
import RewardToInfluencer from "../brief-dialog/RewardToInfluencer";

export default function BudgetAndReward(props: ICreateCampaignProps) {
  const { form } = props;

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [productServiceDialog, setProductServiceDialog] = useState(false);

  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  const { append, remove } = useFieldArray({
    control,
    name: "budget.reward",
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isEdit = params.get("status") === "edit";

  const reward = getValues("budget.reward");
  const budgetTotal = watch("budget.total");
  const budgetInfluAmount = watch("budget.influencer_amount");
  const eachInflue = watch("budget.each_influencer");
  const isCampaignPrivate = watch("campaign_type") === "private";

  const handleChangeBudget = (ev: any, keyName: string) => {
    const value = ev.target.value.replace(/,/gi, "");
    const isNumeric = typeof value === "number" || /^\d+$/.test(value);
    if (isNumeric) {
      setValue(keyName as ICampaignBriefFormKey, Number(value));
    } else if (value === "") {
      setValue(keyName as ICampaignBriefFormKey, null as any);
    }
  };

  const onEechInfluencer = () => {
    if (budgetTotal && budgetInfluAmount) {
      const eachInflu = budgetTotal / budgetInfluAmount;
      setValue("job_limit", Math.floor(budgetInfluAmount));
      if (isCampaignPrivate) {
        setValue("budget.each_influencer", 0);
      } else {
        setValue("budget.each_influencer", Math.floor(eachInflu));
      }
    } else {
      setValue("budget.each_influencer", null);
    }
  };

  return (
    <>
      <CardContent>
        <Typography color="primary" fontWeight="500" mb={3}>
          Budget (งบประมาณ)
        </Typography>
        <Stack spacing={2}>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Campaign Budget"
            required
          >
            <div>
              <TextFieldCustom
                variant="outlined"
                placeholder="2,000"
                fullWidth
                error={!!errors?.budget?.total}
                helperText={errors?.budget?.total?.message}
                value={numberWithCommas(budgetTotal?.toString() || "")}
                onChange={(ev) => handleChangeBudget(ev, "budget.total")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">บาท</InputAdornment>
                  ),
                }}
                onBlur={onEechInfluencer}
              />
              {isCampaignPrivate && (
                <Box ml={3} mt={2}>
                  <Typography variant="caption" fontWeight={400}>
                    การกำหนด Budget สำหรับแคมเปญแบบ{" "}
                    <span style={{ fontWeight: 500 }}>“Private”</span>{" "}
                    จะเป็นการประมาณการเท่านั้น โดยผลรวมของ Budget
                    จะขึ้นอยู่กับผลรวมของ Rate Card จากการเลือก Influencer
                  </Typography>
                </Box>
              )}
            </div>
          </WrapInputWithLabel>
          <WrapInputWithLabel
            labelWidth="200px"
            title="Influencer amount"
            required
          >
            <TextFieldCustom
              variant="outlined"
              placeholder="Influencer Amount"
              fullWidth
              error={!!errors?.budget?.influencer_amount}
              helperText={errors?.budget?.influencer_amount?.message}
              value={numberWithCommas(
                budgetInfluAmount ? budgetInfluAmount.toString() : ""
              )}
              onChange={(ev) => handleChangeBudget(ev, "budget.influencer_amount")}
              onBlur={onEechInfluencer}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">คน</InputAdornment>
                ),
              }}
            />
          </WrapInputWithLabel>
          {!isCampaignPrivate && (
            <WrapInputWithLabel
              labelWidth="200px"
              title={
                <Typography fontSize="14px" mt={2.5}>
                  Each influencer
                </Typography>
              }
            >
              <Box sx={classes.eachInflue}>
                {!eachInflue ? (
                  <Typography sx={{ fontSize: "14px", color: "#00000099" }}>
                    ระบบคำนวณให้
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "14px", color: "#000" }}>
                    {isEdit ? (
                      <>{numberWithCommas(eachInflue / 0.85)}</>
                    ) : (
                      <>{numberWithCommas(eachInflue)}</>
                    )}
                  </Typography>
                )}
                <Typography>บาท</Typography>
              </Box>
            </WrapInputWithLabel>
          )}
        </Stack>
      </CardContent>
      <CardContent>
        <Typography color="primary" fontWeight="500" mb={3}>
          Reward (สิ่งที่อินฟลูเอนเซอร์จะได้รับ)
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WrapInputWithLabel
              labelWidth="200px"
              title={
                <FormGroup>
                  <FormControlLabel
                    sx={classes.formControlLabel}
                    control={<Checkbox defaultChecked disabled />}
                    label="ค่าตอบแทนในการรีวิว"
                  />
                </FormGroup>
              }
            >
              <Box sx={classes.eachInflue}>
                {isCampaignPrivate ? (
                  <Typography variant="body2">
                    ขึ้นอยู่กับ Ratecard ของ Influencer
                  </Typography>
                ) : !eachInflue ? (
                  <Typography variant="body2" sx={{ color: "#00000099" }}>
                    ระบบคำนวณให้
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    {isEdit ? (
                      <>{numberWithCommas(eachInflue / 0.85)}</>
                    ) : (
                      <>{numberWithCommas(eachInflue)}</>
                    )}
                  </Typography>
                )}
                <Typography>บาท</Typography>
              </Box>
            </WrapInputWithLabel>
          </Grid>
          <Grid item xs={3} mt="7px">
            <Box sx={classes.rewardOpen}>
              {productServiceDialog || (reward && reward.length) ? (
                <CheckBoxIcon
                  onClick={() => setProductServiceDialog(!productServiceDialog)}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  onClick={() => setProductServiceDialog(!productServiceDialog)}
                />
              )}
              <Typography>สินค้าหรือบริการ</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            display={
              productServiceDialog === true || (reward && reward.length)
                ? "flex"
                : "none"
            }
          >
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={4}>
                {reward?.map((item: IBudgetAndRewardItem, index: number) => (
                  <Grid item xs={6} key={index}>
                    <Box display="flex" sx={classes.productAndService}>
                      <Avatar src={item.image.url} />
                      <Box sx={classes.productAndServiceheader}>
                        <Typography>{item.name}</Typography>
                        <Typography>{item.description}</Typography>
                      </Box>
                      <IconButton
                        onClick={() => remove(index)}
                        sx={classes.productAndServiceIcon}
                      >
                        <RemoveCircleIcon color="secondary" />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Button
                sx={classes.addProductAndServiceButton}
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => setOpenAddProduct(true)}
              >
                เพิ่มสินค้าหรือบริการ
              </Button>

              <RewardToInfluencer
                openAddProduct={openAddProduct}
                setOpenAddProduct={setOpenAddProduct}
                errors={errors}
                append={append}
                form={form}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}
