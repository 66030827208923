// mui
import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
// type and style
import { ITargetAndConditionsDialogProps } from "../CampaignDetail.type";
import { useTermsAndConditionsDialogStyle as classes } from "../CampaignDetail.style";

export default function TermsAndConditionsDialog(
  props: ITargetAndConditionsDialogProps
) {
  const { open, onClose, handleAcceptCondition } = props;
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose(false)}>
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>ข้อตกลงและเงื่อนไขการให้บริการ</Typography>
        <Typography>
          โปรดอ่านและทำความเข้าใจ เรื่อง ข้อตกลงและเงื่อนไขการให้บริการ
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={classes.dialogContent}>
          <div>
            <Typography variant="h5">Employer(ผู้จ้างงาน)</Typography>

            {/* finance */}
            <Box mt={5}>
              <Typography variant="h6">การเงิน</Typography>
              <ul>
                {[
                  "เติมเงินเข้า Wallet เพื่อใช้สำหรับชำระค่าบริการ Campaign และอื่น ๆ",
                  "ไม่สามารถถอนเงินออกจาก Wallet เป็นเงินสดได้",
                  "ยอดเงินใน Wallet มีอายุ 12 เดือน หากครบกำหนดเวลาของแต่ละยอดการเติมเงิน จะถูกหักเข้าระบบ HashU",
                ].map((text, index) => (
                  <li key={index}>
                    <Typography>{text}</Typography>
                  </li>
                ))}
                <li>
                  <Typography>
                    การใช้เงินใน Wallet เพื่อชำระค่าบริการต่าง ๆ จะใช้จากยอดที่เติมเข้าก่อนตัวอย่าง
                  </Typography>
                  <ul>
                    {[
                      "การใช้เงินใน Wallet เพื่อชำระค่าบริการต่าง ๆ จะใช้จากยอดที่เติมเข้าก่อนตัวอย่าง",
                      "ข้อ 2. 1 มี.ค. 66: เติมเงิน 25,000 บาท หมดอายุ 1 มี.ค. 67",
                      "ข้อ 3. 1 ม.ค. 67: ใช้บริการ Campaign เป็นเงิน 25,000 บาท",
                      "ระบบหักเงินจาก Wallet จากยอด ข้อ 1. 10,000 บาท และจากยอด ข้อ 2. 15,000 บาท",
                      "ดังนั้น จะเหลือเงินใน Wallet 10,000 บาท ( จากยอด 2. หมดอายุ 1 มี.ค. 67)"
                    ].map((text, index) => (
                      <li key={index}>
                        <Typography>{text}</Typography>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Typography>
                    ชำระเงินค่ามัดจำจาก Wallet เมื่อประกาศรับสมัคร Campaign ตามอัตราค่ามัดจำ และเงินจำนวนที่เหลือใน Wallet ถูก Hold ไว้ ไม่สามารถใช้สำหรับ Campaign อื่นได้
                  </Typography>
                </li>
              </ul>

              <Typography variant="h6" my={5}>เงื่อนไขเงินมัดจำ</Typography>
              <Box sx={classes.table}>
                <Typography variant="h6">อัตราค่ามัดจำ</Typography>
                <table>
                  <tr>
                    <th>ราคารวมของ Campaign</th>
                    <th>อัตราค่าบริการร้อยละ</th>
                  </tr>
                  <tr>
                    <td>มากกว่า 100,000 บาท</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>มากกว่า 50,000 ถึง 100,000 บาท</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>ตั้งแต่ 10,000 ถึง 50,000 บาท</td>
                    <td>15%</td>
                  </tr>
                  <tr>
                    <td>ไม่เกิน 10,000 บาท</td>
                    <td>20%</td>
                  </tr>
                </table>
                <ul>
                  <li>
                    <Typography>กรณีที่ 1 เมื่อครบกำหนดปิดรับสมัคร Campaign และมีผู้สมัคร "น้อยกว่า" จำนวนที่ต้องการ</Typography>
                    <ul>
                      <li>
                        <Typography>Employer สามารถยกเลิก Campaign ได้</Typography>
                      </li>
                      <li>
                        <Typography>ระบบจะคืนเงินมัดจำเข้า Wallet ให้ Employer ภายใน 24 ชั่วโมง</Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Typography>กรณีที่ 2 เมื่อครบกำหนดปิดรับสมัคร Campaign และมีผู้สมัคร "มากกว่าหรือเท่ากับ" จำนวนที่ต้องการ  Employer สามารถเลือกปฏิบัติได้ดังนี้</Typography>
                    <ul>
                      <li>
                        <Typography>เลือก Influencer เข้า Campaign และเริ่ม Campaign หากจำนวน Influencer จำนวนที่ต้องการใน Campaign ให้ระบบคืนเงินเข้า Wallet ของ Employer ภายใน 24 ชั่วโมง</Typography>
                      </li>
                      <li>
                        <Typography>ยกเลิก Campaign</Typography>
                        <ul>
                          <li>
                            <Typography>ระบบตัดชำระค่ามัดจำ</Typography>
                          </li>
                          <li>
                            <Typography>ระบบจะคืนเงินที่เหลือที่ Hold ไว้สำหรับ Campaign เข้า Wallet</Typography>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Box>
            </Box>

            {/* campaign management */}
            <div>
              <Typography variant="h5">การจัดการ Campaign</Typography>
              <Box mt={5}>
                <Typography variant="h6" ml={5}>หน้าที่ และความรับผิดชอบ</Typography>
                <ul>
                  {[
                    {
                      text: "สรุปรายละเอียดงานให้ละเอียด และครบถ้วน"
                    },
                    {
                      text: "เมื่อประกาศงานให้ Influencer สามารถ สมัครเข้าร่วมได้ จะไม่สามารถเปลี่ยนแปลงรายละเอียดงานได้"
                    },
                    {
                      text: "เมื่อครบกำหนดปิดรับสมัคร Influencer แล้ว Employer มีหน้าที่ประกาศผลการคัดเลือก Influencer ภายในเวลาที่ตนเองกำหนด ทั้งนี้หากไม่ประกาศในเวลาที่กำหนด ระบบจะปิด Campaign อัตโนมัติ และจัดการค่ามัดจำดังนี้",
                      subText: [
                        "กรณีที่ Influencers สมัครมาจำนวนน้อยกว่ากำหนด ระบบจะคืนเงินมัดจำ และเงินที่ Hold ทั้งหมดให้ Employer Wallet",
                        "กรณีที่ Influencers สมัครมาจำนวนมากกว่า หรือเท่ากับกว่ากำหนด ระบบจะยึดเงินมัดจำ และคืนเงินที่ Hold ให้ Employer Wallet"
                      ]
                    },
                    {
                      text: "เมื่อ Influencer เริ่มงาน Employer มีหน้าที่อำนวยสิ่งจำเป็นดังนี้",
                      subText: [
                        "กรณีงานรีวิวสินค้าที่ต้องส่ง ให้ส่งสินค้าถึง Influencer ภายใน 5 วัน นับจากวันที่ Employer กำหนดส่งของ (ระบบส่ง Email แจ้งเตือนหลังจาก 2 วันผ่านไป และยังไม่ส่งของ)",
                        "กรณีรีวิวสถานที่ ให้นัดหมาย และจัดตารางคิวการเข้าสถานที่ ก่อนกำหนดวันเข้าสถานที่ที่ประกาศไว้"
                      ]
                    },
                    {
                      text: "เมื่อ Influencer ส่ง Draft Post ทั้งการส่งครั้งแรก และการแก้ไขงาน ให้ Employer ตรวจงาน และส่งผลการผ่านอนุมัติ หรือแก้ไข ภายใน 3 วัน ทั้งนี้หากครบกำหนดเวลา ระบบตัดสินให้ผลงานของ Influencer ผ่านอนุมัติ โดยอัตโนมัติ",
                      subText: [
                        "การผ่านอนุมัติ Post อัตโนมัติ ระบบเลือกให้ Influencer เผยแพร่ Post บน Social Platform ตามช่วงวันที่กำหนดใน Campaign และช่วงเวลา 00.00 น. - 23:59 น.",
                        "Employer สามารถตรวจงาน และให้แก้ไข Draft Post ได้ 2 ครั้ง"
                      ]
                    },
                    {
                      text: "เมื่อตรวจงาน และให้ Draft Post ผ่านอนุมัติ Employer มีหน้าที่กำหนดช่วงวัน และเวลาให้ Influencer เผยแพร่ Post บน Social Platform",
                    },
                  ].map((item, index) => (
                    <li key={index}>
                      <Typography>{item.text}</Typography>
                      {item.subText && (
                        <ul>
                          {item.subText.map((subText, subIndex) => (
                            <li key={subIndex}>
                              <Typography>{subText}</Typography>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </Box>
              <div>
                <Typography variant="h6" ml={5}>ข้อพิพาท</Typography>
                <ul>
                  <li>
                    <Typography>กรณี Influencer แก้ไขงานครบ 2 ครั้ง และ Employer ไม่ให้ Draft Post ผ่าน Influencer จะได้รับค่าจ้างตามอัตราค่ามัดจำ Campaign ของค่าจ้างทั้งหมดที่ Influencer จะได้รับ</Typography>
                  </li>
                </ul>
              </div>
              <div>
                <Typography variant="h6" ml={5}>Influencer (ผู้ถูกจ้างงาน)</Typography>
                <Typography variant="h6" ml={5} mt={3}>การเงิน</Typography>
                <ul>
                  <li>
                    <Typography>Influencer มีหน้าที่กรอกข้อมูลบัญชีธนาคาร และแนบเอกสารอื่น ๆ สำหรับรับเงินค่าจ้างการทำงาน เพื่อให้สามารถรับงานในระบบได้ (ถ้าไม่มีบัญชีธนาคาร ไม่สามารถรับงานได้ แต่เข้าดูประกาศงานได้ และมี Popup เตือนในหน้า Profile หลังจาก Sync Social แล้ว)</Typography>
                  </li>
                  <li>
                    <Typography>ระบบชำระเงินค่าจ้างตัดยอดรวมของงานที่เสร็จสมบูรณ์ครบตามกำหนดเวลาอายุ Post ของงาน ในช่วงเวลานั้น เดือนละ 2 ครั้งคือ ทุกวันที่ 5 และวันที่ 20 ของทุกเดือน</Typography>
                    <ul>
                      <li>
                        <Typography>ภาษี ระบบจะทำหักภาษี ณ ที่จ่าย 3% พร้อมแนบเอกสารหลักฐานภาษีหัก ณ ที่จ่ายให้ในระบบ</Typography>
                      </li>
                      <li>
                        <Typography>การชำระเงิน เป็นการโอนเงินเข้าบัญชีธนาคาร</Typography>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            {/* punishment */}
            <div>
              <Typography variant="h5">บทลงโทษ</Typography>
              <ul>
                <li>
                  <Typography>Influencer ได้รับบทลงโทษเมื่อ</Typography>
                  <ul>
                    {[
                      "ยกเลิกงาน หลังจากได้รับเลือกเริ่มงาน Campaign",
                      "ทิ้งงาน ไม่ส่งงาน หรือ แก้ไขงานตามกำหนดเวลา",
                      "ไม่ทำงานตามรายละเอียดที่ Campaign กำหนด",
                      "ไม่แนบลิ้งส่งงาน หลังจากเผยแพร่ Post บน Social Platform",
                      "ไม่เปิด Public Post ไม่ครบตาม Campaign กำหนด",
                      "ซ่อน ลบ Post ก่อนครบกำหนดเวลาใน Campaign"
                    ].map((text, index) => (
                      <li key={index}>
                        <Typography>{text}</Typography>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <Typography>บทลงโทษ คือ Influencer ไม่ได้รับค่าจ้างการทำงาน Campaign ที่ลงโทษ และไม่สามารถสมัคร หรือได้รับเลือกให้เข้าร่วม Campaign ทุก Social Platform Account เงื่อนไขระยะเวลาการลงโทษดังนี้</Typography>
                  <ul>
                    {[
                      "ลงโทษครั้งที่ 1 ระงับบัญชี 2 สัปดาห์",
                      "ลงโทษครั้งที่ 2 ระงับบัญชี 1 เดือน",
                      "ลงโทษครั้งที่ 3 ระงับบัญชี 3 เดือน",
                      "ลงโทษครั้งที่ 4 ระงับบัญชีถาวร ทุก Social Platform Account"
                    ].map((text, index) => (
                      <li key={index}>
                        <Typography>{text}</Typography>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <Box sx={classes.acceptCondition} mt={10}>
            <Button
              onClick={() => handleAcceptCondition("service", false)}
              variant="contained"
            >
              อ่านและทำความเข้าใจเรียบร้อยแล้ว
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleAcceptCondition("service", true)}
            >
              ยกเลิก
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
