import { useState } from "react";
// mui
import { Box, Button, Divider, Grid, Icon, Typography } from "@mui/material";
// style and type
import { useJobApprovedStyle as classes } from "./JobApproved.style";
import { IInitMyJobsJobTabForm, IMyJobTabProps } from "../../JobsTab.type";
// components
import Post from "./job-draft-post/Post";
import DatePost from "./job-draft-post/DatePost";
import PublishedLink from "./job-draft-post/PublishedLink";
import ProductReturn from "./job-draft-post/ProductReturn";
import PlaceDetailDialog from "../../job-dialog/PlaceDetailDialog";
import ReviewPostInfluencer from "../../job-default/ReviewPostInfluencer";
import ShippingProductDialog from "../../job-dialog/ShippingProductDialog";
// other
import { useForm } from "react-hook-form";

const initJobDraftPostForm: IInitMyJobsJobTabForm = {
  published_link: "",
  product_return: {
    shipping_type: "",
    tracking_no: "",
    note: "",
  },
};

export default function JobApproved(props: IMyJobTabProps) {
  const { jobsInfo } = props;

  const [viewPlaceDialog, setViewPlaceDialog] = useState(false);
  const [openShippingProduct, setOpenShippingProduct] = useState(false);

  const form = useForm<IInitMyJobsJobTabForm>({
    defaultValues: initJobDraftPostForm,
  });

  const campaignInfo = jobsInfo?.campaign_info;
  const prodReturnType = campaignInfo?.product_review_type;
  const isSendProductReturn = prodReturnType === "send_return";
  const isPlace = campaignInfo?.review_type === "place";

  const componentList = [
    {
      component: <DatePost />,
      display: "initial",
    },
    {
      component: <Post />,
      display: "initial",
    },
    {
      component: <ProductReturn form={form} />,
      display: !isSendProductReturn ? "none" : "initial",
    },
    {
      component: <PublishedLink form={form} />,
      display: "initial",
    },
  ];

  return (
    <Box sx={classes.jobTabsCon}>
      <Box sx={classes.jobTitle}>
        <Box display={{ xs: "none", sm: "initial" }}>
          <Typography>Job</Typography>
          <Typography>กรอกข้อมูลเพื่อส่งงาน</Typography>
        </Box>
        {isPlace ? (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-location" />}
            sx={classes.shippingProBtn}
            onClick={() => setViewPlaceDialog(true)}
          >
            Location
          </Button>
        ) : isSendProductReturn ? (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-product-received" />}
            sx={classes.shippingProBtn}
            onClick={() => setOpenShippingProduct(true)}
          >
            Shipping Product
          </Button>
        ) : null}
      </Box>
      <Divider sx={classes.divider} />
      <Grid container spacing={[0, 5]}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={5}>
            {componentList.map((item, index) => (
              <Grid key={index} item xs={12} display={item.display}>
                {item.component}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} display={["none", "initial"]}>
          <ReviewPostInfluencer
            jobDetailForInflu={jobsInfo}
            fileType={jobsInfo?.content_type.type}
          />
        </Grid>
      </Grid>
      <ShippingProductDialog
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
        jobInfluencerDetail={jobsInfo}
      />
      <PlaceDetailDialog
        openPlace={viewPlaceDialog}
        setOpenPlace={setViewPlaceDialog}
        place={campaignInfo?.location}
      />
    </Box>
  );
}
