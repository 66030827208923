import { useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Checkbox,
  Typography,
  FormControlLabel,
  Tooltip as TooltipMui,
} from "@mui/material";
// type
import { IEngagementProps } from "./campaignReport.type";
// style and colors
import { useEngagementStyle as classes } from "./CampaignReport.style";
import { hGrey, hPink, hPurple } from "@theme/index";
// ChartJs
import { EngagementChart } from "./CampaignReport.chart";
import { Line } from "react-chartjs-2";

export default function Engagement(props: IEngagementProps) {
  const { engagement } = props;
  const [labels, setLabels] = useState<string[]>([]);
  const [likes, setLikes] = useState<number[]>([]);
  const [comments, setComments] = useState<number[]>([]);
  const [shares, setShares] = useState<number[]>([]);

  useEffect(() => {
    const labelLists = engagement?.chart.map((item) => item.date);
    const likeLists = engagement?.chart.map((item) => item.like);
    const commentLists = engagement?.chart.map((item) => item.comment);
    const shareLists = engagement?.chart.map((item) => item.share);
    setLabels(labelLists ? labelLists : []);
    setLikes(likeLists ? likeLists : []);
    setComments(commentLists ? commentLists : []);
    setShares(shareLists ? shareLists : []);
  }, [engagement]);

  const options = {
    ...EngagementChart.options,
  };

  const data = {
    labels,
    ...EngagementChart.data,
    datasets: [
      {
        fill: true,
        id: 1,
        label: "Likes",
        data: likes,
        borderColor: hPurple[300],
        backgroundColor: "rgb(84, 36, 133,0.2)",
      },
      {
        fill: true,
        id: 2,
        label: "Comments",
        data: comments,
        borderColor: hPink[300],
        backgroundColor: "rgba(232, 0, 138, 0.2)",
      },
      {
        fill: true,
        id: 3,
        label: "Share",
        data: shares,
        borderColor: "#959595",
        backgroundColor: "rgba(224 , 224, 224, 0.4)",
      },
    ],
  };

  const engagementTotal = [
    {
      title: "ER",
      subTitle: "Engagement Rate",
      total: engagement?.engagement_rate,
      icon: (
        <Icon
          className="ico-hu-engagement-rate"
          fontSize="large"
          color="secondary"
        />
      ),
    },
    {
      title: "Engagement",
      subTitle: "Engagement",
      total: engagement?.engagment,
      icon: (
        <Icon
          className="ico-hu-engagement-outline"
          fontSize="large"
          color="primary"
        />
      ),
    },
    {
      title: "Likes",
      subTitle: "Likes",
      total: engagement?.like,
      icon: <Icon className="ico-hu-like" fontSize="large" color="primary" />,
    },
    {
      title: "Comments",
      subTitle: "Comments",
      total: engagement?.comment,
      icon: (
        <Icon className="ico-hu-comment" fontSize="large" color="primary" />
      ),
    },
    {
      title: "Shares",
      subTitle: "Shares",
      total: engagement?.share,
      icon: (
        <Icon className="ico-hu-share-m" fontSize="large" color="primary" />
      ),
    },
  ];

  const engagementCheckboxGhraph = [
    {
      label: "Likes",
      color: "secondary",
      value: "0",
    },
    {
      label: "Comments",
      color: "primary",
      value: "1",
    },
    {
      label: "Shares",
      color: "success",
      value: "2",
    },
  ];


  return (
    <Box sx={classes.container}>
      <Box sx={classes.engTitle}>
        <Typography>Engagement</Typography>
      </Box>

      <Box sx={classes.engGrid}>
        <Grid container spacing={4}>
          {engagementTotal.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Box sx={classes.engCard}>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <Box ml={["20px", "20px", "20px", "10px"]}>
                      <TooltipMui
                        className="engagementTitle"
                        sx={{
                          display: ["flex", "none", "none", "block", "none"],
                        }}
                        placement="top"
                        title={
                          item.title === "ER" ? "EngagementData" : item.title
                        }
                      >
                        <Typography
                          color={hGrey[900]}
                          fontSize={["14px", "14px", "16px"]}
                        >
                          {item.title}
                        </Typography>
                      </TooltipMui>
                      <Typography sx={classes.engSubtitle}>
                        {item.subTitle}
                      </Typography>

                      <Typography
                        variant="h4"
                        fontWeight="600"
                        color={
                          item.title === "ER"
                            ? hPink[300]
                            : item.title === "Engagement"
                            ? hPurple[300]
                            : hPurple[400]
                        }
                      >
                        {new Intl.NumberFormat("jp-JP", {
                          minimumFractionDigits: item.title === "ER" ? 2 : 0,
                        }).format(item.total ? item.total : 0)}
                        {item.title === "ER" ? "%" : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} sx={classes.engCardBoxIcon}>
                    <Box
                      color={
                        item.title === "Engagement Rate"
                          ? hPink[300]
                          : item.title === "Engagement"
                          ? hPurple[300]
                          : hPurple[400]
                      }
                      sx={classes.engCardIcon}
                    >
                      {item.icon}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={classes.chartLineBox}>
        <Line
          id="chartjs"
          options={options}
          data={data}
          plugins={EngagementChart.chartAreaBorder}
        />
      </Box>
      <Box>
        <Box sx={classes.chartCheckboxBox}>
          {engagementCheckboxGhraph.map((checkbox, index) => (
            <Box key={index}>
              <FormControlLabel
                sx={classes.chartCheckbox}
                control={
                  <Checkbox
                    // onClick={(e: any) => checkboxChart(e.target.value)}
                    value={checkbox.value}
                    size="small"
                    color={
                      checkbox.label === "Likes"
                        ? "primary"
                        : checkbox.label === "Comments"
                        ? "secondary"
                        : "default"
                    }
                    defaultChecked
                  />
                }
                label={checkbox.label}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
