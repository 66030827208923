// mui
import { Box, Icon } from "@mui/material";

export interface IItemsCategory {
  itemsCategory: string;
}

export default function ItemsCategory(props: IItemsCategory) {
  const { itemsCategory } = props;

  return (
    <>
      {itemsCategory === "pet" ? (
        <Icon className="ico-hu-c-pet" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "vehicle" ? (
        <Icon className="ico-hu-c-car" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "beauty" ? (
        <Icon className="ico-hu-c-beauty" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "business and finance" ? (
        <Icon className="ico-hu-c-finance" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "education" ? (
        <Icon className="ico-hu-c-education" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "art and entertainment" ? (
        <Icon className="ico-hu-c-art" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "fashion" ? (
        <Icon className="ico-hu-c-fashion" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "food and beverage" ? (
        <Icon className="ico-hu-c-food" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "gaming and toys" ? (
        <Icon className="ico-hu-c-game" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "home and decoration" ? (
        <Icon className="ico-hu-c-home" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "lifestyle" ? (
        <Icon className="ico-hu-c-life" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "family" ? (
        <Icon className="ico-hu-c-family" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "technology and gadget" ? (
        <Icon className="ico-hu-c-tech" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "travel" ? (
        <Icon className="ico-hu-c-travel" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "sport and health" ? (
        <Icon className="ico-hu-c-sport" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "blockchain" ? (
        <Icon className="ico-hu-c-blockchain" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "cryptocurrency" ? (
        <Icon className="ico-hu-c-crypto" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "nft" ? (
        <Icon className="ico-hu-c-nft" sx={{ fontSize: "40px" }} />
      ) : itemsCategory === "other" ? (
        <Box></Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
}
