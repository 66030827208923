// mui and style
import { Box, Divider, Icon, Typography } from "@mui/material";
import { useSocialTabStyles as classes } from "./SocialTab.style";
// components
import Overall from "./Overall";
import OtherPlatform from "./OtherPlatform";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// other
import GetPlatform from "@global/get-platform";
import { useHistory, useLocation, useParams } from "react-router-dom";

export default function SocialTab() {
  const { influencerId } = useParams<{ influencerId: string }>();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const platformSelected = params.get("platform") || "over-all";

  const accountInfo = useAppSelector(selectInfluencerSocial);

  const handleTabPlatformChange = (platform: string) => {
    history.replace(
      `/influencers/${influencerId}/info?tab=social&platform=${platform}`
    );
  };

  return (
    <Box sx={classes.container}>
      {Object.values(accountInfo || {}).length ? (
        <>
          <Box display="flex" flexDirection="column" width="20%" rowGap="12px">
            <Box
              sx={
                platformSelected === "over-all"
                  ? classes.tabSelected
                  : classes.tab
              }
              onClick={() => handleTabPlatformChange("over-all")}
            >
              <Icon className="ico-hu-conversion" color="primary" />
              <Typography ml="10px">Overall</Typography>
            </Box>
            {Object.values(accountInfo || {}).map((tab, index) => {
              if (tab.length <= 0) return null;
              return (
                <Box
                  key={index}
                  sx={
                    platformSelected === tab?.[0]?.platform
                      ? classes.tabSelected
                      : classes.tab
                  }
                  onClick={() => handleTabPlatformChange(tab?.[0]?.platform)}
                >
                  <GetPlatform platform={tab?.[0]?.platform} />
                  <Typography
                    ml="5px"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {tab?.[0]?.social_account?.[0]?.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Divider orientation="vertical" flexItem />
            <Box width="80%">
              {platformSelected === "" ? null : platformSelected !==
                "over-all" ? (
                <OtherPlatform />
              ) : (
                <Overall />
              )}
            </Box>
        </>
      ) : (
        <Box sx={classes.noSocial}>
          <img
            src={require("@assets/images/infographic/admin/influencer-list/social-tab/no-activity.png")}
            alt="no campaign"
          />
          <Box sx={classes.textNoSocial}>
            <Typography variant="h5" my="8px">
              ไม่พบการเชื่อมต่อบัญชี
            </Typography>
            <Typography>
              อินฟลูเอนเซอร์ยังไม่ได้เชื่อมต่อบัญชีกับ HashU
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
