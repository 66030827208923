import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { PackageAPI, PaymentAPI } from "@api/global";
import { IPackageInfoState } from "./type";

const initialState: IPackageInfoState = {
  data: null,
  loading: false,
  error: "",
  balance: 0,
  packageSelected: null
};

export const getPackageInfoAsync = createAsyncThunk(
  "packageInfoAsync",
  async () => {
    try {
      const response = await PackageAPI.getOnePackage();
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const getWalletBalanceAsync = createAsyncThunk(
  "walletBalanceAsync",
  async (req:{ isPersonal:boolean, employer_id?:string, team_id?:string }) => {
    try {
      const response = await PaymentAPI.checkBalance(req.isPersonal, req.employer_id, req.team_id);
      return response;
    } catch (err) {
      throw err;
    } 
  }
);

export const PackageInfoSlice = createSlice({
  name: "packageInfo",
  initialState,
  reducers: {
    setPackageSelected: ( state, { payload }) => {
      state.packageSelected = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackageInfoAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPackageInfoAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getPackageInfoAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      })
      .addCase(getWalletBalanceAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getWalletBalanceAsync.fulfilled, (state, action) => {
        state.balance = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getWalletBalanceAsync.rejected, (state, action) => {
        state.balance = 0;
        state.loading = false;
        state.error = action.error.message || "";
      })
  },
});

export const { setPackageSelected } = PackageInfoSlice.actions

export const selectPackageList = (state: RootState) => state.packageInfo.data
export const selectWalletBalance = (state: RootState) => state.packageInfo.balance
export const selectPackageListOnload = (state: RootState) => state.packageInfo.loading
export const selectPackageSelected = (state: RootState) => state.packageInfo.packageSelected

export default PackageInfoSlice.reducer;
