export const initShippingProduct = {
  product_shipping: {
    shipping_type: "",
    tracking_no: "",
    note: "",
  },
  conversion: {
    lead_link: "",
    coupon: "",
    coupon_name: "",
    start_validity_period: "",
    end_validity_period: "",
    note: "",
  },
};

export const shippingTypeOption = [
  {
    id: 0,
    value: "ThailandPost",
    label: "ไปรษณีไทย",
    img: require("@assets/images/tarcking/thai-post.jpeg"),
  },
  {
    id: 1,
    value: "Kerry Express",
    label: "Kerry Express",
    img: require("@assets/images/tarcking/kerry.jpeg"),
  },
  {
    id: 2,
    value: "SCG express",
    label: "SCG express",
    img: require("@assets/images/tarcking/rscg.jpeg"),
  },
  {
    id: 3,
    value: "Flash Express",
    label: "Flash Express",
    img: require("@assets/images/tarcking/rflash.jpeg"),
  },
  {
    id: 4,
    value: "J&T express",
    label: "J&T express",
    img: require("@assets/images/tarcking/rj&t.jpeg"),
  },
  {
    id: 5,
    value: "Speed-D",
    label: "Speed-D",
    img: require("@assets/images/tarcking/speed-d.jpeg"),
  },
  {
    id: 6,
    value: "Nim Express",
    label: "Nim Express",
    img: require("@assets/images/tarcking/rnim.jpeg"),
  },
  {
    id: 7,
    value: "Ninja Van",
    label: "Ninja Van",
    img: require("@assets/images/tarcking/ninjavan.jpeg"),
  },
  {
    id: 8,
    value: "DHL",
    label: "DHL",
    img: require("@assets/images/tarcking/rdhl.png"),
  },
  {
    id: 9,
    value: "Grab",
    label: "Grab",
    img: require("@assets/images/tarcking/grab.png"),
  },
  {
    id: 10,
    value: "Line Man",
    label: "Line Man",
    img: require("@assets/images/tarcking/lineman.png"),
  },
  {
    id: 11,
    value: "Lala Move",
    label: "Lala Move",
    img: require("@assets/images/tarcking/lalamove.png"),
  },
  {
    id: 12,
    value: "Othder",
    label: "Othder",
    img: "",
  },
];
