import React, { useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Icon,
  useTheme,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import { useCampaignBriefStyle as classes } from "./Campaign.style";
// type and api
import {
  ICampaignList,
  ICampaignFilter,
  ICampaignParams,
} from "./Campaign.type";
import { CampaignAPI, EmployerAPI } from "@api/global";
// component
import CampaignFilter from "@components/campaign/CampaignFilter";
import CampaignAdminTabs from "@components/campaign/CampaignAdminTabs";
import CampaignCard from "@components/global/campaign-card/CampaignCard";
import CampaignEmployerTabs from "@components/campaign/CampaignEmployerTabs";
// redux
import { useAppDispatch } from "@store/hook";
import { getCategoryAsync } from "@slices/CategoryList.slice";
import { getEmployerInfoAsync } from "@slices/EmployerInfo.slice";
// other
import Loading from "@components/global/loading/Loading";
import { useHistory, useLocation } from "react-router-dom";
import PaginationCustom from "@components/global/pagination-custom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function Campaign() {
  const [openFilter, setOpenFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsPerPageData, setRowsPerPageData] = useState<number[]>([]);
  const [campaignList, setCampaignList] = useState<ICampaignList | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;
  const employerID = session_token?.employer_id || "";

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const mdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const search = params.get("q") || "";
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const type = params.get("type") || "";
  const orderBy = params.get("order_by") || "date";
  const sort = params.get("sort") || "DESC";
  const cate = params.get("cate");
  const categories = cate ? cate.split(",").map(Number) : [];

  useEffect(() => {
    if (lgScreen) {
      setRowsPerPage(16);
      setRowsPerPageData([16, 20, 24]);
    } else if (mdScreen) {
      setRowsPerPage(15);
      setRowsPerPageData([15, 21, 27]);
    } else if (xsScreen) {
      setRowsPerPage(16);
      setRowsPerPageData([20]);
    } else {
      setRowsPerPage(15);
      setRowsPerPageData([15, 20, 25]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lgScreen, mdScreen, xsScreen]);

  useEffect(() => {
    if (role !== "employer") return
    dispatch(getEmployerInfoAsync())
    // eslint-disable-next-line
  },[dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCategoryAsync());
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;
    const params: ICampaignFilter = {
      status: status,
      search: search,
      page: page,
      item_per_page: rowsPerPage,
      campaign_type: type,
      platform: platform,
      order_by: orderBy,
      sort: sort,
      category: categories,
    };

    const api =
      role === "employer"
        ? EmployerAPI.getEmployerCamapign(employerID, params)
        : CampaignAPI.getCampaignList(params);

    api.then((response) => {
      if (isMounted) {
        setCampaignList(response);
        setIsLoading(false);
      }})
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [status, search, page, rowsPerPage, type, platform, orderBy, sort]);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    const params: ICampaignParams = {
      page: pageValue,
      q: search,
      status: status,
      sort: sort,
      order_by: orderBy,
      platform: platform,
      type: type,
      cate: categories,
    };

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof ICampaignParams]}`)
      .join("&");

    history.replace(`/campaigns?${queryString}`);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const goCampaignInfo = (empId: string, campId: string) => {
    if (role === "employer") {
      history.push(`/campaigns/${campId}/info`);
    } else {
      history.push(`/campaigns/${campId}/info?emp_id=${empId}`);
    }
  };

  const handleSearchChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    history.replace(`/campaigns?q=${search}&status=${status}`);
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <CampaignFilter open={openFilter} onClose={setOpenFilter} />
      <Box sx={classes.hederTabWrap}>
        <Box sx={classes.headerTab}>
          <div>
            <Typography variant="h5" sx={classes.campaignTitle}>
              Campaign
            </Typography>
            {role === "employer" ? (
              <CampaignEmployerTabs campaignList={campaignList} />
            ) : (
              <>
                {mdScreen && (
                  <div>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              className="ico-hu-search"
                              sx={{ paddingRight: "2px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={search}
                      onChange={handleSearchChange}
                      sx={{
                        maxHeight: "32px",
                        minWidth: "200px",
                        maxWidth: "300px",
                        marginRight: "12px",
                      }}
                    />
                  </div>
                )}
                <CampaignAdminTabs campaignList={campaignList} onClose={setOpenFilter} />
              </>
            )}
          </div>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        <Grid container spacing={5}>
          {campaignList?.item?.length ? (
            campaignList?.item.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                <Box
                  key={item.id}
                  sx={{ cursor: "pointer" }}
                  onClick={() => goCampaignInfo(item.company.employer_id, item.id)}
                >
                  <CampaignCard
                    isCampaign={true}
                    name={item.name}
                    status={item.status}
                    limit={item.job_limit}
                    update={item.updated_at}
                    platform={item.platform}
                    category={item.category}
                    jobCount={item.job_count}
                    avgBudget={item.avg_budget}
                    coverUrl={item.cover_image?.url}
                    contentType={item.content_type}
                    companyUrl={item.company?.media?.url}
                  />
                </Box>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              minHeight={{ xs: "65vh", sm: "73vh", xl: "80vh" }}
            >
              <Box sx={classes.noCampaign}>
                {status === "" ? (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/my-jobs/no-jobs.png")}
                      alt="no campaign"
                    />
                    <Box sx={classes.noCampDiscWrap}>
                      <Typography variant="h5" my="8px">
                        ไม่พบผลการค้นหา
                      </Typography>
                      <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <img
                      src={require("@assets/images/infographic/influencer/search-not-found.png")}
                      alt="search not found"
                    />
                    <Box sx={classes.noCampDiscWrap}>
                      <Typography variant="h5" my="8px">
                        ไม่พบผลการค้นหา
                      </Typography>
                      <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={classes.pagination}>
        {campaignList?.item?.length ? (
          <PaginationCustom
            page={page}
            count={
              page > 0
                ? Math.ceil((campaignList.total_item || 0) / rowsPerPage)
                : 0
            }
            onPaginationChange={handleChangePage}
            rowsPerPageData={rowsPerPageData}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Box>
    </div>
  );
}
