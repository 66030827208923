import { useRef, useState } from "react";
// material
import {
  Box,
  Card,
  Grid,
  Stack,
  Avatar,
  Divider,
  CardHeader,
  Typography,
  CardContent,
} from "@mui/material";
// style
import { ButtonSignUpColorful } from "../home-main/HomeMain.styles";
import { useHomeInfluencerStyle as classes } from "./HomeInfluencer.style";
// components
import { InfulencerImage } from "./InfulencerImage";
import { InfluencerCommunity } from "./InfluencerCommunity";
import { CarouselContent } from "../global/carousel-content";
// types and utils
import { IBreakpoint, IDevice } from "@views/global/global.types";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";
// images
import CarCate from "@assets/images/home/influencer-page/category-icon/car.png";
import PetCate from "@assets/images/home/influencer-page/category-icon/pet.png";
import GameCate from "@assets/images/home/influencer-page/category-icon/game.png";
import FoodCate from "@assets/images/home/influencer-page/category-icon/food.png";
import SportCate from "@assets/images/home/influencer-page/category-icon/sport.png";
import BeautyCate from "@assets/images/home/influencer-page/category-icon/beauty.png";
import FamilyCate from "@assets/images/home/influencer-page/category-icon/family.png";
import FashionCate from "@assets/images/home/influencer-page/category-icon/fashion.png";
import TechologyCate from "@assets/images/home/influencer-page/category-icon/techology.png";
import InvestmentCate from "@assets/images/home/influencer-page/category-icon/investment.png";
import InfographicDesk from "@assets/images/home/influencer-page/infographic/d-influencer-info.png";
import InfographicTablet from "@assets/images/home/influencer-page/infographic/t-influencer-info.png";
import InfographicMobile from "@assets/images/home/influencer-page/infographic/p-influencer-info.png";
// mock influencer
import influencer1 from "@assets/images/home/influencer-page/influencer_s-hashu/pakkanaa.jpg";
import influencer2 from "@assets/images/home/influencer-page/influencer_s-hashu/jnjenny.jpg";
import influencer3 from "@assets/images/home/influencer-page/influencer_s-hashu/copyggolf.jpg";
import influencer4 from "@assets/images/home/influencer-page/influencer_s-hashu/miickyhengpangpua.jpg";
import influencer5 from "@assets/images/home/influencer-page/influencer_s-hashu/ninkkie.jpg";
import influencer6 from "@assets/images/home/influencer-page/influencer_s-hashu/guiguitargg.jpg";
import influencer7 from "@assets/images/home/influencer-page/influencer_s-hashu/tntinna.jpg";
import influencer8 from "@assets/images/home/influencer-page/influencer_s-hashu/choteklsw.jpg";
import influencer9 from "@assets/images/home/influencer-page/influencer_s-hashu/ploy_parie.jpg";
import influencer10 from "@assets/images/home/influencer-page/influencer_s-hashu/keeuiiniy.jpg";
import influencerSay3 from "@assets/images/home/influencer-page/influencer_s-hashu/Eelawnuht4.jpg";
import influencerSay1 from "@assets/images/home/influencer-page/influencer_s-hashu/aliane-hero.jpg";
import influencerSay2 from "@assets/images/home/influencer-page/influencer_s-hashu/Cookirpavisa.png";

export default function HomeInfluencer({
  device,
  breakpoint,
  setOpenCreateAccount,
}: {
  device: IDevice;
  breakpoint: IBreakpoint;
  setOpenCreateAccount: (isOpen: boolean) => void;
}) {
  const categoryBox = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const { session_token } = getTokenFromStorage();

  const category = [
    InvestmentCate,
    BeautyCate,
    FashionCate,
    FoodCate,
    FamilyCate,
    SportCate,
    GameCate,
    CarCate,
    PetCate,
    TechologyCate,
  ];

  const influencerList = [
    {
      name: "Pakkanaa",
      followers: 48000,
      image: influencer1,
      url: "https://www.instagram.com/pakkanaa/?fbclid=IwAR3RmpRy7h9HrDItQjSpehbb6EO3uz97Ie_eBF3-HMG6hh_sdBW0Hyci30I"
    },
    {
      name: "Jnjennyblog",
      followers: 20000,
      image: influencer2,
      url: "https://www.instagram.com/jnjenny.blog/"
    },
    {
      name: "Copyggolf",
      followers: 16000,
      image: influencer3,
      url: "https://www.tiktok.com/@copyggolf?fbclid=IwAR0puWE-I15hVTfH4s7FnEInDkXB5IUK4OOLw8Ycdx9Ejggfv_dxJ9uyFms"
    },
    {
      name: "Miickyhengpangpua",
      followers: 4000,
      image: influencer4,
      url: "https://www.tiktok.com/@miickyhengpangpua?_t=8YCerXqcnje&_r=1&fbclid=IwAR23rD-YXVaxiFbSFQBnygsEKxPGqaBZUZTkUBQZt5TM1kVVqcP7ZJ6g22o"
    },
    {
      name: "Ninkkie",
      followers: 3000,
      image: influencer5,
      url: "https://www.tiktok.com/@ninkkieee"
    },
    {
      name: "Guiguitargg",
      followers: 52000,
      image: influencer6,
      url: "https://www.instagram.com/guiguitargg?fbclid=IwAR1YvtRALotlgzVn3BQlYtBeSPDEAf4sYtPge-GlK_B2t6ln25gGDZoqVPI"
    },
    {
      name: "Tntinna",
      followers: 10000,
      image: influencer7,
      url: "https://www.instagram.com/tntinna/"
    },
    {
      name: "Choteklsw",
      followers: 2000,
      image: influencer8,
      url: "https://www.instagram.com/tntinna/"
    },
    {
      name: "Ploy_parie",
      followers: 3000,
      image: influencer9,
      url: "https://www.instagram.com/ploy_pariel/"
    },
    {
      name: "Keeuiiniy",
      followers: 4000,
      image: influencer10,
      url: "https://www.instagram.com/keeuiiniy/"
    },
  ];
  
  const influencerSayList = [
    {
      name: "Aliane_hero",
      followers: 69900,
      image: influencerSay1,
      comment:
        "ดีลงานให้ครบ เข้าใจง่าย บัทเจทดีงาม เหมาะสมค่ะ  เพราะ influencer แม่และลูก แม่ว่าเด็กเป็นอะไรที่ควบคุมยาก และต้องใช้เวลาในการถ่าย ซึ่งทางทีมก็บรีฟงานได้ดีค่ะ",
    },
    {
      name: "Cookirpavisa",
      followers: 16900,
      image: influencerSay2,
      comment:
        "พี่ๆของบริษัทน่ารักมากเลย สุภาพ และเป็นกันเองมากๆ 😳 ไม่เกร็งเลย มีอะไรสงสัย หรืออยากสอบถามก็ทักหาได้เลย พี่ๆ พร้อมให้คำตอบที่สงสัยทุกครั้งที่ทักไป ❤️ ส่วนงานพี่ๆ ของบริษัทก็แจ้งรายละเอียดข้อมูลงานทุกอย่างไว้ละเอียดยิบ สะดวกต่อการทำงานมาก เลยทำรอบเดียวผ่านเลยย🥰",
    },
    {
      name: "Eelawnuht4",
      followers: 62300,
      image: influencerSay3,
      comment:
        "พอได้ร่วมงานกับทาง HashU agency ประทับใจมากค่ะ ทั้งการให้รายละเอียดงานที่ชัดเจน การบรีฟงาน ตรวจงาน หรือตามงานให้ตรงเวลา ชอบตรงที่เวลาต้องแก้งานตรงไหน ก็จะแจ้งชัดเจนตามจุดที่ต้องแก้ ทำให้ไม่ต้องมาแก้งานหลายๆรอบ แล้วก็การพูดคุยกับพี่ๆ น่ารักมากๆค่า ไม่เครียดเกินไป ทำให้รู้สึกสบายใจที่จะได้ทำงานด้วยค่า ❤",
    },
  ];

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const createInfluencerSayCardArray = () => {
    if (influencerSayList.length > 0) {
      let arr = [];
      let displayCount = device === "desktop" ? 3 : device === "tablet" ? 2 : 1;
      const maxPage = Math.ceil(influencerSayList.length / displayCount);
      for (let i = 0; i < maxPage; i++) {
        let arr2 = [];
        const defaultLength = displayCount * (i + 1);
        const length =
          influencerSayList.length >= defaultLength
            ? defaultLength
            : influencerSayList.length;
        for (let j = i * displayCount; j < length; j++) {
          arr2.push(influencerSayList[j]);
        }
        arr.push(arr2);
      }
      return arr;
    } else {
      return [];
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(event.clientX - (categoryBox.current?.offsetLeft || 0));
    setScrollLeft(categoryBox.current?.scrollLeft || 0);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isDragging) return;
    const x = event.clientX - (categoryBox.current?.offsetLeft || 0);
    const walk = (x - startX) * 2;
    if (categoryBox.current) {
      categoryBox.current.scrollLeft = scrollLeft - walk;
    }
  };

  return (
    <Stack>
      <Box id="back-to-top-anchor" sx={classes.containerStack}>
        <Box sx={classes.coverBox}>
          <Box>
            <Box
              pt={[null, 30, 30, 18]}
              pb={[4, 2, 2, 4]}
              sx={classes.yellowColor}
            >
              <Typography
                color="inherit"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "h4",
                  null,
                  null,
                  "h2"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                Influencer
              </Typography>
            </Box>
            <Box pb={[6, 4, 5, 10]}>
              <Typography
                color="common.white"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "body1",
                  "body1",
                  "body1",
                  "h5"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                เปิดประสบการณ์การเป็น Influencer
                <br />
                สู่สังคม Influencer ที่มีประสิทธิภาพ
                {device === "mobile" && <br />}
                พร้อมช่วยพัฒนาทักษะ {device !== "mobile" && <br />}
                สร้างชื่อเสียง {device === "mobile" && <br />}
                ให้เป็นที่รู้จักของแบรนด์ต่างๆ มากมาย
              </Typography>
            </Box>
            <Box pb={[7, 9, 10, 12]} sx={classes.yellowColor}>
              <Typography
                color="inherit"
                variant={setValueByBreakpoint(
                  breakpoint,
                  "h6",
                  null,
                  null,
                  "h4"
                )}
                align={setValueByBreakpoint(breakpoint, "center", "left")}
              >
                “ไม่ว่าคุณจะเป็นใคร แค่มีใจรักการรีวิว
                <br />
                ก็สามารถเข้าร่วมสังคมอินฟลูกับเราได้”
              </Typography>
            </Box>
            {session_token != null && (
              <Box
                textAlign={device === "mobile" ? "center" : undefined}
                pb={18}
              >
                <ButtonSignUpColorful
                  onClick={() => setOpenCreateAccount(true)}
                >
                  <Typography variant="body1">สมัครเป็น Influencer</Typography>
                </ButtonSignUpColorful>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        bgcolor="common.white"
        pt={[6, 7, 7, 9]}
        pb={[4, 5, 5, 7]}
        px={[8, 12, 12, 30]}
      >
        <Box
          ref={categoryBox}
          onMouseDown={handleMouseDown}
          onMouseUp={() => setIsDragging(false)}
          onMouseLeave={() => setIsDragging(false)}
          onMouseMove={handleMouseMove}
          sx={classes.category}
        >
          {category.map((cate, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={cate}
                alt="Who we are"
                style={{
                  width: setValueByBreakpoint(
                    breakpoint,
                    "40px",
                    "52px",
                    "52px",
                    "78px"
                  ),
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={classes.infographicBox}>
        <Stack
          alignItems="center"
          height="100%"
          py={[3, 8, 10, 20]}
          px={[4, 0]}
        >
          <Typography
            color="common.black"
            variant={setValueByBreakpoint(breakpoint, "h5", null, null, "h3")}
            fontWeight={setValueByBreakpoint(
              breakpoint,
              "bold",
              null,
              null,
              "normal"
            )}
            align="center"
            mb={[5, 4, 4, 5]}
          >
            3 ขั้นตอน เป็น <span style={classes.pinkColor}>Influencer</span>{" "}
            {device === "mobile" && <br />}กับ HashU ได้ง่ายๆ
          </Typography>
          <Typography
            color="common.black"
            variant={setValueByBreakpoint(
              breakpoint,
              "body1",
              null,
              null,
              "h5"
            )}
            align="center"
            mb={[10, 10, 10, 13]}
          >
            ให้คุณได้เปิดประตูสู่สังคม Influencer{" "}
            {device === "mobile" && <br />} แบบใหม่ ที่สนุก และไม่ซ้ำใคร
            <br />
            ไปด้วยกันเรา ด้วย 3 ขั้นตอนง่ายๆ
          </Typography>
          <img
            src={
              device === "desktop"
                ? InfographicDesk
                : device === "tablet"
                ? InfographicTablet
                : InfographicMobile
            }
            alt="step infographic"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "90%",
                "90%",
                "90%",
                "80%"
              ),
              height: "auto",
            }}
          />
        </Stack>
      </Box>

      <Box bgcolor="common.white">
        <InfluencerCommunity breakpoint={breakpoint} />
      </Box>

      <Box sx={classes.influencerHashUBox}>
        <Stack sx={classes.influencerHashUStack}>
          <Typography
            color="common.white"
            variant={setValueByBreakpoint(breakpoint, "h6", "h5", null, "h4")}
            fontWeight="bold"
            align={setValueByBreakpoint(breakpoint, "center", "right")}
            pb={[5, 4, 4, 8]}
          >
            เข้าสู่สังคม Influencer ไปกับ HashU
          </Typography>
          <Typography
            color="common.white"
            variant={setValueByBreakpoint(
              breakpoint,
              "body1",
              null,
              null,
              "h6"
            )}
            align={setValueByBreakpoint(breakpoint, "center", "right")}
            pb={[8, 10, 10, 14]}
          >
            ร่วมสร้างสังคมใหม่ เปิดโลก Influencer{" "}
            {device === "mobile" && <br />}
            ที่ใหญ่
            {device !== "mobile" && <br />}
            และมีประสิทธิภาพยิ่งขึ้น ให้คุณสนุก {device === "mobile" && <br />}
            มีความสุข
            {device !== "mobile" && <br />}
            ในการสร้างทุกคอนเทนต์ไปกับเรา
          </Typography>
          {session_token != null && (
            <Box
              textAlign={device === "mobile" ? "center" : "right"}
              mb={[12, 0]}
            >
              <ButtonSignUpColorful
                color="secondary"
                variant="contained"
                onClick={() => setOpenCreateAccount(true)}
              >
                <Typography variant="body1">สมัครเป็น Influencer</Typography>
              </ButtonSignUpColorful>
            </Box>
          )}
        </Stack>
      </Box>

      <Box
        bgcolor="common.white"
        py={[14, 16, 16, 20]}
        px={[6, 18, 25, 40, 70]}
      >
        <Stack>
          <Typography
            color="secondary"
            variant={setValueByBreakpoint(breakpoint, "h5", null, null, "h3")}
            fontWeight={setValueByBreakpoint(
              breakpoint,
              "bold",
              null,
              null,
              "normal"
            )}
            align="center"
            pb={[4, 4, 5, 6]}
          >
            Influencer’s HashU
          </Typography>
          <Typography
            color="common.black"
            variant={setValueByBreakpoint(
              breakpoint,
              "body1",
              null,
              null,
              "h5"
            )}
            align="center"
            pb={[6, 10, 10, 10]}
          >
            เหล่า <span style={classes.pinkColor}>Influencer</span> สุดคูล ของ
            HashU{device === "mobile" && <br />} ที่รวบไว้กว่าครึ่งวงการ
            {device !== "mobile" && <br />}
            ไม่ว่าจะเป็นไลฟ์สไตล์ใด{device === "mobile" && <br />} อยู่สายไหน
            ก็เป็น Influencer ร่วมกับเราได้
            <br />
            พร้อมมาสร้างผลงานสุดคูล และสร้างสังคม{device === "mobile" && (
              <br />
            )}{" "}
            Influencer สุดเจ๋งไปพร้อมๆ กัน
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={{ xs: 12, sm: 12, md: 12, lg: 10 }}
            rowSpacing={{ xs: 4, sm: 6 }}
            columnSpacing={{ xs: 4, sm: 6 }}
          >
            {influencerList
              .filter((_, index) => {
                const range =
                  breakpoint === "xs"
                    ? 4
                    : breakpoint === "sm"
                    ? 6
                    : breakpoint === "md"
                    ? 8
                    : 10;
                if (index < range) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((influ, index) => (
                <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
                  <InfulencerImage
                    key={index}
                    breakpoint={breakpoint}
                    influencer={influ}
                  />
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Box>

      <Box sx={classes.carouselBox}>
        <Stack justifyContent="center" alignItems="center" height="100%">
          <Typography
            color="common.black"
            variant={setValueByBreakpoint(breakpoint, "h6", "h5", null, "h3")}
            fontWeight={setValueByBreakpoint(
              breakpoint,
              "bold",
              null,
              null,
              "normal"
            )}
            align="center"
            pb={[1, 8, 8, 8, 12]}
          >
            What <span style={classes.pinkColor}>Influencers</span> say about us
          </Typography>
          <Box>
            <CarouselContent
              device={device}
              maxLength={
                device === "desktop"
                  ? Math.ceil(influencerSayList.length / 3)
                  : device === "tablet"
                  ? Math.ceil(influencerSayList.length / 2)
                  : influencerSayList.length
              }
              slideIconHeight="140px"
            >
              {createInfluencerSayCardArray().map((x, i) => (
                <Box
                  key={"data-1-" + i}
                  display="flex"
                  mx="auto"
                  sx={classes.contentBox}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    columnSpacing={setValueByBreakpoint(breakpoint, 0, 4, 6, 8)}
                    minHeight={["300px", "380px", "400px", "400px"]}
                    px={[0, 1, 4, 2]}
                  >
                    {x.map((y, j) => (
                      <Grid
                        key={"data-2-" + j}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        px={[0, 2, 2, 0]}
                        py={[4, 2, 2, 0]}
                        height="100%"
                      >
                        <Card sx={classes.influencerCard}>
                          <CardHeader
                            avatar={
                              <Avatar
                                alt="Remy Sharp"
                                src={y.image}
                                sx={classes.influencerAvatar}
                              />
                            }
                            title={
                              <Typography
                                variant="body1"
                                color="common.white"
                                fontWeight="bold"
                                align="left"
                                mb={2}
                              >
                                {y.name}
                              </Typography>
                            }
                            subheader={
                              <Typography
                                variant={setValueByBreakpoint(
                                  breakpoint,
                                  "caption",
                                  "body2"
                                )}
                                fontWeight="normal"
                                color="common.white"
                                align="left"
                              >
                                {numberFormat.format(y.followers)}{" "}
                                followers
                              </Typography>
                            }
                            sx={classes.influencerCardHeader}
                          />
                          <Divider sx={classes.divider} />
                          <CardContent sx={classes.influencerCardContent}>
                            <Typography
                              color="common.white"
                              variant={setValueByBreakpoint(
                                breakpoint,
                                "body2",
                                "body1"
                              )}
                              align="left"
                              lineHeight={1.7}
                            >
                              <span style={{ fontSize: "28px !important" }}>
                                "{" "}
                              </span>
                              {y.comment}
                              <span style={{ fontSize: "28px !important" }}>
                                {" "}
                                "
                              </span>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </CarouselContent>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
