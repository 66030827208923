// mui
import {
  Box,
  Icon,
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
// style and icons
import { usePlaceDetailDialogStyle as classes } from "../JobsTab.style";
import CancelIcon from "@mui/icons-material/Cancel";
// type
import { IPlaceDetailDialogProps } from "../JobsTab.type";

export default function PlaceDetailDialog(props: IPlaceDetailDialogProps) {
  const { place, openPlace, setOpenPlace } = props;

  return (
    <Dialog
      open={openPlace}
      onClose={() => setOpenPlace(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={classes.dialog}
    >
      <DialogTitle
        sx={{
          ...classes.dialogTitle,
          width: "89%",
          mt: "10px",
        }}
        id="alert-dialog-title"
      >
        <Typography variant="title1">Location</Typography>
        <IconButton onClick={() => setOpenPlace(false)}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.contentDialog}>
        <Box sx={{ "& img": { width: "100%" }, mt: "24px" }}>
          <img src={place?.static_map_url} alt="static map" />
        </Box>
        <Box>
          <Typography variant="h5" mt="15px">
            {place?.name}
          </Typography>
          <Box display="flex" mt="14px">
            <Icon className="ico-hu-location" color="primary" />
            <Box ml={5}>
              <Typography variant="title1" color="primary">
                Address
              </Typography>
              <Typography mt={1}>{place?.address}</Typography>
            </Box>
          </Box>
          <Box display="flex" mt="14px">
            <Icon className="ico-hu-phone" color="primary" />
            <Box ml={5}>
              <Box display="flex" flexDirection="column">
                <Typography variant="title1" color="primary">
                  Contact
                </Typography>
                <Typography variant="subTitle2" mt={1}>
                  โปรดทำการติดต่อเจ้าหน้าที่เพื่อทำการนัดหมาย
                  ก่อนเดินทางไปยังสถานที่จริง
                </Typography>
              </Box>
              <Box component="ul" display="flex" gap={5}>
                <Box>
                  {["Contact Name", "Phone"].map((label) => (
                    <Typography component="li" key={label} ml={[-5, 0]} mb={3}>
                      {label}
                    </Typography>
                  ))}
                </Box>
                <Box display="flex" flexDirection="column">
                  {[place?.contact, place?.phone].map((label, index) => (
                    <Typography
                      key={index}
                      variant="title1"
                      ml={[-5, 0]}
                      mb={3}
                    >
                      {label}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack
          gap={3}
          sx={classes.btn}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Button
            variant="outlined"
            startIcon={<Icon className="ico-hu-location" />}
            onClick={() => window.open(place?.url, "_blank")}
          >
            เส้นทาง
          </Button>
          <a href={`tel:+66${place?.phone}`}>
            <Button
              variant="contained"
              startIcon={<Icon className="ico-hu-phone" />}
              fullWidth
            >
              ทำการนัดหมาย
            </Button>
          </a>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
