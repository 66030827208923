import { useEffect, useState } from "react";
// mui
import {
  Box,
  Chip,
  Icon,
  Radio,
  Button,
  Drawer,
  Slider,
  Divider,
  Collapse,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
// data
import {
  followers,
  genderList,
  sortPublic,
  sortPrivate,
  initialParams,
} from "./InfluencerListFilterData";
// redux
import {
  getCategoryAsync,
  selectCategoryList,
} from "@slices/CategoryList.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// others
import TextFieldCustom from "@global/text-field";
import { useFilterInfluencerListStyle as classes } from "./InfluencerListFilter.style";

export const initLength = {
  ltq: 100,
  gtq: 0,
};

function valuetext(value: number) {
  return `${value}°C`;
}

export default function InfluencerListFilter(props: any) {
  const {
    openFilterInfluencer,
    toggleDrawers,
    queryParams,
    setCampaignInfluencerListParams,
    campaignType,
  } = props;

  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(selectCategoryList);

  const [openInfluencer, setOpenInfluencer] = useState(false);
  const [openPerformance, setOpenPerformance] = useState(false);
  const [openTarget, setOpenTarget] = useState(false);
  const [valueAge, setValueAge] = useState<number[]>([0, 100]);
  const [valueEngagement, setValueEngagement] = useState<number[]>([0, 100]);
  const [valueTargetAge, setValueTargetAge] = useState<number[]>([0, 100]);
  const [filterData, setFilterData] = useState<any>(queryParams);

  useEffect(() => {
    dispatch(getCategoryAsync);
  }, [dispatch]);

  const handleOpenInfluencer = () => {
    setOpenInfluencer(!openInfluencer);
  };
  const handleOpenPerformance = () => {
    setOpenPerformance(!openPerformance);
  };
  const handleOpenTarget = () => {
    setOpenTarget(!openTarget);
  };

  const handleChangeAge = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") return;
    setValueAge(newValue as number[]);
    handleSelectFilter({
      influencer_age: {
        ltq: newValue[1],
        gtq: newValue[0],
      },
    });
  };

  const handleChangeEngagement = (
    _event: any,
    newValue: number | number[]
  ) => {
    if (typeof newValue === "number") return;
    setValueEngagement(newValue as number[]);
    handleSelectFilter({
      engagement_rate: {
        ltq: newValue[1],
        gtq: newValue[0],
      },
    });
  };

  const handleChangeTargetAge = (_event: any, newValue: number | number[]) => {
    if (typeof newValue === "number") return;
    setValueTargetAge(newValue as number[]);
    handleSelectFilter({
      target_age: {
        ltq: newValue[1],
        gtq: newValue[0],
      },
    });
  };

  const handleSelectFilter = (data: any) => {
    console.log("data", data);
    setFilterData((prev: any) => {
      return {...prev, ...data };
    });
  };

  const onApplyFilter = () => {
    setCampaignInfluencerListParams(filterData);
    toggleDrawers(false);
  };

  const onClearFilter = () => {
    setCampaignInfluencerListParams(initialParams);
    toggleDrawers(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openFilterInfluencer}
      onClose={() => toggleDrawers(false)}
      sx={classes.drawer}
    >
      {/* Header */}
      <Box sx={classes.headers}>
        <Box className="boxHeader">
          <Typography variant="h6">Filter</Typography>
          <Icon className="ico-hu-close" onClick={() => toggleDrawers(false)} />
        </Box>
      </Box>

      {/* Content */}
      <Box sx={classes.content}>
        {/* Sort By */}
        <Box sx={classes.sortBy}>
          <Typography variant="h6">Sort By</Typography>
          <FormControl>
            <RadioGroup sx={{ minHeight: "90px" }}>
              {campaignType === "public" ? (
                <>
                  {sortPublic.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          onChange={() => {
                            handleSelectFilter({
                              order_by: item.order_by,
                              sort: item.sort,
                            });
                          }}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </>
              ) : (
                <>
                  {sortPrivate.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={
                        <Radio
                          // checked={
                          //   `${filterData.order_by}${filterData.sort}` ===
                          //   item.value
                          // }
                          onChange={() => {}}
                          value={item.value}
                          inputProps={{ "aria-label": item.label }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </>
              )}
              {/*  */}
            </RadioGroup>
          </FormControl>

          <Divider sx={classes.divider} />
        </Box>

        {/* Search */}
        {/* <Box padding="0 24px">
          <Box sx={classes.subHeader} onClick={handleOpenSearch}>
            <Typography variant="h6">Search</Typography>
            {openSearch ? (
              <Icon className="ico-hu-chevron-up" />
            ) : (
              <Icon className="ico-hu-chevron-down" />
            )}
          </Box>

          <Collapse in={openSearch} timeout="auto" unmountOnExit>
            <Paper component="form" sx={classes.paperSearch}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                // onChange={handleChange}
                sx={{ width: "120px", height: "40px" }}
              >
                <MenuItem value="include">Include</MenuItem>
                <MenuItem value="exclude">Exclude</MenuItem>
              </Select>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Influencer, content, hashtag"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Icon className="ico-hu-search" />
              </IconButton>
            </Paper>
          </Collapse>

          <Divider sx={classes.divider} />
        </Box> */}

        {/* Influencer */}
        <Box padding="0 24px">
          <Box sx={classes.subHeader} onClick={handleOpenInfluencer}>
            <Typography variant="h6">Influencer</Typography>
            {openInfluencer ? (
              <Icon className="ico-hu-chevron-up" />
            ) : (
              <Icon className="ico-hu-chevron-down" />
            )}
          </Box>

          <Collapse in={openInfluencer} timeout="auto" unmountOnExit>
            <Box marginTop="20px">
              {/* Category */}
              <Typography variant="title1">Category</Typography>
              <Box sx={classes.boxCategory}>
                <Chip
                  label="All"
                  sx={{
                    ...classes.chip,
                    border: `1px solid ${
                      filterData.category?.length === 0 ? "#F4ECFF" : "#9e9e9e"
                    }`,
                    bgcolor:
                      filterData.category?.length === 0 ? "#F4ECFF" : "#fff",
                    color:
                      filterData.category?.length === 0 ? "#893DBD" : "#545454",
                  }}
                  onClick={() => {
                    handleSelectFilter({ category: "" });
                  }}
                />

                {categoryList.map((item) => {
                  return (
                    <Chip
                      key={item.id}
                      label={item.name_en}
                      sx={{
                        ...classes.chip,
                        border: `1px solid ${
                          filterData.category === item.id
                            ? "#F4ECFF"
                            : "#9e9e9e"
                        }`,
                        bgcolor:
                          filterData.category === item.id ? "#F4ECFF" : "#fff",
                        color:
                          filterData.category === item.id
                            ? "#893DBD"
                            : "#545454",
                      }}
                      onClick={() => {
                        handleSelectFilter({ category: Number(`${item.id}`) });
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            {/* Influencer Age */}
            <Box marginTop="20px">
              <Typography variant="title1">Age</Typography>
              <Box marginTop="20px">
                <Slider
                  getAriaLabel={() => "Age range"}
                  value={valueAge}
                  onChange={handleChangeAge}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextFieldCustom
                  value={valueAge[0]}
                  onChange={(e) => {
                    const value = Number(e.target.value.replaceAll(/\D/g, ""));
                    setValueAge((prev) => {
                      return value > 100 ? [100, prev[1]] : [value, prev[1]];
                    });
                    handleSelectFilter({
                      influencer_age: {
                        ltq: valueAge[1],
                        gtq: value > 100 ? 100 : value,
                      },
                    });
                  }}
                  variant="outlined"
                  placeholder="min"
                />
                <Box width="20%"></Box>
                <TextFieldCustom
                  value={valueAge[1]}
                  onChange={(e) => {
                    const value = Number(e.target.value.replaceAll(/\D/g, ""));
                    setValueAge((prev) => {
                      return value > 100 ? [prev[0], 100] : [prev[0], value];
                    });
                    handleSelectFilter({
                      influencer_age: {
                        ltq: value > 100 ? 100 : value,
                        gtq: valueAge[0],
                      },
                    });
                  }}
                  type="number"
                  variant="outlined"
                  placeholder="max"
                />
              </Box>
            </Box>

            {/* Location */}
            {/* <Box sx={{ marginTop: "20px" }}>
              <Typography variant="title1">Location</Typography>
              <Box sx={{ marginTop: "20px" }}>
                <MultipleSelectCustom
                  id="filter-influencer-location"
                  value={[]}
                  setValue={() => {}}
                  placeholder={"Search"}
                  data={provincesList}
                  fullWidth
                  valueKey="id"
                  labelKey="name_th"
                />
              </Box>
            </Box> */}

            {/* Gender */}
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="title1">Gender</Typography>
              <Box sx={classes.boxCategory}>
                {/* <Chip
                  label="All"
                  sx={{
                    ...classes.chip,
                    border: `1px solid ${
                      filterData.category?.length === 0 ? "#F4ECFF" : "#9e9e9e"
                    }`,
                    bgcolor:
                      filterData.category?.length === 0 ? "#F4ECFF" : "#fff",
                    color:
                      filterData.category?.length === 0 ? "#893DBD" : "#545454",
                  }}
                  onClick={() => {
                    handleSelectFilter(filterData);
                  }}
                /> */}
                {genderList.map((item) => {
                  return (
                    <Chip
                      key={item.id}
                      label={item.title}
                      sx={{
                        ...classes.chip,
                        border: `1px solid ${
                          filterData.category === item.id
                            ? "#F4ECFF"
                            : "#9e9e9e"
                        }`,
                        bgcolor:
                          filterData.influencer_gender === item.value
                            ? "#F4ECFF"
                            : "#fff",
                        color:
                          filterData.influencer_gender === item.value
                            ? "#893DBD"
                            : "#545454",
                      }}
                      onClick={() => {
                        handleSelectFilter({
                          influencer_gender: `${item.value}`,
                        });
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            {/* Follower */}
            <Box marginTop="20px">
              <Typography variant="title1">Followers</Typography>
              <Box>
                <FormControl>
                  <RadioGroup sx={{ minHeight: "90px" }}>
                    {followers.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item.value}
                        control={
                          <Radio
                            // onChange={() => {}}
                            onChange={() => {
                              handleSelectFilter({
                                follower: item.sort,
                              });
                            }}
                            value={item.value}
                            inputProps={{ "aria-label": item.label }}
                          />
                        }
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Collapse>

          <Divider sx={classes.divider} />
        </Box>

        {/* Performance */}
        <Box padding="24px">
          <Box sx={classes.subHeader} onClick={handleOpenPerformance}>
            <Typography variant="h6">Performance</Typography>
            {openPerformance ? (
              <Icon className="ico-hu-chevron-up" />
            ) : (
              <Icon className="ico-hu-chevron-down" />
            )}
          </Box>

          <Collapse in={openPerformance} timeout="auto" unmountOnExit>
            {/* Engagement Rate */}
            <Box marginTop="20px">
              <Typography variant="title1">Engagement Rage</Typography>
              <Box marginTop="20px">
                <Slider
                  getAriaLabel={() => "Engagement range"}
                  value={valueEngagement}
                  onChange={handleChangeEngagement}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextFieldCustom
                  value={valueEngagement[0]}
                  onChange={(e) => {
                    const value = Number(e.target.value.replaceAll(/\D/g, ""));
                    setValueEngagement((prev) => {
                      return value > 100 ? [100, prev[1]] : [value, prev[1]];
                    });
                    handleSelectFilter({
                      engagement_rate: {
                        ltq: valueEngagement[1],
                        gtq: value > 100 ? 100 : value,
                      },
                    });
                  }}
                  type="number"
                  variant="outlined"
                  placeholder="min"
                />
                <Box width="20%"></Box>
                <TextFieldCustom
                  value={valueEngagement[1]}
                  onChange={(e) => {
                    const value = Number(e.target.value.replaceAll(/\D/g, ""));
                    setValueEngagement((prev) => {
                      return value > 100 ? [prev[0], 100] : [prev[0], value];
                    });
                    handleSelectFilter({
                      engagement_rate: {
                        ltq: value > 100 ? 100 : value,
                        gtq: valueEngagement[0],
                      },
                    });
                  }}
                  type="number"
                  variant="outlined"
                  placeholder="max"
                />
              </Box>
            </Box>
          </Collapse>

          <Divider sx={classes.divider} />
        </Box>

        {/* Target Audience */}
        <Box padding="0 24px" marginBottom="40px">
          <Box sx={classes.subHeader} onClick={handleOpenTarget}>
            <Typography variant="h6">Target Audience</Typography>
            {openTarget ? (
              <Icon className="ico-hu-chevron-up" />
            ) : (
              <Icon className="ico-hu-chevron-down" />
            )}
          </Box>

          <Collapse in={openTarget} timeout="auto" unmountOnExit>
            {/* Target Age */}
            <Box marginTop="20px">
              <Typography variant="title1">Age</Typography>
              <Box marginTop="20px">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={valueTargetAge}
                  onChange={handleChangeTargetAge}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextFieldCustom
                  value={valueTargetAge[0]}
                  onChange={(e) => {
                    const value = Number(e.target.value.replaceAll(/\D/g, ""));
                    setValueTargetAge((prev) => {
                      return value > 100 ? [100, prev[1]] : [value, prev[1]];
                    });
                    handleSelectFilter({
                      target_age: {
                        ltq: valueTargetAge[1],
                        gtq: value > 100 ? 100 : value,
                      },
                    });
                  }}
                  type="number"
                  variant="outlined"
                  placeholder="min"
                />
                <Box width="20%"></Box>
                <TextFieldCustom
                  value={valueAge[1]}
                  onChange={(e) => {
                    const value = Number(e.target.value.replaceAll(/\D/g, ""));
                    setValueTargetAge((prev) => {
                      return value > 100 ? [prev[0], 100] : [prev[0], value];
                    });
                    handleSelectFilter({
                      target_age: {
                        ltq: value > 100 ? 100 : value,
                        gtq: valueTargetAge[0],
                      },
                    });
                  }}
                  type="number"
                  variant="outlined"
                  placeholder="max"
                />
              </Box>
            </Box>

            {/* Target Gender */}
            {/* <Box sx={{ margin: "20px 0" }}>
              <Typography variant="title1">Gender</Typography>
              <Box sx={classes.boxCategory}>
                <Chip
                  label="All"
                  sx={{
                    ...classes.chip,
                    border: `1px solid ${
                      filterData.category?.length === 0 ? "#F4ECFF" : "#9e9e9e"
                    }`,
                    bgcolor:
                      filterData.category?.length === 0 ? "#F4ECFF" : "#fff",
                    color:
                      filterData.category?.length === 0 ? "#893DBD" : "#545454",
                  }}
                  onClick={() => {
                    handleSelectFilter(filterData);
                  }}
                />
                {genderList.map((item) => {
                  return (
                    <Chip
                      key={item.id}
                      label={item.title}
                      sx={{
                        ...classes.chip,
                        border: `1px solid ${
                          filterData.category === item.id
                            ? "#F4ECFF"
                            : "#9e9e9e"
                        }`,
                        bgcolor:
                          filterData.influencer_gender === item.value
                            ? "#F4ECFF"
                            : "#fff",
                        color:
                          filterData.influencer_gender === item.value
                            ? "#893DBD"
                            : "#545454",
                      }}
                      onClick={() => {}}
                    />
                  );
                })}
              </Box>
            </Box> */}

            {/* Target Location */}
            {/* <Box sx={{ marginTop: "20px" }}>
              <Typography variant="title1">Location</Typography>
              <Box sx={{ marginTop: "20px" }}>
                <MultipleSelectCustom
                  id="filter-target-audience-location"
                  value={[]}
                  setValue={() => {}}
                  placeholder={"Search"}
                  data={provincesList}
                  fullWidth
                  valueKey="id"
                  labelKey="name_th"
                />
              </Box>
            </Box> */}
          </Collapse>
        </Box>
      </Box>

      {/* Footer */}
      <Box sx={classes.footer}>
        <Box className="boxFooter">
          <Typography
            variant="title1"
            color="#893DBD"
            onClick={onClearFilter}
            sx={{ cursor: "pointer" }}
          >
            Clear
          </Typography>
          <Button onClick={onApplyFilter}>Apply</Button>
        </Box>
      </Box>
    </Drawer>
  );
}
