import { ChangeEvent, useEffect, useState } from "react";
// mui and style
import { Box } from "@mui/material";
import { useEventDetailStyle as classes } from "./EventDetail.style";
// type and component
import { ITabPanelProps } from "../../employer/employer-detail/Detail.type";
import EventsBanner from "@components/home-events/EventsBanner";
import EventDetailsTab from "@components/home-events/event-detail/event-detail-tab/EventDetailTab";
import EventDetailTabs from "@components/home-events/event-detail/event-detail-tabs/EventDetailTabs";
import EventLeaderboardTab from "@components/home-events/event-detail/event-leaderboard-tab/EventLeaderboardTab";
// redux
import { useDispatch } from "react-redux";
import { getActivityAsync } from "@slices/Activity.slice";

export default function EventDetail() {
  const [tabSelected, setTabSelected] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActivityAsync({ page: 1, item_per_page: 10 }));
  }, [dispatch]);

  const handleChangeTab = (_event: ChangeEvent<{}>, newValue: number) => {
    setTabSelected(newValue);
  };

  const TabPanel = (props: ITabPanelProps) => {
    const { children, value, index } = props;
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const handleActiveTab = (index: string) => {
    return {
      id: `social-tab-${index}`,
      "aria-controls": `social-tabpanel-${index}`,
    };
  };

  return (
    <Box sx={classes.conatiner}>
      <EventsBanner />
      <Box mt={{ xs: "90px", md: "initial" }}>
        <EventDetailTabs
          handleChangeTab={handleChangeTab}
          handleActiveTab={handleActiveTab}
          tabSelected={tabSelected}
        />
        <EventDetailsTab
          index={0}
          TabPanel={TabPanel}
          tabSelected={tabSelected}
        />
        <EventLeaderboardTab
          index={1}
          TabPanel={TabPanel}
          tabSelected={tabSelected}
        />
      </Box>
    </Box>
  );
}
