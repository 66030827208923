import React, { useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Avatar,
  Button,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
// type and api
import { IPaymentMethodData } from "@slices/type";
import { PackageAPI, PaymentAPI } from "@api/global";
// style and icon
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { usePaymentMethodStyle as classes } from "./SubscriptionPayment.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectPackageSelected, selectWalletBalance } from "@slices/PackageInfo.slice";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
import { selectPaymentMethod } from "@slices/PaymentMethod.slice";
// other
import { useHistory, useParams } from "react-router-dom";
import Loading from "@global/loading/Loading";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function PaymentMethod(props: {
  grandTotal: number;
  prepay: number;
}) {
  const { grandTotal, prepay } = props;
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { type } = useParams<{ type:string }>()

  const { session_token } = getTokenFromStorage();
  const employerId = session_token?.employer_id || "";

  const packageSelected = useAppSelector(selectPackageSelected)
  const paymentMethodList = useAppSelector(selectPaymentMethod);
  const employerInfo = useAppSelector(selectEmployerInfo);
  const walletBalance = useAppSelector(selectWalletBalance);
  const isWalletBalance = grandTotal - walletBalance <= 0;
  const isPersonal = (employerInfo?.package_plan || packageSelected?.package_plan) === "personal";

  const internetBanking = paymentMethodList?.find((method) => method.id === 1);
  const mobileBanking = paymentMethodList?.find((method) => method.id === 2);
  const creditCard = paymentMethodList?.find((method) => method.id === 3);
  const eWallet = paymentMethodList?.find((method) => method.id === 4);
  const qrPayment = paymentMethodList?.find((method) => method.id === 5);

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const numberFormat = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const onChillPayPublisher = (data?: IPaymentMethodData) => {
    const plan = isPersonal
      ? { employer_id: employerId }
      : { team_id: employerInfo?.team?.id };
    const body = {
      ...plan,
      amount: grandTotal - walletBalance,
      description: "subscription-payment",
      pay_type: {
        id: 2,
      },
      pay_method: {
        id: data?.id || 0,
      },
      attachment: null,
      payment_plan: employerInfo?.package_plan || packageSelected?.package_plan || "personal"
    };
    PaymentAPI.chillpayPublisher(body)
      .then((response) => {
        if (response.PaymentUrl) {
          window.location.replace(response.PaymentUrl);
        } else {
          SwalCustom.fire({
            icon: "error",
            title: "Oops !",
            text: `${response.Message}`,
            showCancelButton: true,
            cancelButtonText: "Close",
            showConfirmButton: false,
          })
        }
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `มีบางอย่างผิดพลาด`,
          showCancelButton: true,
          cancelButtonText: "Close",
          showConfirmButton: false,
        });
      });
  };

  const onPayment = () => {
    const isYearly = packageSelected?.plan === "yearly";
    const body = {
      subscription_type: packageSelected?.package_plan || "",
      package_id: packageSelected?.package_id || 0,
      // is package === 1(free) month is -1
      month: packageSelected?.package_id === 1 ? -1 : isYearly ? 12 : prepay,
      yearly: isYearly,
      member_limit: packageSelected?.member || 0,
    };
    setIsLoading(true);
    PackageAPI.replanPackage(body)
      .then(() => {
        setIsLoading(false);
        history.push("/subscription");
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `มีบางอย่างผิดพลาด`,
          showCancelButton: true,
          cancelButtonText: "Close",
          showConfirmButton: false,
        });
      });
  };

  if (isLoading) return <Loading />

  return (
    <>
      {type === "downgrade" ? (
        <Box sx={classes.payBtn}>
          <Button
            variant="contained"
            onClick={onPayment}
          >
            Downgrade
          </Button>
        </Box>
      ) : isWalletBalance ? (
        <>
          <Box sx={classes.con}>
            <Typography variant="h6">ช่องทางการชำระเงิน</Typography>
            <Box sx={classes.isWalletBalanceBox}>
              <Typography variant="h6">My Wallet</Typography>
              <Box mt="16px">
                <Typography variant="h4">
                  {numberFormat.format(walletBalance)} THB
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={classes.payBtn}>
            <Button
              variant="contained"
              startIcon={<Icon className="ico-hu-money" />}
              onClick={onPayment}
            >
              ชำระเงิน {numberFormat.format(grandTotal)} บาท
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={classes.con}>
          <Typography variant="h6">เลือกช่องทางการชำระเงิน</Typography>
          <Box sx={classes.accordiun}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ height: "64px", p: "24px" }}
              >
                <Typography variant="title1">QR Payment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} p="0 15px 15px 15px">
                  {qrPayment?.data.map((li, index) => (
                    <Grid item key={index} xs={4} xl={3}>
                      <Box
                        onClick={() => onChillPayPublisher(li)}
                        sx={classes.accordionDetails}
                      >
                        <Avatar
                          src={li.image_logo_url?.[0]}
                          sx={classes.qrPaymentImg}
                        >
                          {li.title.charAt(0)}
                        </Avatar>
                        <Typography ml="10px" textTransform="capitalize">
                          {li.title.replace("_", " ")}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ height: "64px", p: "24px" }}
              >
                <Typography variant="title1">e-Wallet</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} p="0 15px 15px 15px">
                  {eWallet?.data.map((li, index) => (
                    <Grid item xs={4} xl={3} key={index}>
                      <Box
                        onClick={() => onChillPayPublisher(li)}
                        sx={classes.accordionDetails}
                      >
                        <Avatar
                          src={li.image_logo_url?.[0]}
                          sx={classes.methodImg}
                        >
                          {li.title.replace("epayment_", "").charAt(0)}
                        </Avatar>
                        <Typography ml="10px" textTransform="capitalize">
                          {li.title.replace("epayment_", "")}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ height: "64px", p: "24px" }}
              >
                <Typography variant="title1">Credit / Debit Card</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} p="0 15px 15px 15px">
                  {creditCard?.data.map((li, index) => (
                    <Grid item xs={4} xl={3} key={index}>
                      <Box
                        onClick={() => onChillPayPublisher(li)}
                        sx={classes.accordionDetails}
                      >
                        <Avatar
                          src={li.image_logo_url?.[0]}
                          sx={classes.craditImg}
                        >
                          {li.title.charAt(0)}
                        </Avatar>
                        <Typography ml="10px" textTransform="capitalize">
                          {li.title}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ height: "64px", p: "24px" }}
              >
                <Typography variant="title1">Mobile Banking</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} p="0 15px 15px 15px">
                  {mobileBanking?.data.map((li, index) => (
                    <Grid item xs={4} xl={3} key={index}>
                      <Box
                        onClick={() => onChillPayPublisher(li)}
                        sx={classes.accordionDetails}
                      >
                        <Avatar
                          src={li.image_logo_url?.[0]}
                          sx={classes.methodImg}
                        >
                          {li.title.charAt(0)}
                        </Avatar>
                        <Typography ml="10px" textTransform="capitalize">
                          {li.title.replace("_", " ")}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ height: "64px", p: "24px" }}
              >
                <Typography variant="title1">Internet Banking</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4} p="0 15px 15px 15px">
                  {internetBanking?.data.map((li, index) => (
                    <Grid item xs={4} xl={3} key={index}>
                      <Box
                        onClick={() => onChillPayPublisher(li)}
                        sx={classes.accordionDetails}
                      >
                        <Avatar
                          src={li.image_logo_url?.[0]}
                          sx={classes.methodImg}
                        >
                          {li.title.charAt(0)}
                        </Avatar>
                        <Typography ml="10px" textTransform="capitalize">
                          {li.title.replace("_", " ")}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      )}
    </>
  );
}
