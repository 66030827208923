import dayjs from "dayjs";

export function compareDate(inputDate:string) {
  const providedDate = new Date(inputDate);
  const currentDate = new Date();

  // ตั้งค่าเวลาของทั้งสองวันให้เป็น 00:00:00 เพื่อเปรียบเทียบเฉพาะวันที่
  providedDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // การเปรียบเทียบวันที่
  if (providedDate.getTime() === currentDate.getTime()) {
    return "วันนี้";
  } else if (providedDate.getTime() === new Date(currentDate.getTime() - 86400000).getTime()) {
    return "เมื่อวาน";
  } else if (providedDate.getMonth() === currentDate.getMonth() && providedDate.getFullYear() === currentDate.getFullYear()) {
    return "เดือนนี้";
  } else if ((providedDate.getMonth() === currentDate.getMonth() - 1 && providedDate.getFullYear() === currentDate.getFullYear()) || (providedDate.getMonth() === 11 && currentDate.getMonth() === 0 && providedDate.getFullYear() === currentDate.getFullYear() - 1)) {
    return "เดือนที่แล้ว";
  } else if (providedDate.getFullYear() === currentDate.getFullYear()) {
    return "ปีนี้";
  } else {
    return dayjs(inputDate).format("DD-MM-YYYY");
  }
}