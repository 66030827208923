import { useState } from "react";
// mui, icons and style
import {
  Box,
  Grid,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useMyJobDetailTabStyle as classes } from "./DetailTab.style";
// type
import { IInitCreateJob, IMyJobDetailTabProps } from "./DetailTab.type";
// component
import Timeline from "@global/campaign-detail/Timeline";
import DoOrDoNot from "@global/campaign-detail/DoOrDoNot";
import CampaignBrief from "@global/campaign-detail/CampaignBrief";
import TargetAudience from "@global/campaign-detail/TargetAudience";
import BudgetAndReward from "@global/campaign-detail/BudgetAndReward";
import CampaignOverview from "@global/campaign-detail/CampaignOverview";
import SocialMediaAccount from "@global/campaign-detail/SocialMediaAccount";
import TermsAndConditions from "@global/campaign-detail/TermsAndConditions";
// others
import { hBlack } from "@theme/index";

const initCreateJob = {
  campaign_id: "",
  social_account_id: 0,
  status: "listing",
  tier: "",
  price: 0,
  price_currency: "baht",
  term_and_condition: [],
};

export default function MyJobDetailTab(props: IMyJobDetailTabProps) {
  const { role, jobInfo } = props;

  const [createJob, setCreateJob] = useState<IInitCreateJob>(initCreateJob);

  const jobStatus = jobInfo?.status;
  const campaignInfo = jobInfo?.campaign_info;

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const handleCreateJob = (
    e: { target: { checked: boolean } },
    order: number
  ) => {
    const campaign = [...createJob.term_and_condition];
    const campaignIndex = campaign.map((item) => {
      if (item.order === order) {
        return {
          ...item,
          status: e.target.checked,
        };
      }
      return item;
    });
    setCreateJob({ ...createJob, term_and_condition: campaignIndex });
  };

  const myJobsDetailList = [
    {
      id: "campaignOverview",
      title: "Campaign Overview",
      subTutle: "ภาพรวมแคมเปญ",
      component: <CampaignOverview campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -350,
    },
    {
      id: "campaignBrief",
      title: "Campaign Brief",
      subTutle: "รายละเอียดแคมเปญ",
      component: <CampaignBrief campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -200,
    },
    {
      id: "doOrDoNot",
      title: "Do and Don't",
      subTutle: "สิ่งที่ต้องทำและสิ่งที่ห้ามทำ",
      component: <DoOrDoNot campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "targetAudience",
      title: "Target Audience",
      subTutle: "กลุ่มเป้าหมาย",
      component: <TargetAudience campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "budgetAndReward",
      title: "Budget and Reward",
      subTutle: "สิ่งที่อินฟลูเอนเซอร์จะได้รับ",
      component: <BudgetAndReward campaignInfo={campaignInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "socialMediaAccount",
      title: "Social Media Account",
      subTutle: "โปรดเลือกบัญชีที่ใช้ในการรับงาน",
      component: <SocialMediaAccount jobInfo={jobInfo} />,
      xs: 9,
      offset: -185,
    },
    {
      id: "termsAndConditions",
      title: "Terms and Conditions",
      subTutle: "โปรดอ่านรายละเอียดและตรวจสอบข้อมูลก่อนทำการสมัคร",
      component: (
        <TermsAndConditions
          campaignInfo={campaignInfo}
          role={role}
          handleCreateJob={handleCreateJob}
          campaignStatus={jobStatus}
        />
      ),
      xs: 9,
      offset: -185,
    },
  ];

  const myJobsDetailForSm = [
    {
      ...myJobsDetailList[4],
      md: 7,
      class: "budgetAndReward",
    },
    {
      id: "timeline",
      title: "Timeline",
      subTutle: "",
      component: <Timeline campaignInfo={campaignInfo} />,
      md: 5,
      class: "timeline",
    },
  ];

  myJobsDetailList.splice(4, screenSm ? 1 : 0);
  const campaignDetailListForSm = screenSm
    ? myJobsDetailForSm.reverse()
    : myJobsDetailForSm;

  return (
    <Box
      sx={{
        ...classes.jobDetailCon,
        borderRadius: {
          xs: "16px",
          sm:
            jobStatus === "listing" || jobStatus === "briefing"
              ? "16px"
              : "0 16px 16px 16px",
        },
        mt: jobStatus === "listing" ? "20px" : 0,
      }}
    >
      <Box sx={{ display: { xs: "none", lg: "inherit" } }}>
        <Typography variant="h5" color={hBlack[200]}>
          Campaign Details
        </Typography>
        <Typography variant="subtitle1" color={hBlack[300]}>
          แสดงรายละเอียดของแคมเปญ
        </Typography>
        <Divider sx={{ my: "24px" }} />
      </Box>

      <Grid container>
        <Grid item xs={12} md={12} lg={8} xl={8.5}>
          <Grid container spacing={4} sx={classes.jobDetailGridCon}>
            {/* display from xs to md screen */}
            {campaignDetailListForSm.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={item.md}
                sx={classes.jobDetailsForSmBox}
              >
                <Box
                  className={item.class}
                  sx={{
                    ...classes.budgetAndTimeline,
                    alignItems: {
                      sm: item.id !== "timeline" ? "start" : "center",
                      md: "initial",
                    },
                    display: item.id === "timeline" ? "flex" : "inintial",
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Box width="100%">
                      <Typography variant="h6">{item.title}</Typography>
                      <Typography variant="body2" color="#5C5C5C">
                        {item.subTutle}
                      </Typography>
                      {item.id === "budgetAndReward" && (
                        <Divider sx={{ width: "100%", my: "20px" }} />
                      )}
                    </Box>
                  </Box>
                  {item.component}
                </Box>
              </Grid>
            ))}
            {myJobsDetailList.map((item, index) => (
              <Grid
                item
                xs={12}
                key={index}
                display={
                  item.id === "socialMediaAccount" && role !== "influencer"
                    ? "none"
                    : "initial"
                }
              >
                <Box sx={classes.jobDetailCard}>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Typography variant="h6" color={hBlack[200]}>
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" color={hBlack[300]}>
                        {item.subTutle}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={classes.divider} />
                  {item.component}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3.5} lg={4} xl={3.5}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={3.5} lg={8} sx={classes.timelineTitleBox}>
              <Box mt="15px" sx={{ width: { xs: "75%", lg: "100%" } }}>
                <Box sx={classes.timelineTitle}>
                  <Typography variant="h6" mr={2}>
                    Timeline
                  </Typography>
                </Box>
                <Timeline campaignInfo={campaignInfo} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
