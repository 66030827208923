// mui and style
import { Avatar, Box, Typography } from "@mui/material";
import { useAddShippingStyle as classes } from "../InfluencerList.style";
// asset
import JT from "@assets/images/tarcking/rj&t.jpeg";
import DHL from "@assets/images/tarcking/rdhl.png";
import SCG from "@assets/images/tarcking/rscg.jpeg";
import Nim from "@assets/images/tarcking/rnim.jpeg";
import Grab from "@assets/images/tarcking/grab.png";
import Flash from "@assets/images/tarcking/rflash.jpeg";
import Kerry from "@assets/images/tarcking/kerry.jpeg";
import SpeedD from "@assets/images/tarcking/speed-d.jpeg";
import Ninja from "@assets/images/tarcking/ninjavan.jpeg";
import Lineman from "@assets/images/tarcking/lineman.png";
import Lalamove from "@assets/images/tarcking/lalamove.png";
import ThilandPost from "@assets/images/tarcking/thai-post.jpeg";

interface IShippingCompanyProps {
  items: string | undefined;
}

const shippingCompanies: Record<string, string> = {
  'ThailandPost': ThilandPost,
  'Kerry Express': Kerry,
  'SCG express': SCG,
  'Flash Express': Flash,
  'J&T express': JT,
  'Speed-D': SpeedD,
  'Nim Express': Nim,
  'Ninja Van': Ninja,
  'DHL': DHL,
  'Grab': Grab,
  'Line Man': Lineman,
  'Lala Move': Lalamove,
};
export default function ShippingCompanyItems(props: IShippingCompanyProps) {
  const { items } = props;

  const avatarSrc = shippingCompanies[items || ""];

  return (
    <Box display="flex" alignItems="center">
      {avatarSrc && <Avatar src={avatarSrc} sx={classes.shippingCompany} />}
      <Typography variant="body2">{items}</Typography>
    </Box>
  );
}