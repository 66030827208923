import { useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Divider,
  Typography,
  DialogActions,
  DialogContent,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
// style
import { SaveButton } from "@views/influencer-profile/MyProfile.style";
import { useEditInformationStyle as classes } from "../employer-detail/Detail.style";
// component
import SelectMultiCategory from "@components/campaign/create-campaign/campaign-brief/brief-default/SelectMultiCategory";
// api and type
import { AppInformationAPI, EmployerAPI } from "@api/global";
import { IEditEmployerProps } from "../employer-detail/Detail.type";
import { IEditEmployerForm, IEditImageUrl } from "./EditEmployer.type";
import { IEditEmployerFormSchema } from "../EmployerList.type";
import { ICategory } from "@api/app-information/type";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditEmployerFormSchema } from "./EditEmployer.schema";
// Data
import {
  initImage,
  optionType,
  optionBusinessSize,
  initEditEmployerForm,
} from "../create-employer/EmployerData";
// redux
import { useAppSelector } from "@store/hook";
import { selectCategoryList } from "@slices/CategoryList.slice";
// other
import Loading from "@global/loading/Loading";
import TextFieldCustom from "@global/text-field";
import BasicSelectCustom from "@global/basic-select";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";
import { formatPhoneNumber } from "@utils/helpers/formateNumber.helpers";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

export default function EditEmployer(props: IEditEmployerProps) {
  const { employerInfo, setEmployerInfo, open, onClose } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isUploadImg, setIsUploadImg] = useState(false);
  const [imageOrgLogo, setImageOrgLogo] = useState<IEditImageUrl>(initImage);
  const [optionCategory, setOptionCategory] = useState<ICategory[]>([]);

  const {
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditEmployerForm>({
    resolver: yupResolver(EditEmployerFormSchema),
    defaultValues: initEditEmployerForm,
  });

  const categoryList = useAppSelector(selectCategoryList);

  useEffect(() => {
    const items = categoryList.map((item) => {
      return {
        id: item.id,
        label: item.name_th,
        value: item.value,
        name_en: item.name_en,
        name_th: item.name_th,
      };
    });
    setOptionCategory(items);
  }, [categoryList]);

  useEffect(() => {
    if (!employerInfo) return;

    const newItems = employerInfo.organisation.category.map(
      (item: any) => item.id
    );

    const form = {
      name: employerInfo.organisation.name,
      size: employerInfo.organisation.size,
      type: employerInfo.organisation.type,
      category: newItems,
      attachment: employerInfo.organisation.attachment,
      email: employerInfo.email,
      fullname: employerInfo.fullname,
      phone: employerInfo.phone,
      line_id: employerInfo.line_id,
    };
    reset(form);

    if (employerInfo.organisation.attachment === null) {
      setImageOrgLogo(initImage);
    } else {
      setImageOrgLogo(employerInfo.organisation.attachment);
    }
  }, [employerInfo, reset, setImageOrgLogo]);

  const handleChangeInput = (
    e: any,
    nameChange: keyof IEditEmployerFormSchema
  ) => {
    setValue(nameChange, e.target.value);
    clearErrors(nameChange);
  };

  const handleUploadImage = async (e: any) => {
    setIsUploadImg(true);
    let data = {
      file: e.target.files[0],
      destination: "organisation",
      permission: "public",
    };
    const result = await AppInformationAPI.uploadAttachment(data);
    if (result) {
      const imageData = {
        file_name: result.file_name as string,
        file_type: result.file_type as string,
        url: result.public_url || result.private_url,
      };
      setImageOrgLogo({
        ...imageOrgLogo,
        file_id: imageOrgLogo.file_id === "" ? "" : imageOrgLogo.file_id,
        ...imageData,
      });
    }
    setIsUploadImg(false);
  };

  const onEditEmployer = (data: IEditEmployerForm) => {
    onClose(false);
    setIsLoading(true);

    const body = {
      profile: {
        fullname: data.fullname,
        phone: data.phone.replaceAll("-", ""),
        line_id: data.line_id,
      },
      organisation: {
        id: employerInfo?.organisation.id || 0,
        name: data.name,
        size: data.size,
        type: data.type,
        attachment: imageOrgLogo,
        category: data.category,
      },
    };

    EmployerAPI.updateEmployerInfo(employerInfo?.employer_full_id || "", body)
      .then((response) => {
        setEmployerInfo(response);
        setIsLoading(false);
        SwalCustom.fire({
          icon: "success",
          title: "Success !",
          showConfirmButton: false,
          timer: 1000,
        });
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "อุ๊ปป !!",
          text: "มีบางอย่างผิดพลาด",
          confirmButtonColor: "#0277bd",
          confirmButtonText: "ตกลง",
          customClass: {
            confirmButton: "prompt-font",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading />;

  return (
    <DialogScrollingCustom
      open={open}
      onClose={() => onClose(false)}
      title="Edit Employer"
      sx={classes.dialog}
    >
      <form onSubmit={handleSubmit(onEditEmployer)}>
        <DialogContent sx={classes.dialogContent}>
          <Box maxHeight="75vh">
            <Box display="flex">
              <Icon className="ico-hu-s-acc-business" sx={{ mr: "8px" }} />
              <Typography variant="body1" fontWeight="600">
                Personal Information
              </Typography>
            </Box>
            <Grid container px={5} pt={4} rowGap="10px">
              <Grid item xs={12} md={4}>
                <Typography variant="title2">Organization Logo :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                {imageOrgLogo.url === "" ? (
                  <Box
                    sx={classes.profileBox}
                    component="label"
                    onChange={handleUploadImage}
                  >
                    <Box sx={classes.uploadImgBox}>
                      {isUploadImg ? (
                        <CircularProgress size={70} thickness={3} />
                      ) : (
                        <>
                          <Icon
                            className="ico-hu-p-photo"
                            sx={{ fontSize: "70px", color: "#616161" }}
                          />
                          <Box display="flex" alignItems="center">
                            <Icon
                              className="ico-hu-upload"
                              sx={{ fontSize: "10px" }}
                            />
                            <Typography variant="caption" ml={1}>
                              Images
                            </Typography>
                          </Box>
                        </>
                      )}
                      <input
                        hidden
                        name="idCardImageUrl"
                        accept="image/*"
                        multiple
                        type="file"
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box position="relative" width="150px">
                    <Box
                      sx={classes.profileBox}
                      component="label"
                      onChange={handleUploadImage}
                    >
                      <Box position="relative">
                        <img
                          src={imageOrgLogo.url}
                          alt="organization-logo"
                          style={classes.orgLogo}
                        />
                        {isUploadImg && (
                          <Box sx={classes.circularBox}>
                            <CircularProgress size={70} thickness={3} />
                          </Box>
                        )}
                      </Box>
                      <input
                        hidden
                        name="idCardImageUrl"
                        accept="image/*"
                        multiple
                        type="file"
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Organization Name :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFieldCustom
                  value={getValues("name")}
                  onChange={(e) => handleChangeInput(e, "name")}
                  type="text"
                  placeholder="Organization Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Full Name :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFieldCustom
                  value={getValues("fullname")}
                  onChange={(e) => handleChangeInput(e, "fullname")}
                  type="text"
                  placeholder="Full Name"
                  fullWidth
                  error={!!errors.fullname}
                  helperText={errors.fullname?.message}
                />
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Size :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <BasicSelectCustom
                  id="size-select"
                  value={getValues("size")}
                  onChange={(e) => handleChangeInput(e, "size")}
                  data={optionBusinessSize}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Select"
                  fullWidth
                  error={!!errors.size}
                  helperText={errors.size?.message}
                />
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Type :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <BasicSelectCustom
                  id="type-select"
                  value={getValues("type")}
                  onChange={(e) => handleChangeInput(e, "type")}
                  data={optionType}
                  valueKey="value"
                  labelKey="label"
                  placeholder="Select Type"
                  fullWidth
                  error={!!errors.type}
                  helperText={errors.type?.message}
                />
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="title2">Categories :</Typography>
                  <Typography variant="caption" color="#5C5C5C">
                    (เลือกได้สูงสุด 3 ประเภท)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <SelectMultiCategory
                  id="multi-select-category"
                  keyName={"category" as any}
                  value={watch("category")}
                  setValue={setValue as any}
                  placeholder="Select"
                  data={optionCategory}
                  fullWidth
                  valueKey="id"
                  labelKey="name_en"
                  error={!!errors.category}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Box display="flex">
              <Icon className="ico-hu-people-top-card" sx={{ mr: "8px" }} />
              <Typography variant="body1" fontWeight="600">
                Contact
              </Typography>
            </Box>
            <Grid container rowGap="10px" px={5} pt={4}>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Email :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFieldCustom
                  value={getValues("email")}
                  type="text"
                  placeholder="Email"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className="ico-hu-email-solid"
                          sx={classes.iconInput}
                        />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  disabled
                />
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Phone :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFieldCustom
                  value={formatPhoneNumber(getValues("phone"))}
                  onChange={(e) => handleChangeInput(e, "phone")}
                  type="text"
                  placeholder="Phone"
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              </Grid>
              <Grid item display="flex" alignItems="center" xs={12} md={4}>
                <Typography variant="title2">Line ID :</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFieldCustom
                  value={getValues("line_id")}
                  onChange={(e) => handleChangeInput(e, "line_id")}
                  type="text"
                  placeholder="Line ID"
                  fullWidth
                  error={!!errors.line_id}
                  helperText={errors.line_id?.message}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions sx={classes.dialogAction}>
          <SaveButton
            variant="contained"
            type="submit"
            startIcon={<Icon className="ico-hu-save" />}
          >
            <Typography variant="button">Save</Typography>
          </SaveButton>
        </DialogActions>
      </form>
    </DialogScrollingCustom>
  );
}
