export const useCampaingTabStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
    borderTop: "1px solid #8C499C",
  },
  noContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  textNoContent: {
    maxWidth: "400px",
    textAlign: "center",
    mt: "15px",
  },
  pagination: {
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
};

export const useCampaignTabFilterStyle = {
  boxFilter: {
    height: "32px",
    backgroundColor: "#EBDFFF",
    color: "#893DBD",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
    marginLeft: "16px",
    textTransform: "capitalize",
    px:"10px"
  },
  icon: {
    fontSize: "16px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  clearBtn: {
    ml: "16px",
    color: "#893DBD",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "500",
  },
};
