// mui
import {
  Grid,
  Paper,
  Stack,
  Avatar,
  Typography,
  DialogActions,
} from "@mui/material";
// component and type
import DialogAuthentication from "@global/dialog-authentication";
import { ISelectAccountDialogProps } from "./AuthenDialog.types";
// image and style
import MarketerImage from "@assets/images/people/marketer.png";
import InfluencerImage from "@assets/images/people/influencer.png";
import { useSelectAccountDialogStyle as classes } from "./authentication.style";

export default function SelectAccountDialog(props: ISelectAccountDialogProps) {
  const { open, setOpen, handleCreateAccount } = props;

  const handleCloseDialog = () => {
    setOpen(false)
  }

  return (
    <DialogAuthentication
      open={open}
      onClose={handleCloseDialog}
      sx={classes.selectAccountDialog}
    >
      <DialogActions sx={classes.selectAccountDialogAction}>
        <Grid container spacing={8} sx={classes.gridContainer}>
          <Grid item xs={12} sm={6}>
            <Paper
              onClick={() => handleCreateAccount("influencer")}
              sx={classes.influencerPaper}
            >
              <Stack alignItems="center" py={10} px={5}>
                <Avatar
                  alt="Influencer"
                  src={InfluencerImage}
                  sx={classes.imageAvatar}
                />
                <Typography
                  variant="h6"
                  color="secondary"
                  align="center"
                  mt={4}
                  mb={2}
                >
                  Influencer
                </Typography>
                <Typography variant="body1" color="grey.700" align="center">
                  ร่วมสร้างสังคมใหม่ เปิดโลก Influencer ไปกับ HashU
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              onClick={() => handleCreateAccount("marketer")}
              sx={classes.marketerPaper}
            >
              <Stack alignItems="center" py={10} px={5}>
                <Avatar
                  alt="Influencer"
                  src={MarketerImage}
                  sx={classes.imageAvatar}
                />
                <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                  mt={4}
                  mb={2}
                >
                  Marketer
                </Typography>
                <Typography variant="body1" color="grey.700" align="center">
                  เลือกนักรีวิวที่ใช่ พร้อมจัดการ แคมเปญของคุณในที่เดียว
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogAuthentication>
  );
}
