// mui and icon
import { Box } from "@mui/material";
import SyncYoutube from "./SyncYoutube";
// component and context
import InfluencerSocialHeader from "../default/Header";
import InfluencerSocialPending from "../default/Pending";
import InfluencerSocialInfo from "../default/Information";
// mui
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";

export default function InfluencerYoutubeTab() {
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const youtubeInfo = influencerSocial?.youtube;

  return (
    <div>
      {!youtubeInfo || !youtubeInfo.length ? (
        <SyncYoutube />
      ) : youtubeInfo[0].social_account.length === 0 ? (
        <InfluencerSocialPending platform="youtube" />
      ) : (
        youtubeInfo && (
          <Box className="d-flex f-d-column" rowGap="20px">
            <InfluencerSocialHeader platform="youtube" />
            <InfluencerSocialInfo
              platform="youtube"
              email={youtubeInfo[0].email}
            />
          </Box>
        )
      )}
    </div>
  );
}
