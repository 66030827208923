import { useEffect, useState } from "react";
// mui and style
import { Box, Icon, Avatar, IconButton, Typography } from "@mui/material";
import { useEditRateCardForMobileStyle as classes } from "../../MyProfile.style";
// redux
import {
  selectInfluencerSocial,
  getInfluencerSocialAsync,
  selectInfluencerSocialOnload,
} from "@slices/InfluencerSocial.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectContentTypelist } from "@slices/ContentTypeList.slice";
// type and component
import { IInitUpdateRateCardForm } from "../../MyProfile.type";
import EditInfluencerRateCard from "@components/influencer-profile/edit-influencer-profile/EditRateCard";
// other
import { useForm } from "react-hook-form";
import Loading from "@global/loading/Loading";
import { useHistory, useLocation } from "react-router-dom";

export default function EditRateCardForMobile() {
  const [isLoading, setIsLoading] = useState(false);

  const { goBack } = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get("platform") || "facebook";

  const dispatch = useAppDispatch();
  const contentTypeList = useAppSelector(selectContentTypelist);
  const influencerSocial = useAppSelector(selectInfluencerSocial);
  const socialLoading = useAppSelector(selectInfluencerSocialOnload);

  useEffect(() => {
    dispatch(getInfluencerSocialAsync({}));
  }, [dispatch]);

  const platform = influencerSocial?.[tab] || [];
  const email = platform[0].email;
  const social = platform[0].social_account[0];

  const platformContenType = contentTypeList
    ?.filter((item) => item.platform === tab)
    ?.map((item) => {
      return {
        id: "",
        content_type_id: item.id,
        value: "0",
        content_type: item.name,
      };
    });

  const initUpdateRateCardForm = social.rate_card.map((item) => {
    return {
      id: item.id,
      content_type_id: item.content_type_id,
      value: item.price.value.toString(),
      content_type: item.content_type,
    };
  });

  const form = useForm<IInitUpdateRateCardForm>({
    defaultValues: {
      social_account_id: social.social_account_id,
      platform: tab || "",
      rate_card: [],
    },
  });

  const { reset } = form;

  useEffect(() => {
    const form = {
      social_account_id: social.social_account_id,
      platform: tab || "",
    };
    if (initUpdateRateCardForm.length) {
      reset({ ...form, rate_card: initUpdateRateCardForm });
    } else if (platformContenType) {
      reset({ ...form, rate_card: platformContenType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || socialLoading) return <Loading />

  return (
    <div className="container-main">
      <Box display="flex" alignItems="center">
        <IconButton onClick={goBack}>
          <Icon className="ico-hu-chevron-left" />
        </IconButton>
        <Typography variant="h6" textAlign="center" width="100%" ml={-3}>
          Rate Card
        </Typography>
      </Box>

      <Box sx={classes.content}>
        <Avatar
          src={social.profile_image}
          sx={{ width: "48px", height: "48px", borderRadius: "5px" }}
        >
          {social.name.charAt(0)}
        </Avatar>
        <Box ml="16px">
          <Typography variant="title1">{social.name}</Typography>
          <Typography variant="body2">{email}</Typography>
        </Box>
      </Box>

      <EditInfluencerRateCard form={form} setIsLoading={setIsLoading} />
    </div>
  );
}
