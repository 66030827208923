import React, { useRef, useState } from "react";
// mui and icon
import {
  Box,
  Zoom,
  Icon,
  Dialog,
  useTheme,
  Typography,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
// style and swiper
import { useViewMultiImageDialogStyle as classes } from "./ViewMultiImageDialog.style";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

interface IViewMuitiImageDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  images?:
    | {
        url: string;
      }[]
    | null;
  initImgIndex?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

SwiperCore.use([Navigation]);

export default function ViewMultiImageDialog(
  props: IViewMuitiImageDialogProps
) {
  const { open, onClose, images, initImgIndex } = props;
  let initIndex = initImgIndex ? initImgIndex : 0;

  const [imageIndex, setImageIndex] = useState(initIndex);
  const image = images ? images : [];

  const swiperRef = useRef<any>(null);
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const handlePrevClick = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleSlideChange = (imgIndex: number) => {
    setImageIndex(imgIndex);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={Transition}
      sx={classes.dialog}
    >
      {/* close dialog button */}
      <Icon
        className={xsScreen ? "ico-hu-close-circle" : "ico-hu-close"}
        sx={classes.closeDialogBtn}
        onClick={handleClose}
      />

      {/* prev and next button */}
      <Box sx={classes.prevAndNextBox}>
        {/* prev button */}
        <div>
          <Icon
            className="ico-hu-chevron-left-circle"
            sx={{
              ...classes.prevBtn,
              display: imageIndex === 0 ? "none" : "block",
            }}
            onClick={
              xsScreen ? handlePrevClick : () => setImageIndex(imageIndex - 1)
            }
          />
        </div>

        {/* next button */}
        <div>
          <Icon
            className="ico-hu-chevron-right-circle"
            sx={{
              ...classes.nextBtn,
              display: imageIndex === image.length - 1 ? "none" : "block",
            }}
            onClick={
              xsScreen ? handleNextClick : () => setImageIndex(imageIndex + 1)
            }
          />
        </div>
      </Box>

      {/* slide images for mobile */}
      <Box sx={classes.slideImageBoxForMobile}>
        <Swiper
          ref={swiperRef}
          className="mySwiper"
          onSlideChange={(swiper) => handleSlideChange(swiper.activeIndex)}
          {...{ initialSlide: imageIndex }}
        >
          {image.map((item, index) => (
            <SwiperSlide key={index} className="swiperSlide">
              <img src={item.url} alt="item" />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* slide images for desktop */}
      <DialogContent sx={classes.slideImageBoxForDesktop}>
        {image.length ? (
          <img
            style={{
              objectFit: "cover",
              maxWidth: "600px",
              maxHeight: "500px",
              marginBottom: "-6px",
            }}
            src={image[imageIndex].url}
            alt="item"
          />
        ) : (
          ""
        )}
      </DialogContent>

      {/* total */}
      <Box sx={classes.total}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "18px",
          }}
        >
          {`${imageIndex + 1} จาก ${image.length}`}
        </Typography>
      </Box>
    </Dialog>
  );
}
