// mui and style
import { Box, Icon, IconButton, Typography } from "@mui/material";
import { useBillingStyle as classes } from "./SubscriptionPayment.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";

export default function Billing(props: {
  openEditBilling: (value: boolean) => void;
}) {
  const { openEditBilling } = props;

  const employerInfo = useAppSelector(selectEmployerInfo);
  const isTeam = employerInfo?.package_plan === "team";
  const billing = isTeam
    ? employerInfo.team?.billing_address
    : employerInfo?.address.billing_address;

  return (
    <Box sx={classes.con}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">ข้อมูลใบเสร็จ</Typography>
        <IconButton onClick={() => openEditBilling(true)}>
          <Icon
            className="ico-hu-edit"
            color="primary"
            sx={{ cursor: "pointer" }}
          />
        </IconButton>
      </Box>
      <Box mt="16px">
        <Typography variant="title1">
          {employerInfo?.organisation.name}
        </Typography>
        <Typography my="6px">{billing?.tax_id}</Typography>
        <Typography>
          {`${billing?.address} ${billing?.subdistrict?.name_th || ""} ${
            billing?.district?.name_th || ""
          } ${billing?.province?.name_th || ""} ${billing?.postcode}`}
        </Typography>
      </Box>
    </Box>
  );
}
