// mui
import { Box } from "@mui/material";
// component and context
import SyncTiktok from "./SyncTiktok";
import InfluencerSocialHeader from "../default/Header";
import InfluencerSocialPending from "../default/Pending";
import InfluencerSocialInfo from "../default/Information";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";

export default function InfluencerTiktokTab() {
  const influencerSocial = useAppSelector(selectInfluencerSocial)
  const tiktokInfo = influencerSocial?.tiktok;

  return (
    <Box>
      {!tiktokInfo ||
      !tiktokInfo.length ||
      tiktokInfo[0].platform_uid === "" ? (
        <SyncTiktok />
      ) : tiktokInfo[0].social_account.length === 0 ? (
        <InfluencerSocialPending platform="tiktok" />
      ) : (
        tiktokInfo && (
          <Box display="flex" flexDirection="column" rowGap={["16px", "20px"]}>
            <InfluencerSocialHeader platform="tiktok" />
            <InfluencerSocialInfo
              platform="tiktok"
              email={tiktokInfo[0].email}
            />
          </Box>
        )
      )}
    </Box>
  );
}
