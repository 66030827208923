// mui and icons
import { Box, Divider, Grid, Icon, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// chartJs
import { typeOfInfluStatsOptionsChart } from "../../OverviewTab.chart";
import { Pie } from "react-chartjs-2";
// type and others
import { colorMatchTier } from "./InfluencerTiers";
import { ITypeOfInfluencerStatsProps } from "../../OverviewTab.type";
import { SocialIconSelected } from "@global/icon-selected/SocialIconSelected";

export const colorMatchPlatform = (platform: string) => {
  switch (platform) {
    case "facebook":
      return "#1A77F1";
    case "tiktok":
      return "#000000";
    case "youtube":
      return "#FF0000";
    case "twitter":
      return "#1D9BF0";
    case "total":
      return "#000000";
    default:
      return "#893DBD";
  }
};

export default function TypeOfInfluencerStats(
  props: ITypeOfInfluencerStatsProps
) {
  const { socialDist } = props;
  const tierChart = socialDist?.tiers_chart || [];
  const reversedTierChart = [...tierChart].reverse();
  const plaformChart = (socialDist?.platforms_chart || []);
  const data = socialDist?.data || [];

  const numberFormat = Intl.NumberFormat("en");

  const platformMatchIcon = (platform: string) => {
    switch (platform) {
      case "overall":
        return <Icon color="primary" className="ico-hu-group" />;
      default:
        return <SocialIconSelected platform={platform} />;
    }
  };

  const influencerTiersData = {
    labels: reversedTierChart.map((tier) => tier.label).reverse(),
    datasets: [
      {
        label: "# of Votes",
        data: reversedTierChart.map((tier) => tier.value).reverse(),
        backgroundColor: reversedTierChart
          .map((tier) => colorMatchTier(tier.label))
          .reverse(),
        borderWidth: 1,
        borderColor: "#fff",
      },
    ],
  };

  const socialMediaPlatformsData = {
    labels: plaformChart.map((tier) => tier.label).reverse(),
    datasets: [
      {
        label: "# of Votes",
        data: plaformChart.map((tier) => tier.value).reverse(),
        backgroundColor: plaformChart
          .map((tier) => colorMatchPlatform(tier.label))
          .reverse(),
        borderWidth: 1,
        borderColor: "#fff",
      },
    ],
  };

  const displayTotal = (total: number, platform: string) => {
    if (total === 0) {
      return (
        <Typography variant="h5" color="red">
          N/A
        </Typography>
      );
    } else {
      return (
        <Typography variant="h5" color={colorMatchPlatform(platform)}>
          {numberFormat.format(total)}
        </Typography>
      );
    }
  };

  return (
    <Box>
      <Box display="flex">
        <Box width="100%">
          <Typography variant="title1">Influencer Tiers</Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box width="200px" height="200px" my="24px">
              <Pie
                options={typeOfInfluStatsOptionsChart}
                data={influencerTiersData}
              />
            </Box>
            <Stack direction="row" spacing={4}>
              {reversedTierChart.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                >
                  <CircleIcon
                    sx={{ color: colorMatchTier(item.label), fontSize: "12px" }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
        <Box width="100%">
          <Typography variant="title1">Social Media Platforms</Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box width="200px" height="200px" my="24px">
              <Pie
                options={typeOfInfluStatsOptionsChart}
                data={socialMediaPlatformsData}
              />
            </Box>
            <Stack direction="row" spacing={4}>
              {plaformChart.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1.5}
                >
                  <CircleIcon
                    sx={{
                      color: colorMatchPlatform(item.label),
                      fontSize: "12px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Grid container spacing="12px" mt="18px">
        {data.map((item, index) => (
          <Grid item key={index} xs={2}>
            <Box
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                p: "20px",
              }}
            >
              <Box display="flex" alignItems="center">
                {platformMatchIcon(item.platform)}
                <Typography
                  variant="title1"
                  ml="13px"
                  sx={{ textTransform: "capitalize" }}
                >
                  {item.platform}
                </Typography>
              </Box>
              <Divider sx={{ my: "16px" }} />
              <Stack spacing={5}>
                {[
                  {
                    label: "Total Users",
                    subLabel: item.total_user,
                  },
                  {
                    label: "Sync Social Media Accounts",
                    subLabel: item.social_account_count,
                  },
                  {
                    label: "Avg. Acc/User",
                    subLabel: item.avg_account_per_user,
                  },
                  {
                    label: "Total Followers",
                    subLabel: item.total_follower,
                  },
                ].map((subItem, index) => (
                  <Box key={index}>
                    <Typography>{subItem.label}</Typography>
                    {displayTotal(subItem.subLabel, item.platform)}
                  </Box>
                ))}
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
