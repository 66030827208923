import React, { useState } from "react";
// mui and icons
import {
  Box,
  Dialog,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
  DialogContent,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// style and type
import { useMyJobstabsItemSmStyle as classes } from "../../my-jobs/MyJobs.style";
import { IJobBoardTabsItemSmProps } from "../JobBoard.type";
import { useLocation } from "react-router-dom";

export default function JobBoardTabsItemSm(props: IJobBoardTabsItemSmProps) {
  const { onHandleFilterTabStatus, setHandleOpenFilter, statusTotal, tabs } = props;

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("c_type") || "";

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={classes.container}>
      <Box onClick={() => setOpen(true)} sx={classes.selectJobTabStatus}>
        <Typography variant="body1">
          {`${
            statusTotal.status === "public"
              ? "ประกาศรับสมัคร"
              : statusTotal.status === "private"
              ? "ได้รับข้อเสนอ"
              : "ทั้งหมด"
          } ${statusTotal.total || ""}`}
        </Typography>
        <ArrowDropDownIcon />
      </Box>
      <Divider sx={classes.divider} orientation="vertical" flexItem />
      <Box
        sx={classes.selectJobTabTitle}
        onClick={() => setHandleOpenFilter(true)}
      >
        <MenuIcon sx={{ fontSize: "16px" }} />
        <Typography ml={2}>Filters</Typography>
      </Box>
      <Dialog
        open={open}
        onClick={() => setOpen(false)}
        fullWidth={fullScreen}
        sx={classes.dialogCon}
      >
        <DialogContent>
          {tabs.map((item, index) => (
            <React.Fragment key={index}>
              <Box
                onClick={() => {
                  onHandleFilterTabStatus(item.campaignType);
                }}
                sx={{
                  ...classes.dialogMenuCanceled,
                  bgcolor: item.campaignType === type ? item.bgColor : "#fff",
                  color: item.campaignType === type ? item.color : undefined,
                }}
              >
                <Typography>
                  {`${item.label} ${item.total === 0 ? "" : `(${item.total})`}`}
                </Typography>
              </Box>
            </React.Fragment>
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
