export const useLoadingStyle = {
  backDrop: {
    bgcolor: "rgba(80, 50, 90, 0.7)",
    zIndex: 9999,
  },
  logoLoading: {
    bgcolor: "rgba(180, 150, 190, 1)",
    borderRadius: "50%",
    width: ["70px", "150px"],
    height: ["70px", "150px"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: "-5px",
    "& > img": {
      width: "50px !important",
      height: "50px !important",
    },
  },
  loadingTop: {
    position: "absolute",
    left: 0,
    color: "rgba(190, 160, 200, 0.9)",
  },
};
