// mui and style
import { Avatar, Badge, Box, Button, Icon, Typography } from "@mui/material";
import { useAddShippingStyle as classes } from "../InfluencerList.style";
// type and util
import { IItemsRes } from "@api/job/type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// other
import GetPlatform from "@global/get-platform";
import ShippingCompanyItems from "./ShippingCompanyItems";
import { useHistory, useLocation, useParams } from "react-router-dom";

export default function CardInfluencer({ item }: { item: IItemsRes }) {
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const numberFormat = Intl.NumberFormat("en");

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const handleAddShipping = (jobId: string, item: IItemsRes) => {
    if (role === "employer") {
      history.push(
        `/campaigns/${campaignId}/add-shipping?job_id=${jobId}`,
        item
      );
    } else {
      history.push(
        `/campaigns/${campaignId}/add-shipping?job_id=${jobId}&emp_id=${employerId}`,
        item
      );
    }
  };

  const goViewInfluencer = (influId: string) => {
    history.push(
      `/influencers/${influId}/profile?platform=${item.platform}&status=view`
    );
  };

  return (
    <Box sx={classes.cardItems}>
      <Box display="flex">
        <Box
          onClick={() => goViewInfluencer(item?.influencer_id)}
          sx={{ cursor: "pointer", mr: "20px" }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={<GetPlatform platform={item.platform} />}
            sx={classes.badge}
          >
            <Avatar
              variant="square"
              sx={classes.avatar}
              src={item.social_account_images[0]}
            />
          </Badge>
        </Box>
        <Box width={["100%", "100%", "100%", "70%", "100%"]}>
          <Typography variant="h6" sx={classes.textTitleEllipsis}>
            {item.name}
          </Typography>
          <Typography variant="subtitle2">
            {numberFormat.format(item.followers)} Followers
          </Typography>
        </Box>
      </Box>

      {item.shipping_product !== null ? (
        <>
          <Box sx={classes.boxDetailAddShipping}>
            <Icon className="ico-hu-shipping" sx={classes.icon} />
            <Typography variant="title2">Shipping Product</Typography>
          </Box>

          <Box display="flex" flexDirection="column" rowGap="10px">
            <Box sx={classes.boxDetailAddShippingData}>
              <Typography variant="body2">Company</Typography>
              <Box display="flex" justifyContent="flex-end" width="50%">
                {item.shipping_product?.shipping_type === "" ? (
                  "-"
                ) : (
                  <ShippingCompanyItems
                    items={item.shipping_product?.shipping_type}
                  />
                )}
              </Box>
            </Box>
            <Box sx={classes.boxDetailAddShippingData}>
              <Typography variant="body2">Traking No</Typography>
              <Typography variant="body2">
                {item.shipping_product?.tracking_no || "-"}
              </Typography>
            </Box>
          </Box>
        </>
      ) : null}

      {item?.conversion !== null ? (
        <>
          <Box sx={classes.boxDetailAddShipping}>
            <Icon
              className="ico-hu-conversion-rate-outline"
              sx={classes.icon}
            />
            <Typography variant="title2">Conversion Traking</Typography>
          </Box>
          <Box mt="6px" sx={classes.boxDetailAddShippingData}>
            <Typography variant="body2">Link URL</Typography>
            <Box display="flex" justifyContent="flex-end" width="50%">
              <Typography variant="body2" sx={classes.textTitleEllipsis}>
                {item.conversion.lead_link || "-"}
              </Typography>
            </Box>
          </Box>
          <Box sx={classes.boxDetailAddShippingData}>
            <Typography variant="body2">Coupong Code</Typography>
            <Typography variant="body2">
              {item.conversion.coupon || "-"}
            </Typography>
          </Box>
        </>
      ) : null}

      <Box mt="25px">
        <Button
          sx={classes.buttonAddShipping}
          onClick={() => {
            handleAddShipping(item.job_id, item);
          }}
        >
          {item?.conversion === null
            ? "Add Tracking No"
            : "Add Tracking No. & Link URL"}
        </Button>
      </Box>
    </Box>
  );
}
