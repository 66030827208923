import { useState } from "react";
// component
import InfluencerSelected from "./InfluencerSelected";
import BeforSelected from "../estimate-summary/BeforSelected";
import SelectInfluencerDialog from "./SelectInfluencerDialog";
import SelectInfluencer from "../estimate-summary/SelectInfluencer";
// api and type
import { JobAPI } from "@api/global";
import { IStatusCandidateProps } from "../InfluencerList.type";
// redux
import { useAppDispatch } from "@store/hook";
import { setInfluencerActiveInfo } from "@slices/InfluencerActiveList.slice";
// other
import Loading from "@global/loading/Loading";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { initialParams } from "./influencer-list-filter/InfluencerListFilterData";

export default function StatusCandidate(props: IStatusCandidateProps) {
  const {
    onClick,
    estimateSummary,
    clearInfluencer,
    candidateInfluencer,
    setCandidateInfluencer,
    openInfluencerSelected,
    toggleInfluSelectedDrawers,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectInfluDialog, setSelectInfluDialog] = useState(false);

  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const handleOpenSelectInfluDialog = () => {
    setSelectInfluDialog(!selectInfluDialog);
  };

  const selectInfluencerActive = async () => {
    setSelectInfluDialog(!selectInfluDialog);
    setIsLoading(true);
    
    JobAPI.influencerListActive(employerId, campaignId, initialParams)
      .then((response) => {
        setCandidateInfluencer(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const selectedInfluencerWithAPI = async () => {
    const influencerSelected = candidateInfluencer?.items.filter((item) => {
      return item.is_selecting;
    });

    setIsLoading(true);
    const reqBody = influencerSelected?.map((item) => {
      return {
        job_id: item.job_id,
      };
    });

    JobAPI.selectedInfluencer(employerId, campaignId, initialParams, reqBody)
      .then((response) => {
        dispatch(setInfluencerActiveInfo(response));
        setIsLoading(false);
        if (role === "employer") {
          history.push(`/campaigns/${campaignId}/charge`, {
            influencerSelected,
          });
        } else {
          history.push(
            `/campaigns/${campaignId}/charge?emp_id=${employerId}`,
            { influencerSelected }
          );
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading />

  return (
    <>
      {candidateInfluencer?.summary.is_selecting ? (
        <>
          <SelectInfluencer
            toggleInfluSelectedDrawers={toggleInfluSelectedDrawers}
            newEstimateSummary={estimateSummary}
            candidateInfluencer={candidateInfluencer}
          />
        </>
      ) : (
        <BeforSelected
          handleOpenSelectInfluDialog={handleOpenSelectInfluDialog}
          candidateInfluencer={candidateInfluencer}
        />
      )}

      <SelectInfluencerDialog
        selectInfluDialog={selectInfluDialog}
        handleOpenSelectInfluDialog={handleOpenSelectInfluDialog}
        selectInfluencerActive={selectInfluencerActive}
      />
      <InfluencerSelected
        openInfluencerSelected={openInfluencerSelected}
        toggleInfluSelectedDrawers={toggleInfluSelectedDrawers}
        newEstimateSummary={estimateSummary}
        onClick={onClick}
        candidateInfluencer={candidateInfluencer}
        selectedInfluencerWithAPI={selectedInfluencerWithAPI}
        clearInfluencer={clearInfluencer}
      />
    </>
  );
}
