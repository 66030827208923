import { useState } from "react";
// mui and icons
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
// types and style
import {
  IInitParams,
  ICampaignReportFilterSmProps,
} from "../campaignReport.type";
import { useCampaignReportFilterSmStyle as classes } from "../CampaignReport.style";
// others
import TextFieldCustom from "../../text-field";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export default function CampaignReportFilterSm(
  props: ICampaignReportFilterSmProps
) {
  let date = new Date();

  const {
    filterDate,
    toggleDrawer,
    openDrawerSm,
    filterDateList,
    setOpenDrawerSm,
  } = props;
  const [endDate, setEndDate] = useState<any>(date);
  const [charTypeSelected, setCharTypeSelected] = useState("weekly");
  const [startDate, setStartDate] = useState<any>(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
  );
  const [handleFilterDate, setHandleFilterDate] = useState<IInitParams>({
    job_id: "",
  });

  const { jobId } = useParams<{ jobId: string }>();

  const onFilterDateSelf = (status: string, date: any) => {
    const data = {
      job_id: jobId,
      date: {
        start: dayjs(status === "start" ? date : startDate).format(
          "YYYY-MM-DD"
        ),
        end: dayjs(status === "end" ? date : endDate).format("YYYY-MM-DD"),
      },
    };
    setCharTypeSelected("filterSelf");
    setHandleFilterDate(data);
  };

  const onFilterDate = (
    filterType: string,
    type: string,
    start: any,
    end: any
  ) => {
    const chartType = {
      job_id: jobId,
      chart_type: type,
    };
    const filterSelf = {
      job_id: jobId,
      date: {
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD"),
      },
    };
    if (filterType === "chartType") {
      setCharTypeSelected("chartType");
      setHandleFilterDate(chartType);
    } else if (filterType === "more1Month") {
      setCharTypeSelected("more1Month");
      setHandleFilterDate(filterSelf);
    }
    setStartDate(start);
    setEndDate(end);
    setCharTypeSelected(type);
  };

  const onApplyFilter = () => {
    filterDate(handleFilterDate, charTypeSelected);
    setOpenDrawerSm(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawerSm}
      onClose={toggleDrawer(false)}
      sx={classes.drawer}
    >
      <Box width={["100vw", "400px"]} position="relative">
        <Box sx={classes.filterTitle}>
          <Typography variant="h6" color="#893DBD">
            Filter
          </Typography>
          <CloseIcon onClick={toggleDrawer(false)} />
        </Box>
        <Divider />
        <Box p="12px">
          <Box ml="8px">
            {filterDateList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  ...classes.filterDateList,
                  bgcolor: item.type === charTypeSelected ? "#EBDFFF" : "#fff",
                  color: item.type === charTypeSelected ? "#8C499C" : "initial",
                }}
                onClick={() => {
                  if (item.filterType !== "filterSelf") {
                    onFilterDate(
                      item.filterType,
                      item.type,
                      item.value.start,
                      item.value.end
                    );
                  } else {
                    setCharTypeSelected(item.filterType);
                  }
                }}
              >
                <Typography variant="body2" ml={3}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
          <Divider sx={{ my: "10px" }} />
          <Stack direction="row" spacing={2} mt={5}>
            <TextFieldCustom
              fullWidth
              value={dayjs(startDate).format("DD/MM/YYYY")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon sx={classes.calendarIcon} />
                  </InputAdornment>
                ),
              }}
              sx={classes.txtFieldDate}
            />
            <TextFieldCustom
              fullWidth
              value={dayjs(endDate).format("DD/MM/YYYY")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon sx={classes.calendarIcon} />
                  </InputAdornment>
                ),
              }}
              sx={classes.txtFieldDate}
            />
          </Stack>
          <Stack spacing={6} sx={classes.datePicker}>
            <DatePicker
              calendarClassName="datepicker-calendar-container"
              selected={startDate}
              onChange={(date: any) => {
                setStartDate(date);
                onFilterDateSelf("start", date);
              }}
              dateFormat="dd/MM/yyyy"
              startDate={startDate}
              endDate={endDate}
              value={startDate}
              selectsStart
              inline
            />
            <DatePicker
              calendarClassName="datepicker-calendar-container"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date: any) => {
                setEndDate(date);
                onFilterDateSelf("end", date);
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              value={endDate}
              inline
            />
          </Stack>
        </Box>
        <Divider />
        <Box sx={classes.applyBox}>
          <Typography color="primary">Clear</Typography>
          <Button
            variant="contained"
            sx={classes.applyBtn}
            onClick={onApplyFilter}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
