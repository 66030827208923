import { hWhite, hGrey, hPink } from "@theme/index";
import { Tab, TabProps, styled } from "@mui/material";
import { useMyJobsDetailStyle } from "@views/my-jobs/jobs-detail/JobsDetail.style";

export const useEmployerCampaignStyle = {
  jobboardCon: {
    objectFit: "cover",
    m: {
      xs: "-30px -10px -55px -20px",
      sm: "-30px -28px -55px -25px",
      md: "-20px 0 30px -20px",
      lg: "-20px 0 0 -20px",
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: ["240px", "240px", "100%"],
  },
  callback: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: {
      xs: "10%",
      md: "4%",
    },
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
    "& > span": {
      display: {
        xs: "none",
        md: "initial",
      },
    },
    "& > svg": {
      m: {
        xs: "13px 0 0 17px",
        md: "13px 0 13px 13px",
      },
      bgcolor: {
        xs: "#fff",
        md: "initial",
      },
      borderRadius: "50%",
      fontSize: {
        xs: "30px",
        md: "16px",
      },
    },
  },
  container: {
    bgcolor: hWhite[100],
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    mb: "24px",
  },
  tab: useMyJobsDetailStyle.tab,
  tabBox: useMyJobsDetailStyle.tabBox,
  tabSelected: useMyJobsDetailStyle.tabSelected,
  imageTitle: {
    width: ["56px", "56px", "64px"],
    height: ["56px", "56px", "64px"],
    borderRadius: "4px",
  },
  campJobTitle: {
    ml: 3,
    display: ["none", "flex"],
    justifyContent: "space-between",
    width: "100%",
    "& div:first-of-type": {
      display: ["none", "flex"],
      "& p": {
        fontSize: ["16px", "20px"],
      },
      "& p:last-of-type": {
        color: hGrey[900],
        ml: [0, 2],
      },
    },
  },
  campJobStatus: {
    display: ["none", "none", "flex"],
    alignItems: "center",
    alignSelf: "flex-start",
    "& svg, p": { color: hPink[300] },
    "& svg": { fontSize: "8px" },
    "& p": { fontSize: ["14px", "14px", "16px"], ml: "5px" },
  },
  campJobItem: {
    justifyContent: "space-between",
    position: {
      xs: "static",
      sm: "absolute",
    },
    top: "35px",
    left: ["63px", "55px"],
    "& svg, p": {
      color: hGrey[900],
    },
    "& hr:nth-of-type(3)": {
      display: ["none", "block"],
    },
    "& div:last-of-type": {
      display: ["none", "flex"],
    },
  },
  campJobItemList: {
    display: "flex",
    alignItems: "center",
    ml: "-5px",
    "& > svg": {
      fontSize: ["16px", "20px"],
    },
    "& > p": {
      fontSize: ["14px", "16px"],
      ml: "5px",
      color: "#212121",
    },
    "& > .css-1cfuqz3-MuiAvatar-root": {
      width: "20px",
      height: "20px",
    },
  },
  socialBox: {
    display: {
      xs: "none",
      lg: "flex",
      xl: "none",
    },
    justifyContent: "space-between",
    mt: 3,
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        color: hGrey[900],
        width: "18px",
        height: "18px",
      },
      "& p": {
        color: hGrey[900],
        fontSize: "14px",
      },
    },
  },
  campJobSecondStatus: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: "8px",
      height: "8px",
      color: hPink[300],
    },
    "& p": {
      color: hPink[300],
      fontSize: "14px",
      ml: "5px",
    },
  },
  campaignTab: {
    ...useMyJobsDetailStyle.campaignTab,
    "& > div": { height: "initial" },
  },
};

export const CampaignTab = styled(Tab)<TabProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: hWhite[100],
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  minHeight: "40px",
  maxHeight: "40px",
  minWidth: "50px",
  maxWidth: "100%",
  fontSize: "26px",
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  "& > .MuiTab-iconWrapper": {
    marginBottom: 0,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: "#fff",
    minWidth: "70px",
    maxWidth: "100%",
    width: "23%",
    maxHeight: "58px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "16%",
    borderRadius: "20px 20px 0px 0px",
    minWidth: "132px",
    maxWidth: "132px",
    minHeight: "34px",
    maxHeight: "34px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  },
  [theme.breakpoints.up("md")]: {
    width: ["20%", "20%", "19.52%"],
    minWidth: "160px",
    maxWidth: "160px",
    minHeight: "44px",
    maxHeight: "44px",
    fontSize: "16px",
  },
}));
