import { useState } from "react";
// mui
import {
  Box,
  Icon,
  Paper,
  Button,
  Divider,
  InputBase,
  IconButton,
  Typography,
} from "@mui/material";
// icon and style
import SearchIcon from "@mui/icons-material/Search";
import { useCampaignCardStyle as classes } from "./Campaign.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// component and type
import NewCampaignDialog from "./NewCampaignDialog";
import { ICampaignList } from "@views/campaign/Campaign.type";
// other
import { useHistory, useLocation } from "react-router-dom";

export default function CampaignEmployerTabs(props: {
  campaignList: ICampaignList | null;
}) {
  const { campaignList } = props;

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";
  const q = params.get("q") || "";

  const [search, setSearch] = useState(q);
  const [openNewCampaignDialog, setOpenNewCampaignDialog] = useState(false);

  const campaignStatusList = [
    {
      status: "",
      title: "ทั้งหมด",
      bgcolor: "#F4ECFF",
      color: "#893DBD",
      total: campaignList?.total || 0,
    },
    {
      status: "briefing",
      title: "ร่างแคมเปญ",
      bgcolor: "#FFFBD4 ",
      color: "#FB8C00",
      total: campaignList?.briefing || 0,
    },
    {
      status: "influencer_listing",
      title: "รับสมัคร",
      bgcolor: "#FFEAF4",
      color: "#E8008A",
      total: campaignList?.influencer_listing || 0,
    },
    {
      status: "published",
      title: "กำลังดำเนินการ",
      bgcolor: "#F4ECFF",
      color: "#893DBD",
      total: campaignList?.published || 0,
    },
    {
      status: "reported",
      title: "รายงาน",
      bgcolor: "#E5F6FF ",
      color: "#0077E4",
      total: campaignList?.reported || 0,
    },
    {
      status: "completed",
      title: "เสร็จสิ้น",
      bgcolor: "#E7FFE8",
      color: "#4CAF50",
      total: campaignList?.completed || 0,
    },
    {
      status: "canceled",
      title: "ยกเลิก",
      color: "#fff",
      bgcolor: "#9E9E9E",
      total: campaignList?.canceled || 0,
    },
  ];

  const employerInfo = useAppSelector(selectEmployerInfo);
  const isTeam = employerInfo?.package_plan === "team";
  const packages = isTeam ? employerInfo.team?.package : employerInfo?.package;

  const handleChangeStatus = (status: string) => {
    if (!status) {
      history.replace("/campaigns");
    } else {
      history.replace(`/campaigns?status=${status}`);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value.trim());
    if (value.trim() === "") {
      history.replace(`/campaigns?q=&status=${status}&page=1`);
    }
  };

  const onSearch = () => {
    history.replace(`/campaigns?q=${search}&status=${status}&page=1`);
  };

  const onCreateCampaign = () => {
    if (
      (campaignList?.campaign_month?.campaign_month_created || 0) >=
      (campaignList?.campaign_month?.campaign_month_limit || 0)
    ) {
      setOpenNewCampaignDialog(true);
    } else {
      history.push(`/campaigns/create`);
    }
  };

  return (
    <Box sx={classes.headerWrap}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" columnGap="16px" alignItems="center">
          <Box sx={classes.planWrap}>
            <Icon className="ico-hu-person" fontSize="small" />
            <Typography variant="title1" textTransform="capitalize">
              {packages?.title || "free"}
            </Typography>
          </Box>
          <Typography variant="h6">
            แคมเปญประจำเดือนนี้ {campaignList?.campaign_month?.campaign_month_created}/
            {campaignList?.campaign_month?.campaign_month_limit}
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Icon className="ico-hu-add-circle" />}
          sx={{ border: "none", "&:hover": { border: "none" } }}
          onClick={onCreateCampaign}
        >
          New Campaign
        </Button>
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Box display="flex" columnGap="8px">
        {campaignStatusList.map((tab, index) => (
          <Button
            key={index}
            onClick={() => handleChangeStatus(tab.status)}
            sx={{
              maxHeight: "40px",
              bgcolor: tab.status === status ? tab.bgcolor : "#fff",
              border: tab.status === status ? "none" : "1px solid #E0E0E0",
              color: tab.status === status ? tab.color : "#5C5C5C",
              fontWeight: tab.status === status ? 500 : 300,
              "&:hover": {
                border: tab.status === status ? "none" : "1px solid #E0E0E0",
                bgcolor: tab.status === status ? tab.bgcolor : "#fff",
              },
              px: "15px",
              fontSize: "16px",
            }}
          >
            <Typography noWrap>
              {`${tab.title} ${tab.total === 0 ? "" : `(${tab.total})`}`}
            </Typography>
          </Button>
        ))}
        <Divider flexItem orientation="vertical" sx={{ m: "5px" }} />
        <Paper sx={classes.serach}>
          <InputBase
            sx={{ ml: 4, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={handleSearchChange}
          />
          <IconButton onClick={onSearch} sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>

      {/* dialog */}
      <NewCampaignDialog
        open={openNewCampaignDialog}
        onClose={setOpenNewCampaignDialog}
      />
    </Box>
  );
}
