import React, { useState } from "react";
// mui and icon
import {
  Box,
  Tab,
  Menu,
  Tabs,
  Table,
  Paper,
  Radio,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  RadioGroup,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
  FormControlLabel,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// type and style
import {
  TabPanelProps,
  IContentDistributionProps,
} from "../../../OverviewTab.type";
import { useContentDistributionStyle as classes } from "../../../OverviewTab.style";
// components
import InfluencerTiersTab from "./InfluencerTiersTab";
import SocialMediaPlaformTab from "./SocialMediaPlaformTab";
import { Link } from "react-scroll";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initHeatMapPercent = [
  {
    start: 0.0,
    end: 2.0,
    color: "#F5EEFF",
  },
  {
    start: 2.0,
    end: 4.0,
    color: "#EBDFFF",
  },
  {
    start: 4.0,
    end: 6.0,
    color: "#D5A4F6",
  },
  {
    start: 6.0,
    end: 8.0,
    color: "#893DBD",
  },
  {
    start: 8,
    end: 100,
    color: "#542485",
  },
];

const initHeatMapInteger = [
  {
    start: 0,
    end: 500,
    color: "#F5EEFF",
  },
  {
    start: 500,
    end: 1000,
    color: "#EBDFFF",
  },
  {
    start: 1000,
    end: 1500,
    color: "#D5A4F6",
  },
  {
    start: 1500,
    end: 2000,
    color: "#893DBD",
  },
  {
    start: 2000,
    end: 100000000,
    color: "#542485",
  },
];

export default function ContentDistribution(props: IContentDistributionProps) {
  const { cateDistTier, cateDistPlatform, setParam, param } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [heatMapSelected, setHeatMapSelected] = useState("Select");
  const [tabValue, setTabValue] = useState(0);
  const [isHeatMap, setIsHeatMap] = useState(false);
  const [heatMapList, setHeatMapList] = useState(initHeatMapPercent);
  const [heatMapType, setHeatMapType] = useState("percent");

  const heatMapOpen = Boolean(anchorEl);
  const handleOpenHeatMap = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anch = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anch);
    }, 600);
  };

  const handleCloseHeatMap = () => {
    setAnchorEl(null);
  };

  const handleChangeTab = (_e: any, newValue: number) => {
    setTabValue(newValue);
  };

  const onHandleChangeHeatMap = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    name: string
  ) => {
    const value = parseInt(e.target.value);
    const heatMap = [...heatMapList];
    if (name === "start") {
      heatMap[index].start = value;
      setHeatMapList(heatMap);
    } else if (name === "end") {
      if (index < 4) {
        heatMap[index].end = value;
        heatMap[index + 1].start = value;
        setHeatMapList(heatMap);
      } else {
        heatMap[index].end = value;
        setHeatMapList(heatMap);
      }
    }
  };

  const onSortTable = (table: string, sort: string) => {
    setParam((prev) => {
      return {
        ...prev,
        sort: {
          table: table,
          sort: sort,
        },
      };
    });
  };

  return (
    <Box id="content-distribution">
      <Stack direction="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="title1">Content Distribution</Typography>
          <Typography variant="subTitle2">
            1 บัญชีสามารถเลือกได้สูงสุด 3 หมวดหมู่
          </Typography>
        </Box>
        <Stack spacing="16px" direction="row">
          <Link
            to="content-distribution"
            spy={true}
            smooth={true}
            offset={-150}
            duration={50}
          >
            <Button
              variant="outlined"
              id="select-heat-map"
              aria-controls={heatMapOpen ? "select-heat-map" : undefined}
              aria-haspopup="true"
              aria-expanded={heatMapOpen ? "true" : undefined}
              onClick={handleOpenHeatMap}
              sx={{
                minHeight: "45px",
                width: "400px",
                borderRadius: !heatMapOpen ? "30px" : "20px 20px 0 0",
              }}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography textTransform="capitalize">
                  {heatMapSelected.replace(/-/gi, " ")}
                </Typography>
                {!heatMapOpen ? (
                  <KeyboardArrowDownIcon color="primary" />
                ) : (
                  <KeyboardArrowUpIcon color="primary" />
                )}
              </Box>
            </Button>
          </Link>
          <Menu
            id="date-select"
            anchorEl={anchorEl}
            open={heatMapOpen}
            onClose={handleCloseHeatMap}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={classes.menu}
          >
            <FormControl sx={classes.formControl}>
              <RadioGroup
                value={heatMapSelected}
                onChange={(e) => setHeatMapSelected(e.target.value)}
              >
                <FormControlLabel
                  value="no-heat-map"
                  control={<Radio />}
                  label="No Heat-Map"
                  onClick={() => setIsHeatMap(false)}
                  sx={{
                    ...classes.formLabel,
                    color:
                      heatMapSelected === "no-heat-map" ? "#8C499C" : "initial",
                    bgcolor:
                      heatMapSelected === "no-heat-map" ? "#EBDFFF" : "initial",
                  }}
                />
                <FormControlLabel
                  value="heat-map"
                  control={<Radio />}
                  label="Heat-Map"
                  onClick={() => setIsHeatMap(true)}
                  sx={{
                    ...classes.formLabel,
                    color:
                      heatMapSelected === "heat-map" ? "#8C499C" : "initial",
                    bgcolor:
                      heatMapSelected === "heat-map" ? "#EBDFFF" : "initial",
                  }}
                />
              </RadioGroup>
            </FormControl>
            {heatMapSelected === "heat-map" && (
              <Box sx={classes.heatMapBox}>
                <FormControl sx={{ mt: "-15px", mb: "5px", ml: "20px" }}>
                  <RadioGroup
                    value={heatMapType}
                    onChange={(e) => setHeatMapType(e.target.value)}
                  >
                    <FormControlLabel
                      value="integer"
                      control={<Radio />}
                      label="จำนวนเต็ม"
                      onClick={() => setHeatMapList(initHeatMapInteger)}
                    />
                    <FormControlLabel
                      value="percent"
                      control={<Radio />}
                      label="เปอร์เซ็นต์"
                      onClick={() => setHeatMapList(initHeatMapPercent)}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant="body2">
                  โปรดระบุเปอร์เซ็นต์เพื่อการแสดงผลข้อมูล
                </Typography>
                <TableContainer component={Paper} sx={classes.tCon}>
                  <Table sx={classes.table}>
                    <TableHead>
                      <TableRow sx={classes.tHead}>
                        {["ระดับ", "ระบุจำนวนเต็ม"].map((table, index) => (
                          <TableCell key={index} sx={{ py: 2 }}>
                            <Typography variant="title2" mr={1.5}>
                              {table}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={classes.tBody}>
                      {heatMapList.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" sx={{ p: 0 }}>
                            <Box sx={classes.heatMapListBox}>
                              <Typography variant="title1" pl="10px">
                                {index + 1}
                              </Typography>
                              <Box
                                width="46px"
                                height="40px"
                                bgcolor={row.color}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" spacing={2} height="40px">
                              {index === 0 ? (
                                <TextField
                                  value={
                                    Number.isNaN(row.start)
                                      ? undefined
                                      : row.start
                                  }
                                  sx={classes.textField}
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  onChange={(e) =>
                                    onHandleChangeHeatMap(e, index, "start")
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {heatMapType === "percent" ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ) : index !== 4 ? (
                                <Box sx={classes.disableTxtField}>
                                  <Typography ml={3.5}>
                                    {row.start ? row.start : "-"}
                                  </Typography>
                                  <Typography mr={3.5}>
                                    {heatMapType === "percent" ? "%" : ""}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box sx={classes.moreThanTxtField}>
                                  <Typography variant="title2">
                                    มากกว่า
                                  </Typography>
                                </Box>
                              )}
                              <Box display="flex" alignItems="center">
                                {index !== 4 && (
                                  <span style={{ fontWeight: 500 }}>-</span>
                                )}
                              </Box>
                              {index < 4 ? (
                                <TextField
                                  value={
                                    Number.isNaN(row.end) ? undefined : row.end
                                  }
                                  sx={classes.textField}
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  onChange={(e) =>
                                    onHandleChangeHeatMap(e, index, "end")
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {heatMapType === "percent" ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ) : (
                                <TextField
                                  value={
                                    Number.isNaN(row.start)
                                      ? undefined
                                      : row.start
                                  }
                                  sx={classes.textField}
                                  variant="outlined"
                                  onChange={(e) =>
                                    onHandleChangeHeatMap(e, index, "start")
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {heatMapType === "percent" ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Menu>
        </Stack>
      </Stack>
      <Box sx={classes.tabsBox}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          sx={{ height: "40px" }}
        >
          <Tab
            label="Social Media Platform"
            sx={classes.tab}
            {...a11yProps(0)}
          />
          <Tab label="Influencer Tiers" sx={classes.tab} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <SocialMediaPlaformTab
          param={param}
          isHeatMap={isHeatMap}
          heatMapList={heatMapList}
          heatMapType={heatMapType}
          onSortTable={onSortTable}
          cateDistPlatform={cateDistPlatform}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <InfluencerTiersTab
          param={param}
          isHeatMap={isHeatMap}
          heatMapList={heatMapList}
          heatMapType={heatMapType}
          onSortTable={onSortTable}
          cateDistTier={cateDistTier}
        />
      </TabPanel>
    </Box>
  );
}
