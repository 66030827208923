// mui and icons
import {
  Box,
  Grid,
  Step,
  Dialog,
  Avatar,
  StepLabel,
  IconButton,
  Typography,
  DialogTitle,
  StepContent,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// style and type
import { useTimelineDialogStyle as classes } from "../CampaignDetail.style";
import { ITimelineDialogProps } from "../CampaignDetail.type";
// others
import influProfile from "@assets/images/people/influencer.png";
import dayjs from "dayjs";

export default function TimelineDraftDialog(props: ITimelineDialogProps) {
  const { open, onClose, timeline, orgUrl, isProduct, isPlace } = props;

  const chekDraftTimline = isPlace
    ? timeline?.end_shipping_date
    : isProduct
    ? timeline?.shipping_date
    : timeline?.announce_date;

  const culDraftTimline = (day: number, date?: string) => {
    const totalDay = isPlace ? day : day + 5;
    return date ? dayjs(date).add(totalDay, "day").format("DD/MM/YYYY") : "";
  };

  const draftPhaseStepLabel = (label: string, role: string) => {
    return (
      <StepLabel
        sx={{ p: 0 }}
        icon={
          <Avatar
            src={role === "influencer" ? influProfile : orgUrl}
            sx={{ width: "48px", height: "48px", zIndex: 1 }}
          />
        }
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="title1" color="#231F20">
            {label}
          </Typography>
        </Box>
      </StepLabel>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography>Timeline</Typography>
        <Typography>ระยะเวลาของแคมเปญ</Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.iconBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={classes.dialogContentBox}>
          <Box mb="16px">
            <Typography variant="title1" color="primary">
              การตรวจ Draft จากอินฟลูเอนเซอร์
            </Typography>
          </Box>
          <Step expanded>
            {draftPhaseStepLabel(
              "อินฟลูเอนเซอร์ส่ง Draft ครั้งแรก",
              "influencer"
            )}
            <StepContent sx={{ ml: "23px", borderLeft: "3px solid #ECECEC" }}>
              <Grid container>
                <Grid item xs={12} sm={5} mt={2}>
                  <Typography variant="title2" ml={2.5}>
                    ส่ง Draft ครั้งแรกภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={classes.dateBox}>
                    <Typography variant="title2" color="primary">
                      {culDraftTimline(5, chekDraftTimline)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box component="ul" ml={[-5, -3]}>
                {[
                  "หลังจากได้รับของ อินฟลูเอนเซอร์ ต้องตรวจสอบสินค้าและแนบหลักฐานการรับ ก่อนเริ่มงานทุกครั้ง",
                  "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ใน ระยะเวลาที่กำหนด ระบบจะพิจารณา บทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                ].map((li, index) => (
                  <Typography key={index} component="li" variant="body2">
                    {li}
                  </Typography>
                ))}
              </Box>
            </StepContent>

            {draftPhaseStepLabel(
              "ผู้จ้างงานตรวจ Draft ครั้งที่ 1",
              "organization"
            )}
            <StepContent sx={{ ml: "23px", borderLeft: "3px solid #ECECEC" }}>
              <Grid container>
                <Grid item xs={12} sm={5} mt={2}>
                  <Typography variant="title2" ml={2.5}>
                    ให้ Feedback ครั้งที่ 1/2 ภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={classes.dateBox}>
                    <Typography variant="title2" color="primary">
                      {culDraftTimline(8, chekDraftTimline)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box component="ul" ml={-3}>
                {[
                  "ผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่กำหนดการส่ง Draft ครั้งแรก",
                  "หากผู้จ้างงานไม่ตรวจงานภายในระยะเวลาที่กำหนด ระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ",
                ].map((li, index) => (
                  <Typography key={index} component="li" variant="body2">
                    {li}
                  </Typography>
                ))}
              </Box>
            </StepContent>

            {draftPhaseStepLabel(
              "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 1",
              "influencer"
            )}
            <StepContent sx={{ ml: "23px", borderLeft: "3px solid #ECECEC" }}>
              <Grid container>
                <Grid item xs={12} sm={5} mt={2}>
                  <Typography variant="title2" ml={2.5}>
                    ส่ง Draft แก้ครั้งที่ 1/2 ภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={classes.dateBox}>
                    <Typography variant="title2" color="primary">
                      {culDraftTimline(11, chekDraftTimline)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box component="ul" ml={-3}>
                {[
                  "อินฟลูเอนเซอร์ต้องส่ง Draft แก้ครั้งที่ 1/2 ภายใน 3 วัน หลังจากได้รับ Feedback ครั้งที่ 1/2 จากผู้จ้างงาน",
                  "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                ].map((li, index) => (
                  <Typography key={index} component="li" variant="body2">
                    {li}
                  </Typography>
                ))}
              </Box>
            </StepContent>

            {draftPhaseStepLabel(
              "ผู้จ้างงานตรวจ Draft ครั้งที่ 2",
              "organization"
            )}
            <StepContent sx={{ ml: "23px", borderLeft: "3px solid #ECECEC" }}>
              <Grid container>
                <Grid item xs={12} sm={5} mt={2}>
                  <Typography variant="title2" ml={2.5}>
                    ให้ Feedback ครั้งที่ 2/2 ภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={classes.dateBox}>
                    <Typography variant="title2" color="primary">
                      {culDraftTimline(14, chekDraftTimline)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box component="ul" ml={-3}>
                {[
                  "ผู้จ้างงานต้องตรวจ Draft ของอินฟลูเอนเซอร์ภายใน 3 วันหลังจากที่กำหนดการส่ง Draft แก้ครั้งที่ 1/2",
                  "หากผู้จ้างงานไม่ตรวจงานภายในระยะเวลาที่กำหนด ระบบจะอนุมัติ Draft ของอินฟลูเอนเซอร์ให้อัตโนมัติ",
                ].map((li, index) => (
                  <Typography key={index} component="li" variant="body2">
                    {li}
                  </Typography>
                ))}
              </Box>
            </StepContent>

            {draftPhaseStepLabel(
              "อินฟลูเอนเซอร์ส่ง Draft แก้ครั้งที่ 2",
              "influencer"
            )}
            <StepContent sx={{ ml: "23px", borderLeft: "3px solid #ECECEC" }}>
              <Grid container>
                <Grid item xs={12} sm={5} mt={2}>
                  <Typography variant="title2" ml={2.5}>
                    ส่ง Draft แก้ครั้งที่ 2/2 ภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={classes.dateBox}>
                    <Typography variant="title2" color="primary">
                      {culDraftTimline(17, chekDraftTimline)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box component="ul" ml={-3}>
                {[
                  "อินฟลูเอนเซอร์ต้องส่ง Draft แก้ครั้งที่ 2/2 ภายใน 3 วัน หลังจากได้รับ Feedback ครั้งที่ 2/2 จากผู้จ้างงาน",
                  "หากอินฟลูเอนเซอร์ไม่ส่ง Draft ในระยะเวลาที่กำหนด ระบบจะพิจารณาบทลงโทษและดำเนินการยกเลิกงาน โดยอัตโนมัติ",
                ].map((li, index) => (
                  <Typography key={index} component="li" variant="body2">
                    {li}
                  </Typography>
                ))}
              </Box>
            </StepContent>

            {draftPhaseStepLabel(
              "ผู้จ้างงานตรวจ Draft ครั้งสุดท้าย",
              "organization"
            )}
            <StepContent sx={{ ml: "23px", border: "none" }}>
              <Grid container>
                <Grid item xs={12} sm={5} mt={2}>
                  <Typography variant="title2" ml={2.5}>
                    ได้รับ Feedback ครั้งสุดท้ายภายในวันที่
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={classes.dateBox}>
                    <Typography variant="title2" color="primary">
                      {culDraftTimline(21, chekDraftTimline)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box component="ul" ml={-3}>
                {[
                  "ในการตรวจครั้งสุดท้าย ผู้จ้างงาน สามารถให้ความคิดเห็นโดยรวมได้  เท่านั้น",
                  "หาก Draft แก้ครั้งที่ 2/2 ของ อินฟลูเอนเซอร์ “ไม่ผ่าน” ผู้จ้างงาน สามารถ “ยกเลิกงาน อินฟลูเอนเซอร์” ได้ โดย อินฟลูเอนเซอร์จะได้รับค่าจ้าง ตามอัตราค่ามัดจำแคมเปญของค่าจ้างทั้งหมด ที่อินฟลูเอนเซอร์จะได้รับ",
                ].map((li, index) => (
                  <Typography key={index} component="li" variant="body2">
                    {li}
                  </Typography>
                ))}
              </Box>
            </StepContent>
          </Step>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
