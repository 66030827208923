export const useCancelCampaignStyle = {
  goBack: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
  },
  card: {
    bgcolor: "#fff",
    p: "24px",
    mt: "24px",
    borderRadius: { xs: "8px", lg: "16px" },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  cancelBtn: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    "& > button": {
      width: "262px",
      height: "48px",
      border: "none",
      "&:hover": { border: "none" },
      fontSize: "16px",
    },
  },
};
