import React from "react";
import ReactDOM from "react-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "react-datepicker/dist/react-datepicker.css";
import "suneditor/dist/css/suneditor.min.css";

import "@fontsource/kanit";
import "./index.css";
import "@assets/icons/hashu-icon.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getTokenFromStorage } from "./utils/helpers/getTokenFromStorage";
import { setTokenToStorage } from "./utils/helpers/setTokenToStorage";
import { AuthenticationAPI } from "@api/global";
import dayjs from "dayjs";

let persistor = persistStore(store);

export let healthCheck = setInterval(async () => {
  const { session_token, refresh_token } = getTokenFromStorage();
  const now = dayjs().unix();
  if (!!session_token) {
    if (session_token?.exp - now <= 0) {
      clearInterval(healthCheck);
      const result = await AuthenticationAPI.refreshToken({
        refresh_token: refresh_token,
      });
      setTokenToStorage(result);
    }
  }
}, 10000);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
