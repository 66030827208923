// mui and image
import { Box, Grid, Typography } from "@mui/material";
import thaiMap from "@assets/images/other/thai-map.png";
// api and type
import { CampaignAPI } from "@api/global";
import { ICreateCampaignProps } from "../../CampaignBrief.type";
// other
import TextFieldCustom from "@global/text-field";

export default function PlaceDetails(props: ICreateCampaignProps) {
  const {
    form: {
      watch,
      setValue,
      register,
      setError,
      clearErrors,
      formState: { errors },
    },
  } = props;

  const isPlace = watch("review_type") === "place";
  const staticMap = watch("location.static_map_url");

  const handleChangeStaticMap = (e: any) => {
    const value = e.target.value;

    const urlRegex =
      /^(https:\/\/www\.google\.com\/maps|https:\/\/goo\.gl\/maps)/;
    const isValidURL = urlRegex.test(value);

    if (!isValidURL) {
      setValue("location.static_map_url", "");
      setError("location.url", {
        message: "URL format is invalid",
      });
    } else {
      clearErrors("location.url");
      CampaignAPI.getGoogleMapLocation({ url:value }).then((response) => {
        const data = {
          ...response,
          name: decodeURIComponent(response.name),
          url: decodeURIComponent(response.url),
        };
        setValue("location", data);
        setValue("location.static_map_url", data.static_map_url);
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">
          Location Details<span className="require">*</span>
        </Typography>
      </Grid>
      <Box component="ul" width="100%">
        <Box display="flex">
          <Grid item xs={2.7}>
            <Box component="li" mt={5}>
              <Typography variant="body2">Google Map Location</Typography>
            </Box>
          </Grid>
          <Grid item xs={9.3} mt="15px">
            <Box>
              <TextFieldCustom
                variant="outlined"
                placeholder="url"
                fullWidth
                error={!!errors?.location?.url}
                helperText={errors?.location?.url?.message}
                inputProps={{
                  ...register("location.url"),
                  onChange: handleChangeStaticMap,
                }}
                sx={{ mb: 2 }}
              />
              <Typography variant="caption" fontWeight={400} ml={3}>
                คัดลอกลิงค์สถานที่ตั้งจาก google map
              </Typography>
              <Box
                onClick={() =>
                  watch("location.url")
                    ? window.open(watch("location.url"), "_blank")
                    : window.open(
                        "https://www.google.com/maps/search/map/@13.5211656,100.0142452,6.73z",
                        "_blank"
                      )
                }
                sx={{
                  mt: "16px",
                  "& > img": {
                    width: "100%",
                    height: "360px",
                    objectFit: "cover",
                    cursor: "pointer",
                  },
                }}
              >
                {staticMap ? (
                  <img src={staticMap} alt="thai map" />
                ) : (
                  <img src={thaiMap} alt="thai map" />
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid item xs={2.7}>
            <li>
              <Typography variant="body2" mt={2.5}>
                Location Name
              </Typography>
            </li>
          </Grid>
          <Grid item xs={9.3} mt="15px">
            <TextFieldCustom
              variant="outlined"
              placeholder="Location Name"
              fullWidth
              error={!!errors?.location?.name}
              helperText={errors?.location?.name?.message}
              inputProps={{
                ...register("location.name", {
                  // required: isPlace,
                }),
              }}
            />
          </Grid>
        </Box>
        <Box display="flex">
          <Grid item xs={2.7}>
            <Box component="li" mt={5}>
              <Typography variant="body2" mt={2.5}>
                Address
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9.3} mt="15px">
            <TextFieldCustom
              variant="outlined"
              placeholder="Address"
              fullWidth
              multiline
              minRows={3}
              error={!!errors?.location?.address}
              helperText={errors?.location?.address?.message}
              inputProps={{
                ...register("location.address", {
                  required: isPlace,
                }),
              }}
            />
          </Grid>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid item xs={2.7}>
            <li>
              <Typography variant="body2" mt={2.5}>
                Contact Name
              </Typography>
            </li>
          </Grid>
          <Grid item xs={9.3} mt="15px">
            <TextFieldCustom
              variant="outlined"
              placeholder="Contact Name"
              fullWidth
              error={!!errors?.location?.contact}
              helperText={errors?.location?.contact?.message}
              inputProps={{
                ...register("location.contact", {
                  required: isPlace,
                }),
              }}
            />
          </Grid>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid item xs={2.7}>
            <li>
              <Typography variant="body2" mt={2.5}>
                Phone
              </Typography>
            </li>
          </Grid>
          <Grid item xs={9.3} mt="15px">
            <TextFieldCustom
              variant="outlined"
              placeholder="Phone"
              fullWidth
              error={!!errors?.location?.phone}
              helperText={errors?.location?.phone?.message}
              inputProps={{
                ...register("location.phone", {
                  required: isPlace,
                }),
              }}
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
}
