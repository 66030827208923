import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Icon,
  Link,
  Paper,
  Stack,
  Button,
  Avatar,
  Popover,
  Divider,
  Typography,
} from "@mui/material";
// style
import {
  ButtonSignIn,
  useHeaderBarDesktopStyle as classes,
} from "./HomeHeaderBar.styles";
import "./HomeHeaderBar.styles.css";
// api and type
import { AuthenticationAPI } from "@api/global";
import { IHomeHeaderBarDesktopProps } from "./HomeHeader.types";
// other
import { menuList } from "./HomeHeaderBar";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import logo from "@assets/images/logo/hashu-logo2.svg";
import useScrollingUp from "@utils/hooks/useScrollingUp";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

function UserCard() {
  const history = useHistory();
  const { signOut } = useAuth();
  const { session_token } = getTokenFromStorage();

  const onLogout = async () => {
    try {
      await AuthenticationAPI.signOut();
      const next = () => history.push("/");
      signOut(next);
    } catch (error) {
      const next = () => history.push("/");
      signOut(next);
    }
  };

  return (
    <Paper sx={{ width: "230px", p: "10px" }}>
      <Box display="flex" justifyContent="center">
        {session_token?.profile_image_url ? (
          <Avatar
            alt="user-profile-img"
            src={session_token?.profile_image_url}
            sx={classes.avatar}
          />
        ) : (
          <Avatar sx={classes.avatar}>
            {session_token?.fullname.charAt(0)}
          </Avatar>
        )}
      </Box>
      <Typography align="center" fontWeight={700}>
        {session_token && session_token.fullname}
      </Typography>
      <Typography variant="subtitle2" align="center">
        ({session_token && session_token.code})
      </Typography>

      <Box mt="10px">
        <Box
          sx={classes.boxMenu}
          onClick={() => {
            session_token?.role === "influencer"
              ? history.push(`/job-board`)
              : session_token?.role === "employer"
              ? history.push(`/team`)
              : history.push("/employers");
          }}
        >
          <Box sx={classes.boxMenuIcon}>
            <Icon className="ico-hu-home" />
          </Box>
          <Box width="70%">
            <Typography variant="caption">HashU Console</Typography>
          </Box>
        </Box>

        {session_token?.role === "influencer" && (
          <Box sx={classes.boxMenu} onClick={() => history.push(`/my-referral`)}>
            <Box sx={classes.boxMenuIcon}>
              <Icon className="ico-hu-group" />
            </Box>
            <Box width="70%">
              <Typography variant="caption">My Referral</Typography>
            </Box>
          </Box>
        )}

        <Divider />

        <Box sx={classes.boxMenuLogout} onClick={onLogout}>
          <Box sx={classes.boxMenuIcon}>
            <Icon className="ico-hu-logout" />
          </Box>
          <Box width="70%">
            <Typography variant="caption">ออกจากระบบ</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default function HomeHeaderBarDesktop({ setOpenSignIn }: IHomeHeaderBarDesktopProps) {
  const isScrollingUp = useScrollingUp();
  const history = useHistory();
  const { session_token } = getTokenFromStorage();

  const [name, setName] = useState(session_token?.fullname || "");
  const [imageProfile, setImageProfile] = useState(session_token?.profile_image_url);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSelected, setMenuSelected] = useState<null | "notification" | "user">(null);

  useEffect(() => {
    setImageProfile(session_token?.profile_image_url);
    setName(session_token?.fullname || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("token"), session_token]);

  const open = Boolean(anchorEl);

  const handleOpenDrawer = (
    event: React.MouseEvent<HTMLElement>,
    menuName: "notification" | "user"
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMenuSelected(menuName);
  };

  const handleCloseDrawer = () => {
    setAnchorEl(null);
    setMenuSelected(null);
  };

  return (
    <header className={`home-bar ${isScrollingUp ? "visible" : "hidden"}`}>
      <Box boxShadow={3} sx={classes.headerBox}>
        <Stack direction="row" spacing={11}>
          <Box display="flex" alignItems="center">
            <Link href="/" underline="none">
              <img src={logo} alt="Hashu Logo" style={classes.logoImg} />
            </Link>
          </Box>
          {menuList.map((menu, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Link href={menu.path} underline="none">
                <Typography
                  variant="body1"
                  color={
                    history.location.pathname === menu.path
                      ? "secondary"
                      : "grey.700"
                  }
                  fontWeight={
                    history.location.pathname === menu.path ? "bold" : "normal"
                  }
                  sx={classes.menuLabel}
                >
                  {menu.label}
                </Typography>
              </Link>
            </Box>
          ))}
        </Stack>
        {session_token ? (
          <div>
            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={{
                bgcolor: "common.white",
                "&:hover": {
                  bgcolor: "common.white",
                },
              }}
              onClick={(event) => {
                handleOpenDrawer(event, "user");
              }}
            >
              {session_token?.profile_image_url ? (
                <Box mr={2}>
                  <Avatar src={imageProfile} alt="profile" />
                </Box>
              ) : (
                <Box mr={2}>
                  <Avatar className="avatar-img">
                    {session_token?.fullname.charAt(0)}
                  </Avatar>
                </Box>
              )}
              <Box
                sx={{
                  display: ["none", "none", "flex"],
                  width: "100%",
                }}
              >
                <Typography variant="subtitle2" color="black">
                  {name.split(" ")[0]}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: ["none", "none", "flex"],
                  alignItems: "center",
                  ml: 2,
                  color: "black",
                }}
              >
                <Icon className="ico-hu-chevron-down" />
              </Box>
            </Button>
            {menuSelected && (
              <Popover
                sx={{ mt: "15px" }}
                open={open}
                onClose={handleCloseDrawer}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {menuSelected === "user" && <UserCard />}
              </Popover>
            )}
          </div>
        ) : (
          <ButtonSignIn onClick={() => setOpenSignIn(true)}>
            <Typography variant="body1" fontWeight="bold">
              Sign In
            </Typography>
          </ButtonSignIn>
        )}
      </Box>
    </header>
  );
}
