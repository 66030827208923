import { useEffect } from "react";
// mui and style
import { Box, Button, Grid, Typography } from "@mui/material";
import { HomeSupportStyle as classes } from "./HomeSupport.style";
// other
import { hColorful } from "@theme/index";
import { useHistory } from "react-router";
import useBreakpoint from "@utils/hooks/useBreakpoint";
import TabMenu from "@components/home-support/TabMenu";
import FAQImage from "@assets/images/home/pricing-and-plan-page/faq.png";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";

export default function HomeSupport() {
  const bp = useBreakpoint();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={classes.contentMain}>
      <Box id="back-to-top-anchor" sx={classes.headerBackground}></Box>
      <Box width={["100%", "100%", "90%", "85%"]}>
        <Box pt={[15, 20, 20, 30]}>
          <Box textAlign="center">
            <Typography
              variant={setValueByBreakpoint(bp, "h4", "h3", "h3", "h2")}
            >
              How Can We Help You?
            </Typography>
            <br />
            <Typography
              variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
              color={"text.secondary"}
            >
              มีอะไรให้เราช่วยคุณไหม?
            </Typography>
          </Box>
        </Box>
        <Box>
          <TabMenu />
        </Box>
        <Box px={15}>
          <Grid
            container
            columns={8}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              xs={8}
              sm={4}
              md={4}
              lg={4}
            >
              <Box textAlign={bp === "xs" ? "center" : "start"}>
                <Typography
                  variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
                >
                  Question ?
                </Typography>
                <br />
                <Typography
                  variant={setValueByBreakpoint(
                    bp,
                    "body1",
                    "body2",
                    "body2",
                    "body1"
                  )}
                  color={"text.secondary"}
                >
                  Feel free to contact us if you have any further question.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    border: "unset",
                    background: `linear-gradient(296.45deg, ${hColorful[100]} -3.27%, ${hColorful[200]} 102.18%)`,
                    "&:hover": {
                      border: "unset",
                    },
                  }}
                  onClick={() => {
                    history.push("/home/contact");
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              xs={8}
              sm={4}
              md={4}
              lg={4}
            >
              <img
                src={FAQImage}
                alt="faq"
                style={{
                  width: setValueByBreakpoint(
                    bp,
                    "280px",
                    "296px",
                    "296px",
                    "445px"
                  ),
                  objectFit: "contain",
                }}
              ></img>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
