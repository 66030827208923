import { useContext, useEffect, useState } from "react";
// mui and style
import { Box, Button, CircularProgress, Icon, Typography } from "@mui/material";
import { useCreateTeamStyle as classes } from "./CreateTeam.style";
// type and api
import { AppInformationAPI, TeamAPI } from "@api/global";
// redux
import {  useAppSelector } from "@store/hook";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";
// component and image
import CreateTeamStep from "./CreateTeamStep";
import DefaultImage from "@assets/images/other/image-empty.jpg";
// other
import { useHistory } from "react-router-dom";
import TextFieldCustom from "@global/text-field";
import { addDefaultImageSrc } from "@utils/helpers/addDefaultImageSrc.helpers";
import TeamContext from "@contexts/Team";
import { IAttachments } from "@api/app-information/type";

const inintAttachment: IAttachments = {
  file_id: "",
  file_name: "",
  file_type: "",
  url: "",
  description: "",
  purpose: "",
};

export default function CreateYourTeam() {
  const teamContext = useContext(TeamContext)
  
  const [attachment, setAttachment] = useState<IAttachments>(inintAttachment);
  const [inputName, setInputName] = useState("");
  const [imgLoading, setImgLoading] = useState(false);

  const history = useHistory();
  const employerInfo = useAppSelector(selectEmployerInfo);

  useEffect(() => {
    if (!teamContext?.teamInfo) return;

    if (teamContext?.teamInfo.attachment) {
      setAttachment(teamContext.teamInfo.attachment)
    }

    if (teamContext?.teamInfo.name) {
      setInputName(teamContext.teamInfo.name)
    }

  }, [teamContext])

  const onUploadImage = async (e: any) => {
    setImgLoading(true);
    const file = e.target.files[0];
    const fileData = {
      file,
      destination: "team",
      permission: "public",
    };
    AppInformationAPI.uploadAttachment(fileData).then((response) => {
      setAttachment({
        file_name: response.file_name,
        file_type: response.file_type,
        url: response.public_url as string,
        file_id: "",
        description: "",
        purpose: ""
      });
    }).finally(() => {
      setImgLoading(false);
    })
  };

  const onSubmit = async () => {
    teamContext?.setIsLoading(true);
    const data = {
      name: inputName,
      attachment: attachment,
    };

    if (teamContext?.teamInfo?.id) {
      TeamAPI.updateTeam(teamContext.teamInfo.id, data).then((response) => {
        teamContext.setTeamInfo(response);
        TeamAPI.updateTeamProgress(response.id, { create: true, member:false, transfer_wallet:false }).then(() => {
          teamContext.setStep("add-member");
        })
      }).finally(() => {
        teamContext?.setIsLoading(false);
      })
    } else {
      TeamAPI.insertTeam(data)
        .then((response) => {
          teamContext?.setTeamInfo(response);
  
          if (employerInfo?.package_plan === "no_plan") {
            history.push(`subscription/plan/team`);
          } else if (employerInfo?.package_plan === "personal") {
            history.push(`subscription/replan/team`);
          } else {
            history.push("/team?st=add-member");
          }
  
          TeamAPI.updateTeamProgress(response.id, {create: true, member:false, transfer_wallet:false }).then(() => {
            teamContext?.setStep("add-member")
          })
        })
        .finally(() => {
          teamContext?.setIsLoading(false);
        });
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box sx={classes.createYourTeamWrap}>
        <CreateTeamStep steped={teamContext?.step || null} />
        <Typography variant="h5" mt="42px" mb="24px">
          Create Your Team
        </Typography>
        <Box
          component="label"
          onChange={onUploadImage}
          minWidth="400px"
          display="flex"
          justifyContent="center"
          sx={{ cursor:"pointer" }}
        >
          {attachment.url ? (
            <Box sx={classes.isUploadImg}>
              {imgLoading && (
                <div className="curcular">
                  <CircularProgress size="70px" />
                </div>
              )}
              <img
                src={attachment.url}
                onError={addDefaultImageSrc}
                alt="create-team"
                className="img"
              />
            </Box>
          ) : (
            <Box sx={classes.isNotUploadImg}>
              {imgLoading ? (
                <CircularProgress size="70px" />
              ) : (
                <div>
                  <img
                    src={DefaultImage}
                    onError={addDefaultImageSrc}
                    alt="create-team"
                    style={{ width: "64px", height: "64px", borderRadius: "5px" }}
                  />
                  <Box display="flex" mt="6px" alignItems="center" justifyContent="center">
                    <Icon className="ico-hu-upload" sx={classes.icon} />
                    <Typography variant="caption" color="#616161">
                      Image
                    </Typography>
                  </Box>
                </div>
              )}
            </Box>
          )}
          <input hidden name="createTeam" accept="image/*" type="file" />
        </Box>

        <Box sx={classes.textFieldWrap}>
          <Typography variant="title1">ชื่อทีม</Typography>
          <TextFieldCustom
            value={inputName}
            sx={classes.textField}
            onChange={(e) => setInputName(e.target.value)}
            placeholder="Type someting..."
          />
        </Box>

        <Box sx={classes.buttonWrap}>
          <Button
            disabled={attachment.url === "" && inputName === ""}
            onClick={onSubmit}
            sx={classes.button}
          >
            <Typography variant="buttonM">ถัดไป</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
