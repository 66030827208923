import { Menu, MenuProps, alpha, styled } from "@mui/material";
import { hBlack, hGrey, hPurple, hSocialColor } from "@theme/index";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const useSyncYoutubeStyle = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "440px",
    width: "100%",
    display: "flex",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
  },
  audienceTabs: {
    minHeight: "fit-content",
    backgroundColor: hPurple[100],
    borderRadius: "45px",
  },
  buttonSyncSocial: {
    maxHeight: "47px",
    minHeight: "47px",
    width: ["300px", "350px"],
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
};

export const useSyncTwitterStyle = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "440px",
    width: "100%",
    display: "flex",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
  },
  audienceTabs: {
    minHeight: "fit-content",
    backgroundColor: hPurple[100],
    borderRadius: "45px",
  },
  twitterLogo: {
    fontSize: ["50px", "70px", "80px"],
    color: hSocialColor.twitter
  },
  buttonSyncSocial: {
    maxHeight: "47px",
    minHeight: "47px",
    color: "#fff",
    width: ["300px", "350px"],
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    bgcolor: hSocialColor.twitter,
    "&:hover": {
      bgcolor: hSocialColor.twitter,
    },
  },
};

export const useSyncFacebookStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "440px",
    width: "100%",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
  },
  syncSocial: {
    cursor: "pointer",
    textAlign: "center",
  },
  buttonSyncSocial: {
    maxHeight: "47px",
    minHeight: "47px",
    color: "#fff",
    width: ["300px", "350px"],
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    bgcolor: hSocialColor.facebook,
    "&:hover": {
      bgcolor: hSocialColor.facebook,
    },
  },
  platformLogo: {
    width: ["50px", "70px", "80px"],
    height: ["50px", "70px", "80px"],
  },
};

export const useInfluencerSocialPendingStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50vh",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
  },
};

export const useInfluencerSocialSyncDataStyle = {
  boxSyncSocial: {
    position: "absolute",
    right: "18px",
  },
  menu: {
    "& .MuiPaper-root": {
      width: "240px",
      borderRadius: "12px",
      mt: "5px",
      border: "1px solid #8C499C",
    },
  },
  profileBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    p: "10px",
    "& > .MuiSvgIcon-root, .css-1cfuqz3-MuiAvatar-root": {
      width: "60px",
      height: "60px",
      borderRadius: "5px",
      objectFit: "cover",
    },
    "& > p:first-of-type": {
      textOverflow: "ellipsis",
      inlineSize: "200px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      mt: "8px",
    },
    "& > p:last-of-type": {
      fontSize: "12px",
      color: "#9E9E9E",
    },
  },
  signOut: {
    "& > .MuiSvgIcon-root": {
      width: "17px",
      height: "17px",
      ml: "5px",
      color: "#5C5C5C",
    },
    "& > p": {
      fontSize: "14px",
      ml: "5px",
      color: "#231F20",
    },
  },
  addAccount: {
    "& .MuiSvgIcon-root": {
      width: "17px",
      height: "17px",
      ml: "5px",
      color: "#5C5C5C",
    },
    "& > p": {
      fontSize: "14px",
      ml: "10px",
      color: "#231F20",
    },
  },
  fabButton: {
    backgroundColor: "white",
    border: "2px solid #8C499C",
    color: "#8C499C",
    zIndex: 0,
  },
};

export const useInfluencerSyncSocialDataStyle = {
  menu: {
    "& .MuiPaper-root": {
      width: "240px",
      borderRadius: "12px",
      mt: "5px",
      border: "1px solid #8C499C",
    },
  },
  profileBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    p: "10px",
    "& > .MuiSvgIcon-root, .css-1cfuqz3-MuiAvatar-root": {
      width: "60px",
      height: "60px",
      borderRadius: "5px",
      objectFit: "cover",
    },
    "& > p:first-of-type": {
      textOverflow: "ellipsis",
      inlineSize: "200px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      mt: "8px",
    },
    "& > p:last-of-type": {
      fontSize: "12px",
      color: "#9E9E9E",
    },
  },
  signOut: {
    "& > .MuiSvgIcon-root": {
      width: "17px",
      height: "17px",
      ml: "5px",
      color: "#5C5C5C",
    },
    "& > p": {
      fontSize: "14px",
      ml: "5px",
      color: "#231F20",
    },
  },
  addAccount: {
    "& .MuiSvgIcon-root": {
      width: "17px",
      height: "17px",
      ml: "5px",
      color: "#5C5C5C",
    },
    "& > p": {
      fontSize: "14px",
      ml: "10px",
      color: "#231F20",
    },
  },
  fabButton: {
    backgroundColor: "white",
    border: "2px solid #8C499C",
    color: "#8C499C",
    zIndex: 0,
  },
};

export const useInfluencerSocialHeaderStyle = {
  boxHeader: {
    position: "relative",
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    bgcolor: "#fff",
    borderRadius: { xs: "8px", sm: "0px 0px 20px 20px" },
    boxShadow: "0px 4px 10px 0px #3535350D",
    py: 4,
    px: 6,
  },
  boxCategory: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  boxImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  badge: {
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },
  avatar: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  stackDetail: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    columnGap: "10px",
    color: "#9E9E9E",
    "& .MuiSvgIcon-root": {
      fontSize: "4px",
    },
  },
  boxStackDetail: {
    display: "inline-flex",
    alignItems: "center",
    color: "#616161",
  },
  iconInStack: {
    marginRight: "6px",
    width: "16px",
    height: "16px",
  },
  stackGender: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "4px",
    },
  },
  itemsCategory: {
    fontSize: "14px",
    fontWeight: 500,
    height: "28px",
    backgroundColor: "#EBDFFF",
    color: "#8C499C",
    px: "10px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
  },
  iconAvatar: {
    width: "22px",
    height: "22px",
  },
  textEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  contentCon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: { xs: "center", sm: "flex-start" },
    mt: { xs: "12px", sm: "0" },
    ml: { xs: "0", sm: "24px" },
  },
};

export const useInfluencerSocialInfoStyle = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    bgcolor: { sm: "#fff" },
    borderRadius: "20px",
    boxShadow: { sm: "0px 4px 10px 0px #3535350D" },
    py: { sm: 4 },
    px: { sm: 6 },
    columnGap: "30px",
    rowGap: { xs: "16px", sm: "30px" },
  },
};

export const useInfluencerSocialDetailStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  boxVideo: {
    widht: "100%",
    borderRadius: "8px",
    "& > video": {
      objectFit: "cover",
      width: "100%",
      borderRadius: "8px",
    },
  },

  // Information Overview
  boxOverviewMain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "92px",
    borderRadius: "10px",
    mt: 3,
    border: "1px solid #E0E0E0",
  },

  overviewBox: {
    display: "flex",
    height: "70px",
    width: "100%",
  },

  overviewStack: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },

  textOverviewTimeline: {
    color: "#ED1968",
    fontWeight: "bold",
  },

  // Audience
  audienceBox: { width: "100%", px: 6, py: 3 },
  audienceTabs: {
    minHeight: "fit-content",
    backgroundColor: hPurple[100],
    borderRadius: "45px",
  },

  audiencePaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    mt: 3,
    boxShadow: 0,
  },

  // Post
  boxImages: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },

  boxVideos: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "140px",
    borderRadius: "8px",
  },

  boxItemsVideo: {
    widht: "100%",
    height: "140px",
    borderRadius: "8px",
    "& > video": {
      objectFit: "cover",
      width: "100%",
      height: "140px",
      borderRadius: "8px",
    },
  },

  boxDetailVideo: {
    position: "absolute",
    bottom: "0px",
    width: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(60,60,60,0), rgba(60,60,60,1))",
    borderRadius: "0 0 8px 8px",
    color: "#fff",
  },

  imageBox: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  videoBox: {
    borderRadius: "8px",
    overflow: "hidden",
    position: "relative",
    height: "140px",
  },
  videoBoxItem: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    bgcolor: "rgba(0,0,0,0.02)",
  },
  videoItem: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "57px",
    background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
    display: "flex",
    alignItems: "center",
  },

  valuesBox: {
    width: "100%",
    display: "flex",
    justifyContent: ["center", "space-around", "center"],
    color: "common.white",
    paddingBottom: "1px",
    "& div:last-of-type": {
      display: ["flex", "none", "flex"],
    },
  },

  IconsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      pr: 1,
      pb: 1,
    },
  },

  icon: {
    fontSize: "18px",
    marginRight: "2px",
  },

  // Screen shot
  socialMediaScreenshotStack: {
    border: "2px solid",
    borderColor: hGrey[100],
    borderRadius: "10px",
    pt: 4,
    pb: 2,
    width: "100%",
    height: "100%",
    mt: 3,
  },

  socialMediaScreenshotAvatar: {
    height: "44px",
    width: "44px",
  },
  // Carousel
  box: {
    marginTop: "16px",
  },

  carouselItemBox: {
    display: "flex",
    flexDirection: "column",
    width: "inherit",
    paddingBottom: "52px",
  },

  background: {
    height: "100%",
    backgroundColor: "#EBDFFF",
  },

  cardContent: {
    height: ["130px", "160px", "160px", "160px"],
    width: "90%",
    boxShadow: "3",
  },

  textEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  boxReplies: {
    display: ["none", "flex"],
    justifyContent: "start",
    marginTop: "12px",
  },

  stack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },

  textBold: {
    margin: "0 12px",
    fontWeight: "bold",
  },

  textItemsSocialMediaScreenshot: {
    display: "flex",
    alignItems: "center",
    color: "#00000",
  },

  captionInstagram: {
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    WebkitLineClamp: "5",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },

  // Information Average
  boxAverage: {
    borderRadius: "10px",
    py: 4,
    border: "1px solid #E0E0E0",
  },
  averageIcon: {
    fontSize: "28px",
    color: hPurple[200],
  },
  boxStep: {
    display: "flex",
    alignItems: "center",
  },

  buttonStep: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    cursor: "pointer",
    "&: hover": {
      backgroundColor: hGrey[100],
    },
  },
};

export const useRateCardStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  rateCardListBox: {
    border: { sm: "1px solid #E0E0E0" },
    borderRadius: "12px",
    p: { sm: "16px" },
    mt: { xs: "8px", sm: "3px" },
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
};

export const useSocialPostStyle = {
  popularPostCon: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  popularPostSlideCon: {
    cursor: "pointer",
    "& .MuiGrid-root": { maxWidth: "initial" },
  },
  popularPostVdo: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    objectFit: "cover",
  },
  popularPostImg: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  popularPostAvg: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
    height: "50px",
    alignItems: "end",
    py: "5px",
    borderRadius: "0 0 8px 8px",
  },
  recentPostCon: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  recentPostSlideCon: {
    cursor: "pointer",
    "& .MuiGrid-root": { maxWidth: "initial" },
  },
  recentPostVdo: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    objectFit: "cover",
  },
  recentPostImg: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  recentPostAvg: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
    height: "50px",
    alignItems: "end",
    py: "5px",
    borderRadius: "0 0 8px 8px",
  },
};

export const useUpdateInfluencerRateCardStyle = {
  dialog: {
    "& .css-1glcjf-MuiPaper-root-MuiDialog-paper": {
      boxShadow: "none",
      overflowY: "initial",
    },
    "& .css-bjtku8": {
      boxShadow: "none",
      overflowY: "initial",
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderBottom: "1px solid #EBDFFF",
    height: "30px",
    bgcolor: "#8C499C",
    "& p:first-of-type": {
      fontSize: "20px",
      fontWeight: "500",
      color: "#8C499C",
      mb: "4px",
    },
    "& p:last-of-type": {
      color: "#5C5C5C",
      fontSize: "14px",
    },
  },
  closeBtn: {
    position: "absolute",
    right: "-18px",
    top: "-18px",
    "& > svg": {
      bgcolor: "#fff",
      borderRadius: "50%",
      fill: "black",
      fontSize: "30px",
    },
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #8C499C",
    borderRadius: "8px",
    boxShadow: " 0px 4px 10px rgba(53, 53, 53, 0.05)",
    p: "16px",
    mt: "20px",
  },
  formBox: {
    maxHeight: {
      xs: "55vh",
      xl: "none",
    },
    overflowY: {
      xs: "scroll",
      xl: "auto",
    },
    my: "10px",
  },
  profile: {
    width: "48px",
    height: "48px",
    borderRadius: "5px",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "300px",
      border: "none",
      fontSize: "16px",
      "&:hover": { border: "none" },
    },
  },
};

export const useInstagramProfessionalStyle = {
  // Information
  container: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "440px",
    width: "100%",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
  },
  goBack: {
    cursor: "pointer",
    position: "absolute",
    top: "20px",
    left: "20px",
  },
  syncSocial: {
    cursor: "pointer",
    textAlign: "center",
  },
  buttonSync: {
    width: "270px",
    backgroundColor: "#8C499C",
    color: "white",
    borderColor: "#8C499C",
    "&:hover": {
      color: "#8C499C",
    },
  },
  defaultButton: {
    width: "270px",
  },
  buttonSyncInstagram: {
    maxHeight: "47px",
    minHeight: "47px",
    color: "#fff",
    width: ["300px", "350px"],
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    bgcolor: "#1A77F1",
    "&:hover": {
      bgcolor: "#1A77F1",
    },
  },
};

export const useDefaultStyle = {
  // Official
  stackContainerOfficial: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "440px",
    width: "100%",
  },
  syncSocial: {
    cursor: "pointer",
    textAlign: "center",
  },
  syncSocialButton: {
    maxHeight: "42px",
    minHeight: "42px",
    bgcolor: "#1877F2",
    color: "#fff",
    width: "270px",
    "& img": { width: "22px", height: "22px" },
    "&:hover": {
      bgcolor: "#1877F2",
    },
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  instagramProfileAvatar: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  instagramBadge: {
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },
  // Unofficial
  container: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
    p: "20px 20px 0 20px",
  },
  card: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    width: "100%",
  },
  Account: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    "& .MuiAvatar-root": {
      width: ["50px", "70px", "80px"],
      height: ["50px", "70px", "80px"],
    },
    "& p": {
      margin: ["15px", "20px"],
      fontSize: ["16px", "20px"],
      fontWeight: 550,
    },
  },
  boxUrl: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    width: "100%",
  },
  fillURLBox: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    width: "100%",
    "& .fillUrlTitle": {
      margin: ["12px auto 10px 20px", "12px auto 16px 20px"],
      fontSize: ["14px", "16px"],
    },
  },
  fillURL: {
    display: ["block", "flex"],
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 20px",
    "& p": {
      fontSize: ["12px", "14px"],
      width: ["100%", "15%"],
      height: "50%",
      marginBottom: ["10px", 0],
      alignSelf: "center",
      "& span": {
        color: "red",
      },
    },
    "& .MuiFormControl-root": {
      width: ["100%", "60%"],
      height: "40px",
    },
  },
  fillTitokInput: {
    width: "480px",
    marginLeft: "24px",
    height: "40px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${hPurple[200]}`,
      " &:hover": {
        border: `1px solid ${hPurple[200]}`,
      },
    },
  },
  verifyCodeToBioBox: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    width: "100%",
    "& .verifyCodeToBioTitle": {
      margin: "20px 0 16px 20px",
      fontSize: ["14px", "16px"],
    },
  },
  verifyCodeToBio: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "16px auto",
    "& p": {
      fontSize: ["14px", "16px"],
      fontWeight: 500,
    },
    "& img": {
      width: ["250px", "400px", "525px"],
      height: ["130px", "200px", "350px"],
      margin: "12px auto",
      border: `1px solid ${hGrey[200]}`,
      borderRadius: "8px",
    },
  },
  verifyCodeBox: {
    border: `1px solid ${hGrey[200]}`,
    borderRadius: "12px",
    margin: "20px 0",
    "& .verifyCodeTitle": {
      margin: "15px 0 16px 20px",
      fontSize: ["14px", "16px"],
    },
  },
  verifyCode: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "10px auto",
    "& .verifyCode": {
      fontSize: "16px",
      fontWeight: 500,
    },
    "& div": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& button": {
        border: "none",
        margin: "10px auto",
        width: ["200px", "300px"],
        height: ["35px", "42px"],
        fontSize: ["12px", "16px"],
        " &:hover": { border: "none" },
      },
      "& p": {
        color: "#5C5C5C",
        fontSize: {
          xs: "12px",
          sm: "14px",
        },
        fontWeight: 400,
        width: "80%",
        textAlign: "center",
        margin: "10px auto",
      },
    },
  },
  buttonVerifyCode: {
    display: "flex",
    justifyContent: "center",
    width: {
      xs: "250px",
      sm: "320px",
    },
    height: {
      xs: "55px",
      sm: "64px",
    },
    margin: "12px auto",
    alignItems: "center",
    position: "relative",
    borderRadius: "8px",
    background: hPurple[100],
    "& p:first-child": {
      color: hPurple[300],
      fontSize: ["20px", "24px"],
    },
  },
  coppyVerifyCode: {
    right: "26px",
    position: "absolute",
    cursor: "pointer",
    " &:hover": {
      color: "#616161",
    },
    width: {
      xs: "20px",
      sm: "40px",
    },
  },
  verifyAccount: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    width: "100%",
    margin: ["20px 0", "20px 0"],
    "& p": {
      margin: "20px 5px 0 20px",
      fontSize: ["14px", "16px"],
    },
    "& button": {
      backgroundColor: hBlack[100],
      display: "flex",
      justifyContent: "center",
      margin: ["25px auto", "32px auto"],
      width: ["200px", "300px"],
      height: "42px",
      border: "none",
      " &:hover": { border: "none" },
    },
  },
  buttonSyncSocial: {
    maxHeight: "47px",
    minHeight: "47px",
    color: "#fff",
    width: ["300px", "350px"],
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  twitterLogo: {
    fill: "#1D9BF0",
    width: ["50px", "70px", "80px"],
    height: ["50px", "70px", "80px"],
  },

  platformLogo: {
    width: ["50px", "70px", "80px"],
    height: ["50px", "70px", "80px"],
  },
};

export const useSyncTiktokStyle = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "440px",
    width: "100%",
    bgcolor: "#fff",
    borderRadius: "0px 0px 20px 20px",
    boxShadow: "0px 4px 10px 0px #3535350D",
  },
  syncSocial: {
    cursor: "pointer",
    textAlign: "center",
  },
  buttonSyncSocial: {
    maxHeight: "47px",
    minHeight: "47px",
    color: "#fff",
    width: ["300px", "350px"],
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    bgcolor: hSocialColor.tiktok,
    "&:hover": {
      bgcolor: hSocialColor.tiktok,
    },
  },
  platformLogo: {
    width: ["50px", "70px", "80px"],
    height: ["50px", "70px", "80px"],
  },
};

export const useSocialScreenshortStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    bgcolor: { xs: "#fff", sm: "initial" },
    p: { xs: "12px 16px 16px 16px", sm: 0 },
    borderRadius: "8px",
    boxShadow: { xs: "0px 4px 10px 0px #3535350D", sm: "initial" },
  },
  contentBox: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    p: "24px",
  },
  tiktokSlides: {
    position: "relative",
    height: { xs: "500px", xl: "700px" },
    mt: "12px",
  },
  tiktokAvg: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "10px",
  },
  tiktokDesc: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    color: "#fff",
  },
  att1: {
    width: "100%",
    borderRadius: "4px",
  },
  desInSocialPost:{
    bgcolor:"#EBDFFF",
    width:"90%",
    py:"100px",
    px:"10px",
    borderRadius:"10px",
    "& > p":{
      display:"-webkit-box",
      textAlign:'center',
      maxHeight:"70px",
      overflow:"hidden",
      textOverflow:"ellipsis",
      WebkitBoxOrient:'vertical',
      WebkitLineClamp:3
    }
  },
  totalAvg: {
    display: "flex",
    alignItems: "center",
    rowGap: "10px",
    mt: "12px",
    columnGap: "12px",
  },
};
