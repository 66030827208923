import { UseFormReturn } from "react-hook-form";
// mui
import { IEmployerJobInfo, IJobForInflueInfoRes } from "@api/job/type";
import { IAttachments, IExternalFile, IIsApproved, ILocation } from "@api/app-information/type";
export interface IInitMyJobsJobTabForm {
  delivery_confirmation?: {
    date_of_receipt: string;
    attachment: IAttachments[];
    note: string;
  };
  published_link?: string;
  product_return?:{
    shipping_type: string;
    tracking_no: string;
    note: string;
  };
}

export type IInfluencerJobStatus = "listing" | "listing" | "working" | "waiting_for_approve" | "approved" | "published" | "published" | "rejected" |  "offering" | "first_draft" | "canceled" | "completed" | "";

export interface IEditImgStatus extends IAttachments {
  viewImgStatus: boolean;
}

export interface IRejectedImages extends IAttachments {
  index:number;
}

export interface IMultiImageInputProps {
  error?: any;
  icon?: string;
  keyName: string;
  setValue: Function;
  [any: string]: any;
  data: IFileUpload[];
  getValues?: Function;
}
export interface IMuitiInputVideoProps {
  error?: any;
  keyName: string;
  setValue: Function;
  [any: string]: any;
  data: IFileUpload[];
}

export interface IFileItems {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate: string;
  webkitRelativePath: string;
}

export interface IFileUpload {
  file_id: string;
  file_name: string;
  file_type: string;
  url: string;
  destination: string;
  purpose: string;
  is_approve:boolean;
}

export interface IShippingProductSmProps {
  handleShippingProduct: () => void;
}

export interface ISubmitDraftSuccessDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}
export interface IUploadUrlDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  urls: IExternalFile[];
  setValue: Function;
}

export interface IShippingProductDialogLgProps {
  openShippingProduct: boolean;
  setOpenShippingProduct: (val: boolean) => void;
  jobEmployerDetail?: IEmployerJobInfo | null;
  jobInfluencerDetail?: IJobForInflueInfoRes | null
}

export interface IDraftPostImagesProps {
  form:UseFormReturn<IInitJobDraftPostForm>;
  editDarft: boolean;
  handleEditDraft: (value: boolean) => void;
}

export interface IShippingProductDialogProps {
  openShippingProduct: boolean;
  setOpenShippingProduct: (val: boolean) => void;
  jobEmployerDetail?: IEmployerJobInfo | null;
  jobInfluencerDetail?: IJobForInflueInfoRes | null
}

export interface IPlaceDetailDialogProps {
  openPlace: boolean;
  setOpenPlace: (val:boolean) => void;
  place?: ILocation;
}

export interface IMyJobTabProps {
  isProductReturn?: boolean;
  isConfirmDelivery?: boolean;
  jobsInfo:IJobForInflueInfoRes | null
}

export interface IInitConfirmGoodsReceivedForm {
  delivery_confirmation: {
    date_of_receipt: string;
    attachment: IAttachments[];
    note: string;
  };
}

export interface IInitJobDraftPostForm {
  job_id: string;
  influencer_id: string;
  employer_id: string;
  is_draft: boolean;
  description: {
    description: string;
    is_approve: IIsApproved;
    note: string;
  };
  attachments: IAttachments[];
  external_file?: IExternalFile[]
}
export interface IInitJobRejectedForm {
  job_id: string;
  influencer_id: string;
  employer_id: string;
  is_draft: boolean;
  description: {
    description: string;
    is_approve: IIsApproved;
    note: string;
  };
  attachments: IAttachments[];
  external_file?: IExternalFile[];
  comment?:string
}

export interface IJobWorkingCaptionProps {
  jobInfInfo: IJobForInflueInfoRes | null;
  form: UseFormReturn<IInitJobDraftPostForm>;
  isDraft: boolean;
}

export interface IJobWorkingDraftPostprops {
  videos?: IAttachments[];
  images?: IAttachments[];
  urls?: IExternalFile[]
  form: UseFormReturn<IInitJobDraftPostForm>;
  isDraft: boolean;
}

export interface IPublishedLinkProps {
  form: UseFormReturn<IInitMyJobsJobTabForm>;
}

export interface IProductReturnProps {
  form: UseFormReturn<IInitMyJobsJobTabForm>;
}

export interface IJobOthersImagesProps {
  attachments?: IAttachments[];
}

export interface IJobOthersVideosProps {
  attachments?: IAttachments[];
  urls?: IExternalFile[];
}

export interface IJobRejectedImages {
  form: UseFormReturn<IInitJobRejectedForm>;
  isEditJob: boolean;
  isDraft: boolean;
  newAttList: IAttachments[];
  attRejectedList: IAttachments[];
}

export interface IJobRejectedVideos {
  form: UseFormReturn<IInitJobRejectedForm>;
  isEditJob: boolean;
  isDraft: boolean;
  newAttList: IAttachments[];
  attRejectedList: IAttachments[];
  newUrlList: IExternalFile[];
  urlRejectedList: IExternalFile[];
}

export const shippingTypeOption = [
  {
    id: 0,
    value: "thai post",
    label: "ไปรษณีไทย",
    img: require(`@assets/images/tarcking/thai-post.jpeg`),
  },
  {
    id: 1,
    value: "kerry express",
    label: "Kerry Express",
    img: require(`@assets/images/tarcking/kerry.jpeg`),
  },
  {
    id: 2,
    value: "scg express",
    label: "SCG express",
    img: require(`@assets/images/tarcking/rscg.jpeg`),
  },
  {
    id: 3,
    value: "flash",
    label: "Flash",
    img: require(`@assets/images/tarcking/rflash.jpeg`),
  },
  {
    id: 4,
    value: "j&t express",
    label: "J&T express",
    img: require(`@assets/images/tarcking/rj&t.jpeg`),
  },
  {
    id: 5,
    value: "speed-d",
    label: "Speed-D",
    img: require(`@assets/images/tarcking/speed-d.jpeg`),
  },
  {
    id: 6,
    value: "nim express",
    label: "Nim Express",
    img: require(`@assets/images/tarcking/rnim.jpeg`),
  },
  {
    id: 7,
    value: "ninja van",
    label: "Ninja Van",
    img: require(`@assets/images/tarcking/ninjavan.jpeg`),
  },
  {
    id: 8,
    value: "dhl",
    label: "DHL",
    img: require(`@assets/images/tarcking/rdhl.png`),
  },
  {
    id: 9,
    value: "grab",
    label: "Grab",
    img: require(`@assets/images/tarcking/grab.png`),
  },
  {
    id: 10,
    value: "line man",
    label: "Line Man",
    img: require(`@assets/images/tarcking/lineman.png`),
  },
  {
    id: 11,
    value: "lalamove",
    label: "Lala Move",
    img: require(`@assets/images/tarcking/lineman.png`),
  },
  {
    id: 12,
    value: "other",
    label: "Othder",
    img: "",
  },
];