// mui and style
import { Grid, Stack, Typography } from "@mui/material";
import { useHomeInfluencerStyle as classes } from "./HomeInfluencer.style";
// component
import { GridMobileReverse } from "@global/grid-mobile-reverse";
// type and util
import { IBreakpoint } from "@views/global/global.types";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";
// image
import Commu1 from "@assets/images/home/influencer-page/influencer-community/01.png";
import Commu2 from "@assets/images/home/influencer-page/influencer-community/02.png";
import Commu3 from "@assets/images/home/influencer-page/influencer-community/03.png";
import Commu4 from "@assets/images/home/influencer-page/influencer-community/04.png";

export function InfluencerCommunity({
  breakpoint,
}: {
  breakpoint: IBreakpoint;
}) {
  return (
    <Stack
      alignItems="center"
      height="100%"
      py={[10, 15, 15, 21]}
      px={[1, 2, 3, 9, 15]}
    >
      <Typography
        color="secondary"
        variant={setValueByBreakpoint(breakpoint, "h5", null, null, "h3")}
        fontWeight={setValueByBreakpoint(
          breakpoint,
          "bold",
          null,
          null,
          "normal"
        )}
        align="center"
        mb={[5, 4, 4, 7]}
      >
        Influencer Community
      </Typography>
      <Typography
        color="grey.700"
        variant={setValueByBreakpoint(breakpoint, "body1", null, null, "h5")}
        align="center"
        mb={[14, 8, 8, 12]}
      >
        คอมมูนิตี้ Influencer ของคนรุ่นใหม่ ที่ให้คุณมากกว่า ‘รายได้’ และ
        ‘ชื่อเสียง’
        <br />
        อีกทั้ง ยังช่วยเพิ่มศักยภาพของคุณ พัฒนาทักษะด้านต่างๆ
        ทำงานได้อย่างมีความสุข
        <br />
        พร้อมเหล่าเพื่อนร่วมวงการมากกว่าครึ่ง!
      </Typography>
      <GridMobileReverse breakpoint={breakpoint}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(breakpoint, "center", "start")}
        >
          <img
            src={Commu1}
            alt="Commu1"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "70%",
                "90%",
                "auto",
                "auto"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "360px",
                "400px"
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
              sx={classes.navyBlueColor}
            >
              How to Use
            </Typography>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[3, 4, 4, 6]}
            >
              ขั้นตอนการใช้งาน HashU
            </Typography>
            <Typography
              color="common.black"
              variant={setValueByBreakpoint(
                breakpoint,
                "body1",
                null,
                null,
                "h6"
              )}
            >
              เพียง 3 ขั้นตอนเข้าสู่การใช้งานระบบ HashU ง่ายๆ
              <br />
              สะดวก สามารถใช้งานผ่านเว็บไซต์ หรือสมาร์ทโฟนได้ทุกเครื่อง
              <br />
              ไม่ว่าจะทำอะไร อยู่ที่ไหน ก็สามารถเข้าใช้งานได้ทันที
            </Typography>
          </Stack>
        </Grid>
      </GridMobileReverse>
      <GridMobileReverse breakpoint={breakpoint} isMobileRevers>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
              sx={classes.navyBlueColor}
            >
              HashU Privilege
            </Typography>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[3, 4, 4, 6]}
            >
              สิทธิพิเศษของ Influencer’s HashU
            </Typography>
            <Typography
              color="common.black"
              variant={setValueByBreakpoint(
                breakpoint,
                "body1",
                null,
                null,
                "h6"
              )}
            >
              ด้วยสังคมเหนือระดับ คอมมูนิตี้ออนไลน์แห่งใหม่
              <br />
              กับความพิเศษเหนือใคร ไม่ว่าจะเป็นการรับงานได้อย่างมากมาย
              <br />
              แบบไม่มีข้อผูกมัด คลาสเรียนเพิ่มทักษะ
              <br />
              ทำงานร่วมกับแบรนด์ชั้นนำทั้งใน และต่างประเทศ
              <br />
              พร้อมการดูแลสุดพิเศษจากเรา
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(
            breakpoint,
            "center",
            "flex-end"
          )}
        >
          <img
            src={Commu2}
            alt="Commu2"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "100%",
                "100%",
                "100%",
                "100%",
                "auto"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "auto",
                "auto",
                "400px"
              ),
            }}
          />
        </Grid>
      </GridMobileReverse>
      <GridMobileReverse breakpoint={breakpoint}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(breakpoint, "center", "start")}
        >
          <img
            src={Commu3}
            alt="Commu3"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "90%",
                "100%",
                "100%",
                "100%",
                "auto"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "auto",
                "auto",
                "400px"
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
              sx={classes.navyBlueColor}
            >
              Skill Development for Influencer
            </Typography>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[3, 4, 4, 6]}
            >
              ทักษะเจ๋งๆ ที่ชาว Influencer ต้องมี!
            </Typography>
            <Typography
              color="common.black"
              variant={setValueByBreakpoint(
                breakpoint,
                "body1",
                null,
                null,
                "h6"
              )}
            >
              Up Skill ขึ้นไปอีกขั้น ให้คุณได้เป็น Influencer สุดเจ๋ง
              <br />
              ที่มีทักษะสุดคูล สารพัดด้วยความรู้สาระดี ๆ
              <br />
              จากผู้เชี่ยวชาญมืออาชีพ ผ่านการบอกเล่าด้วยประสบการณ์จริง!
            </Typography>
          </Stack>
        </Grid>
      </GridMobileReverse>
      <GridMobileReverse breakpoint={breakpoint} isMobileRevers>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
        >
          <Stack>
            <Typography
              variant={setValueByBreakpoint(
                breakpoint,
                "body2",
                null,
                null,
                "h6"
              )}
              fontWeight="bold"
              mb={[1, 2, 2, 5]}
              sx={classes.navyBlueColor}
            >
              HashU Information
            </Typography>
            <Typography
              color="secondary"
              variant={setValueByBreakpoint(breakpoint, "h6", null, null, "h4")}
              fontWeight="bold"
              mb={[3, 4, 4, 6]}
            >
              ข่าวสารกิจกรรม
            </Typography>
            <Typography
              color="common.black"
              variant={setValueByBreakpoint(
                breakpoint,
                "body1",
                null,
                null,
                "h6"
              )}
            >
              อัปเดตทุกเรื่องได้ก่อนใคร ทั้งสาระข่าวสาร
              <br />
              และกิจกรรมสุดสนุกจาก HashU ให้คุณสนุกได้
              <br />
              ทุกวันแบบไม่มีเบื่อ!
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          alignItems="center"
          justifyContent={setValueByBreakpoint(
            breakpoint,
            "center",
            "flex-end"
          )}
        >
          <img
            src={Commu4}
            alt="Commu4"
            style={{
              width: setValueByBreakpoint(
                breakpoint,
                "100%",
                "100%",
                "100%",
                "100%",
                "auto"
              ),
              height: setValueByBreakpoint(
                breakpoint,
                "auto",
                "auto",
                "auto",
                "auto",
                "400px"
              ),
            }}
          />
        </Grid>
      </GridMobileReverse>
    </Stack>
  );
}
