import { useEffect, useState } from "react";
// mui and style
import { Box, Card, Grid, Link, Typography } from "@mui/material";
import { useTrackStatusStyles as classes } from "./TackStatus.style";
// component
import CardPrivate from "@components/campaign/campaign-private/CardPrivate";
import SelectingMore from "@components/campaign/campaign-private/status/SelectingMore";
import DrawerPrivateInfluencerSelected from "@components/campaign/campaign-private/DrawerPrivateInfluencerSelected";
// type and api
import {
  IPrivateItemRes,
  IPrivateCampaignParams,
  ICampaignPrivateItemRes,
} from "@api/job/type";
import { JobAPI } from "@api/global";
// icon and route
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useHistory, useLocation, useParams } from "react-router-dom";
// redux
import {
  setCheckSelectedPrivate,
  selecteCheckSelectPrivate,
} from "@slices/CampaignPrivate.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// other
import { scrollToTop } from "@utils/helpers/scrollToTop";
import Loading from "@components/global/loading/Loading";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

const initInfluencerListParams = {
  page: 1,
  item_per_page: 20,
};

export default function SelectingMoreInfluencer() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const checkSelected = useAppSelector(selecteCheckSelectPrivate);

  const { session_token } = getTokenFromStorage()

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const [openInfluencerSelected, setOpenInfluencerSelected] = useState(false);
  const [privateInfluencerList, setPrivateInfluencerList] = useState<ICampaignPrivateItemRes>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAPI(employerId, campaignId, initInfluencerListParams);
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId, campaignId]);

  const toggleInfluSelectedDrawers = (open: boolean) => {
    setOpenInfluencerSelected(open);
  };

  const getAPI = async (
    employerId: string,
    campaignId: string,
    params: IPrivateCampaignParams
  ) => {
    try {
      const response = await JobAPI.getInfluencerPrivate(employerId, campaignId, params);
      const newResponse = response;

      if (
        checkSelected.employerId === employerId &&
        checkSelected.campaignId === campaignId
      ) {
        response.item.forEach((item: IPrivateItemRes) => {
          const findIndex = checkSelected.item.findIndex((find) => {
            return find.influId === item.influencer_id;
          });
          if (findIndex !== -1) {
            newResponse.item[findIndex].status =
              checkSelected.item[findIndex].status;
          }
        });
      }

      setPrivateInfluencerList(response);

      dispatch(
        setCheckSelectedPrivate({
          employerId,
          campaignId: campaignId,
          item: newResponse.item.map((item: IPrivateItemRes) => {
            return {
              influId: item.influencer_id,
              status: item.status,
            };
          }),
        })
      );
    } catch (err) {
      return err;
    }
  };

  const handleClick = (id: string) => {
    if (!privateInfluencerList) return;

    const findIndex = privateInfluencerList.item.findIndex(
      (find) => find.influencer_id === id
    );

    if (findIndex === -1) return;

    const newPrivate = privateInfluencerList;

    setPrivateInfluencerList((prev) => {
      if (!prev) return;
      prev.item[findIndex].status =
        prev.item[findIndex].status === "selecting_more"
          ? ""
          : "selecting_more";
      return {
        ...prev,
      };
    });

    dispatch(
      setCheckSelectedPrivate({
        ...checkSelected,
        item: newPrivate.item.map((item) => ({
          influId: item.influencer_id,
          status: item.status,
        })),
      })
    );
  };

  const goViewInfluencer = (influencerId: string, item: IPrivateItemRes) => {
    history.push(
      `/influencers/${influencerId}/profile?platform=${item.platform}&c_type=selecting-more`
    );
  };

  const workingList = privateInfluencerList?.item.filter(
    (filter) => filter.status === "working"
  );

  const selectedList = privateInfluencerList?.item.filter(
    (filter) => filter.status === "selecting_more"
  );

  const clearInfluencer = () => {
    toggleInfluSelectedDrawers(false);
    setIsLoading(true);
    if (!privateInfluencerList) return;

    const clearStatus = privateInfluencerList.item.map((item) => {
      if (item.status === "selecting_more") {
        return { ...item, status: "" };
      }
      return item;
    });

    setPrivateInfluencerList({
      ...privateInfluencerList,
      item: clearStatus,
    });

    const clearInRedux = checkSelected.item.map((item) => {
      if (item.status === "selecting_more") {
        return { ...item, status: "" };
      }
      return item;
    });

    dispatch(
      setCheckSelectedPrivate({
        ...checkSelected,
        item: clearInRedux,
      })
    );

    setIsLoading(false);
  };

  const selectPrivateOptional = async () => {
    toggleInfluSelectedDrawers(false);
    setIsLoading(true);

    const reqBody = selectedList?.map((item: any) => {
      return {
        social_account_id: item.id,
        status: "",
      };
    });

    try {
      await JobAPI.insertOptionalJobPrivateCampaign(
        employerId,
        campaignId,
        reqBody
      );

      const changeStatusRedux = checkSelected.item.map((item) => {
        if (item.status === "selecting_more") {
          return { ...item, status: "reserved" };
        }
        return item;
      });

      dispatch(
        setCheckSelectedPrivate({
          ...checkSelected,
          item: changeStatusRedux,
        })
      );

      history.push(
        `/campaigns/${campaignId}/track-proposal-status?emp_id=${employerId}`
      );
    } finally {
      setIsLoading(false);
    }
  };
  
  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Link
        onClick={() => history.goBack()}
        underline="none"
        sx={classes.goBack}
      >
        <KeyboardArrowLeftIcon />
        <span>Back</span>
      </Link>

      <div>
        <Typography variant="h5">Selecting More Influencers</Typography>
        <Typography variant="subtitle1">เพิ่มรายชื่ออินฟลูเอนเซอร์</Typography>
      </div>

      <Card sx={classes.card}>
        <div>
          <Typography variant="h5">Influencer List</Typography>
          <Typography variant="subtitle1">รายชื่ออินฟลูเอนเซอร์</Typography>
        </div>
        <Box sx={classes.boxCardInflu}>
          <SelectingMore
            item={privateInfluencerList}
            toggleDrawers={toggleInfluSelectedDrawers}
            privateSelected={selectedList}
            workingList={workingList}
          />
        </Box>

        <Grid container spacing={4}>
          {privateInfluencerList?.item
            .filter(
              (filter) =>
                !(
                  filter.status === "working" ||
                  filter.status === "offering" ||
                  filter.status === "canceled" ||
                  filter.status === "stealth" ||
                  filter.status === "reserved"
                )
            )
            .map((item) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={2.4}
                  xl={2}
                  key={item.influencer_id}
                >
                  <CardPrivate
                    item={item}
                    handleClick={handleClick}
                    goViewInfluencer={goViewInfluencer}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Card>

      <DrawerPrivateInfluencerSelected
        openInfluencerSelected={openInfluencerSelected}
        toggleDrawers={toggleInfluSelectedDrawers}
        privateSelected={selectedList || []}
        handleClick={handleClick}
        clearInfluencer={clearInfluencer}
        selectPrivateOptional={selectPrivateOptional}
      />
    </div>
  );
}
