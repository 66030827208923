export const useEarningsTabStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
    borderTop: "1px solid #8C499C",
  },
  boxDetail: {
    border: "1px solid #ECECEC",
    borderRadius: "12px",
    mb: "24px",
  },
};

export const useEarningListStyle = {
  boxStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 20px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  boxIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#893DBD",
    width: "52px",
    height: "52px",
    bgcolor: "#F5F6F8",
    borderRadius: "50%",
  },
  boxHistiroy: {
    my: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  exportBtn: {
    width: "120px",
    backgroundColor: "green",
    color: "#fff",
    ":hover": {
      border: "2px solid green",
      color: "green",
    },
  },
  importBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    width: "120px",
    backgroundColor: "orange",
    color: "#fff",
    border: "2px solid #fff",
    cursor: "pointer",
    ":hover": {
      border: "2px solid orange",
      backgroundColor: "#fff",
      color: "orange",
    },
  },
  dissabledImportBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    width: "120px",
    backgroundColor: "#9E9E9E",
    color: "#fff",
    border: "2px solid #fff",
  },
  search: {
    width: "480px",
    display: "flex",
    border: "1px solid #8C499C",
    height: "40px",
    boxShadow: "none",
  },
  pagination: {
    mt: "20px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
  pending: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFEEDF",
    color: "#FB8C00",
    borderRadius: "50px",
    p: "3px 10px",
  },
  paid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#DBFFDC",
    color: "#4CAF50",
    borderRadius: "50px",
    p: "3px 10px",
  },
  button: {
    width: "140px",
    backgroundColor: "#8C499C",
    color: "#fff",
    ":hover": {
      border: "2px solid #8C499C",
      color: "#8C499C",
    },
  },
  noEarning: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    bgcolor: "#fff",
    borderRadius: "16px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
    py: "40px",
  },
  textNoEarning: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
  taxButton: {
    width: "140px",
    backgroundColor: "#E8008A",
    color: "#fff",
    ":hover": {
      border: "2px solid #E8008A",
      color: "#E8008A",
    },
  },
};

export const useBankAccountStyle = {
  boxImageId: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    width: "150px",
    height: "150px",
    "& img": {
      width: "150px",
      height: "150px",
      objectFit: "cover",
      borderRadius: "8px",
    },
  },
}