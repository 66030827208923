import * as React from "react";
// mui and icon
import {
  Box,
  Icon,
  Step,
  styled,
  Divider,
  Stepper,
  StepLabel,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "2px dashed #8C499C",
      backgroundColor: "#fff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#8C499C",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; lastComplete?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#F5F6F8",
  zIndex: 1,
  color: "#8C499C",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#8C499C",
  }),
  ...(ownerState.completed && {
    border: "1px solid #8C499C",
    backgroundColor: ownerState.lastComplete ? "red" : "#8C499C",
    color: "#fff",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Icon className="ico-hu-campaign-overview" />,
    2: <Icon className="ico-hu-group" />,
    3: <Icon className="ico-hu-my-job" />,
    4: <Icon className="ico-hu-send" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function PrivateStep(props: { activeStep?: number }) {
  const { activeStep } = props;

  const steps = [
    {
      label: "Verifying Campaign Details",
      subLabel: "ตรวจสอบรายละเอียดของแคมแปญ",
    },
    {
      label: "Building the Dream Team",
      subLabel: "เลือกอินฟลูเอนเซอร์เข้าร่วมแคมเปญ",
    },
    {
      label: "Organizing the List",
      subLabel: "จัดลำดับรายชื่อ",
    },
    {
      label: "Offering to Influencers",
      subLabel: "ยื่นข้อเสนอให้แก่อินฟลูเอนเซอร์",
    },
  ];

  return (
    <Box sx={{ width: "100%", mt: "20px", mb: "30px" }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep || 1}
        connector={<ColorlibConnector />}
        sx={{ mb: "35px" }}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Box>
                <Typography variant="title1">{step.label}</Typography>
                <Typography>{step.subLabel}</Typography>
              </Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider />
    </Box>
  );
}
