import { useEffect, useState } from "react";
// mui and icon
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "./CampaignPrivate.style";
// type
import { IPrivateItemRes } from "@api/job/type";
import { IPrivateSummary } from "./PrivateCampaign.type";
// component
import PrivateStep from "../campaign-detail/PrivateStep";
import CardInfluencerPrivate from "./CardInfluencerPrivate";
import InfluencerListStatus from "./status/InfluencerListStatus";
// route
import { useHistory, useLocation, useParams } from "react-router-dom";
// redux
import {
  insertJobPrivateCampaign,
  getInfluencerPrivateCampaign,
  selectInfluencerPrivateCampaign,
} from "@slices/CampaignPrivate.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
// Data
import { initPrivateSummary } from "./PrivateCampaignData";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function PrivateIndex() {
  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";

  const { campaignId } = useParams<{ campaignId: string }>();

  const dispatch = useAppDispatch();
  const influencerPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const jobLimit = campaignInfo?.job_limit;

  const [privateSelected, setPrivateSelected] = useState<IPrivateItemRes[]>([]);
  const [privateSummary, setPrivateSummary] = useState<IPrivateSummary>(initPrivateSummary);

  useEffect(() => {
    const filterData = influencerPrivateInfo?.item?.filter((item) => {
      return item.status === "stealth" || item.status === "reserved";
    });
    setPrivateSelected(filterData || []);
  }, [influencerPrivateInfo?.item]);

  useEffect(() => {
    const params = {
      employerId: employerId,
      campaignId: campaignId,
      params: {
        page: 1,
        item_per_page: 20,
      },
    };
    dispatch(getInfluencerPrivateCampaign(params));
  }, [dispatch, employerId, campaignId]);

  useEffect(() => {
    const followers = privateSelected?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.follower)),
      0
    );
    const likes = privateSelected?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.avg_like)),
      0
    );
    const comments = privateSelected?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.avg_comment)),
      0
    );
    const shares = privateSelected?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.avg_share)),
      0
    );
    const budgets = privateSelected?.reduce(
      (sum: any, item: any) => (sum = sum + Number(item.rate_card)),
      0
    );
    setPrivateSummary({
      followers: followers,
      likes: likes,
      comments: comments,
      shares: shares,
      budgets: budgets,
      privateSelected: privateSelected.length,
    });
  }, [privateSelected]);

  const goOrganizing = () => {
    if (role === "employer") {
      history.push(`/campaigns/${campaignId}/info?st=private-organizing`);
    } else {
      history.push(`/campaigns/${campaignId}/info?st=private-organizing&emp_id=${employerId}`);
    }
  };

  const insertJobPrivateCampaignWithAPI = async (data: IPrivateItemRes[]) => {
    const newData = data.map((item) => {
      return {
        social_account_id: item.id,
        status: "reserved",
      };
    });
    if (jobLimit && newData.length >= jobLimit) {
      for (let i = 0; i < jobLimit; i++) {
        newData[i].status = "stealth";
      }
    }
    const req = {
      employerId: employerId,
      campaignId: campaignId,
      body: newData,
    };
    try {
      dispatch(insertJobPrivateCampaign(req));
      goOrganizing();
    } catch (error: any) {
      return error;
    }
  };

  const goViewInfluencer = (influId: string, item: IPrivateItemRes) => {
    history.push(`/influencers/${influId}/profile?platform=${item.platform}`);
  };

  return (
    <Card sx={{ padding: "24px" }}>
      <PrivateStep activeStep={2} />

      <Typography variant="h5">Influencer List</Typography>
      <Typography variant="subtitle1">รายชื่ออินฟลูเอนเซอร์</Typography>

      <Box sx={{ marginTop: "24px" }}>
        <InfluencerListStatus
          privateSummary={privateSummary}
          jobLimit={Number(jobLimit)}
          totalBudget={Number(influencerPrivateInfo?.total_budget)}
          inserJob={insertJobPrivateCampaignWithAPI}
          privateSelected={privateSelected}
        />
      </Box>

      <Box sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            m: "32px 0 24px 15px",
          }}
        >
          <Typography variant="h5" sx={{ mr: "16px" }}>
            รายชื่ออินฟลูเอนเซอร์
          </Typography>
        </Box>

        <Grid container spacing={5}>
          {influencerPrivateInfo?.item?.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                lg={2.4}
                xl={2}
                key={item.influencer_id}
              >
                <CardInfluencerPrivate
                  item={item}
                  privateSelected={privateSelected}
                  setPrivateSelected={setPrivateSelected}
                  handleViewInfluencer={goViewInfluencer}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Divider sx={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => history.goBack()} sx={classes.backButton}>
          Back
        </Button>
        <Button
          onClick={() => {
            insertJobPrivateCampaignWithAPI(privateSelected);
          }}
          disabled={
            typeof jobLimit !== "undefined" &&
            privateSummary.privateSelected >= jobLimit
              ? false
              : true
          }
          sx={classes.nextButton}
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}
