import React, { useState } from "react";
// mui and icons
import {
  Box,
  Dialog,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
  DialogContent,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// style and type
import { useMyJobstabsItemSmStyle as classes } from "../MyJobs.style";
import { IMyJobstabsItemSmProps } from "../MyJobs.type";
// other
import { useLocation } from "react-router-dom";

export default function MyJobstabsItemSm(props: IMyJobstabsItemSmProps) {
  const {
    myJobsTabs,
    selectStatusTab,
    myJobsTabsStatus,
    setHandleOpenFilter,
    handleSelectJobStatus,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status") || "";

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={classes.container}>
      <Box onClick={handleClickOpen} sx={classes.selectJobTabStatus}>
        <Typography variant="body1">
          {`${selectStatusTab.text} ${
            selectStatusTab.total === 0 ? "" : `(${selectStatusTab.total})`
          }`}
        </Typography>
        <ArrowDropDownIcon />
      </Box>
      <Divider sx={classes.divider} orientation="vertical" flexItem />
      <Box
        sx={classes.selectJobTabTitle}
        onClick={() => setHandleOpenFilter(true)}
      >
        <MenuIcon sx={{ fontSize: "16px" }} />
        <Typography ml={2}>Filters</Typography>
      </Box>
      <Dialog
        open={openDialog}
        onClick={handleClose}
        fullWidth={mdScreen}
        sx={classes.dialogCon}
      >
        <DialogContent>
          {myJobsTabs
            .slice(0, myJobsTabs.length - 3)
            .concat(myJobsTabsStatus)
            .map((item, index) => (
              <Box
                key={index}
                onClick={() => handleSelectJobStatus(item)}
                sx={{
                  ...classes.dialogMenu,
                  bgcolor: status === item.key ? item.bgColor : "#fff",
                  color: status === item.key ? item.color : undefined,
                }}
              >
                <Typography>
                  {`${item.text} ${item.total === 0 ? "" : `(${item.total})`}`}
                </Typography>
              </Box>
            ))}
          {myJobsTabs.slice(4, myJobsTabs.length).map((item, index) => (
            <React.Fragment key={index}>
              {item.key === "canceled" && <Divider sx={{ my: "10px" }} />}
              <Box
                onClick={() => handleSelectJobStatus(item)}
                sx={{
                  ...classes.dialogMenuCanceled,
                  bgcolor: status === item.key ? item.bgColor : "#fff",
                  color: status === item.key ? item.color : undefined,
                }}
              >
                <Typography>
                  {`${item.text} ${item.total === 0 ? "" : `(${item.total})`}`}
                </Typography>
              </Box>
            </React.Fragment>
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
