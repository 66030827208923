import { useEffect, useState } from "react";
// mui, icon and style
import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEditBankAccountForMobileStyle as classes } from "../../MyProfile.style";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BankAccountFormSchema } from "@components/influencer-profile/InfluencerProfile.shema";
// reudx
import {
  selectInfluencerProfile,
  getInfluencerProfileAsync,
  selectInfluencerProfileOnload,
  updateInfluencerBankAccountAsync,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getInfluencerSocialAsync } from "@slices/InfluencerSocial.slice";
import { getBankListAsync, selectBankListOnload } from "@slices/BankList.slice";
// route and type
import { useHistory } from "react-router-dom";
import { IBankAccountForm } from "@components/influencer-profile/InfluencerProfile.type";
// other
import Loading from "@components/global/loading/Loading";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import { initBankAccountForm } from "@components/influencer-profile/influencer-default/BankAccount";
import EditInfluencerBankAccount from "@components/influencer-profile/edit-influencer-profile/EditBankAccount";

export default function EditBankAccountForMobile() {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const influencerLoading = useAppSelector(selectInfluencerProfileOnload);
  const bankListLoading = useAppSelector(selectBankListOnload);
  const infBankInfo = influencerInfo?.bank_account;

  const form = useForm<IBankAccountForm>({
    resolver: yupResolver(BankAccountFormSchema),
    defaultValues: initBankAccountForm,
  });

  useEffect(() => {
    dispatch(getInfluencerProfileAsync({}));
    dispatch(getInfluencerSocialAsync({}));
    dispatch(getBankListAsync());
  },[dispatch])

  useEffect(() => {
    if (!infBankInfo) return;
    const data = {
      account_name: infBankInfo.account_name,
      account_number: infBankInfo.account_number,
      bank: infBankInfo.bank,
      account_type: infBankInfo.account_type,
      tax_id: infBankInfo.tax_id,
    };
    form.reset(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infBankInfo]);

  const onUpdateBankAccount = (value: IBankAccountForm) => {
    setIsLoading(true);
    if (!value.bank) return;
    let body = {
      bank_account: {
        account_name: value.account_name,
        account_number: value.account_number,
        bank_id: value.bank.id,
        tax_id: value.tax_id.split("-").join(""),
        account_type: value.account_type,
      },
    };

    dispatch(updateInfluencerBankAccountAsync(body))
      .then(async () => {
        setIsLoading(false);
        await SwalCustom.fire({
          icon: "success",
          title: "Updated Bank account success",
          showConfirmButton: false,
          timer: 1600,
        });
        history.push("/my-profile/mobile");
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
          timer: 1600,
        });
      });
  };

  if (isLoading || influencerLoading || bankListLoading) return <Loading />

  return (
    <div className="container-main">
      <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt="-10px">
        <KeyboardArrowLeftIcon
          onClick={() => history.goBack()}
          sx={{ position: "absolute", left: 0 }}
        />
        <Typography variant="h6">Address</Typography>
      </Box>
      <Box sx={classes.content}>
        <EditInfluencerBankAccount form={form} onSubmit={onUpdateBankAccount} />
      </Box>
    </div>
  );
}
