// mui
import { Box, Icon } from "@mui/material";

export interface IItemsGender {
  itemsGender: string;
}

export default function ItemsGender(props: IItemsGender) {
  const { itemsGender } = props;
  return (
    <>
      {itemsGender === "male" ? (
        <Icon className="ico-hu-male" sx={{ fontSize: "40px" }} />
      ) : itemsGender === "female" ? (
        <Icon className="ico-hu-female" sx={{ fontSize: "40px" }} />
      ) : itemsGender === "lgbtq" ? (
        <Icon className="ico-hu-lgbt" sx={{ fontSize: "40px" }} />
      ) : itemsGender === "other" ? (
        <Box></Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
}
