// mui and icon
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// type and style
import { IInfluencerTiersProps } from "../../OverviewTab.type";
import { ITiersDisItem as IValue } from "@api/dashboard/type";
import { useInfluencerTiersStyle as classes } from "../../OverviewTab.style";
// others
import { colorMatchPlatform } from "./TypeOfInfluencerStats";

export const colorMatchTier = (val?: string) => {
  if (val === "mega") {
    return "#4A489D";
  } else if (val === "macro") {
    return "#542485";
  } else if (val === "micro") {
    return "#FB79C3";
  } else if (val === "nano") {
    return "#ED1968";
  } else {
    return "#893DBD";
  }
};

export const createData = (
  label?: IValue,
  tot?: IValue,
  fb?: IValue,
  ig?: IValue,
  tw?: IValue,
  ttk?: IValue,
  yt?: IValue
) => {
  const total = tot?.value;
  const facebook = fb?.value;
  const instagram = ig?.value;
  const twitter = tw?.value;
  const tiktok = ttk?.value;
  const youtube = yt?.value;
  const color = colorMatchTier(label?.label);
  return {
    label,
    total,
    facebook,
    instagram,
    twitter,
    tiktok,
    youtube,
    color,
  };
};

export default function InfluencerTiers(props: IInfluencerTiersProps) {
  const { tiersDist } = props;

  const total = tiersDist?.total || [];
  const fb = tiersDist?.facebook || [];
  const ig = tiersDist?.instagram || [];
  const tw = tiersDist?.twitter || [];
  const ttk = tiersDist?.tiktok || [];
  const yt = tiersDist?.youtube || [];

  const infTierList = [...total.map((t) => t)].map((label, i) =>
    createData(label, total?.[i], fb?.[i], ig?.[i], tw?.[i], ttk?.[i], yt?.[i])
  );

  const numberFormat = (val?: { number: number; percent: number }) => {
    if (val) {
      const number = val.number === 0 ? 0 : Intl.NumberFormat("en").format(val.number);
      return `${number || 0} (${val.percent.toFixed(2)}%)`;
    }
  };

  return (
    <Box>
      <Typography variant="title1">
        Influencer Tiers Across Platforms
      </Typography>
      <TableContainer sx={classes.tableCon} component={Paper}>
        <Table sx={{ minWidth: "300px" }}>
          <TableHead>
            <TableRow sx={classes.tableRow}>
              {[
                "",
                "total",
                "facebook",
                "instagram",
                "twitter",
                "tiktok",
                "youtube",
              ].map((row, index) => (
                <TableCell
                  key={index}
                  align="right"
                  sx={{ bgcolor: "#F5F6F8" }}
                >
                  <Typography
                    variant="title2"
                    sx={{ textTransform: "capitalize" }}
                    color={colorMatchPlatform(row)}
                  >
                    {row}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={classes.tableBody}>
            {infTierList.map((row, index) => (
              <TableRow key={index} sx={classes.tBodyRow}>
                <TableCell align="left">
                  <Box display="flex" alignItems="center">
                    {row.label?.label !== "total" && (
                      <CircleIcon sx={{ fontSize: "14px", color: row.color }} />
                    )}
                    <Typography
                      variant="title1"
                      sx={{ textTransform: "capitalize", ml: "10px" }}
                    >
                      {row.label?.label?.replace(/_/gi, "-")}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="title1">
                    {numberFormat(row.total)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#1A77F1">
                    {numberFormat(row.facebook)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#893DBD">
                    {numberFormat(row.instagram)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#1D9BF0">
                    {numberFormat(row.twitter)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{numberFormat(row.tiktok)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#FF0000">
                    {numberFormat(row.youtube)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
