import { useState } from "react";
// mui and style
import {
  Box,
  Icon,
  Stack,
  Dialog,
  Divider,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useInfluencerPortfolioStyle as classes } from "../InfluencerProfile.style";
// api and type
import { InfluencerAPI } from "@api/global";
import { IInfluencerPortfolio } from "@api/influencer/type";
import { IMyPortfolioForm } from "../InfluencerProfile.type";
// hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MyPortfolioFormSchema } from "../InfluencerProfile.shema";
// redux
import {
  setInfluencerProfile,
  selectInfluencerProfile,
  setDeleteInfluencerPortfolio,
} from "@slices/InfluencerProfile.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
// other
import nl2br from "react-nl2br";
import { useHistory } from "react-router-dom";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import EditInfluencerPortfolio from "../edit-influencer-profile/EditPortfolio";

export const initMyPortfolioForm: IMyPortfolioForm = {
  id: "",
  title: "",
  url: "",
  description: "",
};

export default function InfluencerPortfolio() {
  const [openToUpdateDialog, setOpenToUpdateDialog] = useState(false);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const history = useHistory();
  const dispatch = useAppDispatch();
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const portfolioInfo: IMyPortfolioForm[] = influencerInfo?.portfolio;

  const form = useForm<IMyPortfolioForm>({
    resolver: yupResolver(MyPortfolioFormSchema),
    defaultValues: initMyPortfolioForm,
  });

  const onOpenToEditPortfolio = (port: IMyPortfolioForm) => {
    if (!port) return;
    if (xsScreen) {
      history.push(`/my-profile/mobile/edit-portfolio?id=${port.id}`);
    } else {
      form.reset(port);
      setOpenToUpdateDialog(true);
    }
  };

  const onOpenToUpsertPortfolio = () => {
    if (xsScreen) {
      history.push(`/my-profile/mobile/edit-portfolio`);
    } else {
      form.reset(initMyPortfolioForm);
      setOpenToUpdateDialog(true);
    }
  };

  const onDeletePortfolio = (portID: string) => {
    setOpenToUpdateDialog(false);
    SwalCustom.fire({
      title: "Are you sure?",
      text: "You want to delete this portfolio!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4CAF50",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await InfluencerAPI.deleteInfluencerPortfolio({ port_id:portID });
          SwalCustom.fire({
            icon: "success",
            title: "Delete portfolio success",
            showConfirmButton: false,
            timer: 1600,
          });
          dispatch(setDeleteInfluencerPortfolio(result));
          setOpenToUpdateDialog(false);
        } catch (error: unknown) {
          await SwalCustom.fire({
            icon: "error",
            title: "Opps !",
            text: "Error, There are something when wrong",
            showConfirmButton: true,
            timer: 1600,
          });
        }
      } else if (result.isDismissed) {
        setOpenToUpdateDialog(true);
      }
    });
  };

  const onUpdatePortfolio = (value: IMyPortfolioForm) => {
    const body = {
      portfolio: [value],
    };
    InfluencerAPI.updateInfluencerPortfolio(body)
      .then((response) => {
        SwalCustom.fire({
          icon: "success",
          title: "Updated portfolio success",
          showConfirmButton: false,
          timer: 1600,
        });
        dispatch(setInfluencerProfile(response));
        setOpenToUpdateDialog(false);
      })
      .catch(() => {
        SwalCustom.fire({
          icon: "error",
          title: "Opps !",
          text: "Error, There are something when wrong",
          showConfirmButton: true,
          timer: 1600,
        });
      });
  };

  return (
    <Box p="20px">
      <Box display="flex" justifyContent="space-between">
        <Box sx={classes.portfolioIconBox}>
          <Icon
            className="ico-hu-my-portfolio"
            color="primary"
            sx={{ fontSize: "18px" }}
          />
          <Typography variant="title1" ml={1}>
            My Portfolio
          </Typography>
        </Box>

        {(portfolioInfo || []).length <= 4 ? (
          <IconButton
            aria-label="save"
            color="primary"
            size="small"
            sx={classes.portfolioCardEditIcon}
            onClick={onOpenToUpsertPortfolio}
          >
            <Icon className="ico-hu-add" sx={{ fontSize: "15px" }} />
          </IconButton>
        ) : null}
      </Box>
      {(portfolioInfo || []).length === 0 ? (
        <Typography
          onClick={onOpenToUpsertPortfolio}
          variant="body1"
          mt="10px"
          color={"text.secondary"}
          sx={{ cursor: "pointer" }}
        >
          + Add Portfolio
        </Typography>
      ) : (
        portfolioInfo.map((port: IInfluencerPortfolio, index: number) => (
          <Box key={index} my={2}>
            <Box display="flex" justifyContent="space-between">
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                onClick={() => window.open(port.url)}
                width={{ xs: "80%", sm: "100%", md: "80%", lg: "100%" }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={classes.profileTitle}
                >
                  {port.title}
                </Typography>
                <Icon
                  className="ico-hu-open"
                  color="primary"
                  sx={{ fontSize: "15px" }}
                />
              </Stack>
              <Box>
                <IconButton
                  color="primary"
                  size="small"
                  sx={classes.portfolioCardEditIcon}
                  onClick={() => onOpenToEditPortfolio(port)}
                >
                  <Icon className="ico-hu-edit" sx={{ fontSize: "15px" }} />
                </IconButton>
              </Box>
            </Box>
            <Typography
              color="rgba(0, 0, 0, 0.54)"
              sx={classes.profileUrl}
              onClick={() => window.open(port.url)}
            >
              {port.url}
            </Typography>
            <Typography mb={2} sx={classes.prodileDesc}>
              {nl2br(port.description)}
            </Typography>
            {index === portfolioInfo.length - 1 ? null : <Divider />}
          </Box>
        ))
      )}

      {/* dialog */}
      <Dialog
        open={openToUpdateDialog}
        onClose={() => setOpenToUpdateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={classes.dialog}
      >
        <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
          <Typography variant="title1">My Portfolio</Typography>
          <IconButton onClick={() => setOpenToUpdateDialog(false)}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.contentDialog}>
          <EditInfluencerPortfolio
            form={form}
            onDeletePortfolio={onDeletePortfolio}
            onSubmit={onUpdatePortfolio}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
