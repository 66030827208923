export const useViewInfluencerReportStyle = {
  backBtn: {
    display: ["none", "flex"],
    marginBottom: "32px",
    "& > span": {
      display: "flex",
      cursor: "pointer",
      marginLeft: "-8px",
    },
  },
  postReportCon: {
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    padding: "24px",
  },
  postReportCardCon: {
    mt: 0.5,
    width: "100%",
    ml: 0,
  },
};
