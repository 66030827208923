import * as yup from "yup";

export const EditInfluencerProfileFormSchema = yup.object().shape({
  fullname: yup.string().required("Please enter your full name.").matches(/^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/, "Full name is not Valid"),
  birth_date: yup.string().nullable(),
  gender: yup.string().required("Please select your gender."),
  nationality: yup.string().nullable(),
  phone: yup.string().required("Please enter your phone.").matches(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/, "Phone number should be 10 digits"),
  line_id: yup.string().nullable(),
  description: yup.string().nullable(),
  images: yup.array().nullable()
});

export const MyPortfolioFormSchema = yup.object().shape({
  title: yup.string().required("Please enter title."),
  // eslint-disable-next-line no-useless-escape
  url: yup
    .string()
    .required("Please enter Past or type a link.")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Past or type a link is not valid"
    ),
  description: yup.string().max(60, "Description should be 60 characters"),
});

export const BankAccountFormSchema = yup.object().shape({
  account_name: yup
    .string()
    .required("Please enter account name.")
    .matches(
      /^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Account name is not valid"
    ),
  account_number: yup.string().required("Please enter account number."),
  bank: yup
    .object()
    .shape({
      id: yup.number(),
      value: yup.string(),
      name_en: yup.string(),
      name_th: yup.string(),
      logo_image_url: yup.string(),
    })
    .required("Please select data.")
    .nullable(),
  account_type: yup.string().nullable(),
  tax_id: yup
    .string()
    .required("Please enter tax id.")
    .matches(
      /^\(?([0-9]{1})\)?[-]?([0-9]{4})[-]?([0-9]{5})[-]?([0-9]{2})[-]?([0-9]{1})$/,
      "Tax ID should be 13 digits"
    ),
});

export const ShippingFormSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter Full Name.")
    .matches(
      /^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Full name is not valid"
    ),
  phone: yup
    .string()
    .required("Please enter Phone Number.")
    .matches(
      /^(\d{3}-\d{3}-\d{4}|\d{10})$/,
      "Phone Number should be 10 digits"
    ),
  province: yup
    .object()
    .shape({
      id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
      abbreviation_en: yup.string(),
      abbreviation_th: yup.string(),
    })
    .required("Please select Province.")
    .nullable(),
  district: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select District.")
    .nullable(),
  subdistrict: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      district_id: yup.number(),
      postcode: yup.string(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select Subdistrict.")
    .nullable(),
  postcode: yup.string().required("Please enter Zipcode."),
  address: yup.string().required("Please enter Address."),
});

export const BillingFormSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter Full Name.")
    .matches(
      /^([A-z\u0E01-\u0E5B].\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Full name is not valid"
    ),
  tax_id: yup
    .string()
    .required("Please enter Tax Id.")
    .matches(
      /^\(?([0-9]{1})\)?[-]?([0-9]{4})[-]?([0-9]{5})[-]?([0-9]{2})[-]?([0-9]{1})$/,
      "Tax ID should be 13 digits"
    ),
  province: yup
    .object()
    .shape({
      id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
      abbreviation_en: yup.string(),
      abbreviation_th: yup.string(),
    })
    .required("Please select Province.")
    .nullable(),
  district: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select District.")
    .nullable(),
  subdistrict: yup
    .object()
    .shape({
      id: yup.number(),
      province_id: yup.number(),
      district_id: yup.number(),
      postcode: yup.string(),
      name_en: yup.string(),
      name_th: yup.string(),
    })
    .required("Please select Subdistrict.")
    .nullable(),
  postcode: yup.string().required("Please enter Zipcode."),
  address: yup.string().required("Please enter Address."),
});