import {
  IGetRevenueParams,
  IGetOverviewParams,
  IGetInfluencerListParams,
  IGetDashboardCampaignParams,
} from "./type";
import { instance } from "@api/global/instance";
import { API_KEY, DASHBOARD_URL } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class DashboardAPIs {
  async getInfluencerList(params: IGetInfluencerListParams) {
    const { access_token } = getTokenFromStorage()
    
    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    }
    const { data } = await instance.get(`${DASHBOARD_URL}/influencers`, { headers, params})
    
    return data;
  }

  async getOverview(params?: IGetOverviewParams) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`${DASHBOARD_URL}/overview`, { headers, params });
    return data;
  }

  async getDashboardSummary() {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get("/dashboard/summary", { headers });
    return data.result.data;
  }

  async getRevenue(params:IGetRevenueParams) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    }
    const { data } = await instance.get("/dashboard/revenue", { headers, params });
    return data.result.data;
  }

  async getOrganization(params:{ page: number, item_per_page: number }) {
    const { access_token } = getTokenFromStorage()
    
    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get("/dashboard/organization", { headers, params });
    return data.result.data;
  }

  async getDashboardCampaign(params:IGetDashboardCampaignParams) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get("/dashboard/campaign", { headers, params });
    return data.result.data;
  }
}