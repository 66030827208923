import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Grid,
  Icon,
  Stack,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/Save";
// style and components
import { useJobRejectedStyle as classes } from "./JobRejected.style";
import SubmitDraftSuccessDialog from "../../job-dialog/SubmitDraftSuccessDialog";
import ReviewPostInfluencer from "../../job-default/ReviewPostInfluencer";
import ShippingProductLg from "../../job-dialog/ShippingProductDialog";
import AdditionalComment from "../../job-default/AdditionalComment";
import PlaceDetailDialog from "../../job-dialog/PlaceDetailDialog";
import Caption from "../../job-default/Caption";
import Images from "./job-draft-post/Images";
import Videos from "./job-draft-post/Videos";
// type and api
import {
  IInitJobRejectedForm,
  IInitJobDraftPostForm,
} from "../../JobsTab.type";
import { JobAPI } from "@api/global";
import { IMyJobTabProps } from "../../JobsTab.type";
import { IAttachments, IExternalFile } from "@api/app-information/type";
// redux
import { useDispatch } from "react-redux";
import { setInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// others
import { useForm } from "react-hook-form";
import Loading from "@global/loading/Loading";
import { useHistory, useParams } from "react-router-dom";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

const initJobRejectedForm: IInitJobRejectedForm = {
  job_id: "",
  influencer_id: "",
  employer_id: "",
  is_draft: true,
  description: {
    description: "",
    is_approve: "",
    note: "",
  },
  attachments: [],
  external_file: [],
  comment: "",
};

export default function JobRejected(props: IMyJobTabProps) {
  const { isProductReturn, jobsInfo } = props;

  const [isEditJob, setIsEditJob] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewPlaceDialog, setViewPlaceDialog] = useState(false);
  const [openShippingProduct, setOpenShippingProduct] = useState(false);
  const [openSubmitDraftSuccess, setOpenSubmitDraftSuccess] = useState(false);

  const form = useForm<IInitJobRejectedForm>({
    // resolver: yupResolver(jobRejectedFormSchema),
    defaultValues: initJobRejectedForm,
  });

  const { handleSubmit, reset, watch, setValue } = form;

  const history = useHistory();
  const { jobId } = useParams<{ jobId: string }>();

  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id || "";

  const dispatch = useDispatch();
  const attList = jobsInfo?.attachments || [];
  const urlList = jobsInfo?.external_file || [];
  const contentType = jobsInfo?.content_type;
  const isVideo = /(video)/.test(contentType?.type || "");
  const isTextOnly = /(text only)/.test(contentType?.title || "");
  const isPlace = jobsInfo?.campaign_info.review_type === "place";
  const isDraft = isEditJob ? !isEditJob : jobsInfo?.is_draft || false;

  const attRejectedList = attList.filter(
    (att) => att.is_approve === "not_approved" || att.is_approve === "drop"
  );
  const urlRejectedList = urlList.filter(
    (url) => url.is_approve === "not_approved" || url.is_approve === "drop"
  );
  const newAttList = attList.filter(
    (att) => att.is_approve !== "not_approved" && att.is_approve !== "drop"
  );
  const newUrlList = urlList.filter(
    (url) => url.is_approve !== "not_approved" && url.is_approve !== "drop"
  );

  useEffect(() => {
    const description = jobsInfo?.description;
    if (attList.length) setValue("attachments", newAttList);
    if (description) setValue("description", description);
    if (newUrlList.length) setValue("external_file", newUrlList);
    setValue("comment", jobsInfo?.comment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsInfo]);

  const onDraftJob = async (value: IInitJobDraftPostForm, isDraft: boolean) => {
    let newAttachment: IAttachments[] = [];
    let newUrls: IExternalFile[] = [];
    if (/(single photo)/.test(contentType?.title || "")) {
      const isAttApprove =
        attList.filter((att) => att.is_approve === "approved").length === 1;
      const attachments = value.attachments.map((att, index) => {
        return {
          ...att,
          order: index + 1,
          is_approve: isAttApprove ? "approved" : att.is_approve,
        };
      });
      newAttachment = attachments;
    } else {
      const attNotApproveList = attList.filter(
        (att) => att.is_approve === "not_approved"
      );
      const urlNotApproveList = urlList.filter(
        (url) => url.is_approve === "not_approved"
      );
      const attachments = value.attachments.map((att, index) => {
        return {
          ...att,
          order: index + 1,
          is_approve:
            !isDraft && attNotApproveList.length ? "new" : att.is_approve,
        };
      });
      const urls = value.external_file?.map((url, index) => {
        return {
          ...url,
          order: index + 1,
          is_approve:
            !isDraft && urlNotApproveList.length ? "new" : url.is_approve,
        };
      });
      newAttachment = attachments;
      newUrls = urls || [];
    }
    const descIsApprove = value.description.is_approve;
    const data = {
      ...value,
      job_id: jobId,
      influencer_id: influencerId,
      is_draft: isDraft,
      attachments: isDraft
        ? [...newAttachment, ...attRejectedList]
        : newAttachment,
      external_file: isDraft ? [...newUrls, ...urlRejectedList] : newUrls,
      description: {
        ...value.description,
        is_approve:
          !isDraft && descIsApprove !== "approved" ? "new" : descIsApprove,
        note: !isDraft ? "" : value.description.note,
      },
    };
    setIsLoading(true);
    await JobAPI.addInfluencerJobRecord(data)
      .then((response) => {
        setIsLoading(false);
        if (isDraft) {
          SwalCustom.fire({
            icon: "success",
            title: "Success !",
            text: "บันทึก Draft เรียบร้อย",
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "กลับไปหน้า My Jobs",
            showCancelButton: true,
            cancelButtonText: "Close",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/my-jobs");
            } else if (result.isDismissed) {
              setIsEditJob(false);
              dispatch(setInfluencerJobInfo(response));
              reset(response);
            }
          });
        } else {
          setOpenSubmitDraftSuccess(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${error.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: `Close`,
        });
      });
  };

  if (isLoading) return <Loading />

  return (
    <Box sx={classes.jobTabsCon}>
      <Box sx={classes.jobTitle}>
        <Box display={{ xs: "none", sm: "initial" }}>
          <Typography>Job</Typography>
          <Typography>กรอกข้อมูลเพื่อส่งงาน</Typography>
        </Box>
        {isPlace ? (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-location" />}
            sx={classes.shippingProBtn}
            onClick={() => setViewPlaceDialog(true)}
          >
            Location
          </Button>
        ) : isProductReturn ? (
          <Button
            variant="contained"
            startIcon={<Icon className="ico-hu-product-received" />}
            sx={classes.shippingProBtn}
            onClick={() => setOpenShippingProduct(true)}
          >
            Shipping Product
          </Button>
        ) : null}
      </Box>
      <Divider sx={classes.divider} />
      <Grid container spacing={[0, 5]}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={5}>
            {jobsInfo?.comment && (
              <Grid item xs={12}>
                <AdditionalComment comment={jobsInfo.comment} />
              </Grid>
            )}
            {!isTextOnly && (
              <Grid item xs={12}>
                {isVideo ? (
                  <Videos
                    form={form}
                    isEditJob={isEditJob}
                    isDraft={isDraft}
                    newAttList={newAttList}
                    attRejectedList={attRejectedList}
                    newUrlList={newUrlList}
                    urlRejectedList={urlRejectedList}
                  />
                ) : (
                  <Images
                    form={form}
                    isEditJob={isEditJob}
                    isDraft={isDraft}
                    newAttList={newAttList}
                    attRejectedList={attRejectedList}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Caption
                jobInfo={jobsInfo}
                register={form.register}
                isDraft={
                  isEditJob && jobsInfo?.description.is_approve !== "approved"
                }
              />
            </Grid>
            <Grid item xs={12}>
              {!isEditJob ? (
                <Stack
                  display={["flex", "none", "none", "flex"]}
                  direction="row"
                  spacing={5}
                  sx={classes.editSubmitBtn}
                >
                  <Button
                    variant="contained"
                    startIcon={<EditRoundedIcon />}
                    onClick={() => setIsEditJob(true)}
                    sx={{ bgcolor: "#fff" }}
                  >
                    แก้ไข
                  </Button>
                  {isDraft && (
                    <Button
                      variant="contained"
                      endIcon={<ArrowCircleRightRoundedIcon />}
                      onClick={() =>
                        handleSubmit((e: IInitJobDraftPostForm) =>
                          onDraftJob(e, false)
                        )()
                      }
                    >
                      ส่ง Draft ให้ผู้จ้างงาน
                    </Button>
                  )}
                </Stack>
              ) : (
                <Stack
                  display={["flex", "none", "none", "flex"]}
                  direction="row"
                  spacing={5}
                  sx={classes.draftBtn}
                >
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() =>
                      handleSubmit((e: IInitJobDraftPostForm) =>
                        onDraftJob(e, true)
                      )()
                    }
                  >
                    บันทึก Draft
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<ArrowCircleRightRoundedIcon />}
                    onClick={() =>
                      handleSubmit((e: IInitJobDraftPostForm) =>
                        onDraftJob(e, false)
                      )()
                    }
                    disabled={
                      watch("description.is_approve") === "new" &&
                      !watch("description.note") &&
                      !isTextOnly
                        ? true
                        : false
                    }
                  >
                    ส่ง Draft ให้ผู้จ้างงาน
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          mt={[0, -5, -5, 0]}
          display={["none", "initial"]}
        >
          <ReviewPostInfluencer
            jobDetailForInflu={jobsInfo}
            watch={watch}
            isEditDraft={isEditJob}
            fileType={jobsInfo?.content_type.type}
            attachments={isDraft ? newAttList : undefined}
          />
          {!isEditJob ? (
            <Stack
              display={["none", "flex", "flex", "none"]}
              direction="row"
              spacing={5}
              mt={5}
              sx={classes.submitBtn}
            >
              <Button
                variant="contained"
                startIcon={<EditRoundedIcon />}
                onClick={() => setIsEditJob(true)}
              >
                แก้ไข
              </Button>
            </Stack>
          ) : (
            <Stack
              display={["none", "flex", "flex", "none"]}
              direction="row"
              spacing={5}
              mt={5}
              sx={classes.draftBtn}
            >
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() =>
                  handleSubmit((e: IInitJobDraftPostForm) =>
                    onDraftJob(e, true)
                  )()
                }
              >
                บันทึก Draft
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowCircleRightRoundedIcon />}
                onClick={() =>
                  handleSubmit((e: IInitJobDraftPostForm) =>
                    onDraftJob(e, false)
                  )()
                }
              >
                ส่ง Draft ให้ผู้จ้างงาน
              </Button>
            </Stack>
          )}
        </Grid>
        <SubmitDraftSuccessDialog
          open={openSubmitDraftSuccess}
          onClose={setOpenSubmitDraftSuccess}
        />
      </Grid>
      <ShippingProductLg
        openShippingProduct={openShippingProduct}
        setOpenShippingProduct={setOpenShippingProduct}
        jobInfluencerDetail={jobsInfo}
      />
      <PlaceDetailDialog
        openPlace={viewPlaceDialog}
        setOpenPlace={setViewPlaceDialog}
        place={jobsInfo?.campaign_info.location}
      />
    </Box>
  );
}
