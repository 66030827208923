import {
  ICreateJobBody,
  IEmployerJobBody,
  IAddInfluJobRecord,
  IGetJobBoardParams,
  IPrivateCampaignParams,
  IEmployerJobListParams,
  IInfluencerActiveListParams,
} from "./type";
import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { IInfluencerJobParams } from "@views/my-jobs/MyJobs.type";
import { IInitMyJobsJobTabForm } from "@components/my-jobs/jobs-detail/jobs-tab/JobsTab.type";
import { IInitEmployerReviewPostForm } from "@views/employer-job/employer-review-post/ReviewPost";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class JobAPIs {
  async getInfluencerJobTimeline(jobId: string, influencerId: string ) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.get(`/jobs/${jobId}/influencers/${influencerId}/timeline`, { headers });
    return data.result.data;
  }

  async getCampaignInfluencerList(
    employerId: string,
    campaignId: string,
    params: IInfluencerActiveListParams
  ) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "X-API-Key": API_KEY,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/jobs/employers/${employerID}/${campaignId}/select`, { headers, params });
    return data.result.data;
  }

  async influencerListActive(employerId: string, campaignId: string, params: IInfluencerActiveListParams) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.patch(`/jobs/employers/${employerID}/${campaignId}/selecting`, null, { headers, params });
    return data.result.data;
  }

  async selectedInfluencer( employerId: string, campaignId: string, params: IInfluencerActiveListParams, body: any ) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.patch(`/jobs/employers/${employerID}/${campaignId}/job-selecting`, body, { headers, params });
    return data.result.data;
  }

  async getEmployerJobInfo(employerId: string, jobId: string ) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/jobs/employers/${employerID}/${jobId}`, { headers });
    return data.result.data;
  }

  async clearInfluenserSelected(employerId: string, campaignId: string, body: any) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.post(`/jobs/employers/${employerID}/${campaignId}/clear-job-selected`, body, { headers });
    return data.result.data;
  }

  async updateEmployerJobShipping(employerId: string, jobId: string, body: any) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.patch(`/jobs/employers/${employerID}`, body, { headers, params: { job_id: jobId } });
    return data.result.data;
  }

  async influencerSelectedToWorking(employerId: string, id: string, body: any) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.post(`/jobs/employers/${employerID}/${id}/select`, body, { headers });
    return data.result.data;
  }

  async getCandidateInfluencer(employerId: string, id: string, params: any) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/jobs/employers/${employerID}/${id}/select`, { headers, params });
    return data.result.data;
  }

  async createJob(body:ICreateJobBody) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const { data } = await instance.post(`/jobs`, body, { headers });
    return data.result.data;
  }

  async getInfluencerJobList(influencerId: string, params: IInfluencerJobParams) {
    const { access_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const { data } = await instance.get(`/jobs/influencers/${influencerId}/my-jobs`, { headers, params });
    return data.result.data;
  }

  async getEmployerJobList(employerId: string, campaignId: string, params: IEmployerJobListParams) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const employerID = employerId || session_token?.influencer_id;

    const { data } = await instance.get(`/jobs/employers/${employerID}/${campaignId}/my-jobs`, { headers, params });
    return data.result.data;
  }

  async getJobBoard(params: IGetJobBoardParams) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.get(`/jobs/${influencerID}/board`, { headers, params });
    return data.result.data;
  }

  async getInfluencerJobInfo(jobId: string, influencerId?: string) {
    const { access_token, session_token } = getTokenFromStorage()
    
    const headers = {
      Authorization: `Bearer ${access_token}`
    };

    const influecerID = influencerId || session_token?.influencer_id;

    const { data } = await instance.get(`/jobs/influencers/${influecerID}/${jobId}`, { headers });
    return data.result.data;
  }

  async updateJobStatus(jobId: string, body: { status: string }) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json" ,
    };
    const { data } = await instance.patch(`/jobs/${jobId}/status`, body, { headers });
    return data.result.data;
  }

  async updateInfluencerJob(jobId: string, body: IInitMyJobsJobTabForm) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json" ,
    };

    const influencerID = session_token?.influencer_id;

    const { data } = await instance.patch(`/jobs/influencers/${influencerID}`, body, { headers, params: { job_id: jobId } })
    return data.result.data;
  }

  async updateEmployerJob(employerId: string, jobId: string, body: IEmployerJobBody) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json" ,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.patch(`/jobs/employers/${employerID}`, body, { headers, params: { job_id: jobId } });
    return data.result.data;
  }

  async addEmployerJobRecord(body: IInitEmployerReviewPostForm) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json" ,
    };
    const { data } = await instance.post(`/jobs/employers/record`, body, { headers });
    return data.result.data;
  }

  async addInfluencerJobRecord(body: IAddInfluJobRecord) {
    const { access_token } = getTokenFromStorage()

    const headers = { 
      Authorization: `Bearer ${access_token}`, 
      "Content-Type": "application/json" ,
    };
    const { data } = await instance.post(`/jobs/influencers/record`, body, { headers });
    return data.result.data;
  }

  async insertOptionalJobPrivateCampaign(employerId: string, campaignId: string, body: any) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.post(`/jobs/employers/${employerID}/${campaignId}/select-private-optional`, body, { headers });
    return data.result.data;
  }

  async confirmJobPrivate(employerId: string, campaignId: string) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.patch(`/jobs/employers/${employerID}/${campaignId}/confirm-private`, null, { headers });
    return data.result.data;
  }

  async insertJobPrivateCampaign(employerId: string, campaignId: string, body: any ) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.post(`/jobs/employers/${employerID}/${campaignId}/select-private`, body, { headers });
    return data.result.data;
  }

  async getInfluencerPrivate(employerId: string, campaignId: string, params: IPrivateCampaignParams ) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/jobs/employers/${employerID}/${campaignId}/select-private`, { headers, params });
    return data.result.data;
  }

  async downloadShippingAddress(campaignId:string, employerId?:string) {
    const { access_token, session_token } = getTokenFromStorage()

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };

    const employerID = employerId || session_token?.employer_id;

    const { data } = await instance.get(`/jobs/employers/${employerID}/${campaignId}/shipping-address`, { headers });
    return data.result.data;
  }
}