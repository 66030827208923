// mui
import { Avatar, Icon } from "@mui/material";
// asset
import tiktok from "@assets/images/social/tiktok-only.png";
import youtube from "@assets/images/social/youtube-only.png";
import facebook from "@assets/images/social/facebook-only.png";
import twitter from "@assets/images/social/twitter_5969020.png";
import instagram from "@assets/images/social/instagram-only.png";

export function SocialIconSelected(props: {
  platform?: string;
  width?: string;
  height?: string;
  color?: string;
}) {
  const { platform, width, height, color } = props;

  const style = {
    width: width ? width : "20px",
    height: height ? height : "20px",
  };

  if (platform === "tiktok") {
    return color ? (
      <Icon className="ico-hu-tiktok" sx={{ color: color, fontSize: height }} />
    ) : (
      <Avatar sx={style} src={tiktok} />
    );
  } else if (platform === "twitter") {
    return color ? (
      <Icon
        className="ico-hu-twitter"
        sx={{ color: color, fontSize: height }}
      />
    ) : (
      <Avatar sx={style} src={twitter} />
    );
  } else if (platform === "facebook") {
    return color ? (
      <Icon
        className="ico-hu-facebook"
        sx={{ color: color, fontSize: height }}
      />
    ) : (
      <Avatar sx={style} src={facebook} />
    );
  } else if (platform === "instagram") {
    return color ? (
      <Icon
        className="ico-hu-instagram"
        sx={{ color: color, fontSize: height }}
      />
    ) : (
      <Avatar sx={style} src={instagram} />
    );
  } else {
    return color ? (
      <Icon
        className="ico-hu-youtube"
        sx={{ color: color, fontSize: height }}
      />
    ) : (
      <Avatar sx={style} src={youtube} />
    );
  }
}
