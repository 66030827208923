import { useEffect, useState } from "react";
// mui and component
import { Box, Icon, Typography } from "@mui/material";
import HeaderMember from "@components/team-management/member/HeaderMember";
import CampaignStatus from "@components/team-management/delete-team/CampaignStatus";
// api and type
import { TeamAPI } from "@api/global";
import { ITeamInfo } from "@api/team/type";
// other
import { useHistory } from "react-router-dom";
import Loading from "@components/global/loading/Loading";

export default function DeleteTeam() {
  const [teamInfo, setTeamInfo] = useState<ITeamInfo | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { goBack } = useHistory();

  useEffect(() => {
    setIsLoading(true)
    let isMounted = true

    TeamAPI.getOneTeam().then((response) => {
      if (isMounted) {
        setTeamInfo(response)
        setIsLoading(false)
      }
    }).catch(() => {
      if (isMounted) {
        setIsLoading(false)
      }
    })
  
    return () => {
      isMounted = false;
    };
  },[])

  if (isLoading) return <Loading />;

  return (
    <div className="container-main">
      <div>
        <Box
          display="inline-flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => goBack()}
        >
          <Icon className="ico-hu-chevron-left" fontSize="small" />
          <Typography variant="buttonS">Back</Typography>
        </Box>
        <Typography variant="h6" mt={2}>
          Delete Team
        </Typography>
      </div>
      <Box my="24px" mx={{ xs: "150px", xl: "250px" }}>
        <HeaderMember teamInfo={teamInfo} />
        <CampaignStatus teamInfo={teamInfo} />
      </Box>
    </div>
  );
}
