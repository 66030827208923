// mui and style
import { Box, Grid, Typography } from "@mui/material";
import { useBankAccountStyle as classes } from "./EarningTab.style"
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
// util
import { formatTaxId } from "@utils/helpers/formateNumber.helpers";

export default function BankAccount() {
  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const bankInfo = influencerInfo?.bank_account;

  return (
    <Grid container spacing={4} p="24px">
      <Grid item xs={4}>
        <Typography variant="title1">ชื่อบัญชีธนาคาร</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{bankInfo?.account_name || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">เลขที่บัญชีธนาคาร</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{bankInfo?.account_number || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">ธนาคาร</Typography>
      </Grid>
      <Grid item xs={8}>
        <Box display="flex">
          {bankInfo?.bank?.logo_image_url && (
            <img
              alt="bank-list"
              src={bankInfo?.bank?.logo_image_url}
              style={{
                width: "24px",
                height: "24px",
                marginRight: "4px",
              }}
            />
          )}
          <Typography>{bankInfo?.bank?.name_th || "-"}</Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">ประเภทบัญชี</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{bankInfo?.account_type || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">เลขประจำตัวผู้เสียภาษี</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {bankInfo?.tax_id ? formatTaxId(bankInfo?.tax_id || "") : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">สำเนาบัตรประชาชน</Typography>
      </Grid>
      <Grid item xs={8}>
        {bankInfo?.id_card_image_url ? (
          <Box sx={classes.boxImageId}>
            <img
              alt="personal-id"
              src={bankInfo?.id_card_image_url?.url}
            />
          </Box>
        ) : "-"}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="title1">สำเนาหน้าสมุดบัญชีธนาคาร</Typography>
      </Grid>
      <Grid item xs={8}>
        {bankInfo?.book_bank_image_url ? (
          <Box sx={classes.boxImageId}>
            <img
              alt="personal-id"
              src={bankInfo?.book_bank_image_url?.url}
            />
          </Box>
        ) : "-"}
      </Grid>
    </Grid>
  );
}
