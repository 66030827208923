import {
  ICreateTeamBody,
  IInvitationBody,
  ITeamProgressBody,
  IAddTeamMemberBody,
  IUpdateTeamBillingBody,
  ITeamActivityLogsParams,
} from "./type";
import { instance } from "@api/global/instance";
import { API_KEY } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class TeamAPIs {
  async updateTeam(teamId: string, body: any) {
    const { access_token } = getTokenFromStorage();
    
    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.patch(`/team/${teamId}`, body, { headers });
    return data.result.data;
  }

  async removeTeamMember(teamId: string, params:{ employer_id: string }) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.patch(`/team/${teamId}/remove`, null, { headers, params });
    return data.result.data;
  }

  async acceptAndRejectInvitation(body: IInvitationBody) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.patch(`/team/invitation`, body, { headers });
    return data.result.data;
  }

  async getInvitationList() {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/team/invitation`, { headers });
    return data.result.data;
  }

  async getTeamActivityLogs(teamId: string, params: ITeamActivityLogsParams) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/team/${teamId}/logs`, { headers, params });
    return data.result.data;
  }

  async addTeamMember(teamId: string, body: any) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.patch(`/team/${teamId}/add`, body, { headers });
    return data.result.data;
  }

  async updateTeamMemberPermission(teamId: string, body: any) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.patch(`/team/${teamId}/permission`, body, { headers });
    return data.result.data;
  }

  async getOneTeam() {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get("/team/findone", { headers });
    return data.result.data;
  }

  async insertTeam(body:ICreateTeamBody) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.post("/team", body, { headers });
    return data.result.data;
  }

  async getEmployerParameters(teamId: string) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/team/${teamId}/parameter`, { headers });
    return data.result.data;
  }

  async renewTeamPackage(body: { team_id: string, is_renew: boolean }) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.patch(`/team/renew`, body, { headers });
    return data.result.data;
  }

  async insertTeamMember(teamId: string, body: IAddTeamMemberBody) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`/team/${teamId}`, body, { headers });
    return data.result.data;
  }

  async updateTeamBilling(teamId: string, body: IUpdateTeamBillingBody) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.patch(`/team/${teamId}/billing`, body, { headers });
    return data.result.data;
  }

  async terminateTeam(teamId: string) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.delete(`/team/${teamId}/terminate`, { headers });
    return data.result.data;
  }

  async getTeamProgress(teamId: string) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.get(`/team/${teamId}/progress`, { headers });
    return data.result.data;
  }

  async updateTeamProgress(teamId: string, body: ITeamProgressBody) {
    const { access_token } = getTokenFromStorage();

    const headers = { 
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.patch(`/team/${teamId}/progress`, body, { headers });
    return data.result.data;
  }
}