export const HomeSupportStyle = {
  contentMain: {
    display: "flex",
    justifyContent: "center",
    padding: "50px 16px",
  },

  headerBackground: {
    height: ["400px", "350px", "350px", "400px", "400px"] as any,
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundImage:
      "linear-gradient(96.87deg, #F7DAE7 0%, #F1E0F2 25.69%, #F1E9F7 55.47%, #EDEAFF 100%)",
    zIndex: -1,
  },
};

export const useTutorialsStyle = {
  container: {
    m: {
      xs: "0 20px 20px 20px",
      sm: "0 40px 40px 40px",
      lg: "180px 125px 80px 120px",
    },
    border: "1px solid #f5f6f8",
  },

  goBack: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: {
      md: "45px",
    },
    height: {
      xs: "20px",
      md: "40px",
      lg: "initial",
    },
    mt: {
      xs: "16px",
      lg: 0,
    },
    color: {
      xs: "#fff",
      lg: "initial",
    },
    position: {
      xs: "absolute",
      md: "initial",
    },
    top: 10,
    left: {
      xs: 15,
      sm: 30,
    },
    "& > .MuiSvgIcon-root": {
      fontSize: { xs: "18px", md: "12px" },
    },
    "& > span": {
      display: { xs: "none", md: "initial" },
    },
  },

  tab: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "10px",
    // "& .css-u0u48i-MuiPaper-root-MuiAccordion-root": {
    //   boxShadow: "none",
    //   backgroundColor: "red",
    // },
  },
};
