// mui and icons
import { Box, Card, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarsIcon from "@mui/icons-material/Stars";
// style and type
import { useLeaderboardCardStyle as classes } from "../../Events.style";
import { ILeaderboardProps } from "../../Events.type";

export default function LeaderboardCard(props: ILeaderboardProps) {
  const { item } = props;

  return (
    <Card sx={classes.card}>
      <div>
        <Box sx={classes.profileBox}>
          <img src={item.profile_url} alt="profile" />
        </Box>
        <Typography fontWeight={500} textAlign="center" my="12px">
          {item.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={classes.ratingBox}>
            <StarsIcon color="primary" />
            <Typography mt="7px">{item.rating}</Typography>
          </Box>
          <Box sx={classes.ratingBox}>
            <StarIcon color="primary" />
            <Typography mt="7px">{item.point}</Typography>
          </Box>
        </Stack>
      </div>
    </Card>
  );
}
