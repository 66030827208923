export const useTrackStatusStyles = {
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },
  boxRemark: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    "& > p": {
      marginLeft: "12px",
    },
  },
  remark: {
    width: "18px",
    height: "18px",
    marginLeft: "12px",
  },
  card: { marginTop: "24px", padding: "24px" },
  boxCardInflu: { margin: "24px 0" },
  divider: { margin: "24px 0" },
  boxNoData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  boxTextNoData: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
};
