import React from "react";
// mui
import { Box, Avatar, Divider, Typography, Icon } from "@mui/material";
// icon and style
// import { Line, Phone, MailSolid } from "@assets/icons/social/social";
import { useInfluencerProfileInfoStyle as classes } from "../InfluencerProfile.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
// other
import nl2br from "react-nl2br";

export default function InfluencerProfileInfo() {
  const influencerInfo = useAppSelector(selectInfluencerProfile);

  const infProfileImage = (purpose: string, className: string) => {
    const image = (influencerInfo?.media?.images || []).find(
      (img) => img?.purpose === purpose
    );
    if (image) {
      return <Avatar src={image.url} className={className} />;
    } else {
      return <Avatar className={className} />;
    }
  };

  return (
    <Box m={{ xs: "20px 20px 12px 20px", lg: "20px" }}>
      <Box display={{ xs: "none", md: "initial" }}>
        <Box m="20px 0px 0 0px" position="relative">
          <Box sx={classes.imageBox}>
            {infProfileImage("profile_image", "profile")}
            <Box sx={classes.profileImg}>
              <Box m="20px">
                <Typography variant="h6" color="#fff">
                  {influencerInfo?.fullname}
                </Typography>
                <Box display="flex" columnGap="10px">
                  {[
                    `age : ${influencerInfo?.age}`,
                    influencerInfo?.gender,
                    influencerInfo?.nationality,
                  ].map((item, index) => (
                    <Typography
                      key={index}
                      variant="title2"
                      textTransform="capitalize"
                      color="#C2A8EF"
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.imageProfileItem}>
          {["frontal", "portrait", "headshot"].map((img, index) => (
            <Box key={index} sx={{ width: "100%" }}>
              {infProfileImage(`influencer_${img}_image`, "profile-item")}
            </Box>
          ))}
        </Box>
        {influencerInfo?.description && (
          <Box sx={classes.descriptionBox}>
            <Typography variant="body2">
              {nl2br(influencerInfo.description)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt="20px">
        <Typography variant="title1">Contact</Typography>
        <Box mt="10px">
          {[
            {
              label: "Phone Number",
              icon: <Icon className="ico-hu-phone" color="primary" />,
              value: influencerInfo?.phone,
            },
            {
              label: "Line ID",
              icon: <Icon className="ico-hu-line" color="primary" />,
              value: influencerInfo?.line_id,
            },
            {
              label: "Email",
              icon: <Icon className="ico-hu-mail" color="primary" />,
              value: influencerInfo?.email,
            },
          ].map((item, index) => (
            <React.Fragment key={index}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box ml={{ lg: "15px" }}>{item.icon}</Box>
                <Box ml="15px">
                  <Typography variant="title2" color="#616161">
                    {item.label} :
                  </Typography>
                  <Typography>{item.value}</Typography>
                </Box>
              </Box>
              {index !== 2 && <Divider sx={{ my: "12px" }} />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
