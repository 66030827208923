export const useVertifyEmail = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  backgroundLiner: {
    height: "320px",
    width: "100%",
    color: "red",
    background: "linear-gradient(to left top, #ED1968 -3.27%, #8C499C 102.18%)",
    position: "absolute",
  },
  dialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    zIndex: "tooltip",
    width: "1200px",
    height: "540px",
    borderRadius: "20px",
  },
  iconWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundColor: "#f5f6f8",
  },
  mailIcon: {
    fontSize: "64px",
    background: "linear-gradient(to left top, #ED1968 -3.27%, #8C499C 102.18%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  contentWrap: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "24px",
    justifyContent: "center",
    padding: "0 24px",
  },
  buttonWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "32px",
  },
  resendButton: {
    width: "360px",
    backgroundColor: "#8c499c",
    color: "#ffffff",
    "&:hover": {
      color: "#8c499c",
    },
  },
  iconFailedWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundColor: "#F44336",
  },
  mailIconFailed: {
    fontSize: "64px",
    background: "#FFFFFF",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  iconSuccessWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundColor: "#4CAF50",
  },
  mailIconSuccess: {
    fontSize: "64px",
    background: "#FFFFFF",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  ompageWrap: {
    textDecoration: "none",
    cursor: "pointer",
    " &:hover": {
      textDecoration: "underline",
    },
  },
  textDanger: {
    color: "red",
    marginTop: "12px",
  },
  textEmail: {
    marginTop: "16px",
    fontWeight: "600",
  },
};
