// mui
import { Box, Typography, InputAdornment, Icon, useTheme } from "@mui/material";
// type and style
import { IDateInputProps } from "./JobDefault.type";
import { useDateInputStyle as classes } from "./JobDefault.style";
// others
import TextFieldCustom from "@global/text-field";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export default function DateInput(props: IDateInputProps) {
  const {
    keyName,
    placeholder,
    minDate,
    errors,
    maxDate,
    form: { watch, setValue },
  } = props;

  const theme = useTheme();
  const xsScreen = theme.breakpoints.between("xs", "sm");

  const dateVal = new Date(watch(keyName as any));
  const date = dateVal.getTime() ? dateVal : null;
  const maxDateVal = maxDate ? new Date(maxDate) : undefined;

  return (
    <Box width="100%" sx={classes.container}>
      <Box className="datepicker-container">
        <DatePicker
          selected={date}
          onChange={(date) => setValue(keyName as any, date)}
          calendarClassName="datepicker-calendar-container"
          dateFormat="dd/MM/yyyy"
          minDate={minDate ? new Date(minDate) : undefined}
          startDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDateVal}
          monthsShown={xsScreen ? 1 : 2}
          customInput={
            <div className="input-container">
              <TextFieldCustom
                fullWidth
                placeholder={placeholder}
                value={date ? dayjs(date).format("DD/MM/YYYY") : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className="ico-hu-calendar-date"
                        sx={{
                          cursor: "pointer",
                          color: errors ? "red" : "#8C499C",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "50px",
                    borderColor: errors ? "red" : "#8C499C",
                  },
                }}
              />
            </div>
          }
        />
      </Box>
      {errors && (
        <Typography
          variant="caption"
          color="error.main"
          fontWeight={400}
          ml="15px"
        >
          {errors}
        </Typography>
      )}
    </Box>
  );
}
