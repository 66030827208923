import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Chip,
  Stack,
  Select,
  Checkbox,
  MenuItem,
  MenuProps,
  FormGroup,
  Typography,
  SelectProps,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
// type and style
import {
  ISelectMultiCategoryProps,
  IMultiSelectCateDataChecked,
} from "../CampaignBrief.type";
import { useSelectMultiCateStyle as classes } from "../CampaignBrief.style";

const SelectMultiple = styled(Select)<SelectProps>(({ theme, ...props }) => {
  const initialStyle = {
    transition: "border-radius 0.1s",

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      padding: "6.5px 14px",
      "& .MuiChip-root .MuiSvgIcon-root": {
        marginRight: "4px",
        fontSize: "16px",
        maxHeight: "80px",
        overflow: "auto",
      },
    },
  };

  if (props.disabled) {
    return {
      ...initialStyle,
      backgroundColor: theme.palette.grey[100],
      "& .MuiSelect-select": {
        ...initialStyle["& .MuiSelect-select"],
        WebkitTextFillColor:
          (props.value as unknown[]).length > 0
            ? theme.palette.common.white
            : theme.palette.grey[300],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.grey[100]} !important`,
      },
    };
  } else if (props.error) {
    return {
      ...initialStyle,
    };
  } else {
    return {
      ...initialStyle,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      },
    };
  }
});

export default function SelectMultiCategory({
  id,
  value,
  setValue,
  placeholder,
  valueKey,
  labelKey,
  data = [],
  sx,
  keyName,
  helperText,
  ...props
}: ISelectMultiCategoryProps) {
  const [width, setWidth] = useState<number | string>(0);
  const [categoryList, setCategoryList] = useState<
    IMultiSelectCateDataChecked[]
  >([]);

  const target = document.getElementById(id) as HTMLElement;

  useEffect(() => {
    setWidth(target ? (target as HTMLElement).offsetWidth : "auto");
    if (data) {
      const categoryList = data.map((item) => {
        const isChecked = value.includes(item.id);
        return { ...item, isChecked };
      });
      setCategoryList(categoryList);
    }
  }, [target, data, value]);

  const onSelectOpen = (method: string) => {
    if (method === "open") {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px 20px 0px 0px"
      );
    } else {
      (target.parentElement as HTMLElement).style.setProperty(
        "border-radius",
        "20px"
      );
    }
  };

  const handleRemoveCategory = (cateId: number) => {
    const cateList = [...categoryList];
    const cateIndex = cateList.findIndex((item) => item.id === cateId);
    cateList[cateIndex].isChecked = false;
    setCategoryList(cateList);
    const setCateList = cateList.filter((item) => item.isChecked === true);
    setValue(
      keyName,
      setCateList.map((item) => item.id)
    );
  };

  const handleSelectCategory = (e: any, cateId: number) => {
    const isChecked = e.target.checked;
    const cateList = [...categoryList];
    const cateIndex = cateList.findIndex((item) => item.id === cateId);
    cateList[cateIndex].isChecked = isChecked;
    setCategoryList(cateList);
    const setCateList = cateList.filter((item) => item.isChecked === true);
    setValue(
      keyName,
      setCateList.map((item) => item.id)
    );
  };

  const categorySelectedList = categoryList.filter((cate) => cate.isChecked === true);
  const selectCateLength = categoryList.filter((item) => item.isChecked === true).length > 2;

  return (
    <>
      <SelectMultiple
        id={id}
        value={value}
        size="small"
        multiple
        color="primary"
        variant="outlined"
        autoWidth
        displayEmpty
        onOpen={() => onSelectOpen("open")}
        onClose={() => onSelectOpen("close")}
        sx={sx && (sx as object)}
        {...props}
        renderValue={() => (
          <Box color="grey.300">
            {categorySelectedList.length ? (
              <Stack direction="row" columnGap={2} rowGap={2} flexWrap="wrap">
                {categorySelectedList.map((item, index) => (
                  <Chip
                    key={index}
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography variant="caption">
                          {item.name_th}
                        </Typography>
                      </Box>
                    }
                    color="primary"
                    onDelete={() => handleRemoveCategory(item.id)}
                    deleteIcon={
                      <CloseIcon sx={{ fontSize: "13px", zIndex: 1500 }} />
                    }
                    sx={classes.chip}
                  />
                ))}
              </Stack>
            ) : (
              placeholder
            )}
          </Box>
        )}
        MenuProps={
          {
            PaperProps: {
              sx: {
                ...classes.menu,
                width: typeof width === "number" ? width - 4 : width,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            MenuListProps: {
              style: {
                overflow: "auto",
              },
            },
          } as Partial<MenuProps>
        }
      >
        {categoryList.length ? (
          categoryList.map((cate, index) => (
            <MenuItem key={index} value={cate.name_th} sx={classes.menuItem}>
              <FormGroup sx={{ width: "100%" }}>
                <FormControlLabel
                  sx={{ width: "100%" }}
                  onChange={(e) => handleSelectCategory(e, cate.id)}
                  control={
                    <Checkbox
                      checked={cate.isChecked}
                      disabled={
                        !cate.isChecked && selectCateLength ? true : false
                      }
                      sx={{ ml: "10px" }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color={
                        !cate.isChecked && selectCateLength
                          ? "rgba(0, 0, 0, 0.26)"
                          : "initial"
                      }
                    >
                      {cate.name_th}
                    </Typography>
                  }
                />
              </FormGroup>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled sx={classes.menuItem}>
            <span>No data</span>
          </MenuItem>
        )}
      </SelectMultiple>
      {helperText && (
        <Typography
          sx={{
            color: "red",
            fontSize: "12px",
            m: "5px 0 -10px 15px",
          }}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
}
