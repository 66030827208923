// mui
import {
  Box,
  Grid,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
// icons and style
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { useRewardToInfluencerStyle as classes } from "../CampaignBrief.style";
// data and type
import { specifyingProductList } from "../campaignBrief.constants";
import { IRewardToInfluencerProps } from "../CampaignBrief.type";
import { ICampaignBriefFormKey } from "@views/campaign/create-campaign/CreateCampaign.type";
// others
import TextFieldCustom from "@global/text-field";
import ImageInput from "../brief-default/ImageInput";

export default function RewardToInfluencerDialog(
  props: IRewardToInfluencerProps
) {
  const { openAddProduct, setOpenAddProduct, errors, append, form } = props;

  const { watch, getValues, register } = form;

  return (
    <Dialog
      open={openAddProduct}
      onClose={() => setOpenAddProduct(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
        <Typography>สินค้าหรือบริการที่อินฟลูเอนเซอร์จะได้รับ</Typography>
        <IconButton onClick={() => setOpenAddProduct(false)}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ my: "24px" }}>
        <Box display="flex">
          <AllInboxIcon />
          <Typography fontSize="16px" fontWeight="500" ml={2}>
            Product or service detail
          </Typography>
        </Box>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={3.5}>
            <Typography>
              Product Image<span className="require">*</span>
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <ImageInput
              form={form}
              keyName="budget.reward.image"
              errors={errors?.budget?.reward?.image}
            />
          </Grid>
          <Grid item xs={3.5}>
            <Typography>
              Product Title<span className="require">*</span>
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <TextFieldCustom
              variant="outlined"
              placeholder="ชื่อสินค้าหรือบริการ"
              fullWidth
              error={!!errors?.budget?.reward?.name}
              helperText={errors.budget?.reward?.name?.message}
              inputProps={{
                ...register("budget.reward.name" as ICampaignBriefFormKey, {
                  required: true,
                }),
              }}
            />
          </Grid>
          <Grid item xs={3.5}>
            <Typography>
              Description<span className="require">*</span>
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <TextFieldCustom
              variant="outlined"
              placeholder="Text"
              fullWidth
              multiline
              minRows={3}
              error={!!errors.budget?.reward?.description}
              helperText={errors?.budget?.reward?.description?.message}
              inputProps={{
                ...register(
                  "budget.reward.description" as ICampaignBriefFormKey,
                  {
                    required: true,
                  }
                ),
              }}
            />
          </Grid>
          <Grid item xs={3.5}></Grid>
          <Grid item xs={8.5}>
            <Box sx={classes.specifyingProductDetails}>
              <Typography>ตัวอย่าง การระบุรายละเอียดสินค้า</Typography>
              <ul>
                {specifyingProductList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={classes.dialogAction}>
        <Button
          onClick={() => {
            append({
              image: getValues("budget.reward.image" as any),
              name: getValues("budget.reward.name" as any),
              description: getValues("budget.reward.description" as any),
            });
            setOpenAddProduct(false);
          }}
          disabled={
            !watch(`budget.reward.name` as any) ||
            !watch(`budget.reward.image.url` as any)
          }
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
