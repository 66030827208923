import {
  hGrey,
  hWhite,
  hPurple,
  hYellow,
  hSocialColor,
} from "@theme/index";

export const useCampaignReportTabStyle = {
  tabPanelEmpWrap: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "common.white",
    borderRadius: {
      xs: "12px",
      sm: "0 12px 12px 12px",
    },
    px: { xs: "0px", lg: "24px" },
    py: { xs: "0px", lg: "20px" },
    boxShadow: 3,
    mt: {
      xs: "-10px",
      sm: "-2.5px",
      md: 0,
    },
  },
  tabfilter: {
    mb: "8px",
    display: "flex",
  },
  campSumInfo: {
    mt: "15px",
    mb: {
      xs: "15px",
      lg: "28px",
    },
  },
  campReport: {
    display: ["initial", "none"],
    mt: ["50px", "34px"],
  },
  InfluPostReport: {
    borderRadius: "20px",
    mt: "20px",
    height: "auto",
  },
  tabPanel: {
    minHeight: "34px !important",
    "& .MuiTabs-indicator": {
      bgcolor: "transparent",
    },
    "& .css-qpb1i6-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1t1s4kk.Mui-selected":
      {
        color: hWhite[100],
        bgcolor: hPurple[200],
      },
    "& button": {
      minHeight: "100% !important",
    },
  },
  tab: {
    color: hPurple[200],
    bgcolor: "#F5EEFF",
    m: "0 5px",
    borderRadius: "50px",
    fontSize: "14px",
  },
};

export const useInfluencerPostReportStyle = {
  container: {
    height: "100%",
    borderRadius: "12px",
  },
  postReportTitle: {
    my: ["4px", "10px"],
    "& p:first-of-type": {
      fontSize: ["20px", "20px", "24px"],
    },
    "& p:last-of-type": {
      fontSize: ["14px", "14px", "16px"],
      color: hGrey[900],
    },
  },
  gridRoot: {
    "& > .MuiGrid-root": {
      display: "flex",
      justifyContent: "center",
    },
    width: "100%",
  },
  postReportGrid: {
    border: `1px solid ${hPurple[100]}`,
    borderRadius: "12px",
    width: "95%",
  },
  postReportTop: {
    display: "flex",
    alignItems: "center",
    ml: ["5px", "20px"],
    mt: "15px",
    "& .influencerImage": {
      width: "60px",
      height: "60px",
      borderRadius: "8px",
    },
    "& .influencerName": {
      display: "flex",
      alignItems: "center",
      "& svg:first-of-type": {
        borderRadius: "50%",
        width: "22px",
        height: "22px",
      },
      "& svg:last-of-type": {
        color: hYellow[300],
        fontSize: ["16px", "20px"],
      },
    },
    "& .influencerFollower": {
      display: "flex",
      alignItems: "center",
      "& svg:first-of-type": {
        borderRadius: "50%",
        width: "16px",
        height: "16px",
        ml: "2px",
        mr: "5px",
      },
      "& p": {
        fontSize: "14px",
        ml: "5px",
      },
    },
  },
  postReportBottom: {
    display: ["block", "flex"],
    mt: "20px",
    justifyContent: "space-between",
    px: ["5px", "20px"],
    width: "93%",
  },
  postReportBottomLeft: {
    border: `1px solid ${hPurple[100]}`,
    borderRadius: "12px",
    height: "100%",
    p: "10px",
    overflow: "hidden",
    position: "relative",
    "& > img": {
      borderRadius: "12px",
      width: "100%",
    },
  },
  socialProfile: {
    display: "flex",
    alignItems: "center",
    mb: "16px",
    "& > img": { objectFit: "cover" },
    "& > p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  desc: {
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  socialTotalBox: {
    position: "absolute",
    bottom: 8,
    height: "30px",
    alignItems: "center",
    width: "98%",
    ml: "-10px",
    mb: "-8px",
    bgcolor: "#fff",
    borderRadius: "0 0 11px 11px",
    px: "10px",
  },
  socialIcon: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": { fontSize: "20px" },
  },
  postReportBottomRight: {
    width: "100%",
    mt: ["18px", 0],
  },
  postReportFirstCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: `1px solid ${hPurple[100]}`,
    borderRadius: "8px",
    minHeight: "120px",
    "& div": {
      display: "flex",
      justifyContent: "space-evenly",
      textAlign: "center",
      mt: "14px",
      "& h5": {
        display: "flex",
        flexDirection: "column",
        "& span": {
          color: hGrey[900],
          fontSize: "14px",
        },
      },
    },
  },
  postReportSecondCard: {
    border: `1px solid ${hPurple[100]}`,
    minHeight: "120px",
    borderRadius: "8px",
  },
  postReportSecondCardTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: "16px",
    "& span": {
      color: hGrey[900],
      fontSize: "14px",
    },
  },
  postReportSecondCardBottom: {
    display: "flex",
    justifyContent: "space-between",
    "& div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        color: hGrey[900],
      },
    },
  },
  postReportThirdCard: {
    border: `1px solid ${hPurple[100]}`,
    minHeight: "120px",
    borderRadius: "8px",
  },
  postReportThirdCardTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& span": {
      color: hGrey[900],
      fontSize: "14px",
    },
  },
  postReportThirdCardBottom: {
    display: "flex",
    justifyContent: "space-evenly",
    "& div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        color: hGrey[900],
      },
    },
  },
  divider: {
    width: "90%",
    mx: "auto",
    my: "15px",
  },
  postReportButton: {
    display: "flex",
    justifyContent: "center",
    m: "16px 15px 16px 4px",
    "& button": {
      width: ["150px", "200px"],
    },
    "& button:last-of-type": {
      ml: ["8px", "20px"],
      border: "none",
      "&:hover": { border: "none" },
      width: ["150px", "200px"],
    },
  },
};

export const useCampaignSummaryInfoStyle = {
  container: {
    border: `2px solid ${hPurple[100]}`,
    borderRadius: {
      xs: "12px",
      lg: "16px",
    },
    padding: "15px",
    height: ["100%", "100%", "85px", "100%"],
    mt: [0, 0, "5px"],
    mb: {
      md: "10px",
      lg: 0,
    },
  },
  campSumImage: {
    borderRadius: ["4px", "4px", "4px", "8px"],
    width: ["48px", "48px", "100px"],
    height: ["48px", "48px", "100px"],
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  campSumBoxTitle: {
    ml: [0, "10px", "20px"],
    mt: ["10px", 0],
    width: ["100%", "60%"],
    "& p:first-of-type": {
      fontWeight: "400",
      fontSize: ["16px", "16px", "24px"],
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& p:last-of-type": {
      fontSize: ["12px", "12px", "16px"],
      color: hGrey[500],
      mt: "3px",
    },
  },
  campSumCardBottom: {
    m: ["10px 0 12px 0", "16px 0 16px 0"],
    position: {
      xs: "static",
      md: "absolute",
    },
    bottom: 0,
    left: "129px",
    "& div:not(:nth-of-type(2)) svg": {
      color: hGrey[900],
    },
    "& svg": { fontSize: "18px" },
    "& p": { color: hGrey[900], fontSize: "14px" },
  },
  campSumCardBottomItem: {
    display: "flex",
    alignItems: ["start", "start", "center"],
    my: "auto",
    "& div:nth-of-type(2)": {
      "& svg": {
        color: hSocialColor.facebook,
      },
    },
  },
  campCompBox: {
    display: "flex",
    mt: "16px",
    alignItems: "center",
    position: {
      xs: "static",
      md: "absolute",
    },
    top: 0,
    right: "20px",
    "& div": {
      borderRadius: "2px",
      width: "24px",
      height: "24px",
    },
    "& p": {
      ml: "10px",
      color: hGrey[900],
      fontSize: "14px",
    },
  },
};
