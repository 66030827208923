export const useDrawerPrivateInfluencerSelectedStyle = {
  // drawer selecting more
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: "20px 0 0 20px",
      width: { xs: "100%", sm: "50%", md: "30%" },
    },
  },
  boxTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
  },
  icon: {
    cursor: "pointer",
    fontSize: "24px",
    color: "#9E9E9E",
  },
  boxCardInflu: {
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    position: "relative",
    "& > img": {
      width: "100%",
      height: "100px",
      borderRadius: "8px 8px 0 0",
      objectFit: "cover",
    },
  },
  fab: {
    minHeight: "20px",
    maxHeight: "20px",
    width: "20px",
    top: "-8px",
    right: "-8px",
    bgcolor: "black",
    position: "absolute",
    "&:hover": {
      bgcolor: "#686868",
    },
  },
  textEllipsis: {
    width: "170px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  boxAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px",
  },
};

export const GridDropZoneStyle = {
  border: "1px dashed #E0E0E0",
  borderRadius: "8px",
  backgroundColor: "rgba(0, 0, 0, 0.03)",
  transition: `height 1s cubic-bezier(0, 1.15, 0.96, 1)`,
};

export const useCampaignPrivateStyles = {
  boxNoContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },
  textNoContent: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
  privateWrap:{
    p:"24px",
    bgcolor: "#fff",
    borderRadius:"16px",
    boxShadow:"0px 2px 8px rgba(0, 0, 0, 0.1)"
  },
  divider: { margin: "24px 0" },
  backButton: { width: "200px", height: "40px" },
  nextButton: {
    width: "200px",
    height: "40px",
    backgroundColor: "#893DBD",
    color: "#fff",
    ":hover": {
      color: "#893DBD",
    },
  },

  // Status
  mainBox: {
    border: "1px solid #EBDFFF",
    borderRadius: "8px",
    padding: "16px",
  },
  boxListName: {
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },

  // Card Influencer
  boxImages: {
    zIndex: 999,
    widht: "100%",
    height: "320px",
    borderRadius: "16px",
    "& > img": {
      display: "block",
      objectFit: "cover",
      width: "100%",
      height: "320px",
      borderRadius: "16px",
      cursor: "pointer",
      border: "3px solid #fff",
    },
  },
  fabSelected: {
    position: "absolute" as "absolute",
    top: "-10px",
    left: "-10px",
    backgroundColor: "common.white",
    width: "36px",
    height: "36px",
    bgcolor: "grey",
    zIndex: 0,
    "&:hover": {
      width: "96px",
      height: "36px",
      borderRadius: "30px",
      transition: "0.3s",
      bgcolor: "red",
    },
    "&:hover .MuiSvgIcon-root": {
      display: "none",
    },
    "&:hover .showText": {
      color: "common.white",
      display: "block",
      transition: "0.4s",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover + img:first-of-type": {
      border: "3px solid #893DBD",
    },
  },
  fab: {
    position: "absolute",
    top: "-10px",
    left: "-10px",
    width: "36px",
    height: "36px",
    zIndex: 0,
    "&:hover": {
      width: "96px",
      height: "36px",
      borderRadius: "30px",
      transition: "0.3s",
    },
    "&:hover .showText": {
      display: "block",
      transition: "0.4s",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover + img:first-of-type": {
      border: "3px solid #893DBD",
    },
  },
  showText: { display: "none", ml: "3px" },
  rateCard: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "22px",
    right: "-3px",
    width: "100px",
    height: "32px",
    color: "#fff",
    borderRadius: "4px 0 0 4px",
  },
  boxDetail: {
    position: "absolute",
    bottom: "-6px",
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))",
    borderRadius: "0 0 12px 12px",
    color: "#fff",
    margin: "3px",
    cursor: "pointer",
  },
  textEllipsis: {
    width: "170px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stackCategory: {
    margin: "4px 0",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    "& .MuiSvgIcon-root": {
      fontSize: "6px",
    },
  },
  textCategory: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "12px",
  },

  // Organizing
  boxStatus: {
    padding: "16px 20px",
    border: "1px solid #EBDFFF",
    borderRadius: "8px",
  },
  boxBudget: {
    width: `100%`,
    height: "10px",
    backgroundColor: "#ECECEC",
    margin: "12px 0",
    borderRadius: "8px",
  },
  boxUseBudget: {
    backgroundColor: "#893DBD",
    height: "10px",
    borderRadius: "8px",
  },
  boxInfluencerCard: {
    border: "1px dashed #E0E0E0",
    borderRadius: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    padding: "24px",
  },
  // Organizing -> CardInfluencer
  boxSelected: {
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    position: "relative",
    "& > img": {
      width: "100%",
      height: "100px",
      borderRadius: "8px 8px 0 0",
      objectFit: "cover",
    },
  },
  fabOrganizing: {
    minHeight: "20px",
    maxHeight: "20px",
    width: "20px",
    top: "-8px",
    right: "-8px",
    bgcolor: "black",
    position: "absolute",
    "&:hover": {
      bgcolor: "#686868",
    },
  },
  icon: {
    fontSize: "16px",
    marginRight: "4px",
  },
  // Dialog
  boxDialog: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    "& > img": {
      width: "240px",
      height: "240px",
      margin: "16px 0",
    },
  },
  boxButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& > Button": {
      width: "200px",
      height: "40px",
      ":last-of-type": {
        backgroundColor: "#893DBD",
        color: "#fff",
        border: "2px solid #893DBD",
        ":hover": {
          backgroundColor: "#893DBD",
          color: "#fff",
          border: "2px solid #893DBD",
        },
      },
    },
  },
  // Offering
  boxPaymentDetail: {
    padding: "24px",
    border: "1px solid #893DBD",
    borderRadius: "8px",
  },
  marginTop: {
    marginTop: "24px",
  },
  boxWallet: {
    padding: "24px",
    border: "1px solid #FFD4ED",
    borderRadius: "8px",
    marginTop: "24px",
  },
  boxBasic: {
    width: "68px",
    height: "28px",
    backgroundColor: "#FFD4ED",
    color: "#ED1968",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    marginLeft: "12px",
  },
  boxIcon: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxShadow: {
    width: "60PX",
    height: "70PX",
    backgroundColor: "#F5F6F8",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "8px 2px 10px rgba(0, 0, 0, 0.1)",
  },
  boxHeadTable: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyConent: "center",
    border: "1px solid black",
    padding: "6px",
  },
  boxLeftTable: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "6px",
    color: "red",
  },
  boxRightTable: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyConent: "center",
    border: "1px solid black",
    padding: "6px",
    color: "red",
  },
  boxMargin: { margin: "18px 0" },
  dividerOffering: { margin: "18px 0 0" },
  boxAccept: {
    margin: "24px 0",
    border: "1px solid #893DBD",
    borderRadius: "8px",
    padding: "12px",
  },
};
