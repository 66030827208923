export const adminDashboardStyles = {
  cardWrap: {
    bgcolor: "#fff",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 2px 10px 0px #0000001A",
  },
  imageWrap: {
    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "contain",
    },
  },
  noCampaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    bgcolor: "#fff",
    borderRadius: "16px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
    py: "40px",
  },
  textNoCampaign: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
  paginate: {
    mt: "20px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },
  textEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  orgPaginateWrap: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    py: "16px",
  },
};
