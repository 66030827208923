import { useEffect } from "react";
// mui
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function MyProfile(props: any) {
  const { TabPanel, tabSelected, index, profile } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TabPanel value={tabSelected} index={index}>
      <Box sx={{ padding: matches ? "0" : "0 40px" }}>
        <Typography variant="h5">{profile.title}</Typography>
        <Box mt="12px">
          <Typography>1. กดเมนู My Profile และมองหา "My Profile"</Typography>
          <Typography>2. คลิกปุ่ม "Edit Profile" เพื่อแก้ไขข้อมูล</Typography>
          <Typography>3. กรอกข้อมูลแล้วกดเมนู "Save"</Typography>
        </Box>

        <Box
          sx={{
            mt: "24px",
            mb: "24px",
            "& video": {
              width: "100%",
            },
          }}
        >
          <video
            height="auto"
            controls
            src="https://storage.googleapis.com/hashu-bucket-uat/media/videos/edit-profile.mp4"
          />
        </Box>
      </Box>
    </TabPanel>
  );
}
