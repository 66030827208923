import { useState } from "react";
// mui, style and icon
import { Avatar, Box, Icon, Typography } from "@mui/material";
import { useSocialScreenshortStyle as classes } from "../InfluencerSocial.style";
import CircleIcon from "@mui/icons-material/Circle";
// type;
import { ISocialAccountRes } from "@api/influencer/type";
import { TPlatform } from "@components/influencer-social/InfluencerSocial.type";
// route and swiper
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// slice
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";

export default function SocialScreenshort() {
  const [screenSlice, setScreenSlice] = useState(0);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const platform = params.get("platform") || "";

  const socialInfo = useAppSelector(selectInfluencerSocial);
  const platformData = (platform: TPlatform | string) => {
    const platformMapping: Record<string, string> = {
      tiktok: "tiktok",
      facebook: "facebook",
      instagram: "instagram",
      twitter: "twitter",
      youtube: "youtube",
    };

    const platformKey = platformMapping[platform];
    return socialInfo?.[platformKey]?.[0];
  };
  const socialAccount = platformData(platform)?.social_account;
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialAccount || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  const numberFormat = Intl.NumberFormat("en", {
    notation: "compact",
  });

  const handleSlideChange = (index: number) => {
    setScreenSlice(index);
  };

  return (
    <Box sx={classes.container}>
      <Typography textAlign="end" fontWeight={500}>
        Social Media Screenshot
      </Typography>
      <Box sx={classes.contentBox}>
        <Box display="flex" alignItems="center" columnGap="5px">
          <Avatar src={accountInfo?.profile_image}>
            {accountInfo?.name.charAt(0)}
          </Avatar>
          <Typography>{accountInfo?.name}</Typography>
        </Box>
        <Swiper
          onSlideChange={(swiper) => handleSlideChange(swiper.activeIndex)}
        >
          {platform === "tiktok"
            ? accountInfo?.social_screenshot.map((post, index) => (
                <SwiperSlide key={index}>
                  <Box sx={classes.tiktokSlides}>
                    <Avatar
                      src={post.media[0]?.url}
                      sx={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "4px",
                      }}
                    />
                    {/* average */}
                    <Box sx={classes.tiktokAvg}>
                      <Avatar src={accountInfo?.profile_image}>
                        {accountInfo?.name.charAt(0)}
                      </Avatar>
                      <div className="d-flex f-d-column a-i-center">
                        <Icon className="ico-hu-love" />
                        {numberFormat.format(post.like)}
                      </div>
                      <div className="d-flex f-d-column a-i-center">
                        <Icon className="ico-hu-comment" />
                        {numberFormat.format(post.comment)}
                      </div>
                      <div className="d-flex f-d-column a-i-center">
                        <Icon className="ico-hu-share" />
                        {numberFormat.format(post.share)}
                      </div>
                    </Box>
                    {/* description */}
                    <Box sx={classes.tiktokDesc}>
                      <Typography>@{accountInfo?.name}</Typography>
                      <Typography className="block-ellipsis" width="80%">
                        {post.description}
                      </Typography>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))
            : accountInfo?.social_screenshot.map((post, index) => (
                <SwiperSlide key={index} className="swiperSlide">
                  <Box key={index} sx={{ position: "relative", mt: "12px" }}>
                    {/* description */}
                    {post.description && (
                      <Box className="d-flex a-i-center" height="75px">
                        <Typography mb="12px" className="block-ellipsis">
                          {post.description}
                        </Typography>
                      </Box>
                    )}
                    {/* image post */}
                    {post.media.length === 1 ? (
                      post.media[0].file_type === "mp4" ? (
                        <Box
                          component="video"
                          src={post.media[0]?.url}
                          autoPlay
                          sx={classes.att1}
                        />
                      ) : (
                        <Avatar src={post.media[0]?.url} sx={classes.att1} />
                      )
                    ) : post.media.length === 2 ? (
                      <Box display="flex">
                        <Avatar
                          src={post.media[0]?.url}
                          sx={{
                            ...classes.att2,
                            borderRadius: "4px 0 0 4px",
                          }}
                        />
                        <Avatar
                          src={post.media[1]?.url}
                          sx={{
                            ...classes.att2,
                            borderRadius: "0 4px 4px 0",
                          }}
                        />
                      </Box>
                    ) : post.media.length === 3 ? (
                      <Box
                        display="flex"
                        height={{ xs: "340px", sm: "500px", xl: "700px" }}
                      >
                        <Box width="50%">
                          <Avatar
                            src={post.media[0]?.url}
                            sx={{
                              height: "50%",
                              width: "100%",
                              borderRadius: "4px 0 0 0",
                            }}
                          />
                          <Avatar
                            src={post.media[1]?.url}
                            sx={{
                              height: "50%",
                              width: "100%",
                              borderRadius: "4px 0 0 4px",
                            }}
                          />
                        </Box>
                        <Avatar
                          src={post.media[2]?.url}
                          sx={{
                            ...classes.att3,
                            borderRadius: "0 4px 4px 0",
                          }}
                        />
                      </Box>
                    ) : post.media.length >= 4 ? (
                      <Box display="flex">
                        <Box
                          width="50%"
                          height={{ xs: "340px", sm: "500px", xl: "100%" }}
                        >
                          <Avatar
                            src={post.media[0]?.url}
                            sx={{
                              height: "50%",
                              width: "100%",
                              borderRadius: "4px 0 0 0",
                            }}
                          />
                          <Avatar
                            src={post.media[1]?.url}
                            sx={{
                              height: "50%",
                              width: "100%",
                              borderRadius: "0 0 0 4px",
                            }}
                          />
                        </Box>
                        <Box
                          width="50%"
                          height={{ xs: "340px", sm: "500px", xl: "100%" }}
                        >
                          <Avatar
                            src={post.media[2]?.url}
                            sx={{
                              height: "50%",
                              width: "100%",
                              borderRadius: "0 4px 4px 0",
                            }}
                          />
                          <Avatar
                            src={post.media[3]?.url}
                            sx={{
                              height: "50%",
                              width: "100%",
                              borderRadius: "0 0 0 4px",
                            }}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={classes.txtOnly}>
                        <Typography textAlign="center">
                          {post.description}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={classes.totalAvg}>
                      <Box className="d-flex a-i-center" columnGap="2px">
                        {platform !== "twitter" ? (
                          <Icon className="ico-hu-like" />
                        ) : (
                          <Icon className="ico-hu-love" />
                        )}
                        {numberFormat.format(post.like)}
                      </Box>
                      <Box className="d-flex a-i-center" columnGap="2px">
                        <Icon className="ico-hu-comment" />
                        {numberFormat.format(post.comment)}
                      </Box>
                      <Box className="d-flex a-i-center" columnGap="2px">
                        {platform === "twitter" ? (
                          <Icon className="ico-hu-retweet" />
                        ) : (
                          <Icon className="ico-hu-share" />
                        )}
                        {numberFormat.format(post.share)}
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
        </Swiper>
        <Box className="d-flex j-c-center" mt="16px">
          <Box sx={{ display: "flex", maxWidth: "100px", columnGap: "5px" }}>
            {[...Array(accountInfo?.social_screenshot.length)].map(
              (_, index) => (
                <CircleIcon
                  key={index}
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: screenSlice === index ? "#848484" : "#D8D8D8",
                  }}
                />
              )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
