import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Icon,
  Avatar,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// api and type
import {
  IInitUpdateRateCardForm,
  IEditInfluencerRateCardProps,
} from "../InfluencerSocial.type";
import { InfluencerAPI } from "@api/global";
import { IRateCard, ISocialAccountRes } from "@api/influencer/type";
// redux
import {
  selectInfluencerSocial,
  getInfluencerSocialAsync,
} from "@slices/InfluencerSocial.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectContentTypelist } from "@slices/ContentTypeList.slice";
// hook and style
import { useForm } from "react-hook-form";
import { useUpdateInfluencerRateCardStyle as classes } from "../InfluencerSocial.style";
// other
import TextFieldCustom from "@global/text-field";
import Loading from "@components/global/loading/Loading";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";

export default function EditInfluencerRateCard(
  props: IEditInfluencerRateCardProps
) {
  const { open, onClose, platform, email } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const contentTypeList = useAppSelector(selectContentTypelist);
  const influencerSocial = useAppSelector(selectInfluencerSocial);

  const socialnfo = (influencerSocial?.[platform] || [])[0];
  let accountInfo: ISocialAccountRes | undefined;

  accountInfo = (socialnfo?.social_account || []).reduce(
    (latestAccount?: ISocialAccountRes, currentAccount?: ISocialAccountRes) => {
      if (
        !latestAccount ||
        (currentAccount?.latest_sync_date || new Date()) >
          latestAccount?.latest_sync_date
      ) {
        return currentAccount;
      }
      return latestAccount;
    },
    undefined
  );

  const platformContenType = contentTypeList
    ?.filter((item) => item.platform === platform)
    ?.map((item) => {
      return {
        id: "",
        content_type_id: item.id,
        value: "0",
        content_type: item.name,
      };
    });

  const initUpdateRateCardForm = accountInfo?.rate_card.map((item) => {
    return {
      id: item.id,
      content_type_id: item.content_type_id,
      value: item.price.value.toString(),
      content_type: item.content_type,
    };
  });

  const form = useForm<IInitUpdateRateCardForm>({
    defaultValues: {
      social_account_id: accountInfo?.social_account_id,
      platform: platform || "",
      rate_card: [],
    },
  });

  const { watch, setValue, handleSubmit, reset } = form;

  useEffect(() => {
    const form = {
      social_account_id: accountInfo?.social_account_id,
      platform: platform || "",
    };
    if (initUpdateRateCardForm?.length) {
      reset({ ...form, rate_card: initUpdateRateCardForm });
    } else if (platformContenType) {
      reset({ ...form, rate_card: platformContenType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numFormater = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
  });

  const textFieldValue = (value: string) => {
    if (Number(value) === 0) {
      return "";
    } else {
      return numFormater.format(Number(value.replace(/,/g, "")));
    }
  };

  const filterRateCard = (type: string) => {
    return watch("rate_card")
      .filter((rate) => rate.content_type.includes(type))
      .sort((a, b) => a.content_type_id - b.content_type_id);
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const { value } = e.target;
    const data = [...watch("rate_card")];
    const dataIndex = data.findIndex((item) => item.content_type_id === id);
    if (Number(value.replace(/,/g, ""))) {
      setValue(`rate_card.${dataIndex}.value`, value);
    } else if (!value) {
      setValue(`rate_card.${dataIndex}.value`, value);
    }
  };

  const onUpdateRateCard = async (value: IInitUpdateRateCardForm) => {
    onClose(false);
    const payload = {
      ...value,
      rate_card: value.rate_card.map((item) => {
        return {
          id: item.id,
          content_type_id: item.content_type_id,
          value: Number(item.value.replace(/,/g, "")),
        };
      }),
    };

    setIsLoading(true);
    if (isLoading) return;

    InfluencerAPI.updateInfluencerRateCard(payload)
      .then((response) => {
        const ratCard = response.rate_card.map((item: IRateCard) => {
          return {
            id: item.id,
            content_type_id: item.content_type_id,
            value: item.price.value.toString(),
            content_type: item.content_type,
          };
        });
        dispatch(getInfluencerSocialAsync({}));
        setValue("rate_card", ratCard);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: "There are somthing wrong",
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  if (isLoading) return <Loading />;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      sx={classes.dialog}
      onClose={() => onClose(false)}
    >
      <DialogTitle
        className="dialog-title"
        sx={classes.dialogTitle}
        id="alert-dialog-title"
      >
        <Typography variant="title1" color="#fff" textTransform="capitalize">
          {platform} - Rate Card
        </Typography>
        <IconButton onClick={() => onClose(false)} sx={classes.closeBtn}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: "0 15px 0 15px !importaint" }}>
        <Box sx={classes.headerContent}>
          <Avatar src={accountInfo?.profile_image} sx={classes.profile}>
            {accountInfo?.name.charAt(0)}
          </Avatar>
          <Box ml="16px">
            <Typography variant="title1">{accountInfo?.name}</Typography>
            <Typography variant="body2">{email}</Typography>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onUpdateRateCard)}>
          <Box sx={classes.formBox}>
            <Box m="14px 0 24px 0">
              <Typography variant="h6">Rate Card</Typography>
              <Typography variant="subTitle2">
                คุณจะได้รับค่าตอบแทนตาม Rate Card ที่คุณกำหนด เมื่อผู้จ้างงาน
                “ยื่นข้อเสนอ” ให้คุณเท่านั้น
              </Typography>
            </Box>
            {filterRateCard("video").length ? (
              <div>
                <Typography variant="title1" textTransform="capitalize">
                  Video
                </Typography>
                {filterRateCard("video").map((item, index) => (
                  <Box
                    key={index}
                    className="d-flex j-c-space-between"
                    mt="8px"
                  >
                    <Box className="d-flex a-i-center" width="50%">
                      <Icon className="ico-hu-p-vdo" />
                      <Typography
                        variant="title2"
                        ml="10px"
                        textTransform="capitalize"
                      >
                        {item?.content_type?.replace("video ", "")} :{" "}
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={textFieldValue(item.value)}
                      variant="outlined"
                      placeholder="rate card..."
                      fullWidth
                      onChange={(e) => onInputChange(e, item.content_type_id)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">THB</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                ))}
                <Divider sx={{ my: "16px" }} />
              </div>
            ) : null}
            {filterRateCard("photo").length ? (
              <Box>
                <Typography variant="title1" textTransform="capitalize">
                  Photo
                </Typography>
                {filterRateCard("photo").map((item, index) => (
                  <Box
                    key={index}
                    className="d-flex j-c-space-between"
                    mt="8px"
                  >
                    <Box className="d-flex a-i-center" width="50%">
                      <Icon className="ico-hu-p-photo" />
                      <Typography
                        variant="title2"
                        ml="10px"
                        textTransform="capitalize"
                      >
                        {item?.content_type?.replace("video ", "")} :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={textFieldValue(item.value)}
                      variant="outlined"
                      placeholder="rate card..."
                      fullWidth
                      onChange={(e) => onInputChange(e, item.content_type_id)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">THB</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                ))}
                <Divider sx={{ my: "16px" }} />
              </Box>
            ) : null}
            {filterRateCard("text").length ? (
              <div>
                <Typography variant="title1" textTransform="capitalize">
                  Text
                </Typography>
                {filterRateCard("text").map((item, index) => (
                  <Box
                    key={index}
                    className="d-flex j-c-space-between"
                    mt="8px"
                  >
                    <Box className="d-flex a-i-center" width="50%">
                      <Icon className="ico-hu-p-text" />
                      <Typography
                        variant="title2"
                        ml="10px"
                        textTransform="capitalize"
                      >
                        {item?.content_type?.replace("video ", "")} :
                      </Typography>
                    </Box>
                    <TextFieldCustom
                      value={textFieldValue(item.value)}
                      variant="outlined"
                      placeholder="rate card..."
                      fullWidth
                      onChange={(e) => onInputChange(e, item.content_type_id)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">THB</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                ))}
                <Divider sx={{ m: "16px 0 6px 0" }} />
              </div>
            ) : null}
          </Box>
          <Box sx={classes.saveBtn}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              startIcon={<Icon className="ico-hu-save" fontSize="small" />}
            >
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
