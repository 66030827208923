export const useOfferringToInfluencerStyle = {
  offeringWrap:{
    p: "24px",
    borderRadius: "15px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    bgcolor: "#fff",
  },
  paymentBtn: {
    mt: "16px",
    border: "none",
    "&:hover": { border: "none" },
    fontSize: "16px",
  },
}

export const usePaymentSummaryStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: 6,
  },
  summaryBox: {
    border: "2px solid #F5EEFF",
    borderRadius: "15px",
    p: "24px",
  },
  summaryDetailBox: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px",
    bgcolor: "#F5EEFF",
    my: "8px",
    p: "16px",
  },
};

export const usePaymentMethodStyle = {
  accordiun: {
    mt: "15px",
    "& .css-g9zaru-MuiPaper-root-MuiAccordion-root.Mui-expanded": {
      m: 0,
    },
    "& > .Mui-expanded:not(.Mui-expanded:first-of-type)": {
      borderTop: "1px solid #E0E0E0",
    },
    "& .MuiAccordion-root:first-of-type": {
      borderRadius: "10px 10px 0 0",
    },
    "& .MuiAccordion-root:last-of-type": {
      borderRadius: "0 0 10px 10px",
    },
  },
  accordionDetails: {
    border: "1px solid #E0E0E0",
    p: "12px",
    borderRadius: "8px",
    height: "52px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  isWalletBalanceBox: {
    border: "1px solid #E0E0E0",
    p: "24px",
    borderRadius: "12px",
    mt: "16px",
  },
  qrPaymentImg: {
    width: "40%",
    borderRadius: "8px",
    "& > img": {
      objectFit: "contain",
    },
  },
  craditImg: {
    width: "55px",
    height: "40px",
    borderRadius: "8px",
    "& > img": {
      objectFit: "contain",
    },
  },
  methodImg: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
};