import { useEffect, useState } from "react";
// mui
import {
  Box,
  Paper,
  Stack,
  Table,
  Avatar,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import { useInfluencerStatusStyle as classes } from "./InfluencerStatusTab.style";
import { TableCellCustom } from "@components/employer-expense/Expense.style";
import { TableRowCustom } from "@components/influencer-earning/Earning.style";
// type and api
import {
  IInfluencerStatus,
  IInfluencerBanSummary,
  IInfluencerBanStatusParams,
} from "@api/influencer/type";
import { InfluencerAPI } from "@api/global";
// other
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Loading from "@global/loading/Loading";
import BasicSelectCustom from "@global/basic-select";
import hashULogo from "@assets/images/logo/hashu-logo64.png";

const sortList = [
  {
    key: "",
    value: "",
    label: "All",
  },
  {
    key: "active_date",
    value: "active_date",
    label: "Active Date",
  },
  {
    key: "inactive_date",
    value: "inactive_date",
    label: "Inactive Date",
  },
];

export default function InfluencerStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [statusParams, setStatusParams] = useState<IInfluencerBanStatusParams>({
    order_by: "",
    order: "desc",
  });
  const [statusList, setStatusList] = useState<IInfluencerStatus[]>();
  const [banSummary, setBanSummary] = useState<IInfluencerBanSummary>();

  const { influencerId } = useParams<{ influencerId: string }>();

  useEffect(() => {
    getInfluencerBanStatus(influencerId, statusParams);
    getInfluencerBanSummary(influencerId);
  }, [statusParams, influencerId]);

  const getInfluencerBanStatus = (
    influencerId: string,
    statusParams: IInfluencerBanStatusParams
  ) => {
    setIsLoading(true);

    InfluencerAPI.getInfluencerBanStatus(influencerId, statusParams)
      .then((response) => {
        setStatusList(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("can not get influencer ban status error : ", err);
      });
  };

  const getInfluencerBanSummary = (influencerId: string) => {
    setIsLoading(true);

    InfluencerAPI.getInfluencerBanSummary(influencerId)
      .then((response) => {
        setBanSummary(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("can not get influencer ban summary error : ", err);
      });
  };

  const handleSelectSort = (data: string) => {
    setStatusParams((prev) => {
      return {
        ...prev,
        order_by: data,
      };
    });
  };

  const banStatusMatchToThai = (status: string) => {
    if (status === "2 weeks") {
      return "การคาดโทษครั้งที่ 1";
    } else if (status === "1 months") {
      return "การคาดโทษครั้งที่ 2";
    } else if (status === "2 months") {
      return "การคาดโทษครั้งที่ 3";
    } else if (status === "permanent") {
      return "ลบบัญชีถาวร";
    } else {
      return "ปกติ";
    }
  };

  if (isLoading) return <Loading />

  return (
    <Box sx={classes.container}>
      <Box sx={classes.boxDetail}>
        <Box padding="24px">
          <Typography variant="h6">สถานะ</Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p="24px">
          {[
            {
              title: "ปกติ",
              value: banSummary?.normal_date,
              color: "#4CAF50",
            },
            {
              title: "การคาดโทษครั้งที่ 1",
              value: banSummary?.ban_no1_date,
              color: "#FB8C00",
            },
            {
              title: "การคาดโทษครั้งที่ 2",
              value: banSummary?.ban_no2_date,
              color: "#FB8C00",
            },
            {
              title: "การคาดโทษครั้งที่ 3",
              value: banSummary?.ban_no3_date,
              color: "#E30D18",
            },
            {
              title: "ลบบัญชีถาวร",
              value: banSummary?.ban_permanent_date,
              color: "#231F20",
            },
          ].map((item, index) => (
            <Box key={index}>
              <Typography>{item.title}</Typography>
              <Typography variant="h5" color={item.color}>
                {item.value ? dayjs(item.value).format("DD/MM/YYYY") : "-"}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt="24px" sx={classes.boxDetail}>
        <Box display="flex" justifyContent="space-between" padding="24px">
          <Typography variant="h6">ประวัติการเปลี่ยนแปลง</Typography>
          <BasicSelectCustom
            disabled={(statusList || []).length <= 0}
            id="admin-console-select-sort-referral"
            value={statusParams.order_by}
            onChange={(e) => handleSelectSort(e.target.value)}
            data={sortList}
            valueKey="value"
            labelKey="label"
            placeholder="select"
            sx={{ width: "240px" }}
          />
        </Box>

        {(statusList || []).length ? (
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {[
                    "วันที่สถานะเปลี่ยนแปลง",
                    "เหตุผลที่โดนเปลี่ยนสถานะ",
                    "วันที่แก้ไขสถานะ",
                    "เหตุผลที่แก้ไขสถานะ",
                    "แก้ไขโดย",
                  ].map((item, index) => (
                    <TableCellCustom key={index}>{item}</TableCellCustom>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {(statusList || []).map((item, index) => (
                  <TableRowCustom key={index}>
                    <TableCellCustom align="left">
                      <Typography>
                        {dayjs(item.start_ban_date).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography>
                        {dayjs(item.start_ban_date).format("HH:mm")} น.
                      </Typography>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Box
                        sx={
                          banStatusMatchToThai(item.ban_period_name) === "ปกติ"
                            ? classes.statusNormal
                            : classes.statusBan
                        }
                      >
                        <Typography variant="title2">
                          {banStatusMatchToThai(item.ban_period_name)}
                        </Typography>
                      </Box>
                      <Typography variant="body2">
                        • {item.status_info.active.reason}
                      </Typography>
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      {item.status_info.inactive !== null && (
                        <>
                          <Typography>
                            {dayjs(item.status_info.inactive.created_at).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                          <Typography>
                            {dayjs(item.status_info.inactive.created_at).format(
                              "HH:mm"
                            )}
                          </Typography>
                        </>
                      )}
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      {item.status_info.inactive !== null && (
                        <>
                          <Box sx={classes.statusNormal}>
                            <Typography variant="title2">ปกติ</Typography>
                          </Box>

                          <Typography variant="body2" key={index}>
                            • {item.status_info.inactive?.reason}
                          </Typography>
                        </>
                      )}
                    </TableCellCustom>

                    <TableCellCustom align="left">
                      <Stack direction="row" alignItems="center">
                        <Avatar variant="square" src={hashULogo} />
                        <Box ml={2.5}>
                          <Typography variant="title1" color="#8C499C">
                            HashU
                          </Typography>
                          <Typography>Automatic</Typography>
                        </Box>
                      </Stack>
                    </TableCellCustom>
                  </TableRowCustom>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={classes.noStatus}>
            <img
              src={require("@assets/images/infographic/influencer/search-not-found.png")}
              alt="no campaign"
            />
            <Box sx={classes.textNoStatus}>
              <Typography variant="h5" my="8px">
                ไม่พบผลการค้นหา
              </Typography>
              <Typography>โปรดทำการค้นหาใหม่อีกครั้ง</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
