import React, { useState } from "react";
// mui
import {
  Fab,
  Box,
  Avatar,
  Divider,
  useTheme,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
// style and icons
import {
  StyledMenu,
  useInfluencerSocialSyncDataStyle as classes,
} from "../InfluencerSocial.style";
// icon
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// other
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { ISyncDataProps } from "../InfluencerSocial.type";

export default function InfluencerSyncSocialData(props: ISyncDataProps) {
  const { profile, name, logOut, updated, platform, onClose } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("md"));

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectCategory = () => {
    if (xsScreen) history.push(`/select-category`, { profile, platform });
    if (!xsScreen) {
      setAnchorEl(null)
      onClose(true)
    };
  };

  return (
    <Box sx={classes.boxSyncSocial}>
      <Fab
        id="customized-button"
        size="small"
        sx={classes.fabButton}
        onClick={handleOpenMenu}
      >
        <ManageAccountsIcon />
      </Fab>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        sx={classes.menu}
      >
        <Box sx={classes.profileBox}>
          <Avatar src={profile?.profile_image} />
          <Typography>{name && name}</Typography>
          {updated && (
            <Typography>
              {`( Updated: ${dayjs(updated).format("DD/MM/YYYY HH:mm A")} )`}
            </Typography>
          )}
        </Box>
        <MenuItem onClick={handleSelectCategory} sx={classes.signOut}>
          <EditIcon />
          <Typography>Edit Category</Typography>
        </MenuItem>
        {logOut ? (
          <MenuItem onClick={logOut} sx={classes.signOut}>
            <DeleteIcon />
            <Typography>Logout {platform}</Typography>
          </MenuItem>
        ) : (
          <MenuItem sx={classes.signOut}>
            <DeleteIcon />
            <Typography>Logout {platform}</Typography>
          </MenuItem>
        )}

        <Divider />
        <MenuItem disabled sx={classes.addAccount}>
          <PersonAddIcon />
          <Typography>Add Account</Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}
