import { createContext, useEffect, useState } from "react";
// mui and style
import { Box, Grid, Link, Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useEmployerCampaignStyle as classes } from "./Detail.style";
// component
import PrivateIndex from "@components/campaign/campaign-private/PrivateIndex";
import CompanyDetail from "@components/campaign/campaign-detail/CompanyDetail";
import EmployerJobTab from "@components/campaign/campaign-detail/job-tab/JobTab";
import OrganizationDetail from "@components/campaign/campaign-detail/OrganizationDetail";
import CampaignReportTab from "@components/campaign/campaign-detail/report-tab/ReportTab";
import CampaignDetailTab from "@components/campaign/campaign-detail/detail-tab/DetailTab";
import IndexOrganizing from "@components/campaign/campaign-private/organizaing-the-list/IndexOrganizing";
import CampaignInfluencerListTab from "@components/campaign/campaign-detail/influencer-list-tab/InfluencerListTab";
import OfferringToInfluencer from "@components/campaign/campaign-private/offering-to-influencer/Offerring";
// redux
import {
  selectCampaignInfo,
  getCampaignInfoAsync,
  selectCampaignInfoOnload,
} from "@slices/CampaignInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getEmployerInfoAsync } from "@slices/EmployerInfo.slice";
// api and type
import { PaymentAPI } from "@api/global";
import { ICampaignInfoContext, ICampaignWalletBalance } from "./Detail.type";
// others
import Loading from "@components/global/loading/Loading";
import BackToTop from "@components/global/back-to-top/BackToTop";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { IPaymentResult } from "@components/subscription/Subscription.type";
import PaymentResultDialog from "@components/subscription/subscription-dialog/PaymentResult";

export const CampaignInfoContext = createContext<ICampaignInfoContext | null>(null);

export default function CampaignDetail() {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentResult, setPaymentResult] = useState<IPaymentResult>();
  const [campaignWalletBalance, setCampaignWalletBalance] = useState<ICampaignWalletBalance | null>(null)
  
  const history = useHistory();
  const location = useLocation();
  const { campaignId } = useParams<{ campaignId: string }>();

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const params = new URLSearchParams(location.search);
  const tabSelected = params.get("tab") || "details";
  const stepPrivate = params.get("st");
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const orderId = params.get("order_id");

  const dispatch = useAppDispatch();
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const isLoading = useAppSelector(selectCampaignInfoOnload)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCampaignInfoAsync(campaignId));
    dispatch(getEmployerInfoAsync(employerId))
  }, [dispatch, campaignId, employerId]);

  useEffect(() => {
    let isMounted = true;
    PaymentAPI.campaignWallet({ employer_id: employerId, campaign_id: campaignId })
      .then((response) => {
        if (isMounted) {
          setCampaignWalletBalance(response)
        }
      }
    )

    return () => {
      isMounted = false
    }
  },[employerId, campaignId])

  useEffect(() => {
    if (!orderId) return;
    PaymentAPI.getPaymentResult(orderId).then((response) => {
      setPaymentResult(response);
      setPaymentSuccess(true);
    });
  }, [orderId]);

  console.log("response", paymentResult)
  console.log("order id", orderId)

  const handleChangeTab = (tab: string) => {
    if (role === "employer") {
      history.replace(`/campaigns/${campaignId}/info?tab=${tab}`);
    } else {
      history.replace(`/campaigns/${campaignId}/info?emp_id=${employerId}&tab=${tab}`);
    }
  };

  if (isLoading) return <Loading />

  return (
    <CampaignInfoContext.Provider value={{ campaignWalletBalance }}>
      <BackToTop />
      <Box className="container-main" position="relative">
        <Box
          id="back-to-top-anchor"
          sx={{
            ...classes.jobboardCon,
            backgroundImage: {
              xs: `url(${campaignInfo?.brief.cover_image.url})`,
              md: "initial",
            },
            objectFit: "cover",
          }}
        >
          <Link
            onClick={() => history.goBack()}
            underline="none"
            sx={classes.callback}
          >
            <KeyboardArrowLeft />
            <span>Back</span>
          </Link>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={4} display={{ xs: "none", lg: "initial" }}>
            <OrganizationDetail campaignInfo={campaignInfo} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <CompanyDetail campaignInfo={campaignInfo} />
          </Grid>
          <Grid item xs={12} lg={4} display={{ xs: "initial", lg: "none" }}>
            <OrganizationDetail campaignInfo={campaignInfo} />
          </Grid>
        </Grid>
        <Box mt="25px">
          {campaignInfo?.status !== "briefing" && (
            <Box sx={classes.tabBox}>
              {["details", "influencer-list", "job", "report"].map(
                (tab, index) => (
                  <Box
                    key={index}
                    component="button"
                    sx={tabSelected === tab ? classes.tabSelected : classes.tab}
                    onClick={() => handleChangeTab(tab)}
                  >
                    <Typography
                      textTransform="capitalize"
                      fontWeight={tabSelected === tab ? 500 : 400}
                    >
                      {tab.replace(/-/, " ")}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          )}
          {stepPrivate === "private-influencer-list" ? (
            <PrivateIndex />
          ) : stepPrivate === "private-organizing" ? (
            <IndexOrganizing />
          ) : stepPrivate === "private-offering" ? (
            <OfferringToInfluencer />
          ) : (
            <>
              {tabSelected === "influencer-list" ? (
                <CampaignInfluencerListTab campaignInfo={campaignInfo} />
              ) : tabSelected === "job" ? (
                <EmployerJobTab campaignInfo={campaignInfo} />
              ) : tabSelected === "report" ? (
                <CampaignReportTab />
              ) : (
                <CampaignDetailTab campaignInfo={campaignInfo} />
              )}
            </>
          )}
        </Box>
      </Box>

      {/* dialog */}
      <PaymentResultDialog
        open={paymentSuccess}
        onClose={setPaymentSuccess}
        data={paymentResult}
        redirect={`/campaigns/${campaignId}/info?st=private-offering`}
      />
    </CampaignInfoContext.Provider>
  );
}
