export const useInviteFriendsStyle = {
  card: {
    p: ["16px", "24px"],
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.05)",
    zIndex: 2,
  },
  codeBox: {
    p: "16px",
    mt: "30px",
    borderRadius: "12px",
    border: "1px solid #E0E0E0",
  },
  codeBtn: {
    width: ["100%", "120px"],
    border: "none",
    "&:hover": { border: "none" },
  },
};

const timelineBtn = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bgcolor: "#F5F6F8",
  zIndex: 2,
  "& .MuiSvgIcon-root": {
    fontSize: "30px",
    color: "initial",
  },
};

export const useInviteFriendsActivityStyle = {
  image: {
    display: "flex",
    justifyContent: "center",
    my: "16px",
    "& > img": {
      width: "100%",
      maxWidth: "360px",
      height: "100%",
      borderRadius: "2px",
    },
  },
  timelineBtnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timelineFalseBtn: timelineBtn,
  timelineTrueBtn: {
    ...timelineBtn,
    bgcolor: "#4CAF50",
    "& .MuiSvgIcon-root": {
      fontSize: "30px",
      color: "#fff",
    },
  },
  timlineDivider: {
    position: "absolute",
    top: 20,
    border: "1px solid #F4ECFF",
    height: {
      xs: "140px",
      // sm: "200px",
    },
    zIndex: 1,
    left: "auto",
  },
};

export const useInviteFriendsNoActivityStyle = {
  total: {
    textAlign: "center",
    m: {
      xs: "20px 0 30px 0",
      md: "32px",
    },
    "& > h3": { color: "#8C499C" },
  },
  referralCodeLink: {
    height: "44px",
    borderRadius: "8px",
    bgcolor: "#F4ECFF",
    py: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > .MuiSvgIcon-root": {
      cursor: "pointer",
    },
    "& > h6": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "80%",
      whiteSpace: "nowrap",
    },
  },
  timelineDivider: {
    ...useInviteFriendsActivityStyle.timlineDivider,
    height: "200px",
  },
  timelineBtnBox: useInviteFriendsActivityStyle.timelineBtnBox,
  timelineBtn: timelineBtn,
};

export const useCurrentStyle = {
  card: {
    p: "24px",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.05)",
    "& > h6": {
      color: "#8C499C",
    },
    zIndex: 2,
  },
};

export const useCurrentActivityStyle = {
  rankingPodium: {
    display: "flex",
    mt: "225px",
    mb: "35px",
    "& .total": {
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "72px",
      height: "32px",
      mx: "auto",
      mt: "5px",
    },
    "& .cube-face1": {
      position: "absolute",
      width: "100px",
      height: "180px",
      lineHeight: "180px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
    },
    "& .cube-face2": {
      position: "absolute",
      width: "100px",
      height: "160px",
      lineHeight: "160px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
    },
    "& .cube-face3": {
      position: "absolute",
      width: "100px",
      height: "140px",
      lineHeight: "140px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
    },
    "& .cube-face4": {
      position: "absolute",
      width: "100px",
      height: "120px",
      lineHeight: "120px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
    },
    "& .cube-face5": {
      position: "absolute",
      width: "100px",
      height: "100px",
      lineHeight: "100px",
      fontSize: "20px",
      color: "white",
      textAlign: "center",
    },
    "& .cube-face1-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#FB8C00",
      borderLeft: "1px solid #fff",
      borderRight: "1px solid #fff",
    },
    "& .cube-face1-top": {
      transform: "rotateX( 69deg) translateZ(134.3px)",
      width: "91px",
      ml: "4px",
      mt: "10.6px",
      bgcolor: "#FB8C00",
      opacity: 0.6,
      borderLeft: "1px solid #fff",
    },
    "& .cube-face2-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#542485",
      borderLeft: "1px solid #fff",
      bottom: 0,
    },
    "& .cube-face2-top": {
      transform:
        "rotateX(68.8deg) translateZ(135.8px)  rotateZ(6deg) rotateY(-1.2deg)",
      width: "100px",
      ml: "11.4px",
      bgcolor: "#542485",
      opacity: 0.6,
      top: "42px",
    },
    "& .cube-face3-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#ED1968",
      borderRight: "1px solid #fff",
      borderLeft: "1px solid #fff",
      height: "140px",
      bottom: 0,
    },
    "& .cube-face3-top": {
      transform:
        "rotateX(69deg) translateZ(137.8px)  rotateZ(-7deg) rotateY(1.5deg)",
      width: "96.5px",
      ml: "-5.5px",
      bgcolor: "#ED1968",
      borderLeft: "1px solid #fff",
      opacity: 0.6,
      top: "76px",
    },
    "& .cube-face4-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#893DBD",
      zIndex: 1,
      height: "120px",
      bottom: 0,
    },
    "& .cube-face4-top": {
      transform:
        "rotateX(70deg) translateZ(134.6px)  rotateZ(10deg) rotateY(-2.1deg)",
      width: "104px",
      ml: "9px",
      bgcolor: "#893DBD",
      opacity: 0.6,
      top: "107px",
    },
    "& .cube-face5-front": {
      transform: "rotateY(0deg) translateZ(100px)",
      bgcolor: "#4A489D",
      zIndex: -1,
      height: "100px",
      bottom: 0,
      borderLeft: "1px solid #fff",
    },
    "& .cube-face5-top": {
      transform:
        "rotateX(70deg) translateZ(135px)  rotateZ(-7deg) rotateY(2.5deg) translateX(0px)",
      width: "102.5px",
      ml: "-6.1px",
      bgcolor: "#4A489D",
      opacity: 0.6,
      top: "140px",
      borderLeft: "1px solid #fff",
    },
    justifyContent: "center",
    alignItems: "center",
  },
  scene: {
    width: "100px",
    height: "180px",
    perspective: "390px",
  },
  cube: {
    width: "100px",
    height: "180px",
    position: " relative",
    transformStyle: "preserve-3d",
    transform: "translateZ(-100px)",
  },
  podiumDetail4: {
    position: "absolute",
    top: -105,
    left: 25,
  },
  podiumDetail2: {
    position: "absolute",
    top: -175,
    left: 15,
  },
  podiumDetail1: {
    position: "absolute",
    top: -220,
    left: 0,
  },
  podiumDetail3: {
    position: "absolute",
    top: -150,
    left: 0,
  },
  podiumDetail5: {
    position: "absolute",
    top: -90,
    left: 5,
  },
  pepleRanking4: {
    border: "1.5px solid #893DBD",
    borderRadius: "50%",
    width: "70px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pepleRanking2: {
    border: "1.5px solid #542485",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pepleRanking1: {
    border: "1.5px solid #FB8C00",
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > img": {
      position: "absolute",
      top: -20,
      right: 0,
      width: "45px",
      height: "40px",
      zIndex: 10,
      transform: "rotate(30deg)",
    },
  },
  pepleRanking3: {
    border: "1.5px solid #ED1968",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pepleRanking5: {
    border: "1.5px solid #4A489D",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  othersRankingBox: {
    height: "96px",
    borderRadius: "10px",
    justifyContent: "space-evenly",
    bgcolor: "#F5F6F8",
  },
  othersRaking: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  activityDetailBtn: {
    border: "none",
    width: "240px",
    "&:hover": { border: "none" },
  },
};

export const useCurrentNoActivityStyle = {
  con: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > img": { width: "100%", maxWidth: "360px", maxHeight: "360px" },
  },
};
