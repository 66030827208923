export const useSettingTabStyle = {
  container: {
    bgcolor: "#fff",
    borderRadius: "0 12px 12px 12px",
    p: "24px",
  },
  card: {
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    p: "24px",
  },
  compLogo: {
    borderRadius: "8px",
    width: "150px",
    height: "150px",
    mt: "6px",
    cursor: "pointer",
  },
  submitBtn: {
    fontSize: "16px",
    border: "none",
    "&:hover": { border: "none" },
    px: "30px",
  },
  deleteInfoBox: {
    p: "16px",
    borderRadius: "8px",
    bgcolor: "#FFF3F3",
  },
  deleteBtn: {
    fontSize: "16px",
    border: "none",
    bgcolor: "#E30D18",
    "&:hover": { border: "none", bgcolor: "#E30D18" },
    px: "50px",
    mt: "20px",
  },
  memberSelected: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    p: "12px",
    mt: "12px",
    maxWidth: "470px",
  },
  memberSelectedName: {
    ml: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

export const useTransferOwnershipStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  content: {
    my: "10px",
    p: "30px",
  },
  memberBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    my: "20px",
  },
  memberCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    p: "12px",
    mt: "12px",
  },
  memberCardDetail: {
    ml: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    columnGap: "20px",
    mt: "24px",
    "& > button": { width: "40%", fontSize: "16px" },
    "& > button:last-of-type": { border: "none" },
  },
};
