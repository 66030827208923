import { useMyJobsDetailStyle } from "@views/my-jobs/jobs-detail/JobsDetail.style";

export const useOldTeamStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  teamBox: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    padding: "24px",
    mt: "16px",
    "& .team-peofile": {
      width: "120px",
      height: "120px",
      borderRadius: "8px",
    },
  },
};

export const useInvitationsStyle = {
  dialog: {
    "& .MuiDialog-container .MuiPaper-root.MuiDialog-paper": {
      minWidth: ["90%", "90%", "90%", "670px"],
      margin: [1, 2, 3],
    },
  },
  invitationListBox: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "24px",
    "& .team-profile": {
      width: "64px",
      height: "64px",
      borderRadius: "8px",
    },
    "& .leader-profile": {
      width: "24px",
      height: "24px",
      marginRight: "8px",
    },
  },
  rejectedBtn: {
    p: "8px 16px",
    mr: "8px",
    ":hover": {
      bgcolor: "red",
      color: "#fff",
    },
    maxHeight:"40px"
  },
  acceptBtn: {
    p: "8px 16px",
    bgcolor: "#8C499C",
    color: "#fff",
    ":hover": {
      color: "#8C499C",
    },
    maxHeight:"44px"
  },
};

export const useTeamManagementStyle = {
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },
  cardCon: {
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05);",
  },

  // Member
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "8px",
    marginRight: "20px",
  },
  boxHeaderDetail: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  boxStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 10px",
    backgroundColor: "#FFEAF4",
    color: "#E8008A",
    borderRadius: "4px",
    height: "36px",
  },

  // members-tab
  tab: useMyJobsDetailStyle.tab,
  tabBox: useMyJobsDetailStyle.tabBox,
  tabSelected: useMyJobsDetailStyle.tabSelected,
  tabDisabled: useMyJobsDetailStyle.tabDisabled,
  card: {
    borderRadius: "0 0 20px 20px",
    p: "24px",
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px 0px #0000001A",
  },
  divider: {
    m: "24px 0",
  },
  paperSearch: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    height: "40px",
    padding: "0 10px",
  },
  buttonPermission: {
    padding: "8px 24px",
    ":hover": {
      backgroundColor: "#8C499C",
      color: "#fff",
      borderColor: "#8C499C",
    },
  },
  buttonAdd: {
    marginLeft: "4px",
    backgroundColor: "#8C499C",
    color: "#fff",
    padding: "8px 24px",
    ":hover": {
      color: "#8C499C",
    },
  },
};

export const useSpecifyAmountStyle = {
  con: {
    borderRadius: "12px",
    p: "24px",
    bgcolor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  specifyAmountBox: {
    display: "flex",
    flexDirection: "column",
    mt: "8px",
    rowGap: "8px",
  },
  specifyAmount: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    cursor: "pointer",
    p: "8px",
  },
  divider: {
    mt: "24px",
    mb: "16px",
    borderStyle: "dashed",
  },
  topUp: {
    display: "flex",
    justifyContent: "space-between",
    "& > h5": {
      fontWeight: 500,
      color: "#8C499C",
    },
  },
};
