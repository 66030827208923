import React, { useEffect, useState } from "react";
// mui
import {
  Box,
  Tab,
  Icon,
  Tabs,
  Drawer,
  Divider,
  useTheme,
  Accordion,
  Typography,
  useMediaQuery,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useHistory } from "react-router-dom";
// icons and style
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTutorialsStyle as classes } from "../HomeSupport.style";
// components
import Start from "@components/home-support/influencer-tutorials/Start";
import SyncSocia from "@components/home-support/influencer-tutorials/SyncSocial";
import MyProfile from "@components/home-support/influencer-tutorials/MyProfile";
// other
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint } from "@utils/helpers/setValueByBreakpoint.helpers";
import { ITabPanelProps } from "../../employer/employer-detail/Detail.type";

export default function InfluencerTutorials() {
  const history = useHistory();
  const bp = useBreakpoint();
  let index = Number(history.location.search.replace("?", ""));
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const socialMenu = [
    { key: "", value: "", title: "" },
    { key: "tiktok", value: "tiktok", title: "Tiktok" },
    { key: "twitter", value: "twitter", title: "Twitter" },
    { key: "facebook", value: "facebook", title: "Facebook" },
    { key: "instagram", value: "instagram", title: "Instagram" },
  ];

  const profileMenu = [
    { key: "a", value: "a", title: "A" },
    { key: "b", value: "b", title: "B" },
    { key: "c", value: "c", title: "C" },
    { key: "d", value: "d", title: "D" },
    { key: "e", value: "e", title: "F" },
    { key: "myprofile", value: "myprofile", title: "โปรไฟล์ของฉัน" },
  ];

  const [value, setValue] = useState(0);
  const [menuMobileState, setMenuMobileState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (index === 0) {
      setValue(0);
    } else if (index === 1) {
      setValue(1);
    } else {
      setValue(5);
    }
  }, [index]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const goBack = () => {
    history.goBack();
  };

  const TabPanel = (props: ITabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`social-tabpanel-${index}`}
        aria-labelledby={`social-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuMobileState(open);
    };

  return (
    <Box sx={classes.container}>
      <Box sx={{ marginTop: matches ? "80px" : "0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "& > .MuiSvgIcon-root": {
              fontSize: { xs: "18px", md: "12px" },
            },
          }}
          onClick={goBack}
        >
          <ArrowBackIosIcon />
          <Typography variant="buttonS">Back</Typography>
        </Box>

        <Typography
          variant={setValueByBreakpoint(bp, "h6", "h5", "h5", "h4")}
          sx={{ margin: "24px 0" }}
        >
          Influencer Tutorials
        </Typography>

        {matches ? (
          <Box
            onClick={toggleDrawer(true)}
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              padding: "20px",
              marginBottom: "24px",
              cursor: "pointer",
            }}
          >
            <Icon className="ico-hu-my-job" sx={{ color: "#616161" }} />
            <Typography sx={{ marginLeft: "12px", color: "#505050" }}>
              Table of Content
            </Typography>
          </Box>
        ) : (
          <></>
        )}

        <Box display="flex">
          {matches ? (
            <></>
          ) : (
            <Box width={"30%"}>
              <Accordion
                square={true}
                sx={{
                  boxShadow: "none",
                  backgroundColor: `#F5F6F8`,
                  widht: "100%",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="title1">เริ่มต้นการใช้งาน</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    <Tab
                      iconPosition="start"
                      label={
                        <Box sx={classes.tab}>
                          <Typography variant="title1">
                            การสร้างบัญชี HashU
                          </Typography>
                        </Box>
                      }
                    />
                  </Tabs>
                </AccordionDetails>
              </Accordion>
              {/* Social */}
              <Accordion
                square={true}
                sx={{
                  boxShadow: "none",
                  backgroundColor: `#F5F6F8`,
                  widht: "100%",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="title1">
                    การเชื่อมต่อ Social Media
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    {socialMenu.map((social, index) => {
                      if (index === 0) {
                        return <></>;
                      } else {
                        return (
                          <Tab
                            key={social.key}
                            iconPosition="start"
                            label={
                              <Box sx={classes.tab}>
                                <Typography variant="title1">
                                  การเชื่อมต่อ {social.title}
                                </Typography>
                              </Box>
                            }
                          />
                        );
                      }
                    })}
                  </Tabs>
                </AccordionDetails>
              </Accordion>
              {/* My Profile */}
              <Accordion
                square={true}
                sx={{
                  boxShadow: "none",
                  backgroundColor: `#F5F6F8`,
                  widht: "100%",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="title1">การจัดการบัญชี</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    {profileMenu.map((profile, index) => {
                      if (index === 5) {
                        return (
                          <Tab
                            key={profile.key}
                            iconPosition="start"
                            label={
                              <Box sx={classes.tab}>
                                <Typography variant="title1">
                                  {profile.title}
                                </Typography>
                              </Box>
                            }
                          />
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </Tabs>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}

          <Box width={["100%", "100%", "100%", "70%"]}>
            <Start TabPanel={TabPanel} tabSelected={value} />
            <>
              {socialMenu.map((social, index) => {
                if (index === 0) {
                  return <></>;
                } else {
                  return (
                    <SyncSocia
                      TabPanel={TabPanel}
                      tabSelected={value}
                      index={index}
                      social={social.title}
                    />
                  );
                }
              })}
            </>
            <>
              {profileMenu.map((profile, index) => {
                if (index === 5) {
                  return (
                    <MyProfile
                      TabPanel={TabPanel}
                      tabSelected={value}
                      index={index}
                      profile={profile}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </>
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor="left"
        open={menuMobileState}
        onClose={toggleDrawer(false)}
        sx={{
          "& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper": {
            borderRadius: "0 16px 16px 0",
          },
        }}
      >
        <Box sx={{ width: [300, 320, 320] }}>
          <Box sx={{ padding: "16px 0 16px 20px", color: "#893DBD" }}>
            <Typography variant="h6">Table of Content</Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: "16px" }}>
            <Accordion
              square={true}
              sx={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="title1">เริ่มต้นการใช้งาน</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  onClick={toggleDrawer(false)}
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab
                    iconPosition="start"
                    label={
                      <Box sx={classes.tab}>
                        <Typography variant="title1">
                          การสร้างบัญชี HashU
                        </Typography>
                      </Box>
                    }
                  />
                </Tabs>
              </AccordionDetails>
            </Accordion>
            {/* Social  */}
            <Accordion
              square={true}
              sx={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="title1">
                  การเชื่อมต่อ Social Media
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  onClick={toggleDrawer(false)}
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {socialMenu.map((social, index) => {
                    if (index === 0) {
                      return <></>;
                    } else {
                      return (
                        <Tab
                          key={social.key}
                          iconPosition="start"
                          label={
                            <Box sx={classes.tab}>
                              <Typography variant="title1">
                                การเชื่อมต่อ {social.title}
                              </Typography>
                            </Box>
                          }
                        />
                      );
                    }
                  })}
                </Tabs>
              </AccordionDetails>
            </Accordion>
            {/* My Profile */}
            <Accordion
              square={true}
              sx={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="title1">การจัดการบัญชี</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  onClick={toggleDrawer(false)}
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {profileMenu.map((profile, index) => {
                    if (index === 5) {
                      return (
                        <Tab
                          key={profile.key}
                          iconPosition="start"
                          label={
                            <Box sx={classes.tab}>
                              <Typography variant="title1">
                                {profile.title}
                              </Typography>
                            </Box>
                          }
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </Tabs>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
