// mui
import {
  Box,
  Icon,
  Paper,
  Stack,
  Table,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
// style
import {
  TableRowCustom,
  TableCellCustom,
  useInfluencerHistoryStyle as classes,
} from "./Earning.style";
// type and other
import { IEarningItemInfo } from "@api/influencer/type";
import GetPlatformColor from "../global/get-platform/GetPlatformColor";
import dayjs from "dayjs";

export default function InfluencerHistoryLg({ earningList }: { earningList: IEarningItemInfo[] }) {
  return (
    <TableContainer
      sx={{ display: { xs: "none", md: "block" } }}
      component={Paper}
    >
      <Table sx={{ minWidth: [980, 1100] }}>
        <TableHead>
          <TableRow>
            {["เเคมเปญ", "ผู้รับเงิน", "วันสิ้นสุดแคมเปญ"].map((title, index) => (
              <TableCellCustom key={index}>{title}</TableCellCustom>
            ))}
            {["วันที่รับเงิน", "จำนวนเงิน", "สถานะ", "เอกสาร"].map((title, index) => (
              <TableCellCustom key={index} align="center">{title}</TableCellCustom>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={classes.tableBody}>
          {earningList.map((item, index) => (
            <TableRowCustom key={index}>
              <TableCellCustom>
                <Stack direction="row" alignItems="center">
                  <Avatar
                    sx={classes.campaignIcon}
                    alt="Campaign"
                    src={item.brand_logo_image_url}
                  />
                  <Box ml={2.5}>
                    <Typography variant="title1" color="primary">
                      {item.campaign_name}
                    </Typography>
                    <Typography variant="body2" fontSize={["12px", "14px"]}>
                      Transaction ID : {item.transaction_id}
                    </Typography>
                  </Box>
                </Stack>
              </TableCellCustom>
              <TableCellCustom>
                <Stack direction="row" alignItems="center">
                  <GetPlatformColor platform={item.platform} />
                  <Typography variant="title1" ml={2} sx={classes.receipName}>
                    {item.fullname}
                  </Typography>
                </Stack>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Typography variant="title1">
                  {item.completed_date ? dayjs(item.completed_date).format("DD-MM-YYYY") : "-"}
                </Typography>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Typography variant="title1">
                  {item.receipt_date ? dayjs(item.receipt_date).format("DD-MM-YYYY") : "-"}
                </Typography>
              </TableCellCustom>
              <TableCellCustom>
                <Typography variant="title1" textTransform="capitalize">
                  {`${new Intl.NumberFormat(
                    "jp-JP",
                    { minimumFractionDigits: 2 }
                  ).format(item.amount)} THB`}
                  </Typography>
              </TableCellCustom>
              <TableCellCustom align="center" sx={{ padding: "auto" }}>
                <Box
                  sx={item.status === "paid" ? classes.statusPaid : classes.statusPending}
                >
                  <Typography variant="body2" fontWeight={500}>
                    {item.status}
                  </Typography>
                </Box>
              </TableCellCustom>
              <TableCellCustom align="center">
                <Button
                  onClick={() => window.open(item.reciept_info.pdf_url)}
                  sx={classes.button}
                  disabled={item.status === "pending"}
                >
                  <Icon className="ico-hu-download-circle" sx={{ mr: "4px" }} />
                  <Typography variant="buttonM">ใบเสร็จ</Typography>
                </Button>
                {item.amount >= 1000 && (
                  <Button
                    onClick={() => window.open(item.tax_info.pdf_url)}
                    sx={classes.taxButton}
                    disabled={item.status === "pending" ? true : false}
                  >
                    <Icon
                      className="ico-hu-download-circle"
                      sx={{ mr: "4px" }}
                    />
                    <Typography variant="buttonM">หัก ณ ที่จ่าย</Typography>
                  </Button>
                )}
              </TableCellCustom>
            </TableRowCustom>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
