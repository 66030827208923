export const expenseStyles = {
  expenseBox: {
    borderRadius: ["none", "none", "15px"],
    backgroundColor: ["none", "none", "#fff"],
    boxShadow: ["none", "none", "0px 4px 8px rgba(0, 0, 0, 0.1)"],
  },
  // noExpese:{
  //   bgcolor: "#fff",
  //   borderRadius: "16px",
  //   height: {
  //     xs: "75vh",
  //     xl: "80vh",
  //   },
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   flexDirection: "column",
  //   "& img": {
  //     width: { xs: "400px", xl: "500px" },
  //     height: { xs: "400px", xl: "500px" },
  //   },
  // },
  pagination: {
    display: "flex",
    justifyContent: "center",
    mt: "24px",
    "& .MuiGrid-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none", md: "flex" },
    },
    "& .css-1gdk4t4-MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root":
      {
        width: {
          xs: "36px",
          md: "40px",
        },
        height: {
          xs: "36px",
          md: "40px",
        },
      },
    bottom: 30,
  },
};
