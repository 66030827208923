import {
  IinitEstimateSummary,
  IInfluencerActiveInfoParams,
} from "../../InfluencerList.type";

export const initEstimateSummary: IinitEstimateSummary = {
  followers: 0,
  likes: 0,
  comments: 0,
  shares: 0,
  totalInfluencerSelected: 0,
};

export const initialParams: IInfluencerActiveInfoParams = {
  page: 1,
  item_per_page: 15,
  status: "listing",
  order_by: "created_date",
  sort: "desc",
  category: "",
};

export const sortPublic = [
  {
    value: "created_date",
    label: "เรียงตามลำดับการสมัคร",
    order_by: "created_date",
    sort: "DESC",
  },
  {
    value: "most_follower",
    label: "Most Followers",
    order_by: "most_follower ",
    sort: "DESC",
  },
  {
    value: "most_engagement",
    label: "Most Engagement Rate",
    order_by: "most_engagement ",
    sort: "DESC",
  },
];

export const sortPrivate = [
  {
    value: "followers",
    label: "Most Followers",
    order_by: "most_follower ",
    sort: "DESC",
  },
  {
    value: "engagement",
    label: "Most Engagement Rate",
    order_by: "most_engagement ",
    sort: "DESC",
  },
  {
    value: "asc",
    label: "Rate Card จากมาก - น้อย",
    order_by: "rate_card",
    sort: "DESC",
  },
  {
    value: "desc",
    label: "Rate Card จากน้อย - มาก",
    order_by: "rate_card",
    sort: "DESC",
  },
];

export const genderList = [
  { id: "male", value: "male", title: "Male" },
  { id: "female", value: "female", title: "Female" },
  { id: "lgbtq", value: "lgbtq", title: "LGBTQ+" },
];

export const followers = [
  {
    value: "nano",
    label: "Nano 1,000 - 10,000",
    sort: {
      ltq: 1000,
      gtq: 10000,
    },
  },
  {
    value: "micro",
    label: "Micro 10,000 - 50,000",
    sort: {
      ltq: 50000,
      gtq: 10000,
    },
  },
  {
    value: "midTier",
    label: "Mid-Tier 50,000 - 500,000",
    sort: {
      ltq: 500000,
      gtq: 50000,
    },
  },
  {
    value: "macro",
    label: "Macro 500,000 - 1,000,000",
    sort: {
      ltq: 1000000,
      gtq: 500000,
    },
  },
  {
    value: "mega",
    label: "Mega 1,000,000+",
    sort: {
      ltq: 10,
      gtq: 0,
    },
  },
];
