import { useEffect, useState } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Link,
  Stack,
  Button,
  Typography,
} from "@mui/material";
// style and icon
import { useAddShippingStyle as classes } from "./AddShipping.style";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CircleIcon from "@mui/icons-material/Circle";
// api and type
import { JobAPI } from "@api/global";
import { IJobEmployerInfoRes } from "@api/job/type";
// component
import ShippingProduct from "./ShippingProduct";
import ConversionTracking from "./ConversionTracking";
// othder
import { useForm } from "react-hook-form";
import Loading from "@global/loading/Loading";
import GetPlatform from "@global/get-platform";
import { initShippingProduct } from "./ShippingTypeData";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export default function AddShipping() {
  const [isLoading, setIsLoading] = useState(false);
  const [jobEmpList, setJobEmpList] = useState<IJobEmployerInfoRes>();

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const jobId = params.get("job_id") || "";
  const { campaignId } = useParams<{ campaignId: string }>();

  const {
    reset,
    watch,
    setValue,
    register,
    getValues,
    clearErrors,
    handleSubmit,
  } = useForm<any>({
    defaultValues: initShippingProduct,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!jobEmpList) return;

    if (jobEmpList.conversion === null) {
      const form = {
        product_shipping: {
          shipping_type: jobEmpList.shipping_address?.shipping_type,
          tracking_no: jobEmpList.shipping_address?.tracking_no,
          note: jobEmpList.shipping_address?.note,
        },
      };
      return reset(form);
    } else {
      const form = {
        product_shipping: {
          shipping_type: jobEmpList.shipping_address?.shipping_type,
          tracking_no: jobEmpList.shipping_address?.tracking_no,
          note: jobEmpList.shipping_address?.note,
        },
        conversion: {
          lead_link: jobEmpList.conversion.lead_link,
          coupon: jobEmpList.conversion.coupon,
          coupon_name: jobEmpList.conversion.coupon_name,
          note: jobEmpList.conversion.note,
          start_validity_period: jobEmpList.conversion.start_validity_period,
          end_validity_period: jobEmpList.conversion.end_validity_period,
        },
      };
      return reset(form);
    }
  }, [jobEmpList, reset]);

  useEffect(() => {
    let isMounted = true;
    JobAPI.getEmployerJobInfo(employerId, jobId)
      .then((response) => {
        if (isMounted) {
          setJobEmpList(response);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [employerId, jobId]);

  const onEditShipping = async (data: any) => {
    JobAPI.updateEmployerJobShipping(employerId, jobId, data).then(() => {
      SwalCustom.fire({
        icon: "success",
        title: "Success !",
        text: `บันทึกเรียบร้อย`,
        confirmButtonColor: "#4CAF50",
        confirmButtonText: "กลับไปหน้า Influencer List",
        showCancelButton: true,
        cancelButtonText: "Close",
      }).then((response) => {
        if (response.isConfirmed) {
          if (role === "employer") {
            history.push(`/campaigns/${campaignId}/info?tab=influencer-list`);
          } else {
            history.push(
              `/campaigns/${campaignId}/info?tab=influencer-list&emp_id=${employerId}`
            );
          }
        }
      });
    });
  };

  const totalEngagement = Math.round((jobEmpList?.social_account.engagement_rate || 0) * 100) / 100;

  if (isLoading) return <Loading />
  
  return (
    <div className="container-main">
      <Link
        onClick={() => history.goBack()}
        underline="none"
        sx={classes.callBack}
      >
        <KeyboardArrowLeftIcon />
        <span>Back</span>
      </Link>

      <form onSubmit={handleSubmit(onEditShipping)}>
        <Box sx={classes.card}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={3}>
              <Box sx={classes.boxImage}>
                <img
                  alt="add-shipping-photos"
                  src={jobEmpList?.social_account.profile_image_url}
                />
                <Box sx={classes.boxDetail}>
                  <Box padding="12px">
                    <Box display="flex" alignItems="center">
                      <GetPlatform
                        platform={jobEmpList?.social_account.platform || ""}
                      />
                      <Typography>{jobEmpList?.social_account.name}</Typography>
                    </Box>
                    <Stack
                      divider={<CircleIcon />}
                      sx={classes.stackCategory}
                    >
                      {jobEmpList?.campaign_info.category.map((cat, index) => (
                        <Typography key={index} sx={classes.textCategory}>
                          {cat.name_en}
                        </Typography>
                      ))}
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <Box display="flex" alignItems="center" width="100%">
                        <Icon
                          className="ico-hu-group"
                          sx={{ fontSize: "16px" }}
                        />
                        <Typography ml={1.5}>
                          {jobEmpList?.social_account.follower}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" width="100%">
                        <Icon
                          className="ico-hu-engagement-rate"
                          sx={{ fontSize: "16px" }}
                        />
                        <Typography ml={1.5}>{totalEngagement}%</Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={9}>
              {jobEmpList?.shipping_address !== null && (
                <Box mt={["24px", "24px", 0]}>
                  <ShippingProduct
                    item={jobEmpList?.shipping_address || null}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    watch={watch}
                  />
                </Box>
              )}

              {jobEmpList?.conversion !== null && (
                <Box
                  mt={
                    jobEmpList?.shipping_address &&
                    jobEmpList.conversion !== null
                      ? "24px"
                      : jobEmpList?.conversion !== null
                      ? ["24px", "24px", 0]
                      : 0
                  }
                >
                  <ConversionTracking
                    item={jobEmpList?.conversion || null}
                    getValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    register={register}
                    watch={watch}
                  />
                </Box>
              )}
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" mt="28px">
            <Button type="submit" sx={classes.buttonSubmit}>
              <FileCopyOutlinedIcon color="inherit" sx={{ mr: "12px" }} />
              <Typography variant="buttonM">บันทึก</Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
}
