// mui and icons
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// type and style
import { IBudgetAndRewardDialogProps } from "../CampaignDetail.type";
import { useBudgetAndRewardDialogStyle as classes } from "../CampaignDetail.style";

export default function BudgetAndRewardDialog(
  props: IBudgetAndRewardDialogProps
) {
  const { open, handleOpen, campaignDetail } = props;

  const handleClose = () => {
    handleOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={classes.dialog}
    >
      <DialogTitle
        sx={{ ...classes.dialogTitle, width: "91%", mt: "10px" }}
        id="alert-dialog-title"
      >
        <Typography>สินค้าหรือบริการที่อินฟลูเอนเซอร์จะได้รับ</Typography>
        <IconButton onClick={handleClose}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.cardContent}>
        <img src={campaignDetail.image.url} alt="Product" />
        <Typography color="primary" fontSize="20px" my="5px">
          {campaignDetail.name}
        </Typography>
        <Typography>{campaignDetail.description}</Typography>
        <Box sx={classes.dialogAction}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
