import { useEffect, useState } from "react";
// mui asn style
import {
  Box,
  Grid,
  Icon,
  Paper,
  Button,
  InputBase,
  IconButton,
  Typography,
} from "@mui/material";
import { useListingStyle as classes } from "./ListingTab.style";
// components
import CardInfluencer from "./default/InfluencerCard";
import ListingTabFilter from "./default/ListingTabFilter";
import Loading from "@components/global/loading/Loading";
// type and api
import { ICategory } from "@api/app-information/type";
import { IGetInfluencerListParams, IInfluencerList } from "@api/dashboard/type";
import {
  //   IInfluecerListParams,
  IInfluecerListQueryParams,
} from "./ListingTab.type";
import { AppInformationAPI, DashboardAPI } from "@api/global";
// other
import PaginationCustom from "@global/pagination-custom";
import { useHistory, useLocation } from "react-router-dom";

const numberFormat = Intl.NumberFormat("en");

export default function ListingTab() {
  const [influencerList, setInfluencerList] = useState<IInfluencerList | null>(null);
  
  const data = influencerList?.data;
  const stats = influencerList?.stats
  const totalItems = data?.total_item || 0;
  
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = Number(params.get("page")) || 1;
  const platform = params.get("platform") || "";
  const banStatus = params.get("status") || "";
  const gender = params.get("gender") || "";
  const q = params.get("q") || "";
  const cate = params.get("cat");
  const categories = cate ? cate.split(",").map(Number) : [];
  const age = params.get("age");
  const ages = age ? age.split(",") : [];
  const province = params.get("prov");
  const provinces = province ? province.split(",").map(Number) : [];
  const totalFilter = (platform ? 1 : 0) + (banStatus ? 1 : 0) + (gender ? 1 : 0) + (q ? 1 : 0) + (categories.length ? 1 : 0) + (ages.length ? 1 : 0) + (provinces.length ? 1 : 0) 
  
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState(q)

  const paramList: IInfluecerListQueryParams = {
    age: ages,
    cat: categories,
    gender: gender,
    page: page,
    platform: platform,
    prov: provinces,
    q: q,
    status: banStatus
  };

  useEffect(() => {
    let isMounted = true;
    const getInfluencerList = () => {
      const param: IGetInfluencerListParams = {
        page: page,
        item_per_page: 20,
        platform: platform,
        status: banStatus,
        age: ages,
        category: categories,
        gender: gender,
        location: provinces,
        search: q,
      };
      setIsLoading(true);
      DashboardAPI.getInfluencerList(param)
        .then((response) => {
          if (isMounted) {
            setInfluencerList(response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (isMounted) {
            setIsLoading(false);
            console.log("get influencer list error : ", err);
          }
        });
    };
    getInfluencerList();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line 
  }, [page, platform, banStatus, age, cate, gender, province, q]);

  useEffect(() => {
    let isMounted = true;
    AppInformationAPI.getCategoryList().then((response) => {
      if (isMounted) {
        const category = response.map((item: ICategory) => {
          return {
            id: item.id,
            label: item.name_en,
            value: item.value,
            name_th: item.name_th,
            name_en: item.name_en,
          };
        });
        setCategoryList(category);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChangePage = (_event: unknown, pageValue: number) => {
    const params: IInfluecerListQueryParams = {
      ...paramList, page: pageValue
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof IInfluecerListQueryParams]}`)
      .join("&");
    history.replace(`/influencers-dashboard?tab=list&${queryString}`);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowPerPage);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target

    if (value === '') {
      const params: IInfluecerListQueryParams = {
        ...paramList, q: value, page: 1
      };
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key as keyof IInfluecerListQueryParams]}`)
        .join("&");
      history.replace(`/influencers-dashboard?tab=list&${queryString}`);
    } else {
      setSearch(value)
    }
  }

  const onSearch = () => {
    const params: IInfluecerListQueryParams = {
      ...paramList, q: search, page: 1
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key as keyof IInfluecerListQueryParams]}`)
      .join("&");
    history.replace(`/influencers-dashboard?tab=list&${queryString}`);
  }

  if (isLoading) return <Loading />

  return (
    <>
      {/* filter */}
      <ListingTabFilter
        open={openFilter}
        onClose={setOpenFilter}
        categoryList={categoryList}
      />
      <Box sx={classes.card}>
        <Typography variant="h6">Statistics</Typography>
        <Box sx={classes.header}>
          {[
            { label: "ทั้งหมด", value: stats?.total, color: "#893DBD" },
            { label: "ปกติ", value: stats?.active, color: "#4CAF50" },
            { label: "คาดโทษครั้งที่ 1", value: stats?.ban_1, color: "#FB8C00" },
            { label: "คาดโทษครั้งที่ 2", value: stats?.ban_2, color: "#FB8C00" },
            { label: "คาดโทษครั้งที่ 3", value: stats?.ban_3, color: "#FB8C00" },
            { label: "คาดโทษครั้งที่ 4", value: stats?.ban_4, color: "#E30D18" },
            { label: "ลบบัญชีถาวร", value: stats?.removed, color: "#231F20" },
          ].map((item, index) => (
            <Box key={index} minWidth="100px">
              <Typography variant="subtitle1">{item.label}</Typography>
              <Typography variant="h4" color={item.color}>
                {numberFormat.format(item.value || 0)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt="20px" sx={classes.card}>
        <Box sx={classes.boxTitle}>
          <Typography variant="body1">
            {data?.data.length} of {data?.total_item} results
          </Typography>
          <Box gap={2} display="flex" justifyContent="flex-end">
            <Paper sx={classes.search}>
              <InputBase
                sx={{ ml: 4, flex: 1 }}
                placeholder="search by campaign name, platform"
                inputProps={{
                  "aria-label": "addmin-console-search-earning",
                }}
                value={search || q}
                onChange={handleSearchChange}
              />
              <IconButton onClick={onSearch} sx={{ p: "10px" }} aria-label="search">
                <Icon className="ico-hu-search" />
              </IconButton>
            </Paper>
            <Button onClick={() => setOpenFilter(true)} sx={classes.filterBtn}>
              <Typography whiteSpace="nowrap" color="#231F20">
                All Filter {totalFilter || ""}
              </Typography>
              <Icon
                className="ico-hu-filter"
                sx={{ ml: "8px", fontSize: "16px" }}
              />
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2} mt="20px">
          {data?.data.map((item) => (
            <Grid key={item.id} item xs={12} sm={4} md={4} lg={2.4}>
              <CardInfluencer item={item} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {data?.data.length ? (
        <Box sx={classes.pagination}>
          <PaginationCustom
            page={page}
            count={page > 0 ? Math.ceil(totalItems / rowsPerPage) : 0}
            onPaginationChange={handleChangePage}
            rowsPerPageData={[]}
            rowsPerPage={rowsPerPage}
            onRowPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : null}
    </>
  );
}
