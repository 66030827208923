import React from "react";
// mui and icon
import {
  Box,
  Icon,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// type and style
import { IInfluencerTiersTabProps } from "../../../OverviewTab.type";
import { useContentDistributionStyle as classes } from "../../../OverviewTab.style";
// others
import { createData } from "../InfluencerTiers";

const tableHeadList = [
  {
    label: "Category",
    table: "",
    sort: "",
  },
  {
    label: "Total",
    table: "tiers.total",
    sort: "DESC",
  },
  {
    label: "Mega",
    table: "tiers.mega",
    sort: "DESC",
  },
  {
    label: "Macro",
    table: "tiers.macro",
    sort: "DESC",
  },
  {
    label: "Mid-Tier",
    table: "tiers.mid_tier",
    sort: "DESC",
  },
  {
    label: "Micro",
    table: "tiers.micro",
    sort: "DESC",
  },
  {
    label: "Nano",
    table: "tiers.nano",
    sort: "DESC",
  },
];

export default function InfluencerTiersTab(props: IInfluencerTiersTabProps) {
  const {
    param,
    isHeatMap,
    heatMapList,
    heatMapType,
    onSortTable,
    cateDistTier,
  } = props;

  const total = cateDistTier?.total || [];
  const mega = cateDistTier?.mega || [];
  const macro = cateDistTier?.macro || [];
  const midTier = cateDistTier?.mid_tier || [];
  const micro = cateDistTier?.micro || [];
  const nano = cateDistTier?.nano || [];
  const sort = param?.sort;

  const tierList = [...total.map((t) => t)].map((label, i) =>
    createData(
      label,
      total?.[i],
      mega?.[i],
      macro?.[i],
      midTier?.[i],
      micro?.[i],
      nano?.[i]
    )
  );

  const matchHeatMap = (data?: { number: number; percent: number }) => {
    const value = heatMapType === "percent" ? data?.percent : data?.number;
    if (isHeatMap) {
      for (let i = 0; i < heatMapList.length; i++) {
        const heatMap = heatMapList[i];
        const val = value || 0;
        if (val >= heatMap.start && val < heatMap.end) {
          return heatMap.color;
        }
      }
    } else {
      return "initial";
    }
  };

  const matchHeatMapColor = (data?: { number: number; percent: number }) => {
    const bgcolor = matchHeatMap(data);
    const isDarkColor =
      bgcolor === "#542485" || bgcolor === "#893DBD" ? "#fff" : "initial";
    const matchColor = {
      bgcolor: bgcolor,
      color: isDarkColor,
    };
    return matchColor;
  };

  const numberFormat = (val?: { number: number; percent: number }) => {
    if (val) {
      const number = val.number === 0 ? 0 : Intl.NumberFormat("en").format(val.number);
      return `${number || 0} (${val.percent.toFixed(2)}%)`;
    }
  };

  return (
    <TableContainer sx={classes.tableCon} component={Paper}>
      <Table sx={{ minWidth: "300px" }}>
        <TableHead>
          <TableRow>
            {tableHeadList.map((row, index) => (
              <TableCell key={index} sx={{ bgcolor: "#F5F6F8" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={index === 0 ? "start" : "end"}
                >
                  <Typography variant="title2" mr={1.5}>
                    {row.label}
                  </Typography>
                  {row.table === sort?.table && sort?.sort === "ASC" ? (
                    <ArrowUpwardIcon
                      onClick={() => onSortTable(row.table, "DESC")}
                      fontSize="small"
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    index !== 0 && (
                      <ArrowDownwardIcon
                        onClick={() => onSortTable(row.table, "ASC")}
                        fontSize="small"
                        sx={{
                          cursor: "pointer",
                          color:
                            row.table === sort?.table
                              ? "#000"
                              : "rgba(0,0,0,0.5)",
                        }}
                      />
                    )
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={classes.tableBody}>
          {tierList.map((row, index) => (
            <TableRow key={index}>
              {[
                { label: row.label },
                { total: row.total },
                { total: row.facebook },
                { total: row.instagram },
                { total: row.twitter },
                { total: row.tiktok },
                { total: row.youtube },
              ].map((row, index) => (
                <React.Fragment key={index}>
                  {row.label ? (
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Icon
                          className={`ico-hu-${row.label.icon_name}`}
                          sx={{ mr: "10px" }}
                        />
                        <Typography variant="title1">
                          {row.label.label === "total"
                            ? "1 บัญชีสามารถเลือกได้สูงสุด 3 หมวดหมู่"
                            : row.label.label}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell
                      align="right"
                      sx={{ ...matchHeatMapColor(row.total) }}
                    >
                      <Typography
                        variant={index === 1 ? "title2" : "body2"}
                        pr={2}
                      >
                        {numberFormat(row.total)}
                      </Typography>
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
