import { useState, useEffect } from "react";
// mui, icon and style
import {
  Box,
  Grid,
  Link,
  Stack,
  Button,
  Divider,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useJobBoardDetailStyle as classes } from "./JobBoardDetail.style";
// component
import Timeline from "@components/global/campaign-detail/Timeline";
import DoOrDoNot from "@components/global/campaign-detail/DoOrDoNot";
import CampaignBrief from "@components/global/campaign-detail/CampaignBrief";
import CompanyDetail from "@components/campaign/campaign-detail/CompanyDetail";
import TargetAudience from "@components/global/campaign-detail/TargetAudience";
import BudgetAndReward from "@components/global/campaign-detail/BudgetAndReward";
import CampaignOverview from "@components/global/campaign-detail/CampaignOverview";
import RejectOfferingDialog from "@components/job-board/job-board-dialog/RejectOffering";
import SocialMediaAccount from "@components/global/campaign-detail/SocialMediaAccount";
import TermsAndConditions from "@components/global/campaign-detail/TermsAndConditions";
import TimelineDetailDialog from "@components/global/campaign-detail/detail-dialog/TimelineDetails";
import RegistCampaignSuccessDialog from "@components/job-board/job-board-dialog/RegistCampaignSuccess";
// redux
import {
  selectCampaignInfo,
  setSnackBarIsActive,
  getCampaignInfoAsync,
  selectCampaignInfoOnload,
} from "@slices/CampaignInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import {
  selectInfluencerJobInfo,
  getInfluencerJobInfoAsync,
  selectInfluencerJobInfoOnload,
} from "@slices/InfluencerJobInfo.slice";
// api and type
import { JobAPI } from "@api/global";
import { ICreateJobPrivate } from "../JobBoard.type";
import { IInitCreateJob } from "@components/campaign/campaign-detail/CampaignDetail.type";
// others
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BackToTop from "@components/global/back-to-top/BackToTop";
import Loading from "@components/global/loading/Loading";
import { scrollToTop } from "@utils/helpers/scrollToTop";

const initCreateJob = {
  campaign_id: "",
  social_account_id: 0,
  status: "listing",
  tier: "",
  price: 0,
  price_currency: "baht",
  term_and_condition: [],
};

const initSuccessDetail = {
  publish_date: "",
  influencer_id: "",
  job_id: "",
  campaign_type: "",
};

export default function JobBoardDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [socialId, setSocialId] = useState<number>();
  const [createJobPublic, setCreateJobPublic] = useState<IInitCreateJob>(initCreateJob);
  const [createJobPrivate, setCreateJobPrivate] = useState<ICreateJobPrivate[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogSelected, setDialogSelected] = useState("");
  const [successDialogDetail, setSuccessDialogDetail] =
    useState(initSuccessDetail);

  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const params = new URLSearchParams(location.search);
  const campaignType = params.get("c_type");

  const { session_token } = getTokenFromStorage();
  const role = session_token?.role;
  const influeId = session_token?.influencer_id || "";

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const dispatch = useAppDispatch();
  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const jobInfoLoading = useAppSelector(selectInfluencerJobInfoOnload);
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const campaignLoading = useAppSelector(selectCampaignInfoOnload);

  const isPrivate = campaignType === "private";
  const isPublic = campaignType === "public";
  const campaign = isPrivate ? jobInfo?.campaign_info : campaignInfo;
  const condtionList = campaign?.brief.term_and_condition;

  useEffect(() => {
    scrollToTop();
    if (isPrivate) {
      const params = {
        jobId: id,
        influencerId: session_token?.influencer_id || "",
      };
      dispatch(getInfluencerJobInfoAsync(params));
    } else {
      dispatch(getCampaignInfoAsync(id));
    }
    dispatch(setSnackBarIsActive(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, campaignType]);

  useEffect(() => {
    if (campaignInfo && isPublic) {
      const conditions = campaignInfo.brief.term_and_condition;
      setCreateJobPublic((prev) => {
        return {
          ...prev,
          campaign_id: campaignInfo.campaign_long_id,
          term_and_condition: conditions.map((cond, index) => {
            return {
              ...cond,
              index: index,
              status: false,
            };
          }),
        };
      });
    } else if (jobInfo && isPrivate) {
      const conditions = jobInfo.campaign_info.brief.term_and_condition;
      const jobPrivate = conditions.map((cond, index) => {
        return {
          ...cond,
          status: false,
          index: index,
        };
      });
      setCreateJobPrivate(jobPrivate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfo, jobInfo, campaignType]);

  const handleCreateJob = (
    e: { target: { checked: boolean } },
    order: number
  ) => {
    const campaign = isPublic
      ? [...createJobPublic.term_and_condition]
      : [...createJobPrivate];
    const campaignIndex = campaign.map((camp) => {
      if (camp.index === order) {
        return {
          ...camp,
          status: e.target.checked,
        };
      }
      return camp;
    });
    if (isPublic) {
      setCreateJobPublic({
        ...createJobPublic,
        term_and_condition: campaignIndex,
      });
    } else {
      setCreateJobPrivate(campaignIndex);
    }
  };

  const handleSocialId = (socialId: number) => {
    setCreateJobPublic({
      ...createJobPublic,
      social_account_id: socialId,
    });
    setSocialId(socialId);
  };

  const handleOpenSuccessDialog = (
    publishDate: string,
    infId: string,
    jobId: string
  ) => {
    const data = {
      ...successDialogDetail,
      publish_date: publishDate,
      influencer_id: infId,
      job_id: jobId,
      campaign_type: campaignType || "",
    };
    setSuccessDialogDetail(data);
    handleOpenDialog(true, "success");
  };

  const handleOpenDialog = (isOpen: boolean, select: string) => {
    setOpenDialog(isOpen);
    setDialogSelected(select);
  };

  const campaignDetailsList = [
    {
      id: "campaignOverview",
      title: "Campaign Overview",
      subTutle: "ภาพรวมแคมเปญ",
      component: <CampaignOverview campaignInfo={campaign} />,
    },
    {
      id: "campaignBrief",
      title: "Campaign Brief",
      subTutle: "รายละเอียดแคมเปญ",
      component: <CampaignBrief campaignInfo={campaign} />,
    },
    {
      id: "doOrDoNot",
      title: "Do and Don't",
      subTutle: "สิ่งที่ต้องทำและสิ่งที่ห้ามทำ",
      component: <DoOrDoNot campaignInfo={campaign} />,
    },
    {
      id: "targetAudience",
      title: "Target Audience",
      subTutle: "กลุ่มเป้าหมาย",
      component: <TargetAudience campaignInfo={campaign} />,
    },
    {
      id: "budgetAndReward",
      title: "Budget and Reward",
      subTutle: "สิ่งที่อินฟลูเอนเซอร์จะได้รับ",
      component: <BudgetAndReward campaignInfo={campaign} />,
    },
    {
      id: "socialMediaAccount",
      title: "Social Media Account",
      subTutle: "โปรดเลือกบัญชีที่ใช้ในการรับงาน",
      component: (
        <SocialMediaAccount
          campaignInfo={isPublic ? campaignInfo : null}
          jobInfo={isPrivate ? jobInfo : null}
          handleSocialId={handleSocialId}
        />
      ),
    },
    {
      id: "termsAndConditions",
      title: "Terms and Conditions",
      subTutle: "โปรดอ่านรายละเอียดและตรวจสอบข้อมูลก่อนทำการสมัคร",
      component: (
        <TermsAndConditions
          role="influencer"
          handleCreateJob={handleCreateJob}
          campaignInfo={campaign}
          campaignStatus={campaign?.status}
        />
      ),
    },
  ];

  const campaignDetailsListForSm = [
    {
      ...campaignDetailsList[4],
      xs: 7,
    },
    {
      id: "timeline",
      title: "Timeline",
      subTutle: "",
      component: <Timeline campaignInfo={campaign} />,
      xs: 5,
    },
  ];

  campaignDetailsList.splice(4, screenSm ? 1 : 0);
  const campaignDetailsForSm = screenSm
    ? campaignDetailsListForSm.reverse()
    : campaignDetailsListForSm;

  const onRegistCampaign = async (status: string) => {
    try {
      setIsLoading(true);
      const result = isPublic
        ? await JobAPI.createJob(createJobPublic)
        : await JobAPI.updateJobStatus(id, { status: status });
      if (result) {
        setIsLoading(false);
        const publishDate = result.campaign_info.timeline.start_publish_date;
        handleOpenSuccessDialog(publishDate, influeId, result.job_id);
      }
    } catch (error: any) {
      setIsLoading(false);
      const errMsg = error.response.data.error;
      SwalCustom.fire({
        icon: "error",
        title: "Oops !",
        text: `${
          errMsg.code === 1322 ? "คุณได้สมัครเคมเปนนี้แล้ว" : errMsg.message
        }`,
        confirmButtonColor: "#E30D18",
        confirmButtonText: "กลับไปหน้า Job Board",
        showCancelButton: true,
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/job-board");
        }
      });
    }
  };

  const onCancleCampaign = () => {
    SwalCustom.fire({
      icon: "question",
      text: "คุณจะปฎิเสธแคมเปญนี้จริงเหรอ",
      confirmButtonColor: "#8C499C",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    }).then(async (response) => {
      if (response.isConfirmed) {
        await JobAPI.updateJobStatus(id, { status: "canceled" }).then(() => {
          handleOpenDialog(true, "rejected");
        });
      }
    });
  };

  if (campaignLoading || jobInfoLoading || isLoading) return <Loading />
  
  return (
    <div className="container-main">
      <BackToTop />
      <Box
        id="back-to-top-anchor"
        sx={{
          ...classes.jobboardCon,
          backgroundImage: {
            xs: `url(${campaign?.brief?.cover_image?.url})`,
            md: "initial",
          },
          objectFit: "cover",
        }}
      >
        <Link
          onClick={() => history.goBack()}
          underline="none"
          sx={classes.callback}
        >
          <KeyboardArrowLeftIcon />
          <span>Back</span>
        </Link>
      </Box>
      <CompanyDetail
        campaignInfo={!isPrivate ? campaignInfo : null}
        jobInfo={isPrivate ? jobInfo : null}
      />
      <Box sx={classes.campDetailCon}>
        <Box sx={{ display: { xs: "none", lg: "inherit" } }}>
          <Typography variant="h5">Campaign Details</Typography>
          <Typography variant="body1" color="#5C5C5C">
            แสดงรายละเอียดของแคมแปน
          </Typography>
          <Divider sx={{ my: "24px" }} />
        </Box>
        <Grid container>
          <Grid item xs={12} md={12} lg={8} xl={8.5}>
            <Grid container spacing={4}>
              {campaignDetailsForSm.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={item.xs}
                  sx={classes.gridItemForSm}
                >
                  <Box
                    sx={{
                      ...classes.budgetAndTimeline,
                      alignItems: {
                        sm: item.id !== "timeline" ? "start" : "center",
                        md: "initial",
                      },
                      display: item.id === "timeline" ? "flex" : "inintial",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box width="100%">
                        <Typography variant="h6">{item.title}</Typography>
                        <Typography variant="body2" color="#5C5C5C">
                          {item.subTutle}
                        </Typography>
                        {item.id === "budgetAndReward" && (
                          <Divider sx={{ width: "100%", my: "20px" }} />
                        )}
                      </Box>
                    </Box>
                    {item.component}
                  </Box>
                </Grid>
              ))}
              {campaignDetailsList.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Box sx={classes.campDetailCard}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="h6">{item.title}</Typography>
                        <Typography variant="body2" color="#5C5C5C">
                          {item.subTutle}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={classes.divider} />
                    {item.component}
                  </Box>
                </Grid>
              ))}
              <Grid item xs={12}>
                {isPublic ? (
                  <Box sx={classes.submitButtonForInflu}>
                    <Button
                      variant="contained"
                      disabled={
                        condtionList?.length ===
                          createJobPublic.term_and_condition.filter(
                            (cond) => cond.status
                          ).length && socialId
                          ? false
                          : true
                      }
                      onClick={() => onRegistCampaign("working")}
                    >
                      สมัครเข้าร่วมแคมเปญ
                    </Button>
                  </Box>
                ) : (
                  <Stack
                    direction="row"
                    spacing={3}
                    sx={classes.conditionsPublicBtn}
                  >
                    <Button
                      variant="contained"
                      disabled={
                        condtionList?.length ===
                          createJobPrivate.filter((cond) => cond.status)
                            .length && socialId
                          ? false
                          : true
                      }
                      onClick={() => onRegistCampaign("listing")}
                    >
                      ตอบรับงาน
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={onCancleCampaign}
                    >
                      ปฏิเสธงาน
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3.5} lg={4} xl={3.5} sx={classes.timelineDetailComp}>
            <Box width="70%">
              <Box display="flex" alignItems="center" height="90px">
                <Typography variant="h6" mr={2}>
                  Timeline
                </Typography>
                {role !== "influencer" && (
                  <HelpOutlineIcon
                    sx={{ cursor: "pointer", color: "#5C5C5C" }}
                    onClick={() => handleOpenDialog(true, "timeline")}
                  />
                )}
              </Box>
              <Timeline campaignInfo={campaign} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {dialogSelected === "success" ? (
        <RegistCampaignSuccessDialog
          open={openDialog}
          onClose={setOpenDialog}
          data={successDialogDetail}
        />
      ) : dialogSelected === "rejected" ? (
        <RejectOfferingDialog open={openDialog} />
      ) : (
        <TimelineDetailDialog
          open={openDialog}
          onClose={setOpenDialog}
          timeline={campaign?.timeline}
          readOnly
        />
      )}
    </div>
  );
}
