import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { AppInformationAPI } from "@api/global";
import { ICategoryState } from "./type";

const initialState: ICategoryState = {
  data: [],
  loading: false,
  error: "",
};

export const getCategoryAsync = createAsyncThunk("categoryList", async () => {
  try {
    const response = await AppInformationAPI.getCategoryList();
    return response;
  } catch (err) {
    console.log("catch", err);
    throw err;
  }
});

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCategoryAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getCategoryAsync.rejected, (state, action) => {
        state.data = [];
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const selectCategoryList = (state: RootState) => state.category.data;
export const selectCategoryOnload = (state: RootState) => state.category.loading;

export default categorySlice.reducer;
