import { useState } from "react";
// mui, icon and style
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useCancelCampaignStyle as classes } from "./Campaign.style";
// components
import CompanyDetail from "@components/campaign/campaign-detail/CompanyDetail";
import CancelCampaignDetail from "@components/campaign/cancel-campaign/CancelCampaignDetail";
import CancelCampaignDialog from "@components/campaign/cancel-campaign/cancel-dialog/CancelCampaign";
import CancelCampaignSuccessDialog from "@components/campaign/cancel-campaign/cancel-dialog/CancelCampaignSuccess";
// redux
import {
  selectCampaignInfo,
  selectCampaignInfoOnload,
} from "@slices/CampaignInfo.slice";
import { useAppSelector } from "@store/hook";
// others
import { useHistory } from "react-router-dom";
import Loading from "@components/global/loading/Loading";
import TextFieldCustom from "@components/global/text-field";

export default function CancelCampaign() {
  const [openCancelCampaign, setOpenCancelCampaign] = useState(false);
  const [openCancelSuccess, setOpenCancelSuccess] = useState(false);

  const { goBack } = useHistory();
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const isLoading = useAppSelector(selectCampaignInfoOnload);

  const onCancelCampaign = () => {
    setOpenCancelCampaign(false);
    setOpenCancelSuccess(true);
  };

  if (isLoading) return <Loading />

  return (
    <div className="container-main">
      <Box sx={classes.goBack} onClick={goBack}>
        <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
        <Typography variant="buttonS">Back</Typography>
      </Box>
      <Box my="20px">
        <Typography variant="h5">Cancel Campaign</Typography>
        <Typography variant="subTitle1">ยกเลิกแคมเปญ</Typography>
      </Box>
      <Box px="220px">
        <CompanyDetail campaignInfo={campaignInfo} />
        <CancelCampaignDetail />
        <Box sx={classes.card}>
          <Box>
            <Typography variant="title2">
              โปรดบอกเราหน่อยว่าทำไมคุณถึงยกเลิกแคมเปญ
            </Typography>
            <TextFieldCustom
              variant="outlined"
              placeholder="เหตุผลอื่นๆ"
              fullWidth
              multiline
              minRows={5}
              sx={{ mt: "6px" }}
            />
          </Box>
          <Box mt="24px">
            <Typography variant="title2">
              สิ่งที่ฉันอยากให้ HashU ปรับปรุง
            </Typography>
            <TextFieldCustom
              variant="outlined"
              placeholder="คุณต้องการให้เราประปรุงอะไรบ้าง"
              fullWidth
              multiline
              minRows={5}
              sx={{ mt: "6px" }}
            />
          </Box>
        </Box>
        <Box sx={classes.cancelBtn}>
          <Button
            variant="contained"
            onClick={() => setOpenCancelCampaign(true)}
          >
            ยืนยันการยกเลิกแคมเปญ
          </Button>
        </Box>
      </Box>

      <CancelCampaignDialog
        open={openCancelCampaign}
        onClose={setOpenCancelCampaign}
        isInfApplyMoreThanSpacify
        onCancelCampaign={onCancelCampaign}
      />
      <CancelCampaignSuccessDialog
        open={openCancelSuccess}
        onClose={setOpenCancelSuccess}
      />
    </div>
  );
}
