import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Fab,
  Icon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// api and type
import { AppInformationAPI } from "@api/global";
import { IImageInputProps } from "../CampaignBrief.type";
// style and others
import { useImageInputStyle as classes } from "../CampaignBrief.style";
import SwalCustom from "@global/sweet-alert-custom/SwalCustom";

export default function ImageInput(props: IImageInputProps) {
  const {
    keyName,
    errors,
    defaultImg,
    form: { setValue },
  } = props;

  const [imageData, setImageData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (defaultImg) {
      setImageData(defaultImg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultImg]);

  const onInputImage = async (e: any) => {
    setIsLoading(true);

    const file = await e.target.files[0];
    const fileData = {
      file,
      destination: "company",
      permission: "public",
    };

    await AppInformationAPI.uploadAttachment(fileData)
      .then((response) => {
        setImageData(response.public_url as string);
        setValue(keyName as any, {
          file_id: "",
          file_name: response.file_name,
          file_type: response.file_type,
          url: response.public_url as string,
          destination: response.destination,
          purpose: "",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: `${err.response.data.error.message}`,
          confirmButtonColor: "#E30D18",
          confirmButtonText: "ตกลง",
        });
      });
  };

  const handleRemove = () => {
    setImageData("");
    setValue(keyName as any, "");
    setIsLoading(false);
  };

  return (
    <Box sx={{ "& > div": { mb: 0 } }}>
      <Box position="relative" width="100px" height="100px" mb={3}>
        {imageData ? (
          <>
            <Box component="label" display="inline-block" sx={classes.image}>
              <img src={imageData} alt="img" />
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                id="input-file"
                onChange={onInputImage}
                style={{ display: "none" }}
              />
            </Box>

            <Fab onClick={handleRemove} sx={classes.removeImgBtn}>
              <CloseIcon style={{ color: "#fff", fontSize: "16px" }} />
            </Fab>
          </>
        ) : (
          <div>
            <Box
              component="label"
              sx={{ ...classes.labelUpload, borderColor: errors ? "red" : "" }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Box mb="-5px">
                    <Icon
                      className="ico-hu-p-photo"
                      sx={{ fontSize: "38px", color: "#616161" }}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Icon
                      className="ico-hu-upload"
                      sx={{ fontSize: "10px", mt: "3px" }}
                    />
                    <span style={{ fontSize: "12px" }}>Image</span>
                  </Stack>
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    id="input-file"
                    style={{ display: "none" }}
                    onChange={onInputImage}
                  />
                </>
              )}
            </Box>
          </div>
        )}
      </Box>

      {errors && (
        <Typography color="error.main" fontSize="12px" fontWeight={300} mt={1}>
          {errors.message}
        </Typography>
      )}
    </Box>
  );
}
