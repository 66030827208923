// mui and
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useInvitationsStyle as classes } from "./TeamManagement.style";
// other
import DialogScrollingCustom from "@global/dialog-scrolling-custom";
import { IInvitationsProps } from "@views/team-management/Team.type";

export default function Invitations(props: IInvitationsProps) {
  const { open, onClose, invitation, handleOpenOldTeam, actionInvitation } = props;

  return (
    <DialogScrollingCustom
      open={open}
      onClose={onClose}
      title="ได้รับคำเชิญร่วมทีม"
      sx={classes.dialog}
    >
      <Box padding="24px">
        <Typography variant="title1">Invitations</Typography>
        <div>
          {invitation?.map((item, index) => {
            return (
              <Box key={index} sx={classes.invitationListBox}>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Avatar
                      src={item?.invite_team?.team_profile_url}
                      variant="square"
                      className="team-profile"
                    />
                    <Box ml="20px">
                      <Typography variant="title1">
                        {item?.invite_team?.team_name}
                      </Typography>
                      <Box display="flex" mt="8px">
                        <Avatar
                          src={item?.invite_team?.leader_profile_url}
                          className="leader-profile"
                        />
                        <Typography variant="subTitle1">
                          {item?.invite_team?.leader_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Button
                      onClick={() => {
                        actionInvitation(invitation[index]?.id, "reject");
                      }}
                      sx={classes.rejectedBtn}
                    >
                      <Typography variant="buttonM">Reject</Typography>
                    </Button>
                    <Button sx={classes.acceptBtn}>
                      <Typography
                        variant="buttonM"
                        onClick={() => {
                          invitation[0]?.current_team?.team_id === ""
                            ? actionInvitation(invitation[index]?.id, "accept")
                            : handleOpenOldTeam(index);
                        }}
                      >
                        Accept
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </div>
      </Box>
    </DialogScrollingCustom>
  );
}
