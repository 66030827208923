// mui and style
import { Box, Fab, Icon, Typography } from "@mui/material";
import { useCampaignPrivateStyles as classes } from "../CampaignPrivate.style";
// type
import { ICardInfluencerPrivateOrganizingProps } from "../PrivateCampaign.type";

export default function CardInfluencerPrivate(
  props: ICardInfluencerPrivateOrganizingProps
) {
  const { item, influencer, setInfluencer } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  const handleDeleteCandidate = () => {
    const listingFilter =
      influencer &&
      influencer.listing?.filter((listing: any) => {
        return listing.id !== item.id;
      });
    const reservedFilter =
      influencer &&
      influencer.reserved?.filter((reserved: any) => {
        return reserved.id !== item.id;
      });
    setInfluencer({
      listing: listingFilter,
      reserved: reservedFilter,
    });
  };

  return (
    <Box p="24px">
      <Box sx={classes.boxSelected}>
        <Fab
          onClick={() =>  handleDeleteCandidate()}
          color="primary"
          aria-label="clearInfluencer"
          sx={classes.fabOrganizing}
        >
          <Icon className="ico-hu-close" sx={{ fontSize: "16px" }} />
        </Fab>
        <img src={item.profile_image_url} alt="private-listing-dnd" />
        <Box sx={{ cursor: "pointer" }}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{ padding: "0 12px" }}
          >
            <Typography sx={classes.textEllipsis}>{item.name}</Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon className="ico-hu-group" sx={classes.icon} />
            <Typography>{numberFormat.format(item.follower)}</Typography>
          </Box>

          <Box display="flex" justifyContent="center">
            <Typography variant="title2" color="#893DBD">
              {Intl.NumberFormat("en").format(item.rate_card)} THB
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
