export function getOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  if (/Mac OS/i.test(userAgent)) {
    return "Mac OS";
  }

  if (/Linux/i.test(userAgent)) {
    return "Linux";
  }

  if (/Win/i.test(userAgent)) {
    return "Windows";
  }

  return "unknown";
}
