import React, { useEffect, useState } from "react";
// mui and style
import { Box } from "@mui/material";
import { useSidebarStyle as classes } from "./Sidebar.styles";
// other
import allMenu from "./menus_list.json";
import ItemSideBar from "./ItemSideBar";
import { useAuth } from "@contexts/Auth";
import { useHistory } from "react-router-dom";
import { IMenu, IMenuProps } from "./Sidebar.types";
import logo from "@assets/images/logo/hashu-logo2.svg";

function Menus(props: IMenuProps) {
  const { menus, handleOpenMenu } = props;
  return <ItemSideBar menus={menus} handleOpenMenu={handleOpenMenu} />;
}

const Sidebar = React.memo(() => {
  const history = useHistory();
  const [menus, setMenus] = useState<IMenu[]>([]);

  const auth = useAuth();

  useEffect(() => {
    const pathSplitted = history.location.pathname.split("/");
    pathSplitted.shift(); // * ไม่เอาค่าว่างมานับ index: 0 : sidebar
    const mainPath = "/" + pathSplitted[0];
    const menuList: IMenu[] =
      auth.role === "influencer"
        ? allMenu.influencer
        : auth.role === "employer"
        ? allMenu.employer
        : allMenu.admin;

    for (const menu of menuList) {
      if (menu.path === mainPath) {
        menu.isActive = false;
      }
    }
    setMenus([...menuList]);
  }, [auth.role, history]);

  const setDefaultMenu = () => {
    for (const menu of menus) {
      menu.isActive = false;
    }
  };

  const goPage = (path?: string) => {
    if (path !== undefined) {
      history.push(path);
    } else {
      history.push("/");
    }
  };

  const handleOpenMenu = (index: number) => {
    setDefaultMenu();
    let path = "";
    if (menus) {
      menus[index].isActive = true;
      path = menus[index].path;
      setMenus([...menus]);
      goPage(path);
    }
  };

  return (
    <Box display={["none", "none", "block"]}>
      <Box sx={classes.sideBar}>
        <div>
          <Box sx={classes.logoBox}>
            <img src={logo} alt="Hashu Logo" width={"52px"} />
          </Box>
          <Box sx={classes.menuCon}>
            <Menus menus={menus} handleOpenMenu={handleOpenMenu}></Menus>
          </Box>
        </div>
      </Box>
    </Box>
  );
});

export default Sidebar;
