export const useAddShippingStyle = {
  // Shipping and Conversion
  cardItems: {
    border: "1px solid #E0E0E0",
    borderRadius: "12px",
    padding: "20px",
  },

  badge: {
    "& .MuiBadge-badge": {
      top: "5px",
      left: "4px",
    },
  },

  avatar: {
    borderRadius: "4px",
    width: "60px",
    height: "60px",
  },

  boxTitle: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },

  textTitleEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  boxDetailAddShipping: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    my: "15px",
  },

  icon: {
    width: "25px",
    height: "25px",
    marginRight: "4px",
    color: "#8C499C",
    fontWeight: "bold",
  },

  boxDetailAddShippingData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  buttonAddShipping: {
    width: "100%",
    height: "32px",
    backgroundColor: "#8C499C",
    color: "white",
    fontSize: "14px",
    ":hover": {
      color: "#8C499C",
    },
  },

  shippingCompany: {
    width: "20px",
    height: "20px",
    marginRight: "4px",
    borderRadius: "4px",
  },
};

export const useCandidateCardStyle = {
  goBack: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: "4%",
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
  },

  fabSelected: {
    position: "absolute" as "absolute",
    top: "-10px",
    left: "-10px",
    backgroundColor: "common.white",
    width: "36px",
    height: "36px",
    bgcolor: "grey",
    "&:hover": {
      width: "96px",
      height: "36px",
      borderRadius: "30px",
      transition: "0.3s",
      bgcolor: "red",
    },
    "&:hover .MuiSvgIcon-root": {
      display: "none",
    },
    "&:hover .showText": {
      color: "common.white",
      display: "block",
      transition: "0.4s",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover + img:first-of-type": {
      border: "3px solid #893DBD",
    },
  },

  fabSelect: {
    position: "absolute",
    top: "-10px",
    left: "-10px",
    width: "36px",
    height: "36px",
    "&:hover": {
      width: "96px",
      height: "36px",
      borderRadius: "30px",
      transition: "0.3s",
    },
    "&:hover .showText": {
      display: "block",
      transition: "0.4s",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover + img:first-of-type": {
      border: "3px solid #893DBD",
    },
  },

  boxImage: {
    widht: "100%",
    height: "320px",
    borderRadius: "16px",
    "& > img": {
      objectFit: "cover",
      width: "100%",
      height: "320px",
      borderRadius: "16px",
      cursor: "pointer",
    },
    "& img:first-of-type": {
      display: "block",
    },
    "& img:last-of-type": {
      display: "none",
    },
    "&:hover img:first-of-type": {
      display: "none",
    },
    "&:hover img:last-of-type": {
      display: "block",
    },
  },

  boxDetail: {
    position: "absolute",
    bottom: "-6px",
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))",
    borderRadius: "0 0 12px 12px",
    color: "#fff",
    margin: "3px",
    cursor: "pointer",
  },

  textEllipsis: {
    width: "170px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  stackCategory: {
    marginBottom: "4px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    "& .MuiSvgIcon-root": {
      fontSize: "6px",
    },
  },

  textCategory: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "12px",
  },

  stackStatus: {
    direction: "row",
    justifyContent: "space-between",
  },
};

export const useCampaignInfluencerListStyle = {
  boxButton: { display: "flex", justifyContent: "center", marginTop: "24px" },

  buttonStart: {
    width: ["100%", "20%"],
    backgroundColor: "#ED1968",
    color: "white",
    border: "2px solid #ED1968",
    "&:hover": {
      backgroundColor: "#ED1968",
      border: "2px solid #ED1968",
      color: "white",
      boxShadow: 3,
    },
  },

  buttonBackToSelect: {
    width: ["100%", "20%"],
    marginRight: "24px",
    border: "2px solid #ED1968",
    color: "#ED1968",
    "&:hover": {
      boxShadow: 3,
    },
  },

  textButtonSelect: {
    fontSize: "14px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  campDetailCon: {
    borderRadius: "0 20px 20px 20px",
    bgcolor: "#fff",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    padding: "24px",
  },
  campDetailTitle: {
    "& p:first-of-type": {
      fontSize: "24px",
      fontWeight: "500",
    },
    "& p:last-of-type": {
      fontSize: "16px",
      color: "#5C5C5C",
    },
  },
  fab: {
    position: "absolute",
    top: "-10px",
    left: "-10px",
    width: "36px",
    height: "36px",
    "&:hover": {
      width: "95px",
      height: "36px",
      borderRadius: "30px",
      transition: "0.3s",
    },
    "&:hover .showText": {
      display: "block",
      transition: "0.4s",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  influRegisCampItem: {
    px: "16px",
    border: "1px solid #EBDFFF",
    borderRadius: "8px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectInflueButton: {
    padding: "16px",
    bgcolor: "#893DBD",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      bgcolor: "#893DBD",
    },
    "& > svg": {
      fontSize: "25px",
    },
    "& > p": {
      fontSize: "18px",
      fontWeight: "500",
    },
  },
  influeSelected: {
    padding: "16px",
    bgcolor: "#ED1968",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "123px",
    minHeight: "123px",
    cursor: "pointer",
    "&:hover": {
      bgcolor: "#ED1968",
    },
    "& > svg": {
      fontSize: "25px",
    },
    "& > p": {
      fontSize: "18px",
      fontWeight: "500",
    },
  },
  influeRegisListsTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    m: "32px 0 24px 15px",
    "& > p": {
      mr: "16px",
      fontSize: "20px",
      fontWeight: "bold",
    },
  },
  pagination: {
    // mt: "40px",
    // display: "grid",
    // justifyContent: "center",
    // "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
    //   bgcolor: "#ED1968",
    //   color: "#fff",
    // },
    mt: "40px",
    display: "grid",
    justifyContent: "center",
    "& .MuiGrid-item:last-of-type": {
      display: { xs: "none" },
    },
    "& .css-68kdax-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      bgcolor: "#ED1968",
      color: "#fff",
    },
  },

  showImageNoData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
  },

  boxTextImageNoData: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },

  startPrivate: {
    width: "240px",
    height: "40px",
    color: "#fff",
    backgroundColor: "#8C499C",
    ":hover": {
      color: "#8C499C",
    },
  },
};

export const useSelectInfluencerDialogStyle = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    my: "20px",
    mx: "10px",
    "& > .MuiAvatar-root": {
      width: "240px",
      height: "240px",
      borderRadius: "10px",
    },
  },
  applyButton: {
    mt: "16px",
    "& button:first-of-type": {
      width: "180px",
      maxHeight: "32px",
      fontSize: "14px",
    },
    "& button:last-of-type": {
      width: "180px",
      maxHeight: "32px",
      fontSize: "14px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};

export const useInfluencerSelectedStyle = {
  drawer: {
    "& .MuiPaper-root": {
      borderRadius: "20px 0 0 20px",
      width: ["100%", "50%", "50%", "30%"],
    },
  },
  boxBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  close: {
    cursor: "pointer",
    fontSize: "24px",
    color: "#9E9E9E",
  },
  boxEstimate: {
    height: "80px",
    margin: "0 24px",
    backgroundColor: "#F5F6F8",
    borderRadius: "8px",
    display: "flex",
    padding: "0 16px",
    alignItems: "center",
  },
  boxInfluencerCard: {
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    position: "relative",
    height: "100%",
    "& > img": {
      width: "100%",
      height: "126px",
      borderRadius: "8px 8px 0 0",
      objectFit: "cover",
      minHeight:"126px"
    },
  },
  fab: {
    minHeight: "20px",
    maxHeight: "20px",
    width: "20px",
    top: "-8px",
    left: "-8px",
    bgcolor: "black",
    position: "absolute",
    "&:hover": {
      bgcolor: "#686868",
    },
  },
  boxCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  iconGroup: {
    fontSize: "16px",
    marginRight: "8px",
    color: "#616161",
  },
};

export const useFilterInfluencerListStyle = {
  drawer: {
    "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
      borderRadius: "20px 0 0 20px",
    },
  },
  drawerBox: {
    width: "480px",
    height: "100vh",
    borderRadius: "20px 0 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  filterTitle: {
    height: "64px",
    minHeight: "64px",
    borderBottom: "1px solid #E0E0E0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    width: "28%",
    borderRadius: "20px 0 0 0",
    px: "24px",
    top: 0,
    zIndex: 1100,
    bgcolor: "#fff",
    "& > p": {
      fontSize: "20px",
      color: "#893DBD",
      fontWeight: "500",
    },
    "& > svg": {
      fontSize: "20px",
      color: "#9E9E9E",
    },
  },
  filterApply: {
    height: "64px",
    minHeight: "64px",
    borderTop: "1px solid #E0E0E0",
    position: "fixed",
    width: "28%",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "24px",
    bgcolor: "#fff",
    zIndex: 1100,
    mt: "30px",
    borderRadius: "0 0 0 20px",
    "& > p": {
      cursor: "pointer",
      color: "#893DBD",
    },
    "& > button": {
      maxHeight: "32px",
      fontSize: "14px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
  filterItemBox: {
    m: "90px 24px",
    height: "100%",
  },
  searchTitleItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchDetailItem: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    height: "40px",
    mt: "15px",
  },
  influeCateDetailItem: {
    "& > p": {
      fontSize: "14px",
      mb: "12px",
      mt: "16px",
    },
    "& > button": {
      m: "3px",
      maxHeight: "32px",
    },
  },
  influAgeDetailItem: {
    mt: "24px",
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > div": {
      display: "flex",
      justifyContent: "center",
      my: "16px",
    },
  },
  influAgeRangeDetailItem: {
    "& input": {
      height: "35px",
      borderRadius: "50px",
      width: "165px",
      px: "15px",
      border: "1px solid #E0E0E0",
      "&:hover": {
        border: "1px solid #E0E0E0",
      },
    },
    display: "flex",
    justifyContent: "space-between",
  },
  influLocatDetailItem: {
    my: "24px",
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > form": {
      display: "flex",
      alignItems: "center",
      borderRadius: "50px",
      height: "40px",
    },
  },
  influGenderDetailItem: {
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > button": {
      maxHeight: "32px",
      mx: "3px",
    },
  },
  influeFollowerDetailItem: {
    mt: "24px",
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > div": {
      display: "flex",
      justifyContent: "center",
      my: "16px",
    },
  },
  followerRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  perEngeDetailItem: {
    mt: "24px",
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > div": {
      display: "flex",
      justifyContent: "center",
      my: "16px",
    },
  },
  targetGenderDetailItem: {
    my: "20px",
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > button": {
      maxHeight: "32px",
      mx: "3px",
    },
  },
  tarLocatDetailItem: {
    my: "24px",
    "& > p": {
      fontSize: "14px",
      mb: "12px",
    },
    "& > form": {
      display: "flex",
      alignItems: "center",
      borderRadius: "50px",
      height: "40px",
      // border: "1px solid #f5f6f8",
    },
  },
  tarLocatCheckboxDetailItem: {
    my: "20px",
    mx: "16px",
    "& .MuiSvgIcon-root": { fontSize: "18px" },
  },
};
