import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { AppInformationAPI } from "@api/global";
import { IActivityState } from "./type";

const initialState: IActivityState = {
  data: null,
  loading: false,
};

export const getActivityAsync = createAsyncThunk(
  "getActivityAsync",
  async (params:{ page:number, item_per_page:number }) => {
    try {
      const response = await AppInformationAPI.getActivityInfo(params);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const ActivitySlice = createSlice({
  name: "activitySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivityAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getActivityAsync.rejected, (state) => {
        state.loading = false;
        state.data = null;
      });
  },
});

export const selectActivity = (state: RootState) => state.activity.data;
export const selectActivityOnload = (state: RootState) => state.activity.loading;

export default ActivitySlice.reducer;
