// mui and style
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useCurrentActivityStyle as classes } from "../MyReferral.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectActivity } from "@slices/Activity.slice";
import { selectInfluencerProfile } from "@slices/InfluencerProfile.slice";
// others
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export default function Activity() {
  const influInfo = useAppSelector(selectInfluencerProfile);
  const activity = useAppSelector(selectActivity);
  const topTear = activity?.ranking.top_tier;
  const location = useHistory();

  const onViewDetailActivity = (activityId?: string) => {
    location.push(`/home/news-and-events/${activityId}`);
  };

  return (
    <>
      <Box>
        <Typography variant="h6">{activity?.name}</Typography>
        <Typography variant="body1">{activity?.description}</Typography>
      </Box>
      <Grid container mt={6}>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            <Typography variant="subTitle2">วันที่เริ่มกิจกรรม</Typography>
            <Typography variant="title1" mt={1}>
              {dayjs(activity?.start).format("DD/MM/YYYY - HH:mm น.")}
            </Typography>
          </Box>
        </Grid>
        <Grid item={true} xs={6}>
          <Box display="flex" flexDirection="column">
            <Typography variant="subTitle2">วันสิ้นสุดกิจกรรม</Typography>
            <Typography variant="title1" mt={1}>
              {dayjs(activity?.end).format("DD/MM/YYYY - HH:mm น.")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={classes.rankingPodium}>
        <Box display={{ xs: "none", sm: "initial" }} sx={classes.scene}>
          <Box sx={classes.cube}>
            <Box sx={classes.podiumDetail4}>
              <Box display="flex" justifyContent="center">
                <Box sx={classes.pepleRanking4}>
                  <Avatar
                    src={topTear && topTear[0]?.profile_image_url}
                    sx={{ width: "60px", height: "60px" }}
                  />
                </Box>
              </Box>
              <Typography textAlign="center" mt={1}>
                {topTear && topTear[0]?.fullname}
              </Typography>
            </Box>
            <div className="cube-face4 cube-face4-front">
              <Typography variant="h5" mt={1}>
                4
              </Typography>
              <Box bgcolor="#7329A5" className="total">
                <Typography variant="title1">
                  {topTear && topTear[0]?.point} P
                </Typography>
              </Box>
            </div>
            <div className="cube-face4 cube-face4-top"></div>
          </Box>
        </Box>
        <Box sx={classes.scene}>
          <Box sx={classes.cube}>
            <Box sx={classes.podiumDetail2}>
              <Box display="flex" justifyContent="center">
                <Box sx={classes.pepleRanking2}>
                  <Avatar
                    src={topTear && topTear[1]?.profile_image_url}
                    sx={{ width: "80px", height: "80px" }}
                  />
                </Box>
              </Box>
              <Typography textAlign="center" mt={1}>
                {topTear && topTear[1]?.fullname}
              </Typography>
            </Box>
            <div className="cube-face2 cube-face2-front">
              <Typography variant="h4" mt={1}>
                2
              </Typography>
              <Box bgcolor="#3F116D" className="total">
                <Typography variant="title1">
                  {topTear && topTear[1]?.point} P
                </Typography>
              </Box>
            </div>
            <div className="cube-face2 cube-face2-top" />
          </Box>
        </Box>
        <Box sx={classes.scene}>
          <Box sx={classes.cube}>
            <Box sx={classes.podiumDetail1}>
              <Box display="flex" justifyContent="center">
                <Box sx={classes.pepleRanking1}>
                  <img
                    src={require("@assets/images/referral/rank-1.png")}
                    alt="rank1"
                  />
                  <Avatar
                    src={topTear && topTear[2]?.profile_image_url}
                    sx={{ width: "100px", height: "100px" }}
                  />
                </Box>
              </Box>
              <Typography textAlign="center" mt={1}>
                {topTear && topTear[2]?.fullname}
              </Typography>
            </Box>
            <div className="cube-face1 cube-face1-front">
              <Typography variant="h3" mt={1}>
                1
              </Typography>
              <Box bgcolor="#EA7305" className="total">
                <Typography variant="title1">
                  {topTear && topTear[2]?.point} P
                </Typography>
              </Box>
            </div>
            <div className="cube-face1 cube-face1-top" />
          </Box>
        </Box>
        <Box sx={classes.scene}>
          <Box sx={classes.cube}>
            <Box sx={classes.podiumDetail3}>
              <Box display="flex" justifyContent="center">
                <Box sx={classes.pepleRanking3}>
                  <Avatar
                    src={topTear && topTear[3]?.profile_image_url}
                    sx={{ width: "80px", height: "80px" }}
                  />
                </Box>
              </Box>
              <Typography textAlign="center" mt={1}>
                {topTear && topTear[3]?.fullname}
              </Typography>
            </Box>
            <div className="cube-face2 cube-face3-front">
              <Typography variant="h4" mt={1}>
                3
              </Typography>
              <Box bgcolor="#CD175B" className="total">
                <Typography variant="title1">
                  {topTear && topTear[3]?.point} P
                </Typography>
              </Box>
            </div>
            <div className="cube-face3 cube-face3-top" />
          </Box>
        </Box>
        <Box display={{ xs: "none", sm: "initial" }} sx={classes.scene}>
          <Box sx={classes.cube}>
            <Box sx={classes.podiumDetail5}>
              <Box display="flex" justifyContent="center">
                <Box sx={classes.pepleRanking5}>
                  <Avatar
                    src={topTear && topTear[4]?.profile_image_url}
                    sx={{ width: "70px", height: "70px" }}
                  />
                </Box>
              </Box>
              <Typography textAlign="center" mt={1}>
                {topTear && topTear[4]?.fullname}
              </Typography>
            </Box>
            <div className="cube-face5 cube-face5-front">
              <Typography variant="h5" mt={1}>
                5
              </Typography>
              <Box bgcolor="#2F2D88" className="total">
                <Typography variant="title1">
                  {topTear && topTear[4]?.point} P
                </Typography>
              </Box>
            </div>
            <div className="cube-face5 cube-face5-top"></div>
          </Box>
        </Box>
      </Box>
      <Stack direction="row" sx={classes.othersRankingBox}>
        <Box sx={classes.othersRaking}>
          <Avatar src={require("@assets/images/people/man.jpg")} />
          <Typography variant="body2" mt={2}>
            {influInfo?.fullname}
          </Typography>
        </Box>
        <Box sx={classes.othersRaking}>
          <Typography variant="h4" color="#893DBD">
            {influInfo?.referer?.activity?.ranking}
          </Typography>
          <Typography variant="body2">Current Rank</Typography>
        </Box>
        <Box sx={classes.othersRaking}>
          <Typography variant="h4" color="#893DBD">
            {influInfo?.referer?.activity?.referrals}
          </Typography>
          <Typography variant="body2">Points</Typography>
        </Box>
      </Stack>
      <Box display="flex" justifyContent="center" mt={6}>
        <Button
          variant="contained"
          onClick={() => onViewDetailActivity(activity?.id)}
          sx={classes.activityDetailBtn}
        >
          รายละเอียดกิจกรรม
        </Button>
      </Box>
    </>
  );
}
