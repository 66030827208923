export const useInfluencerStatusStyle = {
  container: {
    borderRadius: "0 0 20px 20px",
    padding: "25px",
    flexGrow: 5,
    boxShadow: "0px 2px 8px 0px #0000001A",
    bgcolor: "#fff",
    borderTop: "1px solid #8C499C",
  },
  boxDetail: {
    border: "1px solid #ECECEC",
    borderRadius: "12px",
  },
  iconStatus: { ml: "4px", fontSize: "20px" },
  statusNormal: {
    backgroundColor: "#E7FFE8",
    color: "#4CAF50",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    padding: "4px 16px",
    width: "50px",
    mb: "12px",
  },
  statusBan: {
    backgroundColor: "#FFFBD4",
    color: "#FB8C00",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    padding: "4px 16px",
    width: "120px",
    mb: "12px",
  },
  noStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    bgcolor: "#fff",
    borderRadius: "16px",
    "& > img": {
      width: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      height: {
        xs: "250px",
        sm: "300px",
        xl: "400px",
      },
      objectFit: "cover",
    },
    py: "40px",
  },
  textNoStatus: {
    width: "100%",
    textAlign: "center",
    mt: "15px",
  },
};
