// mui
import { styled, TableCell, tableCellClasses, TableRow } from "@mui/material";

export const TableCellCustom = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#893DBD",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const useOverviewTabFilterDateStyle = {
  filterDateBox: {
    "& button": {
      width: "280px",
      display: "flex",
      justifyContent: "space-between",
      px: "20px",
      maxHeight: "40px",
      minHeight: "40px",
      borderWidth: "1px",
      bgcolor: "#fff",
      "&:hover": {
        borderWidth: "1px",
      },
    },
  },
  menuDate: {
    "& > .MuiPaper-root": {
      border: "1px solid #8C499C",
      mt: "10px",
      minHeight: "415px",
      width: "500px",
      borderRadius: "20px",
    },
  },
  menuDateGridCon: {
    "& .datepicker-calendar-container": {
      border: "none",
      boxShadow: "none",
      ml: "-5px",
    },
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: 300,
    height: "36px",
    borderRadius: "4px",
    "&:hover": {
      bgcolor: "#EBDFFF",
      color: "#8C499C",
      fontWeight: 500,
    },
    ml: "10px",
  },
  txtFieldDate: {
    mb: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      borderColor: "#8C499C",
    },
  },
  reactDate: {
    "& .datepicker-calendar-container, .react-datepicker__month-container": {
      width: "100%",
    },
    "& .react-datepicker__day--in-range, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range":
      {
        borderRadius: "50%",
        bgcolor: "#EBDFFF",
      },
  },
  applyBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "200px",
    "& > span": {
      color: "#893DBD",
      cursor: "pointer",
    },
    "& > button": {
      border: "none",
      "&:hover": { border: "none" },
      bgcolor: "#893DBD",
    },
  },
};

const title = {
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
  bgcolor: "#fff",
  p: "24px",
};

export const useRegistrationFunnelStyle = {
  title: title,
  funnelDetailBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderLeft: "1px solid #E0E0E0",
  },
  lastStatsBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderLeft: "1px solid #E0E0E0",
    borderRight: "1px solid #E0E0E0",
  },
  statsTotolBottom: {
    direction: "row",
    justifyContent: "space-between",
    p: "16px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  statsBox: {
    minHeight: "200px",
    display: "flex",
    alignItems: "end",
  },
  firstStats: {
    borderTop: "38px solid transparent",
    borderBottom: "0px solid transparent",
    height: "160px",
  },
  secondStats: {
    borderTop: "38px solid transparent",
    borderBottom: "0px solid transparent",
    height: "120px",
  },
  thirdStats: {
    borderTop: "40px solid transparent",
    borderBottom: "0px solid transparent",
    height: "80px",
  },
  fourthStats: {
    borderTop: "35px solid transparent",
    borderBottom: "0px solid transparent",
    height: "45px",
  },
  fithStats: {
    borderTop: "20px solid transparent",
    borderBottom: "0px solid transparent",
    height: "25px",
  },
};

export const useReferralsRankingStyle = {
  title: { ...title, p: 0, minHeight: "1015px", height: "100%" },
  tableBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& th,td": {
      p: "18px",
    },
  },
  rankNum: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profile: {
    width: "48px",
    height: "48px",
    borderRadius: "4px",
  },
  txtEllip: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  infog: {
    width: "80%",
    borderRadius: 0,
    maxHeight: "320px",
    height: "80%",
  },
  noRankBox: {
    height: "83%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
};

const totalListBox = {
  height: "96px",
  width: "100%",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const useReferralsStatsStyle = {
  title: title,
  totalBox: {
    height: "96px",
    width: "100%",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  totalSelectedBox: {
    ...totalListBox,
    bgcolor: "#F5F6F8",
    border: "1px solid #F5F6F8",
  },
  totalUnSelectedBox: {
    ...totalListBox,
    border: "1px solid #E0E0E0",
    cursor: "pointer",
  },
  total: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mr: "16px",
  },
  statsPie: {
    my: "50px",
    mx: "30px",
    alignItems: "center",
    justifyContent: "center",
  },
  tableTotal: {
    minWidth: { xs: "100%", xl: "750px" },
  },
  tableUnTotal: {
    minWidth: { xs: "50%", xl: "300px" },
  },
  tHead: {
    "& th,td": { border: 0, p: 2 },
  },
  tBody: {
    "& td, th": {
      p: 2,
      border: 0,
    },
    "&:last-child td, &:last-child th": {
      borderTop: 1,
      borderBottom: 1,
      borderColor: "#E0E0E0",
      fontWeight: 500,
    },
  },
};

export const useTypeOfInfluencerStyle = {
  title: title,
};

export const useInfluencerTiersStyle = {
  tableCon: {
    borderRadius: "12px 12px 0 0",
    boxShadow: "none",
    mt: "16px",
  },
  tableRow: {
    "& th, tr": { p: 3.5 },
  },
  tableBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& td, tr": { p: 2.5 },
  },
  tBodyRow: {
    "&:last-child td, &:last-child th": {
      "& p": {
        fontWeight: 500,
      },
    },
  },
};

export const useContentDistributionStyle = {
  tableCon: {
    borderRadius: 0,
    boxShadow: "none",
  },
  tableBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& td, tr": { p: 2.5 },
    "& tr:last-of-type": {
      "& td": {
        border: 0,
        bgcolor: "#fff",
        color: "initial",
        "& p": {
          fontWeight: 500,
        },
      },
    },
  },
  menu: {
    "& > .MuiPaper-root": {
      border: "2px solid #8C499C",
      minHeight: "80px",
      width: "396px",
      borderRadius: "0 0 20px 20px",
    },
  },
  formControl: {
    width: "100%",
    mt: "-8px",
    px: "13px",
    py: "3px",
  },
  formLabel: {
    "&:hover": {
      bgcolor: "#EBDFFF",
      borderRadius: "3px",
      color: "#8C499C",
    },
  },
  heatMapBox: {
    minHeight: "100px",
    maxHeight: "285px",
    pl: "45px",
    pt: "12px",
    "& .MuiPaper-root": { boxShadow: "none" },
  },
  tHead: {
    "& th": {
      bgcolor: "#F5F6F8",
      border: 0,
    },
    "& th:first-of-type": {
      borderRadius: "12px 0 0 0",
    },
    "& th:last-of-type": {
      borderRadius: "0 12px 0 0",
    },
  },
  tCon: {
    my: "12px",
    width: "95%",
  },
  table: {
    minWidth: "80px",
    border: 0,
  },
  tBody: {
    "& tr, td": { border: 0, py: "10px", overflow: "hidden", height: "20px" },
  },
  heatMapListBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80px",
  },
  textField: {
    width: "100%",
    overflow: "visible",
    "& input": { height: "40px" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8C499C",
      },
      "&:hover fieldset": {
        borderColor: "#8C499C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8C499C",
      },
    },
  },
  disableTxtField: {
    width: "100%",
    bgcolor: "#F5F6F8",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  moreThanTxtField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
  },
  tabsBox: {
    borderBottom: 1,
    borderColor: "#893DBD",
    mt: "16px",
    "& .css-19mh16d-MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#fff",
      bgcolor: "#893DBD",
    },
    "& button": {
      minHeight: "40px",
      maxHeight: "40px",
    },
    "& .css-1aihmmp-MuiTabs-root": {
      minHeight: "40px",
      maxHeight: "40px",
    },
  },
  tab: {
    fontSize: "16px",
    borderRadius: "16px 16px 0 0",
  },
};

export const useInfluencerDistributioStyle = {
  title: title,
};

export const useInfluencerDistributionListStyle = {
  tableCon: {
    borderRadius: "12px 12px 0 0",
    boxShadow: "none",
    mt: "16px",
  },
  tBody: {
    "& .MuiTableRow-root:nth-of-type(even)": {
      bgcolor: "#fff",
    },
    "& td, tr": { p: 2.5 },
  },
  tBodyRow: {
    "&:last-child td p, &:last-child th p": {
      fontWeight: 500,
    },
  },
  menu: {
    mt: "18px",
    "& .MuiPaper-root": {
      borderRadius: "0 0 15px 15px",
      border: "1px solid #8C499C",
    },
  },
  menuItem: {
    "&:hover": {
      bgcolor: "#EBDFFF",
      color: "#8C499C",
      fontWeight: 500,
    },
  },
  menuItemSelected: {
    bgcolor: "#EBDFFF",
    color: "#8C499C",
    fontWeight: 500,
  },
};
