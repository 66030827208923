import React from "react";
// mui and icon
import {
  Box,
  Icon,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// style and type
import { useChangePlanDialogStyle as classes } from "../Subscription.style";
import { IPackage } from "@views/subscription/Subscription.type";
import { IChangePlanDialogProps } from "../Subscription.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectPackageList, selectPackageSelected } from "@slices/PackageInfo.slice";
// other
import { useHistory } from "react-router-dom";

export default function ChangePlanDialog(props: IChangePlanDialogProps) {
  const { open, onClose, packagePlan, plan, upgradeType } = props;

  const history = useHistory();

  const numberFormat = Intl.NumberFormat("en", {
    maximumFractionDigits: 3,
  });

  const packageSelected = useAppSelector(selectPackageSelected)
  const packageList = useAppSelector(selectPackageList);
  const isUpgYearlyPlan = packageSelected?.plan === "yearly";
  const isUpgTeamPlan = packageSelected?.package_plan === "team";
  const isCurrYearlyPlan = packagePlan?.plan === "yearly";
  const currentPrice = plan === "team"
    ? packagePlan?.fees?.price_team
    : packagePlan?.fees?.price;

  const planDetail = (isTeam: boolean, packages?: IPackage, color?: string) => {
    const textColor = (text?: number | string, other?: string) => {
      return (
        <>
          <Typography mt="6px">
            <span style={{ color: color, fontWeight: 500 }}>{text} </span>
            {other}
          </Typography>
        </>
      );
    };
    return (
      <Box>
        {[
          textColor(
            isTeam ? packages?.team_management.usage_limit : 1,
            "สมาชิก (การใช้งานส่วนบุคคล )"
          ),

          textColor(
            isTeam ? packages?.campaign_management.campaign_limit_per_month : 1,
            "แคมเปญต่อเดือน"
          ),
          textColor(
            isTeam ? packages?.campaign_management.campaign_limit_per_month : 1,
            "อินฟลูเอนเซอร์ต่อแคมเปญ"
          ),
          textColor(
            `${packages?.report.is_report_export ? "สามารถ" : "ไม่สามารถ"}`,
            "ดาวน์โหลดรายงานได้"
          ),
          textColor(
            `${packages?.notification.is_line_chat ? "ได้รับ" : "ไม่ได้รับ"}`,
            "การแจ้งเตือนผ่านไลน์"
          ),
        ].map((li, index) => (
          <React.Fragment key={index}>{li}</React.Fragment>
        ))}
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
        <Typography variant="title1">เปลี่ยนแผน</Typography>
        <IconButton onClick={() => onClose(false)}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.contentDialog}>
        <Box mt="20px">
          <Typography variant="h5" textAlign="center">
            Change Subscription Plan
          </Typography>
          <Box sx={classes.contentBox}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6" textAlign="center">
                แผนปัจจุบัน
              </Typography>
              <Box sx={classes.planListBox}>
                <Box sx={classes.titleBox}>
                  <Icon className="ico-hu-person" />
                  <Typography
                    variant="title1"
                    ml="5px"
                    textTransform="capitalize"
                  >
                    {packagePlan?.title || "free"}
                  </Typography>
                </Box>
                <Box sx={classes.totalBox}>
                  <Typography variant="h4">
                    {numberFormat.format(
                      (isCurrYearlyPlan ? 10 : 1) * (currentPrice || 0)
                    )}
                  </Typography>
                  <Typography variant="subTitle2">
                    บาท/ {isCurrYearlyPlan ? "ปี" : "เดือน"}{" "}
                  </Typography>
                </Box>
                {planDetail(plan === "team", packagePlan)}
              </Box>
            </Box>
            <Icon
              className="ico-hu-arrow-right"
              sx={{ mx: "25px", fontSize: "30px" }}
            />
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6" textAlign="center">
                แผนใหม่
              </Typography>
              <Box sx={classes.planListBox}>
                <Box
                  sx={{
                    ...classes.titleBox,
                    color: "#E8008A",
                    bgcolor: "#FFEAF4",
                  }}
                >
                  <Icon className="ico-hu-person" />
                  <Typography
                    variant="title1"
                    ml="5px"
                    textTransform="capitalize"
                  >
                    {packageSelected?.title}
                  </Typography>
                </Box>
                <Box sx={classes.totalBox}>
                  <Typography variant="h4">{packageSelected?.price}</Typography>
                  <Typography variant="subTitle2">
                    บาท/ {isUpgYearlyPlan ? "ปี" : "เดือน"}{" "}
                  </Typography>
                </Box>
                {planDetail(
                  isUpgTeamPlan,
                  packageList?.find((p) => p.title === packageSelected?.title),
                  "#4CAF50"
                )}
              </Box>
            </Box>
          </Box>
          <Typography variant="h6" textAlign="center" mt="25px" mb="20px">
            ต้องการดำเนินการต่อหรือไม่
          </Typography>
          <Box sx={classes.submitBtn}>
            <Button onClick={() => onClose(false)} fullWidth>
              ยกเลิก
            </Button>
            <Button
              onClick={() => history.push(`/subscription/${upgradeType}?plan=${plan}`)}
              variant="contained"
              fullWidth
            >
              ดำเนินการต่อ
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
