// mui and icon
import { Box, Fab, Icon, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// style and type
import { useCandidateCardStyle as classes } from "../InfluencerList.style";
import { ICandidateInfluencerProps } from "../InfluencerList.type";
import { ICategory } from "@api/app-information/type";
// other
import GetPlatform from "@global/get-platform/index";

export default function CandidateInfluencerCard(
  props: ICandidateInfluencerProps
) {
  const {
    item,
    handleViewInfluencer,
    checkLimit,
    checkStatus,
    onClick,
    candidateInfluencer,
  } = props;

  const totalEngagement = Math.round(item.engagement_rate * 100) / 100;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Box sx={{ position: "relative" }}>
      {checkStatus ? (
        <>
          <Fab
            color={item.is_selecting ? "success" : "primary"}
            aria-label="add"
            disabled={checkLimit && !item.is_selecting}
            sx={item.is_selecting ? classes.fabSelected : classes.fabSelect}
            onClick={() => {
              onClick(item.job_id);
            }}
          >
            {item.is_selecting ? (
              <>
                <Icon className="ico-hu-check" sx={{ color: "#fff" }} />
                <Typography
                  className="showText"
                  sx={{ display: "none", ml: "3px" }}
                >
                  Remove
                </Typography>
              </>
            ) : (
              <>
                <Icon className="ico-hu-add" />
                <Typography
                  className="showText"
                  sx={{ display: "none", ml: "3px" }}
                >
                  Select
                </Typography>
              </>
            )}
          </Fab>
        </>
      ) : null}

      <Box
        sx={classes.boxImage}
        onClick={
          candidateInfluencer.summary.is_selecting
            ? () => onClick(item.job_id)
            : () => handleViewInfluencer(item.influencer_id, item)
        }
      >
        <img
          src={item.social_account_images[0]}
          className="first-image"
          alt="firstimage"
          style={{
            border: item.is_selecting ? "3px solid #4CAF50" : "3px solid #fff",
          }}
        />
        <img
          src={item.social_account_images[1]}
          className="last-image"
          alt="lastimage"
          style={{
            border: item.is_selecting ? "3px solid #4CAF50" : "3px solid #fff",
          }}
        />
      </Box>
      <Box
        sx={classes.boxDetail}
        onClick={() => handleViewInfluencer(item.influencer_id, item)}
      >
        <Box sx={{ padding: "12px" }}>
          <Box display="flex">
            <GetPlatform platform={item.platform} />
            <Typography sx={classes.textEllipsis}>{item.name}</Typography>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            divider={<CircleIcon />}
            sx={classes.stackCategory}
          >
            {item.category.map((cat: ICategory) => (
              <Typography key={cat.id} sx={classes.textCategory}>
                {cat.name_en}
              </Typography>
            ))}
          </Stack>

          <Stack direction="row" sx={classes.stackStatus}>
            <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
              <Icon className="ico-hu-group" sx={{ fontSize: "16px" }} />
              <Typography ml={1.5}>
                {numberFormat.format(item.followers)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
              <Icon
                className="ico-hu-engagement-rate"
                sx={{ fontSize: "16px" }}
              />
              <Typography ml={1.5}>{totalEngagement}%</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
