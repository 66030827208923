import { useEffect, useState } from "react";
// mui and icon
import {
  Box,
  Stack,
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// style and type
import { useUploadUrlStyle as classes } from "../JobsTab.style";
import { IIsApproved, IExternalFile } from "@api/app-information/type";
import { IUploadUrlDialogProps } from "../JobsTab.type";
// others
import TextFieldCustom from "@global/text-field";

export default function UploadUrlDialog(props: IUploadUrlDialogProps) {
  const { open, onClose, urls, setValue } = props;

  const [newUrl, setNewUrl] = useState("");
  const [urlList, setUrlList] = useState<IExternalFile[]>([]);

  useEffect(() => {
    setUrlList(urls);
  }, [urls]);

  const onCloseDialog = () => {
    onClose(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    const data = [...urlList];
    data[index].url = event.target.value;
    setUrlList(data);
    // setValue(`external_file.${index}.url`, event.target.value);
  };

  const handleInputNewUrl = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNewUrl(event.target.value);
  };

  const handleRemoveUrl = (index: number) => {
    const data = [...urlList];
    data.splice(index, 1);
    setUrlList(data);
  };

  const onAddNewUrl = () => {
    const url = {
      order: urlList.length + 1,
      file_id: "",
      url: newUrl,
      is_approve: "new" as IIsApproved,
      note: "",
    };
    setUrlList([...urlList, url]);
    setNewUrl("");
  };

  const onSubmitUrl = () => {
    setValue("external_file", urlList);
    onCloseDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={classes.dialog}
    >
      <DialogTitle sx={classes.dialogTitle} id="alert-dialog-title">
        <Typography variant="title1">Upload Video Url</Typography>
        <IconButton onClick={onCloseDialog}>
          <CancelIcon viewBox="3 3 18 18" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.contentDialog}>
        <Box my="24px">
          <Typography variant="h6">Paste Video URL</Typography>
          <Typography variant="subTitle2">
            แนบลิงค์ URL หรือแหล่งที่มาของวิดีโอ
          </Typography>
        </Box>
        <Stack spacing="16px">
          {urlList.map((url, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Typography variant="title2" mr="20px">
                {index + 1}
              </Typography>
              <TextFieldCustom
                variant="outlined"
                multiline
                fullWidth
                value={url.url}
                sx={classes.textfield}
                InputProps={{
                  onChange: (e) => handleInputChange(e, index),
                  endAdornment: (
                    <IconButton
                      aria-label="add an alarm"
                      component="label"
                      sx={classes.IconButton}
                      onClick={() => handleRemoveUrl(index)}
                    >
                      <RemoveCircleIcon color="error" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          ))}
        </Stack>
        <Box display="flex" alignItems="center" mt="16px">
          <Typography variant="title2" mr="20px">
            {urlList.length + 1}
          </Typography>
          <TextFieldCustom
            variant="outlined"
            multiline
            fullWidth
            value={newUrl}
            sx={classes.textfield}
            placeholder="Link URL"
            InputProps={{
              onChange: handleInputNewUrl,
              endAdornment: (
                <IconButton
                  aria-label="add an alarm"
                  component="label"
                  onClick={onAddNewUrl}
                  sx={classes.IconButton}
                >
                  <AddIcon sx={classes.addIcon} />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box sx={classes.btnBox}>
          <Button
            variant="contained"
            onClick={onSubmitUrl}
            sx={{ maxWidth: "200px" }}
          >
            Submit URL
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
