// import { useDraftApprovalStyle } from "@components/campaign/campaign-detail/job-tab/JobTab.style";

import { useDraftApprovalStyle } from "@components/employer-job/EmployerJob.style";

export const useMultiImageInputStyle = {
  labelUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    background: "#fff",
    width: "100px",
    height: "100px",
    border: "2px dashed #9E9E9E",
    boxSizing: "border-box",
    borderRadius: "4px",
    color: " gray",
    position: "relative",
  },
  imageIsActiveBox: {
    "& > button": {
      bgcolor: "#4CAF50",
      color: "#fff",
      zIndex: 0,
      "&:hover": {
        bgcolor: "#4CAF50",
      },
    },
  },
  imageIsNotActiveBox: {
    "& > button": {
      bgcolor: "black",
      color: "#fff",
      "&:hover": {
        bgcolor: "black",
      },
      zIndex: 0,
    },
  },
  removeImgBtn: {
    top: "-6px",
    right: "-7px",
    maxWidth: "20px",
    minWidth: "20px",
    maxHeight: "20px",
    minHeight: "20px",
    position: "absolute",
  },
  imageDataList: {
    display: "inline-block",
    cursor: "pointer",
    "& > img": {
      height: "100px",
      width: "100px",
      objectFit: "cover",
      borderRadius: "8px",
    },
  },
};

export const useDateInputStyle = {
  container: {
    "& .react-datepicker__day--disabled": {
      color: "#999",
      borderRadius: "50%",
    },
    "& .react-datepicker__day": {
      borderRadius: "50%",
    },
    "& .react-datepicker__day--selected": {
      bgcolor: "primary.main",
      color: "#fff",
    },
    "& .react-datepicker__day--keyboard-selected": {
      bgcolor: "#ebdfff",
    },
  },
};

export const useReviewPostInfluencerStyle = {
  influPostCon: {
    padding: "20px",
    borderRadius: "12px",
    border: {
      sm: "2px solid #EBDFFF",
    },
    display: "flex",
    justifyContent: "center",
    bgcolor: {
      xs: "#fff",
      sm: "initial",
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  postProfile: {
    display: "flex",
    alignItems: "center",
    mb: "16px",
    "& > img": {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  notImgPostBox: {
    height: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  postImgProfile: {
    my: "16px",
    "& > img": {
      width: "100%",
      height: "380px",
      borderRadius: "4px",
    },
  },
  postImgItem2: {
    display: "flex",
    height: "380px",
    "& img": { width: "190px", objectFit: "cover" },
    justifyContent: "center",
    "& img:first-of-type": { borderRadius: "4px 0 0 4px" },
    "& img:last-of-type": { borderRadius: "0 4px 4px 0" },
  },
  postImgItem3: {
    display: "flex",
    height: "380px",
    "& > img": {
      width: "50%",
      objectFit: "cover",
      borderRadius: "4px 0 0 4px",
    },
    "& > div": {
      "& img": { height: "186.5px", width: "190px", ml: "5px" },
      "& img:first-of-type": { borderRadius: "0 4px 0 0" },
      "& img:last-of-type": { borderRadius: "0 0 4px 0" },
    },
  },
  postImgItem4: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "380px",
      height: "380px",
      borderRadius: "4px",
    },
    "& > div": {
      "& img": {
        width: "120px",
        borderRadius: "4px",
      },
    },
  },
  postImgItemMoreThan4: {
    position: "absolute",
    width: "120px",
    height: "125px",
    right: 0,
    borderRadius: "4px",
    bgcolor: "#000",
    opacity: 0.2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
  urlBox: {
    height: "380px",
    width: "100%",
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "80px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const videoItem = {
  width: {
    xs: "100px",
    sm: "120px",
  },
  "& > button": {
    bgcolor: "black",
    minHeight: "20px",
    maxHeight: "20px",
    maxWidth: "20px",
    color: "#fff",
    "&:hover": {
      bgcolor: "black",
    },
    zIndex: 9,
    top: -9,
    right: -9,
    position: "absolute",
  },
  "& video": {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "8px",
    cursor: "pointer",
  },
};

export const useMultiVideoInputStyle = {
  videoIsNotActive: {
    ...videoItem,
    "& > button": {
      bgcolor: "black",
    },
    "&:hover": {
      bgcolor: "black",
    },
  },
  videoIsActive: {
    ...videoItem,
    "& > button": {
      bgcolor: "#4CAF50",
      color: "#fff",
      top: -7,
      right: -7,
      "&:hover": {
        bgcolor: "#4CAF50",
      },
    },
  },
  removeBtn: {
    bgcolor: "black",
    minHeight: "20px",
    maxHeight: "20px",
    maxWidth: "20px",
    color: "#fff",
    "&:hover": {
      bgcolor: "black",
    },
    zIndex: 9,
    top: -7,
    right: -7,
    position: "absolute",
  },
  approveBtn: {
    bgcolor: "black",
    minHeight: "20px",
    maxHeight: "20px",
    maxWidth: "20px",
    color: "#fff",
    "&:hover": {
      bgcolor: "black",
    },
    zIndex: 9,
    top: -9,
    right: -9,
    position: "absolute",
  },
  iconPlayVideo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
  },
  labelUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    position: "relative",
    minHeight: "100px",
    maxWidth: {
      sm: "100%",
    },
    boxSizing: " border-box",
    color: "gray",
    width: { xs: "100px", lg: "120px" },
    height: { xs: "100px", lg: "120px" },
    border: "2px dashed #9E9E9E",
    borderRadius: "4px",
    bgcolor: "#fff",
    // mt: "8px",
  },
  uploadBtn: {
    position: "absolute",
    bottom: "-10px",
    right: "-10px",
    maxWidth: "28px",
    minWidth: "28px",
    maxHeight: "28px",
    minHeight: "28px",
    zIndex: 9,
  },
  urlBox: {
    height: {
      xs: "100px",
      sm: "120px",
    },
    width: {
      xs: "100px",
      sm: "120px",
    },
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "40px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const useConversionStyle = {
  listingTitle: {
    color: "#5C5C5C",
    fontSize: "14px",
    mt: "7px",
  },
  txtLink: {
    bgcolor: "#F4ECFF",
    height: "40px",
    borderRadius: "50px",
    ml: {
      xs: "30px",
      md: "10px",
    },
    p: "0 15px 0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > p": {
      color: "#893DBD",
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "80%",
    },
    "& > button": {
      bgcolor: "#fff",
      color: "#893DBD",
      height: "28px",
      width: "28px",
      "& > .MuiSvgIcon-root": {
        fontSize: "18px",
      },
    },
  },
  couponTxtItem: {
    "& li": { ml: "15px", fontSize: "14px", fontWeight: "500" },
  },
};

export const useAdditionalCommentStyle = {
  datePostBox: useDraftApprovalStyle.datePostBox,
  datePostTitle: useDraftApprovalStyle.datePostTitle,
  divider: useDraftApprovalStyle.divider,
  comment: {
    borderRadius: "8px",
    bgcolor: "#F5F6F8",
    p: "10px",
    minHeight: "50px",
  },
};

export const useCaptionStyle = {
  captionCon: {
    border: {
      sm: "2px solid #EBDFFF",
    },
    borderRadius: "12px",
    bgcolor: "#fff",
    p: {
      xs: "24px 24px 0 24px",
      sm: 0,
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  title: {
    p: {
      sm: "24px",
    },
    "& p:first-of-type": { fontSize: "20px", fontWeight: "500" },
    "& p:last-of-type": { fontSize: "14px", color: "#5C5C5C" },
  },
  captionEditingDesc: {
    bgcolor: "#FDF3F3",
    borderRadius: "8px",
    mt: "16px",
    padding: "12px",
    "& p:first-of-type": {
      color: "red",
      fontWeight: "500",
      fontSize: "14px",
      mb: "12px",
    },
  },
};

export const usePlaceStyle = {
  placeCon: useCaptionStyle.captionCon,
  title: useCaptionStyle.title,
  map: {
    width: { xs: "100%", lg: "50%" },
    "& img": { objectFit: "cover", width: "100%", height: "100%" },
  },
  detailBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: { xs: "100%", lg: "50%" },
  },
  btn: {
    justifyContent: "center",
    "& button": {
      minWidth: "220px",
    },
    "& > a": {
      textDecoration: "none",
      "& button": {
        border: "none",
        "&:hover": { border: "none" },
      },
    },
    "a:-webkit-any-link": {
      textDecoration: "none",
    },
  },
};
