import { useState } from "react";
// mui and style
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useInstagramProfessionalStyle as classes } from "../../../InfluencerSocial.style";
// component
import InstagramProfConnected from "./Connected";
import UnofficialDefault from "@components/influencer-social/default/UnofficialDefault";
// asset
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Instagram from "@assets/images/social/ig-glyph-gradient.png";

export default function InstagramProfessional(props: {
  checkComponents: (value: boolean) => void;
}) {
  const { checkComponents } = props;
  const [connected, setConnected] = useState(false);
  const [unConnect, setUnConnect] = useState(false);

  return unConnect ? (
    // <NonConnect checkComponents={setNonConnect} />
    <UnofficialDefault checkComponents={setUnConnect} platform="instagram" />
  ) : connected ? (
    <InstagramProfConnected setConnected={setConnected} />
  ) : (
    <Stack sx={classes.container}>
      <Box sx={classes.goBack} onClick={() => checkComponents(false)}>
        <ArrowBackIosIcon />
      </Box>
      <Box sx={classes.syncSocial} py={3}>
        <img
          src={Instagram}
          alt="facebook_sync"
          style={{ height: "72px", width: "72px" }}
        />
      </Box>
      <Box textAlign="center" width="40%" pt={4}>
        <Typography variant="h5" color="inherit">
          Connect with Instagram
        </Typography>
      </Box>
      <Box textAlign="center" py={5}>
        <Typography variant="body1" mx="10px">
          คุณได้ทำการเชื่อมต่อ Instagram Account กับ Facebook Page แล้วใช่ไหม ?
        </Typography>
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent={["center", "flex-end"]}>
            <Button onClick={() => setConnected(true)} sx={classes.buttonSync}>
              <Typography variant="buttonM">ฉันเชื่อมต่อแล้ว</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent={["center", "flex-start"]}>
            <Button
              onClick={() => setUnConnect(true)}
              sx={classes.defaultButton}
            >
              <Typography variant="buttonM">ฉันยังไม่ได้เชื่อมต่อ</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
