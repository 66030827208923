import { styled } from "@mui/system";
import { darken } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { hColorful, hSocialColor } from "@theme/index";

export const useFooterStyle = {
  mainBox: {
    background: `linear-gradient(296.45deg, ${hColorful[100]} -3.27%, ${hColorful[200]} 102.18%)`,
  },
};

export const ButtonTrial = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: "180px",
  minHeight: "42px",
  borderRadius: "50px",
  "&:hover": {
    color: darken(theme.palette.common.white, 0.1),
    backgroundColor: darken(theme.palette.common.white, 0.1),
  },
}));

export const ButtonLine = styled(Button)<ButtonProps>(({ theme }) => ({
  color: hSocialColor["line"],
  backgroundColor: hSocialColor["line"],
  width: "180px",
  minHeight: "42px",
  borderRadius: "50px",
  "&:hover": {
    color: darken(hSocialColor["line"], 0.1),
    backgroundColor: darken(hSocialColor["line"], 0.1),
  },
}));
