import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Six() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>
        6. How does the Company consider the rights of the owner of the
        information?
      </Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>As the owner, you have the rights as follows;</Typography>
      <Typography sx={{ marginTop: "20px" }}>
        You can access your personal data within the responsibility of the
        Company to check and change your personal information and visit your
        personal account page by logging into the HashU Platform at any time.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The appropriate access or use of the information on the HashU Platform,
        including User Content is subject to the Terms of Use of the Company.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        6.2 Right to request a copy of your personal data.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        You can obtain a copy of your personal data that is under the
        responsibility of the Company according to the methods and channels
        specified by the Company in section 2.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        6.3 Right to request disclosure of the acquisition of personal data by
        indirect means.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        If the Company has collected information from other sources as specified
        by you in Section 4, you may request that the Company disclose such
        information acquisition in accordance with the methods and channels
        specified by the Company in Section 2.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        6.4 Right to object to the collection, use, or disclosure of your
        personal data.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The Company recognizes the importance of your personal information so
        that you can object to the collection, use, or disclosure of your
        personal information to the Company at any time unless the Company has a
        legitimate right to reject your objection.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        6.5 Right to delete or request destruction or temporarily suspend or
        convert data into an anonymous data format.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        Unless the Company has the legal right to refuse or the Company is
        obliged to comply with the specific laws or the personal information
        that the Company collects for freedom of expression, you may delete your
        information from the HashU Platform and keep a copy of your information,
        including requesting the Company to suspend the use of your personal
        information temporarily.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        Suppose the Company does not comply with the policy or relevant laws. In
        that case, you can ask the Company to destroy or temporarily suspend or
        convert the information into an anonymous data format unless the laws
        require the Company to continue maintaining that information.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        6.6 Right to request to update information completely and does not cause
        misunderstandings.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        In the event that your personal information is not current, incomplete,
        which may cause misunderstandings to third parties, you can continue to
        update your personal information completely and not be offensive at any
        time through the HashU Platform by yourself. However, if the Company has
        an error or has problems or obstacles causing you not to do this by
        yourself, you can inform the Company to proceed according to the methods
        and channels specified by the Company in section 2.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        6.7 Right to complain to relevant agencies
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        Suppose the Company is a data controller following the definition in the
        Personal Data Protection Act. If the Company does not comply with your
        request or exercise of rights, you, as the personal data subject, have
        the right to submit a complaint with the Company to the relevant
        authorities and are responsible for it.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The Company would like to clarify that the exercise of your rights as
        shown in this section 6 must not cause damage to the rights and freedoms
        of other people. If it is such a case or in the case of the Company is
        required to comply with the laws or court order, the Company reserves
        the right not to process your request.
      </Typography>
    </Box>
  );
}
