import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Stack,
  Avatar,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
// style and icons
import { useShippingProductDialogStyle as classes } from "../JobTab.style";
import CancelIcon from "@mui/icons-material/Cancel";
// type and others
import { IShippingProductDialogProps } from "../JobTab.type";
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";
import dayjs from "dayjs";

export default function ShippingProductDialog(
  props: IShippingProductDialogProps
) {
  const { openShippingProduct, setOpenShippingProduct, jobInfo } = props;

  const [viewImageDialog, setViewImageDialog] = useState(false);

  const shipping = jobInfo?.shipping_address;
  const delivery = jobInfo?.delivery_confirmation;

  const onViewJobImage = (status: boolean) => {
    setOpenShippingProduct(!status);
    setViewImageDialog(status);
  };

  return (
    <>
      <Dialog
        open={openShippingProduct}
        onClose={() => setOpenShippingProduct(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={classes.dialog}
      >
        <DialogTitle
          sx={{
            ...classes.dialogTitle,
            width: "89%",
            mt: "10px",
          }}
          id="alert-dialog-title"
        >
          <Typography variant="title1">Shipping Product</Typography>
          <IconButton onClick={() => setOpenShippingProduct(false)}>
            <CancelIcon viewBox="3 3 18 18" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={classes.contentDialog}>
          <Box my="20px" mx="10px">
            <Box>
              <Typography variant="h6">
                Shipping Product to Influencer
              </Typography>
              <Typography variant="subTitle2" color="#5C5C5C">
                ผู้จ้างงานส่งสินค้าสำหรับการรีวิวให้อินฟลูเอนเซอร์
              </Typography>
            </Box>
            <Grid container spacing={4} mt="15px">
              <Grid item xs={3.5}>
                <Typography variant="title1">Shipping Type</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography>{shipping?.shipping_type}</Typography>
              </Grid>
              <Grid item xs={3.5}>
                <Typography variant="title1">Tracking Code</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography variant="title1" sx={classes.trackingCode}>
                  {shipping?.tracking_no}
                </Typography>
              </Grid>
              <Grid item xs={3.5}>
                <Typography variant="title1">Note</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography>{shipping?.note ? shipping.note : "-"}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: "15px" }} />
            <Box>
              <Typography variant="h6">Confirm Goods Received</Typography>
              <Typography variant="subTitle2" color="#5C5C5C">
                ยืนยันสินค้าทีไ่ด้รับจากผู้จ้างงาน
              </Typography>
            </Box>
            <Grid container spacing={3} mt="15px">
              <Grid item xs={3.5}>
                <Typography variant="title1">Date of Receipt</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography>
                  {delivery?.date_of_receipt
                    ? dayjs(delivery.date_of_receipt).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3.5}>
                <Typography variant="title1">Photos</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Stack
                  direction="row"
                  columnGap={2}
                  sx={classes.photos}
                  onClick={() => onViewJobImage(true)}
                >
                  {delivery?.attachment.slice(0, 3).map((item, index) => (
                    <Avatar key={index} src={item.url} />
                  ))}
                  {Number(delivery?.attachment.length) > 3 && (
                    <Box sx={classes.photosItem}>
                      <Typography>
                        + {Number(delivery?.attachment.length) - 3}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <Typography variant="title1">Note</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <Typography>{delivery?.note ? delivery.note : "-"}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: "15px" }} />
            <Box sx={classes.closeBtn}>
              <Button
                onClick={() => setOpenShippingProduct(false)}
                variant="outlined"
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ViewMultiImageDialog
        open={viewImageDialog}
        onClose={onViewJobImage}
        images={delivery?.attachment}
      />
    </>
  );
}
