import { useState } from "react";
// mui and icons
import {
  Box,
  Fab,
  Icon,
  Grid,
  Stack,
  Avatar,
  Divider,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// style and type
import { useImagesStyle as classes } from "../JobRejected.style";
import { IJobRejectedImages } from "../../../JobsTab.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerJobInfo } from "@slices/InfluencerJobInfo.slice";
// components and others
import MultiImageInput from "../../../job-default/MultiImageInput";
import ViewMultiImageDialog from "@global/view-image-dialog/ViewMultiImageDialog";

export default function Images(props: IJobRejectedImages) {
  const {
    form: {
      watch,
      setValue,
      formState: { errors },
    },
    isDraft,
    isEditJob,
    newAttList,
    attRejectedList,
  } = props;

  const [viewDraftImage, setViewDraftImage] = useState(false);

  const jobInfo = useAppSelector(selectInfluencerJobInfo);
  const contentType = jobInfo?.content_type;
  const attList = jobInfo?.attachments || [];

  const attApprovedList = attList.filter(
    (att) => att.is_approve === "approved"
  );
  const attNotApproveList = attRejectedList.filter(
    (att) => att.is_approve === "not_approved"
  );
  const attWatch = watch("attachments") || [];
  const isSinglePhoto = /(single photo)/.test(contentType?.title || "");
  const isDraftAtt = isSinglePhoto
    ? !attApprovedList.length && isEditJob
    : isEditJob && attNotApproveList.length;

  return (
    <Box sx={classes.imageCon}>
      <Box sx={classes.title}>
        <Typography>Images</Typography>
        <Typography>
          อัพโหลดรูปภาพการรีวิวสินค้า (ไฟล์รูปภาพไม่เกิน 8 mb)
          วันที่อินฟลูเอนเซอร์โพสต์งาน
        </Typography>
      </Box>
      <Divider sx={{ mt: ["20px", 0], mr: ["24px", 0] }} />
      {isDraft ? (
        <Stack
          direction="row"
          flexWrap="wrap"
          rowGap={[2, 7]}
          columnGap={[2, 5]}
          p="24px"
        >
          {newAttList.map((att, index) => (
            <Box key={index} sx={classes.attIsActiveBox}>
              {att.is_approve === "approved" && (
                <Fab sx={classes.attApprovedIcon}>
                  <Icon className="ico-hu-check" sx={{ fontSize: "16px" }} />
                </Fab>
              )}
              <Avatar
                src={att.url}
                sx={classes.imgItem}
                onClick={() => setViewDraftImage(true)}
              />
              <Typography variant="body2" textAlign="center" mt={2}>
                {`รูปที่ ${att.order}`}
              </Typography>
            </Box>
          ))}
        </Stack>
      ) : (
        <>
          {attRejectedList.length ? (
            <Grid container direction="column" spacing={5} p="24px">
              <Box sx={classes.titleImgItem}>
                <CancelIcon />
                <Typography>รูปภาพที่ต้องแก้ไข</Typography>
              </Box>

              <Stack
                direction="row"
                columnGap={[7, 4, 7]}
                rowGap={7}
                flexWrap="wrap"
                mr={5}
                ml={[0, 5]}
              >
                {attRejectedList.map((att, index) => (
                  <Box key={index} sx={classes.imgItemEditingGrid}>
                    <Typography variant="title1" mb="8px">
                      {`รูปที่ ${att.order}`}
                    </Typography>
                    <Avatar src={att.url} sx={classes.imgItemEditing} />
                    <Box sx={classes.imgItemEditingDesc}>
                      <Typography>ความคิดเห็นจากผู้จ้างงาน :</Typography>
                      <Typography>{att.note}</Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Grid>
          ) : null}
          {isDraftAtt ? (
            <>
              <Divider sx={{ m: "0 24px 24px 24px" }} />
              <Box p={["0 24px 24px 0", "0 24px 24px 24px"]}>
                <Box display="flex" mb="15px">
                  <AddCircleRoundedIcon color="primary" />
                  <Typography color="primary" fontWeight="500" ml={1.5}>
                    เพิ่มรูปภาพ
                  </Typography>
                </Box>
                <Box display="flex" mb="20px">
                  <Typography textAlign="center">เครื่องหมาย </Typography>
                  <Icon
                    className="ico-hu-check-circle"
                    color="success"
                    sx={{ mx: "5px" }}
                  />{" "}
                  <Typography textAlign="center">
                    บนรูปภาพ คือ รูปทีได้รับการอนุมัติจากผู้จ้างงานแล้ว
                  </Typography>
                </Box>
                <Box sx={classes.multiImgInputBox}>
                  <MultiImageInput
                    keyName="attachments"
                    setValue={setValue}
                    errors={errors?.attachments}
                    images={attWatch}
                    showImageIndex
                    disableIsActive
                  />
                </Box>
              </Box>
            </>
          ) : attApprovedList.length ? (
            <>
              <Divider sx={{ m: "0 24px 24px 24px" }} />
              <Box p={["0 24px 24px 0", " 0 24px 24px 24px"]}>
                <Box sx={classes.imgItemApprove}>
                  <CheckCircleIcon />
                  <Typography>รูปภาพที่ได้รับการอนุมัติแล้ว</Typography>
                </Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  rowGap={[2, 7]}
                  columnGap={[2, 5]}
                >
                  {attApprovedList.map((item, index) => (
                    <Box key={index} mt={-2}>
                      <Typography variant="title1">
                        {`รูปที่ ${item.order}`}
                      </Typography>
                      <Avatar
                        src={item.url}
                        sx={classes.imgItem}
                        onClick={() => setViewDraftImage(true)}
                      />
                    </Box>
                  ))}
                </Stack>
              </Box>
            </>
          ) : null}
        </>
      )}

      <ViewMultiImageDialog
        open={viewDraftImage}
        onClose={setViewDraftImage}
        images={jobInfo?.attachments || attApprovedList || attRejectedList}
      />
    </Box>
  );
}
