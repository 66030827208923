// mui and style
import {
  Box,
  Icon,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from "@mui/material";
import { usePaymentCompletedDialogStyle as classes } from "../Subscription.style";
// type and others
import { IPaymentResultDialogProps } from "../Subscription.type";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export default function PaymentResultDialog(props: IPaymentResultDialogProps) {
  const { open, onClose, data, redirect } = props;

  const numberFormat = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const isSuccess = data?.status_id === 1;
  const history = useHistory();

  const onCloseDialog = () => {
    onClose(false);
    if (redirect) history.push(redirect);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ p: "45px" }}>
        <Box display="flex" justifyContent="center">
          <Box
            sx={{
              ...classes.checkIcon,
              bgcolor: isSuccess ? "#4CAF50" : "#E30D18",
            }}
          >
            <Icon
              className={`ico-hu-${isSuccess ? "check" : "close"}`}
              sx={{ fontSize: "100px", color: "#fff" }}
            />
          </Box>
        </Box>
        <Typography variant="h5" textAlign="center" mt="24px">
          {data?.status}
        </Typography>
        {isSuccess ? (
          <Box mt="24px">
            {[
              {
                label: "วันที่ทำรายการ",
                value: dayjs(data?.transaction_date).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
              },
              { label: "เลขที่รายการ", value: data?.order_id },
              {
                label: "ช่องทางการชำระเงิน",
                value: data?.payment_method.replace("_", " "),
              },
            ].map((li, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                mb="10px"
              >
                <Typography variant="title1">{li.label}</Typography>
                <Typography textTransform="capitalize">{li.value}</Typography>
              </Box>
            ))}
            <Box sx={classes.wallet}>
              <Typography variant="title1">จำนวนเงิน</Typography>
              <Typography variant="h6" color="#8C499C">
                {numberFormat.format(data?.amount || 0)} บาท
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography textAlign="center" mb="24px" mt="5px">
            เกิดข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง
          </Typography>
        )}
        <Box sx={classes.closeBtn}>
          <Button variant="contained" onClick={onCloseDialog}>
            {isSuccess ? "เสร็จสิ้น" : "Close"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
