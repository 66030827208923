// mui and type
import { Grid, Typography } from "@mui/material";
import { IOrganizingStatusProps } from "../PrivateCampaign.type";

export default function OrganizingStatus(props: IOrganizingStatusProps) {
  const { jobLimit, privateSummary } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Grid container>
      <Grid item xs={12} sm={6} lg={2}>
        <Typography>จำนวนอินฟลูเอนเซอร์</Typography>
        <Typography
          variant="h5"
          color={privateSummary.privateSelected > jobLimit ? "red" : "#893DBD"}
        >
          {privateSummary.privateSelected}/{jobLimit}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={1.5}>
        <Typography>Followers</Typography>
        <Typography variant="h5" color="#893DBD">
          {numberFormat.format(privateSummary.followers)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={1.5}>
        <Typography>Likes</Typography>
        <Typography variant="h5" color="#893DBD">
          {numberFormat.format(privateSummary.likes)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={2}>
        <Typography>Comments</Typography>
        <Typography variant="h5" color="#893DBD">
          {numberFormat.format(privateSummary.comments)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={2}>
        <Typography>Shares</Typography>
        <Typography variant="h5" color="#893DBD">
          {numberFormat.format(privateSummary.shares)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography>Budget</Typography>
        <Typography variant="h5" color="#893DBD">
          {Intl.NumberFormat("en").format(Math.round(privateSummary.budgets))} THB
        </Typography>
      </Grid>
    </Grid>
  );
}
