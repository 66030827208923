import { useState } from "react";
// mui
import {
  Box,
  Grid,
  Avatar,
  Button,
  Divider,
  useTheme,
  Accordion,
  Typography,
  useMediaQuery,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
// icon and style
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useInfluencerHistoryStyle as classes } from "./Earning.style";
// type and other
import { IEarningItemInfo } from "@api/influencer/type";
import { SocialIconSelected } from "../global/icon-selected/SocialIconSelected";

export default function InfluencerHistorySm({ earningList }: { earningList: IEarningItemInfo[] }) {
  const [expanded, setExpanded] = useState<string | false>(false);

  const theme = useTheme();
  const screenMd = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container display={{ md: "none" }} spacing={3}>
      {earningList.map((item, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          display="flex"
          justifyContent="center"
          sx={classes.card}
        >
          <Accordion
            sx={classes.accordion}
            expanded={expanded === `panel${index}` || screenMd}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary>
              <Box display="flex" alignItems="center" width="100%">
                <Avatar
                  src={item.brand_logo_image_url}
                  sx={classes.campaignIcon}
                />
                <Box ml={3} sx={classes.influName}>
                  <div>
                    <Typography
                      color="primary"
                      variant="title1"
                      fontWeight={500}
                    >
                      {item.social_name}
                    </Typography>
                    <Typography variant="body2">
                      {`${new Intl.NumberFormat(
                        "jp-JP",
                        { minimumFractionDigits: 2 }
                      ).format(item.amount)} THB`}
                    </Typography>
                  </div>
                  {!screenMd && <KeyboardArrowDownIcon />}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ mt: "-4px" }} />
              <Box sx={classes.cardTable}>
                <div className="cardTable">
                  <Typography variant="body2" color="#505050">
                    Transaction ID
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {item.transaction_id}
                  </Typography>
                </div>
                <div className="cardTable">
                  <Typography variant="body2" color="#505050">
                    ผู้รับเงิน
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <SocialIconSelected
                      platform="facebook"
                      width="16px"
                      height="16px"
                    />
                    <Typography variant="body2" fontWeight={500} ml="5px">
                      {item.fullname}
                    </Typography>
                  </Box>
                </div>
                <div className="cardTable">
                  <Typography variant="body2" color="#505050">
                    วันสิ้นสุดแคมเปญ
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {item.completed_date || "-"}
                  </Typography>
                </div>
                <div className="cardTable">
                  <Typography variant="body2" color="#505050">
                    วันที่รับเงิน
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {item.receipt_date || "-"}
                  </Typography>
                </div>
                <Box className="cardTable" mb={2}>
                  <Typography variant="body2" color="#505050">
                    สถานะ
                  </Typography>
                  <Box sx={item.status === "paid" ? classes.statusPaid : classes.statusPending}>
                    {item.status}
                  </Box>
                </Box>
                <Box sx={classes.earning}>
                  <Typography variant="body2" color="#505050">
                    Earnings
                  </Typography>
                  <Typography
                    variant="title1"
                    fontWeight={500}
                    color="primary"
                  >
                    {`${new Intl.NumberFormat("jp-JP", {
                      minimumFractionDigits: 2,
                    }).format(item.amount)} THB`}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="body2" color="#505050">
                  เอกสาร
                </Typography>
                <div>
                  <Button
                    onClick={() => window.open(item.reciept_info.pdf_url)}
                    sx={classes.button}
                    disabled={item.status === "pending"}
                  >
                    <Typography variant="buttonM">ใบเสร็จ</Typography>
                  </Button>
                  {item.amount >= 1000 && (
                    <Button
                      onClick={() => window.open(item.tax_info.pdf_url)}
                      sx={classes.taxButton}
                      disabled={item.status === "pending"}
                    >
                      <Typography variant="buttonM">หัก ณ ที่จ่าย</Typography>
                    </Button>
                  )}
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
}
