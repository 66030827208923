// mui and style
import {
  Box,
  Card,
  Icon,
  Stack,
  Avatar,
  Divider,
  Typography,
} from "@mui/material";
import { useTeamManagementStyle as classes } from "../TeamManagement.style";
// redux
import { ITeamInfo } from "@api/team/type";
import dayjs from "dayjs";

export default function HeaderMember({ teamInfo } : { teamInfo?: ITeamInfo | null }) {

  return (
    <Card sx={classes.cardCon}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Avatar
            variant="square"
            src={teamInfo?.attachment?.url}
            sx={classes.avatar}
          />
          <Box sx={classes.boxHeaderDetail}>
            <Typography variant="h6">{teamInfo?.name}</Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Box display="flex" alignItems="center">
                <Icon className="ico-hu-group" sx={{ marginRight: "4px" }} />
                <Typography>Team ID : {teamInfo?.code}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Icon
                  className="ico-hu-calendar-date"
                  sx={{ mr: "4px" }}
                />
                <Typography>
                  {teamInfo?.created_at ? dayjs(teamInfo?.created_at).format("DD/MM/YYYY") : "-"}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box sx={classes.boxStatus}>
          <Icon className="ico-hu-group" sx={{ mr: "4px" }} />

          <Typography variant="title1" textTransform="capitalize">
            {teamInfo?.package?.title || "free"}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
