import { useEffect, useState } from "react";
// component
import HomeMarketer from "@components/home-marketer/HomeMarketer";
import CreateAccountDialog from "@components/authentication-dialog/CreateAccountDialog";
// others
import { IDevice } from "../global/global.types";
import useBreakpoint from "@utils/hooks/useBreakpoint";

export default function HomeMarketerPage() {
  const [device, setDevice] = useState<IDevice>("mobile");
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  
  const bp = useBreakpoint();
  
  useEffect(() => {
    if (bp === "xs") {
      setDevice("mobile");
    } else if (bp === "sm" || bp === "md") {
      setDevice("tablet");
    } else {
      setDevice("desktop");
    }
  }, [bp]);

  return (
    <div>
      <HomeMarketer
        device={device}
        breakpoint={bp}
        setIsOpenCreateAccount={setOpenCreateAccount}
      />

      {/* -- UAT comment here -- */}
      <CreateAccountDialog
        open={openCreateAccount}
        setOpen={setOpenCreateAccount}
        accountSelected={"marketer"}
      />
      {/* -- End comment -- */}
    </div>
  );
}
