// mui, icon and style
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useMyProfileForMobileStyle as classes } from "../../MyProfile.style";
// component
import InfluencerAddress from "@components/influencer-profile/influencer-default/Address";
import InfluencerProfileInfo from "@components/influencer-profile/influencer-default/MyProfile";
import InfluencerPortfolio from "@components/influencer-profile/influencer-default/MyPortfolio";
import InfluencerBankAccount from "@components/influencer-profile/influencer-default/BankAccount";
// redux
import {
  selectInfluencerProfile,
  selectInfluencerProfileOnload,
} from "@slices/InfluencerProfile.slice";
import { useAppSelector } from "@store/hook";
// othe
import { useHistory } from "react-router-dom";
import Loading from "@global/loading/Loading";

export default function MyProfileForMobile() {
  const history = useHistory();

  const influencerInfo = useAppSelector(selectInfluencerProfile);
  const influencerLoading = useAppSelector(selectInfluencerProfileOnload);

  const profile = (influencerInfo?.media?.images || []).find(
    (img) => img?.purpose === "profile_image"
  );

  const profileItem = (purpose: string) => {
    return (influencerInfo?.media?.images || []).find(
      (img) => img?.purpose === purpose
    )?.url;
  };

  if (influencerLoading) return <Loading />

  return (
    <Box className="container-main" sx={{ bgcolor: "#E0E0E0" }}>
      <Box
        sx={{
          ...classes.profileWrap,
          backgroundImage: `url(${profile?.url})`,
        }}
      >
        <IconButton
          onClick={() => history.push("/my-profile")}
          sx={classes.editProfile}
        >
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: "#000" }} />
        </IconButton>
        <Box sx={classes.profileItemWrap}>
          <Box m={["0 0 20px 25px", "20px"]}>
            <Typography variant="h6" color="#fff">
              {influencerInfo?.fullname}
            </Typography>
            <Box display="flex" columnGap="10px">
              {[
                `age : ${influencerInfo?.age}`,
                influencerInfo?.gender,
                influencerInfo?.nationality,
              ].map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  textTransform="capitalize"
                  color="#fff"
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Box display="flex" mt="16px">
              {["frontal", "portrait", "headshot"].map((img, index) => (
                <Avatar
                  key={index}
                  src={profileItem(`influencer_${img}_image`)}
                  sx={{ width: "40px", height: "40px", borderRadius: 0 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" rowGap="16px">
        {[
          <InfluencerProfileInfo />,
          <InfluencerAddress />,
          <InfluencerBankAccount />,
          <InfluencerPortfolio />,
        ].map((component, index) => (
          <Box key={index} sx={classes.componentWrap}>
            {component}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
