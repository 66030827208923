const tab = {
  height: "100%",
  width: "160px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: {
    xs: "8px",
    sm: "20px 20px 0 0",
  },
  cursor: "pointer",
  bgcolor: {
    xs: "#fff",
    sm: "none",
  },
  zIndex: 1,
  borderBottom: {
    xs: "initial",
    sm: "1px solid #8C499C",
  },
  "& > span": {
    display: {
      sm: "none",
    },
  },
  border: "none",
  "& > p": {
    color: "#8C499C",
  },
};

export const useMyJobsDetailStyle = {
  myJobsCon: {
    objectFit: "cover",
    m: {
      xs: "-30px -10px -55px -20px",
      sm: "-30px -28px -55px -25px",
      md: "-20px 0 0 -20px",
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: ["240px", "240px", "100%"],
  },
  callback: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    mb: "15px",
    width: {
      xs: "10%",
      md: "4%",
    },
    "& svg, span": {
      color: "black",
      fontSize: "14px",
    },
    "& > span": {
      display: {
        xs: "none",
        md: "initial",
      },
    },
    "& > svg": {
      m: {
        xs: "13px 0 0 17px",
        md: "13px 0 13px 13px",
      },
      bgcolor: {
        xs: "#fff",
        md: "initial",
      },
      borderRadius: "50%",
      fontSize: {
        xs: "30px",
        md: "16px",
      },
    },
  },
  campaignTab: {
    minHeight: ["65px", "34px", "44px"],
    maxHeight: ["65px", "34px", "44px"],
    borderRadius: ["8px", "18px 12px 0 0"],
    margin: ["15px 0 20px 0", "15px 0 3px 0"],
    "& .css-i4bv87-MuiSvgIcon-root, .css-bpy6lo > .MuiTab-iconWrapper, .css-wvkgta > .MuiTab-iconWrapper, .css-vubbuv":
      {
        display: ["flex", "none"],
        width: "20px",
        height: "20px",
      },
    "& .css-m5f4gq-MuiTypography-root": {
      fontSize: ["12px", "14px"],
    },
    "& .css-heg063-MuiTabs-flexContainer": {
      justifyContent: ["space-between", "start"],
      height: ["70px", 0],
      ml: [0, "-10px"],
    },
    "& .css-16fhvdp .css-k008qs, .css-k008qs": {
      justifyContent: ["space-between", "start"],
    },
    display: "flex",
    justifyContent: "space-between",
  },
  tabBox: {
    display: "flex",
    justifyContent: {
      xs: "space-between",
      sm: "start",
    },
    m: {
      xs: "16px 0 16px 0",
      sm: "24px 0 0 0",
    },
    height: { xs: "66px", sm: "44px" },
    borderRadius: {
      xs: "8px",
      sm: 0,
    },
    overflow: "hidden",
    bgcolor: {
      xs: "#fff",
      sm: "initial",
    },
    boxShadow: {
      xs: "0px 12px 60px rgba(118, 118, 128, 0.08), 0px -1px 4px rgba(118, 118, 128, 0.02), 0px 2px 24px rgba(118, 118, 128, 0.02)",
      sm: "none",
    },
  },
  tab: tab,
  tabSelected: {
    ...tab,
    bgcolor: "#8C499C",
    "& > p, > span": {
      color: "#fff",
    },
  },
  tabDisabled: {
    ...tab,
    bgcolor: {
      xs: "#fff",
      sm: "#E0E0E0",
    },
    "& > span, > p": {
      color: {
        xs: "#E0E0E0",
        sm: "#fff",
      },
    },
    borderBottom: "1px solid #E0E0E0",
    cursor: "context-menu",
  },
};
