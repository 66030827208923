// mui and style
import { Box, Divider, Typography } from "@mui/material";
import { useSocialTabStyles as classes } from "./SocialTab.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
// type and other
import GetPlatform from "@global/get-platform";

type Average =
  | "follower"
  | "avg_like"
  | "avg_comment"
  | "avg_share"
  | "engagement_rate";

export default function Overall() {

  const socialInfo = useAppSelector(selectInfluencerSocial)

  const percentFormat = (value: number) => {
    let total = Math.round(value * 100) / 100;
    return total + "%";
  };

  const numFormat = Intl.NumberFormat("en", {
    notation: "compact",
  });

  return (
    <Box display="flex" flexDirection="column" rowGap="12px">
      {Object.values(socialInfo || {}).map((item, index) => (
        <Box
          key={index}
          display={item.length <= 0 ? "none" : "initial"}
          sx={classes.boxSocial}
        >
          <Box display="flex" alignItems="center">
            <GetPlatform platform={item?.[0]?.platform} />
            <Typography variant="h6">
              {item?.[0]?.social_account?.[0]?.name}
            </Typography>
          </Box>
          <Divider sx={{ margin: "10px 0" }} />
          <Box display="flex" justifyContent="space-between">
            {[
              { label: "Followers", value: "follower" },
              { label: "Avg. Likes", value: "avg_like" },
              { label: "Avg. Comments", value: "avg_comment" },
              { label: "Avg. Shares", value: "avg_share" },
              { label: "Engagement Rate", value: "engagement_rate" },
            ].map((avg, index) => {
              const value =
                item?.[0]?.social_account?.[0]?.[avg?.value as Average];
              return (
                <Box key={index}>
                  <Typography mt={2}>{avg.label}</Typography>
                  <Typography variant="h5">
                    {avg?.value === "engagement_rate"
                      ? percentFormat(value)
                      : numFormat.format(value)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
