// mui and icon
import { Box, Grid, Stack, Divider, Typography } from "@mui/material";
import RssFeedIcon from "@mui/icons-material/RssFeed";
// style and type
import { useDraftApprovalStyle as classes } from "../EmployerJob.style";
import { IDraftApprovalProps } from "../EmployerJob.type";
// redux
import { useAppSelector } from "@store/hook";
import { selectEmployerJobInfo } from "@slices/EmployerJobInfo.slice";
// others
import DateRangeInputCustom from "@global/date-range-input-custom/DateRangeInputCustom";
import TextFieldCustom from "@global/text-field";
import dayjs from "dayjs";

export default function DatePost(props: IDraftApprovalProps) {
  const {
    form: {
      watch,
      register,
      setValue,
      formState: { errors },
    },
  } = props;

  const jobInfo = useAppSelector(selectEmployerJobInfo);
  const jobStatus = jobInfo?.campaign_info.status;
  const publishedRange = jobInfo?.published_range;
  const timeline = jobInfo?.campaign_info.timeline;
  const isDraftPost = jobStatus === "published" && publishedRange?.published_at;

  return (
    <Box sx={classes.datePostBox}>
      <Box sx={classes.datePostTitle}>
        <Typography>Date Post</Typography>
        <Typography>
          {isDraftPost
            ? "วันที่อินฟลูเอนเซอร์โพสต์งาน"
            : "โปรดระบุวันที่ต้องการให้อินฟลูเอนเซอร์โพสต์งาน"}
        </Typography>
      </Box>
      <Divider sx={classes.divider} />
      {!isDraftPost && (
        <Box sx={classes.publishedBox}>
          <Box sx={classes.publishedIcon}>
            <RssFeedIcon sx={{ color: "#505050", fontSize: "30px" }} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="space-between">
            <Typography variant="title1">โพสต์และแนบลิงค์ส่งงาน</Typography>
            <Typography color="#505050">
              {dayjs(timeline?.start_publish_date).format("DD/MM/YYYY")} -{" "}
              {dayjs(timeline?.end_publish_date).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
      )}
      <Grid container spacing={4}>
        <Grid item xs={2.5}>
          <Typography fontWeight={500}>
            Published Date{" "}
            {isDraftPost ? "" : <span className="require">*</span>}
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          {isDraftPost ? (
            <Typography>{`${dayjs(publishedRange?.published_at).format(
              "DD/MM/YYYY"
            )} - ${dayjs(publishedRange?.end_published_at).format(
              "DD/MM/YYYY"
            )}`}</Typography>
          ) : (
            <DateRangeInputCustom
              startKeyName="publish_range.published_at"
              endKeyName="publish_range.end_published_at"
              startError={errors.publish_range?.published_at?.message}
              endError={errors.publish_range?.end_published_at?.message}
              minDate={timeline?.start_publish_date}
              maxDate={timeline?.end_publish_date}
              placeholderFirst="Start Date"
              placeholderSecond="End Date"
              setValue={setValue}
              watch={watch}
            />
          )}
        </Grid>
        <Grid item xs={2.5}>
          <Typography fontWeight={500}>
            Time range
            {isDraftPost ? "" : <span className="require">*</span>}
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          {isDraftPost ? (
            <Typography>
              {`${publishedRange?.start_time} - ${publishedRange?.end_time}`}
            </Typography>
          ) : (
            <Stack direction="row" spacing={2}>
              <TextFieldCustom
                variant="outlined"
                type="time"
                fullWidth
                inputProps={{ ...register("publish_range.start_time") }}
              />
              <Box display="flex" alignItems="center">
                <span>-</span>
              </Box>
              <TextFieldCustom
                variant="outlined"
                type="time"
                fullWidth
                inputProps={{ ...register("publish_range.end_time") }}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={2.5}>
          <Typography fontWeight={500}>Note</Typography>
        </Grid>
        <Grid item xs={8.5}>
          {isDraftPost ? (
            <Typography>
              {publishedRange?.note || "-"}
            </Typography>
          ) : (
            <TextFieldCustom
              variant="outlined"
              multiline
              minRows={4}
              placeholder="Please enter text..."
              fullWidth
              inputProps={{ ...register("publish_range.note") }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
