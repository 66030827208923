// mui and style
import { Box, Button, Grid, Typography } from "@mui/material";
import { useCampaignInfluencerListStyle as classes } from "../InfluencerList.style";
// icon and type
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { ISelectInfluencerProps } from "../InfluencerList.type";

export default function SelectInfluencer(props: ISelectInfluencerProps) {
  const {
    candidateInfluencer,
    toggleInfluSelectedDrawers,
    newEstimateSummary,
  } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนที่สมัคร</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {candidateInfluencer?.total_item}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนที่ต้องการ</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {newEstimateSummary?.totalInfluencerSelected} /{" "}
            {candidateInfluencer?.summary?.job_limit}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>Follower</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {numberFormat.format(newEstimateSummary?.followers || 0)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>Likes</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {numberFormat.format(newEstimateSummary?.likes || 0)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>Comments</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {numberFormat.format(newEstimateSummary?.comments || 0)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} lg={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>Share</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {numberFormat.format(newEstimateSummary?.shares || 0)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Button
          onClick={toggleInfluSelectedDrawers}
          sx={classes.influeSelected}
          fullWidth
        >
          <PersonAddAltIcon />
          <Typography>ผู้ที่ได้รับการคัดเลือก</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
