import { useEffect, useState } from "react";
// mui and icon
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// style
import {
  ReferralTab,
  ReferralTabs,
  useMyReferralStyle as classes,
} from "./MyReferral.style";
// components
import InviteFriends from "@components/influencer-referral/invite-friends/InviteFriends";
import CurrentActivity from "@components/influencer-referral/current-activity/CurrentActivity";
// redux
import { useAppDispatch } from "@store/hook";
import { getActivityAsync } from "@slices/Activity.slice";
import { getInfluencerProfileAsync } from "@slices/InfluencerProfile.slice";
// other
import { useHistory } from "react-router-dom";
import { scrollToTop } from "@utils/helpers/scrollToTop";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

function TabPanel(props: any) {
  const { children, value, index } = props;
  return <Box width="100%">{value === index && <Box>{children}</Box>}</Box>;
}

export default function MyReferral() {
  const [value, setValue] = useState(0);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { session_token } = getTokenFromStorage();
  const influencerId = session_token?.influencer_id;

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    scrollToTop();
    dispatch(getInfluencerProfileAsync({ influencerId }));
    dispatch(getActivityAsync({ page: 1, item_per_page: 10 }));
  }, [dispatch, influencerId]);

  const changTabs = (_event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box className="container-main" position="relative">
      <Box sx={classes.titleBox}>
        <Box sx={classes.backBox} onClick={() => history.goBack()}>
          <ArrowBackIosIcon />
          <Typography variant="buttonS">Back</Typography>
        </Box>
        <Box sx={classes.title}>
          <Typography variant="h5">My Referral</Typography>
          <Box sx={classes.tabBox}>
            <ReferralTabs
              value={screenSm ? value : false}
              onChange={changTabs}
              centered
            >
              <ReferralTab label="Invite Friends" />
              <ReferralTab label="Activity" />
            </ReferralTabs>
          </Box>
        </Box>
      </Box>
      <Box sx={classes.bgSticky} />

      {/* for mobile screen */}
      <Grid container display={{ lg: "none" }}>
        <TabPanel value={value} index={0}>
          <InviteFriends />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CurrentActivity />
        </TabPanel>
      </Grid>

      {/* for desktop screen */}
      <Grid container spacing={5} display={{ xs: "none", lg: "flex" }}>
        <InviteFriends />
        <CurrentActivity />
      </Grid>
    </Box>
  );
}
