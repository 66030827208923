import { instance } from "@api/global/instance";
import { API_KEY, SOCIAL_URL, SYNC_FACEBOOK, SYNC_INSTAGRAM_AND_TWITTER } from "@utils/constants/api.constants";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";

export class SocialAPIs {
  async getVertifyTiktokCode(body: { url:string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const { data } = await instance.post(`${SOCIAL_URL}/tiktok/verify-code`, new URLSearchParams({ url: body.url }), { headers });
    return data.result.data;
  }

  async verifyTiktokAccount(body: { username:string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const { data } = await instance.post(`${SOCIAL_URL}/tiktok/verify-account`, new URLSearchParams({ username: body.username }), { headers });
    return data.result.data;
  }

  async verifyInstagramAccount(body: { username:string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const { data } = await instance.post(`${SOCIAL_URL}/instagram/verify-account`, new URLSearchParams({ username: body.username }), { headers });
    return data.result.data;
  }

  async getVertifyInstagramCode(body: { url:string }) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const { data } = await instance.post(`${SOCIAL_URL}/instagram/verify-code`, new URLSearchParams({ url: body.url }), { headers });
    return data.result.data;
  }

  async saveTwitterToken() {
    const { access_token } = getTokenFromStorage();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };
    const { data } = await instance.post(`${SOCIAL_URL}/twitter/tokens`, { headers });
    return data.result.data;
  }

  async signOutFacebook(socialId: number) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.delete(`${SYNC_FACEBOOK}/facebook/${socialId}/logout`, { headers });
    return data.result.data;
  }

  async signOutSocial(plateform: string, socialId: number) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.delete(`${SYNC_INSTAGRAM_AND_TWITTER}/${plateform}/${socialId}/logout`, { headers });
    return data.result.data;
  }

  async signOutYoutube(socialId: number) {
    const { access_token } = getTokenFromStorage();

    const headers = {
      "X-API-Key": API_KEY,
      Authorization: `Bearer ${access_token}`,
    };
    const { data } = await instance.delete(`${SOCIAL_URL}/youtube/${socialId}/logout`, { headers });
    return data.result.data;
  }
}