// mui and icon
import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// type and style
import { useChargeNotSuccessStyle as classes } from "../CampaignCharge.style";
import { IChargeNotSuccessProps } from "@views/campaign/campaign-charge/Charge.type";

export default function PaymentNotSuccess(props: IChargeNotSuccessProps) {
  const { open, onClose } = props;

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent sx={{ my: "24px", mx: "40px" }}>
        <Box sx={classes.titleBox}>
          <CancelIcon sx={classes.iconNotSuccess} />
          <Typography variant="h5" mt="24px">
            ทำรายการไม่สำเร็จ
          </Typography>
          <Typography mt={2}>
            เกิดข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogAction}>
        <Button variant="contained" onClick={() => onClose(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
