// mui and icon
import { Box, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
// other
import chroma from "chroma-js";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

interface ICreatableSelect {
  options: any[];
  [any: string]: any;
  setValue?: Function;
}

function CreatableSelectCustom(props: ICreatableSelect) {
  const { options, value, onChange, prefix, badgeColor, toggle, type, status } =
    props;
  const getColor = "#8C499C";

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles: any, { menuIsOpen }: any) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: menuIsOpen ? "15px 15px 0 0" : "20px",
      borderColor: "#8C499C",
      whiteSpace: "nowrap",
      boxShadow: "0 0 0 0.1px #8C499C",
      ":focus": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      ":hover": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 0.1px #8C499C",
      },
      ":active": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      ":visited": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      ":target": {
        borderColor: "#8C499C",
        boxShadow: "0 0 0 1px #8C499C",
      },
      "::placeholder": {
        fontSize: "14px",
        fontWeight: 300,
      },
      fontSize: "14px",
      fontWeight: 300,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma(getColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? getColor
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : getColor,
        cursor: isDisabled ? "not-allowed" : "default",
        marginTop: "-3.5px",
        marginBottom: "-4px",
        ":active": {
          ...styles[":active"],
          borderColor: "#8C499C",
          backgroundColor: !isDisabled
            ? isSelected
              ? getColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles) => {
      const color = chroma(getColor);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        borderRadius: "20px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: getColor,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: getColor,
      ":hover": {
        backgroundColor: getColor,
        color: "white",
      },
    }),
  };

  const styles = {
    badge: {
      // backgroundColor: "primary.main",
      backgroundColor: badgeColor ? badgeColor : "rgba(140, 73, 156, 1)",
      paddingInline: "5px ",
      borderRadius: "25px",
      marginRight: "5px",
      color: "white",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
    },
    valueContainer: {
      display: "flex",
      alignItems: "center",
      mt: "5px",
      // flexWrap: "wrap",
    },
  };

  // const ref = React.createRef();

  const handleRemoveValue = (valueItem: any) => {
    if (!onChange) return;

    if (status === "location") {
      const removedValue = value.find(
        (val: any) => val.name_th === valueItem.name_th
      );

      if (!removedValue) return;
      onChange(
        value.filter((val: any) => val.name_th !== valueItem.name_th),
        { name: valueItem.label, action: "remove-value", removedValue }
      );
    } else if (status === "gender") {
      const gender = [...value];
      const data = gender.filter(
        (item: string) => item !== (valueItem as string)
      );
      onChange(data);
    } else {
      const removedValue = value.find(
        (val: any) => val.value === valueItem.value
      );

      if (!removedValue) return;
      onChange(
        value.filter((val: any) => val.value !== valueItem.value),
        { name: valueItem.label, action: "remove-value", removedValue }
      );
    }
  };

  const onHandleToggleActive = (item: any) => {
    const res = value.map((val: any) => {
      return val.value === item.value;
    });
    onChange(res);
  };

  return (
    <Box
      sx={{
        position: "relative",
        "& .css-26l3qy-menu": {
          mt: "-1px",
          borderRadius: "0 0 15px 15px",
          border: "1px solid #8C499C",
          position: "absolute",
          "& div:last-of-type": {
            borderRadius: "0 0 13.7px 13.7px",
            border: "1px solid #fff",
          },
        },
      }}
    >
      <CreatableSelect
        // ref={ref}
        isMulti
        controlShouldRenderValue={false}
        styles={colourStyles}
        options={options}
        onChange={async (ev1, ev2) => {
          if (status === "gender") {
            const data = ev2.option.value;
            const newList = ev1.map((item) => {
              return item.value === ev2.option.value ? data : item;
            });
            return onChange(newList);
          } else if (status === "hashtag") {
            const data = {
              id: ev2.option.id ? ev2.option.id : 0,
              value: ev2.option.value as string,
              slug: ev2.option.value as string,
              type: !type ? "" : type,
            };
            const newList = ev1.map((item) => {
              return item.id === ev2.option.id ? data : item;
            });
            return onChange(newList);
          } else if (status === "location") {
            const list = {
              id: ev2.option.id,
              name_th: ev2.option.value,
              name_en: ev2.option.value,
              index: 0,
            };
            const newList = ev1.map((item) => {
              return item.id === ev2.option.id ? list : item;
            });
            onChange(newList);
          }
        }}
        value={value}
        // {...props}
      />
      <Box display="flex" flexWrap="wrap">
        {value.length
          ? value.map((item: any, index: number) => {
              return (
                <Box key={index} sx={styles.valueContainer}>
                  <Box style={styles.badge}>
                    {toggle && (
                      <IconButton onClick={() => onHandleToggleActive(item)}>
                        {item.active ? (
                          <i
                            className="ico-hu-add"
                            style={{
                              fontSize: "10px",
                              marginRight: "5px",
                              color: "white",
                            }}
                          />
                        ) : (
                          <i
                            className="ico-hu-remove"
                            style={{
                              fontSize: "10px",
                              marginRight: "5px",
                              color: "white",
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                    <Typography variant="body1" color="commin.white">
                      {prefix && prefix}
                      {status === "location"
                        ? (item.name_en as string).slice(0).toLocaleLowerCase()
                        : status === "gender"
                        ? item
                        : item.value}
                    </Typography>
                    <IconButton
                      // id={item.id}
                      name={item.value}
                      sx={{ top: 0 }}
                      onClick={() => handleRemoveValue(item)}
                    >
                      <ClearIcon sx={{ color: "white", fontSize: "16px" }} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })
          : null}
      </Box>
    </Box>
  );
}

export default CreatableSelectCustom;
