import { RootState } from "@store/store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// api and type
import { AppInformationAPI } from "@api/global";
import { IInitBankState } from "./type";

const initialState: IInitBankState = {
  data: null,
  loading: false,
};

export const getBankListAsync = createAsyncThunk(
  "bankList",
  async () => {
    try {
      const response = await AppInformationAPI.getBankList()
      return response;
    } catch (err) {
      console.log("catch", err);
      throw err;
    }
  }
);

export const bankListSlice = createSlice({
  name: "bankList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBankListAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBankListAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getBankListAsync.rejected, (state) => {
        state.data = null;
        state.loading = false;
      });
  },
});

export const selectBankList = (state: RootState) => state.bankList.data;
export const selectBankListOnload = (state: RootState) => state.bankList.loading;

export default bankListSlice.reducer;
