import { useMyJobsTabStyle } from "../../JobsTab.style";

export const useJobWorkingStyle = {
  jobTabsCon: useMyJobsTabStyle.jobTabsCon,
  jobTitle: useMyJobsTabStyle.jobTitle,
  shippingProBtn: useMyJobsTabStyle.shippingProBtn,
  divider: {
    my: "24px",
    display: {
      xs: "none",
      sm: "flex",
    },
  },
};

export const useConfirmGoodsReceivedStyle = {
  confirmCon: {
    border: {
      xs: "1px solid #fff",
      sm: "2px solid #EBDFFF",
    },
    borderRadius: "12px",
    bgcolor: "#fff",
    p: {
      xs: "24px 24px 0 24px",
      sm: 0,
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  title: {
    p: {
      sm: "24px",
    },
    "& p:first-of-type": { fontSize: "20px", fontWeight: "500" },
    "& p:last-of-type": { fontSize: "14px", color: "#5C5C5C" },
  },
  listingBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& button": {
      maxHeight: "36px",
      width: "248px",
      border: "none",
      "&:hover": { border: "none" },
    },
  },
};

export const useShippingProductToInfluencerStyle = {
  shippingCon: {
    border: {
      xs: "none",
      sm: "2px solid #EBDFFF",
    },
    borderRadius: "12px",
    height: {
      xs: "85%",
      sm: "100%",
      md: "483px",
    },
    bgcolor: "#fff",
    p: {
      xs: "24px",
      sm: 0,
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  title: useConfirmGoodsReceivedStyle.title,
  postCodeLabel: {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      borderRadius: "4px",
      width: "24px",
      height: "24px",
      mr: "10px",
    },
    "& > p": {
      fontWeight: "400",
    },
  },
  postCode: {
    color: "#8C499C",
    textDecoration: "underline",
  },
  address: {
    "& p:first-of-type": {
      fontWeight: "400",
    },
    "& p:nth-of-type(2)": {
      fontWeight: "400",
      color: "#5C5C5C",
      my: "5px",
    },
    "& p:last-of-type": {
      fontWeight: "400",
      color: "#5C5C5C",
    },
  },
};

export const useDraftImagesStyle = {
  imageCon: {
    border: {
      xs: "1px solid #fff",
      sm: "2px solid #EBDFFF",
    },
    borderRadius: "12px",
    bgcolor: "#fff",
    p: {
      xs: "24px 0 0 24px",
      sm: 0,
    },
    boxShadow: {
      xs: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      sm: "none",
    },
  },
  title: useConfirmGoodsReceivedStyle.title,
  imgItem: {
    width: {
      xs: "100%",
      sm: "120px",
    },
    height: {
      xs: "150px",
      sm: "120px",
    },
    borderRadius: "4px",
    cursor: "pointer",
  },
  imgItemEditing: {
    width: {
      xs: "100%",
      sm: "220px",
      md: "240px",
    },
    maxWidth: "350px",
    height: {
      xs: "300px",
      sm: "220px",
      md: "240px",
    },
    borderRadius: "4px",
    "& video": {
      maxWidth: {
        xs: "100%",
        sm: "220px",
        md: "240px",
      },
      maxHeight: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
      objectFit: "cover",
    },
  },
  editViewBtn: {
    color: "#8C499C",
    bgcolor: "#fff",
  },
  titleImgItem: {
    display: "flex",
    color: "red",
    m: {
      xs: "15px 0 15px 0",
      sm: "15px",
    },
    "& > p": { ml: "5px" },
  },
  draftBox: {
    borderRadius: "8px",
    bgcolor: "rgba(0, 0, 0, 0.03)",
    p: "16px",
    m: {
      xs: "16px 24px 16px 0",
      sm: "24px",
    },
  },
  isDraftBox: {
    columnGap: {
      xs: "16px",
      sm: "20px",
    },
    rowGap: {
      xs: "16px",
      sm: "20px",
    },
    flexWrap: "wrap",
    width: "100%",
    mt: "12px",
    pb: {
      xs: "16px",
      sm: 0,
    },
  },
  urlBox: {
    height: {
      xs: "100px",
      sm: "120px",
    },
    width: {
      xs: "100px",
      sm: "120px",
    },
    borderRadius: "8px",
    position: "relative",
    mt: 2,
    overflow: "hidden",
    border: "1px solid #E0E0E0",
  },
  urlIcon: {
    bgcolor: "rgba(255, 255, 255, 0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > .material-icons": {
      fontSize: "40px",
    },
  },
  urlImg: {
    "& > img": {
      width: "80%",
      height: "80%",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  draftVideo: {
    width: { xs: "100px", sm: "120px" },
    height: { xs: "100px", sm: "120px" },
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    "& > .css-1637l5p": {
      borderRadius: "0px",
    },
  },
  iconPlayVido: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    maxWith: "350px",
  },
  imgItemEditingGrid: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: {
      xs: "100%",
      sm: "220px",
      md: "240px",
    },
    "& .viewImage": {
      display: "none",
    },
    maxWidth: "300px",
    "&:hover .viewImage": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      width: {
        xs: "100%",
        sm: "220px",
        md: "240px",
      },
      maxWidth: "350px",
      height: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
    },
    "&:hover .viewBgImage": {
      position: "absolute",
      top: 0,
      width: "100%",
      maxWidth: "350px",
      height: {
        xs: "300px",
        sm: "220px",
        md: "240px",
      },
      bgcolor: "#000",
      borderRadius: "4px",
      opacity: 0.3,
    },
  },
  imgItemEditingDesc: {
    bgcolor: "#FDF3F3",
    width: "90%",
    maxWidth: "330px",
    borderRadius: "8px",
    mt: "16px",
    padding: "12px",
    "& p:first-of-type": {
      color: "red",
      fontWeight: "500",
      fontSize: "14px",
      mb: "12px",
    },
  },
  imgItemApprove: {
    display: "flex",
    color: "#4CAF50",
    mb: "24px",
    "& > p": { fontWeight: "500", ml: "5px" },
  },
};

const draftBtn = {
  justifyContent: "center",
  "& button": {
    maxHeight: "40px",
    width: "240px",
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
};

export const useJobDraftPostStyle = {
  submitBtn: {
    justifyContent: "center",
    "& button": {
      maxHeight: "40px",
      width: "240px",
    },
    "& button:first-of-type": {
      border: "2px solid #8C499C",
      bgcolor: "#fff",
      color: "primary.main",
    },
    "& button:last-of-type": {
      border: "none",
    },
  },
  draftBtn: {
    justifyContent: "center",
    "& button": {
      maxHeight: "40px",
      width: "240px",
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
  },
  isDraftBtn: {
    ...draftBtn,
    "& button:first-of-type": {
      bgcolor: "#F4ECFF",
      color: "#893DBD",
    },
  },
  isNotDraftBtn: draftBtn,
};
