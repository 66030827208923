// mui and style
import { Avatar, Box, Typography } from "@mui/material";
import { useLeaderboardPodiumStyle as classes } from "../../Events.style";
// redux
import { useAppSelector } from "@store/hook";
import { selectActivity } from "@slices/Activity.slice";
// others
import useBreakpoint from "@utils/hooks/useBreakpoint";
import { setValueByBreakpoint as setVariant } from "@utils/helpers/setValueByBreakpoint.helpers";

export default function LeaderboardDodium() {
  const bp = useBreakpoint();
  const activity = useAppSelector(selectActivity);
  const topTear = activity?.ranking.top_tier;

  return (
    <Box sx={classes.rankingPodium}>
      <Box display={{ xs: "none", lg: "initial" }} sx={classes.scene}>
        <Box sx={classes.cube}>
          <Box sx={classes.podiumDetail4}>
            <Box display="flex" justifyContent="center">
              <Box sx={classes.pepleRanking4}>
                <Avatar
                  src={topTear && topTear[0]?.profile_image_url}
                  sx={{ width: "112px", height: "112px" }}
                />
              </Box>
            </Box>
            <Typography textAlign="center" mt={3}>
              {topTear && topTear[0]?.fullname}
            </Typography>
          </Box>
          <Box className="cube-face4 cube-face4-front">
            <Box width="80px" height="80px" sx={classes.numberRaking}>
              <Typography variant="h5" fontSize="48px" color="#893DBD">
                4
              </Typography>
            </Box>
            <Box bgcolor="#7329A5" className="total">
              <Typography variant={setVariant(bp, "title2", "h6")}>
                {topTear && topTear[0]?.point} P
              </Typography>
            </Box>
          </Box>
          <div className="cube-face4 cube-face4-top"></div>
        </Box>
      </Box>
      <Box sx={classes.scene}>
        <Box sx={classes.cube}>
          <Box sx={classes.podiumDetail2}>
            <Box display="flex" justifyContent="center">
              <Box sx={classes.pepleRanking2}>
                <Avatar
                  src={topTear && topTear[1]?.profile_image_url}
                  sx={{
                    width: { xs: "56px", sm: "122px", md: "142px" },
                    height: { xs: "56px", sm: "122px", md: "142px" },
                  }}
                />
              </Box>
            </Box>
            <Typography textAlign="center" mt={3}>
              {topTear && topTear[1]?.fullname}
            </Typography>
          </Box>
          <div className="cube-face2 cube-face2-front">
            <Box
              width={["32px", "100px"]}
              height={["32px", "100px"]}
              sx={classes.numberRaking}
            >
              <Typography
                variant="h5"
                fontSize={["24px", "60px"]}
                color="#542485"
              >
                2
              </Typography>
            </Box>
            <Box bgcolor="#3F116D" className="total">
              <Typography variant={setVariant(bp, "title2", "h6")}>
                {topTear && topTear[1]?.point} P
              </Typography>
            </Box>
          </div>
          <div className="cube-face2 cube-face2-top" />
        </Box>
      </Box>
      <Box sx={classes.scene}>
        <Box sx={classes.cube}>
          <Box sx={classes.podiumDetail1}>
            <Box display="flex" justifyContent="center">
              <Box sx={classes.pepleRanking1}>
                <img
                  src={require("@assets/images/referral/rank-1.png")}
                  alt="rank1"
                />
                <Avatar src={topTear && topTear[2]?.profile_image_url} />
              </Box>
            </Box>
            <Typography textAlign="center" mt={3}>
              {topTear && topTear[2]?.fullname}
            </Typography>
          </Box>
          <div className="cube-face1 cube-face1-front">
            <Box
              width={["48px", "120px"]}
              height={["48px", "120px"]}
              sx={classes.numberRaking}
            >
              <Typography
                variant="h5"
                fontSize={["36px", "72px"]}
                color="#EA7305"
              >
                1
              </Typography>
            </Box>
            <Box bgcolor="#EA7305" className="total">
              <Typography variant={setVariant(bp, "title2", "h6")}>
                {topTear && topTear[2]?.point} P
              </Typography>
            </Box>
          </div>
          <div className="cube-face1 cube-face1-top" />
        </Box>
      </Box>
      <Box sx={classes.scene}>
        <Box sx={classes.cube}>
          <Box sx={classes.podiumDetail3}>
            <Box display="flex" justifyContent="center">
              <Box sx={classes.pepleRanking3}>
                <Avatar src={topTear && topTear[3]?.profile_image_url} />
              </Box>
            </Box>
            <Typography textAlign="center" mt={3}>
              {topTear && topTear[3]?.fullname}
            </Typography>
          </Box>
          <div className="cube-face3 cube-face3-front">
            <Box
              width={["32px", "100px"]}
              height={["32px", "100px"]}
              sx={classes.numberRaking}
            >
              <Typography
                variant={setVariant(bp, "title1", "h5")}
                fontSize={["24px", "60px"]}
                color="#CD175B"
              >
                3
              </Typography>
            </Box>
            <Box bgcolor="#CD175B" className="total">
              <Typography variant={setVariant(bp, "title2", "h6")}>
                {topTear && topTear[3]?.point} P
              </Typography>
            </Box>
          </div>
          <div className="cube-face3 cube-face3-top" />
        </Box>
      </Box>
      <Box display={{ xs: "none", lg: "initial" }} sx={classes.scene}>
        <Box sx={classes.cube}>
          <Box sx={classes.podiumDetail5}>
            <Box display="flex" justifyContent="center">
              <Box sx={classes.pepleRanking5}>
                <Avatar
                  src={topTear && topTear[4]?.profile_image_url}
                  sx={{ width: "112px", height: "112px" }}
                />
              </Box>
            </Box>
            <Typography textAlign="center" mt={1}>
              {topTear && topTear[4]?.fullname}
            </Typography>
          </Box>
          <div className="cube-face5 cube-face5-front">
            <Box width="80px" height="80px" sx={classes.numberRaking}>
              <Typography variant="h5" fontSize="48px" color="#2F2D88">
                5
              </Typography>
            </Box>
            <Box bgcolor="#2F2D88" className="total">
              <Typography variant={setVariant(bp, "title2", "h6")}>
                {topTear && topTear[4]?.point} P
              </Typography>
            </Box>
          </div>
          <div className="cube-face5 cube-face5-top"></div>
        </Box>
      </Box>
    </Box>
  );
}
