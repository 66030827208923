import { Box, Grid, Tooltip, Typography } from "@mui/material";

interface WrapInputWithLabelProps {
  title: any;
  children: any;
  [any: string]: any;
  subTitle?: string;
}

function WrapInputWithLabel(props: WrapInputWithLabelProps) {
  const { title, children, tooltips, required, labelWidth, subTitle } = props;

  const styles = {
    label: {
      minWidth: "80px",
      width: labelWidth ? labelWidth : "150px",
    },
  };

  return (

    <Grid container mb="10px">
      <Grid item xs={3} sx={styles.label}>
        <Box
          sx={{
            fontSize: "14px",
            display: "flex",
            mt: subTitle ? 0 : 2.5,
          }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Box>{title}</Box>
              {required && <span className="require">*</span>}
            </Box>

            {subTitle && (
              <Typography fontSize="12px" color="#5C5C5C">
                {subTitle}
              </Typography>
            )}
          </Box>
          {required && (
            <Box display="flex" alignItems="end">
              <Typography variant="body1" fontWeight="400"></Typography>
              {/* <span> :</span> */}
            </Box>
          )}
          <Box display="flex" alignItems="end">
            {tooltips && (
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.white",
                      },
                      color: "common.black",
                      width: 500,
                      p: 3,
                    },
                  },
                }}
                title={tooltips}
              >
                <span>
                  <img
                    src="@assets/images/info.png"
                    style={{
                      marginLeft: "5px",
                      width: "15px",
                      height: "15px",
                      objectFit: "contain",
                    }}
                    alt="info"
                  />
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
}

export default WrapInputWithLabel;
