// mui and icon
import { Box, Button, Grid, Typography } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
// style and other
import { useCampaignInfluencerListStyle as classes } from "../InfluencerList.style";
import dayjs from "dayjs";

export default function InfluencerListStatus(props: any) {
  const { status } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>ประกาศรับสมัคร</Typography>
          <Typography>
            {dayjs(status?.start_recruiting_date).format("DD/MM/YYYY")} -{" "}
            {dayjs(status?.end_recruiting_date).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>ประกาศผลการคัดเลือกภายในวันที่</Typography>
          <Typography>
            {dayjs(status?.announce_date).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนที่สมัคร</Typography>
          <Typography>{status?.job_count}</Typography>
        </Box>
      </Grid>
      <Grid item xs={1.5}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนที่ต้องการ</Typography>
          <Typography>{status?.job_limit}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Button
          sx={classes.selectInflueButton}
          //   onClick={handleOpenSelectInfluDialog}
          fullWidth
        >
          <PersonAddAltIcon />
          <Typography>ต้องการเลือกอินฟลูเอนเซอร์</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
