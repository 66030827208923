const numFormater = Intl.NumberFormat("en", {
  notation: "standard",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const percentPrice = (type: string, price: number) => {
  const isDeposit = type === "deposit";
  let num = 0;

  if (price <= 10000) {
    num = isDeposit ? 0.2 : 0.8;
  } else if (price <= 50000) {
    num = isDeposit ? 0.15 : 0.85;
  } else if (price <= 100000) {
    num = isDeposit ? 0.08 : 0.92;
  } else {
    num = isDeposit ? 0.05 : 0.95;
  }
  return numFormater.format(price * num);
};

export const depositInfo = (price: number) => {
  const num =
    price <= 10000 ? 20 : price <= 50000 ? 15 : price <= 100000 ? 8 : 5;
  return num;
};