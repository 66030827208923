// mui and style
import { Box, Grid, Typography } from "@mui/material";
import { useCampaignInfluencerListStyle as classes } from "../InfluencerList.style";
// type
import { IStatusInfluencerSelectedProps } from "../InfluencerList.type";

export default function InfluencerSelected(
  props: IStatusInfluencerSelectedProps
) {
  const { summary } = props;

  const numberFormat = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} lg={2}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>จำนวนอินฟลูเอนเซอร์</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {summary?.job_count} / {summary?.job_limit}
          </Typography>
        </Box>
      </Grid>
      {[
        {
          label: "Follower",
          value: summary?.total_followers
        },
        {
          label: "Likes",
          value: summary?.total_likes
        },
        {
          label: "Comments",
          value: summary?.total_comments
        },
        {
          label: "Share",
          value: summary?.total_shares
        },
      ].map((item, index) => (
        <Grid item key={index} xs={12} sm={3} lg={1.5}>
          <Box sx={classes.influRegisCampItem}>
            <Typography>{item.label}</Typography>
            <Typography variant="h5" fontWeight="600" color="#893DBD">
              {numberFormat.format(item.value || 0)}
            </Typography>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} sm={12} lg={4}>
        <Box sx={classes.influRegisCampItem}>
          <Typography>Budget</Typography>
          <Typography variant="h5" fontWeight="600" color="#893DBD">
            {Intl.NumberFormat("en").format(summary?.current_budget || 0)} /{" "}
            {Intl.NumberFormat("en").format(summary?.total_budget || 0)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
