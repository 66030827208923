// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Avatar,
  Typography,
  DialogContent,
} from "@mui/material";
// type and style
import { ICreateCampaignSuccessDialogProps } from "../CampaignDetail.type";
import { useCreatecampaignSuccessStyle as classes } from "../CampaignDetail.style";
// react route and redux
import { useAppSelector } from "@store/hook";
import { useHistory } from "react-router-dom";
import { selectEmployerInfo } from "@slices/EmployerInfo.slice";

export default function CreateCampaignSuccessDialog(
  props: ICreateCampaignSuccessDialogProps
) {
  const { open, onClose, campaignName, campaignType } = props;

  const history = useHistory();
  const employerInfo = useAppSelector(selectEmployerInfo);

  const backEmpInfo = () => {
    history.push(`/employers/${employerInfo?.employer_full_id}/info`);
    onClose(false);
  };

  const backCampDetail = () => {
    history.push(`/campaigns`);
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent>
        <Box sx={classes.content}>
          <Avatar
            src={require("@assets/images/infographic/influencer/job-board/private-accept-invitation.png")}
          />
          <Box sx={classes.contentTxt}>
            <Typography>สร้างแคมเปญสำเร็จ</Typography>
            {campaignType === "private" ? (
              <Typography>
                {`คุณได้ยื่นข้อเสนอแคมเปญ “${campaignName || ""}”
              ให้กับอินฟลูเอนเซอร์`}
              </Typography>
            ) : (
              <Typography>
                {`แคมเปญ “${campaignName || ""}”
              ได้ประกาศรับสมัครอินฟลูเอนเซอร์เป็นที่เรียบร้อยแล้ว`}
              </Typography>
            )}
          </Box>
          <Stack direction="row" spacing={4} sx={classes.applyButton}>
            <Button onClick={backEmpInfo}>กลับหน้า “Employer Info”</Button>
            <Button onClick={backCampDetail} variant="contained">
              ไปหน้า Campign
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
