// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from "@mui/material";
// style and type
import { useSelectInfluencerDialogStyle as classes } from "../InfluencerList.style";
import { ISelectInfluencerDialogProps } from "../InfluencerList.type";

export default function SelectInfluencerDialog(
  props: ISelectInfluencerDialogProps
) {
  const {
    selectInfluDialog,
    handleOpenSelectInfluDialog,
    selectInfluencerActive,
  } = props;

  return (
    <Dialog
      open={selectInfluDialog}
      onClose={handleOpenSelectInfluDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent>
        <Box sx={classes.content}>
          <Typography variant="h5">คัดเลือกระหว่างช่วงการรับสมัคร</Typography>

          <Typography variant="body2" sx={{ marginTop: "8px" }}>
            การเปิดรับสมัครจะเปิดจนกว่าผู้จ้างงานจะทำการคัดเลือกเสร็จสิ้น
          </Typography>
          <Typography variant="body2">
            หากผู้จ้างงานทำการ "เลือก" และ "ยืนยัน"
            รายชื่อโดยชำระค่าบริการที่เหลือ
          </Typography>
          <Typography variant="body2">
            ระบบขะทำการปิดรับสมัครพร้อมประกาศรายชื่ออินฟลูเอนเซอร์ที่ผ่านการคัดเลือกโดยอัตโนมัติ
          </Typography>

          <Box
            sx={{
              "& > img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
            }}
          >
            <img
              src={require("@assets/images/infographic/admin/influencer-selected/dialog-select.png")}
              alt="dialog-select-influencer"
            />
          </Box>

          <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
            ท่านต้องการดำเนินการต่อหรือไม่ ?
          </Typography>
          <Stack direction="row" spacing={4} sx={classes.applyButton}>
            <Button onClick={handleOpenSelectInfluDialog}>ยกเลิก</Button>
            <Button variant="contained" onClick={selectInfluencerActive}>
              ดำเนินการต่อ
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
