import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// api and type
import { AppInformationAPI } from "@api/global";
import { IContentTypeState } from "./type";

const initialState: IContentTypeState = {
  data: null,
  loading: false,
  error: "",
};

export const getContentTypeAsync = createAsyncThunk(
  "contentTypeList",
  async () => {
    try {
      const response = await AppInformationAPI.getContentType();
      return response;
    }
    catch (err){
      throw(err)
    }
  }
)

export const ContentTypeListSlice = createSlice({
  name: "contentTypeList",
  initialState,
  reducers: {
    contentTypeList: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContentTypeAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getContentTypeAsync.fulfilled, (state, action) => {
        const data = action.payload;
        state.data = data
        state.loading = false;
        state.error = "";
      })
      .addCase(getContentTypeAsync.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const selectContentTypelist = (state: RootState) => state.contentType.data;
export const selectContentTypeOnload = (state: RootState) => state.contentType.loading;

export default ContentTypeListSlice.reducer;