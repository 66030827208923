import { useState, useEffect } from "react";
// mui
import {
  Box,
  Grid,
  Icon,
  Stack,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
// types and style
import { ISocialRes } from "@api/influencer/type";
import { ISocialMediaAccountProps } from "./CampaignDetail.type";
import { useSocialMediaAccountStyle as classes } from "./CampaignDetail.style";
// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hook";
import { selectInfluencerSocial } from "@slices/InfluencerSocial.slice";
import { setSnackBar, setSnackBarIsActive } from "@slices/CampaignInfo.slice";
// others
import { hBlack } from "@theme/index";
import { useHistory, useLocation } from "react-router-dom";
import { SocialIconSelected } from "../icon-selected/SocialIconSelected";

export default function SocialMediaAccount(props: ISocialMediaAccountProps) {
  const { campaignInfo, handleSocialId, jobInfo } = props;
  const [social, setSocial] = useState<ISocialRes[]>();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const infSocial = useAppSelector(selectInfluencerSocial);
  const campInfo = jobInfo?.campaign_info || campaignInfo;
  const plateformSelected = campInfo?.platform || "";
  const socialAccount = social?.[0]?.social_account;

  useEffect(() => {
    const platformMap: Record<string, string> = {
      facebook: "facebook",
      twitter: "twitter",
      tiktok: "tiktok",
      youtube: "youtube",
      instagram: "instagram",
    };

    const selectedSocial = infSocial?.[platformMap[plateformSelected]];
    setSocial(selectedSocial);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [plateformSelected, infSocial]);

  useEffect(() => {
    if (socialAccount?.length && handleSocialId) {
      handleSocialId(Number(socialAccount?.[0].social_account_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [social]);

  const handleToMyProfile = () => {
    const data = {
      campaignName: campInfo?.name,
      organizationUrl: campInfo?.organisation?.attachment?.url,
      path: location.pathname,
    };
    dispatch(setSnackBar(data));
    dispatch(setSnackBarIsActive(true));
    history.push("/my-profile");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Platform
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-5px", 0]}>
        <Stack direction="row" spacing={2} sx={classes.platform}>
          <SocialIconSelected platform={plateformSelected} />
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {plateformSelected}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <Typography variant="title1" color={hBlack[200]}>
          Social Media Account
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} xl={9} mt={["-5px", 0]}>
        {socialAccount?.length ? (
          <Box sx={classes.social}>
            <Avatar
              sx={{ borderRadius: "4px" }}
              src={socialAccount?.[0]?.profile_image || ""}
            />
            <Box width="70%" flexWrap="nowrap">
              <Typography>{socialAccount?.[0]?.name || ""}</Typography>
              <Typography>
                {socialAccount?.[0]?.social_account_type || ""}
              </Typography>
            </Box>
          </Box>
        ) : jobInfo ? (
          <Box sx={classes.social}>
            <Avatar
              sx={{ borderRadius: "4px" }}
              src={jobInfo.social_account?.profile_image_url}
            />
            <Box width="70%" flexWrap="nowrap">
              <Typography>{jobInfo.social_account?.name}</Typography>
              <Typography>
                {jobInfo.social_account?.social_account_type}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Typography mb={2}>
              ไปหน้า My Profile เพื่อทำการเชื่อมต่อบัญชีของคุณ
            </Typography>
            <Button
              variant="outlined"
              onClick={handleToMyProfile}
              startIcon={<Icon className="ico-hu-person-circle" />}
            >
              My Profile
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
}
