import { useState } from "react";
// mui and componrnt
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardInfluencerPrivate from "./CardInfluencerPrivate";
// redux
import { useAppSelector } from "@store/hook";
import { selectInfluencerPrivateCampaign } from "@slices/CampaignPrivate.slice";
// grid-dnd
import {
  swap,
  move,
  GridItem,
  GridDropZone,
  GridContextProvider,
} from "react-grid-dnd";

export default function PrivateSelected() {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const influencerPrivateInfo = useAppSelector(selectInfluencerPrivateCampaign);

  const listing = influencerPrivateInfo?.item.filter((item) => {
    return item.status === "listing";
  });
  const reserved = influencerPrivateInfo?.item.filter((item) => {
    return item.status === "reserved";
  });

  const [items, setItems] = useState<any>({
    left: listing,
    rigth: reserved,
  });

  function onChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ): void {
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems((prevItems: any) => ({
        ...prevItems,
        [sourceId]: result[0],
        [targetId]: result[1],
      }));
    } else {
      const result = swap(items[sourceId], sourceIndex, targetIndex);
      return setItems((prevItems: any) => ({
        ...prevItems,
        [sourceId]: result,
      }));
    }
  }

  return (
    <GridContextProvider onChange={onChange}>
      <Box sx={{ touchAction: "none" }}>
        <GridDropZone
          id="left"
          boxesPerRow={sm ? 1 : md ? 3 : lg ? 8 : 8}
          rowHeight={100}
          style={{
            border: "1px dashed #E0E0E0",
            borderRadius: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            minHeight: "400px",
          }}
        >
          {items.left.map((item: any, index: number) => {
            return (
              <GridItem key={index}>
                <CardInfluencerPrivate item={item} handleClick={() => {}} />
              </GridItem>
            );
          })}
        </GridDropZone>

        <Box sx={{ margin: "24px 0" }}>
          <Typography variant="h6">2. อินฟลูเอนเซอร์ตัวสำรอง</Typography>
          <Typography>
            กรณีที่รายชื่อตัวจริงปฎิเสธการรับงาน
            ระบบจะดำเนินการส่งคำเชิญให้ตัวสำรองตามลำดับ
          </Typography>
        </Box>
        <GridDropZone
          id="rigth"
          boxesPerRow={sm ? 1 : md ? 3 : lg ? 8 : 8}
          rowHeight={70}
          style={{
            border: "1px dashed #E0E0E0",
            borderRadius: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            minHeight: "400px",
          }}
        >
          {items.rigth.map((item: any) => {
            return (
              <GridItem key={item.influencer_id}>
                <CardInfluencerPrivate item={item} handleClick={() => {}} />
              </GridItem>
            );
          })}
        </GridDropZone>
      </Box>
    </GridContextProvider>
  );
}
