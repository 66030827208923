export const useShippingProductStyle = {
  shippingProductTitle: {
    p: "16px",
    mt: "15px",
    bgcolor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  },
  prductImageBox: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: "110px",
      height: "110px",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
  productImages: {
    position: "absolute",
    right: 0,
    width: "110px",
    height: "110px",
    borderRadius: "8px",
    bgcolor: "#000",
    opacity: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      color: "#fff",
      fontSize: "26px",
    },
  },
};
