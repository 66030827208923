import dayjs from "dayjs";
import * as yup from "yup";

export const SignInFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
  password: yup.string().required("Please enter your password."),
});

export const CreateAccountFormSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Please enter Full Name.")
    .matches(
      /^([A-z\u0E01-\u0E5B]+\D*)(\s{1})([A-z\u0E01-\u0E5B].\D*)$/,
      "Full name is not valid"
    ),
  birthdate: yup.string().required("Please enter your birthdate.")
  .test("birthdate", "Birth Date is invalid", function(value) {
    const isDateValid = (date: string) => {
      const dateFormat = "MM/DD/YYYY";
      const parsedDate = dayjs(date, dateFormat, true);
      const isValid = parsedDate.isValid();
      const isInputSameAsParsed = parsedDate.format(dateFormat) === date;
  
      return isValid && isInputSameAsParsed;
    }
    return isDateValid(value || "")
  }).test("birthdate",'Year should be B.C.', function(value) {
    const presentYear = new Date().getFullYear();
    const vlYear = dayjs(value).year()
    return presentYear >= vlYear
  }).typeError("Please enter your birthdate."),
  gender: yup.string().required("Please enter your gender.").nullable(true),
  nationality: yup
    .string()
    .required("Please select your nationality.")
    .nullable(true),
  phone: yup
    .string()
    .required("Please enter your phone.")
    .matches(
      /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
      "Phone number should be 10 digits"
    ),
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
  password: yup
    .string()
    .required("Please enter your password.")
    .matches(
      /^[A-Za-z0-9]+$/,
      "ต้องเป็นตัวอักษรตัวใหญ่หรือตัวเล็กหรือตัวเลขในภาษาอังกฤษเท่านั้น"
    )
    .matches(
      /^.{4,16}$/,
      "ต้องมีความยาวตั้งแต่ 4 ตัวอักษร และไม่เกิน 16 ตัวอักษร"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password.")
    .oneOf([yup.ref("password")], "Passwords do not match.")
    .matches(
      /^[A-Za-z0-9]+$/,
      "ต้องเป็นตัวอักษรตัวใหญ่หรือตัวเล็กหรือตัวเลขในภาษาอังกฤษเท่านั้น"
    )
    .matches(
      /^.{4,16}$/,
      "ต้องมีความยาวตั้งแต่ 4 ตัวอักษร และไม่เกิน 16 ตัวอักษร"
    ),
});

export const ForgetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Please enter your email."),
});

export const ResetPasswordSchema = yup.object().shape({
  recovery_code: yup.string().required("Please enter your recover code."),
  new_password: yup
    .string()
    .required("Please enter your new password.")
    .matches(
      /^[A-Za-z0-9]+$/,
      "ต้องเป็นตัวอักษรตัวใหญ่หรือตัวเล็กหรือตัวเลขในภาษาอังกฤษเท่านั้น"
    )
    .matches(
      /^.{4,16}$/,
      "ต้องมีความยาวตั้งแต่ 4 ตัวอักษร และไม่เกิน 16 ตัวอักษร"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password.")
    .oneOf([yup.ref("new_password")], "Passwords do not match.")
    .matches(
      /^[A-Za-z0-9]+$/,
      "ต้องเป็นตัวอักษรตัวใหญ่หรือตัวเล็กหรือตัวเลขในภาษาอังกฤษเท่านั้น"
    )
    .matches(
      /^.{4,16}$/,
      "ต้องมีความยาวตั้งแต่ 4 ตัวอักษร และไม่เกิน 16 ตัวอักษร"
    ),
});
