// mui and style
import {
  Box,
  Icon,
  Avatar,
  Button,
  Typography,
  DialogContent,
} from "@mui/material";
import { useTransferOwnershipStyle as classes } from "../SettingTab.style";
// other
import { ITransferOwnershipDialogProps } from "../../Member.type";
import DialogScrollingCustom from "@global/dialog-scrolling-custom";

export default function TransferOwnershipDialog(props: ITransferOwnershipDialogProps) {
  const { open, onClose, teamLead, newLead } = props;

  return (
    <DialogScrollingCustom
      title="โอนสิทธิ์การเป็นเจ้าของทีม"
      open={open}
      onClose={() => onClose(false)}
      sx={classes.dialog}
    >
      <DialogContent sx={classes.content}>
        <Box sx={classes.memberBox}>
          <Box width="100%">
            <Typography variant="title1" textAlign="center" display="block">
              Team Lead เดิม
            </Typography>
            <Box sx={classes.memberCard}>
              <Box display="flex">
                <Avatar
                  src={teamLead?.profile_image_url}
                  sx={{ width: "48px", height: "48px" }}
                />
                <Box sx={classes.memberCardDetail}>
                  <Typography variant="title1">{teamLead?.fullname}</Typography>
                  <Typography variant="body2">{teamLead?.email}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Icon className="ico-hu-arrow-right" sx={{ m: "35px 15px 0 15px" }} />
          <Box width="100%">
            <Typography variant="title1" textAlign="center" display="block">
              Team Lead ใหม่
            </Typography>
            <Box sx={classes.memberCard}>
              <Box display="flex">
                <Avatar
                  src={newLead?.profile_image_url}
                  sx={{ width: "48px", height: "48px" }}
                />
                <Box sx={classes.memberCardDetail}>
                  <Typography variant="title1">{newLead?.fullname}</Typography>
                  <Typography variant="body2">{newLead?.email}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt="24px">
          <Typography textAlign="center">
            คุณต้องการโอนสิทธิ์ในการดูแลทีม{" "}
            <span style={{ fontWeight: 500 }}>“{teamLead?.fullname}”</span>{" "}
            ให้กับ{" "}
            <span style={{ fontWeight: 500 }}>“{newLead?.fullname}”</span>{" "}
            ใช่หรือไม่?
          </Typography>
          <Box sx={classes.submitBtn}>
            <Button>ยกเลิก</Button>
            <Button variant="contained">ยืนยัน</Button>
          </Box>
        </Box>
      </DialogContent>
    </DialogScrollingCustom>
  );
}
