import { useEffect } from "react";
// mui and component
import { Grid, Typography } from "@mui/material";
import Loading from "@components/global/loading/Loading";
import PackagePlanList from "@components/subscription/current-package-plan/PackagePlanList";
import PackagePlanSummary from "@components/subscription/current-package-plan/PackagePlanSummary";
// redux
import {
  selectEmployerInfo,
  getEmployerInfoAsync,
  selectEmployerInfoOnload,
} from "@slices/EmployerInfo.slice";
import { useAppDispatch, useAppSelector } from "@store/hook";
import { getPackageInfoAsync, getWalletBalanceAsync } from "@slices/PackageInfo.slice";
// other
import { useHistory } from "react-router-dom";

export default function SubscriptionPlan() {
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  const employerInfo = useAppSelector(selectEmployerInfo);
  const loading = useAppSelector(selectEmployerInfoOnload);

  useEffect(() => {
    dispatch(getEmployerInfoAsync());
    dispatch(getPackageInfoAsync());
    dispatch(getWalletBalanceAsync({
      isPersonal: employerInfo?.package_plan === "personal",
      employer_id: employerInfo?.employer_full_id,
      team_id: employerInfo?.team?.id,
    }));
  // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (employerInfo?.package_plan === "no_plan") {
      history.replace(`subscription/plan/${employerInfo?.package_plan}`)
    };

    // eslint-disable-next-line
  },[employerInfo?.package_plan]);

  if (loading) return <Loading />

  return (
    <div className="container-main">
      <div>
        <Typography variant="h5">Subscription Plan</Typography>
        <Typography variant="subTitle1">การสมัครสมาชิก</Typography>
      </div>
      <Grid container spacing="24px" mt="5px">
        <Grid item xs={4}>
          <PackagePlanSummary />
        </Grid>
        <Grid item xs={8}>
          <PackagePlanList />
        </Grid>
      </Grid>
    </div>
  );
}
