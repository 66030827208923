import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function Foru() {
  return (
    <Box sx={{ margin: "20px" }}>
      <Typography>
        4. What information does the Company collect for use or transfer?
      </Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      <Typography>
        To provide the Company’s service, the Company must ask you to fill in
        your information before using the HashU Platform. If you do not provide
        data to the Company, it may result in the Company being unable to offer
        products and services that are suitable and meet your needs. Sometimes,
        the Company is obliged to collect your information from other sources
        (other than gathering information from you), which the Company will
        notify you within a period required by laws and in the event that the
        Company will use your said information to disclose the Company will
        inform you before the disclosure of that information for the first time.
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>
        The Company has guidelines and methods for collecting your information
        as follows:
      </Typography>
      <Typography sx={{ marginTop: "20px" }}>4.1 Data collection</Typography>
      <Box sx={{ marginTop: "10px" }}>
        <Typography>
          The Company will collect the following information:
        </Typography>
        <Typography>
          ● Any information which makes you personally identifiable, whether
          directly or indirectly, is regarded as <b>"Personal Information"</b>
          that will be protected under this Privacy Policy, including:
        </Typography>
        <Typography>
          ○ Information about you, including your name, surname, age, ID number,
          bank account interest, aptitude, and other properties. Suppose the
          Company needs to collect sensitive personal information, including
          gender, race, ethnicity, belief and /or opinion on different aspects
          of sexual behavior, personal history, and health information. In that
          case, the Company will inform you of the purpose of use and ask for
          your consent again.
        </Typography>
        <Typography>
          ○ Information about the domicile, residence, contact place.
        </Typography>
        <Typography>
          ○ Contact information such as email address, working address,
          telephone number, social media account.
        </Typography>
        <Typography>
          ○ Any other identification which you reached through online or offline
          systems.
        </Typography>
        <Typography>
          ○ Social media contact information, number of friends, number of
          posts, number of interactions with friends for your post.
        </Typography>
        <Typography>
          ○ consumer behavior, lifestyle, health information, preference
          information, opinions, and/or
        </Typography>
        <Typography>
          ○ Your personal information that is made public with your consent,
          such as the personal information you display through social media,
          including but not limited to your profile photo on your computer's
          network address (IP address), Brief biographical details on your
          account (Bio).
        </Typography>
        <Typography>
          ● Any other information that does not identify you personally can be
          used in combination to identify you. It may include personal
          information such as the number of followers on social media and
          product offerings or your service until able to identify you and/or
          information about your internet connection and the device you use to
          access the HashU Platform and usage data.
        </Typography>
      </Box>
      <Typography sx={{ marginTop: "20px" }}>4.2 Storage method</Typography>
      <Box sx={{ marginTop: "10px" }}>
        <Typography>
          The Company collects your information in the following ways:
        </Typography>
        <Typography>
          ● By means of communicating with you directly, and you provide
          information through that communication method.
        </Typography>
        <Typography>
          ● With an automated method of entering the HashU Platform through the
          website. The information obtained automatically may include usage
          details, addresses on a computer's network (IP addresses), and
          information obtained through cookies and other tracking technologies.
        </Typography>
        <Typography>
          ● By means of contacting other parties such as the Marketers or other
          persons associated with the HashU Platform and including its
          affiliates; group companies, authorized person of the Company to
          operate under the HashU Platform and their business partners.
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          The information which the Company receives from or through the HashU
          Platform in various forms. These include information that we may use
          automatic data collection technologies to collect that information
          which consists of
        </Typography>
        <Box>
          <Typography>
            ● The information that you have filled in forms on the HashU
            Platform, including information provided while
          </Typography>
          <Typography>○ Register for the HashU Platform.</Typography>
          <Typography>○ Subscribing the Company's services.</Typography>
          <Typography>○ Publish messages over the internet.</Typography>
          <Typography>○ Requesting additional services; or</Typography>
          <Typography>
            ○ Information you report problems with the HashU Platform.
          </Typography>
        </Box>
        <Typography sx={{ marginTop: "10px" }}>
          ● The information that you have filled in forms on the HashU Platform,
          including information provided while{" "}
        </Typography>
        <Typography>
          ● Records and copies of all forms of communication with each other,
          including email communications.
        </Typography>
        <Typography>
          ● Details of transactions you make through the Tellscrore Platform,
          such as your operations through the HashU Platform.
        </Typography>
        <Typography>
          ● Details of the implementation of your order. You may be requested to
          provide financial information prior to placing an order through the
          HashU Platform.
        </Typography>
        <Typography>
          ● Your search terms are listed on the HashU Platform.
        </Typography>
        <Typography>
          ● Information that you published or displayed in public of the HashU
          Platform ("Notice"), or send information to the other party using the
          HashU Platform or third parties ("User Content")
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          Note: when you publish Notice and/or User Content of other users via
          the HashU Platform, the Company assumes you already know and accept
          the risks that may arise as though you can configure the privacy
          settings for such information by logging into your personal account.
          The Company would like to inform you that no security measure is
          perfect or inaccessible. The Company cannot control the actions of
          other users who may publish Notice and/or User Content you provide to
          others, so the Company cannot and do not guarantee that an unwanted
          person will not see the publication and/or transmission of User
          Content. However, the Company has realized and fully complied with
          international information and technology security standards.
        </Typography>
        <Typography>
          ● Details of your visit to the HashU Platform also include traffic
          data, location data, logs, other communication information, and the
          sources you access and use on the HashU Platform.
        </Typography>
        <Typography>
          ● Information about computers or your internet connection also
          includes IP addresses, operating systems, and browser type.
        </Typography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>4.3 Storage method</Typography>
        <Typography sx={{ marginTop: "10px" }}>
          For your benefit, the Company will collect your personal information
          for as long as is reasonably necessary to achieve the objectives
          stated in this Privacy Policy, to establish and use or defend against
          legal claims. However, if there are any legal requirements, we may
          need to retain your personal data longer than that period.
        </Typography>
        <Typography sx={{ marginTop: "10px" }}>
          In addition, the Company would like to inform you that if you have
          exercised your right to delete your personal data, it will be
          destroyed immediately, and the Company will not be able to recover it
          in any case.
        </Typography>
      </Box>
    </Box>
  );
}
