import { RootState } from "@store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// type and api
import { JobAPI } from "@api/global";
import { IPrivateCampaignState } from "./type";

const initialState: IPrivateCampaignState = {
  data: null,
  loading: false,
  checkSelectedPrivate: {
    employerId: "",
    campaignId: "",
    item: [],
  },
  influSelected: [],
};

export const getInfluencerPrivateCampaign = createAsyncThunk(
  "influencerPrivateCampaign",
  async (req: { employerId: string; campaignId: string; params: { page: number, item_per_page: number}}) => {
    try {
      const response = await JobAPI.getInfluencerPrivate(req.employerId, req.campaignId, req.params);
      return response;
    } catch (err) {
      console.log("catch", err);
      throw err;
    }
  }
);

export const insertJobPrivateCampaign = createAsyncThunk(
  "insertJobPrivateCampaign",
  async (req: { employerId: string; campaignId: string; body: any }) => {
    try {
      const response = await JobAPI.insertJobPrivateCampaign(req.employerId, req.campaignId, req.body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const confirmJobPrivate = createAsyncThunk(
  "confirmJobPrivate",
  async (req: { employerId: string; id: string }) => {
    try {
      const response = await JobAPI.confirmJobPrivate(req.employerId, req.id);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const InfluencerPrivateCampaignSlice = createSlice({
  name: "influencerPrivateCampaignSlice",
  initialState,
  reducers: {
    setInfluencerPrivateCampaign: (state, { payload }) => {
      state.data = payload;
    },
    setCheckSelectedPrivate: (state, { payload }) => {
      state.checkSelectedPrivate = payload;
    },
    setInfluencerSelected: (state, { payload }) => {
      state.influSelected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerPrivateCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getInfluencerPrivateCampaign.fulfilled,
        (state, { payload }) => {
          state.data = payload;
          state.loading = false;
        }
      )
      .addCase(getInfluencerPrivateCampaign.rejected, (state) => {
        state.data = null;
        state.loading = false;
      })
      // Insert
      .addCase(insertJobPrivateCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(insertJobPrivateCampaign.fulfilled, (state, { payload }) => {
          state.data = payload;
          state.loading = false;
      })
      .addCase(insertJobPrivateCampaign.rejected, (state) => {
        state.data = null;
        state.loading = false;
      })
      // Confirm
      .addCase(confirmJobPrivate.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmJobPrivate.fulfilled, (state, { payload }) => {
          state.data = payload;
          state.loading = false;
      })
      .addCase(confirmJobPrivate.rejected, (state) => {
        state.data = null;
        state.loading = false;
      });
  },
});

export const { setInfluencerPrivateCampaign, setCheckSelectedPrivate, setInfluencerSelected } = InfluencerPrivateCampaignSlice.actions;

export const selectInfluencerPrivateCampaign = (state: RootState) => state.influencerPrivateCampaign.data;
export const selectInfluencerPrivateCampaignOnload = (state: RootState) => state.influencerPrivateCampaign.loading;
export const selecteCheckSelectPrivate = (state: RootState) => state.influencerPrivateCampaign.checkSelectedPrivate;
export const selectInfluencerSelected = (state: RootState) => state.influencerPrivateCampaign.influSelected;

export default InfluencerPrivateCampaignSlice.reducer;
