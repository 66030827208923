import React, { useState } from "react";
// mui
import {
  Box,
  Icon,
  List,
  Link,
  Avatar,
  Drawer,
  Divider,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
} from "@mui/material";
// Style
import MenuIcon from "@mui/icons-material/Menu";
import { useHeaderBarMobileStyle as classes } from "./HomeHeaderBar.styles";
// image and util
import Logo from "@assets/images/logo/hashu-logo2.svg";
import LogoWhite from "@assets/images/logo/hashu-logo1.svg";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
// api and type
import { AuthenticationAPI } from "@api/global";
import { IHomeHeaderBarMobileProps } from "./HomeHeader.types";
// other
import { menuList } from "./HomeHeaderBar";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";

export default function HomeHeaderBarMobile(props: IHomeHeaderBarMobileProps) {
  const { breakpoint, setOpenSignIn } = props;

  const [menuMobileState, setMenuMobileState] = useState(false);
  const [toggle, setToggle] = useState(false);

  const history = useHistory();
  const { session_token } = getTokenFromStorage();
  const { signOut } = useAuth();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
      setMenuMobileState(open);
    };

  const isHomePath = () => {
    const pathName = history.location.pathname;
    const regPath = pathName.match(/\d+/g)?.toString();
    switch (pathName.replace(`/${regPath}`, "")) {
      case "/":
        return true;
      case "/home/influencer":
        return true;
      case "/home/news-and-events":
        return false;
      case "/home/marketer":
        return true;
      case "/home/pricing":
        return false;
      case "/home/contact":
        return false;
      case "/home/support":
        return false;
      case "/home/support/tutorials":
        return false;
      default:
        return true;
    }
  };

  const onLogout = async () => {
    try {
      await AuthenticationAPI.signOut();
      const next = () => history.push("/");
      signOut(next);
    } catch (error) {
      const next = () => history.push("/");
      signOut(next);
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={menuMobileState}
        onClose={toggleDrawer(false)}
        sx={{
          "& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper": {
            borderRadius: "0 16px 16px 0",
          },
        }}
      >
        <Box
          sx={classes.drawerBox}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box py={3} px={2}>
            <Box display="flex" justifyContent="center" mt={12} mb={7}>
              <Link href="/" underline="none">
                <img src={Logo} alt="logo" style={classes.logoImg} />
              </Link>
            </Box>

            {toggle ? (
              <>
                <Box sx={classes.boxLoggedIn} onClick={() => setToggle(false)}>
                  <Box display="flex" alignItems="center">
                    <Icon className="ico-hu-chevron-left" />
                    Back
                  </Box>
                </Box>

                <Box mt="24px">
                  <Box
                    sx={classes.boxMenu}
                    onClick={() => {
                      session_token?.role === "influencer"
                        ? history.push(`/job-board`)
                        : history.push("/employers");
                    }}
                  >
                    <Box sx={classes.boxMenuIcon}>
                      <Icon className="ico-hu-home" />
                    </Box>
                    <Box width="70%">
                      <Typography variant="caption">HashU Console</Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={classes.boxMenu}
                    onClick={() => history.push(`/my-referral`)}
                  >
                    <Box sx={classes.boxMenuIcon}>
                      <Icon className="ico-hu-group" />
                    </Box>
                    <Box width="70%">
                      <Typography variant="caption">My Referral</Typography>
                    </Box>
                  </Box>

                  <Divider />

                  <Box onClick={toggleDrawer(false)}>
                    <Box onClick={() => setToggle(false)}>
                      <Box sx={classes.boxMenuLogout} onClick={onLogout}>
                        <Box sx={classes.boxMenuIcon}>
                          <Icon className="ico-hu-logout" />
                        </Box>
                        <Box width="70%">
                          <Typography variant="caption">ออกจากระบบ</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                {session_token ? (
                  <Box sx={classes.boxLoggedIn} onClick={() => setToggle(true)}>
                    <div>
                      <Avatar
                        src={session_token.profile_image_url}
                        alt="profile-images"
                      />
                    </div>
                    <Box width="100%">
                      <Typography
                        variant="caption"
                        sx={classes.profileFullName}
                      >
                        {session_token.fullname}
                      </Typography>
                    </Box>
                    <Box>
                      <Icon
                        className="ico-hu-chevron-right"
                        sx={classes.profileIcon}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={classes.boxSignIn} onClick={toggleDrawer(false)}>
                    <ListItemButton onClick={() => setOpenSignIn(true)}>
                      <ListItemText
                        primary="Sign In"
                        primaryTypographyProps={{
                          variant: breakpoint === "xs" ? "body1" : "h6",
                          fontWeight: "bold",
                          align: "center",
                          sx: classes.signInListItemText,
                        }}
                      />
                    </ListItemButton>
                  </Box>
                )}

                <Divider sx={{ mt: "24px" }} />

                <List sx={classes.menuList}>
                  {menuList.map((menu, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={classes.menuListItem}
                    >
                      <ListItemButton>
                        <Link href={menu.path} underline="none" width="100%">
                          <ListItemText
                            primary={menu.label}
                            primaryTypographyProps={{
                              variant: breakpoint === "xs" ? "body1" : "h6",
                              fontWeight: "bold",
                              color:
                                history.location.pathname === menu.path
                                  ? "secondary"
                                  : "grey.700",
                              align: "center",
                            }}
                          />
                        </Link>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Box>
        </Box>
      </Drawer>

      <Box sx={classes.menuAbsolute}>
        <IconButton
          size="small"
          onClick={toggleDrawer(true)}
          sx={{
            ...classes.menuIconButton,
            color: isHomePath() ? "common.white" : "common.black",
          }}
        >
          <MenuIcon color="inherit" fontSize="inherit" />
        </IconButton>
        <img
          src={isHomePath() ? LogoWhite : Logo}
          alt="HashuWhite"
          style={{
            width: breakpoint === "xs" ? "32px" : "40px",
            objectFit: "contain",
          }}
        />
      </Box>
    </React.Fragment>
  );
}
