import React, { useRef, useState } from "react";
// mui
import { Box, Icon, Zoom, Dialog, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

interface IViewMuitiImageDialogSmProps {
  open: boolean;
  onClose: (value: boolean) => void;
  images: {
    url: string;
  }[];
  initImgIndex?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

SwiperCore.use([Navigation]);

export default function ViewMultiImageDialogSm(
  props: IViewMuitiImageDialogSmProps
) {
  const { open, onClose, images, initImgIndex } = props;

  let initIndex = initImgIndex ? initImgIndex : 0;
  const [currentIndex, setCurrentIndex] = useState(initIndex);
  const swiperRef = useRef<any>(null);

  const handlePrevClick = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleSlideChange = (imgIndex: number) => {
    setCurrentIndex(imgIndex);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={Transition}
      sx={{
        "& .css-fkeb6g-MuiPaper-root-MuiDialog-paper, .css-t255vf": {
          borderRadius: 0,
          position: "initial",
          bgcolor: "#000",
          boxShadow: "none",
        },
        "& .MuiDialog-container": {
          position: "relative",
          display: "flex",
        },
        bgcolor: "rgba(0,0,0,1)",
        mx: "-35px",
      }}
    >
      <Icon
        className="ico-hu-close-circle"
        sx={{
          position: "absolute",
          top: "20px",
          right: "50px",
          color: "#fff",
          zIndex: 100,
          fontSize: "30px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          display: {
            xs: "none",
            sm: "flex",
          },
          alignItems: "center",
        }}
      >
        <Icon
          className="ico-hu-chevron-left-circle"
          sx={{
            color: "#fff",
            fontSize: "40px",
            ml: "50px",
            display: initImgIndex === 0 ? "none" : "block",
            cursor: "pointer",
          }}
          onClick={handlePrevClick}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100vh",
          display: {
            xs: "none",
            sm: "flex",
          },
          alignItems: "center",
        }}
      >
        <Icon
          className="ico-hu-chevron-right-circle"
          sx={{
            color: "#fff",
            fontSize: "40px",
            mr: "50px",
            display: currentIndex === images.length - 1 ? "none" : "block",
            cursor: "pointer",
          }}
          onClick={handleNextClick}
        />
      </Box>
      <Box
        sx={{
          height: "85vh",
          bgcolor: "none",
          display: "felx",
          justifyContent: "center",
          alignItems: "center",
          "& .mySwiper": {
            height: "85vh",
            maxWidth: "500px",
            width: {
              xs: "100vw",
              sm: "100%",
            },
            "& .swiperSlide": {
              width: {
                xs: "100vw",
                sm: "100%",
              },
              maxWidth: "600px",
              display: "flex",
              alignItems: "center",
            },
          },
        }}
      >
        <Swiper
          ref={swiperRef}
          className="mySwiper"
          onSlideChange={(swiper) => handleSlideChange(swiper.activeIndex)}
          initialSlide={currentIndex}
        >
          {images.map((item, index) => (
            <SwiperSlide key={index} className="swiperSlide">
              <Box
                component="img"
                sx={{ width: "100%", objectFit: "cover" }}
                src={item.url}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          left: "36px",
          color: "#fff",
          zIndex: 100,
          fontSize: "30px",
          cursor: "pointer",
          width: "100vw",
        }}
      >
        <Typography textAlign="center">{`${currentIndex + 1} จาก ${
          images.length
        }`}</Typography>
      </Box>
    </Dialog>
  );
}
