import { useContext, useEffect, useState } from "react";
// mui and style
import {
  Box,
  Grid,
  Icon,
  Button,
  Divider,
  Checkbox,
  Typography,
} from "@mui/material";
import { useOfferringToInfluencerStyle as classes } from "./Offering.style";
// api and route
import { CampaignAPI, PaymentAPI } from "@api/global";
import { useHistory, useLocation, useParams } from "react-router-dom";
// components
import PaymentMethod from "./PaymentMethod";
import PaymentSummary from "./PaymentSummary";
import Loading from "@components/global/loading/Loading";
import PrivateStep from "@components/campaign/campaign-detail/PrivateStep";
import ChargeSuccess from "@components/campaign/campaign-charge/charge-dialog/ChargeSuccess";
import ChargeNotSuccess from "@components/campaign/campaign-charge/charge-dialog/ChargeNotSuccess";
import TimelineDetailDialog from "@components/global/campaign-detail/detail-dialog/TimelineDetails";
import TermsAndConditionsDialog from "@components/global/campaign-detail/detail-dialog/TermsAndConditions";
// redux
import { useAppDispatch, useAppSelector } from "@store/hook";
import { selectCampaignInfo } from "@slices/CampaignInfo.slice";
import { getPaymentMethodAsync } from "@slices/PaymentMethod.slice";
// others
import { CampaignInfoContext } from "@views/campaign/campaign-detail";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import SwalCustom from "@components/global/sweet-alert-custom/SwalCustom";

export default function OfferringToInfluencer() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAcceptService, setIsAcceptService] = useState(false);
  const [isAcceptTimeline, setIsAcceptTimeline] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogSelected, setDialogSelected] = useState("");

  const { session_token } = getTokenFromStorage();
  
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employerId = params.get("emp_id") || session_token?.employer_id || "";
  const { campaignId } = useParams<{ campaignId: string }>();
  
  const campaignInfo = useAppSelector(selectCampaignInfo);
  const prdReviewType = campaignInfo?.product_review_type;
  const isProduct = prdReviewType === "send" || prdReviewType === "send_return";
  
  const campaignWallet = useContext(CampaignInfoContext)?.campaignWalletBalance;
  const isWalletBalance = (campaignWallet?.total || 0) >= 0;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodAsync());
  },[dispatch])

  const openConditionDialog = (key: string) => {
    setOpenDialog(true);
    setDialogSelected(key);
  };

  const numFormater = Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
  });

  const handleAcceptCondition = (key: string, checked: boolean) => {
    setOpenDialog(false);
    setDialogSelected(key);
    if (key === "timeline") setIsAcceptTimeline(!checked);
    if (key === "service") setIsAcceptService(!checked);
  };

  const onHolding = () => {
    setIsLoading(true);
    const body = {
      employer_id: employerId,
      description: "",
      campaign: {
        id: campaignId,
      },
    };
    
    PaymentAPI.holding(body)
      .then(() => {
        CampaignAPI.updateCampaignStatus({ status: "influencer_listing" }, campaignId)
          .then(() => {
            setIsLoading(false);
            history.push(`/campaigns/${campaignId}/info`)
          }).catch((error) => {
            console.log("error : ", error)
            setIsLoading(false);
          })
      })
      .catch(() => {
        setIsLoading(false);
        SwalCustom.fire({
          icon: "error",
          title: "Oops !",
          text: "There are someting wrong",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Close",
        });
      })
  };

  if (isLoading) return <Loading />

  return (
    <Box sx={classes.offeringWrap}>
      <div>
        <PrivateStep activeStep={3} />
        <div>
          <Typography variant="h5">1. ข้อตกลงและเงื่อนไข</Typography>
          <Box my="16px">
            {[
              {
                key: "timeline",
                label:
                  "ข้าพเจ้าได้อ่านและทำความเข้าใจ เกี่ยวกับระยะเวลาของแคมเปญ(Timeline) เรียบร้อยแล้ว",
                checked: isAcceptTimeline,
              },
              {
                key: "service",
                label: "ข้าพเจ้ายอมรับข้อกำหนดและเงื่อนไขการใช้บริการของ HashU",
                checked: isAcceptService,
              },
            ].map((li, index) => (
              <Box key={index} display="flex" alignItems="center">
                <Checkbox
                  checked={li.checked}
                  onClick={() => openConditionDialog(li.key)}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ color: "#8C499C" }}
                />
                <Box display="flex">
                  <Typography>{li.label}</Typography>
                  <Typography
                    onClick={() => openConditionDialog(li.key)}
                    color="primary"
                    ml={1}
                    sx={{ cursor: "pointer" }}
                  >
                    อ่านเพิ่มเติม
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </div>
        <Box mb="16px">
          <Typography variant="h5">2. ชำระค่าบริการ</Typography>
        </Box>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <PaymentSummary />
        </Grid>
        <Grid item xs={8}>
          <PaymentMethod />
          {isWalletBalance && (
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                startIcon={<Icon className="ico-hu-money-outline" />}
                onClick={onHolding}
                sx={classes.paymentBtn}
                disabled={!isAcceptTimeline || !isAcceptService}
              >
                ชำระเงิน{" "}
                {numFormater.format(campaignWallet?.campaign_price || 0)} บาท
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box>
        <Divider sx={{ my: "24px" }} />
        <Button
          onClick={() => history.goBack()}
          sx={{ width: "200px", fontSize: "16px" }}
        >
          Back
        </Button>
      </Box>
      {/* dialog */}
      {dialogSelected === "payment-success" ? (
        <ChargeSuccess
          open={openDialog}
          onClose={setOpenDialog}
          campaignPrice={campaignWallet?.campaign_price || 0}
        />
      ) : dialogSelected === "timeline" ? (
        <TimelineDetailDialog
          open={openDialog}
          onClose={setOpenDialog}
          handleAcceptCondition={handleAcceptCondition}
          timeline={campaignInfo?.timeline}
          readOnly={false}
          isPlace={campaignInfo?.review_type === "place"}
          isProduct={isProduct}
          isNoShipping={prdReviewType === "no_shipping"}
          orgUrl={campaignInfo?.organisation?.attachment?.url}
          period={campaignInfo?.period}
          isCampaignPrivate={campaignInfo?.campaign_type === "private"}
        />
      ) : dialogSelected === "service" ? (
        <TermsAndConditionsDialog
          open={openDialog}
          onClose={setOpenDialog}
          handleAcceptCondition={handleAcceptCondition}
        />
      ) : (
        <ChargeNotSuccess open={openDialog} onClose={setOpenDialog} />
      )}
    </Box>
  );
}
