// mui and style
import {
  Box,
  Stack,
  Dialog,
  Avatar,
  Button,
  Typography,
  DialogContent,
} from "@mui/material";
import { useSubmitDraftSuccessStyle as classes } from "../JobsTab.style";
// redux
import { useAppDispatch } from "@store/hook";
import { getInfluencerJobInfoAsync } from "@slices/InfluencerJobInfo.slice";
// type and others
import { ISubmitDraftSuccessDialogProps } from "../JobsTab.type";
import { getTokenFromStorage } from "@utils/helpers/getTokenFromStorage";
import { useHistory, useParams } from "react-router-dom";

export default function SubmitDraftSuccessDialog(
  props: ISubmitDraftSuccessDialogProps
) {
  const { open, onClose } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { jobId } = useParams<{ jobId: string }>();
  const { session_token } = getTokenFromStorage();

  const onCloseDialog = () => {
    const req = {
      jobId: jobId,
      influencerId: session_token?.influencer_id || "",
    };
    onClose(false);
    dispatch(getInfluencerJobInfoAsync(req));
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      sx={classes.dialog}
    >
      <DialogContent sx={classes.contentDialog}>
        <Box sx={classes.discBox}>
          <Avatar
            src={require("@assets/images/infographic/influencer/my-jobs/submit-draft-to-employer.png")}
            sx={classes.img}
          />
          <Box textAlign="center" my="32px">
            <Typography variant="h5">ส่ง Darft สำเร็จ</Typography>
            <Typography>คุณได้ทำการส่ง Draft ให้แก่ผู้จ้างงานแล้ว</Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={3} sx={classes.btnBox}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Close
          </Button>
          <Button variant="contained" onClick={() => history.push("/my-jobs")}>
            ไปหน้า "My Job"
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
